import CreateNewsForm from 'Forms/News/CreateNewsForm'
import React from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { notifyError, notifySuccess } from 'tools/toaster'

const AddNewsButton = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  return (
    <div className="p-2">
      <CreateNewsForm
        onCancel={() => navigate(-1)}
        onDone={news => {
          notifySuccess(intl.formatMessage({ id: 'news.form.create.success' }, { title: news.title }))
          navigate(-1)
        }}
        onError={notifyError}
      />
    </div>
  )
}

export default AddNewsButton
