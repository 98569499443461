import { invisibleButtonAttrs } from 'Components/Button/InvisibleButton'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Popup } from 'semantic-ui-react'
import { VideoClipError } from 'services/api/graphql'
import styled from 'styled-components'

const TriggerButton = styled.button`
  ${invisibleButtonAttrs}
  color: #ed4337;
  font-weight: bold;

  &.light {
    color: #000000;
    display: block;
    margin: auto;
    font-weight: normal;
  }
`

interface IProps {
  error?: VideoClipError | null
  buttonMessage?: string
  light?: boolean
}

const VideoErrorPopup: React.FC<IProps> = ({ error, buttonMessage = 'common.error', light: secondary }) => {
  return (
    <Popup
      on="click"
      trigger={
        <TriggerButton className={secondary ? 'light' : ''}>
          <FormattedMessage id={buttonMessage} />
        </TriggerButton>
      }
    >
      {error && (
        <>
          <strong>
            <FormattedMessage id="medias.videoClip.error.guid" />:{' '}
          </strong>
          {error.guid}
          <br />
          <strong>
            <FormattedMessage id="medias.videoClip.error.workflowStatus" />:{' '}
          </strong>
          {error.workflowStatus}
          <br />
          <strong>
            <FormattedMessage id="medias.videoClip.error.workflowErrorAt" />:{' '}
          </strong>
          {error.workflowErrorAt}
          <br />
          <strong>
            <FormattedMessage id="medias.videoClip.error.errorMessage" />:{' '}
          </strong>
          {error.errorMessage}
          <br />
          <strong>
            <FormattedMessage id="medias.videoClip.error.errorDetails" />:{' '}
          </strong>
          {error.errorDetails}
        </>
      )}
    </Popup>
  )
}

export default VideoErrorPopup
