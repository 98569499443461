import SelectItemListInput, { SelectItemListInputProps } from 'Components/Form/SelectItemListInput'
import { useGetEditedCategories } from 'models/EditedCategory'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { EditedCategory, Maybe, Organism } from 'services/api/graphql'

export interface SelectEditCategoryInputProps extends Omit<SelectItemListInputProps, 'options'> {
  value?: string | null
  organismId: Organism['id']
  onChange: (value: Maybe<EditedCategory['id']>) => void
}

export const SelectEditCategoryInput = ({ value, organismId, onChange, ...props }: SelectEditCategoryInputProps) => {
  const { data, loading } = useGetEditedCategories({
    variables: { filters: { organismId } },
    fetchPolicy: 'cache-and-network',
  })
  const intl = useIntl()
  const options = useMemo(() => {
    return (
      data?.map(data => ({
        key: data.id,
        value: data.id,
        text: data.title,
      })) || []
    )
  }, [data])
  return (
    <SelectItemListInput
      value={value}
      loading={loading}
      options={options}
      onChange={onChange}
      {...props}
      noSelectionEnabled={true}
      noSelectionTitle={intl.formatMessage({ id: 'common.none' })}
    />
  )
}
