import { QueryHookOptions, useMutation, useQuery } from '@apollo/client'
import {
  CreateWildmokaMediaLiveStreamInput,
  CreateMediaVideoClipInput,
  CREATE_MEDIA_FILE_MUTATION,
  CREATE_WILDMOKA_MEDIA_LIVESTREAM_MUTATION,
  CREATE_MEDIA_VIDEO_CLIP_MUTATION,
  DELETE_MEDIA_MUTATION,
  GET_MEDIAS_QUERY,
  GET_MEDIA_QUERY,
  Media,
  MutationCreateMediaFileArgs,
  MutationTogglePinArgs,
  QueryMediaArgs,
  QueryMediasArgs,
  TOGGLE_PIN_MUTATION,
  UpdateMediaInput,
  UPDATE_MEDIA_MUTATION,
  GENERATE_MEDIA_REPLAY_MUTATION,
  MutationGenerateMediaReplayArgs,
} from 'services/api/graphql'

export const useDeleteMedia = () =>
  useMutation<{ deleteMedia: boolean }, { mediaId: Media['id'] }>(DELETE_MEDIA_MUTATION)

export const useUpdateMedia = () =>
  useMutation<{ updateMedia: Media }, { mediaId: Media['id']; input: UpdateMediaInput }>(UPDATE_MEDIA_MUTATION)

export const useGenerateMediaReplay = () =>
  useMutation<{ generateMediaReplay: boolean }, MutationGenerateMediaReplayArgs>(GENERATE_MEDIA_REPLAY_MUTATION)

export interface GetMediasResult {
  totalCount: number
  medias: Media[]
}

export const useGetMedias = (options?: QueryHookOptions<{ medias: GetMediasResult }, QueryMediasArgs>) =>
  useQuery(GET_MEDIAS_QUERY, options)

export const useGetMedia = (
  mediaId: string,
  options?: QueryHookOptions<{ media: Media | undefined }, QueryMediaArgs>,
) => {
  const { data, ...rest } = useQuery<{ media: Media | undefined }>(GET_MEDIA_QUERY, {
    variables: { mediaId },
    ...options,
  })
  return { media: data && data.media, ...rest }
}

export const useCreateWildmokaMediaLiveStream = () =>
  useMutation<{ createWildmokaMediaLiveStream: Media }, { input: CreateWildmokaMediaLiveStreamInput }>(
    CREATE_WILDMOKA_MEDIA_LIVESTREAM_MUTATION,
  )

export const useCreateMediaVideoClip = () =>
  useMutation<{ createMediaVideoClip: Media }, { input: CreateMediaVideoClipInput }>(CREATE_MEDIA_VIDEO_CLIP_MUTATION)

export const useCreateMediaFile = () =>
  useMutation<{ createMediaFile: Media }, MutationCreateMediaFileArgs>(CREATE_MEDIA_FILE_MUTATION)

export const getMediaThumbail = (media: Media) =>
  media.image
    ? media.image.thumbUrl
    : media.thumbnail && media.thumbnail.image
    ? media.thumbnail.image.thumbUrl
    : undefined

export const useTogglePin = () => useMutation<{ togglePin: boolean }, MutationTogglePinArgs>(TOGGLE_PIN_MUTATION)
