import { convertToDropdownOptions } from 'Forms/utils'
import { FragmentOf, readFragment } from 'gql.tada'
import React, { FC, useMemo } from 'react'
import { Field } from 'react-final-form'
import { Form as UiForm, Table } from 'semantic-ui-react'
import { AffiliateType, LinkTag } from 'services/api/graphql'

import { AffiliateFieldType } from './AdCampaignFormFields'
import { LinkTitleTagFragment } from './fragments'

interface Props {
  index: number
  name: string
  item: AffiliateFieldType
  links?: FragmentOf<typeof LinkTitleTagFragment>[] | null
  onChangeFields: (index: number, item: AffiliateFieldType) => void
}

export const AffiliateLinkItem: FC<Props> = ({ index, name, links: _links, item, onChangeFields }) => {
  const links = _links && _links?.map(link => readFragment(LinkTitleTagFragment, link))
  const linkOptions = useMemo(
    () =>
      links
        ?.filter(
          ({ tag }) =>
            (item.type === AffiliateType.GoogleAdManager && tag === LinkTag.Google) ||
            (item.type !== AffiliateType.GoogleAdManager && tag !== LinkTag.Google),
        )
        .map(convertToDropdownOptions),
    [item.type, links],
  )

  return (
    <Table.Cell>
      <Field
        name={`${name}.AffiliateLinkItem`}
        render={({ input }) => {
          return (
            <UiForm.Dropdown
              name={input.name}
              selection
              search
              onChange={(e, { value }) => {
                if (value) {
                  onChangeFields(index, { ...item, type: item.type, linkId: value?.toString() })
                }
              }}
              options={linkOptions}
              value={item.linkId}
            />
          )
        }}
      />
    </Table.Cell>
  )
}
