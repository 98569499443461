import CancelFormButton from 'Components/Form/CancelFormButton'
import { SelectedTranslationsType, TranslatedInput } from 'Components/Form/SelectLocalInput'
import Config from 'config'
import { ValidationErrors } from 'final-form'
import arrayMutators from 'final-form-arrays'
import { useCreatePurchasableOffer, useUpdatePurchasableOffer } from 'models'
import React, { useMemo, useState } from 'react'
import { useCallback } from 'react'
import { Form } from 'react-final-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button, Dimmer, Message, Modal } from 'semantic-ui-react'
import { OfferDescriptionInput, OfferMode, PublicationStatus, PurchasableOffer } from 'services/api/graphql'
import { useStore } from 'stores'
import { notifySuccess } from 'tools/toaster'
import { getSelectedTranslations, validateTranslation } from 'tools/translationTools'

import PurchasableOffersFormFields from './PurchasableOffersFormFields'
import {
  getCreateInputFromPurchasableOffersForm,
  getTranslationInputFromPurchasableOffersValues,
  getUpdateInputFromPurchasableOffersForm,
} from './tools'

interface PurchasableOffersFormProps {
  purchasableOffer?: PurchasableOffer
  onDone?: () => void
  onCancel: () => void
}

export enum PassType {
  delay = 'delay',
  expiredAt = 'expiredAt',
}

export interface FormResults extends Omit<PurchasableOffer, 'descriptionT' | 'titleT'> {
  passType: PassType
  descriptionT?: OfferDescriptionInput[] | null
  titleT?: TranslatedInput
}

const PurchasableOffersForm = ({ purchasableOffer, onDone, onCancel }: PurchasableOffersFormProps) => {
  const intl = useIntl()
  const store = useStore()
  const organismId = store.organismId
  const [createPurchasableOffer, { loading: isLoadingCreate }] = useCreatePurchasableOffer()
  const [updatePurchasableOffer, { loading: isLoadingUpdate }] = useUpdatePurchasableOffer()
  const initialValues = useMemo(
    () => getTranslationInputFromPurchasableOffersValues(purchasableOffer),
    [purchasableOffer],
  )
  const [defaultLocale, setDefaultLocale] = useState<string>(
    initialValues?.defaultLocale || Config.availableLocalesOptions[0].value,
  )

  const [selectedTranslations, setSelectedTranslations] = useState<SelectedTranslationsType[]>(
    initialValues ? getSelectedTranslations({ titleT: initialValues.titleT || undefined }) : [],
  )
  const [activeTranslation, setActiveTranslation] = useState<string | undefined>(defaultLocale)

  const isLoading = useMemo(() => isLoadingCreate || isLoadingUpdate, [isLoadingUpdate, isLoadingCreate])

  const onSubmit = async ({ defaultLocale: _defaultLocale, ...values }: FormResults) => {
    if (!organismId) return

    // Force expired date to be before Cache Update
    if (values.expiredAt) {
      values.expiredAt.setHours(23, 0)
    }
    // Reset expirationDate if we selected 'delay' mode Pass
    if (values.mode === OfferMode.Pass && values.passType === PassType.delay) {
      values.expiredAt = null
    }

    if (purchasableOffer) {
      const result = await updatePurchasableOffer({
        variables: {
          id: purchasableOffer.id,
          input: getUpdateInputFromPurchasableOffersForm(values, selectedTranslations, defaultLocale),
        },
      })

      if (result && result.data) {
        notifySuccess(
          intl.formatMessage({
            id: 'purchasableOffer.update_success',
          }),
        )
        if (onDone) onDone()
      }
    } else {
      const result = await createPurchasableOffer({
        variables: {
          input: {
            ...getCreateInputFromPurchasableOffersForm(values, selectedTranslations, defaultLocale),
            organismId,
            products: [],
          },
        },
      })

      if (result && result.data) {
        notifySuccess(
          intl.formatMessage({
            id: 'purchasableOffer.create_success',
          }),
        )
        if (onDone) onDone()
      }
    }
  }

  const shouldDisplayExpirationWarning = useCallback(
    (delayDiff: number, expiredAtDiff: number, expirationModeChanged: boolean) =>
      purchasableOffer &&
      purchasableOffer.publicationStatus === PublicationStatus.Published &&
      (delayDiff !== 0 || expiredAtDiff !== 0 || expirationModeChanged),
    [purchasableOffer],
  )

  const validate = useCallback(
    (values: FormResults): ValidationErrors | Promise<ValidationErrors> | undefined => {
      return {
        titleT: validateTranslation(activeTranslation, values?.titleT?.[`${defaultLocale}`], intl),
      }
    },
    [activeTranslation, defaultLocale, intl],
  )

  return (
    <Form<FormResults>
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues}
      mutators={{
        ...arrayMutators,
      }}
      render={formProps => {
        const { handleSubmit, form, pristine, values } = formProps
        const delayDiff =
          purchasableOffer && purchasableOffer.delay && values.delay && values.passType === PassType.delay
            ? values.delay - purchasableOffer.delay
            : 0
        const expiredAtDiff =
          purchasableOffer && purchasableOffer.expiredAt && values.expiredAt && values.passType === PassType.expiredAt
            ? new Date(values.expiredAt).getTime() - new Date(purchasableOffer.expiredAt).getTime()
            : 0
        const expirationModeChanged = !!(
          purchasableOffer &&
          ((values.passType === PassType.delay && purchasableOffer.expiredAt) ||
            (values.passType === PassType.expiredAt && !purchasableOffer.expiredAt))
        )

        return (
          <>
            <Modal.Header>
              <FormattedMessage id="purchasableOffer.name" />
            </Modal.Header>
            <Modal.Content>
              <Dimmer.Dimmable dimmed={isLoading}>
                <Dimmer active={isLoading} inverted />
                <PurchasableOffersFormFields
                  selectedTranslations={selectedTranslations}
                  onChangeSelectedTranslations={setSelectedTranslations}
                  onChangeActiveTranslation={setActiveTranslation}
                  activeTranslation={activeTranslation}
                  defaultLocale={defaultLocale}
                  onChangeDefaultLocale={setDefaultLocale}
                />
              </Dimmer.Dimmable>
            </Modal.Content>
            <Modal.Actions>
              {shouldDisplayExpirationWarning(delayDiff, expiredAtDiff, expirationModeChanged) && (
                <Message warning>
                  <p>
                    <FormattedMessage id="purchasableOffer.expiration_warning" />
                  </p>
                  <p>
                    <em>
                      {!expirationModeChanged && (
                        <>
                          <FormattedMessage
                            id={
                              delayDiff > 0 || expiredAtDiff > 0
                                ? 'purchasableOffer.expiration_warning.increase'
                                : 'purchasableOffer.expiration_warning.decrease'
                            }
                          />{' '}
                        </>
                      )}
                      {values.passType === PassType.delay && (delayDiff < 0 || expirationModeChanged) && (
                        <FormattedMessage id="purchasableOffer.expiration_warning.stop" />
                      )}
                    </em>
                  </p>
                </Message>
              )}
              <CancelFormButton pristine={pristine} onConfirm={form.reset} onCancel={onCancel} />
              <Button positive loading={isLoading} onClick={() => handleSubmit()}>
                <FormattedMessage id="common.submit" />
              </Button>
            </Modal.Actions>
          </>
        )
      }}
    />
  )
}

export default PurchasableOffersForm
