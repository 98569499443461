import Loader from 'Components/Loader'
import PageHeader from 'Components/PageHeader'
import UserCsvButton from 'Components/User/UserCsvButton'
import UsersPaginatedTable, { UserSortBy } from 'Components/User/UsersPaginatedTable'
import ConfirmationButton from 'Containers/Button/ConfirmationButton'
import usePagination from 'hooks/usePagination'
import { useGetLightOrganism, useGetOrganismLicensedUsers, useRevokeAllLicenses } from 'models'
import React, { useCallback, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button, Grid, Message } from 'semantic-ui-react'
import { Permission } from 'services/api/graphql'
import { useCurrentUser, useStore } from 'stores'

const LicensedUsersView: React.FC = () => {
  const currentUser = useCurrentUser()
  const intl = useIntl()
  const { organismId } = useStore()
  const { organism, loading: loadingOrganism } = useGetLightOrganism(organismId || '')

  const [sortBy, setSortBy] = useState<UserSortBy | undefined>()

  const { activePage, onPageChange, paginationParams, pageSize } = usePagination()

  const {
    data: usersData,
    loading: loadingUsers,
    refetch,
  } = useGetOrganismLicensedUsers(
    { organismId: organismId || '', sortBy, ...paginationParams },
    { skip: !organism || !organism.license || !organism.license.enabled },
  )

  const totalCount = useMemo(() => usersData && usersData.users.totalCount, [usersData])
  const users = useMemo(() => (usersData && usersData.users.users) || [], [usersData])
  const totalPages = useMemo(() => {
    if (!totalCount) return 0
    return Math.ceil(totalCount / pageSize)
  }, [pageSize, totalCount])

  const loading = useMemo(() => loadingOrganism || loadingUsers, [loadingOrganism, loadingUsers])

  const [revokeAllLicenses, { loading: loadingRevoke }] = useRevokeAllLicenses()

  const onRevokeLicenses = useCallback(async () => {
    if (!organismId) return

    await revokeAllLicenses({ organismId })
    refetch()
  }, [organismId, refetch, revokeAllLicenses])

  return (
    <Loader loading={loading || loadingRevoke}>
      <Grid padded>
        <Grid.Column>
          <PageHeader title={<FormattedMessage id="licensed_users.header" />}>
            {(totalCount || 0) > 0 && (
              <>
                <UserCsvButton filters={{ licensedForOrganismId: organismId }} fansView />
                {currentUser.can(Permission.RevokeLicenses, organismId || undefined) && (
                  <ConfirmationButton
                    action={() => onRevokeLicenses()}
                    confirmText={
                      <FormattedMessage
                        id="licensed_users.confirm_revoke_all"
                        values={{
                          organism: (organism && organism.title) || '',
                        }}
                      />
                    }
                    successText={intl.formatMessage(
                      {
                        id: 'licensed_users.revoke_all_success',
                      },
                      { organism: (organism && organism.title) || '' },
                    )}
                  >
                    <Button color="red">
                      <FormattedMessage id="licensed_users.revoke_all" />
                    </Button>
                  </ConfirmationButton>
                )}
              </>
            )}
          </PageHeader>
          {organism && (!organism.license || !organism.license.enabled) && (
            <Message error>
              <FormattedMessage id="licensed_users.organism_no_licenseApi" />
            </Message>
          )}
          {!organismId && (
            <Message error>
              <FormattedMessage id="licensed_users.no_organism" />
            </Message>
          )}
          {usersData && (
            <>
              <span>
                <FormattedMessage id="users.totalCount" />
                 : {totalCount}
              </span>
              <UsersPaginatedTable
                users={users || []}
                withActions={false}
                sortBy={sortBy}
                onSort={sort => setSortBy(sort)}
                activePage={activePage}
                totalPages={totalPages}
                onPageChange={onPageChange}
              />
            </>
          )}
        </Grid.Column>
      </Grid>
    </Loader>
  )
}

export default LicensedUsersView
