import _ from 'lodash'
import { Logo } from 'services/api/graphql'

export const logoUrlForLightBackground = (logo: Logo): string | undefined => {
  const { coloredImage, transparentDarkImage, transparentLightImage } = logo
  if (!logo) return
  return (
    (transparentLightImage && transparentLightImage.image && transparentLightImage.image.thumbUrl) ||
    (coloredImage && coloredImage.image && coloredImage.image.thumbUrl) ||
    (transparentDarkImage && transparentDarkImage.image && transparentDarkImage.image.thumbUrl) ||
    undefined
  )
}

export const delay = (t: number): Promise<void> => new Promise(resolve => setTimeout(resolve, t))

export const pickDeep = (
  collection: any,
  predicate: (value: any, key: string | number | symbol) => boolean,
  thisArg?: any,
): any => {
  if (!_.isFunction(predicate)) {
    throw new Error('predicate is not a function')
  }
  predicate = _.memoize(predicate, thisArg)

  return _.transform(collection, (memo, val, key) => {
    let include = predicate(val, key)
    if (include && _.isObject(val)) {
      val = val instanceof Date ? val : pickDeep(val, predicate)
      include = val !== null || val !== undefined
    }
    if (include) {
      Array.isArray(collection) ? memo.push(val) : (memo[key] = val)
    }
  })
}

export function isNotNullOrUndefined<T>(item: T | null | undefined): item is T {
  return item !== null && item !== undefined
}
