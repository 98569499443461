import { DragDropContext, Droppable, DropResult } from '@hello-pangea/dnd'
import Banner from 'Components/Banner'
import Blink from 'Components/Blink'
import InvisibleButton from 'Components/Button/InvisibleButton'
import RefreshButton from 'Components/Button/RefreshButton'
import SROnly from 'Components/Button/SROnly'
import DraggableTableRow from 'Components/DraggableTableRow/DraggableTableRow'
import Loader from 'Components/Loader'
import MatureContentLogo from 'Components/MatureContentLogo'
import PageHeader from 'Components/PageHeader'
import SportEventStateLabel from 'Components/SportEventStateLabel'
import { IsLocked } from 'Components/SportItemSet/IsLocked'
import ConfirmationButton from 'Containers/Button/ConfirmationButton'
import SelectFile from 'Containers/SelectFile'
import { CreateExternalFeedLivestreamForm } from 'Forms/Media/CreateExternalFeedLivestreamForm'
import { CreateExternalFeedVideoclipForm } from 'Forms/Media/CreateExternalFeedVideoclipForm'
import { CreateSVEMediaLiveForm } from 'Forms/Media/CreateSVEMediaLiveForm'
import { WildmokaMediaLiveForm } from 'Forms/Media/WildmokaMediaLiveForm'
import RemoveSportItemButton from 'Forms/SportItem/RemoveSportItemButton'
import { useHandleCreateMediaLive } from 'Forms/SportItem/SportItemTools'
import { applicationUris } from 'Layout/uris'
import { useGetActiveLiveStreamsIds, useGetSportItems, useMovePlaylistItem, useUpdateSportItem } from 'models'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Button, Dropdown, Grid, Icon, Image, Popup, Ref, SemanticICONS, Table } from 'semantic-ui-react'
import {
  MediaContentFragment,
  MediaType,
  Organism,
  Permission,
  SportEventState,
  SportItem,
  SportItemContentFragment,
  SportItemSet,
  SportItemSetType,
  useSportItemQuery,
  useSportItemSetWithOrganismQuery,
  useStopSportItemLiveMutation,
  VideoContentType,
  VideoEngine,
} from 'services/api/graphql'
import { useCurrentUser } from 'stores'
import SportItemSetContext from 'stores/Contexts/SportItemSetContext'
import styled from 'styled-components'
import { format } from 'tools/date'
import {
  sportEventItemIsLiveById as _sportEventItemIsLive,
  sportItemHasActiveLive as _sportItemHasActiveLive,
} from 'tools/sportItem'
import { notifyError, notifySuccess } from 'tools/toaster'

const TitleCellContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const Title = styled.div`
  font-weight: bold;
  font-size: 1rem;
`

const Cover = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;

  > * + * {
    margin-left: 0.6rem;
  }
`
const CoverImage = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
const Buttons = styled.div`
  display: flex;
  justify-content: center;
`

const iconsVideoContentType = {
  replay: 'undo',
  live: 'video',
  vod: 'film',
}

export interface SportItemSetViewBaseProps {
  genericTitle: string // Title displayed while sportItemSet is not yet loaded
  sportItemSetId: SportItemSet['id']
  allSportItemSetsTitle: string
  allSportItemSetsViewUrl: string
}

const SportItemSetViewBase: React.FC<SportItemSetViewBaseProps> = ({
  genericTitle,
  sportItemSetId,
  allSportItemSetsTitle,
  allSportItemSetsViewUrl,
}) => {
  // Fetch data from service-platform
  const currentUser = useCurrentUser()
  const navigate = useNavigate()
  const location = useLocation()
  const videoClipOpen = location.state?.videoClipOpen || false
  const sportItemId = location.state?.sportItemId || ''

  const {
    data: sportItemsData,
    error,
    loading: loadingGetSportItems,
    refetch: refetchSportItems,
  } = useGetSportItems(sportItemSetId, {
    fetchPolicy: 'cache-and-network',
  })

  const SportItemSetQuery = useSportItemSetWithOrganismQuery({
    variables: { sportItemSetId },
    fetchPolicy: 'cache-and-network',
  })
  const sportItemSet = SportItemSetQuery.data?.sportItemSet
  const { data: activeLivestreamsData, refetch: activeLivestreamRefetch } = useGetActiveLiveStreamsIds()
  const [sportItems, setSportItems] = useState<SportItem[]>([])
  const { handleCreateMediaLive, isLoading: loadingCreateMediaLive } = useHandleCreateMediaLive()

  useEffect(() => {
    if (sportItemsData && sportItemsData.sportItems) {
      setSportItems(sportItemsData.sportItems.sportItems)
    }
  }, [sportItemsData])

  const refresh = useCallback(() => {
    refetchSportItems()
    activeLivestreamRefetch()
  }, [activeLivestreamRefetch, refetchSportItems])

  // Various states
  const intl = useIntl()
  const [selectedItemId, setSelectedItemId] = useState<string>(sportItemId)
  const { data } = useSportItemQuery({
    variables: { sportItemId: selectedItemId || '' },
    skip: !selectedItemId,
  })

  const [showModalCreateLive, setShowModalCreateLive] = useState(false)
  const [updateSportItem, { loading: loadingUpdateSportItem }] = useUpdateSportItem()

  const [selectVideoClipOpen, setSelectVideoClipOpen] = useState(videoClipOpen)
  const [addEmbedVideoClipModal, setAddEmbedVideoClipModal] = useState(false)

  const selectedSportItem = data?.sportItem

  const showCreateExternalFeedClip = useMemo(
    () => sportItemSet?.videoEngine === VideoEngine.ExternalFeed || sportItemSet?.type === SportItemSetType.Playlist,
    [sportItemSet?.type, sportItemSet?.videoEngine],
  )

  const addLiveToItem = useCallback((itemId: string) => {
    setSelectedItemId(itemId)
    setShowModalCreateLive(true)
  }, [])

  const onAddVideoClipToItem = useCallback((itemId: string, isEmbed) => {
    setSelectedItemId(itemId)
    if (isEmbed) {
      // Add embed VOD
      setAddEmbedVideoClipModal(true)
    } else {
      // Pick VOD from library
      setSelectVideoClipOpen(true)
    }
  }, [])

  const addVideoClipToSportItem = useCallback(
    async (media: MediaContentFragment, sportItem: SportItemContentFragment) => {
      setSelectVideoClipOpen(false)
      try {
        if (!sportItem.mediasIds.includes(media.id)) {
          await updateSportItem({
            variables: {
              input: {
                id: sportItem.id,
                mediasIds: [...sportItem.mediasIds, media.id],
              },
            },
          })
          notifySuccess(intl.formatMessage({ id: 'sportItem.media_created' }))
          refresh()
          setTimeout(() => navigate(applicationUris.sportItemMedias(sportItem.id)), 500)
        } else {
          notifyError(intl.formatMessage({ id: 'sportItem.media_already_exists' }))
        }
      } catch (e) {
        notifyError(intl.formatMessage({ id: 'sportItem.create_media_error' }), e as Error)
      }
    },
    [updateSportItem, intl, refresh, navigate],
  )

  // Stop sportItem live
  const [stopSportItemLive, { loading: loadingStopLive }] = useStopSportItemLiveMutation()

  // Error notification
  useEffect(() => {
    if (error) notifyError(<FormattedMessage id="error.general" />, error)
  }, [error])

  // Computed utilitaries

  // Check if a sportItem a one or several running live
  const sportItemHasActiveLive = useCallback(
    (id: string) => _sportItemHasActiveLive(id, activeLivestreamsData),
    [activeLivestreamsData],
  )

  const sportEventItemIsLive = useCallback((id: string) => _sportEventItemIsLive(id, sportItems), [sportItems])

  // Check if the sportItemSet's event has ended
  const eventEnded = useMemo(
    () => sportItemSet && sportItemSet.sportEvent && sportItemSet.sportEvent.state === SportEventState.Finished,
    [sportItemSet],
  )

  const isLiveFinished = useCallback(
    (id: string): boolean => {
      const item = sportItems.find(sportItem => sportItem.id === id)
      return (item && item.sportEventItem && new Date(item.sportEventItem.stopDate) < new Date(Date.now())) || false
    },
    [sportItems],
  )

  // Cannot create live in playlist or if event has ended

  const isSportEvent = sportItemSet && sportItemSet.type === SportItemSetType.SportEvent

  const [movePlaylistItem, { data: moveResult }] = useMovePlaylistItem()

  const onMoveItem = useCallback(
    (result: DropResult) => {
      const { destination, source, reason, draggableId } = result
      // Do nothing if drop cancelled
      if (!destination || reason === 'CANCEL' || destination.index === source.index) {
        return
      }

      // Call service-platform
      movePlaylistItem({
        variables: {
          input: { id: draggableId, position: destination.index },
        },
      })

      // Display new order (optimistic)
      const newOrder = sportItems.filter(item => item.id !== draggableId)
      const sportItem = sportItems[source.index]
      newOrder.splice(destination.index, 0, sportItem)
      setSportItems(newOrder)
    },
    [movePlaylistItem, sportItems],
  )

  // Update sportitems once the request is finished
  useEffect(() => {
    if (moveResult && moveResult.movePlaylistItem) {
      setSportItems(moveResult.movePlaylistItem)
    }
  }, [moveResult])

  const loading = loadingGetSportItems || loadingStopLive || loadingUpdateSportItem || loadingCreateMediaLive

  return (
    <Loader loading={loading}>
      {sportItemSet && (
        <Banner
          src={
            (sportItemSet.bannerImage &&
              sportItemSet.bannerImage.image &&
              sportItemSet.bannerImage.image.downloadUrl) ||
            ''
          }
        />
      )}

      <Grid padded>
        <Grid.Column>
          <SportItemSetContext.Provider value={sportItemSet || undefined}>
            <PageHeader
              title={
                sportItemSet ? (
                  <>
                    <FormattedMessage
                      id={`sportItemSet.header.${sportItemSet.type}`}
                      values={{ title: sportItemSet.title }}
                    />
                    {sportItemSet.type === SportItemSetType.SportEvent && sportItemSet.sportEvent && (
                      <SportEventStateLabel state={sportItemSet.sportEvent.state} />
                    )}
                  </>
                ) : (
                  genericTitle
                )
              }
            >
              <RefreshButton onClick={() => refresh()} loading={loading} />

              {currentUser.can(Permission.SportItemSetUpdate) && sportItemSet && (
                <Link
                  to={{
                    pathname:
                      sportItemSet.type === SportItemSetType.Playlist
                        ? applicationUris.playlist(sportItemSet.id)
                        : applicationUris.sportEvent(sportItemSet.id),
                  }}
                >
                  <Icon name="edit" link size="large" inverted color="black" />
                </Link>
              )}
              <Popup
                content={allSportItemSetsTitle}
                trigger={
                  <Link to={allSportItemSetsViewUrl}>
                    <Icon name="list" link size="large" inverted color="black" />
                  </Link>
                }
              />

              {currentUser.can(
                Permission.SportItemCreate,
                (sportItemSet && sportItemSet.ownerOrganismId) || undefined,
                true,
              ) &&
                sportItemSet?.id && (
                  <Link to={applicationUris.sportItemCreate(sportItemSet.id)}>
                    <Button disabled={!sportItemSet} circular icon="add" color="red"></Button>
                  </Link>
                )}
            </PageHeader>

            <DragDropContext onDragEnd={onMoveItem}>
              <Table style={{ flex: 1 }}>
                <Table.Header>
                  <Table.Row textAlign="center">
                    <Table.HeaderCell collapsing></Table.HeaderCell>
                    {/* Is Locked Icon */}
                    <Table.HeaderCell collapsing></Table.HeaderCell>
                    {/* Logo */}
                    <Table.HeaderCell width={1}></Table.HeaderCell>
                    {/* Type */}
                    <Table.HeaderCell width={2}>
                      <FormattedMessage id="sportEvent.type" />
                    </Table.HeaderCell>
                    {/* SportEvent Date */}
                    {isSportEvent && (
                      <Table.HeaderCell width={2}>
                        <FormattedMessage id="sportEvent.date" />
                      </Table.HeaderCell>
                    )}
                    {/* Title / Subtile */}
                    <Table.HeaderCell width={3}>
                      <FormattedMessage id="sportEvent.title" />
                    </Table.HeaderCell>
                    <Table.HeaderCell width={3}>
                      <FormattedMessage id="sportEvent.subtitle" />
                    </Table.HeaderCell>
                    {/* Publication status */}
                    <Table.HeaderCell>
                      <FormattedMessage id="publicationStatus" />
                    </Table.HeaderCell>
                    {/* Medias */}
                    <Table.HeaderCell width={2}>
                      <FormattedMessage id="sportItem.medias" />
                    </Table.HeaderCell>
                    {/* Actions */}
                    <Table.HeaderCell width={2}>
                      <FormattedMessage id="sportEvent.actions" />
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Droppable droppableId="table">
                  {provided => (
                    <Ref innerRef={provided.innerRef}>
                      <Table.Body {...provided.droppableProps}>
                        {sportItems.map(
                          (
                            {
                              id,
                              title,
                              subtitle,
                              publicationStatus,
                              sportEventItem,
                              coverImage,
                              videoContentType,
                              medias,
                              matureContent,
                              reverseRestriction,
                            },
                            index,
                          ) => {
                            const mediasCount = medias.length
                            return (
                              <DraggableTableRow key={id} draggableId={id} index={index}>
                                {({ dragHandleProps }) => (
                                  <>
                                    <Table.Cell collapsing textAlign="center" {...dragHandleProps}>
                                      <Icon name="bars" />
                                    </Table.Cell>
                                    {/* Is Locked Icon */}
                                    <Table.Cell>
                                      {sportItemSet && (
                                        <IsLocked
                                          sportItemSet={sportItemSet as SportItemSet}
                                          videoContentType={videoContentType}
                                          reverseRestriction={reverseRestriction}
                                        />
                                      )}
                                    </Table.Cell>

                                    {/* Logo */}
                                    <Table.Cell {...dragHandleProps} textAlign="center">
                                      <Cover>
                                        <CoverImage src={coverImage && coverImage.image && coverImage.image.thumbUrl} />
                                        {matureContent && <MatureContentLogo />}
                                      </Cover>
                                    </Table.Cell>

                                    {/* Type */}
                                    <Table.Cell textAlign="center">
                                      <Popup
                                        position="top center"
                                        content={<FormattedMessage id={`videoContentType.${videoContentType}`} />}
                                        trigger={
                                          <Icon name={iconsVideoContentType[videoContentType] as SemanticICONS} />
                                        }
                                      />
                                    </Table.Cell>
                                    {/* SportEvent Date */}
                                    {isSportEvent && (
                                      <Table.Cell textAlign="center">
                                        {sportEventItem &&
                                          format(sportEventItem.startDate, 'dddd D MMMM YYYY HH:mm', intl.locale)}
                                      </Table.Cell>
                                    )}
                                    {/* Title / Subtile */}
                                    <Table.Cell textAlign="center">
                                      <TitleCellContent>
                                        <Title>{title}</Title>
                                      </TitleCellContent>
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">
                                      <TitleCellContent>
                                        <Title>{subtitle}</Title>
                                      </TitleCellContent>
                                    </Table.Cell>

                                    {/* Publication */}
                                    <Table.Cell textAlign="center">
                                      <FormattedMessage id={`publicationStatus.${publicationStatus}`} />
                                    </Table.Cell>

                                    {/* Medias */}
                                    <Table.Cell textAlign="right">
                                      {mediasCount === 0 && <FormattedMessage id="sportItem.no_media" />}

                                      {mediasCount > 0 && (
                                        <Link to={applicationUris.sportItemMedias(id)}>
                                          <FormattedMessage
                                            id="sportItem.list_medias"
                                            values={{ number: mediasCount }}
                                          />
                                        </Link>
                                      )}

                                      {(currentUser.can(
                                        Permission.LiveStreamCreate,
                                        (sportItemSet && sportItemSet.ownerOrganismId) || undefined,
                                        true,
                                      ) ||
                                        currentUser.can(
                                          Permission.VideoClipCreate,
                                          (sportItemSet && sportItemSet.ownerOrganismId) || undefined,
                                          true,
                                        )) && (
                                        <Dropdown
                                          trigger={<Icon name="plus circle" color="red" />}
                                          as={InvisibleButton}
                                          icon={false}
                                          inline
                                        >
                                          <Dropdown.Menu>
                                            {currentUser.can(
                                              Permission.LiveStreamCreate,
                                              (sportItemSet && sportItemSet.ownerOrganismId) || undefined,
                                              true,
                                            ) &&
                                              videoContentType === VideoContentType.Live &&
                                              !eventEnded &&
                                              !isLiveFinished(id) && (
                                                <Dropdown.Item onClick={() => addLiveToItem(id)}>
                                                  <FormattedMessage id="sportItem.add_live" />
                                                </Dropdown.Item>
                                              )}

                                            {currentUser.can(
                                              Permission.VideoClipCreate,
                                              (sportItemSet && sportItemSet.ownerOrganismId) || undefined,
                                              true,
                                            ) && (
                                              <>
                                                <Dropdown.Item onClick={() => onAddVideoClipToItem(id, false)}>
                                                  <FormattedMessage id="sportItem.add_clip" />
                                                </Dropdown.Item>
                                                {showCreateExternalFeedClip && (
                                                  <Dropdown.Item onClick={() => onAddVideoClipToItem(id, true)}>
                                                    <FormattedMessage id="sportItemSets.videoEngine.externalFeed" />
                                                  </Dropdown.Item>
                                                )}
                                              </>
                                            )}
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      )}
                                    </Table.Cell>

                                    {/* Actions */}
                                    <Table.Cell textAlign="center">
                                      {/* Edit SportItem */}
                                      <Buttons>
                                        {currentUser.can(
                                          Permission.SportItemUpdate,
                                          (sportItemSet && sportItemSet.ownerOrganismId) || undefined,
                                          true,
                                        ) && (
                                          <Link
                                            to={{
                                              pathname: applicationUris.sportItem(id),
                                            }}
                                          >
                                            <Icon name="edit" link size="large" inverted color="black" />
                                          </Link>
                                        )}

                                        {/* Remove SportItem */}
                                        {!sportEventItemIsLive(id) &&
                                          currentUser.can(
                                            Permission.SportItemDelete,
                                            (sportItemSet && sportItemSet.ownerOrganismId) || undefined,
                                            true,
                                          ) && (
                                            <RemoveSportItemButton
                                              sportItemId={id}
                                              confirmText={intl.formatMessage(
                                                {
                                                  id: 'sportItem.delete.confirm',
                                                },
                                                { title },
                                              )}
                                              successText={intl.formatMessage(
                                                {
                                                  id: 'sportItem.delete.success',
                                                },
                                                { title },
                                              )}
                                              onDone={refresh}
                                            />
                                          )}

                                        {/* Stop SportItem (Black square) */}
                                        {sportEventItemIsLive(id) && (
                                          <ConfirmationButton
                                            action={() =>
                                              stopSportItemLive({
                                                variables: { id },
                                              })
                                            }
                                            confirmText={
                                              <FormattedMessage id="sportItem.confirm_stop" values={{ title }} />
                                            }
                                            successText={intl.formatMessage(
                                              {
                                                id: 'sportItem.live_stopped',
                                              },
                                              { title },
                                            )}
                                            onDone={refresh}
                                          >
                                            <Icon name="stop" />

                                            <SROnly>
                                              <FormattedMessage id="sportItem.stop_live" values={{ title }} />
                                            </SROnly>
                                          </ConfirmationButton>
                                        )}

                                        {/* Live indicator (red circle) */}
                                        {sportItemHasActiveLive(id) && (
                                          <Popup
                                            trigger={
                                              <Blink>
                                                <Icon
                                                  color="red"
                                                  name={sportEventItemIsLive(id) ? 'circle' : 'circle outline'}
                                                  size="large"
                                                />
                                              </Blink>
                                            }
                                            position="top center"
                                            content={
                                              <FormattedMessage
                                                id={`sportItem.${sportEventItemIsLive(id) ? 'live' : 'ending'}`}
                                              />
                                            }
                                          />
                                        )}
                                      </Buttons>
                                    </Table.Cell>
                                  </>
                                )}
                              </DraggableTableRow>
                            )
                          },
                        )}

                        {provided.placeholder}
                      </Table.Body>
                    </Ref>
                  )}
                </Droppable>
              </Table>
            </DragDropContext>
          </SportItemSetContext.Provider>
        </Grid.Column>
      </Grid>

      {selectedItemId && selectedSportItem && (
        <>
          {sportItemSet?.videoEngine === VideoEngine.Sportall && (
            <CreateSVEMediaLiveForm
              open={showModalCreateLive}
              onDone={() => {
                setShowModalCreateLive(false)
                refresh()
              }}
              onCancel={() => setShowModalCreateLive(false)}
              sportItemId={selectedSportItem.id}
            />
          )}

          {sportItemSet?.videoEngine === VideoEngine.Wildmoka && (
            <WildmokaMediaLiveForm
              loading={loading}
              open={showModalCreateLive}
              onSubmit={live => {
                handleCreateMediaLive(selectedItemId, { videoEngine: VideoEngine.Wildmoka, ...live })
              }}
              onCancel={() => setShowModalCreateLive(false)}
              sportItem={selectedSportItem}
            />
          )}
          {showCreateExternalFeedClip && (
            <>
              <CreateExternalFeedVideoclipForm
                open={addEmbedVideoClipModal}
                onSubmit={() => {
                  setAddEmbedVideoClipModal(false)
                  refresh()
                }}
                onCancel={() => setAddEmbedVideoClipModal(false)}
                sportItem={selectedSportItem}
              />
              <CreateExternalFeedLivestreamForm
                open={showModalCreateLive}
                onSubmit={() => {
                  setShowModalCreateLive(false)
                  refresh()
                }}
                onCancel={() => setShowModalCreateLive(false)}
                sportItem={selectedSportItem}
              />
            </>
          )}
          <SelectFile
            open={selectVideoClipOpen}
            type={MediaType.VideoClip}
            onCancel={() => {
              setSelectVideoClipOpen(false)
            }}
            onDone={media => {
              addVideoClipToSportItem(media, selectedSportItem)
            }}
            header={<FormattedMessage id="sportItem.selectVideoClip" />}
            organismId={sportItemSet?.ownerOrganismId as Organism['id'] | undefined}
          />
        </>
      )}
    </Loader>
  )
}

export default SportItemSetViewBase
