import React, { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { Dropdown } from 'semantic-ui-react'
import { WmEventState } from 'services/api/graphql'

interface SelectEventProps {
  value: WmEventState
  onChange: (state: WmEventState) => void
  disabled?: boolean
}
const POINT_OF_NO_RETURN = WmEventState.Started

const SelectEventState: React.FC<SelectEventProps> = ({ value, onChange, disabled }) => {
  const intl = useIntl()

  const statesOrder = useMemo(
    () => [WmEventState.Pending, WmEventState.Testing, WmEventState.Started, WmEventState.Stopped],
    [],
  )

  const [selectableStates, setSelectableStates] = useState<WmEventState[]>([])
  useEffect(() => {
    const stateIndex = statesOrder.indexOf(value)
    if (stateIndex >= statesOrder.indexOf(POINT_OF_NO_RETURN)) {
      setSelectableStates(statesOrder.slice(stateIndex + 1))
    } else if (stateIndex === -1) {
      setSelectableStates([])
    } else {
      setSelectableStates(statesOrder.filter(state => state !== value))
    }
  }, [statesOrder, value])

  return (
    <Dropdown
      text={intl.formatMessage({ id: `eventState.${value}` })}
      disabled={disabled}
      icon={selectableStates.length > 0 ? 'dropdown' : ''}
    >
      <Dropdown.Menu>
        {selectableStates.map(state => (
          <Dropdown.Item
            key={state}
            text={intl.formatMessage({ id: `eventState.${state}` })}
            value={state}
            onClick={() => onChange(state)}
          />
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default SelectEventState
