import InvisibleButton from 'Components/Button/InvisibleButton'
import SROnly from 'Components/Button/SROnly'
import SelectItemListInput from 'Components/Form/SelectItemListInput'
import { useListProfiles } from 'models'
import React, { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { Icon } from 'semantic-ui-react'
import { Maybe, WmProfile } from 'services/api/graphql'
import styled from 'styled-components'

export interface SelectWMProfileProps {
  value: WmProfile['id'] | Array<WmProfile['id']> | undefined
  onChange: (value: Maybe<WmProfile['id'] | Array<WmProfile['id']>>) => void
  noSelectionEnabled?: boolean
  noSelectionTitle?: string
  multiple?: boolean
  disabled?: boolean
}

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  > .field {
    flex: 1;
    margin: 0 !important;
  }
`

const SelectWMProfileInput: React.FC<SelectWMProfileProps> = props => {
  const { data: profiles, loading: loadingProfiles, refetch } = useListProfiles()

  const profilesOptions = useMemo(
    () =>
      (profiles || []).map(profile => ({
        key: profile.id,
        value: profile.id,
        text: profile.name,
      })),
    [profiles],
  )

  return (
    <FlexContainer>
      <SelectItemListInput {...props} loading={loadingProfiles} options={profilesOptions || []} />
      <InvisibleButton onClick={() => refetch()} type="button">
        <Icon name="refresh" />
        <SROnly>
          <FormattedMessage id="selectWMProfileInput.refresh" />
        </SROnly>
      </InvisibleButton>
    </FlexContainer>
  )
}

export default SelectWMProfileInput
