import { FormBaseProps } from 'Forms/FormBase'
import { useCreateOrganism } from 'models'
import React from 'react'
import { CreateOrganismInput, Organism, OrganismType } from 'services/api/graphql'
import { getTranslationInputFromForm } from 'tools/translationTools'

import OrganismForm from './OrganismForm'

interface CreateOrganismFormProps extends FormBaseProps<Organism> {
  type?: OrganismType
  title: string
}

const CreateOrganismForm = ({ type, title, onCancel, onDone, onError }: CreateOrganismFormProps) => {
  const [createOrganism, { loading }] = useCreateOrganism()
  return (
    <OrganismForm
      title={title}
      edit={false}
      editType={!type}
      onSubmit={({ titleT, descriptionT, ...values }, defaultLocale, selectedTranslations) => {
        const input: CreateOrganismInput = {
          ...values,
          titleT: getTranslationInputFromForm(selectedTranslations, titleT),
          descriptionT: getTranslationInputFromForm(selectedTranslations, descriptionT),
          title: titleT?.[defaultLocale] || '',
          description: descriptionT?.[defaultLocale] || '',
        }
        createOrganism({
          variables: { input: type ? { ...input, type } : input },
        })
          .then(result => {
            if (result && result.data) onDone(result.data.createOrganism)
          })
          .catch(onError)
      }}
      onCancel={onCancel}
      loading={loading}
    />
  )
}

export default CreateOrganismForm
