import CropImageAndUpload from 'Containers/CropImage/CropImageAndUpload'
import React from 'react'
import { FileMetadata, Media } from 'services/api/graphql'
import { useFetchFile } from 'tools/hooks'
import { ImageConstraints } from 'types/image'

export interface CropImageFromSportallFileAndUploadProps {
  selectedFile: Media
  onDone: (imageMedia: Media) => void // Called when crop + upload done
  onCancel?: () => void
  imageConstraints?: ImageConstraints
  ownerOrganismId?: string
}
const CropImageAndUploadFromSportallFile = ({
  selectedFile,
  onDone,
  onCancel,
  imageConstraints,
  ownerOrganismId,
}: CropImageFromSportallFileAndUploadProps) => {
  const { blob: imageBlob } = useFetchFile(
    (selectedFile.image && selectedFile.image.downloadUrl) || '',
    selectedFile.image?.filename,
  )
  if (!imageBlob) return null

  const sourceMetadata = selectedFile.image && selectedFile.image.metadata

  const fileMetadata: FileMetadata = {
    sourceFileId: selectedFile.id,
    title: sourceMetadata && sourceMetadata.title,
    description: sourceMetadata && sourceMetadata.description,
  }

  return (
    <CropImageAndUpload
      image={imageBlob}
      onDone={onDone}
      onCancel={onCancel}
      fileMetadata={fileMetadata}
      imageConstraints={imageConstraints}
      ownerOrganismId={ownerOrganismId}
    />
  )
}

export default CropImageAndUploadFromSportallFile
