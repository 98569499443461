import React, { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { FormattedMessage } from 'react-intl'
import { Button, Icon, Modal } from 'semantic-ui-react'
import styled from 'styled-components'

import DropZoneView from './DropZoneView'

interface FileInputModalProps {
  open: boolean
  accept?: string | string[]
  header?: React.ReactNode
  loading?: boolean
  onValidate: (file: File) => void
  onCancel: () => void
}

const FileDisplay = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  > * + * {
    margin-top: 0.5rem;
  }
`

const ImageDisplay = styled.img`
  max-width: 20rem;
  max-height: 20rem;
`

const FileInputModal: React.FC<FileInputModalProps> = ({ open, accept, header, loading, onValidate, onCancel }) => {
  const [file, setFile] = useState<File | null>()
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: files => {
      setFile(files[0])
    },
    accept,
  })

  const [imageUrl] = useState('')

  const submit = useCallback(() => {
    if (file && file.type.startsWith('image/')) {
      onValidate(file)
    }
  }, [file, onValidate])

  // Reset file once closed
  useEffect(() => {
    if (!open) {
      setFile(null)
    }
  }, [open])

  return (
    <Modal mountNode={document.getElementById('modals')} open={open}>
      <Modal.Header>{header || <FormattedMessage id="fileInput.header" />}</Modal.Header>
      <Modal.Content>
        <DropZoneView root={getRootProps()} input={getInputProps()}>
          {file ? (
            <FileDisplay>
              {imageUrl ? <ImageDisplay src={imageUrl} /> : <Icon name="file" size="big" />}
              <span>{file.name}</span>
            </FileDisplay>
          ) : (
            <FormattedMessage id="fileInput.help" />
          )}
        </DropZoneView>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onCancel}>
          <FormattedMessage id="fileInput.cancel" />
        </Button>
        <Button onClick={submit} positive disabled={!file} loading={loading}>
          <FormattedMessage id="fileInput.validate" />
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default FileInputModal
