import Loader from 'Components/Loader'
import PageHeader from 'Components/PageHeader'
import { observer } from 'mobx-react'
import { useGetLinks } from 'models'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Grid, Table, Dropdown } from 'semantic-ui-react'
import { LinkSort, LinkSortProperty, LinkTag, SortDirection } from 'services/api/graphql'
import { useStore } from 'stores'
import styled from 'styled-components'
import { notifyError } from 'tools/toaster'

const UrlText = styled.a`
  font-size: small;
  color: darkgrey;
`

const UnclickableHeader = styled(Table.HeaderCell)`
  cursor: default !important;
`

type LinkTagOptionType = 'both' | 'sponsoring' | 'link'

const LinksView: FC = () => {
  const intl = useIntl()
  const { organismId } = useStore()
  const [orderBy, setOrderBy] = useState<LinkSort | undefined>()
  const [linkTag, setLinkTag] = useState<LinkTagOptionType>('both')

  const linkTagOptions = [
    { key: 'both', value: 'both', text: intl.formatMessage({ id: 'common.all' }) },
    { key: 'sponsoring', value: 'sponsoring', text: intl.formatMessage({ id: 'link.tag.sponsoring' }) },
    { key: 'link', value: 'link', text: intl.formatMessage({ id: 'links.link' }) },
  ]

  const linkTagFilter = useMemo(() => {
    if (linkTag === 'both') {
      return null
    }
    return linkTag === 'link' ? undefined : LinkTag.Sponsoring
  }, [linkTag])

  const { links, error, loading } = useGetLinks({
    fetchPolicy: 'network-only',
    variables: { filters: { organismId, tag: linkTagFilter }, orderBy },
  })

  useEffect(() => {
    if (error) notifyError(<FormattedMessage id="error.general" />, error)
  }, [error])

  const isOrderedByColumn = (property: LinkSortProperty) => {
    if (orderBy?.property !== property) {
      return undefined
    }
    return orderBy.direction === SortDirection.Ascending ? 'ascending' : 'descending'
  }

  const handleOrderByColumn = (property: LinkSortProperty) => () => {
    if (orderBy?.property !== property) {
      setOrderBy({ property, direction: SortDirection.Descending })
      return
    }
    const direction = orderBy.direction === SortDirection.Descending ? SortDirection.Ascending : undefined
    setOrderBy(orderBy.direction === SortDirection.Descending ? { property, direction } : undefined)
  }

  return (
    <Loader loading={loading}>
      <Grid padded>
        <Grid.Column>
          <PageHeader title={intl.formatMessage({ id: 'analytics.links' })} />
          <Dropdown
            selection
            value={linkTag}
            onChange={(_e, data) => setLinkTag((data.value || 'both') as LinkTagOptionType)}
            options={linkTagOptions}
          />
          <Table sortable>
            <Table.Header>
              <Table.Row textAlign="center">
                <UnclickableHeader textAlign="center" width={1}>
                  <FormattedMessage id="links.title" />
                </UnclickableHeader>
                <UnclickableHeader width={2}>
                  <FormattedMessage id="link.link" />
                </UnclickableHeader>
                <Table.HeaderCell
                  width={1}
                  sorted={isOrderedByColumn(LinkSortProperty.Clicks)}
                  onClick={handleOrderByColumn(LinkSortProperty.Clicks)}
                >
                  <FormattedMessage id="common.clicks" />
                </Table.HeaderCell>
                <Table.HeaderCell
                  width={1}
                  sorted={isOrderedByColumn(LinkSortProperty.UniqueClicks)}
                  onClick={handleOrderByColumn(LinkSortProperty.UniqueClicks)}
                >
                  <FormattedMessage id="common.uniqueClicks" />
                </Table.HeaderCell>
                <Table.HeaderCell
                  width={1}
                  sorted={isOrderedByColumn(LinkSortProperty.Views)}
                  onClick={handleOrderByColumn(LinkSortProperty.Views)}
                >
                  <FormattedMessage id="common.views" />
                </Table.HeaderCell>
                <Table.HeaderCell
                  width={1}
                  sorted={isOrderedByColumn(LinkSortProperty.UniqueViews)}
                  onClick={handleOrderByColumn(LinkSortProperty.UniqueViews)}
                >
                  <FormattedMessage id="common.uniqueViews" />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {links?.map(({ id, title, url, clicks, uniqueClicks, views, uniqueViews, gamTagUrl }) => (
                <Table.Row key={id}>
                  <Table.Cell>
                    <strong>{title}</strong>
                  </Table.Cell>
                  <Table.Cell>
                    <UrlText href={url || gamTagUrl || ''} target="_blank" rel="noopener noreferrer">
                      {(url || gamTagUrl || '').length > 50
                        ? `${(url || gamTagUrl)?.slice(0, 50)}...`
                        : url || gamTagUrl}
                    </UrlText>
                  </Table.Cell>
                  <Table.Cell textAlign="center">{clicks}</Table.Cell>
                  <Table.Cell textAlign="center">{uniqueClicks}</Table.Cell>
                  <Table.Cell textAlign="center">{views}</Table.Cell>
                  <Table.Cell textAlign="center">{uniqueViews}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid>
    </Loader>
  )
}

export default observer(LinksView)
