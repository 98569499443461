import RefreshButton from 'Components/Button/RefreshButton'
import Loader from 'Components/Loader'
import PageHeader from 'Components/PageHeader'
import TablePagination from 'Components/TablePagination'
import ImageFilePreviewButton from 'Containers/Button/ImageFilePreviewButton'
import RemoveNewsButton from 'Forms/News/RemoveNewsButton'
import { applicationUris } from 'Layout/uris'
import usePagination from 'hooks/usePagination'
import _ from 'lodash'
import { observer } from 'mobx-react'
import { useGetOrganismsMap, useGetNewss } from 'models'
import React, { useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { Button, Grid, Icon, Input, Table } from 'semantic-ui-react'
import { Permission } from 'services/api/graphql'

import { useCurrentUser, useStore } from '../stores'
import { notifyError } from '../tools/toaster'

const NewsView = () => {
  const intl = useIntl()
  const currentUser = useCurrentUser()
  const store = useStore()

  const [search, setSearch] = useState<string | null>()

  const { activePage, onPageChange, paginationParams, pageSize } = usePagination()

  const { totalCount, news, error, loading, refetch } = useGetNewss({
    variables: { filters: { organismId: store.organismId }, ...paginationParams, search },
    fetchPolicy: 'cache-and-network',
  })
  const { organisms } = useGetOrganismsMap()

  const totalPages = useMemo(() => {
    if (!totalCount) return 0
    return Math.ceil(totalCount / pageSize)
  }, [pageSize, totalCount])

  const handleOnSearchChange = useMemo(
    () =>
      _.debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        onPageChange(1)
        setSearch(e.target.value)
      }, 300),
    [onPageChange],
  )

  useEffect(() => {
    if (error) notifyError(<FormattedMessage id="error.general" />, error)
  }, [error])

  return (
    <Loader loading={loading}>
      <Grid padded>
        <Grid.Column>
          <PageHeader title={intl.formatMessage({ id: 'news' })}>
            <Input
              icon="search"
              loading={loading}
              placeholder={intl.formatMessage({
                id: 'users.search',
              })}
              onChange={handleOnSearchChange}
            />
            <RefreshButton onClick={() => refetch()} loading={loading} />
            {currentUser.can(Permission.NewsCreate) && (
              <Link to={applicationUris.newsCreate}>
                <Button icon="add" circular color="red" />
              </Link>
            )}
          </PageHeader>

          <Table style={{ flex: 1 }}>
            <Table.Header>
              <Table.Row textAlign="center">
                <Table.HeaderCell width={1}>
                  <FormattedMessage id="news.organism" />
                </Table.HeaderCell>
                <Table.HeaderCell width={1}>
                  <FormattedMessage id="news.title" />
                </Table.HeaderCell>
                <Table.HeaderCell width={1}>
                  <FormattedMessage id="publicationStatus" />
                </Table.HeaderCell>
                <Table.HeaderCell width={1}>
                  <FormattedMessage id="news.banner" />
                </Table.HeaderCell>
                <Table.HeaderCell width={1}>
                  <FormattedMessage id="actions" />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {news?.map(({ id, coverImage, title, publicationStatus, organismId }) => {
                const organim = organismId && organisms[organismId]

                return (
                  <Table.Row key={id}>
                    <Table.Cell textAlign="center">
                      {organim ? organim.title : organismId ? `Organisme inconnu ${organismId}` : ''}
                    </Table.Cell>
                    <Table.Cell>
                      <strong>{title}</strong>
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <FormattedMessage id={`publicationStatus.${publicationStatus}`} />
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      {coverImage?.image && (
                        <ImageFilePreviewButton title="Image Banner" imageFile={coverImage.image} />
                      )}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      {currentUser.can(Permission.NewsUpdate) && (
                        <Link
                          to={{
                            pathname: applicationUris.news(id),
                          }}
                        >
                          <Icon name="edit" link size="large" inverted color="black" />
                        </Link>
                      )}
                      {currentUser.can(Permission.NewsDelete) && (
                        <RemoveNewsButton
                          newsId={id}
                          confirmText={intl.formatMessage({ id: 'news.form.delete.question' }, { title })}
                          successText={intl.formatMessage({ id: 'news.form.delete.success' }, { title })}
                          onDone={refetch}
                        />
                      )}
                    </Table.Cell>
                  </Table.Row>
                )
              })}
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid>
      <div className="pb-3">
        {totalPages > 1 && (
          <TablePagination totalPages={totalPages} activePage={activePage || 1} onPageChange={onPageChange} />
        )}
      </div>
    </Loader>
  )
}

export default observer(NewsView)
