import ConfirmationButton, { ConfirmationButtonBaseProps } from 'Containers/Button/ConfirmationButton'
import { useDeleteLink } from 'models'
import React from 'react'
import { Link } from 'services/api/graphql'

interface RemoveLinkButtonProps extends ConfirmationButtonBaseProps {
  linkId: Link['id']
}

const RemoveLinkButton = ({ linkId, ...props }: RemoveLinkButtonProps) => {
  const [removeLink] = useDeleteLink()
  return <ConfirmationButton action={() => removeLink({ variables: { linkId } })} {...props} />
}

export default RemoveLinkButton
