import { useApolloClient } from '@apollo/client'
import EditUserForm from 'Forms/User/EditUserForm'
import { observer } from 'mobx-react-lite'
import React, { Fragment, useState } from 'react'
import { User } from 'services/api/graphql'
import { useStore } from 'stores'
import { notifyError, notifySuccess } from 'tools/toaster'

import { userTitle } from './helper'

interface EditUserButtonProps {
  userId: User['id']
  onDone?: () => void
  render: (onClick: () => void) => React.ReactNode
}

const EditUserButton = observer(({ onDone, userId, render }: EditUserButtonProps) => {
  const client = useApolloClient()
  const store = useStore()

  const [form, setForm] = useState<React.ReactNode | null>()

  const handleClick = () => {
    setForm(
      userId && (
        <EditUserForm
          userId={userId}
          onCancel={() => setForm(null)}
          onDone={user => {
            setForm(null)
            notifySuccess(`User ${userTitle(user)} mis à jour`)

            const meUserId = store.currentUser.user && store.currentUser.user.id
            if (meUserId && meUserId === userId) {
              // Refresh me
              store.currentUser.refreshMe(client)
            }
            if (onDone) onDone()
          }}
          onError={notifyError}
        />
      ),
    )
  }
  return (
    <Fragment>
      {render(handleClick)}
      {form}
    </Fragment>
  )
})

export default EditUserButton
