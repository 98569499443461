import SAFormField from 'Components/Form/SAFormField'
import React from 'react'
import { useIntl } from 'react-intl'
import { Button, Form, Input } from 'semantic-ui-react'
import { validateRequired } from 'tools/formValidators'

export type WecNewsFilteringCriteriaProps = {
  ids: string[]
  translations: {
    singular: string
    plural: string
  }
}

export const WecNewsFilteringCriteria = ({
  ids,
  translations: { singular, plural },
}: WecNewsFilteringCriteriaProps) => {
  const intl = useIntl()

  return (
    <>
      <h5 className="m-4">{intl.formatMessage({ id: `widget.externalContent.WECNews.${plural}` })}</h5>

      <SAFormField<typeof ids, HTMLDivElement>
        name={`wecContent.${singular}ECMIds`}
        render={({ input }) =>
          ids.map((_id, index) => (
            <SAFormField
              key={index}
              name={`wecContent.${singular}ECMIds[${index}]`}
              validate={validateRequired}
              render={({ input: subInput, meta }) => (
                <div className="relative">
                  <Form.Field
                    control={Input}
                    {...subInput}
                    required
                    error={!!meta.touched && !!meta.error}
                    placeholder={`${intl.formatMessage({
                      id: `widget.externalContent.WECNews.${singular}`,
                    })} ${index + 1}`}
                  />

                  <Button
                    className="absolute right-0 top-1"
                    basic
                    compact
                    type="button"
                    icon="remove"
                    onClick={() => input.onChange([...ids.slice(0, index), ...ids.slice(index + 1)])}
                  />
                </div>
              )}
            />
          ))
        }
      />

      <SAFormField
        name={`wecContent.${singular}ECMIds`}
        render={({ input }) => <Button type="button" icon="add" onClick={() => input.onChange([...ids, ''])} />}
      />
    </>
  )
}
