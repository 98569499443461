import React, { useMemo } from 'react'
import { Dropdown, DropdownItemProps, DropdownProps } from 'semantic-ui-react'

export interface SelectItemListInputProps extends Omit<DropdownProps, 'clearable'> {
  value: any
  onChange: (value: any) => void
  noSelectionEnabled?: boolean
  noSelectionTitle?: string
  disabled?: boolean
  options: DropdownItemProps[]
  noNullValue?: boolean
}

const ___NO_SELECTION___ = '___NO_SELECTION___'

const SelectItemListInput = ({
  options,
  value,
  onChange,
  noSelectionEnabled,
  noSelectionTitle = 'Aucun',
  multiple,
  disabled,
  noNullValue,
  ...props
}: SelectItemListInputProps) => {
  const finalOptions = useMemo(() => {
    const optionsList: DropdownItemProps[] = []
    if (noSelectionEnabled) {
      optionsList.push({
        key: ___NO_SELECTION___,
        value: ___NO_SELECTION___,
        text: noSelectionTitle,
      })
    }
    return [...optionsList, ...options]
  }, [options, noSelectionEnabled, noSelectionTitle])
  return (
    <Dropdown
      value={value || (multiple ? [] : null)}
      lazyLoad
      disabled={disabled}
      search
      selection
      multiple={multiple}
      options={finalOptions}
      onChange={(e, data) => {
        const item = data.value !== ___NO_SELECTION___ ? data.value : noNullValue ? [] : null
        onChange(item)
      }}
      clearable
      {...props}
    />
  )
}

export default SelectItemListInput
