import SelectItemListInput, { SelectItemListInputProps } from 'Components/Form/SelectItemListInput'
import React, { useMemo } from 'react'
import { Maybe, SveChannel, SveChannelContentFragment } from 'services/api/graphql'

export interface SelectSVEChannelInputProps extends Omit<SelectItemListInputProps, 'options'> {
  value: SveChannel['id'] | undefined
  onChange: (value: Maybe<SveChannel['id']>) => void
  source: Array<Pick<SveChannelContentFragment, 'id' | 'name'>>
}

export const SelectSVEChannelInput = ({ source, ...props }: SelectSVEChannelInputProps) => {
  const options = useMemo(() => {
    return source.map(sveChannel => ({
      key: sveChannel.id,
      value: sveChannel.id,
      text: sveChannel.name,
    }))
  }, [source])

  return <SelectItemListInput options={options} {...props} />
}
