import { useEffect, useRef } from 'react'

export const useInterval = (callback: () => void, delay: number | null | false): void => {
  const savedCallback = useRef(callback)

  useEffect(() => {
    savedCallback.current = callback
  })

  useEffect(() => {
    if (delay === null || delay === false) return
    savedCallback.current()
  }, [delay])

  // Set up the interval.
  useEffect(() => {
    if (delay === null || delay === false) return undefined
    const tick = () => savedCallback.current()
    const id = setInterval(tick, delay)
    return () => clearInterval(id)
  }, [delay])
}
