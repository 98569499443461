import { FormBaseProps } from 'Forms/FormBase'
import React, { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { SveChannel, User, useSveChannelQuery, useUpdateSveChannelMutation } from 'services/api/graphql'

import { SVEChannelForm, SVEChannelFormValues } from './SVEChannelForm'

export interface EditSVEChannelFormProps extends FormBaseProps<User> {
  sveChannelId: SveChannel['id']
  onDone: () => void
  onCancel?: () => void
}
export const EditSVEChannelForm = ({ sveChannelId, onDone, ...props }: EditSVEChannelFormProps) => {
  const intl = useIntl()
  const [updateSVEChannel, UpdateSveChannelMutationResult] = useUpdateSveChannelMutation()
  const SVEChannelQuery = useSveChannelQuery({ variables: { channelId: sveChannelId }, fetchPolicy: 'network-only' })
  const sveChannel = SVEChannelQuery.data?.sveChannel

  const handleSubmit = useCallback(
    async ({ name }: SVEChannelFormValues) => {
      return updateSVEChannel({ variables: { input: { channelId: sveChannelId, name } } }).then(onDone)
    },
    [onDone, sveChannelId, updateSVEChannel],
  )
  if (!sveChannel) return null
  // const userWithoutTypenames = JSON.parse(JSON.stringify(sveChannel), (key, value) =>
  //   key === '__typename' ? undefined : value,
  // )
  const loading = UpdateSveChannelMutationResult.loading || SVEChannelQuery.loading

  return (
    <SVEChannelForm
      sveChannelId={sveChannelId}
      title={intl.formatMessage({ id: 'sveChannels.form.edit.title' }, { name: sveChannel.name })}
      onSubmit={handleSubmit}
      initialValues={sveChannel}
      loading={loading}
      edit
      onDone={onDone}
      onError={props.onError ?? (e => console.error(e))}
      {...props}
    />
  )
}
