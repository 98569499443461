import { observer } from 'mobx-react'
import { useCreateCategory, useGetCategories } from 'models'
import React, { useCallback, useMemo } from 'react'
import { Dropdown, DropdownProps } from 'semantic-ui-react'
import { Category, Maybe, Permission } from 'services/api/graphql'
import { useCurrentUser } from 'stores'

export interface SelectCategoryInputProps {
  value: Array<Category['id']> | undefined
  onChange: (value: Maybe<Array<Category['id']>>) => void
  multiple?: boolean
  disabled?: boolean
}

const SelectCategoryInput: React.FC<SelectCategoryInputProps> = observer(({ value, onChange, multiple }) => {
  // Check if current user can create new categories
  const currentUser = useCurrentUser()
  const canAddCategory = useMemo(() => currentUser.can(Permission.CategoryCreate, undefined, true), [currentUser])

  // Fetch categories
  const { categories, loading, refetch } = useGetCategories()
  const [createCategory, { loading: createLoading }] = useCreateCategory()

  const categoriesOptions = useMemo(
    () =>
      (categories || []).map(category => ({
        key: category.id,
        value: category.id,
        text: category.title,
      })),
    [categories],
  )

  /**
   * Create a new category, and select it
   */
  const addCategory = useCallback(
    async (title: string) => {
      const data = await createCategory({ input: { title } })
      if (data) {
        const category = data.createCategory
        onChange([...(value || []), category.id])
        refetch()
      }
    },
    [createCategory, onChange, refetch, value],
  )

  /**
   * Check that a change in values contains only categories id, and not a new title
   */
  const areChangesWithinIds = useCallback(
    (changeValue: string[] | string) => {
      const values = multiple ? (changeValue as string[]) : [changeValue as string]
      // Every values must be found in categories
      return values.every(v => (categories || []).some(category => category.id === v))
    },
    [multiple, categories],
  )

  return (
    <Dropdown
      multiple={multiple}
      value={value || (multiple ? [] : undefined)}
      loading={loading || createLoading}
      lazyLoad
      search
      selection
      allowAdditions={canAddCategory}
      options={categoriesOptions}
      onChange={(e, data: DropdownProps) => {
        const changes = data.value as string[] | undefined
        if (!changes || areChangesWithinIds(changes)) {
          onChange(changes || null)
        }
      }}
      onAddItem={(e, data) => addCategory(data.value as string)}
    />
  )
})

export default SelectCategoryInput
