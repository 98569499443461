import { Icon } from '@iconify/react'
import { Marker } from 'models'
import React, { useState } from 'react'
import { PiArrowLineLeftBold } from 'react-icons/pi'
import { useIntl } from 'react-intl'
import { Button, Input, Label } from 'semantic-ui-react'
import { formatTime, timeToSeconds, validateTimeFormat } from 'tools/formatTime'

interface MarkerInputsProps {
  validFormat: boolean
  time: number
  title: string
  icon: string
  currentTime: number
  duration: number
  markersList: Marker[]
  isUpdateInput?: Boolean
  onSetTime: (value: number) => void
  onSetTitle: (value: string) => void
  onSetIcon: (value: string) => void
  onValidFormat: (value: boolean) => void
}

const MarkerInputs: React.FC<MarkerInputsProps> = ({
  time,
  title,
  icon,
  currentTime,
  validFormat,
  duration,
  markersList,
  isUpdateInput,
  onSetTime,
  onSetTitle,
  onValidFormat,
  onSetIcon,
}) => {
  const intl = useIntl()

  const [open, setOpen] = useState<boolean>(false)

  const handleTimeChange = (value: string) => {
    if (value && validateTimeFormat(value)) {
      const newTimeValue = timeToSeconds(value)
      onSetTime(newTimeValue)
      onValidFormat(true)
    } else {
      onValidFormat(false)
    }
  }

  const updateMarkersList = (title: string, time: number, icon: string) => {
    markersList.push({ title, time, icon })
    onSetTitle('')
    onSetIcon('')
    onSetTime(0)
    setOpen(false)
  }
  const canceledUpdateInput = () => {
    onSetTitle('')
    onSetIcon('')
    onSetTime(0)
    onValidFormat(true)
    setOpen(false)
  }

  return (
    <div className="flex h-full self-center p-2 bg-gray-100 border border-gray-200 rounded-md shadow-md">
      {!open && (
        <>
          <Button
            className="h-full"
            color="black"
            onClick={() => {
              onSetTime(currentTime)
              onValidFormat(true)
            }}
          >
            <PiArrowLineLeftBold size={20} />
          </Button>
          <Button className="w-28 h-12" onClick={() => setOpen(true)}>
            <div>{formatTime(time)}</div>
          </Button>
        </>
      )}
      {open && (
        <Input
          label={intl.formatMessage({ id: 'markers.time' }) + (!validFormat ? ' *' : '')}
          className="w-28 h-12 mr-24"
          type="text"
          error={!validFormat || time > duration}
          defaultValue={formatTime(time)}
          onChange={e => {
            handleTimeChange(e.target.value)
          }}
        />
      )}
      <Input
        label={intl.formatMessage({ id: 'markers.title' }) + (!title ? ' *' : '')}
        maxLength={25}
        className="w-52 h-12 mr-20"
        type="text"
        error={!title}
        value={title}
        onChange={e => {
          onSetTitle(e.target.value)
        }}
        placeholder={intl.formatMessage({ id: 'markers.titleMaxcharacters' })}
      />
      {icon && (
        <div className="flex h-full">
          <Label size={'small'}>
            {intl.formatMessage({ id: 'markers.selectedIcone' })}
            <Label.Detail>
              <Icon icon={`custom-${icon}`} width={34} />
            </Label.Detail>
          </Label>
        </div>
      )}
      {!isUpdateInput && (
        <>
          <button className="bg-red-600 rounded text-white w-16 h-12 mx-2" onClick={() => canceledUpdateInput()}>
            {intl.formatMessage({ id: 'edit.cancel' })}
          </button>
          {validFormat && time <= duration && title && (
            <Button
              color="black"
              className="h-12 w-16 mx-2"
              onClick={() => {
                updateMarkersList(title, time, icon)
              }}
            >
              {intl.formatMessage({ id: 'edit.confirmation' })}
            </Button>
          )}
        </>
      )}
    </div>
  )
}

export default MarkerInputs
