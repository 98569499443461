import { useEffect, useRef, useState } from 'react'

export const useBlobUrl = (blob: Blob | undefined | null) => {
  const [url, setUrl] = useState<string | null>()
  const blobRef = useRef<Blob>()

  useEffect(() => {
    if (blob !== blobRef.current) {
      blobRef.current = blob ?? undefined

      if (!blob) {
        setUrl(null)
      } else {
        setUrl(window.URL.createObjectURL(blob))
      }
    }

    return () => {
      if (url) window.URL.revokeObjectURL(url)
    }
  }, [blob, url])

  return url
}
