import React from 'react'
import { Dimmer, Loader as SUILoader } from 'semantic-ui-react'
import styled from 'styled-components'

const Container = styled.div`
  position: relative;
  clear: both;
  width: 100%;
  height: 100%;
  flex: 1;
`

export interface LoaderProps {
  children?: React.ReactNode
  loading?: boolean
}

const Loader = ({ children, loading }: LoaderProps) => {
  return (
    <Container>
      <Dimmer active={loading} inverted>
        <SUILoader />
      </Dimmer>
      {children}
    </Container>
  )
}

export default Loader
