import { gql } from '@apollo/client'

export const APPLICATION_DESIGN_FRAGMENT = gql`
  fragment applicationDesignContent on ApplicationDesign {
    id
    title
    description
    organismId
    theme {
      primaryColor
      onPrimaryColor
      surfaceColor
      onSurfaceColor
      onSurfaceAlternateColor
      top10Color
      top10BorderColor
    }
  }
`

export const GET_APPLICATION_DESIGN_QUERY = gql`
  query applicationDesigns($first: Int, $offset: Int, $filters: ApplicationDesignFilters) {
    applicationDesigns(first: $first, offset: $offset, filters: $filters) {
      applicationDesigns {
        ...applicationDesignContent
      }
      totalCount
    }
  }
  ${APPLICATION_DESIGN_FRAGMENT}
`

export const CREATE_APPLICATION_DESIGN_MUTATION = gql`
  mutation createApplicationDesign($input: CreateApplicationDesignInput!) {
    createApplicationDesign(input: $input) {
      ...applicationDesignContent
    }
  }
  ${APPLICATION_DESIGN_FRAGMENT}
`

export const UPDATE_APPLICATION_DESIGN_MUTATION = gql`
  mutation updateApplicationDesign($input: UpdateApplicationDesignInput!) {
    updateApplicationDesign(input: $input) {
      ...applicationDesignContent
    }
  }
  ${APPLICATION_DESIGN_FRAGMENT}
`

export const DELETE_APPLICATION_DESIGN_MUTATION = gql`
  mutation deleteApplicationDesign($id: ID!) {
    deleteApplicationDesign(id: $id) {
      id
    }
  }
`
