import React from 'react'
import { useIntl } from 'react-intl'
import { Dropdown, DropdownItemProps, Icon, Table, TableHeaderCellProps } from 'semantic-ui-react'
import styled from 'styled-components'

const CellContent = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const CellLabel = styled.span`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`

// Edit semantic-ui style to render the filter value in italic
const FilterLabel = styled.span`
  .dropdown > .text {
    font-weight: normal;
    font-style: italic;
  }
`

export enum SemanticUISortDirection {
  ascending = 'ascending',
  descending = 'descending',
}

export interface FilterableTableHeaderProps<T extends string | number | boolean> extends TableHeaderCellProps {
  label: React.ReactNode
  options: DropdownItemProps[]
  onChange: (value: T | undefined) => void
  value?: T | undefined
}

/**
 * Header cell for semantic-ui table with a selectable dropdown for filtering
 */
export default function FilterableTableHeader<T extends string | number | boolean>({
  label,
  options,
  onChange,
  value,
  sorted,
  ...headerProps
}: FilterableTableHeaderProps<T>): React.ReactElement {
  const intl = useIntl()
  const emptyOption: DropdownItemProps = {
    key: 0,
    value: undefined,
    text: intl.formatMessage({ id: 'common.none' }),
  }

  return (
    <Table.HeaderCell {...headerProps}>
      <CellContent>
        <CellLabel>
          {label}
          {sorted && (
            <Icon
              name={
                (sorted as SemanticUISortDirection) === SemanticUISortDirection.ascending
                  ? 'sort ascending'
                  : 'sort descending'
              }
            />
          )}
        </CellLabel>
        <FilterLabel>
          <Dropdown
            icon="filter"
            options={[emptyOption, ...options]}
            onChange={(e, data) => onChange(data.value as T | undefined)}
            value={value || 0}
          />
        </FilterLabel>
      </CellContent>
    </Table.HeaderCell>
  )
}
