import SelectItemListInput from 'Components/Form/SelectItemListInput'
import { SportItemSet, useGetSportItemSets } from 'models'
import React, { useEffect, useMemo } from 'react'

export interface SelectSportItemSetInputProps {
  value?: string | string[]
  onChange: (value?: string | string[]) => void
  organismId: string
  noSelectionEnabled?: boolean
  noSelectionTitle?: string
  multiple?: boolean
  disabled?: boolean
  className?: string
  getSportItemSets?: (items: SportItemSet[]) => void
  selectedSportItemSets?: SportItemSet[]
}

const SelectSportItemSetInput = ({
  value,
  onChange,
  noSelectionEnabled,
  noSelectionTitle,
  organismId,
  multiple,
  disabled,
  className,
  getSportItemSets,
  selectedSportItemSets,
}: SelectSportItemSetInputProps) => {
  const { data, loading } = useGetSportItemSets({
    variables: { filters: { organismId } },
    skip: !organismId,
    fetchPolicy: 'cache-and-network',
  })

  const sportItemSets = useMemo(() => (data && data.sportItemSets.sportItemSets) || [], [data])
  const options = useMemo(() => {
    if (selectedSportItemSets) {
      // Allow to remove items in options for a single add input
      return sportItemSets
        .filter(item => !selectedSportItemSets.includes(item))
        .map(item => ({
          key: item.id,
          value: item.id,
          text: item.title,
        }))
    }

    return sportItemSets.map(sportItemSet => ({
      key: sportItemSet.id,
      value: sportItemSet.id,
      text: sportItemSet.title,
    }))
  }, [selectedSportItemSets, sportItemSets])

  useEffect(() => {
    getSportItemSets && getSportItemSets(sportItemSets)
  }, [getSportItemSets, sportItemSets])

  return (
    <SelectItemListInput
      className={className}
      value={value}
      loading={loading}
      disabled={loading || disabled}
      multiple={multiple}
      options={options}
      noSelectionEnabled={noSelectionEnabled}
      noSelectionTitle={noSelectionTitle}
      onChange={onChange}
    />
  )
}

export default SelectSportItemSetInput
