import ConfirmationButton from 'Containers/Button/ConfirmationButton'
import ThirdPartyApplicationForm from 'Forms/ThirdPartyApplication/ThirdPartyApplicationForm'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Icon, Modal, Table } from 'semantic-ui-react'
import { Permission, ThirdPartyApplicationContentFragment } from 'services/api/graphql'
import { useCurrentUser } from 'stores'

interface ThirdPartyApplicationRowProps {
  thirdPartyApplication?: ThirdPartyApplicationContentFragment
  onUpdateApplication: () => Promise<any>
  onRemoveApplication: (id: string) => Promise<any>
}

const ThirdPartyApplicationViewRow = ({
  thirdPartyApplication,
  onUpdateApplication,
  onRemoveApplication,
}: ThirdPartyApplicationRowProps) => {
  const intl = useIntl()
  const currentUser = useCurrentUser()
  const [formModalOpen, setFormModalOpen] = useState(false)
  if (!thirdPartyApplication) return null

  return (
    <Table.Row>
      <Table.Cell textAlign="center">{thirdPartyApplication.name}</Table.Cell>
      <Table.Cell textAlign="center">{thirdPartyApplication.organism?.title}</Table.Cell>
      <Table.Cell textAlign="center">{thirdPartyApplication.description}</Table.Cell>
      <Table.Cell textAlign="center">
        <div className="flex justify-center center space-x-4">
          {currentUser.can(Permission.ThirdPartyApplicationUpdate) && (
            <Modal
              mountNode={document.getElementById('modals')}
              trigger={<Icon name="edit" link size="large" inverted color="black" />}
              content={
                <ThirdPartyApplicationForm
                  isEdit={true}
                  thirdPartyApplication={thirdPartyApplication}
                  onDone={async () => {
                    await onUpdateApplication()
                    setFormModalOpen(false)
                  }}
                  onCancel={() => setFormModalOpen(false)}
                />
              }
              onClose={() => setFormModalOpen(false)}
              onOpen={() => setFormModalOpen(true)}
              open={formModalOpen}
            />
          )}
          {currentUser.can(Permission.ThirdPartyApplicationDelete) && (
            <ConfirmationButton
              action={() => onRemoveApplication(thirdPartyApplication.id)}
              confirmText={intl.formatMessage({ id: 'thirdPartyApplication.delete_confirm' })}
              successText={intl.formatMessage({ id: 'thirdPartyApplication.delete_success' })}
            />
          )}
        </div>
      </Table.Cell>
    </Table.Row>
  )
}

export default ThirdPartyApplicationViewRow
