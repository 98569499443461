import { applicationUris } from 'Layout/uris'
import LicensedUsersView from 'Views/LicensedUsersView'
import UsersView from 'Views/Users/view'
import React from 'react'
import { Access, userCanAccessToPath } from 'services/api'
import { IUser } from 'stores'

const usersRoutes = (user: IUser | null) => [
  {
    path: applicationUris.users,
    element: <UsersView />,
    loader: () => userCanAccessToPath(user, [Access.UsersView]),
  },
  {
    path: applicationUris.licensedUsers,
    element: <LicensedUsersView />,
    loader: () => userCanAccessToPath(user, [Access.LicensedUsers]),
  },
]

export default usersRoutes
