import React, { useMemo } from 'react'
import styled from 'styled-components'

const Container = styled.span`
  > * + * {
    margin-left: 0.5em;
  }
`

interface DurationProps {
  valueSec: number
  full?: boolean
}

const Duration: React.FC<DurationProps> = ({ valueSec, full }) => {
  const hours = useMemo(() => Math.floor(valueSec / 60 / 60), [valueSec])
  const minutes = useMemo(() => Math.floor(valueSec / 60) % 60, [valueSec])
  const seconds = useMemo(() => Math.floor(valueSec) % 60, [valueSec])

  const displayNumber = (v: number) => `${v < 10 ? '0' : ''}${v}`

  return (
    <Container>
      {(hours || full) && <span>{hours}h</span>}
      {(minutes || full) && <span>{displayNumber(minutes)}m</span>}
      <span>{displayNumber(seconds)}s</span>
    </Container>
  )
}

export default Duration
