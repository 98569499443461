export interface UserMinimumProps {
  email: string
  firstName?: string | undefined | null
  lastName?: string | undefined | null
}
export const userTitle = ({ email, firstName, lastName }: UserMinimumProps): string => {
  const finalFirstName = firstName || ''
  const finaLastName = lastName || ''
  return finalFirstName || finaLastName ? `${finalFirstName} ${finaLastName} (${email})` : `${email}`
}
