import GenericForm from 'Components/Form/GenericForm'
import SAFormField from 'Components/Form/SAFormField'
import SwitchContentTypesButtonInput, {
  SwitchContentTypeSelector,
} from 'Containers/Form/SwitchButtonInput/SwitchContentTypesButtonInput'
import React, { useCallback } from 'react'
import { FieldRenderProps } from 'react-final-form'
import { useIntl } from 'react-intl'
import { Form } from 'semantic-ui-react'
import {
  CreateMediaVideoClipExternalFeedInput,
  ExternalFeedType,
  SportItemContentFragment,
  useCreateMediaVideoClipExternalFeedMutation,
} from 'services/api/graphql'
import { validateRequired } from 'tools/formValidators'
import { notifyError, notifySuccess } from 'tools/toaster'

export interface CreateExternalFeedVideoclipFormProps {
  open: boolean
  onSubmit: () => void
  onCancel: () => void
  sportItem: SportItemContentFragment
}

export const CreateExternalFeedVideoclipForm: React.FC<CreateExternalFeedVideoclipFormProps> = ({
  open,
  onSubmit,
  onCancel,
  sportItem,
}) => {
  const intl = useIntl()
  const [createMediaVideoClipExternalFeed, { loading: loadingCreateExternalFeedVideoclip }] =
    useCreateMediaVideoClipExternalFeedMutation()

  const handleSubmit = useCallback(
    async (values: CreateMediaVideoClipExternalFeedInput) => {
      try {
        await createMediaVideoClipExternalFeed({
          variables: {
            input: {
              media: {
                date: new Date(),
                title: values.media.title,
                sportItemId: sportItem.id,
              },
              externalFeedProvider: values.externalFeedProvider,
              externalFeedUrl: values.externalFeedUrl,
            },
          },
        })
        notifySuccess(intl.formatMessage({ id: 'sportItem.media_updated' }))
        onSubmit()
      } catch (e) {
        notifyError(
          intl.formatMessage({
            id: `sportItem.create_media_error`,
          }),
        )
      }
    },
    [createMediaVideoClipExternalFeed, intl, onSubmit, sportItem.id],
  )
  return (
    <GenericForm
      header={intl.formatMessage({ id: 'sportItemMedia.videoClipExternalFeed.create' })}
      initialValues={{}}
      onCancel={onCancel}
      onSubmit={handleSubmit}
      loading={loadingCreateExternalFeedVideoclip}
      open={open}
    >
      <SAFormField
        name="media.title"
        validate={validateRequired}
        render={({ input, meta }) => (
          <Form.Input
            {...input}
            required
            error={!!meta.touched && !!meta.error}
            label={intl.formatMessage({ id: 'sportItemMedia.live.name' })}
          />
        )}
      />
      <SAFormField
        name="externalFeedProvider"
        validate={validateRequired}
        render={({ input, meta }: FieldRenderProps<ExternalFeedType, HTMLElement>) => (
          <Form.Field
            {...input}
            required
            label={intl.formatMessage({ id: 'sportItem.externalFeed.provider' })}
            error={!!meta.touched && !!meta.error}
            control={() => (
              <SwitchContentTypesButtonInput
                onChange={input.onChange}
                value={input.value}
                typeSelector={SwitchContentTypeSelector.ExternalFeedTypes}
              />
            )}
          />
        )}
      />
      <SAFormField
        name="externalFeedUrl"
        validate={validateRequired}
        render={({ input, meta }) => (
          <Form.Input
            {...input}
            required
            error={!!meta.touched && !!meta.error}
            label={intl.formatMessage({ id: 'sportItem.externalFeed.link' })}
          />
        )}
      />
    </GenericForm>
  )
}
