import React from 'react'
import { IntersectionOptions, useInView } from 'react-intersection-observer'

type ElementIntersectionProps = IntersectionOptions & {
  children: React.ReactNode
}

const ElementIntersection = ({ children, ...options }: ElementIntersectionProps) => {
  const { ref, inView } = useInView(options)

  return <div ref={ref}>{inView && children}</div>
}

export default ElementIntersection
