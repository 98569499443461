import { gql } from '@apollo/client'

export const SVECHANNEL_FRAGMENT = gql`
  fragment sveChannelContent on SVEChannel {
    id
    name
    status

    logoUrl
    inputLossImageUrl
    socialOutputUrl
    socialOutputStreamName

    endpoints {
      rtmpInputUrl
      cmafEndpointUrl
      hlsEndpointUrl
    }
  }
`
export const SVEBROADCAST_FRAGMENT = gql`
  fragment sveBroadcastContent on SVEBroadcast {
    id
    status
    channelId
    startDate
    clipStartDate
    stopDate
    scheduledStartDate
    scheduledClipStartDate
    scheduledStopDate
  }
`

export const SVELIVE_MONITORING_FRAGMENT = gql`
  fragment sveLiveMonitoringContent on SVELiveMonitoring {
    alerts {
      audioNotDetected
      initialProbeTakingLonger
      videoNotDetected
      RTMPHasNoAudioVideo
      RTMPStreamNotFound
    }
    stats {
      networkIn
      droppedFrames
      inputVideoFrameRate
      outputAudioLevelLkfs
    }
  }
`
