import TableCellInput from 'Components/Form/TableCellInput'
import Loader from 'Components/Loader'
import PageHeader from 'Components/PageHeader'
import StreamDetailsModal from 'Components/StreamDetailsModal'
import { useGetStream, useListStreams, useWriteStreamMap } from 'models'
import React, { FC, useCallback, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button, Grid, Icon, Table, TableCell } from 'semantic-ui-react'
import { WmStreamLight } from 'services/api/graphql'
import styled from 'styled-components'
import validator from 'validator'

const ActionsButtons = styled.div`
  display: flex;
  justify-content: flex-start;
`

const SteamMapView: FC = () => {
  const intl = useIntl()

  const {
    data: streams,
    loading,
    refetch,
  } = useListStreams({
    fetchPolicy: 'cache-and-network',
  })

  const [editedStream, setEditedStream] = useState('')
  const [writeStreamMap, { loading: loadingWrite }] = useWriteStreamMap()
  const [playbackUrlValue, setPlaybackUrlValue] = useState('')
  const isPlaybackUrlValid = useMemo(() => !!playbackUrlValue && validator.isURL(playbackUrlValue), [playbackUrlValue])
  const [channelArnValue, setChannelArnValue] = useState('')

  const onEditStream = useCallback((stream: WmStreamLight) => {
    const mapping = stream.map
    const playbackUrl = (mapping && mapping.playbackUrl) || ''
    const channelArn = (mapping && mapping.channelArn) || ''
    setEditedStream(stream.id)
    setPlaybackUrlValue(playbackUrl)
    setChannelArnValue(channelArn)
  }, [])
  const onPlaybackUrlChange = (url: string) => {
    setPlaybackUrlValue(url)
    // Auto generate arn from url
    const parsedData = url.match(/^https:\/\/.+eu-west-([0-9])\.(.+)\.channel\.(.+)\.m3u8$/)
    if (!parsedData) return
    const [_, euRegion, id1, id2] = parsedData
    setChannelArnValue(`arn:aws:ivs:eu-west-${euRegion}:${id1}:channel/${id2}`)
  }
  const submitStreamMap = useCallback(async () => {
    if (isPlaybackUrlValid) {
      await writeStreamMap({
        input: {
          streamId: editedStream,
          playbackUrl: playbackUrlValue,
          channelArn: channelArnValue,
        },
      })
      await refetch()
      setEditedStream('')
      setPlaybackUrlValue('')
    }
  }, [isPlaybackUrlValid, writeStreamMap, editedStream, playbackUrlValue, channelArnValue, refetch])

  // Get stream details
  const [selectedStreamId, setSelectedStreamId] = useState<string | undefined>()
  const { stream: streamDetails, loading: loadingStreamDetails } = useGetStream(selectedStreamId)
  const streamDetailsModalOpen = useMemo(
    () => !!selectedStreamId && !!streamDetails && !loadingStreamDetails,
    [selectedStreamId, streamDetails, loadingStreamDetails],
  )
  const closeStreamDetailsModal = useCallback(() => {
    setSelectedStreamId(undefined)
  }, [])

  return (
    <Loader loading={loading || loadingWrite || loadingStreamDetails}>
      <Grid padded>
        <Grid.Column>
          <PageHeader title={<FormattedMessage id="streamMap.header" />} />

          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width="2">
                  <FormattedMessage id="streamMap.stream" />
                </Table.HeaderCell>
                <Table.HeaderCell width="4">
                  <FormattedMessage id="streamMap.outputUrl" />
                </Table.HeaderCell>
                <Table.HeaderCell width="4">
                  <FormattedMessage id="streamMap.playbackUrl" />
                </Table.HeaderCell>
                <Table.HeaderCell width="1" />
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {streams &&
                streams.map(stream => (
                  <Table.Row key={stream.id} warning={!stream.map}>
                    <Table.Cell>{stream.name}</Table.Cell>
                    <Table.Cell>{stream.outputUrl}</Table.Cell>
                    <TableCell>
                      {editedStream !== stream.id &&
                        (stream.map ? (
                          stream.map.playbackUrl
                        ) : (
                          <>
                            <Icon name="warning sign" /> <FormattedMessage id="streamMap.no_mapping" />
                          </>
                        ))}
                      {editedStream === stream.id && (
                        <>
                          <TableCellInput
                            value={playbackUrlValue}
                            onChange={e => onPlaybackUrlChange(e.target.value)}
                            error={!isPlaybackUrlValid}
                            onValidate={submitStreamMap}
                            onCancel={() => setEditedStream('')}
                          />
                          <TableCellInput
                            value={channelArnValue}
                            onChange={e => setChannelArnValue(e.target.value)}
                            size="mini"
                            disabled={!playbackUrlValue}
                          />
                        </>
                      )}
                    </TableCell>
                    <TableCell>
                      <ActionsButtons>
                        {editedStream !== stream.id && (
                          <>
                            <Button
                              icon="pencil"
                              primary
                              onClick={() => onEditStream(stream)}
                              aria-label={intl.formatMessage({ id: 'streamMap.edit' }, { name: stream.name })}
                            />
                            <Button
                              icon="info"
                              circular
                              onClick={() => setSelectedStreamId(stream.id)}
                              aria-label={intl.formatMessage({ id: 'streamMap.info' }, { name: stream.name })}
                            ></Button>
                          </>
                        )}
                        {editedStream === stream.id && (
                          <>
                            <Button
                              icon="check"
                              positive
                              disabled={!isPlaybackUrlValid}
                              onClick={() => submitStreamMap()}
                              aria-label={intl.formatMessage({
                                id: 'common.submit',
                              })}
                            />
                            <Button
                              icon="ban"
                              onClick={() => setEditedStream('')}
                              aria-label={intl.formatMessage({
                                id: 'common.cancel',
                              })}
                            />
                          </>
                        )}
                      </ActionsButtons>
                    </TableCell>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid>
      {streamDetails && (
        <StreamDetailsModal open={streamDetailsModalOpen} streams={[streamDetails]} onClose={closeStreamDetailsModal} />
      )}
    </Loader>
  )
}

export default SteamMapView
