import { gql } from '@apollo/client'

export const WM_STREAM_FRAGMENT = gql`
  fragment wmStreamContent on WMStream {
    id
    name
    ingestionStatus
    inputUrl
    outputUrl
    map {
      id
      channelArn
    }
  }
`

export const LIST_STREAMS_QUERY = gql`
  query listStreams($filters: StreamFilters) {
    listStreams(filters: $filters) {
      id
      state
      name
      status
      tags
      outputUrl
      map {
        id
        playbackUrl
        outputUrl
        channelArn
      }
    }
  }
`

export const GET_STREAM_QUERY = gql`
  query getStream($id: WildmokaID!) {
    getStream(id: $id) {
      ...wmStreamContent
    }
  }

  ${WM_STREAM_FRAGMENT}
`

export const LIST_TEMPLATES_QUERY = gql`
  query listTemplates($type: WMTemplateType, $organismId: ID) {
    listTemplates(type: $type, organismId: $organismId) {
      id
      name
      type
    }
  }
`

export const LIST_PROFILES_QUERY = gql`
  query listProfiles {
    listProfiles {
      id
      name
      tags
    }
  }
`

export const WRITE_STREAM_MAP_MUTATION = gql`
  mutation writeStreamMap($input: WriteStreamMapInput!) {
    writeStreamMap(input: $input) {
      id
      streamId
      playbackUrl
    }
  }
`
