import InvisibleButton from 'Components/Button/InvisibleButton'
import SROnly from 'Components/Button/SROnly'
import React, { Fragment, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Button, Icon, Popup, PopupProps } from 'semantic-ui-react'
import styled from 'styled-components'
import { notifyError, notifySuccess } from 'tools/toaster'

import { ConfirmationButtonBaseProps } from './ConfirmationButton'

const ConfirmationPopup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > * + * {
    margin-top: 0.5rem;
  }

  text-align: center;
`

const Actions = styled.div`
  flex: 1;
  display: flex;

  > * {
    flex: 1;
  }
`

interface InlineConfirmationButtonProps extends ConfirmationButtonBaseProps {
  action: () => Promise<any>
  position?: PopupProps['position']
  children?: React.ReactNode
  negative?: boolean
}

const InlineConfirmationButton = ({
  action,
  confirmText,
  successText,
  onCancel,
  onDone,
  onError,
  children,
  position,
  negative = false,
}: InlineConfirmationButtonProps) => {
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  return (
    <Fragment>
      <Popup
        position={position || 'top center'}
        open={open}
        trigger={
          <InvisibleButton onClick={() => setOpen(true)}>
            {children || (
              <>
                <Icon name="remove" link size="large" inverted color="red" />
                <SROnly>
                  <FormattedMessage id="common.delete" />
                </SROnly>
              </>
            )}
          </InvisibleButton>
        }
      >
        <ConfirmationPopup>
          <span>{confirmText}</span>
          <Actions>
            <Button
              onClick={() => {
                setOpen(false)
                if (onCancel) onCancel()
              }}
            >
              <FormattedMessage id="common.no" />
            </Button>
            <Button
              positive={!negative}
              negative={negative}
              loading={loading}
              onClick={() => {
                setLoading(true)
                action()
                  .then(() => {
                    notifySuccess(successText)
                    setOpen(false)
                    if (onDone) {
                      onDone()
                    }
                  })
                  .catch(error => {
                    notifyError(<FormattedMessage id="error.general" />, error)
                    if (onError) onError(error)
                  })
                  .finally(() => setLoading(false))
              }}
            >
              <FormattedMessage id="common.yes" />
            </Button>
          </Actions>
        </ConfirmationPopup>
      </Popup>
    </Fragment>
  )
}

export default InlineConfirmationButton
