import { BlockWithAlignableContents } from '@lexical/react/LexicalBlockWithAlignableContents'
import classnames from 'classnames'
import React, { FC } from 'react'

type BlockWithAlignableContentsProps = Parameters<typeof BlockWithAlignableContents>[0]

export type YoutubeElementProps = {
  videoID: string
} & Omit<BlockWithAlignableContentsProps, 'children'>

const YoutubeElement: FC<YoutubeElementProps> = ({ videoID, ...props }) => (
  <BlockWithAlignableContents {...props} format="center">
    <iframe
      className={classnames('border-0', {
        'mx-auto': props.format === 'center',
        'ml-auto': props.format === 'right',
      })}
      width="560"
      height="315"
      src={`https://www.youtube.com/embed/${videoID}`}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen={true}
      title="YouTube video"
    />
  </BlockWithAlignableContents>
)

export default YoutubeElement
