import CropImageAndUploadFromSportallFile from 'Containers/CropImage/CropImageAndUploadFromSportallFile'
import FilesLibraryView from 'Containers/FilesLibraryView'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useState } from 'react'
import { useIntl } from 'react-intl'
import { Button } from 'semantic-ui-react'
import { Media, MediaType, Organism, Permission } from 'services/api/graphql'
import { useCurrentUser, useStore } from 'stores'
import styled from 'styled-components'
import { aspectRatioText, doesImageFormatRespectConstraints } from 'tools/image'
import { doesImageFileRespectConstraints, fileImageDimensions } from 'tools/imageFile'
import { ImageConstraints, ImageDimensions } from 'types/image'

import CropImageAndUpload from './CropImage/CropImageAndUpload'

const Constraints = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const Constraint = styled.div`
  color: red;
  font-weight: bold;
`
const ConstraintRespected = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;
  color: green;
`

const Container = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
`
const Buttons = styled.div`
  display: flex;
  justify-content: center;
`

export interface SelectImageViewProps {
  selectedMediaId: string | null
  onDone: (media: Media) => void
  imageConstraints?: ImageConstraints
  renderOrganismPicker?: boolean
  organismId?: Organism['id']
}

const SelectImageView = observer(
  ({ onDone, imageConstraints, renderOrganismPicker, organismId }: SelectImageViewProps) => {
    const intl = useIntl()
    const currentUser = useCurrentUser()
    const store = useStore()

    const [cropAndUploadElement, setCropAndUploadElement] = useState<JSX.Element | null>()

    const onUploadImage = useCallback(
      async (selectedFile: File, refresh: () => void) => {
        setCropAndUploadElement(
          <CropImageAndUpload
            image={selectedFile}
            onDone={file => {
              refresh()
              setCropAndUploadElement(null)
              onDone(file)
            }}
            onCancel={() => setCropAndUploadElement(null)}
            canImportSourceImage
            imageConstraints={imageConstraints}
            ownerOrganismId={store.organismId ?? undefined}
          />,
        )
      },
      [onDone, store.organismId, imageConstraints],
    )

    const renderImageConstraintDescriptionText = useCallback(
      (imageConstraints: ImageConstraints, constraintsRespected: boolean) => (
        <Constraints>
          <span>{intl.formatMessage({ id: 'imageConstraints' })}</span>

          {imageConstraints.aspect && (
            <Constraint>
              {intl.formatMessage(
                { id: 'imageConstraints.aspect.select' },
                { aspect: aspectRatioText(imageConstraints.aspect) },
              )}
            </Constraint>
          )}

          {imageConstraints.format && (
            <Constraint>
              {intl.formatMessage({ id: 'imageConstraints.format' }, { format: imageConstraints.format })}
            </Constraint>
          )}

          {imageConstraints.minWidth !== undefined && imageConstraints.minHeight !== undefined && (
            <Constraint>
              {intl.formatMessage(
                { id: 'imageConstraints.height.min.and.width.min' },
                { width: imageConstraints.minWidth, height: imageConstraints.minHeight },
              )}
            </Constraint>
          )}

          {imageConstraints.minWidth !== undefined && imageConstraints.minHeight === undefined && (
            <Constraint>
              {intl.formatMessage({ id: 'imageConstraints.width.min.select' }, { width: imageConstraints.minWidth })}
            </Constraint>
          )}

          {imageConstraints.minWidth === undefined && imageConstraints.minHeight !== undefined && (
            <Constraint>
              {intl.formatMessage({ id: 'imageConstraints.height.min.select' }, { height: imageConstraints.minHeight })}
            </Constraint>
          )}

          {imageConstraints.maxWidth !== undefined && imageConstraints.maxHeight !== undefined && (
            <Constraint>
              Avoir une taille maximum de {imageConstraints.maxWidth} x {imageConstraints.maxHeight}
            </Constraint>
          )}

          {imageConstraints.maxWidth !== undefined && imageConstraints.maxHeight === undefined && (
            <Constraint>
              {intl.formatMessage({ id: 'imageConstraints.width.max.select' }, { width: imageConstraints.maxWidth })}
            </Constraint>
          )}

          {imageConstraints.maxWidth === undefined && imageConstraints.maxHeight !== undefined && (
            <Constraint>
              {intl.formatMessage({ id: 'imageConstraints.height.max.select' }, { width: imageConstraints.maxHeight })}
            </Constraint>
          )}

          {constraintsRespected && (
            <ConstraintRespected>{intl.formatMessage({ id: 'imageConstraints.valid' })}</ConstraintRespected>
          )}
        </Constraints>
      ),
      [intl],
    )

    const imageCanBeCropped = useCallback((imageSize: ImageDimensions, imageConstraints: ImageConstraints): boolean => {
      if (imageConstraints.minWidth !== undefined && imageSize.width < imageConstraints.minWidth) return false
      if (imageConstraints.minHeight !== undefined && imageSize.height < imageConstraints.minHeight) return false
      return true
    }, [])

    return (
      <Container>
        <FilesLibraryView
          mediaType={MediaType.Image}
          renderFileDetails={({ media: selectedImage, refresh }) => {
            const isImageValid =
              (selectedImage.image && doesImageFileRespectConstraints(selectedImage.image, imageConstraints)) || false

            const imageRespectFormat =
              (selectedImage.image &&
                imageConstraints?.format &&
                doesImageFormatRespectConstraints(selectedImage.image, imageConstraints?.format)) ||
              true

            const imageSize = selectedImage.image && fileImageDimensions(selectedImage.image)
            const canBeCropped = imageConstraints && imageSize ? imageCanBeCropped(imageSize, imageConstraints) : true

            return (
              <Column>
                {imageConstraints && renderImageConstraintDescriptionText(imageConstraints, isImageValid)}

                <Buttons>
                  {canBeCropped && !imageConstraints?.format && currentUser.can(Permission.FileCreate) && (
                    <Button
                      primary
                      onClick={() => {
                        // Ask for crop
                        setCropAndUploadElement(
                          <CropImageAndUploadFromSportallFile
                            selectedFile={selectedImage}
                            imageConstraints={imageConstraints}
                            onDone={(croppedImage: Media) => {
                              setCropAndUploadElement(null)
                              refresh()
                              onDone(croppedImage)
                            }}
                            onCancel={() => setCropAndUploadElement(null)}
                            ownerOrganismId={store.organismId || undefined}
                          />,
                        )
                      }}
                    >
                      {intl.formatMessage({ id: 'medias.crop' })}
                    </Button>
                  )}

                  {isImageValid && imageRespectFormat && (
                    <Button
                      primary
                      onClick={() => {
                        if (selectedImage) onDone(selectedImage)
                      }}
                    >
                      {intl.formatMessage({ id: 'common.select' })}
                    </Button>
                  )}
                </Buttons>
              </Column>
            )
          }}
          onUploadFile={onUploadImage}
          dropAcceptedMimetypes={['image/png', 'image/jpeg']}
          imageConstraints={imageConstraints}
          renderOrganismPicker={renderOrganismPicker}
          organismId={organismId}
        />

        {cropAndUploadElement}
      </Container>
    )
  },
)
export default SelectImageView
