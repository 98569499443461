import React from 'react'
import { Pagination } from 'semantic-ui-react'
import styled from 'styled-components'

const PaginationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
`

interface TablePaginationProps {
  totalPages: number
  activePage: number
  onPageChange: (page: number) => void
}

const TablePagination: React.FC<TablePaginationProps> = ({ totalPages, activePage, onPageChange }) => {
  return (
    <PaginationWrapper>
      <Pagination
        totalPages={totalPages}
        activePage={activePage}
        onPageChange={(e, data) => onPageChange(data.activePage as number)}
      />
    </PaginationWrapper>
  )
}

export default TablePagination
