/**
 * Classnames applied to rendered lexical elements.
 *
 * @see https://lexical.dev/docs/getting-started/theming
 */
export default {
  ltr: 'ltr',
  rtl: 'rtl',
  placeholder: 'editor-placeholder',
  // rich text
  paragraph: 'relative m-0 mb-2',
  heading: {
    h1: 'text-xl text-bold',
    h2: 'text-lg text-bold',
    h3: '',
    h4: '',
    h5: '',
  },
  list: {
    nested: {
      listitem: 'editor-nested-listitem',
    },
    ol: 'list-decimal mb-2',
    ul: 'list-disc mb-2',
    listitem: 'mx-8 my-0',
  },
  quote: 'm-2 text-gray-400 border-l-4 pl-4 py-2',
  text: {
    bold: 'text-bold',
    italic: 'italic',
    overflowed: '',
    hashtag: '',
    underline: 'underline',
    strikethrough: 'line-through',
    underlineStrikethrough: 'underline line-through',
    code: 'bg-gray-100 text-sm',
  },

  // link
  link: 'text-blue-500',

  // custom plugins
  image: 'inline-block',
  embedBlock: {
    base: 'select-none border-2 border-transparent my-2',
    focus: 'border-blue-400',
  },
}
