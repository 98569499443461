import Loader from 'Components/Loader'
import { useGetContentPushStreamExports } from 'models/ContentPushStream'
import React, { memo, useCallback } from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { Header, Icon, Table } from 'semantic-ui-react'

type ContentPushStreamExportsProps = {
  contentPushStreamId: string
}

const limit = 10

const ContentPushStreamExports = ({ contentPushStreamId }: ContentPushStreamExportsProps) => {
  const intl = useIntl()
  const { data, fetchMore, loading } = useGetContentPushStreamExports({
    variables: {
      contentPushStreamId,
      first: limit,
      offset: 0,
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  })

  const loadMore = useCallback(async () => {
    await fetchMore({
      variables: {
        first: limit,
        offset: data.sportItemSets?.length,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev
        if (!prev) return fetchMoreResult

        return Object.assign({}, prev, {
          contentPushStreamExports: {
            sportItemSets: [
              ...prev.contentPushStreamExports.sportItemSets,
              ...fetchMoreResult.contentPushStreamExports.sportItemSets,
            ],
            totalCount: fetchMoreResult.contentPushStreamExports.totalCount,
          },
        })
      },
    })
  }, [data.sportItemSets?.length, fetchMore])

  return (
    <>
      <Header as="h3">
        {intl.formatMessage({ id: 'contentPushStream.create.exportFlow' }, { totalCount: data.totalCount })}
      </Header>
      <Table className="flex">
        {/* Header */}
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={1} textAlign="left">
              {intl.formatMessage({ id: 'contentPushStream.exportFlow.eventTitle' })}
            </Table.HeaderCell>
            <Table.HeaderCell width={1} textAlign="left">
              {intl.formatMessage({ id: 'contentPushStream.exportFlow.organismName' })}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        {/* SportItemSets associated to contentPushServiceStream */}
        <Table.Body>
          {data.sportItemSets?.map(sportItemSet => (
            <Table.Row key={sportItemSet.id}>
              <Table.Cell textAlign="left">
                <Link to={`/${sportItemSet.type}/${sportItemSet.id}`}>{sportItemSet.title}</Link>
              </Table.Cell>
              <Table.Cell textAlign="left">{sportItemSet.ownerOrganism?.title}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      {/* No result */}
      {data.totalCount === 0 && (
        <div className="flex justify-center">{intl.formatMessage({ id: 'contentPushStream.exportFlow.noResult' })}</div>
      )}

      {/* Fetch more */}
      <Loader loading={loading} />
      <div
        className={`flex justify-center cursor-pointer ${
          data.totalCount === data.sportItemSets?.length ? 'hidden' : ''
        }`}
        onClick={loadMore}
      >
        <span className="text-gray-500">{intl.formatMessage({ id: 'seeMore' })}</span>
        <Icon className="text-gray-500" name="dropdown" />
      </div>
    </>
  )
}

export default memo(ContentPushStreamExports)
