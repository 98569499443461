import { BlockWithAlignableContents } from '@lexical/react/LexicalBlockWithAlignableContents'
import classnames from 'classnames'
import React, { FC } from 'react'
import { Tweet } from 'react-twitter-widgets'

type BlockWithAlignableContentsProps = Parameters<typeof BlockWithAlignableContents>[0]

export type TweetElementProps = {
  tweetID: string
} & Omit<BlockWithAlignableContentsProps, 'children'>

const TweetElement: FC<TweetElementProps> = ({ tweetID, ...props }) => (
  <BlockWithAlignableContents {...props}>
    <div
      className={classnames('inline-block w-1/2', {
        'mx-auto': props.format === 'center',
        'ml-auto': props.format === 'right',
      })}
    >
      <Tweet tweetId={tweetID} />
    </div>
  </BlockWithAlignableContents>
)

export default TweetElement
