import InvisibleButton from 'Components/Button/InvisibleButton'
import RefreshButton from 'Components/Button/RefreshButton'
import Loader from 'Components/Loader'
import PageHeader from 'Components/PageHeader'
import TablePagination from 'Components/TablePagination'
import VideoPlayerModal from 'Components/VideoPlayer/VideoPlayerModal'
import AddLinkButton from 'Forms/Link/AddLinkButton'
import EditLinkButton from 'Forms/Link/EditLinkButton'
import RemoveLinkButton from 'Forms/Link/RemoveLinkButton'
import usePagination from 'hooks/usePagination'
import _ from 'lodash'
import { observer } from 'mobx-react'
import { useGetLinks } from 'models'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button, Grid, Icon, Input, Popup, Table } from 'semantic-ui-react'
import { LinkTag, Permission } from 'services/api/graphql'
import { useCurrentUser, useStore } from 'stores'
import styled from 'styled-components'
import { notifyError } from 'tools/toaster'

const IconThumbnail = styled.img`
  display: flex;
  width: 48px;
  height: 48px;
  object-fit: contain;
  border-radius: 5px;
  border-color: black;
  border-style: solid;
  border-width: 1px;
`
const IconThumbnailContainer = styled.div`
  display: flex;
  justify-content: center;
`
const Buttons = styled.div`
  display: flex;
  justify-content: center;
`
const UrlText = styled.a`
  font-size: small;
  color: darkgrey;
`

const LinksView = () => {
  const intl = useIntl()
  const currentUser = useCurrentUser()
  const { organismId } = useStore()
  const [search, setSearch] = useState<string | null>()

  const { activePage, onPageChange, paginationParams, pageSize } = usePagination()

  const { links, totalCount, error, loading, refetch } = useGetLinks({
    fetchPolicy: 'cache-and-network',
    variables: { filters: { organismId, search }, ...paginationParams },
  })

  const [selectedVideoUrl, setSelectedVideoUrl] = useState<string>('')
  const [playerOpen, setPlayerOpen] = useState(false)

  const openVideoPlayer = useCallback((url: string) => {
    setSelectedVideoUrl(url)
    setPlayerOpen(true)
  }, [])

  useEffect(() => {
    if (error) notifyError(<FormattedMessage id="error.general" />, error)
  }, [error])

  const totalPages = useMemo(() => {
    if (!totalCount) return 0
    return Math.ceil(totalCount / pageSize)
  }, [pageSize, totalCount])

  const handleOnSearchChange = useMemo(
    () =>
      _.debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        onPageChange(1)
        setSearch(e.target.value)
      }, 300),
    [onPageChange],
  )

  return (
    <Loader loading={loading}>
      <Grid padded>
        <Grid.Column>
          <PageHeader title={intl.formatMessage({ id: 'links' })}>
            <Input
              icon="search"
              loading={loading}
              placeholder={intl.formatMessage({
                id: 'users.search',
              })}
              onChange={handleOnSearchChange}
            />
            <RefreshButton onClick={() => refetch()} loading={loading} />
            {currentUser.can(Permission.LinkCreate) && (
              <AddLinkButton
                onDone={refetch}
                render={onClick => <Button circular icon="add" color="red" onClick={onClick} />}
              />
            )}
          </PageHeader>

          <Table style={{ flex: 1 }}>
            <Table.Header>
              <Table.Row textAlign="center">
                <Table.HeaderCell width={1}>
                  <FormattedMessage id="links.picture" />
                </Table.HeaderCell>
                <Table.HeaderCell width={1}>
                  <FormattedMessage id="links.type" />
                </Table.HeaderCell>
                <Table.HeaderCell width={1}>
                  <FormattedMessage id="links.title" />
                </Table.HeaderCell>
                <Table.HeaderCell width={2}>
                  <FormattedMessage id="link.link" />
                </Table.HeaderCell>
                <Table.HeaderCell width={1}>
                  <FormattedMessage id="link.organisms" />
                </Table.HeaderCell>
                <Table.HeaderCell width={1}>
                  <FormattedMessage id="publicationStatus" />
                </Table.HeaderCell>
                <Table.HeaderCell width={1}>
                  <FormattedMessage id="links.teaserVideo" />
                </Table.HeaderCell>
                <Table.HeaderCell width={1}>
                  <FormattedMessage id="actions" />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {links?.map(
                ({
                  id,
                  title,
                  image,
                  publicationStatus,
                  ownerOrganisms,
                  ownerOrganismIds,
                  tag,
                  url,
                  teaserVideo,
                  gamTagUrl,
                }) => (
                  <Table.Row key={id}>
                    <Table.Cell textAlign="center">
                      {image?.image?.thumbUrl && (
                        <IconThumbnailContainer>
                          <IconThumbnail src={image.image.thumbUrl} alt="Link image" />
                        </IconThumbnailContainer>
                      )}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <Popup
                        position="top center"
                        content={<FormattedMessage id={`links.type.${tag || 'link'}`} />}
                        trigger={
                          <Icon name={tag === LinkTag.Sponsoring ? 'money bill alternate outline' : 'linkify'} />
                        }
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <strong>{title}</strong>
                    </Table.Cell>
                    <Table.Cell textAlign="center" width={1}>
                      <UrlText href={url || gamTagUrl || ''} target="_blank" rel="noopener noreferrer">
                        {(url || gamTagUrl || '').length > 50
                          ? `${(url || gamTagUrl)?.slice(0, 50)}...`
                          : url || gamTagUrl}
                      </UrlText>
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      {ownerOrganisms?.map(({ title }, index) =>
                        index === ownerOrganismIds.length - 1 ? title : `${title}, `,
                      )}
                      {ownerOrganismIds && !ownerOrganisms && `Organisme inconnu ${ownerOrganismIds}`}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <FormattedMessage id={`publicationStatus.${publicationStatus}`} />
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      {teaserVideo && teaserVideo.videoClip && (
                        <InvisibleButton
                          onClick={() =>
                            teaserVideo && teaserVideo.videoClip && openVideoPlayer(teaserVideo.videoClip.playbackUrl)
                          }
                        >
                          <Icon name="play" inverted color="black" />
                        </InvisibleButton>
                      )}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <Buttons>
                        {currentUser.can(Permission.LinkUpdate) && (
                          <EditLinkButton
                            linkId={id}
                            render={onClick => (
                              <Icon name="edit" link size="large" inverted color="black" onClick={onClick} />
                            )}
                          />
                        )}
                        {currentUser.can(Permission.LinkDelete) && (
                          <RemoveLinkButton
                            linkId={id}
                            confirmText={intl.formatMessage({ id: 'links.form.delete.question' }, { title })}
                            successText={intl.formatMessage({ id: 'links.form.delete.success' }, { title })}
                            onDone={refetch}
                          />
                        )}
                      </Buttons>
                    </Table.Cell>
                  </Table.Row>
                ),
              )}
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid>
      {!!totalPages && totalPages > 1 && (
        <TablePagination totalPages={totalPages} activePage={activePage || 1} onPageChange={onPageChange} />
      )}
      <VideoPlayerModal open={playerOpen} onClose={() => setPlayerOpen(false)} url={selectedVideoUrl} />
    </Loader>
  )
}

export default observer(LinksView)
