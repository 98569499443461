import SelectItemListInput from 'Components/Form/SelectItemListInput'
import React, { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { Maybe, UserRoleId } from 'services/api/graphql'
import UserRolesContext from 'stores/Contexts/UserRolesContext'

export interface SelectUserRolesInputProps {
  value: UserRoleId[] | undefined
  onChange: (value: Maybe<UserRoleId[]>) => void
  noSelectionEnabled?: boolean
  noSelectionTitle?: string
  disabled?: boolean
}

const SelectUserRolesInput = ({
  value,
  onChange,
  noSelectionEnabled,
  noSelectionTitle = 'Aucun',
  disabled,
}: SelectUserRolesInputProps) => {
  const intl = useIntl()
  const getOptions = useCallback(
    (userRoles: UserRoleId[]) => {
      return userRoles.map(userRoleId => ({
        key: userRoleId,
        value: userRoleId,
        text: intl.formatMessage({ id: `userRole.${userRoleId}` }),
      }))
    },
    [intl],
  )

  return (
    <UserRolesContext.Consumer>
      {userRoles => (
        <SelectItemListInput
          value={value}
          options={getOptions([...new Set<UserRoleId>([...userRoles, ...(value || [])])])}
          multiple
          noSelectionEnabled={noSelectionEnabled}
          noSelectionTitle={noSelectionTitle}
          onChange={onChange}
          disabled={disabled}
        />
      )}
    </UserRolesContext.Consumer>
  )
}

export default SelectUserRolesInput
