import { applicationUris } from 'Layout/uris'
import { useLogin } from 'hooks/auth'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link, useNavigate } from 'react-router-dom'
import { Form, Message, Segment } from 'semantic-ui-react'
import styled from 'styled-components'
import I18nError from 'tools/I18nError'
import { getErrorMessage } from 'tools/graphql'

const ForgotLink = styled.a`
  font-size: 0.9em;
`

const LoginForm = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState(false)
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState(false)
  const [formError, setFormError] = useState(false)
  const { login, loading, error: loginError } = useLogin()

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    let error = false
    if (email === '' || email.length < 2) {
      setEmailError(true)
      error = true
    } else {
      setEmailError(false)
    }
    if (password === '') {
      setPasswordError(true)
      error = true
    } else {
      setPasswordError(false)
    }
    if (error) {
      setFormError(true)
      return
    }
    setFormError(false)

    try {
      await login(email, password)
      navigate(applicationUris.dashboard)
    } catch (e) {
      setFormError(true)
    }
  }
  return (
    <>
      <Segment>
        <Form onSubmit={onSubmit} error={!!loginError || !!formError}>
          {loginError && (
            <>
              <Message
                error
                content={
                  loginError.name === 'I18nError' ? (
                    <FormattedMessage id={(loginError as I18nError).messageId} />
                  ) : (
                    getErrorMessage(loginError)
                  )
                }
              />
            </>
          )}
          <Form.Field>
            <Form.Input
              disabled={loading}
              required
              label={intl.formatMessage({ id: 'users.email' })}
              name="email"
              placeholder="my@email.com"
              onChange={e => setEmail(e.target.value)}
              error={emailError}
            />
          </Form.Field>
          <Form.Field>
            <Form.Input
              disabled={loading}
              required
              name="password"
              label={intl.formatMessage({ id: 'users.password' })}
              type="password"
              onChange={e => setPassword(e.target.value)}
              error={passwordError}
            />
          </Form.Field>
          <Form.Field>
            <Form.Button
              primary
              content={intl.formatMessage({ id: 'login.submit' })}
              disabled={!email || !password || loading}
              type="submit"
              loading={loading}
            />
          </Form.Field>
        </Form>
      </Segment>
      <Link to="/forgot-password">
        <ForgotLink>
          <FormattedMessage id="login.forgotPassword" />
        </ForgotLink>
      </Link>
    </>
  )
}

export default observer(LoginForm)
