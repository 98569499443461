import { graphql } from 'gql'

export const OrganismTitleFragment = graphql(`
  fragment organism on Organism @_unmask {
    id
    title
  }
`)

export const GetOrganismsQuery = graphql(
  `
    query organisms($filters: OrganismFilters) {
      organisms(filters: $filters) {
        organisms {
          ...organism
        }
        totalCount
      }
    }
  `,
  [OrganismTitleFragment],
)
