import { gql } from '@apollo/client'

import { LIGHT_ORGANISM_FRAGMENT } from './Organism'

export const THIRD_PARTY_APPLICATION_FRAGMENT = gql`
  fragment thirdPartyApplicationContent on ThirdPartyApplication {
    id
    name
    organismId
    keys {
      id
      name
      key
      type
      enabled
      expirationDate
    }
    description
    organism {
      ...lightOrganismContent
    }
  }
  ${LIGHT_ORGANISM_FRAGMENT}
`

export const GET_THIRD_PARTY_APPLICATIONS_QUERY = gql`
  query thirdPartyApplications($filters: ThirdPartyApplicationsFilters) {
    thirdPartyApplications(filters: $filters) {
      thirdPartyApplications {
        ...thirdPartyApplicationContent
      }
      totalCount
    }
  }
  ${THIRD_PARTY_APPLICATION_FRAGMENT}
`

export const CREATE_THIRD_PARTY_APPLICATION_MUTATION = gql`
  mutation createThirdPartyApplication($input: CreateThirdPartyApplicationInput!) {
    createThirdPartyApplication(input: $input) {
      ...thirdPartyApplicationContent
    }
  }
  ${THIRD_PARTY_APPLICATION_FRAGMENT}
`

export const UPDATE_APPLICATION_MUTATION = gql`
  mutation updateThirdPartyApplication($input: UpdateThirdPartyApplicationInput!) {
    updateThirdPartyApplication(input: $input) {
      ...thirdPartyApplicationContent
    }
  }
  ${THIRD_PARTY_APPLICATION_FRAGMENT}
`

export const DELETE_APPLICATION_MUTATION = gql`
  mutation deleteThirdPartyApplication($id: ID!) {
    deleteThirdPartyApplication(id: $id) {
      id
    }
  }
`
