import { ApolloClient } from '@apollo/client'
import { cast, Instance, SnapshotOut, types } from 'mobx-state-tree'

import { login, logout } from 'services/api/auth'
import { ME_QUERY, Permission, User, UserRoleId } from 'services/api/graphql'
import I18nError from 'tools/I18nError'

const fetchUser = async (client: ApolloClient<unknown>, token?: string): Promise<User> => {
  // Fetch /me
  const result = await client.query({
    query: ME_QUERY,
    fetchPolicy: 'network-only',
    ...(token
      ? {
          context: {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        }
      : {}),
  })
  const user = result.data && result.data.me
  return user
}

export const PermissionsMapModel = types.map(types.boolean)

const OrganismPermissionsModel = types.model('OrganismPermissions', {
  organismId: types.string,
  permissions: types.array(types.string),
})
const UserRightModel = types.model('UserRight', {
  generalPermissions: types.array(types.string),
  organismsPermissions: types.array(OrganismPermissionsModel),
})

const UserModel = types
  .model('User', {
    id: types.string,
    email: types.string,
    firstName: types.string,
    lastName: types.string,
    roleIds: types.array(types.string),
    organismIds: types.array(types.string),
    permissions: UserRightModel,
    avatarUrl: types.optional(types.maybeNull(types.string), null),
  })
  .views(self => ({
    get mergedPermissions(): Permission[] {
      return [
        ...new Set([
          ...(self.permissions.generalPermissions as Permission[]),
          ...self.permissions.organismsPermissions.reduce(
            (result: Permission[], orgaPermissions) => [...result, ...(orgaPermissions.permissions as Permission[])],
            [],
          ),
        ]),
      ]
    },
    get userRoleIds(): UserRoleId[] {
      return self.roleIds as UserRoleId[]
    },
  }))

export type IUser = Instance<typeof UserModel>
export type IUserRight = Instance<typeof UserRightModel>
export type IOrganismPermissions = Instance<typeof OrganismPermissionsModel>

export const CurrentUserModel = types
  .model('Auth', {
    // user: types.maybeNull(User),
    authToken: types.optional(types.maybeNull(types.string), null),
    user: types.optional(types.maybeNull(UserModel), null),
  })
  .actions(self => ({
    setToken: (token: string | null) => {
      self.authToken = token
    },
    setUserAndAuth: async (user: User | null, authToken: string | null) => {
      if (!user) {
        self.user = null
        self.authToken = null
        return
      }
      // const permissionsMap: PermissionsMap = _.mapValues(_.mapKeys(permissions, permission => permission), _value => true)
      const finalUser: IUser = {
        id: user.id,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        roleIds: cast(user.roleIds || []),
        organismIds: cast(user.organismIds || []),
        permissions: {
          generalPermissions: cast(user.allPermissions.generalPermissions),
          organismsPermissions: cast(
            user.allPermissions.organismsPermissions.map(
              ({ organismId, permissions }): IOrganismPermissions => ({
                organismId,
                permissions: cast(permissions),
              }),
            ),
          ),
        },
        mergedPermissions: [], // view
        userRoleIds: [], // view
        avatarUrl: user.avatarUrl || null,
      }
      self.user = finalUser
      self.authToken = authToken
    },
  }))
  .actions(self => ({
    _login: async (client: ApolloClient<unknown>, email: string, password: string) => {
      const { token } = await login(email, password)
      // now fetch user
      const user = await fetchUser(client, token)

      // Check if user have authorized roles
      const allowed = user.allPermissions.generalPermissions.includes(Permission.BoAccess) || process.env.REACT_APP_TEST
      if (!allowed) {
        throw new I18nError('Role not allowed for backoffice', 'login.no_allowed_role')
      }

      self.setUserAndAuth(user, token)
    },
    _logout: async () => {
      if (self.authToken) {
        const authToken = self.authToken
        self.setUserAndAuth(null, null)
        await logout(authToken)
      }
    },
    setupAuth: async (client: ApolloClient<unknown>) => {
      if (self.authToken) {
        // Refresh user
        const user = await fetchUser(client)

        self.setUserAndAuth(user, self.authToken)
      }
    },
  }))
  .actions(self => ({
    refreshMe: async (client: ApolloClient<unknown>) => {
      self.setupAuth(client)
    },
  }))
  .views(self => ({
    get loggedUser() {
      return self.user
    },
    get isLogged() {
      return !!self.authToken && !!self.user
    },
    /**
     * Return true if a user has a permission granted
     * @param permission
     * @param organismId checks if the permission is granted for this organism
     * @param strict if true, and no organismId is provided, permissions must be granted generally
     */
    can(permission: Permission, organismId?: string, strict?: boolean): boolean {
      if (!self.user) return false

      if (!organismId) {
        if (strict) {
          return self.user.permissions.generalPermissions.includes(permission)
        }
        return self.user.mergedPermissions.includes(permission)
      }

      return (
        self.user.permissions.generalPermissions.includes(permission) ||
        self.user.permissions.organismsPermissions.findIndex(
          ({ organismId: pOrgId, permissions }) => pOrgId === organismId && permissions.includes(permission),
        ) > -1
      )
    },
    getOrganismIdsForPermission(permission: Permission): string[] {
      if (!self.user) return []

      return self.user.permissions.organismsPermissions.reduce(
        (allowedOrganismIds: string[], { organismId, permissions }) => [
          ...allowedOrganismIds,
          ...(permissions.includes(permission) ? [organismId] : []),
        ],
        [],
      )
    },
    /* Return true only if user can select "noOrganism" (admin and caster only) */
    get isAdmin(): boolean {
      return (
        (self.user &&
          self.user.roleIds.findIndex(roleId =>
            [UserRoleId.Admin, UserRoleId.SuperAdmin].includes(roleId as UserRoleId),
          ) > -1) ||
        false
      )
    },
  }))

export type CurrentUser = Instance<typeof CurrentUserModel>

export type CurrentUserSnapshot = SnapshotOut<typeof CurrentUserModel>
