import { SelectedTranslationsType } from 'Components/Form/SelectLocalInput'
import { graphql, ResultOf } from 'gql'
import { isNil } from 'lodash'
import {
  CacUserAuthenticationRule,
  ContentPushStreamServiceName,
  PublicationStatus,
  SportEventItem,
  SportEventItemUpdateInput,
  SportItem,
  SportItemContentType,
  UpdateSportItemInput,
  VideoContentType,
} from 'services/api/graphql'
import { getTranslationInputFromForm, getTranslationInputFromValues } from 'tools/translationTools'

import { SportEventItemFormValues } from './SportEventItemFieldSet'
import { SportItemFormValues } from './SportItemForm'

export const SportItemSportEvent_APIToFormValues = ({
  startDate,
  stopDate,
  testDate,
  publishAutoreplay,
}: SportEventItem): SportEventItemFormValues => {
  return {
    startDate: new Date(startDate),
    stopDate: new Date(stopDate),
    testDate: testDate ? new Date(testDate) : new Date(),
    publishAutoreplay,
    passthrough: false,
  }
}
export const SportItemSportEvent_FormValuesAPI_Update = (
  sportEvent: SportEventItemFormValues,
): SportEventItemUpdateInput => {
  const startDate = sportEvent.startDate
  const stopDate = sportEvent.stopDate
  const testDate = sportEvent.testDate

  if (testDate) testDate.setSeconds(0)
  startDate.setSeconds(0)
  stopDate.setSeconds(0)

  return {
    ...sportEvent,
    startDate,
    stopDate,
    testDate: new Date(testDate),
  }
}

const ContentTranslationFragment = graphql(`
  fragment contentTranslation on ContentTranslation @_unmask {
    locale
    content
  }
`)

export const SportItemFragment = graphql(
  `
    fragment sportItem on SportItem @_unmask {
      id
      title
      titleT {
        ...contentTranslation
      }
      subtitleT {
        ...contentTranslation
      }
      descriptionT {
        ...contentTranslation
      }
      sportItemSetId
      videoContentType
      coverImageFileId
      defaultLocale
      publicationStatus
      contentType
      cac {
        userAuthenticationRule
      }
      matureContent
      sportEventItem {
        testDate
        startDate
        stopDate
        publishAutoreplay
      }
      reverseRestriction
      modeRadio
      contentPushServiceNamesExcluded
    }
  `,
  [ContentTranslationFragment],
)

export const SportItem_APIToFormValues = ({
  id,
  publicationStatus,
  coverImageFileId,
  videoContentType,
  contentType,
  matureContent,
  sportEventItem,
  reverseRestriction,
  cac,
  contentPushServiceNamesExcluded,
  modeRadio,
  ...sportItem
}: ResultOf<typeof SportItemFragment>): SportItemFormValues => {
  return {
    ...getTranslationInputFromValues<Partial<SportItem>>({
      titleT: sportItem.titleT,
      subtitleT: sportItem.subtitleT,
      descriptionT: sportItem.descriptionT || undefined,
    }),
    id,
    defaultLocale: sportItem.defaultLocale || undefined,
    publicationStatus: publicationStatus as PublicationStatus,
    coverImageFileId,
    videoContentType: videoContentType as VideoContentType,
    contentType: contentType as SportItemContentType,
    cac: {
      userAuthenticationRule: (cac?.userAuthenticationRule as CacUserAuthenticationRule) ?? undefined,
    },
    matureContent,
    sportEventItem: sportEventItem && SportItemSportEvent_APIToFormValues(sportEventItem),
    reverseRestriction: !isNil(reverseRestriction) ? reverseRestriction : undefined,
    modeRadio: modeRadio || false,
    contentPushServiceNamesExcluded: (contentPushServiceNamesExcluded as ContentPushStreamServiceName[]) || undefined,
  }
}

export const SportItem_FormValuesToAPI_Update = (
  {
    id,
    titleT,
    subtitleT,
    descriptionT,
    publicationStatus,
    coverImageFileId,
    videoContentType,
    contentType,
    matureContent,
    sportEventItem,
    reverseRestriction,
    defaultLocale,
    cac,
    chatActivated,
    contentPushServiceNamesExcluded,
    modeRadio,
  }: SportItemFormValues,
  selectedTranslations: SelectedTranslationsType[],
): UpdateSportItemInput => {
  if (isNil(id)) {
    throw new Error(`No id`)
  }

  return {
    id,
    titleT: getTranslationInputFromForm(selectedTranslations, titleT),
    subtitleT: getTranslationInputFromForm(selectedTranslations, subtitleT),
    descriptionT: getTranslationInputFromForm(selectedTranslations, descriptionT),
    title: (defaultLocale && titleT?.[defaultLocale]) || '',
    subtitle: (defaultLocale && subtitleT?.[defaultLocale]) || '',
    description: (defaultLocale && descriptionT?.[defaultLocale]) || '',
    videoContentType,
    contentType,
    cac: {
      userAuthenticationRule: cac?.userAuthenticationRule || undefined,
    },
    publicationStatus,
    coverImageFileId,
    sportEventItem: sportEventItem && SportItemSportEvent_FormValuesAPI_Update(sportEventItem),
    matureContent,
    reverseRestriction,
    chatActivated,
    contentPushServiceNamesExcluded: contentPushServiceNamesExcluded || undefined,
    modeRadio,
  }
}
