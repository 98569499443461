import RefreshButton from 'Components/Button/RefreshButton'
import Loader from 'Components/Loader'
import PageHeader from 'Components/PageHeader'
import { SVEBroadcastsTable } from 'Containers/SVEBroadcastsTable'
import React, { FC, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { Navigate, useParams } from 'react-router-dom'
import { Grid } from 'semantic-ui-react'
import { SveChannel, UserRoleId, useSveChannelWithBroadcastsQuery } from 'services/api/graphql'
import { notifyError } from 'tools/toaster'

export interface UserRoleIconsProps {
  roleIds: UserRoleId[]
}

export interface SVEChannelViewCoreProps {
  sveChannelId: SveChannel['id']
}
export const SVEChannelViewCore: FC<SVEChannelViewCoreProps> = ({ sveChannelId }) => {
  const { data, error, refetch, loading } = useSveChannelWithBroadcastsQuery({
    variables: { channelId: sveChannelId },
    fetchPolicy: 'cache-and-network',
  })

  const sveChannel = data?.sveChannel
  const sveBroadcasts = sveChannel?.broadcasts ?? []

  useEffect(() => {
    if (error) notifyError(<FormattedMessage id="error.general" />, error)
  }, [error])

  return (
    <Loader loading={loading}>
      <Grid padded>
        <Grid.Column>
          <PageHeader
            title={
              <FormattedMessage
                id={`sveBroadcasts.management`}
                values={{ title: sveChannel?.name, id: sveChannel?.id }}
              />
            }
          >
            <RefreshButton onClick={() => refetch()} loading={loading} />
          </PageHeader>

          <SVEBroadcastsTable sveBroadcasts={sveBroadcasts} />
        </Grid.Column>
      </Grid>
    </Loader>
  )
}

export const SVEChannelView: FC = () => {
  const { sveChannelId } = useParams<{ sveChannelId: string }>()
  return sveChannelId ? <SVEChannelViewCore sveChannelId={sveChannelId} /> : <Navigate to="/dashboard" />
}
