import DownloadButton from 'Components/Button/DownloadButton'
import InvisibleButton from 'Components/Button/InvisibleButton'
import VideoPlayerModal from 'Components/VideoPlayer/VideoPlayerModal'
import ConfirmationButton from 'Containers/Button/ConfirmationButton'
import SelectFile from 'Containers/SelectFile'
import { CreateExternalFeedVideoclipForm } from 'Forms/Media/CreateExternalFeedVideoclipForm'
import MediaInfoForm, { MediaInfoFormData } from 'Forms/Media/MediaInfoForm'
import { useUpdateMedia, useUpdateSportItem } from 'models'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl'
import { Button, Checkbox, Header, Icon, Table } from 'semantic-ui-react'
import {
  Media,
  MediaContentFragment,
  MediaType,
  Permission,
  PublicationStatus,
  SportItemContentFragment,
  SportItemSetType,
  VideoClipStatus,
  VideoEngine,
} from 'services/api/graphql'
import { useCurrentUser } from 'stores'
import { notifyError, notifySuccess } from 'tools/toaster'

import VideoErrorPopup from '../../VideoErrorPopup'

import VideoClipDetailsModal from './VideoClipDetailsModal'

type SportItemVideoClipsTableProps = {
  sportItem: SportItemContentFragment
  sportItemSetType?: SportItemSetType
  videoEngine?: VideoEngine
  refresh: () => void
  onLoading: (loading: boolean) => void
}

const SportItemVideoClipsTable: React.FC<SportItemVideoClipsTableProps> = ({
  sportItem,
  videoEngine,
  sportItemSetType,
  refresh,
  onLoading,
}) => {
  const intl = useIntl()

  const currentUser = useCurrentUser()
  const [selectedVideo, setSelectedVideo] = useState<MediaContentFragment | null>(null)
  const [showModalDetails, setShowModalDetails] = useState(false)
  const [playerOpen, setPlayerOpen] = useState(false)
  const [addFileVideoClipModal, setAddFileVideoClipModal] = useState(false)
  const [addEmbedVideoClipModal, setAddEmbedVideoClipModal] = useState(false)
  const [editedVideo, setEditedVideo] = useState<MediaInfoFormData>()
  const [editedMediaId, setEditedMediaId] = useState<string>('')
  const [showModalEditVideo, setShowModalEditVideo] = useState(false)

  const [updateSportItem, { loading: loadingUpdateSportItem }] = useUpdateSportItem()
  const [updateMedia, { loading: loadingUpdateMedia }] = useUpdateMedia()

  const videos = useMemo(
    () => (sportItem.medias || []).filter(media => media.type === MediaType.VideoClip),
    [sportItem.medias],
  )
  const openVideoPlayer = useCallback((media: MediaContentFragment) => {
    setSelectedVideo(media)
    setPlayerOpen(true)
  }, [])

  // Open modal to show clip details
  const displayClipDetails = useCallback((media: MediaContentFragment) => {
    setSelectedVideo(media)
    setShowModalDetails(true)
  }, [])

  const onDeleteVideo = useCallback(
    (deletedVideo: MediaContentFragment) =>
      updateSportItem({
        variables: {
          input: {
            id: sportItem.id,
            mediasIds: sportItem.mediasIds.filter(mediaId => mediaId !== deletedVideo.id),
          },
        },
      }),
    [sportItem.id, sportItem.mediasIds, updateSportItem],
  )

  const addVideoClip = useCallback(
    async (media: Media) => {
      setAddFileVideoClipModal(false)
      if (!sportItem.mediasIds.includes(media.id)) {
        await updateSportItem({
          variables: {
            input: {
              id: sportItem.id,
              mediasIds: [...sportItem.mediasIds, media.id],
            },
          },
        })
        notifySuccess(intl.formatMessage({ id: 'sportItem.media_added' }))
        refresh()
      } else {
        notifyError(intl.formatMessage({ id: 'sportItem.media_already_exists' }))
      }
    },
    [sportItem.mediasIds, sportItem.id, updateSportItem, intl, refresh],
  )

  const onPublishVideo = useCallback(
    async (videoClip: MediaContentFragment) => {
      await updateMedia({
        variables: {
          mediaId: videoClip.id,
          input: {
            publicationStatus:
              videoClip.publicationStatus === PublicationStatus.Published
                ? PublicationStatus.Draft
                : PublicationStatus.Published,
          },
        },
      })
      refresh()
    },
    [refresh, updateMedia],
  )

  const openEditVideoModal = useCallback((video: MediaContentFragment) => {
    const { id, title, date, thumbnailId, videoClip } = video
    if (videoClip) {
      setEditedMediaId(id)
      setEditedVideo({
        id: videoClip.id,
        title,
        date: date || undefined,
        thumbnailId: thumbnailId || undefined,
        captions: videoClip?.captions?.map(caption => ({
          title: caption.title,
          language: caption.language,
          srcFileId: caption.srcFileId,
        })),
      })
      setShowModalEditVideo(true)
    }
  }, [])

  const onEditVideoClip = useCallback(
    async ({ id, title, date, thumbnailId }: MediaInfoFormData) => {
      setShowModalEditVideo(false)
      if (id && editedMediaId) {
        try {
          await updateMedia({
            variables: {
              mediaId: editedMediaId,
              input: { title, date, thumbnailId },
            },
          })

          notifySuccess(intl.formatMessage({ id: 'sportItem.media_updated' }))
          refresh()
        } catch (e) {
          notifyError(intl.formatMessage({ id: 'sportItem.update_media_error' }))
        }
      }
    },
    [editedMediaId, intl, refresh, updateMedia],
  )

  useEffect(() => {
    onLoading(loadingUpdateMedia || loadingUpdateSportItem)
  }, [loadingUpdateMedia, loadingUpdateSportItem, onLoading])

  const showCreateExternalFeedClip = useMemo(
    () => videoEngine === VideoEngine.ExternalFeed || sportItemSetType === SportItemSetType.Playlist,
    [sportItemSetType, videoEngine],
  )
  return (
    <>
      <div className="flex flex-row justify-between items-center">
        <Header as="h2">
          <FormattedMessage id="sportItemMedias.videos" />
        </Header>
        {currentUser.can(Permission.VideoClipCreate) && (
          <div className="space-x-2">
            {showCreateExternalFeedClip && (
              <Button color="blue" onClick={() => setAddEmbedVideoClipModal(true)}>
                <FormattedMessage id="sportItem.externalFeed.videoClip.create" />
              </Button>
            )}
            <Button color="blue" onClick={() => setAddFileVideoClipModal(true)}>
              <FormattedMessage id="selectFile.add" />
            </Button>
          </div>
        )}
      </div>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={5}>
              <FormattedMessage id="sportItemMedias.name" />
            </Table.HeaderCell>
            <Table.HeaderCell width={3}>
              <FormattedMessage id="sportItemMedias.date" />
            </Table.HeaderCell>
            <Table.HeaderCell width={3}>
              <FormattedMessage id="sportItemMedias.published" />
            </Table.HeaderCell>
            <Table.HeaderCell width={2} />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {videos.map(videoMedia => (
            <Table.Row key={videoMedia.id}>
              <Table.Cell>{videoMedia.title}</Table.Cell>
              <Table.Cell>
                <FormattedDate
                  day="2-digit"
                  month="2-digit"
                  year="numeric"
                  hour="2-digit"
                  minute="2-digit"
                  value={videoMedia.date ? new Date(videoMedia.date) : ''}
                />
              </Table.Cell>
              <Table.Cell>
                <Checkbox
                  toggle
                  checked={videoMedia.publicationStatus === PublicationStatus.Published}
                  onChange={() => onPublishVideo(videoMedia)}
                />
              </Table.Cell>
              <Table.Cell textAlign="right">
                {videoMedia.videoClip && videoMedia.videoClip.status === VideoClipStatus.Available && (
                  <>
                    <InvisibleButton onClick={() => videoMedia.videoClip && displayClipDetails(videoMedia)}>
                      <Icon name="info circle" />
                    </InvisibleButton>
                    <InvisibleButton onClick={() => videoMedia.videoClip && openVideoPlayer(videoMedia)}>
                      <Icon name="eye" />
                    </InvisibleButton>
                    {!videoMedia?.videoClip?.externalFeed && (
                      <DownloadButton source={videoMedia.videoClip.downloadLink} name={videoMedia.title} />
                    )}
                  </>
                )}

                {videoMedia.videoClip &&
                  (videoMedia.videoClip.status === VideoClipStatus.Uploaded ||
                    videoMedia.videoClip.status === VideoClipStatus.Encoding) && (
                    <p className="mr-3 font-bold text-green-500">
                      <FormattedMessage id="common.encoding" />
                    </p>
                  )}
                {videoMedia.videoClip && videoMedia.videoClip.status === VideoClipStatus.Error && (
                  <p className="mr-3 font-bold text-red-500">
                    <VideoErrorPopup error={videoMedia.videoClip.error} />
                  </p>
                )}
                {currentUser.can(Permission.LiveStreamUpdate) && (
                  <InvisibleButton onClick={() => openEditVideoModal(videoMedia)}>
                    <Icon name="edit" />
                  </InvisibleButton>
                )}
                <ConfirmationButton
                  action={() => onDeleteVideo(videoMedia)}
                  onDone={refresh}
                  successText={intl.formatMessage({
                    id: 'video.delete_success',
                  })}
                  confirmText={intl.formatMessage(
                    {
                      id: 'video.delete',
                    },
                    {
                      title: videoMedia.title,
                    },
                  )}
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      {/* Video player Preview modal */}
      <VideoPlayerModal
        open={playerOpen}
        onRefresh={refresh}
        isEmbed={!!selectedVideo?.videoClip?.externalFeed}
        showEditTrim={!selectedVideo?.videoClip?.externalFeed}
        showEditMarkers={!selectedVideo?.videoClip?.externalFeed}
        mediaToUpdate={selectedVideo}
        onClose={() => {
          setPlayerOpen(false)
        }}
        url={selectedVideo?.videoClip?.playbackUrl || ''}
        videoContentType={sportItem.videoContentType}
      />

      {/* Add video clip Player Embed  */}
      <CreateExternalFeedVideoclipForm
        open={addEmbedVideoClipModal}
        onSubmit={() => {
          setAddEmbedVideoClipModal(false)
          refresh()
        }}
        onCancel={() => setAddEmbedVideoClipModal(false)}
        sportItem={sportItem}
      />

      {/* Add  VOD modal  */}
      <SelectFile
        open={addFileVideoClipModal}
        type={MediaType.VideoClip}
        onCancel={() => setAddFileVideoClipModal(false)}
        onDone={addVideoClip}
      />

      {/* Edit Details modal */}
      <MediaInfoForm
        showCaptions={!selectedVideo?.videoClip?.externalFeed}
        open={showModalEditVideo}
        value={editedVideo}
        onSubmit={onEditVideoClip}
        onCancel={() => setShowModalEditVideo(false)}
        type={MediaType.VideoClip}
        header={intl.formatMessage({ id: 'mediaInfo.update.videoClip' })}
      />

      {/* Information Details modal */}
      {selectedVideo && (
        <VideoClipDetailsModal
          open={showModalDetails}
          media={selectedVideo}
          onClose={() => setShowModalDetails(false)}
        />
      )}
    </>
  )
}

export default SportItemVideoClipsTable
