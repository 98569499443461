import { gql } from '@apollo/client'

const DEFAULT_CONFIG_FRAGMENT = gql`
  fragment defaultConfigContent on DefaultConfig {
    sportItemDefaultCover
    liveStreamDefaultDuration
    liveDefaultTemplate
    clipDefaultTemplate
    androidMinVersion
    appleMinVersion
    sveChannelDelayHour {
      mounting
      unmounting
    }
    emailingConfig {
      startliveStream
      startliveStreamTest
      endliveStream
    }
    videoGridUrl
  }
`

export const GET_DEFAULT_CONFIG_QUERY = gql`
  query defaultConfig {
    defaultConfig {
      ...defaultConfigContent
    }
  }

  ${DEFAULT_CONFIG_FRAGMENT}
`

export const INIT_DEFAULT_CONFIG_MUTATION = gql`
  mutation initDefaultConfig($input: InitDefaultConfigInput!) {
    initDefaultConfig(input: $input) {
      ...defaultConfigContent
    }
  }

  ${DEFAULT_CONFIG_FRAGMENT}
`

export const UPDATE_DEFAULT_CONFIG_MUTATION = gql`
  mutation updateDefaultConfig($input: UpdateDefaultConfigInput!) {
    updateDefaultConfig(input: $input) {
      ...defaultConfigContent
    }
  }

  ${DEFAULT_CONFIG_FRAGMENT}
`
