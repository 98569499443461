import React, { useRef } from 'react'
import styled from 'styled-components'
import { notifySuccess } from 'tools/toaster'

import InvisibleButton from './Button/InvisibleButton'

const InvisibleInput = styled.input`
  position: absolute;
  left: -9999px;
`

interface CopyButtonProps {
  value: string
  notificationSuccess?: string
}

const CopyButton: React.FC<CopyButtonProps> = ({ value, notificationSuccess, children }) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const copy = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (inputRef.current) {
      inputRef.current.select()
      document.execCommand('copy')
      e.currentTarget.focus()
      notifySuccess(notificationSuccess || `Text copied in clipboard`)
    }
  }

  return (
    <>
      <InvisibleButton onClick={copy}>{children}</InvisibleButton>
      <InvisibleInput tabIndex={-1} value={value} ref={inputRef} readOnly />
    </>
  )
}

export default CopyButton
