import { useEffect, useState } from 'react'
import { getFile } from 'tools/file'

export type UseFetchFile = {
  blob: Blob | undefined
  loading: boolean
  error: Error | undefined
}
export const useFetchFile = (url: string, filename?: string) => {
  const [file, setFile] = useState<File>()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<Error>()

  useEffect(() => {
    let cancel = false

    fetch(url, { mode: 'cors', cache: 'reload' })
      .then(response => response.blob())
      .then(resBlob => !cancel && setFile(getFile(resBlob, filename)))
      .catch(setError)
      .finally(() => setLoading(false))

    return () => {
      cancel = true
    }
  }, [filename, url])
  return { blob: file, loading, error }
}
