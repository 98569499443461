import { convertToDropdownOptions } from 'Forms/utils'
import React, { FC, useMemo } from 'react'
import { Field } from 'react-final-form'
import { DropdownItemProps, Form as UiForm, Table } from 'semantic-ui-react'
import { SportItemSet } from 'services/api/graphql'

import { FieldsType } from '../EditedCategoryFormFields'

interface Props {
  index: number
  name: string
  fields: FieldsType[]
  sportItemSets?: SportItemSet[]
  onChangeFields: (index: number, item: FieldsType) => void
}

export const SportItemSetItem: FC<Props> = ({ index, name, sportItemSets, fields, onChangeFields }) => {
  const sportItemSetOptions = useMemo(() => sportItemSets?.map(convertToDropdownOptions), [sportItemSets])

  const sportItemOptions: DropdownItemProps[] = useMemo(() => {
    const sportItemSetId = fields[index]?.sportItemSetId
    const sportItemSet = sportItemSets?.find(item => sportItemSetId === item.id)
    return sportItemSet?.items?.map(item => item && convertToDropdownOptions(item)) ?? []
  }, [fields, index, sportItemSets])

  return (
    <>
      <Table.Cell>
        <Field
          name={`${name}.sportItemSetId`}
          render={({ input }) => {
            return (
              <UiForm.Dropdown
                name={input.name}
                selection
                search
                onChange={(e, { value }) =>
                  onChangeFields(index, { ...fields[index], sportItemSetId: value?.toString() })
                }
                options={sportItemSetOptions}
                value={fields[index].sportItemSetId || undefined}
              />
            )
          }}
        />
      </Table.Cell>
      <Table.Cell>
        <Field
          name={`${name}.sportItemId`}
          render={({ input }) => (
            <UiForm.Dropdown
              name={input.name}
              selection
              search
              disabled={sportItemOptions.length <= 0}
              clearable
              onChange={(e, { value }) => onChangeFields(index, { ...fields[index], sportItemId: value?.toString() })}
              options={sportItemOptions}
              value={fields[index].sportItemId || undefined}
            />
          )}
        />
      </Table.Cell>
    </>
  )
}
