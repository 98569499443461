import React from 'react'

import { Header } from 'semantic-ui-react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`
const Buttons = styled.div`
  display: flex;
  align-items: center;
  & > :not(:last-child) {
    margin-right: 8px;
  }
`

export interface TablePageHeaderProps {
  title: React.ReactNode
  children?: React.ReactNode
}

const PageHeader = ({ title, children }: TablePageHeaderProps) => (
  <Container>
    <Header as="h1">{title}</Header>
    <Buttons>{children}</Buttons>
  </Container>
)

export default PageHeader
