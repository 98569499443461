import { gql } from '@apollo/client'

import { SVEBROADCAST_FRAGMENT, SVELIVE_MONITORING_FRAGMENT } from './SportallVideoEngine/fragments'

export const LIVESTREAM_FRAGMENT = gql`
  fragment liveStreamContent on LiveStream {
    id
    playbackUrl(expirationInSeconds: 21600)
    ingestUrl
    wildmokaEventID
    videoEngine
    state
    # Wildmoka Video Engine
    streamId
    event {
      id
      name
      description
      testStartDate
      editoStartDate
      editoStopDate
      stopDate
      state
      notifEmail
      clipCreationAtEnd
      clipPublicationAtEnd
      stream {
        id
        name
        tags
      }
      live {
        id
        publishStatus
        type
        template {
          id
          name
          type
        }
        autoAnnounceDate
      }
      livePublication
      clipTemplate {
        id
        name
        type
      }
    }
    # Sportall Video Engine
    sveBroadcastId
    sveBroadcast {
      ...sveBroadcastContent
    }
    sveChannelId
    sveLiveMonitoring {
      ...sveLiveMonitoringContent
    }
    #Player Embed Video Engine
    externalFeedProvider
    externalFeedUrl
  }
  ${SVELIVE_MONITORING_FRAGMENT}
  ${SVEBROADCAST_FRAGMENT}
`

export const UPDATE_LIVESTREAM_MUTATION = gql`
  mutation updateLiveStream($input: UpdateWildmokaLiveStreamInput!) {
    updateLiveStream(input: $input) {
      ...liveStreamContent
    }
  }

  ${LIVESTREAM_FRAGMENT}
`

export const UPDATE_LIVESTREAM_STATE_MUTATION = gql`
  mutation updateLiveStreamState($id: ID!, $state: WMEventState!) {
    updateLiveStreamState(id: $id, state: $state) {
      ...liveStreamContent
    }
  }

  ${LIVESTREAM_FRAGMENT}
`
export const SET_LIVESTREAM_STATE_MUTATION = gql`
  mutation setLiveStreamState($id: ID!, $state: LiveStreamState!) {
    setLiveStreamState(id: $id, state: $state) {
      ...liveStreamContent
    }
  }

  ${LIVESTREAM_FRAGMENT}
`

export const GET_ACTIVE_LIVESTREAMS_IDS_QUERY = gql`
  query activeLiveStreams {
    activeLiveStreams {
      liveStreams {
        id
      }
      sportItems {
        id
      }
    }
  }
`

export const LIVESTREAM_STREAM_CONFLICT_FRAGMENT = gql`
  fragment liveStreamStreamConflictContent on LiveStreamStreamConflict {
    liveStream {
      id
      sportItem {
        id
        title
        medias {
          id
          title
          liveStreamId
        }
      }
    }
  }
`

export const CHECK_STREAM_UNUSED_QUERY = gql`
  query checkStreamUnused($streamId: WildmokaID!, $sportItemSetId: ID!, $from: Date!, $to: Date!) {
    checkStreamUnused(streamId: $streamId, sportItemSetId: $sportItemSetId, from: $from, to: $to) {
      ...liveStreamStreamConflictContent
    }
  }
  ${LIVESTREAM_STREAM_CONFLICT_FRAGMENT}
`

export const GET_LIVESTREAM_PLAYBACKURL_QUERY = gql`
  query getLivestreamPlaybackUrl($liveStreamId: ID!, $expirationInSeconds: Int) {
    liveStream(id: $liveStreamId) {
      id
      playbackUrl(expirationInSeconds: $expirationInSeconds)
    }
  }
`

export const UPDATE_LIVESTREAM_STATES_CACHE_QUERY = gql`
  mutation updateLiveStreamStatesCache {
    updateLiveStreamStatesCache
  }
`
