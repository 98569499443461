import SAFormField from 'Components/Form/SAFormField'
import React, { FC } from 'react'
import { FieldRenderProps } from 'react-final-form'
import { Form } from 'semantic-ui-react'

import { AffiliateFieldType } from './AdCampaignFormFields'

import SelectAffiliateDisplayTypes from './SelectAffiliateDisplayTypes'

type Props = {
  onChangeFields: (index: number, item: AffiliateFieldType) => void
  index: number
  item: AffiliateFieldType
  indexHasButton: number
}

export const AffiliateDisplayType: FC<Props> = ({ index, onChangeFields, item, indexHasButton }) => (
  <SAFormField
    name={`displayTypes-${index}`}
    render={({ input, meta }: FieldRenderProps<string[], HTMLElement>) => (
      <Form.Field error={!!meta.touched && !!meta.error}>
        <SelectAffiliateDisplayTypes
          index={index}
          onChangeFields={onChangeFields}
          item={item}
          multiple
          indexHasButton={indexHasButton}
          {...input}
        />
      </Form.Field>
    )}
  />
)
