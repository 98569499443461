import NumberInput from 'Components/Form/NumberInput'
import SAFormField from 'Components/Form/SAFormField'
import FileInput from 'Containers/Form/FileInput'
import SelectWMTemplateInput from 'Containers/Form/SelectWMTemplateInput'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { Form as RFForm } from 'react-final-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button, Form, Segment } from 'semantic-ui-react'
import { InitDefaultConfigInput, MediaType, UpdateDefaultConfigInput } from 'services/api/graphql'
import { useTemplates } from 'stores'
import { validateRequired, validateUrl } from 'tools/formValidators'

interface DefaultConfigFormProps<T extends UpdateDefaultConfigInput | InitDefaultConfigInput> {
  onSubmit: (value: T) => void
  initialValues?: T
  loading?: boolean
}

function DefaultConfigForm<T extends UpdateDefaultConfigInput | InitDefaultConfigInput>({
  onSubmit,
  initialValues,
  loading,
}: DefaultConfigFormProps<T>) {
  const intl = useIntl()

  const templates = useTemplates()

  return (
    <RFForm onSubmit={onSubmit} initialValues={initialValues}>
      {formState => (
        <Form onSubmit={formState.handleSubmit} className="mt-8">
          <Segment color="grey">
            <div className="text-xl font-bold flex justify-center mb-8">
              {intl.formatMessage({
                id: 'defaultConfig.defaultTemplate',
              })}
            </div>
            <Form.Group widths="equal">
              <SAFormField
                name="liveDefaultTemplate"
                validate={validateRequired}
                render={({ input, meta }) => (
                  <Form.Field required error={!!meta.touched && !!meta.error}>
                    <label>
                      <FormattedMessage id="defaultConfig.liveDefaultTemplate" />
                    </label>
                    <SelectWMTemplateInput {...input} source={templates.live} loading={templates.loading} />
                  </Form.Field>
                )}
              />

              <SAFormField
                name="clipDefaultTemplate"
                validate={validateRequired}
                render={({ input, meta }) => (
                  <Form.Field required error={!!meta.touched && !!meta.error}>
                    <label>
                      <FormattedMessage id="defaultConfig.clipDefaultTemplate" />
                    </label>
                    <SelectWMTemplateInput {...input} source={templates.video} loading={templates.loading} />
                  </Form.Field>
                )}
              />
            </Form.Group>
          </Segment>
          <SAFormField
            name="liveStreamDefaultDuration"
            validate={validateRequired}
            render={({ input, meta }) => (
              <Form.Field
                required
                error={!!meta.touched && !!meta.error}
                label={intl.formatMessage({
                  id: 'defaultConfig.liveStreamDefaultDuration',
                })}
                control={NumberInput}
                {...input}
                value={Math.floor(input.value / 1000 / 60)}
                onChange={(value: number) => {
                  input.onChange(value * 60 * 1000)
                }}
              ></Form.Field>
            )}
          />
          <Segment color="grey" className="mt-8">
            <div className="text-xl font-bold flex justify-center mb-8">
              {intl.formatMessage({
                id: 'defaultConfig.emailingConfig',
              })}
            </div>
            <Form.Group widths="equal">
              <SAFormField
                name="emailingConfig.startliveStream"
                validate={validateRequired}
                render={({ input, meta }) => (
                  <Form.Field
                    required
                    error={!!meta.touched && !!meta.error}
                    label={intl.formatMessage({
                      id: 'defaultConfig.emailingConfig.dayBeforeStartliveStream',
                    })}
                    control={NumberInput}
                    {...input}
                  ></Form.Field>
                )}
              />
              <SAFormField
                name="emailingConfig.startliveStreamTest"
                validate={validateRequired}
                render={({ input, meta }) => (
                  <Form.Field
                    required
                    error={!!meta.touched && !!meta.error}
                    label={intl.formatMessage({
                      id: 'defaultConfig.emailingConfig.startliveStream',
                    })}
                    control={NumberInput}
                    {...input}
                  ></Form.Field>
                )}
              />
              <SAFormField
                name="emailingConfig.endliveStream"
                validate={validateRequired}
                render={({ input, meta }) => (
                  <Form.Field
                    required
                    error={!!meta.touched && !!meta.error}
                    label={intl.formatMessage({
                      id: 'defaultConfig.emailingConfig.endliveStream',
                    })}
                    control={NumberInput}
                    {...input}
                  ></Form.Field>
                )}
              />
            </Form.Group>
          </Segment>
          <Segment color="grey" className="mt-8">
            <div className="text-xl font-bold flex justify-center mb-8">
              {intl.formatMessage({
                id: 'defaultConfig.minVersion',
              })}
            </div>
            <Form.Group widths="equal">
              <SAFormField
                name="androidMinVersion"
                validate={validateRequired}
                render={({ input, meta }) => (
                  <Form.Input
                    required
                    error={!!meta.touched && !!meta.error}
                    label={intl.formatMessage({
                      id: 'defaultConfig.androidMinVersion',
                    })}
                    {...input}
                  ></Form.Input>
                )}
              />

              <SAFormField
                name="appleMinVersion"
                validate={validateRequired}
                render={({ input, meta }) => (
                  <Form.Input
                    required
                    error={!!meta.touched && !!meta.error}
                    label={intl.formatMessage({
                      id: 'defaultConfig.appleMinVersion',
                    })}
                    {...input}
                  ></Form.Input>
                )}
              />
            </Form.Group>
          </Segment>

          <Segment color="grey" className="mt-8">
            <div className="text-xl font-bold flex justify-center mb-8">
              {intl.formatMessage({
                id: 'defaultConfig.sveChannelDelayHour.title',
              })}
            </div>
            <Form.Group widths="equal">
              <SAFormField
                name="sveChannelDelayHour.mounting"
                validate={validateRequired}
                render={({ input, meta }) => (
                  <Form.Field
                    required
                    error={!!meta.touched && !!meta.error}
                    label={intl.formatMessage({
                      id: 'defaultConfig.sveChannelDelayHour.mounting',
                    })}
                    control={NumberInput}
                    {...input}
                  />
                )}
              />

              <SAFormField
                name="sveChannelDelayHour.unmounting"
                validate={validateRequired}
                render={({ input, meta }) => (
                  <Form.Field
                    required
                    error={!!meta.touched && !!meta.error}
                    label={intl.formatMessage({
                      id: 'defaultConfig.sveChannelDelayHour.unmounting',
                    })}
                    control={NumberInput}
                    {...input}
                  />
                )}
              />
            </Form.Group>
          </Segment>
          <SAFormField
            name="videoGridUrl"
            allowNull={true}
            validate={validateUrl}
            render={({ input, meta }) => (
              <Form.Input
                id="videoGridUrl"
                error={!!meta.touched && !!meta.error}
                label={intl.formatMessage({
                  id: 'defaultConfig.videoGridUrl',
                })}
                {...input}
              />
            )}
          />
          <SAFormField
            name="sportItemDefaultCover"
            validate={validateRequired}
            render={({ input, meta }) => (
              <Form.Field
                control={FileInput}
                {...input}
                type={MediaType.Image}
                required
                error={!!meta.touched && !!meta.error}
                label={intl.formatMessage({
                  id: 'defaultConfig.sportItemDefaultCover',
                })}
                description={intl.formatMessage({
                  id: 'defaultConfig.imageItemDescription',
                })}
                mimetype="image"
                imageConstraints={{
                  aspect: 3 / 2,
                  minWidth: 200,
                  minHeight: 150,
                  maxSize: 3000000,
                }}
                renderOrganismPicker
              />
            )}
          />

          <Button primary type="submit" disabled={formState.invalid || loading}>
            <FormattedMessage id="defaultConfig.submit" />
          </Button>
        </Form>
      )}
    </RFForm>
  )
}

// Mobx observer can not keep the generic props (see issue https://github.com/mobxjs/mobx-react-lite/issues/243)
export const InitDefaultConfigForm = observer<DefaultConfigFormProps<InitDefaultConfigInput>>(DefaultConfigForm)
export const UpdateDefaultConfigForm = observer<DefaultConfigFormProps<UpdateDefaultConfigInput>>(DefaultConfigForm)
