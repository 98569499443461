import { FormBaseProps } from 'Forms/FormBase'
import { useCreateUser } from 'models'
import React from 'react'
import { User } from 'services/api/graphql'

import UserForm from './UserForm'

interface CreateUserFormProps extends FormBaseProps<User> {}

const CreateUserForm = ({ onCancel, onDone, onError }: CreateUserFormProps) => {
  const [createUser, { loading }] = useCreateUser()
  return (
    <UserForm
      title={`Nouvel utilisateur`}
      edit={false}
      onSubmit={values => {
        createUser({ variables: { input: values } })
          .then(result => {
            if (result && result.data) onDone(result.data.createUser)
          })
          .catch(onError)
      }}
      onCancel={onCancel}
      loading={loading}
    />
  )
}

export default CreateUserForm
