import { ApolloClient } from '@apollo/client'
import localForage from 'localforage'
import { Instance, SnapshotOut, cast, types } from 'mobx-state-tree'
import { persist } from 'mst-persist'

import { Organism, UserRoleId } from 'services/api/graphql'

import { CurrentUserModel } from './CurrentUserStore'
import { DebugModel } from './Debug'
import { TemplatesModel } from './TemplateStore'

const OrganismModel = types.model({
  id: types.string,
  title: types.string,
})

export const RootStoreModel = types
  .model('Root', {
    currentUser: CurrentUserModel,
    organismId: types.maybeNull(types.string),
    organismTitles: types.optional(types.array(OrganismModel), []),
    lang: types.maybeNull(types.string),
    debug: types.optional(DebugModel, {}),
    templates: TemplatesModel,
  })
  .actions(self => ({
    _postLogin: () => {
      if (
        self.currentUser.loggedUser &&
        !self.currentUser.loggedUser.roleIds.includes(UserRoleId.Admin) &&
        self.currentUser.loggedUser.organismIds &&
        self.currentUser.loggedUser.organismIds.length > 0
      ) {
        // Automatically select first organism (except for admin)
        self.organismId = self.currentUser.loggedUser.organismIds[0]
      }
    },
    logout: async () => {
      self.organismId = null
      self.debug = DebugModel.create({})
      await self.currentUser._logout()
    },
  }))
  .actions(self => ({
    login: async (client: ApolloClient<unknown>, email: string, password: string) => {
      await self.currentUser._login(client, email, password)
      self._postLogin()
    },
    setOrganismId: (organismId: Organism['id'] | null) => {
      self.organismId = organismId || null
    },
    setLang: (lang: string) => {
      self.lang = lang
    },
    setOrganismTitles: (organisms: { id: string; title: string }[]) => (self.organismTitles = cast(organisms)),
    findOrganism: (id: string) => self.organismTitles.find(organism => organism.id === id),
  }))

export type RootStore = Instance<typeof RootStoreModel>

export type RootStoreSnapshot = SnapshotOut<typeof RootStoreModel>

export const createRootStore = (): RootStore =>
  RootStoreModel.create({
    currentUser: CurrentUserModel.create({}),
    organismId: null,
    lang: null,
    debug: DebugModel.create({}),
    templates: TemplatesModel.create({ loading: false }),
  })

export const setupRootStore = async (persis = true): Promise<RootStore> => {
  const rootStore = createRootStore()

  // localForage.clear()
  if (persis) {
    try {
      await persist('mst-store', rootStore, { storage: localForage })
    } catch (e) {
      console.error(e)
    }
  }

  await rootStore.debug.fetchVersions()

  return rootStore
}
