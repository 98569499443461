// eslint-disable-next-line @typescript-eslint/no-var-requires
require('dotenv').config()

import { FlagNameValues } from 'semantic-ui-react'

export type FirebaseConfig = {
  apiKey: string
  authDomain: string
  databaseURL: string
  projectId: string
  storageBucket: string
  messagingSenderId: string
  appId: string
  measurementId: string
}

export interface IConfig {
  tenantName: string
  appPlatform: string
  apiUrl: string
  availableLocalesOptions: { key: string; value: string; flag: FlagNameValues; text: string }[]
  pageSize: number
  firebaseConfig: FirebaseConfig
  webappUrl: string
  vodCaptionLangOptions: { key: string; value: string; text: string }[]
}

const getVarFromEnv = (name: string): unknown | undefined =>
  (window as any)?._env_?.[name] || process.env?.[name] || (window as any)?.[name]

const Config: IConfig = {
  tenantName: getVarFromEnv('REACT_APP_TENANT_NAME') as string,
  appPlatform: getVarFromEnv('REACT_APP_PLATFORM') as string,
  apiUrl: (getVarFromEnv('REACT_APP_API_URL') as string | undefined) || 'http://localhost:5000',
  availableLocalesOptions: [
    { key: 'fr', value: 'fr', flag: 'fr', text: 'Français' },
    { key: 'en', value: 'en', flag: 'uk', text: 'English' },
  ],
  pageSize: 20,
  firebaseConfig: {
    apiKey: getVarFromEnv('REACT_APP_FIREBASE_API_KEY') as string,
    authDomain: getVarFromEnv('REACT_APP_FIREBASE_AUTH_DOMAIN') as string,
    databaseURL: getVarFromEnv('REACT_APP_FIREBASE_DB_URL') as string,
    projectId: getVarFromEnv('REACT_APP_FIREBASE_PROJECT_ID') as string,
    storageBucket: getVarFromEnv('REACT_APP_FIREBASE_STORAGE_BUCKET') as string,
    messagingSenderId: getVarFromEnv('REACT_APP_FIREBASE_MSG_SENDER_ID') as string,
    appId: getVarFromEnv('REACT_APP_FIREBASE_APP_ID') as string,
    measurementId: getVarFromEnv('REACT_APP_FIREBASE_MEASUREMENT_ID') as string,
  },
  webappUrl: getVarFromEnv('REACT_APP_WEBAPP_URL') as string,
  vodCaptionLangOptions: [
    { key: 'QAA', value: 'QAA', text: 'QAA' },
    { key: 'FRA', value: 'FRA', text: 'Français (FRA)' },
    { key: 'ENG', value: 'ENG', text: 'English (ENG)' },
    { key: 'ITA', value: 'ITA', text: 'Italien (ITA)' },
    { key: 'DEU', value: 'DEU', text: 'Allemand (DEU)' },
    { key: 'SPA', value: 'SPA', text: 'Espagnol (SPA)' },
    { key: 'POR', value: 'POR', text: 'Portugais (POR)' },
    { key: 'NLD', value: 'NLD', text: 'Néerlandais (NLD)' },
    { key: 'DAN', value: 'DAN', text: 'Danois (DAN)' },
    { key: 'FIN', value: 'FIN', text: 'Finlandais (FIN)' },
    { key: 'NOR', value: 'NOR', text: 'Norvégien (NOR)' },
    { key: 'SWE', value: 'SWE', text: 'Suédois (SWE)' },
    { key: 'JPN', value: 'JPN', text: 'Japonais (JPN)' },
  ],
}

export default Config
