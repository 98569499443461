import SelectItemListInput from 'Components/Form/SelectItemListInput'
import React, { useMemo } from 'react'
import { Maybe, Permission } from 'services/api/graphql'

export interface SelectPermissionsInputProps {
  value: Permission[] | undefined
  onChange: (value: Maybe<Permission[]>) => void
  noSelectionEnabled?: boolean
  noSelectionTitle?: string
  disabled?: boolean
}

const allPermissions = (Object.keys(Permission) as Array<keyof typeof Permission>).map(key => Permission[key])

const SelectPermissionsInput = ({
  value,
  onChange,
  noSelectionEnabled,
  noSelectionTitle = 'Aucun',
  disabled,
}: SelectPermissionsInputProps) => {
  const options = useMemo(() => {
    return allPermissions.map(permission => ({
      key: permission,
      value: permission,
      text: permission,
    }))
  }, [])
  return (
    <SelectItemListInput
      value={value}
      options={options}
      multiple
      noSelectionEnabled={noSelectionEnabled}
      noSelectionTitle={noSelectionTitle}
      onChange={onChange}
      disabled={disabled}
    />
  )
}

export default SelectPermissionsInput
