import CheckboxInput from 'Components/Form/CheckboxInput'
import SAFormField from 'Components/Form/SAFormField'
import SelectLocalInput, { SelectedTranslationsType, TranslatedInput } from 'Components/Form/SelectLocalInput'
import FileInput from 'Containers/Form/FileInput'
import { SelectApplicationDesignInput } from 'Containers/Form/SelectApplicationDesignInput'
import { SelectEditCategoryInput } from 'Containers/Form/SelectEditCategoryInput'
import SelectFrontPageStatusInput from 'Containers/Form/SelectFrontPageInput'
import SelectPublicationStatusInput from 'Containers/Form/SelectPublicationStatusInput'
import { FormApi } from 'final-form'
import { useGetOrganism } from 'models'
import React, { FC, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Form } from 'semantic-ui-react'
import {
  Application,
  ApplicationDesign,
  ContentSet,
  CustomPage,
  CustomPageWidget,
  EditedCategory,
  ExternalWidgets,
  Media,
  MediaType,
  PublicationStatus,
  WidgetsType,
} from 'services/api/graphql'
import { useStore } from 'stores'
import { validateRequired } from 'tools/formValidators'
import { Nullable } from 'types/utils'

import CustomPageFormFieldsWidgets from './CustomPageFormFieldsWidgets'

export type CustomPagesFormValues = Nullable<{
  id: string
  application: Application
  applicationId?: string
  backgroundImage?: Media
  backgroundImageId?: Media['id']
  backgroundVideo?: Media
  backgroundVideoId?: Media['id']
  content: ContentSet[]
  frontPageId?: EditedCategory['id']
  icon?: Media
  iconId?: Media['id']
  published: PublicationStatus
  title: string
  titleT?: TranslatedInput | undefined
  widgets: CustomPageWidget[]
  showInNavigationHeader: boolean
  customPageApplicationDesignId: string
  defaultLocale: string
  isHomePage?: boolean
  widgetsType: WidgetsType
  externalWidgets?: Omit<ExternalWidgets, '__typename' | 'liveScoreWidgets'>
}>

type Props = {
  defaultLocale: string
  onChangeDefaultLocale: (value: string) => void
  selectedTranslations: SelectedTranslationsType[]
  onChangeSelectedTranslations: (newCountryTranslation: SelectedTranslationsType[]) => void
  onChangeActiveTranslation: (newActiveTranslation?: string) => void
  activeTranslation: string | undefined
  initialValues?: CustomPage
  values: CustomPagesFormValues
  onChange: FormApi<CustomPagesFormValues>['change']
}

const CustomFormFields: FC<Props> = ({
  defaultLocale,
  onChangeDefaultLocale,
  selectedTranslations,
  onChangeSelectedTranslations,
  onChangeActiveTranslation,
  activeTranslation,
  initialValues,
  values,
  onChange,
}) => {
  const intl = useIntl()
  const store = useStore()
  const organismId = store.organismId || ''
  const { organism } = useGetOrganism(organismId)
  const [selectedCustomPageApplicationDesignId, setSelectedCustomPageApplicationDesignId] = useState<
    ApplicationDesign['id'] | null
  >((initialValues && initialValues.customPageApplicationDesignId) || null)
  const [selectedCustomPageEditCategoryId, setSelectedCustomPageEditCategoryId] = useState<EditedCategory['id'] | null>(
    initialValues?.frontPageId || null,
  )
  const [hasFrontPageId, setHasFrontPageId] = useState<boolean>(!!(initialValues && initialValues?.frontPageId))

  useEffect(() => {
    if (!hasFrontPageId) {
      setSelectedCustomPageEditCategoryId(null)
    }
  }, [hasFrontPageId])

  return (
    <Form>
      <SAFormField
        name="locale"
        render={({ input }) => (
          <Form.Field
            {...input}
            disabled={false}
            label={intl.formatMessage({ id: 'locale' })}
            control={() => (
              <SelectLocalInput
                defaultLocale={defaultLocale}
                onChangeDefaultLocale={onChangeDefaultLocale}
                activeTranslation={activeTranslation}
                onChangeActiveTranslation={onChangeActiveTranslation}
                selectedTranslations={selectedTranslations}
                onChangeSelectedTranslations={onChangeSelectedTranslations}
              />
            )}
          />
        )}
      />
      <SAFormField
        name={`titleT.${activeTranslation}`}
        render={({ input, meta }) => (
          <Form.Input
            {...input}
            required={activeTranslation === defaultLocale}
            error={!!meta.touched && !!meta.error}
            label={intl.formatMessage({ id: 'customPage.title' })}
          />
        )}
      />
      <SAFormField
        name="published"
        validate={validateRequired}
        render={({ input, meta }) => (
          <Form.Field
            control={SelectPublicationStatusInput}
            {...input}
            error={!!meta.touched && !!meta.error}
            label={intl.formatMessage({ id: 'customPage.publicationStatus' })}
          />
        )}
      />
      <SAFormField
        name="customPageApplicationDesignId"
        render={({ input }) => (
          <Form.Field
            {...input}
            label={intl.formatMessage({ id: 'customPage.customPageApplicationDesignId' })}
            control={() => (
              <SelectApplicationDesignInput
                value={selectedCustomPageApplicationDesignId}
                organismId={organismId}
                onChange={applicationDesignId => {
                  setSelectedCustomPageApplicationDesignId(applicationDesignId)
                  input.onChange(applicationDesignId || null)
                }}
              />
            )}
          />
        )}
      />
      <div className="text-xl font-bold mb-4">
        <FormattedMessage id="customPage.inNavigation" />
      </div>

      <SAFormField
        name="showInNavigationHeader"
        render={({ input, meta }) => (
          <Form.Field error={!!meta.touched && !!meta.error}>
            <CheckboxInput
              {...input}
              checked={input.value !== false}
              label={
                <label>
                  <FormattedMessage id="customPage.showInNavigationHeader" />
                </label>
              }
            />
          </Form.Field>
        )}
      />
      <Form.Group>
        <SAFormField
          name="iconId"
          render={({ input, meta }) => (
            <Form.Field
              {...input}
              error={!!meta.touched && !!meta.error}
              label={intl.formatMessage({ id: 'mediaInfo.thumbnail' })}
              control={FileInput}
              type={MediaType.Image}
              renderOrganismPicker
              imageConstraints={{ maxSize: 3000000 }}
            ></Form.Field>
          )}
        />

        <SAFormField
          name="menuBannerImageId"
          render={({ input, meta }) => (
            <Form.Field
              control={FileInput}
              {...input}
              type={MediaType.Image}
              error={!!meta.touched && !!meta.error}
              label={intl.formatMessage({ id: 'customPage.menuBannerImageId' })}
              renderOrganismPicker
              imageConstraints={{ maxSize: 3000000, aspect: 16 / 9 }}
            />
          )}
        />
      </Form.Group>
      <div className="text-xl font-bold mb-4">
        <FormattedMessage id="customPage.frontPage" />
      </div>
      <div className="mb-4">
        <SelectFrontPageStatusInput
          value={hasFrontPageId}
          onChange={() => {
            const newHasFrontPageId = !hasFrontPageId
            setHasFrontPageId(newHasFrontPageId)
            if (newHasFrontPageId) {
              onChange('backgroundImageId', null)
              onChange('backgroundVideoId', null)
            }
            if (!newHasFrontPageId) {
              onChange('frontPageId', null)
            }
          }}
        />
      </div>
      {!hasFrontPageId && (
        <Form.Group>
          <SAFormField
            name="backgroundVideoId"
            render={({ input, meta }) => (
              <Form.Field
                control={FileInput}
                {...input}
                type={MediaType.VideoClip}
                error={!!meta.touched && !!meta.error}
                label={intl.formatMessage({ id: 'customPage.backgroundVideoId' })}
                renderOrganismPicker
              />
            )}
          />

          <SAFormField
            name="backgroundImageId"
            render={({ input, meta }) => (
              <Form.Field
                control={FileInput}
                {...input}
                type={MediaType.Image}
                error={!!meta.touched && !!meta.error}
                label={intl.formatMessage({ id: 'customPage.backgroundImageId' })}
                renderOrganismPicker
                imageConstraints={{ maxSize: 3000000 }}
              />
            )}
          />
        </Form.Group>
      )}

      {hasFrontPageId && (
        <SAFormField
          name="frontPageId"
          validate={validateRequired}
          render={({ input }) => (
            <Form.Field
              {...input}
              label={intl.formatMessage({ id: 'customPage.frontPage.editedCategory' })}
              required
              control={() => (
                <SelectEditCategoryInput
                  value={selectedCustomPageEditCategoryId}
                  organismId={organismId}
                  onChange={editCategoryId => {
                    setSelectedCustomPageEditCategoryId(editCategoryId)
                    input.onChange(editCategoryId || null)
                  }}
                />
              )}
            />
          )}
        />
      )}

      {organism && (
        <CustomPageFormFieldsWidgets organism={organism} activeTranslation={activeTranslation} values={values} />
      )}
    </Form>
  )
}

export default CustomFormFields
