import GenericForm from 'Components/Form/GenericForm'
import InlineFileInput from 'Components/Form/InlineFileInput'
import SAFormField from 'Components/Form/SAFormField'
import { SelectDateInput } from 'Components/Form/SelectDateInput'
import SelectItemListInput from 'Components/Form/SelectItemListInput'
import FileInput from 'Containers/Form/FileInput'
import SelectOrganismsInput from 'Containers/Form/SelectOrganismsInput'
import Config from 'config'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Form, Icon, Input } from 'semantic-ui-react'
import { MediaType, Permission } from 'services/api/graphql'
import { useStore } from 'stores'
import { notifyError } from 'tools/toaster'

export interface MediaInfoFormData {
  id?: string
  videoClipId?: string
  title: string
  date?: Date
  thumbnailId?: string
  ownerOrganismId?: string
  captions?: Captions[]
}

type Captions = {
  title?: string
  srtFile?: File
  srcFileId?: string
  language: string
}

interface MediaInfoFormProps {
  onSubmit: (data: MediaInfoFormData) => void
  onCancel: () => void
  open: boolean
  value?: MediaInfoFormData
  type: MediaType
  header: string
  defaultName?: string
  loading?: boolean
  progress?: number
  progressMessage?: string
  showCaptions?: boolean
}

const MediaInfoForm: React.FC<MediaInfoFormProps> = observer(
  ({
    onSubmit,
    onCancel,
    open,
    value,
    type,
    header,
    defaultName,
    loading,
    progress,
    progressMessage,
    showCaptions = true,
  }) => {
    const [initialValues, setInitialValues] = useState<MediaInfoFormData>()
    const intl = useIntl()
    const store = useStore()
    const [subs, setSubs] = useState<Captions[]>([])

    useEffect(() => {
      setInitialValues(
        value || {
          title: defaultName || '',
        },
      )
      if (value?.captions) setSubs(value.captions)
    }, [value, defaultName])

    const onUploadLocalFile = useCallback(
      (uploadedFile: File) => {
        if (!new RegExp('(' + ['srt'].join('|').replace(/\./g, '\\.') + ')$').test(uploadedFile.name)) {
          notifyError(intl.formatMessage({ id: 'medias.addingCaptionsError' }))
          return
        }
        setSubs([...subs, { srtFile: uploadedFile, language: Config.vodCaptionLangOptions[0].value }])
      },
      [intl, subs],
    )

    return (
      <GenericForm
        header={header}
        initialValues={initialValues}
        onCancel={onCancel}
        onSubmit={data => onSubmit({ ...data, captions: subs })}
        open={open}
        loading={!!loading}
        progress={progress}
        progressMessage={progressMessage}
      >
        {() => (
          <>
            <SAFormField
              name="title"
              render={({ input, meta }) => (
                <Form.Field
                  control={Input}
                  {...input}
                  required
                  error={!!meta.touched && !!meta.error}
                  label={intl.formatMessage({ id: 'mediaInfo.name' })}
                />
              )}
            />

            <SAFormField
              name="date"
              render={({ input, meta }) => (
                <Form.Field
                  error={!!meta.touched && !!meta.error}
                  datetime={false}
                  control={SelectDateInput}
                  format="DD/MM/YYYY"
                  {...input}
                  label={intl.formatMessage({
                    id: 'mediaInfo.date',
                  })}
                />
              )}
            />

            {type !== MediaType.Image && (
              <SAFormField
                name="thumbnailId"
                render={({ input, meta }) => (
                  <Form.Field
                    error={!!meta.touched && !!meta.error}
                    label={intl.formatMessage({
                      id: 'mediaInfo.thumbnail',
                    })}
                    control={FileInput}
                    {...input}
                    type={MediaType.Image}
                    renderOrganismPicker
                    imageConstraints={{ maxSize: 3000000 }}
                  ></Form.Field>
                )}
              />
            )}

            {!store.organismId && store.currentUser.can(Permission.MediaUpdateOrganism) && (
              <SAFormField
                name="ownerOrganismId"
                render={({ input, meta }) => (
                  <Form.Field
                    error={!!meta.touched && !!meta.error}
                    label={intl.formatMessage({
                      id: 'mediaInfo.ownerOrganismId',
                    })}
                    control={SelectOrganismsInput}
                    {...input}
                    noSelectionEnabled
                  ></Form.Field>
                )}
              />
            )}

            {type === MediaType.VideoClip && showCaptions && (
              <>
                <p className="font-bold" style={{ fontSize: '13px' }}>
                  {intl.formatMessage({ id: 'medias.addingCaptionsTitle' })}
                </p>
                <SAFormField
                  name="captions"
                  render={({ input, meta }) => (
                    <div className="pb-20">
                      {subs.length === 0 && !!value && (
                        <p className="text-sm text-gray-600">{intl.formatMessage({ id: 'medias.noCaption' })}</p>
                      )}

                      {subs.map((subtitle, index) => (
                        <div className="flex flex-row mb-4" key={`${subtitle.srtFile}-${index}`}>
                          <div className="mr-2">
                            <Form.Field
                              control={Input}
                              {...input}
                              required
                              disabled={!!value}
                              value={subtitle?.srtFile?.name || subtitle.title}
                              error={!!meta.touched && !!meta.error}
                            />
                          </div>
                          <SelectItemListInput
                            value={subtitle.language}
                            disabled={!!value}
                            options={Config.vodCaptionLangOptions}
                            onChange={(lang: string) => {
                              const temp = [...subs]
                              temp[index].language = lang
                              setSubs(temp)
                            }}
                          />
                          {!value && (
                            <Icon
                              name="trash"
                              size="large"
                              className="pl-3 self-center cursor-pointer"
                              onClick={() => {
                                setSubs(subs.filter((sub, i) => i !== index))
                              }}
                            />
                          )}
                        </div>
                      ))}
                      {!value && (
                        <InlineFileInput
                          onChange={onUploadLocalFile}
                          name="newFile"
                          placeholder={intl.formatMessage({
                            id: 'medias.addingCaptions',
                          })}
                        />
                      )}
                    </div>
                  )}
                />
              </>
            )}
          </>
        )}
      </GenericForm>
    )
  },
)

export default MediaInfoForm
