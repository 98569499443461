import ForgotPassword from 'Components/ForgotPassword'
import LoginForm from 'Components/LoginForm'
import ResetPassword from 'Components/ResetPassword'
import VerifyAccount from 'Components/VerifyAccount'
import { applicationUris } from 'Layout/uris'
import React from 'react'

const authRoutes = () => [
  {
    path: applicationUris.login,
    element: <LoginForm />,
  },
  { path: applicationUris.forgotPassword, element: <ForgotPassword /> },
  { path: applicationUris.resetPassword, element: <ResetPassword /> },
  { path: applicationUris.verifyAccount, element: <VerifyAccount /> },
]

export default authRoutes
