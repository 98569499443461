export const formatTime = (seconds: number) => {
  if (seconds === 0 || isNaN(seconds)) {
    return `00:00:00`
  }
  const date = new Date(seconds * 1000)
  const hh = Math.floor(seconds / 3600)
    .toString()
    .padStart(2, '0')
  const mm = date.getUTCMinutes().toString().padStart(2, '0')
  const ss = date.getUTCSeconds().toString().padStart(2, '0')
  return `${hh}:${mm}:${ss}`
}
export const timeToSeconds = (time: string): number => {
  const [hh, mm, ss] = time.split(':').map(Number)
  return hh * 3600 + mm * 60 + ss
}

export const validateTimeFormat = (time: string): boolean => {
  const timeRegex = /^(?:[0-9]|[0-9][0-9]):[0-5][0-9]:[0-5][0-9]$/
  return timeRegex.test(time)
}
