import { types } from 'mobx-state-tree'
import { getServicePlatformVesion, ApplicationDetails } from 'services/api'

const ServicePlatformVersionModel = types.model('ServicePlatformVersion', {
  version: types.string,
  buildDate: types.Date,
  env: types.string,
  startDate: types.Date,
})

const BackofficeVersionModel = types.model('BackofficeVersion', {
  version: types.string,
  buildDate: types.Date,
})

export const DebugModel = types
  .model('Debug', {
    showUserPermissions: types.optional(types.boolean, false),
    servicePlatformVersion: types.maybeNull(ServicePlatformVersionModel),
    backofficeVersion: types.maybeNull(BackofficeVersionModel),
  })
  .actions(self => ({
    _setVersions: (spVersion: ApplicationDetails) => {
      self.servicePlatformVersion = {
        version: spVersion.version,
        buildDate: new Date(spVersion.buildDate),
        env: spVersion.env,
        startDate: new Date(spVersion.startDate),
      }
      self.backofficeVersion = {
        version: process.env.REACT_APP_VERSION || '',
        buildDate: process.env.REACT_APP_BUILD_DATE ? new Date(process.env.REACT_APP_BUILD_DATE) : new Date(),
      }
    },
  }))
  .actions(self => ({
    toggleUserPermissionView: () => {
      self.showUserPermissions = !self.showUserPermissions
    },
    fetchVersions: async () => {
      const spVersion = await getServicePlatformVesion()
      self._setVersions(spVersion)
    },
  }))
