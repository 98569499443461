import React from 'react'
import { FormattedMessage } from 'react-intl'
import { List, Modal } from 'semantic-ui-react'
import { MediaContentFragment } from 'services/api/graphql'

interface VideoClipDetailsModalProps {
  media: MediaContentFragment
  open: boolean
  onClose: () => void
}

const VideoClipDetailsModal: React.FC<VideoClipDetailsModalProps> = ({ media, open, onClose }) => {
  const { title, videoClip } = media
  return (
    <Modal mountNode={document.getElementById('modals')} open={open} onClose={onClose} closeIcon>
      <Modal.Content>
        <List>
          <List.Item>
            <List.Header>
              <FormattedMessage id="videoClip.title" />
            </List.Header>
            <List.Description>{title}</List.Description>
          </List.Item>
          {videoClip && (
            <List.Item>
              <List.Header>
                <FormattedMessage id="videoClip.playbackUrl" />
              </List.Header>
              <List.Description>{videoClip.playbackUrl}</List.Description>
            </List.Item>
          )}
        </List>
      </Modal.Content>
    </Modal>
  )
}

export default VideoClipDetailsModal
