import { DraggableProvided } from '@hello-pangea/dnd'
import ConfirmationButton from 'Containers/Button/ConfirmationButton'
import { applicationUris } from 'Layout/uris'
import config from 'config'
import { useSetHomePage } from 'models'
import React, { useCallback } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { Button, Icon, Table } from 'semantic-ui-react'
import { CustomPage, Permission } from 'services/api/graphql'
import { useCurrentUser } from 'stores'
import styled from 'styled-components'

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 8px;
  }
`

interface CustomPageViewRowProps {
  customPage: CustomPage
  draggableProvided: DraggableProvided
  onUpdateCustomPage: () => Promise<any>
  onRemoveCustomPage: () => Promise<any>
}

const CustomPageViewRow = ({
  customPage,
  draggableProvided,
  onUpdateCustomPage,
  onRemoveCustomPage,
}: CustomPageViewRowProps) => {
  const intl = useIntl()
  const currentUser = useCurrentUser()
  const [setHomePage, { loading }] = useSetHomePage()

  const changeHomePage = useCallback(async () => {
    if (!customPage.isHomePage) {
      await setHomePage({
        variables: { id: customPage.id },
      })
      await onUpdateCustomPage()
    }
  }, [customPage.id, customPage.isHomePage, onUpdateCustomPage, setHomePage])

  return (
    <>
      {currentUser.can(Permission.CustomPageUpdate) && (
        <>
          <Table.Cell collapsing textAlign="center" {...draggableProvided.dragHandleProps}>
            <Icon name="bars" />
          </Table.Cell>
          <Table.Cell collapsing textAlign="center">
            <Button
              icon="check"
              circular
              positive={!!customPage.isHomePage}
              loading={loading}
              onClick={changeHomePage}
            />
          </Table.Cell>
        </>
      )}
      <Table.Cell textAlign="center">{customPage.title}</Table.Cell>
      <Table.Cell
        textAlign="center"
        className="text-sm text-gray-400"
      >{`${config.webappUrl}page/${customPage.id}`}</Table.Cell>
      <Table.Cell textAlign="center">
        <FormattedMessage id={`publicationStatus.${customPage.published}`} />
      </Table.Cell>
      <Table.Cell textAlign="center">
        <Buttons>
          {currentUser.can(Permission.CustomPageUpdate) && (
            <Link
              to={{
                pathname: applicationUris.customPage(customPage.id),
              }}
            >
              <Icon name="edit" link size="large" inverted color="black" />
            </Link>
          )}
          {currentUser.can(Permission.CustomPageDelete) && (
            <ConfirmationButton
              action={onRemoveCustomPage}
              confirmText={intl.formatMessage({
                id: 'customPage.delete_confirm',
              })}
              successText={intl.formatMessage({
                id: 'customPage.delete_success',
              })}
            />
          )}
        </Buttons>
      </Table.Cell>
    </>
  )
}

export default CustomPageViewRow
