import { DragDropContext, Droppable, DropResult } from '@hello-pangea/dnd'
import InvisibleButton from 'Components/Button/InvisibleButton'
import SROnly from 'Components/Button/SROnly'
import DraggableTableRow from 'Components/DraggableTableRow/DraggableTableRow'
import FilterableTableHeader from 'Components/FilterableTableHeader'
import Loader from 'Components/Loader'
import PageHeader from 'Components/PageHeader'
import ConfirmationButton from 'Containers/Button/ConfirmationButton'
import FrontPageForm, { FrontPageFormValues } from 'Forms/FrontPage/FrontPageForm'
import { applicationUris } from 'Layout/uris'
import {
  FrontPage,
  FrontPageState,
  PublicationStatus,
  useCreateFrontPage,
  useListFrontPages,
  useMoveFrontPagePosition,
  useUpdateFrontPage,
} from 'models'
import React, { FC, useCallback, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { Button, DropdownItemProps, Grid, Icon, Image, Ref, Table } from 'semantic-ui-react'
import { useDeleteFrontPageMutation } from 'services/api/graphql'
import styled from 'styled-components'
import { format } from 'tools/date'
import { notifyError, notifySuccess } from 'tools/toaster'

interface OnFrontLabelProps {
  active: boolean
  planned: boolean
}
const OnFrontLabel = styled.span<OnFrontLabelProps>`
  font-weight: ${props => (props.active ? 'bold' : 'normal')};
  font-style: ${props => (props.planned ? 'italic' : 'default')};
`

const BannerImage = styled(Image)`
  height: 48px;
  object-fit: 'cover';
`

const FrontPagesView: FC = () => {
  const intl = useIntl()

  const [current, setCurrent] = useState<boolean | undefined>()
  const { frontPages, loading, refetch } = useListFrontPages({
    variables: { current },
  })
  // Keep the list ordered after updates
  const orderedFrontPages = useMemo(
    () => [
      ...(frontPages || [])
        .filter(page => page.state !== FrontPageState.Finished)
        .sort((a, b) => a.position - b.position),
      ...(frontPages || []).filter(page => page.state === FrontPageState.Finished),
    ],
    [frontPages],
  )

  // Creation, edition
  const [createFrontPage, { loading: createLoading }] = useCreateFrontPage()
  const [updateFrontPage, { loading: updateLoading }] = useUpdateFrontPage()
  const [moveFrontPagePosition, { loading: moveLoading }] = useMoveFrontPagePosition()

  const [formOpen, setFormOpen] = useState(false)
  const [editedFrontPage, setEditedFrontPage] = useState<FrontPage | null>(null)

  const editedFrontPageData = useMemo<FrontPageFormValues | undefined>(
    () =>
      (editedFrontPage && {
        start: editedFrontPage.start,
        end: editedFrontPage.end || undefined,
        sportItemSetId: editedFrontPage.sportItemSetId,
        sportItemId: editedFrontPage.sportItemId || undefined,
        coverImageId: editedFrontPage.coverImageId,
        coverVideoId: editedFrontPage.coverVideoId,
        titleImageId: editedFrontPage.titleImageId,
        title: editedFrontPage.title,
        description: editedFrontPage.description,
        tags: [...editedFrontPage.tags],
        redirectsAutomatically: !!editedFrontPage.redirectsAutomatically,
        publicationStatus: editedFrontPage.publicationStatus,
      }) ||
      undefined,
    [editedFrontPage],
  )

  const onCreate = () => {
    setFormOpen(true)
  }

  const onEdit = (frontPage: FrontPage) => {
    setEditedFrontPage(frontPage)
    setFormOpen(true)
  }

  const closeForm = () => {
    setEditedFrontPage(null)
    setFormOpen(false)
  }

  // Move front page position
  const onMoveFrontPage = useCallback(
    (result: DropResult) => {
      const { destination, source, reason, draggableId } = result
      // Do nothing if drop cancelled
      if (!frontPages || !destination || reason === 'CANCEL' || destination.index === source.index) {
        return
      }

      // Prevent placing the frontPage after finished ones
      let index = Math.min(destination.index, orderedFrontPages.length)
      while (index > 0 && orderedFrontPages[index].state === FrontPageState.Finished) {
        index = index - 1
      }

      // Obtain new position from item previously at this index
      const position = orderedFrontPages[index].position + (source.index < index ? 1 : 0)

      // Call service-platform
      moveFrontPagePosition({
        input: { id: draggableId, position },
      })
    },
    [frontPages, moveFrontPagePosition, orderedFrontPages],
  )

  const onFormSubmit = useCallback(
    async (data: FrontPageFormValues) => {
      setFormOpen(false)

      if (editedFrontPage !== null) {
        // Update front Page
        await updateFrontPage({
          variables: {
            id: editedFrontPage.id,
            input: {
              ...data,
              // start can be edited only if it is set in the future
              start: new Date(data.start) > new Date() ? data.start : undefined,
            },
          },
        })
        setEditedFrontPage(null)
        notifySuccess(intl.formatMessage({ id: 'frontPage.update_success' }))
      } else {
        // create front Page
        await createFrontPage({
          variables: {
            input: {
              ...data,
            },
          },
        })
        notifySuccess(intl.formatMessage({ id: 'frontPage.create_success' }))
      }
      refetch()
    },
    [createFrontPage, editedFrontPage, intl, refetch, updateFrontPage],
  )

  // Delete
  const [deleteFrontPage, { loading: loadingDelete }] = useDeleteFrontPageMutation()
  const cancelFrontPage = useCallback(
    async (frontPage: FrontPage) => {
      await deleteFrontPage({ variables: { id: frontPage.id } })
    },
    [deleteFrontPage],
  )

  // Filters data
  const statusOptions: DropdownItemProps[] = useMemo(
    () => [
      {
        key: true,
        value: true,
        text: intl.formatMessage({ id: 'frontPages.onFront' }),
      },
      {
        key: false,
        value: false,
        text: intl.formatMessage({ id: 'frontPages.notOnFront' }),
      },
    ],
    [intl],
  )

  return (
    <Loader loading={loading || createLoading || updateLoading || loadingDelete || moveLoading}>
      <Grid padded>
        <Grid.Column>
          <PageHeader title={<FormattedMessage id="frontPages.title" />}>
            <Button as={Link} to={applicationUris.frontPagesStats} primary>
              <FormattedMessage id="frontPages.stats" />
            </Button>
            <Button circular color="red" icon="plus" onClick={() => onCreate()} />
          </PageHeader>

          <DragDropContext onDragEnd={onMoveFrontPage}>
            <Table>
              <Table.Header>
                <Table.Row textAlign="center">
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell>
                    <FormattedMessage id="frontPages.sportitemSet.title" />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <FormattedMessage id="frontPages.sportitemSet.organism" />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <FormattedMessage id="frontPages.sportitemSet.type" />
                  </Table.HeaderCell>
                  <FilterableTableHeader<boolean>
                    label={<FormattedMessage id="frontPages.sportitemSet.onFront" />}
                    options={statusOptions}
                    onChange={value => setCurrent(value)}
                    value={current}
                  />
                  <Table.HeaderCell>
                    <FormattedMessage id="frontPages.actions" />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Droppable droppableId="table">
                {provided => (
                  <Ref innerRef={provided.innerRef}>
                    <Table.Body>
                      {orderedFrontPages.map((frontPage, index) => {
                        return (
                          <DraggableTableRow
                            key={frontPage.id}
                            draggableId={frontPage.id}
                            index={index}
                            isDragDisabled={frontPage.state === FrontPageState.Finished}
                            textAlign="center"
                          >
                            {({ dragHandleProps }) => (
                              <>
                                <Table.Cell collapsing {...dragHandleProps} content={<Icon name="bars" />} />
                                <Table.Cell>
                                  <>
                                    {frontPage.titleImage && frontPage.titleImage.image && (
                                      <BannerImage src={frontPage.titleImage.image.thumbUrl} />
                                    )}
                                  </>
                                </Table.Cell>
                                <Table.Cell>{frontPage.title}</Table.Cell>
                                <Table.Cell>
                                  {frontPage.sportItemSet &&
                                    frontPage.sportItemSet.ownerOrganism &&
                                    frontPage.sportItemSet.ownerOrganism.title}
                                </Table.Cell>
                                <Table.Cell>
                                  {frontPage.sportItemSet && (
                                    <FormattedMessage id={`sportItemSetType.${frontPage.sportItemSet.type}`} />
                                  )}
                                </Table.Cell>
                                <Table.Cell>
                                  <>
                                    <OnFrontLabel
                                      active={
                                        frontPage.publicationStatus === PublicationStatus.Published &&
                                        frontPage.state === FrontPageState.Active
                                      }
                                      planned={
                                        frontPage.state === FrontPageState.Upcoming ||
                                        (frontPage.state === FrontPageState.Active &&
                                          frontPage.publicationStatus === PublicationStatus.Draft)
                                      }
                                    >
                                      {frontPage.publicationStatus === PublicationStatus.Draft && (
                                        <>
                                          <FormattedMessage id="publicationStatus.draft" /> /{' '}
                                        </>
                                      )}
                                      {frontPage.state === FrontPageState.Finished ? (
                                        <FormattedMessage id="frontPages.notOnFront" />
                                      ) : (
                                        <FormattedMessage
                                          id={
                                            frontPage.state === FrontPageState.Active
                                              ? 'frontPages.onFront'
                                              : 'frontPages.planned'
                                          }
                                          values={{
                                            start: format(frontPage.start, 'D MMMM YYYY HH:mm'),
                                          }}
                                        />
                                      )}
                                    </OnFrontLabel>
                                    {frontPage.end && (
                                      <>
                                        <br />
                                        <FormattedMessage
                                          id="frontPages.until"
                                          values={{
                                            end: format(frontPage.end, 'D MMMM YYYY HH:mm'),
                                          }}
                                        />
                                      </>
                                    )}
                                  </>
                                </Table.Cell>
                                <Table.Cell>
                                  <InvisibleButton
                                    onClick={() => onEdit(frontPage)}
                                    title={intl.formatMessage({
                                      id: 'frontPage.edit',
                                    })}
                                  >
                                    <Icon
                                      link={frontPage.state !== FrontPageState.Finished}
                                      size="large"
                                      name="edit"
                                      color="black"
                                      inverted
                                    />
                                  </InvisibleButton>
                                  <ConfirmationButton
                                    confirmText={
                                      <FormattedMessage
                                        id={`frontPage.${
                                          frontPage.state === FrontPageState.Active ? 'stop_confirm' : 'delete_confirm'
                                        }`}
                                      />
                                    }
                                    successText={intl.formatMessage({
                                      id: 'frontPage.delete_success',
                                    })}
                                    action={() => cancelFrontPage(frontPage)}
                                    onDone={() => refetch()}
                                    onError={notifyError}
                                  >
                                    <Icon name="cancel" color="red" size="large" link />
                                    <SROnly>
                                      <FormattedMessage
                                        id={`frontPage.${
                                          frontPage.state === FrontPageState.Active ? 'stop' : 'delete'
                                        }`}
                                      />
                                    </SROnly>
                                  </ConfirmationButton>
                                </Table.Cell>
                              </>
                            )}
                          </DraggableTableRow>
                        )
                      })}
                    </Table.Body>
                  </Ref>
                )}
              </Droppable>
            </Table>
          </DragDropContext>
        </Grid.Column>
      </Grid>
      <FrontPageForm open={formOpen} value={editedFrontPageData} onCancel={closeForm} onSubmit={onFormSubmit} />
    </Loader>
  )
}

export default FrontPagesView
