import React from 'react'

import { Field as RFField, FieldProps as RFFieldProps } from 'react-final-form'
import { FieldRenderProps } from 'react-final-form'
import { Message } from 'semantic-ui-react'

import { RequiredValue } from 'tools/formValidators'

export type RenderFormFieldProps<FieldValue, T extends HTMLElement> = (
  props: FieldRenderProps<FieldValue, T>,
) => React.ReactNode
export const renderFormField =
  <FieldValue, T extends HTMLElement>(render: RenderFormFieldProps<FieldValue, T>) =>
  (props: FieldRenderProps<FieldValue, T>) => {
    const { meta } = props
    return (
      // 'field' is RFField class name
      <div className="field">
        {render(props)}
        {meta.touched && meta.error && meta.error !== RequiredValue && <Message negative>{meta.error}</Message>}
        {/* {meta.touched && meta.error && <span className="error"> {meta.error}</span>} */}
      </div>
    )
  }

export interface SAFormFieldProps<FieldValue, T extends HTMLElement>
  extends RFFieldProps<FieldValue, FieldRenderProps<FieldValue, HTMLElement, FieldValue>, T> {}

const SAFormField = <FieldValue, T extends HTMLElement>({ render, ...props }: SAFormFieldProps<FieldValue, T>) => (
  <RFField {...props} render={render && renderFormField(render)} />
)

export default SAFormField
