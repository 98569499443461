import { useCallback, useEffect, useRef, useState } from 'react'
import { compressImage } from 'tools/image'
import { ImageFormat } from 'types/image'

export const useImageCompression = (image: Blob | null, format: ImageFormat, compressionLevel?: number) => {
  const [compressed, setCompressed] = useState<File>()
  const [loading, setLoading] = useState(false)
  const cancelCompressed = useRef(false)

  const compress = useCallback(async () => {
    if (image) {
      setLoading(true)
      const newImage = await compressImage(image, format, compressionLevel)
      if (!cancelCompressed.current) {
        setCompressed(newImage)
        setLoading(false)
      }
    }
  }, [image, format, compressionLevel])

  useEffect(() => {
    cancelCompressed.current = false
    if (image) {
      compress()
    } else {
      setCompressed(undefined)
    }

    return () => {
      cancelCompressed.current = true
    }
  }, [image, compressionLevel, compress])

  return { image: compressed, loading }
}
