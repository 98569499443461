import EditSportItemSetForm from 'Forms/SportItemSet/EditSportItemSetForm'
import React from 'react'
import { useIntl } from 'react-intl'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Segment } from 'semantic-ui-react'
import { notifyError, notifySuccess } from 'tools/toaster'

type EditSportItemSetButtonProps = {
  sportItemSetViewModalId?: string
  onClose?: () => void
}

const EditSportItemSetButton = ({ sportItemSetViewModalId, onClose }: EditSportItemSetButtonProps) => {
  const intl = useIntl()
  const { sportItemSetId } = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  const isModal = location.state?.isModal || false

  return (
    <Segment>
      {(sportItemSetId || sportItemSetViewModalId) && (
        <EditSportItemSetForm
          editType={true}
          isModal={isModal}
          sportItemSetId={sportItemSetViewModalId || (sportItemSetId as string)}
          onCancel={() => (onClose ? onClose() : navigate(-1))}
          onDone={sportItemSet => {
            notifySuccess(
              intl.formatMessage(
                { id: `sportItemSet.update_success.${sportItemSet.type}` },
                { title: sportItemSet.title },
              ),
            )
            onClose ? onClose() : navigate(-1)
          }}
          onError={notifyError}
        />
      )}
    </Segment>
  )
}

export default EditSportItemSetButton
