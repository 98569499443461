import { useLatestPurchaseExportDownloadUrlLazyQuery, useSchedulePurchaseExport } from 'models'
import React, { useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import { Button } from 'semantic-ui-react'
import { notifyError } from 'tools/toaster'

const PurchaseExportView: React.FC = () => {
  const [latestPurchaseExportDownloadUrl, { loading }] = useLatestPurchaseExportDownloadUrlLazyQuery({
    fetchPolicy: 'network-only',
  })
  const [schedulePurchaseExport, { loading: scheduleLoading }] = useSchedulePurchaseExport()

  const getLatestPurchaseExport = useCallback(async () => {
    try {
      const result = await latestPurchaseExportDownloadUrl()
      const latestPurchaseExport = result.data?.latestPurchaseExportDownloadUrl
      if (!latestPurchaseExport) {
        return
      }

      const link = document.createElement('a')
      link.href = latestPurchaseExport
      link.click()
    } catch (error) {
      notifyError((error as Error).message)
      console.error(error)
    }
  }, [latestPurchaseExportDownloadUrl])

  return (
    <>
      <div>
        <Button primary onClick={() => schedulePurchaseExport()} loading={scheduleLoading}>
          <FormattedMessage id="purchases.export.schedule" />
        </Button>
      </div>
      <Button primary onClick={getLatestPurchaseExport} loading={loading} disabled={loading}>
        <FormattedMessage id="purchases.export.generateCSV" />
      </Button>
    </>
  )
}

export default PurchaseExportView
