import SwitchInput from 'Components/Form/SwitchInput'
import { CacUserAuthenticationRule, useApplicationsQuery } from 'models'
import React, { FC, useState } from 'react'
import { IntlShape, useIntl } from 'react-intl'
import { Icon } from 'semantic-ui-react'
import { useStore } from 'stores'

type Props = {
  onChange: (isRequired: CacUserAuthenticationRule | undefined) => void
  value: CacUserAuthenticationRule | undefined
  withDisableButton?: boolean
  ownerOrganismId?: string | null
  displayDefaultBehaviour?: boolean
  sportItemSetAuthenticationCac?: CacUserAuthenticationRule
}

const options = (intl: IntlShape) => [
  {
    key: CacUserAuthenticationRule.NotRequired,
    value: CacUserAuthenticationRule.NotRequired,
    text: intl.formatMessage({ id: 'cac.authenticationRule.notRequired' }),
  },
  {
    key: CacUserAuthenticationRule.Required,
    value: CacUserAuthenticationRule.Required,
    text: intl.formatMessage({ id: 'cac.authenticationRule.required' }),
  },
]

export const ContentAccessControlUserAuthenticationFormFields: FC<Props> = ({
  value,
  onChange,
  withDisableButton = false,
  displayDefaultBehaviour = true,
  ownerOrganismId,
  sportItemSetAuthenticationCac,
}) => {
  const intl = useIntl()
  const store = useStore()
  const [disabled, setDisabled] = useState<boolean>(!withDisableButton && !value)

  const { data } = useApplicationsQuery({
    variables: { filters: { organismId: store.organismId || ownerOrganismId } },
    skip: !(store.organismId || ownerOrganismId),
  })
  const application = data?.applications?.totalCount === 1 ? data?.applications?.applications[0] : null
  const defaultCac = sportItemSetAuthenticationCac || application?.cac?.userAuthenticationRule || undefined

  return !disabled && displayDefaultBehaviour && !value ? (
    <p>
      {defaultCac !== CacUserAuthenticationRule.NotRequired
        ? intl.formatMessage({ id: 'cac.authenticationRule.contentRuleHelper.required' })
        : intl.formatMessage({ id: 'cac.authenticationRule.contentRuleHelper.notRequired' })}
      <button
        className="font-medium ml-1 text-blue-500 hover:text-blue-400"
        type="button"
        onClick={() => setDisabled(true)}
      >
        {intl.formatMessage({ id: 'cac.authenticationRule.forceDifferentBehaviour' })}
      </button>
      .
    </p>
  ) : (
    <div className="flex">
      <SwitchInput<CacUserAuthenticationRule> value={value} onChange={onChange} options={options(intl)} />
      <div className="flex ml-3">
        {((!!defaultCac && displayDefaultBehaviour) || (!disabled && !!value)) && (
          <button
            type="button"
            className="m-1.5 px-2 rounded-full bg-red-500 text-gray-100 hover:bg-red-400 hover:text-red-100"
            onClick={() => {
              setDisabled(false)
              onChange(undefined)
            }}
          >
            <Icon name="close" fitted />
          </button>
        )}
      </div>
    </div>
  )
}
