import CropImageAndUpload from 'Containers/CropImage/CropImageAndUpload'
import CropImageAndUploadFromSportallFile from 'Containers/CropImage/CropImageAndUploadFromSportallFile'
import FilesLibraryView from 'Containers/FilesLibraryView'
import MediaInfoForm, { MediaInfoFormData } from 'Forms/Media/MediaInfoForm'
import { observer } from 'mobx-react-lite'
import {
  useCompleteMultipart,
  useCreateMediaVideoClip,
  useCreateMultipart,
  useDeleteMedia,
  useUpdateMedia,
  useUploadPart,
} from 'models'
import React, { useCallback, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button, Confirm, Popup } from 'semantic-ui-react'
import { chunkFile, getMd5Hash } from 'services/api'
import { CaptionInput, Media, MediaType, Permission } from 'services/api/graphql'
import { useCurrentUser, useStore } from 'stores'
import styled from 'styled-components'
import { notifyError, notifySuccess } from 'tools/toaster'

const Buttons = styled.div`
  display: flex;
  justify-content: center;
`

const Container = styled.div`
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
  padding: 16px;
`

const MediaLibraryView = observer(() => {
  const currentUser = useCurrentUser()
  const store = useStore()
  const [cropAndUploadElement, setCropAndUploadElement] = useState<JSX.Element | null>()
  const [deleteMedia] = useDeleteMedia()
  const [confirm, setConfirm] = useState<JSX.Element | null>()

  // Video form
  const [mediaFormOpen, setMediaFormOpen] = useState(false)
  const [newFile, setNewFile] = useState<File | null>(null)
  const [editedMediaData, setEditedMediaData] = useState<MediaInfoFormData | null>(null)
  const [editedMediaId, setEditedMediaId] = useState<string | null>(null)
  const [editedMediaType, setEditedMediaType] = useState<MediaType>()
  const [refreshFn, setRefreshFn] = useState<() => void>()
  const [createMediaVideoClip] = useCreateMediaVideoClip()
  const [updateMedia] = useUpdateMedia()
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [progressMessage, setProgressMessage] = useState<string>()
  const [progress, setProgress] = useState<number>()

  const createMultipart = useCreateMultipart()
  const [uploadPart] = useUploadPart()
  const [completeMultipart] = useCompleteMultipart()

  // Open image or video form on upload
  const handleFileUpload = useCallback(
    (uploadedFile: File, refresh: () => void) => {
      if (uploadedFile.type.startsWith('image/')) {
        // Image, allow cropping
        setCropAndUploadElement(
          <CropImageAndUpload
            image={uploadedFile}
            onDone={() => {
              refresh()
              setCropAndUploadElement(null)
            }}
            onCancel={() => setCropAndUploadElement(null)}
            canImportSourceImage
            ownerOrganismId={store.organismId ?? undefined}
          />,
        )
      } else if (uploadedFile.type.startsWith('video/')) {
        // Video, open videoclip form
        setNewFile(uploadedFile)
        setMediaFormOpen(true)
        setRefreshFn(() => refresh)
      }
    },
    [store.organismId],
  )

  const handleEditMedia = useCallback((media: Media) => {
    setEditedMediaData({
      id: media.id,
      title: media.title,
      date: media.date || undefined,
      thumbnailId: media.thumbnailId || undefined,
      ownerOrganismId: media.ownerOrganismId || undefined,
      captions: media.videoClip?.captions || [],
      videoClipId: media.videoClipId || undefined,
    })
    setEditedMediaId(media.id)
    setEditedMediaType(media.type)
    setMediaFormOpen(true)
  }, [])

  const onCancelMediaForm = useCallback(() => {
    setMediaFormOpen(false)
    setEditedMediaData(null)
    setEditedMediaId(null)
    setNewFile(null)
  }, [])

  const mediaFormHeader = useMemo(() => {
    if (!editedMediaId) {
      return intl.formatMessage({ id: 'mediaInfo.create.videoClip' })
    }
    return intl.formatMessage({ id: `mediaInfo.update.${editedMediaType}` })
  }, [editedMediaId, editedMediaType, intl])

  // Create new videoclip media or update a media
  const onSubmitMediaInfo = useCallback(
    async (data: MediaInfoFormData) => {
      setLoading(true)
      try {
        const captions: CaptionInput[] = []

        if (newFile && !editedMediaId) {
          setProgressMessage(intl.formatMessage({ id: 'medias.videoClip.preparingUpload' }))
          setProgress(undefined)

          setProgressMessage(`${intl.formatMessage({ id: 'medias.videoClip.uploading' })} ${newFile.name}`)
          const hash = await getMd5Hash(newFile, (percent: number) => setProgress(percent))
          const clipMultipart = await createMultipart({ hash, fileName: newFile.name, extension: 'mp4' })

          if (!clipMultipart || !clipMultipart.uploadId || !clipMultipart.key) {
            setLoading(false)
            return notifyError(new Error(intl.formatMessage({ id: 'medias.error.signedPolicy' })))
          }

          await chunkFile(
            newFile,
            clipMultipart.currentPart,
            async (chunk: Blob, part: number) => {
              await uploadPart({
                variables: {
                  input: {
                    hash,
                    chunk,
                    key: clipMultipart.key,
                    part,
                    uploadId: clipMultipart.uploadId,
                    size: chunk.size,
                  },
                },
              })
            },
            (percent: number) => setProgress(percent),
            async () => {
              await completeMultipart({
                variables: {
                  input: {
                    hash,
                    uploadId: clipMultipart.uploadId,
                  },
                },
              })
            },
          )

          if (data?.captions) {
            for (const caption of data?.captions) {
              if (caption?.srtFile) {
                setProgressMessage(
                  `${intl.formatMessage({ id: 'medias.videoClip.uploading' })} ${caption.srtFile.name}`,
                )
                const hash = await getMd5Hash(caption.srtFile, (percent: number) => setProgress(percent))
                const multipart = await createMultipart({ hash, fileName: caption.srtFile.name, extension: 'srt' })

                if (!multipart || !multipart.uploadId || !multipart.key) {
                  setLoading(false)
                  return notifyError(new Error(intl.formatMessage({ id: 'medias.error.signedPolicy' })))
                }

                captions.push({ srcFileId: multipart.key, language: caption.language, title: caption.srtFile.name })
                await chunkFile(
                  caption.srtFile,
                  multipart.currentPart,
                  async (chunk: Blob, part: number) => {
                    await uploadPart({
                      variables: {
                        input: {
                          hash,
                          chunk,
                          key: multipart.key,
                          part,
                          uploadId: multipart.uploadId,
                          size: chunk.size,
                        },
                      },
                    })
                  },
                  (percent: number) => setProgress(percent),
                  async () => {
                    await completeMultipart({
                      variables: {
                        input: {
                          hash,
                          uploadId: multipart.uploadId,
                        },
                      },
                    })
                  },
                )
              }
            }
          }

          setProgress(undefined)
          setProgressMessage(undefined)
          const res = await createMediaVideoClip({
            variables: {
              input: {
                media: {
                  title: data.title,
                  date: data.date,
                  thumbnailId: data.thumbnailId,
                  ownerOrganismId: store.organismId || data.ownerOrganismId,
                },
                fileId: clipMultipart.key,
                captions,
              },
            },
          })
          if (res.data && refreshFn) {
            refreshFn()
          }
        } else if (editedMediaId && editedMediaData) {
          await updateMedia({
            variables: {
              mediaId: editedMediaId,
              input: {
                title: data.title || '',
                thumbnailId: data.thumbnailId,
                date: data.date,
                ownerOrganismId: data.ownerOrganismId,
              },
            },
          })
        }
        setMediaFormOpen(false)
        setLoading(false)
      } catch (e) {
        return notifyError(e as Error)
      } finally {
        setLoading(false)
      }
      return
    },
    [
      newFile,
      editedMediaId,
      editedMediaData,
      intl,
      createMultipart,
      createMediaVideoClip,
      store.organismId,
      refreshFn,
      uploadPart,
      completeMultipart,
      updateMedia,
    ],
  )

  const userCanDeleteMedia = useCallback(
    (media: Media) => {
      const permission = media.type === MediaType.VideoClip ? Permission.VideoClipDelete : Permission.FileDelete

      return currentUser.can(permission, media.ownerOrganismId || undefined, true)
    },
    [currentUser],
  )
  const userCanUpdateMedia = useCallback(
    (media: Media) => {
      const permission = media.type === MediaType.VideoClip ? Permission.VideoClipUpdate : Permission.FileUpdate

      return currentUser.can(permission, media.ownerOrganismId || undefined, true)
    },
    [currentUser],
  )

  return (
    <Container>
      <FilesLibraryView
        renderFileDetails={({ media: selectedSportallFile, refresh, resetFileSelection }) => (
          <Buttons>
            {userCanUpdateMedia(selectedSportallFile) && (
              <Popup
                position="top center"
                trigger={
                  <Button primary circular icon="edit" onClick={() => handleEditMedia(selectedSportallFile)}></Button>
                }
              >
                <FormattedMessage id="medias.edit" />
              </Popup>
            )}

            {selectedSportallFile.type === MediaType.Image && currentUser.can(Permission.FileCreate) && (
              <Popup
                position="top center"
                trigger={
                  <Button
                    primary
                    circular
                    icon="expand"
                    onClick={() =>
                      setCropAndUploadElement(
                        <CropImageAndUploadFromSportallFile
                          selectedFile={selectedSportallFile}
                          ownerOrganismId={store.organismId || undefined}
                          onDone={() => {
                            refresh()
                            setCropAndUploadElement(null)
                          }}
                          onCancel={() => setCropAndUploadElement(null)}
                        />,
                      )
                    }
                  ></Button>
                }
              >
                <FormattedMessage id="medias.crop" />
              </Popup>
            )}

            {userCanDeleteMedia(selectedSportallFile) && (
              <Popup
                position="top center"
                trigger={
                  <Button
                    negative
                    circular
                    icon="delete"
                    onClick={() => {
                      setConfirm(
                        <Confirm
                          size="tiny"
                          open
                          // header="Annulation"
                          content={`Voulez vous vraiment supprimer le media "${selectedSportallFile.title}" ?`}
                          cancelButton={{ content: 'Non' }}
                          confirmButton={{ content: 'Oui', negative: true }}
                          onConfirm={() => {
                            setConfirm(null)
                            deleteMedia({
                              variables: { mediaId: selectedSportallFile.id },
                            })
                              .then(() => {
                                notifySuccess(`Le media ${selectedSportallFile.title} a été supprimé`)
                                resetFileSelection()
                                refresh()
                              })
                              .catch(notifyError)
                          }}
                          onCancel={() => setConfirm(null)}
                        />,
                      )
                    }}
                  ></Button>
                }
              >
                <FormattedMessage id="medias.delete" />
              </Popup>
            )}
          </Buttons>
        )}
        onUploadFile={handleFileUpload}
        dropAcceptedMimetypes={['image/png', 'image/jpeg', 'video/mp4']}
      />

      {cropAndUploadElement}

      {confirm}

      {mediaFormOpen && (
        <MediaInfoForm
          loading={loading}
          progress={progress}
          progressMessage={progressMessage}
          value={editedMediaData || undefined}
          open
          onCancel={onCancelMediaForm}
          onSubmit={onSubmitMediaInfo}
          defaultName={(newFile && newFile.name) || ''}
          type={editedMediaId && editedMediaType ? editedMediaType : MediaType.VideoClip}
          header={mediaFormHeader}
        />
      )}
    </Container>
  )
})

export default MediaLibraryView
