import Loader from 'Components/Loader'
import { useFetchMediasLoadMore } from 'hooks/useFetchMedias'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import InfiniteScroller from 'react-infinite-scroller'
import { Media, MediaType, Organism } from 'services/api/graphql'
import { useStore } from 'stores'
import styled from 'styled-components'
import { ImageConstraints } from 'types/image'

import MediaCard from './MediaCard'

const ScrollerContainer = styled.div`
  height: 100%;
  overflow: auto;
`

const MediaListLoadMoreScroller = styled(InfiniteScroller)`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  > * + * {
    margin-top: 2rem;
  }
`

const MediaView = styled.div`
  flex: 1;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  /* display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); */
  /* padding-left: 10px; */
  padding-right: 10px;
  justify-content: center;
  max-width: 1000px;
  margin: auto;
`

export interface MediaListViewProps {
  search?: string | undefined
  type?: MediaType | undefined
  pageSize?: number
  selectedMediaId?: Media['id'] | undefined
  onMediaClick?: (media: Media) => void
  onLoading?: (loading: boolean) => void
  imageConstraints?: ImageConstraints
  organismId?: Organism['id']
}

const MediaListView = observer(
  ({
    search,
    type,
    onMediaClick,
    pageSize = 50,
    selectedMediaId,
    onLoading,
    imageConstraints,
    organismId,
  }: MediaListViewProps) => {
    const { medias, loading, totalCount, loadMore } = useFetchMediasLoadMore({
      search,
      type,
      pageSize,
      organismId,
    })

    const store = useStore()
    const [scrollerKey, setSetScrollerKey] = useState(0)

    useEffect(() => {
      // Make Scroller to remount & reinitialize to "zero data"
      // We use setSetscrollerKey with functional prop to prevent useEffect() here
      // to depend on scrollerKey (prevent infinite loop)
      // scrollerKey change is used to trigger a rerender of InfiniteScroller
      // Since we have no way right now to ask InfiniteScroller to reset itself we
      // we this technik ;-)
      setSetScrollerKey(value => (value ? 0 : 1))
    }, [search, type])

    useEffect(() => {
      // Make Scroller to remount & reinitialize to "zero data"
      onLoading?.(loading)
    }, [loading, onLoading])

    const items = useMemo(() => {
      return medias
        .filter(media =>
          store.organismId ? !media.ownerOrganismId || media.ownerOrganismId === store.organismId : true,
        )
        .map((media, i) => (
          <MediaCard
            key={i}
            media={media}
            selectedMediaId={selectedMediaId}
            imageConstraints={imageConstraints}
            onMediaClick={onMediaClick}
          />
        ))
    }, [medias, store.organismId, selectedMediaId, imageConstraints, onMediaClick])

    const allItemsFetched = useMemo(() => medias.length >= totalCount, [medias, totalCount])

    const currentPage = useMemo(
      () => (pageSize > 0 ? Math.ceil(medias.length / pageSize) : 0),
      [pageSize, medias.length],
    )

    const loadMedias = useCallback(async () => {
      if (loading || allItemsFetched) {
        return
      }

      await loadMore(currentPage)
    }, [loading, allItemsFetched, loadMore, currentPage])

    return (
      <ScrollerContainer>
        <MediaListLoadMoreScroller
          key={scrollerKey}
          pageStart={0}
          loadMore={loadMedias}
          hasMore={!allItemsFetched}
          useWindow={false}
          // initialLoad={initialLoad}
        >
          <MediaView>{items}</MediaView>

          <Loader loading={loading} />
        </MediaListLoadMoreScroller>
      </ScrollerContainer>
    )
  },
)

export default MediaListView
