import { DragDropContext, Draggable, Droppable, DropResult } from '@hello-pangea/dnd'
import RefreshButton from 'Components/Button/RefreshButton'
import Loader from 'Components/Loader'
import PageHeader from 'Components/PageHeader'
import ConfirmationButton from 'Containers/Button/ConfirmationButton'
import { useGetSports, useMoveSportFrontPage, useUpdateSport } from 'models'
import React, { useEffect, useMemo, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { DropdownItemProps, Form as UIForm, Grid, Icon, Ref, Table } from 'semantic-ui-react'
import { Permission, Sport } from 'services/api/graphql'
import { useCurrentUser } from 'stores'

const SportsFrontPages = () => {
  const intl = useIntl()
  const currentUser = useCurrentUser()

  const [frontPages, setFrontPages] = useState<Sport[]>([])
  const [sportsOptions, setSportsOptions] = useState<DropdownItemProps[]>([])
  const {
    sports,
    loading: isLoadingSports,
    refetch,
  } = useGetSports({
    fetchPolicy: 'cache-and-network',
  })
  const [updateSport, { loading: isUpdating }] = useUpdateSport()
  const [moveSportFrontPage, { loading: isMoving }] = useMoveSportFrontPage()

  useEffect(() => {
    if (!sports) return

    setFrontPages(sports.filter(sport => sport.onFront).sort((a, b) => (a.frontPosition ?? 0) - (b.frontPosition ?? 0)))
    setSportsOptions(
      sports
        .filter(sport => !sport.onFront)
        .map(sport => ({
          value: sport.id,
          text: sport.title,
        })),
    )
  }, [sports])

  const handleAddFrontPage = async (values: any) => {
    const { sportId } = values
    if (!sportId) return

    await updateSport({
      variables: {
        input: {
          id: sportId,
          onFront: true,
        },
      },
    })
  }

  const onMoveItem = async (result: DropResult) => {
    const { draggableId, destination } = result
    if (!destination || !sports) return

    await moveSportFrontPage({
      variables: {
        input: { id: draggableId, frontPosition: destination.index },
      },
    })
  }

  const handleRemoveFrontPage = async (sportId: Sport['id']) => {
    if (!sportId) return

    await updateSport({
      variables: {
        input: {
          id: sportId,
          onFront: false,
        },
      },
    })
  }

  const loading = useMemo(() => isLoadingSports || isUpdating || isMoving, [isLoadingSports, isUpdating, isMoving])

  return (
    <Loader loading={loading}>
      <Grid padded>
        <Grid.Column>
          <PageHeader title={intl.formatMessage({ id: 'sport.frontPage.pageTitle' })}>
            <RefreshButton onClick={() => refetch()} loading={loading} />
          </PageHeader>

          <Form
            onSubmit={handleAddFrontPage}
            render={({ handleSubmit }) => (
              <UIForm onSubmit={handleSubmit}>
                <UIForm.Group>
                  <Field
                    name="sportId"
                    render={({ input }) => (
                      <UIForm.Dropdown
                        selection
                        inline
                        placeholder={intl.formatMessage({
                          id: 'sport.frontPage.add.placeholder',
                        })}
                        options={sportsOptions}
                        onChange={(e, { value }) => input.onChange(value)}
                      />
                    )}
                  />
                  <UIForm.Button
                    primary
                    content={intl.formatMessage({
                      id: 'sport.frontPage.add',
                    })}
                  />
                </UIForm.Group>
              </UIForm>
            )}
          />
          <DragDropContext onDragEnd={result => onMoveItem(result)}>
            <Table striped compact celled>
              <Table.Header>
                <Table.Row textAlign="center">
                  <Table.HeaderCell />
                  <Table.HeaderCell>
                    <FormattedMessage id="sport.title" />
                  </Table.HeaderCell>
                  <Table.HeaderCell width={1} textAlign="center">
                    <FormattedMessage id="application.actions" />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Droppable droppableId="frontpages">
                {provided => (
                  <Ref innerRef={provided.innerRef}>
                    <Table.Body {...provided.droppableProps}>
                      {frontPages.map((frontPage, index) => {
                        return (
                          <Draggable key={frontPage.id} draggableId={frontPage.id} index={index}>
                            {draggableProvided => (
                              <Ref innerRef={draggableProvided.innerRef}>
                                <Table.Row {...draggableProvided.draggableProps}>
                                  <Table.Cell
                                    collapsing
                                    textAlign="center"
                                    {...draggableProvided.dragHandleProps}
                                    content={<Icon name="bars" />}
                                  />
                                  <Table.Cell>{frontPage.title}</Table.Cell>
                                  <Table.Cell>
                                    {currentUser.can(Permission.SportUpdate) && (
                                      <ConfirmationButton
                                        action={() => handleRemoveFrontPage(frontPage.id)}
                                        confirmText={intl.formatMessage(
                                          {
                                            id: 'sport.frontPage.delete_confirm',
                                          },
                                          {
                                            sport: frontPage.title,
                                          },
                                        )}
                                        successText={intl.formatMessage(
                                          {
                                            id: 'sport.frontPage.delete_success',
                                          },
                                          {
                                            sport: frontPage.title,
                                          },
                                        )}
                                        onDone={() => {}}
                                      />
                                    )}
                                  </Table.Cell>
                                </Table.Row>
                              </Ref>
                            )}
                          </Draggable>
                        )
                      })}
                    </Table.Body>
                  </Ref>
                )}
              </Droppable>
            </Table>
          </DragDropContext>
        </Grid.Column>
      </Grid>
    </Loader>
  )
}

export default SportsFrontPages
