import GenericForm from 'Components/Form/GenericForm'
import SAFormField from 'Components/Form/SAFormField'
import SwitchContentTypesButtonInput, {
  SwitchContentTypeSelector,
} from 'Containers/Form/SwitchButtonInput/SwitchContentTypesButtonInput'
import React, { useCallback } from 'react'
import { FieldRenderProps } from 'react-final-form'
import { useIntl } from 'react-intl'
import { Form } from 'semantic-ui-react'
import {
  CreateExternalFeedMediaLiveStreamInput,
  ExternalFeedType,
  SportItemContentFragment,
  useCreateExternalFeedMediaLiveStreamMutation,
} from 'services/api/graphql'
import { validateRequired } from 'tools/formValidators'
import { notifyError, notifySuccess } from 'tools/toaster'

export interface CreateExternalFeedLivestreamFormProps {
  open: boolean
  onSubmit: () => void
  onCancel: () => void
  sportItem: SportItemContentFragment
}

export const CreateExternalFeedLivestreamForm: React.FC<CreateExternalFeedLivestreamFormProps> = ({
  open,
  onSubmit,
  onCancel,
  sportItem,
}) => {
  const intl = useIntl()
  const [createExternalFeedLiveStream, { loading: loadingCreateExternalFeedLiveStream }] =
    useCreateExternalFeedMediaLiveStreamMutation()

  const handleSubmit = useCallback(
    async (values: CreateExternalFeedMediaLiveStreamInput) => {
      try {
        await createExternalFeedLiveStream({
          variables: {
            input: {
              media: {
                title: values.media.title,
                sportItemId: sportItem.id,
              },
              externalFeedProvider: values.externalFeedProvider,
              externalFeedUrl: values.externalFeedUrl,
            },
          },
        })
        notifySuccess(intl.formatMessage({ id: 'sportItem.media_updated' }))
        onSubmit()
      } catch (e) {
        notifyError(
          intl.formatMessage({
            id: `sportItem.create_media_error`,
          }),
        )
      }
    },
    [createExternalFeedLiveStream, intl, onSubmit, sportItem.id],
  )
  return (
    <GenericForm
      header={intl.formatMessage({ id: 'sportItemMedia.live.create' })}
      initialValues={{}}
      onCancel={onCancel}
      onSubmit={handleSubmit}
      loading={loadingCreateExternalFeedLiveStream}
      open={open}
    >
      <SAFormField
        name="media.title"
        validate={validateRequired}
        render={({ input, meta }) => (
          <Form.Input
            {...input}
            required
            error={!!meta.touched && !!meta.error}
            label={intl.formatMessage({ id: 'sportItemMedia.live.name' })}
          />
        )}
      />
      <SAFormField
        name="externalFeedProvider"
        validate={validateRequired}
        render={({ input, meta }: FieldRenderProps<ExternalFeedType, HTMLElement>) => (
          <Form.Field
            {...input}
            required
            label={intl.formatMessage({ id: 'sportItem.externalFeed.provider' })}
            error={!!meta.touched && !!meta.error}
            control={() => (
              <SwitchContentTypesButtonInput
                onChange={input.onChange}
                value={input.value}
                typeSelector={SwitchContentTypeSelector.ExternalFeedTypes}
              />
            )}
          />
        )}
      />
      <SAFormField
        name="externalFeedUrl"
        validate={validateRequired}
        render={({ input, meta }) => (
          <Form.Input
            {...input}
            required
            error={!!meta.touched && !!meta.error}
            label={intl.formatMessage({ id: 'sportItem.externalFeed.link' })}
          />
        )}
      />
    </GenericForm>
  )
}
