import { FormBaseProps } from 'Forms/FormBase'
import React from 'react'
import { Organism, OrganismContentFragment, useOrganismQuery, useUpdateOrganismMutation } from 'services/api/graphql'
import { cloneWithoutGraphqlCacheTypenames } from 'tools/graphql'
import { getTranslationInputFromForm, getTranslationInputFromValues } from 'tools/translationTools'

import OrganismForm, { OrganismFormValues } from './OrganismForm'

interface EditOrganismFormBaseProps {
  onCancel?: () => void
  onDone: (organism: OrganismContentFragment) => void
  onError: (error: Error) => void
}
interface EditOrganismFormWithOrganismProps extends FormBaseProps<OrganismContentFragment> {
  organismValues: OrganismFormValues
  editType: boolean
  titleFormat: string // {{title}} will be replaced by organisme title
}
const EditOrganismFormWithOrganism = ({
  organismValues,
  editType,
  titleFormat,
  onCancel,
  onDone,
  onError,
}: EditOrganismFormWithOrganismProps) => {
  const title = titleFormat.replace('{{title}}', organismValues.title)
  const [updateOrganism, { loading }] = useUpdateOrganismMutation()
  return (
    <OrganismForm
      title={title}
      edit
      editType={editType}
      initialValues={organismValues}
      onSubmit={({ defaultLocale: _locale, ...values }, defaultLocale, selectedTranslations) => {
        if (!organismValues.id) {
          throw new Error(`Missing Organism Id !`)
        }

        const titleT = getTranslationInputFromForm(selectedTranslations, values.titleT)
        const descriptionT = getTranslationInputFromForm(selectedTranslations, values.descriptionT)

        updateOrganism({
          variables: {
            input: {
              ...values,
              titleT,
              title: values.titleT?.[defaultLocale] || '',
              descriptionT,
              description: values.descriptionT?.[defaultLocale] || '',
              id: organismValues.id,
            },
          },
        })
          .then(result => {
            if (result && result.data) onDone(result.data.updateOrganism)
          })
          .catch(onError)
      }}
      onCancel={onCancel}
      loading={loading}
    />
  )
}

interface EditOrganismFormProps extends EditOrganismFormBaseProps {
  organismId: Organism['id']
  titleFormat: string
  editType: boolean
}
const EditOrganismForm = ({ organismId, ...props }: EditOrganismFormProps) => {
  const { data } = useOrganismQuery({ variables: { organismId }, fetchPolicy: 'cache-and-network' })
  const organism = data?.organism
  if (!organism) return null

  const { logo, bannerVideo, bannerImage, adminBadge } = organism
  const organismValues: OrganismFormValues = {
    ...getTranslationInputFromValues({ titleT: organism.titleT, descriptionT: organism.descriptionT }),
    id: organism.id,
    defaultLocale: organism.defaultLocale || undefined,
    type: organism.type,
    whiteLabel: !!organism.whiteLabel,
    title: organism.title,
    description: organism.description,
    bannerImageFileId: (bannerImage && bannerImage.id) || undefined,
    adminBadgeId: adminBadge?.id || undefined,
    bannerVideoId: (bannerVideo && bannerVideo.id) || undefined,
    logo: logo && {
      coloredImageFileId: logo.coloredImage && logo.coloredImage.id,
      transparentLightImageFileId: logo.transparentLightImage && logo.transparentLightImage.id,
      transparentDarkImageFileId: logo.transparentDarkImage && logo.transparentDarkImage.id,
    },
    officialWebSiteUrl: organism.officialWebSiteUrl,
    sportIds: organism.sports && organism.sports.map(sport => sport.id),
    forbiddenKeyWords:
      (organism.forbiddenKeyWords && organism.forbiddenKeyWords.map(forbiddenKeyWord => forbiddenKeyWord)) || undefined,
    enableExternalSources: organism.enableExternalSources,
    availableExternalSources: organism.availableExternalSources,
    streamsIds: organism.streamsIds || [],
    wmProfileId: organism.wmProfileId || undefined,
    sveChannelIds: organism.sveChannelIds || [],
    license: organism.license ? cloneWithoutGraphqlCacheTypenames(organism.license) : undefined,
    publicationStatus: organism.publicationStatus || undefined,
    organismPageApplicationDesignId: organism.organismPageApplicationDesignId || null,
    contentPageApplicationDesignId: organism.contentPageApplicationDesignId || null,
    tags: organism.tags ? cloneWithoutGraphqlCacheTypenames(organism.tags) : [],
    visibleInMultisportApplication: organism.visibleInMultisportApplication || false,
    adminsAutomatedEmails: organism.adminsAutomatedEmails || undefined,
  }
  return <EditOrganismFormWithOrganism organismValues={organismValues} {...props} />
}
export default EditOrganismForm
