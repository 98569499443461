import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button, Confirm } from 'semantic-ui-react'

interface CancelFormButtonProps {
  pristine: boolean
  onConfirm: () => void
  onCancel: () => void
}

const CancelFormButton = (props: CancelFormButtonProps) => {
  const intl = useIntl()
  const { pristine, onCancel, onConfirm } = props
  const [open, setOpen] = useState<boolean>(false)

  const onCancelClick = () => {
    if (pristine) {
      onCancel()
      return
    }

    setOpen(true)
  }

  return (
    <>
      <Button negative onClick={onCancelClick}>
        <FormattedMessage id="common.cancel" />
      </Button>
      <Confirm
        size="tiny"
        open={open}
        content={intl.formatMessage({ id: 'common.cancel.sentence' })}
        cancelButton={{ content: intl.formatMessage({ id: 'common.no' }) }}
        confirmButton={{
          content: intl.formatMessage({ id: 'common.yes' }),
          negative: true,
        }}
        onConfirm={() => {
          setOpen(false)
          onConfirm()
          if (onCancel) onCancel()
        }}
        onCancel={() => setOpen(false)}
      />
    </>
  )
}

export default CancelFormButton
