import Loader from 'Components/Loader'
import CreateSportItemForm, {
  CreateMediaLiveStreamExternalFeedOptions,
  CreateMediaLiveStreamOptions,
} from 'Forms/SportItem/CreateSportItemForm'
import { applicationUris } from 'Layout/uris'
import React, { useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate, useParams } from 'react-router-dom'
import { Segment } from 'semantic-ui-react'
import {
  SportEventState,
  SportItem,
  SportItemSetType,
  useCreateMediaVideoClipExternalFeedMutation,
  useSportItemSetWithOrganismQuery,
  VideoContentType,
  VideoEngine,
} from 'services/api/graphql'
import { notifyError, notifySuccess } from 'tools/toaster'

import { useHandleCreateMediaLive } from './SportItemTools'

const AddSportItemButton = () => {
  const intl = useIntl()
  const navigate = useNavigate()

  const { sportItemSetId } = useParams()

  const SportItemSetQuery = useSportItemSetWithOrganismQuery({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { sportItemSetId: sportItemSetId! },
    fetchPolicy: 'cache-and-network',
    skip: !sportItemSetId,
  })

  const sportItemSet = SportItemSetQuery.data?.sportItemSet

  const { handleCreateMediaLive, isLoading: loadingCreateMediaLive } = useHandleCreateMediaLive()
  const [createMediaVideoClipExternalFeed] = useCreateMediaVideoClipExternalFeedMutation()

  const eventEnded = useMemo(
    () => sportItemSet && sportItemSet.sportEvent && sportItemSet.sportEvent.state === SportEventState.Finished,
    [sportItemSet],
  )

  const liveNotAllowed = useMemo(
    () => eventEnded || (sportItemSet && sportItemSet.type === SportItemSetType.Playlist),
    [eventEnded, sportItemSet],
  )

  const handleCreateExternalFeedVideoClip = useCallback(
    async (sportItem: SportItem, creationOptions?: CreateMediaLiveStreamExternalFeedOptions) => {
      if (creationOptions && creationOptions.externalFeedProvider && creationOptions.externalFeedUrl) {
        // Create Media videoClip Player Embed
        await createMediaVideoClipExternalFeed({
          variables: {
            input: {
              media: {
                date: new Date(),
                title: sportItem.title,
                sportItemId: sportItem.id,
              },
              externalFeedProvider: creationOptions.externalFeedProvider,
              externalFeedUrl: creationOptions.externalFeedUrl,
            },
          },
        })
        notifySuccess(intl.formatMessage({ id: 'sportItem.media_created' }))
        setTimeout(() => navigate(applicationUris.sportItemMedias(sportItem.id)), 500)
      }
      // avoid redirect sportItemMedia for videoClip creation
    },
    [createMediaVideoClipExternalFeed, intl, navigate],
  )

  const afterSportItemCreation = useCallback(
    async (sportItem: SportItem, creationOptions?: CreateMediaLiveStreamOptions) => {
      if (sportItem.videoContentType === VideoContentType.Live) {
        if (creationOptions) {
          // Create Media live
          await handleCreateMediaLive(sportItem.id, creationOptions, sportItemSet?.wmClippingTemplateId)
        }
      } else {
        if (creationOptions?.videoEngine === VideoEngine.ExternalFeed) {
          await handleCreateExternalFeedVideoClip(sportItem, creationOptions)
        }
      }
    },
    [handleCreateMediaLive, sportItemSet?.wmClippingTemplateId, handleCreateExternalFeedVideoClip],
  )

  return (
    <Loader loading={loadingCreateMediaLive}>
      <Segment>
        {sportItemSetId && (
          <CreateSportItemForm
            videoContentType={liveNotAllowed ? VideoContentType.Vod : undefined}
            title={intl.formatMessage({ id: 'sportItemSet.add_item' })}
            sportItemSetId={sportItemSetId}
            isLoading={loadingCreateMediaLive}
            onCancel={() => navigate(-1)}
            onDone={async (sportItem, creationOptions) => {
              notifySuccess(
                intl.formatMessage(
                  { id: `sportItem.create_success.${sportItem.videoContentType}` },
                  {
                    title: sportItem.title,
                  },
                ),
              )
              await afterSportItemCreation(sportItem, creationOptions)
              navigate(
                sportItemSet?.type === SportItemSetType.SportEvent
                  ? applicationUris.sportEventItems(sportItemSetId)
                  : applicationUris.playlistItems(sportItemSetId),
                {
                  state: {
                    videoClipOpen:
                      (sportItem.videoContentType === VideoContentType.Vod ||
                        creationOptions?.videoEngine === VideoEngine.ExternalFeed) &&
                      !(creationOptions as CreateMediaLiveStreamExternalFeedOptions)?.externalFeedUrl,
                    sportItemId: sportItem.id,
                  },
                },
              )
            }}
            onError={notifyError}
          />
        )}
      </Segment>
    </Loader>
  )
}

export default AddSportItemButton
