import CheckboxInput from 'Components/Form/CheckboxInput'
import SAFormField from 'Components/Form/SAFormField'
import { SelectUserLocationConditionInput } from 'Containers/Form/SelectUserLocationConditionInput'
import classnames from 'classnames'
import { ValidationErrors } from 'final-form'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Form, Header, Segment } from 'semantic-ui-react'
import { UserLocationCondition } from 'services/api/graphql'
import { validateRequired } from 'tools/formValidators'

export type CacUserLocationRuleFormValues = {
  location: {
    latitude: number
    longitude: number
  }
  distanceKM: number
  condition: UserLocationCondition
}
export type ContentAccessControlUserLocationFormValues = {
  hasUserLocationRule: boolean
  userLocationRule: CacUserLocationRuleFormValues | undefined
}

export interface ContentAccessControlUserLocationFormFieldsProps {
  values: ContentAccessControlUserLocationFormValues
  errors: ValidationErrors
  name: string
}

const ContentAccessControlUserLocationFormFields = ({
  values,
  name,
}: ContentAccessControlUserLocationFormFieldsProps) => {
  const intl = useIntl()

  return (
    <Segment>
      <Header>
        <FormattedMessage id="cac.form.userLocationRule.title" />
      </Header>
      {/* user location checkbox */}
      <SAFormField
        className="bg-red-300"
        name={`${name}.hasUserLocationRule`}
        render={({ input, meta }) => (
          <Form.Field error={!!meta.touched && !!meta.error}>
            <CheckboxInput
              {...input}
              toggle
              checked={values.hasUserLocationRule}
              label={
                <label>
                  <FormattedMessage id="cac.form.hasUserLocationRule" />
                </label>
              }
            />
          </Form.Field>
        )}
      />
      {values.hasUserLocationRule && (
        <div className={classnames({ hidden: false && !values.hasUserLocationRule })}>
          {/* UserLocation */}
          {/* Location */}
          <Form.Group>
            <SAFormField
              name={`${name}.userLocationRule.location.latitude`}
              validate={validateRequired}
              render={({ input, meta }) => (
                <Form.Input
                  required
                  type="number"
                  error={!!meta.touched && !!meta.error}
                  label={intl.formatMessage({
                    id: 'cac.form.userLocationRule.location.latitude',
                  })}
                  {...input}
                  onChange={event => input.onChange(parseFloat(event.target.value))}
                />
              )}
            />
            <SAFormField
              name={`${name}.userLocationRule.location.longitude`}
              validate={validateRequired}
              render={({ input, meta }) => (
                <Form.Input
                  required
                  type="number"
                  error={!!meta.touched && !!meta.error}
                  label={intl.formatMessage({
                    id: 'cac.form.userLocationRule.location.longitude',
                  })}
                  {...input}
                  onChange={event => input.onChange(parseFloat(event.target.value))}
                />
              )}
            />
            {/* Distance */}
            <SAFormField
              name={`${name}.userLocationRule.distanceKM`}
              validate={validateRequired}
              render={({ input, meta }) => (
                <Form.Input
                  required
                  type="number"
                  error={!!meta.touched && !!meta.error}
                  label={intl.formatMessage({
                    id: 'cac.form.userLocationRule.distancekm',
                  })}
                  {...input}
                  onChange={event => input.onChange(parseFloat(event.target.value))}
                />
              )}
            />
          </Form.Group>

          {/* User location condition*/}
          <div className="mb-2">
            <SAFormField
              name={`${name}.userLocationRule.condition`}
              validate={validateRequired}
              render={({ input, meta }) => (
                <Form.Field
                  control={SelectUserLocationConditionInput}
                  {...input}
                  required
                  error={!!meta.touched && !!meta.error}
                  label={intl.formatMessage({
                    id: 'cac.form.userLocationRule.condition',
                  })}
                />
              )}
            />
          </div>
          {values.userLocationRule?.condition && (
            <div className="mb-2">
              {intl.formatMessage({
                id:
                  values.userLocationRule?.condition === UserLocationCondition.InArea
                    ? 'cac.form.userLocationRule.condition.description.inArea'
                    : 'cac.form.userLocationRule.condition.description.outOfArea',
              })}
            </div>
          )}
        </div>
      )}
    </Segment>
  )
}

export default observer(ContentAccessControlUserLocationFormFields)
