import { QueryHookOptions, useQuery } from '@apollo/client'
import {
  Category,
  CREATE_CATEGORY_MUTATION,
  DELETE_CATEGORY_MUTATION,
  GET_CATEGORIES_QUERY,
  MutationCreateCategoryArgs,
  MutationDeleteCategoryArgs,
  MutationUpdateCategoryArgs,
  UPDATE_CATEGORY_MUTATION,
} from 'services/api/graphql'
import { useShortMutation } from 'tools/graphql'

export const useGetCategories = (options?: QueryHookOptions<{ categories: Category[] }>) => {
  const { data, ...res } = useQuery<{ categories: Category[] }>(GET_CATEGORIES_QUERY, options)
  return { categories: data && data.categories, ...res }
}

export const useCreateCategory = () =>
  useShortMutation<
    {
      createCategory: Category
    },
    MutationCreateCategoryArgs
  >(CREATE_CATEGORY_MUTATION)

export const useUpdateCategory = () =>
  useShortMutation<
    {
      updateCategory: Category
    },
    MutationUpdateCategoryArgs
  >(UPDATE_CATEGORY_MUTATION)

export const useDeleteCategory = () =>
  useShortMutation<{ deleteCategory: boolean }, MutationDeleteCategoryArgs>(DELETE_CATEGORY_MUTATION)
