import React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

const MATURE_CONTENT_LOGO_SRC = '/assets/csa_moins16.svg'

const InlineImg = styled.img`
  height: 1.3rem;
  object-fit: contain;
`

const MatureContentLogo: React.FC = () => {
  const intl = useIntl()
  return <InlineImg src={MATURE_CONTENT_LOGO_SRC} alt={intl.formatMessage({ id: 'sportItem.matureContent' })} />
}

export default MatureContentLogo
