import { gql } from '@apollo/client'

import { CONTENT_TRANSLATION_FRAGMENT } from './ContentTranslation'
import { LINKITEM_FRAGMENT } from './Link'
import { ORGANISM_FRAGMENT } from './Organism'
import { TAB_FRAGMENT } from './Tab'

export const SVESTREAM_FRAGMENT = gql`
  fragment sveStreamContent on SVEStream {
    sveChannelId
    socialNetworkWMStreamId
    outputStreamId
  }
`

export const SPORTITEMSET_FRAGMENT = gql`
  fragment sportItemSetContent on SportItemSet {
    __typename
    id
    sportIds
    sports {
      id
      title
      color
    }
    type
    title
    subtitle
    description
    titleT {
      ...contentTranslationContent
    }
    subtitleT {
      ...contentTranslationContent
    }
    descriptionT {
      ...contentTranslationContent
    }
    defaultLocale
    publicationStatus
    ownerOrganismId
    ownerOrganism {
      title
    }
    offerIds
    offers {
      id
      title
    }
    restrictionLevel
    tabs {
      ...tabContent
    }
    websiteUrl
    lockPublishingLive
    lockPublishingClip
    sponsorPlatform {
      link {
        ...linkItemContent
      }
      linkId
      display
      videoPreroll
    }

    items {
      id
      title
    }

    bannerImageFileId
    bannerImage {
      id
      image {
        id
        thumbUrl
        downloadUrl
      }
    }

    bannerVideoId
    bannerVideo {
      id
      videoClip {
        id
        playbackUrl
      }
    }

    testPeriodEnd

    sportEvent {
      startDate
      endDate
      state
      liveTemplateId
      backstageTemplateId
      clipTemplateId
    }

    categoriesIds
    categories {
      id
      title
    }

    legalRestrictions {
      id
      description
      countries
      type
      timeSlots {
        from
        to
      }
    }
    cac {
      userLocationRule {
        location {
          latitude
          longitude
        }
        distance
        condition
      }
      userAuthenticationRule
    }

    videoEngine
    # Wildmoka + Sportall Video Engines
    streams {
      id
      name
    }

    # Wildmoka Video Engine
    streamsIds

    # Sportall Video Engine
    sveStreams {
      ...sveStreamContent
    }
    wmClippingTemplateId
    sveInputLossImageMediaId
    sveOverlayLogoImageMediaId
    visibleInMultisportApplication
    activateSportItemChatByDefault
    contentPushServiceNames
    tag {
      id
      title
    }
    subTags
    activateGreenPlayback
    adCampaignId
    contentPushServiceGenericStreamIds
    contentPushServiceLiveEventIds
    contentPushServiceGenericStreams {
      id
      name
      createdAt
      updatedAt
    }
    noSpoilerMode {
      enabled
      minutesBeforeDisabling
    }
    disableReadParallelLivestreamsLimit
  }
  ${LINKITEM_FRAGMENT}
  ${CONTENT_TRANSLATION_FRAGMENT}
  ${SVESTREAM_FRAGMENT}
  ${TAB_FRAGMENT}
`

const LIGHT_SPORTITEMSET_FRAGMENT = gql`
  fragment lightSportItemSetContent on SportItemSet {
    id
    sportIds
    sports {
      id
      title
      color
    }
    type
    title
    subtitle
    publicationStatus
    ownerOrganismId
    restrictionLevel
    tabs {
      ...tabContent
    }
    websiteUrl
    bannerImageFileId
    bannerImage {
      id
      image {
        id
        thumbUrl
        downloadUrl
      }
    }
    bannerVideoId
    bannerVideo {
      id
      videoClip {
        id
        playbackUrl
      }
    }
    sportEvent {
      startDate
      endDate
      state
    }
    streamsIds
    contentPushServiceNames
    legalRestrictions {
      id
      description
      countries
      type
      timeSlots {
        from
        to
      }
    }
  }
  ${TAB_FRAGMENT}
`
const SPORTITEMSET_WITH_ORGANISM_FRAGMENT = gql`
  fragment sportItemSetWithOrganismContent on SportItemSet {
    ...sportItemSetContent
    ownerOrganism {
      ...organismContent
    }
  }
  ${SPORTITEMSET_FRAGMENT}
  ${ORGANISM_FRAGMENT}
`

/**
 * SportItemSet
 */
export const GET_SPORTITEMSETS_QUERY = gql`
  query sportItemSets(
    $filters: SportItemSetFilters
    $first: Int
    $offset: Int
    $orderBy: [SportItemSetSort!]
    $withItems: Boolean
  ) {
    sportItemSets(filters: $filters, first: $first, offset: $offset, orderBy: $orderBy, withItems: $withItems) {
      sportItemSets {
        ...lightSportItemSetContent
        items {
          id
          title
        }
      }
      totalCount
    }
  }
  ${LIGHT_SPORTITEMSET_FRAGMENT}
`

export const GET_SPORTITEMSET_QUERY = gql`
  query sportItemSet($sportItemSetId: ID!) {
    sportItemSet(id: $sportItemSetId) {
      ...sportItemSetContent
    }
  }
  ${SPORTITEMSET_FRAGMENT}
`

export const GET_SPORTITEMSET_WITH_ORGANISM_QUERY = gql`
  query sportItemSetWithOrganism($sportItemSetId: ID!) {
    sportItemSet(id: $sportItemSetId) {
      ...sportItemSetWithOrganismContent
    }
  }
  ${SPORTITEMSET_WITH_ORGANISM_FRAGMENT}
`

export const GET_SPORTITEMSETS_WITH_CHILDREN_QUERY = gql`
  query sportItemSetsWithChildren($filters: SportItemSetFilters) {
    sportItemSets(filters: $filters) {
      sportItemSets {
        id
        type
        ownerOrganismId
        title
        items {
          id
          videoContentType
          title
        }
      }
      totalCount
    }
  }
`

export const GET_SPORTITEMSET_HAS_LIVES_RUNNING = gql`
  query sportItemSetHasLivesRunning($sportItemSetId: ID, $filters: SportItemFilters) {
    sportItemSet(id: $sportItemSetId) {
      id
      items(filters: $filters) {
        id
      }
    }
  }
`

export const GET_SPORTITEMSET_AVAILABLE_SVE_CHANEL_IDS_QUERY = gql`
  query sportItemSetAvailableSVEChannelIds($sportItemSetId: ID!) {
    sportItemSet(id: $sportItemSetId) {
      availableSVEChannelIds
    }
  }
`

export const CREATE_SPORTITEMSET_MUTATION = gql`
  mutation createSportItemSet($input: CreateSportItemSetInput!) {
    createSportItemSet(input: $input) {
      ...sportItemSetContent
    }
  }
  ${SPORTITEMSET_FRAGMENT}
`

export const UPDATE_SPORTITEMSET_MUTATION = gql`
  mutation updateSportItemSet($input: UpdateSportItemSetInput!) {
    updateSportItemSet(input: $input) {
      ...sportItemSetContent
    }
  }
  ${SPORTITEMSET_FRAGMENT}
`

export const DELETE_SPORTITEMSET_MUTATION = gql`
  mutation deleteSportItemSet($sportItemSetId: ID!) {
    deleteSportItemSet(id: $sportItemSetId) {
      id
    }
  }
`

export const CHECK_STREAMS_AVAILABLE_QUERY = gql`
  query checkStreamsAvailable($streamsIds: [WildmokaID!]!, $from: Date!, $to: Date!) {
    checkStreamsAvailable(streamsIds: $streamsIds, from: $from, to: $to) {
      streamId
      sportItemSet {
        id
        title
      }
    }
  }
`

export const CHECK_SVECHANNELS_AVAILABLE_QUERY = gql`
  query checkSVEChannelsAvailable($input: CheckSVEChannelsAvailableInput!) {
    checkSVEChannelsAvailable(input: $input) {
      id
      name
      status
      available
    }
  }
  ${SVESTREAM_FRAGMENT}
`
