import { gql } from '@apollo/client'

import { FILE_FRAGMENT } from './File'
import { LIVESTREAM_FRAGMENT } from './LiveStream'
import { VIDEOCLIP_FRAGMENT } from './VideoClip'

export const MEDIA_FRAGMENT = gql`
  fragment mediaContent on Media {
    id
    createdAt
    updatedAt
    title
    type
    publicationStatus
    tags
    date
    startTrim
    endTrim
    liveStreamId
    liveStream {
      ...liveStreamContent
    }
    videoClipId
    videoClip {
      ...videoClipContent
    }
    imageId
    image {
      ...fileContent
    }
    thumbnailId
    thumbnail {
      id
      image {
        ...fileContent
      }
    }
    ownerOrganismId
    pinned
    timelineMarkers {
      title
      time
      icon
    }
  }

  ${LIVESTREAM_FRAGMENT}
  ${VIDEOCLIP_FRAGMENT}
  ${FILE_FRAGMENT}
`

export const MEDIA_LIGHT_FRAGMENT = gql`
  fragment mediaLightContent on Media {
    id
    title
    type
    publicationStatus
    tags
    date
    startTrim
    endTrim
    thumbnailId
    ownerOrganismId
    pinned
    timelineMarkers {
      title
      time
      icon
    }
  }
`

export const GENERATE_MEDIA_REPLAY_MUTATION = gql`
  mutation generateMediaReplay($id: ID!, $input: GenerateMediaReplayInput) {
    generateMediaReplay(id: $id, input: $input)
  }
`

export const DELETE_MEDIA_MUTATION = gql`
  mutation deleteMedia($mediaId: ID!) {
    deleteMedia(id: $mediaId)
  }
`

export const UPDATE_MEDIA_MUTATION = gql`
  mutation updateMedia($mediaId: ID!, $input: UpdateMediaInput!) {
    updateMedia(id: $mediaId, input: $input) {
      ...mediaLightContent
    }
  }

  ${MEDIA_LIGHT_FRAGMENT}
`

export const GET_MEDIAS_QUERY = gql`
  query medias($type: MediaType, $search: String, $first: Int, $offset: Int, $organismId: ID) {
    medias(type: $type, search: $search, first: $first, offset: $offset, organismId: $organismId) {
      totalCount
      medias {
        ...mediaContent
      }
    }
  }
  ${MEDIA_FRAGMENT}
`

export const GET_MEDIA_QUERY = gql`
  query media($mediaId: ID!) {
    media(id: $mediaId) {
      ...mediaContent
    }
  }
  ${MEDIA_FRAGMENT}
`

export const CREATE_WILDMOKA_MEDIA_LIVESTREAM_MUTATION = gql`
  mutation createWildmokaMediaLiveStream($input: CreateWildmokaMediaLiveStreamInput!) {
    createWildmokaMediaLiveStream(input: $input) {
      ...mediaContent
    }
  }

  ${MEDIA_FRAGMENT}
`

export const CREATE_SVE_MEDIA_LIVESTREAM_MUTATION = gql`
  mutation createSVEMediaLiveStream($input: CreateSVEMediaLiveStreamInput!) {
    createSVEMediaLiveStream(input: $input) {
      ...mediaContent
    }
  }

  ${MEDIA_FRAGMENT}
`

export const CREATE_PLAYER_EMBED_MEDIA_LIVESTREAM_MUTATION = gql`
  mutation createExternalFeedMediaLiveStream($input: CreateExternalFeedMediaLiveStreamInput!) {
    createExternalFeedMediaLiveStream(input: $input) {
      ...mediaContent
    }
  }

  ${MEDIA_FRAGMENT}
`

export const CREATE_MEDIA_VIDEO_CLIP_MUTATION = gql`
  mutation createMediaVideoClip($input: CreateMediaVideoClipInput!) {
    createMediaVideoClip(input: $input) {
      ...mediaContent
    }
  }

  ${MEDIA_FRAGMENT}
`

export const CREATE_MEDIA_VIDEO_CLIP_PLAYER_EMBED_MUTATION = gql`
  mutation createMediaVideoClipExternalFeed($input: CreateMediaVideoClipExternalFeedInput!) {
    createMediaVideoClipExternalFeed(input: $input) {
      ...mediaContent
    }
  }

  ${MEDIA_FRAGMENT}
`

export const CREATE_MEDIA_FILE_MUTATION = gql`
  mutation createMediaFile($input: CreateMediaFileInput!) {
    createMediaFile(input: $input) {
      ...mediaContent
    }
  }
  ${MEDIA_FRAGMENT}
`

export const TOGGLE_PIN_MUTATION = gql`
  mutation togglePin($id: ID!) {
    togglePin(id: $id)
  }
`
