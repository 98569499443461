import { SelectedTranslationsType } from 'Components/Form/SelectLocalInput'
import { tabsToApi, tabsToForm } from 'Forms/Tab/utils'
import { isNil } from 'lodash'
import {
  CreateSportItemSetInput,
  SportEvent,
  SportEventCreateInput,
  SportEventUpdateInput,
  SportItemSet,
  SportItemSetType,
  UpdateSportItemSetInput,
} from 'services/api/graphql'
import { getTranslationInputFromForm, getTranslationInputFromValues } from 'tools/translationTools'

import {
  ContentAccessControl_APIToFormValues,
  ContentAccessControl_FormValuesToAPI,
} from '../ContentAccessControl/UserLocationRule/UserLocation.transformers'

import { SportItemSetFormValues, SportItemSetSportEventFormValues } from './SportItemSetForm'

export const SportItemSetSportEvent_APIToFormValues = ({
  startDate,
  endDate,
  liveTemplateId,
  clipTemplateId,
  backstageTemplateId,
}: SportEvent): SportItemSetSportEventFormValues => {
  return {
    period: {
      startDate: new Date(startDate),
      endDate: new Date(endDate),
    },
    liveTemplateId,
    clipTemplateId,
    backstageTemplateId,
  }
}

export const SportItemSetSportEvent_FormValuesAPI_Update = ({
  period,
  ...data
}: SportItemSetSportEventFormValues): SportEventUpdateInput => ({
  ...data,
  startDate: period.startDate,
  endDate: period.endDate,
})

export const SportItemSetSportEvent_FormValuesAPI_Create = ({
  period,
  ...data
}: SportItemSetSportEventFormValues): SportEventCreateInput => ({
  ...data,
  startDate: period.startDate,
  endDate: period.endDate,
})

export const SportItemSet_APIToFormValues = ({
  id,
  title,
  subtitle,
  description,
  bannerImageFileId,
  streamsIds,
  sportIds,
  bannerVideoId,
  sportEvent,
  offerIds,
  lockPublishingLive,
  lockPublishingClip,
  cac,
  categoriesIds,
  publicationStatus,
  restrictionLevel,
  type,
  ownerOrganismId,
  testPeriodEnd,
  tabs,
  websiteUrl,
  videoEngine,
  sveStreams,
  wmClippingTemplateId,
  sveInputLossImageMediaId,
  sveOverlayLogoImageMediaId,
  visibleInMultisportApplication,
  activateSportItemChatByDefault,
  contentPushServiceNames,
  tag,
  subTags,
  activateGreenPlayback,
  adCampaignId,
  noSpoilerMode,
  ...sportItemSet
}: SportItemSet): Partial<SportItemSetFormValues> => ({
  ...getTranslationInputFromValues<Partial<SportItemSet>>({
    titleT: sportItemSet.titleT,
    subtitleT: sportItemSet.subtitleT,
    descriptionT: sportItemSet.descriptionT || undefined,
  }),
  id,
  publicationStatus,
  restrictionLevel,
  defaultLocale: sportItemSet.defaultLocale || undefined,
  title,
  categoriesIds,
  type,
  ownerOrganismId: ownerOrganismId || null,
  testPeriodEnd: testPeriodEnd || null,
  tabs: tabsToForm(tabs),
  websiteUrl: websiteUrl || null,
  subtitle: subtitle ?? undefined,
  description: description ?? undefined,
  bannerImageFileId: bannerImageFileId ?? undefined,
  streamsIds: streamsIds ?? undefined,
  sportIds: sportIds ?? undefined,
  bannerVideoId: bannerVideoId ?? undefined,
  sportEvent: sportEvent ? SportItemSetSportEvent_APIToFormValues(sportEvent) : undefined,
  offerIds: offerIds ?? undefined,
  lockPublishingLive: !isNil(lockPublishingLive) ? lockPublishingLive : undefined,
  lockPublishingClip: !isNil(lockPublishingClip) ? lockPublishingClip : undefined,
  cac: {
    userLocationRule: cac
      ? ContentAccessControl_APIToFormValues(cac)
      : { hasUserLocationRule: false, userLocationRule: undefined },
    userAuthenticationRule: cac?.userAuthenticationRule || undefined,
  },
  videoEngine,
  sveStreams,
  wmClippingTemplateId: wmClippingTemplateId || undefined,
  sveInputLossImageMediaId,
  sveOverlayLogoImageMediaId,
  visibleInMultisportApplication,
  activateSportItemChatByDefault,
  contentPushServiceNames: contentPushServiceNames ?? undefined,
  tagId: tag?.id || undefined,
  subTags: subTags || undefined,
  activateGreenPlayback: activateGreenPlayback || false,
  adCampaignId: adCampaignId ?? undefined,
  contentPushServiceGenericStreamIds: sportItemSet?.contentPushServiceGenericStreamIds || [],
  contentPushServiceLiveEventIds: sportItemSet?.contentPushServiceLiveEventIds || [],
  noSpoilerMode: {
    enabled: noSpoilerMode?.enabled || false,
    minutesBeforeDisabling: noSpoilerMode?.minutesBeforeDisabling || 0,
  },
  disableReadParallelLivestreamsLimit: sportItemSet?.disableReadParallelLivestreamsLimit ?? undefined,
})

export const SportItemSet_FormValuesToAPI_Update = (
  {
    id,
    type,
    sportIds,
    title,
    subtitle,
    description,
    titleT,
    subtitleT,
    descriptionT,
    publicationStatus,
    bannerImageFileId,
    bannerVideoId,
    ownerOrganismId,
    streamsIds,
    testPeriodEnd,
    sportEvent,
    websiteUrl,
    categoriesIds,
    lockPublishingLive,
    lockPublishingClip,
    offerIds,
    restrictionLevel,
    cac,
    sveStreams,
    wmClippingTemplateId,
    sveInputLossImageMediaId,
    sveOverlayLogoImageMediaId,
    visibleInMultisportApplication,
    activateSportItemChatByDefault,
    contentPushServiceNames,
    tagId,
    subTags,
    activateGreenPlayback,
    adCampaignId,
    contentPushServiceGenericStreamIds,
    contentPushServiceLiveEventIds,
    noSpoilerMode,
    tabs,
    disableReadParallelLivestreamsLimit,
  }: SportItemSetFormValues,
  selectedTranslations: SelectedTranslationsType[],
): UpdateSportItemSetInput => {
  if (isNil(id)) {
    throw new Error(`No id`)
  }

  return {
    titleT: getTranslationInputFromForm(selectedTranslations, titleT),
    subtitleT: getTranslationInputFromForm(selectedTranslations, subtitleT),
    descriptionT: getTranslationInputFromForm(selectedTranslations, descriptionT),
    ownerOrganismId,
    streamsIds,
    testPeriodEnd,
    type,
    sportIds,
    title,
    subtitle,
    description,
    publicationStatus,
    bannerImageFileId,
    bannerVideoId,
    id,
    sportEvent: sportEvent && SportItemSetSportEvent_FormValuesAPI_Update(sportEvent),
    tabs: tabsToApi(tabs, selectedTranslations),
    websiteUrl,
    categoriesIds,
    lockPublishingLive,
    lockPublishingClip,
    offerIds,
    restrictionLevel,
    cac: {
      userLocationRule: cac && ContentAccessControl_FormValuesToAPI(cac.userLocationRule).userLocationRule,
      userAuthenticationRule: cac.userAuthenticationRule,
    },
    sveStreams: sveStreams.map(({ __typename, socialNetworkWMStreamId, ...sveStream }) => ({
      socialNetworkWMStreamId: socialNetworkWMStreamId ?? null,
      ...sveStream,
    })),
    wmClippingTemplateId: wmClippingTemplateId || undefined,
    sveInputLossImageMediaId,
    sveOverlayLogoImageMediaId,
    visibleInMultisportApplication,
    activateSportItemChatByDefault,
    contentPushServiceNames,
    tagId,
    subTags,
    activateGreenPlayback,
    adCampaignId: adCampaignId || null,
    contentPushServiceGenericStreamIds: contentPushServiceGenericStreamIds || [],
    contentPushServiceLiveEventIds: contentPushServiceLiveEventIds || [],
    noSpoilerMode: {
      enabled: noSpoilerMode?.enabled || false,
      minutesBeforeDisabling: noSpoilerMode?.minutesBeforeDisabling || 0,
    },
    disableReadParallelLivestreamsLimit,
  }
}

export const SportItemSet_FormValuesToAPI_Create = (
  { sportEvent, cac, tabs, ...data }: SportItemSetFormValues,
  defaultLocale: string,
  selectedTranslations: SelectedTranslationsType[],
): CreateSportItemSetInput => {
  const { titleT, subtitleT, descriptionT, noSpoilerMode, ...values } = data

  return {
    ...values,
    titleT: getTranslationInputFromForm(selectedTranslations, titleT),
    subtitleT: getTranslationInputFromForm(selectedTranslations, subtitleT),
    descriptionT: getTranslationInputFromForm(selectedTranslations, descriptionT),
    title: data.titleT?.[defaultLocale] || '',
    description: data.descriptionT?.[defaultLocale] || '',
    subtitle: data.subtitleT?.[defaultLocale] || '',
    sportEvent: sportEvent && SportItemSetSportEvent_FormValuesAPI_Create(sportEvent),
    cac: {
      userLocationRule: cac ? ContentAccessControl_FormValuesToAPI(cac.userLocationRule).userLocationRule : undefined,
      userAuthenticationRule: cac.userAuthenticationRule,
    },
    tabs: tabsToApi(tabs, selectedTranslations),
    ...(data.type === SportItemSetType.SportEvent && {
      noSpoilerMode: {
        enabled: noSpoilerMode?.enabled || false,
        minutesBeforeDisabling: noSpoilerMode?.minutesBeforeDisabling || 0,
      },
    }),
  }
}
