import CheckboxInput from 'Components/Form/CheckboxInput'
import GenericForm from 'Components/Form/GenericForm'
import SAFormField from 'Components/Form/SAFormField'
import SelectLocalInput, { SelectedTranslationsType, TranslatedInput } from 'Components/Form/SelectLocalInput'
import MatureContentLogo from 'Components/MatureContentLogo'
import FileInput from 'Containers/Form/FileInput'
import SelectPublicationStatusInput from 'Containers/Form/SelectPublicationStatusInput'
import SelectVideoTypePlaylist, { VideoTypePlaylist } from 'Containers/Form/SelectVideoTypePlaylistInput'
import SelectVideoContentTypeInput, {
  SwitchContentTypeSelector,
} from 'Containers/Form/SwitchButtonInput/SwitchContentTypesButtonInput'
import SwitchContentTypesButtonInput from 'Containers/Form/SwitchButtonInput/SwitchContentTypesButtonInput'
import { ContentAccessControlUserAuthenticationFormFields } from 'Forms/ContentAccessControl/UserAuthenticationRule/UserAuthenticationFormFields'
import { ValidationErrors } from 'final-form'
import { collection, CollectionReference, getFirestore, limit, onSnapshot, query, where } from 'firebase/firestore'
import { observer } from 'mobx-react-lite'
import { useGetOrganismOffers } from 'models'
import { ChatRoom } from 'models/ChatRoom'
import { useGetAvailableContentPushServices } from 'models/ContentPushStream'
import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FieldInputProps, FieldRenderProps } from 'react-final-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { Form, Header, Icon, Segment, TextArea } from 'semantic-ui-react'
import {
  AvailableContentPushService,
  CacUserAuthenticationRule,
  ContentPushStreamServiceName,
  File,
  MediaType,
  Permission,
  ExternalFeedType,
  PremiumAccess,
  PublicationStatus,
  PurchasableOffer,
  SportItemContentType,
  SportItemSet,
  SportItemSetContentFragment,
  SportItemSetType,
  useSportItemSetWithOrganismQuery,
  VideoContentType,
  VideoEngine,
} from 'services/api/graphql'
import { useCurrentUser } from 'stores'
import styled from 'styled-components'
import { validateRequired } from 'tools/formValidators'
import { isNotNullOrUndefined } from 'tools/tools'
import { validateTranslation } from 'tools/translationTools'

import { SportEventItemFieldSet, SportEventItemFormValues } from './SportEventItemFieldSet'

export type SportItemFormValues = {
  id?: string // must be set when editing
  titleT?: TranslatedInput | null
  subtitleT?: TranslatedInput | null
  descriptionT?: TranslatedInput | null
  defaultLocale?: string | null | undefined
  coverImageFileId: File['id']
  videoContentType: VideoContentType
  contentType?: SportItemContentType
  publicationStatus: PublicationStatus
  matureContent: boolean
  sportEventItem?: SportEventItemFormValues | null
  reverseRestriction?: boolean
  cac?: {
    userAuthenticationRule?: CacUserAuthenticationRule
  }
  chatActivated?: boolean
  modeRadio?: boolean
  contentPushServiceNamesExcluded?: ContentPushStreamServiceName[]

  // Player Embed is out of SportEventItem as it should work for both lives and VOD
  externalFeedProvider?: ExternalFeedType
  externalFeedUrl?: string

  // Player Embed or VOD for playlist
  videoTypePlaylist?: VideoTypePlaylist
}

const MatureContentLabel = styled.div`
  display: flex;
  align-items: center;

  > * + * {
    margin-left: 0.6rem;
  }
`

interface SportItemFormProps {
  title: string
  videoContentType?: VideoContentType
  edit: boolean // if true => Update Sport otherwise Add Sport
  editType: boolean
  isModal: boolean
  initialValues?: Partial<SportItemFormValues>
  onChangeDefaultLocale: (value: string) => void
  onSubmit: (values: SportItemFormValues, selectedTranslations: SelectedTranslationsType[]) => Promise<void> | void
  onCancel?: () => void
  loading: boolean
  open?: boolean
  onChangeSelectedTranslations: (newCountryTranslation: SelectedTranslationsType[]) => void
  selectedTranslations: SelectedTranslationsType[]
  defaultLocale: string
  videoEngine: VideoEngine
  sportItemSetId: SportItemSet['id']
  sportItemSetType?: SportItemSet['type']
}

const SportItemForm = observer(
  ({
    videoContentType,
    edit,
    editType,
    isModal,
    initialValues,
    onChangeSelectedTranslations,
    selectedTranslations,
    defaultLocale,
    onChangeDefaultLocale,
    onSubmit,
    onCancel,
    loading,
    title,
    videoEngine,
    open = true,
    sportItemSetId,
  }: SportItemFormProps) => {
    const { data: sportItemSetData } = useSportItemSetWithOrganismQuery({
      variables: { sportItemSetId },
      fetchPolicy: 'cache-and-network',
    })
    const sportItemSet = sportItemSetData?.sportItemSet
    const intl = useIntl()
    const currentUser = useCurrentUser()
    const [sportEventValid, setSportEventValid] = useState(true)
    const [sportEventLoading, setSportEventLoading] = useState(false)
    const [activeTranslation, setActiveTranslation] = useState<string | undefined>(defaultLocale)
    const [isChatActive, setIsChatActive] = useState<boolean>(
      (!edit && sportItemSet?.activateSportItemChatByDefault) || false,
    )
    const roomChatActive = useRef<boolean>()

    const { offers } = useGetOrganismOffers(sportItemSet?.ownerOrganismId ?? undefined, {
      skip: !sportItemSet?.ownerOrganismId,
    })

    const { data: contentPushServicesData } = useGetAvailableContentPushServices()

    const contentPushServices = useMemo(
      () =>
        contentPushServicesData.filter(el =>
          sportItemSet?.contentPushServiceNames?.includes(el.type as ContentPushStreamServiceName),
        ),
      [contentPushServicesData, sportItemSet?.contentPushServiceNames],
    )

    const showContentShares = useMemo(
      () =>
        contentPushServices &&
        sportItemSet?.contentPushServiceNames &&
        contentPushServices
          .filter(service => service.customDestination)
          .some(service =>
            sportItemSet?.contentPushServiceNames?.includes(service.type as ContentPushStreamServiceName),
          ) &&
        currentUser.can(Permission.IncludeContentStream, sportItemSet.ownerOrganismId || undefined, true),
      [contentPushServices, currentUser, sportItemSet?.contentPushServiceNames, sportItemSet?.ownerOrganismId],
    )

    useEffect(() => {
      if (!initialValues?.id) {
        return
      }
      const roomsRef = collection(getFirestore(), 'rooms') as CollectionReference<ChatRoom>
      const roomsQuery = query<ChatRoom>(roomsRef, where('sportItemId', '==', initialValues?.id), limit(1))
      let chatRoomFound = false
      const unsubscribe = onSnapshot<ChatRoom>(roomsQuery, snapshot => {
        snapshot.docChanges().forEach(({ doc }) => {
          chatRoomFound = true
          setIsChatActive(doc.data().active)
          roomChatActive.current = doc.data().active
        })
      })
      if (!chatRoomFound) {
        roomChatActive.current = false
      }
      return () => {
        unsubscribe()
      }
    }, [initialValues?.id])

    const validate = useCallback(
      (values: SportItemFormValues): ValidationErrors | Promise<ValidationErrors> | undefined => {
        return {
          titleT: validateTranslation(activeTranslation, values?.titleT?.[`${defaultLocale}`], intl),
          descriptionT: validateTranslation(
            activeTranslation,
            values?.descriptionT?.[`${defaultLocale}`] || undefined,
            intl,
          ),
          subtitleT: validateTranslation(activeTranslation, values?.subtitleT?.[`${defaultLocale}`], intl),
          sportEvent: values.videoContentType === VideoContentType.Live && !sportEventValid ? true : undefined,
        }
      },
      [activeTranslation, defaultLocale, intl, sportEventValid],
    )

    const eventStarted = useMemo(
      () =>
        (edit &&
          initialValues &&
          initialValues.sportEventItem &&
          new Date(initialValues.sportEventItem.startDate) < new Date()) ||
        false,
      [edit, initialValues],
    )
    const eventEnded = useMemo(
      () =>
        (edit &&
          initialValues &&
          initialValues.sportEventItem &&
          new Date(initialValues.sportEventItem.stopDate) < new Date()) ||
        false,
      [edit, initialValues],
    )

    const isPublicationLocked = useCallback(
      (sportItemSet: SportItemSetContentFragment, videoContentType: VideoContentType) => {
        return (
          ((videoContentType === VideoContentType.Live && sportItemSet.lockPublishingLive) ||
            ((videoContentType === VideoContentType.Vod || videoContentType === VideoContentType.Replay) &&
              sportItemSet.lockPublishingClip) ||
            false) &&
          !currentUser.can(Permission.SportItemSetManagePublication, sportItemSet.ownerOrganismId || undefined, true)
        )
      },
      [currentUser],
    )

    const getRestrictionStatus = useCallback(
      (sportItemSet: SportItemSetContentFragment, videoContentType: VideoContentType): PremiumAccess => {
        if (
          !offers ||
          (sportItemSet.restrictionLevel === PremiumAccess.LiveReplay && videoContentType === VideoContentType.Vod)
        ) {
          return PremiumAccess.None
        }
        return sportItemSet.restrictionLevel
      },
      [offers],
    )

    const getSportItemSetOffers = useCallback(
      (sportItemSet: SportItemSetContentFragment): PurchasableOffer[] => {
        return (
          (offers &&
            sportItemSet.offerIds
              .map(offerId => offers.find(offer => offer.id === offerId))
              .filter(isNotNullOrUndefined)) ||
          []
        )
      },
      [offers],
    )

    const submitForm = useCallback(
      (values: SportItemFormValues) => {
        onSubmit(
          {
            ...values,
            publicationStatus: values.publicationStatus || PublicationStatus.Draft,
            sportEventItem: values.videoContentType === VideoContentType.Live ? values.sportEventItem : undefined,
            chatActivated: isChatActive !== roomChatActive.current ? isChatActive : undefined,
          },
          selectedTranslations,
        )
      },
      [isChatActive, onSubmit, selectedTranslations],
    )

    const possibleSVEChannelIds = useMemo(
      () => sportItemSet?.sveStreams.map(({ sveChannelId }) => sveChannelId) ?? [],
      [sportItemSet?.sveStreams],
    )

    const onChangeContentPushServiceNamesExcluded = useCallback(
      (
        value: boolean,
        input: FieldInputProps<SportItemFormValues, HTMLSelectElement>,
        values: SportItemFormValues,
        serviceName: AvailableContentPushService,
      ) => {
        if (value) {
          input.onChange([...(values?.contentPushServiceNamesExcluded || []), serviceName.type])
        } else if (values?.contentPushServiceNamesExcluded?.length) {
          const temp = values?.contentPushServiceNamesExcluded
          input.onChange(temp.filter(str => str !== serviceName.type))
        }
      },
      [],
    )

    return (
      <GenericForm
        header={title}
        initialValues={
          videoContentType ? ({ ...initialValues, videoContentType } as SportItemFormValues) : initialValues
        } // Force type value if given as param
        onCancel={onCancel}
        onSubmit={submitForm}
        isModal={isModal}
        validate={validate}
        loading={loading || sportEventLoading}
        open={open}
      >
        {({ values }) => {
          if (!sportItemSet) return null

          const restrictionStatus = sportItemSet ? getRestrictionStatus(sportItemSet, values.videoContentType) : []
          const sportItemSetOffers = sportItemSet ? getSportItemSetOffers(sportItemSet) : []
          const sportItemSetType = (sportItemSet && sportItemSet.type) || SportItemSetType.SportEvent

          const reverseRestrictionValue = values.reverseRestriction
          const reverseIsLocked =
            (!!reverseRestrictionValue && restrictionStatus === PremiumAccess.None) ||
            (!reverseRestrictionValue && restrictionStatus !== PremiumAccess.None)

          const externalFeedRequired =
            values.videoContentType === VideoContentType.Live ||
            values?.videoTypePlaylist === VideoTypePlaylist.ExternalFeed

          return (
            <Fragment>
              <SAFormField
                name="locale"
                render={({ input }) => (
                  <Form.Field
                    {...input}
                    disabled={false}
                    label={intl.formatMessage({ id: 'locale' })}
                    control={() => (
                      <SelectLocalInput
                        defaultLocale={defaultLocale}
                        onChangeDefaultLocale={onChangeDefaultLocale}
                        activeTranslation={activeTranslation}
                        onChangeActiveTranslation={setActiveTranslation}
                        selectedTranslations={selectedTranslations}
                        onChangeSelectedTranslations={onChangeSelectedTranslations}
                      />
                    )}
                  />
                )}
              />
              {!edit && editType && (
                <SAFormField
                  name="videoContentType"
                  validate={validateRequired}
                  render={({ input, meta }) => (
                    <Form.Field
                      {...input}
                      required
                      control={() => (
                        <SelectVideoContentTypeInput
                          onChange={input.onChange}
                          value={input.value}
                          typeSelector={SwitchContentTypeSelector.VideoContentTypes}
                        />
                      )}
                      disabled={values.videoContentType === VideoContentType.Replay}
                      error={meta.touched && meta.error}
                      label={intl.formatMessage({ id: 'sportItem.videoContentType' })}
                      description={intl.formatMessage({
                        id: 'sportItem.videoContentType.description',
                      })}
                    />
                  )}
                />
              )}
              {!edit && sportItemSetType === SportItemSetType.Playlist && (
                <SAFormField
                  name="videoTypePlaylist"
                  validate={validateRequired}
                  render={({ input, meta }) => (
                    <Form.Field
                      control={SelectVideoTypePlaylist}
                      {...input}
                      required
                      error={meta.touched && meta.error}
                      label={intl.formatMessage({
                        id: 'sportItem.playlistMediaType',
                      })}
                    />
                  )}
                />
              )}
              {sportItemSet &&
                values.videoContentType &&
                !isPublicationLocked(sportItemSet, values.videoContentType) && (
                  <SAFormField
                    name="publicationStatus"
                    validate={validateRequired}
                    render={({ input, meta }) => (
                      <Form.Field
                        control={SelectPublicationStatusInput}
                        {...input}
                        required
                        error={meta.touched && meta.error}
                        label={intl.formatMessage({
                          id: 'sportItem.publicationStatus',
                        })}
                      />
                    )}
                  />
                )}
              {values.videoContentType === VideoContentType.Live && (
                <SAFormField
                  name="contentType"
                  validate={validateRequired}
                  render={({ input, meta }) => (
                    <Form.Field
                      control={() => (
                        <SelectVideoContentTypeInput
                          onChange={input.onChange}
                          value={input.value}
                          typeSelector={SwitchContentTypeSelector.SportItemContentTypes}
                        />
                      )}
                      {...input}
                      required
                      error={meta.touched && meta.error}
                      label={intl.formatMessage({
                        id: 'sportItem.contentType',
                      })}
                    />
                  )}
                />
              )}

              <SAFormField
                name={`titleT.${activeTranslation}`}
                render={({ input, meta }) => (
                  <Form.Input
                    {...input}
                    required={activeTranslation === defaultLocale}
                    error={meta.touched && meta.error}
                    label={intl.formatMessage({ id: 'sportItem.title' })}
                  />
                )}
              />
              <SAFormField
                name={`subtitleT.${activeTranslation}`}
                render={({ input, meta }) => (
                  <Form.Input
                    {...input}
                    required={activeTranslation === defaultLocale}
                    error={meta.touched && meta.error}
                    label={intl.formatMessage({
                      id: 'sportItem.subtitle',
                    })}
                  />
                )}
              />
              <SAFormField
                name={`descriptionT.${activeTranslation}`}
                render={({ input, meta }) => (
                  <Form.Input
                    {...input}
                    required={activeTranslation === defaultLocale}
                    control={TextArea}
                    maxLength={500}
                    error={meta.touched && meta.error}
                    label={intl.formatMessage({
                      id: 'sportItem.description',
                    })}
                  />
                )}
              />
              <SAFormField
                name="matureContent"
                render={({ input, meta }) => (
                  <Form.Field error={meta.touched && meta.error}>
                    <CheckboxInput
                      {...input}
                      checked={values.matureContent}
                      label={
                        <label>
                          <MatureContentLabel>
                            <span>
                              <FormattedMessage id="sportItem.matureContent" />
                            </span>
                            <MatureContentLogo />
                          </MatureContentLabel>
                        </label>
                      }
                    />
                  </Form.Field>
                )}
              />
              <SAFormField
                name="coverImageFileId"
                validate={validateRequired}
                render={({ input, meta }) => (
                  <Form.Field
                    control={FileInput}
                    {...input}
                    type={MediaType.Image}
                    required
                    error={meta.touched && meta.error}
                    label={intl.formatMessage({
                      id: 'sportItem.coverImageFileId',
                    })}
                    description={intl.formatMessage({
                      id: 'sportItem.coverImageFileId.description',
                    })}
                    mimetype="image"
                    imageConstraints={{
                      aspect: 3 / 2,
                      minWidth: 225,
                      minHeight: 150,
                      maxSize: 3000000,
                    }}
                    organismId={sportItemSet?.ownerOrganismId}
                  />
                )}
              />

              {sportItemSetOffers.length > 0 && (
                <Segment color="black">
                  <Header>
                    <FormattedMessage id="sportItem.restrictions" />
                  </Header>
                  <SAFormField
                    name="reverseRestriction"
                    render={({ input }) => (
                      <>
                        <Form.Checkbox
                          name={input.name}
                          checked={!!input.value}
                          label={intl.formatMessage({
                            id: `sportItem.reverseRestriction.${
                              restrictionStatus === PremiumAccess.None ? 'lock' : 'unlock'
                            }`,
                          })}
                          onChange={(_, { checked }) => input.onChange(checked)}
                        />

                        <em>
                          <Icon name="info" />
                          {reverseIsLocked ? (
                            <FormattedMessage
                              id={`sportItem.${
                                sportItemSetType === SportItemSetType.SportEvent ? 'eventOfferIds' : 'playlistOfferIds'
                              }`}
                              values={{
                                offers: sportItemSetOffers.map(offer => offer.title).join(', '),
                              }}
                            />
                          ) : (
                            <FormattedMessage id="sportItem.isUnLocked" />
                          )}
                        </em>
                      </>
                    )}
                  />
                </Segment>
              )}

              {/* Metadata SportItemSet (SportEvent) */}
              {(values.videoContentType === VideoContentType.Live || (edit && values.sportEventItem)) &&
                sportItemSet && (
                  <SAFormField
                    name="sportEventItem"
                    render={({ input }: FieldRenderProps<SportEventItemFormValues, HTMLElement>) =>
                      sportItemSet.sportEvent && (
                        <SportEventItemFieldSet
                          {...input}
                          sportEvent={sportItemSet.sportEvent}
                          onValidate={setSportEventValid}
                          onLoading={setSportEventLoading}
                          initLivestream={!edit}
                          sportItemSetId={sportItemSet.id}
                          wmStreams={sportItemSet.streams || undefined}
                          sportItemId={(edit && values.id) || undefined}
                          eventStarted={eventStarted}
                          eventEnded={eventEnded}
                          videoEngine={videoEngine}
                          sveChannelIds={possibleSVEChannelIds}
                        />
                      )
                    }
                  />
                )}

              {/* Player Embed */}
              {(videoEngine === VideoEngine.ExternalFeed ||
                values?.videoTypePlaylist === VideoTypePlaylist.ExternalFeed) &&
                !edit && (
                  <>
                    <SAFormField
                      name="externalFeedProvider"
                      validate={externalFeedRequired ? validateRequired : undefined}
                      render={({ input, meta }: FieldRenderProps<ExternalFeedType, HTMLElement>) => (
                        <Form.Field
                          {...input}
                          required={externalFeedRequired}
                          label={intl.formatMessage({ id: 'sportItem.externalFeed.provider' })}
                          error={!!meta.touched && !!meta.error}
                          control={() => (
                            <SwitchContentTypesButtonInput
                              onChange={input.onChange}
                              value={input.value}
                              typeSelector={SwitchContentTypeSelector.ExternalFeedTypes}
                            />
                          )}
                        />
                      )}
                    />
                    <SAFormField
                      name="externalFeedUrl"
                      validate={externalFeedRequired ? validateRequired : undefined}
                      render={({ input, meta }) => (
                        <Form.Input
                          {...input}
                          required={externalFeedRequired}
                          error={!!meta.touched && !!meta.error}
                          label={intl.formatMessage({ id: 'sportItem.externalFeed.link' })}
                        />
                      )}
                    />
                  </>
                )}

              {currentUser.can(Permission.ChatRoomsCreate, sportItemSet?.ownerOrganismId || undefined, true) && (
                <SAFormField
                  name="chatActivated"
                  render={({ input, meta }) => (
                    <Form.Field error={!!meta.touched && !!meta.error}>
                      <CheckboxInput
                        {...input}
                        checked={isChatActive || false}
                        onChange={setIsChatActive}
                        label={
                          <label>
                            <FormattedMessage id="sportItem.chatActivated" />
                          </label>
                        }
                      />
                    </Form.Field>
                  )}
                />
              )}
              <SAFormField
                name="modeRadio"
                render={({ input, meta }) => (
                  <Form.Field error={!!meta.touched && !!meta.error}>
                    <CheckboxInput
                      {...input}
                      checked={values.modeRadio}
                      label={
                        <label>
                          <FormattedMessage id="sportItem.radioActivated" />
                        </label>
                      }
                    />
                  </Form.Field>
                )}
              />
              {showContentShares && (
                <>
                  <Header as="h3">{intl.formatMessage({ id: 'contentPushStream.title' })}</Header>
                  <Segment>
                    {contentPushServices.map(
                      (serviceName: AvailableContentPushService, index) =>
                        serviceName.customDestination && (
                          <SAFormField
                            key={`${serviceName}${index}`}
                            name={'contentPushServiceNamesExcluded'}
                            render={({ input, meta }: FieldRenderProps<SportItemFormValues, HTMLSelectElement>) => (
                              <Form.Field error={!!meta.touched && !!meta.error}>
                                <CheckboxInput
                                  {...input}
                                  onChange={value =>
                                    onChangeContentPushServiceNamesExcluded(value, input, values, serviceName)
                                  }
                                  checked={
                                    !!values.contentPushServiceNamesExcluded?.includes(
                                      serviceName.type as ContentPushStreamServiceName,
                                    )
                                  }
                                  label={intl.formatMessage(
                                    { id: 'contentPushStream.exclude' },
                                    { type: serviceName.type },
                                  )}
                                />
                              </Form.Field>
                            )}
                          />
                        ),
                    )}
                  </Segment>
                </>
              )}

              {currentUser.can(
                Permission.ApplicationContentAccessControlUpdate,
                sportItemSet?.ownerOrganismId || undefined,
                true,
              ) && (
                <>
                  <Header>{intl.formatMessage({ id: 'application.form.cac.userAuthentication.title' })}</Header>
                  <SAFormField
                    name="cac.userAuthenticationRule"
                    render={({ input }) => (
                      <>
                        <ContentAccessControlUserAuthenticationFormFields
                          onChange={value => input.onChange(value)}
                          value={input.value as CacUserAuthenticationRule | undefined}
                          withDisableButton
                          ownerOrganismId={sportItemSet?.ownerOrganismId}
                          sportItemSetAuthenticationCac={sportItemSet.cac?.userAuthenticationRule || undefined}
                        />
                        <p className="text-sm italic pl-2 pt-1">
                          {intl.formatMessage({ id: 'application.form.cac.userAuthentication.helper' })}
                        </p>
                      </>
                    )}
                  />
                </>
              )}
            </Fragment>
          )
        }}
      </GenericForm>
    )
  },
)

export default SportItemForm
