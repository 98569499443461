import SelectSportItemSetInput from 'Containers/Form/SelectSportItemSetInput'
import { FormApi } from 'final-form'
import { SportItemSet } from 'models'

import React, { useCallback, useEffect, useState } from 'react'
import { FieldInputProps } from 'react-final-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button, Form, Icon, Popup } from 'semantic-ui-react'

import AddSportItemsModal from './AddSportItemsModal'
import { LegalRestrictionFormValue } from './LegalRestrictionForm'

interface AddSportItemSetsInputProps {
  values: LegalRestrictionFormValue
  form: FormApi<LegalRestrictionFormValue, Partial<LegalRestrictionFormValue>>
  input: FieldInputProps<LegalRestrictionFormValue, HTMLElement>
  initialValues: LegalRestrictionFormValue
}

const AddSportItemSetsWithSportItemsInput: React.FC<AddSportItemSetsInputProps> = ({
  input,
  values,
  form,
  initialValues,
}) => {
  const intl = useIntl()

  const [selectedSportItemSet, setSelectedSportItemSet] = useState<SportItemSet | null>()
  const [selectedSportItemSets, setSelectedSportItemSets] = useState<SportItemSet[]>([])
  const [selectedSportItemSetModal, setSelectedSportItemSetModal] = useState<SportItemSet | null>()
  const [sportItemSets, setSportItemSets] = useState<SportItemSet[]>()

  useEffect(() => {
    setSelectedSportItemSets([])
  }, [values.organismId])

  useEffect(() => {
    if (sportItemSets && initialValues.sportItemSetIds) {
      setSelectedSportItemSets(sportItemSets.filter(event => initialValues.sportItemSetIds?.includes(event.id)))
    }
  }, [sportItemSets, initialValues.sportItemSetIds])

  const onRemoveSportItemSet = useCallback(
    (item: SportItemSet) => {
      const tmp = selectedSportItemSets.filter(el => el.id !== item.id)
      setSelectedSportItemSets(tmp)

      // Remove sportItems associated
      const currentEvent = sportItemSets?.find(el => el.id === item.id)
      const sportItemIds = currentEvent?.items?.map(el => el.id)
      form.change(
        'sportItemIds',
        values.sportItemIds?.filter(id => !sportItemIds?.includes(id)),
      )
      form.change(
        'sportItemSetIds',
        tmp.map(el => el.id),
      )
    },
    [form, selectedSportItemSets, sportItemSets, values.sportItemIds],
  )

  return (
    <Form.Field required>
      <label>
        <FormattedMessage id="legalRestriction.sportItemSetIds" />
      </label>

      <div className="flex flex-row items-center mb-2">
        <SelectSportItemSetInput
          {...input}
          getSportItemSets={setSportItemSets}
          className="w-3/4 mr-2"
          organismId={values.organismId || ''}
          value={selectedSportItemSet?.id}
          selectedSportItemSets={selectedSportItemSets}
          onChange={value => {
            if (sportItemSets && typeof value === 'string') {
              setSelectedSportItemSet(sportItemSets.find(e => e.id === value))
            }
          }}
        />
        <Button
          primary
          disabled={!selectedSportItemSet}
          className="h-10"
          onClick={e => {
            e.preventDefault()
            if (selectedSportItemSet) {
              const tmp = [...selectedSportItemSets, selectedSportItemSet]
              setSelectedSportItemSets(tmp)
              setSelectedSportItemSet(null)
              form.change(
                'sportItemSetIds',
                tmp.map(el => el.id),
              )
            }
          }}
        >
          {intl.formatMessage({
            id: 'common.add',
          })}
        </Button>
      </div>

      {/* SportItemSets selection*/}
      {selectedSportItemSets.length > 0 && (
        <div className="w-3/4">
          {selectedSportItemSets.map(item => {
            const currentEvent = sportItemSets?.find(el => el.id === item.id)
            const restrictedSportItemsCount = currentEvent
              ? currentEvent.items?.filter(e => values.sportItemIds?.includes(e.id)).length
              : 0

            return (
              <div key={item.id} className="flex flex-row justify-between  rounded-md bg-gray-100 my-1 pl-3 pr-2 py-2">
                <span className="py-1">{item.title}</span>
                <div className="flex flex-row items-center justify-between space-x-1">
                  <div
                    className="flex rounded-md bg-gray-200 h-full px-2 items-center justify-center"
                    style={{ minWidth: 30 }}
                  >
                    <span className="font-bold text-sm">{restrictedSportItemsCount || 'Tous'}</span>
                  </div>

                  {/* Open modal for selecting sportItems from Event */}
                  <Popup
                    trigger={
                      <div
                        className="cursor-pointer px-3 rounded-md bg-gray-200 flex items-center h-full"
                        onClick={() => {
                          setSelectedSportItemSetModal(item)
                        }}
                      >
                        <Icon name="add" fitted size="small" />
                      </div>
                    }
                  >
                    <FormattedMessage id="legalRestrictions.addSportItemSetWithItems.tips" />
                  </Popup>

                  {/* Remove sportItemSet */}
                  <div
                    className="cursor-pointer px-3 rounded-md bg-red-100 flex items-center h-full"
                    onClick={() => onRemoveSportItemSet(item)}
                  >
                    <Icon name="remove" color="red" fitted size="small" className="self-center" />
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      )}

      {/* Modal for selecting sportItems from target Event */}
      {!!selectedSportItemSetModal && (
        <AddSportItemsModal
          sportItemSet={selectedSportItemSetModal}
          initialValues={values}
          form={form}
          onCancel={() => {
            setSelectedSportItemSetModal(null)
          }}
          onSubmit={() => {
            setSelectedSportItemSetModal(null)
          }}
        />
      )}
    </Form.Field>
  )
}

export default AddSportItemSetsWithSportItemsInput
