import CancelFormButton from 'Components/Form/CancelFormButton'
import arrayMutators from 'final-form-arrays'
import { useUpdatePurchasableOffer, useUpdatePurchasableOfferProduct } from 'models'
import React from 'react'
import { Form } from 'react-final-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button, Dimmer, Modal } from 'semantic-ui-react'
import { Product, ProductInput, PurchasableOffer } from 'services/api/graphql'
import { notifySuccess } from 'tools/toaster'

import ProductsFormFields, { PremiumRestriction, ProductFormValues } from './ProductsFormFields'

interface PurchasableOffersFormProps {
  purchasableOffer: PurchasableOffer
  product?: Product
  onDone?: () => void
  onCancel: () => void
}

const ProductsForm = ({ purchasableOffer, product, onDone, onCancel }: PurchasableOffersFormProps) => {
  const intl = useIntl()
  const [updateOffer, { loading: isLoadingOffer }] = useUpdatePurchasableOffer()
  const [updateProduct, { loading: isLoadingProduct }] = useUpdatePurchasableOfferProduct()

  const onSubmit = async (values: ProductFormValues) => {
    const productInput: ProductInput = {
      ...{
        ...values,
        // Remove fields that should not be registered
        isPremium: undefined,
        premiumReplaceAll: undefined,
        premiumRestriction: undefined,
      },
      // Computed values from fields
      licensedUserOnly: values.premiumRestriction === PremiumRestriction.licensedUserOnly,
      purchasableOfferId:
        values.premiumRestriction === PremiumRestriction.purchasableOfferId ? values.purchasableOfferId : null,
      premiumOf: values.premiumReplaceAll ? null : values.premiumOf,
    }
    if (product) {
      const result = await updateProduct({
        variables: {
          id: purchasableOffer.id,
          productId: product.productId,
          input: productInput,
        },
      })

      if (result && result.data) {
        notifySuccess(
          intl.formatMessage({
            id: 'product.update_success',
          }),
        )
        if (onDone) onDone()
      }
    } else {
      const result = await updateOffer({
        variables: {
          id: purchasableOffer.id,
          input: {
            products: [{ ...productInput, prices: values.prices || [] }],
          },
        },
      })

      if (result && result.data) {
        notifySuccess(
          intl.formatMessage({
            id: 'product.create_success',
          }),
        )
        if (onDone) onDone()
      }
    }
  }

  const loading = isLoadingProduct || isLoadingOffer

  return (
    <Form<ProductFormValues>
      onSubmit={onSubmit}
      initialValues={
        product && {
          ...product,
          isPremium: !!product.purchasableOfferId || !!product.licensedUserOnly,
          premiumRestriction: product.licensedUserOnly
            ? PremiumRestriction.licensedUserOnly
            : PremiumRestriction.purchasableOfferId,
          premiumReplaceAll: !product.premiumOf || product.premiumOf.length === 0,
        }
      }
      mutators={{
        ...arrayMutators,
      }}
      render={formProps => {
        const { handleSubmit, form, pristine } = formProps

        return (
          <>
            <Modal.Header>
              <FormattedMessage id="product.name" />
            </Modal.Header>
            <Modal.Content>
              <Dimmer.Dimmable dimmed={loading}>
                <Dimmer active={loading} inverted />
                <ProductsFormFields products={purchasableOffer.products} mode={purchasableOffer.mode} />
              </Dimmer.Dimmable>
            </Modal.Content>
            <Modal.Actions>
              <CancelFormButton pristine={pristine} onConfirm={form.reset} onCancel={onCancel} />
              <Button positive loading={loading} onClick={() => handleSubmit()}>
                <FormattedMessage id="common.submit" />
              </Button>
            </Modal.Actions>
          </>
        )
      }}
    />
  )
}

export default ProductsForm
