import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $insertNodeToNearestRoot, mergeRegister } from '@lexical/utils'
import { COMMAND_PRIORITY_EDITOR, createCommand, LexicalCommand } from 'lexical'
import { FC, useEffect } from 'react'

import { $createImageNode, ImageNode, ImagePayload } from './ImageNode'

export const INSERT_IMAGE_COMMAND: LexicalCommand<ImagePayload> = createCommand()

const ImagePlugin: FC = () => {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    // check if ImageNode has been provided to editor
    if (!editor.hasNodes([ImageNode])) {
      throw new Error('ImagePlugin: ImageNode not registered on editor')
    }

    // register custom commands
    return mergeRegister(
      editor.registerCommand<ImagePayload>(
        INSERT_IMAGE_COMMAND,
        payload => {
          const imageNode = $createImageNode(payload)
          $insertNodeToNearestRoot(imageNode)
          return true
        },
        COMMAND_PRIORITY_EDITOR,
      ),
    )
  }, [editor])

  return null
}

export default ImagePlugin

export * from './ImageNode'
