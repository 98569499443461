import { jwtDecode } from 'jwt-decode'
import { useCallback, useEffect } from 'react'
import { useUpdateTokenPayloadMutation } from 'services/api/graphql'
import { useStore } from 'stores'

export const useUpdateTokenPayload = () => {
  const {
    currentUser: { authToken: token, setToken },
  } = useStore()
  const [updateTokenPayload] = useUpdateTokenPayloadMutation()

  const update = useCallback(async () => {
    const token = await updateTokenPayload()
    token.data && setToken(token.data.updateTokenPayload)
  }, [setToken, updateTokenPayload])

  useEffect(() => {
    if (token && jwtDecode<{ uid?: string }>(token)?.uid) {
      update()
    }
  }, [token, update])
}
