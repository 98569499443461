import React, { FC, HTMLProps } from 'react'
import { Button } from 'semantic-ui-react'

import BlockTypeSelect from './BlockTypeSelect'
import InsertImageButton from './InsertImageButton'
import InsertLinkButton from './InsertLinkButton'
import InsertTweetButton from './InsertTweetButton'
import InsertYoutubeButton from './InsertYoutubeButton'
import ToolbarButton from './ToolbarButton'

export type ToolbarProps = Omit<HTMLProps<HTMLDivElement>, 'children'>

/**
 * Wysiwyg agnostic toolbar built with semantic-ui.
 *
 * It dispatches events through the <Editor> context so any wysiwyg
 * implementation can catch and handle them.
 */
const Toolbar: FC<ToolbarProps> = props => (
  <div className="flex items-center gap-2 sticky -top-6 z-20 bg-white" {...props}>
    <Button.Group>
      <ToolbarButton icon="undo" cmd="undo" tooltipId="undo" />
      <ToolbarButton icon="redo" cmd="redo" tooltipId="redo" />
    </Button.Group>

    <BlockTypeSelect />

    <Button.Group>
      <ToolbarButton icon="bold" cmd="formatText" payload="bold" tooltipId="bold" />
      <ToolbarButton icon="italic" cmd="formatText" payload="italic" tooltipId="italic" />
      <ToolbarButton icon="underline" cmd="formatText" payload="underline" tooltipId="underline" />
      <ToolbarButton icon="strikethrough" cmd="formatText" payload="strikethrough" tooltipId="strikethrough" />
      {/* <ToolbarButton icon="code" cmd="formatText" payload="code" tooltipId="code" /> */}
      <InsertLinkButton />
    </Button.Group>

    <Button.Group>
      <ToolbarButton icon="align left" cmd="formatElement" payload="left" tooltipId="left" />
      <ToolbarButton icon="align center" cmd="formatElement" payload="center" tooltipId="center" />
      <ToolbarButton icon="align right" cmd="formatElement" payload="right" tooltipId="right" />
      <ToolbarButton icon="align justify" cmd="formatElement" payload="justify" tooltipId="justify" />
    </Button.Group>

    <Button.Group>
      <InsertImageButton />
      <InsertYoutubeButton />
      <InsertTweetButton />
    </Button.Group>
  </div>
)

export default Toolbar
