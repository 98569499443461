import { FormBaseProps } from 'Forms/FormBase'
import { useGetUser, useUpdateUser } from 'models'
import React from 'react'

import { User } from 'services/api/graphql'

import UserForm, { UserFormValues } from './UserForm'
import { userTitle } from './helper'

interface EditUserFormWithUserProps extends FormBaseProps<User> {
  userValues: UserFormValues
}
const EditUserFormWithUser = ({ userValues, onCancel, onDone, onError }: EditUserFormWithUserProps) => {
  const [updateUser, { loading }] = useUpdateUser()
  return (
    <UserForm
      title={`Modification de l'utilisateur "${userTitle(userValues)}"`}
      edit
      initialValues={userValues}
      onSubmit={values => {
        if (!userValues.id) {
          throw new Error(`Missing user id !`)
        }
        updateUser({ variables: { input: { ...values, id: userValues.id } } })
          .then(result => {
            if (result && result.data) onDone(result.data.updateUser)
          })
          .catch(onError)
      }}
      onCancel={onCancel}
      loading={loading}
    />
  )
}

interface EditUserFormProps extends FormBaseProps<User> {
  userId: User['id']
}
const EditUserForm = ({ userId, ...props }: EditUserFormProps) => {
  const { user } = useGetUser(userId, { fetchPolicy: 'network-only' })
  if (!user) return null

  const userWithoutTypenames: any = { ...user }
  delete userWithoutTypenames.__typename

  return <EditUserFormWithUser userValues={{ ...userWithoutTypenames }} {...props} />
}
export default EditUserForm
