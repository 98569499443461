import SelectItemListInput from 'Components/Form/SelectItemListInput'
import React, { useMemo } from 'react'
import { Maybe, SportItem } from 'services/api/graphql'

export interface SelectSportItemInputProps {
  value: SportItem['id'] | undefined
  onChange: (value: Maybe<SportItem['id']>) => void
  noSelectionEnabled?: boolean
  noSelectionTitle?: string
  source: SportItem[]
  loading?: boolean
}

const SelectSportItemInput = ({
  value,
  onChange,
  noSelectionEnabled,
  noSelectionTitle = 'Aucun',
  source,
  loading,
}: SelectSportItemInputProps) => {
  const options = useMemo(() => {
    return source.map(sportItem => ({
      key: sportItem.id,
      value: sportItem.id,
      text: sportItem.title,
    }))
  }, [source])
  return (
    <SelectItemListInput
      value={value}
      loading={loading}
      options={options}
      noSelectionEnabled={noSelectionEnabled}
      noSelectionTitle={noSelectionTitle}
      onChange={onChange}
    />
  )
}

export default SelectSportItemInput
