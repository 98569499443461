import React from 'react'
import { Outlet } from 'react-router-dom'
import styled from 'styled-components'

const Screen = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const AuthLayout = () => (
  <Screen>
    <Outlet />
  </Screen>
)

export default AuthLayout
