import { MobXProviderContext } from 'mobx-react'
import { useContext } from 'react'

import { CurrentUser, IUser } from './CurrentUserStore'
import { RootStore } from './RootStore'
import { Templates } from './TemplateStore'

export const useStores = () => useContext(MobXProviderContext)
export const useStore = (): RootStore => useStores().store

export const useCurrentUser = (): CurrentUser => useStore().currentUser
export const useUser = (): IUser => {
  const user = useContext(MobXProviderContext).store.currentUser.loggedUser
  if (!user) {
    throw new Error(`Try to access user object while not logged in !`)
  }
  return user
}

export const useTemplates = (): Templates => {
  return useContext(MobXProviderContext).store.templates
}
