import { FormBaseProps } from 'Forms/FormBase'
import { useCreateLink } from 'models'
import React from 'react'
import { useIntl } from 'react-intl'
import { CreateLinkInput, Link } from 'services/api/graphql'
import { getTranslationInputFromForm } from 'tools/translationTools'

import LinkForm from './LinkForm'

interface CreateLinkFormProps extends FormBaseProps<Link> {}

const CreateLinkForm = ({ onCancel, onDone, onError }: CreateLinkFormProps) => {
  const intl = useIntl()
  const [createLink, { loading }] = useCreateLink()
  return (
    <LinkForm
      title={intl.formatMessage({ id: 'links.form.create.title' })}
      edit={false}
      onSubmit={({ titleT, subtitleT, descriptionT, ...values }, defaultLocale, selectedTranslations) => {
        const input: CreateLinkInput = {
          titleT: getTranslationInputFromForm(selectedTranslations, titleT),
          title: titleT?.[defaultLocale] || '',
          subtitleT: getTranslationInputFromForm(selectedTranslations, subtitleT),
          subtitle: subtitleT?.[defaultLocale] || '',
          descriptionT: getTranslationInputFromForm(selectedTranslations, descriptionT),
          description: descriptionT?.[defaultLocale] || '',
          countries: values.countries || [],
          categoriesIds: values.categoriesIds || [],
          publicationStatus: values.publicationStatus,
          tag: values.tag,
          url: values.url,
          imageId: values.imageId,
          ownerOrganismIds: values.ownerOrganismIds,
          teaserVideoId: values.teaserVideoId,
          billboardId: values.billboardId,
          gamTagUrl: values.gamTagUrl,
        }
        createLink({ variables: { input } })
          .then(result => {
            if (result && result.data) onDone(result.data.createLink)
          })
          .catch(onError)
      }}
      onCancel={onCancel}
      loading={loading}
    />
  )
}

export default CreateLinkForm
