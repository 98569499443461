import { ChatRoom } from 'models/ChatRoom'
import { useReducer } from 'react'

export const chatRoomsInitialeState = {}

type ReducerState = {
  loading?: boolean
  chatRooms?: ChatRoom[]
}

type AddChatRoomAction = {
  type: 'ADD_CHAT_ROOM'
  value: ChatRoom
}

type ModifyChatRoomAction = {
  type: 'MODIFY_CHAT_ROOM'
  value: ChatRoom
}

type ResetChatRoomAction = {
  type: 'RESET_CHAT_ROOMS'
}

type RemoveChatRoomAction = {
  type: 'REMOVE_CHAT_ROOM'
  value: string
}

type SetLoadingAction = {
  type: 'SET_LOADING'
  value: boolean
}

type ReducerAction =
  | AddChatRoomAction
  | ModifyChatRoomAction
  | RemoveChatRoomAction
  | ResetChatRoomAction
  | SetLoadingAction

const chatRoomsReducer = (state: ReducerState, action: ReducerAction) => {
  switch (action.type) {
    case 'ADD_CHAT_ROOM': {
      const chatRoom = action.value
      return {
        chatRooms: [...(state.chatRooms?.filter(({ id }) => id !== chatRoom.id) || []), chatRoom],
      }
    }
    case 'MODIFY_CHAT_ROOM': {
      const chatRoom = action.value
      return {
        chatRooms: [...(state.chatRooms?.filter(({ id }) => id !== chatRoom.id) || []), chatRoom],
      }
    }
    case 'REMOVE_CHAT_ROOM': {
      const chatRoomId = action.value
      return {
        chatRooms: [...(state.chatRooms?.filter(({ id }) => id !== chatRoomId) || [])],
      }
    }
    case 'RESET_CHAT_ROOMS': {
      return {
        chatRooms: [],
      }
    }
    case 'SET_LOADING': {
      const loading = action.value
      return {
        ...state,
        loading,
      }
    }
    default:
      return state
  }
}

export const useChatRoomsStore = () => {
  const [value, dispatch] = useReducer(chatRoomsReducer, chatRoomsInitialeState)

  const addChatRoom = (chatRoom: ChatRoom) => {
    dispatch({ type: 'ADD_CHAT_ROOM', value: chatRoom })
  }

  const modifyChatRoom = (chatRoom: ChatRoom) => {
    dispatch({ type: 'MODIFY_CHAT_ROOM', value: chatRoom })
  }

  const removeChatRoom = (id: string) => {
    dispatch({ type: 'REMOVE_CHAT_ROOM', value: id })
  }

  const resetRooms = () => {
    dispatch({ type: 'RESET_CHAT_ROOMS' })
  }

  const setLoading = (loading: boolean) => {
    dispatch({ type: 'SET_LOADING', value: loading })
  }

  return {
    chatRooms: value.chatRooms || [],
    loading: value.loading !== undefined ? value.loading : true,
    addChatRoom,
    modifyChatRoom,
    removeChatRoom,
    resetRooms,
    setLoading,
  }
}
