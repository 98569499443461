import RefreshButton from 'Components/Button/RefreshButton'
import Loader from 'Components/Loader'
import PageHeader from 'Components/PageHeader'
import ThirdPartyApplicationForm from 'Forms/ThirdPartyApplication/ThirdPartyApplicationForm'
import { Permission, useDeleteThirdPartyApplicationMutation, useThirdPartyApplicationsQuery } from 'models'
import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button, Grid, Modal, Table } from 'semantic-ui-react'
import { GET_THIRD_PARTY_APPLICATIONS_QUERY } from 'services/api/graphql/ThirdPartyApplication'
import { useCurrentUser, useStore } from 'stores'
import { cloneWithoutGraphqlCacheTypenames } from 'tools/graphql'

import ThirdPartyApplicationViewRow from './Row'

const ThirdPartyApplicationsView = () => {
  const store = useStore()
  const intl = useIntl()
  const [deleteThirdPartyApplication] = useDeleteThirdPartyApplicationMutation()

  const { data, loading, refetch } = useThirdPartyApplicationsQuery({
    variables: {
      filters: {
        organismId: store.organismId || undefined,
      },
    },
    fetchPolicy: 'cache-and-network',
  })

  const handleDeleteThirdPartyApplication = async (id: string) => {
    await deleteThirdPartyApplication({
      variables: { id },
      refetchQueries: [{ query: GET_THIRD_PARTY_APPLICATIONS_QUERY }, 'thirdPartyApplications'],
    })
  }

  const thirdPartyApplications = cloneWithoutGraphqlCacheTypenames(
    data?.thirdPartyApplications?.thirdPartyApplications ?? [],
  )

  const currentUser = useCurrentUser()
  const [formModalOpen, setFormModalOpen] = useState(false)

  return (
    <Loader loading={loading}>
      <Grid padded>
        <Grid.Column>
          <PageHeader title={intl.formatMessage({ id: 'thirdPartyApplication.title' })}>
            <RefreshButton onClick={() => refetch()} loading={loading} />
            {currentUser.can(Permission.ThirdPartyApplicationCreate) && (
              <Modal
                mountNode={document.getElementById('modals')}
                trigger={<Button icon="add" circular color="red" />}
                content={
                  <ThirdPartyApplicationForm
                    isEdit={false}
                    organismId={store.organismId || ''}
                    onDone={() => {
                      refetch()
                      setFormModalOpen(false)
                    }}
                    onCancel={() => setFormModalOpen(false)}
                  />
                }
                onClose={() => setFormModalOpen(false)}
                onOpen={() => setFormModalOpen(true)}
                open={formModalOpen}
              />
            )}
          </PageHeader>
          <Table striped compact celled>
            <Table.Header>
              <Table.Row textAlign="center">
                <Table.HeaderCell>
                  <FormattedMessage id="common.title" />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <FormattedMessage id="common.organism" />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <FormattedMessage id="common.description" />
                </Table.HeaderCell>
                <Table.HeaderCell width={1} textAlign="center">
                  <FormattedMessage id="application.actions" />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {thirdPartyApplications.map(thirdPartyApplication => (
                <ThirdPartyApplicationViewRow
                  key={thirdPartyApplication.id}
                  thirdPartyApplication={thirdPartyApplication}
                  onUpdateApplication={refetch}
                  onRemoveApplication={handleDeleteThirdPartyApplication}
                />
              ))}
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid>
    </Loader>
  )
}

export default ThirdPartyApplicationsView
