import { DragDropContext, Droppable, DropResult } from '@hello-pangea/dnd'
import RefreshButton from 'Components/Button/RefreshButton'
import DraggableTableRow from 'Components/DraggableTableRow/DraggableTableRow'
import Loader from 'Components/Loader'
import PageHeader from 'Components/PageHeader'
import PurchaseExportView from 'Containers/PurchaseExportView'
import { useDeletePurchableOffer, useGetOrganismWithSubscriptions, useMovePurchasableOffer } from 'models'
import React, { useCallback, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button, Grid, Modal, Ref, Table } from 'semantic-ui-react'
import { Permission, PurchasableOffer } from 'services/api/graphql'
import { useCurrentUser, useStore } from 'stores'

import PurchasableOffersDataRow from './PurchasableOffers/PurchasableOffersDataRow'
import PurchasableOffersForm from './PurchasableOffers/PurchasableOffersForm'

const PurchasableOffersView = () => {
  const store = useStore()
  const intl = useIntl()
  const currentUser = useCurrentUser()
  const organismId = store.organismId

  const [isModalOpen, setModalOpen] = useState(false)
  const { organism, loading, refetch } = useGetOrganismWithSubscriptions(organismId || '')
  const [purchasableOffers, setPurchasableOffers] = useState<PurchasableOffer[]>([])

  useEffect(() => {
    setPurchasableOffers(organism ? organism.offers : [])
  }, [organism])

  const [deleteOffer, { loading: deleteLoading }] = useDeletePurchableOffer()
  const onDeleteOffer = useCallback(
    async (id: string) => {
      await deleteOffer({ id })
      refetch()
    },
    [deleteOffer, refetch],
  )
  const [movepurchasableOffer] = useMovePurchasableOffer()

  const onMoveItem = async (result: DropResult) => {
    const { draggableId, destination, source, reason } = result

    if (!destination || reason === 'CANCEL' || destination.index === source.index) {
      return
    }

    await movepurchasableOffer({
      variables: {
        input: { id: draggableId, position: destination.index },
      },
    })

    void refetch()
  }

  return (
    <Loader loading={loading || deleteLoading}>
      <Grid padded>
        <Grid.Column>
          <PageHeader title={intl.formatMessage({ id: 'purchasableOffer.name' })}>
            {currentUser.can(Permission.PurchasesSnapshotExportRead) && <PurchaseExportView />}
            <RefreshButton onClick={() => refetch()} loading={loading} />
            {currentUser.can(Permission.PurchasableOfferWrite) && (
              <Modal
                mountNode={document.getElementById('modals')}
                trigger={<Button icon="add" circular color="red" />}
                content={
                  <PurchasableOffersForm
                    onDone={() => {
                      refetch()
                      setModalOpen(false)
                    }}
                    onCancel={() => setModalOpen(false)}
                  />
                }
                onClose={() => setModalOpen(false)}
                onOpen={() => setModalOpen(true)}
                open={isModalOpen}
              />
            )}
          </PageHeader>
          <DragDropContext onDragEnd={onMoveItem}>
            <Table striped compact celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell />
                  <Table.HeaderCell>
                    <FormattedMessage id="purchasableOffer.title" />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <FormattedMessage id="purchasableOffer.mode" />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <FormattedMessage id="purchasableOffer.delay_date" />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <FormattedMessage id="purchasableOffer.subscriptions" />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <FormattedMessage id="purchasableOffer.publicationStatus" />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <FormattedMessage id="purchasableOffer.actions" />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Droppable isDropDisabled={!store.organismId} droppableId="purchasableOffer">
                {provided => (
                  <Ref innerRef={provided.innerRef}>
                    <Table.Body {...provided.droppableProps}>
                      {purchasableOffers && purchasableOffers.length > 0 && (
                        <>
                          {purchasableOffers.map((offer: PurchasableOffer, index: number) => (
                            <DraggableTableRow
                              isDragDisabled={!store.organismId}
                              key={offer.id}
                              draggableId={offer.id}
                              index={index}
                            >
                              {provided => (
                                <PurchasableOffersDataRow
                                  draggableProvided={provided}
                                  key={offer.id}
                                  purchasableOffer={offer}
                                  onDelete={() => onDeleteOffer(offer.id)}
                                />
                              )}
                            </DraggableTableRow>
                          ))}
                        </>
                      )}
                    </Table.Body>
                  </Ref>
                )}
              </Droppable>
            </Table>
          </DragDropContext>
        </Grid.Column>
      </Grid>
    </Loader>
  )
}

export default PurchasableOffersView
