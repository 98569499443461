import CopyButton from 'Components/CopyButton'
import SAFormField from 'Components/Form/SAFormField'
import SelectOrganismsInput from 'Containers/Form/SelectOrganismsInput'
import React from 'react'
import { FieldRenderProps } from 'react-final-form'
import { useIntl } from 'react-intl'
import { Icon } from 'semantic-ui-react'
import { Form as UiForm } from 'semantic-ui-react'
import { Organism, UpdateThirdApplicationKeyInput } from 'services/api/graphql'
import { useStore } from 'stores/useStores'
import { validateRequired } from 'tools/formValidators'

interface ThirdPartyApplicationFormFieldsProps {
  keys: UpdateThirdApplicationKeyInput[]
  onChangeKeyToEdit: (key: UpdateThirdApplicationKeyInput | undefined | null, index: number) => void
  onRemoveKeyAtIndex: (index: number) => void
}

const ThirdPartyApplicationFormFields = ({
  onChangeKeyToEdit,
  keys,
  onRemoveKeyAtIndex,
}: ThirdPartyApplicationFormFieldsProps) => {
  const intl = useIntl()
  const { organismId } = useStore()

  return (
    <UiForm>
      <SAFormField
        name="name"
        validate={validateRequired}
        render={({ input, meta }) => (
          <UiForm.Input
            {...input}
            error={!!meta.touched && !!meta.error}
            label={intl.formatMessage({ id: 'common.title' })}
          />
        )}
      />
      <SAFormField
        name="description"
        validate={validateRequired}
        render={({ input, meta }) => (
          <UiForm.Input
            {...input}
            error={!!meta.touched && !!meta.error}
            label={intl.formatMessage({ id: 'common.description' })}
          />
        )}
      />
      {!organismId && (
        <SAFormField
          name="organismId"
          render={({ input, meta }: FieldRenderProps<Organism['id'], HTMLInputElement>) => (
            <UiForm.Field
              control={SelectOrganismsInput}
              {...input}
              error={!!meta.touched && !!meta.error}
              label={intl.formatMessage({ id: 'common.organism' })}
            />
          )}
        />
      )}
      <div className="flex justify-between mt-8">
        <h2 className="font-bold text-xl leading-none">{intl.formatMessage({ id: 'common.keys' })}</h2>
        <button
          onClick={() => onChangeKeyToEdit(undefined, keys.length)}
          className="flex bg-gray-400 rounded-full p-1 center align-center px-1.5 py-1"
        >
          <Icon name="add" link size="large" inverted fitted />
        </button>
      </div>
      {keys.length === 0 && (
        <p className="italic text-gray-500 pl-2">{intl.formatMessage({ id: 'thirdPartyApplication.noKeyDefined' })}</p>
      )}
      {keys.length !== 0 && (
        <table className="w-full">
          <thead>
            <tr>
              <th className="py-2">{intl.formatMessage({ id: 'common.name' })}</th>
              <th className="py-2">{intl.formatMessage({ id: 'common.type' })}</th>
              <th className="py-2" />
            </tr>
          </thead>
          {keys.map((key, index) => (
            <tbody key={key.id || `key-${index}`}>
              <tr>
                <td>{key.name}</td>
                <td>{key.type}</td>
                <td className="flex justify-end">
                  <CopyButton value={key.key}>
                    <Icon name="copy" link size="large" fitted />
                  </CopyButton>
                  <button
                    onClick={() => onChangeKeyToEdit(key, index)}
                    className="flex rounded-full p-1 center align-center px-1.5 py-1"
                  >
                    <Icon name="edit" link size="large" fitted />
                  </button>
                  <button
                    onClick={() => onRemoveKeyAtIndex(index)}
                    className="flex rounded-full p-1 center align-center px-1.5 py-1 text-red-600"
                  >
                    <Icon name="trash" link size="large" fitted />
                  </button>
                </td>
              </tr>
            </tbody>
          ))}
        </table>
      )}
    </UiForm>
  )
}

export default ThirdPartyApplicationFormFields
