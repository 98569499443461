import { useQuery } from '@apollo/client'
import UserUpdateCacheReceiptButton from 'Components/UserUpdateCacheReceiptButton'
import { UserFragment } from 'Views/Users/fragments'
import { FragmentOf, ResultOf, readFragment } from 'gql.tada'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Divider, Grid, List, Loader } from 'semantic-ui-react'
import { Permission, User } from 'services/api/graphql'
import { useCurrentUser } from 'stores'

import { GetUserDetailsQuery, UserOffersFragment } from './fragments'

interface UserViewOffersProps {
  user: ResultOf<typeof UserFragment> | User
}

const UserViewOffers = ({ user: { id: userId } }: UserViewOffersProps) => {
  const currentUser = useCurrentUser()
  const intl = useIntl()
  const { data: userOffers, loading } = useQuery<{ user: FragmentOf<typeof UserOffersFragment> }>(GetUserDetailsQuery, {
    variables: { userId, offers: true, fan: false, licenses: false },
  })

  return (
    <Grid.Row>
      <Grid.Column width={16}>
        {currentUser.can(Permission.UpdateCacheReceipt) && <UserUpdateCacheReceiptButton userId={userId} />}

        <Divider />

        {loading ? (
          <Loader active />
        ) : (
          <List>
            {userOffers?.user &&
              readFragment(UserOffersFragment, userOffers.user).receipts.map((receipt, index) => (
                <List.Item key={receipt.offerId || index}>
                  {receipt.offer && receipt.offer.title}{' '}
                  <strong>
                    <FormattedMessage id={`receiptStatus.${receipt.status}`} />
                  </strong>{' '}
                  - ({receipt.productId || intl.formatMessage({ id: 'users.offers.obtainedFreely' })})
                </List.Item>
              ))}
          </List>
        )}
      </Grid.Column>
    </Grid.Row>
  )
}

export default UserViewOffers
