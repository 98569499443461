import InvisibleButton from 'Components/Button/InvisibleButton'
import SelectEventState from 'Components/Form/SelectEventState'
import LiveStreamPlayerModal from 'Components/LiveStreamPlayerModal'
import ConfirmationButton from 'Containers/Button/ConfirmationButton'
import { WildmokaMediaLiveForm, WildmokaMediaLiveFormData } from 'Forms/Media/WildmokaMediaLiveForm'
import { useDeleteMedia, useUpdateLiveStream, useUpdateMedia } from 'models'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl'
import { Button, Header, Icon, Table } from 'semantic-ui-react'
import {
  LiveStream,
  LiveStreamContentFragment,
  MediaContentFragment,
  MediaType,
  Permission,
  SportEventState,
  WmEvent,
  WmEventState,
  WmLiveType,
  useUpdateLiveStreamStateMutation,
  useCreateWildmokaMediaLiveStreamMutation,
} from 'services/api/graphql'
import { useCurrentUser } from 'stores'
import { notifyError, notifySuccess } from 'tools/toaster'

import { SportItemLivesTableProps } from '../types'

import { LiveStreamWildmokaDetailsModal } from './LiveStreamWildmokaDetailsModal'

export const SportItemWildmokaLivesTable: React.FC<SportItemLivesTableProps> = ({
  sportItem,
  refresh,
  onLoading,
  loading,
}) => {
  const [showModalCreateLive, setShowModalCreateLive] = useState(false)
  const [showModalDetailsLive, setShowModalDetailsLive] = useState(false)
  const [selectedLive, setSelectedLive] = useState<MediaContentFragment | null>(null)
  const [playerOpen, setPlayerOpen] = useState(false)
  const intl = useIntl()
  const currentUser = useCurrentUser()

  const lives = useMemo(
    () => (sportItem.medias || []).filter(media => media.type === MediaType.LiveStream),
    [sportItem],
  )

  const eventEnded = useMemo(() => {
    const sportItemSet = sportItem.sportItemSet
    if (sportItemSet) {
      return sportItemSet && sportItemSet.sportEvent && sportItemSet.sportEvent.state === SportEventState.Finished
    }
    return false
  }, [sportItem])

  const liveEnded = useMemo(
    () =>
      (sportItem && sportItem.sportEventItem && new Date(sportItem.sportEventItem.stopDate) < new Date(Date.now())) ||
      false,
    [sportItem],
  )

  // GraphQL requests
  const [updateLiveStreamState, { loading: loadingUpdateLive }] = useUpdateLiveStreamStateMutation()
  const [createWildmokaMediaLiveStream, { loading: loadingCreateLiveStream }] =
    useCreateWildmokaMediaLiveStreamMutation()
  const [updateLiveStream, { loading: loadingUpdateLiveStream }] = useUpdateLiveStream()
  const [updateMedia] = useUpdateMedia()
  const [deleteMedia, { loading: loadingDeleteMedia }] = useDeleteMedia()

  // Open modal to create a new live
  const openNewLiveModal = useCallback(() => {
    setSelectedLive(null)
    setShowModalCreateLive(true)
  }, [])

  // Submit
  const onSubmitMediaLive = useCallback(
    async ({ title, ...live }: WildmokaMediaLiveFormData) => {
      const liveData = {
        ...live,
        templateLive: live.templateLive || '',
        stream: live.stream || '',
        autoAnnounceDate: live.autoAnnounceDate || undefined,
      }
      try {
        if (!selectedLive) {
          // Create a new live
          await createWildmokaMediaLiveStream({
            variables: {
              input: {
                media: {
                  title: title || sportItem.title,
                  sportItemId: sportItem.id,
                  tags: [],
                },
                ...liveData,
              },
            },
          })
          notifySuccess(intl.formatMessage({ id: 'sportItem.media_created' }))
        } else if (selectedLive.liveStream) {
          // Update selected live
          await Promise.all([
            updateLiveStream({
              variables: {
                input: {
                  id: selectedLive.liveStream.id,
                  name: title,
                  ...liveData,
                },
              },
            }),
            updateMedia({
              variables: { mediaId: selectedLive.id, input: { title } },
            }),
          ])
          notifySuccess(intl.formatMessage({ id: 'sportItem.media_updated' }))
        }
        setShowModalCreateLive(false)
        refresh()
      } catch (e) {
        notifyError(
          intl.formatMessage({
            id: `sportItem.${selectedLive ? 'update_media_error' : 'create_media_error'}`,
          }),
        )
      }
    },
    [
      createWildmokaMediaLiveStream,
      intl,
      refresh,
      selectedLive,
      sportItem.id,
      sportItem.title,
      updateLiveStream,
      updateMedia,
    ],
  )

  // Update a live's state
  const onChangeLiveState = useCallback(
    async (liveStream: LiveStreamContentFragment, state: WmEventState) => {
      await updateLiveStreamState({
        variables: { id: liveStream.id, state },
      })
      refresh()
    },
    [refresh, updateLiveStreamState],
  )

  // Open modal to edit live
  const editMediaLive = useCallback((media: MediaContentFragment) => {
    setSelectedLive(media)
    setShowModalCreateLive(true)
  }, [])

  // Open modal to show live details
  const displayMediaLive = useCallback((media: MediaContentFragment) => {
    setSelectedLive(media)
    setShowModalDetailsLive(true)
  }, [])

  // Open player
  const openLiveStreamPlayer = useCallback((media: MediaContentFragment) => {
    setSelectedLive(media)
    setPlayerOpen(true)
  }, [])

  // Delete live
  const onDeleteLiveStream = useCallback(
    (liveStreamMedia: MediaContentFragment) => {
      return deleteMedia({
        variables: { mediaId: liveStreamMedia.id },
      })
    },
    [deleteMedia],
  )

  // Show loading spinner
  useEffect(() => {
    onLoading(loadingUpdateLive || loadingCreateLiveStream || loadingUpdateLiveStream || loadingDeleteMedia)
  }, [loadingUpdateLive, loadingCreateLiveStream, loadingUpdateLiveStream, loadingDeleteMedia, onLoading])

  // Fill update form with event's data
  const getLiveFormData = (event: WmEvent, id: LiveStream['id']): WildmokaMediaLiveFormData => ({
    id,
    title: event.name,
    description: event.description || undefined,
    templateLive: (event.live && event.live.template && event.live.template.id) || '',
    templateClip: (event.clipTemplate && event.clipTemplate.id) || '',
    stream: event.stream.id,
    notificationEmail: event.notifEmail || undefined,
    autoAnnounceDate: event.live && event.live.autoAnnounceDate ? new Date(event.live.autoAnnounceDate) : undefined,
    createClipAtEndOfEvent: event.clipCreationAtEnd,
    passthrough: (event.live && event.live.type === WmLiveType.Passthrough) || false,
  })

  return (
    <div>
      <div className="flex flex-row justify-between items-center">
        <Header as="h2">
          <FormattedMessage id="sportItemMedias.lives" />
        </Header>
        {currentUser.can(Permission.LiveStreamCreate) && !eventEnded && !liveEnded && (
          <Button circular icon="add" color="red" onClick={openNewLiveModal} />
        )}
      </div>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={5}>
              <FormattedMessage id="sportItemMedias.name" />
            </Table.HeaderCell>
            <Table.HeaderCell width={3}>
              <FormattedMessage id="sportItemMedias.startDate" />
            </Table.HeaderCell>
            <Table.HeaderCell width={3}>
              <FormattedMessage id="sportItemMedias.endDate" />
            </Table.HeaderCell>
            <Table.HeaderCell width={3}>
              <FormattedMessage id="sportItemMedias.state" />
            </Table.HeaderCell>
            <Table.HeaderCell width={2} />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {lives.map(mediaLive => (
            <Table.Row key={mediaLive.id}>
              <Table.Cell>{mediaLive.title}</Table.Cell>

              <Table.Cell>
                <FormattedDate
                  day="2-digit"
                  month="2-digit"
                  year="numeric"
                  hour="2-digit"
                  minute="2-digit"
                  value={(mediaLive.liveStream?.event && new Date(mediaLive.liveStream.event.editoStartDate)) || ''}
                />
              </Table.Cell>
              <Table.Cell>
                <FormattedDate
                  day="2-digit"
                  month="2-digit"
                  year="numeric"
                  hour="2-digit"
                  minute="2-digit"
                  value={
                    (mediaLive.liveStream?.event &&
                      new Date(mediaLive.liveStream.event.stopDate || mediaLive.liveStream.event.editoStopDate)) ||
                    ''
                  }
                />
              </Table.Cell>
              <Table.Cell>
                {mediaLive.liveStream?.event && (
                  <SelectEventState
                    value={mediaLive.liveStream.event.state}
                    onChange={state => mediaLive.liveStream && onChangeLiveState(mediaLive.liveStream, state)}
                    disabled={!currentUser.can(Permission.LiveStreamUpdate)}
                  />
                )}
              </Table.Cell>
              <Table.Cell textAlign="right">
                <InvisibleButton onClick={() => displayMediaLive(mediaLive)}>
                  <Icon name="info circle" />
                </InvisibleButton>
                {mediaLive.liveStream?.event &&
                  ![WmEventState.Stopped, WmEventState.Ended].includes(mediaLive.liveStream.event.state) && (
                    <InvisibleButton onClick={() => openLiveStreamPlayer(mediaLive)}>
                      <Icon name="eye" />
                    </InvisibleButton>
                  )}
                {mediaLive.liveStream?.event &&
                  mediaLive.liveStream.event.state === WmEventState.Pending &&
                  currentUser.can(Permission.LiveStreamUpdate) && (
                    <InvisibleButton onClick={() => editMediaLive(mediaLive)}>
                      <Icon name="edit" />
                    </InvisibleButton>
                  )}
                {currentUser.can(Permission.LiveStreamDelete) && (
                  <ConfirmationButton
                    action={() => onDeleteLiveStream(mediaLive)}
                    onDone={refresh}
                    successText={intl.formatMessage({
                      id: 'livestream.delete_success',
                    })}
                    confirmText={intl.formatMessage(
                      {
                        id: 'livestream.delete',
                      },
                      { title: mediaLive.title },
                    )}
                  />
                )}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <WildmokaMediaLiveForm
        loading={loading}
        open={showModalCreateLive}
        sportItem={sportItem}
        onSubmit={live => onSubmitMediaLive(live)}
        onCancel={() => setShowModalCreateLive(false)}
        {...(selectedLive && selectedLive.liveStream?.event
          ? {
              value: getLiveFormData(selectedLive.liveStream.event, selectedLive.liveStream.id),
            }
          : {})}
      />
      {selectedLive && selectedLive.liveStream && (
        <LiveStreamPlayerModal
          open={playerOpen}
          liveStream={selectedLive.liveStream}
          onClose={() => setPlayerOpen(false)}
        />
      )}
      {selectedLive && selectedLive.liveStream && (
        <LiveStreamWildmokaDetailsModal
          open={showModalDetailsLive}
          livestream={selectedLive.liveStream}
          onClose={() => setShowModalDetailsLive(false)}
        />
      )}
    </div>
  )
}
