import { addSeconds } from 'date-fns'
import { ActiveLiveStreamsResult, LiveStreamState, SportEventItem, SportItem } from 'models'

export const sportItemHasActiveLive = (
  id: string,
  activeLivestreamsData:
    | {
        activeLiveStreams: ActiveLiveStreamsResult
      }
    | undefined,
) => {
  if (activeLivestreamsData) {
    return (
      activeLivestreamsData.activeLiveStreams.sportItems.findIndex(
        sportItem => sportItem.id.toString() === id.toString(),
      ) > -1
    )
  }
  return false
}

export const sportEventItemIsNotStarted = (event: SportEventItem) => {
  const now = new Date()
  const { testDate, startDate } = event

  return testDate ? new Date(testDate) > now : new Date(startDate) > now
}

export const sportEventItemIsTesting = (event: SportEventItem) => {
  const now = new Date()
  if (!event.testDate) return false
  const { testDate, startDate } = event

  return new Date(startDate) > now && new Date(addSeconds(testDate, -1)) <= now
}

export const sportEventItemIsLiveById = (id: string, sportItems: SportItem[]) => {
  const sportItem = sportItems.find(item => item.id === id)
  if (!sportItem?.sportEventItem) return false

  return sportEventItemIsLive(sportItem.sportEventItem)
}

export const sportEventItemIsLive = (event: SportEventItem) => {
  const now = new Date()
  const { testDate, startDate, stopDate } = event

  return (
    new Date(stopDate) > now &&
    (new Date(addSeconds(startDate, -1)) <= now || (testDate ? new Date(addSeconds(testDate, -1)) <= now : false))
  )
}

export const sportEventItemIsOver = (event: SportEventItem) => {
  const now = new Date()
  const { stopDate } = event

  return new Date(stopDate) <= now
}

export const getSportEventItemState = (event: SportEventItem): LiveStreamState => {
  if (sportEventItemIsNotStarted(event)) return LiveStreamState.NotStarted
  if (sportEventItemIsTesting(event)) return LiveStreamState.Testing
  if (sportEventItemIsLive(event)) return LiveStreamState.Started
  return LiveStreamState.Finished
}
