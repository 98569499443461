import ImageLightbox from 'Components/ImageLightbox'
import React, { useState, Fragment } from 'react'

import { Icon, Popup } from 'semantic-ui-react'
import styled from 'styled-components'

const Tooltip = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
`
const TooltipImage = styled.img`
  width: 48px;
  height: 48px;
`
export interface ImagePreviewButtonProps {
  title?: string | undefined
  imageUrl: string | null | undefined
  thumbUrl: string | null | undefined
}
const ImagePreviewButton = ({ title, thumbUrl, imageUrl }: ImagePreviewButtonProps) => {
  const [lightboxImageUrl, setLightboxImageUrl] = useState<string | null>(null)
  return (
    <Fragment>
      <Popup
        flowing
        position="top center"
        trigger={
          <Icon
            name="image"
            link
            size="large"
            inverted
            color="black"
            onClick={() => setLightboxImageUrl(imageUrl ?? null)}
          />
        }
      >
        {title && <Popup.Header>{title}</Popup.Header>}
        {thumbUrl && (
          <Tooltip>
            <TooltipImage src={thumbUrl} alt="preview" />
          </Tooltip>
        )}
      </Popup>
      {!!lightboxImageUrl && <ImageLightbox src={lightboxImageUrl} onClose={() => setLightboxImageUrl(null)} />}
    </Fragment>
  )
}

export default ImagePreviewButton
