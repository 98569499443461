import { DatePicker } from 'antd'
import { RangePickerProps } from 'antd/lib/date-picker/generatePicker'
import moment, { Moment } from 'moment'
import React from 'react'
import styled from 'styled-components'
import { isDateInRange } from 'tools/date'

const { RangePicker } = DatePicker

const Container = styled.div`
  display: flex;
  align-items: center;
  > .field {
    flex: 1;
    margin: 0 !important;
  }
  > * + * {
    margin-left: 0.4rem;
  }
`

export type Period = {
  startDate: Date
  endDate: Date
}
export type SelectDateRangeInputProps = Omit<RangePickerProps<Moment>, 'value' | 'onChange'> & {
  value: Period | undefined | null
  onChange: (value: Period | undefined | null) => void
  datetime?: boolean
  minDate?: Date
  maxDate?: Date
}

export const SelectDateRangeInput: React.FC<SelectDateRangeInputProps> = ({
  value,
  onChange,
  datetime,
  minDate,
  maxDate,
  ...props
}: SelectDateRangeInputProps) => {
  return (
    <Container>
      <RangePicker
        format="DD/MM/YYYY"
        bordered={false}
        disabledDate={date => !isDateInRange({ date: date.toDate(), minDate, maxDate })}
        value={value && [moment(value.startDate), moment(value.endDate)]}
        onChange={value => {
          onChange(value && value[0] && value[1] && { startDate: value[0]?.toDate(), endDate: value[1]?.toDate() })
        }}
        showTime={datetime}
        showNow
        {...props}
      />
    </Container>
  )
}
