import SAFormField from 'Components/Form/SAFormField'
import SelectLocalInput, { SelectLocalInputProps } from 'Components/Form/SelectLocalInput'
import { TabPosition } from 'models'
import React, { useMemo } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import * as icons from 'react-icons/md'
import { useIntl } from 'react-intl'
import { Form, Message } from 'semantic-ui-react'
import { validateRequired, validateUrl } from 'tools/formValidators'
import { MaterialIcon, renderMaterialIcon } from 'tools/icon'

export type TabEditProps = SelectLocalInputProps & {
  index: number
}

export const TabEdit = ({ index, ...localeProps }: TabEditProps) => {
  const intl = useIntl()
  const { defaultLocale, activeTranslation } = localeProps

  const iconsOptions = useMemo(() => Object.keys(icons).map(name => ({ text: name, value: name })), [])

  return (
    <>
      {/* Locale */}
      <SAFormField
        name="locale"
        render={({ input }) => (
          <Form.Field
            {...input}
            disabled={false}
            label={intl.formatMessage({ id: 'locale' })}
            control={() => <SelectLocalInput {...localeProps} />}
          />
        )}
      />

      {/* Title */}
      <SAFormField
        name={`tabs[${index}].titleT.${activeTranslation}`}
        required
        validate={validateRequired}
        render={({ input, meta }) => (
          <Form.Input
            {...input}
            label={intl.formatMessage({ id: 'common.title' })}
            required={activeTranslation === defaultLocale}
            error={!!meta.touched && !!meta.error}
          />
        )}
      />

      {/* URL */}
      <SAFormField
        name={`tabs[${index}].url`}
        required
        validate={validateUrl}
        render={({ input, meta }) => (
          <Form.Input
            {...input}
            label={intl.formatMessage({ id: 'common.url' })}
            required
            error={!!meta.touched && !!meta.error}
          />
        )}
      />

      <Message>
        <Message.Header>{intl.formatMessage({ id: 'tabs.url.interpolation.header' })}</Message.Header>

        <p
          className="whitespace-pre"
          dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'tabs.url.interpolation.content' }) }}
        />
      </Message>

      {/* Position */}
      <SAFormField
        name={`tabs[${index}].position`}
        required
        validate={validateRequired}
        render={({ input, meta }) => (
          <Form.Select
            {...input}
            label={intl.formatMessage({ id: 'tabs.position.title' })}
            error={!!meta.touched && !!meta.error}
            required
            search
            onChange={(_, { value }) => value && input.onChange(value)}
            options={Object.values(TabPosition).map(position => ({
              value: position,
              text: intl.formatMessage({ id: `tabs.position.${position}` }),
            }))}
          />
        )}
      />

      {/* Icon */}
      <SAFormField
        name={`tabs[${index}].icon`}
        required
        validate={validateRequired}
        render={({ input, meta }) => (
          <div className="flex items-center">
            <Form.Select
              {...input}
              label={intl.formatMessage({ id: 'common.icon' })}
              className="flex-1"
              required
              error={!!meta.touched && !!meta.error}
              search
              value={input.value.title}
              onChange={(_, { value }) => {
                if (value) {
                  input.onChange({
                    title: value,
                    svg: renderToStaticMarkup(renderMaterialIcon(value as MaterialIcon) as JSX.Element).replaceAll(
                      / (width|height)="\d+"/g,
                      '',
                    ),
                  })
                }
              }}
              options={iconsOptions}
            />

            <span className="flex-1 ml-4">{renderMaterialIcon(input.value?.title)}</span>
          </div>
        )}
      />

      {/* Blank */}
      <SAFormField
        name={`tabs[${index}].blank`}
        render={({ input }) => (
          <Form.Checkbox
            checked={!!input.value}
            label={intl.formatMessage({
              id: 'tabs.blank',
            })}
            onChange={(_, { checked }) => input.onChange(!!checked)}
          />
        )}
      />

      <div className="mb-48" />
    </>
  )
}
