import CancelFormButton from 'Components/Form/CancelFormButton'
import arrayMutators from 'final-form-arrays'
import { useCreateApplicationDesignMutation, useUpdateApplicationDesignMutation } from 'models'
import React, { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button, Dimmer, Modal } from 'semantic-ui-react'
import { ApplicationDesign } from 'services/api/graphql'
import { getErrorMessage } from 'tools/graphql'
import { notifyError, notifySuccess } from 'tools/toaster'

import ApplicationDesignFormFields from './ApplicationDesignFormFields'

interface ApplicationDesignFormProps {
  applicationDesign?: ApplicationDesign
  action?: string
  organismId?: string | null
  onCancel: () => void
  onDone?: () => void
}

const ApplicationDesignForm = (props: ApplicationDesignFormProps) => {
  const intl = useIntl()
  const { applicationDesign, organismId, action, onCancel, onDone } = props
  const [updateApplicationDesign, { loading: isUpdateLoading }] = useUpdateApplicationDesignMutation()
  const [createApplicationDesign, { loading: isCreateLoading }] = useCreateApplicationDesignMutation()
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(isCreateLoading || isUpdateLoading)
  }, [isCreateLoading, isUpdateLoading])

  const onSubmit = async (values: any) => {
    if (applicationDesign) {
      const result = await updateApplicationDesign({
        variables: {
          input: values,
        },
      })

      if (result && result.data) {
        notifySuccess(
          intl.formatMessage({
            id: 'applicationDesign.update_success',
          }),
        )
        if (onDone) onDone()
      }
    } else if (action === 'create') {
      if (organismId) {
        values.organismId = organismId
      }

      const result = await createApplicationDesign({
        variables: {
          input: values,
        },
      }).catch(error => {
        notifyError(getErrorMessage(error)?.replace('%{id}', organismId ?? ''))
      })

      if (result && result.data) {
        notifySuccess(
          intl.formatMessage({
            id: 'applicationDesign.create_success',
          }),
        )
        if (onDone) onDone()
      }
    }
  }

  return (
    <Form
      onSubmit={onSubmit}
      mutators={{
        ...arrayMutators,
      }}
      initialValues={applicationDesign}
      render={formProps => {
        const { handleSubmit, form, pristine } = formProps
        return (
          <>
            <Modal.Header>
              <FormattedMessage id="applicationDesign.name" />
            </Modal.Header>

            <Modal.Content>
              <Dimmer.Dimmable dimmed={isLoading}>
                <Dimmer active={isLoading} inverted />

                <ApplicationDesignFormFields />
              </Dimmer.Dimmable>
            </Modal.Content>

            <Modal.Actions>
              <CancelFormButton pristine={pristine} onConfirm={form.reset} onCancel={onCancel} />

              <Button positive loading={isLoading} onClick={() => handleSubmit()}>
                <FormattedMessage id="common.submit" />
              </Button>
            </Modal.Actions>
          </>
        )
      }}
    />
  )
}

export default ApplicationDesignForm
