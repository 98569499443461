import InvisibleButton from 'Components/Button/InvisibleButton'
import ColorCircle from 'Components/ColorCircle'
import SportEventStateLabel from 'Components/SportEventStateLabel'
import VideoPlayerModal from 'Components/VideoPlayer/VideoPlayerModal'
import ImageFilePreviewButton from 'Containers/Button/ImageFilePreviewButton'
import { useGetOrganismsMap } from 'models'
import React, { useCallback, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Icon, Popup, Table } from 'semantic-ui-react'
import { SportItemSet, SportItemSetType } from 'services/api/graphql'

export interface SportItemSetTableProps {
  sportItemSets: SportItemSet[]
  actions?: (value: SportItemSet) => React.ReactNode
  refetch: () => void
}

const SportItemSetTable: React.FC<SportItemSetTableProps> = ({ sportItemSets, actions, refetch }) => {
  const { organisms } = useGetOrganismsMap()
  const [selectedVideoUrl, setSelectedVideoUrl] = useState<string>('')
  const [playerOpen, setPlayerOpen] = useState(false)
  const openVideoPlayer = useCallback((url: string) => {
    setSelectedVideoUrl(url)
    setPlayerOpen(true)
  }, [])

  return (
    <>
      <Table style={{ flex: 1 }}>
        <Table.Header>
          <Table.Row textAlign="center">
            <Table.HeaderCell width={1} colSpan={2}>
              <FormattedMessage id="sportItemSets.sport" />
            </Table.HeaderCell>
            <Table.HeaderCell width={2}>
              <FormattedMessage id="sportItemSets.type" />
            </Table.HeaderCell>
            <Table.HeaderCell width={2}>
              <FormattedMessage id="sportItemSets.organism" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="sportItemSets.title" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="sportItemSets.subtitle" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="sportItemSets.publicationStatus" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="sportItemSets.website" />
            </Table.HeaderCell>
            <Table.HeaderCell width={1}>
              <FormattedMessage id="sportItemSets.banner" />
            </Table.HeaderCell>
            <Table.HeaderCell width={1}>
              <FormattedMessage id="sportItemSets.teaser" />
            </Table.HeaderCell>
            <Table.HeaderCell width={1}>
              <FormattedMessage id="sportItemSets.state" />
            </Table.HeaderCell>
            {!!actions && (
              <Table.HeaderCell width={2} textAlign="center">
                <FormattedMessage id="sportItemSets.actions" />
              </Table.HeaderCell>
            )}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {organisms &&
            sportItemSets.map(sportItemSet => {
              const {
                id,
                type: sportItemSetType,
                title: sportItemSetTitle,
                subtitle: sportItemSetSubtitle,
                bannerImage,
                bannerVideo,
                sports,
                publicationStatus,
                ownerOrganismId,
                sportEvent,
                websiteUrl,
              } = sportItemSet
              const organim = ownerOrganismId && organisms[ownerOrganismId]
              const sport = sports[0]
              return (
                <Table.Row key={id}>
                  <Table.Cell>
                    <ColorCircle color={sport && sport.color} />
                  </Table.Cell>
                  <Table.Cell textAlign="center">{sport && sport.title}</Table.Cell>
                  <Table.Cell textAlign="center">
                    <Popup
                      position="top center"
                      content={<FormattedMessage id={`sportItemSetType.${sportItemSetType}`} />}
                      trigger={<Icon name={sportItemSetType === SportItemSetType.Playlist ? 'video play' : 'flag'} />}
                    />
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    {organim ? organim.title : ownerOrganismId ? `Organisme inconnu ${ownerOrganismId}` : ''}
                  </Table.Cell>
                  <Table.Cell textAlign="center">{sportItemSetTitle}</Table.Cell>
                  <Table.Cell textAlign="center">{sportItemSetSubtitle}</Table.Cell>
                  <Table.Cell textAlign="center">
                    <FormattedMessage id={`publicationStatus.${publicationStatus}`} />
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    {websiteUrl && (
                      <Popup
                        flowing
                        position="top center"
                        trigger={
                          <a href={websiteUrl} target="_blank" rel="noopener noreferrer">
                            <Icon name="linkify" color="black" inverted />
                          </a>
                        }
                      >
                        {websiteUrl}
                      </Popup>
                    )}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    {bannerImage && bannerImage.image && (
                      <ImageFilePreviewButton title="Image Banner" imageFile={bannerImage.image} />
                    )}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    {bannerVideo && bannerVideo.videoClip && (
                      <InvisibleButton
                        onClick={() =>
                          bannerVideo && bannerVideo.videoClip && openVideoPlayer(bannerVideo.videoClip.playbackUrl)
                        }
                      >
                        <Icon name="play" inverted color="black" />
                      </InvisibleButton>
                    )}
                  </Table.Cell>

                  <Table.Cell textAlign="center">
                    {sportItemSetType === SportItemSetType.SportEvent && sportEvent && (
                      <SportEventStateLabel state={sportEvent.state} />
                    )}
                  </Table.Cell>
                  {!!actions && <Table.Cell textAlign="center">{actions(sportItemSet)}</Table.Cell>}
                </Table.Row>
              )
            })}
        </Table.Body>
      </Table>
      <VideoPlayerModal
        open={playerOpen}
        onRefresh={refetch}
        onClose={() => {
          setPlayerOpen(false)
        }}
        url={selectedVideoUrl}
      />
    </>
  )
}

export default SportItemSetTable
