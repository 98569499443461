import CreateOrganismForm from 'Forms/Organism/CreateOrganismForm'
import React, { Fragment, useState } from 'react'
import { OrganismType } from 'services/api/graphql'
import { notifyError, notifySuccess } from 'tools/toaster'

export interface AddOrganismButtonProps {
  type?: OrganismType
  title: string
  successTextFormat: string // {{title}} replaced with organisme title
  onDone: () => void
  render: (onClick: () => void) => React.ReactNode
}
const AddOrganismButton = ({ title, successTextFormat, type, onDone, render }: AddOrganismButtonProps) => {
  const [form, setForm] = useState<React.ReactNode>()
  const handleClick = () => {
    setForm(
      <CreateOrganismForm
        type={type}
        title={title}
        onCancel={() => setForm(null)}
        onDone={organism => {
          setForm(null)
          notifySuccess(successTextFormat.replace('{{title}}', organism.title))
          onDone()
        }}
        onError={notifyError}
      />,
    )
  }
  return (
    <Fragment>
      {render(handleClick)}
      {form}
    </Fragment>
  )
}

export default AddOrganismButton
