import { useQuery } from '@apollo/client'
import { SelectedTranslationsType } from 'Components/Form/SelectLocalInput'
import { FormBaseProps } from 'Forms/FormBase'
import { useUpdateSportItem } from 'models'
import React, { useCallback, useState } from 'react'
import { useIntl } from 'react-intl'
import { SportItem, useSportItemSetQuery } from 'services/api/graphql'
import { getSelectedTranslations } from 'tools/translationTools'

import SportItemForm, { SportItemFormValues } from './SportItemForm'
import { SportItem_APIToFormValues, SportItem_FormValuesToAPI_Update } from './SportItemForm.transformers'
import { GetSportItemQuery } from './fragments'

interface EditSportItemFormBaseProps {
  onCancel?: () => void
  onDone: (sportItem: SportItem) => void
  onError: (error: Error) => void
}
interface EditSportItemFormWithSportItemProps extends FormBaseProps<SportItem> {
  sportItemValues: SportItemFormValues
  sportItemSetId: string
  isModal: boolean
  editType: boolean
  title: string
}
const EditSportItemFormWithSportItem = ({
  sportItemSetId,
  sportItemValues,
  isModal,
  editType,
  onCancel,
  onDone,
  onError,
  title,
}: EditSportItemFormWithSportItemProps) => {
  const [updateSportItem, { loading: updateSportItemLoading }] = useUpdateSportItem()
  const SportItemSetQuery = useSportItemSetQuery({ variables: { sportItemSetId } })
  const videoEngine = SportItemSetQuery.data?.sportItemSet?.videoEngine

  const loading = updateSportItemLoading || SportItemSetQuery.loading

  const intl = useIntl()
  const initialValues: SportItemFormValues = sportItemValues
  const [selectedTranslations, setSelectedTranslations] = useState<SelectedTranslationsType[]>(
    getSelectedTranslations(initialValues),
  )
  const [defaultLocale, setDefaultLocale] = useState<string>(initialValues.defaultLocale || '')

  const handleSubmit = useCallback(
    async (values, selectedTranslations) => {
      if (!sportItemValues.id) {
        throw new Error(`Missing sportItem id !`)
      }

      // cannot be destructured like defaultLocale as it can be undefined on finished events
      if (values?.sportEventItem) {
        delete values?.sportEventItem?.passthrough
      }

      const input = SportItem_FormValuesToAPI_Update(values, selectedTranslations)

      try {
        const res = await updateSportItem({ variables: { input } })
        if (res && res.data) onDone(res.data.updateSportItem)
      } catch (err: any) {
        onError?.(err)
      }
    },
    [onDone, onError, sportItemValues.id, updateSportItem],
  )
  if (!videoEngine) return null

  return (
    <SportItemForm
      onChangeSelectedTranslations={setSelectedTranslations}
      isModal={isModal}
      selectedTranslations={selectedTranslations}
      defaultLocale={defaultLocale}
      onChangeDefaultLocale={setDefaultLocale}
      title={intl.formatMessage({ id: `sportItem.edit.header` }, { title })}
      edit
      editType={editType}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      loading={loading}
      videoEngine={videoEngine}
      sportItemSetId={sportItemSetId}
    />
  )
}

interface EditSportItemFormProps extends EditSportItemFormBaseProps {
  sportItemId: SportItem['id']
  isModal: boolean
  editType: boolean
}
const EditSportItemForm = ({ sportItemId, ...props }: EditSportItemFormProps) => {
  const { data } = useQuery(GetSportItemQuery, { variables: { id: sportItemId }, fetchPolicy: 'network-only' })
  const sportItem = data?.sportItem
  if (!sportItem) return null

  const editableSportItemValues: SportItemFormValues = SportItem_APIToFormValues(sportItem)
  return (
    <EditSportItemFormWithSportItem
      sportItemValues={editableSportItemValues}
      sportItemSetId={sportItem.sportItemSetId}
      title={sportItem.title}
      {...props}
    />
  )
}
export default EditSportItemForm
