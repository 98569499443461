import Editor from 'Components/Form/Editor/Editor'
import GenericForm from 'Components/Form/GenericForm'
import SAFormField from 'Components/Form/SAFormField'
import { SelectDateInput } from 'Components/Form/SelectDateInput'
import SelectLocalInput, { SelectedTranslationsType, TranslatedInput } from 'Components/Form/SelectLocalInput'
import FileInput from 'Containers/Form/FileInput'
import SelectOrganismsInput from 'Containers/Form/SelectOrganismsInput'
import SelectPublicationStatusInput from 'Containers/Form/SelectPublicationStatusInput'
import { ValidationErrors } from 'final-form'
import React, { FC, useCallback, useState } from 'react'
import { FieldRenderProps } from 'react-final-form'
import { useIntl } from 'react-intl'
import { Form, TextArea } from 'semantic-ui-react'
import { Media, MediaType, News, PublicationStatus } from 'services/api/graphql'
import { useStore } from 'stores'
import { validateRequired, validateRequiredEditor } from 'tools/formValidators'
import { getSelectedTranslations, validateTranslation } from 'tools/translationTools'
import './NewsForm.css'

export type NewsFormValues = {
  id?: string // must be set when editing
  organismId: string
  title: string
  titleT?: TranslatedInput | null
  subtitle: string
  subtitleT?: TranslatedInput | null
  description: string
  descriptionT?: TranslatedInput | null
  content: string
  contentT?: TranslatedInput | null
  defaultLocale?: string
  imageId: Media['id']
  publicationStatus: PublicationStatus
}

export type NewsFormProps = {
  title: string
  edit: boolean
  isModal: boolean
  initialValues?: NewsFormValues
  onSubmit: (
    values: NewsFormValues,
    defaultLocale: string,
    selectedTranslations: SelectedTranslationsType[],
  ) => Promise<void> | void
  onCancel?: () => void
  loading: boolean
}

const NewsForm: FC<NewsFormProps> = ({ initialValues, onSubmit, onCancel, loading, title, isModal }) => {
  const intl = useIntl()
  const {
    organismId,
    currentUser: { isAdmin },
  } = useStore()

  const [selectedTranslations, setSelectedTranslations] = useState<SelectedTranslationsType[]>(
    initialValues ? getSelectedTranslations(initialValues) : [],
  )
  const [defaultLocale, setDefaultLocale] = useState<string>(initialValues?.defaultLocale || 'fr')
  const [activeTranslation, setActiveTranslation] = useState<string | undefined>(defaultLocale)

  const onChangeDefaultLocale = useCallback((defaultLocale: string) => {
    setDefaultLocale(defaultLocale)
    setActiveTranslation(defaultLocale)
  }, [])

  const validate = useCallback(
    (values): ValidationErrors | Promise<ValidationErrors> | undefined => ({
      titleT: validateTranslation(activeTranslation, values?.titleT?.[`${defaultLocale}`], intl),
      descriptionT: validateTranslation(activeTranslation, values?.descriptionT?.[`${defaultLocale}`], intl),
      contentT: validateTranslation(activeTranslation, values?.contentT?.[`${defaultLocale}`], intl),
    }),
    [activeTranslation, defaultLocale, intl],
  )

  const handleSubmit = useCallback(
    values => onSubmit({ organismId, ...values }, defaultLocale, selectedTranslations),
    [defaultLocale, onSubmit, organismId, selectedTranslations],
  )

  return (
    <GenericForm
      header={title}
      initialValues={initialValues}
      onCancel={onCancel}
      onSubmit={handleSubmit}
      validate={validate}
      loading={loading}
      isModal={isModal}
    >
      <SAFormField
        name="locale"
        render={({ input }) => (
          <Form.Field
            {...input}
            label={intl.formatMessage({ id: 'locale' })}
            control={() => (
              <SelectLocalInput
                defaultLocale={defaultLocale}
                onChangeDefaultLocale={onChangeDefaultLocale}
                activeTranslation={activeTranslation}
                onChangeActiveTranslation={setActiveTranslation}
                selectedTranslations={selectedTranslations}
                onChangeSelectedTranslations={setSelectedTranslations}
              />
            )}
          />
        )}
      />
      <SAFormField
        name="organismId"
        validate={validateRequired}
        defaultValue={organismId || undefined}
        render={({ input, meta }: FieldRenderProps<News['organismId'], HTMLInputElement>) => (
          <Form.Field
            control={SelectOrganismsInput}
            {...input}
            required
            error={!!meta.touched && !!meta.error}
            label={intl.formatMessage({ id: 'organism' })}
            disabled={!isAdmin}
          />
        )}
      />
      <SAFormField
        name={`titleT.${activeTranslation}`}
        render={({ input, meta }) => (
          <Form.Input
            {...input}
            required={activeTranslation === defaultLocale}
            error={!!meta.touched && !!meta.error}
            label={intl.formatMessage({ id: 'news.title' })}
          />
        )}
      />
      <SAFormField
        name={`subtitleT.${activeTranslation}`}
        render={({ input, meta }) => (
          <div className="newsFormContainer">
            <Form.Field
              error={!!meta.touched && !!meta.error}
              required
              isNewsForm={true}
              datetime={false}
              control={SelectDateInput}
              format="dddd DD MMMM"
              {...input}
              label={intl.formatMessage({
                id: 'news.date',
              })}
            />
          </div>
        )}
      />

      <SAFormField
        name={`descriptionT.${activeTranslation}`}
        render={({ input, meta }) => (
          <Form.Input
            {...input}
            required={activeTranslation === defaultLocale}
            control={TextArea}
            maxLength={500}
            error={!!meta.touched && !!meta.error}
            label={intl.formatMessage({ id: 'news.description' })}
            placeholder={intl.formatMessage({ id: 'news.description.placeholder' })}
          />
        )}
      />
      <SAFormField
        name="publicationStatus"
        validate={validateRequired}
        render={({ input, meta }) => (
          <Form.Field
            control={SelectPublicationStatusInput}
            {...input}
            required
            error={!!meta.touched && !!meta.error}
            label={intl.formatMessage({ id: 'publicationStatus' })}
          />
        )}
      />
      <SAFormField
        name="imageId"
        validate={validateRequired}
        render={({ input, meta }) => (
          <Form.Field
            control={FileInput}
            {...input}
            type={MediaType.Image}
            required
            error={!!meta.touched && !!meta.error}
            label={intl.formatMessage({ id: 'news.coverImage' })}
            mimetype="image"
            imageConstraints={{
              aspect: 3 / 2,
              minWidth: 225,
              minHeight: 150,
              maxSize: 3000000,
            }}
            renderOrganismPicker
          />
        )}
      />
      <SAFormField
        key={activeTranslation}
        name={`contentT.${activeTranslation}`}
        validate={validateRequiredEditor}
        render={({ input, meta }) => (
          <Form.Field
            {...input}
            control={Editor}
            required
            maxLength={50000}
            error={meta.touched && meta.error}
            label={intl.formatMessage({
              id: 'news.content',
            })}
            organismId={isAdmin ? undefined : organismId}
          />
        )}
      />
    </GenericForm>
  )
}

export default NewsForm
