import React from 'react'
import { useCallback } from 'react'
import { Input, InputProps } from 'semantic-ui-react'
import styled from 'styled-components'

const StyledInput = styled(Input)`
  width: 100%;
`

interface TableCellInputProps extends InputProps {
  onValidate?: () => void
  onCancel?: () => void
}

const TableCellInput: React.FC<TableCellInputProps> = ({ onValidate, onCancel, ...props }) => {
  /** Detect when user press Enter or Escape while editing */
  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Enter' && onValidate) {
        onValidate()
      }
      if (e.key === 'Escape' && onCancel) {
        onCancel()
      }
    },
    [onValidate, onCancel],
  )

  return <StyledInput {...props} onKeyDown={handleKeyDown} />
}

export default TableCellInput
