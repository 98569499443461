import { gql } from '@apollo/client'

import { CONTENT_TRANSLATION_FRAGMENT } from './ContentTranslation'

export const TAB_FRAGMENT = gql`
  fragment tabContent on Tab {
    title
    titleT {
      ...contentTranslationContent
    }
    url
    position
    icon {
      title
      svg
    }
    blank
  }
  ${CONTENT_TRANSLATION_FRAGMENT}
`
