import { IntlShape } from 'react-intl'

import { Access } from 'services/api'

import { applicationUris } from './uris'

export enum MenuItemId {
  AdCampaigns = 'adCampaigns',
  Administration = 'administration',
  ApplicationDesigns = 'applicationDesigns',
  Applications = 'applications',
  ChatRooms = 'chat-rooms',
  ContentPushStreams = 'contentPushStreams',
  Contents = 'contents',
  CustomPages = 'customPages',
  Dashboard = 'dashboard',
  DefaultConfig = 'defaultConfig',
  EditedCategory = 'editedCategories',
  Editorial = 'editorial',
  FrontPages = 'frontPages',
  LegalRestrictions = 'legalRestrictions',
  Library = 'library',
  LicensedUsers = 'licended-users',
  Links = 'links',
  LinksAnalytics = 'linksAnalytics',
  Live = 'live',
  Monitoring = 'monitoring',
  News = 'news',
  Organisms = 'organisms',
  OrganismsFrontPages = 'organismsFrontPages',
  OrganismsPages = 'organismsPages',
  Playlists = 'playlists',
  PurchasableOffers = 'purchasableOffers',
  Sportevents = 'sportevents',
  Sports = 'sports',
  StreamMap = 'StreamMap',
  SVEChannels = 'SVEChannels',
  ThirdPartyApplications = 'third-party-applications',
  Users = 'users',
  VideoGrid = 'video-grid',
}

export type Item = {
  id: MenuItemId
  title: string
  route: string
  access?: Access[]
}
export type SidebarMenuItem = {
  id: MenuItemId
  title: string
  items: Item[]
}
export const menuItems = (intl: IntlShape): SidebarMenuItem[] => [
  {
    id: MenuItemId.Monitoring,
    title: intl.formatMessage({ id: 'menu.monitoring' }),
    items: [
      {
        id: MenuItemId.Dashboard,
        title: intl.formatMessage({ id: 'menu.monitoringDashboard' }),
        route: applicationUris.dashboard,
      },
      {
        id: MenuItemId.VideoGrid,
        title: intl.formatMessage({ id: 'menu.videoGrid' }),
        route: applicationUris.videoGrid,
        access: [Access.VideoGrid],
      },
    ],
  },
  {
    id: MenuItemId.Users,
    title: intl.formatMessage({ id: 'menu.users' }),
    items: [
      {
        id: MenuItemId.Users,
        title: intl.formatMessage({ id: 'menu.users' }),
        route: applicationUris.users,
        access: [Access.UsersView],
      },
      {
        id: MenuItemId.LicensedUsers,
        title: intl.formatMessage({ id: 'menu.licensedUsers' }),
        route: applicationUris.licensedUsers,
        access: [Access.LicensedUsers],
      },
    ],
  },
  {
    id: MenuItemId.Sports,
    title: intl.formatMessage({ id: 'menu.sports' }),
    items: [
      {
        id: MenuItemId.Sports,
        title: intl.formatMessage({ id: 'menu.allsports' }),
        route: applicationUris.sports,
        access: [Access.SportsView],
      },
      {
        id: MenuItemId.OrganismsFrontPages,
        title: intl.formatMessage({ id: 'menu.sports.frontPages' }),
        route: applicationUris.sportsFrontPages,
        access: [Access.FrontPages],
      },
    ],
  },
  {
    id: MenuItemId.Organisms,
    title: intl.formatMessage({ id: 'menu.organisms' }),
    items: [
      {
        id: MenuItemId.Organisms,
        title: intl.formatMessage({ id: 'menu.allorganisms' }),
        route: applicationUris.organisms,
        access: [Access.OrganismsView],
      },
      {
        id: MenuItemId.PurchasableOffers,
        title: intl.formatMessage({ id: 'menu.purchasableOffers' }),
        route: applicationUris.purchasableOffers,
        access: [Access.PurchasableOffersView],
      },
      {
        id: MenuItemId.ApplicationDesigns,
        title: intl.formatMessage({ id: 'menu.applicationDesigns' }),
        route: applicationUris.applicationDesigns,
        access: [Access.ApplicationDesigns],
      },

      {
        id: MenuItemId.OrganismsFrontPages,
        title: intl.formatMessage({ id: 'menu.organisms.frontPages' }),
        route: applicationUris.organismsFrontPages,
        access: [Access.FrontPages],
      },
    ],
  },
  {
    id: MenuItemId.Contents,
    title: intl.formatMessage({ id: 'menu.contents' }),
    items: [
      {
        id: MenuItemId.Sportevents,
        title: intl.formatMessage({ id: 'menu.events' }),
        route: applicationUris.sportEvents,
        access: [Access.SportEventsView],
      },
      {
        id: MenuItemId.Playlists,
        title: intl.formatMessage({ id: 'menu.playlists' }),
        route: applicationUris.playlists,
        access: [Access.PlayListsView],
      },
      {
        id: MenuItemId.News,
        title: intl.formatMessage({ id: 'menu.news' }),
        route: applicationUris.newss,
        access: [Access.NewsView],
      },
      {
        id: MenuItemId.ChatRooms,
        title: intl.formatMessage({ id: 'menu.chatRooms' }),
        route: applicationUris.chatRooms,
        access: [Access.ChatRooms],
      },
    ],
  },
  {
    id: MenuItemId.Editorial,
    title: intl.formatMessage({ id: 'menu.editorial' }),
    items: [
      {
        id: MenuItemId.FrontPages,
        title: intl.formatMessage({ id: 'menu.frontPages' }),
        route: applicationUris.frontPages,
        access: [Access.FrontPages],
      },
      {
        id: MenuItemId.EditedCategory,
        title: intl.formatMessage({ id: 'editedCategory.title' }, { type: 'plural' }),
        route: applicationUris.editedCategories,
        access: [Access.EditedCategories],
      },
      {
        id: MenuItemId.Applications,
        title: intl.formatMessage({ id: 'menu.applications' }),
        route: applicationUris.applications,
        access: [Access.ApplicationView],
      },
      {
        id: MenuItemId.CustomPages,
        title: intl.formatMessage({ id: 'customPage.name' }, { type: 'plural' }),
        route: applicationUris.customPages,
        access: [Access.CustomPagesView],
      },
      {
        id: MenuItemId.OrganismsPages,
        title: intl.formatMessage({ id: 'menu.organisms.pages' }),
        route: applicationUris.organismsPages,
        access: [Access.OrganismPagesView],
      },
    ],
  },
  {
    id: MenuItemId.Links,
    title: intl.formatMessage({ id: 'menu.links' }),
    items: [
      {
        id: MenuItemId.Links,
        title: intl.formatMessage({ id: 'menu.links' }),
        route: applicationUris.links,
        access: [Access.Links],
      },
      {
        id: MenuItemId.AdCampaigns,
        title: intl.formatMessage({ id: 'menu.adCampaigns' }),
        route: applicationUris.adCampaigns,
        access: [Access.AdCampaigns],
      },
      {
        id: MenuItemId.LinksAnalytics,
        title: intl.formatMessage({ id: 'menu.linksStats' }),
        route: applicationUris.linksAnalytics,
        access: [Access.LinksStatistics],
      },
    ],
  },
  {
    id: MenuItemId.Library,
    title: intl.formatMessage({ id: 'menu.medias' }),
    items: [
      {
        id: MenuItemId.Library,
        title: intl.formatMessage({ id: 'menu.medias' }),
        route: applicationUris.medias,
        access: [Access.MediaLibraryView],
      },
    ],
  },

  {
    id: MenuItemId.Administration,
    title: intl.formatMessage({ id: 'menu.administration' }),
    items: [
      {
        id: MenuItemId.DefaultConfig,
        title: intl.formatMessage({ id: 'menu.defaultConfig' }),
        route: applicationUris.defaultConfig,
        access: [Access.DefaultConfig],
      },
      {
        id: MenuItemId.ContentPushStreams,
        title: intl.formatMessage({ id: 'menu.contentPushStreams' }),
        route: applicationUris.contentPushStreams,
        access: [Access.ContentPushStreams],
      },
      {
        id: MenuItemId.SVEChannels,
        title: intl.formatMessage({ id: 'menu.sveChannels' }),
        route: applicationUris.sveChannels,
        access: [Access.SVEChannels],
      },
      {
        id: MenuItemId.StreamMap,
        title: intl.formatMessage({ id: 'menu.streamMap' }),
        route: applicationUris.streamMap,
        access: [Access.StreamMap],
      },
      {
        id: MenuItemId.LegalRestrictions,
        title: intl.formatMessage({ id: 'menu.legalRestrictions' }),
        route: applicationUris.legalRestrictions,
        access: [Access.LegalRestrictions],
      },
      {
        id: MenuItemId.ThirdPartyApplications,
        title: intl.formatMessage({ id: 'menu.thirdPartyApplications' }),
        route: applicationUris.thirdPartyApplications,
        access: [Access.ThirdPartyApplicationView],
      },
    ],
  },
]
