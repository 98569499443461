import InvisibleButton from 'Components/Button/InvisibleButton'
import SROnly from 'Components/Button/SROnly'
import React, { Fragment, useState } from 'react'
import { useCallback } from 'react'
import { useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import { Button, ButtonGroup, Icon, Modal, Popup, PopupProps } from 'semantic-ui-react'
import { IconSizeProp } from 'semantic-ui-react/dist/commonjs/elements/Icon/Icon'
import { SemanticICONS } from 'semantic-ui-react/dist/commonjs/generic'
import { notifyError, notifySuccess } from 'tools/toaster'

export interface ConfirmationButtonBaseProps {
  confirmText: React.ReactNode
  successText: string
  onDone?: () => void
  onError?: (error: Error) => void
  onCancel?: () => void
  icon?: SemanticICONS
  size?: IconSizeProp
  disabled?: boolean
  light?: boolean
  position?: PopupProps['position']
  notifySuccess?: boolean
}

interface ConfirmationButtonProps extends ConfirmationButtonBaseProps {
  action: () => Promise<any>
  children?: React.ReactNode
}

const ConfirmationButton = ({
  action,
  confirmText,
  successText,
  onCancel,
  onDone,
  onError,
  icon = 'remove',
  size = 'large',
  disabled,
  light,
  position,
  children,
  notifySuccess: notifySuccessEnabled = true,
}: ConfirmationButtonProps) => {
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const buttonRef = useRef<HTMLButtonElement>(null)

  const cancel = useCallback(() => {
    setOpen(false)
    if (onCancel) onCancel()
  }, [onCancel])

  const validate = useCallback(() => {
    setLoading(true)
    action()
      .then(() => {
        notifySuccessEnabled && notifySuccess(successText)
        setOpen(false)
        if (onDone) {
          onDone()
        }
      })
      .catch(error => {
        notifyError(<FormattedMessage id="error.general" />, error)
        if (onError) onError(error)
      })
      .finally(() => setLoading(false))
  }, [action, notifySuccessEnabled, onDone, onError, successText])

  return (
    <Fragment>
      <InvisibleButton
        onClick={e => {
          e.preventDefault()
          setOpen(true)
        }}
        disabled={disabled}
        ref={buttonRef}
      >
        {children || (
          <>
            <Icon name={icon} link={!disabled} size={size} inverted color="red" />
            <SROnly>
              <FormattedMessage id="common.delete" />
            </SROnly>
          </>
        )}
      </InvisibleButton>

      <Modal mountNode={document.getElementById('modals')} size="mini" open={!light && open} onClose={cancel}>
        <Modal.Header>{confirmText}</Modal.Header>

        <Modal.Actions>
          <Button onClick={cancel}>
            <FormattedMessage id="common.no" />
          </Button>
          <Button negative loading={loading} onClick={validate}>
            <FormattedMessage id="common.yes" />
          </Button>
        </Modal.Actions>
      </Modal>

      <Popup open={light && open} context={buttonRef} onClose={cancel} position={position} size="small">
        <Popup.Header>{confirmText}</Popup.Header>
        <Popup.Content>
          <ButtonGroup fluid size="small">
            <Button onClick={cancel}>
              <FormattedMessage id="common.no" />
            </Button>
            <Button negative loading={loading} onClick={validate}>
              <FormattedMessage id="common.yes" />
            </Button>
          </ButtonGroup>
        </Popup.Content>
      </Popup>
    </Fragment>
  )
}

export default ConfirmationButton
