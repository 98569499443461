import PageHeader from 'Components/PageHeader'
import EditedCategoryForm from 'Forms/EditedCategory/EditedCategoryForm'
import { useGetEditedCategory } from 'models'
import React from 'react'
import { useIntl } from 'react-intl'
import { useNavigate, useParams } from 'react-router-dom'
import { Segment } from 'semantic-ui-react'
import { useStore } from 'stores'

import styled from 'styled-components'
import { cloneWithoutGraphqlCacheTypenames } from 'tools/graphql'

type EditedCategoryViewProps = {
  editedCategoryViewModalId?: string
  onClose?: () => void
}
const EditedCategoryView = ({ editedCategoryViewModalId, onClose }: EditedCategoryViewProps) => {
  const store = useStore()
  const intl = useIntl()
  const navigate = useNavigate()

  const { editedCategoryId } = useParams()

  const isEdit = Boolean(editedCategoryId || editedCategoryViewModalId)

  const { editedCategory, loading: editedCategoryLoading } = useGetEditedCategory(
    editedCategoryViewModalId || (editedCategoryId as string),
    {
      skip: !editedCategoryId && !editedCategoryViewModalId,
    },
  )

  return (
    <EditedCategoryContainer>
      <div className="p-2">
        <PageHeader title={intl.formatMessage({ id: 'editedCategory.title' }, { type: 'singular' })} />
      </div>
      <Segment>
        {!editedCategoryLoading && (
          <EditedCategoryForm
            isEdit={isEdit}
            editedCategory={editedCategory && cloneWithoutGraphqlCacheTypenames(editedCategory)}
            organismId={store.organismId || undefined}
            onCancel={() => (editedCategoryViewModalId && onClose ? onClose() : navigate(-1))}
            onDone={() => (editedCategoryViewModalId && onClose ? onClose() : navigate(-1))}
          />
        )}
      </Segment>
    </EditedCategoryContainer>
  )
}
const EditedCategoryContainer = styled.div`
  display: 'flex',
  'flex-direction': 'column',
  padding: '20px',
`

export default EditedCategoryView
