export type ConvertToDropdownOption = {
  id: string
  title: string
}

export const convertToDropdownOptions = (data: ConvertToDropdownOption) => ({
  key: data.id,
  value: data.id,
  text: data.title,
})
