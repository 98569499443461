import { useGetUsersExportStatus, useScheduleUsersExport } from 'models'
import React, { useCallback } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button } from 'semantic-ui-react'
import { useLatestUsersExportDownloadUrlLazyQuery, UsersExportState, UsersExportView } from 'services/api/graphql'
import { notifyError } from 'tools/toaster'

const UsersExportButton: React.FC = () => {
  const intl = useIntl()

  const [latestUsersExportDownloadUrl, { loading }] = useLatestUsersExportDownloadUrlLazyQuery({
    fetchPolicy: 'network-only',
  })
  const [scheduleUsersExport, { loading: scheduleLoading }] = useScheduleUsersExport()

  const { usersExportStatus } = useGetUsersExportStatus()

  const getLatestUserExport = useCallback(async () => {
    try {
      const result = await latestUsersExportDownloadUrl()
      const latestUsersExport = result.data?.latestUsersExportDownloadUrl
      if (!latestUsersExport) {
        return
      }

      const link = document.createElement('a')
      link.href = latestUsersExport
      link.click()
    } catch (error) {
      notifyError((error as Error).message)
      console.error(error)
    }
  }, [latestUsersExportDownloadUrl])

  const scheduleExport = useCallback(async () => {
    await scheduleUsersExport({ variables: { input: { view: UsersExportView.Admin } } })
  }, [scheduleUsersExport])

  return (
    <>
      <div title={intl.formatMessage({ id: `users.export.status.${usersExportStatus || 'loading'}` })}>
        <Button
          primary
          onClick={scheduleExport}
          loading={scheduleLoading}
          disabled={scheduleLoading || usersExportStatus !== UsersExportState.Idle}
        >
          <FormattedMessage id="users.export.schedule" />
        </Button>
      </div>
      <Button primary onClick={getLatestUserExport} loading={loading} disabled={loading}>
        <FormattedMessage id="users.export.generateCSV" />
      </Button>
    </>
  )
}

export default UsersExportButton
