import EditSportForm from 'Forms/Sport/EditSportForm'
import React, { Fragment, useState } from 'react'
import { useIntl } from 'react-intl'
import { Sport } from 'services/api/graphql'
import { notifyError, notifySuccess } from 'tools/toaster'

interface EditSportButtonProps {
  sportId: Sport['id']
  onDone?: () => void
  render: (onClick: () => void) => React.ReactNode
}

const EditSportButton = ({ onDone, sportId, render }: EditSportButtonProps) => {
  const intl = useIntl()
  const [form, setForm] = useState<React.ReactNode | null>()

  const handleClick = () => {
    setForm(
      sportId && (
        <EditSportForm
          sportId={sportId}
          onCancel={() => setForm(null)}
          onDone={sport => {
            setForm(null)
            notifySuccess(intl.formatMessage({ id: 'sports.form.edit.success' }, { title: sport.title }))
            if (onDone) onDone()
          }}
          onError={notifyError}
        />
      ),
    )
  }
  return (
    <Fragment>
      {render(handleClick)}
      {form}
    </Fragment>
  )
}

export default EditSportButton
