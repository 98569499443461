import RefreshButton from 'Components/Button/RefreshButton'
import Loader from 'Components/Loader'
import PageHeader from 'Components/PageHeader'
import { applicationUris } from 'Layout/uris'
import { useDeletePurchableOfferProduct, useGetPurchasableOffer } from 'models'
import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Button, Grid, Header, Modal, Table } from 'semantic-ui-react'
import { OfferMode, Permission, Product, PurchasableOffer } from 'services/api/graphql'
import { useCurrentUser, useStore } from 'stores'
import { cloneWithoutGraphqlCacheTypenames } from 'tools/graphql'

import ProductsDataRow from './Products/ProductsDataRow'
import ProductsForm from './Products/ProductsForm'

const ProductsView = () => {
  const currentUser = useCurrentUser()
  const navigate = useNavigate()

  const store = useStore()
  const intl = useIntl()
  const { purchasableOfferId } = useParams<{ purchasableOfferId: string }>()
  const [isModalOpen, setModalOpen] = useState(false)
  const [purchasableOffer, setPurchasableOffer] = useState<PurchasableOffer>()
  const [products, setProducts] = useState<PurchasableOffer['products']>([])

  const { loading, refetch } = useGetPurchasableOffer(purchasableOfferId ?? '', {
    fetchPolicy: 'cache-and-network',
    skip: !purchasableOfferId,
    onCompleted: data => {
      setPurchasableOffer(cloneWithoutGraphqlCacheTypenames(data.purchasableOffer))
    },
  })
  const [deleteProduct] = useDeletePurchableOfferProduct()

  useEffect(() => {
    setProducts((purchasableOffer && purchasableOffer.products) || [])
  }, [purchasableOffer])

  useEffect(() => {
    if (purchasableOffer && store.organismId !== purchasableOffer.organismId) {
      navigate(applicationUris.purchasableOffers)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.organismId])

  const removeProduct = async (product: Product) => {
    if (!purchasableOfferId) return
    await deleteProduct({
      variables: {
        id: purchasableOfferId,
        input: {
          productId: product.productId,
        },
      },
    })
    refetch()
  }

  const pageTitle = (
    <>
      {purchasableOffer && <Header>{purchasableOffer.title}:</Header>}
      {intl.formatMessage({ id: 'product.name' })}
    </>
  )
  return (
    <Loader loading={loading}>
      <Grid padded>
        <Grid.Column>
          <p>
            <Link to={applicationUris.purchasableOffers}>
              <Button labelPosition="left" icon="left chevron" content={intl.formatMessage({ id: 'common.back' })} />
            </Link>
          </p>
          <PageHeader title={pageTitle}>
            <RefreshButton onClick={() => refetch()} loading={loading} />
            {currentUser.can(Permission.PurchasableOfferWrite) && purchasableOffer && (
              <Modal
                mountNode={document.getElementById('modals')}
                trigger={<Button icon="add" circular color="red" />}
                content={
                  <ProductsForm
                    purchasableOffer={purchasableOffer}
                    onDone={() => {
                      refetch()
                      setModalOpen(false)
                    }}
                    onCancel={() => setModalOpen(false)}
                  />
                }
                onClose={() => setModalOpen(false)}
                onOpen={() => setModalOpen(true)}
                open={isModalOpen}
              />
            )}
          </PageHeader>
          <Table striped compact celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  <FormattedMessage id="purchasableOffer.publicationStatus" />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <FormattedMessage id={'product.description'} />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <FormattedMessage id="product.productId" />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <FormattedMessage id="product.platform" />
                </Table.HeaderCell>
                {purchasableOffer && purchasableOffer.mode === OfferMode.Subscription && (
                  <Table.HeaderCell>
                    <FormattedMessage id="product.period" />
                  </Table.HeaderCell>
                )}
                <Table.HeaderCell>
                  <FormattedMessage id="product.actions" />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {products &&
                products.map(
                  (product, index) =>
                    purchasableOffer && (
                      <ProductsDataRow
                        key={index}
                        purchasableOffer={purchasableOffer}
                        product={product}
                        onUpdateData={refetch}
                        onRemoveProduct={() => removeProduct(product)}
                      />
                    ),
                )}
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid>
    </Loader>
  )
}

export default ProductsView
