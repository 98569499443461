import { applicationUris } from 'Layout/uris'
import SportsFrontPages from 'Views/SportsFrontPages'
import SportsView from 'Views/SportsView'
import React from 'react'
import { Access, userCanAccessToPath } from 'services/api'
import { IUser } from 'stores'

const sportsRoutes = (user: IUser | null) => [
  {
    path: applicationUris.sports,
    element: <SportsView />,
    loader: () => userCanAccessToPath(user, [Access.SportsView]),
  },
  {
    path: applicationUris.sportsFrontPages,
    element: <SportsFrontPages />,
    loader: () => userCanAccessToPath(user, [Access.FrontPages]),
  },
]

export default sportsRoutes
