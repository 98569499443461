import React, { createContext, Dispatch, FC, PropsWithChildren, useCallback, useMemo, useState } from 'react'
import { useEventEmitter, UseEventEmitterDispatch, UseEventEmitterSubscribe } from 'tools/hooks'

export type Commands = {
  change: void
  undo: void
  redo: void
  blockType: 'paragraph' | 'ul' | 'ol' | 'quote'
  formatText: 'bold' | 'italic' | 'underline' | 'strikethrough' | 'code'
  formatElement: 'left' | 'center' | 'right' | 'justify'
  insertLink: string
  updateLink: string
  removeLink: string
  image: string
  video: string
  youtube: string
  tweet: string
}

export type CommandType = keyof Commands
export type CommandPayload<T extends CommandType> = Commands[T]

export type EditorButtonState = {
  active?: boolean
  disabled?: boolean
}

export type EditorState = {
  undo?: EditorButtonState
  redo?: EditorButtonState
  blockType?: 'paragraph' | 'ul' | 'ol' | 'quote'
  formatText?: {
    bold?: EditorButtonState
    italic?: EditorButtonState
    underline?: EditorButtonState
    strikethrough?: EditorButtonState
    code?: EditorButtonState
  }
  formatElement?: {
    left?: EditorButtonState
    center?: EditorButtonState
    right?: EditorButtonState
    justify?: EditorButtonState
  }
  insertLink?: boolean
  currentLinkElement?: HTMLElement | null
  currentLinkHref?: string
  organismId?: string
}

export type EditorContext = [
  state: EditorState,
  setState: Dispatch<Partial<EditorState>>,
  subscribe: UseEventEmitterSubscribe<CommandType, any>,
  dispatch: UseEventEmitterDispatch<CommandType, any>,
]

export const EditorContext = createContext<EditorContext | null>(null)

export type EditorProviderProps = PropsWithChildren<{
  organismId?: string
}>

/**
 * Provides a context to manage the ui state of an <Editor>
 * - `state` is the editor ui state
 * - `setState` sets the editor ui state
 * - `subscribe` subscribes to editor commands
 * - `dispatch` dispatches editor commands
 */
const EditorProvider: FC<EditorProviderProps> = ({ organismId, ...props }) => {
  // state to manage editor ui (disabled/active toolbar buttons, etc...)
  const [state, setState] = useState<EditorState>({
    blockType: 'paragraph',
    organismId,
  })
  const mergeState = useCallback<Dispatch<Partial<EditorState>>>(state => {
    setState(prevState => ({ ...prevState, ...state }))
  }, [])

  // event emitter to subscribe/dispatch editor commands
  const [subscribe, dispatch] = useEventEmitter<CommandType, any>()

  // combined context value
  const context = useMemo<EditorContext>(
    () => [state, mergeState, subscribe, dispatch],
    [dispatch, mergeState, state, subscribe],
  )

  return <EditorContext.Provider value={context} {...props} />
}

export default EditorProvider
