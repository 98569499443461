import { gql } from '@apollo/client'

export const FILE_FRAGMENT = gql`
  fragment fileContent on File {
    id
    filename
    size
    mimetype
    downloadUrl
    thumbUrl
    createdAt
    metadata {
      title
      description
      aspect
      width
      height
      sourceFileId
    }
  }
`

/**
 * Files
 */
export const GET_FILE_IMAGE_URL_QUERY = gql`
  query fileImageUrl($fileId: ID!) {
    fileImageUrl(fileId: $fileId)
  }
`

export const GET_FILES_QUERY = gql`
  query files($mimetype: String, $search: String, $first: Int, $offset: Int) {
    files(mimetype: $mimetype, search: $search, first: $first, offset: $offset) {
      totalCount
      files {
        ...fileContent
      }
    }
  }
  ${FILE_FRAGMENT}
`

export const GET_FILE_QUERY = gql`
  query file($fileId: ID!) {
    file(id: $fileId) {
      ...fileContent
    }
  }
  ${FILE_FRAGMENT}
`
