import { SelectedTranslationsType, TranslatedInput } from 'Components/Form/SelectLocalInput'
import { SportItemSetFormValues } from 'Forms/SportItemSet/SportItemSetForm'
import { Tab, TabInput } from 'models'
import { getTranslationInputFromForm, getTranslationInputFromValues } from 'tools/translationTools'

export type TabForm = Omit<Tab, 'titleT'> & { titleT: TranslatedInput }

export const tabsToForm = (tabs?: Tab[] | null): TabForm[] =>
  tabs?.map(({ __typename, titleT, icon: { __typename: __typenameIcon, ...icon }, ...tab }) => ({
    ...tab,
    titleT: getTranslationInputFromValues({ titleT })?.titleT as TranslatedInput,
    icon,
  })) ?? []

export const tabsToApi = (
  tabs: SportItemSetFormValues['tabs'],
  selectedTranslations: SelectedTranslationsType[],
): TabInput[] | null =>
  tabs?.length
    ? tabs.map(({ titleT, ...tab }) => ({ ...tab, titleT: getTranslationInputFromForm(selectedTranslations, titleT) }))
    : null
