import React, { useMemo, forwardRef, useImperativeHandle } from 'react'
import {
  Maybe,
  Organism,
  SveChannel,
  SveChannelState,
  useOrganismsQuery,
  useSveChannelsQuery,
} from 'services/api/graphql'

import { SelectSVEChannelsInput, SelectSVEChannelsInputProps } from './SelectSVEChannelsInput'

export interface SelectOrganismSVEChannelsInputProps
  extends Omit<SelectSVEChannelsInputProps, 'source' | 'value' | 'onChange'> {
  organismId: Organism['id'] | undefined
  value: Array<SveChannel['id']> | undefined
  onChange: (value: Maybe<Array<SveChannel['id']>>) => void
}
export interface SelectOrganismSVEChannelsInputRefProps {
  refresh: () => void
}
export const SelectOrganismSVEChannelsInput = forwardRef<
  SelectOrganismSVEChannelsInputRefProps,
  SelectOrganismSVEChannelsInputProps
  // eslint-disable-next-line react/prop-types
>(({ value, onChange, organismId, ...props }, ref) => {
  const OrganismsQuery = useOrganismsQuery()
  const organisms = useMemo(
    () => OrganismsQuery.data?.organisms?.organisms ?? [],
    [OrganismsQuery.data?.organisms?.organisms],
  )
  const SVEChannelsQuery = useSveChannelsQuery({ variables: { input: {} } })

  useImperativeHandle(ref, () => ({
    refresh: () => {
      SVEChannelsQuery.refetch()
    },
  }))

  const sveChannels = useMemo(() => {
    // Keep channels not associated to any organism or associated to the given organismId
    const sveChannels = SVEChannelsQuery.data?.sveChannels.sveChannels ?? []
    return sveChannels.filter(sveChannel => {
      if (sveChannel.status === SveChannelState.Deleted) return false
      const associatedOrganisms = organisms.filter(
        organism => organism.id !== organismId && organism.sveChannelIds?.includes(sveChannel.id),
      )
      if (associatedOrganisms.length > 0) {
        return false
      }
      return true
    })
  }, [SVEChannelsQuery.data?.sveChannels.sveChannels, organismId, organisms])

  const loading = SVEChannelsQuery.loading || OrganismsQuery.loading
  return (
    <SelectSVEChannelsInput
      value={value}
      onChange={value => onChange(value ? (Array.isArray(value) ? value : [value]) : null)}
      source={sveChannels}
      loading={loading}
      {...props}
    />
  )
})
