import SwitchInput from 'Components/Form/SwitchInput'
import React from 'react'

import { useIntl } from 'react-intl'
import {
  OrganismType,
  ExternalFeedType,
  PublicationStatus,
  SportItemContentType,
  VideoContentType,
} from 'services/api/graphql'

import { options } from './options'

type ContentTypes =
  | VideoContentType
  | SportItemContentType
  | OrganismType
  | PublicationStatus
  | ExternalFeedType
  | undefined

export interface SelectVideoContentTypeInputProps {
  value: ContentTypes
  onChange: (value: ContentTypes) => void
  typeSelector: SwitchContentTypeSelector
}

export enum SwitchContentTypeSelector {
  VideoContentTypes = 'videoContentTypes',
  SportItemContentTypes = 'sportItemContentTypes',
  OrganismTypes = 'organismTypes',
  PublicationStatusTypes = 'publicationStatusTypes',
  ExternalFeedTypes = 'externalFeedTypes',
}

const SwitchContentTypesButtonInput = ({ value, onChange, typeSelector }: SelectVideoContentTypeInputProps) => {
  const intl = useIntl()

  switch (typeSelector) {
    case SwitchContentTypeSelector.VideoContentTypes: {
      return (
        <SwitchInput<VideoContentType>
          value={value as VideoContentType}
          onChange={onChange}
          options={options(intl).videoContentTypes}
        />
      )
    }
    case SwitchContentTypeSelector.SportItemContentTypes:
      return (
        <SwitchInput<SportItemContentType>
          value={value as SportItemContentType}
          onChange={onChange}
          options={options(intl).sportItemContentTypes}
        />
      )
    case SwitchContentTypeSelector.OrganismTypes: {
      return (
        <SwitchInput<OrganismType>
          value={value as OrganismType}
          onChange={onChange}
          options={options(intl).organismTypes}
        />
      )
    }
    case SwitchContentTypeSelector.PublicationStatusTypes: {
      return (
        <SwitchInput<PublicationStatus>
          value={value as PublicationStatus}
          onChange={onChange}
          options={options(intl).publicationStatusTypes}
        />
      )
    }
    case SwitchContentTypeSelector.ExternalFeedTypes: {
      return (
        <SwitchInput<ExternalFeedType>
          value={value as ExternalFeedType}
          onChange={onChange}
          options={options(intl).externalFeedTypes}
        />
      )
    }
  }
}

export default SwitchContentTypesButtonInput
