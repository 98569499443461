import React, { useCallback, useState } from 'react'

import { SketchPicker, ColorResult } from 'react-color'
import { Input, Popup, Grid, Button } from 'semantic-ui-react'
import styled from 'styled-components'

const CircleContainer = styled.div`
  display: flex;
  align-items: center;
`
const Circle = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 15px;
  border-style: solid;
  border-color: #bebebe;
  border-width: 1px;
`

const TextInput = styled(Input)`
  margin-left: 8px;
`

const WrappedSketchPicker = styled(SketchPicker)`
  margin-bottom: 16px;
`
interface ColorInputProps {
  value: string | undefined
  onChange: (value: string | undefined) => void
  placeholder?: string | undefined
}
const ColorInput = ({ value, onChange, placeholder }: ColorInputProps) => {
  const [selectedColor, setSelectedColor] = useState(value)
  const [open, setOpen] = useState(false)
  const handleOnChange = useCallback((color: ColorResult | undefined) => {
    setSelectedColor(color && color.hex)
  }, [])
  // Keep className for error style
  return (
    <Input className="colorinput" type="text" actionPosition="left" action>
      <Popup
        className="colorinput-popup"
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        trigger={
          <CircleContainer>
            {/* Keep className for error style */}
            <Circle className="circle" style={{ backgroundColor: value }} />
          </CircleContainer>
        }
        on="click"
        position="bottom center"
      >
        <Grid>
          <Grid.Column textAlign="center">
            <WrappedSketchPicker color={selectedColor} onChange={handleOnChange} onChangeComplete={handleOnChange} />
            <Button
              negative
              onClick={() => {
                setOpen(false)
                onChange && onChange(undefined)
              }}
            >
              Reset
            </Button>
            <Button
              positive
              onClick={() => {
                setOpen(false)
                onChange && onChange(selectedColor)
              }}
            >
              OK
            </Button>
          </Grid.Column>
        </Grid>
      </Popup>
      <TextInput readOnly value={value} placeholder={placeholder} />
    </Input>
  )
}

export default ColorInput
