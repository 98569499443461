import FilePreview from 'Containers/FilePreview'
import React, { useMemo } from 'react'
import { Image } from 'semantic-ui-react'
import { Media, MediaType } from 'services/api/graphql'

interface FileInputPreviewProps {
  mediaId: Media['id'] | undefined
  type?: MediaType
}

const NO_VIDEO_SRC = '/assets/novideo.png'
const NO_IMAGE_SRC = '/assets/noimage.png'

const FileInputPreview = ({ mediaId, type }: FileInputPreviewProps) => {
  const noMediaImg = useMemo(() => (type === MediaType.VideoClip ? NO_VIDEO_SRC : NO_IMAGE_SRC), [type])
  if (!mediaId) {
    return <Image src={noMediaImg} style={{ objectFit: 'cover', width: '100%', height: '100%' }} />
  }
  return <FilePreview mediaId={mediaId} />
}

export default FileInputPreview
