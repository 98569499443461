import React, { VFC, useCallback, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Button, Popup } from 'semantic-ui-react'
import { Media, MediaType } from 'services/api/graphql'

import { useEditorCommand } from '../useEditor'

import SelectFileModal from './SelectFileModal'

export type InsertImageButtonProps = {
  onDone?: (media: Media) => void
}

const InsertImageButton: VFC<InsertImageButtonProps> = props => {
  const [, dispatch] = useEditorCommand()

  const [open, setOpen] = useState(false)

  const onClick = useCallback(() => {
    setOpen(true)
  }, [])

  const onCancel = useCallback(() => {
    setOpen(false)
  }, [])

  const onDone = useCallback(
    media => {
      if (media?.image?.downloadUrl) {
        dispatch('image', media.image.downloadUrl)
      }
      setOpen(false)
    },
    [dispatch],
  )

  return (
    <>
      <Popup
        content={<FormattedMessage id="news.image" />}
        trigger={<Button type="button" icon="image" onClick={onClick} />}
      />

      <SelectFileModal
        open={open}
        type={MediaType.Image}
        onCancel={onCancel}
        onDone={onDone}
        header={<FormattedMessage id="editor.selectImage" />}
        {...props}
      />
    </>
  )
}

export default InsertImageButton
