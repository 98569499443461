import { FormBaseProps } from 'Forms/FormBase'
import { useCreateSport } from 'models'
import React from 'react'
import { useIntl } from 'react-intl'
import { CreateSportInput, Sport } from 'services/api/graphql'
import { getTranslationInputFromForm } from 'tools/translationTools'

import SportForm from './SportForm'

interface CreateSportFormProps extends FormBaseProps<Sport> {}

const CreateSportForm = ({ onCancel, onDone, onError }: CreateSportFormProps) => {
  const intl = useIntl()
  const [createSport, { loading }] = useCreateSport()
  return (
    <SportForm
      title={intl.formatMessage({ id: 'sports.form.create.title' })}
      edit={false}
      onSubmit={({ titleT, ...values }, defaultLocale, selectedTranslations) => {
        const input: CreateSportInput = {
          ...values,
          titleT: getTranslationInputFromForm(selectedTranslations, titleT),
          title: titleT?.[defaultLocale] || '',
        }
        createSport({ variables: { input } })
          .then(result => {
            if (result && result.data) onDone(result.data.createSport)
          })
          .catch(onError)
      }}
      onCancel={onCancel}
      loading={loading}
    />
  )
}

export default CreateSportForm
