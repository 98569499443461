import classnames from 'classnames'
import React from 'react'
import { useIntl } from 'react-intl'
import { Button } from 'semantic-ui-react'
import { useCurrentUser } from 'stores'
import { formatTime } from 'tools/formatTime'

interface EditVideoViewProps {
  startTrim: number
  endTrim: number
  duration: number
  showEditTrim?: boolean
  showEditMarkers?: boolean
  isLive?: boolean
  isReplay?: boolean
  onSetCurrentView: (value: string) => void
  onGenerateReplay: () => void
  generateMediaReplayLoading?: boolean
}

const EditVideoView: React.FC<EditVideoViewProps> = ({
  startTrim,
  endTrim,
  duration,
  showEditTrim,
  showEditMarkers,
  isLive,
  isReplay,
  onSetCurrentView,
  onGenerateReplay,
  generateMediaReplayLoading,
}) => {
  const intl = useIntl()
  const { isAdmin } = useCurrentUser()

  return (
    <div className="flex flex-row justify-between">
      <div
        className={classnames('text-black', {
          'text-blue-600': startTrim !== 0,
        })}
      >
        {formatTime(startTrim)}
      </div>
      <div className="self-center">
        {showEditTrim && !isLive && (
          <>
            {isAdmin && isReplay && (
              <Button
                loading={generateMediaReplayLoading}
                color="green"
                className="flex mt-4 justify-center h-full"
                onClick={onGenerateReplay}
              >
                {intl.formatMessage({ id: 'videoClip.generateReplay' })}
              </Button>
            )}
            <Button
              color="blue"
              className="flex mt-4 justify-center w-32 h-full"
              onClick={() => onSetCurrentView('editTrimVideo')}
            >
              {intl.formatMessage({ id: 'edit.trimVideo' })}
            </Button>
          </>
        )}
        {showEditMarkers && (
          <Button
            color="blue"
            className="flex mt-4 justify-center w-32 h-full"
            onClick={() => onSetCurrentView('editTimelineMarkers')}
          >
            {intl.formatMessage({ id: 'edit.timelineMarkers' })}
          </Button>
        )}
      </div>
      <div
        className={classnames('text-black', {
          'text-blue-600': endTrim !== duration,
        })}
      >
        {formatTime(endTrim)}
      </div>
    </div>
  )
}
export default EditVideoView
