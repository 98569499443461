import InvisibleButton from 'Components/Button/InvisibleButton'
import SelectLiveStream from 'Components/Form/SelectLiveStreamState'
import { LiveStreamSVEPlayerModal } from 'Components/LiveStreamSVEPlayerModal'
import ConfirmationButton from 'Containers/Button/ConfirmationButton'
import { CreateSVEMediaLiveForm } from 'Forms/Media/CreateSVEMediaLiveForm'
import { useDeleteMedia } from 'models'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl'
import { Button, Header, Icon, Table } from 'semantic-ui-react'
import {
  LiveStreamContentFragment,
  LiveStreamState,
  MediaContentFragment,
  MediaType,
  Permission,
  SportEventState,
  useSetLiveStreamStateMutation,
} from 'services/api/graphql'
import { useCurrentUser } from 'stores'

import { SportItemLivesTableProps } from '../types'

import { LiveStreamSVEDetailsModal } from './LiveStreamSVEDetailsModal'

export const SportItemSVELivesTable: React.FC<SportItemLivesTableProps> = ({ sportItem, refresh, onLoading }) => {
  const [showModalDetailsLive, setShowModalDetailsLive] = useState(false)
  const [selectedLive, setSelectedLive] = useState<MediaContentFragment | null>(null)
  const [playerOpen, setPlayerOpen] = useState(false)
  const intl = useIntl()
  const currentUser = useCurrentUser()
  const [showModalCreateLive, setShowModalCreateLive] = useState(false)

  const lives = useMemo(
    () => (sportItem.medias || []).filter(media => media.type === MediaType.LiveStream),
    [sportItem],
  )

  const eventEnded = useMemo(() => {
    const sportItemSet = sportItem.sportItemSet
    if (sportItemSet) {
      return sportItemSet && sportItemSet.sportEvent && sportItemSet.sportEvent.state === SportEventState.Finished
    }
    return false
  }, [sportItem])
  const liveEnded = useMemo(
    () =>
      (sportItem && sportItem.sportEventItem && new Date(sportItem.sportEventItem.stopDate) < new Date(Date.now())) ||
      false,
    [sportItem],
  )

  // GraphQL requests
  const [setLiveStreamState, { loading: loadingUpdateLive }] = useSetLiveStreamStateMutation()
  const [deleteMedia, { loading: loadingDeleteMedia }] = useDeleteMedia()

  // Update a live's state
  const onChangeLiveState = useCallback(
    async (liveStream: LiveStreamContentFragment, state: LiveStreamState) => {
      await setLiveStreamState({
        variables: { id: liveStream.id, state },
      })
      refresh()
    },
    [refresh, setLiveStreamState],
  )

  // Open modal to show live details
  const displayMediaLive = useCallback((media: MediaContentFragment) => {
    setSelectedLive(media)
    setShowModalDetailsLive(true)
  }, [])

  // Open player
  const openLiveStreamPlayer = useCallback((media: MediaContentFragment) => {
    setSelectedLive(media)
    setPlayerOpen(true)
  }, [])

  // Delete live
  const onDeleteLiveStream = useCallback(
    (liveStreamMedia: MediaContentFragment) => {
      return deleteMedia({
        variables: { mediaId: liveStreamMedia.id },
      })
    },
    [deleteMedia],
  )
  // Show loading spinner
  useEffect(() => {
    onLoading(loadingUpdateLive || loadingDeleteMedia)
  }, [loadingUpdateLive, loadingDeleteMedia, onLoading])

  return (
    <div>
      <div className="flex flex-row justify-between items-center">
        <Header as="h2">
          <FormattedMessage id="sportItemMedias.lives" />
        </Header>
        {currentUser.can(Permission.LiveStreamCreate) && !eventEnded && !liveEnded && (
          <Button circular icon="add" color="red" onClick={() => setShowModalCreateLive(true)} />
        )}
      </div>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={3}>
              <FormattedMessage id="sportItemMedias.name" />
            </Table.HeaderCell>
            <Table.HeaderCell width={2}>
              <FormattedMessage id="sportItemMedias.sve.testDate" />
            </Table.HeaderCell>
            <Table.HeaderCell width={2}>
              <FormattedMessage id="sportItemMedias.sve.scheduledClipStartDate" />
            </Table.HeaderCell>
            <Table.HeaderCell width={2}>
              <FormattedMessage id="sportItemMedias.sve.scheduledStopDate" />
            </Table.HeaderCell>
            <Table.HeaderCell width={2}>
              <FormattedMessage id="sportItemMedias.state" />
            </Table.HeaderCell>
            <Table.HeaderCell width={2} />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {lives.map(mediaLive => (
            <Table.Row key={mediaLive.id}>
              <Table.Cell>{mediaLive.title}</Table.Cell>

              <Table.Cell>
                {sportItem?.sportEventItem?.testDate ? (
                  <FormattedDate
                    day="2-digit"
                    month="2-digit"
                    year="numeric"
                    hour="2-digit"
                    minute="2-digit"
                    second="2-digit"
                    value={new Date(sportItem?.sportEventItem?.testDate)}
                  />
                ) : (
                  <span>{`-`}</span>
                )}
              </Table.Cell>
              <Table.Cell>
                {sportItem.sportEventItem?.startDate ? (
                  <FormattedDate
                    day="2-digit"
                    month="2-digit"
                    year="numeric"
                    hour="2-digit"
                    minute="2-digit"
                    second="2-digit"
                    value={
                      new Date(
                        mediaLive?.liveStream?.sveBroadcast?.scheduledClipStartDate ||
                          sportItem.sportEventItem.startDate,
                      )
                    }
                  />
                ) : (
                  <span>{`-`}</span>
                )}
              </Table.Cell>
              <Table.Cell>
                {sportItem.sportEventItem?.stopDate ? (
                  <FormattedDate
                    day="2-digit"
                    month="2-digit"
                    year="numeric"
                    hour="2-digit"
                    minute="2-digit"
                    second="2-digit"
                    value={
                      new Date(
                        mediaLive?.liveStream?.sveBroadcast?.scheduledStopDate || sportItem.sportEventItem?.stopDate,
                      )
                    }
                  />
                ) : (
                  <span>{`-`}</span>
                )}
              </Table.Cell>
              <Table.Cell>
                {mediaLive.liveStream && (
                  <SelectLiveStream
                    value={mediaLive.liveStream.state}
                    onChange={state => mediaLive.liveStream && onChangeLiveState(mediaLive.liveStream, state)}
                    disabled={!currentUser.can(Permission.LiveStreamUpdate)}
                  />
                )}
              </Table.Cell>
              <Table.Cell textAlign="right">
                <InvisibleButton onClick={() => displayMediaLive(mediaLive)}>
                  <Icon name="info circle" />
                </InvisibleButton>
                {mediaLive.liveStream && ![LiveStreamState.Finished].includes(mediaLive.liveStream.state) && (
                  <InvisibleButton onClick={() => openLiveStreamPlayer(mediaLive)}>
                    <Icon name="eye" />
                  </InvisibleButton>
                )}
                {currentUser.can(Permission.LiveStreamDelete) && (
                  <ConfirmationButton
                    action={() => onDeleteLiveStream(mediaLive)}
                    onDone={refresh}
                    successText={intl.formatMessage({
                      id: 'livestream.delete_success',
                    })}
                    confirmText={intl.formatMessage(
                      {
                        id: 'livestream.delete',
                      },
                      { title: mediaLive.title },
                    )}
                  />
                )}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <CreateSVEMediaLiveForm
        open={showModalCreateLive}
        onDone={() => {
          setShowModalCreateLive(false)
          refresh()
        }}
        onCancel={() => setShowModalCreateLive(false)}
        sportItemId={sportItem.id}
      />
      {selectedLive?.liveStream && (
        <LiveStreamSVEPlayerModal
          open={playerOpen}
          onRefresh={refresh}
          mediaToUpdate={sportItem.medias?.[0]}
          showEditMarkers={true}
          liveStream={selectedLive.liveStream}
          onClose={() => setPlayerOpen(false)}
        />
      )}
      {selectedLive?.liveStream && (
        <LiveStreamSVEDetailsModal
          open={showModalDetailsLive}
          livestream={selectedLive.liveStream}
          onClose={() => setShowModalDetailsLive(false)}
        />
      )}
    </div>
  )
}
