import SAFormField from 'Components/Form/SAFormField'
import Loader from 'Components/Loader'
import FileInput from 'Containers/Form/FileInput'
import SelectWMTemplateInput from 'Containers/Form/SelectWMTemplateInput'
import { observer } from 'mobx-react-lite'
import { default as React, FC } from 'react'
import { FieldRenderProps } from 'react-final-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { Form } from 'semantic-ui-react'
import { MediaType, Organism, Scalars } from 'services/api/graphql'
import { useCurrentUser } from 'stores'
import { Templates } from 'stores/TemplateStore'
import { ImageFormat } from 'types/image'

import { SVEStreamsFields, SVEStreamsFieldsProps } from './SVEStreamsFields'

export interface SportItemSetSVEFieldsProps extends Omit<SVEStreamsFieldsProps, 'name'> {
  wmTemplates: Templates
  hasLiveRunning?: boolean
  showScreachInputStream?: boolean
  organismId?: Organism['id']
  checkChannelLoading?: boolean
}
export const SportItemSetSVEFields: FC<SportItemSetSVEFieldsProps> = observer(
  ({
    availableWMStreams,
    availableSVEChannels,
    wmTemplates,
    hasLiveRunning = false,
    showScreachInputStream = false,
    organismId,
    checkChannelLoading = false,
  }) => {
    const intl = useIntl()
    const { isAdmin } = useCurrentUser()

    return (
      <>
        <Loader loading={checkChannelLoading}>
          <SVEStreamsFields
            name={'sveStreams'}
            availableWMStreams={availableWMStreams ?? []}
            availableSVEChannels={availableSVEChannels}
            hasLiveRunning={hasLiveRunning}
            showScreachInputStream={showScreachInputStream}
          />
          {isAdmin && (
            <SAFormField
              name="wmClippingTemplateId"
              render={({ input, meta }: FieldRenderProps<Scalars['WildmokaID'], HTMLElement>) => (
                <Form.Field error={!!meta.touched && !!meta.error} disabled={hasLiveRunning}>
                  <label>
                    <FormattedMessage id="sportItemSets.form.wilmokaLiveTemplate" />
                  </label>
                  <SelectWMTemplateInput {...input} source={wmTemplates.live} loading={wmTemplates.loading} />
                </Form.Field>
              )}
            />
          )}
        </Loader>

        <SAFormField
          name="sveOverlayLogoImageMediaId"
          render={({ input, meta }) => (
            <Form.Field
              control={FileInput}
              {...input}
              type={MediaType.Image}
              error={!!meta.touched && !!meta.error}
              label={intl.formatMessage({
                id: 'sportItemSets.form.sveOverlayLogoImageMediaId.title',
              })}
              description={intl.formatMessage({
                id: 'sportItemSets.form.sveOverlayLogoImageMediaId.description',
              })}
              mimetype="image"
              // TODO: Use correctly resize react componen to restrict images to 1920x1080
              imageConstraints={{ aspect: 16 / 9, format: ImageFormat.PNG }}
              organismId={organismId}
            />
          )}
        />
        <SAFormField
          name="sveInputLossImageMediaId"
          render={({ input, meta }) => {
            return (
              <Form.Field
                control={FileInput}
                {...input}
                type={MediaType.Image}
                error={!!meta.touched && !!meta.error}
                label={intl.formatMessage({
                  id: 'sportItemSets.form.sveInputLossImageMediaId.title',
                })}
                description={intl.formatMessage({
                  id: 'sportItemSets.form.sveInputLossImageMediaId.description',
                })}
                mimetype="image"
                imageConstraints={{
                  aspect: 16 / 9,
                  width: 1920,
                  height: 1080,
                  format: ImageFormat.PNG,
                }}
                organismId={organismId}
                disabled={hasLiveRunning}
              />
            )
          }}
        />
      </>
    )
  },
)
