import { Icon } from '@iconify/react'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Dropdown } from 'semantic-ui-react'

import customIcons from './markerIcons.json'

interface IconSelectionProps {
  onIconSelect: (value: string) => void
}

interface Categories {
  [index: string]: string[]
}

const IconSelection: React.FC<IconSelectionProps> = ({ onIconSelect }) => {
  const intl = useIntl()

  const [selectedCategory, setSelectedCategory] = useState<string>()

  return (
    <div className="p-6 mt-8 bg-gray-100 border border-gray-200 rounded-md shadow-md">
      <div className="mb-4 font-bold">{intl.formatMessage({ id: 'markerList.addIcon' })}</div>
      <div className="flex mb-4">
        <Dropdown
          placeholder={intl.formatMessage({ id: 'markerList.selectCategory' })}
          fluid
          selection
          options={Object.keys(customIcons.categories).map(category => ({
            key: category,
            text: intl.formatMessage({ id: `markerList.${category}` }),
            value: category,
          }))}
          onChange={(e, data) => setSelectedCategory(data.value as string)}
        />
      </div>
      <div className="grid gap-8 grid-cols-8">
        {selectedCategory &&
          (customIcons.categories as Categories)[selectedCategory].map(icon => (
            <div
              key={icon}
              onClick={() => onIconSelect(icon)}
              className="flex justify-center mt-4 p-4 border border-gray-200 rounded-md cursor-pointer hover:bg-gray-200"
            >
              <Icon icon={`custom-${icon}`} width={34} />
            </div>
          ))}
      </div>
    </div>
  )
}

export default IconSelection
