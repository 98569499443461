import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { Label, SemanticCOLORS } from 'semantic-ui-react'
import { SveChannelState } from 'services/api/graphql'

const sveChannelStateColor = (state: SveChannelState): SemanticCOLORS | undefined => {
  switch (state) {
    case SveChannelState.Deleted: {
      return 'grey'
    }
    case SveChannelState.Idle: {
      return 'grey'
    }
    case SveChannelState.Mounting:
    case SveChannelState.Updating:
      return 'yellow'
    case SveChannelState.Unmounting: {
      return 'blue'
    }
    case SveChannelState.Ready: {
      return 'green'
    }
  }
  return undefined
}
const sveChannelStateStringId = (state: SveChannelState) => {
  switch (state) {
    case SveChannelState.Idle:
      return 'sveChannels.state.idle'
    case SveChannelState.Mounting:
      return 'sveChannels.state.mounting'
    case SveChannelState.Ready:
      return 'sveChannels.state.ready'
    case SveChannelState.Unmounting:
      return 'sveChannels.state.unmounting'
    case SveChannelState.Updating:
      return 'sveChannels.state.updating'
    case SveChannelState.Deleted:
      return 'sveChannels.state.deleted'
  }
}

export interface SVEChannelStateTagProps {
  state: SveChannelState
}
export const SVEChannelStateTag: FC<SVEChannelStateTagProps> = ({ state }) => {
  return (
    <Label color={sveChannelStateColor(state)}>
      <FormattedMessage id={sveChannelStateStringId(state)} />
    </Label>
  )
}
