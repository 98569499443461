import { DragDropContext, Droppable, DropResult } from '@hello-pangea/dnd'
import CustomPageViewRow from 'Components/CustomPage/CustomPageViewRow'
import DraggableTableRow from 'Components/DraggableTableRow/DraggableTableRow'

import Loader from 'Components/Loader'
import PageHeader from 'Components/PageHeader'
import SelectApplicationInput from 'Components/SelectApplicationInput'
import { applicationUris } from 'Layout/uris'

import { CustomPage, Permission, useDeleteCustomPage, useGetCustomPages, useMoveCustomPage } from 'models'
import React, { useCallback, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link, useLocation } from 'react-router-dom'
import { Button, Grid, Ref, Table } from 'semantic-ui-react'
import { useCurrentUser } from 'stores'
import { cloneWithoutGraphqlCacheTypenames } from 'tools/graphql'

const CustomPagesView = () => {
  const intl = useIntl()

  const location = useLocation()

  const searchParams = new URLSearchParams(location.search)
  const applicationId = searchParams.get('applicationId')

  const [customPages, setCustomPages] = useState<CustomPage[]>([])
  const currentUser = useCurrentUser()

  const { loading, refetch } = useGetCustomPages({
    variables: {
      filters: { applicationMongoId: applicationId, withNotShownInNavigationHeader: true },
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      const list = (data && data.customPages && data.customPages.customPages) || []
      setCustomPages(cloneWithoutGraphqlCacheTypenames(list))
    },

    skip: !applicationId,
  })

  const [moveCustomPage] = useMoveCustomPage()
  const [removeCustomPage] = useDeleteCustomPage()

  const onMoveItem = useCallback(
    async (result: DropResult) => {
      const { draggableId, destination, source, reason } = result
      if (!destination || reason === 'CANCEL' || destination.index === source.index) {
        return
      }

      await moveCustomPage({
        variables: {
          input: { id: draggableId, position: destination.index },
        },
      })

      const newOrder = customPages.filter(item => item.id !== draggableId)
      const customPage = customPages[source.index]
      newOrder.splice(destination.index, 0, customPage)
      setCustomPages(newOrder)
    },
    [customPages, moveCustomPage],
  )

  return (
    <Loader loading={loading}>
      <Grid padded>
        <Grid.Column>
          <PageHeader title={intl.formatMessage({ id: 'customPage.name' }, { type: 'plural' })}>
            {currentUser.can(Permission.CustomPageCreate) && applicationId && (
              <Link to={{ pathname: applicationUris.customPageCreate, search: `applicationId=${applicationId}` }}>
                <Button icon="add" circular color="red" />
              </Link>
            )}
          </PageHeader>
          {currentUser.can(Permission.CustomPageRead) && <SelectApplicationInput />}
          <DragDropContext onDragEnd={onMoveItem}>
            <Table striped compact celled>
              <Table.Header>
                <Table.Row textAlign="center">
                  {currentUser.can(Permission.CustomPageUpdate) && (
                    <>
                      <Table.HeaderCell />
                      <Table.HeaderCell>
                        <FormattedMessage id="customPage.isHomePage" />
                      </Table.HeaderCell>
                    </>
                  )}
                  <Table.HeaderCell>
                    <FormattedMessage id="customPage.title" />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <FormattedMessage id="customPage.link" />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <FormattedMessage id="customPage.publicationStatus" />
                  </Table.HeaderCell>
                  <Table.HeaderCell width={1} textAlign="center">
                    {currentUser.can(Permission.CustomPageUpdate) && <FormattedMessage id="customPage.actions" />}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Droppable droppableId="customPages">
                {provided => (
                  <Ref innerRef={provided.innerRef}>
                    <Table.Body {...provided.droppableProps}>
                      {customPages.length > 0 && applicationId && (
                        <>
                          {customPages.map((customPage, index) => (
                            <DraggableTableRow key={customPage.id} draggableId={customPage.id} index={index}>
                              {provided => (
                                <CustomPageViewRow
                                  draggableProvided={provided}
                                  customPage={customPage}
                                  onUpdateCustomPage={refetch}
                                  onRemoveCustomPage={async () => {
                                    await removeCustomPage({
                                      variables: { id: customPage.id },
                                    })
                                    refetch()
                                  }}
                                />
                              )}
                            </DraggableTableRow>
                          ))}
                        </>
                      )}
                    </Table.Body>
                  </Ref>
                )}
              </Droppable>
            </Table>
          </DragDropContext>
        </Grid.Column>
      </Grid>
    </Loader>
  )
}

export default CustomPagesView
