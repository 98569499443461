import { FormBaseProps } from 'Forms/FormBase'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useState } from 'react'
import {
  SportItemSet,
  SportItemSetContentFragment,
  SportItemSetType,
  useCreateSportItemSetMutation,
  VideoEngine,
} from 'services/api/graphql'
import { useStore } from 'stores'

import { SportItemSetForm } from './SportItemSetForm'
import { SportItemSet_FormValuesToAPI_Create } from './SportItemSetForm.transformers'

interface CreateSportItemSetFormProps extends Omit<FormBaseProps<SportItemSet>, 'onDone'> {
  type: SportItemSetType
  title: string
  onDone: (value: SportItemSetContentFragment) => void
}

const CreateSportItemSetForm = ({ title, type, onCancel, onDone, onError }: CreateSportItemSetFormProps) => {
  const [createSportItemSet] = useCreateSportItemSetMutation()
  const [isLoading, setIsLoading] = useState(false)

  const { organismId } = useStore()

  const handleSubmit = useCallback(
    async (values, defaultLocale, selectedTranslations) => {
      const input = SportItemSet_FormValuesToAPI_Create(
        {
          ...values,
          type: type || values.type,
        },
        defaultLocale,
        selectedTranslations,
      )
      setIsLoading(true)
      try {
        const result = await createSportItemSet({ variables: { input } })
        if (result && result.data) {
          onDone(result.data.createSportItemSet)
        }
      } catch (error) {
        onError?.(error as Error)
      }
      setIsLoading(false)
    },
    [createSportItemSet, onDone, onError, type],
  )

  return (
    <SportItemSetForm
      title={title}
      edit={false}
      isModal={false}
      type={type}
      editType={!type}
      initialValues={{
        ownerOrganismId: organismId,
        videoEngine: VideoEngine.Sportall,
        ...(type === SportItemSetType.SportEvent && {
          noSpoilerMode: {
            minutesBeforeDisabling: 0,
            enabled: true,
          },
        }),
      }}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      loading={isLoading}
    />
  )
}

export default observer(CreateSportItemSetForm)
