import SelectItemListInput from 'Components/Form/SelectItemListInput'
import { getCodes } from 'country-list'
import countriesi18n from 'i18n-iso-countries'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'

interface SelectCountryInputProps {
  value: string | string[] | undefined
  onChange: (v: string | string[] | undefined) => void
  multiple?: boolean
  disabled?: boolean
  noNullValue?: boolean
}

const SelectCountryInput: React.FC<SelectCountryInputProps> = ({
  value,
  onChange,
  multiple,
  disabled,
  noNullValue,
}) => {
  const intl = useIntl()

  const countryOptions = useMemo(
    () =>
      getCodes()
        .map(code => ({
          key: code,
          value: code,
          text: countriesi18n.getName(code, intl.locale),
          flag: code.toLowerCase(),
        }))
        .sort((a, b) => a.text.localeCompare(b.text)),
    [intl],
  )

  return (
    <SelectItemListInput
      value={value}
      multiple={multiple}
      options={countryOptions}
      noSelectionEnabled={!multiple}
      noSelectionTitle={intl.formatMessage({ id: 'common.none' })}
      onChange={onChange}
      disabled={disabled}
      noNullValue={noNullValue}
    />
  )
}

export default SelectCountryInput
