import InvisibleButton from 'Components/Button/InvisibleButton'
import CheckboxInput from 'Components/Form/CheckboxInput'
import GenericForm from 'Components/Form/GenericForm'
import SAFormField from 'Components/Form/SAFormField'
import { SelectDateInput } from 'Components/Form/SelectDateInput'
import SelectMultipleTextInput from 'Components/Form/SelectMultipleTextInput'
import FileInput from 'Containers/Form/FileInput'
import SelectOrganismsInput from 'Containers/Form/SelectOrganismsInput'
import SelectPublicationStatusInput from 'Containers/Form/SelectPublicationStatusInput'
import SelectSportItemInput from 'Containers/Form/SelectSportItemInput'
import SelectSportItemSetInput from 'Containers/Form/SelectSportItemSetInput'
import { ValidationErrors } from 'final-form'
import { useGetSportItems, useGetSportItemSet } from 'models'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Form, Icon, Popup, TextArea } from 'semantic-ui-react'
import { MediaType, PublicationStatus } from 'services/api/graphql'
import styled from 'styled-components'
import { validateRequired } from 'tools/formValidators'

const CopyMedia = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`

const CopyInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: stretch;
  > *:first-child {
    flex: 1;
  }
  > * + * {
    margin-left: 0.5rem;
  }
`

export interface FrontPageFormValues {
  start: Date
  end?: Date | null
  sportItemSetId: string
  sportItemId?: string | null
  coverImageId: string
  coverVideoId?: string | null
  titleImageId: string
  title?: string | null
  description?: string | null
  tags: string[]
  redirectsAutomatically?: boolean
  publicationStatus: PublicationStatus
  publishInTop?: boolean
}

interface FrontPageFormProps {
  onSubmit: (values: FrontPageFormValues) => void
  onCancel: () => void
  open: boolean
  value?: FrontPageFormValues
}

const FrontPageForm: React.FC<FrontPageFormProps> = ({ onSubmit, onCancel, open, value }) => {
  const intl = useIntl()

  const now = useMemo(() => new Date(), [])
  const initialValues: FrontPageFormValues = useMemo(
    () =>
      value || {
        start: now,
        sportItemSetId: '',
        coverImageId: '',
        titleImageId: '',
        tags: [],
        publicationStatus: PublicationStatus.Draft,
      },
    [now, value],
  )

  const [organismId, setOrganismId] = useState('')
  const [sportItemSetId, setSportItemSetId] = useState(initialValues.sportItemSetId)
  const [sportItemId, setSportItemId] = useState(initialValues.sportItemId)

  const { sportItemSet, loading: loadingSportItemSet } = useGetSportItemSet(sportItemSetId, {
    skip: !sportItemSetId,
  })

  const parentBanner = useMemo(
    () => sportItemSet && sportItemSet.bannerImage && sportItemSet.bannerImage.id,
    [sportItemSet],
  )
  const parentVideo = useMemo(
    () => sportItemSet && sportItemSet.bannerVideo && sportItemSet.bannerVideo.id,
    [sportItemSet],
  )
  const categories = useMemo(
    () => (sportItemSet && sportItemSet.categories.map(category => category.title)) || [],
    [sportItemSet],
  )

  // On edition, retrieve sportItemSet & organism
  useEffect(() => {
    if (initialValues.sportItemSetId) {
      setSportItemSetId(initialValues.sportItemSetId)
    } else {
      setOrganismId('')
      setSportItemSetId('')
    }
  }, [initialValues])
  useEffect(() => {
    if (open) {
      if (sportItemSet && (!organismId || organismId !== sportItemSet.ownerOrganismId)) {
        setOrganismId(sportItemSet.ownerOrganismId || '')
      }
    }
  }, [open, organismId, sportItemSet])

  const { data: sportItemsData, loading: loadingSportItems } = useGetSportItems(sportItemSetId, {
    skip: !sportItemSetId,
    fetchPolicy: 'cache-and-network',
  })
  const sportItem = useMemo(
    () => sportItemsData && sportItemsData.sportItems.sportItems.find(item => item.id === sportItemId),
    [sportItemId, sportItemsData],
  )

  const [plannedEnd, setPlannedEnd] = useState(false)
  const [redirectsAutomatically, setRedirectsAutomatically] = useState<boolean>(
    !!(value && value.redirectsAutomatically),
  )
  const [publishInTop, setPublishInTop] = useState<boolean>(true)

  useEffect(() => {
    if (open) {
      setRedirectsAutomatically(!!(value && value.redirectsAutomatically))
      setPlannedEnd((value && !!value.end) || false)
    }
  }, [value, open])

  const validate = useCallback(
    (data: FrontPageFormValues) => {
      const errors: ValidationErrors = {}

      if (data.end && data.end < data.start) {
        errors.end = intl.formatMessage({ id: 'frontPage.end.error_start' })
      }

      return errors
    },
    [intl],
  )

  const onFormSubmit = useCallback(
    (data: FrontPageFormValues) => {
      onSubmit({
        ...data,
        redirectsAutomatically,
        end: plannedEnd ? data.end : null,
        publishInTop,
      })
      setOrganismId('')
      setSportItemSetId('')
      setPublishInTop(true)
    },
    [onSubmit, redirectsAutomatically, plannedEnd, publishInTop],
  )

  return (
    <GenericForm
      header={intl.formatMessage({
        id: `frontPage.${value ? 'update' : 'create'}`,
      })}
      loading={loadingSportItemSet}
      initialValues={initialValues}
      onCancel={() => {
        setPublishInTop(true)
        onCancel()
      }}
      onSubmit={onFormSubmit}
      validate={validate}
      open={open}
    >
      {({ values, form }) => {
        return (
          <>
            <Form.Field
              label={intl.formatMessage({
                id: 'frontPage.organismId',
              })}
              required
              control={SelectOrganismsInput}
              value={organismId}
              onChange={(id: string) => {
                setOrganismId(id)
                form.change('sportItemSetId', undefined)
              }}
            />

            <SAFormField
              name="sportItemSetId"
              validate={validateRequired}
              render={({ input }) => (
                <Form.Field required>
                  <label>
                    <FormattedMessage id="frontPage.sportItemSetId" />
                  </label>
                  <SelectSportItemSetInput
                    {...input}
                    onChange={id => {
                      setSportItemSetId(id as string)
                      input.onChange(id)
                    }}
                    disabled={!organismId}
                    organismId={organismId}
                  />
                </Form.Field>
              )}
            />

            <SAFormField
              name="sportItemId"
              render={({ input }) => (
                <Form.Field
                  control={SelectSportItemInput}
                  {...input}
                  onChange={(id: string) => {
                    setSportItemId(id || null)
                    input.onChange(id || null)
                  }}
                  noSelectionEnabled={true}
                  noSelectionTitle={intl.formatMessage({
                    id: 'frontPage.noSportItem',
                  })}
                  source={(sportItemsData && sportItemsData.sportItems.sportItems) || []}
                  loading={loadingSportItems}
                  label={intl.formatMessage({ id: 'frontPage.sportItem' })}
                />
              )}
            />

            <SAFormField
              name="redirectsAutomatically"
              render={() => (
                <Form.Field>
                  <CheckboxInput
                    name="redirectsAutomatically"
                    checked={redirectsAutomatically}
                    onChange={setRedirectsAutomatically}
                    label={
                      <label>
                        <FormattedMessage id="frontPage.redirectsAutomatically" />
                      </label>
                    }
                  />
                </Form.Field>
              )}
            />

            <SAFormField
              name="start"
              validate={validateRequired}
              render={({ input, meta }) => (
                <Form.Field
                  control={() => SelectDateInput({ datetime: true, ...input })}
                  format="DD/MM/YYYY HH:mm"
                  minDate={now}
                  {...input}
                  required
                  error={!!meta.touched && !!meta.error}
                  label={intl.formatMessage({ id: 'frontPage.start' })}
                />
              )}
            />

            <SAFormField
              name="plannedEnd"
              render={() => (
                <Form.Field>
                  <CheckboxInput
                    name="plannedEnd"
                    checked={plannedEnd}
                    onChange={checked => setPlannedEnd(checked)}
                    label={
                      <label>
                        <FormattedMessage id="frontPage.plannedEnd" />
                      </label>
                    }
                  />
                </Form.Field>
              )}
            />

            {plannedEnd && (
              <SAFormField
                name="end"
                validate={plannedEnd && validateRequired}
                render={({ input, meta }) => (
                  <Form.Field
                    control={() => SelectDateInput({ datetime: true, ...input })}
                    format="DD/MM/YYYY HH:mm"
                    minDate={new Date(values.start)}
                    {...input}
                    onChange={input.onChange}
                    error={!!meta.touched && !!meta.error}
                    label={intl.formatMessage({ id: 'frontPage.end' })}
                  />
                )}
              />
            )}

            <SAFormField
              name="publishInTop"
              render={() => (
                <Form.Field>
                  <CheckboxInput
                    name="publishInTop"
                    checked={publishInTop}
                    onChange={checked => setPublishInTop(checked)}
                    label={
                      <label>
                        <FormattedMessage id="frontPage.onTop" />
                      </label>
                    }
                  />
                </Form.Field>
              )}
            />

            <SAFormField
              name="coverImageId"
              validate={validateRequired}
              render={({ input, meta }) => (
                <Form.Field required error={meta.touched && !!meta.error}>
                  <label>
                    <FormattedMessage id="frontPage.coverImageId" />
                  </label>
                  <CopyMedia>
                    <FileInput
                      {...input}
                      type={MediaType.Image}
                      imageConstraints={{
                        aspect: 16 / 9,
                        minWidth: 225,
                        minHeight: 150,
                        maxSize: 3000000,
                      }}
                      renderOrganismPicker
                    />
                    <Popup
                      on="hover"
                      position="left center"
                      content={<FormattedMessage id="frontPage.targetCopy" />}
                      trigger={
                        <InvisibleButton
                          type="button"
                          disabled={!parentBanner}
                          onClick={() => {
                            if (parentBanner) {
                              input.onChange(parentBanner)
                            }
                          }}
                        >
                          <Icon name="copy outline" color="black" size="large" />
                        </InvisibleButton>
                      }
                    />
                  </CopyMedia>
                </Form.Field>
              )}
            />

            <SAFormField<string, HTMLElement>
              name="coverVideoId"
              render={({ input, meta }) => (
                <Form.Field error={meta.touched && !!meta.error}>
                  <label>
                    <FormattedMessage id="frontPage.coverVideoId" />
                  </label>
                  <CopyMedia>
                    <FileInput {...input} type={MediaType.VideoClip} renderOrganismPicker />
                    <Popup
                      on="hover"
                      position="left center"
                      content={<FormattedMessage id="frontPage.targetCopy" />}
                      trigger={
                        <InvisibleButton
                          type="button"
                          disabled={!parentVideo}
                          onClick={() => {
                            if (parentVideo) {
                              input.onChange(parentVideo)
                            }
                          }}
                        >
                          <Icon name="copy outline" color="black" size="large" />
                        </InvisibleButton>
                      }
                    />
                  </CopyMedia>
                </Form.Field>
              )}
            />

            <SAFormField
              name="titleImageId"
              validate={validateRequired}
              render={({ input, meta }) => (
                <Form.Field
                  control={FileInput}
                  {...input}
                  type={MediaType.Image}
                  required
                  error={meta.touched && !!meta.error}
                  label={intl.formatMessage({
                    id: 'frontPage.titleImageId',
                  })}
                  imageConstraints={{
                    aspect: 5 / 2,
                    minWidth: 225,
                    minHeight: 150,
                    maxSize: 3000000,
                  }}
                  renderOrganismPicker
                />
              )}
            />

            <SAFormField
              name="title"
              render={({ input }) => (
                <Form.Field
                  control={Form.Input}
                  {...input}
                  label={intl.formatMessage({ id: 'frontPage.title' })}
                  placeholder={(sportItem && sportItem.title) || (sportItemSet && sportItemSet.title) || ''}
                />
              )}
            />

            <SAFormField
              name="description"
              render={({ input }) => (
                <Form.Field
                  {...input}
                  control={TextArea}
                  maxLength={500}
                  label={intl.formatMessage({ id: 'frontPage.description' })}
                  placeholder={(sportItem && sportItem.description) || (sportItemSet && sportItemSet.description) || ''}
                />
              )}
            />

            <SAFormField<string[], HTMLElement>
              name="tags"
              render={({ input }) => (
                <Form.Field>
                  <label>
                    <FormattedMessage id="frontPage.tags" />
                  </label>
                  <CopyInput>
                    <SelectMultipleTextInput {...input} />
                    <Popup
                      on="hover"
                      content={<FormattedMessage id="frontPage.targetCopy" />}
                      trigger={
                        <InvisibleButton
                          type="button"
                          disabled={categories.length === 0}
                          onClick={() => {
                            input.onChange([...new Set([...input.value, ...categories])])
                          }}
                        >
                          <Icon name="copy outline" color="black" size="large" />
                        </InvisibleButton>
                      }
                    />
                  </CopyInput>
                </Form.Field>
              )}
            />

            <SAFormField
              name="publicationStatus"
              validate={validateRequired}
              render={({ input, meta }) => (
                <Form.Field
                  control={SelectPublicationStatusInput}
                  {...input}
                  required
                  error={!!meta.touched && !!meta.error}
                  label={intl.formatMessage({
                    id: 'publicationStatus',
                  })}
                />
              )}
            />
          </>
        )
      }}
    </GenericForm>
  )
}

export default FrontPageForm
