import GenericForm from 'Components/Form/GenericForm'
import SAFormField from 'Components/Form/SAFormField'
import SelectItemListInput from 'Components/Form/SelectItemListInput'
import classnames from 'classnames'
import {
  AvailableContentPushService,
  ContentDeliveryFrequency,
  ContentDeliveryPeriodicityInput,
  ContentPushStream,
  OutputStream,
  OutputStreamInput,
} from 'models'
import {
  useCreateContentPushStream,
  useUpdateContentPushStream,
  useUpdateOutputStreams,
} from 'models/ContentPushStream'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { Form, Icon } from 'semantic-ui-react'

import { validateRequired } from 'tools/formValidators'

import { cloneWithoutGraphqlCacheTypenames } from 'tools/graphql'

import { notifyError, notifySuccess } from 'tools/toaster'

import ContentPushStreamExports from './ContentPushStreamExports'
import { ContentPushStreamDeliveryConfModal } from './DeliveryConfiguration'

export type ContentPushServiceStreamForm = {
  id?: string
  name?: string
  customDestination?: boolean
  contentDeliveryServiceConfig?: ContentDeliveryServiceConfigForm
}

export type OutputStreamForm = {
  id?: string
  name?: string
  outputUrl?: string
}

export type ContentDeliveryServiceConfigForm = {
  type?: string
  periodicity?: {
    frequency?: ContentDeliveryFrequency
    firstSendDate?: Date
  }
  destination?: {
    bucketName: string
    bucketPath?: string
    region: string
    accessKey: string
    secretKey: string
    endpointUrl?: string
  }
  outputStreams?: OutputStreamForm[]
  retentionDurationDays?: number
}

export type ContentPushServiceStreamModalProps = {
  services: AvailableContentPushService[]
  outputStreams: OutputStream[]
  cps?: ContentPushStream
  onCancel?: () => void
  onDone?: () => void
}

export const ContentPushStreamModal = ({
  services,
  outputStreams,
  cps,
  onCancel,
  onDone,
}: ContentPushServiceStreamModalProps) => {
  const intl = useIntl()

  const [modalOpen, setModalOpen] = useState(false)
  const [service, setService] = useState<ContentPushServiceStreamForm>((cps as ContentPushServiceStreamForm) ?? {})

  const [createContentPushStream, { loading: loadingCreateContentPushStream }] = useCreateContentPushStream()
  const [updateContentPushStream, { loading: loadingUpdateContentPushStream }] = useUpdateContentPushStream()
  const [updateOutputStreams, { loading: loadingUpdateOutputStreams }] = useUpdateOutputStreams()

  useEffect(() => {
    const cpsWithoutTypenames = { ...cps }
    delete cpsWithoutTypenames['__typename']

    if (cpsWithoutTypenames) {
      setService({
        ...cpsWithoutTypenames,
        customDestination:
          cps && services.find(s => s.type === cps.contentDeliveryServiceConfig.type)?.customDestination,
        contentDeliveryServiceConfig: {
          ...(cpsWithoutTypenames?.contentDeliveryServiceConfig as ContentPushServiceStreamForm),
          periodicity: {
            frequency: cpsWithoutTypenames?.contentDeliveryServiceConfig?.periodicity
              ?.frequency as ContentDeliveryFrequency,
            firstSendDate: cpsWithoutTypenames?.contentDeliveryServiceConfig?.periodicity?.firstSendDate
              ? new Date(cpsWithoutTypenames.contentDeliveryServiceConfig.periodicity.firstSendDate)
              : undefined,
          },
          retentionDurationDays: cpsWithoutTypenames?.contentDeliveryServiceConfig?.retentionDurationDays || undefined,
          outputStreams: outputStreams && cloneWithoutGraphqlCacheTypenames(outputStreams),
        },
      })
    }
  }, [services, outputStreams, cps])

  const servicesNamesOptions = useMemo(
    () =>
      services.map(({ type }) => ({
        key: type,
        value: type,
        text: type,
      })),
    [services],
  )

  const validateDelivery = useCallback(() => {
    if (
      !service?.contentDeliveryServiceConfig?.periodicity ||
      service?.customDestination === undefined ||
      (service?.customDestination && !service?.contentDeliveryServiceConfig?.destination)
    ) {
      return 'Required'
    }
    return undefined
  }, [service])

  const onSubmit = useCallback(
    async (values: ContentPushServiceStreamForm) => {
      try {
        await (cps ? updateContentPushStream : createContentPushStream)({
          variables: {
            input: {
              id: cps?.id as string,
              name: values.name as string,
              contentDeliveryServiceConfig: {
                type: values.contentDeliveryServiceConfig?.type as string,
                periodicity: values.contentDeliveryServiceConfig?.periodicity as ContentDeliveryPeriodicityInput,
                ...(values.contentDeliveryServiceConfig?.destination && {
                  destination: cloneWithoutGraphqlCacheTypenames(values.contentDeliveryServiceConfig?.destination),
                }),
                retentionDurationDays: values.contentDeliveryServiceConfig?.retentionDurationDays,
              },
            },
          },
        })

        if (values.contentDeliveryServiceConfig?.outputStreams) {
          await updateOutputStreams({
            variables: {
              input: {
                outputStreams: values.contentDeliveryServiceConfig?.outputStreams as OutputStreamInput[],
              },
            },
          })
        }

        notifySuccess(intl.formatMessage({ id: `contentPushStream.${cps ? 'updated' : 'created'}` }))
        onDone?.()
      } catch (err) {
        notifyError(err)
      }
    },
    [cps, updateContentPushStream, createContentPushStream, intl, onDone, updateOutputStreams],
  )

  const onDoneCPSDeliveryConfiguration = (values: ContentDeliveryServiceConfigForm) => {
    setService({
      ...service,
      contentDeliveryServiceConfig: values,
    })
    setModalOpen(false)
  }

  const loading = loadingCreateContentPushStream || loadingUpdateContentPushStream || loadingUpdateOutputStreams

  return (
    <GenericForm
      header={intl.formatMessage({ id: 'contentPushStream.create.title' }, { type: '' })}
      // Force type value if given as param
      initialValues={service}
      onCancel={onCancel}
      onSubmit={onSubmit}
      loading={loading}
      open={true}
    >
      {({ values: { id, name, contentDeliveryServiceConfig, customDestination }, values }) => {
        return (
          <div style={{ minHeight: '35vh' }}>
            {/* Name */}
            <SAFormField
              name={`name`}
              validate={validateRequired}
              render={({ input, meta }) => (
                <Form.Input
                  {...input}
                  required
                  error={meta.touched && meta.error}
                  label={intl.formatMessage({ id: 'contentPushStream.name' })}
                />
              )}
            />
            {/* Type */}
            <SAFormField
              name="contentDeliveryServiceConfig.type"
              validate={validateDelivery}
              render={({ input }) => (
                <Form.Field required>
                  <label>{intl.formatMessage({ id: 'contentPushStream.type' })}</label>

                  <div className="flex items-center">
                    <SelectItemListInput
                      {...input}
                      options={servicesNamesOptions}
                      onChange={(type: string) => {
                        setService({
                          ...service,
                          name,
                          contentDeliveryServiceConfig: { ...service?.contentDeliveryServiceConfig, type },
                          customDestination: services?.find(s => s.type === type)?.customDestination,
                        })
                        setModalOpen(true)
                      }}
                    />

                    {/* Edit button */}
                    {contentDeliveryServiceConfig?.type && (
                      <Icon
                        name="edit"
                        link
                        size="large"
                        inverted
                        color="black"
                        onClick={() => setModalOpen(true)}
                        className="pl-4"
                      />
                    )}
                  </div>
                </Form.Field>
              )}
            />
            {cps?.downloadExportUrl && (
              <a href={cps?.downloadExportUrl}>
                {intl.formatMessage({ id: 'contentPushStream.downloadExportFile' })}
                <Icon name="download" color="black" className="pl-2" />
              </a>
            )}
            {/* Export flow */}
            {contentDeliveryServiceConfig?.periodicity && id ? (
              <ContentPushStreamExports contentPushStreamId={id} />
            ) : (
              <div className={classnames({ 'mb-16': !contentDeliveryServiceConfig?.periodicity })}></div>
            )}
            {/* Delivery form */}
            {modalOpen && (
              <ContentPushStreamDeliveryConfModal
                delivery={values.contentDeliveryServiceConfig as ContentDeliveryServiceConfigForm}
                customDestination={customDestination}
                onCancel={() => setModalOpen(false)}
                onDone={onDoneCPSDeliveryConfiguration}
              />
            )}
          </div>
        )
      }}
    </GenericForm>
  )
}
