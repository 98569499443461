import React from 'react'
import { Image } from 'semantic-ui-react'
import styled from 'styled-components'

const BannerDiv = styled.div`
  height: 200px;
`
const BannerImage = styled(Image)`
  height: 100%;
  width: 100%;
  object-fit: cover;
`

interface BannerProps {
  src: string
}

const Banner: React.FC<BannerProps> = ({ src }) => {
  return (
    <BannerDiv>
      <BannerImage src={src} />
    </BannerDiv>
  )
}

export default Banner
