import axios from 'axios'
import { createXXHash64 } from 'hash-wasm'
import I18nError from 'tools/I18nError'

const CHUNK_SIZE = 1024 * 1024 * 5 // 5Mo

export const uploadToS3 = async (signedUrl: string, file: File, onProgress?: (percent: number) => void) => {
  if (file.type !== 'video/mp4') {
    throw new I18nError('medias.error.mimeType')
  }

  await axios.put(signedUrl, file, {
    headers: {
      'content-type': file.type,
    },
    onUploadProgress: progressEvent => {
      if (onProgress) {
        const percent = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        onProgress(percent)
      }
    },
  })
}

export const chunkFile = async (
  file: File,
  startingChunk: number,
  onChunkReady: (chunk: Blob, part: number) => Promise<void>,
  onProgress: (percent: number) => void,
  onDone: () => Promise<void>,
): Promise<void> => {
  const nbChunks = Math.ceil(file.size / CHUNK_SIZE)

  for (let currentChunk = startingChunk; currentChunk < nbChunks; currentChunk++) {
    const offset = currentChunk * CHUNK_SIZE

    const chunk = file.slice(offset, offset + CHUNK_SIZE)

    if (onChunkReady) {
      await onChunkReady(chunk, currentChunk + 1)

      if (onProgress) {
        const percent = Math.round((currentChunk * 100) / nbChunks)
        onProgress(percent)
      }
    }
  }

  await onDone()
}

export const getMd5Hash = async (file: File, onProgress: (percent: number) => void): Promise<string> => {
  const nbChunks = Math.ceil(file.size / CHUNK_SIZE)
  const hash = await createXXHash64()
  hash.init()

  for (let currentChunk = 0; currentChunk < nbChunks; currentChunk++) {
    await new Promise<void>((resolve, reject) => {
      const offset = currentChunk * CHUNK_SIZE
      const chunk = file.slice(offset, offset + CHUNK_SIZE)
      const reader = new FileReader()

      reader.onloadend = () => {
        hash.update(reader.result as string)
        if (onProgress) {
          const percent = Math.round((currentChunk * 100) / nbChunks)
          onProgress(percent)
        }
        resolve()
      }
      reader.onerror = () => reject(reader.error)

      reader.readAsBinaryString(chunk)
    })
  }
  const signature = hash.digest('hex')

  return signature
}
