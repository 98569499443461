import { observer } from 'mobx-react'
import React from 'react'
import { useIntl } from 'react-intl'
import { Dropdown, DropdownProps } from 'semantic-ui-react'
import { AffiliateDisplayType, AffiliateType, Maybe } from 'services/api/graphql'

import { AffiliateFieldType } from './AdCampaignFormFields'

export interface SelectAffiliateDisplayTypesInputProps {
  value: Array<string> | []
  item: AffiliateFieldType
  onChange: (value: Maybe<AffiliateDisplayType>) => void
  index: number
  onChangeFields: (index: number, item: AffiliateFieldType) => void
  multiple?: boolean
  disabled?: boolean
  indexHasButton: number
}

const affiliateDisplayTypes = [
  AffiliateDisplayType.Ad,
  AffiliateDisplayType.Billboard,
  AffiliateDisplayType.Thumbnail,
  AffiliateDisplayType.Button,
]

const SelectAffiliateDisplayTypes: React.FC<SelectAffiliateDisplayTypesInputProps> = observer(
  ({ multiple, index, onChangeFields, item, indexHasButton }) => {
    const intl = useIntl()
    const getAffiliateOptions = () => {
      const affiliateOptions = []

      if (item.type === AffiliateType.GoogleAdManager) {
        affiliateOptions.push({
          key: AffiliateDisplayType.Ad,
          value: AffiliateDisplayType.Ad,
          text: intl.formatMessage({ id: 'affiliates.displayType.ad' }),
        })
      } else {
        affiliateOptions.push(
          ...affiliateDisplayTypes
            .filter(type => indexHasButton === -1 || indexHasButton === index || type !== AffiliateDisplayType.Button)
            .map(type => ({
              key: type,
              value: type,
              text: intl.formatMessage({ id: `affiliates.displayType.${type}` }),
            })),
        )
      }

      return affiliateOptions
    }

    const options = getAffiliateOptions()

    return (
      <Dropdown
        multiple={multiple}
        value={item.displayTypes}
        lazyLoad
        search
        selection
        options={options}
        onChange={(e, data: DropdownProps) => {
          const changes = data.value as AffiliateDisplayType[]
          onChangeFields(index, { ...item, displayTypes: changes })
        }}
      />
    )
  },
)

export default SelectAffiliateDisplayTypes
