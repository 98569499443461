import InvisibleButton from 'Components/Button/InvisibleButton'
import RefreshButton from 'Components/Button/RefreshButton'
import ColorCircle from 'Components/ColorCircle'
import Loader from 'Components/Loader'
import PageHeader from 'Components/PageHeader'
import VideoPlayerModal from 'Components/VideoPlayer/VideoPlayerModal'
import ImageFilePreviewButton from 'Containers/Button/ImageFilePreviewButton'
import AddSportButton from 'Forms/Sport/AddSportButton'
import EditSportButton from 'Forms/Sport/EditSportButton'
import RemoveSportButton from 'Forms/Sport/RemoveSportButton'
import _ from 'lodash'
import { useGetSports } from 'models'
import React, { useCallback, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button, Grid, Icon, Table } from 'semantic-ui-react'
import { Permission } from 'services/api/graphql'
import { useCurrentUser } from 'stores'
import styled from 'styled-components'
import { notifyError } from 'tools/toaster'

const IconThumbnail = styled.img`
  width: 48px;
  height: 48px;
  object-fit: contain;
  border-radius: 5px;
  border-color: black;
  border-style: solid;
  border-width: 1px;
`
const Buttons = styled.div`
  display: flex;
  justify-content: center;
`

const SportsView = () => {
  const intl = useIntl()
  const currentUser = useCurrentUser()
  const { sports, error, loading, refetch } = useGetSports({
    fetchPolicy: 'cache-and-network',
  })

  const [selectedVideoUrl, setSelectedVideoUrl] = useState<string>('')
  const [playerOpen, setPlayerOpen] = useState(false)

  const openVideoPlayer = useCallback((url: string) => {
    setSelectedVideoUrl(url)
    setPlayerOpen(true)
  }, [])

  useEffect(() => {
    if (error) notifyError(<FormattedMessage id="error.general" />, error)
  }, [error])

  return (
    <Loader loading={loading}>
      <Grid padded>
        <Grid.Column>
          <PageHeader title={intl.formatMessage({ id: 'sports' })}>
            <RefreshButton onClick={() => refetch()} loading={loading} />
            {currentUser.can(Permission.SportCreate) && (
              <AddSportButton
                onDone={refetch}
                render={onClick => <Button circular icon="add" color="red" onClick={onClick} />}
              />
            )}
          </PageHeader>

          <Table style={{ flex: 1 }}>
            <Table.Header>
              <Table.Row textAlign="center">
                <Table.HeaderCell width={1}>
                  <FormattedMessage id="sports.icon" />
                </Table.HeaderCell>
                <Table.HeaderCell width={1}>
                  <FormattedMessage id="sports.color" />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <FormattedMessage id="sports.name" />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <FormattedMessage id="organism" />
                </Table.HeaderCell>
                <Table.HeaderCell width={2}>
                  <FormattedMessage id="sports.pictures" />
                </Table.HeaderCell>
                <Table.HeaderCell width={1}>
                  <FormattedMessage id="sports.teaser" />
                </Table.HeaderCell>
                <Table.HeaderCell width={2}>
                  <FormattedMessage id="sports.actions" />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {_.map(sports, ({ id, color, title, organisms, iconImage, bannerImage, cardImage, bannerVideo }) => {
                return (
                  <Table.Row key={id}>
                    <Table.Cell textAlign="center">
                      {iconImage && iconImage.image && iconImage.image.thumbUrl && (
                        <IconThumbnail src={iconImage.image.thumbUrl} alt="icône Sport" />
                      )}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <ColorCircle color={color} />
                    </Table.Cell>
                    <Table.Cell>
                      <strong>{title}</strong>
                    </Table.Cell>
                    <Table.Cell>{organisms && _.map(organisms, 'title').join(',')}</Table.Cell>
                    <Table.Cell textAlign="center">
                      {bannerImage && bannerImage.image && (
                        <ImageFilePreviewButton title="Image Banner" imageFile={bannerImage.image} />
                      )}
                      {cardImage && cardImage.image && (
                        <ImageFilePreviewButton title="Image Carte" imageFile={cardImage.image} />
                      )}
                    </Table.Cell>

                    <Table.Cell textAlign="center">
                      {bannerVideo && bannerVideo.videoClip && (
                        <InvisibleButton
                          onClick={() =>
                            bannerVideo && bannerVideo.videoClip && openVideoPlayer(bannerVideo.videoClip.playbackUrl)
                          }
                        >
                          <Icon name="play" inverted color="black" />
                        </InvisibleButton>
                      )}
                    </Table.Cell>

                    <Table.Cell textAlign="center">
                      <Buttons>
                        {currentUser.can(Permission.SportUpdate) && (
                          <EditSportButton
                            sportId={id}
                            render={onClick => (
                              <Icon name="edit" link size="large" inverted color="black" onClick={onClick} />
                            )}
                          />
                        )}
                        {currentUser.can(Permission.SportDelete) && (
                          <RemoveSportButton
                            sportId={id}
                            confirmText={intl.formatMessage(
                              {
                                id: 'sports.form.delete.question',
                              },
                              { title },
                            )}
                            successText={intl.formatMessage(
                              {
                                id: 'sports.form.delete.success',
                              },
                              { title },
                            )}
                            onDone={refetch}
                          />
                        )}
                      </Buttons>
                    </Table.Cell>
                  </Table.Row>
                )
              })}
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid>

      <VideoPlayerModal open={playerOpen} onClose={() => setPlayerOpen(false)} url={selectedVideoUrl} />
    </Loader>
  )
}

export default SportsView
