import CheckboxInput from 'Components/Form/CheckboxInput'
import SAFormField from 'Components/Form/SAFormField'
import { SelectDateInput } from 'Components/Form/SelectDateInput'
import { SelectSVEChannelWithConflicts } from 'Components/SelectSVEChannelWithConflicts'
import { SelectStreamWithConflicts } from 'Components/SelectStreamWithConflicts'
import { getLiveStreamTitle, useCheckSportItemStreamsMovable, useGetDefaultConfig } from 'models'
import React, { useCallback, useEffect, useState } from 'react'
import { FieldRenderProps } from 'react-final-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { Form, Icon, Message, Popup } from 'semantic-ui-react'
import {
  LiveStreamStreamConflictContentFragment,
  Scalars,
  SportEvent,
  SportItemSet,
  SveChannel,
  useSveChannelsQuery,
  VideoEngine,
  WmStream,
} from 'services/api/graphql'
import { validateRequired } from 'tools/formValidators'

export interface SportEventItemFormValues {
  testDate: Date
  startDate: Date
  stopDate: Date
  publishAutoreplay?: boolean
  // Video Engine Input
  streamId?: Scalars['WildmokaID']
  passthrough: boolean
  sveChannelId?: SveChannel['id']
}

export interface SportEventItemFieldsetProps {
  value?: SportEventItemFormValues
  onChange: (value: SportEventItemFormValues) => void
  onValidate?: (valid: boolean) => void
  onLoading?: (loading: boolean) => void
  sportEvent: SportEvent
  sportItemSetId: SportItemSet['id']
  initLivestream?: boolean
  sportItemId?: string
  eventStarted?: boolean
  eventEnded?: boolean
  videoEngine: VideoEngine
  wmStreams?: Pick<WmStream, 'id' | 'name'>[]
  sveChannelIds?: SveChannel['id'][]
}

export const SportEventItemFieldSet: React.FC<SportEventItemFieldsetProps> = ({
  sportEvent,
  value,
  sportItemSetId,
  onChange,
  onValidate,
  onLoading,
  initLivestream,
  sportItemId,
  eventStarted,
  eventEnded,
  videoEngine,
  wmStreams,
  sveChannelIds,
}) => {
  const intl = useIntl()

  // Min, max
  const [minStartDate, setMinStartDate] = useState(new Date())
  const [maxDate, setMaxDate] = useState<Date | null>(null)

  const sveChannelsQuery = useSveChannelsQuery({
    variables: { input: { filters: { channelIds: sveChannelIds } } },
    skip: videoEngine !== VideoEngine.Sportall || !sveChannelIds,
  })
  const sveChannels = sveChannelsQuery.data?.sveChannels.sveChannels
  const [data, setData] = useState<SportEventItemFormValues>(
    value || {
      testDate: new Date(),
      startDate: minStartDate,
      stopDate: minStartDate,
      publishAutoreplay: true,
      passthrough: false,
    },
  )

  const { defaultConfig, loading: configLoading } = useGetDefaultConfig()
  const initializeValues = useCallback(() => {
    const min = new Date(sportEvent.startDate)
    const now = new Date()
    setMinStartDate(now > min ? now : min)
    const max = new Date(sportEvent.endDate)
    // Check that sportItemSet max date is inferior to auto min, to prevent errors with date selectors
    if (max && max > min) {
      setMaxDate(max)
    }

    const defaultStart = min > now ? min : now
    const testPeriodValue = !!(value && value.testDate)
    const initVal: SportEventItemFormValues = value || {
      testDate: testPeriodValue ? defaultStart : new Date(),
      startDate: defaultStart,
      stopDate: new Date(
        defaultStart.getTime() + (defaultConfig ? defaultConfig.liveStreamDefaultDuration : 5 * 60 * 60 * 1000),
      ),
      publishAutoreplay: true,
      passthrough: false,
    }
    setData(initVal)
    onChange(initVal)
  }, [sportEvent.startDate, sportEvent.endDate, value, defaultConfig, onChange])

  // Initial values are set only when sportEvent is changed
  useEffect(() => {
    if (sportEvent) {
      initializeValues()
    }
  }, [initializeValues, sportEvent])

  const [dateErrorId, setDateErrorId] = useState('')
  const [streamConflicts, setStreamConflicts] = useState<LiveStreamStreamConflictContentFragment[]>([])
  const [streamErrorsMessages, setStreamErrorMessages] = useState<string[]>([])

  const { data: sportItemStreamConflicts, loading: sportItemStreamConflictsLoading } = useCheckSportItemStreamsMovable(
    {
      sportItemId: sportItemId || '',
      from: data.startDate,
      to: data.stopDate,
    },
    {
      skip: !sportItemId || !data.startDate || !data.stopDate,
      fetchPolicy: 'cache-and-network',
    },
  )

  const updateData = useCallback(
    (newData: SportEventItemFormValues) => {
      setData(newData)
      onChange(newData)
    },
    [onChange],
  )

  // Validate
  useEffect(() => {
    setDateErrorId('')
    let valid = true

    // Ensure dates are consistent (start < end, etc)
    if (data.testDate && data.startDate && data.testDate > data.startDate) {
      valid = false
      setDateErrorId('sportEventItem.testDate_error')
    } else if (data.stopDate && data.startDate && data.startDate >= data.stopDate) {
      valid = false
      setDateErrorId('sportEventItem.stopDate_error')
    }

    // Validate stream is not used by another livestream
    if (streamConflicts.length > 0) {
      valid = false
      setStreamErrorMessages(
        streamConflicts.map(conflict => {
          const stream = (wmStreams || []).find(str => str.id === data.streamId)
          return intl.formatMessage(
            { id: 'livestream.stream_conflict' },
            {
              stream: (stream && stream.name) || '',
              liveStream: getLiveStreamTitle(conflict.liveStream),
              sportItem: (conflict.liveStream.sportItem && conflict.liveStream.sportItem.title) || '',
            },
          )
        }),
      )
    }

    if (sportItemStreamConflictsLoading || (sportItemStreamConflicts && sportItemStreamConflicts.length > 0)) {
      valid = false
    }

    if (onValidate) onValidate(valid)
  }, [
    data.testDate,
    data.startDate,
    data.stopDate,
    streamConflicts,
    sportItemStreamConflicts,
    sportItemStreamConflictsLoading,
    data.streamId,
    onValidate,
    wmStreams,
    intl,
  ])

  // Loading
  const [streamLoading, setStreamLoading] = useState(false)
  useEffect(() => {
    if (onLoading) {
      onLoading(configLoading || streamLoading)
    }
  }, [onLoading, configLoading, streamLoading])

  return (
    <>
      <SAFormField
        name="sportEventItem.testDate"
        validate={validateRequired}
        render={({ input, meta }) => (
          <Form.Field
            {...input}
            datetime={true}
            required
            control={SelectDateInput}
            minDate={minStartDate}
            maxDate={maxDate}
            initialDate={minStartDate}
            onChange={(date: Date) => {
              updateData({ ...data, testDate: date })
            }}
            error={!!meta.touched && !!meta.error}
            label={intl.formatMessage({
              id: 'sportEventItem.testDate',
            })}
            disabled={eventStarted}
          />
        )}
      />
      <SAFormField
        name="sportEventItem.startDate"
        validate={validateRequired}
        render={({ input, meta }) => (
          <Form.Field
            {...input}
            datetime={true}
            required
            control={SelectDateInput}
            minDate={data.testDate || minStartDate}
            maxDate={maxDate}
            initialDate={data.testDate || minStartDate}
            onChange={(date: Date) => {
              updateData({ ...data, startDate: date })
            }}
            error={!!meta.touched && !!meta.error}
            label={intl.formatMessage({
              id: 'sportEventItem.startDate',
            })}
            disabled={eventStarted}
          />
        )}
      />
      <SAFormField
        name="sportEventItem.stopDate"
        validate={validateRequired}
        render={({ input, meta }) => (
          <Form.Field
            {...input}
            datetime={true}
            required
            control={SelectDateInput}
            minDate={data.startDate || minStartDate}
            maxDate={maxDate}
            initialDate={data.startDate || minStartDate}
            onChange={(date: Date) => {
              updateData({ ...data, stopDate: date })
            }}
            error={!!meta.touched && !!meta.error}
            label={intl.formatMessage({
              id: 'sportEventItem.stopDate',
            })}
            disabled={eventEnded}
          />
        )}
      />

      {dateErrorId && (
        <Message negative>
          <FormattedMessage id={dateErrorId} />
        </Message>
      )}

      {sportItemStreamConflicts && sportItemStreamConflicts.length > 0 && (
        <Message negative>
          {sportItemStreamConflicts.map(conflict => (
            <FormattedMessage
              key={`${conflict.sportItem.id}-${conflict.stream?.id || conflict.sveBroadcast?.id}`}
              id="sportEventItem.sportItem_stream_conflict"
              values={{
                stream:
                  conflict.videoEngine === VideoEngine.Wildmoka
                    ? conflict.stream?.name ?? ''
                    : conflict.sveBroadcast?.channelId ?? '',
                sportItemTitle: conflict.sportItem.title,
              }}
            />
          ))}
        </Message>
      )}
      {!eventEnded && (
        <SAFormField
          name="sportEventItem.publishAutoreplay"
          render={({ input, meta }) => (
            <Form.Field error={!!meta.touched && !!meta.error}>
              <CheckboxInput
                {...input}
                checked={data.publishAutoreplay}
                onChange={checked => {
                  updateData({ ...data, publishAutoreplay: checked })
                }}
                label={
                  <label>
                    <FormattedMessage id="sportEventItem.publishAutoreplay" />
                  </label>
                }
              />
            </Form.Field>
          )}
        />
      )}
      {initLivestream && (
        <>
          {/* Wildmoka */}
          {videoEngine === VideoEngine.Wildmoka && (
            <>
              <SAFormField
                name="sportEventItem.passthrough"
                render={({ input, meta }) => (
                  <Form.Field error={!!meta.touched && !!meta.error}>
                    <CheckboxInput
                      {...input}
                      checked={data.passthrough}
                      onChange={checked => {
                        updateData({ ...data, passthrough: checked })
                      }}
                      label={
                        <label>
                          <FormattedMessage id="sportEventItem.passthrough" />
                        </label>
                      }
                    />
                    <Popup
                      content={<FormattedMessage id="sportEventItem.passthrough_info" />}
                      trigger={<Icon name="question circle" color="blue" />}
                    />
                  </Form.Field>
                )}
              />
              {wmStreams && (
                <SAFormField
                  name="sportEventItem.streamId"
                  validate={validateRequired}
                  render={({ input, meta }: FieldRenderProps<Scalars['WildmokaID'], HTMLElement>) => (
                    <>
                      <Form.Field
                        error={!!meta.touched && streamConflicts.length > 0}
                        description={intl.formatMessage({
                          id: 'sportEventItem.streamId.description',
                        })}
                        required
                      >
                        <label>
                          <FormattedMessage id="sportEventItem.streamId" />
                        </label>
                        <SelectStreamWithConflicts
                          {...input}
                          from={data.startDate}
                          to={data.stopDate}
                          onChange={streamId => {
                            updateData({
                              ...data,
                              streamId: streamId || undefined,
                            })
                          }}
                          onConflicts={setStreamConflicts}
                          onLoading={setStreamLoading}
                          source={wmStreams || []}
                          loading={false}
                          sportItemSetId={sportItemSetId}
                          disabled={eventStarted}
                        />
                      </Form.Field>
                    </>
                  )}
                />
              )}
            </>
          )}
          {/* SVE */}
          {videoEngine === VideoEngine.Sportall && (
            <SAFormField
              name="sportEventItem.sveChannelId"
              render={({ input, meta }: FieldRenderProps<SveChannel['id'], HTMLElement>) => (
                <>
                  <Form.Field
                    error={!!meta.touched && streamConflicts.length > 0}
                    description={intl.formatMessage({
                      id: 'sportEventItem.sve.description',
                    })}
                  >
                    <label>
                      <FormattedMessage id="sportEventItem.sve.channel" />
                    </label>
                    <SelectSVEChannelWithConflicts
                      {...input}
                      from={data.startDate}
                      to={data.stopDate}
                      onChange={sveChannelId => {
                        updateData({
                          ...data,
                          sveChannelId: sveChannelId || undefined,
                        })
                      }}
                      onConflicts={setStreamConflicts}
                      onLoading={setStreamLoading}
                      source={sveChannels ?? []}
                      loading={false}
                      sportItemSetId={sportItemSetId}
                      disabled={eventStarted}
                    />
                  </Form.Field>
                </>
              )}
            />
          )}
          {streamConflicts && streamConflicts.length > 0 && (
            <Message
              negative
              content={streamErrorsMessages.map((message: string) => (
                <span key={message}>
                  {message}
                  <br />
                </span>
              ))}
            />
          )}
        </>
      )}
    </>
  )
}
