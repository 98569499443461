import React, { useCallback, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Button, Icon } from 'semantic-ui-react'
import styled from 'styled-components'

const InvisibleInput = styled.input`
  opacity: 0;
  height: 0;
  width: 0;
  padding: 0 !important;
  margin: 0;
  flex: 0;
`

const Container = styled.div`
  display: flex;
  align-items: center;
  > * + * {
    margin-left: 1em;
  }
`

interface FileInputValue {
  name?: string
  url?: string
}

interface InlineFileInputProps {
  value?: FileInputValue
  name: string
  accept?: string
  onChange: (file: File) => void
  placeholder?: React.ReactNode
}

const InlineFileInput: React.FC<InlineFileInputProps> = ({ value, name, accept, onChange, placeholder }) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [file, setFile] = useState('')

  const handleChange = useCallback(
    ({ target: { files } }: React.ChangeEvent<HTMLInputElement>) => {
      const file = files && files.item(0)
      if (file) {
        onChange(file)
        setFile('')
      }
    },
    [onChange],
  )

  return (
    <Container>
      <Button basic type="button" onClick={() => inputRef?.current?.click()}>
        <Icon name="folder" />

        {placeholder || <FormattedMessage id="common.browse" />}
      </Button>

      <InvisibleInput
        type="file"
        name={name}
        accept={accept}
        ref={inputRef}
        value={file}
        onChange={e => handleChange(e)}
      />

      {value &&
        (value.url ? (
          <a href={value.url} download>
            {value.name}
          </a>
        ) : (
          <span>{value.name || <FormattedMessage id="common.download" />}</span>
        ))}
    </Container>
  )
}

export default InlineFileInput
