import React, { FC, useState } from 'react'
import { Button, Input, Popup } from 'semantic-ui-react'

import { useEditorCommand, useEditorState } from '../useEditor'

const EditLinkPopup: FC = () => {
  const [{ currentLinkHref, currentLinkElement }] = useEditorState()
  const [, dispatch] = useEditorCommand()

  const open = Boolean(currentLinkElement)
  const [{ editMode, href }, setState] = useState<{ editMode: boolean; href?: string }>({
    editMode: false,
  })

  return (
    <Popup
      open={open}
      context={currentLinkElement as NonNullable<HTMLElement>}
      hoverable
      content={
        !editMode ? (
          <div className="flex items-center gap-4">
            <a className="text-blue-500" href={currentLinkHref} target="_blank" rel="noreferrer">
              {currentLinkHref}
            </a>
            <Button.Group size="small">
              <Button
                icon="edit"
                onClick={() => {
                  setState({ editMode: true, href: currentLinkHref })
                }}
              />
              <Button
                icon="trash"
                onClick={() => {
                  dispatch('removeLink')
                }}
              />
            </Button.Group>
          </div>
        ) : (
          <div className="flex items-center gap-4">
            <Input
              // ref={inputRef}
              type="text"
              value={href}
              onChange={e => {
                setState(state => ({ ...state, href: e.target.value }))
              }}
              // onKeyDown={(event) => {
              //   if (event.key === 'Enter') {
              //     event.preventDefault();
              //     if (lastSelection !== null) {
              //       if (linkUrl !== '') {
              //         editor.dispatchCommand(TOGGLE_LINK_COMMAND, linkUrl);
              //       }
              //       setEditMode(false);
              //     }
              //   } else if (event.key === 'Escape') {
              //     event.preventDefault();
              //     setEditMode(false);
              //   }
              // }}
            />
            <Button.Group size="small">
              <Button
                icon="cancel"
                onClick={() => {
                  setState({ editMode: false })
                }}
              />
              <Button
                icon="check"
                onClick={() => {
                  dispatch('updateLink', href)
                  setState({ editMode: false })
                }}
              />
            </Button.Group>
          </div>
        )
      }
    />
  )
}

export default EditLinkPopup
