import ConfirmationButton from 'Containers/Button/ConfirmationButton'
import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Icon, Modal, Table } from 'semantic-ui-react'
import { OfferMode, Permission, Product, PurchasableOffer } from 'services/api/graphql'
import { useCurrentUser } from 'stores'

import ProductsForm from './ProductsForm'

interface ProductsDataRowProps {
  purchasableOffer: PurchasableOffer
  product: Product
  onUpdateData?: () => void
  onRemoveProduct: () => Promise<void>
}

const ProductsDataRow = ({ purchasableOffer, product, onUpdateData, onRemoveProduct }: ProductsDataRowProps) => {
  const currentUser = useCurrentUser()
  const intl = useIntl()
  const [isModalOpen, setModalOpen] = useState(false)

  const getDescription = () => {
    const mode = intl.formatMessage({
      id: `purchasableOffer.mode.${purchasableOffer.mode}`,
    })
    const period =
      purchasableOffer &&
      purchasableOffer.mode === OfferMode.Subscription &&
      intl.formatMessage({ id: `date.frequency.${product.period}` })
    const isPremium = product.purchasableOfferId && intl.formatMessage({ id: 'product.premium' })
    const prices = product.prices.map(price => `${price.value} ${price.currency}`)

    return (
      <>
        {mode} {isPremium || ''} {product.platform} {period || ''} <br />
        {prices.join(', ')}
      </>
    )
  }

  return (
    <Table.Row>
      <Table.Cell>
        <FormattedMessage id={`publicationStatus.${product.publicationStatus}`} />
      </Table.Cell>
      <Table.Cell>{getDescription()}</Table.Cell>
      <Table.Cell>{product.productId}</Table.Cell>
      <Table.Cell>{product.platform}</Table.Cell>
      {purchasableOffer.mode === OfferMode.Subscription && (
        <Table.Cell>
          <FormattedMessage id={`date.${product.period}`} />
        </Table.Cell>
      )}
      <Table.Cell>
        {currentUser.can(Permission.PurchasableOfferWrite) && (
          <Modal
            mountNode={document.getElementById('modals')}
            trigger={<Icon name="edit" link size="large" inverted color="black" />}
            onOpen={() => setModalOpen(true)}
            onClose={() => setModalOpen(false)}
            open={isModalOpen}
          >
            <ProductsForm
              purchasableOffer={purchasableOffer}
              product={product}
              onDone={() => {
                if (onUpdateData) {
                  onUpdateData()
                }
                setModalOpen(false)
              }}
              onCancel={() => setModalOpen(false)}
            />
          </Modal>
        )}
        {currentUser.can(Permission.PurchasableOfferWrite) && (
          <ConfirmationButton
            action={onRemoveProduct}
            confirmText={intl.formatMessage({
              id: 'product.delete_confirm',
            })}
            successText={intl.formatMessage({
              id: 'product.delete_success',
            })}
          />
        )}
      </Table.Cell>
    </Table.Row>
  )
}

export default ProductsDataRow
