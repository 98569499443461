import SelectItemListInput from 'Components/Form/SelectItemListInput'
import React, { useMemo } from 'react'
import { Maybe, WmTemplate } from 'services/api/graphql'

export interface SelectWMTemplateInputProps {
  value: WmTemplate['id'] | undefined
  onChange: (value: Maybe<WmTemplate['id']>) => void
  noSelectionEnabled?: boolean
  noSelectionTitle?: string
  source: WmTemplate[]
  loading?: boolean
}

const SelectWMTemplateInput = ({
  value,
  onChange,
  noSelectionEnabled,
  noSelectionTitle = 'Aucun',
  source,
  loading,
}: SelectWMTemplateInputProps) => {
  const options = useMemo(() => {
    return source.map(template => ({
      key: template.id,
      value: template.id,
      text: template.name,
    }))
  }, [source])
  return (
    <SelectItemListInput
      value={value}
      loading={loading}
      disabled={loading}
      options={options}
      noSelectionEnabled={noSelectionEnabled}
      noSelectionTitle={noSelectionTitle}
      onChange={onChange}
    />
  )
}

export default SelectWMTemplateInput
