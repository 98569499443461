import { QueryHookOptions, useMutation, useQuery } from '@apollo/client'
import {
  CreateLinkInput,
  CREATE_LINK_MUTATION,
  DELETE_LINK_MUTATION,
  GET_LINKS_QUERY,
  GET_LINK_QUERY,
  QueryLinksArgs,
  Link,
  UpdateLinkInput,
  UPDATE_LINK_MUTATION,
} from 'services/api/graphql'

/**
 * Hooks
 */
export type GetLinkResult = {
  link: Link | undefined
}
export const useGetLink = (linkId: Link['id'], options?: QueryHookOptions<GetLinkResult>) => {
  const { data, ...rest } = useQuery<GetLinkResult>(GET_LINK_QUERY, {
    variables: { linkId },
    ...options,
  })
  return { link: data?.link, ...rest }
}

export type GetLinksResult = {
  links: {
    links: Link[]
    totalCount: number
  }
}
export const useGetLinks = (options?: QueryHookOptions<GetLinksResult, QueryLinksArgs>) => {
  const { data, ...rest } = useQuery<GetLinksResult, QueryLinksArgs>(GET_LINKS_QUERY, options)
  const links = data?.links.links
  const totalCount = data?.links.totalCount
  return { links, totalCount, ...rest }
}

export const useCreateLink = () => useMutation<{ createLink: Link }, { input: CreateLinkInput }>(CREATE_LINK_MUTATION)
export const useUpdateLink = () => useMutation<{ updateLink: Link }, { input: UpdateLinkInput }>(UPDATE_LINK_MUTATION)
export const useDeleteLink = () => useMutation<{ deleteLink: Link }, { linkId: Link['id'] }>(DELETE_LINK_MUTATION)
