import { gql } from '@apollo/client'

export const APPLICATION_FRAGMENT = gql`
  fragment applicationContent on Application {
    id
    title
    applicationId
    type
    url
    sponsoringUrl
    sponsorImageFileId
    organismId
    organism {
      whiteLabel
      id
      title
    }
    cac {
      userAuthenticationRule
    }
    publicationStatus
    excludedContent {
      sportItemSetIds
      sportItemSets {
        id
        title
      }
      sportItemIds
      sportItems {
        id
        title
      }
    }
    packageName
    appleSharedSecret
  }
`

export const GET_APPLICATION_QUERY = gql`
  query application($applicationId: ID!) {
    application(id: $applicationId) {
      ...applicationContent
    }
  }
  ${APPLICATION_FRAGMENT}
`

export const GET_APPLICATION_FROM_APPLICATION_ID_QUERY = gql`
  query applicationFromApplicationId($applicationId: ID!) {
    applicationFromApplicationId(id: $applicationId) {
      ...applicationContent
    }
  }
  ${APPLICATION_FRAGMENT}
`

export const GET_APPLICATIONS_QUERY = gql`
  query applications($filters: ApplicationsFilters) {
    applications(filters: $filters) {
      applications {
        ...applicationContent
      }
      totalCount
    }
  }
  ${APPLICATION_FRAGMENT}
`

export const CREATE_APPLICATION_MUTATION = gql`
  mutation createApplication($input: CreateApplicationInput!) {
    createApplication(input: $input) {
      ...applicationContent
    }
  }
  ${APPLICATION_FRAGMENT}
`

export const UPDATE_APPLICATION_MUTATION = gql`
  mutation updateApplication($input: UpdateApplicationInput!) {
    updateApplication(input: $input) {
      ...applicationContent
    }
  }
  ${APPLICATION_FRAGMENT}
`

export const DELETE_APPLICATION_MUTATION = gql`
  mutation deleteApplication($id: ID!) {
    deleteApplication(id: $id) {
      id
    }
  }
`
