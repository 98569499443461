import AddNewsButton from 'Forms/News/AddNewsButton'
import EditNewsButton from 'Forms/News/EditNewsButton'
import AddSportItemButton from 'Forms/SportItem/AddSportItemButton'
import EditSportItemButton from 'Forms/SportItem/EditSportItemButton'
import AddSportItemSetButton from 'Forms/SportItemSet/AddSportItemSetButton'
import EditSportItemSetButton from 'Forms/SportItemSet/EditSportItemSetButton'
import { applicationUris } from 'Layout/uris'
import ChatRoomsView from 'Views/ChatRoomsView'
import NewsView from 'Views/NewsView'
import PlaylistView from 'Views/PlaylistView'
import PlaylistsView from 'Views/PlaylistsView'
import SportEventView from 'Views/SportEventView'
import SportEventsView from 'Views/SportEventsView'
import SportItemMediaView from 'Views/SportItemMediasView'
import React from 'react'
import { Access, userCanAccessToPath } from 'services/api'
import { IUser } from 'stores'

const contentsRoutes = (user: IUser | null) => [
  {
    path: applicationUris.sportEvents,
    element: <SportEventsView />,
    loader: () => userCanAccessToPath(user, [Access.SportEventsView]),
  },
  {
    path: applicationUris.sportEventCreate,
    element: <AddSportItemSetButton />,
    loader: () => userCanAccessToPath(user, [Access.SportEventsView]),
  },
  {
    path: applicationUris.sportEventItems(':sportItemSetId'),
    element: <SportEventView />,
    loader: () => userCanAccessToPath(user, [Access.SportEventsView]),
  },
  {
    path: applicationUris.sportItemCreate(':sportItemSetId'),
    element: <AddSportItemButton />,
    loader: () => userCanAccessToPath(user, [Access.SportEventsView]),
  },
  {
    path: applicationUris.sportEvent(':sportItemSetId'),
    element: <EditSportItemSetButton />,
    loader: () => userCanAccessToPath(user, [Access.SportEventsView]),
  },
  {
    path: applicationUris.sportItem(':sportItemId'),
    element: <EditSportItemButton />,
    loader: () => userCanAccessToPath(user, [Access.SportEventsView]),
  },
  {
    path: applicationUris.playlists,
    element: <PlaylistsView />,
    loader: () => userCanAccessToPath(user, [Access.PlayListsView]),
  },
  {
    path: applicationUris.playlistCreate,
    element: <AddSportItemSetButton />,
    loader: () => userCanAccessToPath(user, [Access.PlayListsView]),
  },
  {
    path: applicationUris.playlist(':sportItemSetId'),
    element: <EditSportItemSetButton />,
    loader: () => userCanAccessToPath(user, [Access.PlayListsView]),
  },
  {
    path: applicationUris.playlistItems(':sportItemSetId'),
    element: <PlaylistView />,
    loader: () => userCanAccessToPath(user, [Access.PlayListsView]),
  },
  {
    path: applicationUris.playlist(':sportItemSetId'),
    element: <EditSportItemSetButton />,
    loader: () => userCanAccessToPath(user, [Access.SportEventsView]),
  },
  {
    path: applicationUris.sportItemMedias(':sportItemId'),
    element: <SportItemMediaView />,
    loader: () => userCanAccessToPath(user, [Access.PlayListsView]),
  },
  {
    path: applicationUris.newss,
    element: <NewsView />,
    loader: () => userCanAccessToPath(user, [Access.NewsView]),
  },
  {
    path: applicationUris.news(':newsId'),
    element: <EditNewsButton />,
    loader: () => userCanAccessToPath(user, [Access.NewsView]),
  },

  {
    path: applicationUris.newsCreate,
    element: <AddNewsButton />,
    loader: () => userCanAccessToPath(user, [Access.NewsView]),
  },
  {
    path: applicationUris.chatRooms,
    element: <ChatRoomsView />,
    loader: () => userCanAccessToPath(user, [Access.ChatRooms]),
  },
]

export default contentsRoutes
