import React, { FC } from 'react'

import EditLinkPopup from './EditLinkPopup'
import ToolbarButton from './ToolbarButton'

const InsertLinkButton: FC = () => (
  <>
    <ToolbarButton icon="linkify" cmd="insertLink" tooltipId="link" />
    <EditLinkPopup />
  </>
)

export default InsertLinkButton
