import CopyButton from 'Components/CopyButton'
import React, { useMemo } from 'react'
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl'
import { Icon, List, Modal } from 'semantic-ui-react'
import { LiveStreamContentFragment, Permission, VideoEngine, WmLiveType } from 'services/api/graphql'
import { useCurrentUser, useStore } from 'stores'

export interface LiveStreamWildmokaDetailsModalProps {
  livestream: LiveStreamContentFragment
  open: boolean
  onClose: () => void
}

export const LiveStreamWildmokaDetailsModal: React.FC<LiveStreamWildmokaDetailsModalProps> = ({
  livestream,
  open,
  onClose,
}) => {
  const currentUser = useCurrentUser()
  const { organismId } = useStore()
  const intl = useIntl()

  const playbackUrlPreview = useMemo(() => {
    const matches = livestream.playbackUrl.match(/^(.+)\?token=(.+)$/)
    if (!matches) return livestream.playbackUrl
    const [_, url, token] = matches
    return `${url}?token=${token.substr(0, 6)}…`
  }, [livestream])

  return (
    <Modal mountNode={document.getElementById('modals')} open={open} onClose={onClose} closeIcon>
      <Modal.Content>
        <List>
          {livestream.videoEngine !== VideoEngine.Wildmoka && (
            <FormattedMessage id="livestream.not_wildmoka_liveStream" />
          )}
          <List.Item>
            <List.Header>
              <FormattedMessage id="livestream.title" />
            </List.Header>
            <List.Description>{livestream.event && livestream.event.name}</List.Description>
          </List.Item>
          {livestream.event?.description && (
            <List.Item>
              <List.Header>
                <FormattedMessage id="livestream.event.description" />
              </List.Header>
              <List.Description>{livestream.event.description}</List.Description>
            </List.Item>
          )}
          <List.Item>
            <List.Header>
              <FormattedMessage id="livestream.template" />
            </List.Header>
            <List.Description>{livestream.event?.live && livestream.event.live.template.name}</List.Description>
          </List.Item>
          {livestream.event?.clipTemplate && (
            <List.Item>
              <List.Header>
                <FormattedMessage id="livestream.clipTemplate" />
              </List.Header>
              <List.Description>{livestream.event.clipTemplate.name}</List.Description>
            </List.Item>
          )}
          <List.Item>
            <List.Header>
              <FormattedMessage id="livestream.stream" />
            </List.Header>
            <List.Description>{livestream.event?.stream.name}</List.Description>
          </List.Item>
          <List.Item>
            <List.Header>
              <FormattedMessage id="livestream.ingestUrl" />
            </List.Header>
            <List.Description>{livestream.ingestUrl}</List.Description>
          </List.Item>
          {currentUser.can(Permission.EditLiveTokenExpiration, organismId || undefined) && (
            <List.Item>
              <List.Header>
                <FormattedMessage id="livestream.playbackUrl" />
                <CopyButton
                  value={livestream.playbackUrl}
                  notificationSuccess={intl.formatMessage({
                    id: 'livestream.playbackUrl_copied',
                  })}
                >
                  <Icon name="copy" />
                </CopyButton>
              </List.Header>
              <List.Description>{playbackUrlPreview}</List.Description>
            </List.Item>
          )}
          <List.Item>
            <List.Header>
              <FormattedMessage id="livestream.event.testStartDate" />
            </List.Header>
            <List.Description>
              <FormattedDate
                day="2-digit"
                month="2-digit"
                year="numeric"
                hour="2-digit"
                minute="2-digit"
                value={livestream.event?.testStartDate}
              />
            </List.Description>
          </List.Item>
          <List.Item>
            <List.Header>
              <FormattedMessage id="livestream.event.editoStartDate" />
            </List.Header>
            <List.Description>
              <FormattedDate
                day="2-digit"
                month="2-digit"
                year="numeric"
                hour="2-digit"
                minute="2-digit"
                value={livestream.event?.editoStartDate}
              />
            </List.Description>
          </List.Item>
          <List.Item>
            <List.Header>
              <FormattedMessage id="livestream.event.editoStopDate" />
            </List.Header>
            <List.Description>
              <FormattedDate
                day="2-digit"
                month="2-digit"
                year="numeric"
                hour="2-digit"
                minute="2-digit"
                value={livestream.event?.stopDate || livestream.event?.stopDate}
              />
            </List.Description>
          </List.Item>
          {livestream.event?.live && livestream.event?.live.type === WmLiveType.Passthrough && (
            <List.Item>
              <List.Header>
                <FormattedMessage id="livestream.event.passthrough_active" />
              </List.Header>
            </List.Item>
          )}
        </List>
      </Modal.Content>
    </Modal>
  )
}
