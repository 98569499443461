import CropImageModal from 'Components/CropImage/CropImageModal'
import React, { Fragment, useCallback, useEffect } from 'react'
import { Dimmer, Loader } from 'semantic-ui-react'
import { useUploadFile } from 'services/api'
import { FileMetadata, Media } from 'services/api/graphql'
import { ImageConstraints, ImageDimensions } from 'types/image'

interface CropImageAndUploadProps {
  onDone: (imageMedia: Media) => void // Called when crop + upload done
  onCancel?: () => void
  image: Blob
  fileMetadata?: FileMetadata | undefined
  canImportSourceImage?: boolean
  imageConstraints?: ImageConstraints
  cropButtonTitle?: string | undefined
  ownerOrganismId?: string
}
const CropImageAndUpload = ({
  image: imageBlob,
  onDone,
  onCancel,
  cropButtonTitle = 'Importer',
  fileMetadata,
  canImportSourceImage,
  imageConstraints,
  ownerOrganismId,
}: CropImageAndUploadProps) => {
  // Upload
  const { uploadFile, result: uploadedSportallFile, loading: loadingUploadFile } = useUploadFile(ownerOrganismId)

  // Start upload
  const handleCropDone = useCallback(
    (image: File, imageSize: ImageDimensions) => {
      const metadata: FileMetadata = {
        ...fileMetadata,
        aspect: imageSize.height && imageSize.width / imageSize.height,
        width: imageSize.width,
        height: imageSize.height,
      }

      uploadFile(image, metadata)
    },
    [fileMetadata, uploadFile],
  )

  useEffect(() => {
    if (uploadedSportallFile) onDone(uploadedSportallFile)
  }, [uploadedSportallFile, onDone])

  // Crop
  return (
    <Fragment>
      {imageBlob.type.startsWith('image') && (
        <Fragment>
          {imageBlob && (
            <CropImageModal
              canImportSourceImage={canImportSourceImage}
              imageConstraints={imageConstraints}
              open
              image={imageBlob}
              onDone={handleCropDone}
              onClose={() => onCancel && onCancel()}
              cropButtonTitle={cropButtonTitle}
            />
          )}
        </Fragment>
      )}
      <Dimmer page active={loadingUploadFile} inverted>
        <Loader active />
      </Dimmer>
    </Fragment>
  )
}

export default CropImageAndUpload
