import SelectItemListInput from 'Components/Form/SelectItemListInput'
import { SwitchOption } from 'Components/Form/SwitchInput'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { RestrictionType } from 'services/api/graphql'

import { RestrictionTarget } from './LegalRestrictionForm'

export interface SelectRestrictionTypeProps {
  value: RestrictionType
  target: RestrictionTarget
  onChange: (value: RestrictionType) => void
}

const SelectRestrictionTypeInput = ({ value, onChange, target }: SelectRestrictionTypeProps) => {
  const intl = useIntl()

  const options: Array<SwitchOption<RestrictionType>> = useMemo(() => {
    const commonOptions = [
      {
        key: RestrictionType.Forbidden,
        value: RestrictionType.Forbidden,
        text: intl.formatMessage({
          id: `legalRestriction.type.forbidden`,
        }),
      },
      {
        key: RestrictionType.OnlyAllowed,
        value: RestrictionType.OnlyAllowed,
        text: intl.formatMessage({
          id: `legalRestriction.type.onlyAllowed`,
        }),
      },
    ]

    if (target === RestrictionTarget.offerIds) {
      return commonOptions
    } else {
      return [
        ...commonOptions,
        {
          key: RestrictionType.TimeSlots,
          value: RestrictionType.TimeSlots,
          text: intl.formatMessage({
            id: `legalRestriction.type.timeSlots`,
          }),
        },
      ]
    }
  }, [intl, target])

  return <SelectItemListInput value={value} onChange={onChange} options={options} />
}

export default SelectRestrictionTypeInput
