import AdCampaignViewRow from 'Components/AdCampaign/AdCampaignViewRow'
import RefreshButton from 'Components/Button/RefreshButton'
import Loader from 'Components/Loader'
import PageHeader from 'Components/PageHeader'
import TablePagination from 'Components/TablePagination'
import AdCampaignForm from 'Forms/AdCampaign/AdCampaignForm'
import usePagination from 'hooks/usePagination'
import { useGetAdCampaigns, useDeleteAdCampaign } from 'models/AdCampaign'
import React, { useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button, Grid, Modal, Table } from 'semantic-ui-react'
import { AdCampaign } from 'services/api/graphql'
import { Permission } from 'services/api/graphql'
import { useCurrentUser, useStore } from 'stores'
import { cloneWithoutGraphqlCacheTypenames } from 'tools/graphql'

const AdCampaignsView = () => {
  const intl = useIntl()
  const { organismId } = useStore()

  const [adCampaigns, setAdCampaigns] = useState<AdCampaign[]>([])
  const { activePage, onPageChange, paginationParams, pageSize } = usePagination()
  const { loading, refetch, data } = useGetAdCampaigns({
    variables: { filters: { organismId }, ...paginationParams },
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      const list = (data && data.adCampaigns && data.adCampaigns.adCampaigns) || []
      setAdCampaigns(cloneWithoutGraphqlCacheTypenames(list))
    },
  })

  const totalCount = useMemo(() => (data && data.adCampaigns.totalCount) || 0, [data])

  const totalPages = useMemo(() => {
    if (!totalCount) return 0
    return Math.ceil(totalCount / pageSize)
  }, [pageSize, totalCount])

  const [removeAdCampaign] = useDeleteAdCampaign()

  const currentUser = useCurrentUser()
  const [formModalOpen, setFormModalOpen] = useState(false)

  return (
    <Loader loading={loading}>
      <Grid padded>
        <Grid.Column>
          <PageHeader title={intl.formatMessage({ id: 'adCampaign' })}>
            <RefreshButton onClick={() => refetch()} loading={loading} />
            {currentUser.can(Permission.AdCampaignCreate) && organismId && (
              <Modal
                mountNode={document.getElementById('modals')}
                trigger={<Button icon="add" circular color="red" />}
                content={
                  <AdCampaignForm
                    action="create"
                    onDone={() => {
                      refetch()
                      setFormModalOpen(false)
                    }}
                    organismId={organismId ?? ''}
                    onCancel={() => setFormModalOpen(false)}
                  />
                }
                onClose={() => setFormModalOpen(false)}
                onOpen={() => setFormModalOpen(true)}
                open={formModalOpen}
              />
            )}
          </PageHeader>
          <Table striped compact celled>
            <Table.Header>
              <Table.Row textAlign="center">
                <Table.HeaderCell>
                  <FormattedMessage id="applicationDesign.title" />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <FormattedMessage id="publicationStatus" />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <FormattedMessage id="menu.organisms" />
                </Table.HeaderCell>
                <Table.HeaderCell width={1} textAlign="center">
                  <FormattedMessage id="customPage.actions" />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {adCampaigns?.map(adCampaign => (
                <AdCampaignViewRow
                  key={adCampaign.id}
                  adCampaign={adCampaign}
                  onUpdateAdCampaign={refetch}
                  onRemoveAdCampaign={async () => {
                    await removeAdCampaign({
                      variables: { id: adCampaign.id },
                    })
                    refetch()
                  }}
                />
              ))}
            </Table.Body>
          </Table>
          {totalPages > 1 && (
            <TablePagination totalPages={totalPages} activePage={activePage || 1} onPageChange={onPageChange} />
          )}
        </Grid.Column>
      </Grid>
    </Loader>
  )
}

export default AdCampaignsView
