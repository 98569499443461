import { BlockWithAlignableContents } from '@lexical/react/LexicalBlockWithAlignableContents'
import React from 'react'
import { FC } from 'react'

type BlockWithAlignableContentsProps = Parameters<typeof BlockWithAlignableContents>[0]

export type ImageElementProps = {
  src: string
  alt?: string
} & Omit<BlockWithAlignableContentsProps, 'children'>

const ImageElement: FC<ImageElementProps> = ({ src, alt, nodeKey, ...props }) => (
  <BlockWithAlignableContents nodeKey={nodeKey} {...props}>
    <img className="inline-block pointer-events-none" src={src} alt={alt} style={{ maxHeight: '250px' }} />
  </BlockWithAlignableContents>
)

export default ImageElement
