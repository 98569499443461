import SAFormField from 'Components/Form/SAFormField'
import { SelectDateInput } from 'Components/Form/SelectDateInput'
import SelectLocalInput, { SelectedTranslationsType, TranslatedInput } from 'Components/Form/SelectLocalInput'
import FileInput from 'Containers/Form/FileInput'
import SelectPublicationStatusInput from 'Containers/Form/SelectPublicationStatusInput'
import { useGetOrganism } from 'models'
import React, { FC, useMemo } from 'react'
import { Field, useField } from 'react-final-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { Form, Header, Input, Segment } from 'semantic-ui-react'
import { MediaType, OfferMode } from 'services/api/graphql'
import { useStore } from 'stores'
import { validateRequired } from 'tools/formValidators'

import DescriptionTranslationFieldArray from './DescriptionTranslationFieldArray'

import { PassType } from './PurchasableOffersForm'

export type PurchasableOffersFormValues = {
  title: string
  titleT?: TranslatedInput | undefined
  description: string
  descriptionT?: TranslatedInput | undefined
}

type Props = {
  defaultLocale: string
  onChangeDefaultLocale: (value: string) => void
  selectedTranslations: SelectedTranslationsType[]
  onChangeSelectedTranslations: (newCountryTranslation: SelectedTranslationsType[]) => void
  onChangeActiveTranslation: (newActiveTranslation?: string) => void
  activeTranslation: string | undefined
}

const PurchasableOffersFormFields: FC<Props> = ({
  activeTranslation,
  onChangeActiveTranslation,
  selectedTranslations,
  onChangeSelectedTranslations,
  defaultLocale,
  onChangeDefaultLocale,
}) => {
  const intl = useIntl()

  const { organismId } = useStore()
  const { organism } = useGetOrganism(organismId || '', { skip: !organismId })

  const fieldMode = useField<OfferMode>('mode')
  const fieldPassType = useField<PassType>('passType')
  const fieldDelay = useField<number>('delay')
  const fieldExpiredAt = useField<Date>('expiredAt')

  const mode = useMemo(() => fieldMode.input.value, [fieldMode.input.value])
  const expiredAt = useMemo(() => fieldExpiredAt.input.value, [fieldExpiredAt.input.value])
  const passType = useMemo(() => fieldPassType.input.value, [fieldPassType.input.value])
  const delay = useMemo(() => fieldDelay.input.value || 1, [fieldDelay.input.value])

  return (
    <Form>
      <SAFormField
        name="locale"
        render={({ input }) => (
          <Form.Field
            {...input}
            disabled={false}
            label={intl.formatMessage({ id: 'locale' })}
            control={() => (
              <SelectLocalInput
                defaultLocale={defaultLocale}
                onChangeDefaultLocale={onChangeDefaultLocale}
                activeTranslation={activeTranslation}
                onChangeActiveTranslation={onChangeActiveTranslation}
                selectedTranslations={selectedTranslations}
                onChangeSelectedTranslations={onChangeSelectedTranslations}
              />
            )}
          />
        )}
      />
      <Field
        name={`titleT.${activeTranslation}`}
        validate={validateRequired}
        render={({ input, meta }) => (
          <Form.Input
            {...input}
            required
            error={meta.touched && !!meta.error}
            label={intl.formatMessage({
              id: 'purchasableOffer.title',
            })}
          />
        )}
      />
      <Field
        name="publicationStatus"
        validate={validateRequired}
        render={({ input, meta }) => (
          <Form.Field
            control={SelectPublicationStatusInput}
            {...input}
            error={meta.touched && !!meta.error}
            label={intl.formatMessage({
              id: 'purchasableOffer.publicationStatus',
            })}
          />
        )}
      />

      {organism && organism.license && organism.license.enabled && (
        <Field<boolean | undefined>
          name="freeWithLicense"
          render={({ input }) => (
            <Form.Checkbox
              checked={!!input.value}
              label={intl.formatMessage({
                id: 'purchasableOffer.freeWithLicense',
              })}
              onChange={(e, { checked }) => input.onChange(!!checked)}
            />
          )}
        />
      )}

      <Field
        name="url"
        render={({ input, meta }) => (
          <Form.Input
            {...input}
            error={meta.touched && !!meta.error}
            label={intl.formatMessage({
              id: 'purchasableOffer.url',
            })}
          />
        )}
      />

      <Field
        name="mode"
        render={({ input }) => (
          <Form.Dropdown
            name={input.name}
            value={input.value}
            selection
            options={Object.values(OfferMode).map(item => ({
              value: item,
              text: intl.formatMessage({
                id: `purchasableOffer.mode.${item}`,
              }),
            }))}
            label="Mode"
            onChange={(e, { value }) => input.onChange(value)}
          />
        )}
      />

      {mode === OfferMode.Pass && (
        <Segment color="grey">
          <Form.Group inline>
            <Field
              name="passType"
              type="radio"
              value={PassType.delay}
              render={({ input }) => (
                <Form.Radio
                  name={input.name}
                  value={input.value}
                  checked={input.checked}
                  label={intl.formatMessage({ id: 'purchasableOffer.delay' })}
                  onChange={(e, { value }) => input.onChange(value)}
                />
              )}
            />
            <Field
              name="passType"
              value={PassType.expiredAt}
              type="radio"
              render={({ input }) => (
                <Form.Radio
                  name={input.name}
                  value={input.value}
                  checked={input.checked}
                  label={intl.formatMessage({
                    id: 'purchasableOffer.expiredAt',
                  })}
                  onChange={(e, { value }) => input.onChange(value)}
                />
              )}
            />
          </Form.Group>

          {passType === PassType.delay ? (
            <Field
              name="delay"
              initialValue={delay}
              parse={value => parseInt(value.toString(), 10)}
              render={({ input }) => (
                <Input
                  {...input}
                  type="number"
                  labelPosition="right"
                  min={1}
                  label={intl.formatMessage({
                    id: 'purchasableOffer.delay.time',
                  })}
                />
              )}
            />
          ) : (
            <Field
              name="expiredAt"
              render={({ input, meta }) => (
                <Form.Field
                  control={SelectDateInput}
                  format="DD/MM/YYYY HH:mm"
                  {...input}
                  value={!!expiredAt && expiredAt}
                  error={meta.touched && !!meta.error}
                  label={intl.formatMessage({
                    id: 'purchasableOffer.expiredAt',
                  })}
                  onChange={(value: Date | undefined) => input.onChange(value || null)}
                  minDate={new Date()}
                />
              )}
            />
          )}
        </Segment>
      )}

      <Form.Group>
        <Field
          name="bannerImageId"
          render={({ input, meta }) => (
            <Form.Field
              {...input}
              error={meta.touched && !!meta.error}
              label={intl.formatMessage({
                id: 'purchasableOffer.bannerImage',
              })}
              control={FileInput}
              type={MediaType.Image}
              renderOrganismPicker
              imageConstraints={{ maxSize: 3000000 }}
            />
          )}
        />
        <Field
          name="thumbnailImageId"
          render={({ input, meta }) => (
            <Form.Field
              {...input}
              error={meta.touched && !!meta.error}
              label={intl.formatMessage({
                id: 'purchasableOffer.thumbnailImage',
              })}
              control={FileInput}
              type={MediaType.Image}
              renderOrganismPicker
              imageConstraints={{ maxSize: 3000000 }}
            />
          )}
        />
      </Form.Group>
      <Header as="h3">
        <FormattedMessage id="purchasableOffer.description" />
      </Header>
      {selectedTranslations.map(({ value }) => (
        <DescriptionTranslationFieldArray key={value} localKey={value} isDisplayed={activeTranslation === value} />
      ))}
    </Form>
  )
}

export default PurchasableOffersFormFields
