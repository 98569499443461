import { invisibleButtonAttrs } from 'Components/Button/InvisibleButton'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { FormattedDate } from 'react-intl'
import { Popup } from 'semantic-ui-react'
import { useStore } from 'stores'
import styled from 'styled-components'

const TriggerButton = styled.button`
  ${invisibleButtonAttrs}
  color: white;
`

const Versions = observer(() => {
  const store = useStore()

  const { servicePlatformVersion, backofficeVersion } = store.debug

  return (
    <Popup on="click" trigger={<TriggerButton style={{ color: 'white' }}>Versions</TriggerButton>}>
      {servicePlatformVersion && (
        <>
          <h3>Service-plateform</h3>
          <ul>
            <li>
              <strong>Version :</strong> {servicePlatformVersion.version}
            </li>

            <li>
              <strong>Build date :</strong> <FormattedDate value={servicePlatformVersion.buildDate} />
            </li>
          </ul>
        </>
      )}
      {backofficeVersion && (
        <>
          <h3>Backoffice</h3>
          <ul>
            <li>
              <strong>Version :</strong> {backofficeVersion.version}
            </li>
            <li>
              <strong>Build date :</strong> <FormattedDate value={backofficeVersion.buildDate} />
            </li>
          </ul>
        </>
      )}
    </Popup>
  )
})

export default Versions
