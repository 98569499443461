import { useContext, useEffect, useRef } from 'react'

import { EditorContext } from './EditorProvider'

/**
 * Retrieves the <Editor> context from <EditorProvider>
 */
const useEditor = () => useContext(EditorContext) as NonNullable<EditorContext>

/**
 * Allows to read or update the <Editor> toolbar ui state (active buttons, etc...).
 */
export const useEditorState = () => {
  const [state, setState] = useEditor()
  return [state, setState] as const
}

/**
 * Allows to subscribe or dispatch <Editor> commands.
 */
export const useEditorCommand = () => {
  const [, , subscribe, dispatch] = useEditor()
  return [subscribe, dispatch] as const
}

/**
 * Registers an adapter to the abstract <Editor> and its <Toolbar> so the wysiwyg
 * implementation of your choice can be used to handle editor commands.
 *
 * @param adapter A callback function called each time a command is emitted by the <Editor>.
 */
export const useEditorAdapter = (adapter: (cmd: string, payload?: any) => void) => {
  const [subscribe] = useEditorCommand()

  // put the adapter in a ref to make sure it's not a dep of useEffect
  const adapterRef = useRef<typeof adapter | null>(adapter)
  adapterRef.current = adapter

  useEffect(() => {
    if (adapterRef.current) {
      const unsubscribe = subscribe(adapterRef.current)

      // for our beloved friend the garbage collector
      return () => {
        unsubscribe()
        adapterRef.current = null
      }
    }
  }, [subscribe])
}
