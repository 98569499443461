import { QueryHookOptions, useMutation, useQuery } from '@apollo/client'
import {
  AdCampaign,
  QueryAdCampaignsArgs,
  CreateAdCampaignInput,
  CREATE_AD_CAMPAIGN_MUTATION,
  UpdateAdCampaignInput,
  UPDATE_AD_CAMPAIGN_MUTATION,
  DELETE_AD_CAMPAIGN_MUTATION,
  GET_AD_CAMPAIGN_QUERY,
} from 'services/api/graphql'

export type GetAdCampaignResults = {
  adCampaigns: {
    adCampaigns: AdCampaign[]
    totalCount: number
  }
}
export const useGetAdCampaigns = (options?: QueryHookOptions<GetAdCampaignResults, QueryAdCampaignsArgs>) =>
  useQuery(GET_AD_CAMPAIGN_QUERY, options)

export const useCreateAdCampaign = () =>
  useMutation<{ createAdCampaign: AdCampaign }, { input: CreateAdCampaignInput }>(CREATE_AD_CAMPAIGN_MUTATION)

export const useUpdateAdCampaign = () =>
  useMutation<{ updateAdCampaign: AdCampaign }, { input: UpdateAdCampaignInput }>(UPDATE_AD_CAMPAIGN_MUTATION)

export const useDeleteAdCampaign = () =>
  useMutation<{ deleteAdCampaign: AdCampaign }, { id: AdCampaign['id'] }>(DELETE_AD_CAMPAIGN_MUTATION)
