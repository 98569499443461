import { useQuery } from '@apollo/client'
import { UserFragment } from 'Views/Users/fragments'
import { FragmentOf, ResultOf, readFragment } from 'gql'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Divider, Grid, Icon, Loader, Table } from 'semantic-ui-react'
import { SignUpOrigin, User } from 'services/api/graphql'
import { format } from 'tools/date'

import { GetUserDetailsQuery, UserFanFragment } from './fragments'

interface UserViewFanProps {
  user: ResultOf<typeof UserFragment> | User
}

const UserViewFan = ({ user: { id: userId } }: UserViewFanProps) => {
  const { data: userFan, loading } = useQuery<{ user: FragmentOf<typeof UserFanFragment> }>(GetUserDetailsQuery, {
    variables: { userId, offers: false, fan: true, licenses: false },
  })
  const user = userFan?.user && readFragment(UserFanFragment, userFan.user)

  return (
    <Grid.Row>
      <Grid.Column width={16}>
        <Divider />

        {loading ? (
          <Loader active />
        ) : (
          <Table basic="very" celled compact striped>
            <Table.Body>
              <Table.Row>
                <Table.Cell width={5}>
                  <FormattedMessage id="users.birthdayDate" />
                </Table.Cell>

                <Table.Cell>{user?.birthdayDate ? format(user.birthdayDate, 'DD/MM/YYYY') : '/'}</Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>
                  <FormattedMessage id="users.country" />
                </Table.Cell>

                <Table.Cell>{user?.location?.country ? `${user.location.country.toUpperCase()}` : '/'}</Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>
                  <FormattedMessage id="users.postalCode" />
                </Table.Cell>

                <Table.Cell>{user?.location?.postalCode || '/'}</Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>
                  <FormattedMessage id="users.cgu" />
                </Table.Cell>

                <Table.Cell>
                  {!!user?.cguAccepted ? <FormattedMessage id="common.yes" /> : <FormattedMessage id="common.no" />}
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>
                  <FormattedMessage id="users.signUpOrigin" />
                </Table.Cell>

                <Table.Cell>
                  <OriginSignup signUpOrigin={user?.signUpOrigin as SignUpOrigin} />
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>
                  <FormattedMessage id="users.verified" />
                </Table.Cell>

                <Table.Cell>
                  {!!user?.verified ? <FormattedMessage id="common.yes" /> : <FormattedMessage id="common.no" />}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        )}
      </Grid.Column>
    </Grid.Row>
  )
}

type OriginSignupProps = {
  signUpOrigin?: SignUpOrigin | null
}
const OriginSignup = ({ signUpOrigin }: OriginSignupProps) => (
  <>
    {signUpOrigin === SignUpOrigin.Apple && <Icon name="apple" size="large" title="Apple" />}
    {signUpOrigin === SignUpOrigin.Email && <Icon name="mail" title="Email" />}
    {signUpOrigin === SignUpOrigin.Facebook && <Icon name="facebook official" size="large" title="Facebook" />}
    {signUpOrigin === SignUpOrigin.Google && <Icon name="google" size="large" title="Google" />}
    {signUpOrigin === SignUpOrigin.External && (
      <>
        <Icon name="facebook official" title="Facebook" /> / <Icon name="google" title="Google" />
      </>
    )}
  </>
)

export default UserViewFan
