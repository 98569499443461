import OrganismPicker, { OrganismPickerBaseProps } from 'Containers/OrganismPicker'
import { observer } from 'mobx-react-lite'
import { useAllowedOrganismTitles } from 'models'
import React from 'react'
import { useLocation } from 'react-router-dom'
import { useStore } from 'stores/useStores'

export type CurrentOrganismPickerProps = OrganismPickerBaseProps

const CurrentOrganismPicker = observer((props: CurrentOrganismPickerProps) => {
  const store = useStore()
  const { organisms } = useAllowedOrganismTitles()
  const location = useLocation()

  return (
    <OrganismPicker
      organisms={organisms || []}
      value={store.organismId}
      onChange={value => {
        store.setOrganismId(value)
        window.history.replaceState(null, '', `${location.pathname}`)
      }}
      {...props}
    />
  )
})

export default CurrentOrganismPicker
