import SwitchInput from 'Components/Form/SwitchInput'
import React from 'react'

import { IntlShape, useIntl } from 'react-intl'
import { UserLocationCondition } from 'services/api/graphql'

export interface SelectUserLocationConditionInputProps {
  value: UserLocationCondition | undefined
  onChange: (value: UserLocationCondition | undefined) => void
}

const options = (intl: IntlShape) => [
  {
    key: UserLocationCondition.InArea,
    value: UserLocationCondition.InArea,
    text: intl.formatMessage({ id: 'userLocationCondition.inArea' }),
  },
  {
    key: UserLocationCondition.OutOfArea,
    value: UserLocationCondition.OutOfArea,
    text: intl.formatMessage({ id: 'userLocationCondition.outOfArea' }),
  },
]

export const SelectUserLocationConditionInput = ({ value, onChange }: SelectUserLocationConditionInputProps) => {
  const intl = useIntl()
  return <SwitchInput value={value} onChange={onChange} options={options(intl)} />
}
