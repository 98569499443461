import SelectFile from 'Containers/SelectFile'
import fileSize from 'filesize'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { Icon } from 'semantic-ui-react'
import { MediaType, Organism } from 'services/api/graphql'
import styled from 'styled-components'
import { aspectRatioText } from 'tools/image'
import { ImageConstraints } from 'types/image'

import FileInputPreview from './FileInputPreview'

const Constraints = styled.div`
  display: flex;
  color: blue;
  font-style: italic;
`
const Constraint = styled.div`
  color: blue;
  font-style: italic;
  &:not(:last-child) {
    margin-right: 8px;
  }
`

interface ImageConstraintsLineProps {
  imageConstraints: ImageConstraints
}
const ImageConstraintsLine = ({ imageConstraints }: ImageConstraintsLineProps) => {
  const intl = useIntl()
  const constraintItems = useMemo(() => {
    const constraints = []
    if (imageConstraints.aspect) constraints.push(`Aspect ratio: ${aspectRatioText(imageConstraints.aspect)}`)

    if (imageConstraints.minWidth !== undefined)
      constraints.push(intl.formatMessage({ id: 'imageConstraints.width.min' }, { width: imageConstraints.minWidth }))

    if (imageConstraints.minHeight !== undefined)
      constraints.push(
        intl.formatMessage({ id: 'imageConstraints.height.min' }, { height: imageConstraints.minHeight }),
      )

    if (imageConstraints.format !== undefined)
      constraints.push(intl.formatMessage({ id: 'imageConstraints.format' }, { format: imageConstraints.format }))

    if (imageConstraints.maxWidth !== undefined)
      constraints.push(intl.formatMessage({ id: 'imageConstraints.width.max' }, { width: imageConstraints.maxWidth }))

    if (imageConstraints.maxHeight !== undefined)
      constraints.push(
        intl.formatMessage({ id: 'imageConstraints.height.max' }, { height: imageConstraints.maxHeight }),
      )

    if (imageConstraints.maxSize !== undefined)
      constraints.push(
        intl.formatMessage(
          { id: 'imageConstraints.maxSize' },
          { maxSize: fileSize(imageConstraints.maxSize, { locale: 'fr-FR' }) },
        ),
      )

    return constraints
  }, [
    imageConstraints.aspect,
    imageConstraints.maxHeight,
    imageConstraints.maxWidth,
    imageConstraints.minHeight,
    imageConstraints.minWidth,
    imageConstraints.format,
    imageConstraints.maxSize,
    intl,
  ])
  return (
    <Constraints>
      <Constraint>({constraintItems.join(', ')})</Constraint>
    </Constraints>
  )
}

const SelectFileChild = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Preview = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  border-color: black;
  border-width: 1px;
  border-style: solid;
  padding: 1px;
  width: 100px;
  height: 100px;
`

const PreviewWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`

const FileInputIcon = styled(Icon)`
  margin-left: 8px;
  color: yellow;
`

const Description = styled.span`
  font-size: 0.9rem;
  font-style: italic;
  margin-left: 12;
`

export interface FileInputProps {
  value: string | null
  type: MediaType
  onChange: (value: string | null) => void
  description?: string | null
  imageConstraints?: ImageConstraints
  hideImageConstraints?: boolean
  renderOrganismPicker?: boolean
  organismId?: Organism['id']
}
const FileInput = ({
  value: mediaId,
  type,
  onChange,
  description,
  imageConstraints,
  hideImageConstraints,
  renderOrganismPicker,
  organismId,
}: FileInputProps) => {
  return (
    <SelectFile
      onDone={file => onChange(file.id)}
      onCancel={() => false}
      imageConstraints={imageConstraints}
      type={type}
      renderOrganismPicker={renderOrganismPicker}
      organismId={organismId}
    >
      {({ selectFile }) => (
        <SelectFileChild className="fileinput">
          <PreviewWrapper>
            {/* Warning: We keep 'preview' class set to keep 'error' style applied (see App.css file) */}
            <Preview className="preview" onClick={() => selectFile(mediaId)}>
              <FileInputPreview mediaId={mediaId ?? undefined} type={type} />
            </Preview>

            {mediaId && (
              <FileInputIcon
                disabled={!mediaId}
                name="remove"
                link
                size="large"
                inverted
                color="red"
                onClick={() => onChange(null)}
              />
            )}
          </PreviewWrapper>

          {!hideImageConstraints && imageConstraints && <ImageConstraintsLine imageConstraints={imageConstraints} />}

          <Description>{description}</Description>
        </SelectFileChild>
      )}
    </SelectFile>
  )
}

export default FileInput
