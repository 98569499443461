import React from 'react'

import styled from 'styled-components'

const Label = styled.strong`
  margin-right: 8px;
`

export interface FileDetailsFieldProps {
  label: string
  content: string | undefined | null
}
const FileDetailsField = ({ label, content }: FileDetailsFieldProps) => {
  return (
    <p>
      <Label>{label} :</Label>
      {content}
    </p>
  )
}

export default FileDetailsField
