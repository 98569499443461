import { useCreateSveChannelMutation } from 'models'
import React from 'react'
import { useIntl } from 'react-intl'

import { SVEChannelForm, SVEChannelFormProps } from './SVEChannelForm'

export interface CreateSVEChannelFormProps
  extends Omit<SVEChannelFormProps, 'title' | 'edit' | 'onSubmit' | 'loading'> {}

export const CreateSVEChannelForm = ({ onCancel, onDone, onError, ...props }: CreateSVEChannelFormProps) => {
  const intl = useIntl()
  const [createSVEChannel, { loading }] = useCreateSveChannelMutation()
  return (
    <SVEChannelForm
      title={intl.formatMessage({ id: 'sveChannels.form.add.title' })}
      edit={false}
      onSubmit={values => {
        createSVEChannel({ variables: { input: values } })
          .then(result => {
            if (result && result.data) onDone(result.data.createSVEChannel)
          })
          .catch(onError)
      }}
      onCancel={onCancel}
      loading={loading}
      onDone={onDone}
      onError={onError}
      {...props}
    />
  )
}
