import CancelFormButton from 'Components/Form/CancelFormButton'
import arrayMutators from 'final-form-arrays'
import { useCreateAdCampaignMutation, useUpdateAdCampaignMutation } from 'models'
import React, { useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button, Dimmer, Modal } from 'semantic-ui-react'
import { AdCampaign, PrerollConfig } from 'services/api/graphql'
import { useStore } from 'stores'
import { getErrorMessage } from 'tools/graphql'
import { notifyError, notifySuccess } from 'tools/toaster'

import AdCampaignFormFields, { AffiliateFieldType } from './AdCampaignFormFields'

interface AdCampaignnFormProps {
  adCampaign?: AdCampaign
  action?: string
  onCancel: () => void
  onDone?: () => void
  organismId: string
}

const AdCampaignForm = (props: AdCampaignnFormProps) => {
  const intl = useIntl()
  const store = useStore()
  const { adCampaign, action, onCancel, onDone, organismId: organismIdFromProps } = props
  const initialValues = useMemo(() => ({ ...adCampaign }), [adCampaign])
  const [updateAdCampaign, { loading: isUpdateLoading }] = useUpdateAdCampaignMutation()
  const [createAdCampaign, { loading: isCreateLoading }] = useCreateAdCampaignMutation()
  const [isLoading, setLoading] = useState(false)
  const [fields, setFields] = useState<AffiliateFieldType[]>(initialValues?.affiliates || [])

  const organismId = organismIdFromProps ?? store.organismId

  if (adCampaign?.prerollConfigs) {
    const newPrefollConfigs: any = {}

    for (let i = 0; i < adCampaign?.prerollConfigs?.length; i++) {
      const config = adCampaign.prerollConfigs[i]
      const key = config.videoType
      newPrefollConfigs[key] = config
    }

    initialValues.prerollConfigs = newPrefollConfigs
  }

  const formatPrerollConfig = (prerollConfig: any, key: string): PrerollConfig => {
    const formattedConfig: any = {}

    formattedConfig.videoType = key
    formattedConfig.billboardDisplayMaxCount = parseInt(prerollConfig.billboardDisplayMaxCount)
    formattedConfig.adDisplayMaxCount = parseInt(prerollConfig.adDisplayMaxCount)

    return formattedConfig
  }

  useEffect(() => {
    setLoading(isCreateLoading || isUpdateLoading)
  }, [isCreateLoading, isUpdateLoading])

  const onSubmit = async (values: any) => {
    const prerollConfigs: PrerollConfig[] = Object.keys(values.prerollConfigs).map(key =>
      formatPrerollConfig(values.prerollConfigs[key], key),
    )
    if (organismId) {
      const finalValues = {
        title: values.title,
        publicationStatus: values.publicationStatus,
        displayedItemsCountMax: parseInt(values.displayedItemsCountMax),
        affiliates: fields,
        organismId: organismIdFromProps || organismId,
        ...{ id: values.id ? values.id : undefined },
        prerollConfigs,
      }
      if (adCampaign) {
        const result = await updateAdCampaign({
          variables: {
            input: finalValues,
          },
        })

        if (result && result.data) {
          notifySuccess(
            intl.formatMessage({
              id: 'adCampaign.update_success',
            }),
          )
          if (onDone) onDone()
        }
      } else if (action === 'create') {
        const result = await createAdCampaign({
          variables: {
            input: finalValues,
          },
        }).catch(error => {
          notifyError(getErrorMessage(error)?.replace('%{id}', organismId))
        })

        if (result && result.data) {
          notifySuccess(
            intl.formatMessage({
              id: 'adCampaign.create_success',
            }),
          )
          if (onDone) onDone()
        }
      }
    } else {
      notifyError('Organism ID is missing')
    }
  }

  return (
    <Form
      onSubmit={onSubmit}
      mutators={{
        ...arrayMutators,
      }}
      initialValues={initialValues}
      render={formProps => {
        const { handleSubmit, form, pristine } = formProps
        return (
          <>
            <Modal.Header>
              <FormattedMessage id="adCampaign" />
            </Modal.Header>
            <Modal.Content>
              <Dimmer.Dimmable dimmed={isLoading}>
                <Dimmer active={isLoading} inverted />
                <AdCampaignFormFields fields={fields} onChangeFields={setFields} />
              </Dimmer.Dimmable>
            </Modal.Content>
            <Modal.Actions>
              <CancelFormButton pristine={pristine} onConfirm={form.reset} onCancel={onCancel} />
              <Button positive loading={isLoading} onClick={() => handleSubmit()}>
                <FormattedMessage id="common.submit" />
              </Button>
            </Modal.Actions>
          </>
        )
      }}
    />
  )
}

export default AdCampaignForm
