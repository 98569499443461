import { gql } from '@apollo/client'

import { CONTENT_TRANSLATION_FRAGMENT } from './ContentTranslation'
import { ORGANISM_FRAGMENT } from './Organism'

/**
 * Links
 */
export const LINKITEM_FRAGMENT = gql`
  fragment linkItemContent on Link {
    __typename
    id
    ownerOrganismIds
    ownerOrganisms {
      ...organismContent
    }
    tag
    title
    titleT {
      ...contentTranslationContent
    }
    subtitle
    subtitleT {
      ...contentTranslationContent
    }
    description
    descriptionT {
      ...contentTranslationContent
    }
    defaultLocale
    clicks
    uniqueClicks
    views
    countries
    uniqueViews
    teaserVideoId
    gamTagUrl
    billboardId
    billboard {
      id
      videoClip {
        id
        playbackUrl
      }
    }
    teaserVideo {
      id
      videoClip {
        id
        playbackUrl
      }
    }
    url
    imageId
    image {
      id
      image {
        id
        thumbUrl
        downloadUrl
      }
    }
    categoriesIds
    categories {
      id
      title
    }
    publicationStatus
  }
  ${ORGANISM_FRAGMENT}
  ${CONTENT_TRANSLATION_FRAGMENT}
`

export const CREATE_LINK_MUTATION = gql`
  mutation createLink($input: CreateLinkInput!) {
    createLink(input: $input) {
      ...linkItemContent
    }
  }
  ${LINKITEM_FRAGMENT}
`
export const UPDATE_LINK_MUTATION = gql`
  mutation updateLink($input: UpdateLinkInput!) {
    updateLink(input: $input) {
      ...linkItemContent
    }
  }
  ${LINKITEM_FRAGMENT}
`

export const DELETE_LINK_MUTATION = gql`
  mutation deleteLink($linkId: ID!) {
    deleteLink(id: $linkId) {
      id
    }
  }
`

export const GET_LINKS_QUERY = gql`
  query getLinks($filters: LinkFilters, $offset: Int, $first: Int, $orderBy: LinkSort) {
    links(filters: $filters, offset: $offset, first: $first, orderBy: $orderBy) {
      links {
        ...linkItemContent
      }
      totalCount
    }
  }
  ${LINKITEM_FRAGMENT}
`

export const GET_LINK_QUERY = gql`
  query link($linkId: ID!) {
    link(id: $linkId) {
      ...linkItemContent
    }
  }
  ${LINKITEM_FRAGMENT}
`
