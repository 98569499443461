import { ResultOf } from 'gql.tada'
import _ from 'lodash'
import React, { useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { Dropdown, Menu } from 'semantic-ui-react'
import { Maybe, Organism } from 'services/api/graphql'
import { OrganismTitleFragment } from 'stores/fragments'
import styled from 'styled-components'

const Container = styled.div`
  min-width: 20rem;

  .dropdown.icon {
    margin-left: auto !important;
  }
`
// semantic-ui just doesn't want to cooperate, so I had to use the little cheat above

export interface OrganismPickerBaseProps {
  inverted?: boolean
  defaultValue?: Organism['id']
  noSelectionEnabled?: boolean
  noSelectionTitle?: string
}
export interface OrganismPickerProps extends OrganismPickerBaseProps {
  organisms: ResultOf<typeof OrganismTitleFragment>[]
  value: Organism['id'] | undefined | null
  onChange: (value: Maybe<Organism['id']>) => void
}
const ___NO_SELECTION___ = '___NO_SELECTION___'

const OrganismPicker = ({
  organisms,
  value,
  onChange,
  defaultValue,
  noSelectionEnabled,
  noSelectionTitle,
  ...props
}: OrganismPickerProps) => {
  const intl = useIntl()

  noSelectionTitle = noSelectionTitle || intl.formatMessage({ id: 'common.none' })

  const options = useMemo(() => {
    const finalOptions = []
    if (noSelectionEnabled) {
      finalOptions.push({
        key: ___NO_SELECTION___,
        value: ___NO_SELECTION___,
        text: noSelectionTitle,
      })
    }
    const organismsOptions = organisms
      ? _.map(organisms, organism => ({
          key: organism?.id,
          value: organism?.id,
          text: organism?.title,
        }))
      : []
    return _.concat(finalOptions, organismsOptions)
  }, [organisms, noSelectionEnabled, noSelectionTitle])

  const handleOnChange = useCallback(
    (event, data) => {
      const val = (data.value !== ___NO_SELECTION___ ? data.value : null) as Maybe<Organism['id']>
      onChange(val)
    },
    [onChange],
  )
  const currentOption = _.find(options, {
    value: value || defaultValue || ___NO_SELECTION___,
  })
  const title = useMemo(() => {
    if (options.length === 0) {
      return intl.formatMessage({ id: 'organisms.noneAvailable' })
    }
    return (
      (currentOption && currentOption.text) ||
      (noSelectionEnabled ? noSelectionTitle : intl.formatMessage({ id: 'organisms.select' }))
    )
  }, [options.length, currentOption, noSelectionEnabled, noSelectionTitle, intl])
  return (
    <Menu compact {...props}>
      <Container>
        <Dropdown
          search
          text={title}
          value={currentOption && currentOption.key}
          options={options}
          onChange={handleOnChange}
          item
        />
      </Container>
    </Menu>
  )
}

export default OrganismPicker
