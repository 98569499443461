import { ApolloClient } from '@apollo/client'
import { cast, Instance, types } from 'mobx-state-tree'
import { LIST_TEMPLATES_QUERY, QueryListTemplatesArgs, WmTemplate, WmTemplateType } from 'services/api/graphql'

const TemplateModel = types.model('Template', {
  id: types.string,
  name: types.string,
  type: types.string,
})

export const TemplatesModel = types
  .model('Templates', {
    list: types.maybeNull(types.array(TemplateModel)),
    loading: types.boolean,
  })
  .views(self => ({
    get video(): WmTemplate[] {
      return ((self.list && self.list.filter(template => template.type === WmTemplateType.Video)) || []) as WmTemplate[]
    },
    get live(): WmTemplate[] {
      return ((self.list && self.list.filter(template => template.type === WmTemplateType.Live)) || []) as WmTemplate[]
    },
  }))
  .actions(self => ({
    _setTemplates: (templates: WmTemplate[]) => {
      self.loading = false
      self.list = cast(templates)
    },
  }))
  .actions(self => ({
    fetchTemplates: async (client: ApolloClient<unknown>, organismId?: string) => {
      self.loading = true
      const result = await client.query<{ listTemplates: WmTemplate[] }, QueryListTemplatesArgs>({
        query: LIST_TEMPLATES_QUERY,
        variables: organismId ? { organismId } : {},
        fetchPolicy: 'network-only',
      })
      self._setTemplates(result.data.listTemplates)
    },
  }))

export type Templates = Instance<typeof TemplatesModel>
