import styled, { keyframes } from 'styled-components'

const blink = keyframes`
  0% {
    opacity: 100%;
  }
  50% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
`

export default styled.div`
  display: inline-block;
  animation: ${blink} 2s linear infinite;
`
