import GenericForm from 'Components/Form/GenericForm'
import SelectItemListInput from 'Components/Form/SelectItemListInput'
import { FormApi } from 'final-form'
import { SportItemSet, useGetSportItems } from 'models'

import React, { useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { LegalRestrictionFormValue } from './LegalRestrictionForm'

interface AddSportItemsModalProps {
  sportItemSet: SportItemSet
  initialValues: LegalRestrictionFormValue | undefined
  onSubmit: () => void
  onCancel: () => void
  form: FormApi<LegalRestrictionFormValue, Partial<LegalRestrictionFormValue>>
}

const AddSportItemsModal: React.FC<AddSportItemsModalProps> = ({
  sportItemSet,
  initialValues,
  onCancel,
  onSubmit,
  form,
}) => {
  const intl = useIntl()

  const { data: sportItemsData, loading: loadingSportItems } = useGetSportItems(sportItemSet.id, {
    fetchPolicy: 'cache-and-network',
  })
  const sportItems = useMemo(() => sportItemsData && sportItemsData.sportItems.sportItems, [sportItemsData])
  const [selectedSportItemIds, setSelectedSportItemIds] = useState(initialValues?.sportItemIds)

  const options = useMemo(() => {
    return sportItems
      ? sportItems.map(sportItem => ({
          key: sportItem.id,
          value: sportItem.id,
          text: sportItem.title,
        }))
      : []
  }, [sportItems])

  return (
    <GenericForm
      header={intl.formatMessage(
        {
          id: 'legalRestrictions.addSportItemSetWithItems.title',
        },
        { sportItemSetName: sportItemSet.title },
      )}
      onCancel={onCancel}
      onSubmit={() => {
        form.change('sportItemIds', selectedSportItemIds)
        onSubmit()
      }}
      loading={false}
    >
      {() => {
        return (
          <div className="flex flex-col" style={{ minHeight: '25vh' }}>
            <SelectItemListInput
              className="w-full"
              value={selectedSportItemIds}
              disabled={loadingSportItems}
              multiple
              options={options}
              onChange={values => {
                if (values.length === 0 && initialValues?.sportItemIds) {
                  setSelectedSportItemIds(
                    initialValues.sportItemIds?.filter(id => !sportItems?.map(el => el.id)?.includes(id)),
                  )
                  return
                }
                setSelectedSportItemIds(values)
              }}
            />
            <span className="italic text-gray-600 mt-4">
              <FormattedMessage id="legalRestrictions.addSportItemSetWithItems.warningTips" />
            </span>
          </div>
        )
      }}
    </GenericForm>
  )
}

export default AddSportItemsModal
