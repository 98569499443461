import { applicationUris } from 'Layout/uris'
import {
  ExternalFeedType,
  SportItem,
  useCreateExternalFeedMediaLiveStreamMutation,
  useCreateSveMediaLiveStreamMutation,
  useCreateWildmokaMediaLiveStreamMutation,
  VideoEngine,
} from 'models'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { notifySuccess, notifyError } from 'tools/toaster'
import { getTranslationInputFromValues } from 'tools/translationTools'

import { CreateMediaLiveStreamOptions } from './CreateSportItemForm'
import { SportItemFormValues } from './SportItemForm'

export const getSportItemFormValuesFromSportItem: (sportItem: SportItem) => SportItemFormValues = sportItem => {
  const {
    sportItemSet: _sportItemSet,
    medias: _medias,
    coverImage: _coverImage,
    sportItemSetId: _sportItemSetId,
    cac,
    contentPushServiceNamesExcluded,
    modeRadio,
    ...formValues
  } = sportItem

  return {
    ...getTranslationInputFromValues<Omit<SportItem, 'medias' | 'sportItemSetId'>>(formValues),
    sportEventItem: sportItem.sportEventItem
      ? {
          ...sportItem.sportEventItem,
          passthrough: false,
          testDate: sportItem.sportEventItem?.testDate || new Date(),
          startDate: sportItem.sportEventItem?.startDate || new Date(),
          stopDate: sportItem.sportEventItem?.stopDate || new Date(),
        }
      : null,
    reverseRestriction: sportItem.reverseRestriction || undefined,
    cac: {
      userAuthenticationRule: cac?.userAuthenticationRule || undefined,
    },
    contentPushServiceNamesExcluded: contentPushServiceNamesExcluded || undefined,
    modeRadio: modeRadio || false,
  }
}

export const useHandleCreateMediaLive = () => {
  const intl = useIntl()
  const navigate = useNavigate()

  const [loading, setLoading] = useState<boolean>(false)
  const [createWildmokaLiveStream, { loading: loadingCreateLiveStream }] = useCreateWildmokaMediaLiveStreamMutation()
  const [createSVELiveStream, { loading: loadingCreateSVELiveStream }] = useCreateSveMediaLiveStreamMutation()
  const [createExternalFeedLiveStream, { loading: loadingCreateExternalFeedLiveStream }] =
    useCreateExternalFeedMediaLiveStreamMutation()

  const isLoading =
    loading || loadingCreateLiveStream || loadingCreateSVELiveStream || loadingCreateExternalFeedLiveStream

  const handleCreateMediaLive = async (
    sportItemId: string,
    creationProps: CreateMediaLiveStreamOptions,
    sportItemSetWmClippingTemplateId?: string | null,
  ) => {
    try {
      switch (creationProps.videoEngine) {
        case VideoEngine.Sportall: {
          const { title, sveChannelId } = creationProps
          setLoading(true)
          if (sveChannelId !== '') {
            await createSVELiveStream({
              variables: {
                input: {
                  media: {
                    title: title || '',
                    tags: [],
                    sportItemId,
                  },
                  sveChannelId,
                  wmClippingTemplateId: sportItemSetWmClippingTemplateId || undefined,
                },
              },
            })
          }
          break
        }
        case VideoEngine.Wildmoka: {
          const { videoEngine: _, title, ...live } = creationProps
          await createWildmokaLiveStream({
            variables: {
              input: {
                media: {
                  title: title || '',
                  tags: [],
                  sportItemId,
                },
                ...live,
                templateLive: live.templateLive || '',
                stream: live.stream || '',
                autoAnnounceDate: live.autoAnnounceDate || undefined,
              },
            },
          })
          break
        }
        case VideoEngine.ExternalFeed: {
          const { videoEngine: _, title, ...live } = creationProps
          await createExternalFeedLiveStream({
            variables: {
              input: {
                media: {
                  title: title || '',
                  tags: [],
                  sportItemId,
                },
                externalFeedProvider: live.externalFeedProvider || ExternalFeedType.Youtube,
                externalFeedUrl: live.externalFeedUrl || '',
              },
            },
          })
          break
        }
      }

      notifySuccess(intl.formatMessage({ id: 'sportItem.media_created' }))

      if ((creationProps.videoEngine === VideoEngine.Sportall && creationProps.sveChannelId === '') || !creationProps)
        return
      // Rediriger vers la page des médias
      setTimeout(() => navigate(applicationUris.sportItemMedias(sportItemId)), 500)
      setLoading(false)
    } catch (e) {
      notifyError(intl.formatMessage({ id: 'sportItem.create_media_error' }), e as Error)
    }
  }

  return { handleCreateMediaLive, isLoading }
}
