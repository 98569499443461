import FileDetailsField from 'Components/FileDetailsField'
import React, { Fragment } from 'react'
import { useIntl } from 'react-intl'
import { Grid } from 'semantic-ui-react'
import { Media } from 'services/api/graphql'
import styled from 'styled-components'
import { format } from 'tools/date'

const Container = styled.div`
  display: flex;
  width: 400px;
  background-color: #dddddd;
  overflow: auto;
`
const Fields = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

interface MediaDetailsSidebarProps {
  media: Media | undefined | null
  children?: React.ReactNode
}

const MediaDetailsSidebar = ({ media, children }: MediaDetailsSidebarProps) => {
  const intl = useIntl()
  return (
    <Container>
      <Grid padded style={{ width: '100%' }}>
        <Grid.Column>
          <Fields>
            {children}

            {media && (
              <>
                <FileDetailsField label={intl.formatMessage({ id: 'media.name' })} content={media && media.title} />
                <FileDetailsField label={intl.formatMessage({ id: 'media.type' })} content={media && `${media.type}`} />

                <FileDetailsField
                  label={intl.formatMessage({ id: 'media.createdAt' })}
                  content={media && `${format(media.createdAt, 'DD MMMM YYYY HH:mm', intl.locale)}`}
                />
                {media.videoClip && (
                  <>
                    <FileDetailsField
                      label={intl.formatMessage({ id: 'media.playbackUrl' })}
                      content={media && media.videoClip && media.videoClip.playbackUrl}
                    />
                    <FileDetailsField
                      label={intl.formatMessage({ id: 'medias.translations' })}
                      content={
                        media.videoClip.captions?.length
                          ? media.videoClip.captions
                              .map(caption => `${caption.language}`)
                              .join(', ')
                              .toString()
                          : intl.formatMessage({ id: 'common.none' })
                      }
                    />
                  </>
                )}
                {media.image && (
                  <>
                    <FileDetailsField
                      label={intl.formatMessage({
                        id: 'media.mimetype',
                      })}
                      content={media && `${media.image.mimetype}`}
                    />
                    <FileDetailsField
                      label={intl.formatMessage({
                        id: 'media.size',
                      })}
                      content={media && `${media.image.size}`}
                    />
                    {media.image.metadata && (
                      <Fragment>
                        {media.image.metadata.title && (
                          <FileDetailsField
                            label={intl.formatMessage({ id: 'media.title' })}
                            content={media.image.metadata.title}
                          />
                        )}
                        {media.image.metadata.description && (
                          <FileDetailsField
                            label={intl.formatMessage({
                              id: 'media.description',
                            })}
                            content={media.image.metadata.description}
                          />
                        )}
                        {media.image.metadata.width && media.image.metadata.height && (
                          <FileDetailsField
                            label={intl.formatMessage({
                              id: 'media.dimensions',
                            })}
                            content={`${media.image.metadata.width} x ${media.image.metadata.height}`}
                          />
                        )}
                      </Fragment>
                    )}
                  </>
                )}
              </>
            )}
          </Fields>
        </Grid.Column>
      </Grid>
    </Container>
  )
}
export default MediaDetailsSidebar
