import { gql } from '@apollo/client'

import { CONTENT_TRANSLATION_FRAGMENT } from './ContentTranslation'

export const CUSTOM_PAGE_WIDGET_FRAGMENT = gql`
  fragment customPageWidget on CustomPageWidget {
    name
    position
    display
    contentDisplay
    cardsDisplay
    options {
      editedCategoryId
      sportItemSetType
      replays {
        nameT {
          ...contentTranslationContent
        }
        defaultLocale
        maxReplays
        depthInDays
      }
      externalContent {
        name
        nameT {
          locale
          content
        }
        defaultLocale
        maxItemsCount
        period {
          startDate
          endDate
        }
        source
        wecContent {
          teamECMIds
          driverECMIds
          carECMIds
          categoryECMIds
          raceECMIds
        }
        rssContent {
          feedUrl
        }
      }
    }
  }
  ${CONTENT_TRANSLATION_FRAGMENT}
`

const CUSTOM_PAGE_FRAGMENT = gql`
  fragment customPageContent on CustomPage {
    id
    title
    titleT {
      ...contentTranslationContent
    }
    defaultLocale
    iconId
    isHomePage
    published
    widgetsType
    widgets {
      ...customPageWidget
    }
    externalWidgets {
      liveScoreAccount
      iframeUrl
      linkUrl
    }
    applicationId
    position
    backgroundVideoId
    backgroundImageId
    menuBannerImageId
    showInNavigationHeader
    customPageApplicationDesignId
    frontPageId
  }
  ${CUSTOM_PAGE_WIDGET_FRAGMENT}
  ${CONTENT_TRANSLATION_FRAGMENT}
`
export const GET_CUSTOM_PAGE_QUERY = gql`
  query customPage($customPageId: ID!) {
    customPage(id: $customPageId) {
      ...customPageContent
    }
  }
  ${CUSTOM_PAGE_FRAGMENT}
`

export const GET_CUSTOM_PAGES_QUERY = gql`
  query customPages($filters: CustomPageFilters) {
    customPages(filters: $filters) {
      customPages {
        ...customPageContent
      }
      totalCount
    }
  }
  ${CUSTOM_PAGE_FRAGMENT}
`

export const CREATE_CUSTOM_PAGE_MUTATION = gql`
  mutation createCustomPage($input: CreateCustomPageInput!) {
    createCustomPage(input: $input) {
      ...customPageContent
    }
  }
  ${CUSTOM_PAGE_FRAGMENT}
`

export const UPDATE_CUSTOM_PAGE_MUTATION = gql`
  mutation updateCustomPage($input: UpdateCustomPageInput!) {
    updateCustomPage(input: $input) {
      ...customPageContent
    }
  }
  ${CUSTOM_PAGE_FRAGMENT}
`

export const DELETE_CUSTOM_PAGE_MUTATION = gql`
  mutation deleteCustomPage($id: ID!) {
    deleteCustomPage(id: $id) {
      id
    }
  }
`

export const MOVE_CUSTOM_PAGE_MUTATION = gql`
  mutation moveCustomPage($input: MoveCustomPageInput!) {
    moveCustomPage(input: $input) {
      id
      position
    }
  }
`

export const SET_HOMEPAGE_MUTATION = gql`
  mutation setHomePage($id: ID!) {
    setHomePage(id: $id) {
      id
    }
  }
`
