import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Button, Icon } from 'semantic-ui-react'
import { useCSVUsers } from 'services/api'
import { UserFilters } from 'services/api/graphql'

export interface UserCsvButtonProps {
  filters: UserFilters
  fansView?: boolean
}

const UserCsvButton: React.FC<UserCsvButtonProps> = ({ filters, fansView }) => {
  const { csvUrl, generateCsv, csvLoading, csvGenerated, progress: csvProgress } = useCSVUsers(!!fansView, filters)

  return (
    <>
      {!csvGenerated && (
        <Button
          primary
          onClick={generateCsv}
          loading={csvLoading}
          label={csvLoading ? `${Math.floor(csvProgress * 100)}%` : undefined}
        >
          <FormattedMessage id="users.generateCsv" />
        </Button>
      )}
      {csvGenerated && (
        <Button as="a" href={csvUrl} download={`users-export-${new Date().getTime()}.csv`} primary disabled={!csvUrl}>
          <Icon name="download" />
          <FormattedMessage id="users.export" />
        </Button>
      )}
    </>
  )
}

export default UserCsvButton
