import SelectItemListInput from 'Components/Form/SelectItemListInput'
import { PurchasableOffer, useGetOrganismOffers } from 'models'
import React, { useEffect, useMemo } from 'react'

export interface SelectOffersInputProps {
  value?: string | string[]
  onChange: (value?: string | string[]) => void
  organismId: string
  noSelectionEnabled?: boolean
  noSelectionTitle?: string
  multiple?: boolean
  disabled?: boolean
  className?: string
  getOffers?: (items: PurchasableOffer[]) => void
  selectedOffers?: PurchasableOffer[] | null
}

const SelectOffersInput = ({
  value,
  onChange,
  noSelectionEnabled,
  noSelectionTitle,
  organismId,
  multiple,
  disabled,
  className,
  getOffers,
  selectedOffers,
}: SelectOffersInputProps) => {
  const { offers: organismOffers, loading } = useGetOrganismOffers(organismId ?? undefined, {
    skip: !organismId,
    fetchPolicy: 'cache-and-network',
  })

  const options = useMemo(() => {
    if (!organismOffers) return []
    if (selectedOffers) {
      // Allow to remove items in options for a single add input
      return organismOffers
        .filter(item => !selectedOffers.find(offer => offer.id === item.id))
        .map((item: PurchasableOffer) => ({
          key: item.id,
          value: item.id,
          text: item.title,
        }))
    }

    return organismOffers.map((offer: PurchasableOffer) => ({
      key: offer.id,
      value: offer.id,
      text: offer.title,
    }))
  }, [organismOffers, selectedOffers])

  useEffect(() => {
    getOffers && organismOffers && getOffers(organismOffers)
  }, [getOffers, organismOffers])

  return (
    <SelectItemListInput
      className={className}
      value={value}
      loading={loading}
      disabled={loading || disabled}
      multiple={multiple}
      options={options}
      noSelectionEnabled={noSelectionEnabled}
      noSelectionTitle={noSelectionTitle}
      onChange={onChange}
    />
  )
}

export default SelectOffersInput
