import axios, { AxiosError } from 'axios'
import Config from 'config'

import { User } from 'services/api/graphql'
import I18nError from 'tools/I18nError'

export type LoginResult = {
  token: string
  user: User | undefined
}

type LoginResponse = {
  token: string
  user: User | undefined
}

export const login = async (email: string, password: string): Promise<LoginResult> => {
  try {
    const result = await axios.post<LoginResponse>(`${Config.apiUrl}/auth/signin`, {
      email,
      password,
    })
    return {
      token: result.data.token,
      user: result.data.user,
    }
  } catch (error) {
    const axiosError = error as AxiosError<string>
    if (axiosError.isAxiosError) {
      if (axiosError.response) {
        switch (axiosError.response.status) {
          case 400: {
            throw new I18nError(axiosError.response.data, 'login.invalid')
          }
          case 401: {
            throw new Error(axiosError.response.data)
          }
        }
      }
    }
    throw error
  }
}

export const forgotPassword = async (email: string): Promise<string> => {
  try {
    const result = await axios.post<string>(`${Config.apiUrl}/auth/forgotPassword`, {
      email,
    })
    return result.data
  } catch (error) {
    throw error
  }
}

export const checkTokenReset = async (token: string): Promise<boolean> => {
  try {
    await axios.get(`${Config.apiUrl}/auth/resetPassword`, {
      params: {
        token,
      },
    })
    return true
  } catch (error) {
    const axiosError = error as AxiosError<LoginResponse>
    if (axiosError.isAxiosError) {
      if (axiosError.response && axiosError.response.status === 401) {
        return false
      }
    }
    throw error
  }
}

interface ResetPasswordResponse {
  user: User
}

export const resetPassword = async (token: string, password: string): Promise<ResetPasswordResponse> => {
  try {
    const result = await axios.post<ResetPasswordResponse>(`${Config.apiUrl}/auth/resetPassword`, {
      token,
      password,
    })
    return result.data
  } catch (error) {
    throw error
  }
}

export const logout = async (authToken: string): Promise<void> => {
  const authAxios = axios.create({
    baseURL: Config.apiUrl,
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  })
  try {
    await authAxios.post(`${Config.apiUrl}/auth/signout`)
  } catch (error) {
    throw error
  }
}

interface VerifyAccountResponse {
  user: User
}

export const verifyAccount = async (token: string): Promise<VerifyAccountResponse> => {
  try {
    const result = await axios.post<VerifyAccountResponse>(`${Config.apiUrl}/auth/verifyAccount`, {
      token,
    })
    return result.data
  } catch (error) {
    throw error
  }
}
