import SwitchInput from 'Components/Form/SwitchInput'
import React from 'react'

import { IntlShape, useIntl } from 'react-intl'
import { PublicationStatus } from 'services/api/graphql'

export interface SelectPublicationStatusInputProps {
  value: PublicationStatus | undefined
  onChange: (value: PublicationStatus | undefined) => void
}

const options = (intl: IntlShape) => [
  {
    key: PublicationStatus.Draft,
    value: PublicationStatus.Draft,
    text: intl.formatMessage({ id: 'publicationStatus.draft' }),
  },
  {
    key: PublicationStatus.Published,
    value: PublicationStatus.Published,
    text: intl.formatMessage({ id: 'publicationStatus.published' }),
  },
]

const SelectPublicationStatusInput = ({ value, onChange }: SelectPublicationStatusInputProps) => {
  const intl = useIntl()
  return <SwitchInput value={value} onChange={onChange} options={options(intl)} />
}

export default SelectPublicationStatusInput
