import { observer } from 'mobx-react-lite'
import React, { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useCreateSveMediaLiveStreamMutation } from 'services/api/graphql'
import { notifyError, notifySuccess } from 'tools/toaster'

import { SVEMediaLiveForm, SVEMediaLiveFormData, SVEMediaLiveFormProps } from './SVEMediaLiveForm'

export interface CreateSVEMediaLiveFormProps
  extends Omit<SVEMediaLiveFormProps, 'onSubmit' | 'onCancel' | 'initialValues' | 'mode'> {
  onDone: () => void
  onCancel: () => void
}

/**
 * CreateSVEMediaLiveForm: Formulaire d'édition de propriétés propres à un channel Sportall
 * Champs:
 *  - media title "Nom"
 *  - live stream SVE channel id
 */
export const CreateSVEMediaLiveForm: React.FC<CreateSVEMediaLiveFormProps> = observer(
  ({ onDone, sportItemId, ...props }) => {
    const intl = useIntl()

    const [createSVEMediaLiveStream, { loading }] = useCreateSveMediaLiveStreamMutation()
    const initialValues: Partial<SVEMediaLiveFormData> = {}
    const handleSubmit = useCallback(
      async (values: SVEMediaLiveFormData) => {
        try {
          await createSVEMediaLiveStream({
            variables: {
              input: {
                media: {
                  title: values.title,
                  sportItemId,
                },
                sveChannelId: values.sveChannelId,
                wmClippingTemplateId: values.wmClippingTemplateId,
              },
            },
          })
          notifySuccess(intl.formatMessage({ id: 'sportItem.media_updated' }))
          onDone()
        } catch (e) {
          notifyError(
            intl.formatMessage({
              id: `sportItem.create_media_error`,
            }),
          )
        }
      },
      [createSVEMediaLiveStream, intl, onDone, sportItemId],
    )
    return (
      <SVEMediaLiveForm
        onSubmit={handleSubmit}
        initialValues={initialValues}
        createMediaLoading={loading}
        mode="create"
        sportItemId={sportItemId}
        {...props}
      />
    )
  },
)
/*
// Agregration pour récupérer tous les livestreams liés à un sportitem, qui ont un sveChannelId correspondant à 
// un de ceux du sportitemset lié (dans ses sveStreams)
[
  {
    '$match': {
      'sportItemSetId': new ObjectId('607db097ffb0c87d073a6d35')
    }
  }, {
    '$lookup': {
      'from': 'medias', 
      'localField': 'mediasIds', 
      'foreignField': '_id', 
      'as': 'medias'
    }
  }, {
    '$unwind': {
      'path': '$medias'
    }
  }, {
    '$match': {
      'medias.type': 'liveStream'
    }
  }, {
    '$lookup': {
      'from': 'livestreams', 
      'localField': 'medias.liveStreamId', 
      'foreignField': '_id', 
      'as': 'liveStream'
    }
  }, {
    '$unwind': {
      'path': '$liveStream'
    }
  }, {
    '$match': {
      'liveStream.sveChannelId': {
        '$in': [
          'my-channel-id'
        ]
      }
    }
  }
]
*/
