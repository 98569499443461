import { gql } from '@apollo/client'

export const AD_CAMPAIGN_FRAGMENT = gql`
  fragment adCampaignContent on AdCampaign {
    publicationStatus
    prerollConfigs {
      videoType
      adDisplayMaxCount
      billboardDisplayMaxCount
    }
    organismId
    displayedItemsCountMax
    affiliates {
      linkId
      displayWeight
      displayTypes
      type
    }
    title
    id
  }
`

export const GET_AD_CAMPAIGN_QUERY = gql`
  query adCampaigns($first: Int, $offset: Int, $filters: AdCampaignFilters) {
    adCampaigns(first: $first, offset: $offset, filters: $filters) {
      adCampaigns {
        ...adCampaignContent
      }
      totalCount
    }
  }
  ${AD_CAMPAIGN_FRAGMENT}
`

export const CREATE_AD_CAMPAIGN_MUTATION = gql`
  mutation createAdCampaign($input: CreateAdCampaignInput!) {
    createAdCampaign(input: $input) {
      ...adCampaignContent
    }
  }
  ${AD_CAMPAIGN_FRAGMENT}
`

export const UPDATE_AD_CAMPAIGN_MUTATION = gql`
  mutation updateAdCampaign($input: UpdateAdCampaignInput!) {
    updateAdCampaign(input: $input) {
      ...adCampaignContent
    }
  }
  ${AD_CAMPAIGN_FRAGMENT}
`

export const DELETE_AD_CAMPAIGN_MUTATION = gql`
  mutation deleteAdCampaign($id: ID!) {
    deleteAdCampaign(id: $id) {
      id
    }
  }
`
