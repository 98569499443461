import { QueryHookOptions, useMutation, useQuery } from '@apollo/client'
import {
  CREATE_LEGAL_RESTRICTION_MUTATION,
  DELETE_LEGAL_RESTRICTION_MUTATION,
  LegalRestriction,
  LIST_LEGAL_RESTRICTIONS_QUERY,
  MutationCreateLegalRestrictionArgs,
  MutationUpdateLegalRestrictionArgs,
  UPDATE_LEGAL_RESTRICTION_MUTATION,
} from 'services/api/graphql'

interface QueryLegalRestrictionsResult {
  legalRestrictions: LegalRestriction[]
}
export const useGetLegalRestrictions = (options?: QueryHookOptions<QueryLegalRestrictionsResult>) => {
  const { data, ...query } = useQuery<QueryLegalRestrictionsResult>(LIST_LEGAL_RESTRICTIONS_QUERY, { ...options })

  return { legalRestrictions: data && data.legalRestrictions, ...query }
}

export const useCreateLegalRestriction = () =>
  useMutation<{ createLegalRestriction: LegalRestriction }, MutationCreateLegalRestrictionArgs>(
    CREATE_LEGAL_RESTRICTION_MUTATION,
  )

export const useUpdateLegalRestriction = () =>
  useMutation<{ updateLegalRestriction: LegalRestriction }, MutationUpdateLegalRestrictionArgs>(
    UPDATE_LEGAL_RESTRICTION_MUTATION,
  )

export const useDeleteLegalRestriction = () =>
  useMutation<{ deleteLegalRestriction: boolean }, { id: string }>(DELETE_LEGAL_RESTRICTION_MUTATION)
