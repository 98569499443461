import CancelFormButton from 'Components/Form/CancelFormButton'
import { SelectedTranslationsType } from 'Components/Form/SelectLocalInput'
import { Content } from 'antd/lib/layout/layout'
import Config from 'config'
import { ValidationErrors } from 'final-form'
import arrayMutators from 'final-form-arrays'
import { useCreateCustomPage, useUpdateCustomPage } from 'models'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button, Dimmer } from 'semantic-ui-react'
import { CreateCustomPageInput, CustomPage, CustomPageWidget, WidgetsType } from 'services/api/graphql'
import { getErrorMessage } from 'tools/graphql'
import { notifyError, notifySuccess } from 'tools/toaster'

import {
  getSelectedTranslations,
  getTranslationInputFromForm,
  getTranslationInputFromValues,
  validateTranslation,
} from 'tools/translationTools'

import CustomPageFormFields, { CustomPagesFormValues } from './CustomPageFormFields'

interface CustomPageFormProps {
  customPage?: CustomPage
  applicationId?: string
  onCancel: () => void
  onDone?: () => void
}

const CustomPageForm = ({ customPage, applicationId, onCancel, onDone }: CustomPageFormProps) => {
  const intl = useIntl()

  const [updateCustomPage, { loading: isUpdateLoading }] = useUpdateCustomPage()
  const [createCustomPage, { loading: isCreateLoading }] = useCreateCustomPage()
  const [isLoading, setLoading] = useState(false)
  const initialValues = useMemo(
    () => (customPage ? getTranslationInputFromValues<CustomPage>(customPage) : { widgetsType: WidgetsType.Internal }),
    [customPage],
  ) as CustomPagesFormValues

  const [selectedTranslations, setSelectedTranslations] = useState<SelectedTranslationsType[]>(
    initialValues ? getSelectedTranslations(initialValues) : [],
  )
  const [defaultLocale, setDefaultLocale] = useState<string>(
    initialValues?.defaultLocale || Config.availableLocalesOptions[0].value,
  )
  const [activeTranslation, setActiveTranslation] = useState<string | undefined>(defaultLocale)

  useEffect(() => {
    setLoading(isCreateLoading || isUpdateLoading)
  }, [isCreateLoading, isUpdateLoading])

  const onSubmit = async ({ defaultLocale: _defaultLocale, ...values }: CustomPagesFormValues) => {
    // Removes empty widgets array + Add position
    values.widgets =
      (values.widgets &&
        values.widgets
          .filter((widget: CustomPageWidget) => !!widget)
          .map((widget: CustomPageWidget, index: number) => ({
            ...widget,
            position: index, // Insert position  based on array orders
          }))) ||
      []

    values.iconId = values.iconId ?? null
    values.backgroundVideoId = values.backgroundVideoId ?? null
    values.backgroundImageId = values.backgroundImageId ?? null

    delete values.isHomePage

    switch (values.widgetsType) {
      case WidgetsType.Internal:
        values.externalWidgets = null
        break
      case WidgetsType.External:
        if (values.externalWidgets) {
          values.externalWidgets.linkUrl = null
          values.externalWidgets.iframeUrl = null
        }
        values.widgets = null
        break
      case WidgetsType.ExternalIframe:
        if (values.externalWidgets) {
          values.externalWidgets.linkUrl = null
          values.externalWidgets.liveScoreAccount = null
        }
        values.widgets = null
        break
      case WidgetsType.ExternalLink:
        if (values.externalWidgets) {
          values.externalWidgets.liveScoreAccount = null
          values.externalWidgets.iframeUrl = null
        }
        values.widgets = null
        break
      default:
        values.widgets = null
        break
    }
    if (customPage) {
      // Delete unwanted values
      delete values.applicationId

      const result = await updateCustomPage({
        variables: {
          input: {
            ...values,
            id: values.id as string,
            titleT: getTranslationInputFromForm(selectedTranslations, values.titleT),
            title: values.titleT?.[defaultLocale] || '',
          },
        },
      })

      if (result && result.data) {
        notifySuccess(
          intl.formatMessage({
            id: 'customPage.update_success',
          }),
        )
        if (onDone) onDone()
      }
    } else if (applicationId) {
      values.applicationId = applicationId

      createCustomPage({
        variables: {
          input: {
            ...(values as CreateCustomPageInput),
            titleT: getTranslationInputFromForm(selectedTranslations, values.titleT),
            title: values.titleT?.[defaultLocale] || '',
            showInNavigationHeader: values.showInNavigationHeader || true,
          },
        },
      })
        .then(result => {
          if (result && result.data) {
            notifySuccess(
              intl.formatMessage({
                id: 'customPage.create_success',
              }),
            )
            if (onDone) onDone()
          }
        })
        .catch(error => {
          notifyError(getErrorMessage(error)?.replace('%{id}', applicationId))
        })
    }
  }

  const validate = useCallback(
    (values: CustomPagesFormValues): ValidationErrors | Promise<ValidationErrors> | undefined => ({
      titleT: validateTranslation(activeTranslation, values?.titleT?.[`${defaultLocale}`], intl),
    }),
    [activeTranslation, defaultLocale, intl],
  )

  return (
    <Form<CustomPagesFormValues>
      onSubmit={onSubmit}
      mutators={{
        ...arrayMutators,
      }}
      validate={validate}
      initialValues={initialValues}
      render={({ handleSubmit, form, pristine, values }) => (
        <>
          <Content>
            <Dimmer.Dimmable dimmed={isLoading}>
              <Dimmer active={isLoading} inverted />

              <CustomPageFormFields
                defaultLocale={defaultLocale}
                onChangeDefaultLocale={setDefaultLocale}
                selectedTranslations={selectedTranslations}
                onChangeSelectedTranslations={setSelectedTranslations}
                onChangeActiveTranslation={setActiveTranslation}
                activeTranslation={activeTranslation}
                initialValues={customPage}
                values={values}
                onChange={form.change}
              />
            </Dimmer.Dimmable>
          </Content>

          <div className="mt-4 text-right">
            <CancelFormButton pristine={pristine} onConfirm={form.reset} onCancel={onCancel} />
            <Button positive loading={isLoading} onClick={handleSubmit}>
              <FormattedMessage id="common.submit" />
            </Button>
          </div>
        </>
      )}
    />
  )
}

export default CustomPageForm
