import classnames from 'classnames'
import React, { memo, useCallback, useState } from 'react'
import { useIntl } from 'react-intl'
import { Button, Icon, Input, Label } from 'semantic-ui-react'
import { Tag, TagInput } from 'services/api/graphql'

import GenericForm from './Form/GenericForm'

type AddTagInputProps = {
  tags: TagInput[]
  onChange: (tags: TagInput[]) => void
}

type AddSubtagModalProps = {
  onCancel: () => void
  onSubmit: (subTags: Tag['subTags']) => void
  initialSubtags: Tag['subTags']
  title: string
}

const AddSubtagModal = ({ title, onCancel, onSubmit, initialSubtags }: AddSubtagModalProps) => {
  const [subTags, setSubTags] = useState<string[]>(initialSubtags || [])
  const [editedTitle, setEditedTitle] = useState('')
  const [targetEditTitle, setTargetEditTitle] = useState('')
  const [targetIndex, setTargetIndex] = useState(-1)
  const [newCategory, setNewCategory] = useState(false)

  const handleKeyDown = useCallback(
    (event: KeyboardEvent, target = false) => {
      if (event.key !== 'Enter') return
      event.preventDefault()
      if (target && targetEditTitle.trim().length !== 0) {
        const temp = [...subTags]
        const editedTagIndex = temp.findIndex((tag, idx) => targetIndex === idx)
        if (editedTagIndex !== -1) temp[editedTagIndex] = targetEditTitle
        setSubTags(temp)
        setTargetIndex(-1)
        setTargetEditTitle('')
      } else if (editedTitle.trim().length !== 0) {
        setSubTags([...subTags, editedTitle])
        setEditedTitle('')
        setNewCategory(false)
      }
    },
    [editedTitle, subTags, targetEditTitle, targetIndex],
  )

  const handleOnChangeInput = useCallback((e: React.ChangeEvent<HTMLInputElement>, target = false) => {
    if (target) {
      setTargetEditTitle(e.target.value)
    } else {
      setEditedTitle(e.target.value)
    }
  }, [])

  const handleOnDelete = useCallback(
    index => {
      setSubTags(subTags.filter((tag, idx) => idx !== index))
    },
    [subTags],
  )

  const onFinish = useCallback(() => {
    onSubmit(subTags)
  }, [onSubmit, subTags])

  return (
    <GenericForm header={title} initialValues={{}} onCancel={onCancel} onSubmit={onFinish} loading={false} open>
      <Label.Group className={classnames('flex items-center flex-wrap', { 'mt-3': subTags.length === 0 })}>
        {subTags.map((subtag, index) => (
          <div
            key={`${subtag}-${index}`}
            className={classnames({
              'rounded-md bg-gray-200 pl-3 pr-2 flex justify-center items-center py-2 my-1 mr-2': targetIndex !== index,
            })}
          >
            <div className="flex items-center justify-center">
              {targetIndex === index ? (
                <Input
                  size="mini"
                  className="mr-2"
                  value={targetEditTitle}
                  onKeyDown={(event: KeyboardEvent) => handleKeyDown(event, true)}
                  onChange={ev => handleOnChangeInput(ev, true)}
                  autoFocus
                />
              ) : (
                <>
                  <span
                    className="font-semibold text-lg m-0 mr-1"
                    onClick={() => {
                      setTargetEditTitle(subtag || '')
                      setTargetIndex(index)
                    }}
                  >
                    {subtag}
                  </span>
                  <div className="cursor-pointer inline-block" onClick={() => handleOnDelete(index)}>
                    <Icon name="remove" color="grey" />
                  </div>
                </>
              )}
            </div>
          </div>
        ))}
        {newCategory ? (
          <div>
            <Input
              onKeyDown={handleKeyDown}
              size="small"
              value={editedTitle}
              onChange={ev => handleOnChangeInput(ev, false)}
              autoFocus
            />
          </div>
        ) : (
          <div>
            <Button
              circular
              icon="plus"
              onClick={e => {
                e.preventDefault()
                setNewCategory(true)
              }}
            />
          </div>
        )}
      </Label.Group>
    </GenericForm>
  )
}

const AddTagInput = ({ onChange, tags }: AddTagInputProps) => {
  const intl = useIntl()

  // Primary tags handling
  const [editedTitle, setEditedTitle] = useState('')
  const [targetEditTitle, setTargetEditTitle] = useState('')
  const [targetIndex, setTargetIndex] = useState(-1)
  const [newCategory, setNewCategory] = useState(false)

  // Subtags handling
  const [subtagModalOpen, setSubtagModalOpen] = useState(false)
  const [primaryTagFocused, setPrimaryTagFocused] = useState<TagInput | null>(null)

  // Delete handling
  const [hoverIndex, setHoverIndex] = useState(-1)

  const handleKeyDown = useCallback(
    (event: KeyboardEvent, target = false) => {
      if (event.key === 'Enter') {
        event.preventDefault()
        if (target) {
          if (targetEditTitle.trim().length !== 0) {
            const temp = [...tags]
            const editedTag = temp.find((tag, idx) => targetIndex === idx)
            editedTag && (editedTag.title = targetEditTitle)
            onChange(temp)
            setTargetEditTitle('')
          }
        } else if (editedTitle.trim().length !== 0) {
          onChange([...tags, { title: editedTitle }])
          setEditedTitle('')
          setNewCategory(false)
        }
      }
    },
    [editedTitle, onChange, tags, targetEditTitle, targetIndex],
  )

  const handleOnChangeInput = useCallback((e: React.ChangeEvent<HTMLInputElement>, target = false) => {
    if (target) {
      setTargetEditTitle(e.target.value)
    } else {
      setEditedTitle(e.target.value)
    }
  }, [])

  const handleOnDelete = useCallback(
    index => {
      onChange(tags.filter((tag, idx) => idx !== index))
    },
    [onChange, tags],
  )

  return (
    <>
      <Label.Group className={`flex items-center flex-wrap ${tags.length === 0 ? 'mt-3' : ''}`}>
        {tags.map((tag, index) => (
          <div
            key={`${tag?.title && tag.title}${index}`}
            onMouseEnter={() => setHoverIndex(index)}
            onMouseLeave={() => setHoverIndex(-1)}
            className={classnames('relative', {
              'rounded-md bg-gray-200 pl-3 pr-2 flex justify-center items-center py-2 my-1 mr-4': targetIndex !== index,
            })}
          >
            <div className="flex items-center justify-center px-1">
              {targetIndex === index ? (
                <Input
                  size="mini"
                  className="mr-2"
                  value={targetEditTitle}
                  onKeyDown={(event: KeyboardEvent) => handleKeyDown(event, true)}
                  onChange={ev => handleOnChangeInput(ev, true)}
                  autoFocus
                />
              ) : (
                <>
                  <span
                    className="font-semibold text-lg m-0 mr-1"
                    onClick={() => {
                      setTargetEditTitle(tag?.title || '')
                      setTargetIndex(index)
                    }}
                  >
                    {tag.title}
                  </span>
                  <div
                    className="absolute rounded-full w-7 h-7 flex justify-center items-center bg-gray-100 shadow-sm cursor-pointer -top-2 -left-2"
                    onClick={() => {
                      setSubtagModalOpen(true)
                      setPrimaryTagFocused(tag)
                    }}
                  >
                    {tag.subTags && tag.subTags?.length > 0 ? (
                      <span className="font-semibold text-xs">{tag.subTags?.length}</span>
                    ) : (
                      <span className="font-semibold">+</span>
                    )}
                  </div>
                </>
              )}
            </div>

            {hoverIndex === index && targetIndex === -1 && (
              <div
                className="absolute flex justify-center items-center cursor-pointer bg-gray-100 rounded-full h-7 w-7 shadow-md z-10 -top-2 -right-2"
                onClick={() => handleOnDelete(index)}
              >
                <Icon name="remove" color="red" fitted size="small" />
              </div>
            )}
          </div>
        ))}
        {newCategory ? (
          <div>
            <Input
              onKeyDown={handleKeyDown}
              size="small"
              value={editedTitle}
              onChange={ev => handleOnChangeInput(ev, false)}
              autoFocus
            />
          </div>
        ) : (
          <div>
            <Button
              circular
              icon="plus"
              onClick={e => {
                e.preventDefault()
                setNewCategory(true)
              }}
            />
          </div>
        )}
      </Label.Group>
      {subtagModalOpen && (
        <AddSubtagModal
          title={intl.formatMessage({ id: 'organisms.addsubtags' }, { title: primaryTagFocused?.title })}
          onCancel={() => {
            setSubtagModalOpen(false)
            setPrimaryTagFocused(null)
          }}
          initialSubtags={primaryTagFocused?.subTags}
          onSubmit={subTags => {
            const temp = [...tags]
            const updateSubtags = temp.find(tag => tag === primaryTagFocused)
            if (updateSubtags) updateSubtags.subTags = [...(subTags || [])]
            setSubtagModalOpen(false)
          }}
        />
      )}
    </>
  )
}

export default memo(AddTagInput)
