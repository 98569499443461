// Map user roles to backoffice access
import { applicationUris } from 'Layout/uris'
import { redirect } from 'react-router-dom'
import { Permission, UserRoleId } from 'services/api/graphql'
import { IUser } from 'stores'

export enum Access {
  Administration = 'Administration',
  AdCampaigns = 'adCampaigns',
  ApplicationDesigns = 'applicationDesigns',
  ApplicationView = 'applicationView',
  ChatRooms = 'chatRooms',
  ClipsView = 'clipsView',
  ContentPushStreams = 'contentPushStreams',
  CustomPagesView = 'customPagesView',
  DefaultConfig = 'defaultConfig',
  EditedCategories = 'editedCategoryView',
  FrontPages = 'frontPages',
  LegalRestrictions = 'legalRestrictions',
  LicensedUsers = 'licensedUsers',
  Links = 'links',
  LinksStatistics = 'LinksStatistics',
  LivesView = 'livesView',
  MediaLibraryView = 'mediaLibraryView',
  NewsView = 'newsView',
  OrganismPagesView = 'organismPagesView',
  OrganismsView = 'organismsView',
  PlayListsView = 'playListsView',
  PostsView = 'postsView',
  ProductsView = 'productsView',
  PurchasableOffersView = 'purchasableOffersView',
  SportsView = 'sportsView',
  SportEventsView = 'sportEventsView',
  SportItemSetsView = 'sportItemSetsView',
  StreamMap = 'streamMap',
  SVEChannel = 'svechannel',
  SVEChannels = 'svechannels',
  ThirdPartyApplicationView = 'thirdPartyApplicationView',
  UsersView = 'usersView',
  VideoGrid = 'VideoGrid',
}

type AccessDefinition = {
  permissions?: Permission[]
  roleIds?: UserRoleId[]
  requiredAccesses?: Access[] | Access
  additionalAccesses?: Access[] | Access
}

type AccessDefinitions = {
  [key in Access]: AccessDefinition
}
const accessDefinitionsBase: AccessDefinitions = {
  [Access.UsersView]: {
    permissions: [Permission.UserRead],
    roleIds: [UserRoleId.Admin],
  },
  [Access.Administration]: {
    roleIds: [UserRoleId.Admin, UserRoleId.SuperAdmin],
  },
  [Access.MediaLibraryView]: { permissions: [Permission.FileRead] },
  [Access.OrganismsView]: { permissions: [Permission.OrganismRead] },
  [Access.PlayListsView]: { permissions: [Permission.SportItemSetRead] },
  [Access.NewsView]: { permissions: [Permission.NewsRead] },
  [Access.SportsView]: { roleIds: [UserRoleId.Admin, UserRoleId.SuperAdmin], permissions: [Permission.SportRead] },
  [Access.SportEventsView]: { permissions: [Permission.SportItemSetRead] },
  [Access.SportItemSetsView]: { permissions: [Permission.SportItemSetRead] },
  [Access.LivesView]: {},
  [Access.ClipsView]: {},
  [Access.PostsView]: {},
  [Access.DefaultConfig]: {
    permissions: [Permission.DefaultConfigUpdate],
  },
  [Access.FrontPages]: {
    permissions: [Permission.FrontPageRead, Permission.FrontPageCreate],
  },
  [Access.StreamMap]: {
    permissions: [Permission.MapWmStream],
  },
  [Access.SVEChannels]: {
    permissions: [Permission.SveChannelsManagement],
  },
  [Access.SVEChannel]: {
    permissions: [Permission.SveChannelsManagement],
  },
  [Access.EditedCategories]: {
    permissions: [Permission.EditedCategoryRead],
  },
  [Access.ApplicationView]: {
    permissions: [Permission.ApplicationRead],
  },
  [Access.ThirdPartyApplicationView]: {
    permissions: [Permission.ThirdPartyApplicationRead],
  },
  [Access.CustomPagesView]: {
    permissions: [Permission.CustomPageRead],
  },
  [Access.OrganismPagesView]: {
    permissions: [Permission.OrganismPageRead],
  },
  [Access.PurchasableOffersView]: {
    roleIds: [UserRoleId.Admin, UserRoleId.SuperAdmin, UserRoleId.OrganismAdministrator],
  },
  [Access.ProductsView]: {
    permissions: [Permission.PurchasableOfferRead],
  },
  [Access.LegalRestrictions]: {
    permissions: [Permission.LegalRestrictionRead, Permission.LegalRestrictionWrite],
  },
  [Access.LicensedUsers]: {
    roleIds: [UserRoleId.SuperAdmin],
  },
  [Access.Links]: {
    permissions: [Permission.LinkRead],
  },
  [Access.LinksStatistics]: {
    permissions: [Permission.LinksStatisticsRead],
  },
  [Access.ChatRooms]: {
    permissions: [Permission.ChatRoomsRead],
  },
  [Access.ContentPushStreams]: {
    permissions: [Permission.ContentPushStreamRead, Permission.ContentPushStreamCreate],
  },
  [Access.ApplicationDesigns]: { permissions: [Permission.AdCampaignRead] },
  [Access.AdCampaigns]: { permissions: [Permission.AdCampaignRead] },
  [Access.VideoGrid]: {
    roleIds: [UserRoleId.Admin, UserRoleId.SuperAdmin, UserRoleId.OrganismAdministrator, UserRoleId.OrganismEditor],
  },
}

/** Read user permissions to determine the permission value of one Access */
const hasAccess = (
  access: Access,
  permissions: Permission[] | undefined,
  roleIds: UserRoleId[] | undefined,
): boolean => {
  const accessDefinition = accessDefinitionsBase[access]
  // Access with permission
  if (permissions) {
    // Check required accesses
    // If required accesses, check that ALL required access are allowed
    if (accessDefinition.requiredAccesses) {
      const requiredAccesses = Array.isArray(accessDefinition.requiredAccesses)
        ? accessDefinition.requiredAccesses
        : [accessDefinition.requiredAccesses]
      for (const requiredAccess of requiredAccesses) {
        if (!hasAccess(requiredAccess, permissions, roleIds)) {
          return false
        }
      }
    }
    // Check with permissions
    if (accessDefinition.permissions) {
      // Check that user has all permissions required
      return accessDefinition.permissions.every(requiredPermission => permissions.includes(requiredPermission))
    }
  }

  // Access with roleIds
  if (roleIds && accessDefinition.roleIds) {
    // User must have at least one required roleId
    return accessDefinition.roleIds.some(requiredRoleId => roleIds.includes(requiredRoleId))
  }

  return false
}

/** Check permission value for one or multiple Access */
export const canAccess = (
  accesses: Access[] | Access | undefined,
  permissions: Permission[],
  roleIds: UserRoleId[],
): boolean => {
  const accessesList = accesses ? (Array.isArray(accesses) ? accesses : [accesses]) : []
  for (const access of accessesList) {
    if (hasAccess(access, permissions, roleIds)) {
      return true
    }
  }
  console.log('no access')
  return false
}

export const userCanAccessToPath = (user: IUser | null, accesses?: Access[]) => {
  if (!user) {
    throw redirect(applicationUris.login)
  }

  if (!canAccess(accesses, user.mergedPermissions, user.userRoleIds)) {
    throw redirect(applicationUris.dashboard)
  }

  return true
}
