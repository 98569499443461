import { useQuery } from '@apollo/client'
import CheckboxInput from 'Components/Form/CheckboxInput'
import GenericForm from 'Components/Form/GenericForm'
import NumberInput from 'Components/Form/NumberInput'
import SAFormField from 'Components/Form/SAFormField'
import { SelectDateInput } from 'Components/Form/SelectDateInput'
import { Period } from 'Components/Form/SelectDateRangeInput'
import SelectItemListInput from 'Components/Form/SelectItemListInput'
import SelectLocalInput, { SelectedTranslationsType, TranslatedInput } from 'Components/Form/SelectLocalInput'
import FileInput from 'Containers/Form/FileInput'
import SelectCategoryInput from 'Containers/Form/SelectCategoryInput'
import SelectOrganismsInput from 'Containers/Form/SelectOrganismsInput'
import SelectPublicationStatusInput from 'Containers/Form/SelectPublicationStatusInput'
import SelectSportInput from 'Containers/Form/SelectSportInput'
import SelectSportItemSetTypeInput from 'Containers/Form/SelectSportItemSetTypeInput'
import SelectVideoEngineInput from 'Containers/Form/SelectVideoEngineInput'
import { ContentAccessControlUserAuthenticationFormFields } from 'Forms/ContentAccessControl/UserAuthenticationRule/UserAuthenticationFormFields'
import ContentAccessControlUserLocationFormFields, {
  ContentAccessControlUserLocationFormValues,
} from 'Forms/ContentAccessControl/UserLocationRule/UserLocationFormFields'
import { TabEditMultiple } from 'Forms/Tab/multiple'
import classnames from 'classnames'
import classNames from 'classnames'
import Config from 'config'
import { endOfDay, isAfter } from 'date-fns'
import { ValidationErrors } from 'final-form'
import arrayMutators from 'final-form-arrays'
import { cloneDeepWith, merge } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useCheckStreamsAvailable, useGetSportItemSetLiveRunning } from 'models'
import { useGetAdCampaigns } from 'models/AdCampaign'
import { useGetAvailableContentPushServices, useGetContentPushStreams } from 'models/ContentPushStream'
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { FieldInputProps, FieldRenderProps } from 'react-final-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { Divider, Form, Header, Message, Segment, TextArea } from 'semantic-ui-react'
import {
  AdCampaign,
  AvailableContentPushService,
  CacUserAuthenticationRule,
  Category,
  ContentPushStream,
  ContentPushStreamServiceName,
  MediaType,
  NoSpoilerModeInput,
  Organism,
  Permission,
  PremiumAccess,
  PublicationStatus,
  Scalars,
  Sport,
  SportItemSetType,
  SveStream,
  Tab,
  Tag,
  useCheckSveChannelsAvailableQuery,
  VideoEngine,
} from 'services/api/graphql'
import { useStore, useTemplates } from 'stores'
import { RequiredValue, validateRequired, validateUrl } from 'tools/formValidators'
import { cloneWithoutGraphqlCacheTypenames } from 'tools/graphql'
import { getSelectedTranslations, validateTranslation } from 'tools/translationTools'

import AccessRestrictionsFieldset from '../AccessRestrictionsFieldset'

import { SportItemSetSVEFields } from './SportItemSetSVEFields'
import { WMStreamsFields } from './WMStreamsFields'
import { GetOrganismStreamsSVEAndWM } from './fragments'
import { subtagOptions, tagOptions } from './tools'

export const validateSportItemSetEndDate = (endDate: Date, values: object) => {
  const { startDate } = values as { startDate: Date | undefined }
  if (!startDate) return
  return isAfter(endDate, startDate) ? undefined : 'La date de fin doit être après la date de début'
}

export type SportItemSetSportEventFormValues = {
  period: Period
  liveTemplateId?: Scalars['WildmokaID'] | null
  clipTemplateId?: Scalars['WildmokaID'] | null
  backstageTemplateId?: Scalars['WildmokaID'] | null
}

export type SportItemSetFormValues = {
  id?: string // must be set when editing
  type: SportItemSetType
  title: string
  subtitle: string
  description: string | null | undefined
  titleT?: TranslatedInput | null
  subtitleT?: TranslatedInput | null
  descriptionT?: TranslatedInput | null
  defaultLocale?: string
  ownerOrganismId: Organism['id'] | null
  sportIds: Array<Sport['id']>
  publicationStatus: PublicationStatus
  bannerImageFileId: string
  bannerVideoId: string | null
  testPeriodEnd: Date | null
  // SportItemSet metadata
  sportEvent: SportItemSetSportEventFormValues | undefined | null
  visibleInMultisportApplication: boolean
  websiteUrl: string | null
  categoriesIds: Array<Category['id']> | null
  lockPublishingLive: boolean
  lockPublishingClip: boolean
  offerIds: string[]
  restrictionLevel: PremiumAccess
  cac: {
    userLocationRule: ContentAccessControlUserLocationFormValues
    userAuthenticationRule?: CacUserAuthenticationRule
  }
  videoEngine: VideoEngine
  // Wildmoka
  streamsIds: Scalars['WildmokaID'][]
  // SVE
  sveStreams: SveStream[]
  wmClippingTemplateId: string | undefined
  sveInputLossImageMediaId: string | undefined | null
  sveOverlayLogoImageMediaId: string | undefined | null
  activateSportItemChatByDefault: boolean
  // Content push streams
  contentPushServiceNames?: ContentPushStreamServiceName[]
  contentPushServiceGenericStreamIds?: ContentPushStream['id'][]
  contentPushServiceLiveEventIds?: ContentPushStream['id'][]
  tagId?: Tag['id']
  subTags?: string[]
  activateGreenPlayback?: boolean
  adCampaignId?: AdCampaign['id']
  noSpoilerMode?: NoSpoilerModeInput
  tabs?: (Omit<Tab, 'titleT'> & { titleT: TranslatedInput })[] | null
  disableReadParallelLivestreamsLimit?: boolean
}
const sportItemSetFormDefaultValues: Partial<SportItemSetFormValues> = {
  title: '',
  subtitle: '',
  description: '',
  sportIds: [],
  publicationStatus: PublicationStatus.Draft,
  bannerImageFileId: '',
  sportEvent: undefined,
  offerIds: [],
  restrictionLevel: PremiumAccess.All,
  cac: {
    userAuthenticationRule: undefined,
    userLocationRule: {
      hasUserLocationRule: false,
      userLocationRule: undefined,
    },
  },
  type: undefined,
  ownerOrganismId: null,
  bannerVideoId: null,
  testPeriodEnd: null,
  websiteUrl: null,
  tabs: [],
  categoriesIds: [],
  lockPublishingLive: false,
  lockPublishingClip: false,
  visibleInMultisportApplication: false,
  activateSportItemChatByDefault: false,
  streamsIds: [],
  sveStreams: [],
  activateGreenPlayback: true,
}

const notUsedDate = new Date()

export interface SportItemSetFormProps {
  title: string
  type?: SportItemSetType
  editType: boolean
  isModal: boolean
  edit: boolean // if true => Update Sport otherwise Add Sport
  initialValues?: Partial<SportItemSetFormValues>
  onSubmit: (
    values: SportItemSetFormValues,
    defaultLocale: string,
    selectedTranslations: SelectedTranslationsType[],
  ) => Promise<void> | void
  onCancel?: () => void
  loading: boolean
}

export const SportItemSetForm = observer(
  ({ type, edit, editType, isModal, initialValues, onSubmit, onCancel, loading, title }: SportItemSetFormProps) => {
    const intl = useIntl()
    const {
      organismId,
      currentUser: { isAdmin },
      currentUser,
    } = useStore()

    const { hasLiveRunning, loading: loadingHasLiveRunning } = useGetSportItemSetLiveRunning(initialValues?.id, {
      skip: !edit,
      fetchPolicy: 'network-only',
    })

    // Values we need to watch to check form validity
    const [ownerOrganismId, setOwnerOrganismId] = useState(initialValues && initialValues.ownerOrganismId)
    const [streamsIds, setStreamsIds] = useState((initialValues && initialValues.streamsIds) || [])

    const [period, setPeriod] = useState<Period>(
      initialValues?.sportEvent?.period ?? { startDate: new Date(), endDate: endOfDay(new Date()) },
    )
    const [selectedTranslations, setSelectedTranslations] = useState<SelectedTranslationsType[]>(
      initialValues ? getSelectedTranslations(initialValues) : [],
    )
    const [defaultLocale, setDefaultLocale] = useState<string>(initialValues?.defaultLocale || 'fr')
    const [activeTranslation, setActiveTranslation] = useState<string | undefined>(defaultLocale)

    const [subTags, setSubTags] = useState<string[]>(initialValues?.subTags || [])
    const [adCampaignId, setAdCampaignId] = useState(initialValues?.adCampaignId || '')
    const [adCampaigns, setAdCampaigns] = useState<AdCampaign[]>([])

    const { data: contentPushStreams } = useGetContentPushStreams({
      variables: {
        filters: { types: [ContentPushStreamServiceName.GenericStream, ContentPushStreamServiceName.LiveEvent] },
      },
    })

    const { data: contentPushServicesData } = useGetAvailableContentPushServices({
      skip: !currentUser.can(Permission.ContentPushStreamRead, organismId || undefined, true),
    })

    const contentPushServices = useMemo(() => {
      if (type === SportItemSetType.Playlist) {
        return contentPushServicesData.filter(el => el.type !== ContentPushStreamServiceName.LiveEvent)
      }

      return contentPushServicesData
    }, [contentPushServicesData, type])

    // Initialize ownerOrganismId with the one in store
    useEffect(() => {
      if (!initialValues && organismId) {
        setOwnerOrganismId(organismId)
      }
    }, [initialValues, organismId])

    // Wildmoka templates
    const wmTemplates = useTemplates()

    const { data: _ownerOrganism, loading: loadingOrganism } = useQuery(GetOrganismStreamsSVEAndWM, {
      variables: {
        id: ownerOrganismId ?? '',
        input: period
          ? { forDateRange: { startDate: period.startDate, endDate: period.endDate } }
          : { forDateRange: { startDate: notUsedDate, endDate: notUsedDate } },
      },
      skip: !ownerOrganismId,
      fetchPolicy: 'cache-and-network',
    })
    const ownerOrganism = _ownerOrganism?.organism

    const { loading: adCampaignLoading } = useGetAdCampaigns({
      variables: { filters: { organismId: organismId } },
      fetchPolicy: 'cache-and-network',
      onCompleted: data => {
        const list = (data && data.adCampaigns && data.adCampaigns.adCampaigns) || []
        setAdCampaigns(cloneWithoutGraphqlCacheTypenames(list))
      },
    })

    const selectableStreams = useMemo(() => ownerOrganism?.streams || [], [ownerOrganism?.streams])

    // check SVE streams conflicts
    const {
      data: sveChannelAvailability,
      loading: checkSveChannelAvailableLoading,
      refetch: checkSveChannelAvailableRefetch,
    } = useCheckSveChannelsAvailableQuery({
      variables: {
        input: {
          sportItemSetId: initialValues?.id,
          channelIds: ownerOrganism?.sveChannelIds ?? [],
          from: period.startDate,
          to: period.endDate,
        },
      },
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'network-only',
      skip: ownerOrganism?.sveChannelIds?.length === 0,
    })

    // check WM streams conflicts
    const { data: streamsConflictsData } = useCheckStreamsAvailable(
      {
        streamsIds,
        from: period?.startDate ?? notUsedDate,
        to: period?.endDate ?? notUsedDate,
      },
      {
        skip: !streamsIds || streamsIds.length === 0 || !period,
      },
    )

    // Select organism's only sport if they have only one
    const defaultSportId = useMemo(() => {
      if (ownerOrganism && ownerOrganism.sportIds.length === 1) {
        return ownerOrganism.sportIds[0]
      }
      return null
    }, [ownerOrganism])
    // Return false if a sportId is no longer selectable, and thus must be updated
    const isSportIdSelectable = useCallback(
      (sportId: string) => !ownerOrganism || ownerOrganism.sportIds.includes(sportId),
      [ownerOrganism],
    )
    // Return true only if all sports in the list are selectables
    const areSportsValid = useCallback(
      (sportIds: string[]) => sportIds.every(sportId => isSportIdSelectable(sportId)),
      [isSportIdSelectable],
    )

    // In edition, only conflicts with other sportItemSet matter
    const streamsConflicts = useMemo(
      () =>
        streamsConflictsData &&
        streamsConflictsData.filter(conflict => (initialValues ? conflict.sportItemSet.id !== initialValues.id : true)),
      [initialValues, streamsConflictsData],
    )

    // Detect if templates have been changed
    const areTemplatesEdited = useCallback(
      (values: SportItemSetFormValues) => {
        if (!edit || !initialValues || !initialValues.sportEvent || !values.sportEvent) {
          return false
        }
        return (
          values.sportEvent.liveTemplateId !== initialValues.sportEvent.liveTemplateId ||
          values.sportEvent.backstageTemplateId !== initialValues.sportEvent.backstageTemplateId ||
          values.sportEvent.clipTemplateId !== initialValues.sportEvent.clipTemplateId
        )
      },
      [edit, initialValues],
    )

    const validate = useCallback(
      (values: SportItemSetFormValues): ValidationErrors | Promise<ValidationErrors> | undefined => {
        const validation: ValidationErrors = {
          titleT: validateTranslation(activeTranslation, values?.titleT?.[`${defaultLocale}`], intl),
          descriptionT: validateTranslation(
            activeTranslation,
            values?.descriptionT?.[`${defaultLocale}`] || undefined,
            intl,
          ),
          subtitleT: validateTranslation(activeTranslation, values?.subtitleT?.[`${defaultLocale}`], intl),
        }
        if (values.type === SportItemSetType.SportEvent) {
          validation.sportEvent = {}
          const { videoEngine } = values
          switch (videoEngine) {
            case VideoEngine.Sportall: {
              break
            }
            case VideoEngine.Wildmoka: {
              validation.sportEvent.liveTemplateId = validateRequired(values.sportEvent?.liveTemplateId)
              validation.sportEvent.clipTemplateId = validateRequired(values.sportEvent?.clipTemplateId)
              validation.sportEvent.backstageTemplateId = validateRequired(values.sportEvent?.backstageTemplateId)
              break
            }
            case VideoEngine.ExternalFeed: {
              break
            }
            default: {
              validation.videoEngine = intl.formatMessage({
                id: 'sportItemSets.noVideoEngineSelected',
              })
              break
            }
          }
        }

        if (values.sportEvent?.period) {
          // Check that start and end date are consistent
          if (values.sportEvent.period.startDate >= values.sportEvent.period.endDate) {
            validation.sportEvent = {
              sportEvent: {
                endDate: intl.formatMessage({
                  id: 'sportEvent.invalid_endDate',
                }),
              },
            }
            return validation
          }

          // Disable check SVE Channel conflicts for wec
          if (Config.tenantName !== 'wec') {
            // When dates and streamsIds are defined, check for conflict
            if (values.streamsIds && values.streamsIds.length > 0 && streamsConflicts && streamsConflicts.length > 0) {
              // Send an error for each conflict
              validation.streamsIds = streamsConflicts.map(conflict => {
                const stream = selectableStreams.find(str => str.id === conflict.streamId)
                return intl.formatMessage(
                  { id: 'sportItemSet.stream_conflict' },
                  {
                    sportItemSet: conflict.sportItemSet.title,
                    stream: stream?.name || '',
                  },
                )
              })
            }
          }
        }

        if (
          values.contentPushServiceNames?.includes(ContentPushStreamServiceName.LiveEvent) &&
          values.contentPushServiceLiveEventIds?.length === 0
        ) {
          validation.contentPushServiceLiveEventIds = intl.formatMessage({
            id: 'sportItemSets.form.noContentPushLiveEventSelected',
          })
        }
        if (
          values.contentPushServiceNames?.includes(ContentPushStreamServiceName.GenericStream) &&
          values.contentPushServiceGenericStreamIds?.length === 0
        ) {
          validation.contentPushServiceGenericStreamIds = intl.formatMessage({
            id: 'sportItemSets.form.noContentPushGenericStreamSelected',
          })
        }

        return validation
      },
      [activeTranslation, defaultLocale, intl, selectableStreams, streamsConflicts],
    )

    // Submit with forcing ownerOrganismId
    const submit = useCallback(
      async (values: SportItemSetFormValues) => {
        if (!values?.sveStreams) values.sveStreams = []

        if (!values.contentPushServiceNames?.includes(ContentPushStreamServiceName.Screach)) {
          values.sveStreams = values.sveStreams.map(sveStream => ({ ...sveStream, outputStreamId: null }))
        }

        onSubmit(
          {
            ...values,
            title: values.titleT?.[defaultLocale] || '',
            subtitle: values.subtitleT?.[defaultLocale] || '',
            description: values.descriptionT?.[defaultLocale] || '',
            ownerOrganismId: ownerOrganismId || values.ownerOrganismId,
            adCampaignId: values.adCampaignId,
            testPeriodEnd: values.publicationStatus !== PublicationStatus.Published ? values.testPeriodEnd : null,
          },
          defaultLocale,
          selectedTranslations,
        )
      },
      [onSubmit, defaultLocale, ownerOrganismId, selectedTranslations],
    )

    const finalInitialValues = useMemo(
      () => ({
        ...merge(cloneDeepWith(sportItemSetFormDefaultValues), {
          sveStreams: [],
          ...initialValues,
        }),
        type: initialValues?.type || type,
        ...(!edit &&
          !!ownerOrganism?.visibleInMultisportApplication && {
            visibleInMultisportApplication: ownerOrganism?.visibleInMultisportApplication,
          }),
      }),
      [edit, initialValues, ownerOrganism?.visibleInMultisportApplication, type],
    )

    const onChangeContentPushServiceNames = useCallback(
      (
        value,
        input: FieldInputProps<SportItemSetFormValues, HTMLSelectElement>,
        values: SportItemSetFormValues,
        serviceName: AvailableContentPushService,
      ) => {
        if (value) {
          input.onChange([...(values?.contentPushServiceNames || []), serviceName.type])
        } else if (values?.contentPushServiceNames?.length) {
          const temp = values?.contentPushServiceNames
          input.onChange(temp.filter(str => str !== serviceName.type))
        }
      },
      [],
    )

    return (
      <GenericForm
        header={title}
        initialValues={finalInitialValues}
        mutators={{
          setSportIds: (args, state, tools) => {
            tools.changeValue(state, 'sportIds', () => args[0])
          },
          ...arrayMutators,
        }}
        isModal={isModal}
        onCancel={onCancel}
        onSubmit={submit}
        validate={validate}
        loading={loading}
        resetInputsAfterSubmit
      >
        {({ values, errors, form }) => {
          // Set sportId to default if necessary
          if (
            defaultSportId &&
            isSportIdSelectable(defaultSportId) &&
            (values.sportIds.length === 0 || !areSportsValid(values.sportIds))
          ) {
            form.mutators.setSportIds([defaultSportId])
          }
          // Clean sportIds if they are no longer selectables
          else if (!areSportsValid(values.sportIds)) {
            form.mutators.setSportIds(values.sportIds.filter(isSportIdSelectable))
          }

          return (
            <Fragment>
              <SAFormField
                name="locale"
                render={({ input }) => (
                  <Form.Field
                    {...input}
                    disabled={false}
                    label={intl.formatMessage({ id: 'locale' })}
                    control={() => (
                      <SelectLocalInput
                        defaultLocale={defaultLocale}
                        onChangeDefaultLocale={setDefaultLocale}
                        activeTranslation={activeTranslation}
                        onChangeActiveTranslation={setActiveTranslation}
                        selectedTranslations={selectedTranslations}
                        onChangeSelectedTranslations={setSelectedTranslations}
                      />
                    )}
                  />
                )}
              />

              {!organismId && (
                <SAFormField
                  name="ownerOrganismId"
                  validate={validateRequired}
                  render={({ input, meta }: FieldRenderProps<Organism['id'], HTMLInputElement>) => (
                    <Form.Field
                      control={SelectOrganismsInput}
                      {...input}
                      onChange={(value: string) => {
                        setOwnerOrganismId(value)
                        values.streamsIds = []
                        form.change('sveStreams', [])
                        form.change('streamsIds', [])
                        input.onChange(value)
                      }}
                      error={!!meta.touched && !!meta.error}
                      label={intl.formatMessage({
                        id: 'sportItemSet.ownerOrganismId',
                      })}
                    />
                  )}
                />
              )}

              {isAdmin && (
                <SAFormField
                  name="visibleInMultisportApplication"
                  render={({ input, meta }) => (
                    <Form.Field error={!!meta.touched && !!meta.error}>
                      <CheckboxInput
                        {...input}
                        toggle
                        checked={values.visibleInMultisportApplication}
                        label={intl.formatMessage({ id: 'sportItemSet.visibleInMultisportApplication' })}
                      />
                    </Form.Field>
                  )}
                />
              )}

              {!edit && editType && (
                <SAFormField
                  name="type"
                  validate={validateRequired}
                  render={({ input, meta }) => (
                    <Form.Field
                      control={SelectSportItemSetTypeInput}
                      {...input}
                      required
                      error={!!meta.touched && !!meta.error}
                      label={intl.formatMessage({ id: 'sportItemSet.type' })}
                    />
                  )}
                />
              )}
              <SAFormField
                name={`titleT.${activeTranslation}`}
                render={({ input, meta }) => (
                  <Form.Input
                    {...input}
                    required={activeTranslation === defaultLocale}
                    error={!!meta.touched && !!meta.error}
                    label={intl.formatMessage({ id: 'sportItemSet.title' })}
                    placeholder={intl.formatMessage({
                      id: 'sportItemSet.title.placeholder',
                    })}
                  />
                )}
              />
              <SAFormField
                name={`subtitleT.${activeTranslation}`}
                render={({ input, meta }) => (
                  <Form.Input
                    {...input}
                    required={activeTranslation === defaultLocale}
                    error={!!meta.touched && !!meta.error}
                    label={intl.formatMessage({ id: 'sportItemSet.subtitle' })}
                    placeholder={intl.formatMessage({
                      id: 'sportItemSet.subtitle.placeholder',
                    })}
                  />
                )}
              />
              <SAFormField
                name={`descriptionT.${activeTranslation}`}
                render={({ input, meta }) => (
                  <Form.Input
                    {...input}
                    required={activeTranslation === defaultLocale}
                    control={TextArea}
                    maxLength={500}
                    error={!!meta.touched && !!meta.error}
                    label={intl.formatMessage({ id: 'sportItemSet.description' })}
                    placeholder={intl.formatMessage({
                      id: 'sportItemSet.description.placeholder',
                    })}
                  />
                )}
              />

              <SAFormField
                name="sportIds"
                validate={validateRequired}
                render={({ input, meta }) => (
                  <Form.Field
                    control={SelectSportInput}
                    {...input}
                    filter={(ownerOrganism && ownerOrganism.sportIds) || undefined}
                    loading={loadingOrganism}
                    value={defaultSportId ? [defaultSportId] : input.value}
                    required
                    disabled={!!defaultSportId || loadingOrganism}
                    error={!!meta.dirty && !!meta.error}
                    label={intl.formatMessage({ id: 'sportItemSet.sportIds' })}
                    multiple
                  />
                )}
              />

              <Segment>
                <Header as="h3">
                  {intl.formatMessage({
                    id: 'sportItemSet.publicationStatus',
                  })}
                </Header>
                <SAFormField
                  name="publicationStatus"
                  validate={validateRequired}
                  render={({ input, meta }) => (
                    <Form.Field
                      {...input}
                      control={SelectPublicationStatusInput}
                      required
                      error={!!meta.touched && !!meta.error}
                    />
                  )}
                />
                {values.publicationStatus === PublicationStatus.Draft && (
                  <>
                    <SAFormField
                      name="testPeriodEnd"
                      validate={(value: Date | null) =>
                        value && value < new Date()
                          ? intl.formatMessage({
                              id: 'sportItemSet.testPeriodEnd.min',
                            })
                          : ''
                      }
                      render={({ input, meta }) => (
                        <Form.Field
                          control={SelectDateInput}
                          format="DD/MM/YYYY HH:mm"
                          {...input}
                          error={!!meta.touched && !!meta.error}
                          label={intl.formatMessage({
                            id: 'sportItemSet.testPeriodEnd',
                          })}
                          onChange={(value: Date | undefined) => input.onChange(value || null)}
                          minDate={new Date()}
                          datetime={true}
                        />
                      )}
                    />
                  </>
                )}
                <Form.Group>
                  {type === SportItemSetType.SportEvent && (
                    <>
                      <SAFormField
                        name="sportEvent.period.startDate"
                        validate={validateRequired}
                        render={({ input, meta }) => {
                          return (
                            <Form.Field
                              {...input}
                              control={SelectDateInput}
                              datetime
                              onChange={async (value: Date | undefined) => {
                                input.onChange(value || null)
                                if (value && period) {
                                  setPeriod({ startDate: value, endDate: period.endDate })
                                  await checkSveChannelAvailableRefetch()
                                }
                              }}
                              required
                              maxDate={values?.sportEvent?.period?.endDate}
                              error={!!meta.touched && !!meta.error}
                              label={intl.formatMessage({
                                id: 'sportEventItem.startDate',
                              })}
                            />
                          )
                        }}
                      />
                      <SAFormField
                        name="sportEvent.period.endDate"
                        validate={validateRequired}
                        render={({ input, meta }) => {
                          return (
                            <Form.Field
                              {...input}
                              control={SelectDateInput}
                              datetime
                              onChange={async (value: Date | undefined) => {
                                input.onChange(value || null)
                                if (value) {
                                  setPeriod({ startDate: period.startDate, endDate: value })
                                  await checkSveChannelAvailableRefetch()
                                }
                              }}
                              required
                              minDate={values?.sportEvent?.period?.startDate}
                              error={!!meta.touched && !!meta.error}
                              label={intl.formatMessage({
                                id: 'sportEventItem.stopDate',
                              })}
                            />
                          )
                        }}
                      />
                    </>
                  )}
                </Form.Group>
              </Segment>

              <Segment>
                <SAFormField
                  name="bannerImageFileId"
                  validate={validateRequired}
                  render={({ input, meta }) => (
                    <Form.Field
                      control={FileInput}
                      {...input}
                      type={MediaType.Image}
                      required
                      error={!!meta.touched && !!meta.error}
                      label={intl.formatMessage({
                        id: 'sportItemSet.bannerImageFileId',
                      })}
                      description={intl.formatMessage({
                        id: 'sportItemSet.bannerImageFileId.description',
                      })}
                      mimetype="image"
                      imageConstraints={{
                        aspect: 16 / 9,
                        minWidth: 356,
                        minHeight: 200,
                        maxSize: 3000000,
                      }}
                      organismId={values.ownerOrganismId}
                    />
                  )}
                />

                <SAFormField
                  name="bannerVideoId"
                  render={({ input, meta }) => (
                    <Form.Field
                      control={FileInput}
                      {...input}
                      type={MediaType.VideoClip}
                      error={!!meta.touched && !!meta.error}
                      label={intl.formatMessage({
                        id: 'sportItemSet.bannerVideoId',
                      })}
                      description={intl.formatMessage({
                        id: 'sportItemSet.bannerVideoId.description',
                      })}
                      organismId={values.ownerOrganismId}
                    />
                  )}
                />
              </Segment>

              {/* Video Engine Selection*/}
              {!ownerOrganism && !ownerOrganismId && (
                <Message visible error>
                  <FormattedMessage id="sportItemSets.videoEngine.selectOrganism" />
                </Message>
              )}
              {values.type === SportItemSetType.SportEvent && ownerOrganismId && (
                <Fragment>
                  <Segment>
                    <Header as="h3">
                      {intl.formatMessage({
                        id: 'sportItemSets.form.sveEngine.selectLabel',
                      })}
                    </Header>

                    <div className={classnames('mb-4')}>
                      <SAFormField
                        name="videoEngine"
                        validate={validateRequired}
                        render={({ input, meta }) => (
                          <Form.Field
                            {...input}
                            disabled={!!edit}
                            control={SelectVideoEngineInput}
                            required
                            error={!!meta.touched && !!meta.error}
                            onChange={(data: VideoEngine) => {
                              input.onChange(data)
                              form.change('contentPushServiceNames', [])
                            }}
                          />
                        )}
                      />
                    </div>

                    {/* Peer to peer */}
                    {values.videoEngine !== VideoEngine.ExternalFeed &&
                      currentUser.can(Permission.ActivateGreenPlayback, organismId || undefined, true) && (
                        <SAFormField
                          name="activateGreenPlayback"
                          true
                          render={({ input, meta }) => (
                            <Form.Field error={!!meta.touched && !!meta.error}>
                              <CheckboxInput
                                {...input}
                                checked={values.activateGreenPlayback}
                                toggle
                                label={
                                  <label>
                                    <FormattedMessage id="sportItemSet.form.activeGreenPlayback" />
                                  </label>
                                }
                              />
                            </Form.Field>
                          )}
                        />
                      )}

                    {/* Sportall  Video Engine */}
                    {values.videoEngine === VideoEngine.Sportall && (
                      <>
                        {!values.sportEvent?.period ? (
                          <Message visible error>
                            <FormattedMessage id="sportItemSets.form.sveStreams.selectPeriod" />
                          </Message>
                        ) : (
                          <SportItemSetSVEFields
                            availableWMStreams={ownerOrganism?.availableWMStreams ?? []}
                            availableSVEChannels={sveChannelAvailability?.checkSVEChannelsAvailable}
                            wmTemplates={wmTemplates}
                            showScreachInputStream={values.contentPushServiceNames?.includes(
                              ContentPushStreamServiceName.Screach,
                            )}
                            hasLiveRunning={loadingHasLiveRunning || hasLiveRunning}
                            organismId={ownerOrganism?.id}
                            checkChannelLoading={checkSveChannelAvailableLoading}
                          />
                        )}
                      </>
                    )}

                    {/* Wildmoka Video Engine streams */}
                    {values.videoEngine === VideoEngine.Wildmoka && (
                      <>
                        <WMStreamsFields
                          organismWMStreams={ownerOrganism?.streams ?? []}
                          streamsConflicts={streamsConflicts}
                          wmTemplates={wmTemplates}
                          errors={errors}
                          loading={loadingOrganism}
                          onStreamIdsChange={setStreamsIds}
                        />

                        {areTemplatesEdited(values) && (
                          <Message visible warning>
                            <FormattedMessage id="sportEvent.templateChange" />
                          </Message>
                        )}
                      </>
                    )}
                  </Segment>
                </Fragment>
              )}

              {/* Tabs */}
              <Segment>
                <Header as="h3">{intl.formatMessage({ id: 'tabs.title' })}</Header>
                <SAFormField<Tab[] | undefined, HTMLElement>
                  name="tabs"
                  validate={tabs => {
                    if (!tabs?.length) return

                    const keys = ['titleT', 'url', 'position', 'icon'] as (keyof Tab)[]
                    if (tabs.some(tab => keys.some(key => !tab[key]))) {
                      return RequiredValue
                    }
                  }}
                  render={({ input, meta }) => (
                    <Form.Input
                      {...input}
                      control={TabEditMultiple}
                      error={!!meta.error}
                      defaultLocale={defaultLocale}
                      onChangeDefaultLocale={setDefaultLocale}
                      activeTranslation={activeTranslation}
                      onChangeActiveTranslation={setActiveTranslation}
                      selectedTranslations={selectedTranslations}
                      onChangeSelectedTranslations={setSelectedTranslations}
                    />
                  )}
                />
              </Segment>
              <Segment>
                <Header as="h3">{intl.formatMessage({ id: 'adCampaign' })}</Header>
                <SAFormField
                  name="adCampaignId"
                  render={({ input, meta }) => (
                    <Form.Field
                      {...input}
                      control={() => (
                        <SelectItemListInput
                          disabled={!currentUser.can(Permission.AdCampaignCreate, ownerOrganism?.id, true)}
                          value={adCampaignId}
                          loading={adCampaignLoading}
                          options={adCampaigns.map(adCampaign => ({
                            key: adCampaign.id,
                            value: adCampaign.id,
                            text: adCampaign.title,
                          }))}
                          onChange={value => {
                            setAdCampaignId(value)
                            input.onChange(value)
                          }}
                        />
                      )}
                      error={!!meta.touched && !!meta.error}
                    />
                  )}
                />
              </Segment>
              <Segment>
                <Header as="h3">{intl.formatMessage({ id: 'sportItem.restrictions' })}</Header>

                <div className="flex flex-col flex-1">
                  {ownerOrganismId && (
                    <SAFormField
                      name="accessRestriction"
                      render={() => (
                        <AccessRestrictionsFieldset
                          values={{
                            restrictionLevel: values.restrictionLevel,
                            offerIds: values.offerIds,
                          }}
                          onChange={restrictions => {
                            form.change('restrictionLevel', restrictions.restrictionLevel)
                            form.change('offerIds', restrictions.offerIds)
                          }}
                          organismId={ownerOrganismId}
                        />
                      )}
                    />
                  )}
                </div>

                <Divider />

                {/* Disable Read Parallel Livestreams Limit */}
                <SAFormField
                  name="disableReadParallelLivestreamsLimit"
                  render={({ input, meta }) => (
                    <Form.Field error={!!meta.touched && !!meta.error}>
                      <CheckboxInput
                        {...input}
                        toggle
                        checked={!values.disableReadParallelLivestreamsLimit}
                        onChange={() => input.onChange(!values.disableReadParallelLivestreamsLimit)}
                        label={
                          <label>
                            <FormattedMessage id="enableReadParallelLivestreamsLimit" />
                          </label>
                        }
                      />
                    </Form.Field>
                  )}
                />
              </Segment>

              {/* No Spoiler */}
              {type === SportItemSetType.SportEvent && (
                <Segment>
                  <Header as="h3">{intl.formatMessage({ id: 'sportItemSets.form.noSpoilerMode.title' })}</Header>
                  <div className="flex flex-col flex-1">
                    <SAFormField
                      name="noSpoilerMode.enabled"
                      render={({ input, meta }) => (
                        <Form.Field error={!!meta.touched && !!meta.error}>
                          <CheckboxInput
                            {...input}
                            toggle
                            checked={values.noSpoilerMode?.enabled}
                            label={intl.formatMessage({
                              id: 'sportItemSets.form.noSpoilerMode.enabled',
                            })}
                          />
                        </Form.Field>
                      )}
                    />

                    <SAFormField
                      name="noSpoilerMode.minutesBeforeDisabling"
                      render={({ input, meta }) => (
                        <Form.Field
                          className={classNames('w-1/2', { hidden: !values.noSpoilerMode?.enabled })}
                          disabled={!values.noSpoilerMode?.enabled}
                          error={!!meta.touched && !!meta.error}
                          label={intl.formatMessage({
                            id: 'sportItemSets.form.noSpoilerMode.minutesBeforeDisabling',
                          })}
                          control={NumberInput}
                          {...input}
                        />
                      )}
                    />
                  </div>
                </Segment>
              )}

              <Segment>
                <Header as="h3">{intl.formatMessage({ id: 'sportItem.details' })}</Header>
                {isAdmin &&
                  currentUser.can(Permission.SportItemSetCategoryEdition, ownerOrganismId || undefined, true) && (
                    <SAFormField
                      name="categoriesIds"
                      render={({ input, meta }: FieldRenderProps<string[], HTMLElement>) => (
                        <Form.Field error={!!meta.touched && !!meta.error}>
                          {isAdmin ? (
                            <label>
                              <FormattedMessage id="sportItemSet.categories.admin" />
                            </label>
                          ) : (
                            <label>
                              <FormattedMessage id="sportItemSet.categories" />
                            </label>
                          )}

                          <SelectCategoryInput multiple {...input} />
                        </Form.Field>
                      )}
                    />
                  )}

                <SAFormField
                  name="tagId"
                  render={({ input, meta }) => (
                    <Form.Field
                      control={() => (
                        <SelectItemListInput
                          disabled={!currentUser.can(Permission.SportItemSetAddTag, ownerOrganism?.id, true)}
                          value={input.value}
                          loading={loadingOrganism}
                          options={tagOptions(ownerOrganism?.tags || [])}
                          onChange={value => {
                            setSubTags([])
                            input.onChange(value)
                          }}
                          multiple={false}
                        />
                      )}
                      {...input}
                      filter={(ownerOrganism && ownerOrganism.tags) || undefined}
                      value={input.value}
                      error={!!meta.dirty && !!meta.error}
                      label={'Tag'}
                    />
                  )}
                />

                {values.tagId && (
                  <SAFormField
                    name="subTags"
                    render={({ input, meta }) => (
                      <Form.Field
                        control={() => (
                          <SelectItemListInput
                            disabled={!currentUser.can(Permission.SportItemSetAddTag, ownerOrganism?.id, true)}
                            value={subTags}
                            loading={loadingOrganism}
                            options={subtagOptions(
                              ownerOrganism?.tags?.find(tag => tag.id === values.tagId)?.subTags || undefined,
                            )}
                            onChange={value => {
                              setSubTags(value)
                              input.onChange(value)
                            }}
                            multiple
                          />
                        )}
                        {...input}
                        filter={(ownerOrganism && ownerOrganism.tags) || undefined}
                        value={input.value}
                        error={!!meta.dirty && !!meta.error}
                        label={intl.formatMessage({ id: 'sportItemSet.subtags' })}
                      />
                    )}
                  />
                )}

                <SAFormField
                  name="websiteUrl"
                  validate={validateUrl}
                  render={({ input, meta }) => (
                    <Form.Input
                      {...input}
                      error={!!meta.touched && !!meta.error}
                      label={intl.formatMessage({ id: 'sportItemSet.websiteUrl' })}
                      placeholder="http://www.sport.com"
                    />
                  )}
                />
              </Segment>

              {/* Moderation publication */}
              {currentUser.can(Permission.SportItemSetManagePublication, ownerOrganismId || undefined, true) && (
                <Segment>
                  <Header as="h3">{intl.formatMessage({ id: 'sportItemSet.lockPublishing' })}</Header>
                  <SAFormField
                    name="lockPublishingLive"
                    render={({ input, meta }) => (
                      <Form.Field error={!!meta.touched && !!meta.error}>
                        <CheckboxInput
                          {...input}
                          checked={values.lockPublishingLive}
                          label={
                            <label>
                              <FormattedMessage id="sportItemSet.lockPublishingLive" />
                            </label>
                          }
                        />
                      </Form.Field>
                    )}
                  />
                  <SAFormField
                    name="lockPublishingClip"
                    render={({ input, meta }) => (
                      <Form.Field error={!!meta.touched && !!meta.error}>
                        <CheckboxInput
                          {...input}
                          checked={values.lockPublishingClip}
                          label={
                            <label>
                              <FormattedMessage id="sportItemSet.lockPublishingClip" />
                            </label>
                          }
                        />
                      </Form.Field>
                    )}
                  />
                </Segment>
              )}

              {/* Content Access Control */}
              {isAdmin &&
                currentUser.can(
                  Permission.SportItemSetContentAccessControlUpdate,
                  ownerOrganismId || undefined,
                  true,
                ) && (
                  <>
                    <ContentAccessControlUserLocationFormFields
                      name="cac.userLocationRule"
                      values={values.cac.userLocationRule}
                      errors={errors?.cac}
                    />
                  </>
                )}

              {/* Chat */}
              {currentUser.can(Permission.ChatRoomsCreate, ownerOrganismId || undefined, true) && (
                <Segment>
                  <Header as="h3">{intl.formatMessage({ id: 'sportItemSet.form.chat' })}</Header>

                  <SAFormField
                    name="activateSportItemChatByDefault"
                    render={({ input, meta }) => (
                      <Form.Field error={!!meta.touched && !!meta.error}>
                        <CheckboxInput
                          {...input}
                          toggle
                          checked={values.activateSportItemChatByDefault || false}
                          label={
                            <label>
                              <FormattedMessage id="sportItemSet.activateSportItemChatByDefault" />
                            </label>
                          }
                        />
                      </Form.Field>
                    )}
                  />
                </Segment>
              )}

              {/* Content Push Service Stream */}
              {isAdmin &&
                currentUser.can(Permission.IncludeContentStream, ownerOrganismId || undefined, true) &&
                [VideoEngine.Sportall, VideoEngine.Wildmoka].includes(values.videoEngine) && (
                  <Segment>
                    <Header as="h3">{intl.formatMessage({ id: 'contentPushStream.title' })}</Header>
                    <p>{intl.formatMessage({ id: 'contentPushStream.include' })}</p>
                    {contentPushServices.map((serviceName: AvailableContentPushService, index) => (
                      <SAFormField
                        key={`${serviceName}${index}`}
                        name={'contentPushServiceNames'}
                        render={({ input, meta }: FieldRenderProps<SportItemSetFormValues, HTMLSelectElement>) => (
                          <Form.Field
                            error={!!meta.touched && !!meta.error}
                            className={classnames({
                              hidden:
                                values.videoEngine === VideoEngine.Wildmoka &&
                                serviceName.type === ContentPushStreamServiceName.Screach,
                            })}
                          >
                            <CheckboxInput
                              {...input}
                              onChange={(value: boolean) => {
                                if (value === false) {
                                  if (serviceName.type === ContentPushStreamServiceName.GenericStream)
                                    form.change('contentPushServiceGenericStreamIds', [])
                                  if (serviceName.type === ContentPushStreamServiceName.LiveEvent)
                                    form.change('contentPushServiceLiveEventIds', [])
                                }
                                onChangeContentPushServiceNames(value, input, values, serviceName)
                              }}
                              checked={
                                !!values.contentPushServiceNames?.includes(
                                  serviceName.type as ContentPushStreamServiceName,
                                )
                              }
                              label={serviceName.type}
                            />
                            {[
                              ContentPushStreamServiceName.GenericStream,
                              ContentPushStreamServiceName.LiveEvent,
                            ].includes(serviceName.type as ContentPushStreamServiceName) && (
                              <SAFormField
                                name={
                                  ContentPushStreamServiceName.GenericStream === serviceName.type
                                    ? 'contentPushServiceGenericStreamIds'
                                    : 'contentPushServiceLiveEventIds'
                                }
                                render={({
                                  input,
                                  meta,
                                }: FieldRenderProps<SportItemSetFormValues, HTMLSelectElement>) => (
                                  <Form.Field
                                    error={!!meta.touched && !!meta.error}
                                    className={classnames({
                                      hidden: !values.contentPushServiceNames?.includes(
                                        serviceName.type as ContentPushStreamServiceName,
                                      ),
                                    })}
                                    control={() => (
                                      <div className="mt-2">
                                        <SelectItemListInput
                                          multiple
                                          options={contentPushStreams
                                            .filter(item => item.contentDelivery?.type === serviceName.type)
                                            .map(cps => ({
                                              key: cps.id,
                                              value: cps.id,
                                              text: cps.name,
                                            }))}
                                          {...input}
                                          noNullValue
                                        />
                                      </div>
                                    )}
                                  />
                                )}
                              />
                            )}
                          </Form.Field>
                        )}
                      />
                    ))}
                  </Segment>
                )}

              {currentUser.can(
                Permission.ApplicationContentAccessControlUpdate,
                ownerOrganismId || undefined,
                true,
              ) && (
                <Segment>
                  <Header as="h3">{intl.formatMessage({ id: 'application.form.cac.userAuthentication.title' })}</Header>

                  <SAFormField
                    name="cac.userAuthenticationRule"
                    render={({ input }) => (
                      <>
                        <ContentAccessControlUserAuthenticationFormFields
                          onChange={value => input.onChange(value)}
                          value={input.value as CacUserAuthenticationRule | undefined}
                          withDisableButton
                          ownerOrganismId={ownerOrganismId}
                        />

                        <p className="text-sm italic pl-2 pt-1">
                          {intl.formatMessage({ id: 'application.form.cac.userAuthentication.helper' })}
                        </p>
                      </>
                    )}
                  />
                </Segment>
              )}
            </Fragment>
          )
        }}
      </GenericForm>
    )
  },
)
