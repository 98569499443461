import { graphql } from 'gql'

export const UserFragment = graphql(`
  fragment user on User {
    id
    email
    lastName
    firstName
    gender
    roleIds
    chatAuthorName
    createdAt
    lastActivity
    organismIds
    licenses {
      organismId
    }
    purchasableReceipts {
      offerId
    }
  }
`)

export const GetUsersQuery = graphql(
  `
    query users($filters: UserFilters, $offset: Int, $first: Int, $sortBy: SortBy) {
      users(filters: $filters, offset: $offset, first: $first, sortBy: $sortBy) {
        users {
          ...user
        }
        totalCount
      }
    }
  `,
  [UserFragment],
)
