import React, { FC, useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import { Button, ButtonProps, Popup } from 'semantic-ui-react'

import { CommandType } from '../EditorProvider'
import { useEditorCommand, useEditorState } from '../useEditor'

export type ToolbarButtonProps = ButtonProps & {
  cmd: CommandType
  payload?: string
  tooltipId: string
}

const ToolbarButton: FC<ToolbarButtonProps> = ({ cmd, payload, tooltipId, ...props }) => {
  const [state] = useEditorState()
  const [, dispatch] = useEditorCommand()

  const active = payload ? (state as any)?.[cmd]?.[payload]?.active : (state as any)?.[cmd]?.active
  const disabled = payload ? (state as any)?.[cmd]?.[payload]?.disabled : (state as any)?.[cmd]?.disabled

  const onClick = useCallback(() => {
    dispatch(cmd, payload)
  }, [cmd, dispatch, payload])

  return (
    <Popup
      content={<FormattedMessage id={`news.${tooltipId}`} />}
      trigger={<Button type="button" active={active} disabled={disabled} onClick={onClick} {...props} />}
    />
  )
}

export default ToolbarButton
