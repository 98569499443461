import { gql } from '@apollo/client'

export const GET_CATEGORIES_QUERY = gql`
  query categories {
    categories {
      id
      title
    }
  }
`

export const CREATE_CATEGORY_MUTATION = gql`
  mutation createCategory($input: CreateCategoryInput!) {
    createCategory(input: $input) {
      id
      title
    }
  }
`

export const UPDATE_CATEGORY_MUTATION = gql`
  mutation updateCategory($input: UpdateCategoryInput!) {
    updateCategory(input: $input) {
      id
      title
    }
  }
`

export const DELETE_CATEGORY_MUTATION = gql`
  mutation deleteCategory($id: ID!) {
    deleteCategory(id: $id)
  }
`
