import ColorInput from 'Components/Form/ColorInput'
import GenericForm from 'Components/Form/GenericForm'
import SAFormField from 'Components/Form/SAFormField'
import SelectLocalInput, { SelectedTranslationsType, TranslatedInput } from 'Components/Form/SelectLocalInput'
import FileInput from 'Containers/Form/FileInput'
import { ValidationErrors } from 'final-form'
import React, { useCallback, useState } from 'react'
import { useIntl } from 'react-intl'
import { Form } from 'semantic-ui-react'
import { Media, MediaType } from 'services/api/graphql'
import { composeValidators, validateColor, validateRequired } from 'tools/formValidators'
import { getSelectedTranslations, validateTranslation } from 'tools/translationTools'

export type SportFormValues = {
  id?: string // must be set when editing
  title: string
  titleT?: TranslatedInput | null
  defaultLocale?: string
  color: string
  cardImageFileId: string
  iconImageFileId: string
  bannerImageFileId: Media['id']
  bannerVideoId?: Media['id'] | null
}

interface SportFormProps {
  title: string
  edit: boolean // if true => Update Sport otherwise Add Sport
  initialValues?: SportFormValues
  onSubmit: (
    values: SportFormValues,
    defaultLocale: string,
    selectedTranslations: SelectedTranslationsType[],
  ) => Promise<void> | void
  onCancel?: () => void
  loading: boolean
}
const SportForm = ({ initialValues, onSubmit, onCancel, loading, title }: SportFormProps) => {
  const intl = useIntl()
  const [selectedTranslations, setSelectedTranslations] = useState<SelectedTranslationsType[]>(
    initialValues ? getSelectedTranslations(initialValues) : [],
  )
  const [defaultLocale, setDefaultLocale] = useState<string>(initialValues?.defaultLocale || '')
  const [activeTranslation, setActiveTranslation] = useState<string | undefined>(defaultLocale)

  const validate = useCallback(
    (values): ValidationErrors | Promise<ValidationErrors> | undefined => ({
      titleT: validateTranslation(activeTranslation, values?.titleT?.[`${defaultLocale}`], intl),
    }),
    [activeTranslation, defaultLocale, intl],
  )

  return (
    <GenericForm
      header={title}
      initialValues={initialValues}
      onCancel={onCancel}
      onSubmit={values => onSubmit(values, defaultLocale, selectedTranslations)}
      validate={validate}
      loading={loading}
    >
      <SAFormField
        name="locale"
        render={({ input }) => (
          <Form.Field
            {...input}
            disabled={false}
            label={intl.formatMessage({ id: 'locale' })}
            control={() => (
              <SelectLocalInput
                defaultLocale={defaultLocale}
                onChangeDefaultLocale={setDefaultLocale}
                activeTranslation={activeTranslation}
                onChangeActiveTranslation={setActiveTranslation}
                selectedTranslations={selectedTranslations}
                onChangeSelectedTranslations={setSelectedTranslations}
              />
            )}
          />
        )}
      />

      <SAFormField
        name={`titleT.${activeTranslation}`}
        render={({ input, meta }) => (
          <Form.Input
            {...input}
            required={activeTranslation === defaultLocale}
            error={!!meta.touched && !!meta.error}
            label={intl.formatMessage({ id: 'sport.title' })}
          />
        )}
      />

      <SAFormField
        name="color"
        validate={composeValidators<SportFormValues['color']>([validateColor, validateRequired])}
        render={({ input, meta }) => (
          <Form.Field
            control={ColorInput}
            {...input}
            required
            error={!!meta.touched && !!meta.error}
            label={intl.formatMessage({ id: 'sport.color' })}
          />
        )}
      />

      <SAFormField
        name="cardImageFileId"
        validate={validateRequired}
        render={({ input, meta }) => (
          <Form.Field
            control={FileInput}
            {...input}
            type={MediaType.Image}
            required
            error={!!meta.touched && !!meta.error}
            label={intl.formatMessage({ id: 'sport.cardImage' })}
            description={intl.formatMessage({
              id: 'sport.cardImage.description',
            })}
            mimetype="image"
            imageConstraints={{ aspect: 3 / 2, minWidth: 150, minHeight: 225, maxSize: 3000000 }}
            renderOrganismPicker
          />
        )}
      />

      <SAFormField
        name="iconImageFileId"
        validate={validateRequired}
        render={({ input, meta }) => (
          <Form.Field
            control={FileInput}
            {...input}
            type={MediaType.Image}
            required
            error={!!meta.touched && !!meta.error}
            label={intl.formatMessage({ id: 'sport.icon' })}
            mimetype="image"
            imageConstraints={{ aspect: 1, minWidth: 150, minHeight: 150, maxSize: 3000000 }}
            renderOrganismPicker
          />
        )}
      />

      <SAFormField
        name="bannerImageFileId"
        validate={validateRequired}
        render={({ input, meta }) => (
          <Form.Field
            control={FileInput}
            {...input}
            type={MediaType.Image}
            required
            error={!!meta.touched && !!meta.error}
            label={intl.formatMessage({ id: 'sport.bannerImage' })}
            description={intl.formatMessage({
              id: 'sport.bannerImage.description',
            })}
            mimetype="image"
            imageConstraints={{ aspect: 16 / 9, minWidth: 356, minHeight: 200, maxSize: 3000000 }}
            renderOrganismPicker
          />
        )}
      />

      <SAFormField
        name="bannerVideoId"
        render={({ input, meta }) => (
          <Form.Field
            control={FileInput}
            {...input}
            type={MediaType.VideoClip}
            error={!!meta.touched && !!meta.error}
            label={intl.formatMessage({ id: 'sport.bannerVideoId' })}
            description={intl.formatMessage({
              id: 'sport.bannerVideoId.description',
            })}
            renderOrganismPicker
          />
        )}
      />
    </GenericForm>
  )
}

export default SportForm
