import GenericForm, { GenericFormProps } from 'Components/Form/GenericForm'
import SAFormField from 'Components/Form/SAFormField'
import { ValidationErrors } from 'final-form'
import React, { Fragment, useCallback } from 'react'
import { useIntl } from 'react-intl'
import { Form } from 'semantic-ui-react'
import { validateRequired } from 'tools/formValidators'

export interface SVEChannelFormValues {
  name: string
}

export interface SVEChannelFormProps extends Omit<GenericFormProps<SVEChannelFormValues>, 'header'> {
  sveChannelId?: string
  title: string
  edit: boolean // if true => Update User otherwise Add User
  onDone: (values: SVEChannelFormValues) => void
  onError: (error: Error) => void
}
export const SVEChannelForm = ({ title, sveChannelId, ...props }: SVEChannelFormProps) => {
  const intl = useIntl()

  const validate = useCallback(
    (values: SVEChannelFormValues): ValidationErrors | Promise<ValidationErrors> | undefined => {
      if (!values.name) {
        return intl.formatMessage({ id: 'sveChannels.form.invalidChannelName' }) as any
      }
      return
    },
    [intl],
  )
  return (
    <GenericForm header={title} validate={validate} {...props}>
      {({}) => (
        <Fragment>
          <SAFormField
            name="name"
            validate={validateRequired}
            render={({ input, meta }) => (
              <Form.Input {...input} required error={!!meta.touched && !!meta.error} label="Nom" autoComplete="off" />
            )}
          />
          {sveChannelId && <span>{`Channel Id: ${sveChannelId}`}</span>}
        </Fragment>
      )}
    </GenericForm>
  )
}
