import ColorInput from 'Components/Form/ColorInput'
import SAFormField from 'Components/Form/SAFormField'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Form, Header, TextArea } from 'semantic-ui-react'

const ApplicationDesignFormFields = () => {
  const intl = useIntl()

  return (
    <Form>
      <SAFormField
        name="title"
        render={({ input, meta }) => (
          <Form.Input
            {...input}
            error={!!meta.touched && !!meta.error}
            required
            placeholder={intl.formatMessage({
              id: 'applicationDesign.title.placeholder',
            })}
            label={intl.formatMessage({ id: 'applicationDesign.title' })}
          />
        )}
      />
      <SAFormField
        name="description"
        render={({ input, meta }) => (
          <Form.Input
            control={TextArea}
            {...input}
            error={!!meta.touched && !!meta.error}
            required
            placeholder={intl.formatMessage({
              id: 'applicationDesign.description.placeholder',
            })}
            label={intl.formatMessage({ id: 'applicationDesign.description' })}
          />
        )}
      />
      <Header as="h2">
        <FormattedMessage id="applicationDesign.theme" />
      </Header>

      <Header as="h3">
        <FormattedMessage id="applicationDesign.theme.primaryColors" />
      </Header>

      <Form.Group widths="equal">
        <SAFormField
          name="theme[primaryColor]"
          render={({ input, meta }) => (
            <Form.Field
              control={ColorInput}
              required
              {...input}
              error={!!meta.touched && !!meta.error}
              label={intl.formatMessage({
                id: 'applicationDesign.theme.primaryColor',
              })}
            />
          )}
        />

        <SAFormField
          name="theme[onPrimaryColor]"
          render={({ input, meta }) => (
            <Form.Field
              control={ColorInput}
              required
              {...input}
              error={!!meta.touched && !!meta.error}
              label={intl.formatMessage({
                id: 'applicationDesign.theme.onPrimaryColor',
              })}
            />
          )}
        />
      </Form.Group>

      <Header as="h3">
        <FormattedMessage id="applicationDesign.theme.secondaryColors" />
      </Header>

      <Form.Group widths="equal">
        <SAFormField
          name="theme[surfaceColor]"
          render={({ input, meta }) => (
            <Form.Field
              control={ColorInput}
              required
              {...input}
              error={!!meta.touched && !!meta.error}
              label={intl.formatMessage({
                id: 'applicationDesign.theme.surfaceColor',
              })}
            />
          )}
        />

        <SAFormField
          name="theme[onSurfaceColor]"
          render={({ input, meta }) => (
            <Form.Field
              control={ColorInput}
              required
              {...input}
              error={!!meta.touched && !!meta.error}
              label={intl.formatMessage({
                id: 'applicationDesign.theme.onSurfaceColor',
              })}
            />
          )}
        />
      </Form.Group>

      <Form.Group widths="equal">
        <SAFormField
          name="theme[onSurfaceAlternateColor]"
          render={({ input, meta }) => (
            <Form.Field
              control={ColorInput}
              required
              {...input}
              error={!!meta.touched && !!meta.error}
              label={intl.formatMessage({
                id: 'applicationDesign.theme.onSurfaceAlternateColor',
              })}
            />
          )}
        />
      </Form.Group>

      <Header as="h3">
        <FormattedMessage id="applicationDesign.theme.top10Colors" />
      </Header>

      <Form.Group widths="equal">
        <SAFormField
          name="theme[top10Color]"
          render={({ input, meta }) => (
            <Form.Field
              control={ColorInput}
              required
              {...input}
              error={!!meta.touched && !!meta.error}
              label={intl.formatMessage({
                id: 'applicationDesign.theme.top10Color',
              })}
            />
          )}
        />

        <SAFormField
          name="theme[top10BorderColor]"
          render={({ input, meta }) => (
            <Form.Field
              control={ColorInput}
              {...input}
              error={!!meta.touched && !!meta.error}
              label={intl.formatMessage({
                id: 'applicationDesign.theme.top10BorderColor',
              })}
            />
          )}
        />
      </Form.Group>
    </Form>
  )
}

export default ApplicationDesignFormFields
