import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $insertNodeToNearestRoot } from '@lexical/utils'
import { COMMAND_PRIORITY_EDITOR, createCommand, LexicalCommand } from 'lexical'
import { FC, useEffect } from 'react'

import { $createTweetNode, TweetNode, TweetPayload } from './TweetNode'

export const INSERT_TWEET_COMMAND: LexicalCommand<TweetPayload> = createCommand('INSERT_TWEET_COMMAND')

const TweetPlugin: FC = () => {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    if (!editor.hasNodes([TweetNode])) {
      throw new Error('TwitterPlugin: TweetNode not registered on editor')
    }

    return editor.registerCommand<TweetPayload>(
      INSERT_TWEET_COMMAND,
      payload => {
        const tweetNode = $createTweetNode(payload)
        $insertNodeToNearestRoot(tweetNode)

        return true
      },
      COMMAND_PRIORITY_EDITOR,
    )
  }, [editor])

  return null
}

export default TweetPlugin

export * from './TweetNode'
