import React, { useCallback, FC } from 'react'
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl'
import { Table } from 'semantic-ui-react'
import { SveBroadcastContentFragment, SveBroadcastState } from 'services/api/graphql'

interface DateViewProps {
  date: Date | string | undefined | null
}
const DateView: FC<DateViewProps> = ({ date }) => {
  if (!date) return <span>{`-`}</span>
  return (
    <FormattedDate
      day="2-digit"
      month="2-digit"
      year="numeric"
      hour="2-digit"
      minute="2-digit"
      second="2-digit"
      value={new Date(date)}
    />
  )
}
export interface SVEBroadcastsTableProps {
  sveBroadcasts: SveBroadcastContentFragment[]
}

export const SVEBroadcastsTable: React.FC<SVEBroadcastsTableProps> = ({ sveBroadcasts }) => {
  const intl = useIntl()
  const sveBroadcastStateString = useCallback(
    (state: SveBroadcastState): string => {
      switch (state) {
        case SveBroadcastState.Scheduled:
          return intl.formatMessage({ id: 'sveBroadcasts.state.scheduled' })
        case SveBroadcastState.Starting:
          return intl.formatMessage({ id: 'sveBroadcasts.state.starting' })
        case SveBroadcastState.Running:
          return intl.formatMessage({ id: 'sveBroadcasts.state.running' })
        case SveBroadcastState.Stopping:
          return intl.formatMessage({ id: 'sveBroadcasts.state.stopping' })
        case SveBroadcastState.Stopped:
          return intl.formatMessage({ id: 'sveBroadcasts.state.stopped' })
      }
    },
    [intl],
  )

  return (
    <>
      <Table style={{ flex: 1 }} sortable>
        <Table.Header>
          <Table.Row textAlign="center">
            <Table.HeaderCell width={2}>
              <FormattedMessage id="sveBroadcasts.table.id" />
            </Table.HeaderCell>
            <Table.HeaderCell width={1}>
              <FormattedMessage id="sveBroadcasts.table.state" />
            </Table.HeaderCell>
            <Table.HeaderCell width={1}>
              <FormattedMessage id="sveBroadcasts.table.scheduledStartDate" />
            </Table.HeaderCell>
            <Table.HeaderCell width={1}>
              <FormattedMessage id="sveBroadcasts.table.scheduledClipStartDate" />
            </Table.HeaderCell>
            <Table.HeaderCell width={1}>
              <FormattedMessage id="sveBroadcasts.table.scheduledStopDate" />
            </Table.HeaderCell>
            <Table.HeaderCell width={1}>
              <FormattedMessage id="sveBroadcasts.table.startDate" />
            </Table.HeaderCell>
            <Table.HeaderCell width={1}>
              <FormattedMessage id="sveBroadcasts.table.clipStartDate" />
            </Table.HeaderCell>
            <Table.HeaderCell width={1}>
              <FormattedMessage id="sveBroadcasts.table.stopDate" />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {sveBroadcasts.length > 0 ? (
            sveBroadcasts.map(sveBroadcast => {
              const {
                id,
                status,
                scheduledStartDate,
                scheduledClipStartDate,
                scheduledStopDate,
                startDate,
                clipStartDate,
                stopDate,
              } = sveBroadcast
              return (
                <Table.Row key={id}>
                  <Table.Cell textAlign="center">
                    <span>{id}</span>
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <span>{sveBroadcastStateString(status)}</span>
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <DateView date={scheduledStartDate} />
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <DateView date={scheduledClipStartDate} />
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <DateView date={scheduledStopDate} />
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <DateView date={startDate} />
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <DateView date={clipStartDate} />
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <DateView date={stopDate} />
                  </Table.Cell>
                </Table.Row>
              )
            })
          ) : (
            <Table.Row>
              <Table.Cell width={3} textAlign="center">
                Aucun broadcast
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </>
  )
}
