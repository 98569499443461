import { Draggable } from '@hello-pangea/dnd'
import EditNewsButton from 'Forms/News/EditNewsButton'
import EditSportItemButton from 'Forms/SportItem/EditSportItemButton'
import EditSportItemSetButton from 'Forms/SportItemSet/EditSportItemSetButton'
import { ResultOf } from 'gql.tada'
import React, { useState } from 'react'
import { Button, Icon, Modal, Ref, Table } from 'semantic-ui-react'
import { SportItemSet } from 'services/api/graphql'

import { FieldsType } from './EditedCategoryFormFields'
import { LinkItem } from './LinkItem'
import { LinkItemFragment } from './LinkItem/fragments'
import { NewsItem } from './NewsItem'
import { NewsItemFragment } from './NewsItem/fragments'
import { SportItemSetItem } from './SportItemSetItem'

interface PageItemsFieldsProps {
  index: number
  name: string
  fields: FieldsType[]
  sportItemSets?: SportItemSet[]
  newss?: ResultOf<typeof NewsItemFragment>[]
  links?: ResultOf<typeof LinkItemFragment>[]
  onRemove: () => void
  onChangeFields: (index: number, item: FieldsType) => void
}

export const EditedCategoryFormItemFields = ({
  index,
  name,
  sportItemSets,
  newss,
  links,
  onRemove,
  fields,
  onChangeFields,
}: PageItemsFieldsProps) => {
  const isLink = fields[index]?.linkId !== undefined && fields[index]?.linkId !== null
  const isNews = fields[index]?.newsId !== undefined && fields[index]?.newsId !== null
  const isSportItemSets = fields[index]?.sportItemSetId !== undefined && fields[index]?.sportItemSetId !== null
  const isSportItem = fields[index]?.sportItemId !== undefined && fields[index]?.sportItemId !== null

  const [openNewsModal, setOpenNewsModal] = useState(false)
  const [openSportItemSetModal, setOpenSportItemSetModal] = useState(false)
  const [openSportItemModal, setOpenSportItemModal] = useState(false)

  return (
    <Draggable draggableId={name} index={index}>
      {provided => {
        return (
          <Ref innerRef={provided.innerRef}>
            <Table.Row {...provided.draggableProps} style={{ backgroundColor: isLink ? '#ebf6ff' : '' }}>
              <Table.Cell collapsing textAlign="center" {...provided.dragHandleProps}>
                <Icon name="bars" />
              </Table.Cell>
              {!isNews && !isLink && (
                <SportItemSetItem
                  fields={fields}
                  index={index}
                  name={name}
                  onChangeFields={onChangeFields}
                  sportItemSets={sportItemSets}
                />
              )}
              {isNews && (
                <NewsItem fields={fields} index={index} name={name} onChangeFields={onChangeFields} newss={newss} />
              )}
              {isLink && (
                <LinkItem fields={fields} index={index} name={name} onChangeFields={onChangeFields} links={links} />
              )}
              <Table.Cell verticalAlign="bottom">
                {newss && fields[index].newsId && (
                  <Modal
                    mountNode={document.getElementById('modals')}
                    trigger={<Icon name="eye" link size="large" />}
                    content={
                      <EditNewsButton newsViewModalId={fields[index].newsId} onClose={() => setOpenNewsModal(false)} />
                    }
                    onOpen={() => setOpenNewsModal(true)}
                    open={openNewsModal}
                  />
                )}

                {isSportItem && fields[index].sportItemId && (
                  <Modal
                    mountNode={document.getElementById('modals')}
                    trigger={<Icon name="eye" link size="large" />}
                    content={
                      <EditSportItemButton
                        sportItemViewModalId={fields[index].sportItemId}
                        onClose={() => setOpenSportItemModal(false)}
                      />
                    }
                    onOpen={() => setOpenSportItemModal(true)}
                    open={openSportItemModal}
                  />
                )}
                {isSportItemSets && !isSportItem && fields[index].sportItemSetId && (
                  <Modal
                    mountNode={document.getElementById('modals')}
                    trigger={<Icon name="eye" link size="large" />}
                    content={
                      <EditSportItemSetButton
                        sportItemSetViewModalId={fields[index].sportItemSetId}
                        onClose={() => setOpenSportItemSetModal(false)}
                      />
                    }
                    onOpen={() => setOpenSportItemSetModal(true)}
                    open={openSportItemSetModal}
                  />
                )}
                <Button icon onClick={onRemove} size="small">
                  <Icon name="remove" />
                </Button>
              </Table.Cell>
            </Table.Row>
          </Ref>
        )
      }}
    </Draggable>
  )
}
