import GenericForm from 'Components/Form/GenericForm'
import NumberInput from 'Components/Form/NumberInput'
import SAFormField from 'Components/Form/SAFormField'
import { SelectDateInput } from 'Components/Form/SelectDateInput'
import arrayMutators from 'final-form-arrays'
import { ContentDeliveryFrequency, ContentPushStreamServiceName } from 'models'
import React, { useCallback } from 'react'
import { FieldInputProps } from 'react-final-form'
import { useIntl } from 'react-intl'
import { Button, Form, Header, Icon, Segment, Table } from 'semantic-ui-react'
import { validateRequired } from 'tools/formValidators'

import { ContentDeliveryServiceConfigForm } from '.'

type ContentPushStreamDeliveryConfModalProps = {
  delivery: ContentDeliveryServiceConfigForm
  customDestination?: boolean
  onCancel?: () => void
  onDone?: (values: any) => void
}

export const ContentPushStreamDeliveryConfModal = ({
  delivery,
  customDestination,
  onCancel,
  onDone,
}: ContentPushStreamDeliveryConfModalProps) => {
  const intl = useIntl()

  const selectFrequency = useCallback(
    (event: React.MouseEvent, input: FieldInputProps<unknown, HTMLElement>, frequency: ContentDeliveryFrequency) => {
      event.preventDefault()
      input.onChange(frequency)
    },
    [],
  )
  return (
    <GenericForm
      header={intl.formatMessage({ id: 'contentPushStream.create.title' }, { type: delivery.type })}
      initialValues={delivery} // Force type value if given as param
      mutators={{ ...arrayMutators }}
      onCancel={onCancel}
      onSubmit={(values: ContentDeliveryServiceConfigForm) => onDone?.(values)}
      loading={false}
      open={true}
    >
      {({ values: { periodicity, outputStreams }, form: { mutators } }) => {
        return (
          <>
            <Segment>
              {/* Frequency */}
              <Header as="h4">{intl.formatMessage({ id: 'contentPushStream.sendingFrequency' })}</Header>

              <SAFormField
                name="periodicity.frequency"
                validate={validateRequired}
                render={({ input }) => (
                  <Form.Field required>
                    <Button.Group>
                      {/* Daily */}
                      <Button
                        color={periodicity?.frequency === ContentDeliveryFrequency.Daily ? 'blue' : undefined}
                        onClick={e => selectFrequency(e, input, ContentDeliveryFrequency.Daily)}
                      >
                        <span className="capitalize">{intl.formatMessage({ id: 'date.frequency.day' })}</span>
                      </Button>

                      {/* Weekly */}
                      <Button
                        color={periodicity?.frequency === ContentDeliveryFrequency.Weekly ? 'blue' : undefined}
                        onClick={e => selectFrequency(e, input, ContentDeliveryFrequency.Weekly)}
                      >
                        <span className="capitalize">{intl.formatMessage({ id: 'date.frequency.week' })}</span>
                      </Button>

                      {/* Monthly */}
                      <Button
                        color={periodicity?.frequency === ContentDeliveryFrequency.Monthly ? 'blue' : undefined}
                        onClick={e => selectFrequency(e, input, ContentDeliveryFrequency.Monthly)}
                      >
                        <span className="capitalize">{intl.formatMessage({ id: 'date.frequency.month' })}</span>
                      </Button>
                    </Button.Group>
                  </Form.Field>
                )}
              />

              {/* First send date */}
              <SAFormField
                name="periodicity.firstSendDate"
                validate={validateRequired}
                render={({ input, meta }) => (
                  <Form.Field
                    {...input}
                    required
                    control={SelectDateInput}
                    minDate={new Date()}
                    error={!!meta.touched && !!meta.error}
                    label={intl.formatMessage({ id: 'contentPushStream.firstSend' })}
                  />
                )}
              />

              {/* Retentin duration days (only for LiveEvent template ) */}
              {delivery.type === ContentPushStreamServiceName.LiveEvent && (
                <SAFormField
                  name="retentionDurationDays"
                  validate={validateRequired}
                  render={({ input, meta }) => (
                    <Form.Field
                      required
                      error={!!meta.touched && !!meta.error}
                      label={intl.formatMessage({ id: 'contentPushStream.create.retentionDays' })}
                      control={NumberInput}
                      {...input}
                    />
                  )}
                />
              )}
            </Segment>

            {/* Destination */}
            {customDestination && (
              <>
                <Segment>
                  <Header as="h4">{intl.formatMessage({ id: 'contentPushStream.create.destination.title' })}</Header>

                  {/* Bucket Name */}
                  <SAFormField
                    name={`destination.bucketName`}
                    validate={validateRequired}
                    render={({ input, meta }) => (
                      <Form.Input
                        {...input}
                        required
                        error={meta.touched && meta.error}
                        label={intl.formatMessage({ id: 'contentPushStream.create.destination.bucketName' })}
                      />
                    )}
                  />

                  {/* Bucket path optionnal */}
                  <SAFormField
                    name={`destination.bucketPath`}
                    render={({ input, meta }) => (
                      <Form.Input
                        {...input}
                        required={false}
                        error={meta.touched && meta.error}
                        placeholder={intl.formatMessage({
                          id: 'contentPushStream.create.destination.bucketPathPlaceholder',
                        })}
                        label={intl.formatMessage({ id: 'contentPushStream.create.destination.bucketPath' })}
                      />
                    )}
                  />

                  {/* Access key */}
                  <SAFormField
                    name={`destination.accessKey`}
                    validate={validateRequired}
                    render={({ input, meta }) => (
                      <Form.Input
                        {...input}
                        required
                        error={meta.touched && meta.error}
                        label={intl.formatMessage({ id: 'contentPushStream.create.destination.accessKey' })}
                      />
                    )}
                  />

                  {/* Secret key */}
                  <SAFormField
                    name={`destination.secretKey`}
                    validate={validateRequired}
                    render={({ input, meta }) => (
                      <Form.Input
                        {...input}
                        required
                        error={meta.touched && meta.error}
                        label={intl.formatMessage({ id: 'contentPushStream.create.destination.secretKey' })}
                      />
                    )}
                  />

                  {/* Bucket region */}
                  <SAFormField
                    name={`destination.region`}
                    validate={validateRequired}
                    render={({ input, meta }) => (
                      <Form.Input
                        {...input}
                        required
                        error={meta.touched && meta.error}
                        label={intl.formatMessage({ id: 'contentPushStream.create.destination.region' })}
                      />
                    )}
                  />

                  {/* Endpoint url (optionnal) */}
                  <SAFormField
                    name={`destination.endpointUrl`}
                    render={({ input, meta }) => (
                      <Form.Input
                        {...input}
                        error={meta.touched && meta.error}
                        label={intl.formatMessage({ id: 'contentPushStream.create.destination.endpointUrl' })}
                      />
                    )}
                  />
                </Segment>
              </>
            )}

            {/* Output streams */}
            {delivery.type === ContentPushStreamServiceName.Screach && (
              <Segment>
                <div className="flex w-full justify-between items-center">
                  <Header as="h4">{intl.formatMessage({ id: 'contentPushStream.create.output.title' })}</Header>

                  <Button
                    circular
                    icon="add"
                    color="red"
                    onClick={e => {
                      e.preventDefault()
                      mutators.push('outputStreams', {})
                    }}
                  />
                </div>

                {(outputStreams?.length ?? 0) > 0 && (
                  <Table style={{ flex: 1 }}>
                    <Table.Header>
                      <Table.Row textAlign="center">
                        <Table.HeaderCell width={6}>
                          {intl.formatMessage({ id: 'contentPushStream.create.output.name' })}
                        </Table.HeaderCell>

                        <Table.HeaderCell width={14}>
                          {intl.formatMessage({ id: 'contentPushStream.create.output.url' })}
                        </Table.HeaderCell>

                        <Table.HeaderCell width={1} />
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {outputStreams?.map((_, index) => (
                        <Table.Row key={index}>
                          {/* Name */}
                          <Table.Cell>
                            <SAFormField
                              name={`outputStreams[${index}].name`}
                              validate={validateRequired}
                              render={({ input, meta }) => (
                                <Form.Input {...input} required error={meta.touched && meta.error} />
                              )}
                            />
                          </Table.Cell>

                          {/* URL */}
                          <Table.Cell>
                            <SAFormField
                              name={`outputStreams[${index}].outputUrl`}
                              validate={validateRequired}
                              render={({ input, meta }) => (
                                <Form.Input {...input} required error={meta.touched && meta.error} />
                              )}
                            />
                          </Table.Cell>

                          <Table.Cell>
                            <Icon
                              name="remove"
                              link
                              size="large"
                              inverted
                              color="red"
                              onClick={() => mutators.remove('outputStreams', index)}
                            />
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                )}

                {!outputStreams?.length && (
                  <span>{intl.formatMessage({ id: 'contentPushStream.create.output.empty' })}</span>
                )}
              </Segment>
            )}
          </>
        )
      }}
    </GenericForm>
  )
}
