import Loader from 'Components/Loader'
import PageHeader from 'Components/PageHeader'
import ConfirmationButton from 'Containers/Button/ConfirmationButton'
import { ContentDeliveryStatus, ContentPushStream, ContentPushStreamServiceName } from 'models'
import {
  useDeleteContentPushStream,
  useGetAvailableContentPushServices,
  useGetContentPushStreams,
  useGetOutputStreams,
  useSyncNow,
} from 'models/ContentPushStream'
import React, { FC, useCallback, useState } from 'react'
import { useIntl } from 'react-intl'
import { Button, Grid, Icon, Popup, Table } from 'semantic-ui-react'
import { notifySuccess } from 'tools/toaster'

import { ContentPushStreamModal } from './modal'

const ContentPushStreamsView: FC = () => {
  const intl = useIntl()

  const [openModal, setOpenModal] = useState(false)
  const [selectedServiceId, setServiceId] = useState<string>()

  // Service names
  const { data: services, loading: loadingServices } = useGetAvailableContentPushServices()

  // Content push streams
  const {
    data: contentPushStreams,
    loading: loadingContentPushStreams,
    refetch: refetchContentPushStreams,
  } = useGetContentPushStreams()

  // Output streams
  const { data: outputStreams, loading: loadingOutputStreams, refetch: refetchOutputStreams } = useGetOutputStreams()

  // Send CPS now
  const [syncNow, { loading: loadingSyncNow }] = useSyncNow()
  const sendNow = useCallback(
    async (id: string, name: string) => {
      await syncNow({ variables: { id } })
      notifySuccess(intl.formatMessage({ id: 'contentPushStream.syncNow' }, { name }))
    },
    [syncNow, intl],
  )

  // Edit CPS
  const editCps = useCallback((id: string) => {
    setOpenModal(true)
    setServiceId(id)
  }, [])

  // Delete CPD
  const [deleteContentPushStream, { loading: loadingDeleteContentPushStream }] = useDeleteContentPushStream()
  const deleteCps = useCallback(
    async (id: string) => {
      await deleteContentPushStream({ variables: { id } })
      refetchContentPushStreams()
    },
    [deleteContentPushStream, refetchContentPushStreams],
  )

  // Close CPS modal
  const closeModal = useCallback(() => {
    setOpenModal(false)
    setServiceId(undefined)
  }, [])

  const loading =
    loadingServices ||
    loadingContentPushStreams ||
    loadingOutputStreams ||
    loadingDeleteContentPushStream ||
    loadingSyncNow

  return (
    <Loader loading={loading}>
      <Grid padded>
        <Grid.Column>
          <div className="flex w-full justify-between items-center">
            <PageHeader title={intl.formatMessage({ id: 'contentPushStream.title' })} />

            <Button
              loading={loading}
              disabled={loading}
              circular
              icon="add"
              color="red"
              onClick={() => setOpenModal(true)}
            />
          </div>
          <Table style={{ flex: 1 }}>
            <Table.Header>
              <Table.Row textAlign="center">
                <Table.HeaderCell textAlign="left" width={1}>
                  {intl.formatMessage({ id: 'contentPushStream.name' })}
                </Table.HeaderCell>
                <Table.HeaderCell width={2}>{intl.formatMessage({ id: 'contentPushStream.type' })}</Table.HeaderCell>
                <Table.HeaderCell width={1}>{'Status'}</Table.HeaderCell>
                <Table.HeaderCell width={1}>{intl.formatMessage({ id: 'contentPushStream.actions' })}</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {contentPushStreams?.map(
                ({ id, name, contentDeliveryServiceConfig: { type }, contentDelivery }: ContentPushStream) => (
                  <Table.Row key={id} textAlign="center">
                    {/* Name */}
                    <Table.Cell textAlign="left">{name}</Table.Cell>

                    {/* Type */}
                    <Table.Cell>{type}</Table.Cell>

                    {/* Status */}
                    <Table.Cell>
                      {contentDelivery?.status === ContentDeliveryStatus.Failed ? (
                        <div className="flex justify-center">
                          <Popup
                            content={contentDelivery.statusDetails}
                            trigger={
                              <span className="cursor-pointer w-1/4 text-red-600 font-bold">
                                {intl.formatMessage({ id: `contentPushStream.status.failed` })}
                              </span>
                            }
                          />
                        </div>
                      ) : (
                        <span>
                          {type === ContentPushStreamServiceName.Screach || !contentDelivery
                            ? 'N/A'
                            : intl.formatMessage({ id: `contentPushStream.status.${contentDelivery?.status}` })}
                        </span>
                      )}
                    </Table.Cell>

                    {/* Actions */}
                    <Table.Cell textAlign="right">
                      <Button color="blue" onClick={() => sendNow(id, name)}>
                        {intl.formatMessage({ id: 'contentPushStream.sendNow' })}
                      </Button>

                      <span className="ml-2">
                        <Icon name="edit" link size="large" color="grey" onClick={() => editCps(id)} />
                      </span>

                      <ConfirmationButton
                        action={() => deleteCps(id)}
                        onDone={() => refetchContentPushStreams()}
                        successText={intl.formatMessage(
                          {
                            id: 'contentPushStream.deleted',
                          },
                          { name },
                        )}
                        confirmText={intl.formatMessage(
                          {
                            id: 'contentPushStream.delete',
                          },
                          { name },
                        )}
                      >
                        <Icon name="remove" link size="large" inverted color="red" />
                      </ConfirmationButton>
                    </Table.Cell>
                  </Table.Row>
                ),
              )}
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid>

      {openModal && (
        <ContentPushStreamModal
          services={services}
          outputStreams={outputStreams}
          cps={selectedServiceId ? contentPushStreams.find(cps => cps.id === selectedServiceId) : undefined}
          onCancel={() => closeModal()}
          onDone={() => {
            closeModal()
            refetchContentPushStreams()
            refetchOutputStreams()
          }}
        />
      )}
    </Loader>
  )
}

export default ContentPushStreamsView
