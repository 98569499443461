import { FragmentOf } from 'gql.tada'
import React from 'react'
import { Button, Icon, Table } from 'semantic-ui-react'
import { AffiliateType } from 'services/api/graphql'

import { AffiliateFieldType } from './AdCampaignFormFields'
import { AffiliateDisplayType } from './AffiliateDisplayTypes'
import { AffiliateLinkItem } from './AffiliateItem'
import { DisplayWeight } from './DisplayWeight'
import { LinkTitleTagFragment } from './fragments'

interface ItemsFieldsProps {
  index: number
  name: string
  item: AffiliateFieldType
  links?: FragmentOf<typeof LinkTitleTagFragment>[] | null
  onRemove: () => void
  onChangeFields: (index: number, item: AffiliateFieldType) => void
  indexHasButton: number
}

export const AdCampaignFormItemFields = ({
  item,
  onRemove,
  onChangeFields,
  links,
  name,
  index,
  indexHasButton,
}: ItemsFieldsProps) => (
  <Table.Row style={{ backgroundColor: item.type === AffiliateType.Link ? '#ebf6ff' : '#FFFACD' }}>
    <Table.Cell colSpan={1}>
      <AffiliateLinkItem item={item} index={index} name={name} onChangeFields={onChangeFields} links={links} />
    </Table.Cell>
    <Table.Cell colSpan={1}>
      <AffiliateDisplayType indexHasButton={indexHasButton} onChangeFields={onChangeFields} index={index} item={item} />
    </Table.Cell>
    <Table.Cell>
      <DisplayWeight index={index} item={item} onChangeFields={onChangeFields} />
    </Table.Cell>
    <Table.Cell>
      <Button icon onClick={onRemove} size="small">
        <Icon name="remove" />
      </Button>
    </Table.Cell>
  </Table.Row>
)
