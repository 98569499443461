import _ from 'lodash'
import React from 'react'
import { Button } from 'semantic-ui-react'

export type SwitchOption<T> = {
  key: string | number | boolean
  value: T
  text: string
}
export interface SwitchInputProps<T> {
  value?: T
  onChange: (value: T) => void
  options: Array<SwitchOption<T>>
}
function SwitchInput<T>({ value, onChange, options }: SwitchInputProps<T>) {
  return (
    <Button.Group>
      {_.map(options, option => (
        <Button
          key={`${option.key}`}
          primary={value === option.value}
          onClick={event => {
            event.preventDefault()
            onChange(option.value)
          }}
        >
          {option.text}
        </Button>
      ))}
    </Button.Group>
  )
}

export default SwitchInput
