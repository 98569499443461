import { isNil } from 'lodash'
import {
  CacUserLocationRule,
  CacUserLocationRuleInput,
  ContentAccessControl,
  ContentAccessControlInput,
} from 'services/api/graphql'

import { CacUserLocationRuleFormValues, ContentAccessControlUserLocationFormValues } from './UserLocationFormFields'

export const CacUserLocationRule_APIToFormValues = ({
  distance,
  ...data
}: CacUserLocationRule): CacUserLocationRuleFormValues => {
  return { ...data, distanceKM: distance / 1000 }
}
export const CacUserLocationRule_FormValuesToAPI = ({
  ...data
}: CacUserLocationRuleFormValues): CacUserLocationRuleInput => {
  if (isNil(data.condition)) {
    throw new Error(`No condition`)
  }
  if (isNil(data.distanceKM)) {
    throw new Error(`No distance`)
  }
  if (isNil(data.location.latitude)) {
    throw new Error(`No data.location.latitude`)
  }
  if (isNil(data.location.longitude)) {
    throw new Error(`No data.location.longitude`)
  }
  return {
    condition: data.condition,
    distance: data.distanceKM * 1000,
    location: { latitude: data.location.latitude, longitude: data.location.longitude },
  }
}

export const ContentAccessControl_APIToFormValues = (
  data: ContentAccessControl,
): ContentAccessControlUserLocationFormValues => ({
  hasUserLocationRule: !!data.userLocationRule,
  userLocationRule: data.userLocationRule ? CacUserLocationRule_APIToFormValues(data.userLocationRule) : undefined,
})

export const ContentAccessControl_FormValuesToAPI = ({
  userLocationRule,
  hasUserLocationRule,
}: ContentAccessControlUserLocationFormValues): ContentAccessControlInput => ({
  userLocationRule:
    hasUserLocationRule && userLocationRule ? CacUserLocationRule_FormValuesToAPI(userLocationRule) : undefined,
})
