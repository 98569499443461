import Loader from 'Components/Loader'
import { Slider } from 'antd'
import classnames from 'classnames'
import fileSize from 'filesize'
import { isNil } from 'lodash'
import _ from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import ReactCrop from 'react-image-crop'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  Button,
  Header,
  Image,
  Message,
  Segment,
  SemanticCOLORS,
  Statistic,
  Loader as SUILoader,
  Dimmer,
  Popup,
} from 'semantic-ui-react'
import { Permission } from 'services/api/graphql'
import { useCurrentUser } from 'stores'
import styled from 'styled-components'
import { useBlobUrl, useCroppedImage, useFileReader } from 'tools/hooks'
import { useImageCompression } from 'tools/hooks/useImageCompression'
import { areSameCrops, aspectRatioText, canonizeCrop, CropInfo, isSameAspectRatio } from 'tools/image'
import { notifyError } from 'tools/toaster'
import { ImageConstraints, ImageDimensions, ImageFormat } from 'types/image'

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
`
const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: auto;
  max-height: 100%;
`
const MainImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  > * {
    background: none;
  }
`

const SideBar = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  max-height: 100%;
  justify-content: space-between;
  background-color: #dddddd;
  padding: 16px;
  overflow-x: hidden;
`

const SideBarContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1em 0;
  max-height: 85%;
  > * {
    max-height: 100%;
  }
`

const SideBarButtons = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-top: 1rem;
  & > * + * {
    margin-left: 3rem !important;
  }
`

export interface CropImageViewProps {
  image: Blob
  initialCrop?: ReactCrop.Crop
  imageConstraints?: ImageConstraints
  canImportSourceImage?: boolean
  onDone: (image: File, imageSize: ImageDimensions) => void
  buttonTitle?: string
}

const CropImageView = ({
  image,
  initialCrop = {},
  onDone,
  imageConstraints,
  canImportSourceImage,
  buttonTitle = 'Continuer',
}: CropImageViewProps) => {
  const currentUser = useCurrentUser()
  const intl = useIntl()

  const { result: sourceImage, loading: sourceImageLoading, error: sourceImageError } = useFileReader(image)
  const [crop, setCrop] = useState<ReactCrop.Crop>(
    imageConstraints ? { ...initialCrop, aspect: imageConstraints.aspect } : initialCrop,
  )
  const [imageEl, setImageEl] = useState<HTMLImageElement>()

  const [format, setFormat] = useState(imageConstraints?.format ?? ImageFormat.JPEG)
  const [compressionLevel, setCompressionLevel] = useState(20)

  const { cropImage, error: imageCropError, loading: imageCropLoading, image: croppedImage } = useCroppedImage()
  const handleCropChange = useCallback((changedCrop: ReactCrop.Crop) => setCrop(changedCrop), [])

  const { image: compressed, loading: compressedLoading } = useImageCompression(image, format, 100 - compressionLevel)

  const { image: croppedCompressed, loading: croppedCompressedLoading } = useImageCompression(
    croppedImage,
    format,
    100 - compressionLevel,
  )

  const imageUrl = useBlobUrl(compressed)
  const croppedImageUrl = useBlobUrl(croppedCompressed)

  const onQualityChange = useMemo(() => _.debounce((q: number) => setCompressionLevel(q), 400), [])

  const canonizedInitialCrop = useMemo(() => canonizeCrop(initialCrop), [initialCrop])

  const error = sourceImageError || imageCropError
  const loading = sourceImageLoading || imageCropLoading

  const [imageDimensions, setImageDimensions] = useState<ImageDimensions>()

  const handleFinalImageLoaded = useCallback(e => {
    const imageElement = e.target
    setImageDimensions({
      width: imageElement.naturalWidth,
      height: imageElement.naturalHeight,
    })
  }, [])

  const finalImageBlob = useMemo(() => croppedCompressed || compressed, [croppedCompressed, compressed])
  const finalImageUrl = useMemo(() => croppedImageUrl || imageUrl, [croppedImageUrl, imageUrl])

  const isConstraintRespected = useCallback(
    (constraint: 'minHeight' | 'maxHeight' | 'minWidth' | 'maxWidth') => {
      const isMin = constraint === 'minHeight' || constraint === 'minWidth'
      const heightOrWidth = constraint === 'minHeight' || constraint === 'maxHeight' ? 'height' : 'width'
      const toRespect = imageConstraints?.[constraint] as number

      return imageDimensions && imageConstraints?.[constraint]
        ? isMin
          ? // Min constraint
            imageDimensions[heightOrWidth] >= toRespect
          : // Max constraint
            imageDimensions[heightOrWidth] <= toRespect
        : true
    },
    [imageConstraints, imageDimensions],
  )

  const isMinHeightRespected = useMemo(() => isConstraintRespected('minHeight'), [isConstraintRespected])
  const isMaxHeightRespected = useMemo(() => isConstraintRespected('maxHeight'), [isConstraintRespected])
  const isMinWidthRespected = useMemo(() => isConstraintRespected('minWidth'), [isConstraintRespected])
  const isMaxWidthRespected = useMemo(() => isConstraintRespected('maxWidth'), [isConstraintRespected])
  const isRatioRespected = useMemo(
    () =>
      imageDimensions && imageConstraints?.aspect
        ? isSameAspectRatio(imageDimensions.width / imageDimensions.height, imageConstraints.aspect)
        : true,
    [imageConstraints?.aspect, imageDimensions],
  )

  const getInvalidImageErrors = useCallback(() => {
    if (!imageDimensions || !imageConstraints) return

    return [
      { respected: isMinHeightRespected, id: 'min_height', constraintKey: 'minHeight' as const },
      { respected: isMaxHeightRespected, id: 'max_height', constraintKey: 'maxHeight' as const },
      { respected: isMinWidthRespected, id: 'min_width', constraintKey: 'minWidth' as const },
      { respected: isMaxWidthRespected, id: 'max_width', constraintKey: 'maxWidth' as const },
      { respected: isRatioRespected, id: 'aspect', constraintKey: 'aspect' as const },
    ].reduce<string[]>((acc, { respected, id, constraintKey }) => {
      if (imageConstraints[constraintKey] && !respected)
        acc.push(
          intl.formatMessage(
            { id: `imageCrop.error.${id}` },
            {
              value:
                constraintKey === 'aspect'
                  ? aspectRatioText(imageConstraints[constraintKey] as number)
                  : imageConstraints[constraintKey],
            },
          ),
        )
      return acc
    }, [])
  }, [
    imageConstraints,
    imageDimensions,
    intl,
    isMaxHeightRespected,
    isMaxWidthRespected,
    isMinHeightRespected,
    isMinWidthRespected,
    isRatioRespected,
  ])

  const isFinalImageValid = useMemo(
    () =>
      (!!imageDimensions
        ? isMinHeightRespected && isMaxHeightRespected && isMinWidthRespected && isMaxWidthRespected && isRatioRespected
        : true) &&
      (finalImageBlob ? (imageConstraints?.maxSize ? finalImageBlob.size < imageConstraints.maxSize : true) : false),
    [
      finalImageBlob,
      imageDimensions,
      isMaxHeightRespected,
      isMaxWidthRespected,
      isMinHeightRespected,
      isMinWidthRespected,
      isRatioRespected,
      imageConstraints,
    ],
  )

  const canImportImage = useMemo(
    () => isFinalImageValid && (canImportSourceImage || finalImageBlob !== image),
    [isFinalImageValid, canImportSourceImage, finalImageBlob, image],
  )

  const size = useMemo(() => {
    const _size = ((croppedCompressed ?? compressed) as Blob | undefined)?.size
    return {
      color: (() => {
        if (!_size || !imageConstraints?.maxSize) return undefined
        if (_size >= imageConstraints.maxSize) return 'red'
        if (_size > imageConstraints.maxSize * 0.5) return 'orange'
        return 'green'
      })(),
      readable: _size && fileSize(_size, { locale: 'fr-FR' }),
    }
  }, [compressed, croppedCompressed, imageConstraints])

  useEffect(() => {
    if (error) notifyError(error)
  }, [error])

  return (
    <Loader loading={loading}>
      <Container>
        <Content>
          {/* Main image */}
          {sourceImage && (
            <MainImageContainer>
              <ReactCrop
                crop={crop}
                src={imageUrl ?? sourceImage}
                {...imageConstraints}
                onChange={handleCropChange}
                onComplete={() => {
                  if (isNil(crop.width) || isNil(crop.height) || isNil(crop.x) || isNil(crop.y)) return

                  const cropInfo: CropInfo = {
                    width: crop.width,
                    height: crop.height,
                    x: crop.x,
                    y: crop.y,
                  }

                  // Reset cropped image when there's no crop
                  if (imageEl)
                    cropImage(
                      crop.width !== 0 || crop.height !== 0
                        ? { image: imageEl, cropInfo, format, filename: image instanceof File ? image.name : undefined }
                        : null,
                    )
                }}
                onImageLoaded={loadedImage => setImageEl(loadedImage)}
              />
            </MainImageContainer>
          )}
        </Content>

        <SideBar>
          <div className="mt-4">
            <Statistic.Group className="items-center justify-evenly">
              {/* Image dimensions */}
              {imageDimensions && (
                <Statistic className="flex-1 w-48 items-center">
                  <Statistic.Value text className="flex space-x-2">
                    <span className={classnames({ 'text-red-500': !isMinWidthRespected || !isMaxWidthRespected })}>
                      {imageDimensions.width}
                    </span>

                    <span className={classnames({ 'text-red-500': !isRatioRespected })}>x</span>

                    <span className={classnames({ 'text-red-500': !isMinHeightRespected || !isMaxHeightRespected })}>
                      {imageDimensions.height}
                    </span>
                  </Statistic.Value>

                  <Statistic.Label>{intl.formatMessage({ id: 'imageCrop.dimensions' })}</Statistic.Label>
                </Statistic>
              )}

              {/* Image size */}
              {size && (
                <Statistic className="flex-1 w-44" color={size.color as SemanticCOLORS | undefined}>
                  <Statistic.Value text>{size.readable}</Statistic.Value>
                  <Statistic.Label>{intl.formatMessage({ id: 'imageCrop.size' })}</Statistic.Label>
                </Statistic>
              )}
            </Statistic.Group>
          </div>

          <SideBarContent>
            {/* Potential error */}
            {!isFinalImageValid && (
              <div className="mt-4">
                {getInvalidImageErrors()?.map((errorMessage, index) => (
                  <Message key={index} negative>
                    {errorMessage}
                  </Message>
                ))}
              </div>
            )}

            {/* Thumbnail of final image */}
            <Segment>
              <Image
                as="img"
                src={finalImageUrl}
                className="w-80 h-80 object-contain"
                onLoad={handleFinalImageLoaded}
              />
            </Segment>

            {/* Format */}
            {!imageConstraints?.format && (
              <div className="flex justify-evenly">
                {[ImageFormat.JPEG, ImageFormat.PNG].map(f => (
                  <Popup
                    key={f}
                    trigger={
                      <Button primary={format === f} onClick={() => setFormat(f)}>
                        {f.toUpperCase()}
                      </Button>
                    }
                    content={<FormattedMessage id={`imageFormat.popup.${f}`} />}
                  />
                ))}
              </div>
            )}

            {/* Compression slider */}
            <Segment raised>
              <Header textAlign="center" size="small">
                {intl.formatMessage({ id: 'imageCrop.compression' })}
              </Header>

              <Dimmer active={compressedLoading || croppedCompressedLoading} inverted>
                <SUILoader />
              </Dimmer>

              <Slider
                className="flex"
                min={0}
                max={100}
                defaultValue={compressionLevel}
                step={1}
                onChange={onQualityChange}
              />

              <div className="mt-8 text-sm">{intl.formatMessage({ id: 'imageCrop.compressionNote' })}</div>
            </Segment>
          </SideBarContent>

          <SideBarButtons>
            {/* Re init */}
            <Button
              disabled={areSameCrops(crop, canonizedInitialCrop)}
              onClick={() => {
                // Reset crop for ReactCrop
                setCrop(
                  imageConstraints
                    ? {
                        ...canonizedInitialCrop,
                        aspect: imageConstraints.aspect,
                      }
                    : canonizedInitialCrop,
                )
                // Make imageUrl to be null
                cropImage(null)
              }}
            >
              <FormattedMessage id="imageCrop.reinit" />
            </Button>

            {/* Upload button */}
            {currentUser.can(Permission.FileCreate) && (
              <Button
                primary
                disabled={!canImportImage}
                onClick={() => finalImageBlob && imageDimensions && onDone(finalImageBlob, imageDimensions)}
              >
                {buttonTitle}
              </Button>
            )}
          </SideBarButtons>
        </SideBar>
      </Container>
    </Loader>
  )
}

export default CropImageView
