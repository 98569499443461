import SelectPremiumAccessInput from 'Containers/Form/SelectPremiumAccessInput'
import { useGetOrganismOffers } from 'models'
import React, { useCallback, useEffect, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { Dropdown, Form } from 'semantic-ui-react'
import { PremiumAccess } from 'services/api/graphql'

export interface AccessRestrictionsValues {
  offerIds: string[]
  restrictionLevel: PremiumAccess
}

interface AccessRestrictionsFieldsetProps {
  organismId: string
  values: AccessRestrictionsValues
  onChange: (values: AccessRestrictionsValues) => void
}

const AccessRestrictionsFieldset: React.FC<AccessRestrictionsFieldsetProps> = ({ organismId, values, onChange }) => {
  const { offers, loading } = useGetOrganismOffers(organismId)

  const selectedOffers = useMemo<string[]>(() => [...values.offerIds], [values])

  const offersOptions = useMemo(
    () => [
      ...(offers
        ? offers.map(offer => ({
            key: offer.id,
            value: offer.id,
            text: offer.title,
          }))
        : []),
    ],
    [offers],
  )

  const showRestriction = useMemo(() => values.offerIds.length > 0, [values])

  const onSelectOffer = useCallback(
    (selected: string[]) => {
      onChange({
        offerIds: selected,
        restrictionLevel: selected.length > 0 ? values.restrictionLevel : PremiumAccess.None,
      })
    },
    [values, onChange],
  )

  const onSelectRestrictionLevel = useCallback(
    (value: PremiumAccess) => {
      onChange({
        offerIds: values.offerIds,
        restrictionLevel: value,
      })
    },
    [values, onChange],
  )

  // Reset values when organism change
  useEffect(() => {
    if (
      offers &&
      values.offerIds.length > 0 &&
      values.offerIds.some(offerId => !offers.some(offer => offer.id === offerId))
    ) {
      onChange({
        offerIds: [],
        restrictionLevel: PremiumAccess.None,
      })
    }
  }, [offers, onChange, values.offerIds])

  return (
    <>
      {offers && offers.length > 0 && (
        <>
          <Form.Field name="restrictionsType">
            <label>
              <FormattedMessage id="sportItemSet.restrictions.type" />
            </label>
            <Dropdown
              value={selectedOffers}
              selection
              lazyLoad
              multiple
              options={offersOptions}
              loading={loading}
              onChange={(e, data) => {
                onSelectOffer(data.value as string[])
              }}
            />
          </Form.Field>
          {showRestriction && (
            <Form.Field required name="restrictionLevel">
              <label>
                <FormattedMessage id="sportItemSet.premiumAccess" />
              </label>
              <SelectPremiumAccessInput value={values.restrictionLevel} onChange={onSelectRestrictionLevel} />
            </Form.Field>
          )}
        </>
      )}
    </>
  )
}

export default AccessRestrictionsFieldset
