import InvisibleButton from 'Components/Button/InvisibleButton'
import DropZoneView from 'Components/DropZoneView'
import InlineFileInput from 'Components/Form/InlineFileInput'
import SelectItemListInput from 'Components/Form/SelectItemListInput'
import Loader from 'Components/Loader'
import VideoErrorPopup from 'Components/VideoErrorPopup'
import VideoPlayerModal from 'Components/VideoPlayer/VideoPlayerModal'
import MediaDetailsSidebar from 'Containers/MediaDetailsSidebar'
import MediaListView from 'Containers/MediaListView'
import classnames from 'classnames'
import _ from 'lodash'
import { getMediaThumbail, useGetVideoClip } from 'models'
import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button, Divider, Image, Input, Segment } from 'semantic-ui-react'
import { Media, MediaType, Organism, Permission, VideoClip, VideoClipStatus } from 'services/api/graphql'
import { useCurrentUser } from 'stores'
import styled from 'styled-components'
import { downloadFile } from 'tools/downloadFile'
import { notifyError } from 'tools/toaster'

import { ImageConstraints } from 'types/image'

import CurrentOrganismPicker from './CurrentOrganismPicker'

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  max-height: 100%;
`
const Content = styled.div`
  flex: 1;
  min-height: 0;
  display: flex;
  position: relative;
`
const Search = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

const Files = styled.div`
  flex: 1;
  display: flex;
  height: 100%;
  > * {
    flex: 1;
  }
`

const FilePreview = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > * + * {
    margin-top: 0.7em;
  }
`
const ImagePreview = styled(Image)`
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  border-color: black;
  border-style: solid;
  border-width: 1px;
`

const ImagePreviewWrapper = styled(Segment)`
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  width: 250px;
  height: 250px;
`
const ImagePreviewContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
`
const VideoClipOverlay = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`
const DropContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  > * {
    min-height: 100%;
  }
`
const VideoClipError = styled.div`
  text-align: left;
  color: #ed4337;

  > strong {
    color: #000000;
  }

  > .error {
    font-weight: bold;
    text-align: center;
  }
`

interface RenderFileDetailsProps {
  media: Media
  refresh: () => void
  resetFileSelection: () => void
}

export interface FilesLibraryViewProps {
  mediaType?: MediaType
  renderFileDetails?: (props: RenderFileDetailsProps) => React.ReactNode
  onUploadFile?: (file: File, refresh: () => void) => void
  dropAcceptedMimetypes?: string | string[]
  imageConstraints?: ImageConstraints
  renderOrganismPicker?: boolean
  organismId?: Organism['id']
}

const FilesLibraryView = ({
  mediaType,
  renderFileDetails,
  dropAcceptedMimetypes,
  onUploadFile,
  imageConstraints,
  renderOrganismPicker,
  organismId,
}: FilesLibraryViewProps) => {
  const currentUser = useCurrentUser()
  const [selectedMedia, setSelectedMedia] = useState<Media | undefined | null>(null)
  const previousMediaId = useRef<Media['id']>()
  const [interval, _setInterval] = useState<NodeJS.Timeout>()
  const [previewLoaded, setPreviewLoaded] = useState(false)
  const [search, setSearch] = useState()
  const [loading, setLoading] = useState(false)
  const [fileListViewKey, setFileListViewKey] = useState(0)
  const [filterType, setFilterType] = useState<MediaType | null>()
  const intl = useIntl()

  const refresh = useCallback(() => {
    // TODO: Ask FileListView to refresh its content
    setFileListViewKey(key => (key ? 0 : 1))
  }, [])

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0]
      if (onUploadFile && file) {
        onUploadFile(file, refresh)
      }
    },
    [onUploadFile, refresh],
  )
  const onDropRejected = useCallback((files: File[]) => {
    if (files.length > 0) {
      notifyError(<FormattedMessage id="fileInput.invalid" values={{ type: files[0].type }} />)
    }
  }, [])

  const { getRootProps, isDragActive, getInputProps } = useDropzone({
    onDrop,
    accept: dropAcceptedMimetypes,
    onDropRejected,
  })
  const dropProps = useMemo(() => currentUser.can(Permission.FileCreate) && getRootProps(), [getRootProps, currentUser])

  const selectMedia = useCallback((media?: Media) => {
    setPreviewLoaded(false)
    setSelectedMedia(null)
    setTimeout(() => setSelectedMedia(media))
  }, [])

  const handleSelectFile = useCallback((media: Media) => selectMedia(media), [selectMedia])

  const handleOnSearchChange = useMemo(() => _.debounce((e: any) => setSearch(e.target.value), 300), [])

  const [playerOpen, setPlayerOpen] = useState(false)
  const [videoUrl, setVideoUrl] = useState('')
  const openVideo = useCallback((video: VideoClip) => {
    setVideoUrl(video.playbackUrl)
    setPlayerOpen(true)
  }, [])

  const selectedMediaThumbnail = useMemo(() => (selectedMedia ? getMediaThumbail(selectedMedia) : ''), [selectedMedia])

  const downloadImage = useCallback(async () => {
    if (!selectedMedia || !selectedMedia.image) {
      return null
    }

    await downloadFile(selectedMedia.image.downloadUrl, selectedMedia.title)
  }, [selectedMedia])

  const getVideoClip = useGetVideoClip()

  useEffect(() => {
    if (
      previousMediaId.current !== selectedMedia?.id &&
      (selectedMedia?.videoClip?.status === VideoClipStatus.Encoding ||
        selectedMedia?.videoClip?.status === VideoClipStatus.Uploaded)
    ) {
      previousMediaId.current = selectedMedia.id

      if (interval) clearInterval(interval)

      _setInterval(
        setInterval(async () => {
          if (!selectedMedia.videoClip) return

          const videoClip = await getVideoClip({ id: selectedMedia.videoClip.id })
          selectMedia({
            ...selectedMedia,
            videoClip: videoClip && {
              ...videoClip,
              downloadUrl: '',
              timestamps: {
                createdAt: videoClip.createdAt,
                updatedAt: videoClip.updatedAt,
              },
            },
          })
        }, 3000),
      )
    } else if (selectedMedia?.videoClip?.status === VideoClipStatus.Available && interval) {
      // Just clear interval when new media is good
      clearInterval(interval)
      _setInterval(undefined)
    }
  }, [getVideoClip, interval, selectMedia, selectedMedia])

  // Only admin and Procaster can have no organism selected
  const { isAdmin } = useCurrentUser()

  // On destroy
  useEffect(
    () => () => {
      interval && clearInterval(interval)
      handleOnSearchChange.cancel()
    },
    [handleOnSearchChange, interval],
  )

  return (
    <Container {...dropProps}>
      <Search>
        <Input
          icon="search"
          loading={loading}
          placeholder={intl.formatMessage({
            id: 'users.search',
          })}
          onChange={handleOnSearchChange}
        />

        {renderOrganismPicker && (
          <div className="flex items-center space-x-2">
            <span>
              <FormattedMessage id="organism" />:
            </span>

            <CurrentOrganismPicker inverted={false} noSelectionEnabled={isAdmin} />
          </div>
        )}

        {!mediaType && (
          <SelectItemListInput
            noSelectionEnabled={true}
            noSelectionTitle={intl.formatMessage({ id: 'medias.all' })}
            value={filterType || undefined}
            options={[MediaType.Image, MediaType.VideoClip].map(filter => ({
              text: intl.formatMessage({ id: `medias.${filter}` }),
              value: filter,
              key: filter,
            }))}
            onChange={(filter?: MediaType) => setFilterType(filter || null)}
          />
        )}

        {currentUser.can(Permission.VideoClipCreate) && onUploadFile && (
          <InlineFileInput
            onChange={file => onUploadFile(file, refresh)}
            name="newFile"
            accept={Array.isArray(dropAcceptedMimetypes) ? dropAcceptedMimetypes.join(',') : dropAcceptedMimetypes}
            placeholder={<FormattedMessage id="medias.adding" />}
          />
        )}
      </Search>

      <Divider />

      <Content>
        <Files>
          <MediaListView
            selectedMediaId={selectedMedia?.id}
            type={mediaType || filterType || undefined}
            search={search}
            onMediaClick={handleSelectFile}
            onLoading={setLoading}
            key={fileListViewKey}
            imageConstraints={imageConstraints}
            organismId={organismId}
          />
        </Files>

        <MediaDetailsSidebar media={selectedMedia}>
          <Fragment>
            <FilePreview>
              {renderFileDetails &&
                selectedMedia &&
                renderFileDetails({
                  media: selectedMedia,
                  refresh,
                  resetFileSelection: () => selectMedia(undefined),
                })}

              {/* Image */}
              {selectedMedia && selectedMedia.image && (
                <>
                  <ImagePreviewContainer>
                    <ImagePreviewWrapper raised>
                      {!previewLoaded && (
                        <div className="absolute w-full h-full">
                          <Loader loading />
                        </div>
                      )}

                      <ImagePreview
                        className={classnames('transition-all ease-in-out duration-300 filter', {
                          'blur-sm': !previewLoaded,
                        })}
                        src={selectedMedia.image.downloadUrl}
                        onLoad={() => setPreviewLoaded(true)}
                      />
                    </ImagePreviewWrapper>
                  </ImagePreviewContainer>

                  <Button primary onClick={downloadImage} target="_blank" rel="noopener noreferrer">
                    <FormattedMessage id="common.download" />
                  </Button>
                </>
              )}

              {/* Video */}
              {selectedMedia && selectedMedia.videoClip && (
                <>
                  {selectedMedia.videoClip.status === VideoClipStatus.Available && (
                    <>
                      <InvisibleButton onClick={() => selectedMedia.videoClip && openVideo(selectedMedia.videoClip)}>
                        <ImagePreviewContainer>
                          <ImagePreviewWrapper raised>
                            <ImagePreview
                              className={classnames('transition-all ease-in-out duration-300 filter', {
                                'blur-sm': !previewLoaded,
                              })}
                              src={selectedMediaThumbnail}
                              onLoad={() => setPreviewLoaded(true)}
                            />

                            <VideoClipOverlay src="/assets/play.png" />
                          </ImagePreviewWrapper>
                        </ImagePreviewContainer>
                      </InvisibleButton>

                      {selectedMedia.videoClip.downloadLink && selectedMedia.videoClip.downloadLink.trim() !== '' && (
                        <a href={selectedMedia.videoClip.downloadLink} download>
                          <Button primary target="_blank" rel="noopener noreferrer">
                            <FormattedMessage id="common.download" />
                          </Button>
                        </a>
                      )}
                    </>
                  )}

                  {(selectedMedia.videoClip.status === VideoClipStatus.Uploaded ||
                    selectedMedia.videoClip.status === VideoClipStatus.Encoding) && (
                    <FormattedMessage id="medias.videoClip.encoding" />
                  )}

                  {selectedMedia.videoClip.status === VideoClipStatus.Error && (
                    <VideoClipError>
                      <p className="error">
                        <FormattedMessage id="medias.videoClip.error" />
                      </p>

                      {selectedMedia.videoClip.error && (
                        <VideoErrorPopup
                          error={selectedMedia.videoClip.error}
                          buttonMessage="medias.videoClip.errorDetails"
                          light
                        />
                      )}
                    </VideoClipError>
                  )}
                </>
              )}
            </FilePreview>

            <Divider />
          </Fragment>
        </MediaDetailsSidebar>

        {onUploadFile && dropProps && isDragActive && (
          <DropContainer>
            <DropZoneView root={{}} input={getInputProps()} />
          </DropContainer>
        )}
      </Content>

      <VideoPlayerModal
        open={playerOpen}
        onRefresh={refresh}
        onClose={() => setPlayerOpen(false)}
        url={videoUrl}
        mediaToUpdate={selectedMedia}
        showEditMarkers={true}
      />
    </Container>
  )
}
export default FilesLibraryView
