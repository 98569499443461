import { useQuery } from '@apollo/client'
import SAFormField from 'Components/Form/SAFormField'
import SelectPublicationStatusInput from 'Containers/Form/SelectPublicationStatusInput'
import { readFragment } from 'gql.tada'
import _ from 'lodash'
import { Affiliate, AffiliateDisplayType, AffiliateType, PrerollConfig, PublicationStatus } from 'models'
import React, { FC, useCallback, useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Form, Header, Icon, Segment, Table } from 'semantic-ui-react'
import { useStore } from 'stores'
import { validateRequired } from 'tools/formValidators'

import { AdCampaignFormItemFields } from './AdCampaignFormItemFields'
import { GetLinksQuery, LinksQueryFragment } from './fragments'

export interface AffiliateFieldType extends Omit<Affiliate, 'id'> {
  id?: string
}

export type AdCampaignFormValues = {
  id: string
  title: string
  affiliates: Affiliate[]
  prerollConfigs: PrerollConfig[]
  organismId: string
  publicationStatus: PublicationStatus
  displayedItemsCountMax: number
}

type Props = {
  fields: AffiliateFieldType[]
  onChangeFields: (newFields: AffiliateFieldType[]) => void
}

export const validateNumber = (event: { key: string; preventDefault: () => void }) => {
  if (!/[0-9]/.test(event.key)) {
    event.preventDefault()
  }
}

const AdCampaignFormFields: FC<Props> = ({ fields, onChangeFields }) => {
  const intl = useIntl()
  const store = useStore()

  const { data: linksQuery, loading: linksLoading } = useQuery(GetLinksQuery, {
    variables: { filters: { organismId: store.organismId } },
    fetchPolicy: 'cache-and-network',
  })
  const links = linksQuery?.links && readFragment(LinksQueryFragment, linksQuery.links)

  const handleAdd = (type: AffiliateType) => {
    const newFields = [...fields]
    newFields.push({
      linkId: '',
      displayWeight: 0,
      type,
      displayTypes: [],
    })
    onChangeFields(newFields)
  }

  const handleRemove = (index: number) => {
    const newFields = [...fields]
    for (let i = index; i < newFields.length - 1; i++) {
      newFields[i] = newFields[i + 1]
    }
    newFields.pop()
    onChangeFields(newFields)
  }

  const handleChangeFields = (index: number, item: AffiliateFieldType) => {
    const newFields = [...fields]
    newFields[index] = {
      linkId: item.linkId,
      displayWeight: item.displayWeight,
      type: item.type,
      displayTypes: item.displayTypes,
    }
    onChangeFields(newFields)
  }

  const autoRegulateFields = useCallback(() => {
    const newFields = _.cloneDeep(fields)
    let total = newFields.reduce((acc, field) => acc + field.displayWeight, 0)

    if (total > 100 || total < 100) {
      for (let i = newFields.length - 1; i >= 0; i--) {
        total = newFields.reduce((acc, field) => acc + field.displayWeight, 0)
        if (total > 100) {
          let diff = total - 100
          if (diff > newFields[i].displayWeight) {
            diff = newFields[i].displayWeight
          }
          newFields[i].displayWeight -= diff
          total -= diff
        }

        if (total <= 100) {
          if (total < 100) {
            newFields[newFields.length - 1].displayWeight += 100 - total
          }
          onChangeFields(newFields)
          break
        }
      }
    }
  }, [fields, onChangeFields])

  useEffect(() => {
    autoRegulateFields()
  }, [autoRegulateFields, fields])

  const indexHasButton = useCallback(
    () => fields.findIndex(field => field.displayTypes.includes(AffiliateDisplayType.Button)),
    [fields],
  )

  return (
    <Form widths="equal">
      <SAFormField
        name="title"
        render={({ input, meta }) => (
          <Form.Input
            {...input}
            error={!!meta.touched && !!meta.error}
            required
            placeholder={intl.formatMessage({
              id: 'adCampaign.title.placeholder',
            })}
            label={intl.formatMessage({ id: 'applicationDesign.title' })}
          />
        )}
      />
      <SAFormField
        name="publicationStatus"
        defaultValue={PublicationStatus.Draft}
        render={({ input, meta }) => (
          <Form.Field
            control={SelectPublicationStatusInput}
            required
            {...input}
            error={!!meta.touched && !!meta.error}
            label={intl.formatMessage({
              id: 'adCampaign.publicationStatus',
            })}
          />
        )}
      />
      <Segment>
        <Header as="h3">{intl.formatMessage({ id: 'adCampaign.settings' })}</Header>
        <Form.Group>
          <div className="ml-2">
            <div className="p-2">
              <FormattedMessage id="adCampaign.displayedItemsCountMax" />
            </div>
            <SAFormField
              name="displayedItemsCountMax"
              validate={validateRequired}
              render={({ input, meta }) => (
                <Form.Input
                  {...input}
                  required
                  type="number"
                  onKeyPress={(event: { key: string; preventDefault: () => void }) => validateNumber(event)}
                  onChange={e => input.onChange(e.target.value)}
                  placeholder="0"
                  min={0}
                  error={!!meta.touched && !!meta.error}
                />
              )}
            />
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell>
                    <FormattedMessage id="adCampaign.prerollConfig.ads" />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <FormattedMessage id="adCampaign.prerollConfig.billboards" />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    <FormattedMessage id="adCampaign.prerollConfig.liveAndReplay" />
                  </Table.Cell>
                  <Table.Cell>
                    <SAFormField
                      name="prerollConfigs.liveAndReplay.adDisplayMaxCount"
                      validate={validateRequired}
                      render={({ input, meta }) => (
                        <Form.Input
                          {...input}
                          required
                          type="number"
                          placeholder="0"
                          min={0}
                          onKeyPress={(event: { key: string; preventDefault: () => void }) => validateNumber(event)}
                          onChange={e => input.onChange(e.target.value)}
                          error={!!meta.touched && !!meta.error}
                        />
                      )}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <SAFormField
                      name="prerollConfigs.liveAndReplay.billboardDisplayMaxCount"
                      validate={validateRequired}
                      render={({ input, meta }) => (
                        <Form.Input
                          {...input}
                          required
                          placeholder="0"
                          type="number"
                          min={0}
                          onKeyPress={(event: { key: string; preventDefault: () => void }) => validateNumber(event)}
                          error={!!meta.touched && !!meta.error}
                          onChange={e => input.onChange(e.target.value)}
                        />
                      )}
                    />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    <FormattedMessage id="adCampaign.prerollConfig.vod" />
                  </Table.Cell>
                  <Table.Cell>
                    <SAFormField
                      name="prerollConfigs.vod.adDisplayMaxCount"
                      validate={validateRequired}
                      render={({ input, meta }) => (
                        <Form.Input
                          {...input}
                          required
                          placeholder="0"
                          type="number"
                          min={0}
                          onKeyPress={(event: { key: string; preventDefault: () => void }) => validateNumber(event)}
                          error={!!meta.touched && !!meta.error}
                          onChange={e => input.onChange(e.target.value)}
                        />
                      )}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <SAFormField
                      name="prerollConfigs.vod.billboardDisplayMaxCount"
                      validate={validateRequired}
                      render={({ input, meta }) => (
                        <Form.Input
                          {...input}
                          required
                          placeholder="0"
                          type="number"
                          min={0}
                          onKeyPress={(event: { key: string; preventDefault: () => void }) => validateNumber(event)}
                          error={!!meta.touched && !!meta.error}
                          onChange={e => input.onChange(e.target.value)}
                        />
                      )}
                    />
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </div>
        </Form.Group>
        {linksLoading ? (
          <Icon loading name="spinner" size="big" />
        ) : (
          <Table>
            <Table.Header>
              <Table.Row textAlign="center">
                <Table.HeaderCell colSpan={1}>
                  <FormattedMessage id="adCampaigns.affiliates" />
                </Table.HeaderCell>
                <Table.HeaderCell colSpan={1}>
                  <FormattedMessage id="adCampaigns.affiliates.tools" />
                </Table.HeaderCell>
                <Table.HeaderCell colSpan={1}>
                  <FormattedMessage id="adCampaigns.affiliates.displayWeight" />
                </Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {fields.map((item, index: number) => (
                <AdCampaignFormItemFields
                  key={`items[${index}]`}
                  indexHasButton={indexHasButton()}
                  index={index}
                  name={`items[${index}]`}
                  links={links?.links}
                  onRemove={() => handleRemove(index)}
                  onChangeFields={handleChangeFields}
                  item={item}
                />
              ))}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.Cell colSpan={1}>
                  <div
                    onClick={() => handleAdd(AffiliateType.Link)}
                    className="ml-1 text-white bg-blue-400 rounded flex h-10 align-middle justify-center pt-2 cursor-pointer"
                  >
                    <Icon name="add" />
                    <div className="ml-1">
                      <FormattedMessage id="editedCategory.addLink" />
                    </div>
                  </div>
                </Table.Cell>
                <Table.Cell colSpan={1}>
                  <div
                    onClick={() => handleAdd(AffiliateType.GoogleAdManager)}
                    className="ml-1 text-white rounded flex h-10 align-middle justify-center pt-2 cursor-pointer bg-yellow-300"
                  >
                    <Icon name="add" />
                    <div className="ml-1">
                      <FormattedMessage id="editedCategory.addGoogleLink" />
                    </div>
                  </div>
                </Table.Cell>
              </Table.Row>
            </Table.Footer>
          </Table>
        )}
      </Segment>
    </Form>
  )
}

export default AdCampaignFormFields
