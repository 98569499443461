import React, { FC } from 'react'

import EditorProvider from './EditorProvider'
import LexicalEditor, { LexicalEditorProps } from './LexicalEditor/LexicalEditor'
import Toolbar from './Toolbar/Toolbar'

export type EditorProviderProps = LexicalEditorProps & {
  organismId?: string
}

const Editor: FC<EditorProviderProps> = ({ organismId, editable = true, ...props }) => (
  <div className="flex flex-col gap-2">
    <EditorProvider organismId={organismId}>
      {editable && <Toolbar />}
      <LexicalEditor editable={editable} {...props} />
    </EditorProvider>
  </div>
)

export default Editor
