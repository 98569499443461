import { gql } from '@apollo/client'

import { CONTENT_TRANSLATION_FRAGMENT } from './ContentTranslation'

const NEWS_FRAGMENT = gql`
  fragment news on News {
    __typename
    id
    organismId
    organism {
      id
      type
      title
      description
      officialWebSiteUrl
    }
    defaultLocale
    title
    titleT {
      ...contentTranslationContent
    }
    subtitle
    subtitleT {
      ...contentTranslationContent
    }
    description
    descriptionT {
      ...contentTranslationContent
    }
    content
    contentT {
      ...contentTranslationContent
    }
    rawContent
    rawContentT {
      ...contentTranslationContent
    }
    publicationStatus
    coverImageId
    coverImage {
      id
      image {
        id
        thumbUrl
        downloadUrl
      }
    }
    createdAt
    updatedAt
  }
  ${CONTENT_TRANSLATION_FRAGMENT}
`

export const GET_NEWSS_QUERY = gql`
  query newss($filters: NewsFilters, $offset: Int, $first: Int, $search: String) {
    newss(filters: $filters, offset: $offset, first: $first, search: $search) {
      news {
        ...news
      }
      totalCount
    }
  }
  ${NEWS_FRAGMENT}
`

export const GET_NEWS_QUERY = gql`
  query news($newsId: ID!) {
    news(id: $newsId) {
      ...news
    }
  }
  ${NEWS_FRAGMENT}
`

export const CREATE_NEWS_MUTATION = gql`
  mutation createNews($input: CreateNewsInput!) {
    createNews(input: $input) {
      ...news
    }
  }
  ${NEWS_FRAGMENT}
`
export const UPDATE_NEWS_MUTATION = gql`
  mutation updateNews($input: UpdateNewsInput!) {
    updateNews(input: $input) {
      ...news
    }
  }
  ${NEWS_FRAGMENT}
`

export const DELETE_NEWS_MUTATION = gql`
  mutation deleteNews($newsId: ID!) {
    deleteNews(id: $newsId) {
      id
    }
  }
`

export const CREATE_TWITTER_WIDGET_MUTATION = gql`
  mutation createTwitterWidget($tweetUrl: String!) {
    createTwitterWidget(tweetUrl: $tweetUrl)
  }
`
