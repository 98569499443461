import SelectItemListInput, { SelectItemListInputProps } from 'Components/Form/SelectItemListInput'
import React, { useMemo } from 'react'
import { Maybe, WmStream } from 'services/api/graphql'

export type WMStreamInputItem = Pick<WmStream, 'id' | 'name'>

export interface SelectWMStreamInputProps extends Omit<SelectItemListInputProps, 'options'> {
  value: WMStreamInputItem['id'] | Array<WMStreamInputItem['id']> | undefined
  onChange: (value: Maybe<WMStreamInputItem['id'] | Array<WMStreamInputItem['id']>>) => void
  source: Array<WMStreamInputItem>
}

export const SelectWMStreamInput = ({ value, onChange, source, ...props }: SelectWMStreamInputProps) => {
  const options = useMemo(() => {
    return source.map(stream => ({
      key: stream.id,
      value: stream.id,
      text: stream.name,
    }))
  }, [source])

  return (
    <>
      <SelectItemListInput value={value} options={options} onChange={onChange} {...props} />
    </>
  )
}
