import config from 'config'
import {
  collection,
  getFirestore,
  query,
  onSnapshot,
  CollectionReference,
  limit,
  startAfter,
  orderBy,
  where,
} from 'firebase/firestore'
import { ChatRoom } from 'models/ChatRoom'
import { useEffect, useMemo, useRef, useState } from 'react'

import { useChatRoomsStore } from './useChatRoomsStore'

export const useChatRooms = (organismId: string | undefined, active: boolean | undefined) => {
  const { chatRooms, addChatRoom, modifyChatRoom, removeChatRoom, loading, setLoading, resetRooms } =
    useChatRoomsStore()
  const [lastPage, setLastPage] = useState<number>(0)
  const previousOrganismId = useRef(organismId)
  const previousActive = useRef(active)

  useEffect(() => {
    if (previousOrganismId.current === organismId && previousActive.current === active) {
      return
    }
    previousOrganismId.current = organismId
    previousActive.current = active
    resetRooms()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organismId, active])

  useEffect(() => {
    const roomsRef = collection(getFirestore(), 'rooms') as CollectionReference<ChatRoom>
    let roomsQuery = query<ChatRoom>(
      roomsRef,
      orderBy('updatedAt'),
      orderBy('createdAt'),
      startAfter(lastPage * config.pageSize),
      limit((lastPage + 1) * config.pageSize),
    )
    if (organismId) {
      roomsQuery = query(roomsQuery, where('organismId', '==', organismId))
    }
    if (active !== undefined) {
      roomsQuery = query(roomsQuery, where('active', '==', active))
    }
    const unsubscribe = onSnapshot<ChatRoom>(roomsQuery, snapshot => {
      setLoading(true)
      snapshot.docChanges().forEach(({ doc, type }) => {
        if (type === 'modified') modifyChatRoom({ ...doc.data(), id: doc.id })
        if (type === 'added') addChatRoom({ ...doc.data(), id: doc.id })
        if (type === 'removed') removeChatRoom(doc.id)
      })
      setLoading(false)
    })
    return () => {
      unsubscribe()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastPage, organismId, active])

  const data = useMemo(
    () => ({
      chatRooms,
      loading,
    }),
    [loading, chatRooms],
  )

  return useMemo(
    () => ({
      data,
      fetchMore: () => setLastPage(lastPage + 1),
      reachedBottom: chatRooms.length < (lastPage + 1) * config.pageSize,
    }),
    [data, chatRooms.length, lastPage],
  )
}
