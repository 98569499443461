import ConfirmationButton, { ConfirmationButtonBaseProps } from 'Containers/Button/ConfirmationButton'
import { useDeleteSportItem } from 'models'
import React from 'react'
import { SportItem } from 'services/api/graphql'

interface RemoveSportItemButtonProps extends ConfirmationButtonBaseProps {
  sportItemId: SportItem['id']
}

const RemoveSportItemButton = ({ sportItemId, ...props }: RemoveSportItemButtonProps) => {
  // const { removeSport, loading } = useRemoveSport(sportId)
  const [removeSportItemSet] = useDeleteSportItem()
  return <ConfirmationButton action={() => removeSportItemSet({ variables: { sportItemId } })} {...props} />
}

export default RemoveSportItemButton
