import RefreshButton from 'Components/Button/RefreshButton'
import Loader from 'Components/Loader'
import PageHeader from 'Components/PageHeader'
import { SportItemExternalFeedLivesTable } from 'Components/SportItemMedias/ExternalFeedVideoEngine/SportItemExternalFeedLivesTable'
import { SportItemSVELivesTable } from 'Components/SportItemMedias/SportallVideoEngine/SportItemSVELivesTable'
import SportItemVideoClipsTable from 'Components/SportItemMedias/VideoClip/SportItemVideosTable'
import { SportItemWildmokaLivesTable } from 'Components/SportItemMedias/WildmokaVideoEngine/SportItemWildmokaLivesTable'
import { applicationUris } from 'Layout/uris'
import React, { useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link, useParams } from 'react-router-dom'
import { Grid, Icon, Message } from 'semantic-ui-react'
import {
  SportItemSetType,
  useSportItemWithSportItemSetQuery,
  VideoContentType,
  VideoEngine,
} from 'services/api/graphql'
import { getGraphqlErrorMessage } from 'tools/graphql'

const SportItemMediaView = () => {
  const { sportItemId } = useParams<{ sportItemId: string }>()
  const [loadingTables, setloadingTables] = useState(false)

  const {
    data,
    loading: loadingSportItem,
    refetch: refetchItem,
    error,
  } = useSportItemWithSportItemSetQuery({
    variables: { sportItemId: sportItemId ?? '' },
    fetchPolicy: 'no-cache',
    skip: !sportItemId,
  })

  const sportItem = data?.sportItem
  const videoEngine = sportItem?.sportItemSet?.videoEngine
  const displayLiveSection = useMemo(
    () => sportItem?.videoContentType === VideoContentType.Live || !!sportItem?.sportEventItem,
    [sportItem?.sportEventItem, sportItem?.videoContentType],
  )

  const loading: boolean = useMemo(() => {
    return loadingSportItem || loadingTables
  }, [loadingSportItem, loadingTables])

  const LiveSectionComponent = useMemo(() => {
    switch (videoEngine) {
      case VideoEngine.Sportall: {
        return SportItemSVELivesTable
      }
      case VideoEngine.Wildmoka: {
        return SportItemWildmokaLivesTable
      }
      case VideoEngine.ExternalFeed: {
        return SportItemExternalFeedLivesTable
      }
      default:
        return SportItemSVELivesTable
    }
  }, [videoEngine])

  return (
    <Loader loading={loading}>
      <Grid padded>
        <Grid.Column>
          {sportItem && (
            <Link
              to={
                sportItem?.sportItemSet?.type === SportItemSetType.SportEvent
                  ? applicationUris.sportEventItems(sportItem.sportItemSetId)
                  : applicationUris.playlistItems(sportItem.sportItemSetId)
              }
            >
              <Icon name="chevron left" />
              <FormattedMessage id="common.back" />
            </Link>
          )}
          <PageHeader title={(sportItem && sportItem.title) || ''}>
            <RefreshButton onClick={() => refetchItem()} loading={loading} />
          </PageHeader>
          {sportItem && (
            <div className="flex flex-col space-y-8">
              {displayLiveSection && (
                <LiveSectionComponent
                  sportItem={sportItem}
                  onLoading={setloadingTables}
                  loading={loading}
                  refresh={refetchItem}
                />
              )}
              <SportItemVideoClipsTable
                videoEngine={videoEngine}
                sportItemSetType={sportItem.sportItemSet?.type}
                sportItem={sportItem}
                onLoading={setloadingTables}
                refresh={refetchItem}
              />
            </div>
          )}
          {error && <Message negative>{getGraphqlErrorMessage(error.graphQLErrors)}</Message>}
        </Grid.Column>
      </Grid>
    </Loader>
  )
}

export default SportItemMediaView
