import { gql } from '@apollo/client'

import { CONTENT_TRANSLATION_FRAGMENT } from './ContentTranslation'
import { MEDIA_FRAGMENT } from './Media'

/**
 * Sports
 */
export const CREATE_SPORT_MUTATION = gql`
  mutation createSport($input: CreateSportInput!) {
    createSport(input: $input) {
      id
      title
      color
      cardImage {
        ...mediaContent
      }
      bannerImage {
        ...mediaContent
      }
      bannerVideo {
        id
        videoClip {
          id
          playbackUrl
        }
      }
      iconImage {
        ...mediaContent
      }
      organisms {
        id
      }
    }
  }
  ${MEDIA_FRAGMENT}
`
export const UPDATE_SPORT_MUTATION = gql`
  mutation updateSport($input: UpdateSportInput!) {
    updateSport(input: $input) {
      id
      title
      color
      onFront
      frontPosition
      cardImage {
        ...mediaContent
      }
      bannerImage {
        ...mediaContent
      }
      bannerVideo {
        id
        videoClip {
          id
          playbackUrl
        }
      }
      iconImage {
        ...mediaContent
      }
    }
  }
  ${MEDIA_FRAGMENT}
`

export const DELETE_SPORT_MUTATION = gql`
  mutation deleteSport($sportId: ID!) {
    deleteSport(id: $sportId) {
      id
    }
  }
`

export const GET_SPORTS_QUERY = gql`
  query getSports {
    sports {
      sports {
        id
        title
        color
        organisms {
          id
          title
        }
        onFront
        frontPosition
        bannerImage {
          ...mediaContent
        }
        bannerVideo {
          id
          videoClip {
            id
            playbackUrl
          }
        }
        iconImage {
          ...mediaContent
        }
        cardImage {
          ...mediaContent
        }
      }
      totalCount
    }
  }
  ${MEDIA_FRAGMENT}
`
export const GET_SPORTS_SHORT_QUERY = gql`
  query sports {
    sports {
      sports {
        id
        title
      }
      totalCount
    }
  }
`

export const GET_SPORT_QUERY = gql`
  query sport($sportId: ID!) {
    sport(id: $sportId) {
      id
      title
      titleT {
        ...contentTranslationContent
      }
      defaultLocale
      color
      onFront
      frontPosition
      cardImage {
        ...mediaContent
      }
      bannerImage {
        ...mediaContent
      }
      bannerVideo {
        id
        videoClip {
          id
          playbackUrl
        }
      }
      iconImage {
        ...mediaContent
      }
    }
  }
  ${MEDIA_FRAGMENT}
  ${CONTENT_TRANSLATION_FRAGMENT}
`

export const MOVE_SPORT_FRONT_PAGE_MUTATION = gql`
  mutation moveSportFrontPage($input: MoveFrontPageInput!) {
    moveSportFrontPage(input: $input) {
      id
      frontPosition
    }
  }
`
