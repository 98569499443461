import { UserFragment } from 'Views/Users/fragments'
import { ResultOf } from 'gql.tada'
import { observer } from 'mobx-react-lite'
import { useGetUserOrganisms, useUserRole } from 'models'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Divider, Grid, Header, Label, List } from 'semantic-ui-react'
import { User, UserRoleId } from 'services/api/graphql'

interface UserViewGeneralProps {
  user: ResultOf<typeof UserFragment> | User
}

const UserViewGeneral = observer(({ user }: UserViewGeneralProps) => {
  const { organisms } = useGetUserOrganisms(user.organismIds)
  return (
    <Grid.Row>
      <Grid.Column width={16}>
        {user.roleIds && (
          <Label.Group color="blue" size="tiny">
            {user.roleIds.map(roleId => (
              <Role key={roleId} id={roleId as UserRoleId} />
            ))}
          </Label.Group>
        )}
      </Grid.Column>

      {!!organisms.length && (
        <Grid.Column width={16}>
          <Divider />

          <Header as="h4">
            <FormattedMessage id="users.organisms" />:
          </Header>

          <List>
            {organisms.map(({ id, title }) => (
              <List.Item key={id}>{title}</List.Item>
            ))}
          </List>
        </Grid.Column>
      )}
    </Grid.Row>
  )
})

type RoleProps = {
  id: UserRoleId
}
const Role = ({ id }: RoleProps) => {
  const { userRole } = useUserRole(id)
  return <Label as="a">{userRole && userRole.title}</Label>
}

export default UserViewGeneral
