import React, { FC, useCallback, useState, ChangeEventHandler } from 'react'
import { FormattedMessage } from 'react-intl'
import { Button, Input, Modal, Popup } from 'semantic-ui-react'

import { useEditorCommand } from '../useEditor'

const InsertYoutubeButton: FC = () => {
  const [, dispatch] = useEditorCommand()

  const [open, setOpen] = useState(false)
  const [videoUrl, setVideoUrl] = useState('')

  const onClick = useCallback(() => {
    setVideoUrl('')
    setOpen(true)
  }, [])

  const onCancel = useCallback(() => {
    setVideoUrl('')
    setOpen(false)
  }, [])

  const onChange = useCallback<ChangeEventHandler<HTMLInputElement>>(e => {
    setVideoUrl(e.target.value)
  }, [])

  const onConfirm = useCallback(() => {
    const match = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/.exec(videoUrl)
    const id = match ? (match?.[2].length === 11 ? match[2] : null) : null

    setVideoUrl('')
    setOpen(false)
    dispatch('youtube', id)
  }, [dispatch, videoUrl])

  return (
    <>
      <Popup
        content={<FormattedMessage id="news.youtube" />}
        trigger={<Button type="button" icon="youtube" onClick={onClick} />}
      />

      <Modal
        mountNode={document.getElementById('modals')}
        open={open}
        size="small"
        closeIcon
        closeOnDimmerClick={false}
        onClose={onCancel}
      >
        <Modal.Header as="h1" content={<FormattedMessage id="editor.addYoutubeVideo" />} />
        <Modal.Content>
          <Input
            type="text"
            className="w-full"
            placeholder="https://www.youtube.com/watch?v=xxxxxxxxxx"
            onChange={onChange}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={onCancel}>
            Annuler
          </Button>
          <Button positive onClick={onConfirm}>
            Valider
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  )
}

export default InsertYoutubeButton
