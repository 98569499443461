import { SelectedTranslationsType } from 'Components/Form/SelectLocalInput'
import { VideoTypePlaylist } from 'Containers/Form/SelectVideoTypePlaylistInput'
import { FormBaseProps } from 'Forms/FormBase'
import { useCreateSportItem } from 'models'
import React, { useCallback, useState } from 'react'
import { IntlShape, useIntl } from 'react-intl'
import {
  ContentPushStreamServiceName,
  CreateSportItemInput,
  ExternalFeedType,
  SportEventItemCreateInput,
  SportItem,
  SportItemContentType,
  SportItemSet,
  SportItemType,
  SveChannel,
  useSportItemSetQuery,
  VideoContentType,
  VideoEngine,
} from 'services/api/graphql'

import { getTranslationInputFromForm } from 'tools/translationTools'

import { SportEventItemFormValues } from './SportEventItemFieldSet'
import SportItemForm, { SportItemFormValues } from './SportItemForm'
import SportItemRecap from './SportItemRecap'

/**
 * Remove additional data from sportEventItem form values
 */
function getSportEventItemCreateInput(
  sportEventItemData: SportEventItemFormValues,
  intl: IntlShape,
): SportEventItemCreateInput {
  if (!sportEventItemData.testDate) {
    throw new Error(
      intl.formatMessage({
        id: 'sportEventItem.testDate',
      }),
    )
  }

  const sportEvent = {
    testDate: sportEventItemData.testDate,
    startDate: sportEventItemData.startDate,
    stopDate: sportEventItemData.stopDate,
    publishAutoreplay: sportEventItemData.publishAutoreplay,
  }

  if (sportEvent.testDate) sportEvent.testDate.setSeconds(0)
  sportEvent.startDate.setSeconds(0)
  sportEvent.stopDate.setSeconds(0)

  return sportEvent
}

export type CreateMediaLiveStreamWidlmokaOptions = {
  videoEngine: VideoEngine.Wildmoka
  id?: string
  title?: string
  description?: string
  templateLive?: string
  templateClip?: string
  stream?: string
  notificationEmail?: string
  createClipAtEndOfEvent?: boolean
  autoAnnounceDate?: Date
  passthrough: boolean
}

export type CreateMediaLiveStreamSVEOptions = {
  videoEngine: VideoEngine.Sportall
  id?: string
  title?: string
  sveChannelId: SveChannel['id']
}

export type CreateMediaLiveStreamExternalFeedOptions = {
  videoEngine: VideoEngine.ExternalFeed
  id?: string
  title?: string
  externalFeedProvider?: ExternalFeedType
  externalFeedUrl?: string
}

export type CreateMediaLiveStreamOptions =
  | CreateMediaLiveStreamWidlmokaOptions
  | CreateMediaLiveStreamSVEOptions
  | CreateMediaLiveStreamExternalFeedOptions

interface CreateSportItemFormProps extends FormBaseProps<SportItem> {
  title: string
  videoContentType?: VideoContentType
  isLoading?: boolean
  sportItemSetId: SportItemSet['id']
  onDone: (value: SportItem, creationOptions?: CreateMediaLiveStreamOptions) => void
}
const CreateSportItemForm = ({
  title,
  sportItemSetId,
  videoContentType,
  isLoading,
  onCancel,
  onDone,
  onError,
}: CreateSportItemFormProps) => {
  const [createSportItem, { loading: createSportItemLoading }] = useCreateSportItem()
  const intl = useIntl()

  const [valuesForValidation, setValuesForValidation] = useState<SportItemFormValues | null>(null)
  const [selectedTranslations, setSelectedTranslations] = useState<SelectedTranslationsType[]>([])
  const [defaultLocale, setDefaultLocale] = useState<string>('fr')

  const [recapOpen, setRecapOpen] = useState(false)
  const { data: sportItemSetData, loading: sportItemSetLoading } = useSportItemSetQuery({
    variables: { sportItemSetId },
  })
  const videoEngine = sportItemSetData?.sportItemSet?.videoEngine
  const loading = createSportItemLoading || sportItemSetLoading || isLoading

  const onSubmit = useCallback((values: SportItemFormValues) => {
    setValuesForValidation(values)
    setRecapOpen(true)
  }, [])

  const onValidate = useCallback(
    async (formValues: SportItemFormValues) => {
      const {
        titleT: _titleT,
        subtitleT: _subtitleT,
        descriptionT: _descriptionT,
        externalFeedProvider,
        externalFeedUrl,
        videoTypePlaylist,
        ...values
      } = formValues

      const titleT = getTranslationInputFromForm(selectedTranslations, formValues.titleT)
      const subtitleT = getTranslationInputFromForm(selectedTranslations, formValues.subtitleT)
      const descriptionT = getTranslationInputFromForm(selectedTranslations, formValues.descriptionT)

      const input: CreateSportItemInput = {
        ...values,
        titleT,
        subtitleT,
        descriptionT,
        title: formValues.titleT?.[defaultLocale] || '',
        subtitle: formValues.subtitleT?.[defaultLocale] || '',
        description: formValues.descriptionT?.[defaultLocale] || '',
        videoContentType: videoContentType || formValues.videoContentType,
        contentType: formValues.contentType || SportItemContentType.Free,
        type:
          formValues.videoContentType === VideoContentType.Live
            ? SportItemType.SportEventItem
            : SportItemType.PlaylistItem,
        sportItemSetId,
        ...(formValues.sportEventItem
          ? {
              sportEventItem: getSportEventItemCreateInput(formValues.sportEventItem, intl),
            }
          : {}),
        chatActivated: values.chatActivated || false,
        modeRadio: values.modeRadio || false,
      }

      try {
        const result = await createSportItem({ variables: { input } })
        if (result.data) {
          let creationOptions: CreateMediaLiveStreamOptions | undefined
          if (values.sportEventItem) {
            switch (videoEngine) {
              case VideoEngine.Sportall: {
                creationOptions = {
                  title: input.title,
                  videoEngine,
                  sveChannelId: values.sportEventItem.sveChannelId ?? '',
                }
                break
              }
              case VideoEngine.Wildmoka: {
                creationOptions = {
                  title: input.title,
                  videoEngine,
                  stream: values.sportEventItem.streamId,
                  passthrough: values.sportEventItem.passthrough,
                }
                break
              }
            }
          }

          if (videoEngine === VideoEngine.ExternalFeed || videoTypePlaylist === VideoTypePlaylist.ExternalFeed) {
            creationOptions = {
              title: input.title,
              videoEngine: VideoEngine.ExternalFeed,
              externalFeedUrl: externalFeedUrl,
              externalFeedProvider: externalFeedProvider,
            }
          }
          onDone(result.data.createSportItem, creationOptions)
          setRecapOpen(false)
        }
      } catch (error) {
        onError?.(error as Error)
      }
    },
    [
      selectedTranslations,
      defaultLocale,
      videoContentType,
      sportItemSetId,
      intl,
      createSportItem,
      videoEngine,
      onDone,
      onError,
    ],
  )

  const onCancelRecap = useCallback(() => {
    setRecapOpen(false)
  }, [])

  if (!videoEngine) return null

  return (
    <>
      <SportItemForm
        title={title}
        isModal={false}
        edit={false}
        videoContentType={videoContentType}
        editType={!videoContentType}
        onSubmit={onSubmit}
        onCancel={onCancel}
        onChangeSelectedTranslations={setSelectedTranslations}
        defaultLocale={defaultLocale}
        onChangeDefaultLocale={setDefaultLocale}
        selectedTranslations={selectedTranslations}
        loading={loading || false}
        initialValues={
          valuesForValidation || {
            contentPushServiceNamesExcluded: [
              ContentPushStreamServiceName.Molotov,
              ContentPushStreamServiceName.GenericStream,
            ],
          }
        }
        open={!recapOpen}
        videoEngine={videoEngine}
        sportItemSetId={sportItemSetId}
        sportItemSetType={sportItemSetData?.sportItemSet?.type}
      />
      {valuesForValidation && (
        <SportItemRecap
          open={recapOpen}
          values={valuesForValidation}
          onValidate={() => onValidate(valuesForValidation)}
          ownerOrganismId={sportItemSetData.sportItemSet?.ownerOrganismId || undefined}
          onCancel={onCancelRecap}
          selectedTranslations={selectedTranslations}
          videoEngine={videoEngine}
        />
      )}
    </>
  )
}

export default CreateSportItemForm
