import RefreshButton from 'Components/Button/RefreshButton'
import CategoriesManagementModal from 'Components/CategoriesManagementModal'
import LegalRestrictionsPreview from 'Components/LegalRestrictionsPreview'
import Loader from 'Components/Loader'
import PageHeader from 'Components/PageHeader'
import SportItemSetTable from 'Components/SportItemSet/SportItemSetTable'
import TablePagination from 'Components/TablePagination'
import RemoveSportItemSetButton from 'Forms/SportItemSet/RemoveSportItemSetButton'
import { applicationUris } from 'Layout/uris'
import usePagination from 'hooks/usePagination'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import { useGetSportItemSets } from 'models'
import React, { useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link, useNavigate } from 'react-router-dom'
import { Button, Grid, Icon, Input } from 'semantic-ui-react'
import { Permission, SportItemSetType } from 'services/api/graphql'
import { useCurrentUser, useStore } from 'stores'
import styled from 'styled-components'
import { notifyError } from 'tools/toaster'

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 8px;
  }
`

export interface SportItemSetsViewBaseProps {
  type: SportItemSetType
  sportItemUrlFormat: string // {{id}} will be replaced with sport item id
  sportItemSetUrlFormat: string // {{id}} will be replaced with sport item id
}

const SportItemSetsViewBase = observer(
  ({ type, sportItemUrlFormat, sportItemSetUrlFormat }: SportItemSetsViewBaseProps) => {
    const store = useStore()
    const intl = useIntl()
    const currentUser = useCurrentUser()
    const [search, setSearch] = useState<string | null>()
    const navigate = useNavigate()
    const { activePage, onPageChange, paginationParams, pageSize } = usePagination()

    const { data, error, loading, refetch } = useGetSportItemSets({
      variables: { filters: { type, organismId: store.organismId, search }, ...paginationParams },
      fetchPolicy: 'cache-and-network',
    })
    const sportItemSets = (data && data.sportItemSets && data.sportItemSets.sportItemSets) || []
    const totalCount = useMemo(() => (data && data.sportItemSets.totalCount) || 0, [data])

    const totalPages = useMemo(() => {
      if (!totalCount) return 0
      return Math.ceil(totalCount / pageSize)
    }, [pageSize, totalCount])

    useEffect(() => {
      if (error) notifyError(<FormattedMessage id="error.general" />, error)
    }, [error])

    // Categories management
    const [categoriesModalOpen, setCategoryModalOpen] = useState(false)
    const sportItemSetype = type === SportItemSetType.SportEvent ? 'event' : 'playlist'

    const handleOnSearchChange = useMemo(
      () =>
        _.debounce((e: React.ChangeEvent<HTMLInputElement>) => {
          onPageChange(1)
          setSearch(e.target.value)
        }, 300),
      [onPageChange],
    )

    const handleNavigate = (type: string) => {
      navigate(
        type === SportItemSetType.SportEvent ? applicationUris.sportEventCreate : applicationUris.playlistCreate,
        {
          state: { type: type },
        },
      )
    }
    return (
      <Loader loading={loading}>
        <Grid padded>
          <Grid.Column>
            <PageHeader
              title={intl.formatMessage({
                id: `sportItemSet.${sportItemSetype}`,
              })}
            >
              <Input
                icon="search"
                loading={loading}
                placeholder={intl.formatMessage({
                  id: 'users.search',
                })}
                onChange={handleOnSearchChange}
              />
              {currentUser.can(Permission.CategoryUpdate) && currentUser.can(Permission.CategoryDelete) && (
                <Button circular icon="tag" size="mini" onClick={() => setCategoryModalOpen(true)} />
              )}
              <RefreshButton onClick={() => refetch()} loading={loading} />
              {currentUser.can(Permission.SportItemSetCreate) && (
                <Button circular icon="add" color="red" onClick={() => handleNavigate(type)} />
              )}
            </PageHeader>

            <SportItemSetTable
              sportItemSets={sportItemSets}
              refetch={refetch}
              actions={({ id, legalRestrictions, ownerOrganismId, sponsorPlatform, title: sportItemSetTitle }) => (
                <Buttons>
                  {currentUser.can(Permission.SportItemSetManageSponsorPlatform, ownerOrganismId || undefined, true) &&
                    sponsorPlatform && (
                      <Link to="analytics/links">
                        <Icon name="chart bar" link size="large" inverted color="black" />
                      </Link>
                    )}

                  {legalRestrictions.length > 0 && (
                    <LegalRestrictionsPreview values={legalRestrictions} title={sportItemSetTitle} />
                  )}

                  <Link to={sportItemUrlFormat.replace('{{id}}', id)}>
                    <Icon name="sitemap" link size="large" inverted color="black" />
                  </Link>

                  {currentUser.can(Permission.SportItemSetUpdate, ownerOrganismId || undefined, true) && (
                    <Link to={sportItemSetUrlFormat.replace('{{id}}', id)}>
                      <Icon name="edit" link size="large" inverted color="black" />
                    </Link>
                  )}

                  {currentUser.can(Permission.SportItemSetDelete, ownerOrganismId || undefined, true) && (
                    <RemoveSportItemSetButton
                      sportItemSetId={id}
                      confirmText={intl.formatMessage(
                        {
                          id: `sportItemSet.${sportItemSetype}.form.delete.question`,
                        },
                        { title: sportItemSetTitle },
                      )}
                      successText={intl.formatMessage(
                        {
                          id: `sportItemSet.${sportItemSetype}.form.delete.success`,
                        },
                        { title: sportItemSetTitle },
                      )}
                      onDone={() => refetch()}
                    />
                  )}
                </Buttons>
              )}
            />
            {totalPages > 1 && (
              <TablePagination totalPages={totalPages} activePage={activePage || 1} onPageChange={onPageChange} />
            )}
          </Grid.Column>
        </Grid>

        <CategoriesManagementModal open={categoriesModalOpen} onClose={() => setCategoryModalOpen(false)} />
      </Loader>
    )
  },
)

export default SportItemSetsViewBase
