import classNames from 'classnames'
import React from 'react'
import { PiArrowLineLeftBold, PiArrowLineRightBold } from 'react-icons/pi'
import { useIntl } from 'react-intl'
import { Button, Input } from 'semantic-ui-react'
import { MediaLightContentFragment } from 'services/api/graphql'
import { formatTime, timeToSeconds, validateTimeFormat } from 'tools/formatTime'

interface TrimButtonProps {
  trimValue: number
  trimRef: number
  mediaToUpdate?: MediaLightContentFragment
  buttonPosition: string | undefined
  currentTime: number
  validFormat: boolean
  duration: number
  positionToOpen: string | undefined
  onSetPositionToOpen: (value: string | undefined) => void
  onUpdateTrim: (value: number) => void
  onValidFormat: (value: boolean) => void
}

const TrimButton: React.FC<TrimButtonProps> = ({
  trimValue,
  trimRef,
  mediaToUpdate,
  buttonPosition,
  currentTime,
  validFormat,
  duration,
  positionToOpen,
  onSetPositionToOpen,
  onUpdateTrim,
  onValidFormat,
}) => {
  const intl = useIntl()
  const isLeftTrimButton = buttonPosition === 'left'

  const handleTrimChange = (value: string | undefined) => {
    if (value && validateTimeFormat(value)) {
      const newTrimValue = timeToSeconds(value)
      onUpdateTrim(newTrimValue)
      onValidFormat(true)
    } else {
      onValidFormat(false)
    }
  }

  const canceledUpdateInput = () => {
    mediaToUpdate && onUpdateTrim(trimRef)
    onValidFormat(true)
    onSetPositionToOpen(undefined)
  }

  return (
    <div className="flex flex-row">
      {!positionToOpen && isLeftTrimButton && (
        <Button color="black" onClick={() => onUpdateTrim(currentTime)}>
          <PiArrowLineLeftBold size={20} />
        </Button>
      )}
      <div className={classNames({ 'flex flex-row-reverse': !isLeftTrimButton })}>
        {positionToOpen && positionToOpen === buttonPosition && (
          <>
            <button className="bg-red-600 rounded text-white w-20 h-12 ml-2" onClick={() => canceledUpdateInput()}>
              {intl.formatMessage({ id: 'edit.cancel' })}
            </button>
            <Input
              className="w-28 h-full mx-2"
              type="text"
              onChange={e => {
                handleTrimChange(e.target.value)
              }}
              onKeyDown={(e: KeyboardEvent) => {
                if (e.key === 'Enter' && validFormat) {
                  onSetPositionToOpen(undefined)
                }
              }}
              defaultValue={formatTime(trimValue)}
            />
            {validFormat && (
              <Button color="black" className="h-full" onClick={() => onSetPositionToOpen(undefined)}>
                {intl.formatMessage({ id: 'edit.confirmation' })}
              </Button>
            )}
          </>
        )}
      </div>
      {!positionToOpen && (
        <Button className="w-28 h-full" onClick={() => onSetPositionToOpen(buttonPosition)}>
          <div className={classNames({ 'text-blue-600': trimValue !== (isLeftTrimButton ? 0 : duration) })}>
            {formatTime(trimValue)}
          </div>
        </Button>
      )}
      {!positionToOpen && !isLeftTrimButton && (
        <Button color="black" onClick={() => onUpdateTrim(currentTime)}>
          <PiArrowLineRightBold size={20} />
        </Button>
      )}
    </div>
  )
}

export default TrimButton
