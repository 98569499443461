import countriesi18n from 'i18n-iso-countries'
import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Icon, List, Modal, Popup } from 'semantic-ui-react'
import { LegalRestriction } from 'services/api/graphql'

import InvisibleButton from './Button/InvisibleButton'

interface LegalRestrictionsPreviewProps {
  values: LegalRestriction[]
  title: string
}

const LegalRestrictionsPreview: React.FC<LegalRestrictionsPreviewProps> = ({ values, title }) => {
  const intl = useIntl()
  const [modalOpen, setModalOpen] = useState(false)
  return (
    <>
      <InvisibleButton onClick={() => setModalOpen(true)}>
        <Popup
          trigger={<Icon link name="law" size="large" color="black" inverted />}
          content={<FormattedMessage id="legalRestrictions.preview.popup" />}
        />
      </InvisibleButton>
      <Modal
        mountNode={document.getElementById('modals')}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        size="small"
      >
        <Modal.Header>
          <FormattedMessage id="legalRestrictions.preview.header" values={{ title }} />
        </Modal.Header>
        <Modal.Content>
          <List bulleted>
            {values.map(({ countries, type, timeSlots, description }, index) => (
              <List.Item key={index}>
                <strong>{countries.map(code => countriesi18n.getName(code, intl.locale)).join(', ')}: </strong>
                <FormattedMessage id={`legalRestrictions.preview.${type}`} values={timeSlots ?? {}} />
                {description && <em> ({description})</em>}
              </List.Item>
            ))}
          </List>
        </Modal.Content>
      </Modal>
    </>
  )
}

export default LegalRestrictionsPreview
