import ApplicationDesignViewRow from 'Components/ApplicationDesign/ApplicationDesignViewRow'
import RefreshButton from 'Components/Button/RefreshButton'
import Loader from 'Components/Loader'
import PageHeader from 'Components/PageHeader'
import TablePagination from 'Components/TablePagination'
import ApplicationDesignForm from 'Forms/ApplicationDesign/ApplicationDesignForm'
import usePagination from 'hooks/usePagination'
import { useDeleteApplicationDesign, useGetApplicationDesigns } from 'models/ApplicationDesign'
import React, { useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button, Grid, Modal, Table } from 'semantic-ui-react'
import { ApplicationDesign } from 'services/api/graphql'
import { Permission } from 'services/api/graphql'
import { useCurrentUser, useStore } from 'stores'
import { cloneWithoutGraphqlCacheTypenames } from 'tools/graphql'

const ApplicationDesignsView = () => {
  const intl = useIntl()
  const store = useStore()

  const [applicationDesigns, setApplicationDesigns] = useState<ApplicationDesign[]>([])
  const { activePage, onPageChange, paginationParams, pageSize } = usePagination()
  const { loading, refetch, data } = useGetApplicationDesigns({
    variables: { filters: { organismId: store.organismId }, ...paginationParams },
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      const list = (data && data.applicationDesigns && data.applicationDesigns.applicationDesigns) || []
      setApplicationDesigns(cloneWithoutGraphqlCacheTypenames(list))
    },
  })

  const totalCount = useMemo(() => (data && data.applicationDesigns.totalCount) || 0, [data])

  const totalPages = useMemo(() => {
    if (!totalCount) return 0
    return Math.ceil(totalCount / pageSize)
  }, [pageSize, totalCount])

  const [removeApplicationDesign] = useDeleteApplicationDesign()

  const currentUser = useCurrentUser()
  const [formModalOpen, setFormModalOpen] = useState(false)

  return (
    <Loader loading={loading}>
      <Grid padded>
        <Grid.Column>
          <PageHeader title={intl.formatMessage({ id: 'applicationDesign.name' })}>
            <RefreshButton onClick={() => refetch()} loading={loading} />
            {currentUser.can(Permission.ApplicationDesignCreate) && store.organismId && (
              <Modal
                mountNode={document.getElementById('modals')}
                trigger={<Button icon="add" circular color="red" />}
                content={
                  <ApplicationDesignForm
                    organismId={store.organismId}
                    action="create"
                    onDone={() => {
                      refetch()
                      setFormModalOpen(false)
                    }}
                    onCancel={() => setFormModalOpen(false)}
                  />
                }
                onClose={() => setFormModalOpen(false)}
                onOpen={() => setFormModalOpen(true)}
                open={formModalOpen}
              />
            )}
          </PageHeader>
          <Table striped compact celled>
            <Table.Header>
              <Table.Row textAlign="center">
                <Table.HeaderCell>
                  <FormattedMessage id="applicationDesign.title" />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <FormattedMessage id="applicationDesign.theme.primaryColor" />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <FormattedMessage id="applicationDesign.theme.onPrimaryColor" />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <FormattedMessage id="applicationDesign.theme.surfaceColor" />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <FormattedMessage id="applicationDesign.theme.onSurfaceColor" />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <FormattedMessage id="applicationDesign.theme.onSurfaceAlternateColor" />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <FormattedMessage id="applicationDesign.theme.top10Color" />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <FormattedMessage id="applicationDesign.theme.top10BorderColor" />
                </Table.HeaderCell>
                <Table.HeaderCell width={1} textAlign="center">
                  <FormattedMessage id="customPage.actions" />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {applicationDesigns.length > 0 && (
                <>
                  {applicationDesigns.map(applicationDesign => (
                    <ApplicationDesignViewRow
                      key={applicationDesign.id}
                      applicationDesign={applicationDesign}
                      onUpdateApplicationDesign={refetch}
                      onRemoveApplicationDesign={async () => {
                        await removeApplicationDesign({
                          variables: { id: applicationDesign.id },
                        })
                        refetch()
                      }}
                    />
                  ))}
                </>
              )}
            </Table.Body>
          </Table>
          {totalPages > 1 && (
            <TablePagination totalPages={totalPages} activePage={activePage || 1} onPageChange={onPageChange} />
          )}
        </Grid.Column>
      </Grid>
    </Loader>
  )
}

export default ApplicationDesignsView
