import GenericForm from 'Components/Form/GenericForm'
import SAFormField from 'Components/Form/SAFormField'
import { Period, SelectDateRangeInput } from 'Components/Form/SelectDateRangeInput'
import SelectLocalInput, { SelectedTranslationsType } from 'Components/Form/SelectLocalInput'
import Config from 'config'
import { endOfDay, startOfDay } from 'date-fns'
import arrayMutators from 'final-form-arrays'
import { ContentTranslation, ExternalContentWidget, Organism, WidgetExternalContentSource } from 'models'
import React, { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { Divider, Form, Input, Segment } from 'semantic-ui-react'
import { composeValidators, validateRequired } from 'tools/formValidators'

import { WecNewsFilteringCriteria } from './WecNewsFilteringCriteria'

export type ExternalContentProps = {
  organism: Organism
  onCancel: () => void
  onSubmit: (externalContent: ExternalContentWidget) => void
  values?: ExternalContentWidget
}

export const ExternalContent = ({ organism, onCancel, onSubmit, values }: ExternalContentProps) => {
  const intl = useIntl()

  const initialValues = useMemo(
    () =>
      values ?? {
        nameT: [{ locale: Config.availableLocalesOptions[0].value, content: '' }],
        defaultLocale: Config.availableLocalesOptions[0].value,
        source: organism.availableExternalSources?.includes(
          WidgetExternalContentSource.WecNews || WidgetExternalContentSource.LeMansNews,
        )
          ? WidgetExternalContentSource.WecNews
          : WidgetExternalContentSource.RssFeed,
        wecContent: { teamECMIds: [], carECMIds: [], driverECMIds: [], categoryECMIds: [] },
      },
    [values, organism.availableExternalSources],
  ) as ExternalContentWidget

  const [selectedTranslations, setSelectedTranslations] = useState<SelectedTranslationsType[]>(
    initialValues.nameT.map(t => ({
      value: t.locale,
      text: Config.availableLocalesOptions.find(l => l.value === t.locale)?.text,
    })),
  )
  const [defaultLocale, setDefaultLocale] = useState<string>(
    initialValues?.defaultLocale || Config.availableLocalesOptions[0].value,
  )
  const [activeTranslation, setActiveTranslation] = useState<string | undefined>(defaultLocale)
  const [indexTranslation, setIndexTranslation] = useState<number | undefined>(0)

  useEffect(() => {
    setIndexTranslation(selectedTranslations.findIndex(t => t.value === activeTranslation))
  }, [activeTranslation, selectedTranslations])

  return (
    <GenericForm
      header={intl.formatMessage({ id: 'customPage.widgets.externalContent' })}
      initialValues={initialValues}
      mutators={{
        ...arrayMutators,
      }}
      onCancel={onCancel}
      onSubmit={form => {
        onSubmit({
          ...form,
          name: '',
          maxItemsCount: form.maxItemsCount && Number(form.maxItemsCount),
          wecContent: [WidgetExternalContentSource.WecNews, WidgetExternalContentSource.LeMansNews].includes(
            form.source,
          )
            ? form.wecContent
            : undefined,
          rssContent: form.source === WidgetExternalContentSource.RssFeed ? form.rssContent : undefined,
        })
      }}
      loading={false}
    >
      {({ values: { source, wecContent, nameT }, form, errors }) => {
        return (
          <>
            {/* Locale */}
            <SAFormField
              name="locale"
              render={({ input }) => (
                <Form.Field
                  {...input}
                  disabled={false}
                  label={intl.formatMessage({ id: 'locale' })}
                  control={() => (
                    <SelectLocalInput
                      defaultLocale={defaultLocale}
                      onChangeDefaultLocale={setDefaultLocale}
                      activeTranslation={activeTranslation}
                      onChangeActiveTranslation={setActiveTranslation}
                      selectedTranslations={selectedTranslations}
                      onChangeSelectedTranslations={newSelectionTranslations => {
                        form.change(
                          'nameT',
                          newSelectionTranslations.map(st => ({
                            locale: st.value,
                            content: nameT?.find(t => t.locale === st.value)?.content as string,
                          })),
                        )
                        setSelectedTranslations(newSelectionTranslations)
                      }}
                      errors={errors?.['nameT']}
                    />
                  )}
                />
              )}
            />

            {/* Name */}
            {nameT.map((_, i) => (
              <SAFormField<ContentTranslation, HTMLElement>
                key={i}
                name={`nameT[${i}].content`}
                validate={validateRequired}
                render={({ input, meta }) =>
                  indexTranslation === i && (
                    <Form.Field
                      control={Input}
                      {...input}
                      required
                      error={!!meta.touched && !!meta.error}
                      label={intl.formatMessage({ id: 'widget.externalContent.name' })}
                    />
                  )
                }
              />
            ))}

            {/* Max items count */}
            <SAFormField<number, HTMLInputElement>
              name="maxItemsCount"
              validate={composeValidators([
                validateRequired,
                v =>
                  // Number must be greather than 0
                  v > 0 ? undefined : intl.formatMessage({ id: 'common.validation.number-gt-zero' }),
              ])}
              render={({ input, meta }) => (
                <Form.Field
                  control={Input}
                  {...input}
                  type="number"
                  required
                  min="1"
                  // Prevent putting a minus sign
                  onKeyDown={(e: KeyboardEvent) => e.key === '-' && e.preventDefault()}
                  error={!!meta.touched && !!meta.error}
                  label={intl.formatMessage({ id: 'widget.externalContent.maxItemsCount' })}
                />
              )}
            />

            {/* Period */}
            {initialValues.source !== WidgetExternalContentSource.RssFeed && (
              <SAFormField<Period, HTMLDivElement>
                name="period"
                render={({ input, meta }) => (
                  <Form.Field
                    control={SelectDateRangeInput}
                    {...input}
                    onChange={(value: Period | undefined) => {
                      input.onChange(
                        value && {
                          startDate: startOfDay(value.startDate),
                          endDate: endOfDay(value.endDate),
                        },
                      )
                    }}
                    error={!!meta.touched && !!meta.error}
                    label={intl.formatMessage({
                      id: 'widget.externalContent.period',
                    })}
                    placeholder={[
                      intl.formatMessage({
                        id: 'widget.externalContent.period.startDate',
                      }),
                      intl.formatMessage({
                        id: 'widget.externalContent.period.endDate',
                      }),
                    ]}
                  />
                )}
              />
            )}

            <Divider />

            {/* Source */}
            <SAFormField
              name="source"
              render={({ input }) => (
                <Form.Dropdown
                  name="source"
                  value={source ?? ''}
                  onChange={(_e, { value }) => input.onChange(value)}
                  selection
                  options={Object.values(WidgetExternalContentSource)
                    .filter(source => organism.availableExternalSources?.includes(source))
                    .map(source => ({
                      text: intl.formatMessage({ id: `widget.externalContent.${source}.title` }),
                      value: source,
                    }))}
                />
              )}
            />

            {/* WEC News */}
            {(source === WidgetExternalContentSource.WecNews || source === WidgetExternalContentSource.LeMansNews) && (
              <Segment>
                <h4 className="font-bold">{intl.formatMessage({ id: 'widget.externalContent.WECNews.filters' })}</h4>

                {/* Teams */}
                <WecNewsFilteringCriteria
                  ids={wecContent?.teamECMIds ?? []}
                  translations={{ singular: 'team', plural: 'teams' }}
                />

                <Divider />

                {/* Drivers */}
                <WecNewsFilteringCriteria
                  ids={wecContent?.driverECMIds ?? []}
                  translations={{ singular: 'driver', plural: 'drivers' }}
                />

                <Divider />

                {/* Cars */}
                <WecNewsFilteringCriteria
                  ids={wecContent?.carECMIds ?? []}
                  translations={{ singular: 'car', plural: 'cars' }}
                />

                <Divider />

                {/* Categories */}
                <WecNewsFilteringCriteria
                  ids={wecContent?.categoryECMIds ?? []}
                  translations={{ singular: 'category', plural: 'categories' }}
                />

                <Divider />

                {/* Races */}
                <WecNewsFilteringCriteria
                  ids={wecContent?.raceECMIds ?? []}
                  translations={{ singular: 'race', plural: 'races' }}
                />
              </Segment>
            )}

            {/* RSS FEED */}
            {source === WidgetExternalContentSource.RssFeed && (
              <Segment>
                {/* URL */}
                <SAFormField
                  name="rssContent.feedUrl"
                  validate={validateRequired}
                  render={({ input, meta }) => (
                    <Form.Field
                      control={Input}
                      {...input}
                      required
                      error={!!meta.touched && !!meta.error}
                      label={intl.formatMessage({ id: 'widget.externalContent.RSSFeed.url' })}
                    />
                  )}
                />
              </Segment>
            )}
          </>
        )
      }}
    </GenericForm>
  )
}
