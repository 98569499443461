import { graphql } from 'gql'

export const LinkTitleTagFragment = graphql(`
  fragment linkTitleTag on Link {
    id
    title
    tag
  }
`)

export const LinksQueryFragment = graphql(
  `
    fragment linksQuery on LinkPageResult {
      links {
        ...linkTitleTag
      }
      totalCount
    }
  `,
  [LinkTitleTagFragment],
)

export const GetLinksQuery = graphql(
  `
    query getLinks($filters: LinkFilters, $offset: Int, $first: Int, $orderBy: LinkSort) {
      links(filters: $filters, offset: $offset, first: $first, orderBy: $orderBy) {
        ...linksQuery
      }
    }
  `,
  [LinksQueryFragment],
)
