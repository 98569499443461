import { useGetUsersArraySkip } from 'models'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { IntlShape, useIntl } from 'react-intl'
import { clearCSVFile, CSVData, makeCSVFile } from 'tools/csvWriter'
import { format } from 'tools/date'

import { LightUserContentFragment, SignUpOrigin, UserFilters } from './graphql'

/** Initialize a CSV Data with only headers */
const initUserCsvData = (intl: IntlShape, fanView?: boolean): CSVData => {
  const headers = [
    intl.formatMessage({ id: 'users.email' }),
    intl.formatMessage({ id: 'users.name' }),
    intl.formatMessage({ id: 'users.createdAt' }),
    intl.formatMessage({ id: 'users.applicationOriginName' }),
    ...(fanView
      ? [
          intl.formatMessage({ id: 'users.birthdayDate' }),
          intl.formatMessage({ id: 'users.country' }),
          intl.formatMessage({ id: 'users.postalCode' }),
          intl.formatMessage({ id: 'users.gender' }),
          intl.formatMessage({ id: 'users.cgu' }),
          intl.formatMessage({ id: 'users.signUpOrigin' }),
          intl.formatMessage({ id: 'users.verified' }),
          intl.formatMessage({ id: 'users.likedOrganisms' }),
          intl.formatMessage({ id: 'users.licenses' }),
          intl.formatMessage({ id: 'users.receipts.offers' }),
        ]
      : [intl.formatMessage({ id: 'users.organisms' }), intl.formatMessage({ id: 'users.roles' })]),
    intl.formatMessage({ id: 'users.lastActivity' }),
  ]
  return {
    headers,
    rows: [],
  }
}

/** Build a CSV Row for an user */
const buildCSVRow = (
  {
    email,
    firstName,
    lastName,
    applicationOrigin,
    roleIds,
    organisms,
    birthdayDate,
    location,
    gender,
    lastActivity,
    cguAccepted,
    signUpOrigin,
    verified,
    fanPreferences,
    receipts,
    licenses,
    createdAt,
  }: LightUserContentFragment,
  fanView: boolean,
  intl: IntlShape,
): Array<string | number | boolean> => [
  email,
  `"${firstName} ${lastName}"`,
  format(createdAt, 'DD/MM/YYYY'),
  (applicationOrigin && applicationOrigin.title) || 'Sportall',
  ...(fanView
    ? [
        (birthdayDate && format(birthdayDate, 'DD/MM/YYYY')) || '',
        ((location && location.country) || '').toUpperCase(),
        (location && location.postalCode) || '',
        (gender && intl.formatMessage({ id: `user.gender.${gender}` })) || '',
        !!cguAccepted ? intl.formatMessage({ id: 'common.yes' }) : intl.formatMessage({ id: 'common.no' }),
        !signUpOrigin ? 'N/A' : signUpOrigin === SignUpOrigin.External ? 'facebook / google' : signUpOrigin.toString(),
        !!verified ? intl.formatMessage({ id: 'common.yes' }) : intl.formatMessage({ id: 'common.no' }),
        fanPreferences && fanPreferences.followedOrganisms
          ? `"${fanPreferences.followedOrganisms.map(org => org.title).join(', ')}"`
          : '',
        (licenses &&
          `"${licenses
            .map(
              license =>
                `${(license.organism && license.organism.title) || ''} (${format(
                  license.registrationDate,
                  'DD/MM/YYYY',
                )}, ${intl.formatMessage({
                  id: license.isLicensed ? 'user.licenses.active' : 'user.licenses.revoked',
                })})`,
            )
            .join(' / ')}"`) ||
          '',
        receipts
          ? receipts
              .map(
                receipt =>
                  receipt.offer &&
                  `${receipt.offer.title}: ${intl.formatMessage({
                    id: `receiptStatus.${receipt.status}`,
                  })}`,
              )
              .join(' / ')
          : '',
      ]
    : [
        (organisms && organisms.map(organism => organism.title).join(' / ')) || '',
        (roleIds && roleIds.join(' / ')) || '',
      ]),
  lastActivity ? format(lastActivity, 'DD/MM/YYYY') : '',
]

const CHUNK_SIZE = 500

export function useCSVUsers(fanView: boolean, filters?: UserFilters) {
  const intl = useIntl()
  const [csvUrl, setCSVUrl] = useState('')
  const [csvLoading, setCsvLoading] = useState(false)
  const [csvData, setCsvData] = useState<CSVData>({ headers: [], rows: [] })
  const getUsers = useGetUsersArraySkip()
  const [progress, setProgress] = useState(0)

  /**
   * Launch the CSV generation by calling service-platform to retrieve all users
   * This only initializes and fill up the csvData object
   */
  const generateCsv = useCallback(async () => {
    setCsvLoading(true)
    setProgress(0)

    const data = initUserCsvData(intl, fanView)

    // Do a first small call only to know the total count of users
    const { totalCount } = await getUsers({
      filters,
      first: 1,
      offset: 0,
    })
    let usersParsed = 0
    let page = 0

    // Retrieve users chunk by chunk
    while (usersParsed < (totalCount || 0)) {
      const { usersArray } = await getUsers({
        filters,
        first: CHUNK_SIZE,
        offset: page * CHUNK_SIZE,
      })
      // Add users to rows
      data.rows = [...data.rows, ...usersArray.map(user => buildCSVRow(user, fanView, intl))]
      usersParsed += usersArray.length
      setProgress(totalCount ? usersParsed / totalCount : 1)
      page++
    }

    setProgress(1)
    setCsvData(data)
  }, [fanView, filters, getUsers, intl])

  useEffect(() => {
    // When csv data is updated, write a CSV File
    if (csvData) {
      const fileUrl = makeCSVFile(csvData)
      setCSVUrl(fileUrl)
      setCsvLoading(false)

      // Clear file once csvData changed or the component is unmount
      return () => clearCSVFile(fileUrl)
    }
  }, [csvData])

  useEffect(() => {
    // Empty CSV data if filters change
    setCsvData({ headers: [], rows: [] })
  }, [fanView, filters])

  const csvGenerated = useMemo(() => csvData.rows.length > 0, [csvData])

  return { csvUrl, generateCsv, csvLoading, csvGenerated, progress }
}
