import CreateLinkForm from 'Forms/Link/CreateLinkForm'
import React, { Fragment, useState } from 'react'
import { useIntl } from 'react-intl'
import { notifyError, notifySuccess } from 'tools/toaster'

interface AddLinkButtonProps {
  onDone: () => void
  render: (onClick: () => void) => React.ReactNode
}
const AddLinkButton = ({ onDone, render }: AddLinkButtonProps) => {
  const intl = useIntl()
  const [form, setForm] = useState<React.ReactNode>()
  const handleClick = () => {
    setForm(
      <CreateLinkForm
        onCancel={() => setForm(null)}
        onDone={link => {
          setForm(null)
          notifySuccess(intl.formatMessage({ id: 'links.form.create.succcess' }, { title: link.title }))
          onDone()
        }}
        onError={notifyError}
      />,
    )
  }
  return (
    <Fragment>
      {render(handleClick)}
      {form}
    </Fragment>
  )
}

export default AddLinkButton
