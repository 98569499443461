import countriesi18n from 'i18n-iso-countries'
import enCountries from 'i18n-iso-countries/langs/en.json'
import frCountries from 'i18n-iso-countries/langs/fr.json'
import { useEffect } from 'react'
import { useStore } from 'stores'

import en from './en.json'
import fr from './fr.json'

countriesi18n.registerLocale(enCountries)
countriesi18n.registerLocale(frCountries)

export enum AvailableLangs {
  fr = 'fr',
  en = 'en',
}

interface ITranslation {
  [key: string]: string
}

export interface IMessages {
  [key: string]: ITranslation
}

export const messages: IMessages = {
  [AvailableLangs.en]: en,
  [AvailableLangs.fr]: fr,
}

export function useI18n() {
  const { lang, setLang } = useStore()

  useEffect(() => {
    const navigatorLang = navigator.language.split(/[-_]/)[0].toLowerCase()
    setLang(Object.keys(AvailableLangs).includes(navigatorLang) ? navigatorLang : AvailableLangs.en)
  }, [lang, setLang])

  return {
    lang: lang || AvailableLangs.en,
    messages: messages[lang || AvailableLangs.en],
  }
}
