import { DraggableProvided } from '@hello-pangea/dnd'
import SelectSportItemSetTypeInput from 'Containers/Form/SelectSportItemSetTypeInput'
import EditedCategoryView from 'Views/EditedCategoryView'
import { Tooltip } from 'antd'
import classNames from 'classnames'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { Button, DropdownItemProps, Form, Icon, Modal, Table } from 'semantic-ui-react'
import {
  ContentSetContentDisplay,
  CardsDisplay,
  CustomPageDisplayTypes,
  CustomPageWidget,
  CustomPageWidgetName,
  EditedCategory,
  Organism,
  SportItemSetType,
} from 'services/api/graphql'
import { currentLocale } from 'tools/date'

import { ExternalContent } from './ExternalContent'
import { Replays } from './Replays'

function isAutomaticWidget({ name }: CustomPageWidget): boolean {
  return [
    CustomPageWidgetName.Live,
    CustomPageWidgetName.Upcoming,
    CustomPageWidgetName.ReadingList,
    CustomPageWidgetName.Top10,
    CustomPageWidgetName.Replays,
  ].includes(name)
}

interface WidgetRowProps {
  editedCategoryList?: EditedCategory[]
  provided: DraggableProvided
  excludeNames?: CustomPageWidgetName[]
  index?: number
  value: CustomPageWidget
  organism: Organism
  onChange: (value: CustomPageWidget) => void
  onRemove: () => void
  activeTranslation?: string
}

const WidgetRow: React.FC<WidgetRowProps> = ({
  editedCategoryList,
  provided,
  excludeNames,
  index,
  value: widget,
  organism,
  onChange,
  onRemove,
  activeTranslation,
}) => {
  const intl = useIntl()
  const [editedCategoriesOptions, setEditedCategoryOptions] = useState<DropdownItemProps[]>([])
  const [openModal, setOpenModal] = useState(false)
  const [openEditedCategoryModal, setOpenEditedCategoryModal] = useState(false)

  const createDropdownOptions = (values: string[]): DropdownItemProps[] => {
    return Object.values(values).map(
      (item): DropdownItemProps => ({
        value: item,
        text: intl.formatMessage({ id: `customPage.widgets.${item}` }),
      }),
    )
  }
  const cardsDisplayOptions = createDropdownOptions([CardsDisplay.Carousel, CardsDisplay.Grid])
  const contentDisplayOptions = createDropdownOptions([
    ContentSetContentDisplay.All,
    ContentSetContentDisplay.ImageWithTitle,
    ContentSetContentDisplay.ImageOnly,
  ])
  const displayTypesOptions = createDropdownOptions([
    CustomPageDisplayTypes.Classic,
    CustomPageDisplayTypes.SecondClass,
    CustomPageDisplayTypes.FirstClass,
  ])

  useEffect(() => {
    if (editedCategoryList) {
      setEditedCategoryOptions(
        editedCategoryList.map(
          (item): DropdownItemProps => ({
            value: item.id,
            text: item.title,
          }),
        ),
      )
    }
  }, [editedCategoryList])

  const nameOptions: DropdownItemProps[] = useMemo(() => {
    const widgetNames = Object.values(CustomPageWidgetName).filter(name =>
      excludeNames ? !excludeNames.includes(name) : true,
    )
    return widgetNames.map(
      (name): DropdownItemProps => ({
        value: name,
        text: intl.formatMessage({ id: `customPage.widgets.${name}` }),
      }),
    )
  }, [excludeNames, intl])

  const widgetOptions = useMemo(() => widget.options || {}, [widget])
  const onEditName = useCallback(
    (name: CustomPageWidgetName) => {
      onChange({
        ...widget,
        name,
        options: {
          ...widgetOptions,
          editedCategoryId: name === CustomPageWidgetName.EditedCategories ? widgetOptions.editedCategoryId : undefined,
        },
      })
    },
    [onChange, widget, widgetOptions],
  )

  return (
    <>
      <Table.Row {...provided.draggableProps}>
        <Tooltip
          title={isAutomaticWidget(widget) ? intl.formatMessage({ id: 'customPage.widgets.automatic' }) : undefined}
        >
          <Table.Cell
            collapsing
            textAlign="center"
            {...provided.dragHandleProps}
            className={classNames({ 'bg-indigo-400 text-white': isAutomaticWidget(widget) })}
          >
            <Icon name="bars" />
          </Table.Cell>
        </Tooltip>

        <Table.Cell>
          <Form.Dropdown
            name={`widget-${index}-name`}
            value={widget.name}
            onChange={(_e, { value }) => onEditName(value as CustomPageWidgetName)}
            selection
            options={nameOptions}
          />
        </Table.Cell>
        <>
          {widget.name === CustomPageWidgetName.ExternalContent ? (
            <>
              <Table.Cell colSpan={3}>
                <span>
                  {
                    widget.options?.externalContent?.nameT.find(t => t.locale === (activeTranslation ?? currentLocale))
                      ?.content
                  }
                </span>
              </Table.Cell>
            </>
          ) : (
            <>
              <Table.Cell>
                <Form.Dropdown
                  name={`widget-${index}-cardsDisplay`}
                  value={widget.cardsDisplay || CardsDisplay.Carousel}
                  onChange={(_e, { value }) =>
                    onChange({
                      ...widget,
                      cardsDisplay: value as CardsDisplay,
                    })
                  }
                  selection
                  options={cardsDisplayOptions}
                />
              </Table.Cell>
              <Table.Cell>
                <Form.Dropdown
                  name={`widget-${index}-displayContent`}
                  value={widget.contentDisplay || ContentSetContentDisplay.All}
                  onChange={(_e, { value }) =>
                    onChange({
                      ...widget,
                      contentDisplay: value as ContentSetContentDisplay,
                    })
                  }
                  selection
                  options={contentDisplayOptions}
                />
              </Table.Cell>
              <Table.Cell>
                <Form.Dropdown
                  name={`widget-${index}-display`}
                  value={widget.display || undefined}
                  onChange={(_e, { value }) =>
                    onChange({
                      ...widget,
                      display: value as CustomPageDisplayTypes,
                    })
                  }
                  selection
                  options={displayTypesOptions}
                />
              </Table.Cell>
            </>
          )}
        </>

        <Table.Cell textAlign="center">
          {widget.name === CustomPageWidgetName.EditedCategories && editedCategoriesOptions.length > 0 && (
            <Form.Dropdown
              name={`widget-${index}-editedCategoryId`}
              value={widgetOptions.editedCategoryId || undefined}
              onChange={(_e, { value }) =>
                onChange({
                  ...widget,
                  options: {
                    ...widgetOptions,
                    editedCategoryId: value as string,
                  },
                })
              }
              selection
              options={editedCategoriesOptions}
            />
          )}

          {widget.name === CustomPageWidgetName.AllContent && (
            <SelectSportItemSetTypeInput
              value={widgetOptions.sportItemSetType || SportItemSetType.SportEvent}
              onChange={type =>
                onChange({
                  ...widget,
                  options: {
                    ...widgetOptions,
                    sportItemSetType: type || undefined,
                  },
                })
              }
            />
          )}
        </Table.Cell>

        <Table.Cell textAlign="right" verticalAlign="bottom">
          {[CustomPageWidgetName.ExternalContent, CustomPageWidgetName.Replays].includes(widget.name) && (
            <Button icon onClick={() => setOpenModal(true)} size="small">
              <Icon name="edit" />
            </Button>
          )}

          {widget.name === CustomPageWidgetName.EditedCategories && widgetOptions.editedCategoryId && (
            <Modal
              mountNode={document.getElementById('modals')}
              trigger={<Icon name="eye" link size="large" />}
              content={
                <EditedCategoryView
                  editedCategoryViewModalId={widgetOptions.editedCategoryId as string}
                  onClose={() => setOpenEditedCategoryModal(false)}
                />
              }
              onOpen={() => setOpenEditedCategoryModal(true)}
              open={openEditedCategoryModal}
            />
          )}
          <Button icon onClick={onRemove} size="small">
            <Icon name="remove" />
          </Button>
        </Table.Cell>
      </Table.Row>

      {openModal && widget.name === CustomPageWidgetName.ExternalContent && (
        <ExternalContent
          values={widget.options?.externalContent ?? undefined}
          organism={organism}
          onCancel={() => setOpenModal(false)}
          onSubmit={externalContent => {
            setOpenModal(false)
            onChange({
              ...widget,
              options: {
                ...widget.options,
                externalContent,
              },
            })
          }}
        />
      )}

      {openModal && widget.name === CustomPageWidgetName.Replays && (
        <Replays
          values={widget.options?.replays ?? undefined}
          onCancel={() => setOpenModal(false)}
          onSubmit={replays => {
            setOpenModal(false)
            onChange({
              ...widget,
              options: {
                ...widget.options,
                replays,
              },
            })
          }}
        />
      )}
    </>
  )
}

export default WidgetRow
