import { graphql } from 'gql'

import { LinkItemFragment } from './LinkItem/fragments'
import { NewsItemFragment } from './NewsItem/fragments'

export const GetLinksQuery = graphql(
  `
    query getLinks($filters: LinkFilters, $offset: Int, $first: Int, $orderBy: LinkSort) {
      links(filters: $filters, offset: $offset, first: $first, orderBy: $orderBy) {
        links {
          ...LinkItem
        }
        totalCount
      }
    }
  `,
  [LinkItemFragment],
)

export const GetNewssQuery = graphql(
  `
    query newss($filters: NewsFilters) {
      newss(filters: $filters) {
        news {
          ...NewsItem
        }
        totalCount
      }
    }
  `,
  [NewsItemFragment],
)
