import { useUserRole } from 'models'
import React from 'react'
// import { UserRoleId } from 'services/api/graphql'
import { Icon, IconProps, Popup, SemanticICONS } from 'semantic-ui-react'
import { UserRoleId } from 'services/api/graphql'

type UserRoleIconDescriptor = {
  color: string
  name: SemanticICONS
}
type UserRoleIconDescriptors = {
  [key in string]: UserRoleIconDescriptor
}
const normalColor = '#FF531C'
const organismRoleColor = '#00AC8C'
const importantColor = '#2185d0'
const userRoleIconDescriptors: UserRoleIconDescriptors = {
  superAdmin: { color: importantColor, name: 'spy' },
  admin: { color: normalColor, name: 'spy' },
  fan: { color: normalColor, name: 'mobile alternate' },
  procaster: { color: normalColor, name: 'video camera' },
  organismAdministrator: { color: organismRoleColor, name: 'spy' },
  organismEditor: { color: organismRoleColor, name: 'pencil alternate' },
  organismMember: { color: organismRoleColor, name: 'user' },
  organismTester: { color: organismRoleColor, name: 'stethoscope' },
  wildmoka: { color: normalColor, name: 'server' },
  vip: { color: organismRoleColor, name: 'star' },
  anonymous: { color: normalColor, name: 'user outline' },
}
export interface UserRoleIconProps extends IconProps {
  roleId: UserRoleId
}
const UserRoleIcon = ({ roleId, ...props }: UserRoleIconProps) => {
  const { userRole } = useUserRole(roleId)
  const iconDescriptor: UserRoleIconDescriptor = userRoleIconDescriptors[roleId]
  return (
    <Popup
      content={userRole && userRole.description}
      header={userRole && userRole.title}
      trigger={iconDescriptor && <Icon name={iconDescriptor.name} style={{ color: iconDescriptor.color }} {...props} />}
      position="top center"
    />
  )
}

export default UserRoleIcon
