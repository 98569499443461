import CropImageView from 'Components/CropImage/CropImageView'
import React from 'react'
import { Modal, ModalProps } from 'semantic-ui-react'
import { ImageConstraints, ImageDimensions } from 'types/image'

export interface CropImageButtonProps extends ModalProps {
  image: Blob
  canImportSourceImage?: boolean
  onDone: (croppedImage: File, imageSize: ImageDimensions) => void
  imageConstraints?: ImageConstraints
  cropButtonTitle?: string | undefined
  loading?: boolean
}
const CropImageModal = ({
  image,
  onDone,
  canImportSourceImage,
  imageConstraints,
  cropButtonTitle,
  ...props
}: CropImageButtonProps) => {
  return (
    <Modal
      mountNode={document.getElementById('modals')}
      closeIcon
      size="fullscreen"
      {...props}
      closeOnDimmerClick={false}
    >
      <Modal.Header content="Retaille d'image" />

      <Modal.Content style={{ height: '80vh' }}>
        {image && (
          <CropImageView
            image={image}
            onDone={onDone}
            canImportSourceImage={canImportSourceImage}
            imageConstraints={imageConstraints}
            buttonTitle={cropButtonTitle}
          />
        )}
      </Modal.Content>
    </Modal>
  )
}

export default CropImageModal
