import { gql } from '@apollo/client'

export const UPDATE_RED_BUTTON_CONFIG_MUTATION = gql`
  mutation updateRedButtonConfig {
    updateRedButtonConfig {
      lives {
        id
        title
        published
        expiryDate
      }
    }
  }
`
