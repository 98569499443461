import { applicationUris } from 'Layout/uris'
import ContentPushStreamsView from 'Views/ContentPushStreamsView'
import DefaultConfigView from 'Views/DefaultConfigView'
import LegalRestrictionView from 'Views/LegalRestrictionsView'
import { SVEChannelView } from 'Views/SVEChannelView'
import { SVEChannelsView } from 'Views/SVEChannelsView'
import StreamMapView from 'Views/StreamMapView'
import ThirdPartyApplicationsView from 'Views/ThirdPartyApplicationsView'
import React from 'react'
import { Access, userCanAccessToPath } from 'services/api'
import { IUser } from 'stores'

const administrationRoutes = (user: IUser | null) => [
  {
    path: applicationUris.defaultConfig,
    element: <DefaultConfigView />,
    loader: () => userCanAccessToPath(user, [Access.DefaultConfig]),
  },
  {
    path: applicationUris.contentPushStreams,
    element: <ContentPushStreamsView />,
    loader: () => userCanAccessToPath(user, [Access.ContentPushStreams]),
  },
  {
    path: applicationUris.sveChannels,
    element: <SVEChannelsView />,
    loader: () => userCanAccessToPath(user, [Access.SVEChannels]),
  },
  {
    path: applicationUris.sveChannel(':sveChannelId'),
    element: <SVEChannelView />,
    loader: () => userCanAccessToPath(user, [Access.SVEChannels]),
  },
  {
    path: applicationUris.streamMap,
    element: <StreamMapView />,
    loader: () => userCanAccessToPath(user, [Access.StreamMap]),
  },
  {
    path: applicationUris.legalRestrictions,
    element: <LegalRestrictionView />,
    loader: () => userCanAccessToPath(user, [Access.LegalRestrictions]),
  },
  {
    path: applicationUris.thirdPartyApplications,
    element: <ThirdPartyApplicationsView />,
    loader: () => userCanAccessToPath(user, [Access.ThirdPartyApplicationView]),
  },
]

export default administrationRoutes
