import React, { MouseEventHandler } from 'react'

import { Button } from 'semantic-ui-react'

export interface RefreshButtonProps {
  onClick: MouseEventHandler<HTMLElement>
  loading: boolean
  color?: Button['props']['color']
}

const RefreshButton = ({ onClick, loading, color }: RefreshButtonProps) => (
  <Button size="mini" icon="refresh" color={color} loading={loading} circular onClick={onClick} />
)

export default RefreshButton
