import CancelFormButton from 'Components/Form/CancelFormButton'
import arrayMutators from 'final-form-arrays'
import {
  ThirdPartyApplicationContentFragment,
  useUpdateThirdPartyApplicationMutation,
  useCreateThirdPartyApplicationMutation,
  UpdateThirdApplicationKeyInput,
} from 'models'
import { nanoid } from 'nanoid'
import React, { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button, Dimmer, Modal } from 'semantic-ui-react'
import { notifySuccess } from 'tools/toaster'

import KeyFormFields from './KeyForm/KeyForm'
import ThirdPartyApplicationFormFields from './ThirdPartyApplicationFormFields'

interface ThirdPartyApplicationFormProps {
  isEdit: boolean
  thirdPartyApplication?: ThirdPartyApplicationContentFragment
  organismId?: string
  onCancel: () => void
  onDone?: () => void
}

type KeyToEdit = {
  index: number
  key: UpdateThirdApplicationKeyInput | undefined | null
}

const ThirdPartyApplicationForm: React.FC<ThirdPartyApplicationFormProps> = ({
  thirdPartyApplication,
  organismId,
  isEdit,
  onCancel,
  onDone,
}) => {
  const intl = useIntl()
  const [updateThirdPartyApplication, { loading: isUpdateLoading }] = useUpdateThirdPartyApplicationMutation()
  const [createThirdPartyApplication, { loading: isCreateLoading }] = useCreateThirdPartyApplicationMutation()
  const [keyToEdit, setKeyToEdit] = useState<KeyToEdit>({ key: null, index: 0 })
  const [isLoading, setLoading] = useState(false)
  const [keys, setKeys] = useState<UpdateThirdApplicationKeyInput[] | undefined>(
    thirdPartyApplication?.keys || undefined,
  )

  const handleKeyToEditChange = (key: UpdateThirdApplicationKeyInput | undefined | null, index: number) => {
    setKeyToEdit({ key, index })
  }

  const handleSubmitKey = (value: UpdateThirdApplicationKeyInput) => {
    const newKeys = [...(keys || [])]
    newKeys[keyToEdit.index] = value
    setKeys(newKeys)
    handleKeyToEditChange(null, 0)
  }

  const handleRemoveKeyAtIndex = (index: number) => {
    const newKeys = [...(keys || [])]
    newKeys.splice(index, 1)
    setKeys(newKeys)
  }

  useEffect(() => {
    setLoading(isCreateLoading || isUpdateLoading)
  }, [isCreateLoading, isUpdateLoading])

  const handleSubmitThirdPartyApplication = async (values: any) => {
    delete values.organism
    if (isEdit && thirdPartyApplication) {
      const result = await updateThirdPartyApplication({
        variables: { input: { ...values, keys: keys?.map(key => ({ ...key, key: key.key || nanoid() })) || [] } },
      })
      if (result && result.data) {
        notifySuccess(intl.formatMessage({ id: 'thirdPartyApplication.update_success' }))
        onDone?.()
      }
    } else if (!isEdit) {
      if (organismId) {
        values['organismId'] = organismId
      }
      const result = await createThirdPartyApplication({
        variables: { input: { ...values, keys: keys?.map(key => ({ ...key, key: key.key || nanoid() })) || [] } },
      })
      if (result && result.data) {
        notifySuccess(intl.formatMessage({ id: 'thirdPartyApplication.create_success' }))
        onDone?.()
      }
    }
  }

  return (
    <>
      <Form
        onSubmit={handleSubmitThirdPartyApplication}
        mutators={{
          ...arrayMutators,
        }}
        initialValues={thirdPartyApplication}
        render={formProps => {
          const { handleSubmit, form, pristine, hasValidationErrors } = formProps
          return (
            <>
              <Modal.Header>
                <FormattedMessage id="thirdPartyApplication.title" />
              </Modal.Header>
              <Modal.Content>
                <Dimmer.Dimmable dimmed={isLoading}>
                  <Dimmer active={isLoading} inverted />
                  <ThirdPartyApplicationFormFields
                    keys={keys || []}
                    onChangeKeyToEdit={handleKeyToEditChange}
                    onRemoveKeyAtIndex={handleRemoveKeyAtIndex}
                  />
                </Dimmer.Dimmable>
              </Modal.Content>
              <Modal.Actions>
                <CancelFormButton pristine={pristine} onConfirm={form.reset} onCancel={onCancel} />
                <Button positive loading={isLoading} onClick={() => handleSubmit()} disabled={hasValidationErrors}>
                  <FormattedMessage id="common.submit" />
                </Button>
              </Modal.Actions>
            </>
          )
        }}
      />
      <Modal
        mountNode={document.getElementById('modals')}
        open={keyToEdit.key !== null}
        onClose={() => handleKeyToEditChange(null, 0)}
      >
        <Form
          onSubmit={handleSubmitKey}
          mutators={{
            ...arrayMutators,
          }}
          initialValues={keyToEdit.key || undefined}
          render={formProps => {
            const { handleSubmit, form, pristine, hasValidationErrors } = formProps
            return (
              <>
                <Modal.Header>{intl.formatMessage({ id: 'common.key' })}</Modal.Header>
                <Modal.Content>
                  <KeyFormFields />
                </Modal.Content>
                <Modal.Actions>
                  <CancelFormButton
                    pristine={pristine}
                    onConfirm={form.reset}
                    onCancel={() => setKeyToEdit({ key: null, index: 0 })}
                  />
                  <Button positive onClick={handleSubmit} disabled={hasValidationErrors}>
                    {intl.formatMessage({ id: 'common.submit' })}
                  </Button>
                </Modal.Actions>
              </>
            )
          }}
        />
      </Modal>
    </>
  )
}

export default ThirdPartyApplicationForm
