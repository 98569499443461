import CheckboxInput from 'Components/Form/CheckboxInput'
import GenericForm from 'Components/Form/GenericForm'
import SAFormField from 'Components/Form/SAFormField'
import { SelectDateInput } from 'Components/Form/SelectDateInput'
import { SelectWMStreamInput, WMStreamInputItem } from 'Containers/Form/SelectWMStreamInput'
import SelectWMTemplateInput from 'Containers/Form/SelectWMTemplateInput'
import { ValidationErrors } from 'final-form'
import { observer } from 'mobx-react-lite'
import { getLiveStreamTitle, useCheckStreamUnused, useGetDefaultConfig } from 'models'
import React, { useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Form, Icon, Input, Message, Popup } from 'semantic-ui-react'
import { Scalars, SportItemContentFragment } from 'services/api/graphql'
import { useTemplates } from 'stores'
import { validateEmail, validateRequired } from 'tools/formValidators'

interface WildmokaMediaLiveFormProps {
  onSubmit: (data: WildmokaMediaLiveFormData) => void
  onCancel: () => void
  open: boolean
  value?: WildmokaMediaLiveFormData
  loading?: boolean
  sportItem: SportItemContentFragment
}

export interface WildmokaMediaLiveFormData {
  id?: string
  title?: string
  description?: string
  templateLive?: Scalars['WildmokaID']
  templateClip?: Scalars['WildmokaID']
  stream?: Scalars['WildmokaID']
  notificationEmail?: string
  createClipAtEndOfEvent?: boolean
  autoAnnounceDate?: Date
  passthrough: boolean
}

/**
 * WildmokaMediaLiveForm: Formulaire d'édition de propriétés propres à un event Wildmoka
 * Champs:
 *  - templateLiveId "Template" (WM)
 *  - templateClipId "Template de replay" (WM)
 *  - streamId (check conflit) "Stream" (WM)
 *  - createClipAtEndOfEvent "Créer un clip à la fin de l'événement" (WM)
 *  - title "Nom"
 *  - description "Description"
 *  - notificationEmail "Email de notification" (WM)
 *  - autoAnnounceDate "Date d'annonce" (WM)
 *  - passthrough "Désactiver l'overlay" (WM)
 */
export const WildmokaMediaLiveForm: React.FC<WildmokaMediaLiveFormProps> = observer(
  ({ onSubmit, onCancel, open, value, sportItem, loading }) => {
    const intl = useIntl()

    const [initialValues, setInitialValues] = useState<WildmokaMediaLiveFormData>()
    const { defaultConfig, loading: loadingConfig } = useGetDefaultConfig()

    // Values we need to watch for validation
    const [streamId, setStreamId] = useState((value && value.stream) || '')

    // Set initial values
    useEffect(() => {
      const initialStreamId =
        (value && value.stream) ||
        (sportItem.sportItemSet && sportItem.sportItemSet.streamsIds && sportItem.sportItemSet.streamsIds[0])
      if (initialStreamId) setStreamId(initialStreamId)

      const initVal: WildmokaMediaLiveFormData = value || {
        title: sportItem.title,
        description: sportItem.description || '',
        stream: initialStreamId || undefined,
        templateLive:
          (sportItem.sportItemSet &&
            sportItem.sportItemSet.sportEvent &&
            sportItem.sportItemSet.sportEvent.liveTemplateId) ||
          (defaultConfig && defaultConfig.liveDefaultTemplate),
        templateClip:
          (sportItem.sportItemSet &&
            sportItem.sportItemSet.sportEvent &&
            sportItem.sportItemSet.sportEvent.clipTemplateId) ||
          (defaultConfig && defaultConfig.liveDefaultTemplate),
        createClipAtEndOfEvent: true,
        passthrough: false,
      }
      setInitialValues(initVal)
      setStreamId(initVal.stream || '')
    }, [value, sportItem, defaultConfig])

    const streams: WMStreamInputItem[] = useMemo(() => {
      if (sportItem.sportItemSet && sportItem.sportItemSet.streams) {
        return sportItem.sportItemSet.streams
      }
      return []
    }, [sportItem])

    const templates = useTemplates()

    const now = useMemo(() => new Date(), [])
    const { data: streamConflictsData, loading: loadingCheckStream } = useCheckStreamUnused({
      sportItemSetId: sportItem.sportItemSetId,
      from: (sportItem.sportEventItem && sportItem.sportEventItem.startDate) || now,
      to: (sportItem.sportEventItem && sportItem.sportEventItem.stopDate) || now,
      streamId,
    })
    const streamConflicts = useMemo(
      () =>
        streamConflictsData &&
        streamConflictsData.filter(conflict => initialValues && initialValues.id !== conflict.liveStream.id),
      [streamConflictsData, initialValues],
    )

    const validate = (data: WildmokaMediaLiveFormData) => {
      const errors: ValidationErrors = {}

      // Validate stream is not used by another livestream
      if (data.stream && streamConflicts && streamConflicts.length > 0) {
        errors.stream = streamConflicts.map(conflict => {
          const stream = streams.find(str => str.id === data.stream)
          return intl.formatMessage(
            { id: 'livestream.stream_conflict' },
            {
              stream: (stream && stream.name) || '',
              liveStream: getLiveStreamTitle(conflict.liveStream),
              sportItem: (conflict.liveStream.sportItem && conflict.liveStream.sportItem.title) || '',
            },
          )
        })
      }
      return errors
    }

    return (
      <GenericForm
        header={intl.formatMessage({
          id: value ? `sportItemMedia.live.update` : `sportItemMedia.live.create`,
        })}
        initialValues={initialValues}
        onCancel={onCancel}
        onSubmit={onSubmit}
        validate={validate}
        loading={loading || templates.loading || loadingConfig || loadingCheckStream || false}
        open={open}
      >
        {({ values }) => {
          return (
            <>
              <SAFormField
                name="templateLive"
                validate={validateRequired}
                render={({ input, meta }) => (
                  <Form.Field required error={!!meta.touched && !!meta.error}>
                    <label>
                      <FormattedMessage id="sportItemMedia.live.templateLive" />
                    </label>
                    <SelectWMTemplateInput {...input} source={templates.live} loading={templates.loading} />
                  </Form.Field>
                )}
              />
              <SAFormField
                name="templateClip"
                validate={validateRequired}
                render={({ input, meta }) => (
                  <Form.Field required error={!!meta.touched && !!meta.error}>
                    <label>
                      <FormattedMessage id="sportItemMedia.live.templateClip" />
                    </label>
                    <SelectWMTemplateInput {...input} source={templates.video} loading={templates.loading} />
                  </Form.Field>
                )}
              />
              <SAFormField
                name="stream"
                validate={validateRequired}
                render={({ input, meta }) => (
                  <Form.Field required error={(!!meta.touched || input.value) && !!meta.error}>
                    <label>
                      <FormattedMessage id="sportItemMedia.live.stream" />
                    </label>
                    <SelectWMStreamInput
                      source={streams}
                      {...input}
                      onChange={val => {
                        setStreamId(val as string)
                        input.onChange(val)
                      }}
                    />
                    {streamConflicts && streamConflicts.length > 0 && (
                      <Message
                        negative
                        content={
                          meta.error &&
                          meta.error.map((message: string) => (
                            <span key={message}>
                              {message}
                              <br />
                            </span>
                          ))
                        }
                      />
                    )}
                  </Form.Field>
                )}
              />

              <SAFormField
                name="createClipAtEndOfEvent"
                render={({ input, meta }) => (
                  <Form.Field error={!!meta.touched && !!meta.error}>
                    <CheckboxInput
                      {...input}
                      checked={values.createClipAtEndOfEvent}
                      label={
                        <label>
                          <FormattedMessage id="sportItemMedia.live.createClipAtEndOfEvent" />
                        </label>
                      }
                    />
                  </Form.Field>
                )}
              />

              <SAFormField
                name="title"
                validate={validateRequired}
                render={({ input, meta }) => (
                  <Form.Field
                    control={Input}
                    {...input}
                    required
                    error={!!meta.touched && !!meta.error}
                    label={intl.formatMessage({ id: 'sportItemMedia.live.name' })}
                  />
                )}
              />

              <SAFormField
                name="description"
                render={({ input, meta }) => (
                  <Form.Field
                    control={Input}
                    {...input}
                    error={!!meta.touched && !!meta.error}
                    label={intl.formatMessage({
                      id: 'sportItemMedia.live.description',
                    })}
                  />
                )}
              />
              {/* WM Specific */}
              <SAFormField
                name="notificationEmail"
                validate={validateEmail}
                render={({ input, meta }) => (
                  <Form.Field
                    control={Input}
                    {...input}
                    error={!!meta.touched && !!meta.error}
                    label={intl.formatMessage({
                      id: 'sportItemMedia.live.notificationEmail',
                    })}
                  />
                )}
              />
              <SAFormField
                name="autoAnnounceDate"
                render={({ input, meta }) => (
                  <Form.Field
                    datetime={true}
                    control={SelectDateInput}
                    {...input}
                    error={!!meta.touched && !!meta.error}
                    label={intl.formatMessage({
                      id: 'sportItemMedia.live.autoAnnounceDate',
                    })}
                  />
                )}
              />

              <SAFormField
                name="passthrough"
                render={({ input, meta }) => (
                  <Form.Field error={!!meta.touched && !!meta.error}>
                    <CheckboxInput
                      {...input}
                      checked={values.passthrough}
                      label={
                        <label>
                          <FormattedMessage id="sportItemMedia.live.passthrough" />
                        </label>
                      }
                    />
                    <Popup
                      content={<FormattedMessage id="sportEventItem.passthrough_info" />}
                      trigger={<Icon name="question circle" color="blue" />}
                    />
                  </Form.Field>
                )}
              />
            </>
          )
        }}
      </GenericForm>
    )
  },
)
