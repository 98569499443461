import SAFormField from 'Components/Form/SAFormField'
import React, { FC } from 'react'
import { Form } from 'semantic-ui-react'

import { AffiliateFieldType, validateNumber } from './AdCampaignFormFields'

type Props = {
  index: number
  onChangeFields: (index: number, item: AffiliateFieldType) => void
  item: AffiliateFieldType
}

export const DisplayWeight: FC<Props> = ({ index, item, onChangeFields }) => (
  <SAFormField
    name={`displayWeight-${index}`}
    render={({ input, meta }) => (
      <Form.Input
        {...input}
        type="number"
        min={1}
        max={100}
        value={item.displayWeight}
        error={!!meta.touched && !!meta.error}
        onKeyPress={(event: { key: string; preventDefault: () => void }) => validateNumber(event)}
        onChange={(e, { value }) => {
          onChangeFields(index, { ...item, displayWeight: parseInt(value) })
          input.onChange(value)
        }}
      />
    )}
  />
)
