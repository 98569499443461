import CopyButton from 'Components/CopyButton'
import { SVEChannelStateTag } from 'Components/SVEChannelStateTag'
import TablePagination from 'Components/TablePagination'

import { EditSVEChannelButton, RemoveSVEChannelButton } from 'Forms/SVEChannel'
import { applicationUris } from 'Layout/uris'
import React, { useCallback } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { Icon, Table } from 'semantic-ui-react'
import {
  Organism,
  OrganismContentFragment,
  Permission,
  SveChannel,
  SveChannelState,
  useUnmountSveChannelMutation,
} from 'services/api/graphql'
import { useCurrentUser } from 'stores'

import ConfirmationButton from './Button/ConfirmationButton'

export type SVEChannelWithOrganism = Omit<SveChannel, 'broadcasts'> & {
  organism: OrganismContentFragment | undefined
}

export interface SVEChannelsTableProps {
  sveChannels: SVEChannelWithOrganism[]
  totalPages?: number
  activePage?: number
  onPageChange?: (page: number) => void
  withActions?: boolean
  refetchSVEchannels?: () => void
  organismId: Organism['id'] | null
}

export const SVEChannelsTable: React.FC<SVEChannelsTableProps> = ({
  sveChannels,
  totalPages,
  activePage,
  onPageChange,
  withActions,
  refetchSVEchannels,
}) => {
  const currentUser = useCurrentUser()
  const intl = useIntl()
  const [unmountChannel] = useUnmountSveChannelMutation()

  const handlePageChange = useCallback(
    (page: number) => {
      if (onPageChange) {
        onPageChange(page)
      }
    },
    [onPageChange],
  )

  const hasPermissionOnSveChannel = useCallback(
    (_sveChannel: Omit<SveChannel, 'broadcasts'>, permission: Permission) =>
      currentUser.can(permission, undefined, true),
    [currentUser],
  )

  const onUnmountChannel = useCallback(
    async (channelId: string) => {
      await unmountChannel({
        variables: {
          channelId,
        },
      })
      refetchSVEchannels && (await refetchSVEchannels())
    },
    [refetchSVEchannels, unmountChannel],
  )

  return (
    <>
      <Table style={{ flex: 1 }} sortable>
        <Table.Header>
          <Table.Row textAlign="center">
            <Table.HeaderCell width={2}>
              <FormattedMessage id="sveChannels.table.name" />
            </Table.HeaderCell>
            <Table.HeaderCell width={2}>
              <FormattedMessage id="sveChannels.table.id" />
            </Table.HeaderCell>
            <Table.HeaderCell width={2}>
              <FormattedMessage id="sveChannels.endpoints.inputUrl" />
            </Table.HeaderCell>
            <Table.HeaderCell width={1}>
              <FormattedMessage id="sveChannels.table.state" />
            </Table.HeaderCell>
            <Table.HeaderCell width={2}>
              <FormattedMessage id="sveChannels.table.organism" />
            </Table.HeaderCell>

            {withActions && (
              <Table.HeaderCell width={1}>
                <FormattedMessage id="sveChannels.actions" />
              </Table.HeaderCell>
            )}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {sveChannels.length > 0 ? (
            sveChannels.map(sveChannel => {
              const { id, name, organism, status } = sveChannel
              const sveChannelViewUrl = applicationUris.sveChannel('{{id}}').replace('{{id}}', id)
              return (
                <Table.Row key={id}>
                  <a href={sveChannelViewUrl}>
                    <Table.Cell textAlign="center">
                      <span>{name}</span>
                    </Table.Cell>
                  </a>
                  <Table.Cell textAlign="center">
                    <span>{id}</span>
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    {sveChannel?.endpoints?.rtmpInputUrl && (
                      <CopyButton
                        value={sveChannel.endpoints.rtmpInputUrl}
                        notificationSuccess={intl.formatMessage({
                          id: 'livestream.sve.ingestUrl_copied',
                        })}
                      >
                        <span>
                          {intl.formatMessage({
                            id: 'livestream.sve.ingestUrl_copie_placeholder',
                          })}
                        </span>
                        <Icon name="copy" />
                      </CopyButton>
                    )}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <SVEChannelStateTag state={status} />
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <span>{organism?.title}</span>
                  </Table.Cell>

                  {withActions && (
                    <Table.Cell textAlign="center">
                      <Link to={sveChannelViewUrl} className="mr-2">
                        <Icon name="sitemap" link size="large" inverted color="black" />
                      </Link>

                      {hasPermissionOnSveChannel(sveChannel, Permission.SveChannelUpdate) && (
                        <EditSVEChannelButton
                          sveChannelId={id}
                          render={onClick => (
                            <Icon name="edit" link size="large" inverted color="black" onClick={onClick} />
                          )}
                        />
                      )}

                      {sveChannel.status === SveChannelState.Ready &&
                        hasPermissionOnSveChannel(sveChannel, Permission.SveChannelDelete) && (
                          <ConfirmationButton
                            successText={intl.formatMessage(
                              {
                                id: 'sveChannels.unmoount_success',
                              },
                              {
                                name: sveChannel.name,
                              },
                            )}
                            confirmText={intl.formatMessage(
                              {
                                id: 'sveChannels.confirm_unmoount',
                              },
                              {
                                name: sveChannel.name,
                              },
                            )}
                            action={() => onUnmountChannel(sveChannel.id)}
                          >
                            <Icon name="eject" link size="large" inverted color="black" />
                          </ConfirmationButton>
                        )}

                      {!sveChannel.organism && hasPermissionOnSveChannel(sveChannel, Permission.SveChannelDelete) && (
                        <RemoveSVEChannelButton
                          sveChannelId={id}
                          confirmText={
                            <FormattedMessage id="sveChannels.confirm_delete" values={{ name: sveChannel.name }} />
                          }
                          successText={intl.formatMessage(
                            {
                              id: 'sveChannels.delete_success',
                            },
                            { name: sveChannel.name },
                          )}
                          onDone={refetchSVEchannels}
                        />
                      )}
                    </Table.Cell>
                  )}
                </Table.Row>
              )
            })
          ) : (
            <Table.Row>
              <Table.Cell width={3} textAlign="center">
                Aucune chaîne
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
      {!!totalPages && totalPages > 1 && (
        <TablePagination
          totalPages={totalPages}
          activePage={activePage || 1}
          onPageChange={page => handlePageChange(page)}
        />
      )}
    </>
  )
}
