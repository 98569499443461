import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableRubric,
  DraggableStateSnapshot,
  Droppable,
  DropResult,
} from '@hello-pangea/dnd'
import SAFormField from 'Components/Form/SAFormField'
import WidgetRow from 'Components/Widget/WidgetRow'
import { useGetEditedCategories } from 'models'
import React, { DragEventHandler } from 'react'
import { FieldArray, FieldArrayProps, FieldArrayRenderProps } from 'react-final-form-arrays'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button, Dropdown, Form, Icon, Loader, Ref, Table } from 'semantic-ui-react'
import { CustomPageWidget, CustomPageWidgetName, Organism, WidgetsType } from 'services/api/graphql'
import { useStore } from 'stores'
import styled from 'styled-components'
import { validateRequired, validateHttpsUrl, composeValidators } from 'tools/formValidators'

const DraggingRow = styled.div`
  opacity: 0.8;
  padding: 20px;
  box-sizing: border-box;
  background-color: #f5fff0;
`

type CustomPageFormFieldsWidgetsProps = {
  organism: Organism
  activeTranslation?: string
  values: any
}

const CustomPageFormFieldsWidgets = ({ organism, activeTranslation, values }: CustomPageFormFieldsWidgetsProps) => {
  const store = useStore()
  const intl = useIntl()

  const { data: editedCategories, loading } = useGetEditedCategories({
    variables: { filters: { organismId: store.organismId } },
    fetchPolicy: 'cache-and-network',
  })

  const onMoveItem = (result: DropResult, fields: FieldArrayProps<CustomPageWidget, HTMLTableRowElement>) => {
    const { source, destination } = result
    if (destination) {
      fields.move(source.index, destination.index)
    }
  }

  if (loading) {
    return <Loader />
  }

  return (
    <>
      <FieldArray name="widgets">
        {({ fields }: FieldArrayRenderProps<CustomPageWidget, HTMLElement>) => (
          <Table striped>
            <Table.Header>
              <Table.HeaderCell colSpan={7}>
                <SAFormField
                  name="widgetsType"
                  render={({ input, meta }) => (
                    <Dropdown
                      error={!!meta.touched && !!meta.error}
                      selection
                      floating
                      value={input.value as WidgetsType}
                      onChange={(_, { value }) => input.onChange(value)}
                      options={Object.values(WidgetsType).map(option => ({
                        key: option,
                        value: option,
                        text: intl.formatMessage({ id: `customPage.${option}` }),
                      }))}
                    />
                  )}
                />
              </Table.HeaderCell>
            </Table.Header>

            {values.widgetsType === WidgetsType.External && (
              <SAFormField
                name="externalWidgets.liveScoreAccount"
                validate={validateRequired}
                render={({ input, meta }) => (
                  <Form.Input
                    {...input}
                    className="p-3"
                    required
                    error={!!meta.touched && !!meta.error}
                    label={intl.formatMessage({
                      id: 'customPage.liveScoreAccount',
                    })}
                  />
                )}
              />
            )}

            {values.widgetsType === WidgetsType.ExternalIframe && (
              <SAFormField
                name="externalWidgets.iframeUrl"
                validate={composeValidators<string>([validateRequired, validateHttpsUrl])}
                render={({ input, meta }) => (
                  <Form.Input
                    {...input}
                    className="p-3"
                    required
                    placeholder="https://www.sport.com"
                    error={!!meta.touched && !!meta.error}
                    label={intl.formatMessage({
                      id: 'customPage.iframeUrl',
                    })}
                  />
                )}
              />
            )}

            {values.widgetsType === WidgetsType.ExternalLink && (
              <SAFormField
                name="externalWidgets.linkUrl"
                validate={composeValidators<string>([validateRequired, validateHttpsUrl])}
                render={({ input, meta }) => (
                  <Form.Input
                    {...input}
                    className="p-3"
                    required
                    placeholder="https://www.sport.com"
                    error={!!meta.touched && !!meta.error}
                    label={intl.formatMessage({
                      id: 'customPage.linkUrl',
                    })}
                  />
                )}
              />
            )}

            {values.widgetsType === WidgetsType.Internal && (
              <>
                <Table.Header>
                  <Table.Row textAlign="center">
                    <Table.HeaderCell></Table.HeaderCell>
                    <Table.HeaderCell>
                      <FormattedMessage id="customPage.widgets" />
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <FormattedMessage id="customPage.widget.cardsDisplay" />
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <FormattedMessage id="customPage.widget.contentDisplay" />
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <FormattedMessage id="customPage.widget.display" />
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <FormattedMessage id="customPage.widget.name" />
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <FormattedMessage id="customPage.actions" />
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <DragDropContext onDragEnd={result => onMoveItem(result, fields)}>
                  <Droppable
                    droppableId="items"
                    renderClone={(
                      provided: DraggableProvided,
                      _snapshot: DraggableStateSnapshot,
                      rubric: DraggableRubric,
                    ) => {
                      const item = fields.value[rubric.source.index].name
                      return (
                        <DraggingRow
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          onDragStart={provided.dragHandleProps?.onDragStart as DragEventHandler<HTMLDivElement>}
                          ref={provided.innerRef}
                        >
                          {item ? <FormattedMessage id={`customPage.widgets.${item}`} /> : ''}
                        </DraggingRow>
                      )
                    }}
                  >
                    {provided => (
                      <Ref innerRef={provided.innerRef}>
                        <Table.Body>
                          {fields.map((name: string, index) => {
                            const widget = fields.value[index]
                            const excludeNames = fields.value
                              .map(w => w.name)
                              .filter(
                                widgetName =>
                                  ![
                                    widget.name,
                                    CustomPageWidgetName.EditedCategories,
                                    CustomPageWidgetName.AllContent,
                                    CustomPageWidgetName.ExternalContent,
                                  ].includes(widgetName),
                              )

                            if (!organism.availableExternalSources?.length)
                              excludeNames.push(CustomPageWidgetName.ExternalContent)

                            return (
                              <Draggable key={name} draggableId={name} index={index}>
                                {providedRow => {
                                  return (
                                    <Ref innerRef={providedRow.innerRef}>
                                      <WidgetRow
                                        index={index}
                                        excludeNames={excludeNames}
                                        onRemove={() => {
                                          fields.remove(index)
                                        }}
                                        value={widget}
                                        organism={organism}
                                        onChange={value => fields.update(index, value)}
                                        provided={providedRow}
                                        editedCategoryList={editedCategories}
                                        activeTranslation={activeTranslation}
                                      />
                                    </Ref>
                                  )
                                }}
                              </Draggable>
                            )
                          })}
                        </Table.Body>
                      </Ref>
                    )}
                  </Droppable>
                </DragDropContext>
              </>
            )}

            {values.widgetsType === WidgetsType.Internal && (
              <Table.Footer>
                <Table.Row>
                  <Table.Cell colSpan={3}>
                    <Button
                      icon
                      onClick={() =>
                        fields.push({
                          name: CustomPageWidgetName.Live,
                          position: fields.length || 0,
                        })
                      }
                    >
                      <Icon name="add" />
                    </Button>
                  </Table.Cell>
                </Table.Row>
              </Table.Footer>
            )}
          </Table>
        )}
      </FieldArray>
    </>
  )
}

export default CustomPageFormFieldsWidgets
