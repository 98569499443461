import { isAfter, isBefore, isEqual, addMinutes } from 'date-fns'
import datefnsFormat from 'date-fns/format'

export const locales: { [key: string]: object } = {
  en: require('date-fns/locale/en'),
  fr: require('date-fns/locale/fr'),
}

export const currentLocale = 'fr'

export const dateFormats: Record<string, string> = {
  fr: 'DD MMMM YYYY',
  en: 'MMMM DD, YYYY',
}

export const format = (date: Date, dateFormat: string, locale = currentLocale) =>
  datefnsFormat(date, dateFormat, { locale: locales[locale] })

export function areDaysIdentical(a: Date, b: Date) {
  return a.getDate() === b.getDate() && a.getMonth() === b.getMonth() && a.getFullYear() === b.getFullYear()
}

export const formatSimpleDateTime = (date: Date) => format(date, 'DD/MM/YYYY HH:mm')

export const isDateInRange = ({
  date,
  minDate,
  maxDate,
}: {
  date: Date
  minDate: Date | undefined
  maxDate: Date | undefined
}) => {
  if (minDate) {
    if (isEqual(date, minDate)) return true
    if (isBefore(date, minDate)) return false
  }
  if (maxDate) {
    if (isEqual(date, maxDate)) return true
    if (isAfter(date, maxDate)) return false
  }
  return true
}

export const getTime = (date: Date) => datefnsFormat(addMinutes(date, date.getTimezoneOffset()), 'HH:mm')
