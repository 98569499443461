import { ApolloError } from '@apollo/client'
import React from 'react'
import { toast, ToastContent, ToastOptions } from 'react-toastify'

import { getErrorMessage } from './graphql'

const props: ToastOptions = {
  containerId: 'messages',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
}

export const notifyInfo = (message: string) => toast.info(message, props)
export const notifySuccess = (message: string) => toast.success(message, props)

export const notifyError = (content: ToastContent | Error | ApolloError, error?: Error | ApolloError) => {
  const message = (
    <div>
      <p>{content instanceof Error ? getErrorMessage(content) : content}</p>

      {error && (
        <p>
          <em>{getErrorMessage(error)}</em>
        </p>
      )}
    </div>
  )
  toast.error(message, {
    containerId: 'error',
    autoClose: 10000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  })
}
