import ConfirmationButton, { ConfirmationButtonBaseProps } from 'Containers/Button/ConfirmationButton'
import { useDeleteOrganism } from 'models'
import React from 'react'
import { Organism } from 'services/api/graphql'

export interface RemoveOrganismButtonProps extends ConfirmationButtonBaseProps {
  organismId: Organism['id']
}

const RemoveOrganismButton = ({ organismId, ...props }: RemoveOrganismButtonProps) => {
  // const { removeSport, loading } = useRemoveSport(sportId)
  const [removeOrganism] = useDeleteOrganism()
  return <ConfirmationButton action={() => removeOrganism({ variables: { organismId } })} {...props} />
}

export default RemoveOrganismButton
