/**
 * Error containing a message id that can be translated with i18n
 */
export default class I18nError extends Error {
  public static NAME = 'I18nError'

  constructor(message = '', public messageId: string = '') {
    super(message)
    this.name = I18nError.NAME
  }
}
