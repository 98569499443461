import React, { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { Dropdown } from 'semantic-ui-react'
import { LiveStreamState } from 'services/api/graphql'

const liveStreamStateTitleId = (state: LiveStreamState): string => {
  switch (state) {
    case LiveStreamState.NotStarted:
      return 'liveStreamState.notStarted'
    case LiveStreamState.Testing:
      return 'liveStreamState.testing'
    case LiveStreamState.Started:
      return 'liveStreamState.started'
    case LiveStreamState.Finished:
      return 'liveStreamState.finished'
  }
}
interface SelectLiveStreamProps {
  value: LiveStreamState
  onChange: (state: LiveStreamState) => void
  disabled?: boolean
}
const POINT_OF_NO_RETURN = LiveStreamState.Started

const SelectLiveStream: React.FC<SelectLiveStreamProps> = ({ value, onChange, disabled }) => {
  const intl = useIntl()

  const statesOrder = useMemo(
    () => [LiveStreamState.NotStarted, LiveStreamState.Testing, LiveStreamState.Started, LiveStreamState.Finished],
    [],
  )

  const [selectableStates, setSelectableStates] = useState<LiveStreamState[]>([])
  useEffect(() => {
    const stateIndex = statesOrder.indexOf(value)
    if (stateIndex >= statesOrder.indexOf(POINT_OF_NO_RETURN)) {
      setSelectableStates(statesOrder.slice(stateIndex + 1))
    } else if (stateIndex === -1) {
      setSelectableStates([])
    } else {
      setSelectableStates(statesOrder.filter(state => state !== value))
    }
  }, [statesOrder, value])

  return (
    <Dropdown
      text={intl.formatMessage({ id: liveStreamStateTitleId(value) })}
      disabled={disabled}
      icon={selectableStates.length > 0 ? 'dropdown' : ''}
    >
      <Dropdown.Menu>
        {selectableStates.map(state => (
          <Dropdown.Item
            key={state}
            text={intl.formatMessage({ id: liveStreamStateTitleId(state) })}
            value={state}
            onClick={() => onChange(state)}
          />
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default SelectLiveStream
