import { applicationUris } from 'Layout/uris'
import classnames from 'classnames'
import { useGetSportItemByChatRoom } from 'models'
import { ChatRoom } from 'models/ChatRoom'
import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { Accordion, Loader, Table } from 'semantic-ui-react'

type Props = {
  chatRoomId: ChatRoom['id']
}

const SeeMore: FC<Props> = ({ chatRoomId }) => {
  const intl = useIntl()
  const { sportItem, loading } = useGetSportItemByChatRoom(chatRoomId, {
    fetchPolicy: 'cache-and-network',
  })

  return (
    <Table.Row>
      <Table.Cell colspan={4}>
        <Accordion>
          <Accordion.Title className="hidden" active />
          <Accordion.Content active>
            {loading && <Loader active inline="centered" />}
            {!loading && (
              <div className="w-100 flex">
                <div className="flex-1">
                  {intl.formatMessage({ id: 'sportItem.sportItem' })}:
                  <Link
                    className={classnames('font-bold ml-2', { 'cursor-not-allowed': !sportItem?.sportItemSet?.id })}
                    to={
                      sportItem?.sportItemSet?.id ? applicationUris.sportEventItems(sportItem?.sportItemSet?.id) : '#'
                    }
                  >
                    {sportItem?.title}
                  </Link>
                </div>
                <span className="flex-1">
                  {intl.formatMessage({ id: 'sportItemSet.description' })}:
                  <span className="font-bold ml-2">{sportItem?.description}</span>
                </span>
              </div>
            )}
          </Accordion.Content>
        </Accordion>
      </Table.Cell>
    </Table.Row>
  )
}

export default SeeMore
