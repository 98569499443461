import DonwloadButton from 'Components/Button/DownloadButton'
import SelectOrganismsInput from 'Containers/Form/SelectOrganismsInput'
import { useImportUsersFansFromCsv } from 'models'
import React, { useCallback } from 'react'
import { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Divider, Form, Message } from 'semantic-ui-react'
import { ImportUsersOptions, User } from 'services/api/graphql'
import { validateRequired } from 'tools/formValidators'

import GenericForm from './GenericForm'
import InlineFileInput from './InlineFileInput'
import SAFormField from './SAFormField'

export interface ImportUsersFormValues extends ImportUsersOptions {
  file: File
}

export interface ImportUsersFormProps {
  open: boolean
  onSuccess: (users: User[]) => void
  onCancel: () => void
}

const ImportUsersForm: React.FC<ImportUsersFormProps> = ({ open, onSuccess, onCancel }) => {
  const intl = useIntl()

  const [importUsersFans, { loading, error }] = useImportUsersFansFromCsv()

  const [license, setLicense] = useState(false)

  const submit = useCallback(
    async ({ file, organismIds, licensedForOrganismId, revokeLicense }: ImportUsersFormValues) => {
      try {
        const res = await importUsersFans({
          file,
          options: {
            organismIds: !license ? organismIds : undefined,
            licensedForOrganismId: license ? licensedForOrganismId : undefined,
            revokeLicense: license ? revokeLicense : undefined,
          },
        })

        if (res) {
          onSuccess(res.importUsersFansFromCsv)
        }
      } catch (e) {}
    },
    [importUsersFans, license, onSuccess],
  )

  const cancel = useCallback(() => {
    setLicense(false)
    onCancel()
  }, [onCancel])

  return (
    <GenericForm<ImportUsersFormValues>
      open={open}
      onSubmit={submit}
      onCancel={cancel}
      loading={loading}
      header={intl.formatMessage({ id: 'users.import_fans' })}
    >
      <div>
        <DonwloadButton source="/files/import_users_template.csv" name="import_users_template.csv">
          <FormattedMessage id="import_users.template" />
        </DonwloadButton>
      </div>

      <Divider />

      {error && error.graphQLErrors.length > 0 && <Message negative>{error.graphQLErrors[0].message}</Message>}

      <Divider className="opacity-0" />

      <SAFormField<File, HTMLInputElement>
        name="file"
        validate={validateRequired}
        render={({ input, meta }) => (
          <Form.Field error={!!meta.touched && !!meta.error} required>
            <label htmlFor={input.name}>
              <FormattedMessage id="import_users.file" />
            </label>
            <InlineFileInput {...input} accept="text/csv" />
          </Form.Field>
        )}
      />

      <Divider className="opacity-0" />

      <div className="flex space-x-4">
        <Form.Checkbox
          className="h-6"
          checked={license}
          label={intl.formatMessage({ id: 'import_users.license' })}
          onChange={(_, { checked }) => setLicense(!!checked)}
        />

        {license && (
          <SAFormField<boolean, HTMLInputElement>
            name="revokeLicense"
            render={({ input, meta }) => (
              <Form.Checkbox
                error={!!meta.touched && !!meta.error}
                checked={input.value}
                label={intl.formatMessage({ id: 'import_users.revokeLicense' })}
                toggle
                onChange={(_, { checked }) => input.onChange(!!checked)}
              />
            )}
          />
        )}
      </div>

      {!license && (
        <SAFormField
          name="organismIds"
          render={({ input, meta }) => (
            <Form.Field
              error={!!meta.touched && !!meta.error}
              label={intl.formatMessage({
                id: 'import_users.organismIds',
              })}
              control={SelectOrganismsInput}
              multiple
              upward={true}
              {...input}
            ></Form.Field>
          )}
        />
      )}

      {license && (
        <SAFormField
          name="licensedForOrganismId"
          validate={validateRequired}
          render={({ input, meta }) => (
            <Form.Field
              error={!!meta.touched && !!meta.error}
              required
              label={intl.formatMessage({
                id: 'import_users.organismId',
              })}
              control={SelectOrganismsInput}
              upward={true}
              {...input}
              filters={{ license: true }}
            ></Form.Field>
          )}
        />
      )}
    </GenericForm>
  )
}

export default ImportUsersForm
