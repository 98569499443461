export const getImageFilename = (image: File | Blob) => getSafeFilename((image instanceof File ? image : File).name)

// Remove accents and other diacritics from file name
export const getSafeFilename = (filename: string) => filename.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

export const getFile = (image: File | Blob, filename?: string) =>
  new File([image], filename ?? getImageFilename(image), {
    lastModified: image instanceof File ? image.lastModified : Date.now(),
    type: image.type,
  })
