import { IntlShape } from 'react-intl'
import {
  SportItemContentType,
  VideoContentType,
  OrganismType,
  PublicationStatus,
  ExternalFeedType,
} from 'services/api/graphql'

export const options = (intl: IntlShape) => ({
  videoContentTypes: [
    {
      key: VideoContentType.Live,
      value: VideoContentType.Live,
      text: intl.formatMessage({ id: 'videoContentType.live' }),
    },
    {
      key: VideoContentType.Vod,
      value: VideoContentType.Vod,
      text: intl.formatMessage({ id: 'videoContentType.vod' }),
    },
  ],
  sportItemContentTypes: [
    {
      key: SportItemContentType.Match,
      value: SportItemContentType.Match,
      text: intl.formatMessage({ id: 'sportItemContentType.match' }),
    },
    {
      key: SportItemContentType.Interview,
      value: SportItemContentType.Interview,
      text: intl.formatMessage({ id: 'sportItemContentType.interview' }),
    },
    {
      key: SportItemContentType.Post,
      value: SportItemContentType.Post,
      text: intl.formatMessage({ id: 'sportItemContentType.post' }),
    },
  ],
  organismTypes: [
    {
      key: OrganismType.Federation,
      value: OrganismType.Federation,
      text: intl.formatMessage({ id: `organism.${OrganismType.Federation}` }),
    },
    {
      key: OrganismType.Club,
      value: OrganismType.Club,
      text: intl.formatMessage({ id: `organism.${OrganismType.Club}` }),
    },
    {
      key: OrganismType.Sponsor,
      value: OrganismType.Sponsor,
      text: intl.formatMessage({ id: `organism.${OrganismType.Sponsor}` }),
    },
    {
      key: OrganismType.Athlete,
      value: OrganismType.Athlete,
      text: intl.formatMessage({ id: `organism.${OrganismType.Athlete}` }),
    },
  ],
  publicationStatusTypes: [
    {
      key: PublicationStatus.Draft,
      value: PublicationStatus.Draft,
      text: intl.formatMessage({ id: 'publicationStatus.draft' }),
    },
    {
      key: PublicationStatus.Published,
      value: PublicationStatus.Published,
      text: intl.formatMessage({ id: 'publicationStatus.published' }),
    },
  ],
  externalFeedTypes: [
    {
      key: ExternalFeedType.Youtube,
      value: ExternalFeedType.Youtube,
      text: intl.formatMessage({ id: 'sportItem.externalFeed.provider.youtube' }),
    },
    {
      key: ExternalFeedType.CustomUrl,
      value: ExternalFeedType.CustomUrl,
      text: intl.formatMessage({ id: 'sportItem.externalFeed.provider.customUrl' }),
    },
  ],
})
