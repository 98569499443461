import CreateUserForm from 'Forms/User/CreateUserForm'
import React, { Fragment, useState } from 'react'
import { notifyError, notifySuccess } from 'tools/toaster'

interface AddUserButtonProps {
  onDone: () => void
  render: (onClick: () => void) => React.ReactNode
}
const AddUserButton = ({ onDone, render }: AddUserButtonProps) => {
  const [form, setForm] = useState<React.ReactNode>()
  const handleClick = () => {
    setForm(
      <CreateUserForm
        onCancel={() => setForm(null)}
        onDone={() => {
          setForm(null)
          notifySuccess(`Nouvel utilisateur créé`)
          onDone()
        }}
        onError={notifyError}
      />,
    )
  }
  return (
    <Fragment>
      {render(handleClick)}
      {form}
    </Fragment>
  )
}

export default AddUserButton
