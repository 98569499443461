import React, { useCallback, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import ReactPlayer from 'react-player'
import { Modal } from 'semantic-ui-react'
import { LiveStreamContentFragment, useGetLivestreamPlaybackUrlQuery, WmEventState } from 'services/api/graphql'
import styled from 'styled-components'

import Loader from './Loader'
import Timer from './Timer'

interface LiveStreamPlayerModalProps {
  liveStream: LiveStreamContentFragment
  open: boolean
  onClose: () => void
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > * + * {
    margin-top: 1em;
  }
`

const Bottom = styled.div`
  width: 100%;
  text-align: right;
`

const TestAnnounce = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 2rem;
  padding: 4rem 0;
`

const LiveStreamPlayerModal: React.FC<LiveStreamPlayerModalProps> = ({ liveStream, open, onClose }) => {
  const { event } = liveStream
  const state = event?.state
  const editoStartDate = event?.editoStartDate
  const editoStopDate = event?.editoStopDate
  const testStartDate = event?.testStartDate
  const [calculatedState, setCalculatedState] = useState(state)
  const { data, loading: loadingPlaybackUrl } = useGetLivestreamPlaybackUrlQuery({
    variables: {
      liveStreamId: liveStream.id,
    },
    fetchPolicy: 'cache-and-network',
    skip: !open,
  })

  const onEndLiveTimer = useCallback(() => {
    if (calculatedState === WmEventState.Testing) {
      setCalculatedState(WmEventState.Started)
    } else {
      onClose()
    }
  }, [calculatedState, onClose])

  return (
    <Modal mountNode={document.getElementById('modals')} open={open} onClose={onClose} closeIcon size="large">
      <Modal.Content>
        <Container>
          {calculatedState === WmEventState.Pending && (
            <TestAnnounce>
              <FormattedMessage id="livestream.test" />
              {testStartDate && (
                <Timer end={new Date(testStartDate)} onEnd={() => setCalculatedState(WmEventState.Testing)} />
              )}
            </TestAnnounce>
          )}
          {calculatedState &&
            editoStartDate &&
            editoStopDate &&
            [WmEventState.Testing, WmEventState.Started].includes(calculatedState) && (
              <Loader loading={loadingPlaybackUrl}>
                {!loadingPlaybackUrl && (
                  <ReactPlayer
                    url={data?.liveStream?.playbackUrl}
                    playing={open}
                    height="100%"
                    width="100%"
                    controls
                  ></ReactPlayer>
                )}
                <Bottom>
                  <FormattedMessage id={`livestream.${calculatedState === WmEventState.Testing ? 'start' : 'stop'}`} />
                  &nbsp;
                  <Timer
                    end={new Date(calculatedState === WmEventState.Testing ? editoStartDate : editoStopDate)}
                    onEnd={onEndLiveTimer}
                  />
                </Bottom>
              </Loader>
            )}
        </Container>
      </Modal.Content>
    </Modal>
  )
}

export default LiveStreamPlayerModal
