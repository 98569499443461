import { gql } from '@apollo/client'

import { APPLICATION_FRAGMENT } from './Application'
import { CONTENT_TRANSLATION_FRAGMENT } from './ContentTranslation'
import { CUSTOM_PAGE_WIDGET_FRAGMENT } from './CustomPage'
import { MEDIA_FRAGMENT } from './Media'
import { PURCHASABLE_OFFER_FRAGMENT } from './PurchasableOffer'
import { SVECHANNEL_FRAGMENT } from './SportallVideoEngine/fragments'
import { WM_STREAM_FRAGMENT } from './Wildmoka'

export const ORGANISM_FRAGMENT = gql`
  fragment organismContent on Organism {
    id
    type
    whiteLabel
    title
    description
    publicationStatus
    titleT {
      ...contentTranslationContent
    }
    descriptionT {
      ...contentTranslationContent
    }
    defaultLocale
    onFront
    frontPosition
    logo {
      coloredImage {
        ...mediaContent
      }
      transparentDarkImage {
        ...mediaContent
      }
      transparentLightImage {
        ...mediaContent
      }
    }
    officialWebSiteUrl
    sportIds
    forbiddenKeyWords
    enableExternalSources
    availableExternalSources
    sports {
      id
    }
    bannerImage {
      ...mediaContent
    }
    adminBadge {
      ...mediaContent
    }
    bannerVideo {
      id
      videoClip {
        id
        playbackUrl
      }
    }
    whiteLabel
    pageWidgets {
      ...customPageWidget
    }
    offers {
      ...purchasableOfferContent
    }
    license {
      enabled
      api
    }
    # Wildmoka Video Engine
    streamsIds
    wmProfileId
    # Sportall Video Engine
    sveChannelIds
    organismPageApplicationDesignId
    contentPageApplicationDesignId
    adminsAutomatedEmails
    tags {
      id
      title
      subTags
    }
    visibleInMultisportApplication
  }
  ${CONTENT_TRANSLATION_FRAGMENT}
  ${MEDIA_FRAGMENT}
  ${PURCHASABLE_OFFER_FRAGMENT}
  ${CUSTOM_PAGE_WIDGET_FRAGMENT}
`

/**
 * Organisms
 */
export const GET_ORGANISMS_QUERY = gql`
  query organisms($filters: OrganismFilters) {
    organisms(filters: $filters) {
      organisms {
        ...organismContent
      }
      totalCount
    }
  }
  ${ORGANISM_FRAGMENT}
`

export const CREATE_ORGANISM_MUTATION = gql`
  mutation createOrganism($input: CreateOrganismInput!) {
    createOrganism(input: $input) {
      ...organismContent
    }
  }
  ${ORGANISM_FRAGMENT}
`

export const UPDATE_ORGANISM_MUTATION = gql`
  mutation updateOrganism($input: UpdateOrganismInput!) {
    updateOrganism(input: $input) {
      ...organismContent
    }
  }
  ${ORGANISM_FRAGMENT}
`

export const GET_ORGANISM_QUERY = gql`
  query organism($organismId: ID!) {
    organism(id: $organismId) {
      ...organismContent
    }
  }
  ${ORGANISM_FRAGMENT}
`

export const LIGHT_ORGANISM_FRAGMENT = gql`
  fragment lightOrganismContent on Organism {
    id
    type
    whiteLabel
    title
    description
    officialWebSiteUrl
    whiteLabel
    fansCount
    license {
      enabled
      api
    }
  }
`

export const GET_LIGHT_ORGANISM_QUERY = gql`
  query lightOrganism($organismId: ID!) {
    organism(id: $organismId) {
      ...lightOrganismContent
    }
  }
  ${LIGHT_ORGANISM_FRAGMENT}
`

export const GET_ORGANISM_WITH_SUBSCRIPTIONS_QUERY = gql`
  query organismWithSubscriptions($organismId: ID!) {
    organism(id: $organismId) {
      ...organismContent
      offers {
        ...purchasableOfferContent
        nbValidSubscriptions
      }
    }
  }
  ${ORGANISM_FRAGMENT}
  ${PURCHASABLE_OFFER_FRAGMENT}
`

export const GET_ORGANISM_WITH_STREAMS_SVECHANNELS_QUERY = gql`
  query organismWithStreamsAndSVEChannels($id: ID!) {
    organism(id: $id) {
      ...organismContent
      streams {
        ...wmStreamContent
      }
      sveChannels {
        ...sveChannelContent
      }
    }
  }
  ${ORGANISM_FRAGMENT}
  ${WM_STREAM_FRAGMENT}
  ${SVECHANNEL_FRAGMENT}
`
export const GET_ORGANISM_WITH_STREAMS_SVECHANNELS_AVAILABLE_SVECHANNELS_QUERY = gql`
  query organismWithStreamsAndSVEChannelsAndAvailableSVEChannelsAndWMStreams(
    $id: ID!
    $input: OrganismAvailableSVEChannelsInput
  ) {
    organism(id: $id) {
      ...organismContent
      streams {
        ...wmStreamContent
      }
      sveChannels {
        ...sveChannelContent
      }
      availableSVEChannels(input: $input) {
        ...sveChannelContent
      }
      availableWMStreams {
        ...wmStreamContent
      }
    }
  }
  ${ORGANISM_FRAGMENT}
  ${WM_STREAM_FRAGMENT}
  ${SVECHANNEL_FRAGMENT}
`

export const GET_ORGANISM_APPLICATIONS_QUERY = gql`
  query organismsApplications($id: ID!) {
    organism(id: $id) {
      id
      whiteLabel
      applications {
        ...applicationContent
      }
    }
  }
  ${APPLICATION_FRAGMENT}
`

export const GET_ORGANISM_OFFERS_QUERY = gql`
  query organismsOffers($id: ID!) {
    organism(id: $id) {
      id
      offers {
        id
        title
        publicationStatus
      }
    }
  }
`

export const DELETE_ORGANISM_MUTATION = gql`
  mutation deleteOrganism($organismId: ID!) {
    deleteOrganism(id: $organismId) {
      id
    }
  }
`

export const MOVE_FRONT_PAGE_MUTATION = gql`
  mutation moveFrontPage($input: MoveFrontPageInput!) {
    moveFrontPage(input: $input) {
      id
      frontPosition
    }
  }
`

export const REVOKE_ALL_LICENSES_MUTATION = gql`
  mutation revokeAllLicenses($organismId: ID!) {
    revokeAllLicenses(organismId: $organismId)
  }
`
