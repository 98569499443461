import SwitchInput from 'Components/Form/SwitchInput'
import React, { useMemo } from 'react'

import { useIntl } from 'react-intl'
import { SportItemSetType } from 'services/api/graphql'

export interface SelectSportItemSetTypeInputProps {
  value: SportItemSetType
  onChange: (value: SportItemSetType) => void
}

const SelectSportItemSetTypeInput = ({ value, onChange }: SelectSportItemSetTypeInputProps) => {
  const intl = useIntl()
  const options = useMemo(
    () => [
      {
        key: SportItemSetType.SportEvent,
        value: SportItemSetType.SportEvent,
        text: intl.formatMessage({
          id: `sportItemSetType.${SportItemSetType.SportEvent}`,
        }),
      },
      {
        key: SportItemSetType.Playlist,
        value: SportItemSetType.Playlist,
        text: intl.formatMessage({
          id: `sportItemSetType.${SportItemSetType.Playlist}`,
        }),
      },
    ],
    [intl],
  )
  return <SwitchInput value={value} onChange={onChange} options={options} />
}

export default SelectSportItemSetTypeInput
