import { QueryHookOptions, useMutation, useQuery } from '@apollo/client'
import {
  CREATE_NEWS_MUTATION,
  CREATE_TWITTER_WIDGET_MUTATION,
  CreateNewsInput,
  DELETE_NEWS_MUTATION,
  GET_NEWSS_QUERY,
  GET_NEWS_QUERY,
  News,
  QueryNewssArgs,
  UPDATE_NEWS_MUTATION,
  UpdateNewsInput,
} from 'services/api/graphql'

export type GetNewssResult = {
  newss: {
    news: News[]
    totalCount: number
  }
}

export const useGetNewss = (options?: QueryHookOptions<GetNewssResult, QueryNewssArgs>) => {
  const { data, ...rest } = useQuery(GET_NEWSS_QUERY, options)
  const totalCount = data?.newss.totalCount
  return { news: data && data.newss && data.newss.news, totalCount, ...rest }
}

export type GetNewsResult = {
  news: News | undefined
}
export const useGetNews = (newsId: News['id'], options?: QueryHookOptions<GetNewsResult>) => {
  const { data, ...rest } = useQuery<GetNewsResult>(GET_NEWS_QUERY, {
    variables: { newsId },
    ...options,
  })
  return { news: data && data.news, ...rest }
}

export const useCreateNews = () => useMutation<{ createNews: News }, { input: CreateNewsInput }>(CREATE_NEWS_MUTATION)
export const useUpdateNews = () => useMutation<{ updateNews: News }, { input: UpdateNewsInput }>(UPDATE_NEWS_MUTATION)
export const useDeleteNews = () => useMutation<{ deleteNews: News }, { newsId: News['id'] }>(DELETE_NEWS_MUTATION)

export const useCreateTwitterWidget = () =>
  useMutation<{ createTwitterWidget: string }, { tweetUrl: string }>(CREATE_TWITTER_WIDGET_MUTATION)
