import UserAvatar from 'Components/UserAvatar'
import CurrentOrganismPicker from 'Containers/CurrentOrganismPicker'
import { observer } from 'mobx-react'
import { useUserRoles } from 'models'
import React, { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Image, Menu } from 'semantic-ui-react'
import { useCurrentUser, useStore } from 'stores'
import styled from 'styled-components'

const Bar = styled.div`
  display: flex;
  align-items: center;
  background-color: black;
`

const MenuBar = styled(Menu)`
  flex: 1;
`

const TopBar = observer(() => {
  const store = useStore()
  const intl = useIntl()

  const { userRoles } = useUserRoles()

  const loggedUser = store.currentUser.loggedUser

  const currentUserRolesTitle = useMemo(() => {
    const roleIds = store.currentUser.user && store.currentUser.user.userRoleIds
    if (!roleIds) return

    const finalUserRoleTitles = userRoles
      ? roleIds.map(roleId => {
          return intl.formatMessage({ id: `userRole.${roleId}` })
        })
      : []

    return finalUserRoleTitles.join(', ')
  }, [store.currentUser.user, userRoles, intl])

  const { isAdmin } = useCurrentUser()

  return (
    <Bar>
      <MenuBar inverted size="massive">
        <Menu.Item>
          <Image size="small">
            <img src="/assets/sportall.png" />
          </Image>
        </Menu.Item>

        {loggedUser && (
          <Menu.Item>
            <div className="mr-1.5">
              <UserAvatar />
            </div>

            {`${loggedUser.firstName} ${loggedUser.lastName}`}
          </Menu.Item>
        )}

        <Menu.Item>
          <FormattedMessage id="organism" />: <CurrentOrganismPicker inverted noSelectionEnabled={isAdmin} />
        </Menu.Item>

        <Menu.Item>{currentUserRolesTitle}</Menu.Item>

        <div className="flex w-full justify-items-end justify-end">
          <button onClick={() => store.logout()} className="flex m-4 px-4 rounded align-self-end bg-white items-center">
            <FormattedMessage id="common.logout" />
          </button>
        </div>
      </MenuBar>
    </Bar>
  )
})

export default TopBar
