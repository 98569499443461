import { useCreateTwitterWidget } from 'models'
import React, { FC, useCallback, useState, ChangeEventHandler } from 'react'
import { FormattedMessage } from 'react-intl'
import { Button, Input, Modal, Popup } from 'semantic-ui-react'

import { useEditorCommand } from '../useEditor'

const InsertTweetButton: FC = () => {
  const [createTwitterWidget, { loading }] = useCreateTwitterWidget()
  const [, dispatch] = useEditorCommand()

  const [open, setOpen] = useState(false)
  const [tweetUrl, setTweetUrl] = useState('')

  const onClick = useCallback(() => {
    setTweetUrl('')
    setOpen(true)
  }, [])

  const onCancel = useCallback(() => {
    setTweetUrl('')
    setOpen(false)
  }, [])

  const onChange = useCallback<ChangeEventHandler<HTMLInputElement>>(e => {
    setTweetUrl(e.target.value)
  }, [])

  const onConfirm = useCallback(async () => {
    if (!tweetUrl) return

    try {
      const { data } = await createTwitterWidget({ variables: { tweetUrl } })
      const split = tweetUrl.split('/').slice(-1)[0]
      const id = split.substr(0, (split + '?').indexOf('?'))
      const widget = data?.createTwitterWidget

      if (id && widget) {
        setTweetUrl('')
        setOpen(false)
        dispatch('tweet', { id, widget })
      }
    } catch (e) {
      // graphql will handle notifyError
    }
  }, [createTwitterWidget, dispatch, tweetUrl])

  return (
    <>
      <Popup
        content={<FormattedMessage id="news.twitter" />}
        trigger={<Button type="button" icon="twitter" onClick={onClick} />}
      />

      <Modal
        mountNode={document.getElementById('modals')}
        open={open}
        size="small"
        closeIcon
        closeOnDimmerClick={false}
        onClose={onCancel}
      >
        <Modal.Header as="h1" content={<FormattedMessage id="editor.addTweet" />} />
        <Modal.Content>
          <Input
            type="text"
            className="w-full"
            placeholder="https://twitter.com/xxxxxxxx/status/xxxxxxxxxxxxxxx"
            onChange={onChange}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={onCancel}>
            Annuler
          </Button>
          <Button positive loading={loading} onClick={onConfirm}>
            Valider
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  )
}

export default InsertTweetButton
