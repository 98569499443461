import { FieldState, FieldValidator } from 'final-form'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import validator from 'validator'

export const RequiredValue = 'Required'
export const isRequired = (value: any | undefined): boolean => {
  return Array.isArray(value) ? value.length > 0 : !!value
}

export const validateRequired = (value: any | undefined) => (isRequired(value?.toString()) ? undefined : RequiredValue)
export const validateColor = (value: string | undefined) =>
  !value || validator.isHexColor(value) ? undefined : RequiredValue

export const validateUrl = (value: string | undefined) => {
  const reg = new RegExp(/^(.+):\/\/(.+)/)
  if (value && !reg.test(value)) {
    return <FormattedMessage id="common.error.urlFormat" />
  }
  return undefined
}

export const validateRequiredEditor = (value: string | undefined) => {
  if (value) {
    const { json, text } = JSON.parse(value)
    const isValid = json?.root.children.length > 0 && text?.trim().length > 0
    if (!isValid) {
      return RequiredValue
    }
  }
  return undefined
}

export const validateHttpsUrl = (value: string | undefined) => {
  const reg = new RegExp(
    /https:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
  )
  if (value && !reg.test(value)) {
    return <FormattedMessage id="common.error.httpsUrlFormat" />
  }
  return undefined
}

export const validateEmail = (value: string | undefined) =>
  !value || validator.isEmail(value) ? undefined : RequiredValue
export const validatePassword = (value: string | undefined) => (!value || value.length > 4 ? undefined : RequiredValue)

export const composeValidators =
  <FieldValue,>(validators: Array<FieldValidator<FieldValue>>): FieldValidator<FieldValue> =>
  (value: any, allValues?: object, meta?: FieldState<FieldValue> | undefined) =>
    validators.reduce(
      (error: Error | undefined, fieldValidator: FieldValidator<FieldValue>) =>
        error || fieldValidator(value, allValues ?? {}, meta),
      undefined,
    )
