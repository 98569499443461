import { Marker } from 'models'

import React, { useState } from 'react'
import { useIntl } from 'react-intl'

import { Container, Modal } from 'semantic-ui-react'

import IconSelection from './IconSelection'
import MarkerInputs from './MarkerInputs'

interface UpdateMarkerModalProps {
  validFormat: boolean
  markersList: Marker[]
  currentTime: number
  duration: number
  index: number
  open: boolean
  onMarkersUpdate: (markers: Marker[]) => void
  onClose: () => void
  onValidFormat: (value: boolean) => void
}

const UpdateMarkerModal: React.FC<UpdateMarkerModalProps> = ({
  markersList,
  index,
  open,
  validFormat,
  currentTime,
  duration,
  onMarkersUpdate,
  onClose,
  onValidFormat,
}) => {
  const intl = useIntl()
  const markerToUpdate = markersList[index]

  const [newTitle, setNewTitle] = useState<string>(markerToUpdate.title || '')
  const [newTime, setNewTime] = useState<number>(markerToUpdate.time)
  const [newIcon, setNewIcon] = useState<string>(markerToUpdate.icon || '')

  const handleUpdateMarker = (markerToUpdate: Marker) => {
    if (!markerToUpdate) return

    markersList?.splice(index, 1, { title: newTitle, time: newTime, icon: newIcon })
    onMarkersUpdate(markersList)
    onClose()
  }

  return (
    <Modal
      mountNode={document.getElementById('modals')}
      open={open}
      onClose={onClose}
      closeIcon
      size="large"
      style={{ marginTop: '350px' }}
    >
      <Modal.Content>
        <Container>
          <div>
            <div className="flex justify-between">
              <MarkerInputs
                time={newTime}
                title={newTitle}
                icon={newIcon}
                markersList={markersList}
                currentTime={currentTime}
                duration={duration}
                validFormat={validFormat}
                isUpdateInput={true}
                onSetTime={setNewTime}
                onSetTitle={setNewTitle}
                onSetIcon={setNewIcon}
                onValidFormat={onValidFormat}
              />
              {!validFormat && (
                <span className="text-red-600 mx-4 text-justify self-center">
                  {intl.formatMessage({ id: 'edit.invalidFormatTime' })}
                </span>
              )}
              {validFormat && (
                <div>
                  <button
                    className="bg-green-600 rounded text-white w-20 h-12 mr-2"
                    onClick={() => {
                      handleUpdateMarker(markerToUpdate)
                    }}
                  >
                    {intl.formatMessage({ id: 'edit.validate' })}
                  </button>
                  <button
                    className="bg-red-600 rounded text-white w-20 h-12 ml-2"
                    onClick={() => {
                      setNewTitle(markerToUpdate.title || '')
                      setNewTime(markerToUpdate.time)
                      onValidFormat(true)
                      onClose()
                    }}
                  >
                    {intl.formatMessage({ id: 'edit.cancel' })}
                  </button>
                </div>
              )}
            </div>

            <IconSelection onIconSelect={setNewIcon} />
          </div>
        </Container>
      </Modal.Content>
    </Modal>
  )
}

export default UpdateMarkerModal
