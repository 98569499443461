import { addCollection } from '@iconify/react'
import { Marker, MediaLightContentFragment, useUpdateMedia } from 'models'
import React, { useCallback, useEffect, useState } from 'react'

import { useIntl } from 'react-intl'

import { notifyError, notifySuccess } from 'tools/toaster'

import IconSelection from './IconSelection'
import MarkerInputs from './MarkerInputs'
import MarkersList from './MarkersList'
import customIcons from './markerIcons.json'

interface EditTimelineMarkersProps {
  mediaToUpdate?: MediaLightContentFragment
  currentTime: number
  validFormat: boolean
  duration: number
  onClose: () => void
  onValidFormat: (value: boolean) => void
}

const EditTimelineMarkers: React.FC<EditTimelineMarkersProps> = ({
  mediaToUpdate,
  currentTime,
  validFormat,
  duration,
  onValidFormat,
  onClose,
}) => {
  const intl = useIntl()

  const [time, setTime] = useState<number>(0)
  const [title, setTitle] = useState<string>('')
  const [icon, setIcon] = useState<string>('')

  const [markersList, setMarkersList] = useState<Marker[]>([])
  const [updateMedia] = useUpdateMedia()

  const canceledUpdateMedia = () => {
    onClose()
  }

  useEffect(() => {
    if (!mediaToUpdate?.timelineMarkers) return
    const markersWithoutTypename = mediaToUpdate.timelineMarkers.map(({ __typename, ...rest }) => rest)
    setMarkersList(markersWithoutTypename)
  }, [mediaToUpdate?.timelineMarkers])

  useEffect(() => {
    if (customIcons) addCollection(customIcons)
  }, [])

  const handleUpdateMedia = useCallback(
    async markers => {
      if (mediaToUpdate && markers) {
        try {
          await updateMedia({
            variables: {
              mediaId: mediaToUpdate.id,
              input: { timelineMarkers: markers },
            },
          }),
            notifySuccess(intl.formatMessage({ id: 'sportItem.media_updated' }))

          onClose()
        } catch (e) {
          notifyError(intl.formatMessage({ id: 'sportItem.update_media_error' }))
        }
      }
    },
    [mediaToUpdate, updateMedia, intl, onClose],
  )

  return (
    <div className="flex w-full flex-col">
      <div className="flex justify-between">
        <div className="flex max-h-12 self-center">
          <MarkerInputs
            time={time}
            title={title}
            icon={icon}
            markersList={markersList}
            currentTime={currentTime}
            duration={duration}
            validFormat={validFormat}
            onSetTime={setTime}
            onSetTitle={setTitle}
            onSetIcon={setIcon}
            onValidFormat={onValidFormat}
          />
        </div>
        {validFormat && time <= duration && (
          <div>
            <button className="bg-red-600 rounded text-white w-20 h-12 mr-2" onClick={canceledUpdateMedia}>
              {intl.formatMessage({ id: 'edit.cancel' })}
            </button>
            <button
              className="bg-green-600 rounded text-white w-20 h-12 ml-2"
              onClick={() => handleUpdateMedia(markersList)}
            >
              {intl.formatMessage({ id: 'edit.validate' })}
            </button>
          </div>
        )}
      </div>
      <div className="self-center mt-8">
        {!validFormat && (
          <span className="text-red-600 mx-2 self-center">{intl.formatMessage({ id: 'edit.invalidFormatTime' })}</span>
        )}
        {time > duration && (
          <span className="text-red-600 mx-2 self-center">{intl.formatMessage({ id: 'markers.invalidEnd' })}</span>
        )}
      </div>
      <div className="mb-4">
        <IconSelection onIconSelect={setIcon} />
      </div>
      {markersList.length > 0 && (
        <MarkersList
          markersList={markersList}
          onMarkersUpdate={setMarkersList}
          validFormat={validFormat}
          onValidFormat={onValidFormat}
          currentTime={currentTime}
          duration={duration}
        />
      )}
    </div>
  )
}

export default EditTimelineMarkers
