import ConfirmationButton, { ConfirmationButtonBaseProps } from 'Containers/Button/ConfirmationButton'
import { useDeleteUser } from 'models'
import React from 'react'
import { User } from 'services/api/graphql'

interface RemoveUserButtonProps extends ConfirmationButtonBaseProps {
  userId: User['id']
}

const RemoveUserButton = ({ userId, ...props }: RemoveUserButtonProps) => {
  // const { removeUser, loading } = useRemoveUser(userId)
  const [removeUser] = useDeleteUser()
  return <ConfirmationButton action={() => removeUser({ variables: { userId } })} {...props} />
}

export default RemoveUserButton
