import { ChatRoom } from 'models/ChatRoom'
import React, { FC, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Accordion, Icon, Popup, Table } from 'semantic-ui-react'

import SeeMore from './SeeMore'

type Props = {
  chatRoom: ChatRoom
}

const ChatRoomRow: FC<Props> = ({ chatRoom }) => {
  const intl = useIntl()
  const [active, setActive] = useState<boolean>(false)

  return (
    <>
      <Table.Row className={active ? 'bg-gray-100' : ''}>
        <Table.Cell>
          <p className="font-bold">{chatRoom.name}</p>
        </Table.Cell>
        <Table.Cell textAlign="center">
          <p className="text-gray-500">{chatRoom.messageCount}</p>
        </Table.Cell>
        <Table.Cell textAlign="center">
          <Popup
            position="top center"
            content={<FormattedMessage id={chatRoom.active ? 'yes' : 'no'} />}
            trigger={<Icon name={chatRoom.active ? 'eye' : 'eye slash'} />}
          />
        </Table.Cell>
        <Table.Cell textAlign="center">
          <Accordion>
            <Accordion.Title active={active} onClick={() => setActive(!active)}>
              <span className="text-blue-500">{intl.formatMessage({ id: 'seeMore' })}</span>
              <Icon className="text-blue-500" name="dropdown" />
            </Accordion.Title>
          </Accordion>
        </Table.Cell>
      </Table.Row>
      {active && <SeeMore chatRoomId={chatRoom.id} />}
    </>
  )
}

export default ChatRoomRow
