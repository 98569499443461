import React from 'react'

import LightBox from 'react-image-lightbox'
import 'react-image-lightbox/style.css' // This only needs to be imported once in your app

export interface ImageLightboxProps {
  src: string
  onClose: () => void
}
const ImageLightbox = ({ src, onClose }: ImageLightboxProps) => <LightBox mainSrc={src} onCloseRequest={onClose} />

export default ImageLightbox
