import { useApolloClient, useMutation } from '@apollo/client'
import { useCallback } from 'react'
import {
  CompleteMultipartInput,
  COMPLETE_MULTIPART_MUTATION,
  CreateMultipartDocument,
  CreateMultipartQuery,
  CreateMultipartQueryVariables,
  SignedUrlDocument,
  SignedUrlQuery,
  SignedUrlQueryVariables,
  UploadPartInput,
  UPLOAD_PART_MUTATION,
  VideoClipDocument,
  VideoClipQuery,
  VideoClipQueryVariables,
} from 'services/api/graphql'

export const useGetSignedUrl = () => {
  const client = useApolloClient()

  return useCallback(
    (variables: SignedUrlQueryVariables) => {
      return client
        .query<SignedUrlQuery, SignedUrlQueryVariables>({
          query: SignedUrlDocument,
          variables,
        })
        .then(result => result.data.signedUrl)
    },
    [client],
  )
}

export const useGetVideoClip = () => {
  const client = useApolloClient()

  return useCallback(
    (variables: VideoClipQueryVariables) => {
      return client
        .query<VideoClipQuery, VideoClipQueryVariables>({
          query: VideoClipDocument,
          variables,
        })
        .then(result => result.data.videoClip || undefined)
    },
    [client],
  )
}

export const useCreateMultipart = () => {
  const client = useApolloClient()

  return useCallback(
    (variables: CreateMultipartQueryVariables) => {
      return client
        .query<CreateMultipartQuery, CreateMultipartQueryVariables>({
          query: CreateMultipartDocument,
          variables,
        })
        .then(result => result.data.createMultipart)
    },
    [client],
  )
}

export const useUploadPart = () =>
  useMutation<{ uploadPart: boolean }, { input: UploadPartInput }>(UPLOAD_PART_MUTATION)

export const useCompleteMultipart = () =>
  useMutation<{ completeMultipart: boolean }, { input: CompleteMultipartInput }>(COMPLETE_MULTIPART_MUTATION)
