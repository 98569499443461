import { Icon as IconifyIcon } from '@iconify/react'
import InvisibleButton from 'Components/Button/InvisibleButton'
import { Marker } from 'models'
import React, { useEffect, useRef, useState } from 'react'

import { FormattedMessage } from 'react-intl'
import { Checkbox, Header, Icon, Table, TableHeader } from 'semantic-ui-react'
import { formatTime } from 'tools/formatTime'

import UpdateMarkerModal from './UpdateMarkerModal'

interface MarkersListProps {
  markersList?: Marker[]
  validFormat: boolean
  currentTime: number
  duration: number
  onMarkersUpdate: (markers: Marker[]) => void
  onValidFormat: (value: boolean) => void
}

const MarkersList: React.FC<MarkersListProps> = ({
  markersList,
  validFormat,
  currentTime,
  duration,
  onValidFormat,
  onMarkersUpdate,
}) => {
  const [open, setOpen] = useState<boolean>(false)
  const [showMarkers, setShowMarkers] = useState(true)
  const [currentMarkers, setCurrentMarkers] = useState<Marker[]>()
  const currentIndex = useRef<number | null>(null)

  const handleDeleteMarker = (index: number) => {
    if (markersList && index !== -1) {
      const updatedMarkersList = [...markersList.slice(0, index), ...markersList.slice(index + 1)]
      onMarkersUpdate(updatedMarkersList)
    }
  }

  useEffect(() => {
    if (markersList) setCurrentMarkers(markersList)
  }, [markersList])

  return (
    <div className="mt-4">
      <TableHeader className="flex flex-row justify-around self-center">
        <Header as="h2">
          <FormattedMessage id="edit.markersList" />
        </Header>
        <div>
          <Table.HeaderCell>
            <FormattedMessage id="edit.show" />
          </Table.HeaderCell>
          <Checkbox toggle checked={showMarkers === true} onChange={() => setShowMarkers(!showMarkers)} />
        </div>
      </TableHeader>
      {showMarkers && (
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={5}>
                <FormattedMessage id="edit.markersList.time" />
              </Table.HeaderCell>
              <Table.HeaderCell width={5}>
                <FormattedMessage id="edit.markersList.title" />
              </Table.HeaderCell>
              <Table.HeaderCell width={5}>
                <FormattedMessage id="edit.markersList.icon" />
              </Table.HeaderCell>
              <Table.HeaderCell width={1} />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {currentMarkers
              ?.sort((a, b) => a.time - b.time)
              .map((currentMarker, index) => (
                <Table.Row key={currentMarker.time}>
                  <Table.Cell>{formatTime(currentMarker.time)}</Table.Cell>
                  <Table.Cell>{currentMarker.title}</Table.Cell>
                  <Table.Cell>
                    <IconifyIcon icon={`custom-${currentMarker.icon}`} width={34} />
                  </Table.Cell>
                  <Table.Cell>
                    <InvisibleButton
                      onClick={() => {
                        setOpen(true)
                        currentIndex.current = index
                      }}
                    >
                      <Icon name="edit" />
                    </InvisibleButton>
                    <InvisibleButton onClick={() => handleDeleteMarker(index)}>
                      <Icon color="red" name="close" />
                    </InvisibleButton>
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      )}
      {markersList && currentIndex.current !== null && (
        <UpdateMarkerModal
          markersList={markersList}
          currentTime={currentTime}
          duration={duration}
          index={currentIndex.current}
          onMarkersUpdate={onMarkersUpdate}
          open={open}
          onClose={() => {
            setOpen(false)
            currentIndex.current = null
          }}
          validFormat={validFormat}
          onValidFormat={onValidFormat}
        />
      )}
    </div>
  )
}

export default MarkersList
