import { applicationUris } from 'Layout/uris'
import MonitoringDashboardView from 'Views/MonitoringDashboardView'
import VideoGridView from 'Views/VideoGridView'

import React from 'react'
import { Access, userCanAccessToPath } from 'services/api'
import { IUser } from 'stores'

const monitoringRoutes = (user: IUser | null) => [
  {
    path: applicationUris.dashboard,
    element: <MonitoringDashboardView />,
  },
  {
    path: applicationUris.videoGrid,
    element: <VideoGridView />,
    loader: () => userCanAccessToPath(user, [Access.VideoGrid]),
  },
]

export default monitoringRoutes
