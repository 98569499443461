import { observer } from 'mobx-react-lite'
import React, { Fragment, useState } from 'react'
import { useIntl } from 'react-intl'
import { SveChannel } from 'services/api/graphql'
import { notifyError, notifySuccess } from 'tools/toaster'

import { EditSVEChannelForm } from './EditSVEChannelForm'

export interface EditSVEChannelButtonProps {
  sveChannelId: SveChannel['id']
  onDone?: () => void
  render: (onClick: () => void) => React.ReactNode
}

export const EditSVEChannelButton = observer(({ onDone, sveChannelId, render }: EditSVEChannelButtonProps) => {
  const intl = useIntl()

  const [form, setForm] = useState<React.ReactNode | null>()

  const handleClick = () => {
    setForm(
      sveChannelId && (
        <EditSVEChannelForm
          sveChannelId={sveChannelId}
          onCancel={() => setForm(null)}
          onDone={() => {
            setForm(null)
            notifySuccess(
              intl.formatMessage({
                id: 'sveChannels.update_success',
              }),
            )

            if (onDone) onDone()
          }}
          onError={notifyError}
        />
      ),
    )
  }
  return (
    <Fragment>
      {render(handleClick)}
      {form}
    </Fragment>
  )
})
