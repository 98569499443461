import { useApolloClient } from '@apollo/client'
import { useCallback, useState } from 'react'
import { useStore } from 'stores'

export const useLogin = () => {
  const client = useApolloClient()
  const store = useStore()
  const [error, setError] = useState<Error | null>(null)
  const [loading, setLoading] = useState(false)

  const executeLogin = useCallback(
    async (email: string, password: string) => {
      setLoading(true)
      setError(null)
      try {
        await store.login(client, email, password)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        setError(error as Error)
      }
    },
    [store, client],
  )

  const logout = useCallback(async () => {
    setLoading(true)
    setError(null)
    try {
      await store.logout()
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setError(error as Error)
    }
  }, [store])

  return { login: executeLogin, logout, loading, error }
}
