import SAFormField from 'Components/Form/SAFormField'
import FileInput from 'Containers/Form/FileInput'
import { ContentAccessControlUserAuthenticationFormFields } from 'Forms/ContentAccessControl/UserAuthenticationRule/UserAuthenticationFormFields'
import React from 'react'
import { useField } from 'react-final-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { Form as UiForm, FormField, Header, Message } from 'semantic-ui-react'
import {
  ApplicationType,
  CacUserAuthenticationRule,
  CreateApplicationInput,
  MediaType,
  Permission,
  UpdateApplicationInput,
} from 'services/api/graphql'
import { useCurrentUser, useStore } from 'stores/useStores'
import { validateHttpsUrl, validateRequired } from 'tools/formValidators'

import SelectPublicationStatusInput from '../../Containers/Form/SelectPublicationStatusInput'

interface ApplicationFormFieldsProps {
  isWhiteLabel: boolean
  values: CreateApplicationInput | UpdateApplicationInput
}

const ApplicationFormFields = ({ isWhiteLabel, values }: ApplicationFormFieldsProps) => {
  const intl = useIntl()
  const store = useStore()
  const currentUser = useCurrentUser()

  const fieldAppId = useField('applicationId')

  const validateApplicationUrl = (value: string | undefined) => {
    if (values.type !== ApplicationType.WebApp) {
      return undefined
    }
    return validateRequired(value) || validateHttpsUrl(value)
  }
  const validateAppleSharedSecret = (value: string | undefined) => {
    if (values.type !== ApplicationType.MobileApp) {
      return undefined
    }
    return !values.packageName ? validateRequired(value) : undefined
  }
  const validatePackageName = (value: string | undefined) => {
    if (values.type !== ApplicationType.MobileApp) {
      return undefined
    }
    return !values.appleSharedSecret ? validateRequired(value) : undefined
  }
  const validateUserAuthenticationRule = (value: CacUserAuthenticationRule | undefined) => {
    if (values.type !== ApplicationType.WebApp) {
      return undefined
    }
    return validateRequired(value)
  }

  return (
    <UiForm>
      <SAFormField
        name="applicationId"
        validate={validateRequired}
        render={({ input, meta }) => (
          <UiForm.Input
            {...input}
            required
            error={!!meta.touched && !!meta.error}
            label={intl.formatMessage({
              id: 'application.applicationId',
            })}
          />
        )}
      />
      <Message visible={fieldAppId.meta.initial && fieldAppId.meta.dirty} warning>
        <FormattedMessage id={'application.appIdWarning'} />
      </Message>
      <SAFormField
        name="title"
        render={({ input, meta }) => (
          <UiForm.Input
            {...input}
            error={!!meta.touched && !!meta.error}
            label={intl.formatMessage({
              id: 'application.field.title',
            })}
          />
        )}
      />
      <SAFormField
        name="publicationStatus"
        render={({ input, meta }) => (
          <UiForm.Field
            control={SelectPublicationStatusInput}
            {...input}
            disabled={!isWhiteLabel}
            error={!!meta.touched && !!meta.error}
            label={intl.formatMessage({
              id: 'application.publicationStatus',
            })}
          />
        )}
      />
      <Message visible={!isWhiteLabel} warning>
        <FormattedMessage id={'application.not.whitelabel'} />
      </Message>
      <SAFormField
        name="type"
        validate={validateRequired}
        render={({ input, meta }) => (
          <UiForm.Dropdown
            required
            name={input.name}
            label={intl.formatMessage({ id: 'application.type' })}
            onChange={(e, { value }) => input.onChange(value)}
            value={input.value}
            error={!!meta.touched && !!meta.error}
            selection
            options={Object.values(ApplicationType).map(type => ({
              value: type,
              text: intl.formatMessage({ id: `application.type.${type}` }),
            }))}
          />
        )}
      />
      <SAFormField
        name="url"
        required={values.type === ApplicationType.WebApp}
        validate={validateApplicationUrl}
        key={values.type === ApplicationType.WebApp ? 'url-required' : 'url'}
        render={({ input, meta }) => (
          <UiForm.Input
            {...input}
            required
            error={!!meta.touched && !!meta.error}
            label={intl.formatMessage({
              id: 'application.url',
            })}
          />
        )}
      />
      {currentUser.can(Permission.ApplicationContentAccessControlUpdate, store.organismId || undefined, true) && (
        <>
          <Header>
            {intl.formatMessage({ id: 'application.form.cac.userAuthentication.title' })}
            {values.type === ApplicationType.WebApp && <span className="text-red-600 ml-2">*</span>}
          </Header>
          <SAFormField
            name="cac.userAuthenticationRule"
            label="blabla"
            required={values.type !== ApplicationType.WebApp}
            validate={validateUserAuthenticationRule}
            render={({ input }) => (
              <>
                <ContentAccessControlUserAuthenticationFormFields
                  onChange={value => input.onChange(value)}
                  value={input.value as CacUserAuthenticationRule | undefined}
                />
                <p className="text-sm italic pl-2 pt-1">
                  {intl.formatMessage({ id: 'application.form.cac.userAuthentication.helper' })}
                </p>
              </>
            )}
          />
          <Header>{intl.formatMessage({ id: 'application.header.sponsor' })}</Header>
          <SAFormField
            name="sponsoringUrl"
            render={({ input, meta }) => (
              <UiForm.Input
                {...input}
                error={!!meta.touched && !!meta.error}
                label={intl.formatMessage({
                  id: 'application.sponsor',
                })}
              />
            )}
          />

          {values.type === 'mobileApp' && (
            <>
              <SAFormField
                name="sponsorImageFileId"
                render={({ input, meta }) => (
                  <FormField
                    control={FileInput}
                    {...input}
                    type={MediaType.Image}
                    error={!!meta.touched && !!meta.error}
                    label={intl.formatMessage({
                      id: 'application.sponsorImageFileId',
                    })}
                    mimetype="image"
                    imageConstraints={{
                      aspect: 16 / 9,
                      minWidth: 356,
                      minHeight: 200,
                      maxSize: 3000000,
                    }}
                    applicationId={values.applicationId}
                  />
                )}
              />
              <SAFormField
                name="appleSharedSecret"
                required={!values.packageName}
                validate={validateAppleSharedSecret}
                render={({ input, meta }) => (
                  <>
                    <UiForm.Input
                      {...input}
                      value={input.value}
                      required={!values.packageName}
                      onChange={event => input.onChange(event.target.value || null)}
                      error={!!meta.touched && !!meta.error}
                      label={intl.formatMessage({
                        id: 'application.field.apple_shared_secret',
                      })}
                    />
                    <p className="text-sm italic pl-2 pt-1">
                      {intl.formatMessage({ id: 'application.field.apple_shared_secret.helper' })}
                    </p>
                  </>
                )}
              />
              <SAFormField
                name="packageName"
                required={!values.appleSharedSecret}
                validate={validatePackageName}
                render={({ input, meta }) => (
                  <>
                    <UiForm.Input
                      {...input}
                      value={input.value}
                      required={!values.appleSharedSecret}
                      onChange={event => input.onChange(event.target.value || null)}
                      error={!!meta.touched && !!meta.error}
                      label={intl.formatMessage({
                        id: 'application.field.android_package_name',
                      })}
                    />
                    <p className="text-sm italic pl-2 pt-1">
                      {intl.formatMessage({ id: 'application.field.android_package_name.helper' })}
                    </p>
                  </>
                )}
              />
            </>
          )}
        </>
      )}
    </UiForm>
  )
}

export default ApplicationFormFields
