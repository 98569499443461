import InvisibleButton from 'Components/Button/InvisibleButton'
import RefreshButton from 'Components/Button/RefreshButton'
import Loader from 'Components/Loader'
import PageHeader from 'Components/PageHeader'
import ConfirmationButton from 'Containers/Button/ConfirmationButton'
import LegalRestrictionForm, { LegalRestrictionFormValue } from 'Forms/LegalRestriction/LegalRestrictionForm'
import { format } from 'date-fns'
import countriesi18n from 'i18n-iso-countries'
import {
  RestrictionTargetFilter,
  RestrictionType,
  useCreateLegalRestriction,
  useDeleteLegalRestriction,
  useUpdateLegalRestriction,
} from 'models'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button, Checkbox, Grid, Icon, Table, TableCell } from 'semantic-ui-react'
import { getTime } from 'tools/date'
import { notifySuccess } from 'tools/toaster'
import { LegalRestriction } from 'types/legalRestriction'

import { useGetLegalRestrictions } from './use-get-legal-restrictions'

const LegalRestrictionView: React.FC = () => {
  const intl = useIntl()

  const { legalRestrictions, loading, refetch } = useGetLegalRestrictions()

  // Show active rules first, but keep initial order
  const [initialOrder, setInitialOrder] = useState<string[]>()
  const sortedLegalRestrictions = useMemo(() => {
    return legalRestrictions?.slice().sort((ra, rb) => {
      if (!initialOrder) {
        const valA = ra.active ? 1 : 0
        const valB = rb.active ? 1 : 0
        return valB - valA
      } else {
        const posA = initialOrder.findIndex(id => id === ra.id)
        const posB = initialOrder.findIndex(id => id === rb.id)
        return posA - posB
      }
    })
  }, [legalRestrictions, initialOrder])
  useEffect(() => {
    if (sortedLegalRestrictions && !initialOrder) {
      setInitialOrder(sortedLegalRestrictions.map(restriction => restriction.id))
    }
  }, [initialOrder, sortedLegalRestrictions])

  const [updateLegalRestriction, { loading: loadingUpdate }] = useUpdateLegalRestriction()
  const [createLegalRestriction, { loading: loadingCreate }] = useCreateLegalRestriction()
  const [deleteLegalRestriction] = useDeleteLegalRestriction()

  const onDeleteLegalRestriction = useCallback(
    async (id: string) => {
      await deleteLegalRestriction({ variables: { id } })
      refetch()
    },
    [deleteLegalRestriction, refetch],
  )

  const activateLegalRestriction = useCallback(
    async (id: string, value: boolean) => {
      await updateLegalRestriction({
        variables: { id, input: { active: value } },
      })
      refetch()
    },
    [refetch, updateLegalRestriction],
  )

  const [formModalOpen, setFormModalOpen] = useState(false)
  const [formValue, setFormValue] = useState<LegalRestrictionFormValue | null>(null)
  const [editedId, setEditedId] = useState('')

  const onAddRestriction = useCallback(() => {
    setFormValue(null)
    setEditedId('')
    setFormModalOpen(true)
  }, [])

  const onEditRestriction = useCallback(
    ({
      id,
      type,
      countries,
      mature,
      organismId,
      sportItemSetIds,
      sportItemSets,
      sportItemIds,
      offerIds,
      restrictionTarget,
      description,
      timeSlots,
    }: LegalRestriction) => {
      setFormValue({
        type,
        countries,
        mature,
        organismId:
          organismId || (sportItemSets && sportItemSets.length > 0 ? sportItemSets[0].ownerOrganismId : undefined),
        sportItemSetIds,
        sportItemIds,
        offerIds,
        restrictionTarget: restrictionTarget || undefined,
        description,
        from: (timeSlots && timeSlots.from) || undefined,
        to: (timeSlots && timeSlots.to) || undefined,
      })
      setEditedId(id)
      setFormModalOpen(true)
    },
    [],
  )

  const onSubmit = useCallback(
    async (value: LegalRestrictionFormValue) => {
      setFormModalOpen(false)
      const input = {
        countries: value.countries,
        mature: value.mature,
        offerIds: value.offerIds,
        organismId:
          value.organismId && (!value.sportItemSetIds || value.sportItemSetIds.length === 0) ? value.organismId : null,
        sportItemSetIds: value.sportItemSetIds,
        sportItemIds: value.sportItemIds,
        type: value.type,
        description: value.description,
        restrictionTarget: value.offerIds?.length ? RestrictionTargetFilter.Offer : RestrictionTargetFilter.Media,
        timeSlots:
          value.type === RestrictionType.TimeSlots
            ? {
                from: value.from ? getTime(value.from) : '00:00',
                to: value.to ? getTime(value.to) : '00:00',
              }
            : null,
      }
      if (editedId) {
        await updateLegalRestriction({
          variables: {
            id: editedId,
            input,
          },
        })
        notifySuccess(intl.formatMessage({ id: 'legalRestriction.updateSuccess' }))
      } else {
        const createResult = await createLegalRestriction({
          variables: { input },
        })
        // Put new restriction on top
        if (createResult.data && initialOrder) {
          setInitialOrder([createResult.data.createLegalRestriction.id, ...initialOrder])
          notifySuccess(intl.formatMessage({ id: 'legalRegistration.createSuccess' }))
        }
      }
      refetch()
    },
    [createLegalRestriction, editedId, initialOrder, intl, refetch, updateLegalRestriction],
  )

  return (
    <Loader loading={loading || loadingUpdate || loadingCreate}>
      <Grid padded>
        <Grid.Column>
          <PageHeader title={intl.formatMessage({ id: 'legalRestrictions.header' })}>
            <RefreshButton onClick={() => refetch()} loading={loading} />
            <Button circular icon="add" color="red" onClick={onAddRestriction} />
          </PageHeader>

          <Table>
            <Table.Header>
              <Table.Row textAlign="center">
                <Table.HeaderCell>
                  <FormattedMessage id="legalRestrictions.countries" />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <FormattedMessage id="legalRestrictions.target" />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <FormattedMessage id="legalRestrictions.action" />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <FormattedMessage id="legalRestrictions.description" />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <FormattedMessage id="legalRestrictions.active" />
                </Table.HeaderCell>
                <Table.HeaderCell width={1} textAlign="center">
                  <FormattedMessage id="legalRestrictions.actions" />
                </Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {sortedLegalRestrictions &&
                sortedLegalRestrictions.map(restriction => {
                  const {
                    id,
                    countries,
                    mature,
                    offers,
                    organism,
                    sportItemSets,
                    type,
                    timeSlots,
                    description,
                    active,
                  } = restriction

                  return (
                    <Table.Row textAlign="center" key={id}>
                      <Table.Cell disabled={!active}>
                        {countries.map(code => countriesi18n.getName(code, intl.locale)).join(', ')}
                      </Table.Cell>
                      <Table.Cell disabled={!active}>
                        {mature && <FormattedMessage id={`legalRestriction.mature.${mature}`} />}
                        {offers && organism && offers.map(offer => offer.title).join(',')}
                        {sportItemSets && sportItemSets.map(sportItemSet => sportItemSet.title).join(',')}
                        {organism && !offers && organism.title}
                      </Table.Cell>
                      <TableCell disabled={!active}>
                        <FormattedMessage
                          id={`legalRestriction.action.${type}`}
                          values={
                            timeSlots
                              ? { from: format(timeSlots.from, 'HH:mm'), to: format(timeSlots.to, 'HH:mm') }
                              : {}
                          }
                        />
                      </TableCell>
                      <TableCell>{description}</TableCell>
                      <TableCell>
                        <Checkbox
                          toggle
                          checked={active}
                          onChange={(_, data) => activateLegalRestriction(id, !!data.checked)}
                        />
                      </TableCell>
                      <TableCell>
                        <InvisibleButton onClick={() => onEditRestriction(restriction)}>
                          <Icon name="edit" link size="large" inverted color="black" />
                        </InvisibleButton>
                        <ConfirmationButton
                          action={() => onDeleteLegalRestriction(restriction.id)}
                          successText={intl.formatMessage({
                            id: 'legalRestriction.deleteSuccess',
                          })}
                          confirmText={intl.formatMessage({
                            id: 'legalRestriction.deleteConfirm',
                          })}
                        >
                          <Icon name="remove" link size="large" inverted color="red" />
                        </ConfirmationButton>
                      </TableCell>
                    </Table.Row>
                  )
                })}
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid>
      {formModalOpen && (
        <LegalRestrictionForm
          open
          value={formValue || undefined}
          onCancel={() => setFormModalOpen(false)}
          onSubmit={onSubmit}
        />
      )}
    </Loader>
  )
}

export default LegalRestrictionView
