import authRoutes from 'Layout/routes/authRoutes'

import dashBoardRoutes from 'Layout/routes/dashBoardRoutes'
import { applicationUris } from 'Layout/uris'
import AuthLayout from 'Views/AuthLayout'

import { useUpdateTokenPayload } from 'hooks/update-token-payload'

import React from 'react'

import { createBrowserRouter, redirect, RouterProvider } from 'react-router-dom'

import { useStore } from 'stores'

import styled from 'styled-components'

import DashboardLayout from './Layout/DashboardLayout'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`
const MainScreen = () => {
  useUpdateTokenPayload()
  const store = useStore()
  const { user } = store.currentUser

  const router = createBrowserRouter([
    {
      element: <AuthLayout />,
      children: authRoutes(),
      loader: () => {
        if (user) throw redirect(applicationUris.dashboard)
        return true
      },
    },
    {
      element: <DashboardLayout />,
      loader: () => {
        if (!user) throw redirect(applicationUris.login)
        return true
      },
      children: dashBoardRoutes(user),
    },
  ])
  return (
    <Container>
      <RouterProvider router={router} />
    </Container>
  )
}

export default MainScreen
