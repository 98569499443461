import { QueryHookOptions, useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { useCallback } from 'react'
import {
  Application,
  CreateOrganismInput,
  MoveFrontPageInput,
  MutationRevokeAllLicensesArgs,
  Organism,
  PurchasableOffer,
  QueryApplicationArgs,
  QueryOrganismArgs,
  QueryOrganismsArgs,
  UpdateOrganismInput,
  GET_ORGANISM_QUERY,
  GET_LIGHT_ORGANISM_QUERY,
  GET_ORGANISM_WITH_SUBSCRIPTIONS_QUERY,
  GET_ORGANISMS_QUERY,
  CREATE_ORGANISM_MUTATION,
  UPDATE_ORGANISM_MUTATION,
  DELETE_ORGANISM_MUTATION,
  GET_ORGANISM_APPLICATIONS_QUERY,
  GET_ORGANISM_OFFERS_QUERY,
  MOVE_FRONT_PAGE_MUTATION,
  REVOKE_ALL_LICENSES_MUTATION,
  UserRoleId,
} from 'services/api/graphql'
import { CurrentUser, useCurrentUser, useStore } from 'stores'
import { GetOrganismsQuery } from 'stores/fragments'
import { useGraphQLDataMap, useShortMutation } from 'tools/graphql'

import { useGetMyOrganisms, useGetMyOrganismTitles } from './User'

export type GetOrganismsResult = {
  organisms: {
    organisms: Organism[]
    totalCount: number
  }
}

export type GetOrganismResult = {
  organism: Organism | undefined
}
export const useGetOrganism = (organismId: Organism['id'], options?: QueryHookOptions<GetOrganismResult>) => {
  const { data, ...rest } = useQuery<GetOrganismResult>(GET_ORGANISM_QUERY, {
    variables: { organismId },
    ...options,
  })
  return { organism: data && data.organism, ...rest }
}

export const useGetLightOrganism = (organismId: Organism['id'], options?: QueryHookOptions<GetOrganismResult>) => {
  const { data, ...rest } = useQuery<GetOrganismResult>(GET_LIGHT_ORGANISM_QUERY, {
    variables: { organismId },
    ...options,
  })
  return { organism: data && data.organism, ...rest }
}

/**
 * Only admin or user not associated to any organism can select any organism
 **/
const haveAllOrganismsAccess = (currentUser: CurrentUser) =>
  !!currentUser.loggedUser &&
  (currentUser.loggedUser.roleIds.includes(UserRoleId.Admin) || currentUser.loggedUser.organismIds.length === 0)

export const useAllowedOrganismTitles = () => {
  const currentUser = useCurrentUser()

  const allOrganismsAccess = haveAllOrganismsAccess(currentUser)
  const userOrganisms = useGetMyOrganismTitles({ skip: allOrganismsAccess })
  const allOrganisms = useGetOrganismTitles()

  return haveAllOrganismsAccess(currentUser) ? allOrganisms : userOrganisms
}

export const useAllowedOrganisms = (options?: QueryHookOptions<any, QueryOrganismsArgs>) => {
  const currentUser = useCurrentUser()

  const allOrganismsAccess = haveAllOrganismsAccess(currentUser)
  const userOrganisms = useGetMyOrganisms({ ...options, skip: allOrganismsAccess })
  const allOrganisms = useGetOrganisms({ ...options, skip: !allOrganismsAccess })

  return allOrganismsAccess ? allOrganisms : userOrganisms
}

export const useGetOrganismWithSubscriptions = (
  organismId: Organism['id'],
  options?: QueryHookOptions<GetOrganismResult>,
) => {
  const { data, ...rest } = useQuery<GetOrganismResult>(GET_ORGANISM_WITH_SUBSCRIPTIONS_QUERY, {
    variables: { organismId },
    ...options,
  })
  return { organism: data && data.organism, ...rest }
}

export const useGetOrganisms = (options?: QueryHookOptions<GetOrganismsResult, QueryOrganismsArgs>) => {
  const { data, ...rest } = useQuery<GetOrganismsResult>(GET_ORGANISMS_QUERY, options)
  const organisms = data && data.organisms && data.organisms.organisms
  return { organisms, ...rest }
}

export const useGetOrganismsMap = () => {
  const { organisms: organismsArray, ...rest } = useGetOrganisms()
  const organisms = useGraphQLDataMap(organismsArray)
  return { organisms, ...rest }
}
export const useCreateOrganism = () =>
  useMutation<{ createOrganism: Organism }, { input: CreateOrganismInput }>(CREATE_ORGANISM_MUTATION)
export const useUpdateOrganism = () =>
  useMutation<{ updateOrganism: Organism }, { input: UpdateOrganismInput }>(UPDATE_ORGANISM_MUTATION)
export const useDeleteOrganism = () =>
  useMutation<{ deleteOrganism: Organism }, { organismId: Organism['id'] }>(DELETE_ORGANISM_MUTATION)

export type GetOrganismApplicationsResult = {
  applications: Application[] | undefined
}
export const useGetOrganismApplications = (
  id?: string,
  options?: QueryHookOptions<{ organism: Organism }, QueryApplicationArgs>,
) => {
  const { data, ...query } = useQuery(GET_ORGANISM_APPLICATIONS_QUERY, {
    variables: {
      id: id || '',
    },
    ...options,
  })
  return {
    applications: data && data.organism && data.organism.applications,
    ...query,
  }
}

export type GetOrganismOffersResult = {
  offers: PurchasableOffer[] | undefined
}
export const useGetOrganismOffers = (
  id?: string,
  options?: QueryHookOptions<{ organism: Organism }, QueryOrganismArgs>,
) => {
  const { data, ...query } = useQuery<{ organism: Organism }, QueryOrganismArgs>(GET_ORGANISM_OFFERS_QUERY, {
    variables: {
      id: id || '',
    },
    ...options,
  })
  return {
    offers: data && data.organism && data.organism.offers,
    ...query,
  }
}

export const useMoveFrontPage = () => {
  return useMutation<{ moveFrontPage: Organism[] }, { input: MoveFrontPageInput }>(MOVE_FRONT_PAGE_MUTATION)
}

export const useRevokeAllLicenses = () =>
  useShortMutation<{ revokeAllLicense: number }, MutationRevokeAllLicensesArgs>(REVOKE_ALL_LICENSES_MUTATION)

export const useGetOrganismTitles = () => {
  const { organismTitles, setOrganismTitles } = useStore()
  const [_getOrganisms, { loading, ...rest }] = useLazyQuery(GetOrganismsQuery)

  const getOrganismTitles = useCallback(async () => {
    const { data } = await _getOrganisms()
    setOrganismTitles(data?.organisms?.organisms ?? [])
  }, [_getOrganisms, setOrganismTitles])

  return {
    getOrganismTitles,
    organisms: organismTitles.map(({ id, title }) => ({ id, title })),
    loading,
    ...rest,
  }
}
