import { QueryHookOptions, useMutation, useQuery } from '@apollo/client'
import {
  CreateSportItemInput,
  CREATE_SPORTITEM_MUTATION,
  DELETE_SPORTITEM_MUTATION,
  GET_SPORTITEMS_QUERY,
  GET_SPORTITEM_QUERY,
  MovePlaylistItemInput,
  MOVE_PLAYLIST_ITEM_MUTATION,
  QueryCheckSportItemStreamsMovableArgs,
  QuerySportItemsArgs,
  SportItemSet,
  SportItemStreamConflict,
  STOP_SPORTITEM_LIVE_MUTATION,
  UpdateSportItemInput,
  UPDATE_SPORTITEM_MUTATION,
  CHECK_SPORTITEM_STREAMS_MOVABLE_QUERY,
  GET_SPORTITEM_BY_CHAT_ROOM_QUERY,
  SportItemByChatRoomQuery,
  QueryMonitoringDashboardArgs,
  GET_MONITORING_DASHBOARD_QUERY,
  SportItem,
} from 'services/api/graphql'
import { getSportEventItemState } from 'tools/sportItem'

import { ChatRoom } from './ChatRoom'

/**
 * Hooks
 */
export type GetSportItemResult = {
  sportItem: SportItem | undefined
}
export const useGetSportItem = (sportItemId: SportItem['id'], options?: QueryHookOptions<GetSportItemResult>) => {
  const { data, ...rest } = useQuery<GetSportItemResult>(GET_SPORTITEM_QUERY, {
    variables: { sportItemId },
    ...options,
  })
  return { sportItem: data && data.sportItem, ...rest }
}

export type GetSportItemsResult = {
  sportItems: {
    sportItems: SportItem[]
    totalCount: number
  }
}

export const useGetSportItems = (
  sportItemSetId: SportItemSet['id'],
  options?: QueryHookOptions<GetSportItemsResult, QuerySportItemsArgs>,
) =>
  useQuery<GetSportItemsResult, QuerySportItemsArgs>(GET_SPORTITEMS_QUERY, {
    ...options,
    variables: { sportItemSetId, ...((options && options.variables) || {}) },
  })

export type GetMonitoringDashboardResult = {
  monitoringDashboard: SportItem[]
}

export const useGetMonitoringDashboard = (
  options?: QueryHookOptions<GetMonitoringDashboardResult, QueryMonitoringDashboardArgs>,
) => {
  const { data, ...query } = useQuery(GET_MONITORING_DASHBOARD_QUERY, options)

  return {
    data:
      data &&
      data.monitoringDashboard?.map(item => ({
        ...item,
        sportEventItem: item.sportEventItem && {
          ...item.sportEventItem,
          testDate: item.sportEventItem.testDate && new Date(item.sportEventItem.testDate),
          startDate: new Date(item.sportEventItem.startDate),
          stopDate: new Date(item.sportEventItem.stopDate),
          state: getSportEventItemState(item.sportEventItem),
        },
      })),
    ...query,
  }
}

export const useCreateSportItem = () =>
  useMutation<{ createSportItem: SportItem }, { input: CreateSportItemInput }>(CREATE_SPORTITEM_MUTATION)
export const useUpdateSportItem = () =>
  useMutation<{ updateSportItem: SportItem }, { input: UpdateSportItemInput }>(UPDATE_SPORTITEM_MUTATION)
export const useDeleteSportItem = () =>
  useMutation<{ deleteSportItem: SportItem }, { sportItemId: SportItem['id'] }>(DELETE_SPORTITEM_MUTATION)

export const useStopSportItemLive = () => {
  return useMutation<{ stopSportItemLive: SportItem }, { id: SportItem['id'] }>(STOP_SPORTITEM_LIVE_MUTATION)
}

export const useMovePlaylistItem = () => {
  return useMutation<{ movePlaylistItem: SportItem[] }, { input: MovePlaylistItemInput }>(MOVE_PLAYLIST_ITEM_MUTATION)
}

export const useCheckSportItemStreamsMovable = (
  input: QueryCheckSportItemStreamsMovableArgs,
  options?: QueryHookOptions<{
    checkSportItemStreamsMovable: SportItemStreamConflict[]
  }>,
) => {
  const { data, ...rest } = useQuery<{
    checkSportItemStreamsMovable: SportItemStreamConflict[]
  }>(CHECK_SPORTITEM_STREAMS_MOVABLE_QUERY, {
    variables: {
      ...input,
    },
    ...options,
  })
  return {
    data: data ? data.checkSportItemStreamsMovable : undefined,
    ...rest,
  }
}

export const useGetSportItemByChatRoom = (
  chatRoomId: ChatRoom['id'],
  options?: QueryHookOptions<SportItemByChatRoomQuery>,
) => {
  const { data, ...rest } = useQuery<SportItemByChatRoomQuery>(GET_SPORTITEM_BY_CHAT_ROOM_QUERY, {
    variables: { chatRoomId },
    ...options,
  })
  return { sportItem: data && data.sportItemByChatRoom, ...rest }
}
