import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Label, SemanticCOLORS } from 'semantic-ui-react'
import { SportEventState } from 'services/api/graphql'

interface SportEventStateLabelProps {
  state: SportEventState
}

const stateColorsMapping: { [key in SportEventState]: SemanticCOLORS } = {
  [SportEventState.NotStarted]: 'blue',
  [SportEventState.Running]: 'green',
  [SportEventState.Finished]: 'grey',
}

const SportEventStateLabel: React.FC<SportEventStateLabelProps> = ({ state }) => {
  return (
    <Label color={stateColorsMapping[state]}>
      <FormattedMessage id={`sportEvent.${state}`} />
    </Label>
  )
}

export default SportEventStateLabel
