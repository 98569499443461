import { applicationUris } from 'Layout/uris'
import React from 'react'
import { SportItemSetType } from 'services/api/graphql'

import SportItemSetsViewBase from './SportItemSetsViewBase'

const SportEventsView = () => {
  return (
    <SportItemSetsViewBase
      type={SportItemSetType.SportEvent}
      sportItemUrlFormat={applicationUris.sportEventItems('{{id}}')}
      sportItemSetUrlFormat={applicationUris.sportEvent('{{id}}')}
    />
  )
}

export default SportEventsView
