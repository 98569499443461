import Resizer from 'react-image-file-resizer'
import { File as SportallFile } from 'services/api/graphql'
import { ImageConstraints, ImageFormat, ImageDimensions } from 'types/image'

import { getFile, getImageFilename } from './file'

import { readAsDataURL } from './fileReader'

const MAX_WIDTH = 2560
const MAX_HEIGHT = 1440

// Image constraints
export const doesImageSizeRespectConstraints = (
  { width, height }: ImageDimensions,
  constraints: ImageConstraints,
): boolean => {
  // Check aspect ratio
  if (constraints.aspect) {
    if (!height) return false
    const aspect = width / height
    if (!isSameAspectRatio(aspect, constraints.aspect)) {
      return false
    }
  }
  if (constraints.minHeight !== undefined && height < constraints.minHeight) {
    return false
  }
  if (constraints.maxHeight !== undefined && height > constraints.maxHeight) {
    return false
  }
  if (constraints.minWidth !== undefined && width < constraints.minWidth) {
    return false
  }
  if (constraints.maxWidth !== undefined && width > constraints.maxWidth) {
    return false
  }
  return true
}

export const isSameAspectRatio = (aspectRatio1: number, aspectRatio2: number, tolerance = 0.02): boolean =>
  Math.abs(aspectRatio1 - aspectRatio2) <= tolerance

export const aspectRatioText = (aspectRatio: number): string | undefined => {
  if (aspectRatio) {
    if (isSameAspectRatio(aspectRatio, 1)) return '1'
    if (isSameAspectRatio(aspectRatio, 4 / 3)) return '4/3'
    if (isSameAspectRatio(aspectRatio, 3 / 4)) return '3/4'
    if (isSameAspectRatio(aspectRatio, 16 / 9)) return '16/9'
    if (isSameAspectRatio(aspectRatio, 9 / 16)) return '9/16'
    if (isSameAspectRatio(aspectRatio, 1 / 2)) return '1/2'
    if (isSameAspectRatio(aspectRatio, 3 / 2)) return '3/2'
    if (isSameAspectRatio(aspectRatio, 2 / 3)) return '2/3'
    if (isSameAspectRatio(aspectRatio, 2)) return '2'
    if (isSameAspectRatio(aspectRatio, 1 / 3)) return '1/3'
    if (isSameAspectRatio(aspectRatio, 3)) return '3'
    return String(aspectRatio.toFixed(2))
  }
}

// check format
export const doesImageFormatRespectConstraints = (file: SportallFile, format: ImageFormat): boolean => {
  const fileType = file.filename.split('.')[1]
  if (format === ImageFormat.PNG || ImageFormat.BMP) {
    return fileType === format
  }
  return false
}

// Cropping
export const canonizeCrop = (crop: ReactCrop.Crop): ReactCrop.Crop => {
  return {
    x: crop.x || 0,
    y: crop.y || 0,
    width: crop.width || 0,
    height: crop.height || 0,
  }
}

export const areSameCrops = (crop1: ReactCrop.Crop, crop2: ReactCrop.Crop): boolean => {
  return crop1.x === crop2.x && crop1.y === crop2.y && crop1.width === crop2.width && crop1.height === crop2.height
}

export const getImageElement = (image: string): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const img = document.createElement('img')
    img.onload = () => resolve(img)
    img.onerror = error => reject(error)
    img.src = image
  })
}

export const resizeImageElement = (
  image: HTMLImageElement,
  x: number,
  y: number,
  width: number,
  height: number,
  format?: ImageFormat,
): Promise<Blob> => {
  if (format === ImageFormat.BMP) throw new Error('Resize in BPM is not supported')

  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas')
    canvas.width = width
    canvas.height = height

    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D

    if (!ctx) reject(new Error('Context is empty'))

    ctx.imageSmoothingEnabled = false
    ctx?.drawImage(image, x, y, width, height, 0, 0, width, height)

    canvas.toBlob(
      blob => {
        if (!blob) {
          reject(new Error('Canvas is empty'))
          return
        }

        resolve(blob)
      },
      format ? `image/${format}` : 'image/png',
    )
  })
}

export type CropInfo = {
  width: number
  height: number
  x: number
  y: number
}

export const getCroppedImage = async (
  image: HTMLImageElement,
  cropInfo: CropInfo,
  format?: ImageFormat,
  filename?: string,
): Promise<File | Blob | null> => {
  const scaleX = image.naturalWidth / image.width
  const scaleY = image.naturalHeight / image.height
  const width = cropInfo.width * scaleX
  const height = cropInfo.height * scaleY

  if (cropInfo.width === 0 || cropInfo.height === 0) {
    return null
  }

  return getFile(
    await resizeImageElement(image, cropInfo.x * scaleX, cropInfo.y * scaleY, width, height, format),
    filename,
  )
}

/** Returns a resized image that fits with max width & height */
export const getResizedImage = async (image: Blob, format?: ImageFormat) => {
  // Create base64 image and assign it to an html element
  const base64 = await readAsDataURL(image)
  const imageEl = await getImageElement(base64)

  // Resize it
  if (imageEl.width > imageEl.height) {
    if (imageEl.width > MAX_WIDTH) {
      imageEl.height = imageEl.height * (MAX_WIDTH / imageEl.width)
      imageEl.width = MAX_WIDTH
    }
  } else {
    if (imageEl.height > MAX_HEIGHT) {
      imageEl.width = imageEl.width * (MAX_HEIGHT / imageEl.height)
      imageEl.height = MAX_HEIGHT
    }
  }

  // And convert it back to blob
  return resizeImageElement(imageEl, 0, 0, imageEl.width, imageEl.height, format)
}

export const compressImage = async (image: File | Blob, format: ImageFormat, compressionLevel = 20) => {
  if (format === ImageFormat.BMP) throw new Error('Compression in BMP is not supported')

  return new Promise<File>(resolve => {
    Resizer.imageFileResizer(
      getFile(image),
      MAX_WIDTH,
      MAX_HEIGHT,
      format === ImageFormat.JPEG || format === ImageFormat.WEBP ? format.toUpperCase() : 'WEBP',
      compressionLevel,
      0,
      async file => {
        const filename = `${getImageFilename(image).split('.').slice(0, -1).join('.')}.${format}`

        if (format === ImageFormat.PNG) {
          resolve(getFile(await getResizedImage(file as File), filename))
          return
        }

        resolve(getFile(file as File, filename))
      },
      'file',
    )
  })
}
