import InlineConfirmationButton from 'Containers/Button/InlineConfirmationButton'
import { useCreateCategory, useDeleteCategory, useGetCategories, useUpdateCategory } from 'models'
import React, { useCallback, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button, Icon, Input, Label, Modal } from 'semantic-ui-react'
import { Category } from 'services/api/graphql'
import { notifySuccess } from 'tools/toaster'

import InvisibleButton from './Button/InvisibleButton'
import Loader from './Loader'

interface CategoriesManagementModalProps {
  open: boolean
  onClose: () => void
}

const CategoriesManagementModal: React.FC<CategoriesManagementModalProps> = ({ open, onClose }) => {
  const intl = useIntl()

  // Fetch categories
  const { categories, loading, refetch } = useGetCategories()

  // Graphql Mutations
  const [deleteCategory, { loading: loadingDelete }] = useDeleteCategory()
  const [updateCategory, { loading: loadingUpdate }] = useUpdateCategory()
  const [createCategory, { loading: loadingCreate }] = useCreateCategory()

  // Edition / creation states
  const [editedCategory, setEditedCategory] = useState<string | null>(null)
  const [editedTitle, setEditedTitle] = useState('')
  const [newCategory, setNewCategory] = useState(false)

  /** Update a category's title */
  const onCategoryTitleUpdate = useCallback(
    async (title: string) => {
      if (!!editedCategory && !!title) {
        await updateCategory({ input: { id: editedCategory, title } })
        notifySuccess(intl.formatMessage({ id: 'categories.update_success' }))
        setEditedCategory(null)
        refetch()
      }
    },
    [editedCategory, updateCategory, intl, refetch],
  )
  /** Create a new category */
  const onCategoryCreate = useCallback(
    async (title: string) => {
      if (!!title) {
        await createCategory({ input: { title } })
        notifySuccess(intl.formatMessage({ id: 'categories.create_success' }, { title }))
        setNewCategory(false)
        refetch()
      }
    },
    [createCategory, intl, refetch],
  )
  /** Detect when user press Enter or Escape while editing or adding a category, then validate or cancel */
  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        if (newCategory) {
          onCategoryCreate(editedTitle)
        } else {
          onCategoryTitleUpdate(editedTitle)
        }
      }
      if (e.key === 'Escape') {
        e.preventDefault()
        setEditedCategory(null)
        setNewCategory(false)
      }
    },
    [newCategory, onCategoryCreate, editedTitle, onCategoryTitleUpdate],
  )
  /** Called when the edit category button is pressed, change the label into an input */
  const editCategory = useCallback((category: Category) => {
    setEditedCategory(category.id)
    setEditedTitle(category.title)
    setNewCategory(false)
  }, [])
  /** Called when the new category button is pressed, add a label with an input */
  const addCategory = useCallback(() => {
    setNewCategory(true)
    setEditedTitle('')
    setEditedCategory(null)
  }, [])

  /** Check if a category is being edited */
  const isEdited = useCallback((id: string) => id === editedCategory, [editedCategory])

  return (
    <Modal
      mountNode={document.getElementById('modals')}
      open={open}
      onClose={onClose}
      closeIcon
      size="fullscreen"
      closeOnEscape={false}
    >
      <Modal.Header>
        <FormattedMessage id="categories.header" />
      </Modal.Header>
      <Modal.Content>
        <Loader loading={loading || loadingCreate || loadingDelete || loadingUpdate}>
          <Label.Group size="big">
            {/* Display list of categories */}
            {categories &&
              categories.map(category => (
                <Label key={category.id}>
                  {!isEdited(category.id) && (
                    // Normal display
                    <>
                      <span>{category.title}</span>
                      <Label.Detail>
                        <InvisibleButton onClick={() => editCategory(category)}>
                          <Icon name="pencil" />
                        </InvisibleButton>
                        <InlineConfirmationButton
                          negative
                          action={() => deleteCategory({ id: category.id })}
                          confirmText={
                            <FormattedMessage id="categories.delete_confirm" values={{ title: category.title }} />
                          }
                          successText={intl.formatMessage(
                            {
                              id: 'categories.delete_success',
                            },
                            { title: category.title },
                          )}
                          onDone={refetch}
                        >
                          <Icon name="remove" color="red" />
                        </InlineConfirmationButton>
                      </Label.Detail>
                    </>
                  )}
                  {isEdited(category.id) && (
                    // Edition display
                    <Input
                      size="mini"
                      value={editedTitle}
                      onKeyDown={handleKeyDown}
                      onChange={e => setEditedTitle(e.target.value)}
                    />
                  )}
                </Label>
              ))}

            {!newCategory && (
              // Add category button
              <Button circular icon="plus" onClick={() => addCategory()} />
            )}
            {newCategory && (
              // New category input
              <Label>
                <Input
                  size="mini"
                  value={editedTitle}
                  onKeyDown={handleKeyDown}
                  onChange={e => setEditedTitle(e.target.value)}
                />
              </Label>
            )}
          </Label.Group>
        </Loader>
      </Modal.Content>
    </Modal>
  )
}

export default CategoriesManagementModal
