import InvisibleButton from 'Components/Button/InvisibleButton'
import RefreshButton from 'Components/Button/RefreshButton'
import Loader from 'Components/Loader'
import PageHeader from 'Components/PageHeader'
import { SVEChannelDetailsModal } from 'Components/SVEChannelDetailsModal'
import StreamDetailsModal from 'Components/StreamDetailsModal'
import VideoPlayerModal from 'Components/VideoPlayer/VideoPlayerModal'
import ImageFilePreviewButton from 'Containers/Button/ImageFilePreviewButton'
import AddOrganismButton from 'Forms/Organism/AddOrganismButton'
import EditOrganismButton from 'Forms/Organism/EditOrganismButton'
import RemoveOrganismButton from 'Forms/Organism/RemoveOrganismButton'
import _ from 'lodash'
import { useAllowedOrganisms } from 'models'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button, Grid, Icon, Input, Popup, Table } from 'semantic-ui-react'
import { Organism, OrganismType, Permission, useOrganismWithStreamsAndSveChannelsQuery } from 'services/api/graphql'
import { useCurrentUser } from 'stores'
import styled from 'styled-components'
import { notifyError } from 'tools/toaster'
import { logoUrlForLightBackground } from 'tools/tools'

const ThumbnailImage = styled.img`
  width: 48px;
  height: 48px;
  object-fit: contain;
`
const Buttons = styled.div`
  display: flex;
  justify-content: center;
`

export interface OrganismsViewProps {
  title: string
  type?: OrganismType
  // Add Button
  addOrganismButtonTitle: string
  addOrganismButtonSuccessTextFormat: string
  // Edit Button
  editOrganismButtonTitleFormat: string
  editOrganismButtonSuccessTextFormat: string
  // Remove Button
  removeOrganismButtonConfirmTextFormat: string // {{title}} will be replaced by organism title
  removeOrganismButtonOrganismRemovedTextFormat: string // {{title}} will be replaced by organism title
}
const OrganismsViewBase = ({
  title,
  type,
  addOrganismButtonTitle,
  addOrganismButtonSuccessTextFormat,
  editOrganismButtonTitleFormat,
  editOrganismButtonSuccessTextFormat,
  removeOrganismButtonConfirmTextFormat,
  removeOrganismButtonOrganismRemovedTextFormat,
}: OrganismsViewProps) => {
  const currentUser = useCurrentUser()
  const [search, setSearch] = useState<string | null>()

  const { organisms, error, loading, refetch } = useAllowedOrganisms({
    variables: { filters: { type, search } },
    fetchPolicy: 'cache-and-network',
  })
  const intl = useIntl()

  const [selectedVideoUrl, setSelectedVideoUrl] = useState<string>('')
  const [playerOpen, setPlayerOpen] = useState(false)

  const openVideoPlayer = useCallback((url: string) => {
    setSelectedVideoUrl(url)
    setPlayerOpen(true)
  }, [])

  useEffect(() => {
    if (error) {
      notifyError(`Erreur lors de la récupération des organismes ${type ? `(${type})` : ''}`, error)
    }
  }, [error, type])
  const [visibleSVEChannelsOrganismId, setVisibleSVEChannelsOrganismId] = useState<Organism['id']>()
  const [organismIdForStreams, setOrganismIdForStreams] = useState<string | undefined>()
  const OrganismWithStreamsAndSveChannelsQuery = useOrganismWithStreamsAndSveChannelsQuery({
    variables: { id: organismIdForStreams ?? '' },
    skip: !organismIdForStreams,
  })
  const organismStreams = OrganismWithStreamsAndSveChannelsQuery.data?.organism?.streams
  const loadingStreams = OrganismWithStreamsAndSveChannelsQuery.loading

  const isModalStreamsOpen = useMemo<boolean>(
    () => !!organismIdForStreams && !loadingStreams,
    [organismIdForStreams, loadingStreams],
  )
  const openModalStreams = useCallback((id: string) => {
    setOrganismIdForStreams(id)
  }, [])
  const closeModalStreams = useCallback(() => {
    setOrganismIdForStreams(undefined)
  }, [])

  const handleOnSearchChange = useMemo(
    () => _.debounce((e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value), 300),
    [],
  )

  return (
    <Loader loading={loading || loadingStreams}>
      <Grid padded>
        <Grid.Column>
          <PageHeader title={title}>
            <Input
              icon="search"
              loading={loading}
              placeholder={intl.formatMessage({
                id: 'users.search',
              })}
              onChange={handleOnSearchChange}
            />
            <RefreshButton onClick={() => refetch()} loading={loading} />
            {currentUser.can(Permission.OrganismCreate) && (
              <AddOrganismButton
                type={type}
                title={addOrganismButtonTitle}
                successTextFormat={addOrganismButtonSuccessTextFormat}
                onDone={refetch}
                render={onClick => <Button circular icon="add" color="red" onClick={onClick} />}
              />
            )}
          </PageHeader>

          <Table style={{ flex: 1 }} className="organisms-table">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={1} textAlign="center">
                  <FormattedMessage id="organisms.logo" />
                </Table.HeaderCell>
                <Table.HeaderCell width={2} textAlign="center">
                  <FormattedMessage id="organisms.type" />
                </Table.HeaderCell>
                <Table.HeaderCell width={2} textAlign="left">
                  <FormattedMessage id="organisms.name" />
                </Table.HeaderCell>
                <Table.HeaderCell width={2} textAlign="left">
                  <FormattedMessage id="organism.publicationStatus" />
                </Table.HeaderCell>
                <Table.HeaderCell width={1} textAlign="center">
                  <FormattedMessage id="organisms.website" />
                </Table.HeaderCell>
                <Table.HeaderCell width={2} textAlign="center">
                  <FormattedMessage id="organisms.images" />
                </Table.HeaderCell>
                <Table.HeaderCell width={1} textAlign="center">
                  <FormattedMessage id="organisms.teaser" />
                </Table.HeaderCell>
                <Table.HeaderCell width={1} textAlign="center">
                  <FormattedMessage id="organisms.wmstreams" />
                </Table.HeaderCell>
                <Table.HeaderCell width={1} textAlign="center">
                  <FormattedMessage id="organisms.svestreams" />
                </Table.HeaderCell>
                <Table.HeaderCell width={2} textAlign="center">
                  <FormattedMessage id="organisms.actions" />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {_.map(
                organisms,
                ({
                  id,
                  type: organismType,
                  logo,
                  title: organismTitle,
                  publicationStatus,
                  officialWebSiteUrl,
                  bannerVideo,
                  bannerImage,
                  streamsIds,
                  sveChannelIds,
                }) => {
                  const logoThumbUrl = logoUrlForLightBackground(logo)
                  return (
                    <Table.Row key={id}>
                      <Table.Cell textAlign="center">
                        {logoThumbUrl && <ThumbnailImage src={logoThumbUrl} alt="Logo" />}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        <FormattedMessage id={`organism.${organismType}`} />
                      </Table.Cell>
                      <Table.Cell>{organismTitle}</Table.Cell>
                      <Table.Cell>
                        {publicationStatus && <FormattedMessage id={`publicationStatus.${publicationStatus}`} />}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {officialWebSiteUrl && (
                          <Popup
                            flowing
                            position="top center"
                            trigger={
                              <a href={officialWebSiteUrl} target="_blank" rel="noopener noreferrer">
                                <Icon name="linkify" inverted color="black" />
                              </a>
                            }
                          >
                            {officialWebSiteUrl}
                          </Popup>
                        )}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {bannerImage && bannerImage.image && (
                          <ImageFilePreviewButton title="Image Banner" imageFile={bannerImage.image} />
                        )}
                        {logo && logo.coloredImage && logo.coloredImage.image && (
                          <ImageFilePreviewButton title="Logo" imageFile={logo.coloredImage.image} />
                        )}
                        {logo && logo.transparentLightImage && logo.transparentLightImage.image && (
                          <ImageFilePreviewButton title="Logo clair" imageFile={logo.transparentLightImage.image} />
                        )}
                        {logo && logo.transparentDarkImage && logo.transparentDarkImage.image && (
                          <ImageFilePreviewButton title="Logo sombre" imageFile={logo.transparentDarkImage.image} />
                        )}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {bannerVideo && bannerVideo.videoClip && (
                          <InvisibleButton
                            onClick={() =>
                              bannerVideo && bannerVideo.videoClip && openVideoPlayer(bannerVideo.videoClip.playbackUrl)
                            }
                          >
                            <Icon name="play" inverted color="black" />
                          </InvisibleButton>
                        )}
                      </Table.Cell>

                      <Table.Cell textAlign="center">
                        {streamsIds && streamsIds.length > 0 ? (
                          <Button onClick={() => openModalStreams(id)} size="tiny" circular>
                            <FormattedMessage id="organisms.streams_btn" />
                          </Button>
                        ) : (
                          <FormattedMessage id="organisms.no_streams" />
                        )}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {sveChannelIds && sveChannelIds.length > 0 ? (
                          <Button onClick={() => setVisibleSVEChannelsOrganismId(id)} size="tiny" circular>
                            <FormattedMessage id="organisms.sveChannels_btn" />
                          </Button>
                        ) : (
                          <FormattedMessage id="organisms.noSVEChannels" />
                        )}
                      </Table.Cell>

                      <Table.Cell textAlign="center">
                        <Buttons>
                          {currentUser.can(Permission.OrganismUpdate, id, true) && (
                            <EditOrganismButton
                              organismId={id}
                              titleFormat={editOrganismButtonTitleFormat}
                              successTextFormat={editOrganismButtonSuccessTextFormat}
                              editType={!type}
                              render={onClick => (
                                <Icon name="edit" link size="large" inverted color="black" onClick={onClick} />
                              )}
                            />
                          )}
                          {currentUser.can(Permission.OrganismDelete, id, true) && (
                            <RemoveOrganismButton
                              organismId={id}
                              confirmText={removeOrganismButtonConfirmTextFormat.replace('{{title}}', organismTitle)}
                              successText={removeOrganismButtonOrganismRemovedTextFormat.replace(
                                '{{title}}',
                                organismTitle,
                              )}
                              onDone={() => refetch()}
                            />
                          )}
                        </Buttons>
                      </Table.Cell>
                    </Table.Row>
                  )
                },
              )}
            </Table.Body>
          </Table>
          {organismStreams && (
            <StreamDetailsModal streams={organismStreams} open={isModalStreamsOpen} onClose={closeModalStreams} />
          )}
          {visibleSVEChannelsOrganismId && (
            <SVEChannelDetailsModal
              open
              onClose={() => setVisibleSVEChannelsOrganismId(undefined)}
              organismId={visibleSVEChannelsOrganismId}
            />
          )}
        </Grid.Column>
      </Grid>

      <VideoPlayerModal open={playerOpen} onClose={() => setPlayerOpen(false)} url={selectedVideoUrl} />
    </Loader>
  )
}

export default OrganismsViewBase
