import { useGetLegalRestrictions as _useGetLegalRestrictions } from 'models'

const dateFromTime = (time: string): Date => {
  const date = new Date()
  const [hours, minutes] = time.split(':')
  date.setUTCHours(Number(hours))
  date.setUTCMinutes(Number(minutes))
  date.setSeconds(0)
  return date
}

export const useGetLegalRestrictions = () => {
  const { legalRestrictions, loading, refetch } = _useGetLegalRestrictions()

  return {
    legalRestrictions:
      legalRestrictions?.map(restriction => ({
        ...restriction,
        timeSlots: restriction.timeSlots && {
          ...restriction.timeSlots,
          from: dateFromTime(restriction.timeSlots.from),
          to: dateFromTime(restriction.timeSlots.to),
        },
      })) ?? [],
    loading,
    refetch,
  }
}
