import GenericForm from 'Components/Form/GenericForm'
import SAFormField from 'Components/Form/SAFormField'
import SelectLocalInput, { SelectedTranslationsType, TranslatedInput } from 'Components/Form/SelectLocalInput'
import SwitchInput from 'Components/Form/SwitchInput'
import FileInput from 'Containers/Form/FileInput'
import SelectCategoryInput from 'Containers/Form/SelectCategoryInput'
import SelectOrganismsInput from 'Containers/Form/SelectOrganismsInput'
import SelectPublicationStatusInput from 'Containers/Form/SelectPublicationStatusInput'
import SelectCountryInput from 'Forms/SelectCountryInput'
import { ValidationErrors } from 'final-form'
import React, { useCallback, useState } from 'react'
import { FieldRenderProps } from 'react-final-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { Form, TextArea } from 'semantic-ui-react'
import { Category, Link, LinkTag, Media, MediaType, PublicationStatus } from 'services/api/graphql'
import { useStore } from 'stores'
import { validateRequired } from 'tools/formValidators'
import { getSelectedTranslations, validateTranslation } from 'tools/translationTools'

export type LinkFormValues = {
  id?: string // must be set when editing
  ownerOrganismIds: string[]
  tag?: LinkTag
  title: string
  titleT?: TranslatedInput | null
  subtitle?: string
  subtitleT?: TranslatedInput | null
  description?: string
  descriptionT?: TranslatedInput | null
  countries: string[] | []
  teaserVideoId?: Media['id'] | null
  billboardId?: Media['id'] | null
  defaultLocale?: string
  url?: string
  imageId: Media['id']
  categoriesIds: Array<Category['id']> | null
  publicationStatus: PublicationStatus
  gamTagUrl?: string
}

interface LinkFormProps {
  title: string
  edit: boolean // if true => Update Sport otherwise Add Sport
  initialValues?: LinkFormValues
  onSubmit: (
    values: LinkFormValues,
    defaultLocale: string,
    selectedTranslations: SelectedTranslationsType[],
  ) => Promise<void> | void
  onCancel?: () => void
  loading: boolean
}
const LinkForm = ({ initialValues, onSubmit, onCancel, loading, title, edit }: LinkFormProps) => {
  const intl = useIntl()
  const {
    organismId,
    currentUser: { isAdmin },
  } = useStore()
  const [selectedTranslations, setSelectedTranslations] = useState<SelectedTranslationsType[]>(
    initialValues ? getSelectedTranslations(initialValues) : [],
  )
  const [defaultLocale, setDefaultLocale] = useState<string>(initialValues?.defaultLocale || '')
  const [activeTranslation, setActiveTranslation] = useState<string | undefined>(defaultLocale)
  const [linkTag, setLinkTag] = useState<LinkTag | undefined>(initialValues?.tag)

  const tagOptions = [
    {
      key: 'link',
      value: undefined,
      text: intl.formatMessage({ id: 'links.link' }),
    },
    {
      key: 'sponsoring',
      value: LinkTag.Sponsoring,
      text: intl.formatMessage({ id: 'link.tag.sponsoring' }),
    },
    {
      key: 'google',
      value: LinkTag.Google,
      text: intl.formatMessage({ id: 'links.google' }),
    },
  ]

  const validate = useCallback(
    (values): ValidationErrors | Promise<ValidationErrors> | undefined => ({
      titleT: validateTranslation(activeTranslation, values?.titleT?.[`${defaultLocale}`], intl),
      subtitleT: validateTranslation(activeTranslation, values?.subtitleT?.[`${defaultLocale}`], intl),
      descriptionT: validateTranslation(activeTranslation, values?.descriptionT?.[`${defaultLocale}`], intl),
    }),
    [activeTranslation, defaultLocale, intl],
  )

  const validateGoogleLinkTag = useCallback(
    (values): ValidationErrors | Promise<ValidationErrors> | undefined => ({
      titleT: validateTranslation(activeTranslation, values?.titleT?.[`${defaultLocale}`], intl),
    }),
    [activeTranslation, defaultLocale, intl],
  )

  const handleSubmit = useCallback(
    values =>
      onSubmit(
        {
          tag: linkTag,
          ownerOrganismIds: [organismId] || values.ownerOrganismIds,
          ...values,
        },
        defaultLocale,
        selectedTranslations,
      ),
    [defaultLocale, linkTag, onSubmit, organismId, selectedTranslations],
  )

  return (
    <GenericForm
      header={title}
      initialValues={initialValues}
      onCancel={onCancel}
      onSubmit={handleSubmit}
      validate={linkTag === LinkTag.Google ? validateGoogleLinkTag : validate}
      loading={loading}
    >
      {!edit && (
        <SAFormField
          name="tag"
          render={({ input }) => (
            <Form.Field
              control={() => <SwitchInput value={linkTag} onChange={setLinkTag} options={tagOptions} />}
              {...input}
              label={intl.formatMessage({ id: 'link.tag' })}
            />
          )}
        />
      )}
      <SAFormField
        name="locale"
        render={({ input }) => (
          <Form.Field
            {...input}
            label={intl.formatMessage({ id: 'locale' })}
            control={() => (
              <SelectLocalInput
                defaultLocale={defaultLocale}
                onChangeDefaultLocale={setDefaultLocale}
                activeTranslation={activeTranslation}
                onChangeActiveTranslation={setActiveTranslation}
                selectedTranslations={selectedTranslations}
                onChangeSelectedTranslations={setSelectedTranslations}
              />
            )}
          />
        )}
      />
      {isAdmin && !organismId && (
        <SAFormField
          name="ownerOrganismIds"
          multiple
          validate={validateRequired}
          defaultValue={organismId ? [organismId] : undefined}
          render={({ input, meta }: FieldRenderProps<Link['ownerOrganismIds'], HTMLInputElement>) => (
            <Form.Field
              control={SelectOrganismsInput}
              {...input}
              required
              error={!!meta.touched && !!meta.error}
              label={intl.formatMessage({ id: 'ownerOrganismIds' })}
            />
          )}
        />
      )}
      {linkTag === LinkTag.Google && (
        <>
          <SAFormField
            name="gamTagUrl"
            render={({ input, meta }) => (
              <Form.Input
                {...input}
                required
                error={!!meta.touched && !!meta.error}
                label={intl.formatMessage({ id: 'link.gamtagUrl' })}
              />
            )}
          />
          <SAFormField
            name="countries"
            render={({ input }) => (
              <Form.Field>
                <label>
                  <FormattedMessage id="legalRestriction.countries" />
                </label>
                <SelectCountryInput {...input} multiple={true} noNullValue={true} value={input.value as string[]} />
              </Form.Field>
            )}
          />
        </>
      )}
      <SAFormField
        name={`titleT.${activeTranslation}`}
        render={({ input, meta }) => (
          <Form.Input
            {...input}
            required={activeTranslation === defaultLocale}
            error={!!meta.touched && !!meta.error}
            label={intl.formatMessage({ id: 'link.title' })}
          />
        )}
      />
      {linkTag !== LinkTag.Google && (
        <SAFormField
          name={`subtitleT.${activeTranslation}`}
          validate={validateRequired}
          render={({ input, meta }) => (
            <Form.Input
              {...input}
              required={activeTranslation === defaultLocale}
              error={!!meta.touched && !!meta.error}
              label={intl.formatMessage({ id: 'subtitle' })}
              placeholder={intl.formatMessage({
                id: 'link.subtitle.placeholder',
              })}
            />
          )}
        />
      )}
      {linkTag !== LinkTag.Google && (
        <SAFormField
          name={`descriptionT.${activeTranslation}`}
          validate={validateRequired}
          render={({ input, meta }) => (
            <Form.Input
              {...input}
              required={activeTranslation === defaultLocale}
              control={TextArea}
              maxLength={500}
              error={!!meta.touched && !!meta.error}
              label={intl.formatMessage({ id: 'description' })}
              placeholder={intl.formatMessage({
                id: 'link.description.placeholder',
              })}
            />
          )}
        />
      )}
      {linkTag !== LinkTag.Google && (
        <SAFormField
          name="url"
          required
          validate={validateRequired}
          render={({ input, meta }) => (
            <Form.Input
              {...input}
              required
              error={!!meta.touched && !!meta.error}
              label={intl.formatMessage({ id: 'links.url' })}
            />
          )}
        />
      )}
      <SAFormField
        name="publicationStatus"
        validate={validateRequired}
        render={({ input, meta }) => (
          <Form.Field
            control={SelectPublicationStatusInput}
            {...input}
            required
            error={!!meta.touched && !!meta.error}
            label={intl.formatMessage({ id: 'links.publicationStatus' })}
          />
        )}
      />
      <SAFormField
        name="imageId"
        validate={validateRequired}
        render={({ input, meta }) => (
          <Form.Field
            control={FileInput}
            {...input}
            type={MediaType.Image}
            required
            error={!!meta.touched && !!meta.error}
            label={intl.formatMessage({ id: 'links.image' })}
            mimetype="image"
            imageConstraints={{
              aspect: 3 / 2,
              minWidth: 225,
              minHeight: 150,
              maxSize: 3000000,
            }}
            renderOrganismPicker
          />
        )}
      />
      {linkTag !== LinkTag.Google && (
        <SAFormField
          name="teaserVideoId"
          render={({ input, meta }) => (
            <Form.Field
              control={FileInput}
              {...input}
              type={MediaType.VideoClip}
              error={!!meta.touched && !!meta.error}
              label={intl.formatMessage({ id: 'links.teaserVideo' })}
              description={intl.formatMessage({
                id: 'link.teaserVideoId.description',
              })}
              renderOrganismPicker
            />
          )}
        />
      )}
      {linkTag !== LinkTag.Google && (
        <SAFormField
          name="billboardId"
          render={({ input, meta }) => (
            <Form.Field
              control={FileInput}
              {...input}
              type={MediaType.VideoClip}
              error={!!meta.touched && !!meta.error}
              label={intl.formatMessage({ id: 'links.billboard' })}
              description={intl.formatMessage({
                id: 'link.billboardsId.description',
              })}
              renderOrganismPicker
            />
          )}
        />
      )}
      {isAdmin && (
        <SAFormField
          name="categoriesIds"
          render={({ input, meta }: FieldRenderProps<string[], HTMLElement>) => (
            <Form.Field error={!!meta.touched && !!meta.error}>
              <label>
                <FormattedMessage id="links.categories" />
              </label>
              <SelectCategoryInput multiple {...input} />
            </Form.Field>
          )}
        />
      )}
    </GenericForm>
  )
}

export default LinkForm
