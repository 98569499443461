import classnames from 'classnames'
import { getMediaThumbail, Media, MediaType, Permission, useTogglePin } from 'models'
import React, { useCallback, useState } from 'react'
import { Button, Icon, Image } from 'semantic-ui-react'
import { useCurrentUser } from 'stores'
import styled from 'styled-components'
import { doesImageFileRespectConstraints } from 'tools/imageFile'
import { ImageConstraints } from 'types/image'

import ElementIntersection from './ElementIntersection'

const MediaCardWrapper = styled.div`
  padding: 8px;
  text-align: center;
  width: 150px;
`
const MediaCardImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 150px;

  &:hover button {
    opacity: 1;
  }
`

const MediaCardImage = styled(Image)`
  height: 150px;
`
const MediaCardSubtitle = styled.span`
  font-size: 1.3rem;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
`
const VideoOverlay = styled.img`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 150px;
`

interface MediaCardProps {
  media: Media
  selectedMediaId?: Media['id']
  imageConstraints?: ImageConstraints
  onMediaClick?: (media: Media) => void
}

const MediaCard = ({ media, selectedMediaId, imageConstraints, onMediaClick }: MediaCardProps) => {
  const { can } = useCurrentUser()

  const [isLoaded, setIsLoaded] = useState(false)
  const [pinned, setPinned] = useState(media.pinned)

  const [togglePin, { loading: loadingPin }] = useTogglePin()

  const clickPin = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation()
      await togglePin({
        variables: { id: media.id },
      })
      setPinned(pinned === undefined ? true : !pinned)
    },
    [media.id, pinned, togglePin],
  )

  const itRespectsConstraints = useCallback(
    () => (media.image && imageConstraints ? doesImageFileRespectConstraints(media.image, imageConstraints) : false),
    [imageConstraints, media.image],
  )

  const isSelected = useCallback(() => selectedMediaId && media.id === selectedMediaId, [media.id, selectedMediaId])

  return (
    <MediaCardWrapper onClick={() => onMediaClick?.(media)}>
      <MediaCardImageWrapper>
        <ElementIntersection threshold={0.15} rootMargin="100px">
          <MediaCardImage
            selected={selectedMediaId && media.id === selectedMediaId}
            className={classnames('w-full text-center mb-2 rounded-sm transition-all ease-in-out duration-500 filter', {
              'shadow-lg rounded-lg': isSelected(),
              'blur-sm': !isLoaded,
            })}
            src={getMediaThumbail(media)}
            onLoad={() => setIsLoaded(true)}
          />

          {media.type === MediaType.VideoClip && <VideoOverlay src="/assets/play.png" />}

          {itRespectsConstraints() && (
            <div className="absolute left-0 top-0 opacity-80 bg-green-500 rounded-br-sm">
              <Icon name="checkmark" className="px-1" inverted corner fitted />
            </div>
          )}

          {can(Permission.MediaPin) && (
            <div className="absolute right-4 top-4">
              <Button
                className={classnames('opacity-0', { 'opacity-100': pinned })}
                compact
                circular
                primary={!!pinned}
                icon="pin"
                loading={loadingPin}
                onClick={clickPin}
              ></Button>
            </div>
          )}
        </ElementIntersection>
      </MediaCardImageWrapper>

      <MediaCardSubtitle>{media.title}</MediaCardSubtitle>
    </MediaCardWrapper>
  )
}

export default MediaCard
