import classnames from 'classnames'
import { addSeconds } from 'date-fns'
import React, { useEffect } from 'react'
import { useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDateCooldown } from 'tools/hooks/useDateCooldown'
import { useInterval } from 'tools/hooks/useInterval'

export interface CooldownProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  endDate: Date
  warningDurationSeconds: number
  whenOver?: () => void
}

export const Cooldown = ({ endDate, warningDurationSeconds, whenOver, className, ...props }: CooldownProps) => {
  const [keyState, forceUpdate] = useState(0)
  const [over, setOver] = useState(false)
  const warningDate = useMemo(() => addSeconds(endDate, -warningDurationSeconds), [endDate, warningDurationSeconds])

  useEffect(() => setOver(false), [endDate])

  useInterval(() => {
    forceUpdate(keyState => keyState + 1)
  }, 1000)

  const count = useDateCooldown(endDate, keyState)

  if (!over && count.over) {
    setOver(true)
    setTimeout(() => whenOver?.())
  }

  const highlightWarning = warningDate && new Date() > warningDate

  return (
    <div className={className} {...props}>
      {count.days !== '00' && (
        <span className={classnames('mr-1', { 'text-red-500': highlightWarning })}>
          <FormattedMessage id="monitoringDashboard.days" values={{ days: Number(count.days) }} />
        </span>
      )}
      <span className={classnames('hours', { 'text-red-500': highlightWarning })}>{count.hours}:</span>
      <span className={classnames('minutes', { 'text-red-500': highlightWarning })}>{count.minutes}:</span>
      <span className={classnames('seconds', { 'text-red-500': highlightWarning })}>{count.seconds}</span>
    </div>
  )
}
