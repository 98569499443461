import { CreateSVEChannelForm } from 'Forms/SVEChannel/CreateSVEChannelForm'
import React, { Fragment, useState } from 'react'
import { useIntl } from 'react-intl'
import { notifyError, notifySuccess } from 'tools/toaster'

interface AddSVEChannelButtonProps {
  onDone: () => void
  render: (onClick: () => void) => React.ReactNode
}
const AddSVEChannelButton = ({ onDone, render }: AddSVEChannelButtonProps) => {
  const intl = useIntl()
  const [form, setForm] = useState<React.ReactNode>()
  const handleClick = () => {
    setForm(
      <CreateSVEChannelForm
        onCancel={() => setForm(null)}
        onDone={() => {
          setForm(null)
          notifySuccess(
            intl.formatMessage({
              id: 'sveChannels.add_success',
            }),
          )
          onDone()
        }}
        onError={notifyError}
      />,
    )
  }
  return (
    <Fragment>
      {render(handleClick)}
      {form}
    </Fragment>
  )
}

export default AddSVEChannelButton
