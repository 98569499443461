// Taken from module react-final-form
//   https://github.com/final-form/react-final-form/blob/master/src/renderComponent.js
import React from 'react'
import { RenderableProps } from 'react-final-form'

// shared logic between components that use either render prop,
// children render function, or component prop
export default function renderComponent<T>({ children, ...props }: RenderableProps<T> & T): React.ReactNode {
  if (typeof children === 'function') {
    return children(props as any)
  }
  return children
}
