import { useGenerateMediaReplay } from 'models'
import React, { useCallback, useEffect, useState } from 'react'

import { useIntl } from 'react-intl'
import { MediaLightContentFragment, VideoContentType } from 'services/api/graphql'

import { notifySuccess } from 'tools/toaster'

import EditVideoView from './EditVideoView'
import EditTimelineMarkers from './TimelineMarkers/EditTimelineMarkers'
import EditTrimVideo from './TrimVideo/EditTrimVideo'

interface EditVideoProps {
  mediaToUpdate: MediaLightContentFragment
  currentTime: number
  duration: number
  showEditTrim?: boolean
  showEditMarkers?: boolean
  isLive?: boolean
  onRefresh?: () => void
  videoContentType?: VideoContentType
}

const EditVideo: React.FC<EditVideoProps> = ({
  mediaToUpdate,
  currentTime,
  duration,
  showEditTrim,
  showEditMarkers,
  isLive,
  onRefresh,
  videoContentType,
}) => {
  const intl = useIntl()

  const [currentView, setCurrentView] = useState<string | undefined>()
  const [validFormat, setValidFormat] = useState<boolean>(true)
  const [startTrim, setStartTrim] = useState<number>(0)
  const [endTrim, setEndTrim] = useState<number>(0)

  const [generateMediaReplay, { loading: generateMediaReplayLoading }] = useGenerateMediaReplay()

  useEffect(() => {
    if (duration && !mediaToUpdate.endTrim) setEndTrim(duration)
    if (mediaToUpdate.startTrim) setStartTrim(mediaToUpdate.startTrim)
    if (mediaToUpdate.endTrim) setEndTrim(mediaToUpdate.endTrim)
  }, [duration, mediaToUpdate.endTrim, mediaToUpdate.startTrim])

  const handleOnGenerateReplay = useCallback(async () => {
    await generateMediaReplay({
      variables: {
        id: mediaToUpdate.id,
        input: {
          startTrim,
          endTrim,
        },
      },
      onCompleted: () => {
        notifySuccess(intl.formatMessage({ id: 'videoClip.generateReplaySucceeded' }))
      },
    })
  }, [endTrim, generateMediaReplay, intl, mediaToUpdate, startTrim])

  return (
    <div className="mt-4">
      {currentView === undefined && (
        <EditVideoView
          startTrim={startTrim}
          endTrim={endTrim}
          duration={duration}
          showEditTrim={showEditTrim}
          showEditMarkers={showEditMarkers}
          isLive={isLive}
          isReplay={videoContentType === VideoContentType.Replay}
          onSetCurrentView={setCurrentView}
          onGenerateReplay={handleOnGenerateReplay}
          generateMediaReplayLoading={generateMediaReplayLoading}
        />
      )}
      {currentView === 'editTrimVideo' && (
        <EditTrimVideo
          onClose={() => {
            setCurrentView(undefined)
            onRefresh?.()
          }}
          mediaToUpdate={mediaToUpdate}
          currentTime={currentTime}
          duration={duration}
          startTrim={startTrim}
          onUpdateStartTrim={setStartTrim}
          endTrim={endTrim}
          onUpdateEndTrim={setEndTrim}
          validFormat={validFormat}
          onValidFormat={setValidFormat}
        />
      )}

      {currentView === 'editTimelineMarkers' && (
        <EditTimelineMarkers
          onClose={() => {
            setCurrentView(undefined)
            onRefresh?.()
          }}
          mediaToUpdate={mediaToUpdate}
          duration={duration}
          currentTime={currentTime}
          validFormat={validFormat}
          onValidFormat={setValidFormat}
        />
      )}
    </div>
  )
}
export default EditVideo
