import PageHeader from 'Components/PageHeader'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import { FormattedMessage, IntlShape, useIntl } from 'react-intl'
import { Button, Dropdown, DropdownItemProps, DropdownProps, Grid, Loader, Table } from 'semantic-ui-react'
import { useStore } from 'stores'

import ChatRoomRow from './Row'
import { useChatRooms } from './useChatRooms'

const activeFilterOptions = (intl: IntlShape): DropdownItemProps[] => [
  { text: intl.formatMessage({ id: 'common.all' }), value: undefined, key: 'all' },
  { text: intl.formatMessage({ id: 'rooms.active' }), value: true, key: 'active' },
  { text: intl.formatMessage({ id: 'rooms.inactive' }), value: false, key: 'inactive' },
]

const ChatRoomsView = () => {
  const [activeFilter, setActiveFilter] = useState<boolean>()
  const intl = useIntl()
  const { organismId } = useStore()
  const { data, fetchMore, reachedBottom } = useChatRooms(organismId || undefined, activeFilter)

  const handleChangeActiveFilter = (_e: React.SyntheticEvent<HTMLElement, Event>, { value }: DropdownProps) => {
    if (typeof value !== 'boolean') {
      setActiveFilter(undefined)
      return
    }
    setActiveFilter(value)
  }

  if (data.loading) {
    return <Loader active />
  }

  return (
    <Grid padded>
      <Grid.Column>
        <PageHeader title={intl.formatMessage({ id: 'chatRooms' })} />
        <div className="flex w-full my-3 justify-end">
          <Dropdown
            selection
            clearable
            placeholder={intl.formatMessage({ id: 'common.all' })}
            options={activeFilterOptions(intl)}
            onChange={handleChangeActiveFilter}
            value={activeFilter}
          />
        </div>
        <Table style={{ flex: 1 }}>
          <Table.Header>
            <Table.Row textAlign="center">
              <Table.HeaderCell width={1}>
                <FormattedMessage id="name" />
              </Table.HeaderCell>
              <Table.HeaderCell width={1}>
                <FormattedMessage id="chatRooms.messageCount" />
              </Table.HeaderCell>
              <Table.HeaderCell width={2}>
                <FormattedMessage id="chatRooms.active" />
              </Table.HeaderCell>
              <Table.HeaderCell width={1}>
                <FormattedMessage id="actions" />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data?.chatRooms.map(chatRoom => (
              <ChatRoomRow key={chatRoom.id} chatRoom={chatRoom} />
            ))}
          </Table.Body>
        </Table>
        {!reachedBottom && <Button onClick={fetchMore}>{intl.formatMessage({ id: 'fetchMore' })}</Button>}
      </Grid.Column>
    </Grid>
  )
}

export default observer(ChatRoomsView)
