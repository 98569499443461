import { gql } from '@apollo/client'

export const VIDEOCLIP_FRAGMENT = gql`
  fragment videoClipContent on VideoClip {
    id
    playbackUrl
    downloadLink
    status
    updatedAt
    createdAt
    fileId
    error {
      guid
      workflowStatus
      workflowErrorAt
      errorMessage
      errorDetails
    }
    captions {
      srcFileId
      language
      title
    }
    externalFeed {
      externalFeedProvider
      externalFeedUrl
    }
    createdAt
    updatedAt
  }
`

export const GET_VIDEOCLIP_QUERY = gql`
  query videoClip($id: ID!) {
    videoClip(id: $id) {
      ...videoClipContent
    }
  }
  ${VIDEOCLIP_FRAGMENT}
`

export const CREATE_MULTIPART_QUERY = gql`
  query createMultipart($hash: String!, $fileName: String, $extension: String) {
    createMultipart(hash: $hash, fileName: $fileName, extension: $extension) {
      uploadId
      key
      currentPart
    }
  }
`
export const UPLOAD_PART_MUTATION = gql`
  mutation uploadPart($input: UploadPartInput!) {
    uploadPart(input: $input)
  }
`

export const COMPLETE_MULTIPART_MUTATION = gql`
  mutation completeMultipart($input: CompleteMultipartInput!) {
    completeMultipart(input: $input)
  }
`
export const GET_SIGNED_URL_QUERY = gql`
  query signedUrl($sportItemId: ID, $fileName: String, $extension: String) {
    signedUrl(sportItemId: $sportItemId, fileName: $fileName, extension: $extension) {
      url
      key
    }
  }
`
