import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link'
import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
  $isListNode,
  ListNode,
} from '@lexical/list'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $createQuoteNode, $isHeadingNode } from '@lexical/rich-text'
import { $isAtNodeEnd, $wrapNodes } from '@lexical/selection'
import { $getNearestNodeOfType, mergeRegister } from '@lexical/utils'
import {
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  REDO_COMMAND,
  UNDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  FORMAT_TEXT_COMMAND,
  FORMAT_ELEMENT_COMMAND,
  $getSelection,
  $isElementNode,
  $isRangeSelection,
  RangeSelection,
  $createParagraphNode,
  $isNodeSelection,
  COMMAND_PRIORITY_LOW,
} from 'lexical'
import { FC, useCallback, useEffect } from 'react'

import { useEditorState, useEditorAdapter } from '../../useEditor'

import { $isImageNode, INSERT_IMAGE_COMMAND } from '../ImagePlugin/ImagePlugin'
import { $isTweetNode, INSERT_TWEET_COMMAND } from '../TweetPlugin/TweetPlugin'
import { $isYouTubeNode, INSERT_YOUTUBE_COMMAND } from '../YoutubePlugin/YoutubePlugin'

const getSelectedNode = (selection: RangeSelection) => {
  const anchorNode = selection.anchor.getNode()
  const focusNode = selection.focus.getNode()

  if (anchorNode === focusNode) {
    return anchorNode
  }

  if (selection.isBackward()) {
    return $isAtNodeEnd(selection.focus) ? anchorNode : focusNode
  }

  return $isAtNodeEnd(selection.anchor) ? focusNode : anchorNode
}

const ToolbarPlugin: FC = () => {
  const [editor] = useLexicalComposerContext()
  const [{ blockType }, setState] = useEditorState()

  // update toolbar ui state according to lexical state
  const updateToolbar = useCallback(() => {
    // holds what has been selected in the editor
    const selection = $getSelection()

    // selection is a text/cursor range
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode()
      const element = anchorNode.getKey() === 'root' ? anchorNode : anchorNode.getTopLevelElementOrThrow()
      const elementDOM = editor.getElementByKey(element.getKey())

      if (elementDOM !== null) {
        // update block type state
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode)
          const blockType = parentList ? parentList.getTag() : element.getTag()
          setState({ blockType })
        } else {
          const blockType = $isHeadingNode(element) ? element.getTag() : (element.getType() as any)
          setState({ blockType })
        }

        // update text align state
        if ($isElementNode(element)) {
          const formatType = element.getFormatType()
          setState({
            formatElement: {
              left: { active: !formatType || formatType === 'left', disabled: false },
              center: { active: formatType === 'center', disabled: false },
              right: { active: formatType === 'right', disabled: false },
              justify: { active: formatType === 'justify', disabled: false },
            },
          })
        }
      }

      // update rich text state
      setState({
        formatText: {
          bold: { active: selection.hasFormat('bold'), disabled: false },
          italic: { active: selection.hasFormat('italic'), disabled: false },
          underline: { active: selection.hasFormat('underline'), disabled: false },
          strikethrough: { active: selection.hasFormat('strikethrough'), disabled: false },
          code: { active: selection.hasFormat('code'), disabled: false },
        },
      })

      // update link state
      const node = getSelectedNode(selection)
      const parent = node.getParent()

      if ($isLinkNode(parent)) {
        const currentLinkElement = editor.getElementByKey(node.getKey())
        const currentLinkHref = parent.getURL()
        setState({
          insertLink: true,
          currentLinkElement,
          currentLinkHref,
        })
      } else if ($isLinkNode(node)) {
        const currentLinkElement = editor.getElementByKey(node.getKey())
        const currentLinkHref = node.getURL()
        setState({
          insertLink: true,
          currentLinkElement,
          currentLinkHref,
        })
      } else {
        setState({
          insertLink: false,
          currentLinkElement: null,
          currentLinkHref: undefined,
        })
      }
    }

    // selection is a custom node
    if ($isNodeSelection(selection)) {
      selection.getNodes().forEach(node => {
        // hande custom plugin nodes
        if ($isImageNode(node) || $isTweetNode(node) || $isYouTubeNode(node)) {
          const formatType = node.getFormatType()
          // update text align state
          setState({
            formatElement: {
              left: { active: !formatType || formatType === 'left', disabled: true },
              center: { active: formatType === 'center', disabled: true },
              right: { active: formatType === 'right', disabled: true },
              justify: { active: formatType === 'justify', disabled: true },
            },
          })
        }
      })
    }
  }, [editor, setState])

  // listen to lexical events to consequently update toolbar ui
  useEffect(
    () =>
      mergeRegister(
        editor.registerUpdateListener(({ editorState }) => {
          editorState.read(() => {
            updateToolbar()
          })
        }),
        editor.registerCommand(
          SELECTION_CHANGE_COMMAND,
          () => {
            updateToolbar()
            return false
          },
          COMMAND_PRIORITY_LOW,
        ),
        editor.registerCommand(
          CAN_UNDO_COMMAND,
          canUndo => {
            setState({ undo: { active: false, disabled: !canUndo } })
            return false
          },
          COMMAND_PRIORITY_LOW,
        ),
        editor.registerCommand(
          CAN_REDO_COMMAND,
          canRedo => {
            setState({ redo: { active: false, disabled: !canRedo } })
            return false
          },
          COMMAND_PRIORITY_LOW,
        ),
      ),
    [editor, setState, updateToolbar],
  )

  // handle commands from abstract <Editor>
  useEditorAdapter(
    useCallback(
      (cmd, payload) => {
        switch (cmd) {
          case 'change': {
            return updateToolbar()
          }
          case 'undo': {
            return editor.dispatchCommand(UNDO_COMMAND, void 0)
          }
          case 'redo': {
            return editor.dispatchCommand(REDO_COMMAND, void 0)
          }
          case 'blockType': {
            switch (payload) {
              case 'paragraph': {
                return editor.update(() => {
                  const selection = $getSelection()
                  if ($isRangeSelection(selection)) {
                    $wrapNodes(selection, () => $createParagraphNode())
                  }
                })
              }
              case 'ol': {
                return blockType === 'ol'
                  ? editor.dispatchCommand(REMOVE_LIST_COMMAND, void 0)
                  : editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, void 0)
              }
              case 'ul': {
                return blockType === 'ul'
                  ? editor.dispatchCommand(REMOVE_LIST_COMMAND, void 0)
                  : editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, void 0)
              }
              case 'quote': {
                return editor.update(() => {
                  const selection = $getSelection()
                  if ($isRangeSelection(selection)) {
                    $wrapNodes(selection, () => $createQuoteNode())
                  }
                })
              }
            }
            break
          }
          case 'formatText': {
            return editor.dispatchCommand(FORMAT_TEXT_COMMAND, payload)
          }
          case 'formatElement': {
            return editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, payload)
          }
          case 'insertLink': {
            return editor.dispatchCommand(TOGGLE_LINK_COMMAND, 'https://')
          }
          case 'updateLink': {
            return editor.dispatchCommand(TOGGLE_LINK_COMMAND, payload)
          }
          case 'removeLink': {
            return editor.dispatchCommand(TOGGLE_LINK_COMMAND, null)
          }
          case 'image': {
            return editor.dispatchCommand(INSERT_IMAGE_COMMAND, { src: payload })
          }
          case 'youtube': {
            return editor.dispatchCommand(INSERT_YOUTUBE_COMMAND, payload)
          }
          case 'tweet': {
            return editor.dispatchCommand(INSERT_TWEET_COMMAND, payload)
          }
        }
      },
      [blockType, editor, updateToolbar],
    ),
  )

  return null
}

export default ToolbarPlugin
