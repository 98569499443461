import { useMemo } from 'react'

export const useDateCooldown = (startDate: Date, keyState?: number, hideCounterWhenElapsed?: boolean) =>
  useMemo(() => {
    const now = new Date()
    let delta = (startDate.getTime() - now.getTime()) / 1000

    let days = 0
    let hours = 0
    let minutes = 0
    let seconds = 0

    if (delta > 0) {
      days = Math.floor(delta / 86400)
      delta -= days * 86400

      hours = Math.floor(delta / 3600) % 24
      delta -= hours * 3600

      minutes = Math.floor(delta / 60) % 60
      delta -= minutes * 60

      seconds = Math.floor(delta)
    }

    return {
      days: days.toString().padStart(2, '0'),
      hours: hours.toString().padStart(2, '0'),
      minutes: minutes.toString().padStart(2, '0'),
      seconds: seconds.toString().padStart(2, '0'),
      elapsed: delta <= 0,
      over: days === 0 && hours === 0 && minutes === 0 && seconds === 0,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hideCounterWhenElapsed, startDate, keyState])
