import CancelFormButton from 'Components/Form/CancelFormButton'
import { SelectedTranslationsType } from 'Components/Form/SelectLocalInput'
import { Content } from 'antd/lib/layout/layout'
import { ValidationErrors } from 'final-form'
import arrayMutators from 'final-form-arrays'
import React, { useCallback, useState } from 'react'
import { Form } from 'react-final-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button, Dimmer } from 'semantic-ui-react'
import { EditedCategory, useCreateEditedCategoryMutation, useUpdateEditedCategoryMutation } from 'services/api/graphql'
import { getErrorMessage } from 'tools/graphql'
import { notifyError, notifySuccess } from 'tools/toaster'
import {
  getSelectedTranslations,
  getTranslationInputFromForm,
  getTranslationInputFromValues,
  validateTranslation,
} from 'tools/translationTools'

import EditedCategoryFormFields, { EditedCategoriesFormValues, FieldsType } from './EditedCategoryFormFields'

interface EditedCategoryFormProps {
  isEdit: boolean
  editedCategory?: EditedCategory
  organismId?: string
  onCancel: () => void
  onDone?: () => void
}

const EditedCategoryForm = (props: EditedCategoryFormProps) => {
  const intl = useIntl()
  const { editedCategory, organismId, onCancel, onDone } = props
  const [updateEditedCategory, UpdateEditedCategoryMutationResult] = useUpdateEditedCategoryMutation()
  const [createEditedCategory, CreateEditedCategoryMutationResult] = useCreateEditedCategoryMutation()
  const loading = UpdateEditedCategoryMutationResult.loading || CreateEditedCategoryMutationResult.loading
  const initialValues = editedCategory ? getTranslationInputFromValues<EditedCategory>(editedCategory) : undefined
  const [defaultLocale, setDefaultLocale] = useState<string>(initialValues?.defaultLocale || '')
  const [selectedTranslations, setSelectedTranslations] = useState<SelectedTranslationsType[]>(
    initialValues ? getSelectedTranslations(initialValues) : [],
  )
  const [activeTranslation, setActiveTranslation] = useState<string | undefined>(defaultLocale)
  const [fields, setFields] = useState<FieldsType[]>(initialValues?.items || [])

  const onSubmit = async (values: EditedCategoriesFormValues) => {
    const { id: _id, defaultLocale: _locale, titleT, ...editedCategoryValues } = values

    // Remove undefined/null values from items
    editedCategoryValues.items =
      values.items &&
      values.items
        .map(item => (!item.sportItemId ? { sportItemSetId: item.sportItemSetId } : item))
        .filter(item => !!item.sportItemSetId && !!item.linkId)

    if (props.isEdit && editedCategory) {
      delete editedCategoryValues.organismId

      const titleT = getTranslationInputFromForm(selectedTranslations, values.titleT)

      const result = await updateEditedCategory({
        variables: {
          id: editedCategory.id,
          input: {
            ...editedCategoryValues,
            items: fields,
            titleT,
            title: values.titleT?.[defaultLocale] || '',
          },
        },
      })

      if (result && result.data) {
        notifySuccess(
          intl.formatMessage({
            id: 'editedCategory.update_success',
          }),
        )
        if (onDone) onDone()
      }
    } else if (!props.isEdit) {
      if (organismId) {
        editedCategoryValues.organismId = organismId
      }

      createEditedCategory({
        variables: {
          input: {
            ...editedCategoryValues,
            items: fields,
            titleT: getTranslationInputFromForm(selectedTranslations, titleT),
            title: values.titleT?.[defaultLocale] || '',
          },
        },
      })
        .then(result => {
          if (result && result.data) {
            notifySuccess(
              intl.formatMessage({
                id: 'editedCategory.create_success',
              }),
            )
            if (onDone) onDone()
          }
        })
        .catch(error => {
          notifyError(getErrorMessage(error)?.replace('%{id}', organismId ?? ''))
        })
    }
  }

  const validate = useCallback(
    (values: EditedCategoriesFormValues): ValidationErrors | Promise<ValidationErrors> | undefined => ({
      titleT: validateTranslation(activeTranslation, values?.titleT?.[`${defaultLocale}`], intl),
    }),
    [activeTranslation, defaultLocale, intl],
  )

  return (
    <Form
      onSubmit={onSubmit}
      mutators={{
        ...arrayMutators,
      }}
      validate={validate}
      initialValues={initialValues}
      render={formProps => {
        const { handleSubmit, form, pristine } = formProps
        return (
          <>
            <Content>
              <Dimmer.Dimmable dimmed={loading}>
                <Dimmer active={loading} inverted />
                <EditedCategoryFormFields
                  defaultLocale={defaultLocale}
                  onChangeDefaultLocale={setDefaultLocale}
                  selectedTranslations={selectedTranslations}
                  onChangeSelectedTranslations={setSelectedTranslations}
                  onChangeActiveTranslation={setActiveTranslation}
                  activeTranslation={activeTranslation}
                  fields={fields}
                  onChangeFields={setFields}
                />
              </Dimmer.Dimmable>
            </Content>
            <div className="text-right mt-4">
              <CancelFormButton pristine={pristine} onConfirm={form.reset} onCancel={onCancel} />
              <Button positive loading={loading} onClick={() => handleSubmit()}>
                <FormattedMessage id="common.submit" />
              </Button>
            </div>
          </>
        )
      }}
    />
  )
}

export default EditedCategoryForm
