import SelectOffersInput from 'Containers/Form/SelectedOfferInput'
import { FormApi } from 'final-form'
import { PurchasableOffer } from 'models'

import React, { useCallback, useEffect, useState } from 'react'
import { FieldInputProps } from 'react-final-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button, Form, Icon } from 'semantic-ui-react'

import { LegalRestrictionFormValue } from './LegalRestrictionForm'

interface AddOffersInputProps {
  values: LegalRestrictionFormValue
  form: FormApi<LegalRestrictionFormValue, Partial<LegalRestrictionFormValue>>
  input: FieldInputProps<LegalRestrictionFormValue, HTMLElement>
  initialValues: LegalRestrictionFormValue
}

const AddOffersInput: React.FC<AddOffersInputProps> = ({ input, values, form, initialValues }) => {
  const intl = useIntl()

  const [selectedOffer, setSelectedOffer] = useState<PurchasableOffer | null>()
  const [selectedOffers, setSelectedOffers] = useState<PurchasableOffer[]>([])
  const [organismOffers, setOrganismOffers] = useState<PurchasableOffer[]>()

  useEffect(() => {
    setSelectedOffers([])
  }, [values.organismId])

  useEffect(() => {
    if (organismOffers && initialValues.offerIds) {
      setSelectedOffers(organismOffers.filter(organismOffer => initialValues.offerIds?.includes(organismOffer.id)))
    }
  }, [initialValues.offerIds, organismOffers])

  const onRemoveOffer = useCallback(
    (item: PurchasableOffer) => {
      const tmp = selectedOffers.filter(el => el.id !== item.id)
      setSelectedOffers(tmp)
      form.change(
        'offerIds',
        tmp.map(el => el.id),
      )
    },
    [form, selectedOffers],
  )

  return (
    <Form.Field required>
      <label>
        <FormattedMessage id="legalRestriction.offer" />
      </label>

      <div className="flex flex-row items-center mb-2">
        <SelectOffersInput
          {...input}
          getOffers={setOrganismOffers}
          className="w-3/4 mr-2"
          organismId={values.organismId || ''}
          value={selectedOffer?.id}
          selectedOffers={selectedOffers}
          onChange={value => {
            if (organismOffers && typeof value === 'string') {
              setSelectedOffer(organismOffers.find(e => e.id === value))
            }
          }}
        />
        <Button
          primary
          disabled={!selectedOffer}
          className="h-10"
          onClick={e => {
            e.preventDefault()
            if (selectedOffer) {
              const tmp = [...selectedOffers, selectedOffer]
              setSelectedOffers(tmp)
              setSelectedOffer(null)
              form.change(
                'offerIds',
                tmp.map(el => el.id),
              )
            }
          }}
        >
          {intl.formatMessage({
            id: 'common.add',
          })}
        </Button>
      </div>

      {/* Offers selection*/}
      {selectedOffers.length > 0 && (
        <div className="w-3/4">
          {selectedOffers.map(item => {
            return (
              <div key={item.id} className="flex flex-row justify-between  rounded-md bg-gray-100 my-1 pl-3 pr-2 py-2">
                <span className="py-1">{item.title}</span>
                <div className="flex flex-row items-center justify-between space-x-1">
                  {/* Remove Offer */}
                  <div
                    className="cursor-pointer px-3 rounded-md bg-red-100 flex items-center h-full"
                    onClick={() => onRemoveOffer(item)}
                  >
                    <Icon name="remove" color="red" fitted size="small" className="self-center" />
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      )}
    </Form.Field>
  )
}

export default AddOffersInput
