import { QueryHookOptions, useMutation, useQuery } from '@apollo/client'
import {
  ActiveLiveStreamsResult,
  CHECK_STREAM_UNUSED_QUERY,
  GET_ACTIVE_LIVESTREAMS_IDS_QUERY,
  LiveStream,
  LiveStreamStreamConflict,
  Maybe,
  QueryCheckStreamUnusedArgs,
  UpdateWildmokaLiveStreamInput,
  UPDATE_LIVESTREAM_MUTATION,
} from 'services/api/graphql'

export const useUpdateLiveStream = () =>
  useMutation<{ updateLiveStream: LiveStream }, { input: UpdateWildmokaLiveStreamInput }>(UPDATE_LIVESTREAM_MUTATION)

export const useGetActiveLiveStreamsIds = () =>
  useQuery<{ activeLiveStreams: ActiveLiveStreamsResult }>(GET_ACTIVE_LIVESTREAMS_IDS_QUERY)

export const useCheckStreamUnused = (
  input: QueryCheckStreamUnusedArgs,
  options?: QueryHookOptions<{
    checkStreamUnused: LiveStreamStreamConflict[]
  }>,
) => {
  const { data, ...rest } = useQuery<{
    checkStreamUnused: LiveStreamStreamConflict[]
  }>(CHECK_STREAM_UNUSED_QUERY, {
    variables: {
      ...input,
    },
    ...options,
  })
  return { data: data ? data.checkStreamUnused : undefined, ...rest }
}

/**
 * Find the title of a livestream by browsing the medias of its sportItemSet
 * @param liveStream
 */
export type GetLiveStreamTitleProps = {
  id: string
  sportItem?: Maybe<{
    __typename?: 'SportItem'
    id: string
    title: string
    medias: Array<{ __typename?: 'Media'; id: string; title: string; liveStreamId?: Maybe<string> }>
  }>
}
export const getLiveStreamTitle = (liveStream: GetLiveStreamTitleProps): string => {
  if (liveStream.sportItem && liveStream.sportItem.medias) {
    const liveStreamMedia = liveStream.sportItem.medias.find(media => media.liveStreamId === liveStream.id)
    return (liveStreamMedia && liveStreamMedia.title) || ''
  }
  return ''
}
