import Axios from 'axios'
import Config from 'config'
import { useCallback, useState } from 'react'

export const axiosInstance = Axios.create({
  baseURL: Config.apiUrl,
})

export const setToken = (token: string | null) => {
  if (token) {
    axiosInstance.defaults.headers.authorization = `Bearer ${token}`
  } else {
    delete axiosInstance.defaults.headers.authorization
  }
}

export function useApiCall<T, Args extends any[]>(apiCall: (...args: Args) => Promise<T>) {
  const [error, setError] = useState<Error>()
  const [loading, setLoading] = useState(false)
  const [result, setResult] = useState<T | null>()

  const execute = useCallback(
    async (...args: Args) => {
      setLoading(true)
      setError(undefined)
      setResult(null)

      try {
        const res = await apiCall(...args)
        setResult(res)
      } catch (e) {
        setError(e as Error)
      } finally {
        setLoading(false)
      }
    },
    [apiCall],
  )

  return { execute, loading, error, result }
}
