import { gql } from '@apollo/client'

import { ORGANISM_FRAGMENT } from './Organism'

/**
 * Users
 */

export const LIGHT_USER_FRAGMENT = gql`
  fragment lightUserContent on User {
    id
    email
    firstName
    lastName
    chatAuthorName
    receipts {
      offerId
      offer {
        title
      }
      type
      status
      productId
    }
    organismIds
    organisms {
      id
      title
    }
    roleIds
    rights {
      generalPermissions
      organismsPermissions {
        organismId
        permissions
      }
    }
    birthdayDate
    gender
    location {
      country
      postalCode
    }
    createdAt
    lastActivity
    cguAccepted
    signUpOrigin
    environment
    verified
    applicationOrigin {
      id
      title
    }
    fanPreferences {
      followedOrganisms {
        id
        title
      }
    }
    licenses {
      organismId
      organism {
        id
        title
      }
      isLicensed
      registrationDate
    }
  }
`

export const GET_USERS_QUERY = gql`
  query users($filters: UserFilters, $offset: Int, $first: Int, $sortBy: SortBy) {
    users(filters: $filters, offset: $offset, first: $first, sortBy: $sortBy) {
      users {
        ...lightUserContent
      }
      totalCount
    }
  }
  ${LIGHT_USER_FRAGMENT}
`

export const GET_USER_QUERY = gql`
  query user($userId: ID!) {
    user(id: $userId) {
      id
      email
      firstName
      lastName
      chatAuthorName
      organismIds
      roleIds
      rights {
        generalPermissions
        organismsPermissions {
          organismId
          permissions
        }
      }
    }
  }
`
export const ME_QUERY = gql`
  query me {
    me {
      id
      email
      firstName
      lastName
      organismIds
      roleIds
      avatarUrl
      rights {
        generalPermissions
        organismsPermissions {
          organismId
          permissions
        }
      }
      allPermissions {
        generalPermissions
        organismsPermissions {
          organismId
          permissions
        }
      }
    }
  }
`
export const GET_USER_RGPD_DATA_QUERY = gql`
  query userRGPDData($id: ID!) {
    user(id: $id) {
      id
      RGPDFormattedData
    }
  }
`

export const CREATE_USER_MUTATION = gql`
  mutation createUser($input: CreateUserInput!) {
    createUser(input: $input) {
      id
      email
      firstName
      lastName
    }
  }
`

export const UPDATE_USER_MUTATION = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      firstName
      lastName
      email
      organismIds
      roleIds
      rights {
        generalPermissions
        organismsPermissions {
          organismId
          permissions
        }
      }
    }
  }
`

export const UPLOAD_AVATAR_MUTATION = gql`
  mutation uploadAvatar($file: Upload!) {
    uploadAvatar(file: $file) {
      id
      avatarUrl
    }
  }
`

export const DELETE_USER_MUTATION = gql`
  mutation deleteUser($userId: ID!) {
    deleteUser(id: $userId) {
      id
    }
  }
`

export const MY_ORGANISMS_QUERY = gql`
  query myOrganisms {
    me {
      id
      organisms {
        ...organismContent
      }
    }
  }

  ${ORGANISM_FRAGMENT}
`

export const UPDATE_CACHE_RECEIPT_MUTATION = gql`
  mutation updateCacheReceipt($userId: ID) {
    updateCacheReceipt(userId: $userId)
  }
`

export const ORGANISM_LICENSED_USERS_QUERY = gql`
  query organismLicensedUsers($organismId: ID!, $offset: Int, $first: Int, $sortBy: SortBy) {
    users(filters: { licensedForOrganismId: $organismId }, offset: $offset, first: $first, sortBy: $sortBy) {
      users {
        id
        email
        firstName
        lastName
        gender
        roleIds
        location {
          country
          postalCode
        }
        createdAt
        lastActivity
        cguAccepted
        signUpOrigin
        verified
        applicationOrigin {
          id
          title
        }
      }
      totalCount
    }
  }
`

export const IMPORT_USERS_FROM_CSV_MUTATION = gql`
  mutation importUsersFansFromCsv($file: Upload!, $options: ImportUsersOptions) {
    importUsersFansFromCsv(file: $file, options: $options)
  }
`

export const REVOKE_USER_LICENSE_MUTATION = gql`
  mutation revokeLicense($userId: ID!, $organismId: ID!) {
    revokeUserLicense(userId: $userId, organismId: $organismId) {
      id
      licenses {
        organismId
        isLicensed
      }
    }
  }
`

export const LATEST_USERS_EXPORT_DOWNLOAD_URL = gql`
  query latestUsersExportDownloadUrl($maxDate: Date) {
    latestUsersExportDownloadUrl(maxDate: $maxDate)
  }
`

export const USERS_EXPORT_STATE = gql`
  query usersExportState {
    usersExportState
  }
`

export const SCHEDULE_USERS_EXPORT = gql`
  mutation scheduleUsersExport($input: UsersExportInput!) {
    scheduleUsersExport(input: $input)
  }
`
export const UPDATE_TOKEN_PAYLOAD = gql`
  mutation updateTokenPayload {
    updateTokenPayload
  }
`
