import CopyButton from 'Components/CopyButton'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Icon, List, Modal } from 'semantic-ui-react'
import { LiveStreamContentFragment } from 'services/api/graphql'

export interface LiveStreamSVEDetailsModalProps {
  livestream: LiveStreamContentFragment
  open: boolean
  onClose: () => void
}

export const LiveStreamExternalFeedDetailsModal: React.FC<LiveStreamSVEDetailsModalProps> = ({
  livestream,
  open,
  onClose,
}) => {
  const intl = useIntl()

  return (
    <Modal mountNode={document.getElementById('modals')} open={open} onClose={onClose} closeIcon>
      <Modal.Content>
        <List>
          <List.Item>
            <List.Header>
              <FormattedMessage id="sportItem.externalFeed.provider" />
            </List.Header>
            <List.Description>{livestream?.externalFeedProvider?.toLocaleUpperCase()}</List.Description>
          </List.Item>
          <List.Item>
            <List.Header>
              <FormattedMessage id="sportItem.externalFeed.link" />
              <CopyButton
                value={livestream?.externalFeedUrl || ''}
                notificationSuccess={intl.formatMessage({
                  id: 'sportItem.externalFeed.linkCopied',
                })}
              >
                <Icon name="copy" />
              </CopyButton>
            </List.Header>
            <List.Description>{livestream?.externalFeedUrl}</List.Description>
          </List.Item>
        </List>
      </Modal.Content>
    </Modal>
  )
}
