import Loader from 'Components/Loader'
import { getMediaThumbail, useGetMedia } from 'models'
import React, { useEffect, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { Image } from 'semantic-ui-react'
import { Media, MediaType } from 'services/api/graphql'
import styled from 'styled-components'
import { notifyError } from 'tools/toaster'

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const ImageWrapper = styled(Image)`
  width: 100%;
  height: 100%;
`

const VideoOverlay = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`

const TitleWrapper = styled.span`
  position: absolute;
  top: 0;
  left: 3px;
  color: white;
  text-shadow: 0 0 2px black;
`

interface FilePreviewProps {
  mediaId: Media['id']
}
const FilePreview = ({ mediaId }: FilePreviewProps) => {
  const { media, loading, error } = useGetMedia(mediaId)

  useEffect(() => {
    if (error) notifyError(<FormattedMessage id="error.general" />, error)
  }, [error])

  const thumbnail = useMemo(() => media && getMediaThumbail(media), [media])

  return (
    <Loader loading={loading}>
      <Container>
        {media ? (
          <>
            <ImageWrapper src={thumbnail} />
            {media.type === MediaType.VideoClip && <VideoOverlay src="/assets/play.png" />}
            <TitleWrapper>{media.title}</TitleWrapper>
          </>
        ) : (
          <span>No file found for id {mediaId}</span>
        )}
      </Container>
    </Loader>
  )
}

export default FilePreview
