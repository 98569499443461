import { DecoratorBlockNode, SerializedDecoratorBlockNode } from '@lexical/react/LexicalDecoratorBlockNode'
import {
  COMMAND_PRIORITY_LOW,
  DOMConversionMap,
  DOMExportOutput,
  EditorConfig,
  ElementFormatType,
  LexicalEditor,
  LexicalNode,
  NodeKey,
  Spread,
} from 'lexical'
import React from 'react'

import ImageElement from './ImageElement'

export type ImagePayload = {
  src: string
  key?: NodeKey
  alt?: string
}

export type SerializedImageNode = Spread<
  {
    src: string
    alt?: string
    type: 'image'
    version: 1
  },
  SerializedDecoratorBlockNode
>

export const $isImageNode = (node: LexicalNode | null | undefined): node is ImageNode => node instanceof ImageNode

export const $createImageNode = ({ src, alt, key }: ImagePayload): ImageNode => new ImageNode(src, alt, key, 'center')

export class ImageNode extends DecoratorBlockNode {
  __src: string
  __alt?: string

  static getType(): string {
    return 'image'
  }

  static clone(node: ImageNode): ImageNode {
    return new ImageNode(node.__src, node.__alt, node.__key, node.__format)
  }

  static importJSON({ src, alt, format }: SerializedImageNode): ImageNode {
    const node = $createImageNode({ src, alt })
    node.setFormat(format)

    return node
  }

  static importDOM(): DOMConversionMap | null {
    return {
      img: () => ({
        conversion: (domNode: Node) => {
          if (domNode instanceof HTMLImageElement && domNode.getAttribute('data-lexical-image')) {
            const node = $createImageNode(domNode)
            return { node }
          }
          return null
        },
        priority: COMMAND_PRIORITY_LOW,
      }),
    }
  }

  constructor(src: string, alt?: string, key?: NodeKey, format?: ElementFormatType) {
    super(format, key)
    this.__src = src
    this.__alt = alt
  }

  exportJSON(): SerializedImageNode {
    return {
      ...super.exportJSON(),
      src: this.getSrc(),
      alt: this.getAlt(),
      type: 'image',
      version: 1,
    }
  }

  exportDOM(): DOMExportOutput {
    const element = super.createDOM()
    element.classList.add('my-2')
    if (this.__format) {
      element.style.textAlign = this.__format
    }

    const img = document.createElement('img')
    img.classList.add('inline-block')
    img.setAttribute('src', this.__src)
    img.setAttribute('data-lexical-image', this.__src)
    if (this.__alt) {
      img.setAttribute('alt', this.__alt)
    }
    element.append(img)

    return { element }
  }

  updateDOM(): false {
    return false
  }

  getSrc(): string {
    return this.__src
  }

  getAlt(): string | undefined {
    return this.__alt
  }

  setSrc(src: string): void {
    const self = this.getWritable()
    self.__src = src
  }

  setAlt(__alt?: string): void {
    const self = this.getWritable()
    self.__alt = __alt
  }

  getTextContent(_includeInert?: boolean | undefined, _includeDirectionless?: false | undefined): string {
    return this.__src
  }

  getFormatType(): ElementFormatType {
    return this.__format
  }

  decorate(_editor: LexicalEditor, config: EditorConfig) {
    const theme = config.theme.embedBlock || {}
    const className = {
      base: theme.base || '',
      focus: theme.focus || '',
    }

    return (
      <ImageElement
        className={className}
        src={this.__src}
        alt={this.__alt}
        nodeKey={this.getKey()}
        format={this.__format}
      />
    )
  }
}
