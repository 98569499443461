import React, { FC, Fragment } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

const Content = styled.p`
  margin-bottom: 8px;
  margin-top: 4px;
  color: black;
  font-size: 1.1rem;
  opacity: 0.8;
`

const Title = styled.p`
  font-size: 1.5rem;
  margin-bottom: 6px;
  font-weight: bold;
`

const SubTitle = styled.p`
  margin-bottom: 2px;
  font-size: 1rem;
  font-weight: bold;
  opacity: 0.6;
`

const Container = styled.div`
  margin-bottom: 20px;
`

interface RecapTranslationItemProps {
  title: string
  originalValue?: string | null
  values?: { locale?: string; value?: string }[]
}

const RecapTranslationItem: FC<RecapTranslationItemProps> = ({ title, values, originalValue }) => {
  const intl = useIntl()

  if (!originalValue && !values) {
    return null
  }

  return (
    <Container>
      <Title>{title}</Title>
      <Content>{originalValue}</Content>
      {values?.map(({ locale, value }) =>
        locale && value ? (
          <Fragment key={locale}>
            <SubTitle>{intl.formatMessage({ id: `locale.${locale}` })}</SubTitle>
            <Content>{value}</Content>
          </Fragment>
        ) : null,
      )}
    </Container>
  )
}

export default RecapTranslationItem
