import React, { useCallback } from 'react'
import { Icon } from 'semantic-ui-react'
import styled from 'styled-components'
import { downloadFile } from 'tools/downloadFile'

interface DownloadButtonProps {
  source: string
  name: string
  forceDownload?: boolean
  children?: React.ReactNode
}

const Link = styled.a`
  color: inherit;
  font-weight: bold;
  &:hover {
    color: inherit;
  }
`

const DonwloadButton: React.FC<DownloadButtonProps> = ({ source, name, forceDownload, children }) => {
  const download = useCallback(async () => {
    await downloadFile(source, name)
  }, [source, name])

  return (
    <>
      {forceDownload && (
        <Link onClick={download} href="#">
          <Icon name="download" /> {children}
        </Link>
      )}
      {!forceDownload && (
        <Link href={source} download>
          <Icon name="download" /> {children}
        </Link>
      )}
    </>
  )
}

export default DonwloadButton
