import { Document, Page, PDFDownloadLink, StyleSheet, Text, View } from '@react-pdf/renderer'
import React from 'react'
import { IntlShape, useIntl } from 'react-intl'
import { Button } from 'semantic-ui-react'
import { SportItemSetType } from 'services/api/graphql'
import { format } from 'tools/date'

// Styles for the PDF document

const BORDER_COLOR = '#b9b9b9'
const HEADER_BG_COLOR = '#cfcfcf'

const styles = StyleSheet.create({
  body: {
    padding: 10,
  },
  table: {
    display: 'table' as 'none', // Turns out this lib isn't perfectly typed
    width: 'auto',
    borderStyle: 'solid',
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  row: {
    margin: 'auto',
    flexDirection: 'row',
    alignItems: 'stretch',
    width: '100%',
  },
  cell: {
    borderStyle: 'solid',
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    margin: 'auto',
    fontSize: 10,
    flex: 1,
    height: '100%',
    padding: 5,
  },
  header: {
    fontSize: 12,
    fontWeight: 'bold',
    borderBottomWidth: 2,
    backgroundColor: HEADER_BG_COLOR,
  },
})

const Table: React.FC = ({ children }) => <View style={styles.table}>{children}</View>
const Row: React.FC = ({ children }) => <View style={styles.row}>{children}</View>
const Cell: React.FC = ({ children }) => (
  <View style={styles.cell}>
    <Text>{children}</Text>
  </View>
)
const HeaderCell: React.FC = ({ children }) => (
  <View style={[styles.cell, styles.header]}>
    <Text>{children}</Text>
  </View>
)

// Data displayed by the table
export interface FrontPageStatsRowData {
  id: string
  title: string
  organism?: string
  type: SportItemSetType
  days: number
  start: Date
  end?: Date
}

// PDF component created with react-pdf
interface FrontPageStatsDocumentProps {
  data: FrontPageStatsRowData[]
  intl: IntlShape
}
const FrontPageStatsDocument: React.FC<FrontPageStatsDocumentProps> = ({ data, intl }) => {
  return (
    <Document>
      <Page style={styles.body} orientation="landscape">
        <Table>
          <Row>
            <HeaderCell>{intl.formatMessage({ id: 'frontPages.sportitemSet.title' })}</HeaderCell>
            <HeaderCell>{intl.formatMessage({ id: 'frontPages.sportitemSet.organism' })}</HeaderCell>
            <HeaderCell>{intl.formatMessage({ id: 'frontPages.sportitemSet.type' })}</HeaderCell>
            <HeaderCell>
              {intl.formatMessage({
                id: 'frontPagesStats.sportitemSet.timeOnFront',
              })}
            </HeaderCell>
            <HeaderCell>
              {intl.formatMessage({
                id: 'frontPagesStats.sportitemSet.lastStart',
              })}
            </HeaderCell>
            <HeaderCell>
              {intl.formatMessage({
                id: 'frontPagesStats.sportitemSet.lastEnd',
              })}
            </HeaderCell>
          </Row>
          {data.map((row, index) => (
            // For some odd reason, using only id as key make react-pdf crash when reordering
            <Row key={`${index}-${row.id}`}>
              <Cell>{row.title}</Cell>
              <Cell>{row.organism || ''}</Cell>
              <Cell>{intl.formatMessage({ id: `sportItemSetType.${row.type}` })}</Cell>
              <Cell>{row.days}</Cell>
              <Cell>{format(row.start, 'DD MMMM YYYY')}</Cell>
              <Cell>
                {row && row.end
                  ? format(row.end, 'DD MMMM YYYY')
                  : intl.formatMessage({ id: 'frontPagesStats.running' })}
              </Cell>
            </Row>
          ))}
        </Table>
      </Page>
    </Document>
  )
}

// Button to generate and download the pdf
interface FrontPageStatsPdfRenderProps {
  data: FrontPageStatsRowData[]
  fileName: string
  label: React.ReactNode
}
const FrontPageStatsPdfRender: React.FC<FrontPageStatsPdfRenderProps> = ({ data, fileName, label }) => {
  const intl = useIntl()

  return (
    <PDFDownloadLink document={<FrontPageStatsDocument data={data} intl={intl} />} fileName={fileName}>
      {({ loading }) =>
        !loading && (
          <Button as="span" role="" primary>
            {label}
          </Button>
        )
      }
    </PDFDownloadLink>
  )
}

export default FrontPageStatsPdfRender
