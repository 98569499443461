import { SelectedTranslationsType } from 'Components/Form/SelectLocalInput'
import {
  CreatePurchasableOfferInput,
  OfferMode,
  PurchasableOffer,
  UpdatePurchasableOfferInput,
} from 'services/api/graphql'
import { getTranslationInputFromForm } from 'tools/translationTools'

import { FormResults, PassType } from './PurchasableOffersForm'

export const getUpdateInputFromPurchasableOffersForm: (
  // Forced to use any here, because descriptionT has to stay dynamic...
  values: any,
  selectedTranslations: SelectedTranslationsType[],
  defaultLocale: string,
) => UpdatePurchasableOfferInput = (values, selectedTranslations, defaultLocale) => {
  const descriptionT = selectedTranslations
    .map(({ value }) => ({
      content: values[`descriptionT${value}`],
      locale: value,
    }))
    .filter(({ content }) => !!content)
  const description = descriptionT.find(({ locale }) => locale === defaultLocale)?.content

  return {
    bannerImageId: values.bannerImageId || null,
    delay: values.delay,
    description,
    expiredAt: values.expiredAt,
    freeWithLicense: values.freeWithLicense,
    mode: values.mode,
    publicationStatus: values.publicationStatus,
    thumbnailImageId: values.thumbnailImageId || null,
    title: values.titleT?.[defaultLocale] || '',
    titleT: getTranslationInputFromForm(selectedTranslations, values.titleT),
    descriptionT,
    url: values.url || null,
  }
}

export const getCreateInputFromPurchasableOffersForm: (
  // Forced to use any here, because descriptionT has to stay dynamic...
  values: any,
  selectedTranslations: SelectedTranslationsType[],
  defaultLocale: string,
) => Omit<CreatePurchasableOfferInput, 'products' | 'organismId'> = (values, selectedTranslations, defaultLocale) => {
  const descriptionT = selectedTranslations
    .map(({ value }) => ({
      content: values[`descriptionT${value}`],
      locale: value,
    }))
    .filter(({ content }) => !!content)
  const description = descriptionT.find(({ locale }) => locale === defaultLocale)?.content
  return {
    bannerImageId: values.bannerImageId,
    delay: values.delay,
    description,
    expiredAt: values.expiredAt,
    freeWithLicense: values.freeWithLicense,
    mode: values.mode,
    publicationStatus: values.publicationStatus,
    thumbnailImageId: values.thumbnailImageId,
    title: values.titleT?.[defaultLocale] || '',
    titleT: getTranslationInputFromForm(selectedTranslations, values.titleT),
    descriptionT,
    url: values.url,
  }
}

export const getTranslationInputFromPurchasableOffersValues: (
  purchasableOffer?: PurchasableOffer,
) => FormResults = purchasableOffer => {
  // Forced to use any here, because descriptionT has to stay dynamic...
  let translations: any = { titleT: undefined }
  purchasableOffer?.titleT?.forEach(({ locale, content }) => {
    translations = { ...translations, titleT: { ...translations.titleT, [`${locale}`]: content } }
  })
  purchasableOffer?.descriptionT?.forEach(({ locale, content }) => {
    translations = {
      ...translations,
      [`descriptionT${locale}`]: content || undefined,
    }
  })
  const values = {
    ...purchasableOffer,
    ...translations,
    mode: purchasableOffer && purchasableOffer.mode ? purchasableOffer.mode : OfferMode.Subscription,
    passType: purchasableOffer && purchasableOffer.expiredAt ? PassType.expiredAt : PassType.delay,
    expiredAt: (purchasableOffer && purchasableOffer.expiredAt && new Date(purchasableOffer.expiredAt)) || undefined,
  } as FormResults
  return values
}
