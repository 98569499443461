import Loader from 'Components/Loader'
import PageHeader from 'Components/PageHeader'
import { InitDefaultConfigForm, UpdateDefaultConfigForm } from 'Forms/DefaultConfig/DefaultConfigForm'
import { useGetDefaultConfig, useInitDefaultConfig, useUpdateDefaultConfig, useUpdateRedButtonConfig } from 'models'
import React, { FC, useCallback, useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button, Grid, Icon, Popup, Segment } from 'semantic-ui-react'
import {
  InitDefaultConfigInput,
  Permission,
  UpdateDefaultConfigInput,
  useCleanCacheDatasourcesMutation,
  useCleanCacheGlobalMutation,
  useCleanCacheGraphqlMutation,
  useUpdateLiveStreamStatesCacheMutation,
} from 'services/api/graphql'
import { useCurrentUser } from 'stores'
import { getErrorMessage } from 'tools/graphql'
import { notifyError, notifySuccess } from 'tools/toaster'

const DefaultConfigView: FC = () => {
  const { defaultConfig, loading, error: noDefaultConfigError, refetch } = useGetDefaultConfig()
  const intl = useIntl()
  const { can } = useCurrentUser()

  const [updateDefaultConfig, { loading: loadingUpdate, error: errorUpdate }] = useUpdateDefaultConfig()
  const [initDefaultConfig, { loading: loadingInit, error: errorInit }] = useInitDefaultConfig()
  const [updateRedButtonConfig, { loading: loadingRedButton, error: errorRedButton }] = useUpdateRedButtonConfig()

  const [cleanCacheGlobal, { loading: loadingCleanCacheGlobal, error: errorCleanCacheGlobal }] =
    useCleanCacheGlobalMutation()
  const [cleanCacheDatasources, { loading: loadingCleanCacheDatasources, error: errorCleanCacheDatasources }] =
    useCleanCacheDatasourcesMutation()
  const [cleanCacheGraphql, { loading: loadingCleanCacheGraphql, error: errorCleanCacheGraphql }] =
    useCleanCacheGraphqlMutation()

  const [
    updateLiveStreamStatesCache,
    { loading: loadingUpdateLiveStreamStatesCache, error: errorUpdateLiveStreamStatesCache },
  ] = useUpdateLiveStreamStatesCacheMutation()

  const onUpdate = useCallback(
    async ({
      sportItemDefaultCover,
      liveDefaultTemplate,
      liveStreamDefaultDuration,
      clipDefaultTemplate,
      androidMinVersion,
      appleMinVersion,
      sveChannelDelayHour,
      emailingConfig,
      videoGridUrl,
    }: UpdateDefaultConfigInput) => {
      if (videoGridUrl === undefined) {
        videoGridUrl = null
      }
      await updateDefaultConfig({
        variables: {
          input: {
            sportItemDefaultCover,
            liveDefaultTemplate,
            liveStreamDefaultDuration,
            clipDefaultTemplate,
            androidMinVersion,
            appleMinVersion,
            sveChannelDelayHour: {
              mounting: sveChannelDelayHour?.mounting || 0,
              unmounting: sveChannelDelayHour?.unmounting || 0,
            },
            emailingConfig: {
              startliveStream: emailingConfig?.startliveStream || 0,
              startliveStreamTest: emailingConfig?.startliveStreamTest || 0,
              endliveStream: emailingConfig?.endliveStream || 0,
            },
            videoGridUrl,
          },
        },
      })
      notifySuccess(intl.formatMessage({ id: 'defaultConfig.saved' }))
    },
    [intl, updateDefaultConfig],
  )
  const onInit = useCallback(
    async ({
      sportItemDefaultCover,
      liveDefaultTemplate,
      liveStreamDefaultDuration,
      clipDefaultTemplate,
      androidMinVersion,
      appleMinVersion,
      sveChannelDelayHour,
      emailingConfig,
      videoGridUrl,
    }: InitDefaultConfigInput) => {
      if (videoGridUrl === undefined) {
        videoGridUrl = null
      }
      await initDefaultConfig({
        variables: {
          input: {
            sportItemDefaultCover,
            liveDefaultTemplate,
            liveStreamDefaultDuration,
            clipDefaultTemplate,
            androidMinVersion,
            appleMinVersion,
            sveChannelDelayHour,
            emailingConfig,
            videoGridUrl,
          },
        },
      })
      notifySuccess(intl.formatMessage({ id: 'defaultConfig.init' }))
      await refetch()
    },
    [initDefaultConfig, intl, refetch],
  )

  const onUpdateRedButton = async () => {
    const { data } = await updateRedButtonConfig()
    if (data) {
      notifySuccess(
        intl.formatMessage({ id: 'redButton.configUpdated' }, { lives: data.updateRedButtonConfig.lives.length }),
      )
    }
  }

  useEffect(() => {
    const error =
      errorUpdate ||
      errorInit ||
      errorRedButton ||
      errorCleanCacheGlobal ||
      errorCleanCacheDatasources ||
      errorUpdateLiveStreamStatesCache

    if (error) {
      notifyError(getErrorMessage(error))
    }
  }, [
    errorUpdate,
    errorInit,
    errorRedButton,
    errorCleanCacheGlobal,
    errorCleanCacheDatasources,
    errorCleanCacheGraphql,
    errorUpdateLiveStreamStatesCache,
  ])

  return (
    <Loader loading={loading}>
      <Grid padded>
        <Grid.Column>
          <PageHeader title={<FormattedMessage id="defaultConfig.title" />}>
            <>
              {can(Permission.CleanCacheGlobal) && (
                <Button
                  secondary
                  inverted
                  type="submit"
                  disabled={loadingCleanCacheGlobal}
                  loading={loadingCleanCacheGlobal}
                  onClick={() => cleanCacheGlobal()}
                >
                  <FormattedMessage id="defaultConfig.cleanCacheGlobal.button" />
                </Button>
              )}

              {can(Permission.CleanCacheDatasources) && (
                <Button
                  secondary
                  inverted
                  type="submit"
                  disabled={loadingCleanCacheDatasources}
                  loading={loadingCleanCacheDatasources}
                  onClick={() => cleanCacheDatasources()}
                >
                  <FormattedMessage id="defaultConfig.cleanCacheDatasources.button" />
                </Button>
              )}

              {can(Permission.CleanCacheGraphql) && (
                <Button
                  secondary
                  inverted
                  type="submit"
                  disabled={loadingCleanCacheGraphql}
                  loading={loadingCleanCacheGraphql}
                  onClick={() => cleanCacheGraphql()}
                >
                  <FormattedMessage id="defaultConfig.cleanCacheGraphql.button" />
                </Button>
              )}

              {can(Permission.LiveStreamCacheUpdate) && (
                <Button
                  negative
                  type="submit"
                  disabled={loadingUpdateLiveStreamStatesCache}
                  loading={loadingUpdateLiveStreamStatesCache}
                  onClick={() => updateLiveStreamStatesCache()}
                >
                  <FormattedMessage id="defaultConfig.updateLiveStreamStatesCache.button" />
                </Button>
              )}

              {can(Permission.UpdateRedButton) && (
                <Popup
                  trigger={
                    <Button
                      color="red"
                      icon="sync"
                      circular
                      onClick={() => onUpdateRedButton()}
                      loading={loadingRedButton}
                      disabled={loadingRedButton}
                    />
                  }
                  on={['hover', 'focus']}
                >
                  <FormattedMessage id="redButton.updateConfig" />
                </Popup>
              )}
            </>
          </PageHeader>

          {defaultConfig && (
            <UpdateDefaultConfigForm
              onSubmit={onUpdate}
              initialValues={defaultConfig as UpdateDefaultConfigInput}
              loading={loadingUpdate}
            />
          )}

          {noDefaultConfigError && (
            <>
              <Segment raised>
                <Icon name="warning" />
                <FormattedMessage id="defaultConfig.not_init" />
              </Segment>
              <InitDefaultConfigForm onSubmit={onInit} loading={loadingInit} />
            </>
          )}
        </Grid.Column>
      </Grid>
    </Loader>
  )
}

export default DefaultConfigView
