import { QueryHookOptions, useMutation, useQuery } from '@apollo/client'
import {
  CHECK_STREAMS_AVAILABLE_QUERY,
  DELETE_SPORTITEMSET_MUTATION,
  GET_SPORTITEMSETS_QUERY,
  GET_SPORTITEMSETS_WITH_CHILDREN_QUERY,
  GET_SPORTITEMSET_QUERY,
  QueryCheckStreamsAvailableArgs,
  QuerySportItemSetsArgs,
  SportItemSet,
  SportItemSetFilters,
  SportItemSetStreamConflict,
  GET_SPORTITEMSET_HAS_LIVES_RUNNING,
} from 'services/api/graphql'

/**
 * Hooks
 */
export type GetSportItemSetResult = {
  sportItemSet: SportItemSet | undefined
}
export const useGetSportItemSet = (
  sportItemSetId: SportItemSet['id'],
  options?: QueryHookOptions<GetSportItemSetResult>,
) => {
  const { data, ...rest } = useQuery<GetSportItemSetResult>(GET_SPORTITEMSET_QUERY, {
    variables: { sportItemSetId },
    ...options,
  })
  return { sportItemSet: data && data.sportItemSet, ...rest }
}

export type GetSportItemSetsResult = {
  sportItemSets: {
    sportItemSets: SportItemSet[]
    totalCount: number
  }
}
export const useGetSportItemSets = (options?: QueryHookOptions<GetSportItemSetsResult, QuerySportItemSetsArgs>) =>
  useQuery(GET_SPORTITEMSETS_QUERY, options)

export const useGetSportItemSetsWithChildren = (
  options?: QueryHookOptions<GetSportItemSetsResult, { filters?: SportItemSetFilters; withItems?: boolean }>,
) => useQuery(GET_SPORTITEMSETS_WITH_CHILDREN_QUERY, options)

export const useDeleteSportItemSet = () =>
  useMutation<{ deleteSportItemSet: SportItemSet }, { sportItemSetId: SportItemSet['id'] }>(
    DELETE_SPORTITEMSET_MUTATION,
  )

export const useGetSportItemSetLiveRunning = (sportItemSetId?: SportItemSet['id'], options?: QueryHookOptions) => {
  const { data, loading } = useQuery(GET_SPORTITEMSET_HAS_LIVES_RUNNING, {
    ...options,
    variables: { sportItemSetId, filters: { live: true } },
  })

  return { hasLiveRunning: data?.sportItemSet?.items?.length > 0, loading }
}

export const useCheckStreamsAvailable = (
  input: QueryCheckStreamsAvailableArgs,
  options?: QueryHookOptions<{
    checkStreamsAvailable: SportItemSetStreamConflict[]
  }>,
) => {
  const { data, ...rest } = useQuery<{
    checkStreamsAvailable: SportItemSetStreamConflict[]
  }>(CHECK_STREAMS_AVAILABLE_QUERY, {
    variables: {
      ...input,
    },
    ...options,
  })
  return { data: data ? data.checkStreamsAvailable : undefined, ...rest }
}
