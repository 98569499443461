import ConfirmationButton, { ConfirmationButtonBaseProps } from 'Containers/Button/ConfirmationButton'
import { useDeleteSport } from 'models'
import React from 'react'
import { Sport } from 'services/api/graphql'

interface RemoveSportButtonProps extends ConfirmationButtonBaseProps {
  sportId: Sport['id']
}

const RemoveSportButton = ({ sportId, ...props }: RemoveSportButtonProps) => {
  // const { removeSport, loading } = useRemoveSport(sportId)
  const [removeSport] = useDeleteSport()
  return <ConfirmationButton action={() => removeSport({ variables: { sportId } })} {...props} />
}

export default RemoveSportButton
