import SwitchInput from 'Components/Form/SwitchInput'
import React from 'react'

import { IntlShape, useIntl } from 'react-intl'

export enum VideoTypePlaylist {
  Video = 'Video',
  ExternalFeed = 'ExternalFeed',
}

export interface SelectVideoTypePlaylistInputProps {
  value: VideoTypePlaylist | undefined
  onChange: (value: VideoTypePlaylist | undefined) => void
}

const options = (intl: IntlShape) => [
  {
    key: VideoTypePlaylist.Video,
    value: VideoTypePlaylist.Video,
    text: intl.formatMessage({ id: 'sportItem.add_clip' }),
  },
  {
    key: VideoTypePlaylist.ExternalFeed,
    value: VideoTypePlaylist.ExternalFeed,
    text: intl.formatMessage({ id: 'sportItemSets.videoEngine.externalFeed' }),
  },
]

const SelectVideoTypePlaylist = ({ value, onChange }: SelectVideoTypePlaylistInputProps) => {
  const intl = useIntl()
  return <SwitchInput value={value} onChange={onChange} options={options(intl)} />
}

export default SelectVideoTypePlaylist
