import { AutoLinkNode, LinkNode } from '@lexical/link'
import { ListItemNode, ListNode } from '@lexical/list'
import { InitialConfigType, LexicalComposer } from '@lexical/react/LexicalComposer'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary'
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin'
import { ListPlugin } from '@lexical/react/LexicalListPlugin'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import { QuoteNode } from '@lexical/rich-text'
import classnames from 'classnames'
import React, { FC, useMemo } from 'react'

import AutoLinkPlugin from './AutoLinkPlugin/AutoLinkPlugin'
import ImagePlugin, { ImageNode } from './ImagePlugin/ImagePlugin'
import LexicalTheme from './LexicalTheme'
import JsonHtmlSerializerPlugin, { JsonHtmlSerializerPluginProps } from './SerializerPlugin/JsonHtmlSerializerPlugin'
import ToolbarPlugin from './ToolbarPlugin/ToolbarPlugin'
import TweetPlugin, { TweetNode } from './TweetPlugin/TweetPlugin'
import YouTubePlugin, { YouTubeNode } from './YoutubePlugin/YoutubePlugin'

export type LexicalEditorProps = Pick<InitialConfigType, 'editable'> & JsonHtmlSerializerPluginProps

/**
 * Wysiwyg <Editor> implementation using lexical.
 *
 * @see https://lexical.dev/docs/intro
 */
const LexicalEditor: FC<LexicalEditorProps> = ({ editable, value, onChange }) => {
  const editorConfig: InitialConfigType = useMemo(
    () => ({
      namespace: 'sportall-editor',
      editable,
      theme: LexicalTheme,
      onError(error) {
        throw error
      },
      nodes: [AutoLinkNode, ImageNode, LinkNode, ListNode, ListItemNode, QuoteNode, TweetNode, YouTubeNode],
    }),
    [editable],
  )

  return (
    <LexicalComposer initialConfig={editorConfig}>
      <ToolbarPlugin />
      <RichTextPlugin
        contentEditable={
          <ContentEditable
            className={classnames({ 'p-4 rounded-md outline-none': editable })}
            style={{ minHeight: '15rem', border: '1px solid rgba(34, 36, 38, .15)' }}
          />
        }
        placeholder={null}
        ErrorBoundary={LexicalErrorBoundary}
      />
      <AutoLinkPlugin />
      <HistoryPlugin />
      <JsonHtmlSerializerPlugin value={value} onChange={onChange} />
      <ImagePlugin />
      <ListPlugin />
      <LinkPlugin />
      <TweetPlugin />
      <YouTubePlugin />
    </LexicalComposer>
  )
}

export default LexicalEditor
