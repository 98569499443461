import { DecoratorBlockNode, SerializedDecoratorBlockNode } from '@lexical/react/LexicalDecoratorBlockNode'
import {
  COMMAND_PRIORITY_LOW,
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
  EditorConfig,
  ElementFormatType,
  LexicalEditor,
  LexicalNode,
  NodeKey,
  Spread,
} from 'lexical'
import React from 'react'

import TweetElement from './TweetElement'

export type TweetPayload = {
  id: string
  widget: string
}

export type SerializedTweetNode = Spread<
  {
    id: string
    widget: string
    type: 'tweet'
    version: 1
  },
  SerializedDecoratorBlockNode
>

export const $createTweetNode = ({ id, widget }: TweetPayload) => new TweetNode(id, widget, 'center')

export const $isTweetNode = (node: TweetNode | LexicalNode | null | undefined): node is TweetNode =>
  node instanceof TweetNode

export class TweetNode extends DecoratorBlockNode {
  __id: string
  __widget: string

  static getType(): string {
    return 'tweet'
  }

  static clone(node: TweetNode): TweetNode {
    return new TweetNode(node.__id, node.__widget, node.__format, node.__key)
  }

  static importJSON(serializedNode: SerializedTweetNode): TweetNode {
    const node = $createTweetNode(serializedNode)
    node.setFormat(serializedNode.format)
    return node
  }

  exportJSON(): SerializedTweetNode {
    return {
      ...super.exportJSON(),
      id: this.getId(),
      widget: this.getWidget(),
      type: 'tweet',
      version: 1,
    }
  }

  static importDOM(): DOMConversionMap<HTMLQuoteElement> | null {
    return {
      blockquote: (domNode: HTMLQuoteElement) => {
        if (!domNode.hasAttribute('data-lexical-tweet-id')) {
          return null
        }
        return {
          conversion: (domNode: HTMLQuoteElement): DOMConversionOutput | null => {
            const id = domNode.getAttribute('data-lexical-tweet-id')
            if (id) {
              const node = $createTweetNode({ id, widget: domNode.outerHTML })
              return { node }
            }
            return null
          },
          priority: COMMAND_PRIORITY_LOW,
        }
      },
    }
  }

  exportDOM(): DOMExportOutput {
    const template = document.createElement('template')
    template.innerHTML = this.__widget

    const element = template.content.firstChild as HTMLElement
    element.setAttribute('data-lexical-tweet-id', this.__id)

    return { element }
  }

  constructor(id: string, widget: string, format?: ElementFormatType, key?: NodeKey) {
    super(format, key)
    this.__id = id
    this.__widget = widget
  }

  getId(): string {
    return this.__id
  }

  getWidget(): string {
    return this.__widget
  }

  getTextContent(_includeInert?: boolean | undefined, _includeDirectionless?: false | undefined): string {
    return `https://twitter.com/i/web/status/${this.__id}`
  }

  getFormatType(): ElementFormatType {
    return this.__format
  }

  decorate(editor: LexicalEditor, config: EditorConfig): JSX.Element {
    const theme = config.theme.embedBlock || {}
    const className = {
      base: theme.base || '',
      focus: theme.focus || '',
    }
    return <TweetElement className={className} format={this.__format} nodeKey={this.getKey()} tweetID={this.__id} />
  }

  isInline(): false {
    return false
  }
}
