import { DropdownItemProps } from 'semantic-ui-react'

export const tagOptions: (tags: { id: string; title: string }[]) => DropdownItemProps[] = tags =>
  tags.map(tag => ({
    key: tag?.id,
    value: tag?.id,
    text: tag?.title,
  })) || []

export const subtagOptions: (tags?: string[]) => DropdownItemProps[] = tags =>
  tags?.map(tag => ({
    key: tag,
    value: tag,
    text: tag,
  })) || []
