import React, { useState } from 'react'
import ReactPlayer from 'react-player'

import { Modal } from 'semantic-ui-react'
import { MediaLightContentFragment, Permission, VideoContentType } from 'services/api/graphql'
import { useCurrentUser } from 'stores'

import styled from 'styled-components'

import EditVideo from './EditVideo'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > * + * {
    margin-top: 1em;
  }
`

interface VideoPlayerModalProps {
  open: boolean
  mediaToUpdate?: MediaLightContentFragment | null
  url: string
  onClose: () => void
  onRefresh?: () => void
  showEditTrim?: boolean
  showEditMarkers?: boolean
  videoContentType?: VideoContentType
  isEmbed?: boolean
}

const VideoPlayerModal: React.FC<VideoPlayerModalProps> = ({
  open,
  url,
  showEditTrim,
  showEditMarkers,
  mediaToUpdate,
  onClose,
  onRefresh,
  videoContentType,
  isEmbed = false,
}) => {
  const currentUser = useCurrentUser()
  const [currentTime, setCurrentTime] = useState<number>(0)
  const [duration, setDuration] = useState<number>(0)

  return (
    <Modal mountNode={document.getElementById('modals')} open={open} onClose={onClose} closeIcon size="large">
      <Modal.Content>
        <Container>
          <ReactPlayer
            url={url}
            playing={open}
            height={isEmbed ? '65vh' : '100%'}
            width="100%"
            controls
            onProgress={e => setCurrentTime(e.playedSeconds)}
            onDuration={setDuration}
          />
        </Container>
        {currentUser.can(Permission.SportItemUpdate) && mediaToUpdate && (showEditTrim || showEditMarkers) && (
          <EditVideo
            currentTime={currentTime}
            duration={duration}
            mediaToUpdate={mediaToUpdate}
            onRefresh={onRefresh}
            showEditTrim={showEditTrim}
            showEditMarkers={showEditMarkers}
            videoContentType={videoContentType}
          />
        )}
      </Modal.Content>
    </Modal>
  )
}

export default VideoPlayerModal
