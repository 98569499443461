import { ApolloProvider, ApolloProvider as ApolloHooksProvider, useApolloClient } from '@apollo/client'
import { setToken } from 'hooks/api'
import { useAuthApolloClient } from 'hooks/apollo'
import { useI18n } from 'i18n'
import { Provider } from 'mobx-react'
import { observer } from 'mobx-react-lite'
import { useGetOrganismTitles, useImportUsersStatus } from 'models'
import React, { useEffect, useState } from 'react'
import 'react-circular-progressbar/dist/styles.css'
import 'react-image-crop/dist/ReactCrop.css'
import 'react-image-lightbox/style.css' // This only needs to be imported once in your app
import { IntlProvider } from 'react-intl'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Loader } from 'semantic-ui-react'
import { useCurrentUser, useStore, useTemplates } from 'stores'
import { RootStore, setupRootStore } from 'stores/RootStore'

import './App.css'
import Screen from './MainScreen'

// language without region code

// const client = new ApolloClient({ uri: 'http://192.168.20.106:5000/graphql' })
// const client = new ApolloClient({ uri: 'http://localhost:5000/graphql' })
// const client = new ApolloClient({ uri: '/graphql' })

// LoggedInScreen must be mounted when currentUser.authToken is there !

const MainScreen = observer(() => {
  const store = useStore()
  const client = useApolloClient()
  const { getOrganismTitles } = useGetOrganismTitles()
  useImportUsersStatus()

  // Fetch user & organism titles at startup if already authenticated !
  useEffect(() => {
    store.currentUser.setupAuth(client)
    if (store.currentUser.user) {
      getOrganismTitles()
    }
  }, [store, client, getOrganismTitles])

  return <Screen />
})

const App: React.FC = observer(() => {
  const currentUser = useCurrentUser()
  const templates = useTemplates()
  const { organismId } = useStore()
  const client = useAuthApolloClient(currentUser.authToken)
  const { lang, messages } = useI18n()

  useEffect(() => {
    setToken(currentUser.authToken)

    if (currentUser.authToken) {
      templates.fetchTemplates(client, organismId || undefined)
    }
  }, [currentUser.authToken, templates, organismId, client])

  return (
    <ApolloHooksProvider client={client}>
      <ApolloProvider client={client}>
        <IntlProvider locale={lang} messages={messages}>
          <div className="App">
            <MainScreen />
            <ToastContainer enableMultiContainer containerId={'error'} position={toast.POSITION.BOTTOM_LEFT} />
            <ToastContainer enableMultiContainer containerId={'messages'} position={toast.POSITION.TOP_RIGHT} />
          </div>
          <div id="modals">
            <div id="child-modals" />
          </div>
        </IntlProvider>
      </ApolloProvider>
    </ApolloHooksProvider>
  )
})

const AppWithStore: React.FC = () => {
  const [store, setStore] = useState<RootStore>()

  useEffect(() => {
    setupRootStore().then(rootStore => {
      setStore(rootStore)
    })
  }, [])
  if (!store) {
    return <Loader />
  }
  return (
    <Provider store={store}>
      <App />
    </Provider>
  )
}
export default AppWithStore
