import SelectItemListInput from 'Components/Form/SelectItemListInput'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { LicenseApi } from 'services/api/graphql'

export interface SelectLicenseApiInputProps {
  value?: LicenseApi | null
  onChange: (value: LicenseApi | null) => void
  disabled?: boolean
}

const SelectLicenseApiInput = ({ value, onChange, disabled }: SelectLicenseApiInputProps) => {
  const intl = useIntl()
  const options = useMemo(
    () =>
      (Object.keys(LicenseApi) as Array<keyof typeof LicenseApi>).map(api => ({
        key: LicenseApi[api],
        value: LicenseApi[api],
        text: intl.formatMessage({
          id: `organism.licenseApi.${LicenseApi[api]}`,
        }),
      })),
    [intl],
  )
  return (
    <SelectItemListInput
      value={value}
      options={options}
      noSelectionEnabled={true}
      noSelectionTitle={intl.formatMessage({ id: 'common.none' })}
      onChange={onChange}
      disabled={disabled}
    />
  )
}

export default SelectLicenseApiInput
