import React from 'react'
import { useIntl } from 'react-intl'
import { Icon } from 'semantic-ui-react'
import { Gender } from 'services/api/graphql'

type GenderIconProps = {
  gender?: Gender | null
}

const GenderIcon = ({ gender }: GenderIconProps) => {
  const intl = useIntl()

  return (
    <>
      {gender && gender === Gender.Male && (
        <Icon
          name="man"
          title={intl.formatMessage({
            id: 'user.gender.male',
          })}
        />
      )}
      {gender && gender === Gender.Female && (
        <Icon
          name="woman"
          title={intl.formatMessage({
            id: 'user.gender.female',
          })}
        />
      )}
      {gender && gender === Gender.NotSpecified && (
        <Icon
          name="question"
          title={intl.formatMessage({
            id: 'user.gender.notSpecified',
          })}
        />
      )}
    </>
  )
}

export default GenderIcon
