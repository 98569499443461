import { gql, QueryHookOptions, useMutation, useQuery } from '@apollo/client'
import {
  CREATE_FRONT_PAGE_MUTATION,
  FrontPage,
  LIST_FRONTPAGES_QUERY,
  MutationCreateFrontPageArgs,
  MutationMoveFrontPagePositionArgs,
  MutationUpdateFrontPageArgs,
  QueryFrontPagesArgs,
  QuerySportItemSetsArgs,
  SPORTITEMSETS_FRONTPAGES_QUERY,
  UPDATE_FRONT_PAGE_MUTATION,
} from 'services/api/graphql'
import { useShortMutation } from 'tools/graphql'

import { GetSportItemSetsResult } from './SportItemSet'

export const useListFrontPages = (options?: QueryHookOptions<{ frontPages: FrontPage[] }, QueryFrontPagesArgs>) => {
  const { data, ...res } = useQuery(LIST_FRONTPAGES_QUERY, options)
  return {
    frontPages: data && data.frontPages,
    ...res,
  }
}

export const useCreateFrontPage = () =>
  useMutation<{ createFrontPage: FrontPage }, MutationCreateFrontPageArgs>(CREATE_FRONT_PAGE_MUTATION)

export const useUpdateFrontPage = () =>
  useMutation<{ updateFrontPage: FrontPage }, MutationUpdateFrontPageArgs>(UPDATE_FRONT_PAGE_MUTATION)

export const useGetSportItemSetsForFrontPages = (
  options?: QueryHookOptions<GetSportItemSetsResult, QuerySportItemSetsArgs>,
) => {
  const { data, ...res } = useQuery(SPORTITEMSETS_FRONTPAGES_QUERY, options)
  return {
    sportItemSets: data && data.sportItemSets.sportItemSets,
    totalCount: data && data.sportItemSets.totalCount,
    ...res,
  }
}

const MOVE_FRONTPAGE_POSITION = gql`
  mutation moveFrontPagePosition($input: MoveFrontPagePositionInput!) {
    moveFrontPagePosition(input: $input) {
      id
      position
    }
  }
`

export const useMoveFrontPagePosition = () =>
  useShortMutation<{ moveFrontPagePosition: FrontPage[] }, MutationMoveFrontPagePositionArgs>(MOVE_FRONTPAGE_POSITION)
