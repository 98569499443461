import { DragDropContext, Droppable, DropResult } from '@hello-pangea/dnd'
import RefreshButton from 'Components/Button/RefreshButton'
import DraggableTableRow from 'Components/DraggableTableRow/DraggableTableRow'
import EditedCategoryViewRow from 'Components/EditedCategory/EditedCateryViewRow'
import Loader from 'Components/Loader'
import PageHeader from 'Components/PageHeader'
import { applicationUris } from 'Layout/uris'
import { useDeleteEditedCategory, useGetEditedCategories, useMoveEditedCategory } from 'models'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { Button, Grid, Ref, Table } from 'semantic-ui-react'
import { EditedCategory } from 'services/api/graphql'
import { Permission } from 'services/api/graphql'
import { useCurrentUser, useStore } from 'stores'
import { cloneWithoutGraphqlCacheTypenames } from 'tools/graphql'

const EditedCategoriesView = () => {
  const store = useStore()
  const intl = useIntl()

  const {
    data: editedCategories,
    loading,
    refetch,
  } = useGetEditedCategories({
    variables: { filters: { organismId: store.organismId } },
    fetchPolicy: 'cache-and-network',
  })

  const [moveEditedCategory] = useMoveEditedCategory()
  const [removeEditedCategory] = useDeleteEditedCategory()

  const currentUser = useCurrentUser()

  const onMoveItem = async (result: DropResult) => {
    const { draggableId, destination, source, reason } = result
    // Do nothing if drop cancelled
    if (!destination || reason === 'CANCEL' || destination.index === source.index) {
      return
    }

    await moveEditedCategory({
      variables: {
        input: { id: draggableId, position: destination.index },
      },
    })

    void refetch()
  }

  return (
    <Loader loading={loading}>
      <Grid padded>
        <Grid.Column>
          <PageHeader title={intl.formatMessage({ id: 'editedCategory.title' }, { type: 'plural' })}>
            <RefreshButton onClick={() => refetch()} loading={loading} />
            {currentUser.can(Permission.EditedCategoryCreate) && (
              <Link to={applicationUris.editedCategoryCreate}>
                <Button icon="add" circular color="red" />
              </Link>
            )}
          </PageHeader>
          <DragDropContext onDragEnd={onMoveItem}>
            <Table striped compact celled>
              <Table.Header>
                <Table.Row textAlign="center">
                  <Table.HeaderCell />
                  <Table.HeaderCell>
                    <FormattedMessage id="editedCategory.items.title" />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <FormattedMessage id="editedCategory.items.publicationStatus" />
                  </Table.HeaderCell>
                  <Table.HeaderCell width={1} textAlign="center">
                    <FormattedMessage id="editedCategory.items.actions" />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Droppable isDropDisabled={!store.organismId} droppableId="editedCategories">
                {provided => (
                  <Ref innerRef={provided.innerRef}>
                    <Table.Body {...provided.droppableProps}>
                      {editedCategories && editedCategories.length > 0 && (
                        <>
                          {editedCategories.map((editedCategory: EditedCategory, index: number) => (
                            <DraggableTableRow
                              isDragDisabled={!store.organismId}
                              key={editedCategory.id}
                              draggableId={editedCategory.id}
                              index={index}
                            >
                              {provided => (
                                <EditedCategoryViewRow
                                  draggableProvided={provided}
                                  editedCategory={cloneWithoutGraphqlCacheTypenames(editedCategory)}
                                  onRemoveCategory={async () => {
                                    await removeEditedCategory({
                                      variables: { id: editedCategory.id },
                                    })
                                    void refetch()
                                  }}
                                />
                              )}
                            </DraggableTableRow>
                          ))}
                        </>
                      )}
                    </Table.Body>
                  </Ref>
                )}
              </Droppable>
            </Table>
          </DragDropContext>
        </Grid.Column>
      </Grid>
    </Loader>
  )
}

export default EditedCategoriesView
