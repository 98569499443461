import { FormBaseProps } from 'Forms/FormBase'
import { useGetNews, useUpdateNews } from 'models'
import React, { FC, useCallback } from 'react'
import { useIntl } from 'react-intl'
import { News, UpdateNewsInput } from 'services/api/graphql'
import { getContentTProp, getTranslationInputFromForm, getTranslationInputFromValues } from 'tools/translationTools'

import NewsForm, { NewsFormValues } from './NewsForm'

type EditNewsFormWithLinkProps = FormBaseProps<News> & {
  newsValues: NewsFormValues
  isModal: boolean
}

const EditNewsFormWithLink: FC<EditNewsFormWithLinkProps> = ({ newsValues, isModal, onCancel, onDone, onError }) => {
  const intl = useIntl()
  const [updateNews, { loading }] = useUpdateNews()

  const handleSubmit = useCallback(
    async ({ id, titleT, subtitleT, descriptionT, contentT, ...values }, defaultLocale, selectedTranslations) => {
      if (!newsValues.id) {
        throw new Error(`Missing News id !`)
      }
      const jsonContentT = getContentTProp(contentT, 'json')
      const htmlContentT = getContentTProp(contentT, 'html')

      try {
        const input: UpdateNewsInput = {
          id,
          titleT: getTranslationInputFromForm(selectedTranslations, titleT),
          title: titleT?.[defaultLocale] || '',
          subtitleT: getTranslationInputFromForm(selectedTranslations, subtitleT),
          subtitle: subtitleT?.[defaultLocale] || '',
          descriptionT: getTranslationInputFromForm(selectedTranslations, descriptionT),
          description: descriptionT?.[defaultLocale] || '',
          contentT: getTranslationInputFromForm(selectedTranslations, htmlContentT),
          content: htmlContentT?.[defaultLocale] || '',
          rawContentT: getTranslationInputFromForm(selectedTranslations, jsonContentT),
          rawContent: jsonContentT?.[defaultLocale] || '',
          publicationStatus: values.publicationStatus,
          coverImageId: values.imageId,
          organismId: values.organismId,
        }

        const res = await updateNews({ variables: { input } })
        if (res && res.data) onDone(res.data.updateNews)
      } catch (err: any) {
        onError?.(err)
      }
    },
    [newsValues.id, updateNews, onDone, onError],
  )

  return (
    <NewsForm
      title={intl.formatMessage({ id: 'news.form.edit.title' }, { title: newsValues.title })}
      edit
      isModal={isModal}
      initialValues={newsValues}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      loading={loading}
    />
  )
}

type EditNewsFormProps = FormBaseProps<News> & {
  newsId: News['id']
  isModal: boolean
}

const EditNewsForm: FC<EditNewsFormProps> = ({ newsId, isModal, ...props }) => {
  const { news } = useGetNews(newsId, { fetchPolicy: 'network-only' })
  if (!news) return null

  const newsValue: NewsFormValues = {
    ...getTranslationInputFromValues({
      titleT: news.titleT,
      subtitleT: news.subtitleT,
      descriptionT: news.descriptionT,
      contentT: news.rawContentT,
    }),
    id: news.id,
    title: news.title,
    imageId: news.coverImageId || '',
    defaultLocale: news.defaultLocale || undefined,
    organismId: news.organismId || '',
    subtitle: news.subtitle,
    description: news.description,
    content: news.rawContent,
    publicationStatus: news.publicationStatus,
  }

  return <EditNewsFormWithLink {...props} newsValues={newsValue} isModal={isModal} />
}
export default EditNewsForm
