import styled from 'styled-components'

export const invisibleButtonAttrs = `
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
`

export default styled.button`
  ${invisibleButtonAttrs}
`
