import { gql } from '@apollo/client'

import { CONTENT_TRANSLATION_FRAGMENT } from './ContentTranslation'
import { MEDIA_FRAGMENT, MEDIA_LIGHT_FRAGMENT } from './Media'
import { SPORTITEMSET_FRAGMENT } from './SportItemSet'
import { TAB_FRAGMENT } from './Tab'

const SPORTITEM_FRAGMENT = gql`
  fragment sportItemContent on SportItem {
    id
    title
    subtitle
    description
    titleT {
      ...contentTranslationContent
    }
    subtitleT {
      ...contentTranslationContent
    }
    descriptionT {
      ...contentTranslationContent
    }
    defaultLocale
    videoContentType
    publicationStatus
    contentType
    sportItemSetId
    reverseRestriction
    sportItemSet {
      id
      streamsIds
      streams {
        id
        name
      }
      ownerOrganismId
      sportEvent {
        startDate
        endDate
        state
        liveTemplateId
        clipTemplateId
        backstageTemplateId
      }
    }
    coverImageFileId
    coverImage {
      id
      image {
        id
        thumbUrl
        downloadUrl
      }
    }
    matureContent
    mediasIds
    medias(filters: { includeUnavailable: true, includeInactive: true }) {
      ...mediaContent
    }
    overrideTabs
    tabs {
      ...tabContent
    }
    sportEventItem {
      startDate
      stopDate
      testDate
      publishAutoreplay
    }
    cac {
      userAuthenticationRule
    }
    contentPushServiceNamesExcluded
    modeRadio
  }

  ${CONTENT_TRANSLATION_FRAGMENT}
  ${MEDIA_FRAGMENT}
  ${TAB_FRAGMENT}
`

// Same as SPORTITEM_FRAGMENT without sportItemSet details retrieval
const SPORTITEM_LIGHT_FRAGMENT = gql`
  fragment sportItemLightContent on SportItem {
    id
    title
    subtitle
    description
    videoContentType
    publicationStatus
    contentType
    sportItemSetId
    coverImageFileId
    coverImage {
      id
      image {
        id
        thumbUrl
        downloadUrl
      }
    }
    matureContent
    mediasIds
    reverseRestriction
    medias(filters: { includeUnavailable: true, includeInactive: true }) {
      ...mediaLightContent
    }
    sportEventItem {
      startDate
      stopDate
    }
  }

  ${MEDIA_LIGHT_FRAGMENT}
`

/**
 * SportItem
 */
export const GET_SPORTITEMS_QUERY = gql`
  query sportItems($sportItemSetId: ID!, $filters: SportItemFilters) {
    sportItems(sportItemSetId: $sportItemSetId, filters: $filters) {
      sportItems {
        ...sportItemLightContent
      }
      sportItemSet {
        id
      }
      totalCount
    }
  }
  ${SPORTITEM_LIGHT_FRAGMENT}
`

export const GET_SPORTITEM_QUERY = gql`
  query sportItem($sportItemId: ID!) {
    sportItem(id: $sportItemId) {
      ...sportItemContent
    }
  }
  ${SPORTITEM_FRAGMENT}
`
export const GET_SPORTITEM_WITH_ORGANISM_QUERY = gql`
  query sportItemWithSportItemSet($sportItemId: ID!) {
    sportItem(id: $sportItemId) {
      ...sportItemContent
      sportItemSet {
        ...sportItemSetContent
      }
    }
  }
  ${SPORTITEM_FRAGMENT}
  ${SPORTITEMSET_FRAGMENT}
`

export const GET_SPORTITEM_BY_CHAT_ROOM_QUERY = gql`
  query sportItemByChatRoom($chatRoomId: ID!) {
    sportItemByChatRoom(chatRoomId: $chatRoomId) {
      ...sportItemContent
    }
  }
  ${SPORTITEM_FRAGMENT}
`

export const CHECK_SPORTITEM_STREAMS_MOVABLE_QUERY = gql`
  query checkSportItemStreamsMovable($sportItemId: ID!, $from: Date!, $to: Date!) {
    checkSportItemStreamsMovable(sportItemId: $sportItemId, from: $from, to: $to) {
      stream {
        id
        name
      }
      sportItem {
        id
        title
      }
    }
  }
`

export const GET_MONITORING_DASHBOARD_QUERY = gql`
  query monitoringDashboard($date: Date!, $organismId: ID) {
    monitoringDashboard(date: $date, organismId: $organismId) {
      ...sportItemContent
      sportItemSet {
        ...sportItemSetContent
      }
    }
  }
  ${SPORTITEM_FRAGMENT}
  ${SPORTITEMSET_FRAGMENT}
`

export const CREATE_SPORTITEM_MUTATION = gql`
  mutation createSportItem($input: CreateSportItemInput!) {
    createSportItem(input: $input) {
      ...sportItemContent
    }
  }
  ${SPORTITEM_FRAGMENT}
`
export const UPDATE_SPORTITEM_MUTATION = gql`
  mutation updateSportItem($input: UpdateSportItemInput!) {
    updateSportItem(input: $input) {
      ...sportItemContent
    }
  }
  ${SPORTITEM_FRAGMENT}
`

export const DELETE_SPORTITEM_MUTATION = gql`
  mutation deleteSportItem($sportItemId: ID!) {
    deleteSportItem(id: $sportItemId) {
      id
    }
  }
`

export const STOP_SPORTITEM_LIVE_MUTATION = gql`
  mutation stopSportItemLive($id: ID!) {
    stopSportItemLive(id: $id) {
      ...sportItemContent
    }
  }

  ${SPORTITEM_FRAGMENT}
`

export const MOVE_PLAYLIST_ITEM_MUTATION = gql`
  mutation movePlaylistItem($input: MovePlaylistItemInput!) {
    movePlaylistItem(input: $input) {
      ...sportItemLightContent
    }
  }

  ${SPORTITEM_LIGHT_FRAGMENT}
`
