import { gql } from '@apollo/client'

/**
 * UserRoles
 */
export const USERROLE_FRAGMENT = gql`
  fragment userRole on UserRole {
    id
    title
    shortTitle
    description
    generalBasePermissions
    organismsBasePermissions
  }
`

export const GET_USERROLES_QUERY = gql`
  query userRoles {
    userRoles {
      ...userRole
    }
  }
  ${USERROLE_FRAGMENT}
`

export const GET_USERROLE_QUERY = gql`
  query userRole($id: UserRoleID!) {
    userRole(id: $id) {
      ...userRole
    }
  }
  ${USERROLE_FRAGMENT}
`
