import { useAuthApolloClient } from 'hooks/apollo'
import { observer } from 'mobx-react'
import { useUploadAvatar } from 'models'
import React, { useCallback, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useCurrentUser } from 'stores'
import styled from 'styled-components'

import InvisibleButton from './Button/InvisibleButton'
import FileInputModal from './FileInputModal'

const AVATAR_SIZE = '2.5rem'
const CircularAvatar = styled.img`
  height: ${AVATAR_SIZE};
  width: ${AVATAR_SIZE};
  border-radius: ${AVATAR_SIZE};
`
const ButtonContainer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
`

const UserAvatar: React.FC = observer(() => {
  const { user, authToken, refreshMe } = useCurrentUser()
  const client = useAuthApolloClient(authToken)

  const [uploadAvatar, { loading }] = useUploadAvatar()
  const [openUploadAvatarModal, setOpenUploadAvatarModal] = useState(false)

  const onUploadImage = useCallback(
    async (image: File) => {
      if (authToken) {
        await uploadAvatar({ variables: { file: image } })
        refreshMe(client)
      }
      setOpenUploadAvatarModal(false)
    },
    [authToken, uploadAvatar, refreshMe, client],
  )

  return (
    <>
      <InvisibleButton onClick={() => setOpenUploadAvatarModal(true)}>
        <ButtonContainer>
          <CircularAvatar src={(user && user.avatarUrl) || '/assets/no-avatar.png'} />
        </ButtonContainer>
      </InvisibleButton>
      <FileInputModal
        open={openUploadAvatarModal}
        header={<FormattedMessage id="avatar.upload" />}
        accept="image/png, image/jpeg"
        onCancel={() => setOpenUploadAvatarModal(false)}
        onValidate={onUploadImage}
        loading={loading}
      />
    </>
  )
})

export default UserAvatar
