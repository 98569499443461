import { DragDropContext, Draggable, Droppable, DropResult } from '@hello-pangea/dnd'
import RefreshButton from 'Components/Button/RefreshButton'
import Loader from 'Components/Loader'
import PageHeader from 'Components/PageHeader'
import WidgetRow from 'Components/Widget/WidgetRow'
import { useGetEditedCategories, useGetOrganism } from 'models'
import React, { useCallback } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button, Form, Grid, Ref, Table } from 'semantic-ui-react'
import {
  CustomPageDisplayTypes,
  CustomPageWidget,
  CustomPageWidgetName,
  useUpdateOrganismMutation,
  Permission,
} from 'services/api/graphql'
import { useStore } from 'stores'
import { useCurrentUser } from 'stores'
import { cloneWithoutGraphqlCacheTypenames } from 'tools/graphql'

const OrganismsPages = () => {
  const store = useStore()
  const intl = useIntl()
  const currentUser = useCurrentUser()
  const {
    organism,
    loading: isLoadingOrganism,
    refetch,
  } = useGetOrganism(store.organismId || '', {
    fetchPolicy: 'cache-and-network',
  })

  const [updateOrganism, { loading: isUpdatingOrganism }] = useUpdateOrganismMutation()
  const { data: editedCategories, loading: isLoadingEditedCategories } = useGetEditedCategories({
    variables: { filters: { organismId: store.organismId } },
    fetchPolicy: 'cache-and-network',
  })

  const updateWidgets = useCallback(
    async (widgetList: CustomPageWidget[]) => {
      if (!organism) return

      // Reset every widgets position
      const newWidgets = cloneWithoutGraphqlCacheTypenames(
        widgetList.map((widget, index) => ({
          ...widget,
          position: index,
        })),
      )
      const input = {
        id: organism.id,
        pageWidgets: newWidgets,
      }

      await updateOrganism({
        variables: {
          input,
        },
      })

      refetch()
    },
    [organism, refetch, updateOrganism],
  )

  const onMoveItem = async (result: DropResult) => {
    const { source, destination } = result
    if (!destination || !organism) return

    const newWidgets = organism.pageWidgets ? [...organism.pageWidgets] : []
    // Move widget into array
    newWidgets.splice(destination.index, 0, newWidgets.splice(source.index, 1)[0])

    updateWidgets(newWidgets)
  }

  const handleRemoveWidget = async (position: number) => {
    if (!organism) return

    const newWidgets = organism.pageWidgets ? [...organism.pageWidgets] : []
    // Remove widget at position
    newWidgets.splice(position, 1)
    updateWidgets(newWidgets)
  }

  const onUpdateWidget = useCallback(
    (index: number, value: CustomPageWidget) => {
      if (!organism) return
      const widgetList = organism.pageWidgets ? [...organism.pageWidgets] : []
      widgetList.splice(index, 1, value)
      updateWidgets(widgetList)
    },
    [organism, updateWidgets],
  )

  const addNewWidget = useCallback(() => {
    if (!organism) return
    const newWidget = {
      name: CustomPageWidgetName.AllContent,
      display: CustomPageDisplayTypes.Classic,
      position: organism.pageWidgets ? organism.pageWidgets.length : 0,
    }

    updateWidgets(organism.pageWidgets ? [...organism.pageWidgets, newWidget] : [newWidget])
  }, [organism, updateWidgets])

  const loading = isLoadingEditedCategories || isUpdatingOrganism || isLoadingOrganism

  if (!organism) {
    return <Loader loading={loading}>Please select an Organism</Loader>
  }

  return (
    <Loader loading={loading}>
      <Grid padded>
        <Grid.Column>
          {currentUser.can(Permission.OrganismPageCreate) && (
            <PageHeader title={intl.formatMessage({ id: 'organism.widgets.pageTitle' })}>
              <RefreshButton onClick={() => refetch()} loading={loading} />
              <Button icon="add" circular color="red" onClick={() => addNewWidget()} />
            </PageHeader>
          )}
          {currentUser.can(Permission.OrganismPageRead) && (
            <Form>
              <Table striped>
                <Table.Header>
                  <Table.Row textAlign="center">
                    <Table.HeaderCell />
                    <Table.HeaderCell>
                      <FormattedMessage id="customPage.widget.name" />
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <FormattedMessage id="customPage.widget.cardsDisplay" />
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <FormattedMessage id="customPage.widget.contentDisplay" />
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <FormattedMessage id="customPage.widget.display" />
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <FormattedMessage id="customPage.widget.name" />
                    </Table.HeaderCell>
                    <Table.HeaderCell width={1} textAlign="center">
                      <FormattedMessage id="application.actions" />
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <DragDropContext onDragEnd={result => onMoveItem(result)}>
                  <Droppable droppableId="widgets">
                    {provided => (
                      <Ref innerRef={provided.innerRef}>
                        <Table.Body>
                          {organism.pageWidgets?.map((widget, index) => {
                            return (
                              <Draggable key={index} draggableId={index.toString()} index={index}>
                                {draggableProvided => (
                                  <Ref innerRef={draggableProvided.innerRef}>
                                    <WidgetRow
                                      index={index}
                                      // Exclude external content when not enabled
                                      excludeNames={
                                        !organism.enableExternalSources
                                          ? [CustomPageWidgetName.ExternalContent]
                                          : undefined
                                      }
                                      provided={draggableProvided}
                                      value={widget}
                                      organism={organism}
                                      editedCategoryList={editedCategories}
                                      onChange={value => onUpdateWidget(index, value)}
                                      onRemove={() => handleRemoveWidget(index)}
                                    />
                                  </Ref>
                                )}
                              </Draggable>
                            )
                          })}
                        </Table.Body>
                      </Ref>
                    )}
                  </Droppable>
                </DragDropContext>
              </Table>
            </Form>
          )}
        </Grid.Column>
      </Grid>
    </Loader>
  )
}

export default OrganismsPages
