import React, { useCallback } from 'react'
import { Input } from 'semantic-ui-react'

interface NumberInputProps {
  value?: number
  onChange?: (value: number | undefined) => void
  placeholder?: string
  error?: boolean
}

const NumberInput: React.FC<NumberInputProps> = ({ value, onChange, placeholder, error }) => {
  const parseValue = useCallback(
    (val: string) => {
      const result = parseInt(val, 10)
      if (onChange) {
        onChange(isNaN(result) ? undefined : result)
      }
    },
    [onChange],
  )

  return <Input value={value} onChange={e => parseValue(e.target.value)} placeholder={placeholder} error={error} />
}

export default NumberInput
