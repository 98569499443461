import { useQuery } from '@apollo/client'
import {
  DragDropContext,
  DraggableProvided,
  DraggableRubric,
  DraggableStateSnapshot,
  Droppable,
  DropResult,
} from '@hello-pangea/dnd'
import SAFormField from 'Components/Form/SAFormField'
import SelectLocalInput, { SelectedTranslationsType, TranslatedInput } from 'Components/Form/SelectLocalInput'
import SelectPublicationStatusInput from 'Containers/Form/SelectPublicationStatusInput'

import { readFragment } from 'gql'
import { EditedCategoryItem, Organism, PublicationStatus, useGetSportItemSetsWithChildren } from 'models'
import React, { DragEventHandler, FC, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button, Form, Form as UiForm, Icon, Ref, Table } from 'semantic-ui-react'
import {} from 'services/api/graphql/News'
import { useStore } from 'stores'
import styled from 'styled-components'

import { EditedCategoryFormItemFields } from './EditedCategoryFormItemFields'
import { LinkItemFragment } from './LinkItem/fragments'
import { NewsItemFragment } from './NewsItem/fragments'
import { GetLinksQuery, GetNewssQuery } from './fragments'

export type EditedCategoriesFormValues = {
  id?: string // must be set when editing
  items: EditedCategoryItem[]
  defaultLocale: String
  organism: Organism
  organismId?: string
  position: number
  published: PublicationStatus
  title: string
  titleT?: TranslatedInput | undefined
}

type Props = {
  defaultLocale: string
  onChangeDefaultLocale: (value: string) => void
  selectedTranslations: SelectedTranslationsType[]
  onChangeSelectedTranslations: (newCountryTranslation: SelectedTranslationsType[]) => void
  onChangeActiveTranslation: (newActiveTranslation?: string) => void
  activeTranslation: string | undefined
  fields: FieldsType[]
  onChangeFields: (newFields: FieldsType[]) => void
}

export interface FieldsType extends Omit<EditedCategoryItem, 'sportItemSetId'> {
  sportItemSetId?: string | null | undefined
}

const EditedCategoryFormFields: FC<Props> = ({
  defaultLocale,
  onChangeDefaultLocale,
  selectedTranslations,
  onChangeSelectedTranslations,
  onChangeActiveTranslation,
  activeTranslation,
  fields,
  onChangeFields,
}) => {
  const store = useStore()
  const intl = useIntl()

  // TODO: Find a better way to have unique keys...
  const [keyRenewer, setKeyRenewer] = useState<number>(0)

  const { data: sportItemSetsRes, loading: itemSetsLoading } = useGetSportItemSetsWithChildren({
    variables: { filters: { organismId: store.organismId }, withItems: true },
    fetchPolicy: 'cache-and-network',
  })
  const sportItemSets = sportItemSetsRes?.sportItemSets?.sportItemSets

  const { data: newssQuery, loading: newsLoading } = useQuery(GetNewssQuery, {
    variables: { filters: { organismId: store.organismId } },
    fetchPolicy: 'cache-and-network',
  })
  const newss = newssQuery?.newss?.news.map(news => readFragment(NewsItemFragment, news))

  const { data: linksQuery, loading: linksLoading } = useQuery(GetLinksQuery, {
    variables: { filters: { organismId: store.organismId } },
    fetchPolicy: 'cache-and-network',
  })
  const links = linksQuery?.links?.links.map(link => readFragment(LinkItemFragment, link))

  const handleMoveItem = (result: DropResult, fields: any) => {
    const { source, destination } = result
    if (!destination || destination.index === source.index) {
      return
    }
    const newFields = [...fields]
    const src = source.index
    const dest = destination.index
    const tmpItem = newFields[src]

    if (destination.index < source.index) {
      for (let i = src - 1; i > dest - 1; i--) {
        newFields[i + 1] = newFields[i]
      }
    } else {
      for (let i = src; i < dest; i++) {
        newFields[i] = newFields[i + 1]
      }
    }
    newFields[dest] = tmpItem
    onChangeFields(newFields)
    setKeyRenewer(keyRenewer + 1)
  }

  const handleRemove = (index: number) => {
    const newFields = [...fields]
    for (let i = index; i < newFields.length - 1; i++) {
      newFields[i] = newFields[i + 1]
    }
    newFields.pop()
    onChangeFields(newFields)
    setKeyRenewer(keyRenewer + 1)
  }

  const handleAdd = (type: 'link' | 'news' | 'sportItemSet') => () => {
    const newFields = [...fields]
    if (type === 'link') {
      newFields.push({ sportItemSetId: undefined, sportItemId: undefined, linkId: '', newsId: undefined })
    } else if (type === 'news') {
      newFields.push({ sportItemSetId: undefined, sportItemId: undefined, linkId: undefined, newsId: '' })
    } else {
      newFields.push({ sportItemSetId: '', sportItemId: undefined, linkId: undefined, newsId: undefined })
    }
    onChangeFields(newFields)
  }

  const handleChangeFields = (index: number, item: FieldsType) => {
    const newFields = [...fields]
    newFields[index] = {
      sportItemId: item.sportItemId || undefined,
      sportItemSetId: item.sportItemSetId || undefined,
      newsId: item.newsId || undefined,
      linkId: item.linkId || undefined,
    }
    onChangeFields(newFields)
  }

  return (
    <UiForm>
      <SAFormField
        name="locale"
        render={({ input }) => (
          <Form.Field
            {...input}
            disabled={false}
            label={intl.formatMessage({ id: 'locale' })}
            control={() => (
              <SelectLocalInput
                defaultLocale={defaultLocale}
                onChangeDefaultLocale={onChangeDefaultLocale}
                activeTranslation={activeTranslation}
                onChangeActiveTranslation={onChangeActiveTranslation}
                selectedTranslations={selectedTranslations}
                onChangeSelectedTranslations={onChangeSelectedTranslations}
              />
            )}
          />
        )}
      />
      <SAFormField
        name={`titleT.${activeTranslation}`}
        render={({ input, meta }) => (
          <UiForm.Input
            {...input}
            required={activeTranslation === defaultLocale}
            label={intl.formatMessage({ id: 'editedCategory.items.title' })}
            error={!!meta.touched && !!meta.error}
          />
        )}
      />
      <SAFormField
        name="published"
        render={({ input, meta }) => (
          <UiForm.Field
            control={SelectPublicationStatusInput}
            {...input}
            error={!!meta.touched && !!meta.error}
            label={intl.formatMessage({
              id: 'editedCategory.items.publicationStatus',
            })}
          />
        )}
      />
      {itemSetsLoading || newsLoading || linksLoading ? (
        <Icon loading name="spinner" size="big" />
      ) : (
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell />
              <Table.HeaderCell>
                <FormattedMessage id="editedCategory.items.sportItemSet.label" />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <FormattedMessage id="editedCategory.items.sportItem.label" />
              </Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <DragDropContext onDragEnd={result => handleMoveItem(result, fields)}>
            <Droppable
              droppableId="items"
              renderClone={(
                provided: DraggableProvided,
                _snapshot: DraggableStateSnapshot,
                rubric: DraggableRubric,
              ) => {
                const itemId =
                  fields[rubric.source.index].sportItemSetId ||
                  fields[rubric.source.index].newsId ||
                  fields[rubric.source.index].linkId
                const item =
                  sportItemSets?.find(sportItemSet => sportItemSet.id === itemId) ||
                  newss?.find(news => news.id === itemId) ||
                  links?.find(link => link.id === itemId)
                return (
                  <div
                    className="opacity-80 p-4 bg-yellow-light"
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    onDragStart={provided.dragHandleProps?.onDragStart as DragEventHandler<HTMLDivElement>}
                    ref={provided.innerRef}
                  >
                    {item && item.title}
                  </div>
                )
              }}
            >
              {provided => (
                <Ref innerRef={provided.innerRef}>
                  <Table.Body {...provided.droppableProps}>
                    {fields.map((_item, index: number) => (
                      <EditedCategoryFormItemFields
                        key={`items[${index}]-${keyRenewer}`}
                        index={index}
                        name={`items[${index}]`}
                        sportItemSets={sportItemSets}
                        newss={newss}
                        links={links}
                        onRemove={() => handleRemove(index)}
                        onChangeFields={handleChangeFields}
                        fields={fields}
                      />
                    ))}
                  </Table.Body>
                </Ref>
              )}
            </Droppable>
          </DragDropContext>
          <Table.Footer>
            <Table.Row>
              <Table.Cell colSpan={3}>
                <div className="flex row-auto space-x-4 content-between">
                  <div
                    className="p-3 text-black rounded flex h-10 align-middle justify-center pt-2 cursor-pointer max-w-m bg-gray-200"
                    onClick={handleAdd('sportItemSet')}
                  >
                    <Icon name="add" />
                    <div className="ml-1">
                      <FormattedMessage id="editedCategory.addSet" />
                    </div>
                  </div>
                  <div
                    className="p-3 text-black rounded flex h-10 align-middle justify-center pt-2 cursor-pointer max-w-m bg-gray-200"
                    onClick={handleAdd('news')}
                  >
                    <Icon name="add" />
                    <div className="ml-1">
                      <FormattedMessage id="editedCategory.addNews" />
                    </div>
                  </div>
                  <div
                    className="p-3 text-white bg-blue-400 rounded flex h-10 align-middle justify-center pt-2 cursor-pointer max-w-sm"
                    onClick={handleAdd('link')}
                  >
                    <Icon name="add" />
                    <div className="ml-1">
                      <FormattedMessage id="editedCategory.addLink" />
                    </div>
                  </div>
                </div>
              </Table.Cell>
            </Table.Row>
          </Table.Footer>
        </Table>
      )}
    </UiForm>
  )
}

export const LinkButton = styled(Button)`
  background-color: #ccdeec !important;
  margin-left: 6px !important;
`

export default EditedCategoryFormFields
