import FilesLibraryView from 'Containers/FilesLibraryView'
import MediaInfoForm, { MediaInfoFormData } from 'Forms/Media/MediaInfoForm'
import { observer } from 'mobx-react-lite'
import { useCompleteMultipart, useCreateMediaVideoClip, useCreateMultipart, useUploadPart } from 'models'
import React, { useCallback, useState } from 'react'
import { useIntl } from 'react-intl'
import { Button } from 'semantic-ui-react'
import { chunkFile, getMd5Hash } from 'services/api'
import { CaptionInput, Media, MediaType, Organism } from 'services/api/graphql'
import { useStore } from 'stores'
import styled from 'styled-components'
import { notifyError } from 'tools/toaster'

const Container = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
`
const Buttons = styled.div`
  display: flex;
  justify-content: center;
`

export interface SelectVideoViewProps {
  selectedMediaId: string | null
  onDone: (media: Media) => void
  renderOrganismPicker?: boolean
  organismId?: Organism['id']
}
const SelectVideoView = observer(({ onDone, renderOrganismPicker, organismId }: SelectVideoViewProps) => {
  const intl = useIntl()
  const [videoFormOpen, setVideoFormOpen] = useState(false)
  const [newFile, setNewFile] = useState<File>()
  const [refreshFn, setRefreshFn] = useState<() => void>()
  const [createMediaVideoClip] = useCreateMediaVideoClip()
  const [loading, setLoading] = useState(false)
  const [progress, setProgress] = useState(0)

  const createMultipart = useCreateMultipart()
  const [uploadPart] = useUploadPart()
  const [completeMultipart] = useCompleteMultipart()

  const store = useStore()

  // When a file is uploaded, open the videoclip creation modal
  const onUploadFile = useCallback((uploadedFile: File, refresh: () => void) => {
    setNewFile(uploadedFile)
    setVideoFormOpen(true)
    setRefreshFn(refresh)
  }, [])
  // Once the form is validated, create videoclip & select it
  const onSubmitVideoClip = useCallback(
    async (data: MediaInfoFormData) => {
      if (!newFile) return
      const captions: CaptionInput[] = []

      setLoading(true)
      try {
        const hash = await getMd5Hash(newFile, (percent: number) => setProgress(percent))
        const clipMultipart = await createMultipart({ hash, fileName: newFile.name, extension: 'mp4' })

        if (!clipMultipart || !clipMultipart.uploadId || !clipMultipart.key) {
          setLoading(false)
          return notifyError(new Error(intl.formatMessage({ id: 'medias.error.signedPolicy' })))
        }

        await chunkFile(
          newFile,
          clipMultipart.currentPart,
          async (chunk: Blob, part: number) => {
            await uploadPart({
              variables: {
                input: {
                  hash,
                  chunk,
                  key: clipMultipart.key,
                  part,
                  uploadId: clipMultipart.uploadId,
                  size: chunk.size,
                },
              },
            })
          },
          (percent: number) => setProgress(percent),
          async () => {
            await completeMultipart({
              variables: {
                input: {
                  hash,
                  uploadId: clipMultipart.uploadId,
                },
              },
            })
          },
        )

        if (data?.captions) {
          for (const caption of data?.captions) {
            if (caption?.srtFile) {
              const hash = await getMd5Hash(caption.srtFile, (percent: number) => setProgress(percent))
              const multipart = await createMultipart({ hash, fileName: caption.srtFile.name, extension: 'srt' })

              if (!multipart || !multipart.uploadId || !multipart.key) {
                setLoading(false)
                return notifyError(new Error(intl.formatMessage({ id: 'medias.error.signedPolicy' })))
              }

              captions.push({ srcFileId: multipart.key, language: caption.language, title: caption.srtFile.name })
              await chunkFile(
                caption.srtFile,
                multipart.currentPart,
                async (chunk: Blob, part: number) => {
                  await uploadPart({
                    variables: {
                      input: {
                        hash,
                        chunk,
                        key: multipart.key,
                        part,
                        uploadId: multipart.uploadId,
                        size: chunk.size,
                      },
                    },
                  })
                },
                (percent: number) => setProgress(percent),
                async () => {
                  await completeMultipart({
                    variables: {
                      input: {
                        hash,
                        uploadId: multipart.uploadId,
                      },
                    },
                  })
                },
              )
            }
          }
        }

        const createMediaInput = {
          input: {
            media: {
              title: data.title,
              date: data.date,
              thumbnailId: data.thumbnailId,
              ownerOrganismId: store.organismId || data.ownerOrganismId,
            },
            fileId: clipMultipart.key,
            captions,
          },
        }
        const res = await createMediaVideoClip({
          variables: createMediaInput,
        })
        if (res.data) {
          if (refreshFn) refreshFn()
          onDone(res.data.createMediaVideoClip)
        }
        setVideoFormOpen(false)
        setLoading(false)
      } catch (e) {
        return notifyError(e as Error)
      } finally {
        setLoading(false)
      }
      return
    },
    [
      newFile,
      createMultipart,
      createMediaVideoClip,
      store.organismId,
      intl,
      uploadPart,
      completeMultipart,
      refreshFn,
      onDone,
    ],
  )

  return (
    <Container>
      <FilesLibraryView
        mediaType={MediaType.VideoClip}
        renderFileDetails={({ media: selectedVideo }) => {
          return (
            <Column>
              <Buttons>
                <Button
                  primary
                  onClick={() => {
                    if (selectedVideo) onDone(selectedVideo)
                  }}
                >
                  Choisir
                </Button>
              </Buttons>
            </Column>
          )
        }}
        onUploadFile={onUploadFile}
        dropAcceptedMimetypes={['video/mp4']}
        renderOrganismPicker={renderOrganismPicker}
        organismId={organismId}
      />

      <MediaInfoForm
        open={videoFormOpen}
        loading={loading}
        progress={progress}
        onCancel={() => setVideoFormOpen(false)}
        onSubmit={onSubmitVideoClip}
        defaultName={(newFile && newFile.name) || ''}
        type={MediaType.VideoClip}
        header={intl.formatMessage({ id: 'mediaInfo.create.videoClip' })}
      />
    </Container>
  )
})
export default SelectVideoView
