import { gql } from '@apollo/client'

const LEGAL_RESTRICTION_FRAGMENT = gql`
  fragment legalRestrictionContent on LegalRestriction {
    id
    active
    countries
    mature
    organismId
    organism {
      id
      title
    }
    sportItemSetIds
    sportItemSets {
      id
      title
      ownerOrganismId
    }
    offers {
      id
      title
    }
    offerIds
    restrictionTarget
    sportItemIds
    type
    description
    timeSlots {
      from
      to
    }
  }
`

export const LIST_LEGAL_RESTRICTIONS_QUERY = gql`
  query legalRestrictions {
    legalRestrictions {
      ...legalRestrictionContent
    }
  }

  ${LEGAL_RESTRICTION_FRAGMENT}
`

export const CREATE_LEGAL_RESTRICTION_MUTATION = gql`
  mutation createLegalRestriction($input: CreateLegalRestrictionInput!) {
    createLegalRestriction(input: $input) {
      ...legalRestrictionContent
    }
  }

  ${LEGAL_RESTRICTION_FRAGMENT}
`

export const UPDATE_LEGAL_RESTRICTION_MUTATION = gql`
  mutation updateLegalRestriction($id: ID!, $input: UpdateLegalRestrictionInput!) {
    updateLegalRestriction(id: $id, input: $input) {
      ...legalRestrictionContent
    }
  }

  ${LEGAL_RESTRICTION_FRAGMENT}
`

export const DELETE_LEGAL_RESTRICTION_MUTATION = gql`
  mutation deleteLegalRestriction($id: ID!) {
    deleteLegalRestriction(id: $id)
  }
`
