import { Draggable, DraggableProvided, DraggableStateSnapshot } from '@hello-pangea/dnd'
import classNames from 'classnames'
import React, { useState } from 'react'
import { createPortal } from 'react-dom'
import { Ref, Table, TableRowProps } from 'semantic-ui-react'
import { CSSProperties } from 'styled-components'
import './DraggableTableRow.css'

interface DraggableTableRowProps {
  draggableId: string
  index: number
  isDragDisabled?: boolean
  children: (provided: DraggableProvided, snapshpt: DraggableStateSnapshot) => React.ReactNode
}

const portal = document.createElement('div')
document.body.appendChild(portal)

const DraggableTableRow: React.FC<DraggableTableRowProps & TableRowProps> = ({
  draggableId,
  index,
  isDragDisabled,
  children,
  ...rowProps
}) => {
  // Used to keep width while dragging
  const [width, setWidth] = useState(0)
  const getRowStyle = (isDragging: boolean, dragStyle: CSSProperties | undefined): CSSProperties => ({
    ...dragStyle,
    ...(isDragging
      ? {
          width: `${width}px`,
        }
      : {}),
  })

  return (
    <Draggable draggableId={draggableId} index={index} isDragDisabled={isDragDisabled}>
      {(provided, snapshot) => {
        const row = (
          <Ref
            innerRef={(row?: HTMLElement | null) => {
              if (row) setWidth(row.offsetWidth)
              return provided.innerRef(row ?? null)
            }}
          >
            <Table.Row
              {...provided.draggableProps}
              {...rowProps}
              style={getRowStyle(snapshot.isDragging, provided.draggableProps.style)}
              className={classNames({ 'DraggableTableRow--dragged': snapshot.isDragging })}
            >
              {children(provided, snapshot)}
            </Table.Row>
          </Ref>
        )

        if (snapshot.isDragging) {
          return createPortal(row, portal)
        }

        return row
      }}
    </Draggable>
  )
}

export default DraggableTableRow
