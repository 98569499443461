import { QueryHookOptions, useMutation, useQuery } from '@apollo/client'
import {
  DELETE_EDITED_CATEGORY_MUTATION,
  EditedCategory,
  GET_EDITED_CATEGORIES_QUERY,
  MoveEditedCategoryInput,
  MOVE_EDITED_CATEGORY_MUTATION,
  QueryEditedCategoriesArgs,
  GET_EDITED_CATEGORY_QUERY,
} from 'services/api/graphql'

export type GetEditedCategoriesResult = {
  editedCategories: {
    editedCategories: EditedCategory[]
    totalCount: number
  }
}
export type GetEditedCategoryResult = {
  editedCategory: EditedCategory
}
export const useGetEditedCategory = (
  editedCategoryId: EditedCategory['id'],
  options?: QueryHookOptions<GetEditedCategoryResult>,
) => {
  const { data, ...rest } = useQuery<GetEditedCategoryResult>(GET_EDITED_CATEGORY_QUERY, {
    variables: { editedCategoryId },
    ...options,
    skip: !editedCategoryId,
  })
  return { editedCategory: data && data.editedCategory, ...rest }
}
export const useGetEditedCategories = (
  options?: QueryHookOptions<GetEditedCategoriesResult, QueryEditedCategoriesArgs>,
) => {
  const { data, ...rest } = useQuery(GET_EDITED_CATEGORIES_QUERY, options)
  return { data: data && data.editedCategories && data.editedCategories.editedCategories, ...rest }
}

export const useDeleteEditedCategory = () =>
  useMutation<{ deleteEditedCategory: EditedCategory }, { id: EditedCategory['id'] }>(DELETE_EDITED_CATEGORY_MUTATION)

export const useMoveEditedCategory = () => {
  return useMutation<{ moveEditedCategory: EditedCategory[] }, { input: MoveEditedCategoryInput }>(
    MOVE_EDITED_CATEGORY_MUTATION,
  )
}
