import GenericForm from 'Components/Form/GenericForm'
import SAFormField from 'Components/Form/SAFormField'
import { SelectSVEChannelInput } from 'Containers/Form/SelectSVEChannelInput'
import SelectWMTemplateInput from 'Containers/Form/SelectWMTemplateInput'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { FieldRenderProps } from 'react-final-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { Form, Input } from 'semantic-ui-react'
import {
  LiveStream,
  Scalars,
  SportItem,
  SveBroadcast,
  useSportItemQuery,
  useSportItemSetAvailableSveChannelIdsQuery,
  useSveChannelsQuery,
} from 'services/api/graphql'
import { useTemplates } from 'stores'
import { Templates } from 'stores/TemplateStore'
import { validateRequired } from 'tools/formValidators'

export interface SVEMediaLiveFormProps {
  onSubmit: (data: SVEMediaLiveFormData) => void
  onCancel: () => void
  open: boolean
  sportItemId: SportItem['id']
  initialValues?: Partial<SVEMediaLiveFormData>
  mode: 'edit' | 'create'
  createMediaLoading?: boolean
}

export interface SVEMediaLiveFormData {
  title: string
  sveChannelId: SveBroadcast['id']
  liveStreamId: LiveStream['id']
  wmClippingTemplateId: string | undefined
}

/**
 * EditSVEMediaLiveForm: Formulaire d'édition de propriétés propres à un channel Sportall
 * Champs:
 *  - media title "Nom"
 *  - live stream SVE channel id
 */
export const SVEMediaLiveForm: React.FC<SVEMediaLiveFormProps> = observer(
  ({ onSubmit, onCancel, open, sportItemId, initialValues, mode, createMediaLoading = false }) => {
    const wmTemplates: Templates = useTemplates()
    const intl = useIntl()

    const { data: sportItemData, loading: sportItemLoading } = useSportItemQuery({
      variables: { sportItemId },
      fetchPolicy: 'cache-and-network',
    })
    const sportItem = sportItemData?.sportItem

    const { data: sportItemSetAvailableChannelData } = useSportItemSetAvailableSveChannelIdsQuery({
      variables: { sportItemSetId: sportItem?.sportItemSetId ?? '' },
      skip: !sportItem?.sportItemSetId,
      fetchPolicy: 'cache-and-network',
    })

    const availableSVEChannelIds = sportItemSetAvailableChannelData?.sportItemSet?.availableSVEChannelIds
    const SVEChannelsQuery = useSveChannelsQuery({
      variables: {
        input: {
          filters: {
            channelIds: availableSVEChannelIds ?? [],
          },
        },
      },
      skip: !availableSVEChannelIds,
      fetchPolicy: 'cache-and-network',
    })
    const sveChannels = SVEChannelsQuery.data?.sveChannels.sveChannels

    const loading = sportItemLoading || SVEChannelsQuery.loading || createMediaLoading

    return (
      <GenericForm
        header={intl.formatMessage({
          id: mode === 'edit' ? `sportItemMedia.live.update` : `sportItemMedia.live.create`,
        })}
        initialValues={initialValues}
        onCancel={onCancel}
        onSubmit={onSubmit}
        loading={loading}
        open={open}
      >
        {() => {
          return (
            <>
              <SAFormField
                name="title"
                validate={validateRequired}
                render={({ input, meta }) => (
                  <Form.Field
                    control={Input}
                    {...input}
                    required
                    error={!!meta.touched && !!meta.error}
                    label={intl.formatMessage({ id: 'sportItemMedia.live.name' })}
                  />
                )}
              />
              <SAFormField
                name="sveChannelId"
                validate={validateRequired}
                render={({ input, meta }) => (
                  <Form.Field required error={(!!meta.touched || input.value) && !!meta.error}>
                    <label>
                      <FormattedMessage id="sportItemMedia.live.sveChannel" />
                    </label>
                    <SelectSVEChannelInput source={sveChannels ?? []} {...input} />
                  </Form.Field>
                )}
              />
              <SAFormField
                name="wmClippingTemplateId"
                render={({ input, meta }: FieldRenderProps<Scalars['WildmokaID'], HTMLElement>) => (
                  <Form.Field error={!!meta.touched && !!meta.error}>
                    <label>
                      <FormattedMessage id="sportItemSets.form.wilmokaLiveTemplate" />
                    </label>
                    <SelectWMTemplateInput {...input} source={wmTemplates.live} loading={wmTemplates.loading} />
                  </Form.Field>
                )}
              />
            </>
          )
        }}
      </GenericForm>
    )
  },
)
