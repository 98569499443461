import SwitchInput from 'Components/Form/SwitchInput'
import React from 'react'

import { useIntl } from 'react-intl'

export interface SelectFrontPageStatusInputProps {
  value: boolean
  onChange: (value: boolean) => void
}

const SelectFrontPageStatusInput = ({ value, onChange }: SelectFrontPageStatusInputProps) => {
  const intl = useIntl()

  const options = [
    {
      key: false,
      value: false,
      text: intl.formatMessage({ id: 'customPage.frontPage.standard' }),
    },
    {
      key: true,
      value: true,
      text: intl.formatMessage({ id: 'customPage.frontPage.carousel' }),
    },
  ]

  return <SwitchInput value={value} onChange={onChange} options={options} />
}

export default SelectFrontPageStatusInput
