export const downloadFile = async (url: string, name: string): Promise<void> => {
  const response = await fetch(url, {
    // ok hear me out, Google & S3 have some issues with CORS in cached requests, so the shortest solution here is to just ignore cache
    // https://bugs.chromium.org/p/chromium/issues/detail?id=809891
    cache: 'no-cache',
  })
  const blob = await response.blob()
  const blobURL = URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = blobURL
  a.download = name
  a.name = name
  a.target = '_blank'
  a.rel = 'noopener noreferrer'

  a.style.display = 'none'

  document.body.appendChild(a)
  a.click()
  if (a.parentNode) {
    a.parentNode.removeChild(a)
  }
}
