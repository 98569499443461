import SelectItemListInput from 'Components/Form/SelectItemListInput'
import Config from 'config'
import { ValidationErrors } from 'final-form'
import filter from 'lodash/filter'
import find from 'lodash/find'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'

import { Button, Popup } from 'semantic-ui-react'
import styled from 'styled-components'

export type SelectedTranslationsType = {
  text: string | undefined
  value: string
}

export type TranslatedInput = {
  // To avoid regenerating inputs for same labels but different languages
  // transformed into ContentTranslationInput {local, content} for graphQL validation
  [key: string]: string
}

export interface SelectLocalInputProps {
  selectedTranslations: SelectedTranslationsType[]
  activeTranslation?: string
  defaultLocale: string
  onChangeSelectedTranslations: (newSelectedTranslations: SelectedTranslationsType[]) => void
  onChangeActiveTranslation: (newActiveTranslation?: string) => void
  onChangeDefaultLocale: (newDefaultLocale: string) => void
  errors?: ValidationErrors
}

const SelectLocalInput = ({
  defaultLocale,
  onChangeDefaultLocale,
  selectedTranslations,
  onChangeSelectedTranslations,
  activeTranslation,
  onChangeActiveTranslation,
  errors,
}: SelectLocalInputProps) => {
  const intl = useIntl()
  const [displaySearchCountry, setDisplaySearchCountry] = useState(false)
  const [hoverIndex, setHoverIndex] = useState(-1)

  useEffect(() => {
    if (!selectedTranslations.length) {
      onChangeSelectedTranslations([
        {
          text: Config.availableLocalesOptions[0].text,
          value: Config.availableLocalesOptions[0].value,
        },
      ])
      onChangeActiveTranslation(Config.availableLocalesOptions[0].value)
      onChangeDefaultLocale(Config.availableLocalesOptions[0].value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTranslations.length])

  const countryOptions = useMemo(
    () =>
      filter(Config.availableLocalesOptions, el =>
        selectedTranslations ? !find(selectedTranslations, ct => ct.value === el.value) : false,
      ),
    [selectedTranslations],
  )

  const handleChangeSelectedCountries = useCallback(
    value => {
      if (!value) {
        setDisplaySearchCountry(false)
        return
      }
      if (!find(selectedTranslations, { value })) {
        setDisplaySearchCountry(false)
        onChangeSelectedTranslations([
          ...(selectedTranslations?.length > 0 ? selectedTranslations : []),
          {
            value,
            text: find(Config.availableLocalesOptions, { value })?.text,
          },
        ])
        onChangeActiveTranslation(value)
      }
    },
    [onChangeActiveTranslation, onChangeSelectedTranslations, selectedTranslations],
  )

  const handleDeleteSelectedCountry = useCallback(
    country => {
      const filtredSelectedTranslations = selectedTranslations.filter(({ value }) => value !== country.value)
      onChangeSelectedTranslations(filtredSelectedTranslations)
      if (country.value === activeTranslation) {
        onChangeActiveTranslation(
          filtredSelectedTranslations.length > 0
            ? find(selectedTranslations, el => el.value !== country.value)?.value
            : 'default',
        )
      }
    },
    [activeTranslation, onChangeActiveTranslation, onChangeSelectedTranslations, selectedTranslations],
  )

  return (
    <div className="flex flex-row flex-start">
      {selectedTranslations.map((country, index) => (
        <SelectedTranslationContainer
          key={index}
          onMouseEnter={() => setHoverIndex(index)}
          onMouseLeave={() => setHoverIndex(-1)}
        >
          <Button
            primary={country.value === activeTranslation}
            color={errors?.[index] ? 'red' : undefined}
            onClick={() => onChangeActiveTranslation(country.value)}
          >
            {country.text}
          </Button>
          {hoverIndex === index && defaultLocale !== country.value && (
            <TrashContainer>
              <Button size="mini" icon="trash" circular onClick={() => handleDeleteSelectedCountry(country)} />
            </TrashContainer>
          )}
          <DefaultContainer>
            {defaultLocale === country.value && (
              <ByDefaultMessage>{intl.formatMessage({ id: 'common.byDefault' })}</ByDefaultMessage>
            )}
            {defaultLocale !== country.value && hoverIndex === index && (
              <Button style={defaultButtonStyle} onClick={() => onChangeDefaultLocale(country.value)}>
                <SetDefaultMessage>{intl.formatMessage({ id: 'common.default' })}</SetDefaultMessage>
              </Button>
            )}
          </DefaultContainer>
        </SelectedTranslationContainer>
      ))}
      {displaySearchCountry && (
        <SearchCountryContainer>
          <SelectItemListInput
            value={undefined}
            placeholder={intl.formatMessage({ id: 'locale.chooseLocale' })}
            options={countryOptions}
            clearable
            onChange={handleChangeSelectedCountries}
            multiple={false}
          />
        </SearchCountryContainer>
      )}
      {!displaySearchCountry && selectedTranslations.length !== Config.availableLocalesOptions.length && (
        <Popup
          content={intl.formatMessage({ id: 'translation.addTranslation' })}
          trigger={
            <Button
              icon="add"
              circular
              style={addButtonStyle}
              onClick={() => !displaySearchCountry && setDisplaySearchCountry(true)}
            />
          }
        />
      )}
    </div>
  )
}

const defaultButtonStyle = {
  padding: 0,
  margin: 0,
  background: 'transparent',
}

const addButtonStyle = {
  marginLeft: 5,
  display: 'inline-block',
  height: '36px',
}

const SelectedTranslationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  position: relative;
  margin-right: 5px;
`

const TrashContainer = styled.div`
  position: absolute;
  top: -10px;
  right: -5px;
`

const DefaultContainer = styled.div`
  margin-top: 4px;
  display: flex;
  justify-content: flex-end;
  margin-right: 5px;
`

const ByDefaultMessage = styled.p`
  font-size: 0.8rem;
  font-style: italic;
  opacity: 0.4;
`

const SetDefaultMessage = styled.p`
  font-size: 0.8rem;
  opacity: 0.8;
`

const SearchCountryContainer = styled.div`
  margin-left: 5;
`

export default SelectLocalInput
