import { QueryHookOptions, QueryOptions, useApolloClient, useMutation, useQuery, useSubscription } from '@apollo/client'
import { graphql, ResultOf } from 'gql'
import { useCallback } from 'react'
import { useIntl } from 'react-intl'
import {
  CreateUserInput,
  CREATE_USER_MUTATION,
  DELETE_USER_MUTATION,
  GET_USERS_QUERY,
  GET_USER_QUERY,
  GET_USER_RGPD_DATA_QUERY,
  IMPORT_USERS_FROM_CSV_MUTATION,
  MutationImportUsersFansFromCsvArgs,
  MutationRevokeUserLicenseArgs,
  ORGANISM_LICENSED_USERS_QUERY,
  QueryUserArgs,
  QueryUsersArgs,
  REVOKE_USER_LICENSE_MUTATION,
  SCHEDULE_USERS_EXPORT,
  SortBy,
  UpdateUserInput,
  UPDATE_CACHE_RECEIPT_MUTATION,
  UPDATE_USER_MUTATION,
  UPLOAD_AVATAR_MUTATION,
  User,
  UserPageResult,
  UsersDocument,
  UsersQuery,
  UsersQueryVariables,
  USERS_EXPORT_STATE,
  UsersExportInput,
  Organism,
  QueryOrganismsArgs,
  MY_ORGANISMS_QUERY,
} from 'services/api/graphql'
import { useStore } from 'stores'
import { OrganismTitleFragment } from 'stores/fragments'
import { useGraphQLDataMap, useShortMutation } from 'tools/graphql'
import { notifyError, notifyInfo, notifySuccess } from 'tools/toaster'

import { useGetOrganismTitles } from './Organism'

/**
 * Hooks
 */
export type GetUsersResult = {
  users: UserPageResult
}

export const useGetUsersArray = (options?: QueryHookOptions<GetUsersResult, QueryUsersArgs>) => {
  const { data, ...rest } = useQuery<GetUsersResult, QueryUsersArgs>(GET_USERS_QUERY, options)
  const usersArray = data && data.users && data.users.users
  const totalCount = data && data.users && data.users.totalCount

  return { usersArray, totalCount, ...rest }
}

export const useGetUsersArraySkip = () => {
  const client = useApolloClient()

  return useCallback(
    async (variables: UsersQueryVariables) => {
      return client
        .query<UsersQuery, UsersQueryVariables>({
          query: UsersDocument,
          variables,
        })
        .then(result => {
          const { data, ...rest } = result
          const usersArray = (data && data.users && data.users.users) ?? []
          const totalCount = data && data.users && data.users.totalCount
          return { usersArray, totalCount, ...rest }
        })
    },
    [client],
  )
}
export const useGetUsersArraySkip2 = () => {
  const { refetch } = useQuery<GetUsersResult, QueryUsersArgs>(GET_USERS_QUERY, {
    skip: true,
  })

  return async (options?: QueryUsersArgs) => {
    const { data, ...rest } = await refetch(options)
    const usersArray = data && data.users && data.users.users
    const totalCount = data && data.users && data.users.totalCount

    return { usersArray, totalCount, ...rest }
  }
}

export const useGetUsers = (options?: QueryHookOptions<GetUsersResult, QueryUsersArgs>) => {
  const { usersArray, ...rest } = useGetUsersArray(options)
  const users = useGraphQLDataMap(usersArray)

  return { users, ...rest }
}

export type GetUserResult = {
  user: User | undefined
}
export const useGetUser = (userId: User['id'], options?: QueryHookOptions<GetUserResult>) => {
  const { data, ...rest } = useQuery<GetUserResult>(GET_USER_QUERY, {
    variables: { userId },
    ...options,
  })
  return { user: data && data.user, ...rest }
}

const MeOrganismIdsQuery = graphql(`
  query meOrganismIds {
    me {
      organismIds
    }
  }
`)

export const useGetMyOrganismTitles = (options?: { skip?: boolean }) => {
  const { organisms } = useGetOrganismTitles()
  const { data, ...rest } = useQuery(MeOrganismIdsQuery, options)

  return {
    organisms:
      (organisms &&
        data?.me?.organismIds &&
        data.me.organismIds.map(
          id => organisms.find(organism => organism.id === id) as NonNullable<(typeof organisms)[0]>,
        )) ??
      [],
    ...rest,
  }
}

export interface MyOrganismsResult {
  me: {
    organisms: Organism[]
  }
}

export const useGetMyOrganisms = (options?: QueryHookOptions<MyOrganismsResult, QueryOrganismsArgs>) => {
  const { data, ...rest } = useQuery<MyOrganismsResult>(MY_ORGANISMS_QUERY, options)
  return { organisms: data && data.me.organisms, ...rest }
}

export const useCreateUser = () => useMutation<{ createUser: User }, { input: CreateUserInput }>(CREATE_USER_MUTATION)
export const useUpdateUser = () => useMutation<{ updateUser: User }, { input: UpdateUserInput }>(UPDATE_USER_MUTATION)
export const useDeleteUser = () => useMutation<{ deleteUser: User }, { userId: User['id'] }>(DELETE_USER_MUTATION)
export const useUploadAvatar = () => useMutation<{ uploadAvatar: User; file: File }>(UPLOAD_AVATAR_MUTATION)
export const useScheduleUsersExport = () =>
  useMutation<{ success: boolean }, { input: UsersExportInput }>(SCHEDULE_USERS_EXPORT)

export const useGetUserRGPDData = (options?: QueryOptions<QueryUserArgs>) => {
  const client = useApolloClient()
  return async (id: string) => {
    const { data, ...rest } = await client.query<GetUserResult, QueryUserArgs>({
      query: GET_USER_RGPD_DATA_QUERY,
      variables: { id },
      ...options,
    })
    return { data: data && data.user?.RGPDFormattedData, ...rest }
  }
}

export const useUpdateCacheReceipt = () => useMutation<{ userId: User['id'] }>(UPDATE_CACHE_RECEIPT_MUTATION)

interface GetOrganismLicensedUsersVariables {
  organismId: string
  offset?: number
  first?: number
  sortBy?: SortBy
}

export const useGetOrganismLicensedUsers = (
  variables: GetOrganismLicensedUsersVariables,
  options: QueryHookOptions<GetUsersResult, GetOrganismLicensedUsersVariables>,
) =>
  useQuery<GetUsersResult, GetOrganismLicensedUsersVariables>(ORGANISM_LICENSED_USERS_QUERY, {
    ...options,
    variables,
    skip: !variables.organismId || options.skip,
  })

export const useImportUsersFansFromCsv = () =>
  useShortMutation<{ importUsersFansFromCsv: User[] }, MutationImportUsersFansFromCsvArgs>(
    IMPORT_USERS_FROM_CSV_MUTATION,
  )

export const useRevokeUserLicense = () =>
  useShortMutation<{ revokeUserLicense: User }, MutationRevokeUserLicenseArgs>(REVOKE_USER_LICENSE_MUTATION)

export const useGetUsersExportStatus = () => {
  const { data, ...rest } = useQuery(USERS_EXPORT_STATE)
  return { usersExportStatus: data && data.usersExportState, ...rest }
}

export const useGetUserOrganisms = (organismIds: User['organismIds']) => {
  const { findOrganism } = useStore()

  return {
    organisms: (organismIds?.map(id => findOrganism(id)).filter(_ => _) ?? []) as ResultOf<
      typeof OrganismTitleFragment
    >[],
  }
}

const ImportUsersStatusSubscription = graphql(`
  subscription importUsersStatus {
    importUsersStatus {
      status
      progress
      error
    }
  }
`)

export const useImportUsersStatus = () => {
  const intl = useIntl()
  useSubscription(ImportUsersStatusSubscription, {
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      if (data?.importUsersStatus) {
        const { status, progress, error } = data.importUsersStatus
        switch (status) {
          case 'starting':
            notifyInfo(intl.formatMessage({ id: 'import_users.starting' }))
            break

          case 'running':
            notifyInfo(intl.formatMessage({ id: 'import_users.running' }, { progress }))
            break

          case 'finished':
            notifySuccess(intl.formatMessage({ id: 'import_users.finished' }))
            break

          case 'error':
            notifyError(intl.formatMessage({ id: 'import_users.error' }, { error }))
            break
        }
      }
    },
  })
}
