import GenderIcon from 'Components/GenderIcon'
import UserRolesIconsList from 'Containers/UserRolesIconsList'
import { UserFragment } from 'Views/Users/fragments'
import { ResultOf } from 'gql.tada'
import React, { SyntheticEvent, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Grid, Header, Icon, List, Menu, MenuItemProps, Modal } from 'semantic-ui-react'
import { Gender, Permission, UserRoleId, User } from 'services/api/graphql'
import { useCurrentUser } from 'stores'
import styled from 'styled-components'
import { format } from 'tools/date'

import UserViewFan from './UserViewFan'
import UserViewGeneral from './UserViewGeneral'
import UserViewLicenses from './UserViewLicenses'
import UserViewOffers from './UserViewOffers'

enum UserMenuItem {
  General = 'general',
  Fan = 'fan',
  Offers = 'offers',
  Licenses = 'licenses',
}

interface UserViewProps {
  user: ResultOf<typeof UserFragment> | User
}

const EmailFlexContainer = styled.div`
  display: flex;
  > * + * {
    margin-left: 0.5rem;
  }
`

const ViewUser: React.FC<UserViewProps> = ({ user }) => {
  const [activeMenu, setActiveMenu] = useState<string | undefined>('general')
  const currentUser = useCurrentUser()

  const handleChangeMenu = (_: SyntheticEvent, { name }: MenuItemProps) => {
    setActiveMenu(name)
  }

  return (
    <Modal.Content>
      <Grid container columns={2}>
        <Grid.Column width={4}>
          <Menu fluid vertical tabular>
            <Menu.Item name="general" onClick={handleChangeMenu} active={activeMenu === UserMenuItem.General}>
              <FormattedMessage id="user.view.menu.general" />
            </Menu.Item>

            {currentUser.can(Permission.UserRead, undefined, true) && (
              <>
                <Menu.Item name="fan" onClick={handleChangeMenu} active={activeMenu === UserMenuItem.Fan}>
                  <FormattedMessage id="user.view.menu.fan" />
                </Menu.Item>

                <Menu.Item
                  name="offers"
                  disabled={!user.purchasableReceipts?.length}
                  onClick={handleChangeMenu}
                  active={activeMenu === UserMenuItem.Offers}
                >
                  <FormattedMessage id="user.view.menu.offers" />
                </Menu.Item>

                {user.licenses && user.licenses.length > 0 && (
                  <Menu.Item name="licenses" onClick={handleChangeMenu} active={activeMenu === UserMenuItem.Licenses}>
                    <FormattedMessage id="user.view.menu.licenses" />
                  </Menu.Item>
                )}
              </>
            )}
          </Menu>
        </Grid.Column>

        <Grid.Column width={12}>
          <Grid container>
            <Grid.Row columns={2}>
              <Grid.Column width={1}>
                <Icon name="user circle" size="big" />
              </Grid.Column>
              <Grid.Column width={15}>
                <Header>
                  <span>
                    {user.firstName} {user.lastName}
                    <GenderIcon gender={user.gender as Gender} />
                    <span className="ml-1 text-base text-gray-500 font-normal">@{user.chatAuthorName}</span>
                  </span>

                  <Header.Subheader>
                    <EmailFlexContainer>
                      <div>{user.email}</div>

                      <UserRolesIconsList roleIds={user.roleIds as UserRoleId[]} size="small" />
                    </EmailFlexContainer>

                    <List horizontal size="small" divided>
                      <List.Item>
                        <FormattedMessage id="users.createdAt" /> {format(user.createdAt, 'DD/MM/YYYY')}
                      </List.Item>

                      <List.Item>
                        <FormattedMessage id="users.lastActivity" />{' '}
                        {user.lastActivity ? format(user.lastActivity, 'DD/MM/YYYY') : '?'}
                      </List.Item>
                    </List>
                  </Header.Subheader>
                </Header>
              </Grid.Column>
            </Grid.Row>

            {activeMenu === UserMenuItem.General && <UserViewGeneral user={user} />}
            {activeMenu === UserMenuItem.Fan && <UserViewFan user={user} />}
            {activeMenu === UserMenuItem.Offers && <UserViewOffers user={user} />}
            {activeMenu === UserMenuItem.Licenses && <UserViewLicenses user={user} />}
          </Grid>
        </Grid.Column>
      </Grid>
    </Modal.Content>
  )
}

export default ViewUser
