import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import ReactPlayer from 'react-player'
import { Modal } from 'semantic-ui-react'
import {
  LiveStreamContentFragment,
  MediaLightContentFragment,
  useGetLivestreamPlaybackUrlQuery,
  Permission,
} from 'services/api/graphql'
import { useCurrentUser } from 'stores'
import styled from 'styled-components'

import Loader from './Loader'
import Timer from './Timer'
import EditVideo from './VideoPlayer/EditVideo'

interface LiveStreamSVEPlayerModalProps {
  liveStream: LiveStreamContentFragment
  open: boolean
  mediaToUpdate?: MediaLightContentFragment | null
  onClose: () => void
  onRefresh?: () => void
  showEditTrim?: boolean
  showEditMarkers?: boolean
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > * + * {
    margin-top: 1em;
  }
`

const Bottom = styled.div`
  width: 100%;
  text-align: right;
`

type TimerContext =
  | {
      waitingEndDate: Date
      state: 'not-started' | 'testing' | 'started'
      message: string
    }
  | {
      state: 'finished'
    }
/**
 * On souhaite utiliser soit la date de début (test), soit la date de clipping comme compte à rebours
 *
 */
export const LiveStreamSVEPlayerModal: React.FC<LiveStreamSVEPlayerModalProps> = ({
  liveStream,
  mediaToUpdate,
  showEditTrim,
  showEditMarkers,
  open,
  onClose,
  onRefresh,
}) => {
  const currentUser = useCurrentUser()
  const [currentTime, setCurrentTime] = useState<number>(0)

  const [nowDate, setNowDate] = useState(new Date(Date.now()))
  const { data, loading: loadingPlaybackUrl } = useGetLivestreamPlaybackUrlQuery({
    variables: {
      liveStreamId: liveStream.id,
    },
    fetchPolicy: 'cache-and-network',
    skip: !open,
  })
  const intl = useIntl()

  const playbackUrl = data?.liveStream?.playbackUrl

  const timerContext: TimerContext | undefined = useMemo(() => {
    const broadcast = liveStream.sveBroadcast
    if (!broadcast) return

    const scheduledStartDate = new Date(broadcast.scheduledStartDate)
    if (nowDate < scheduledStartDate) {
      return {
        waitingEndDate: scheduledStartDate,
        state: 'not-started',
        message: intl.formatMessage({ id: 'livestream.test' }),
      }
    }
    const scheduledClipStartDate = new Date(broadcast.scheduledClipStartDate)
    if (nowDate < scheduledClipStartDate) {
      return {
        waitingEndDate: scheduledClipStartDate,
        state: 'testing',
        message: intl.formatMessage({ id: 'livestream.start' }),
      }
    }
    const scheduledStopDate = new Date(broadcast.scheduledStopDate)
    if (nowDate < scheduledStopDate) {
      return {
        waitingEndDate: scheduledStopDate,
        state: 'started',
        message: intl.formatMessage({ id: 'livestream.stop' }),
      }
    }
    return {
      state: 'finished',
    }
  }, [intl, liveStream.sveBroadcast, nowDate])

  const duration = () => {
    if (liveStream?.sveBroadcast?.scheduledStartDate && liveStream?.sveBroadcast?.scheduledStopDate) {
      const scheduledStartDate = new Date(liveStream.sveBroadcast.scheduledStartDate).getTime() / 1000
      const scheduledStopDate = new Date(liveStream.sveBroadcast.scheduledStopDate).getTime() / 1000

      return Math.ceil(scheduledStopDate - scheduledStartDate)
    }
    return 0
  }

  useEffect(() => {
    if (timerContext?.state === 'finished') {
      onClose()
    }
  }, [onClose, timerContext, timerContext?.state])

  const handleTimerEnd = useCallback(() => {
    setNowDate(new Date())
  }, [])

  return (
    <Modal mountNode={document.getElementById('modals')} open={open} onClose={onClose} closeIcon size="large">
      <Modal.Content>
        <Container>
          <Loader loading={loadingPlaybackUrl}>
            {playbackUrl && (
              <ReactPlayer
                url={playbackUrl}
                playing={open}
                height="100%"
                width="100%"
                controls
                onProgress={e => setCurrentTime(e.playedSeconds)}
              ></ReactPlayer>
            )}
            <Bottom>
              {timerContext && timerContext?.state !== 'finished' && (
                <div className="flex justify-end space-x-2">
                  <div>{timerContext.message}</div>
                  <Timer end={timerContext?.waitingEndDate} onEnd={handleTimerEnd} />
                </div>
              )}
            </Bottom>
          </Loader>
        </Container>
        {currentUser.can(Permission.SportItemUpdate) && mediaToUpdate && (showEditTrim || showEditMarkers) && (
          <EditVideo
            currentTime={currentTime}
            duration={duration()}
            isLive={true}
            mediaToUpdate={mediaToUpdate}
            onRefresh={onRefresh}
            showEditTrim={showEditTrim}
            showEditMarkers={showEditMarkers}
          />
        )}
      </Modal.Content>
    </Modal>
  )
}
