import React from 'react'

import { useIntl } from 'react-intl'

import OrganismsViewBase from './OrganismsViewBase'

const OrganismsView = () => {
  const intl = useIntl()

  return (
    <OrganismsViewBase
      title={intl.formatMessage({ id: 'organism' })}
      addOrganismButtonTitle={intl.formatMessage({
        id: 'organism.form.create.title',
      })}
      addOrganismButtonSuccessTextFormat={intl.formatMessage({
        id: 'organism.form.create.success',
      })}
      editOrganismButtonTitleFormat={intl.formatMessage({
        id: 'organism.form.edit.title',
      })}
      editOrganismButtonSuccessTextFormat={intl.formatMessage({
        id: 'organism.form.edit.success',
      })}
      removeOrganismButtonConfirmTextFormat={intl.formatMessage({
        id: 'organism.form.delete.question',
      })}
      removeOrganismButtonOrganismRemovedTextFormat={intl.formatMessage({
        id: 'organism.form.delete.success',
      })}
    />
  )
}

export default OrganismsView
