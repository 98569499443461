import { FormBaseProps } from 'Forms/FormBase'
import { useGetLink, useUpdateLink } from 'models'
import React, { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { Link, UpdateLinkInput } from 'services/api/graphql'
import { getTranslationInputFromForm, getTranslationInputFromValues } from 'tools/translationTools'

import LinkForm, { LinkFormValues } from './LinkForm'

interface EditLinkFormWithLinkProps extends FormBaseProps<Link> {
  linkValues: LinkFormValues
}
const EditLinkFormWithLink = ({ linkValues, onCancel, onDone, onError }: EditLinkFormWithLinkProps) => {
  const intl = useIntl()
  const [updateLink, { loading }] = useUpdateLink()

  const handleSubmit = useCallback(
    async ({ defaultLocale: _locale, ...values }, defaultLocale, selectedTranslations) => {
      if (!linkValues.id) {
        throw new Error(`Missing Link id !`)
      }
      try {
        const input: UpdateLinkInput = {
          id: values.id,
          titleT: getTranslationInputFromForm(selectedTranslations, values.titleT),
          title: values.titleT?.[defaultLocale] || '',
          subtitleT: getTranslationInputFromForm(selectedTranslations, values.subtitleT),
          subtitle: values.subtitleT?.[defaultLocale] || '',
          countries: values.countries || [],
          descriptionT: getTranslationInputFromForm(selectedTranslations, values.descriptionT),
          description: values.descriptionT?.[defaultLocale] || '',
          categoriesIds: values.categoriesIds || [],
          publicationStatus: values.publicationStatus,
          url: values.url,
          imageId: values.imageId,
          ownerOrganismIds: values.ownerOrganismIds || [],
          teaserVideoId: values.teaserVideoId,
          billboardId: values.billboardId,
          gamTagUrl: values.gamTagUrl,
        }
        const res = await updateLink({ variables: { input } })
        if (res && res.data) onDone(res.data.updateLink)
      } catch (err: any) {
        onError?.(err)
      }
    },
    [onDone, onError, linkValues.id, updateLink],
  )

  return (
    <LinkForm
      title={intl.formatMessage({ id: 'links.form.edit.title' }, { title: linkValues.title })}
      edit
      initialValues={linkValues}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      loading={loading}
    />
  )
}

interface EditLinkFormProps extends FormBaseProps<Link> {
  linkId: Link['id']
}
const EditLinkForm = ({ linkId, ...props }: EditLinkFormProps) => {
  const { link } = useGetLink(linkId, { fetchPolicy: 'network-only' })
  if (!link) return null

  const { image, teaserVideo, billboard } = link
  const linkValues: LinkFormValues = {
    ...getTranslationInputFromValues({
      titleT: link.titleT,
      subtitleT: link.subtitleT,
      descriptionT: link.descriptionT,
    }),
    id: link.id,
    title: link.title,
    imageId: (image && image.id) || '',
    teaserVideoId: (teaserVideo && teaserVideo.id) || undefined,
    billboardId: (billboard && billboard.id) || undefined,
    gamTagUrl: link.gamTagUrl || undefined,
    defaultLocale: link.defaultLocale || undefined,
    ownerOrganismIds: link.ownerOrganismIds || [],
    subtitle: link.subtitle || undefined,
    description: link.description || undefined,
    categoriesIds: link.categoriesIds || [],
    publicationStatus: link.publicationStatus,
    tag: link.tag || undefined,
    url: link.url || undefined,
    countries: (link.countries.filter(country => country !== null) as string[]) || [],
  }
  return <EditLinkFormWithLink {...props} linkValues={linkValues} />
}
export default EditLinkForm
