import { useQuery } from '@apollo/client'
import ConfirmationButton from 'Containers/Button/ConfirmationButton'
import { UserFragment } from 'Views/Users/fragments'
import { FragmentOf, ResultOf, readFragment } from 'gql.tada'
import { observer } from 'mobx-react-lite'
import { useGetUserOrganisms, useRevokeUserLicense } from 'models'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Divider, Grid, Icon, Label, List, Loader } from 'semantic-ui-react'
import { Permission, User } from 'services/api/graphql'
import { useCurrentUser } from 'stores'
import { format } from 'tools/date'

import { GetUserDetailsQuery, UserLicensesFragment } from './fragments'

interface UserViewLicensesProps {
  user: ResultOf<typeof UserFragment> | User
}

const UserViewLicenses = observer(({ user: { id: userId } }: UserViewLicensesProps) => {
  const currentUser = useCurrentUser()
  const intl = useIntl()
  const [revokeLicense] = useRevokeUserLicense()

  const { data: userLicenses, loading } = useQuery<{ user: FragmentOf<typeof UserLicensesFragment> }>(
    GetUserDetailsQuery,
    {
      variables: { userId, offers: false, fan: false, licenses: true },
    },
  )

  const { organisms } = useGetUserOrganisms(
    userLicenses?.user &&
      readFragment(UserLicensesFragment, userLicenses.user).licenses?.map(({ organismId }) => organismId),
  )

  const licenses =
    userLicenses?.user &&
    organisms.map(({ id, title }) => {
      const license = readFragment(UserLicensesFragment, userLicenses.user).licenses?.find(
        license => license.organismId === id,
      )
      return {
        ...license,
        organism: {
          id,
          title,
        },
      }
    })

  return (
    <Grid.Row>
      <Grid.Column width={16}>
        <Divider />

        {loading ? (
          <Loader active />
        ) : (
          <List>
            {(licenses || []).map(({ registrationDate, isLicensed, organismId, organism }) => (
              <List.Item key={organismId}>
                <strong>{organism?.title || <FormattedMessage id="user.licenses.unknown_organism" />} </strong>

                <FormattedMessage
                  id="user.licenses.registrationDate"
                  values={{
                    date: registrationDate && format(registrationDate, 'DD/MM/YYYY'),
                  }}
                />

                {!isLicensed && (
                  <Label color="red" size="small">
                    <FormattedMessage id="user.licenses.revoked" />
                  </Label>
                )}

                {isLicensed && currentUser.can(Permission.RevokeLicenses, organismId) && (
                  <ConfirmationButton
                    light
                    action={async () => {
                      if (organismId) {
                        return revokeLicense({
                          userId,
                          organismId,
                        })
                      }
                    }}
                    icon="cancel"
                    confirmText={intl.formatMessage({
                      id: 'user.licenses.confirm_revoke_license',
                    })}
                    successText={intl.formatMessage(
                      { id: 'user.licenses.revoke_license_success' },
                      {
                        organism: organism?.title || '',
                      },
                    )}
                  >
                    <Icon name="ban" color="red" />
                  </ConfirmationButton>
                )}
              </List.Item>
            ))}
          </List>
        )}
      </Grid.Column>
    </Grid.Row>
  )
})

export default UserViewLicenses
