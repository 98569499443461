import config from 'config'
import { useState } from 'react'

const usePagination = (defaultActivePage?: number) => {
  const [activePage, setActivePage] = useState(defaultActivePage || 1)
  const pageSize = config.pageSize

  return {
    activePage,
    onPageChange: setActivePage,
    paginationParams: {
      offset: (activePage - 1) * pageSize,
      first: pageSize,
    },
    pageSize: pageSize,
  }
}

export default usePagination
