import { FormBaseProps } from 'Forms/FormBase'
import { useCreateNews } from 'models'
import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import { CreateNewsInput, News } from 'services/api/graphql'
import { getContentTProp, getTranslationInputFromForm } from 'tools/translationTools'

import NewsForm from './NewsForm'

interface CreateNewsFormProps extends FormBaseProps<News> {}

const CreateNewsForm: FC<CreateNewsFormProps> = ({ onCancel, onDone, onError }) => {
  const intl = useIntl()
  const [createNews, { loading }] = useCreateNews()

  return (
    <NewsForm
      title={intl.formatMessage({ id: 'news.form.create.title' })}
      edit={false}
      isModal={false}
      onSubmit={({ titleT, subtitleT, descriptionT, contentT, ...values }, defaultLocale, selectedTranslations) => {
        const jsonContentT = getContentTProp(contentT, 'json')
        const htmlContentT = getContentTProp(contentT, 'html')

        const input: CreateNewsInput = {
          titleT: getTranslationInputFromForm(selectedTranslations, titleT),
          title: titleT?.[defaultLocale] || '',
          subtitleT: getTranslationInputFromForm(selectedTranslations, subtitleT),
          subtitle: subtitleT?.[defaultLocale] || '',
          descriptionT: getTranslationInputFromForm(selectedTranslations, descriptionT),
          description: descriptionT?.[defaultLocale] || '',
          contentT: getTranslationInputFromForm(selectedTranslations, htmlContentT),
          content: htmlContentT?.[defaultLocale] || '',
          rawContentT: getTranslationInputFromForm(selectedTranslations, jsonContentT),
          rawContent: jsonContentT?.[defaultLocale] || '',
          publicationStatus: values.publicationStatus,
          coverImageId: values.imageId,
          organismId: values.organismId,
        }

        createNews({ variables: { input } })
          .then(result => {
            if (result && result.data) onDone(result.data.createNews)
          })
          .catch(onError)
      }}
      onCancel={onCancel}
      loading={loading}
    />
  )
}

export default CreateNewsForm
