import { PremiumAccess, SportItem, SportItemSet, SportItemSetType, VideoContentType } from 'models'
import React, { useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import { Icon, Popup } from 'semantic-ui-react'

interface IsLockedProps {
  sportItemSet: SportItemSet
  videoContentType: SportItem['videoContentType']
  reverseRestriction: boolean | undefined | null
}

export const IsLocked = ({ sportItemSet, videoContentType, reverseRestriction }: IsLockedProps) => {
  const isLocked = useCallback(() => {
    let isLock = false,
      hasOffer = false

    if (sportItemSet) {
      hasOffer = sportItemSet.offerIds.length > 0

      if (!hasOffer) {
        return false
      }

      // No restrictions at all
      if (sportItemSet.restrictionLevel === PremiumAccess.None) {
        isLock = false
      }

      // I have no offer OR live restricted but sportItem is PlayList -> not locked
      else if (
        sportItemSet.restrictionLevel === PremiumAccess.LiveReplay &&
        videoContentType === VideoContentType.Vod
      ) {
        isLock = false
      } else {
        isLock = true
      }
    }

    return reverseRestriction ? !isLock : isLock
  }, [reverseRestriction, sportItemSet, videoContentType])

  return isLocked() ? (
    <Popup
      trigger={<Icon name="lock" />}
      content={
        <FormattedMessage
          id={`sportItem.${sportItemSet.type === SportItemSetType.SportEvent ? 'eventOfferIds' : 'playlistOfferIds'}`}
          values={{
            offers: sportItemSet.offers && sportItemSet.offers.map(offer => offer.title).join(', '),
          }}
        />
      }
    />
  ) : null
}
