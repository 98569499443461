import { gql } from '@apollo/client'

const FRONTPAGE_FRAGMENT = gql`
  fragment frontPageContent on FrontPage {
    id
    start
    end
    state
    sportItemSetId
    sportItemSet {
      id
      title
      type
      ownerOrganism {
        id
        title
      }
    }
    sportItemId
    sportItem {
      id
      title
    }
    coverImageId
    coverImage {
      id
      image {
        id
        thumbUrl
        downloadUrl
      }
    }
    coverVideoId
    coverVideo {
      id
      videoClip {
        id
        playbackUrl
      }
    }
    titleImageId
    titleImage {
      id
      image {
        id
        thumbUrl
        downloadUrl
      }
    }
    redirectsAutomatically
    title
    description
    tags
    publicationStatus
    position
  }
`

export const LIST_FRONTPAGES_QUERY = gql`
  query frontPages($current: Boolean) {
    frontPages(current: $current) {
      ...frontPageContent
    }
  }

  ${FRONTPAGE_FRAGMENT}
`

export const CREATE_FRONT_PAGE_MUTATION = gql`
  mutation createFrontPage($input: CreateFrontPageInput!) {
    createFrontPage(input: $input) {
      ...frontPageContent
    }
  }

  ${FRONTPAGE_FRAGMENT}
`

export const UPDATE_FRONT_PAGE_MUTATION = gql`
  mutation updateFrontPage($id: ID!, $input: UpdateFrontPageInput!) {
    updateFrontPage(id: $id, input: $input) {
      ...frontPageContent
    }
  }

  ${FRONTPAGE_FRAGMENT}
`

export const DELETE_FRONT_PAGE_MUTATION = gql`
  mutation deleteFrontPage($id: ID!) {
    deleteFrontPage(id: $id)
  }
`

export const SPORTITEMSET_FRONTPAGE_FRAGMENT = gql`
  fragment sportItemSetFrontPageContent on SportItemSet {
    id
    type
    title
    ownerOrganismId
    ownerOrganism {
      id
      title
    }
    frontPages(withDeleted: true) {
      id
      start
      end
    }
    onFront
    timeOnFront
  }
`

export const SPORTITEMSETS_FRONTPAGES_QUERY = gql`
  query frontPageSportItemSets($filters: SportItemSetFilters, $orderBy: [SportItemSetSort!]) {
    sportItemSets(filters: $filters, orderBy: $orderBy) {
      sportItemSets {
        ...sportItemSetFrontPageContent
      }
      totalCount
    }
  }

  ${SPORTITEMSET_FRONTPAGE_FRAGMENT}
`
