import { useUpdateCacheReceipt } from 'models'
import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button, ButtonGroup, Header, Popup } from 'semantic-ui-react'

import { notifySuccess } from '../tools/toaster'

interface UserUpdateCacheReceiptButtonProps {
  userId?: string
}

const UserUpdateCacheReceiptButton = ({ userId }: UserUpdateCacheReceiptButtonProps) => {
  const intl = useIntl()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [updateCacheReceipt] = useUpdateCacheReceipt()

  const updateAllCacheReceipt = async () => {
    setIsLoading(true)
    try {
      const result = await updateCacheReceipt({ variables: { userId } })
      if (result) {
        notifySuccess(intl.formatMessage({ id: 'users.updateCacheReceipt.success' }))
      }
    } finally {
      setIsLoading(false)
      setIsOpen(false)
    }
  }

  return (
    <>
      {!userId ? (
        <Popup
          on="click"
          open={isOpen}
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
          position="bottom center"
          trigger={
            <Button
              color="red"
              loading={isLoading}
              content={intl.formatMessage({
                id: 'users.updateCacheReceipt',
              })}
            />
          }
          content={
            <>
              <Header>
                <FormattedMessage id="users.updateCacheReceipt.warning" />
              </Header>
              <ButtonGroup>
                <Button content={intl.formatMessage({ id: 'common.cancel' })} onClick={() => setIsOpen(false)} />
                <Button
                  primary
                  content={intl.formatMessage({ id: 'common.confirm' })}
                  onClick={updateAllCacheReceipt}
                />
              </ButtonGroup>
            </>
          }
        />
      ) : (
        <Button
          color="red"
          loading={isLoading}
          onClick={updateAllCacheReceipt}
          content={intl.formatMessage({
            id: 'users.updateCacheReceipt',
          })}
        />
      )}
    </>
  )
}

export default UserUpdateCacheReceiptButton
