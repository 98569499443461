import React from 'react'
import { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone'
import styled from 'styled-components'

const DropZone = styled.div`
  display: flex;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  background-color: #f0f0f0e0;
  padding: 16px;
  border-radius: 20px;
`
const DottedArea = styled.div`
  flex: 1;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-width: 4px;
  border-color: rgba(0, 0, 0, 0.1);
  border-style: dashed;
  border-radius: 10px;
  cursor: pointer;
`
const InvisibleInput = styled.input``

interface DropZoneViewProps {
  root: DropzoneRootProps
  input: DropzoneInputProps
}

const DropZoneView: React.FC<DropZoneViewProps> = ({ root, input, children }) => (
  <DropZone>
    <DottedArea {...root}>
      <InvisibleInput {...input} />
      {children}
    </DottedArea>
  </DropZone>
)

export default DropZoneView
