import { useCreateMediaFile } from 'models'
import { useCallback } from 'react'

import { FileMetadata, Media } from 'services/api/graphql'

export type UploadProgress = {
  loaded: number
  total: number
}
export type UploadConfig = {
  onUploadProgress: (progress: UploadProgress) => void
}
export type UseUploadFileResult = {
  uploadFile: (file: File, metadata?: FileMetadata, config?: UploadConfig) => void
  result: Media | undefined
  loading: boolean
  error: Error | undefined
}

export const useUploadFile = (ownerOrganismId?: string): UseUploadFileResult => {
  const [createMedia, { data, error, loading }] = useCreateMediaFile()
  const uploadFile = useCallback(
    (file: File, metadata?: FileMetadata, config?: UploadConfig) => {
      createMedia({
        variables: {
          input: {
            media: {
              title: file.name,
              ownerOrganismId,
            },
            file,
            metadata,
          },
        },
        context: {
          fetchOptions: {
            // TODO: Fix upload progress not working
            onUploadProgress: (progress: any) => {
              if (config) {
                config.onUploadProgress(progress)
              }
            },
          },
        },
      })
    },
    [createMedia, ownerOrganismId],
  )
  return { uploadFile, result: data ? data.createMediaFile : undefined, loading, error }
}
