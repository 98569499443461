import CreateSportItemSetForm from 'Forms/SportItemSet/CreateSportItemSetForm'
import React from 'react'
import { useIntl } from 'react-intl'
import { useLocation, useNavigate } from 'react-router-dom'
import { Segment } from 'semantic-ui-react'
import { SportItemSetType } from 'services/api/graphql'
import { notifyError, notifySuccess } from 'tools/toaster'

const AddSportItemSetButton = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const location = useLocation()

  const type = location.state?.type || false

  const sportItemSetype = type === SportItemSetType.SportEvent ? 'event' : 'playlist'

  return (
    <Segment>
      <CreateSportItemSetForm
        type={type}
        title={intl.formatMessage({
          id: `sportItemSet.${sportItemSetype}.form.create.title`,
        })}
        onCancel={() => navigate(-1)}
        onDone={sportItemSet => {
          notifySuccess(
            intl.formatMessage(
              { id: `sportItemSet.${sportItemSetype}.form.create.success` },
              { title: sportItemSet.title },
            ),
          )
          navigate(-1)
        }}
        onError={notifyError}
      />
    </Segment>
  )
}

export default AddSportItemSetButton
