import SAFormField from 'Components/Form/SAFormField'
import { SelectWMStreamInput } from 'Containers/Form/SelectWMStreamInput'
import SelectWMTemplateInput from 'Containers/Form/SelectWMTemplateInput'
import { ValidationErrors } from 'final-form'
import { ResultOf } from 'gql.tada'
import { observer } from 'mobx-react-lite'
import React, { FC } from 'react'
import { FieldRenderProps } from 'react-final-form'
import { FormattedMessage } from 'react-intl'
import { Form, Message } from 'semantic-ui-react'
import { Scalars, SportItemSetStreamConflict } from 'services/api/graphql'
import { Templates } from 'stores/TemplateStore'

import { SportItemSetSportEventFormValues } from './SportItemSetForm'
import { WmStreamFragment } from './fragments'

export type WMStreamsFormValues = {
  sportEvent: SportItemSetSportEventFormValues | undefined | null
  streamsIds: Scalars['WildmokaID'][]
}
export interface WMStreamsFieldsProps {
  organismWMStreams: ResultOf<typeof WmStreamFragment>[]
  streamsConflicts: SportItemSetStreamConflict[] | undefined
  wmTemplates: Templates
  errors: ValidationErrors
  loading: boolean
  onStreamIdsChange: (streamIds: string[]) => void
}

export const WMStreamsFields: FC<WMStreamsFieldsProps> = observer(
  ({ errors, organismWMStreams, loading, streamsConflicts, wmTemplates, onStreamIdsChange }) => {
    return (
      <div className="mt-4">
        <SAFormField
          name="streamsIds"
          render={({ input }: FieldRenderProps<Array<Scalars['WildmokaID']>, HTMLInputElement>) => (
            <Form.Field error={errors?.streamsIds}>
              <label className="mt-2">
                <FormattedMessage id="sportItemSet.streamsIds" />
              </label>
              <SelectWMStreamInput
                multiple
                {...input}
                source={organismWMStreams}
                loading={loading}
                disabled={loading}
                onChange={value => {
                  onStreamIdsChange(value as Array<Scalars['WildmokaID']>)
                  input.onChange(value)
                }}
              />

              {streamsConflicts && streamsConflicts.length > 0 && (
                <Message
                  negative
                  content={
                    errors?.streamsIds &&
                    errors.streamsIds.map((message: string) => (
                      <>
                        {message}
                        <br />
                      </>
                    ))
                  }
                />
              )}
            </Form.Field>
          )}
        />
        <SAFormField
          name="sportEvent.liveTemplateId"
          render={({ input, meta }: FieldRenderProps<Scalars['WildmokaID'], HTMLElement>) => (
            <Form.Field required error={!!meta.touched && !!meta.error}>
              <label>
                <FormattedMessage id="sportEvent.liveTemplateId" />
              </label>
              <SelectWMTemplateInput {...input} source={wmTemplates.live} loading={wmTemplates.loading} />
            </Form.Field>
          )}
        />

        <SAFormField
          name="sportEvent.clipTemplateId"
          render={({ input, meta }: FieldRenderProps<Scalars['WildmokaID'], HTMLElement>) => (
            <Form.Field required error={!!meta.touched && !!meta.error}>
              <label>
                <FormattedMessage id="sportEvent.clipTemplateId" />
              </label>
              <SelectWMTemplateInput {...input} source={wmTemplates.video} loading={wmTemplates.loading} />
            </Form.Field>
          )}
        />
        <SAFormField
          name="sportEvent.backstageTemplateId"
          render={({ input, meta }: FieldRenderProps<Scalars['WildmokaID'], HTMLElement>) => (
            <Form.Field required error={!!meta.touched && !!meta.error}>
              <label>
                <FormattedMessage id="sportEvent.backstageTemplateId" />
              </label>
              <SelectWMTemplateInput {...input} source={wmTemplates.live} loading={wmTemplates.loading} />
            </Form.Field>
          )}
        />
      </div>
    )
  },
)
