import SelectImageView from 'Containers/SelectImageView'
import React, { Fragment, useCallback, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Modal } from 'semantic-ui-react'
import { Media, MediaType, Organism } from 'services/api/graphql'
import { ImageConstraints } from 'types/image'

import SelectVideoView from './SelectVideoView'

export interface SelectFileChildrenProps {
  selectFile: (fileId: Media['id'] | null) => void
}
export interface SelectFileProps {
  imageConstraints?: ImageConstraints
  type: MediaType
  open?: boolean
  onDone: (media: Media) => void
  onCancel: () => void
  children?: (props: SelectFileChildrenProps) => JSX.Element
  header?: React.ReactNode
  renderOrganismPicker?: boolean
  organismId?: Organism['id']
}
/**
 * Select Sportall File (DB) from file library
 */
const SelectFile = ({
  children,
  header,
  open,
  onDone,
  onCancel,
  type,
  imageConstraints,
  renderOrganismPicker,
  organismId,
}: SelectFileProps) => {
  const [selectedSportallFileId, setSelectedSportallFileId] = useState<string | null>(null)
  const [selfOpen, setSelfOpen] = useState(false)

  const handleSelectFile = useCallback((mediaId: Media['id'] | null) => {
    setSelfOpen(true)
    setSelectedSportallFileId(mediaId)
  }, [])

  const handleOnClose = useCallback(() => {
    setSelfOpen(false)
    if (onCancel) onCancel()
  }, [onCancel])

  const handleValidateFile = useCallback(
    (media: Media) => {
      setSelfOpen(false)
      onDone(media)
    },
    [onDone],
  )

  return (
    <Fragment>
      {children && children({ selectFile: fileId => handleSelectFile(fileId) })}

      <Modal
        mountNode={document.getElementById('modals')}
        open={open || selfOpen}
        size="large"
        closeIcon
        closeOnDimmerClick={false}
        onClose={handleOnClose}
      >
        <Modal.Header as="h1" content={header || <FormattedMessage id="selectFile.header" />} />

        <Modal.Content style={{ height: '80vh' }}>
          {type === MediaType.Image && (
            <SelectImageView
              selectedMediaId={selectedSportallFileId}
              onDone={handleValidateFile}
              imageConstraints={imageConstraints}
              renderOrganismPicker={renderOrganismPicker}
              organismId={organismId}
            />
          )}

          {type === MediaType.VideoClip && (
            <SelectVideoView
              selectedMediaId={selectedSportallFileId}
              onDone={handleValidateFile}
              renderOrganismPicker={renderOrganismPicker}
              organismId={organismId}
            />
          )}
        </Modal.Content>
      </Modal>
    </Fragment>
  )
}
export default SelectFile
