import { useGetDefaultConfig } from 'models'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { HashLink as Link } from 'react-router-hash-link'
import { Button } from 'semantic-ui-react'
import { useStore } from 'stores'

const VideoGridView = () => {
  const intl = useIntl()
  const { defaultConfig } = useGetDefaultConfig()
  const { organismId } = useStore()

  const source = useMemo(() => {
    if (!defaultConfig?.videoGridUrl) return undefined
    if (!organismId) return defaultConfig.videoGridUrl

    const url = new URL(defaultConfig.videoGridUrl)
    url.searchParams.set('organismId', organismId)
    return url.toString()
  }, [defaultConfig?.videoGridUrl, organismId])

  return (
    <div className="h-full">
      {defaultConfig?.videoGridUrl ? (
        <iframe src={source} className="w-full h-full" allowFullScreen />
      ) : (
        <div className="pt-10 flex flex-col text-center items-center">
          <p className="text-lg md:text-lg lg:text-xl bold">{intl.formatMessage({ id: 'videoGrid.url.missing' })}</p>
          <div className="flex items-baseline justify-center space-x-2 ">
            <p className="text-lg md:text-lg lg:text-xl">{intl.formatMessage({ id: 'common.modification' })} </p>
            <Button className="w-32" compact>
              <Link className="text-black font-normal" to="/config#videoGridUrl">
                {intl.formatMessage({ id: 'common.clickHere' })}
              </Link>
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default VideoGridView
