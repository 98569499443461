import { DraggableProvided } from '@hello-pangea/dnd'
import ConfirmationButton from 'Containers/Button/ConfirmationButton'
import { applicationUris } from 'Layout/uris'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { Icon, Table } from 'semantic-ui-react'
import { EditedCategory, Permission } from 'services/api/graphql'
import { useCurrentUser } from 'stores'
import styled from 'styled-components'

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  & > :not(:last-child) {
    margin-right: 8px;
  }
`

interface EditedCategoryRowProps {
  editedCategory: EditedCategory
  draggableProvided: DraggableProvided
  onRemoveCategory: () => Promise<any>
}

const EditedCategoryViewRow = ({ editedCategory, draggableProvided, onRemoveCategory }: EditedCategoryRowProps) => {
  const intl = useIntl()
  const currentUser = useCurrentUser()

  return (
    <>
      <Table.Cell collapsing textAlign="center" {...draggableProvided.dragHandleProps}>
        <Icon name="bars" />
      </Table.Cell>
      <Table.Cell textAlign="center">{editedCategory.title}</Table.Cell>
      <Table.Cell textAlign="center">
        <FormattedMessage id={`publicationStatus.${editedCategory.published}`} />
      </Table.Cell>
      <Table.Cell textAlign="center">
        <Buttons>
          {currentUser.can(Permission.EditedCategoryUpdate) && (
            <Link to={applicationUris.editedCategory(editedCategory.id)}>
              <Icon name="edit" link size="large" inverted color="black" />
            </Link>
          )}
          {currentUser.can(Permission.EditedCategoryDelete) && (
            <ConfirmationButton
              action={onRemoveCategory}
              confirmText={intl.formatMessage({
                id: 'editedCategory.delete_confirm',
              })}
              successText={intl.formatMessage({
                id: 'editedCategory.delete_success',
              })}
            />
          )}
        </Buttons>
      </Table.Cell>
    </>
  )
}

export default EditedCategoryViewRow
