import { SelectedTranslationsType } from 'Components/Form/SelectLocalInput'
import RecapTranslationItem from 'Components/RecapTranslationItem'
import { isNil } from 'lodash'
import { useGetMedia, useListStreams } from 'models'
import React, { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button, Image, Modal } from 'semantic-ui-react'
import { Organism, Permission, useSveChannelQuery, VideoContentType, VideoEngine } from 'services/api/graphql'
import { useCurrentUser } from 'stores'
import styled from 'styled-components'
import { format } from 'tools/date'

import { SportItemFormValues } from './SportItemForm'

const Title = styled.p`
  font-size: 1.5rem;
  margin-bottom: 6px;
  font-weight: bold;
`

const Container = styled.div`
  margin-bottom: 20px;
`

interface SportItemRecapProps {
  values: SportItemFormValues
  onValidate: () => Promise<void> | void
  selectedTranslations: SelectedTranslationsType[]
  onCancel: (values: SportItemFormValues) => Promise<void> | void
  open: boolean
  videoEngine: VideoEngine
  ownerOrganismId?: Organism['id']
}

const SportItemRecap = ({
  values,
  onValidate,
  selectedTranslations,
  onCancel,
  open,
  videoEngine,
  ownerOrganismId,
}: SportItemRecapProps) => {
  const intl = useIntl()
  const currentUser = useCurrentUser()

  const { media: coverImage } = useGetMedia(values.coverImageFileId)
  const { data: streams } = useListStreams({
    skip: currentUser.can(Permission.ReadWmStreams, ownerOrganismId, true),
  })
  const stream = useMemo(() => {
    if (streams && values.sportEventItem) {
      return streams.find(str => (values.sportEventItem && str.id === values.sportEventItem.streamId) || false)
    }
    return undefined
  }, [streams, values])
  const sveChannelId = values.sportEventItem?.sveChannelId
  const { data } = useSveChannelQuery({ variables: { channelId: sveChannelId ?? '' }, skip: !sveChannelId })
  const sveChannel = data?.sveChannel

  const title = (!isNil(values.defaultLocale) && values?.titleT?.[values.defaultLocale]) || ''
  const subtitle = (!isNil(values.defaultLocale) && values?.subtitleT?.[values.defaultLocale]) || ''
  const description = (!isNil(values.defaultLocale) && values?.descriptionT?.[values.defaultLocale]) || ''

  return (
    <Modal mountNode={document.getElementById('modals')} open={open}>
      <Modal.Header>
        <FormattedMessage id="sportItem.recap_header" />
      </Modal.Header>
      <Modal.Content>
        <RecapTranslationItem
          title={intl.formatMessage({ id: 'sportItem.title' })}
          originalValue={title}
          values={selectedTranslations.map(({ value }) => ({ locale: value, value: values?.titleT?.[value] }))}
        />
        <RecapTranslationItem
          title={intl.formatMessage({ id: 'sportItem.subtitle' })}
          originalValue={subtitle}
          values={selectedTranslations.map(({ value }) => ({ locale: value, value: values?.subtitleT?.[value] }))}
        />
        <RecapTranslationItem
          title={intl.formatMessage({ id: 'sportItem.description' })}
          originalValue={description || undefined}
          values={selectedTranslations.map(({ value }) => ({ locale: value, value: values?.descriptionT?.[value] }))}
        />
        <RecapTranslationItem
          title={intl.formatMessage({ id: 'sportItem.videoContentType' })}
          originalValue={intl.formatMessage({ id: `videoContentType.${values.videoContentType}` })}
        />
        <RecapTranslationItem
          title={intl.formatMessage({ id: 'sportItem.contentType' })}
          originalValue={values.contentType && intl.formatMessage({ id: `sportItemContentType.${values.contentType}` })}
        />
        <Container>
          <Title>
            <FormattedMessage id="sportItem.coverImageFileId" />
          </Title>
          {coverImage && coverImage.image && <Image src={coverImage.image.thumbUrl} />}
        </Container>
        <RecapTranslationItem
          title={intl.formatMessage({ id: 'sportItem.publicationStatus' })}
          originalValue={intl.formatMessage({ id: `publicationStatus.${values.publicationStatus}` })}
        />

        {/* Metadata SportItemSet (SportEvent) */}
        {values.videoContentType === VideoContentType.Live && values.sportEventItem && (
          <>
            <RecapTranslationItem
              title={intl.formatMessage({ id: 'sportEventItem.testDate' })}
              originalValue={
                values.sportEventItem.testDate && format(values.sportEventItem.testDate, 'DD MMMM YYYY HH:mm')
              }
            />
            <RecapTranslationItem
              title={intl.formatMessage({ id: 'sportEventItem.startDate' })}
              originalValue={format(values.sportEventItem.startDate, 'DD MMMM YYYY HH:mm')}
            />
            <RecapTranslationItem
              title={intl.formatMessage({ id: 'sportEventItem.stopDate' })}
              originalValue={format(values.sportEventItem.stopDate, 'DD MMMM YYYY HH:mm')}
            />
            {videoEngine === VideoEngine.Wildmoka && (
              <>
                <RecapTranslationItem
                  title={intl.formatMessage({ id: 'sportEventItem.publishAutoreplay' })}
                  originalValue={intl.formatMessage({
                    id: `common.${values.sportEventItem.publishAutoreplay ? 'yes' : 'no'}`,
                  })}
                />
                <RecapTranslationItem
                  title={intl.formatMessage({ id: 'sportEventItem.streamId' })}
                  originalValue={stream && stream.name}
                />
                <RecapTranslationItem
                  title=""
                  originalValue={
                    values.sportEventItem.passthrough
                      ? intl.formatMessage({ id: 'sportEventItem.passthrough_active' })
                      : ''
                  }
                />
              </>
            )}
            {videoEngine === VideoEngine.Sportall && (
              <RecapTranslationItem
                title={intl.formatMessage({ id: 'sportEventItem.sveChannel' })}
                originalValue={sveChannel?.name}
              />
            )}
          </>
        )}
      </Modal.Content>

      <Modal.Actions>
        <Button negative onClick={() => onCancel(values)}>
          <FormattedMessage id="common.cancel" />
        </Button>
        <Button positive onClick={onValidate}>
          <FormattedMessage id="common.submit" />
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default SportItemRecap
