import { QueryHookOptions, useMutation, useQuery } from '@apollo/client'
import {
  CreateApplicationDesignInput,
  CREATE_APPLICATION_DESIGN_MUTATION,
  DELETE_APPLICATION_DESIGN_MUTATION,
  ApplicationDesign,
  GET_APPLICATION_DESIGN_QUERY,
  QueryApplicationDesignsArgs,
  UpdateApplicationDesignInput,
  UPDATE_APPLICATION_DESIGN_MUTATION,
} from 'services/api/graphql'

export type GetApplicationDesignsResult = {
  applicationDesigns: {
    applicationDesigns: ApplicationDesign[]
    totalCount: number
  }
}

export const useGetApplicationDesigns = (
  options?: QueryHookOptions<GetApplicationDesignsResult, QueryApplicationDesignsArgs>,
) => useQuery(GET_APPLICATION_DESIGN_QUERY, options)

export const useCreateApplicationDesign = () =>
  useMutation<{ createApplicationDesign: ApplicationDesign }, { input: CreateApplicationDesignInput }>(
    CREATE_APPLICATION_DESIGN_MUTATION,
  )

export const useUpdateApplicationDesign = () =>
  useMutation<{ updateApplicationDesign: ApplicationDesign }, { input: UpdateApplicationDesignInput }>(
    UPDATE_APPLICATION_DESIGN_MUTATION,
  )

export const useDeleteApplicationDesign = () =>
  useMutation<{ deleteApplicationDesign: ApplicationDesign }, { id: ApplicationDesign['id'] }>(
    DELETE_APPLICATION_DESIGN_MUTATION,
  )
