import ConfirmationButton from 'Containers/Button/ConfirmationButton'
import ApplicationDesignForm from 'Forms/ApplicationDesign/ApplicationDesignForm'
import classnames from 'classnames'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Icon, Modal, Table } from 'semantic-ui-react'
import { ApplicationDesign, Permission } from 'services/api/graphql'
import { useCurrentUser } from 'stores'
import styled from 'styled-components'

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  & > :not(:last-child) {
    margin-right: 8px;
  }
`

const ColorCircle: React.FC<{ color: string }> = ({ color }) => {
  const [isHovering, setIsHovering] = useState(false)

  const handleMouseEnter = () => {
    setIsHovering(true)
  }

  const handleMouseLeave = () => {
    setIsHovering(false)
  }
  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} className="flex justify-center items-center">
      {isHovering ? (
        <div
          className="m-auto text-base"
          style={{
            color: color === '#ffffff' ? 'black' : color,
          }}
        >
          {color}
        </div>
      ) : (
        <div
          className={classnames('m-auto w-6 h-6 rounded-xl', color === '#ffffff' ? 'border border-black' : undefined)}
          style={{
            backgroundColor: `${color}`,
          }}
        />
      )}
    </div>
  )
}

interface ApplicationDesignViewRowProps {
  applicationDesign: ApplicationDesign
  onUpdateApplicationDesign: () => Promise<any>
  onRemoveApplicationDesign: () => Promise<any>
}

const CustomPageViewRow = ({
  applicationDesign,
  onUpdateApplicationDesign,
  onRemoveApplicationDesign,
}: ApplicationDesignViewRowProps) => {
  const intl = useIntl()
  const currentUser = useCurrentUser()
  const [formModalOpen, setFormModalOpen] = useState(false)
  const { id, title } = applicationDesign
  const {
    primaryColor,
    onPrimaryColor,
    surfaceColor,
    onSurfaceColor,
    onSurfaceAlternateColor,
    top10Color,
    top10BorderColor,
  } = applicationDesign.theme

  return (
    <Table.Row key={id}>
      <Table.Cell textAlign="center" width={3}>
        {title}
      </Table.Cell>
      <Table.Cell>
        <ColorCircle color={primaryColor} />
      </Table.Cell>
      <Table.Cell>
        <ColorCircle color={onPrimaryColor} />
      </Table.Cell>
      <Table.Cell>
        <ColorCircle color={surfaceColor} />
      </Table.Cell>
      <Table.Cell>
        <ColorCircle color={onSurfaceColor} />
      </Table.Cell>
      <Table.Cell>
        <ColorCircle color={onSurfaceAlternateColor} />
      </Table.Cell>
      <Table.Cell>
        <ColorCircle color={top10Color} />
      </Table.Cell>
      <Table.Cell>{top10BorderColor && <ColorCircle color={top10BorderColor} />}</Table.Cell>
      <Table.Cell textAlign="center">
        <Buttons>
          {currentUser.can(Permission.ApplicationDesignUpdate) && (
            <Modal
              mountNode={document.getElementById('modals')}
              trigger={<Icon name="edit" link size="large" inverted color="black" />}
              content={
                <ApplicationDesignForm
                  applicationDesign={applicationDesign}
                  onDone={async () => {
                    await onUpdateApplicationDesign()
                    setFormModalOpen(false)
                  }}
                  onCancel={() => setFormModalOpen(false)}
                />
              }
              onClose={() => setFormModalOpen(false)}
              onOpen={() => setFormModalOpen(true)}
              open={formModalOpen}
            />
          )}
          {currentUser.can(Permission.ApplicationDesignDelete) && (
            <ConfirmationButton
              action={onRemoveApplicationDesign}
              confirmText={intl.formatMessage({
                id: 'applicationDesign.delete_confirm',
              })}
              successText={intl.formatMessage({
                id: 'applicationDesign.delete_success',
              })}
            />
          )}
        </Buttons>
      </Table.Cell>
    </Table.Row>
  )
}

export default CustomPageViewRow
