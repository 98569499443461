import { useApiCall } from 'hooks/api'
import React, { useCallback, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { Form, Segment } from 'semantic-ui-react'
import { forgotPassword } from 'services/api/auth'
import styled from 'styled-components'

const Container = styled.div`
  min-width: 40%;
`

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState('')
  const { execute: callForgotPassword, result: message, error, loading } = useApiCall(forgotPassword)

  const onSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      callForgotPassword(email)
    },
    [callForgotPassword, email],
  )

  return (
    <Container>
      <Segment>
        <Form onSubmit={onSubmit} error={!!error}>
          <p>
            <FormattedMessage id="forgotPassword.text" />
          </p>
          <Form.Field>
            <Form.Input
              disabled={loading}
              required
              label="Mail"
              name="email"
              placeholder="my@email.com"
              onChange={e => setEmail(e.target.value)}
            />
          </Form.Field>

          <Form.Field>
            <Form.Button primary disabled={!email || loading} type="submit" loading={loading}>
              <FormattedMessage id="forgotPassword.submit" />
            </Form.Button>
          </Form.Field>
          {message && <p>{message}</p>}
        </Form>
      </Segment>
      <Link to="/">
        <FormattedMessage id="forgotPassword.back" />
      </Link>
    </Container>
  )
}

export default ForgotPassword
