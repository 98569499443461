import PageHeader from 'Components/PageHeader'
import EditNewsForm from 'Forms/News/EditNewsForm'
import React from 'react'
import { useIntl } from 'react-intl'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Segment } from 'semantic-ui-react'
import { notifyError, notifySuccess } from 'tools/toaster'

type EditNewsButtonProps = {
  newsViewModalId?: string
  onClose?: () => void
}

const EditNewsButton = ({ newsViewModalId, onClose }: EditNewsButtonProps) => {
  const intl = useIntl()
  const { newsId } = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  const isModal = location.state?.isModal || false

  return (
    <div>
      <div className="p-4">
        <PageHeader title={intl.formatMessage({ id: 'news' })} />
      </div>
      <Segment>
        {(newsId || newsViewModalId) && (
          <EditNewsForm
            newsId={newsViewModalId || (newsId as string)}
            isModal={isModal}
            onCancel={() => (onClose ? onClose() : navigate(-1))}
            onDone={news => {
              onClose ? onClose() : navigate(-1)
              notifySuccess(intl.formatMessage({ id: 'news.form.edit.success' }, { title: news.title }))
            }}
            onError={notifyError}
          />
        )}
      </Segment>
    </div>
  )
}

export default EditNewsButton
