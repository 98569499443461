import SelectPublicationStatusInput from 'Containers/Form/SelectPublicationStatusInput'
import { useGetLightOrganism, useGetOrganismOffers } from 'models'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Field, useField } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button, Form, Icon, Message, Segment, Table } from 'semantic-ui-react'
import { OfferMode, Period, Platform, PriceInput, Product, PublicationStatus } from 'services/api/graphql'
import { useStore } from 'stores'
import { validateRequired } from 'tools/formValidators'

interface ProductsFormFieldsProps {
  mode: OfferMode
  products: Product[]
}

export enum PremiumRestriction {
  purchasableOfferId = 'purchasableOfferId',
  licensedUserOnly = 'licensedUserOnly',
}

export interface ProductFormValues {
  productId: string
  publicationStatus: PublicationStatus
  platform: Platform
  period?: Period | null
  isPremium: boolean
  premiumRestriction?: PremiumRestriction
  purchasableOfferId?: string | null
  premiumReplaceAll?: boolean
  premiumOf?: string[] | null
  prices: PriceInput[]
}

const ProductsFormFields = ({ mode, products }: ProductsFormFieldsProps) => {
  const intl = useIntl()
  const store = useStore()

  const fieldProductId = useField<Platform>('productId')
  const fieldPlateform = useField<Platform>('platform')
  const fieldPremiumRestriction = useField<PremiumRestriction>('premiumRestriction')
  const fieldPurchasableOfferId = useField<string>('purchasableOfferId')
  const fieldPremiumOf = useField<string[]>('premiumOf')
  const fieldPremiumReplaceAll = useField<boolean>('premiumReplaceAll')
  const platform = useMemo(() => fieldPlateform.input.value, [fieldPlateform.input.value])
  const disablePrices = useMemo(() => Platform.Stripe === platform, [platform])

  const { offers } = useGetOrganismOffers(store.organismId || undefined)
  const { organism } = useGetLightOrganism(store.organismId || '', {
    skip: !store.organismId,
  })

  const offersOptions = useMemo(() => {
    if (!offers) return []
    return offers.map(offer => ({
      text: offer.title,
      value: offer.id,
      key: offer.id,
    }))
  }, [offers])

  const productsByPlatform = useMemo(
    () => products.filter(product => product.platform === platform && product.productId !== fieldProductId.input.value),
    [products, platform, fieldProductId.input.value],
  )

  const productOptions = useMemo(() => {
    if (!productsByPlatform) return []
    return productsByPlatform.map(product => ({
      text: product.productId,
      value: product.productId,
      key: product.productId,
    }))
  }, [productsByPlatform])

  const [isPremium, setIsPremium] = useState<boolean | undefined>(
    !!fieldPurchasableOfferId.input.value ||
      fieldPremiumRestriction.input.value === PremiumRestriction.licensedUserOnly,
  )
  const [premiumReplaceAll, setPremiumReplaceAll] = useState<boolean | undefined>(
    fieldPremiumOf.input.value.length === 0,
  )

  // Reset premium fields when premium is Off
  useEffect(() => {
    if (!isPremium) {
      fieldPurchasableOfferId.input.onChange(null)
      fieldPremiumOf.input.onChange(null)
      fieldPremiumRestriction.input.onChange(PremiumRestriction.purchasableOfferId)
    }
  }, [fieldPremiumOf.input, fieldPremiumRestriction.input, fieldPurchasableOfferId.input, isPremium])

  // When licenceUser is selected, clear purchasableOfferId
  useEffect(() => {
    if (fieldPremiumRestriction.input.value === PremiumRestriction.licensedUserOnly) {
      fieldPurchasableOfferId.input.onChange(null)
    }
  }, [fieldPremiumRestriction.input.value, fieldPurchasableOfferId.input])

  const handlePremiumReplaceAll = useCallback(
    (checked: boolean | undefined) => {
      fieldPremiumOf.input.onChange(null)
      fieldPremiumReplaceAll.input.onChange(checked)
      setPremiumReplaceAll(checked)
    },
    [fieldPremiumOf.input, fieldPremiumReplaceAll.input],
  )

  return (
    <Form>
      <Field
        name="productId"
        type="text"
        validate={validateRequired}
        render={({ input, meta }) => (
          <Form.Input
            {...input}
            required
            error={meta.touched && meta.error}
            label={intl.formatMessage({
              id: 'product.productId',
            })}
          />
        )}
      />
      <Field
        name="publicationStatus"
        validate={validateRequired}
        render={({ input, meta }) => (
          <Form.Field
            control={SelectPublicationStatusInput}
            {...input}
            error={meta.touched && meta.error}
            label={intl.formatMessage({
              id: 'purchasableOffer.publicationStatus',
            })}
          />
        )}
      />
      <Field
        name="platform"
        type="text"
        validate={validateRequired}
        render={({ input, meta }) => (
          <Form.Dropdown
            name={input.name}
            value={input.value}
            required
            selection
            error={meta.touched && meta.error}
            options={Object.values(Platform).map(item => ({
              value: item,
              text: item,
            }))}
            label={intl.formatMessage({
              id: 'product.platform',
            })}
            onChange={(e, { value }) => input.onChange(value)}
          />
        )}
      />
      {mode === OfferMode.Subscription && (
        <Field
          name="period"
          render={({ input }) => (
            <Form.Dropdown
              name={input.name}
              value={input.value}
              selection
              options={Object.values(Period).map(item => ({
                value: item,
                text: intl.formatMessage({ id: `date.${item}` }),
              }))}
              label={intl.formatMessage({
                id: 'product.period',
              })}
              onChange={(e, { value }) => input.onChange(value)}
            />
          )}
        />
      )}
      <Segment color="grey">
        <Form.Checkbox
          name="isPremium"
          label={intl.formatMessage({ id: 'product.isPremium' })}
          checked={isPremium}
          onChange={(e, { checked }) => setIsPremium(checked)}
        />
        {isPremium && (
          <>
            <Field
              name="premiumRestriction"
              validate={isPremium && validateRequired}
              allowNull
              render={({ input, meta }) => (
                <Form.Dropdown
                  name={input.name}
                  value={input.value}
                  selection
                  required
                  disabled={!organism || !organism.license || !organism.license.enabled}
                  options={Object.values(PremiumRestriction).map(item => ({
                    value: item,
                    text: intl.formatMessage(
                      { id: `product.premiumRestriction.${item}` },
                      { organism: (organism && organism.title) || '' },
                    ),
                  }))}
                  label={intl.formatMessage({
                    id: 'product.premiumRestriction',
                  })}
                  error={meta.touched && meta.error}
                  onChange={(e, { value }) => input.onChange(value)}
                />
              )}
            />

            {fieldPremiumRestriction.input.value === PremiumRestriction.purchasableOfferId && (
              <Field
                name="purchasableOfferId"
                validate={isPremium && validateRequired}
                allowNull
                render={({ input, meta }) => (
                  <Form.Dropdown
                    name={input.name}
                    value={input.value}
                    selection
                    required
                    clearable={true}
                    options={offersOptions}
                    label={intl.formatMessage({
                      id: 'product.purchasableOfferId',
                    })}
                    error={meta.touched && meta.error}
                    onChange={(e, { value }) => input.onChange(value)}
                  />
                )}
              />
            )}

            <Form.Checkbox
              name="premiumReplaceAll"
              label={intl.formatMessage({ id: 'product.premiumReplaceAll' }, { platform })}
              checked={premiumReplaceAll}
              onChange={(e, { checked }) => handlePremiumReplaceAll(checked)}
            />
            {!premiumReplaceAll && (
              <Field
                name="premiumOf"
                render={({ input, meta }) => (
                  <Form.Dropdown
                    name={input.name}
                    value={input.value}
                    selection
                    multiple
                    required
                    disabled={!platform}
                    clearable={true}
                    options={productOptions}
                    label={intl.formatMessage({
                      id: 'product.premiumOf',
                    })}
                    error={meta.touched && meta.error}
                    onChange={(e, { value }) => input.onChange(value)}
                  />
                )}
              />
            )}
          </>
        )}
      </Segment>
      {platform === Platform.Stripe && (
        <Message visible warning>
          <Message.Header>
            <FormattedMessage id="purchasableOffer.stripe.warning.header" />
          </Message.Header>
          <FormattedMessage id="purchasableOffer.stripe.warning" />
        </Message>
      )}
      <FieldArray name="prices">
        {({ fields, meta: metaField }) => (
          <Table striped>
            <Table.Header>
              <Table.Row negative={!!(metaField.touched && metaField.error)}>
                <Table.HeaderCell colSpan={3}>
                  {intl.formatMessage({
                    id: 'product.prices',
                  })}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {fields.map((name: string, index: number) => (
                <Table.Row key={index}>
                  <Table.Cell>
                    <Field
                      name={`${name}.value`}
                      parse={value => parseFloat(value)}
                      required
                      validate={validateRequired}
                      render={({ input, meta }) => (
                        <Form.Input
                          {...input}
                          type="number"
                          step="0.01"
                          min="0"
                          disabled={disablePrices}
                          error={meta.touched && meta.error}
                          label={intl.formatMessage({
                            id: 'product.price.value',
                          })}
                        />
                      )}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <Field
                      name={`${name}.currency`}
                      render={({ input }) => (
                        <Form.Field
                          control="input"
                          disabled={disablePrices}
                          {...input}
                          label={intl.formatMessage({
                            id: 'product.price.currency',
                          })}
                        />
                      )}
                    />
                  </Table.Cell>
                  {!disablePrices && (
                    <Table.Cell verticalAlign="bottom">
                      <Button icon onClick={() => fields.remove(index)} size="small">
                        <Icon name="remove" />
                      </Button>
                    </Table.Cell>
                  )}
                </Table.Row>
              ))}
            </Table.Body>
            {!disablePrices && (
              <Table.Footer>
                <Table.Row>
                  <Table.Cell colSpan={3}>
                    <Button icon onClick={() => fields.push({})}>
                      <Icon name="add" />
                    </Button>
                  </Table.Cell>
                </Table.Row>
              </Table.Footer>
            )}
          </Table>
        )}
      </FieldArray>
    </Form>
  )
}

export default ProductsFormFields
