import SelectItemListInput from 'Components/Form/SelectItemListInput'
import { SVEChannelStateTag } from 'Components/SVEChannelStateTag'
import ConfirmationButton from 'Containers/Button/ConfirmationButton'
import { SelectSVEChannelsInput } from 'Containers/Form/SelectSVEChannelsInput'
import { SelectWMStreamInput } from 'Containers/Form/SelectWMStreamInput'
import { ResultOf } from 'gql.tada'
import { useGetDefaultConfig } from 'models'
import { useGetOutputStreams } from 'models/ContentPushStream'
import React, { FC, useEffect, useState } from 'react'
import { FieldArray, FieldArrayRenderProps } from 'react-final-form-arrays'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button, Form, Message, Segment } from 'semantic-ui-react'
import { OutputStream, Permission, SportItemSetSveChannelAvailability, SveStream } from 'services/api/graphql'
import { useCurrentUser, useStore } from 'stores'

import { WmStreamFragment } from './fragments'

export interface SVEStreamsFieldsProps {
  name: string
  availableWMStreams: ResultOf<typeof WmStreamFragment>[]
  availableSVEChannels?: SportItemSetSveChannelAvailability[]
  hasLiveRunning?: boolean
  showScreachInputStream?: boolean
}
export const SVEStreamsFields: FC<SVEStreamsFieldsProps> = ({
  name,
  availableWMStreams,
  availableSVEChannels,
  hasLiveRunning,
  showScreachInputStream = false,
}) => {
  const intl = useIntl()
  const { defaultConfig } = useGetDefaultConfig()
  const { organismId } = useStore()
  const currentUser = useCurrentUser()
  const [currentSVEChannel, setCurrentSVEChannel] = useState<SportItemSetSveChannelAvailability | undefined>()
  const [currentWMStreamId, setCurrentWMStreamId] = useState<string>()
  const { data: outputStreams, loading: outputStreamsLoading } = useGetOutputStreams({
    skip: !currentUser.can(Permission.ContentPushStreamRead, organismId || undefined, true),
  })
  const options = (availableOS: OutputStream[], outputStream?: OutputStream) => [
    ...availableOS.map(stream => ({
      key: stream.id,
      value: stream.id,
      text: stream.name,
    })),
    outputStream
      ? {
          key: outputStream.id,
          value: outputStream.id,
          text: outputStream.name,
        }
      : {},
  ]

  useEffect(() => {
    setCurrentSVEChannel(undefined)
  }, [availableSVEChannels])

  return (
    <FieldArray name={name}>
      {({ fields }: FieldArrayRenderProps<SveStream, HTMLElement>) => {
        const sveStreams = fields?.value || []
        const fieldsSVEChannelIds = sveStreams.map(({ sveChannelId }) => sveChannelId)
        const leftChannels = availableSVEChannels?.filter(channel => !fieldsSVEChannelIds.includes(channel.id))
        const usedOutputStreamIds = fields?.value?.map(os => os.outputStreamId) || []
        const availableOS = outputStreams.filter(os => !usedOutputStreamIds.includes(os.id))

        return (
          <>
            <div className="flex flex-row mt-4">
              <p className="font-bold mr-2">
                <FormattedMessage id="sportItemSets.form.sveStreams" />
              </p>
              {defaultConfig && (
                <span className="italic">
                  <FormattedMessage
                    id="sportItemSets.form.SVEChannelDelayMountingTip"
                    values={{
                      hour: defaultConfig.sveChannelDelayHour.mounting,
                    }}
                  />
                </span>
              )}
            </div>

            <Form.Field>
              <Message visible={currentSVEChannel ? currentSVEChannel?.available === false : false} error>
                <FormattedMessage
                  id="sportItemSets.form.SVEChannelUnavailable"
                  values={{ channelName: currentSVEChannel?.name }}
                />
              </Message>
              <div className="flex items-center space-x-4 mb-4">
                <SelectSVEChannelsInput
                  style={{ width: 400 }}
                  source={leftChannels ?? []}
                  values={leftChannels}
                  value={currentSVEChannel?.id}
                  onChange={value => {
                    const inputChannelId = Array.isArray(value) ? value[0] : value
                    setCurrentSVEChannel(
                      availableSVEChannels?.find(channel => channel.id === inputChannelId) ?? undefined,
                    )
                  }}
                />
                <Button
                  primary
                  disabled={!currentSVEChannel || (currentSVEChannel && currentSVEChannel.available === false)}
                  onClick={e => {
                    if (!currentSVEChannel) return
                    if (currentSVEChannel.available === false) return
                    e.preventDefault()
                    setCurrentSVEChannel(undefined)
                    setCurrentWMStreamId(undefined)
                    fields.push({
                      sveChannelId: currentSVEChannel.id,
                      socialNetworkWMStreamId: currentWMStreamId,
                    })
                  }}
                >
                  {intl.formatMessage({
                    id: 'common.add',
                  })}
                </Button>
              </div>
            </Form.Field>

            {sveStreams.length > 0 &&
              fields.map((name, index) => {
                const sveStream = sveStreams[index]
                const sveChannel = availableSVEChannels?.find(sveChannel => {
                  if (sveChannel.available === false && sveStream.sveChannelId === sveChannel.id) {
                    fields.remove(index)
                    return
                  }
                  return sveChannel.id === sveStream.sveChannelId
                })
                const wmStream = availableWMStreams.find(wmStream => wmStream.id === sveStream.socialNetworkWMStreamId)
                const sveStreamWMStreamIds = sveStreams.map(({ socialNetworkWMStreamId }) => socialNetworkWMStreamId)
                const selectableWMStreams = availableWMStreams.filter(
                  wmStream =>
                    !sveStreamWMStreamIds.includes(wmStream.id) || sveStream.socialNetworkWMStreamId === wmStream.id,
                )
                const outputStream = outputStreams.find(os => os.id === sveStream.outputStreamId)

                return (
                  <Segment key={`${name}${index}`} disabled={hasLiveRunning}>
                    <div className="flex">
                      <div className="flex flex-col flex-1 ">
                        <div className="flex flex-row items-center">
                          <div className="mr-2">{sveChannel && <SVEChannelStateTag state={sveChannel.status} />}</div>
                          {sveChannel?.name}
                        </div>
                        <div className="py-2 pl-6 flex space-x-2 flex-1 items-center">
                          <div>Wildmoka Input Stream</div>
                          <SelectWMStreamInput
                            value={wmStream?.id}
                            disabled={hasLiveRunning}
                            onChange={value => {
                              fields.update(index, {
                                sveChannelId: sveStream.sveChannelId,
                                socialNetworkWMStreamId: Array.isArray(value) ? value?.[0] : value,
                                outputStreamId: sveStream.outputStreamId,
                              })
                              setCurrentWMStreamId(wmStream?.id)
                            }}
                            source={selectableWMStreams}
                          />
                        </div>
                        {showScreachInputStream && (
                          <div className="py-2 pl-6 flex space-x-2 flex-1 items-center">
                            <div>{intl.formatMessage({ id: 'contentPushStream.screachInput' })}</div>
                            <SelectItemListInput
                              options={options(availableOS, outputStream)}
                              disabled={hasLiveRunning}
                              loading={outputStreamsLoading}
                              value={outputStream?.id}
                              onChange={value => {
                                fields.update(index, {
                                  sveChannelId: sveStream.sveChannelId,
                                  socialNetworkWMStreamId: wmStream?.id,
                                  outputStreamId: value,
                                })
                              }}
                              source={availableOS}
                            />
                          </div>
                        )}
                      </div>
                      <ConfirmationButton
                        action={async () => {
                          fields.remove(index)
                        }}
                        confirmText={intl.formatMessage({
                          id: 'sportItemSets.form.confirm.sveStreamRemoval',
                        })}
                        successText={intl.formatMessage({
                          id: 'sportItemSets.form.confirm.sveStreamRemoved',
                        })}
                        notifySuccess={false}
                      />
                    </div>
                  </Segment>
                )
              })}
          </>
        )
      }}
    </FieldArray>
  )
}
