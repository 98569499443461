import EditOrganismForm from 'Forms/Organism/EditOrganismForm'
import React, { Fragment, useState } from 'react'
import { Organism } from 'services/api/graphql'
import { notifyError, notifySuccess } from 'tools/toaster'

export interface EditOrganismButtonProps {
  organismId: Organism['id']
  titleFormat: string // {{title}} will be replaced by organisme title
  successTextFormat: string // {{title}} replaced with organisme title
  editType: boolean
  onDone?: () => void
  render: (onClick: () => void) => React.ReactNode
}

const EditOrganismButton = ({
  titleFormat,
  successTextFormat,
  editType,
  onDone,
  organismId,
  render,
}: EditOrganismButtonProps) => {
  const [form, setForm] = useState<React.ReactNode | null>()

  const handleClick = () => {
    setForm(
      organismId && (
        <EditOrganismForm
          titleFormat={titleFormat}
          editType={editType}
          organismId={organismId}
          onCancel={() => setForm(null)}
          onDone={organism => {
            setForm(null)
            notifySuccess(successTextFormat.replace('{{title}}', organism.title))
            if (onDone) onDone()
          }}
          onError={notifyError}
        />
      ),
    )
  }
  return (
    <Fragment>
      {render(handleClick)}
      {form}
    </Fragment>
  )
}

export default EditOrganismButton
