import { applicationUris } from 'Layout/uris'
import AdCampaignsView from 'Views/AdCampaignsView'
import LinksAnalyticsView from 'Views/Analytics/Links'
import LinksView from 'Views/LinksView'
import React from 'react'
import { Access, userCanAccessToPath } from 'services/api'
import { IUser } from 'stores'

const affiliationsRoutes = (user: IUser | null) => [
  {
    path: applicationUris.links,
    element: <LinksView />,
    loader: () => userCanAccessToPath(user, [Access.Links]),
  },
  {
    path: applicationUris.adCampaigns,
    element: <AdCampaignsView />,
    loader: () => userCanAccessToPath(user, [Access.AdCampaigns]),
  },
  {
    path: applicationUris.linksAnalytics,
    element: <LinksAnalyticsView />,
    loader: () => userCanAccessToPath(user, [Access.LinksStatistics]),
  },
]

export default affiliationsRoutes
