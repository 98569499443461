import CopyButton from 'Components/CopyButton'
import { SVEChannelStateTag } from 'Components/SVEChannelStateTag'
import React, { Fragment, useCallback, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Header, Icon, List, Modal, Table } from 'semantic-ui-react'
import {
  LiveStreamContentFragment,
  Permission,
  UserRoleId,
  useSveChannelQuery,
  VideoEngine,
} from 'services/api/graphql'
import { useCurrentUser, useStore } from 'stores'
import { formatSimpleDateTime } from 'tools/date'

export interface LiveStreamSVEDetailsModalProps {
  livestream: LiveStreamContentFragment
  open: boolean
  onClose: () => void
}

export const LiveStreamSVEDetailsModal: React.FC<LiveStreamSVEDetailsModalProps> = ({ livestream, open, onClose }) => {
  const currentUser = useCurrentUser()
  const { organismId } = useStore()
  const intl = useIntl()

  const dateAndScheduledDateString = useCallback(
    ({ date, scheduledDate }: { date: Date | undefined | null; scheduledDate?: Date | undefined | null }): string => {
      const scheduledDateString = scheduledDate
        ? `${intl.formatMessage(
            { id: 'livestream.sve.channel.scheduledDate' },
            { date: formatSimpleDateTime(scheduledDate) },
          )}`
        : ``
      if (!date) {
        if (!scheduledDateString) return ''
        return scheduledDateString
      }
      return `${formatSimpleDateTime(date)}${scheduledDateString ? ` (${scheduledDateString})` : ''}`
    },
    [intl],
  )
  const { data } = useSveChannelQuery({
    variables: { channelId: livestream.sveChannelId ?? '' },
    skip: !livestream.sveChannelId,
  })
  const sveChannel = data?.sveChannel
  const sveBroadcast = livestream.sveBroadcast

  const playbackUrlPreview = useMemo(() => {
    const matches = livestream.playbackUrl.match(/^(.+)\?token=(.+)$/)
    if (!matches) return livestream.playbackUrl
    const [_, url, token] = matches
    return `${url}?token=${token.substr(0, 6)}…`
  }, [livestream])

  return (
    <Modal mountNode={document.getElementById('modals')} open={open} onClose={onClose} closeIcon>
      <Modal.Content>
        <List>
          {livestream.videoEngine !== VideoEngine.Sportall && <FormattedMessage id="livestream.not_sve_liveStream" />}
          <Header as="h2">
            <FormattedMessage id="sportItemSets.form.sveEngine.title" />
          </Header>
          {/* Channel */}
          <List.Item>
            <List.Header>
              <div className="flex space-x-2 items-baseline mb-2">
                <div>
                  <FormattedMessage id="livestream.sve.channel.id" />
                </div>
                {sveChannel && <SVEChannelStateTag state={sveChannel.status} />}
              </div>
            </List.Header>
            <List.Description>{sveChannel?.id}</List.Description>
          </List.Item>
          <List.Item>
            <List.Header>
              <FormattedMessage id="livestream.sve.channel" />
            </List.Header>
            <List.Description>{sveChannel?.name}</List.Description>
          </List.Item>
          {sveChannel?.endpoints && (
            <>
              <List.Item>
                <List.Header>
                  <FormattedMessage id="livestream.sve.ingestUrl" />
                  {sveChannel.endpoints.rtmpInputUrl && (
                    <CopyButton
                      value={sveChannel.endpoints.rtmpInputUrl}
                      notificationSuccess={intl.formatMessage({
                        id: 'livestream.sve.ingestUrl_copied',
                      })}
                    >
                      <Icon name="copy" />
                    </CopyButton>
                  )}
                </List.Header>
                <List.Description>{sveChannel.endpoints.rtmpInputUrl}</List.Description>
              </List.Item>
            </>
          )}
          {currentUser.can(Permission.EditLiveTokenExpiration, organismId || undefined) &&
            playbackUrlPreview !== '' && (
              <List.Item>
                <List.Header>
                  <FormattedMessage id="livestream.playbackUrl" />
                  <CopyButton
                    value={livestream.playbackUrl}
                    notificationSuccess={intl.formatMessage({
                      id: 'livestream.playbackUrl_copied',
                    })}
                  >
                    <Icon name="copy" />
                  </CopyButton>
                </List.Header>
                <List.Description>
                  <p className="truncate">{playbackUrlPreview}</p>
                </List.Description>
              </List.Item>
            )}
          <List.Item>
            <List.Header>
              <FormattedMessage id="livestream.sve.outputSocialNetwork" />
            </List.Header>
            <List.Description>
              {sveChannel?.socialOutputUrl ? <FormattedMessage id="common.yes" /> : <FormattedMessage id="common.no" />}
            </List.Description>
          </List.Item>

          {/* Broadcast */}
          <List.Item>
            <List.Header>
              <FormattedMessage id="livestream.sve.broadcast.id" />
            </List.Header>
            <List.Description>{sveBroadcast?.id || livestream.sveBroadcastId}</List.Description>
          </List.Item>
          {currentUser?.user?.roleIds.some(roleId =>
            [UserRoleId.Admin, UserRoleId.SuperAdmin].includes(roleId as UserRoleId),
          ) && (
            <>
              {sveBroadcast?.status && (
                <List.Item>
                  <List.Header>
                    <FormattedMessage id="livestream.sve.broadcast.state" />
                  </List.Header>
                  <List.Description>
                    <div className="flex space-x-2">
                      <FormattedMessage id={`sveBroadcasts.state.${sveBroadcast?.status}`} />
                    </div>
                  </List.Description>
                </List.Item>
              )}
              {sveBroadcast && (
                <>
                  <List.Item>
                    <List.Header>
                      <FormattedMessage id="livestream.sve.broadcast.startDate" />
                    </List.Header>
                    <List.Description>
                      <div className="flex space-x-2">
                        {dateAndScheduledDateString({
                          date: sveBroadcast?.startDate,
                          scheduledDate: sveBroadcast?.scheduledStartDate,
                        })}
                      </div>
                    </List.Description>
                  </List.Item>
                  <List.Item>
                    <List.Header>
                      <FormattedMessage id="livestream.sve.broadcast.clipStartDate" />
                    </List.Header>
                    <List.Description>
                      <div className="flex space-x-2">
                        {dateAndScheduledDateString({
                          date: sveBroadcast?.clipStartDate,
                          scheduledDate: sveBroadcast?.scheduledClipStartDate,
                        })}
                      </div>
                    </List.Description>
                  </List.Item>
                  <List.Item>
                    <List.Header>
                      <FormattedMessage id="livestream.sve.broadcast.stopDate" />
                    </List.Header>
                    <List.Description>
                      <div className="flex space-x-2">
                        {dateAndScheduledDateString({
                          date: sveBroadcast?.stopDate,
                          scheduledDate: sveBroadcast?.scheduledStopDate,
                        })}
                      </div>
                    </List.Description>
                  </List.Item>
                </>
              )}
            </>
          )}
        </List>
        {livestream.sveLiveMonitoring?.stats && livestream.sveLiveMonitoring?.alerts && (
          <Fragment>
            <Header as="h2">Live Monitoring</Header>
            <Table className="flex flex-1">
              <Table.Header>
                <Table.Row textAlign="center">
                  {/* Do not translate alerts fields */}
                  <Table.HeaderCell width={1}>Initial Probe Taking Longer</Table.HeaderCell>
                  <Table.HeaderCell width={1}>Audio Not Detected</Table.HeaderCell>
                  <Table.HeaderCell width={1}>Video Not Detected</Table.HeaderCell>
                  <Table.HeaderCell width={1}>RTMP Has No Audio/Video</Table.HeaderCell>
                  <Table.HeaderCell width={1}>RTMP Stream Not Found</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Cell textAlign="center">
                  {livestream.sveLiveMonitoring?.alerts?.initialProbeTakingLonger || 'N/A'}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {livestream.sveLiveMonitoring?.alerts?.audioNotDetected || 'N/A'}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {livestream.sveLiveMonitoring?.alerts?.videoNotDetected || 'N/A'}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {livestream.sveLiveMonitoring?.alerts?.RTMPHasNoAudioVideo || 'N/A'}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {livestream.sveLiveMonitoring?.alerts?.RTMPStreamNotFound || 'N/A'}
                </Table.Cell>
              </Table.Body>
            </Table>
            <Table className="flex flex-1">
              <Table.Header>
                <Table.Row textAlign="center">
                  <Table.HeaderCell width={1}>Network In</Table.HeaderCell>
                  <Table.HeaderCell width={1}>Dropped Frames</Table.HeaderCell>
                  <Table.HeaderCell width={1}>Input Video Frame Rate</Table.HeaderCell>
                  <Table.HeaderCell width={1}>OutputAudio Level Lkfs</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Cell textAlign="center">{livestream.sveLiveMonitoring?.stats?.networkIn || 'N/A'}</Table.Cell>
                <Table.Cell textAlign="center">
                  {livestream.sveLiveMonitoring?.stats?.droppedFrames || 'N/A'}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {livestream.sveLiveMonitoring?.stats?.inputVideoFrameRate || 'N/A'}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {livestream.sveLiveMonitoring?.stats?.outputAudioLevelLkfs || 'N/A'}
                </Table.Cell>
              </Table.Body>
            </Table>
          </Fragment>
        )}
      </Modal.Content>
    </Modal>
  )
}
