import React from 'react'
import * as icons from 'react-icons/md'

export type MaterialIcon = keyof typeof icons

export const renderMaterialIcon = (name: MaterialIcon) => {
  if (!name) return

  const Icon = icons[name]
  return Icon && <Icon size={40} />
}
