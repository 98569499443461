import { gql } from '@apollo/client'

import { CONTENT_TRANSLATION_FRAGMENT } from './ContentTranslation'

export const EDITED_CATEGORY_FRAGMENT = gql`
  fragment editedCategoryContent on EditedCategory {
    id
    organismId
    title
    titleT {
      ...contentTranslationContent
    }
    defaultLocale
    published
    position
    items {
      sportItemId
      sportItemSetId
      newsId
      linkId
    }
  }
  ${CONTENT_TRANSLATION_FRAGMENT}
`

export const GET_EDITED_CATEGORIES_QUERY = gql`
  query editedCategories($filters: EditedCategoryFilters) {
    editedCategories(filters: $filters) {
      editedCategories {
        ...editedCategoryContent
      }
      totalCount
    }
  }
  ${EDITED_CATEGORY_FRAGMENT}
`
export const GET_EDITED_CATEGORY_QUERY = gql`
  query editedCategory($editedCategoryId: ID!) {
    editedCategory(id: $editedCategoryId) {
      ...editedCategoryContent
    }
  }
  ${EDITED_CATEGORY_FRAGMENT}
`
export const CREATE_EDITED_CATEGORY_MUTATION = gql`
  mutation createEditedCategory($input: CreateEditedCategoryInput!) {
    createEditedCategory(input: $input) {
      ...editedCategoryContent
    }
  }
  ${EDITED_CATEGORY_FRAGMENT}
`

export const UPDATE_EDITED_CATEGORY_MUTATION = gql`
  mutation updateEditedCategory($id: ID!, $input: UpdateEditedCategoryInput!) {
    updateEditedCategory(id: $id, input: $input) {
      ...editedCategoryContent
    }
  }
  ${EDITED_CATEGORY_FRAGMENT}
`

export const DELETE_EDITED_CATEGORY_MUTATION = gql`
  mutation deleteEditedCategory($id: ID!) {
    deleteEditedCategory(id: $id) {
      id
    }
  }
`

export const MOVE_EDITED_CATEGORY_MUTATION = gql`
  mutation moveEditedCategory($input: MoveEditedCategoryInput!) {
    moveEditedCategory(input: $input) {
      id
      position
    }
  }
`
