import { useApiCall } from 'hooks/api'
import { useLogin } from 'hooks/auth'
import { parse } from 'query-string'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useLocation } from 'react-router-dom'
import { Button, Container, Form, Icon, Segment } from 'semantic-ui-react'
import { checkTokenReset, resetPassword } from 'services/api/auth'

const ResetPassword: FC = () => {
  const location = useLocation()

  const {
    execute: callCheckTokenReset,
    result: tokenChecked,
    error: checkError,
    loading: checkLoading,
  } = useApiCall(checkTokenReset)
  const {
    execute: callResetPassword,
    result: resetResult,
    error: resetError,
    loading: resetLoading,
  } = useApiCall(resetPassword)
  const { login, loading: loginLoading } = useLogin()

  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [invalid, setInvalid] = useState(true)

  const { token } = parse(location.search)

  useEffect(() => {
    if (token) callCheckTokenReset(token as string)
  }, [callCheckTokenReset, token])

  useEffect(() => {
    if (password && confirmPassword && password === confirmPassword && password.length > 2) {
      setInvalid(false)
    } else {
      setInvalid(true)
    }
  }, [password, confirmPassword])

  useEffect(() => {
    if (resetResult) {
      login(resetResult.user.email, password)
    }
  }, [login, password, resetResult])

  const onSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      callResetPassword(token as string, password)
    },
    [callResetPassword, token, password],
  )

  if (checkLoading || loginLoading) {
    return <Icon loading name="spinner" size="huge" />
  }

  return (
    <Segment>
      {(!token || tokenChecked === false || checkError || resetError || resetResult) && (
        <Container>
          <p>
            <FormattedMessage
              id={`resetPassword.${
                resetResult ? 'success' : resetError ? 'error' : token ? 'invalidToken' : 'tokenMissing'
              }`}
            />
          </p>
        </Container>
      )}

      {tokenChecked && !resetResult && !resetError && (
        <Form onSubmit={onSubmit}>
          <Form.Field>
            <Form.Input
              type="password"
              label={<FormattedMessage id="resetPassword.password" />}
              required
              disabled={resetLoading}
              name="password"
              onChange={e => setPassword(e.target.value)}
            />
          </Form.Field>

          <Form.Field error={!!confirmPassword && confirmPassword !== password}>
            <Form.Input
              type="password"
              label={<FormattedMessage id="resetPassword.confirm" />}
              required
              disabled={resetLoading}
              name="confirm-password"
              onChange={e => setConfirmPassword(e.target.value)}
            />
          </Form.Field>

          <Button type="submit" disabled={invalid || resetLoading} primary>
            <FormattedMessage id="resetPassword.submit" />
          </Button>
        </Form>
      )}
    </Segment>
  )
}

export default ResetPassword
