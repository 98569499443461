import { useMutation, gql } from '@apollo/client'
import ConfirmationButton, { ConfirmationButtonBaseProps } from 'Containers/Button/ConfirmationButton'
import { SportItemSet, GetSportItemSetsResult } from 'models'
import React from 'react'

import { DELETE_SPORTITEMSET_MUTATION } from 'services/api/graphql'

const SPORTITEMSET_FRAGMENT = gql`
  fragment sportItemSetContent on SportItemSet {
    __typename
    id
    sports {
      id
      title
      color
    }
    type
    title
    description
    publicationStatus
    ownerOrganismId

    bannerImage {
      id
      thumbUrl
      downloadUrl
    }

    sportEvent {
      startDate
      endDate
      places {
        name
        city
        country
      }
      state
    }
  }
`

export const GET_SPORTITEMSETS_FOR_REMOVAL = gql`
  query sportItemSetsForRemoval($type: SportItemSetType, $organismId: ID) {
    sportItemSets(type: $type, organismId: $organismId) {
      sportItemSets {
        ...sportItemSetContent
      }
      totalCount
    }
  }
  ${SPORTITEMSET_FRAGMENT}
`

interface RemoveSportItemSetButtonProps extends ConfirmationButtonBaseProps {
  sportItemSetId: SportItemSet['id']
}

const RemoveSportItemSetButton = ({ sportItemSetId, ...props }: RemoveSportItemSetButtonProps) => {
  // const { removeSport, loading } = useRemoveSport(sportId)
  const [removeSportItemSet] = useMutation(DELETE_SPORTITEMSET_MUTATION, {
    variables: { sportItemSetId },
    update: (cache, { data: { deleteSportItemSet } }) => {
      try {
        const data = cache.readQuery<GetSportItemSetsResult>({
          query: GET_SPORTITEMSETS_FOR_REMOVAL,
        })
        if (!data) return
        const sportItemSets = data.sportItemSets.sportItemSets.filter(
          sportItemSet => sportItemSet.id !== deleteSportItemSet.id,
        )
        const totalCount =
          data.sportItemSets.totalCount + sportItemSets.length === data.sportItemSets.sportItemSets.length ? 0 : 1
        const updatedData: GetSportItemSetsResult = {
          ...data,
          sportItemSets: { sportItemSets, totalCount },
        }
        cache.writeQuery<GetSportItemSetsResult>({
          query: GET_SPORTITEMSETS_FOR_REMOVAL,
          data: updatedData,
        })
      } catch (error) {
        console.error('UPDATE ERROR:', error)
      }
    },
  })
  return <ConfirmationButton action={removeSportItemSet} {...props} />
}

export default RemoveSportItemSetButton
