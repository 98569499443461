import classnames from 'classnames'
import React, { memo, useCallback, useState } from 'react'
import { Button, Icon, Input, Label } from 'semantic-ui-react'

type AddForbiddenKeyWordsInputProps = {
  forbiddenKeyWords: string[]
  onChange: (forbiddenKeyWords: string[]) => void
}

const AddForbiddenKeyWordInput = ({ onChange, forbiddenKeyWords }: AddForbiddenKeyWordsInputProps) => {
  // Primary tags handling
  const [editedTitle, setEditedTitle] = useState('')
  const [targetEditTitle, setTargetEditTitle] = useState('')
  const [targetIndex, setTargetIndex] = useState(-1)
  const [newCategory, setNewCategory] = useState(false)

  // Delete handling
  const [hoverIndex, setHoverIndex] = useState(-1)

  const handleKeyDown = useCallback(
    (event: KeyboardEvent, target = false) => {
      if (event.key === 'Enter') {
        event.preventDefault()
        if (target) {
          if (targetEditTitle.trim().length !== 0) {
            const temp = [...forbiddenKeyWords]
            const updatedForbiddenKeyWords = temp.map((forbiddenKeyWord, idx) => {
              if (targetIndex === idx) {
                return targetEditTitle
              }
              return forbiddenKeyWord
            })

            onChange(updatedForbiddenKeyWords)

            setTargetEditTitle('')
          }
        } else if (editedTitle.trim().length !== 0) {
          onChange([...forbiddenKeyWords, editedTitle])
          setEditedTitle('')
          setNewCategory(false)
        }
      }
    },
    [editedTitle, forbiddenKeyWords, onChange, targetEditTitle, targetIndex],
  )

  const handleOnChangeInput = useCallback((e: React.ChangeEvent<HTMLInputElement>, target = false) => {
    if (target) {
      setTargetEditTitle(e.target.value)
    } else {
      setEditedTitle(e.target.value)
    }
  }, [])

  const handleOnDelete = useCallback(
    index => {
      onChange(forbiddenKeyWords.filter((forbiddenKeyWord, idx) => idx !== index))
    },
    [onChange, forbiddenKeyWords],
  )

  return (
    <>
      <Label.Group className={`flex items-center flex-wrap ${forbiddenKeyWords.length === 0 ? 'mt-3' : ''}`}>
        {forbiddenKeyWords.map((forbiddenKeyWord, index) => (
          <div
            key={`${forbiddenKeyWord}${index}`}
            onMouseEnter={() => setHoverIndex(index)}
            onMouseLeave={() => setHoverIndex(-1)}
            className={classnames('relative', {
              'rounded-md bg-gray-200 pl-3 pr-2 flex justify-center items-center py-2 my-1 mr-4': targetIndex !== index,
            })}
          >
            <div className="flex items-center justify-center px-1">
              {targetIndex === index ? (
                <Input
                  size="mini"
                  className="mr-2"
                  value={targetEditTitle}
                  onKeyDown={(event: KeyboardEvent) => handleKeyDown(event, true)}
                  onChange={ev => handleOnChangeInput(ev, true)}
                  autoFocus
                />
              ) : (
                <>
                  <span
                    className="font-semibold text-lg m-0 mr-1"
                    onClick={() => {
                      setTargetEditTitle(forbiddenKeyWord || '')
                      setTargetIndex(index)
                    }}
                  >
                    {forbiddenKeyWord}
                  </span>
                </>
              )}
            </div>

            {hoverIndex === index && targetIndex === -1 && (
              <div
                className="absolute flex justify-center items-center cursor-pointer bg-gray-100 rounded-full h-7 w-7 shadow-md z-10 -top-2 -right-2"
                onClick={() => handleOnDelete(index)}
              >
                <Icon name="remove" color="red" fitted size="small" />
              </div>
            )}
          </div>
        ))}
        {newCategory ? (
          <div>
            <Input
              onKeyDown={handleKeyDown}
              size="small"
              value={editedTitle}
              onChange={ev => handleOnChangeInput(ev, false)}
              autoFocus
            />
          </div>
        ) : (
          <div>
            <Button
              circular
              icon="plus"
              onClick={e => {
                e.preventDefault()
                setNewCategory(true)
              }}
            />
          </div>
        )}
      </Label.Group>
    </>
  )
}

export default memo(AddForbiddenKeyWordInput)
