import { QueryHookOptions, useQuery } from '@apollo/client'
import {
  GET_STREAM_QUERY,
  LIST_PROFILES_QUERY,
  LIST_STREAMS_QUERY,
  LIST_TEMPLATES_QUERY,
  MutationWriteStreamMapArgs,
  QueryGetStreamArgs,
  QueryListStreamsArgs,
  QueryListTemplatesArgs,
  StreamMap,
  WmProfile,
  WmStream,
  WmStreamLight,
  WmTemplate,
  WRITE_STREAM_MAP_MUTATION,
} from 'services/api/graphql'
import { useShortMutation } from 'tools/graphql'

export const useListStreams = (options?: QueryHookOptions<{ listStreams: WmStreamLight[] }, QueryListStreamsArgs>) => {
  const { data, ...query } = useQuery<{ listStreams: WmStreamLight[] }, QueryListStreamsArgs>(
    LIST_STREAMS_QUERY,
    options,
  )
  return { data: data && data.listStreams, ...query }
}

export const useGetStream = (id?: string, options?: QueryHookOptions<{ getStream: WmStream }, QueryGetStreamArgs>) => {
  const { data, ...query } = useQuery<{ getStream: WmStream }, QueryGetStreamArgs>(GET_STREAM_QUERY, {
    ...options,
    variables: {
      id: id || '',
    },
    skip: !id,
    fetchPolicy: 'cache-and-network',
  })

  return { stream: data && data.getStream, ...query }
}

export const useListTemplates = (
  options?: QueryHookOptions<{ listTemplates: WmTemplate[] }, QueryListTemplatesArgs>,
) => {
  const { data, ...query } = useQuery(LIST_TEMPLATES_QUERY, options)
  return { data: data && data.listTemplates, ...query }
}

export const useListProfiles = (options?: QueryHookOptions<{ listProfiles: WmProfile[] }>) => {
  const { data, ...query } = useQuery(LIST_PROFILES_QUERY, options)
  return { data: data && data.listProfiles, ...query }
}

export const useWriteStreamMap = () =>
  useShortMutation<{ writeStreamMap: StreamMap }, MutationWriteStreamMapArgs>(WRITE_STREAM_MAP_MUTATION)
