import { convertToDropdownOptions } from 'Forms/utils'
import { ResultOf } from 'gql.tada'
import React, { FC, useMemo } from 'react'
import { Field } from 'react-final-form'
import { Form as UiForm, Table } from 'semantic-ui-react'

import { FieldsType } from '../EditedCategoryFormFields'

import { LinkItemFragment } from './fragments'

interface Props {
  index: number
  name: string
  fields: FieldsType[]
  links?: ResultOf<typeof LinkItemFragment>[]
  onChangeFields: (index: number, item: FieldsType) => void
}

export const LinkItem: FC<Props> = ({ index, name, links, fields, onChangeFields }) => {
  const linkOptions = useMemo(() => links?.map(convertToDropdownOptions), [links])

  return (
    <Table.Cell colSpan={2}>
      <Field
        name={`${name}.linkId`}
        render={({ input }) => {
          return (
            <UiForm.Dropdown
              name={input.name}
              selection
              search
              onChange={(e, { value }) => onChangeFields(index, { ...fields[index], linkId: value?.toString() })}
              options={linkOptions}
              value={fields[index].linkId || undefined}
            />
          )
        }}
      />
    </Table.Cell>
  )
}
