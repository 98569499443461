import { applicationUris } from 'Layout/uris'
import ApplicationDesignsView from 'Views/ApplicationDesignsView'
import OrganismsFrontPages from 'Views/OrganismsFrontPages'
import OrganismsView from 'Views/OrganismsView'
import ProductsView from 'Views/ProductsView'
import PurchasableOffersView from 'Views/PurchasableOffersView'
import React from 'react'
import { Access, userCanAccessToPath } from 'services/api'
import { IUser } from 'stores'

const organismsRoutes = (user: IUser | null) => [
  {
    path: applicationUris.organisms,
    element: <OrganismsView />,
    loader: () => userCanAccessToPath(user, [Access.OrganismsView]),
  },
  {
    path: applicationUris.purchasableOffers,
    element: <PurchasableOffersView />,
    loader: () => userCanAccessToPath(user, [Access.PurchasableOffersView]),
  },
  {
    path: applicationUris.products(':purchasableOfferId'),
    element: <ProductsView />,
    loader: () => userCanAccessToPath(user, [Access.ProductsView]),
  },
  {
    path: applicationUris.applicationDesigns,
    element: <ApplicationDesignsView />,
    loader: () => userCanAccessToPath(user, [Access.ApplicationDesigns]),
  },
  {
    path: applicationUris.organismsFrontPages,
    element: <OrganismsFrontPages />,
    loader: () => userCanAccessToPath(user, [Access.FrontPages]),
  },
]

export default organismsRoutes
