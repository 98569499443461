import InvisibleButton from 'Components/Button/InvisibleButton'
import RefreshButton from 'Components/Button/RefreshButton'
import { Cooldown } from 'Components/Cooldown'
import CheckboxInput from 'Components/Form/CheckboxInput'
import { SelectDateInput } from 'Components/Form/SelectDateInput'
import LiveStreamPlayerModal from 'Components/LiveStreamPlayerModal'
import { LiveStreamSVEPlayerModal } from 'Components/LiveStreamSVEPlayerModal'
import Loader from 'Components/Loader'
import PageHeader from 'Components/PageHeader'
import { LiveStreamExternalFeedDetailsModal } from 'Components/SportItemMedias/ExternalFeedVideoEngine/LiveStreamExternalFeedDetailsModal'
import { LiveStreamSVEDetailsModal } from 'Components/SportItemMedias/SportallVideoEngine/LiveStreamSVEDetailsModal'
import { LiveStreamWildmokaDetailsModal } from 'Components/SportItemMedias/WildmokaVideoEngine/LiveStreamWildmokaDetailsModal'
import { IsLocked } from 'Components/SportItemSet/IsLocked'
import VideoPlayerModal from 'Components/VideoPlayer/VideoPlayerModal'
import ConfirmationButton from 'Containers/Button/ConfirmationButton'
import { applicationUris } from 'Layout/uris'
import classnames from 'classnames'
import config from 'config'
import { format } from 'date-fns'
import { JSONPath } from 'jsonpath-plus'
import { observer } from 'mobx-react'
import { useGetMonitoringDashboard } from 'models'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { Grid, Icon, Popup, Table, TableCell } from 'semantic-ui-react'
import {
  LiveStreamState,
  MediaContentFragment,
  Permission,
  useStopSportItemLiveMutation,
  VideoContentType,
  VideoEngine,
} from 'services/api/graphql'
import { useCurrentUser, useStore } from 'stores'
import { redirectionApplicationPlatformUrl } from 'tools/redirectionPlatformUrl'
import { Sort, toggleSort } from 'tools/sort'
import { notifyError } from 'tools/toaster'

enum DashboardHeader {
  Title = 'sportItemSet.title',
  Media = 'title',
  Organism = 'sportItemSet.ownerOrganism.title',
  PublicationStatus = 'publicationStatus',
  TestDate = 'sportEventItem.testDate',
  StartDate = 'sportEventItem.startDate',
  StopDate = 'sportEventItem.stopDate',
}

const MonitoringDashboardView = () => {
  const intl = useIntl()

  // User
  const currentUser = useCurrentUser()

  // Sorting
  const [withOrganismFilter, setWithOrganismFilter] = useState<boolean>(false)
  const [column, setColumn] = useState(DashboardHeader.StartDate)
  const [direction, setDirection] = useState<Sort>('ascending')
  const updateSort = useCallback(
    (nextColumn: DashboardHeader) => {
      setDirection(toggleSort(column, nextColumn, direction))
      setColumn(nextColumn)
    },
    [column, direction],
  )

  // Date
  const [date, setDate] = useState<Date | undefined>(new Date())

  // Hide finished lives
  const [hideFinished, setHideFinished] = useState(false)

  // Auto refresh
  const [autoRefresh, setAutoRefresh] = useState(true)
  const [refreshInterval, setRefreshInterval] = useState<number>()

  // Organism
  const { organismId } = useStore()

  const now = new Date()

  // Dashboard data
  const {
    data: dashboard,
    error,
    loading: loadingDashboard,
    refetch,
  } = useGetMonitoringDashboard({
    variables: { date: date || now, organismId: withOrganismFilter ? organismId : undefined },
    fetchPolicy: 'cache-and-network',
  })

  // Setup refresh interval to refetch every minute
  useEffect(() => {
    if (autoRefresh && !refreshInterval) setRefreshInterval(window.setInterval(() => refetch(), 60000))

    // On destroy
    return () => {
      if (refreshInterval) {
        clearInterval(refreshInterval)
        setRefreshInterval(undefined)
      }
    }
  }, [autoRefresh, refetch, refreshInterval])

  // Sorted when data, column or direction change
  const sortedDashboard = useMemo(() => {
    const order = direction === 'ascending' ? 1 : -1
    return dashboard
      ?.filter(d => (hideFinished ? d.sportEventItem?.state !== LiveStreamState.Finished : true))
      .sort((a, b) => {
        const aProp = JSONPath({ json: a, path: `$.${column}`, wrap: false })
        const bProp = JSONPath({ json: b, path: `$.${column}`, wrap: false })
        if (aProp > bProp) return order
        if (aProp < bProp) return -order
        return 0
      })
  }, [dashboard, column, direction, hideFinished])

  // Action flags
  const [showModalDetailsLive, setShowModalDetailsLive] = useState(false)
  const [selectedLive, setSelectedLive] = useState<MediaContentFragment | null>(null)
  const [playerOpen, setPlayerOpen] = useState(false)

  // Open modal to show live details
  const displayMediaLive = useCallback((media: MediaContentFragment) => {
    setSelectedLive(media)
    setShowModalDetailsLive(true)
  }, [])

  // Open player
  const openLiveStreamPlayer = useCallback((media: MediaContentFragment) => {
    setSelectedLive(media)
    setPlayerOpen(true)
  }, [])

  const onClosePlayerModal = useCallback(() => {
    setPlayerOpen(false)
    setSelectedLive(null)
  }, [])

  // Stop live
  const [stopSportItemLive, { loading: loadingStopLive }] = useStopSportItemLiveMutation()

  const hasSVEAlerts = useCallback((media?: MediaContentFragment, state?: LiveStreamState) => {
    const alerts = media?.liveStream?.sveLiveMonitoring?.alerts
    if (state === LiveStreamState.Finished) return false

    return alerts ? Object.values(alerts).some(value => value === 'SET') : false
  }, [])

  // Misc
  const loading = loadingDashboard || loadingStopLive
  const [keyState, forceUpdate] = useState(0)

  useEffect(() => {
    if (error) notifyError(<FormattedMessage id="error.general" />, error)
  }, [error])

  useEffect(() => {
    // Refresh with new media data modal view When adding markers
    let updatedIndex = -1
    if (selectedLive && selectedLive.id) {
      updatedIndex = dashboard?.findIndex(item => item?.medias?.[0]?.id === selectedLive.id) || -1
    }

    if (selectedLive && updatedIndex !== -1) {
      setSelectedLive(dashboard?.[updatedIndex]?.medias?.[0] || null)
    }
  }, [dashboard, selectedLive])

  return (
    <Loader loading={loading}>
      <Grid padded>
        <Grid.Column>
          <PageHeader title={intl.formatMessage({ id: 'menu.monitoringDashboard' })}>
            <Popup
              content={<FormattedMessage id={'monitoringDashboard.autoRefresh'} />}
              trigger={
                <div>
                  <RefreshButton
                    onClick={() => setAutoRefresh(!autoRefresh)}
                    loading={loadingDashboard}
                    color={autoRefresh ? 'green' : undefined}
                  />
                </div>
              }
            />
          </PageHeader>

          <div className="flex space-x-4">
            <SelectDateInput
              value={date}
              allowClear={false}
              bordered
              onChange={newDate => setDate(newDate || undefined)}
              format="dddd DD MMMM"
              className="w-64"
            />

            {!!organismId && (
              <label className="flex self-center my-6 space-x-2">
                <CheckboxInput
                  toggle
                  name="organism"
                  onChange={() => setWithOrganismFilter(!withOrganismFilter)}
                  checked={withOrganismFilter}
                />

                <span>
                  <FormattedMessage id="monitoringDashboard.withOrganismFilterLabel" />
                </span>
              </label>
            )}

            <label className="flex self-center my-6 space-x-2">
              <CheckboxInput
                toggle
                name="hideFinished"
                onChange={() => setHideFinished(!hideFinished)}
                checked={hideFinished}
              />

              <span>
                <FormattedMessage id="monitoringDashboard.hideFinishedLabel" />
              </span>
            </label>
          </div>

          <Table sortable className="flex-1">
            <Table.Header>
              <Table.Row textAlign="center">
                <Table.HeaderCell disabled width={1}></Table.HeaderCell>

                <Table.HeaderCell
                  sorted={column === DashboardHeader.Title ? direction : undefined}
                  onClick={() => updateSort(DashboardHeader.Title)}
                  width={1}
                >
                  <FormattedMessage id="monitoringDashboard.title" />
                </Table.HeaderCell>

                <Table.HeaderCell
                  sorted={column === DashboardHeader.Media ? direction : undefined}
                  onClick={() => updateSort(DashboardHeader.Media)}
                  width={2}
                >
                  <FormattedMessage id="monitoringDashboard.media" />
                </Table.HeaderCell>

                <Table.HeaderCell
                  sorted={column === DashboardHeader.Organism ? direction : undefined}
                  onClick={() => updateSort(DashboardHeader.Organism)}
                  width={1}
                >
                  <FormattedMessage id="monitoringDashboard.organism" />
                </Table.HeaderCell>

                <Table.HeaderCell
                  sorted={column === DashboardHeader.PublicationStatus ? direction : undefined}
                  onClick={() => updateSort(DashboardHeader.PublicationStatus)}
                  width={1}
                >
                  <FormattedMessage id="monitoringDashboard.publicationStatus" />
                </Table.HeaderCell>

                <Table.HeaderCell
                  sorted={column === DashboardHeader.TestDate ? direction : undefined}
                  onClick={() => updateSort(DashboardHeader.TestDate)}
                  width={1}
                >
                  <FormattedMessage id="monitoringDashboard.testDate" />
                </Table.HeaderCell>

                <Table.HeaderCell
                  sorted={column === DashboardHeader.StartDate ? direction : undefined}
                  onClick={() => updateSort(DashboardHeader.StartDate)}
                  width={1}
                >
                  <FormattedMessage id="monitoringDashboard.startDate" />
                </Table.HeaderCell>

                <Table.HeaderCell
                  sorted={column === DashboardHeader.StopDate ? direction : undefined}
                  onClick={() => updateSort(DashboardHeader.StopDate)}
                  width={1}
                >
                  <FormattedMessage id="monitoringDashboard.stopDate" />
                </Table.HeaderCell>

                <Table.HeaderCell disabled width={2}>
                  <FormattedMessage id="monitoringDashboard.nextStep" />
                </Table.HeaderCell>

                <Table.HeaderCell disabled width={1}>
                  <FormattedMessage id="monitoringDashboard.actions" />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {sortedDashboard?.map(
                ({
                  id,
                  sportItemSet,
                  videoContentType,
                  reverseRestriction,
                  title,
                  sportEventItem,
                  publicationStatus,
                  medias,
                }) => (
                  <Table.Row key={id}>
                    {/* Infos */}
                    <Table.Cell
                      className={classnames('border-l-8', {
                        'border-yellow-400': sportEventItem?.state === LiveStreamState.Testing,
                        'border-green-400': hasSVEAlerts(medias?.[0])
                          ? false
                          : sportEventItem?.state === LiveStreamState.Started,
                        'border-gray-400': sportEventItem?.state === LiveStreamState.Finished,
                        'border-red-400': hasSVEAlerts(medias?.[0], sportEventItem?.state),
                      })}
                    >
                      {/* Premium */}
                      {sportItemSet && (
                        <IsLocked
                          sportItemSet={sportItemSet}
                          videoContentType={videoContentType}
                          reverseRestriction={reverseRestriction}
                        />
                      )}

                      {/* Legal restrictions */}
                      {(sportItemSet?.legalRestrictions?.length ?? 0) > 0 && (
                        <Popup
                          trigger={<Icon name="law" />}
                          content={<FormattedMessage id="legalRestrictions.preview.popup" />}
                        />
                      )}
                    </Table.Cell>

                    {/* Sport item set title */}
                    <Table.Cell selectable textAlign="center">
                      <Link to={applicationUris.sportEventItems(sportItemSet?.id as string)}>
                        {sportItemSet?.title}
                      </Link>
                    </Table.Cell>

                    {/* Sport item title */}
                    <Table.Cell selectable textAlign="center">
                      <Link to={applicationUris.sportItemMedias(id)}>{title}</Link>
                    </Table.Cell>

                    {/* Organism */}
                    <TableCell textAlign="center">{sportItemSet?.ownerOrganism?.title}</TableCell>

                    {/* Publication status */}
                    <Table.Cell textAlign="center">
                      {intl.formatMessage({ id: `publicationStatus.${publicationStatus}` })}
                    </Table.Cell>

                    {/* Test date */}
                    <Table.Cell textAlign="center">
                      {sportEventItem?.testDate && format(sportEventItem.testDate, 'DD/MM HH:mm')}
                    </Table.Cell>

                    {/* Start date */}
                    <Table.Cell textAlign="center">
                      {sportEventItem && format(sportEventItem.startDate, 'DD/MM HH:mm')}
                    </Table.Cell>

                    {/* Stop date */}
                    <Table.Cell textAlign="center">
                      {sportEventItem && format(sportEventItem.stopDate, 'DD/MM HH:mm')}
                    </Table.Cell>

                    {/* Next step */}
                    <Table.Cell textAlign="center">
                      {/* Live not over */}
                      {sportEventItem && (
                        <>
                          <span
                            className={classnames({
                              'text-gray-500': sportEventItem.state === LiveStreamState.Finished,
                            })}
                          >
                            <FormattedMessage
                              id={`monitoringDashboard.nextStep.${
                                // Simulate testing when no testDate to get "Start of live in" instead of "Testing in"
                                sportEventItem.state === LiveStreamState.NotStarted && !sportEventItem.testDate
                                  ? LiveStreamState.Testing
                                  : sportEventItem.state
                              }`}
                            />
                          </span>

                          {sportEventItem.state !== LiveStreamState.Finished && (
                            <Cooldown
                              endDate={
                                sportEventItem.state === LiveStreamState.NotStarted && sportEventItem.testDate
                                  ? sportEventItem.testDate
                                  : sportEventItem.state !== LiveStreamState.Started
                                  ? sportEventItem.startDate
                                  : sportEventItem.stopDate
                              }
                              warningDurationSeconds={sportEventItem.state === LiveStreamState.Started ? 10 * 60 : 0}
                              whenOver={() => forceUpdate(keyState + 1)}
                            />
                          )}
                        </>
                      )}
                    </Table.Cell>

                    {/* Actions */}
                    <Table.Cell textAlign="right">
                      {medias.length > 0 && (
                        <>
                          {/* Media Application Link */}
                          <InvisibleButton>
                            <a
                              target="blank"
                              href={`${redirectionApplicationPlatformUrl(
                                config.tenantName,
                                config.appPlatform,
                              )}/sportitem/${id}`}
                            >
                              <Icon size="large" name="globe" />
                            </a>
                          </InvisibleButton>
                          {/* Media infos */}
                          <InvisibleButton onClick={() => displayMediaLive(medias[0])}>
                            <Icon size="large" name="info circle" />
                          </InvisibleButton>

                          {/* Player */}
                          {medias[0].liveStream && ![LiveStreamState.Finished].includes(medias[0].liveStream.state) && (
                            <InvisibleButton onClick={() => openLiveStreamPlayer(medias[0])}>
                              <Icon className="ml-1" size="large" name="eye" />
                            </InvisibleButton>
                          )}

                          {/* Stop live */}
                          {videoContentType === VideoContentType.Live &&
                            (sportEventItem?.state === LiveStreamState.Testing ||
                              sportEventItem?.state === LiveStreamState.Started) &&
                            currentUser.can(Permission.LiveStreamUpdate) && (
                              <ConfirmationButton
                                action={() =>
                                  stopSportItemLive({
                                    variables: { id },
                                  })
                                }
                                onDone={() => refetch()}
                                successText={intl.formatMessage(
                                  {
                                    id: 'sportItem.live_stopped',
                                  },
                                  { title: medias[0].title },
                                )}
                                confirmText={intl.formatMessage(
                                  {
                                    id: 'sportItem.stop_live',
                                  },
                                  { title: medias[0].title },
                                )}
                              >
                                <Icon className="ml-1" size="large" name="stop" />
                              </ConfirmationButton>
                            )}
                        </>
                      )}
                    </Table.Cell>
                  </Table.Row>
                ),
              )}
            </Table.Body>
          </Table>

          {/* No live on selected day */}
          {!dashboard?.length && (
            <div className="text-2xl m-4">
              <FormattedMessage id="monitoringDashboard.noLives" />
            </div>
          )}

          {/* No more lives on selected day */}
          {!sortedDashboard?.length && !!dashboard?.length && (
            <div className="text-2xl m-4">
              <FormattedMessage id="monitoringDashboard.noMoreLives" />
            </div>
          )}
        </Grid.Column>
      </Grid>

      {/* SVE */}
      {selectedLive?.liveStream?.videoEngine === VideoEngine.Sportall && (
        <>
          <LiveStreamSVEDetailsModal
            open={showModalDetailsLive}
            livestream={selectedLive.liveStream}
            onClose={() => setShowModalDetailsLive(false)}
          />
          <LiveStreamSVEPlayerModal
            open={playerOpen}
            onRefresh={refetch}
            mediaToUpdate={selectedLive}
            showEditMarkers={true}
            liveStream={selectedLive.liveStream}
            onClose={onClosePlayerModal}
          />
        </>
      )}

      {/* Wildmoka */}
      {selectedLive?.liveStream?.videoEngine === VideoEngine.Wildmoka && (
        <>
          <LiveStreamWildmokaDetailsModal
            open={showModalDetailsLive}
            livestream={selectedLive.liveStream}
            onClose={() => setShowModalDetailsLive(false)}
          />
          <LiveStreamPlayerModal open={playerOpen} liveStream={selectedLive.liveStream} onClose={onClosePlayerModal} />
        </>
      )}

      {/* ExternalFeed */}
      {selectedLive?.liveStream?.videoEngine === VideoEngine.ExternalFeed && (
        <>
          <LiveStreamExternalFeedDetailsModal
            open={showModalDetailsLive}
            livestream={selectedLive.liveStream}
            onClose={() => setShowModalDetailsLive(false)}
          />
          <VideoPlayerModal
            open={playerOpen}
            isEmbed
            onClose={onClosePlayerModal}
            url={selectedLive?.liveStream?.playbackUrl || ''}
          />
        </>
      )}
    </Loader>
  )
}

export default observer(MonitoringDashboardView)
