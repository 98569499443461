import AddForbiddenKeyWordInput from 'Components/AddForbiddenKeyWordInput'
import AddTagInput from 'Components/AddTagInput'
import CheckboxInput from 'Components/Form/CheckboxInput'
import GenericForm from 'Components/Form/GenericForm'
import SAFormField from 'Components/Form/SAFormField'
import SelectItemListInput from 'Components/Form/SelectItemListInput'
import SelectLocalInput, { SelectedTranslationsType, TranslatedInput } from 'Components/Form/SelectLocalInput'
import FileInput from 'Containers/Form/FileInput'
import { SelectApplicationDesignInput } from 'Containers/Form/SelectApplicationDesignInput'
import SelectLicenseApiInput from 'Containers/Form/SelectLicenseApiInput'
import {
  SelectOrganismSVEChannelsInput,
  SelectOrganismSVEChannelsInputRefProps,
} from 'Containers/Form/SelectOrganismSVEChannelsInput'
import SelectPublicationStatusInput from 'Containers/Form/SelectPublicationStatusInput'
import SelectSportInput from 'Containers/Form/SelectSportInput'
import SelectWMProfileInput from 'Containers/Form/SelectWMProfileInput'
import { SelectWMStreamInput } from 'Containers/Form/SelectWMStreamInput'
import SwitchContentTypesButtonInput, {
  SwitchContentTypeSelector,
} from 'Containers/Form/SwitchButtonInput/SwitchContentTypesButtonInput'
import { SVEChannelsViewCore } from 'Views/SVEChannelsView'
import Config from 'config'
import { ValidationErrors } from 'final-form'
import { observer } from 'mobx-react-lite'
import { useListStreams } from 'models'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { FieldRenderProps } from 'react-final-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button, Form, Grid, Header, Modal, Segment, TextArea } from 'semantic-ui-react'
import {
  ApplicationDesign,
  LicenseApi,
  Media,
  MediaType,
  OrganismLicenseInput,
  OrganismType,
  Permission,
  PublicationStatus,
  Scalars,
  Sport,
  TagInput,
  WidgetExternalContentSource,
} from 'services/api/graphql'
import { useCurrentUser, useStore } from 'stores'
import { composeValidators, validateRequired, validateUrl } from 'tools/formValidators'
import { getSelectedTranslations, validateTranslation } from 'tools/translationTools'

export type OrganismFormValues = {
  id?: string // must be set when editing
  type: OrganismType
  whiteLabel: boolean
  title: string
  titleT?: TranslatedInput | null
  defaultLocale?: string
  description: string | undefined | null
  descriptionT?: TranslatedInput | null
  logo: {
    coloredImageFileId: string | undefined | null
    transparentLightImageFileId: string | undefined | null
    transparentDarkImageFileId: string | undefined | null
  }
  officialWebSiteUrl: string
  forbiddenKeyWords?: string[]
  sportIds: Array<Sport['id']>
  enableExternalSources?: boolean | null
  availableExternalSources?: Array<WidgetExternalContentSource> | null
  adminBadgeId?: Media['id'] | null
  bannerImageFileId?: Media['id'] | null
  bannerVideoId?: Media['id'] | null
  license?: OrganismLicenseInput | null
  streamsIds: Array<Scalars['WildmokaID']>
  wmProfileId?: Scalars['WildmokaID']
  sveChannelIds?: string[]
  publicationStatus?: PublicationStatus | undefined | null
  organismPageApplicationDesignId: ApplicationDesign['id'] | null
  contentPageApplicationDesignId: ApplicationDesign['id'] | null
  adminsAutomatedEmails?: boolean | undefined
  tags: TagInput[]
  visibleInMultisportApplication: boolean
}

interface OrganismFormProps {
  title: string
  editType: boolean
  edit: boolean // if true => Update Organism otherwise Add Organism
  initialValues?: OrganismFormValues
  onSubmit: (
    values: OrganismFormValues,
    defaultLocale: string,
    selectedTranslations: SelectedTranslationsType[],
  ) => Promise<void> | void
  onCancel?: () => void
  loading: boolean
}
const OrganismForm = observer(
  ({ editType, initialValues, onSubmit, onCancel, loading, title, edit }: OrganismFormProps) => {
    const intl = useIntl()
    const { can } = useCurrentUser()
    const { currentUser } = useStore()
    const store = useStore()
    const [selectedTranslations, setSelectedTranslations] = useState<SelectedTranslationsType[]>(
      initialValues ? getSelectedTranslations(initialValues) : [],
    )
    const [defaultLocale, setDefaultLocale] = useState<string>(initialValues?.defaultLocale || '')
    const [activeTranslation, setActiveTranslation] = useState<string | undefined>(defaultLocale)
    const sveChannelSelectInputRef = useRef<SelectOrganismSVEChannelsInputRefProps>(null)
    const [channelsModalOpen, setChannelsModalOpen] = useState(false)
    const { data: dataStreams, loading: loadingStreams } = useListStreams({
      variables: { filters: { pool: false, mapped: true } },
      skip: !can(Permission.ReadWmStreams, undefined, true),
    })
    const [licenseEnabled, setLicenseEnabled] = useState(
      !!(initialValues && initialValues.license && initialValues.license.enabled),
    )
    const [licenseApi, setLicenseApi] = useState<LicenseApi | null>(
      (initialValues && initialValues.license && initialValues.license.api) || null,
    )
    const [adminsAutomatedEmails, setAdminsAutomatedEmails] = useState(
      !!(initialValues && initialValues.adminsAutomatedEmails),
    )
    const [selectedOrganismPageApplicationDesignId, setSelectedOrganismPageApplicationDesignId] = useState<
      ApplicationDesign['id'] | null
    >((initialValues && initialValues.organismPageApplicationDesignId) || null)
    const organismId = store.organismId || ''

    const validate = useCallback(
      (values: OrganismFormValues): ValidationErrors | Promise<ValidationErrors> | undefined => {
        const validation = {
          titleT: validateTranslation(activeTranslation, values?.titleT?.[`${defaultLocale}`], intl),
          descriptionT: validateTranslation(activeTranslation, values?.descriptionT?.[`${defaultLocale}`], intl),
        }
        if (
          !values.logo ||
          (!values.logo.coloredImageFileId &&
            !values.logo.transparentLightImageFileId &&
            !values.logo.transparentDarkImageFileId)
        ) {
          return {
            ...validation,
            'logo.coloredImageFileId': intl.formatMessage({
              id: 'organism.logo.coloredImageFileId.missing',
            }),
          }
        } else if (!values.bannerImageFileId && !values.bannerVideoId) {
          return {
            ...validation,
            bannerImageFileId: intl.formatMessage({
              id: 'organism.error.missingBanner',
            }),
            bannerVideoId: intl.formatMessage({
              id: 'organism.error.missingBanner',
            }),
          }
        }
        return
      },
      [activeTranslation, defaultLocale, intl],
    )

    const externalSourcesOptions = useMemo(() => {
      // Keep title FIA WEC condition to avoid error for staging / candidate
      // Use tenant for production
      if (
        Config.tenantName === 'wec' ||
        (['candidate', 'staging'].includes(Config.appPlatform) && initialValues?.title === 'FIA WEC')
      ) {
        return Object.values(WidgetExternalContentSource).map(source => ({
          text: intl.formatMessage({ id: `widget.externalContent.${source}.title` }),
          value: source,
        }))
      }

      return [
        {
          text: intl.formatMessage({
            id: `widget.externalContent.${WidgetExternalContentSource.RssFeed}.title`,
          }),
          value: WidgetExternalContentSource.RssFeed,
        },
      ]
    }, [initialValues?.title, intl])

    const submit = useCallback(
      (values: OrganismFormValues) => {
        onSubmit(
          {
            ...values,
            visibleInMultisportApplication: values?.visibleInMultisportApplication ?? true,
            organismPageApplicationDesignId:
              selectedOrganismPageApplicationDesignId && selectedOrganismPageApplicationDesignId?.length > 1
                ? selectedOrganismPageApplicationDesignId
                : null,
            license: licenseEnabled
              ? {
                  enabled: true,
                  api: licenseApi,
                }
              : null,
            adminsAutomatedEmails,
          },
          defaultLocale,
          selectedTranslations,
        )
      },
      [
        onSubmit,
        selectedOrganismPageApplicationDesignId,
        licenseEnabled,
        licenseApi,
        defaultLocale,
        selectedTranslations,
        adminsAutomatedEmails,
      ],
    )

    return (
      <GenericForm
        header={title}
        initialValues={initialValues}
        onCancel={onCancel}
        onSubmit={submit}
        validate={validate}
        loading={loading}
      >
        {({ values }) => {
          return (
            <>
              <SAFormField
                name="locale"
                render={({ input }) => (
                  <Form.Field
                    {...input}
                    disabled={false}
                    label={intl.formatMessage({ id: 'locale' })}
                    control={() => (
                      <SelectLocalInput
                        defaultLocale={defaultLocale}
                        onChangeDefaultLocale={setDefaultLocale}
                        activeTranslation={activeTranslation}
                        onChangeActiveTranslation={setActiveTranslation}
                        selectedTranslations={selectedTranslations}
                        onChangeSelectedTranslations={setSelectedTranslations}
                      />
                    )}
                  />
                )}
              />

              {can(Permission.EditOrganismWhiteLabel, undefined, true) && (
                <>
                  <SAFormField
                    name="whiteLabel"
                    render={({ input }) => (
                      <CheckboxInput
                        {...input}
                        checked={values.whiteLabel}
                        toggle
                        name="whiteLabel"
                        label={intl.formatMessage({ id: 'organism.whiteLabel' })}
                      />
                    )}
                  />
                  <SAFormField
                    name="visibleInMultisportApplication"
                    render={({ input }) => (
                      <CheckboxInput
                        {...input}
                        toggle
                        checked={values.visibleInMultisportApplication ?? true}
                        label={intl.formatMessage({ id: 'sportItemSet.visibleInMultisportApplication' })}
                      />
                    )}
                  />
                </>
              )}
              <SAFormField
                name="publicationStatus"
                render={({ input, meta }) => (
                  <Form.Field
                    control={SelectPublicationStatusInput}
                    {...input}
                    error={!!meta.touched && !!meta.error}
                    label={intl.formatMessage({
                      id: 'editedCategory.items.publicationStatus',
                    })}
                  />
                )}
              />

              {editType && (
                <SAFormField
                  name="type"
                  validate={validateRequired}
                  render={({ input, meta }) => (
                    <Form.Field
                      control={() => (
                        <SwitchContentTypesButtonInput
                          onChange={input.onChange}
                          value={input.value}
                          typeSelector={SwitchContentTypeSelector.OrganismTypes}
                        />
                      )}
                      {...input}
                      required
                      error={!!meta.touched && !!meta.error}
                      label={intl.formatMessage({ id: 'organism.type' })}
                      description={intl.formatMessage({
                        id: 'organism.type.description',
                      })}
                    />
                  )}
                />
              )}

              <SAFormField
                name={`titleT.${activeTranslation}`}
                render={({ input, meta }) => (
                  <Form.Input
                    {...input}
                    required={activeTranslation === defaultLocale}
                    error={!!meta.touched && !!meta.error}
                    label={intl.formatMessage({ id: 'organism.title' })}
                    placeholder={intl.formatMessage({ id: 'organism.title' })}
                  />
                )}
              />

              <SAFormField
                name={`descriptionT.${activeTranslation}`}
                render={({ input, meta }) => (
                  <Form.Input
                    {...input}
                    required
                    control={TextArea}
                    maxLength={500}
                    error={!!meta.touched && !!meta.error}
                    label={intl.formatMessage({ id: 'organism.description' })}
                    placeholder={intl.formatMessage({
                      id: 'organism.description.placeholder',
                    })}
                  />
                )}
              />

              <SAFormField
                name="sportIds"
                validate={validateRequired}
                render={({ input, meta }) => (
                  <Form.Field
                    control={SelectSportInput}
                    {...input}
                    multiple
                    required
                    error={!!meta.touched && !!meta.error}
                    label={intl.formatMessage({ id: 'organism.sportIds' })}
                    description={intl.formatMessage({
                      id: 'organism.sportIds.description',
                    })}
                  />
                )}
              />

              <SAFormField
                name="availableExternalSources"
                render={({ input, meta }) => (
                  <Form.Field
                    control={SelectItemListInput}
                    {...input}
                    multiple
                    error={!!meta.touched && !!meta.error}
                    label={intl.formatMessage({ id: 'organism.availableExternalSources' })}
                    options={externalSourcesOptions}
                  />
                )}
              />
              <SAFormField
                name="tags"
                render={({ input }) => (
                  <Form.Field
                    {...input}
                    disabled={!currentUser.can(Permission.OrganismCreateTag, organismId, true)}
                    label={'Tags'}
                    control={() => <AddTagInput tags={values.tags || []} onChange={input.onChange} />}
                  />
                )}
              />
              <SAFormField
                name="forbiddenKeyWords"
                render={({ input }) => (
                  <Form.Input
                    {...input}
                    disabled={!currentUser.can(Permission.OrganismCreateForbiddenKeyWords, organismId, true)}
                    label={intl.formatMessage({
                      id: 'organism.forbiddenKeyWords',
                    })}
                    control={() => (
                      <AddForbiddenKeyWordInput
                        forbiddenKeyWords={values.forbiddenKeyWords || []}
                        onChange={input.onChange}
                      />
                    )}
                  />
                )}
              />
              <SAFormField
                name="officialWebSiteUrl"
                validate={composeValidators<OrganismFormValues['officialWebSiteUrl']>([validateUrl, validateRequired])}
                render={({ input, meta }) => (
                  <Form.Input
                    {...input}
                    required
                    error={!!meta.touched && !!meta.error}
                    label={intl.formatMessage({
                      id: 'organism.officialWebSiteUrl',
                    })}
                    placeholder="http://www.sport.com"
                  />
                )}
              />

              {edit && organismId && (
                <SAFormField
                  name="organismPageApplicationDesignId"
                  render={({ input }: FieldRenderProps<Array<ApplicationDesign>, HTMLInputElement>) => (
                    <Form.Field
                      description={intl.formatMessage({ id: 'organism.organismPageApplicationDesignId.description' })}
                    >
                      <label>
                        <FormattedMessage id="organism.organismPageApplicationDesignId" />
                      </label>
                      <SelectApplicationDesignInput
                        {...input}
                        value={selectedOrganismPageApplicationDesignId}
                        organismId={organismId}
                        onChange={applicationDesignId =>
                          setSelectedOrganismPageApplicationDesignId(applicationDesignId)
                        }
                      />
                    </Form.Field>
                  )}
                />
              )}

              <SAFormField
                name="bannerImageFileId"
                validate={validateRequired}
                render={({ input, meta }) => (
                  <Form.Field
                    control={FileInput}
                    {...input}
                    type={MediaType.Image}
                    required
                    error={!!meta.touched && !!meta.error}
                    label={intl.formatMessage({
                      id: 'organism.bannerImageFileId',
                    })}
                    description={intl.formatMessage({
                      id: 'organism.bannerImageFileId.description',
                    })}
                    mimetype="image"
                    imageConstraints={{
                      aspect: 16 / 9,
                      minWidth: 356,
                      minHeight: 200,
                      maxSize: 3000000,
                    }}
                  />
                )}
              />

              <SAFormField
                name="bannerVideoId"
                render={({ input, meta }) => (
                  <Form.Field
                    control={FileInput}
                    {...input}
                    type={MediaType.VideoClip}
                    error={!!meta.touched && !!meta.error}
                    label={intl.formatMessage({ id: 'organism.bannerVideoId' })}
                    description={intl.formatMessage({
                      id: 'organism.bannerVideoId.description',
                    })}
                  />
                )}
              />

              <SAFormField
                name="logo.coloredImageFileId"
                validate={validateRequired}
                render={({ input, meta }) => (
                  <Form.Field
                    control={FileInput}
                    {...input}
                    type={MediaType.Image}
                    required
                    error={!!meta.touched && !!meta.error}
                    label={intl.formatMessage({
                      id: 'organism.logo.coloredImageFileId',
                    })}
                    description={intl.formatMessage({
                      id: 'organism.logo.coloredImageFileId.description',
                    })}
                    mimetype="image"
                    imageConstraints={{ minWidth: 300, minHeight: 200, maxSize: 3000000 }}
                  />
                )}
              />

              <SAFormField
                name="logo.transparentLightImageFileId"
                render={({ input, meta }) => (
                  <Form.Field
                    control={FileInput}
                    {...input}
                    type={MediaType.Image}
                    error={!!meta.touched && !!meta.error}
                    label={intl.formatMessage({
                      id: 'organism.logo.transparentLightImageFileId',
                    })}
                    description={intl.formatMessage({
                      id: 'organism.logo.transparentLightImageFileId.description',
                    })}
                    mimetype="image"
                    imageConstraints={{ minWidth: 300, minHeight: 200, maxSize: 3000000 }}
                  />
                )}
              />

              <SAFormField
                name="logo.transparentDarkImageFileId"
                render={({ input, meta }) => (
                  <Form.Field
                    control={FileInput}
                    {...input}
                    type={MediaType.Image}
                    error={!!meta.touched && !!meta.error}
                    label={intl.formatMessage({
                      id: 'organism.logo.transparentDarkImageFileId',
                    })}
                    description={intl.formatMessage({
                      id: 'organism.logo.transparentDarkImageFileId.description',
                    })}
                    mimetype="image"
                    imageConstraints={{ minWidth: 300, minHeight: 200, maxSize: 3000000 }}
                  />
                )}
              />

              <SAFormField
                name="adminBadgeId"
                render={({ input, meta }) => (
                  <Form.Field
                    control={FileInput}
                    {...input}
                    type={MediaType.Image}
                    error={!!meta.touched && !!meta.error}
                    label={intl.formatMessage({
                      id: 'organism.adminBadgeId',
                    })}
                    description={intl.formatMessage({
                      id: 'organism.adminBadgeId.description',
                    })}
                    mimetype="image"
                    imageConstraints={{ minWidth: 200, minHeight: 200, maxSize: 3000000 }}
                  />
                )}
              />

              <Segment>
                <Header as="h3">{intl.formatMessage({ id: 'organism.form.sveEngine.title' })}</Header>

                <div>
                  <div>
                    {can(Permission.SveChannelRead, undefined, true) && (
                      <SAFormField
                        name="sveChannelIds"
                        render={({ input }: FieldRenderProps<Array<string>, HTMLInputElement>) => (
                          <>
                            <Grid columns="equal">
                              <Grid.Column>
                                <Form.Field
                                  description={intl.formatMessage({
                                    id: 'organism.streamsIds.description',
                                  })}
                                >
                                  <label>
                                    <FormattedMessage id="organism.form.sve.channelIds" />
                                  </label>

                                  <SelectOrganismSVEChannelsInput
                                    ref={sveChannelSelectInputRef}
                                    organismId={values.id}
                                    {...input}
                                  />
                                </Form.Field>
                              </Grid.Column>

                              <Grid.Column verticalAlign="bottom">
                                <Button
                                  primary
                                  onClick={e => {
                                    e.preventDefault()
                                    setChannelsModalOpen(true)
                                  }}
                                >
                                  {intl.formatMessage({ id: 'organism.form.sve.channelsManagement' })}
                                </Button>
                              </Grid.Column>
                            </Grid>

                            <Modal
                              mountNode={document.getElementById('modals')}
                              open={channelsModalOpen}
                              onClose={() => {
                                setChannelsModalOpen(false)
                                sveChannelSelectInputRef.current?.refresh()
                              }}
                              closeIcon
                              size="large"
                            >
                              <Modal.Content>
                                <SVEChannelsViewCore organismId={values.id ?? null} />
                              </Modal.Content>
                            </Modal>
                          </>
                        )}
                      />
                    )}
                  </div>
                </div>
              </Segment>

              <Segment>
                <Header as="h3">{intl.formatMessage({ id: 'organism.form.videoEngine.wildmokaStreams.title' })}</Header>

                {can(Permission.ReadWmStreams, undefined, true) && (
                  <SAFormField
                    name="streamsIds"
                    render={({ input }: FieldRenderProps<Array<Scalars['WildmokaID']>, HTMLInputElement>) => (
                      <Form.Field description={intl.formatMessage({ id: 'organism.streamsIds.description' })}>
                        <label>
                          <FormattedMessage id="organism.streamsIds" />
                        </label>

                        <SelectWMStreamInput multiple {...input} loading={loadingStreams} source={dataStreams || []} />
                      </Form.Field>
                    )}
                  />
                )}

                {can(Permission.UpdateSportallPrivateFields, undefined, true) && (
                  <SAFormField
                    name="wmProfileId"
                    required={!!values.streamsIds?.length}
                    validate={!!values.streamsIds?.length ? validateRequired : undefined}
                    // We have to change the key to trigger validate function again
                    key={!!values.streamsIds?.length ? 'wmProfileId-required' : 'wmProfileId'}
                    render={({ input }: FieldRenderProps<Scalars['WildmokaID'], HTMLInputElement>) => (
                      <Form.Field>
                        <label>
                          <FormattedMessage id="organism.wmProfileId" />
                        </label>
                        <SelectWMProfileInput {...input} />
                      </Form.Field>
                    )}
                  />
                )}
              </Segment>

              {can(Permission.UpdateSportallPrivateFields, undefined, true) && (
                <>
                  <SAFormField
                    name="license.enabled"
                    render={({ input }) => (
                      <CheckboxInput
                        {...input}
                        checked={licenseEnabled}
                        name="whiteLabel"
                        label={intl.formatMessage({ id: 'organism.licenseEnabled' })}
                        onChange={checked => setLicenseEnabled(checked)}
                      />
                    )}
                  />

                  {licenseEnabled && (
                    <SAFormField
                      name="license.api"
                      render={({ input }: FieldRenderProps<LicenseApi | undefined, HTMLInputElement>) => (
                        <Form.Field>
                          <label>
                            <FormattedMessage id="organism.licenseApi" />
                          </label>

                          <SelectLicenseApiInput {...input} value={licenseApi} onChange={api => setLicenseApi(api)} />
                        </Form.Field>
                      )}
                    />
                  )}
                </>
              )}
              {can(Permission.EditOrganismWhiteLabel, undefined, true) && (
                <SAFormField
                  name="adminsAutomatedEmails"
                  render={({ input }) => (
                    <CheckboxInput
                      {...input}
                      checked={adminsAutomatedEmails}
                      name="adminsAutomatedEmails"
                      label={intl.formatMessage({ id: 'defaultConfig.emailingConfig' })}
                      onChange={checked => setAdminsAutomatedEmails(checked)}
                    />
                  )}
                />
              )}
            </>
          )
        }}
      </GenericForm>
    )
  },
)

export default OrganismForm
