import CancelFormButton from 'Components/Form/CancelFormButton'
import arrayMutators from 'final-form-arrays'
import { ApplicationContentFragment, useCreateApplicationMutation, useUpdateApplicationMutation } from 'models'
import React, { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button, Dimmer, Modal } from 'semantic-ui-react'
import { notifySuccess } from 'tools/toaster'

import ApplicationFormFields from './ApplicationFormFields'

interface ApplicationFormProps {
  isEdit: boolean
  application?: ApplicationContentFragment
  organismId?: string
  isWhiteLabel: boolean
  onCancel: () => void
  onDone?: () => void
}

const ApplicationForm = (props: ApplicationFormProps) => {
  const intl = useIntl()
  const { application, organismId, onCancel, onDone } = props
  const [updateApplication, { loading: isUpdateLoading }] = useUpdateApplicationMutation()
  const [createApplication, { loading: isCreateLoading }] = useCreateApplicationMutation()
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(isCreateLoading || isUpdateLoading)
  }, [isCreateLoading, isUpdateLoading])

  const onSubmit = async (values: any) => {
    delete values.organism
    delete values.excludedContent

    if (props.isEdit && application) {
      const result = await updateApplication({
        variables: {
          input: values,
        },
      })
      if (result && result.data) {
        notifySuccess(
          intl.formatMessage({
            id: 'application.update_success',
          }),
        )
        if (onDone) onDone()
      }
    } else if (!props.isEdit) {
      if (organismId) {
        values['organismId'] = organismId
      }
      createApplication({
        variables: {
          input: values,
        },
      }).then(result => {
        if (result && result.data) {
          notifySuccess(
            intl.formatMessage({
              id: 'application.create_success',
            }),
          )
          if (onDone) onDone()
        }
      })
    }
  }

  return (
    <Form
      onSubmit={onSubmit}
      mutators={{
        ...arrayMutators,
      }}
      initialValues={application}
      render={formProps => {
        const { handleSubmit, form, pristine, values, hasValidationErrors } = formProps
        return (
          <>
            <Modal.Header>
              <FormattedMessage id="application.title" />
            </Modal.Header>
            <Modal.Content>
              <Dimmer.Dimmable dimmed={isLoading}>
                <Dimmer active={isLoading} inverted />
                <ApplicationFormFields isWhiteLabel={props.isWhiteLabel || !organismId} values={values} />
              </Dimmer.Dimmable>
            </Modal.Content>
            <Modal.Actions>
              <CancelFormButton pristine={pristine} onConfirm={form.reset} onCancel={onCancel} />
              <Button positive loading={isLoading} onClick={() => handleSubmit()} disabled={hasValidationErrors}>
                <FormattedMessage id="common.submit" />
              </Button>
            </Modal.Actions>
          </>
        )
      }}
    />
  )
}

export default ApplicationForm
