import SelectItemListInput from 'Components/Form/SelectItemListInput'
import { useGetSportsShort } from 'models'
import React, { useMemo } from 'react'
import { Maybe, Scalars, Sport } from 'services/api/graphql'

export type LightSportPageResult = {
  sports?: Maybe<Array<{ id: Sport['id']; title: Sport['title'] }>>
  totalCount?: Maybe<Scalars['Int']>
}

export interface SelectSportInputProps {
  value: Maybe<Sport['id']> | Array<Sport['id']>
  onChange: (value: Maybe<Sport['id']> | Array<Sport['id']>) => void
  noSelectionEnabled?: boolean
  noSelectionTitle?: string
  multiple?: boolean
  filter?: Array<Sport['id']>
  loading?: boolean
}

const SelectSportInput = ({
  value,
  onChange,
  noSelectionEnabled,
  noSelectionTitle = 'Aucun',
  multiple = false,
  filter,
  loading,
}: SelectSportInputProps) => {
  const { data, loading: loadingSports /*, error, refetch*/ } = useGetSportsShort()
  const options = useMemo(() => {
    const sports = (data && data.sports && data.sports.sports) || []
    return sports
      .filter(sport => (filter ? filter.includes(sport.id) : true))
      .map(sport => ({
        key: sport.id,
        value: sport.id,
        text: sport.title,
      }))
  }, [data, filter])
  return (
    <SelectItemListInput
      value={value}
      loading={loading || loadingSports}
      options={options}
      noSelectionEnabled={noSelectionEnabled}
      noSelectionTitle={noSelectionTitle}
      onChange={onChange}
      multiple={multiple}
    />
  )
}

export default SelectSportInput
