import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import Duration from './Duration'

interface TimerProps {
  end: Date
  onEnd?: () => void
}

const Container = styled.span`
  font-weight: bold;
`

const Timer: React.FC<TimerProps> = ({ end, onEnd }) => {
  const [timeRemain, setTimeRemain] = useState(-1)
  const intervalRef = useRef<NodeJS.Timeout>()

  const updateTimer = useCallback(() => {
    setTimeRemain(t => {
      const secondsLeft = t - 1
      if (secondsLeft <= 0) {
        if (intervalRef.current) clearInterval(intervalRef.current)
        if (onEnd) onEnd()
      }
      return secondsLeft
    })
  }, [onEnd])

  useEffect(() => {
    const now = new Date()
    if (now >= end) {
      if (onEnd) onEnd()
    } else {
      setTimeRemain((end.getTime() - now.getTime()) / 1000)
      intervalRef.current = setInterval(updateTimer, 1000)
    }
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, [end, onEnd, updateTimer])

  return (
    <Container>
      <Duration valueSec={timeRemain} full />
    </Container>
  )
}

export default Timer
