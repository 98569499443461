import React from 'react'
import { Checkbox, HtmlLabelProps } from 'semantic-ui-react'
import { SemanticShorthandItem } from 'semantic-ui-react/dist/commonjs/generic'

interface CheckboxInputProps {
  name: string
  onChange: (v: boolean) => void
  checked?: boolean
  disabled?: boolean
  label?: SemanticShorthandItem<HtmlLabelProps>
  toggle?: boolean
}

const CheckboxInput: React.FC<CheckboxInputProps> = ({ name, onChange, checked, disabled, label, toggle }) => {
  return (
    <Checkbox
      type="checkbox"
      toggle={toggle}
      name={name}
      value={name}
      checked={checked || false}
      onChange={() => onChange(!checked)}
      disabled={disabled}
      label={label}
    />
  )
}

export default CheckboxInput
