import { useEffect, useState } from 'react'

export const useFileReader = (blob: Blob | undefined) => {
  const [result, setResult] = useState<string>()
  const [loading, setLoading] = useState(!!blob)
  const [error, setError] = useState<ProgressEvent>()
  useEffect(() => {
    if (!blob) return
    const fileReader = new FileReader()
    fileReader.onload = () => setResult(fileReader.result as string)
    fileReader.onerror = err => setError(err)
    fileReader.onloadstart = () => setLoading(true)
    fileReader.onloadend = () => setLoading(false)
    fileReader.readAsDataURL(blob)
  }, [blob])
  return { result, loading, error }
}
