import CreateSportForm from 'Forms/Sport/CreateSportForm'
import React, { Fragment, useState } from 'react'
import { useIntl } from 'react-intl'
import { notifyError, notifySuccess } from 'tools/toaster'

interface AddSportButtonProps {
  onDone: () => void
  render: (onClick: () => void) => React.ReactNode
}
const AddSportButton = ({ onDone, render }: AddSportButtonProps) => {
  const intl = useIntl()
  const [form, setForm] = useState<React.ReactNode>()
  const handleClick = () => {
    setForm(
      <CreateSportForm
        onCancel={() => setForm(null)}
        onDone={sport => {
          setForm(null)
          notifySuccess(intl.formatMessage({ id: 'sports.form.create.success' }, { title: sport.title }))
          onDone()
        }}
        onError={notifyError}
      />,
    )
  }
  return (
    <Fragment>
      {render(handleClick)}
      {form}
    </Fragment>
  )
}

export default AddSportButton
