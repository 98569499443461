import { Reducer, useCallback, useMemo, useReducer, useRef } from 'react'

export type UseEventEmitterCallback<Event, Payload> = (event: Event, payload?: Payload) => void
export type UseEventEmitterState<Event, Payload> = Array<UseEventEmitterCallback<Event, Payload>>
export type UseEventEmitterAction<Event, Payload> = {
  type: 'subscribe' | 'unsubscribe'
  callback: UseEventEmitterCallback<Event, Payload>
}
export type UseEventEmitterUnsubscribe = () => void
export type UseEventEmitterSubscribe<Event, Payload> = (
  cb: UseEventEmitterCallback<Event, Payload>,
) => UseEventEmitterUnsubscribe
export type UseEventEmitterDispatch<Event, Payload> = UseEventEmitterCallback<Event, Payload>
export type UseEventEmitterReturn<Event, Payload> = [
  UseEventEmitterSubscribe<Event, Payload>,
  UseEventEmitterCallback<Event, Payload>,
]

/**
 * Hook to create an event emitter.
 *
 * Usage:
 *
 * ```ts
 * const [subscribe, dispatch] = useEventEmitter<string, any>()
 * const callback = useCallback((event, payload) => {
 *   console.log(event, payload) // foo bar
 * }, [])
 *
 * useEffect(() => subscribe(callback), [callback, subscribe])
 *
 * dispatch('foo', 'bar')
 * ```
 */
export const useEventEmitter = <Event, Payload = any>() => {
  const [subscribers, dispatch] = useReducer<
    Reducer<UseEventEmitterState<Event, Payload>, UseEventEmitterAction<Event, Payload>>
  >((state, { type, callback }) => {
    switch (type) {
      case 'subscribe': {
        return state.includes(callback) ? state : [...state, callback]
      }
      case 'unsubscribe': {
        return !state.includes(callback) ? state : [...state.filter(cb => cb !== callback)]
      }
      default: {
        throw new Error(`Unknown useEventEmitter action: ${type}`)
      }
    }
  }, [])

  const unsubscribe = useCallback<(cb: UseEventEmitterCallback<Event, Payload>) => void>(callback => {
    dispatch({ type: 'unsubscribe', callback })
  }, [])

  const subscribe = useCallback<(cb: UseEventEmitterCallback<Event, Payload>) => () => void>(
    callback => {
      dispatch({ type: 'subscribe', callback })
      return () => {
        unsubscribe(callback)
      }
    },
    [unsubscribe],
  )

  const subscribersRef = useRef(subscribers)
  subscribersRef.current = useMemo(() => subscribers, [subscribers])

  const dispatchEvent = useCallback<UseEventEmitterCallback<Event, Payload>>((event, payload) => {
    subscribersRef?.current.forEach(cb => cb(event, payload))
  }, [])

  return useMemo<UseEventEmitterReturn<Event, Payload>>(() => [subscribe, dispatchEvent], [subscribe, dispatchEvent])
}
