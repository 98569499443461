import { GetMediasResult, useGetMedias } from 'models'
import { useCallback } from 'react'
import { MediaType, Organism } from 'services/api/graphql'

interface UseFetchFileLoadMoreProps {
  search?: string | undefined
  type?: MediaType | undefined
  pageSize?: number
  organismId?: Organism['id']
}
export const useFetchMediasLoadMore = ({ search, type, pageSize = 10, organismId }: UseFetchFileLoadMoreProps) => {
  const { data, fetchMore, refetch, loading } = useGetMedias({
    variables: {
      first: pageSize,
      offset: 0,
      search,
      type,
      organismId,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  })
  const medias = (data && data.medias && data.medias.medias) || []
  const totalCount = (data && data.medias && data.medias.totalCount) || 0

  const loadMore = useCallback(
    async (page: number) => {
      await fetchMore({
        variables: {
          first: pageSize,
          offset: page * pageSize,
          search,
          type,
          organismId,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev
          if (!prev) return fetchMoreResult
          const result: { medias: GetMediasResult } = Object.assign({}, prev, fetchMoreResult)
          result.medias.medias = [...(prev.medias && prev.medias.medias), ...fetchMoreResult.medias.medias]
          return result
        },
      })
    },
    [fetchMore, type, search, pageSize, organismId],
  )

  return { medias, totalCount, loadMore, refetch, loading }
}
