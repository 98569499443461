import { $generateHtmlFromNodes } from '@lexical/html'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin'
import { $getRoot, EditorState, LexicalEditor } from 'lexical'
import React, { FC, useCallback, useRef } from 'react'

export type JsonHtmlSerializerPluginProps = {
  value?: string
  onChange?: (editorState: string) => void
}

const handleChange = (editorState: EditorState, editor: LexicalEditor) => {
  const json = editorState.toJSON()
  const html = $generateHtmlFromNodes(editor)
  const text = editorState.read(() => $getRoot().getTextContent())
  return JSON.stringify({ json, html, text })
}

/**
 * Plugin to provide value & onChange props in both html & lexical JSON format.
 *
 * This is a temporary fix due to lexical html parser issues so we also need the json state.
 *
 * @see https://github.com/facebook/lexical/issues/3879
 * @see https://github.com/facebook/lexical/issues/3042
 * @see https://github.com/facebook/lexical/issues/2452
 */
const JsonHtmlSerializerPlugin: FC<JsonHtmlSerializerPluginProps> = ({ value, onChange }) => {
  const [editor] = useLexicalComposerContext()
  const initialized = useRef(false)

  // initial state value
  if (!initialized.current) {
    if (value) {
      // restore editor state
      editor.update(() => {
        // parse json to lexical state
        const parsedValue = JSON.parse(value)
        const state = parsedValue?.json ?? parsedValue
        const editorState = editor.parseEditorState(state)
        editor.setEditorState(editorState)
      })
      // trigger on change after state has been restored
      if (onChange) {
        const editorState = editor.getEditorState()
        editorState.read(() => {
          onChange(handleChange(editorState, editor))
        })
      }
    }
    initialized.current = true
  }

  // <OnChangePlugin> callback
  const onEditorChange = useCallback<(editorState: EditorState, editor: LexicalEditor) => void>(
    (editorState, editor) => {
      // trigger on change
      if (onChange) {
        editorState.read(() => {
          onChange(handleChange(editorState, editor))
        })
      }
    },
    [onChange],
  )

  return <OnChangePlugin onChange={onEditorChange} />
}

export default JsonHtmlSerializerPlugin
