import React, { useCallback, useState } from 'react'
import { useEffect } from 'react'
import { Dropdown, DropdownItemProps } from 'semantic-ui-react'

export interface SelectMultipleTextInputProps {
  value: string[] | undefined
  onChange: (value: string[]) => void
}

const SelectMultipleTextInput = ({ value, onChange }: SelectMultipleTextInputProps) => {
  const [options, setOptions] = useState<DropdownItemProps[]>([])
  useEffect(() => {
    setOptions(
      (Array.isArray(value) ? value : []).map(text => ({
        key: text,
        text,
        value: text,
      })),
    )
  }, [value])

  const handleOnAddItem = useCallback(
    (event, { value: newValue }) => {
      const text = newValue as string
      setOptions([{ key: text, text, value: text }, ...options])
    },
    [options],
  )
  const handleOnChange = useCallback(
    (event, { value: newValue }) => {
      onChange(newValue)
    },
    [onChange],
  )

  return (
    <Dropdown
      search
      selection
      multiple
      allowAdditions
      options={options}
      value={value}
      onAddItem={handleOnAddItem}
      onChange={handleOnChange}
    />
  )
}
export default SelectMultipleTextInput
