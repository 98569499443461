import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Header, List, Modal } from 'semantic-ui-react'
import { Organism, useOrganismQuery, useSveChannelsQuery } from 'services/api/graphql'

import { SVEChannelStateTag } from './SVEChannelStateTag'

interface LiveSVEChannelDetailsModalProps {
  organismId: Organism['id']
  open: boolean
  onClose: () => void
}

export const SVEChannelDetailsModal: React.FC<LiveSVEChannelDetailsModalProps> = ({ organismId, open, onClose }) => {
  const OrganismQuery = useOrganismQuery({ variables: { organismId } })
  const organism = OrganismQuery.data?.organism
  const SVEChannelsQuery = useSveChannelsQuery({
    variables: { input: { filters: { channelIds: organism?.sveChannelIds ?? [] } } },
    skip: !organism?.sveChannelIds,
    fetchPolicy: 'cache-and-network',
  })
  const sveChannels = SVEChannelsQuery.data?.sveChannels.sveChannels
  return (
    <Modal mountNode={document.getElementById('modals')} open={open} onClose={onClose} closeIcon>
      <Modal.Content>
        {sveChannels?.length === 0 && <FormattedMessage id="sveChannels.noChannels" />}
        {sveChannels?.map(sveChannel => (
          <React.Fragment key={sveChannel.id}>
            <Header as="h3">
              {sveChannel.name}
              <SVEChannelStateTag state={sveChannel.status} />
            </Header>
            <List>
              <List.Item>
                <List.Header>
                  <FormattedMessage id="sveChannels.channelId" />
                </List.Header>
                <List.Description>{sveChannel.id}</List.Description>
              </List.Item>

              {sveChannel.endpoints?.rtmpInputUrl && (
                <List.Item>
                  <List.Header>
                    <FormattedMessage id="sveChannels.endpoints.inputUrl" />
                  </List.Header>
                  <List.Description>{sveChannel.endpoints?.rtmpInputUrl}</List.Description>
                </List.Item>
              )}
              {sveChannel.endpoints?.cmafEndpointUrl && (
                <List.Item>
                  <List.Header>
                    <FormattedMessage id="sveChannels.endpoints.outputUrl" />
                  </List.Header>
                  <List.Description>{sveChannel.endpoints?.cmafEndpointUrl}</List.Description>
                </List.Item>
              )}
              {sveChannel.endpoints?.hlsEndpointUrl && (
                <List.Item>
                  <List.Header>
                    <FormattedMessage id="sveChannels.endpoints.hlsEndpointUrl" />
                  </List.Header>
                  <List.Description>{sveChannel.endpoints?.hlsEndpointUrl}</List.Description>
                </List.Item>
              )}
            </List>
          </React.Fragment>
        ))}
      </Modal.Content>
    </Modal>
  )
}
