import { SelectedTranslationsType, TranslatedInput } from 'Components/Form/SelectLocalInput'
import Config from 'config'
import { ContentTranslationInput } from 'models'
import { IntlShape } from 'react-intl'
import { Nullable } from 'types/utils'

export const getTranslationInputFromForm: (
  selectedTranslations: SelectedTranslationsType[],
  value?: TranslatedInput | null,
) => ContentTranslationInput[] | undefined = (selectedTranslations, value) =>
  !value
    ? undefined
    : selectedTranslations
        .filter(st => !!value?.[st.value])
        .map(st => ({
          locale: st.value,
          content: value?.[st.value] || '',
        }))

export const validateTranslation = (
  activeTranslation: string | undefined,
  value: string | undefined,
  intl: IntlShape,
) => ({
  [`${activeTranslation}`]: !!value ? undefined : intl.formatMessage({ id: 'errors.fillOriginalFormValue' }),
})

type TranslationValueType = {
  descriptionT?: ContentTranslationInput[] | null
  subtitleT?: ContentTranslationInput[] | null
  contentT?: ContentTranslationInput[] | null
  titleT?: ContentTranslationInput[] | null
  nameT?: ContentTranslationInput[] | null
}

export type TranslationInputType = Nullable<{
  descriptionT?: TranslatedInput
  subtitleT?: TranslatedInput
  contentT?: TranslatedInput
  titleT?: TranslatedInput
  nameT?: TranslatedInput
}>

export const getTranslationInputFromValues: <T>(
  values: T & TranslationValueType,
) => T & TranslationInputType = values => {
  let translations: TranslationInputType = {
    descriptionT: undefined,
    titleT: undefined,
    subtitleT: undefined,
    contentT: undefined,
    nameT: undefined,
  }

  values.titleT?.forEach(({ locale, content }) => {
    translations = { ...translations, titleT: { ...translations.titleT, [`${locale}`]: content } }
  })
  values.descriptionT?.forEach(({ locale, content }) => {
    translations = { ...translations, descriptionT: { ...translations.descriptionT, [`${locale}`]: content } }
  })
  values.subtitleT?.forEach(({ locale, content }) => {
    translations = { ...translations, subtitleT: { ...translations.subtitleT, [`${locale}`]: content } }
  })
  values.contentT?.forEach(({ locale, content }) => {
    translations = { ...translations, contentT: { ...translations.contentT, [`${locale}`]: content } }
  })
  values.nameT?.forEach(({ locale, content }) => {
    translations = { ...translations, subtitleT: { ...translations.nameT, [`${locale}`]: content } }
  })

  return {
    ...values,
    contentT: translations.contentT,
    descriptionT: translations.descriptionT,
    subtitleT: translations.subtitleT,
    titleT: translations.titleT,
    nameT: translations.nameT,
  }
}

export const getSelectedTranslations: (values: {} & TranslationInputType) => SelectedTranslationsType[] = values =>
  Config.availableLocalesOptions.filter(
    ({ value }) =>
      values?.subtitleT?.[value] ||
      values?.descriptionT?.[value] ||
      values?.titleT?.[value] ||
      values?.nameT?.[value] ||
      values?.contentT?.[value],
  ) || []

/**
 * Extracts either the json or the html content value of a wysiwyg TranslatedInput.
 * Json state of the editor is needed because of the HTML parser not working as expected.
 *
 * @param input a string representing both the json & html state of the wysiwyg editor
 * @param type json or html
 *
 * @see https://github.com/facebook/lexical/issues/3879
 * @see https://github.com/facebook/lexical/issues/3042
 * @see https://github.com/facebook/lexical/issues/2452
 */
export const getContentTProp = (
  input: TranslatedInput | undefined | null,
  type: 'json' | 'html',
): TranslatedInput | undefined | null =>
  input &&
  Object.entries(input).reduce((acc, [key, value]) => {
    // value is a string representing a `{ json, html }` object
    const parsed = JSON.parse(value)
    // extract json or html from parsed value
    const content = parsed[type]
    // if not a string (json), restringify it for output
    const output = typeof content !== 'string' ? JSON.stringify(content) : content

    return { ...acc, [key]: output }
  }, {})
