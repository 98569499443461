import { useCallback, useState } from 'react'
import { CropInfo, getCroppedImage } from 'tools/image'
import { ImageFormat } from 'types/image'

export type CropImageParams = {
  image: HTMLImageElement
  cropInfo: CropInfo
  format: ImageFormat
  filename?: string
}
export const useCroppedImage = () => {
  const [croppedImage, setCroppedImage] = useState<File | Blob | null>(null)
  const [error, setError] = useState<Error>()
  const [loading, setLoading] = useState(false)
  const cropImage = useCallback(async (cropImageInfo: CropImageParams | undefined | null) => {
    if (!cropImageInfo) {
      setCroppedImage(null)
      return
    }

    const { image, cropInfo, format, filename } = cropImageInfo

    setLoading(true)

    try {
      setCroppedImage(await getCroppedImage(image, cropInfo, format, filename))
    } catch (error: any) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }, [])

  return { image: croppedImage, error, loading, cropImage }
}
