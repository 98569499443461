import React from 'react'
import { IconSizeProp } from 'semantic-ui-react/dist/commonjs/elements/Icon/Icon'
import { UserRoleId } from 'services/api/graphql'

import UserRoleIcon from './UserRoleIcon'

export interface UserRolesIconsListProps {
  roleIds: UserRoleId[]
  size?: IconSizeProp
}

const UserRolesIconsList = ({ roleIds, size }: UserRolesIconsListProps) => (
  <div>
    {roleIds.map(roleId => (
      <UserRoleIcon key={roleId} roleId={roleId} size={size} />
    ))}
  </div>
)

export default UserRolesIconsList
