import SelectItemListInput, { SelectItemListInputProps } from 'Components/Form/SelectItemListInput'
import { useGetApplicationDesigns } from 'models/ApplicationDesign'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { ApplicationDesign, Maybe } from 'services/api/graphql'

export interface SelectApplicationDesignInputProps extends Omit<SelectItemListInputProps, 'options'> {
  value?: string | null
  organismId: ApplicationDesign['id']
  onChange: (value: Maybe<ApplicationDesign['id']>) => void
}

export const SelectApplicationDesignInput = ({
  value,
  organismId,
  onChange,
  ...props
}: SelectApplicationDesignInputProps) => {
  const { data, loading } = useGetApplicationDesigns({ variables: { filters: { organismId } } })
  const intl = useIntl()
  const applicationDesigns = useMemo(() => (data && data.applicationDesigns.applicationDesigns) || [], [data])
  const options = useMemo(() => {
    return applicationDesigns.map(applicationDesign => ({
      key: applicationDesign.id,
      value: applicationDesign.id,
      text: applicationDesign.title,
    }))
  }, [applicationDesigns])
  return (
    <SelectItemListInput
      value={value}
      loading={loading}
      options={options}
      onChange={onChange}
      {...props}
      noSelectionEnabled={true}
      noSelectionTitle={intl.formatMessage({ id: 'common.none' })}
    />
  )
}
