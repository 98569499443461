import FilterableTableHeader, { SemanticUISortDirection } from 'Components/FilterableTableHeader'
import FrontPageStatsPdfRender, { FrontPageStatsRowData } from 'Components/FrontPageStatsPdfRender'
import Loader from 'Components/Loader'
import PageHeader from 'Components/PageHeader'
import { applicationUris } from 'Layout/uris'
import { useGetOrganismTitles, useGetSportItemSetsForFrontPages } from 'models'
import React, { FC, useCallback, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { Button, DropdownItemProps, Grid, Table } from 'semantic-ui-react'
import {
  FrontPage,
  SortDirection,
  SportItemSet,
  SportItemSetFilters,
  SportItemSetSort,
  SportItemSetSortProperty,
  SportItemSetType,
} from 'services/api/graphql'
import { format } from 'tools/date'

const FrontPagesStatsView: FC = () => {
  const intl = useIntl()

  const [filters, setFilters] = useState<SportItemSetFilters>({})
  const [orderBy, setOrderBy] = useState<SportItemSetSort[]>([])
  const { sportItemSets, loading } = useGetSportItemSetsForFrontPages({
    variables: { filters, orderBy },
  })

  const handleSort = useCallback(
    (property: SportItemSetSortProperty) => {
      const sort = orderBy.find(sortItem => sortItem.property === property)
      if (sort) {
        if (sort.direction === SortDirection.Ascending) {
          setOrderBy([
            ...orderBy.filter(sortItem => sortItem.property !== property),
            { property, direction: SortDirection.Descending },
          ])
        } else {
          setOrderBy(orderBy.filter(sortItem => sortItem.property !== property))
        }
      } else {
        setOrderBy([...orderBy, { property, direction: SortDirection.Ascending }])
      }
    },
    [orderBy],
  )
  const getSortState = useCallback(
    (property: SportItemSetSortProperty): SemanticUISortDirection | undefined => {
      const sort = orderBy.find(sortItem => sortItem.property === property)
      if (!sort) return undefined
      return sort.direction === SortDirection.Descending
        ? SemanticUISortDirection.descending
        : SemanticUISortDirection.ascending
    },
    [orderBy],
  )

  const msInDay = 24 * 60 * 60 * 1000
  const getDaysOnFront = useCallback((time: number): number => Math.round(time / msInDay), [msInDay])
  const sportItemSetsOnFront: SportItemSet[] = useMemo(() => {
    if (!sportItemSets) return []
    return sportItemSets.filter(sportItemSet => Number(sportItemSet.timeOnFront) > msInDay)
  }, [msInDay, sportItemSets])
  const getLastFrontPage = (frontPages: FrontPage[]): FrontPage | undefined =>
    [...frontPages].sort((a, b) => (new Date(a.start) < new Date(b.start) ? 1 : -1))[0]

  const typesOptions: DropdownItemProps[] = useMemo(
    () =>
      (Object.keys(SportItemSetType) as Array<keyof typeof SportItemSetType>).map(type => ({
        key: type,
        value: SportItemSetType[type],
        text: intl.formatMessage({
          id: `sportItemSetType.${SportItemSetType[type]}`,
        }),
      })),
    [intl],
  )

  const { organisms } = useGetOrganismTitles()

  const organismOptions: DropdownItemProps[] = useMemo(() => {
    if (!organisms) return []

    return organisms.map(({ id, title }) => ({
      key: id,
      value: id,
      text: title,
    }))
  }, [organisms])

  const tableData: FrontPageStatsRowData[] = useMemo(
    () =>
      sportItemSetsOnFront.map(sportItemSet => {
        const lastFrontPage = getLastFrontPage(sportItemSet.frontPages)
        return {
          id: sportItemSet.id,
          title: sportItemSet.title,
          organism: (sportItemSet.ownerOrganism && sportItemSet.ownerOrganism.title) || undefined,
          type: sportItemSet.type,
          days: getDaysOnFront(Number(sportItemSet.timeOnFront)),
          start: (lastFrontPage && lastFrontPage.start) || new Date(),
          end: (lastFrontPage && lastFrontPage.end) || undefined,
        }
      }),
    [getDaysOnFront, sportItemSetsOnFront],
  )

  return (
    <Loader loading={loading}>
      <Grid padded>
        <Grid.Column>
          <PageHeader title={<FormattedMessage id="frontPagesStats.title" />}>
            <Button as={Link} to={applicationUris.frontPages}>
              <FormattedMessage id="frontPagesStats.back" />
            </Button>
            <FrontPageStatsPdfRender
              data={tableData}
              fileName={`front-pages_stats_${format(new Date(), 'DD/MM/YY')}.pdf`}
              label={<FormattedMessage id="frontPagesStats.download" />}
            />
          </PageHeader>

          <Table sortable>
            <Table.Header>
              <Table.Row textAlign="left">
                <Table.HeaderCell
                  onClick={() => handleSort(SportItemSetSortProperty.Title)}
                  sorted={getSortState(SportItemSetSortProperty.Title)}
                >
                  <FormattedMessage id="frontPages.sportitemSet.title" />
                </Table.HeaderCell>
                <FilterableTableHeader<string>
                  label={<FormattedMessage id="frontPages.sportitemSet.organism" />}
                  options={organismOptions}
                  onChange={organismId =>
                    setFilters(filtersValues => ({
                      ...filtersValues,
                      organismId,
                    }))
                  }
                  value={filters.organismId || undefined}
                />
                <FilterableTableHeader<SportItemSetType>
                  label={<FormattedMessage id="frontPages.sportitemSet.type" />}
                  options={typesOptions}
                  onChange={type => setFilters(filtersValues => ({ ...filtersValues, type }))}
                  value={filters.type || undefined}
                  onClick={() => handleSort(SportItemSetSortProperty.Type)}
                  sorted={getSortState(SportItemSetSortProperty.Type)}
                />
                <Table.HeaderCell
                  onClick={() => handleSort(SportItemSetSortProperty.TimeOnFront)}
                  sorted={getSortState(SportItemSetSortProperty.TimeOnFront)}
                >
                  <FormattedMessage id="frontPagesStats.sportitemSet.timeOnFront" />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <FormattedMessage id="frontPagesStats.sportitemSet.lastStart" />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <FormattedMessage id="frontPagesStats.sportitemSet.lastEnd" />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {tableData.map(row => {
                return (
                  <Table.Row textAlign="center" key={row.id}>
                    <Table.Cell>{row.title}</Table.Cell>
                    <Table.Cell>{row.organism}</Table.Cell>
                    <Table.Cell>
                      <FormattedMessage id={`sportItemSetType.${row.type}`} />
                    </Table.Cell>
                    <Table.Cell>{row.days}</Table.Cell>
                    <Table.Cell>{format(row.start, 'DD MMMM YYYY')}</Table.Cell>
                    <Table.Cell>
                      {row.end ? format(row.end, 'DD MMMM YYYY') : <FormattedMessage id="frontPagesStats.running" />}
                    </Table.Cell>
                  </Table.Row>
                )
              })}
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid>
    </Loader>
  )
}

export default FrontPagesStatsView
