import ImagePreviewButton from 'Components/Button/ImagePreviewButton'

import React from 'react'
import { File } from 'services/api/graphql'

export interface ImageFilePreviewButtonProps {
  title?: string | undefined
  imageFile: File
}

const ImageFilePreviewButton = ({ title, imageFile }: ImageFilePreviewButtonProps) => {
  return <ImagePreviewButton title={title} imageUrl={imageFile.downloadUrl} thumbUrl={imageFile.thumbUrl} />
}

export default ImageFilePreviewButton
