import { QueryHookOptions, useMutation, useQuery } from '@apollo/client'
import {
  CreateCustomPageInput,
  CREATE_CUSTOM_PAGE_MUTATION,
  CustomPage,
  DELETE_CUSTOM_PAGE_MUTATION,
  GET_CUSTOM_PAGES_QUERY,
  MoveCustomPageInput,
  MOVE_CUSTOM_PAGE_MUTATION,
  QueryCustomPagesArgs,
  SET_HOMEPAGE_MUTATION,
  UpdateCustomPageInput,
  UPDATE_CUSTOM_PAGE_MUTATION,
  GET_CUSTOM_PAGE_QUERY,
} from 'services/api/graphql'

export type GetCustomPagesResult = {
  customPages: {
    customPages: CustomPage[]
    totalCount: number
  }
}

export type GetCustomPageResult = {
  customPage: CustomPage
}

export const useGetCustomPages = (options?: QueryHookOptions<GetCustomPagesResult, QueryCustomPagesArgs>) =>
  useQuery(GET_CUSTOM_PAGES_QUERY, options)

export const useGetCustomPage = (customPageId: CustomPage['id'], options?: QueryHookOptions<GetCustomPageResult>) =>
  useQuery(GET_CUSTOM_PAGE_QUERY, {
    variables: { customPageId },
    ...options,
    skip: !customPageId,
  })

export const useCreateCustomPage = () =>
  useMutation<{ createCustomPage: CustomPage }, { input: CreateCustomPageInput }>(CREATE_CUSTOM_PAGE_MUTATION)

export const useUpdateCustomPage = () =>
  useMutation<{ updateCustomPage: CustomPage }, { input: UpdateCustomPageInput }>(UPDATE_CUSTOM_PAGE_MUTATION)

export const useDeleteCustomPage = () =>
  useMutation<{ deleteCustomPage: CustomPage }, { id: CustomPage['id'] }>(DELETE_CUSTOM_PAGE_MUTATION)

export const useMoveCustomPage = () => {
  return useMutation<{ moveCustomPage: CustomPage[] }, { input: MoveCustomPageInput }>(MOVE_CUSTOM_PAGE_MUTATION)
}

export const useSetHomePage = () =>
  useMutation<{ setHomePage: CustomPage }, { id: CustomPage['id'] }>(SET_HOMEPAGE_MUTATION)
