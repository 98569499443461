import { QueryHookOptions, useMutation, useQuery } from '@apollo/client'
import {
  AvailableContentPushService,
  ContentPushStream,
  ContentPushStreamsQueryVariables,
  CreateContentPushStreamInput,
  OutputStream,
  QueryContentPushStreamExportsArgs,
  SportItemSetsPageResult,
  UpdateContentPushStreamInput,
  UpdateOutputStreamsInput,
} from 'services/api/graphql'
import {
  CREATE_CONTENT_PUSH_STREAM_MUTATION,
  DELETE_CONTENT_PUSH_STREAM_MUTATION,
  GET_AVAILABLE_CONTENT_PUSH_SERVICES_QUERY,
  GET_CONTENT_PUSH_STREAMS_QUERY,
  GET_CONTENT_PUSH_STREAM_EXPORTS,
  GET_OUTPUT_STREAMS,
  SYNC_NOW_MUTATION,
  UPDATE_CONTENT_PUSH_STREAM_MUTATION,
  UPDATE_OUTPUT_STREAMS_MUTATION,
} from 'services/api/graphql/ContentPushStream'

type GetContentPushStreamExports = {
  contentPushStreamExports: SportItemSetsPageResult
}

export const useGetContentPushStreamExports = (
  options?: QueryHookOptions<GetContentPushStreamExports, QueryContentPushStreamExportsArgs>,
) => {
  const { data, ...query } = useQuery(GET_CONTENT_PUSH_STREAM_EXPORTS, options)

  return {
    data: {
      sportItemSets: data?.contentPushStreamExports.sportItemSets,
      totalCount: data?.contentPushStreamExports.totalCount,
    },
    ...query,
  }
}

type GetAvailableContentPushServicesResult = {
  availableContentPushServices: AvailableContentPushService[]
}

export const useGetAvailableContentPushServices = (
  options?: QueryHookOptions<GetAvailableContentPushServicesResult>,
) => {
  const { data, ...query } = useQuery(GET_AVAILABLE_CONTENT_PUSH_SERVICES_QUERY, {
    ...options,
  })

  return {
    data: data ? data.availableContentPushServices : [],
    ...query,
  }
}

type GetContentPushStreamsResult = {
  contentPushStreams: ContentPushStream[]
}

export const useGetContentPushStreams = (
  options?: QueryHookOptions<GetContentPushStreamsResult, ContentPushStreamsQueryVariables>,
) => {
  const { data, ...query } = useQuery<GetContentPushStreamsResult, ContentPushStreamsQueryVariables>(
    GET_CONTENT_PUSH_STREAMS_QUERY,
    options,
  )

  return {
    data: data
      ? data.contentPushStreams.map(cps => ({
          ...cps,
          contentDeliveryServiceConfig: {
            ...cps.contentDeliveryServiceConfig,
            periodicity: {
              ...cps.contentDeliveryServiceConfig.periodicity,
              firstSendDate:
                cps.contentDeliveryServiceConfig.periodicity?.firstSendDate &&
                new Date(cps.contentDeliveryServiceConfig.periodicity.firstSendDate),
            },
          },
        }))
      : [],
    ...query,
  }
}

type GetOutputStreamsResult = {
  outputStreams: OutputStream[]
}

export const useGetOutputStreams = (options?: QueryHookOptions<GetOutputStreamsResult>) => {
  const { data, ...query } = useQuery(GET_OUTPUT_STREAMS, {
    ...options,
  })

  return {
    data: data ? data.outputStreams : [],
    ...query,
  }
}

export const useCreateContentPushStream = () =>
  useMutation<{ createContentPushStream: ContentPushStream }, { input: CreateContentPushStreamInput }>(
    CREATE_CONTENT_PUSH_STREAM_MUTATION,
  )

export const useUpdateContentPushStream = () =>
  useMutation<{ updateContentPushStream: ContentPushStream }, { input: UpdateContentPushStreamInput }>(
    UPDATE_CONTENT_PUSH_STREAM_MUTATION,
  )

export const useDeleteContentPushStream = () =>
  useMutation<{ deleteContentPushStream: string }, { id: string }>(DELETE_CONTENT_PUSH_STREAM_MUTATION)

export const useUpdateOutputStreams = () =>
  useMutation<{ syncNow: string }, { input: UpdateOutputStreamsInput }>(UPDATE_OUTPUT_STREAMS_MUTATION)

export const useSyncNow = () => useMutation<{ updateOutputStreams: OutputStream[] }, { id: string }>(SYNC_NOW_MUTATION)
