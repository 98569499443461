import { File as SportallFile } from 'services/api/graphql'
import { doesImageFormatRespectConstraints, doesImageSizeRespectConstraints } from 'tools/image'
import { ImageConstraints, ImageDimensions } from 'types/image'

export const fileImageDimensions = (file: SportallFile): ImageDimensions | undefined => {
  const { metadata } = file
  if (!metadata) return
  const { width, height } = metadata
  if (width === undefined || width === null) return
  if (height === undefined || height === null) return
  return { width, height }
}

export const doesImageFileRespectConstraints = (file: SportallFile, constraints?: ImageConstraints): boolean => {
  if (!constraints) return true

  // get image dimensions (must be an image ;-))
  const imageDimensions = fileImageDimensions(file)
  if (!imageDimensions) return false

  if (constraints.format && !doesImageFormatRespectConstraints(file, constraints.format)) return false

  return doesImageSizeRespectConstraints(imageDimensions, constraints)
}
