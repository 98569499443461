import ConfirmationButton, { ConfirmationButtonBaseProps } from 'Containers/Button/ConfirmationButton'
import { useDeleteNews } from 'models'
import React, { FC } from 'react'
import { News } from 'services/api/graphql'

interface RemoveNewsButtonProps extends ConfirmationButtonBaseProps {
  newsId: News['id']
}

const RemoveNewsButton: FC<RemoveNewsButtonProps> = ({ newsId, ...props }) => {
  const [removeNews] = useDeleteNews()
  return <ConfirmationButton action={() => removeNews({ variables: { newsId } })} {...props} />
}

export default RemoveNewsButton
