import styled from 'styled-components'

export interface ColorCircleProps {
  color?: string | undefined
}
const ColorCircle: (props: ColorCircleProps) => JSX.Element = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background-color: ${props => props.color || 'transparent'};
  border-width: ${props => (!props.color || props.color === 'transparent' ? '1px' : '0px')};
  border-color: grey;
  border-style: solid;
`

// background-color: ${props => props.color || 'transparent'};

// border-width: ${props => (!props.color || (props.color === 'transparent')) ? '1px' : '0px'};
export default ColorCircle
