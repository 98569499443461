import InvisibleButton from 'Components/Button/InvisibleButton'
import CopyButton from 'Components/CopyButton'
import TablePagination from 'Components/TablePagination'
import UserRolesIconsList from 'Containers/UserRolesIconsList'
import EditUserButton from 'Forms/User/EditUserButton'
import RemoveUserButton from 'Forms/User/RemoveUserButton'
import { userTitle } from 'Forms/User/helper'
import UserView from 'Views/UserView/UserView'
import { UserFragment } from 'Views/Users/fragments'
import { ResultOf } from 'gql.tada'
import { useGetUserRGPDData } from 'models'
import React, { useCallback } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Icon, Modal, Popup, Table } from 'semantic-ui-react'
import { Permission, User, UserRoleId } from 'services/api/graphql'
import { useCurrentUser, useStore, useUser } from 'stores'
import styled from 'styled-components'
import { format } from 'tools/date'
import { downloadFile } from 'tools/downloadFile'
import { notifyError } from 'tools/toaster'

const Buttons = styled.div`
  display: flex;
  justify-content: center;
`

export enum UserSortColumns {
  EMAIL = 'email',
  FIRSTNAME = 'firstName',
  CREATED_AT = 'createdAt',
  LAST_ACTIVITY = 'lastActivity',
}

export interface UserSortBy {
  column: UserSortColumns
  direction: 1 | -1
}

export interface UsersPaginatedTableProps {
  users: (ResultOf<typeof UserFragment> | User)[]
  sortBy?: UserSortBy
  onSort?: (sort?: UserSortBy) => void
  totalPages?: number
  activePage?: number
  onPageChange?: (page: number) => void
  withActions?: boolean
  onUserDeleted?: () => void
}

const UsersPaginatedTable: React.FC<UsersPaginatedTableProps> = ({
  users,
  sortBy,
  onSort,
  totalPages,
  activePage,
  onPageChange,
  withActions,
  onUserDeleted,
}) => {
  const user = useUser()
  const currentUser = useCurrentUser()
  const { organismId } = useStore()
  const intl = useIntl()

  const handleSort = useCallback(
    (column: UserSortColumns) => {
      if (onSort) {
        if (!sortBy) {
          return onSort({
            column,
            direction: 1,
          })
        }
        if (column !== sortBy.column) {
          return onSort({
            ...sortBy,
            column,
          })
        }

        if (sortBy.direction === -1) {
          return onSort(undefined)
        }

        return onSort({
          ...sortBy,
          direction: sortBy.direction === 1 ? -1 : 1,
        })
      }
    },
    [onSort, sortBy],
  )
  const handlePageChange = useCallback(
    (page: number) => {
      if (onPageChange) {
        onPageChange(page)
      }
    },
    [onPageChange],
  )

  const getUserRGPD = useGetUserRGPDData()
  const downloadUserRGPD = useCallback(
    async (id: string) => {
      try {
        const { data: csvData, errors } = await getUserRGPD(id)
        if (csvData) {
          const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' })
          const fileUrl = URL.createObjectURL(blob)
          await downloadFile(fileUrl, `${id}_RGPD.csv`)
          URL.revokeObjectURL(fileUrl)
        } else if (errors && errors.length > 0) {
          notifyError(errors)
        }
      } catch (e) {
        notifyError((e as Error).message)
      }
    },
    [getUserRGPD],
  )

  const hasPermissionOnUser = useCallback(
    (user: { organismIds?: User['organismIds'] }, permission: Permission) =>
      currentUser.can(permission, undefined, true) ||
      (organismId && currentUser.can(permission, organismId) && (user.organismIds || []).includes(organismId)),
    [currentUser, organismId],
  )

  return (
    <>
      <Table style={{ flex: 1 }} sortable>
        <Table.Header>
          <Table.Row textAlign="center">
            <Table.HeaderCell width={1}>
              <FormattedMessage id="users.roles" />
            </Table.HeaderCell>
            <Table.HeaderCell
              width={2}
              sorted={
                sortBy && sortBy.column === UserSortColumns.EMAIL
                  ? sortBy.direction === 1
                    ? 'ascending'
                    : 'descending'
                  : undefined
              }
              onClick={() => handleSort(UserSortColumns.EMAIL)}
            >
              <FormattedMessage id="users.email" />
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={
                sortBy && sortBy.column === UserSortColumns.FIRSTNAME
                  ? sortBy.direction === 1
                    ? 'ascending'
                    : 'descending'
                  : undefined
              }
              onClick={() => handleSort(UserSortColumns.FIRSTNAME)}
            >
              <FormattedMessage id="users.name" />
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={
                sortBy && sortBy.column === UserSortColumns.CREATED_AT
                  ? sortBy.direction === 1
                    ? 'ascending'
                    : 'descending'
                  : undefined
              }
              onClick={() => handleSort(UserSortColumns.CREATED_AT)}
            >
              <FormattedMessage id="users.createdAt" />
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={
                sortBy && sortBy.column === UserSortColumns.LAST_ACTIVITY
                  ? sortBy.direction === 1
                    ? 'ascending'
                    : 'descending'
                  : undefined
              }
              onClick={() => handleSort(UserSortColumns.LAST_ACTIVITY)}
            >
              <FormattedMessage id="users.lastActivity" />
            </Table.HeaderCell>
            {withActions && (
              <Table.HeaderCell width={2}>
                <FormattedMessage id="users.actions" />
              </Table.HeaderCell>
            )}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {users.map(userItem => {
            const { id, email, firstName, lastName, createdAt, lastActivity, roleIds } = userItem
            const me = user.id === id
            return (
              <Table.Row key={id} style={me ? { backgroundColor: '#dddddd' } : {}}>
                <Table.Cell textAlign="center">
                  <UserRolesIconsList roleIds={roleIds as UserRoleId[]} />
                </Table.Cell>
                <Table.Cell textAlign="center">
                  <span>
                    {email}
                    {me && (
                      <span>
                        {' '}
                        (<FormattedMessage id="users.me" />)
                      </span>
                    )}
                  </span>
                </Table.Cell>
                <Table.Cell textAlign="center">
                  <span>{`${firstName || ''} ${lastName || ''}`}</span>
                </Table.Cell>
                <Table.Cell textAlign="center">{format(createdAt, 'DD/MM/YYYY')}</Table.Cell>
                <Table.Cell textAlign="center">{lastActivity ? format(lastActivity, 'DD/MM/YYYY') : ''}</Table.Cell>
                {withActions && (
                  <Table.Cell textAlign="center">
                    <Buttons>
                      <Modal
                        mountNode={document.getElementById('modals')}
                        trigger={<Icon name="eye" link size="large" />}
                        content={<UserView user={userItem} />}
                      />
                      {hasPermissionOnUser(userItem, Permission.UserUpdate) && (
                        <EditUserButton
                          userId={id}
                          render={onClick => (
                            <Icon name="edit" link size="large" inverted color="black" onClick={onClick} />
                          )}
                        />
                      )}
                      {!me && hasPermissionOnUser(userItem, Permission.UserDelete) && (
                        <RemoveUserButton
                          userId={id}
                          confirmText={
                            <FormattedMessage
                              id="users.confirm_delete"
                              values={{
                                username: userTitle({
                                  firstName,
                                  lastName,
                                  email,
                                }),
                              }}
                            />
                          }
                          successText={intl.formatMessage(
                            {
                              id: 'users.delete_success',
                            },
                            {
                              username: userTitle({
                                firstName,
                                lastName,
                                email,
                              }),
                            },
                          )}
                          onDone={onUserDeleted}
                        />
                      )}
                      {(me || currentUser.can(Permission.UserAccessRgpdData)) && (
                        <>
                          <Popup
                            content={<FormattedMessage id="users.download_rgpd" />}
                            trigger={
                              <InvisibleButton onClick={() => downloadUserRGPD(id)}>
                                <Icon name="file alternate outline" size="large" inverted color="black" />
                              </InvisibleButton>
                            }
                          />
                          <Popup
                            content={<FormattedMessage id="users.copy_id" />}
                            trigger={
                              <div>
                                <CopyButton
                                  value={id}
                                  notificationSuccess={intl.formatMessage({ id: 'users.id_copied' }, { email })}
                                >
                                  <Icon name="id badge outline" size="large" inverted color="black"></Icon>
                                </CopyButton>
                              </div>
                            }
                          />
                        </>
                      )}
                    </Buttons>
                  </Table.Cell>
                )}
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
      {!!totalPages && totalPages > 1 && (
        <TablePagination
          totalPages={totalPages}
          activePage={activePage || 1}
          onPageChange={page => handlePageChange(page)}
        />
      )}
    </>
  )
}

export default UsersPaginatedTable
