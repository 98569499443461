import React, { FC } from 'react'
import { Field } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { useIntl } from 'react-intl'
import { Button, Form, Icon, Table } from 'semantic-ui-react'

type Props = {
  localKey: string
  isDisplayed: boolean
}

const DescriptionTranslationFieldArray: FC<Props> = ({ localKey, isDisplayed }) => {
  const intl = useIntl()

  if (!isDisplayed) return null

  return (
    <FieldArray name={`descriptionT${localKey}`}>
      {({ fields }) => {
        return (
          <Table striped>
            <Table.Body>
              {fields.map((name: string, index: number) => (
                <Table.Row key={index}>
                  <Table.Cell>
                    <Field
                      name={`${name}.header`}
                      render={({ input, meta }) => (
                        <Form.TextArea
                          {...input}
                          value={input.value}
                          error={meta.touched && !!meta.error}
                          label={intl.formatMessage({
                            id: 'purchasableOffer.description.header',
                          })}
                        />
                      )}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <Field
                      name={`${name}.content`}
                      render={({ input, meta }) => (
                        <Form.TextArea
                          {...input}
                          value={input.value}
                          error={meta.touched && !!meta.error}
                          label={intl.formatMessage({
                            id: 'purchasableOffer.description.content',
                          })}
                        />
                      )}
                    />
                  </Table.Cell>
                  <Table.Cell verticalAlign="bottom">
                    <Button icon onClick={() => fields.remove(index)} size="small">
                      <Icon name="remove" />
                    </Button>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.Cell colSpan={3}>
                  <Button icon onClick={() => fields.push({})}>
                    <Icon name="add" />
                  </Button>
                </Table.Cell>
              </Table.Row>
            </Table.Footer>
          </Table>
        )
      }}
    </FieldArray>
  )
}

export default DescriptionTranslationFieldArray
