import { applicationUris } from 'Layout/uris'
import MediaLibraryView from 'Views/MediaLibraryView'
import React from 'react'
import { Navigate } from 'react-router-dom'
import { Access, userCanAccessToPath } from 'services/api'
import { IUser } from 'stores'

import administrationRoutes from './administrationRoutes'
import affiliationsRoutes from './affiliationsRoutes'
import contentsRoutes from './contentsRoutes'
import editorialRoutes from './editorialRoutes'
import monitoringRoutes from './monitoringRoutes'
import organismsRoutes from './organismsRoutes'
import sportsRoutes from './sportsRoutes'
import usersRoutes from './usersRoutes'

const dashBoardRoutes = (user: IUser | null) => [
  {
    path: '/',
    loader: () => userCanAccessToPath(user),
  },
  ...monitoringRoutes(user),
  ...usersRoutes(user),
  ...sportsRoutes(user),
  ...organismsRoutes(user),
  ...contentsRoutes(user),
  ...editorialRoutes(user),
  ...affiliationsRoutes(user),
  {
    path: applicationUris.medias,
    element: <MediaLibraryView />,
    loader: () => userCanAccessToPath(user, [Access.MediaLibraryView]),
  },
  ...administrationRoutes(user),
  {
    path: '*',
    element: <Navigate to={applicationUris.dashboard} replace />,
  },
]

export default dashBoardRoutes
