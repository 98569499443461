import { ApolloClient, ApolloLink, from, InMemoryCache, ServerError, split } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { getMainDefinition } from '@apollo/client/utilities'
import { createUploadLink } from 'apollo-upload-client'
import Config from 'config'
import { createClient } from 'graphql-ws'
import { useMemo } from 'react'
import { useCurrentUser } from 'stores'
import { getGraphqlErrorMessage, getStatusCode } from 'tools/graphql'
import { notifyError } from 'tools/toaster'

export const useAuthApolloClient = (authToken: string | null | undefined): ApolloClient<any> => {
  const currentUser = useCurrentUser()

  const client = useMemo(() => {
    const httpLink = createUploadLink({
      uri: `${Config.apiUrl}/graphql`,
      headers: {
        'Cache-Control': 'no-cache',
        'Apollo-Require-Preflight': 'true',
      },
    })
    const wsLink = new GraphQLWsLink(
      createClient({
        url: `${Config.apiUrl.replace(/^http(s?):\/\//, 'ws$1://')}/graphql`,
        connectionParams: {
          authToken,
        },
      }),
    )

    const authLink = setContext((_, context) => {
      if (!authToken) {
        return context
      }
      const { headers } = context
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${authToken}`,
        },
      }
    })

    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        const message = getGraphqlErrorMessage(graphQLErrors)
        if (message) {
          notifyError(message)
        }

        if (getStatusCode(graphQLErrors) === 401) {
          currentUser._logout()
        }
      }

      if (networkError) {
        console.error('[Network Error]:', networkError)
        if (
          ['ServerError', 'ServerParseError'].includes(networkError.name) &&
          (networkError as ServerError).statusCode === 401
        ) {
          currentUser._logout()
        }
      }
    })

    const requestsLink = split(
      // split based on operation type
      ({ query }) => {
        const definition = getMainDefinition(query)
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
      },
      wsLink,
      httpLink as unknown as ApolloLink,
    )

    const links = [authLink, errorLink, requestsLink]

    const apolloClient = new ApolloClient({
      link: from(links),
      cache: new InMemoryCache(),
      connectToDevTools: process.env.NODE_ENV === 'development',
    })
    return apolloClient
  }, [authToken, currentUser])

  return client
}
