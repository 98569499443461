import { AutoLinkPlugin as LexicalAutoLinkPlugin, LinkMatcher } from '@lexical/react/LexicalAutoLinkPlugin'
import React, { FC } from 'react'

const urlMatcher =
  /((https?:\/\/(www\.)?)|(www\.))[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/

const emailMatcher =
  /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/

const matchers: LinkMatcher[] = [
  (text: string) => {
    const match = urlMatcher.exec(text)
    if (match === null) {
      return null
    }
    const fullMatch = match[0]
    return {
      index: match.index,
      length: fullMatch.length,
      text: fullMatch,
      url: fullMatch.startsWith('http') ? fullMatch : `https://${fullMatch}`,
    }
  },
  (text: string) => {
    const match = emailMatcher.exec(text)
    return (
      match && {
        index: match.index,
        length: match[0].length,
        text: match[0],
        url: `mailto:${match[0]}`,
      }
    )
  },
]

const AutoLinkPlugin: FC = () => <LexicalAutoLinkPlugin matchers={matchers} />

export default AutoLinkPlugin
