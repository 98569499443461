import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Header, Label, List, Modal } from 'semantic-ui-react'
import { Maybe, StreamMap, WmIngestionStatus, WmStream } from 'services/api/graphql'

interface LiveStreamDetailsModalProps {
  streams: (Pick<WmStream, 'name' | 'inputUrl' | 'ingestionStatus'> & {
    map?: Maybe<Pick<StreamMap, 'id' | 'channelArn'>>
  })[]
  open: boolean
  onClose: () => void
}

const StreamDetailsModal: React.FC<LiveStreamDetailsModalProps> = ({ streams, open, onClose }) => {
  const ingestionStatusColor = (status: WmIngestionStatus) => {
    if (status === WmIngestionStatus.Waiting) return 'blue'
    if (status === WmIngestionStatus.Ingesting) return 'green'
    if (status === WmIngestionStatus.Stopped) return 'black'

    return undefined
  }

  return (
    <Modal mountNode={document.getElementById('modals')} open={open} onClose={onClose} closeIcon>
      <Modal.Content>
        {streams.map(stream => (
          <>
            <Header as="h3">{stream.name}</Header>
            <List>
              <List.Item>
                <List.Header>
                  <FormattedMessage id="stream.inputUrl" />
                </List.Header>
                <List.Description>{stream.inputUrl}</List.Description>
              </List.Item>

              {stream.map && (
                <List.Item>
                  <List.Header>
                    <FormattedMessage id="stream.channelArn" />
                  </List.Header>
                  <List.Description>{stream.map.channelArn}</List.Description>
                </List.Item>
              )}

              <List.Item>
                <List.Header>
                  <FormattedMessage id="stream.ingestionStatus" />
                </List.Header>
                <List.Description>
                  <Label color={ingestionStatusColor(stream.ingestionStatus)}>
                    <FormattedMessage id={`stream.ingestionStatus.${stream.ingestionStatus}`} />
                  </Label>
                </List.Description>
              </List.Item>
            </List>
          </>
        ))}
      </Modal.Content>
    </Modal>
  )
}

export default StreamDetailsModal
