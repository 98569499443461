import SAFormField from 'Components/Form/SAFormField'
import { SelectDateInput } from 'Components/Form/SelectDateInput'
import SwitchInput from 'Components/Form/SwitchInput'
import React from 'react'
import { useIntl } from 'react-intl'
import { Form, Form as UiForm } from 'semantic-ui-react'
import { ApplicationKeyType } from 'services/api/graphql'
import { validateRequired } from 'tools/formValidators'

const KeyFormFields: React.FC = () => {
  const intl = useIntl()
  const typeOptions = [
    {
      key: ApplicationKeyType.IframePlayerAccess,
      value: ApplicationKeyType.IframePlayerAccess,
      text: intl.formatMessage({ id: 'thirdPartyApplicationKeyType.iframePlayerAccess' }),
    },
    {
      key: ApplicationKeyType.IframePlayerAccessWithLive,
      value: ApplicationKeyType.IframePlayerAccessWithLive,
      text: intl.formatMessage({ id: 'thirdPartyApplicationKeyType.iframePlayerAccessWithLive' }),
    },
  ]

  const activeOptions = [
    {
      key: 'active',
      value: true,
      text: intl.formatMessage({ id: 'common.yes' }),
    },
    {
      key: 'inactive',
      value: false,
      text: intl.formatMessage({ id: 'common.no' }),
    },
  ]

  return (
    <UiForm>
      <SAFormField
        name="name"
        validate={validateRequired}
        render={({ input, meta }) => (
          <UiForm.Input
            {...input}
            required
            error={!!meta.touched && !!meta.error}
            label={intl.formatMessage({ id: 'common.title' })}
          />
        )}
      />
      <SAFormField
        name="enabled"
        validate={value => value === undefined}
        render={({ input, meta }) => (
          <Form.Field
            control={() => <SwitchInput value={input.value} onChange={input.onChange} options={activeOptions} />}
            {...input}
            required
            error={meta.touched && meta.error}
            label={intl.formatMessage({ id: 'thirdPartyApplicationKey.active' })}
          />
        )}
      />
      <SAFormField
        name="expirationDate"
        validate={validateRequired}
        render={({ input, meta }) => (
          <Form.Field
            {...input}
            required
            control={SelectDateInput}
            minDate={new Date()}
            error={!!meta.touched && !!meta.error}
            label={intl.formatMessage({ id: 'common.expirationDate' })}
          />
        )}
      />
      <SAFormField
        name="type"
        validate={validateRequired}
        render={({ input, meta }) => (
          <Form.Field
            control={() => <SwitchInput value={input.value} onChange={input.onChange} options={typeOptions} />}
            {...input}
            required
            error={meta.touched && meta.error}
            label={intl.formatMessage({ id: 'common.type' })}
          />
        )}
      />
    </UiForm>
  )
}

export default KeyFormFields
