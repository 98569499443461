import Axios from 'axios'
import Config from 'config'

export interface ApplicationDetails {
  version: string
  buildDate: string
  env: string
  startDate: string
}

export async function getServicePlatformVesion() {
  const res = await Axios.get<ApplicationDetails>(`${Config.apiUrl}/api/version`)
  return res.data
}
