import EditLinkForm from 'Forms/Link/EditLinkForm'
import React, { Fragment, useState } from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'services/api/graphql'
import { notifyError, notifySuccess } from 'tools/toaster'

interface EditLinkButtonProps {
  linkId: Link['id']
  onDone?: () => void
  render: (onClick: () => void) => React.ReactNode
}

const EditLinkButton = ({ onDone, linkId, render }: EditLinkButtonProps) => {
  const intl = useIntl()
  const [form, setForm] = useState<React.ReactNode | null>()

  const handleClick = () => {
    setForm(
      linkId && (
        <EditLinkForm
          linkId={linkId}
          onCancel={() => setForm(null)}
          onDone={link => {
            setForm(null)
            notifySuccess(intl.formatMessage({ id: 'links.form.edit.success' }, { title: link.title }))
            if (onDone) onDone()
          }}
          onError={notifyError}
        />
      ),
    )
  }
  return (
    <Fragment>
      {render(handleClick)}
      {form}
    </Fragment>
  )
}

export default EditLinkButton
