import SelectFile, { SelectFileProps } from 'Containers/SelectFile'
import React, { FC } from 'react'

import { useEditorState } from '../useEditor'

export type SelectFileModalProps = Omit<SelectFileProps, 'organismId'>

const SelectFileModal: FC<SelectFileModalProps> = props => {
  const [{ organismId }] = useEditorState()

  return <SelectFile {...props} organismId={organismId} />
}

export default SelectFileModal
