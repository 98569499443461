import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
const defaultOptions = {} as const
export type AdCampaignContentFragment = {
  __typename?: 'AdCampaign'
  publicationStatus: PublicationStatus
  organismId: string
  displayedItemsCountMax: number
  title: string
  id: string
  prerollConfigs: Array<{
    __typename?: 'PrerollConfig'
    videoType: PrerollVideoType
    adDisplayMaxCount: number
    billboardDisplayMaxCount: number
  }>
  affiliates: Array<{
    __typename?: 'Affiliate'
    linkId: string
    displayWeight: number
    displayTypes: Array<AffiliateDisplayType>
    type: AffiliateType
  }>
}

export type AdCampaignsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  filters?: InputMaybe<AdCampaignFilters>
}>

export type AdCampaignsQuery = {
  __typename?: 'Query'
  adCampaigns?: {
    __typename?: 'AdCampaignResult'
    totalCount: number
    adCampaigns: Array<{
      __typename?: 'AdCampaign'
      publicationStatus: PublicationStatus
      organismId: string
      displayedItemsCountMax: number
      title: string
      id: string
      prerollConfigs: Array<{
        __typename?: 'PrerollConfig'
        videoType: PrerollVideoType
        adDisplayMaxCount: number
        billboardDisplayMaxCount: number
      }>
      affiliates: Array<{
        __typename?: 'Affiliate'
        linkId: string
        displayWeight: number
        displayTypes: Array<AffiliateDisplayType>
        type: AffiliateType
      }>
    }>
  } | null
}

export type CreateAdCampaignMutationVariables = Exact<{
  input: CreateAdCampaignInput
}>

export type CreateAdCampaignMutation = {
  __typename?: 'Mutation'
  createAdCampaign: {
    __typename?: 'AdCampaign'
    publicationStatus: PublicationStatus
    organismId: string
    displayedItemsCountMax: number
    title: string
    id: string
    prerollConfigs: Array<{
      __typename?: 'PrerollConfig'
      videoType: PrerollVideoType
      adDisplayMaxCount: number
      billboardDisplayMaxCount: number
    }>
    affiliates: Array<{
      __typename?: 'Affiliate'
      linkId: string
      displayWeight: number
      displayTypes: Array<AffiliateDisplayType>
      type: AffiliateType
    }>
  }
}

export type UpdateAdCampaignMutationVariables = Exact<{
  input: UpdateAdCampaignInput
}>

export type UpdateAdCampaignMutation = {
  __typename?: 'Mutation'
  updateAdCampaign: {
    __typename?: 'AdCampaign'
    publicationStatus: PublicationStatus
    organismId: string
    displayedItemsCountMax: number
    title: string
    id: string
    prerollConfigs: Array<{
      __typename?: 'PrerollConfig'
      videoType: PrerollVideoType
      adDisplayMaxCount: number
      billboardDisplayMaxCount: number
    }>
    affiliates: Array<{
      __typename?: 'Affiliate'
      linkId: string
      displayWeight: number
      displayTypes: Array<AffiliateDisplayType>
      type: AffiliateType
    }>
  }
}

export type DeleteAdCampaignMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type DeleteAdCampaignMutation = {
  __typename?: 'Mutation'
  deleteAdCampaign?: { __typename?: 'AdCampaign'; id: string } | null
}

export type ApplicationContentFragment = {
  __typename?: 'Application'
  id: string
  title?: string | null
  applicationId: string
  type: ApplicationType
  url?: string | null
  sponsoringUrl?: string | null
  sponsorImageFileId?: string | null
  organismId?: string | null
  publicationStatus: PublicationStatus
  packageName?: string | null
  appleSharedSecret?: string | null
  organism?: { __typename?: 'Organism'; whiteLabel: boolean; id: string; title: string } | null
  cac?: { __typename?: 'ContentAccessControl'; userAuthenticationRule?: CacUserAuthenticationRule | null } | null
  excludedContent: {
    __typename?: 'ExcludedContent'
    sportItemSetIds: Array<string>
    sportItemIds: Array<string>
    sportItemSets: Array<{ __typename?: 'SportItemSet'; id: string; title: string }>
    sportItems: Array<{ __typename?: 'SportItem'; id: string; title: string }>
  }
}

export type ApplicationQueryVariables = Exact<{
  applicationId: Scalars['ID']
}>

export type ApplicationQuery = {
  __typename?: 'Query'
  application?: {
    __typename?: 'Application'
    id: string
    title?: string | null
    applicationId: string
    type: ApplicationType
    url?: string | null
    sponsoringUrl?: string | null
    sponsorImageFileId?: string | null
    organismId?: string | null
    publicationStatus: PublicationStatus
    packageName?: string | null
    appleSharedSecret?: string | null
    organism?: { __typename?: 'Organism'; whiteLabel: boolean; id: string; title: string } | null
    cac?: { __typename?: 'ContentAccessControl'; userAuthenticationRule?: CacUserAuthenticationRule | null } | null
    excludedContent: {
      __typename?: 'ExcludedContent'
      sportItemSetIds: Array<string>
      sportItemIds: Array<string>
      sportItemSets: Array<{ __typename?: 'SportItemSet'; id: string; title: string }>
      sportItems: Array<{ __typename?: 'SportItem'; id: string; title: string }>
    }
  } | null
}

export type ApplicationFromApplicationIdQueryVariables = Exact<{
  applicationId: Scalars['ID']
}>

export type ApplicationFromApplicationIdQuery = {
  __typename?: 'Query'
  applicationFromApplicationId?: {
    __typename?: 'Application'
    id: string
    title?: string | null
    applicationId: string
    type: ApplicationType
    url?: string | null
    sponsoringUrl?: string | null
    sponsorImageFileId?: string | null
    organismId?: string | null
    publicationStatus: PublicationStatus
    packageName?: string | null
    appleSharedSecret?: string | null
    organism?: { __typename?: 'Organism'; whiteLabel: boolean; id: string; title: string } | null
    cac?: { __typename?: 'ContentAccessControl'; userAuthenticationRule?: CacUserAuthenticationRule | null } | null
    excludedContent: {
      __typename?: 'ExcludedContent'
      sportItemSetIds: Array<string>
      sportItemIds: Array<string>
      sportItemSets: Array<{ __typename?: 'SportItemSet'; id: string; title: string }>
      sportItems: Array<{ __typename?: 'SportItem'; id: string; title: string }>
    }
  } | null
}

export type ApplicationsQueryVariables = Exact<{
  filters?: InputMaybe<ApplicationsFilters>
}>

export type ApplicationsQuery = {
  __typename?: 'Query'
  applications?: {
    __typename?: 'ApplicationPageResult'
    totalCount?: number | null
    applications: Array<{
      __typename?: 'Application'
      id: string
      title?: string | null
      applicationId: string
      type: ApplicationType
      url?: string | null
      sponsoringUrl?: string | null
      sponsorImageFileId?: string | null
      organismId?: string | null
      publicationStatus: PublicationStatus
      packageName?: string | null
      appleSharedSecret?: string | null
      organism?: { __typename?: 'Organism'; whiteLabel: boolean; id: string; title: string } | null
      cac?: { __typename?: 'ContentAccessControl'; userAuthenticationRule?: CacUserAuthenticationRule | null } | null
      excludedContent: {
        __typename?: 'ExcludedContent'
        sportItemSetIds: Array<string>
        sportItemIds: Array<string>
        sportItemSets: Array<{ __typename?: 'SportItemSet'; id: string; title: string }>
        sportItems: Array<{ __typename?: 'SportItem'; id: string; title: string }>
      }
    }>
  } | null
}

export type CreateApplicationMutationVariables = Exact<{
  input: CreateApplicationInput
}>

export type CreateApplicationMutation = {
  __typename?: 'Mutation'
  createApplication: {
    __typename?: 'Application'
    id: string
    title?: string | null
    applicationId: string
    type: ApplicationType
    url?: string | null
    sponsoringUrl?: string | null
    sponsorImageFileId?: string | null
    organismId?: string | null
    publicationStatus: PublicationStatus
    packageName?: string | null
    appleSharedSecret?: string | null
    organism?: { __typename?: 'Organism'; whiteLabel: boolean; id: string; title: string } | null
    cac?: { __typename?: 'ContentAccessControl'; userAuthenticationRule?: CacUserAuthenticationRule | null } | null
    excludedContent: {
      __typename?: 'ExcludedContent'
      sportItemSetIds: Array<string>
      sportItemIds: Array<string>
      sportItemSets: Array<{ __typename?: 'SportItemSet'; id: string; title: string }>
      sportItems: Array<{ __typename?: 'SportItem'; id: string; title: string }>
    }
  }
}

export type UpdateApplicationMutationVariables = Exact<{
  input: UpdateApplicationInput
}>

export type UpdateApplicationMutation = {
  __typename?: 'Mutation'
  updateApplication: {
    __typename?: 'Application'
    id: string
    title?: string | null
    applicationId: string
    type: ApplicationType
    url?: string | null
    sponsoringUrl?: string | null
    sponsorImageFileId?: string | null
    organismId?: string | null
    publicationStatus: PublicationStatus
    packageName?: string | null
    appleSharedSecret?: string | null
    organism?: { __typename?: 'Organism'; whiteLabel: boolean; id: string; title: string } | null
    cac?: { __typename?: 'ContentAccessControl'; userAuthenticationRule?: CacUserAuthenticationRule | null } | null
    excludedContent: {
      __typename?: 'ExcludedContent'
      sportItemSetIds: Array<string>
      sportItemIds: Array<string>
      sportItemSets: Array<{ __typename?: 'SportItemSet'; id: string; title: string }>
      sportItems: Array<{ __typename?: 'SportItem'; id: string; title: string }>
    }
  }
}

export type DeleteApplicationMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type DeleteApplicationMutation = {
  __typename?: 'Mutation'
  deleteApplication?: { __typename?: 'Application'; id: string } | null
}

export type ApplicationDesignContentFragment = {
  __typename?: 'ApplicationDesign'
  id: string
  title: string
  description: string
  organismId: string
  theme: {
    __typename?: 'ApplicationDesignTheme'
    primaryColor: string
    onPrimaryColor: string
    surfaceColor: string
    onSurfaceColor: string
    onSurfaceAlternateColor: string
    top10Color: string
    top10BorderColor?: string | null
  }
}

export type ApplicationDesignsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  filters?: InputMaybe<ApplicationDesignFilters>
}>

export type ApplicationDesignsQuery = {
  __typename?: 'Query'
  applicationDesigns?: {
    __typename?: 'ApplicationDesignPageResult'
    totalCount: number
    applicationDesigns: Array<{
      __typename?: 'ApplicationDesign'
      id: string
      title: string
      description: string
      organismId: string
      theme: {
        __typename?: 'ApplicationDesignTheme'
        primaryColor: string
        onPrimaryColor: string
        surfaceColor: string
        onSurfaceColor: string
        onSurfaceAlternateColor: string
        top10Color: string
        top10BorderColor?: string | null
      }
    }>
  } | null
}

export type CreateApplicationDesignMutationVariables = Exact<{
  input: CreateApplicationDesignInput
}>

export type CreateApplicationDesignMutation = {
  __typename?: 'Mutation'
  createApplicationDesign: {
    __typename?: 'ApplicationDesign'
    id: string
    title: string
    description: string
    organismId: string
    theme: {
      __typename?: 'ApplicationDesignTheme'
      primaryColor: string
      onPrimaryColor: string
      surfaceColor: string
      onSurfaceColor: string
      onSurfaceAlternateColor: string
      top10Color: string
      top10BorderColor?: string | null
    }
  }
}

export type UpdateApplicationDesignMutationVariables = Exact<{
  input: UpdateApplicationDesignInput
}>

export type UpdateApplicationDesignMutation = {
  __typename?: 'Mutation'
  updateApplicationDesign: {
    __typename?: 'ApplicationDesign'
    id: string
    title: string
    description: string
    organismId: string
    theme: {
      __typename?: 'ApplicationDesignTheme'
      primaryColor: string
      onPrimaryColor: string
      surfaceColor: string
      onSurfaceColor: string
      onSurfaceAlternateColor: string
      top10Color: string
      top10BorderColor?: string | null
    }
  }
}

export type DeleteApplicationDesignMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type DeleteApplicationDesignMutation = {
  __typename?: 'Mutation'
  deleteApplicationDesign?: { __typename?: 'ApplicationDesign'; id: string } | null
}

export type CategoriesQueryVariables = Exact<{ [key: string]: never }>

export type CategoriesQuery = {
  __typename?: 'Query'
  categories: Array<{ __typename?: 'Category'; id: string; title: string }>
}

export type CreateCategoryMutationVariables = Exact<{
  input: CreateCategoryInput
}>

export type CreateCategoryMutation = {
  __typename?: 'Mutation'
  createCategory: { __typename?: 'Category'; id: string; title: string }
}

export type UpdateCategoryMutationVariables = Exact<{
  input: UpdateCategoryInput
}>

export type UpdateCategoryMutation = {
  __typename?: 'Mutation'
  updateCategory: { __typename?: 'Category'; id: string; title: string }
}

export type DeleteCategoryMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type DeleteCategoryMutation = { __typename?: 'Mutation'; deleteCategory: boolean }

export type ContentPushStreamContentFragment = {
  __typename?: 'ContentPushStream'
  id: string
  name: string
  downloadExportUrl?: string | null
  contentDeliveryServiceConfig: {
    __typename?: 'ContentDeliveryServiceConfig'
    type?: string | null
    retentionDurationDays?: number | null
    periodicity?: {
      __typename?: 'ContentDeliveryPeriodicity'
      frequency?: ContentDeliveryFrequency | null
      firstSendDate?: Date | null
    } | null
    destination?: {
      __typename?: 'ContentDeliveryDestination'
      bucketName: string
      region: string
      bucketPath?: string | null
      accessKey: string
      secretKey: string
      endpointUrl?: string | null
    } | null
  }
  contentDelivery?: {
    __typename?: 'ContentPushServiceStreamDelivery'
    type?: string | null
    status?: string | null
    statusDetails?: string | null
  } | null
}

export type OutputStreamContentFragment = { __typename?: 'OutputStream'; id: string; name: string; outputUrl: string }

export type ContentPushStreamExportsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  contentPushStreamId: Scalars['ID']
}>

export type ContentPushStreamExportsQuery = {
  __typename?: 'Query'
  contentPushStreamExports?: {
    __typename?: 'SportItemSetsPageResult'
    totalCount: number
    sportItemSets: Array<{
      __typename?: 'SportItemSet'
      id: string
      title: string
      type: SportItemSetType
      ownerOrganism?: { __typename?: 'Organism'; title: string } | null
    }>
  } | null
}

export type ContentPushStreamsQueryVariables = Exact<{
  filters?: InputMaybe<ContentPushStreamsFiltersQuery>
}>

export type ContentPushStreamsQuery = {
  __typename?: 'Query'
  contentPushStreams: Array<{
    __typename?: 'ContentPushStream'
    id: string
    name: string
    downloadExportUrl?: string | null
    contentDeliveryServiceConfig: {
      __typename?: 'ContentDeliveryServiceConfig'
      type?: string | null
      retentionDurationDays?: number | null
      periodicity?: {
        __typename?: 'ContentDeliveryPeriodicity'
        frequency?: ContentDeliveryFrequency | null
        firstSendDate?: Date | null
      } | null
      destination?: {
        __typename?: 'ContentDeliveryDestination'
        bucketName: string
        region: string
        bucketPath?: string | null
        accessKey: string
        secretKey: string
        endpointUrl?: string | null
      } | null
    }
    contentDelivery?: {
      __typename?: 'ContentPushServiceStreamDelivery'
      type?: string | null
      status?: string | null
      statusDetails?: string | null
    } | null
  }>
}

export type AvailableContentPushServicesQueryVariables = Exact<{ [key: string]: never }>

export type AvailableContentPushServicesQuery = {
  __typename?: 'Query'
  availableContentPushServices: Array<{
    __typename?: 'AvailableContentPushService'
    type: string
    customDestination: boolean
  }>
}

export type OutputStreamsQueryVariables = Exact<{ [key: string]: never }>

export type OutputStreamsQuery = {
  __typename?: 'Query'
  outputStreams: Array<{ __typename?: 'OutputStream'; id: string; name: string; outputUrl: string }>
}

export type CreateContentPushStreamMutationVariables = Exact<{
  input: CreateContentPushStreamInput
}>

export type CreateContentPushStreamMutation = { __typename?: 'Mutation'; createContentPushStream: boolean }

export type UpdateContentPushStreamMutationVariables = Exact<{
  input: UpdateContentPushStreamInput
}>

export type UpdateContentPushStreamMutation = { __typename?: 'Mutation'; updateContentPushStream: boolean }

export type DeleteContentPushStreamMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type DeleteContentPushStreamMutation = { __typename?: 'Mutation'; deleteContentPushStream: boolean }

export type UpdateOutputStreamsMutationVariables = Exact<{
  input: UpdateOutputStreamsInput
}>

export type UpdateOutputStreamsMutation = { __typename?: 'Mutation'; updateOutputStreams: boolean }

export type SyncNowMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type SyncNowMutation = { __typename?: 'Mutation'; syncNow: boolean }

export type ContentTranslationContentFragment = { __typename?: 'ContentTranslation'; locale: string; content: string }

export type CustomPageWidgetFragment = {
  __typename?: 'CustomPageWidget'
  name: CustomPageWidgetName
  position: number
  display?: CustomPageDisplayTypes | null
  contentDisplay?: ContentSetContentDisplay | null
  cardsDisplay?: CardsDisplay | null
  options?: {
    __typename?: 'CustomPageWidgetOptions'
    editedCategoryId?: string | null
    sportItemSetType?: SportItemSetType | null
    replays?: {
      __typename?: 'ReplaysWidget'
      defaultLocale: string
      maxReplays?: number | null
      depthInDays?: number | null
      nameT: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }>
    } | null
    externalContent?: {
      __typename?: 'ExternalContentWidget'
      name?: string | null
      defaultLocale: string
      maxItemsCount: number
      source: WidgetExternalContentSource
      nameT: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }>
      period?: { __typename?: 'StartEndDates'; startDate: Date; endDate: Date } | null
      wecContent?: {
        __typename?: 'WECNewsContent'
        teamECMIds?: Array<string> | null
        driverECMIds?: Array<string> | null
        carECMIds?: Array<string> | null
        categoryECMIds?: Array<string> | null
        raceECMIds?: Array<string> | null
      } | null
      rssContent?: { __typename?: 'RSSFeedContent'; feedUrl: string } | null
    } | null
  } | null
}

export type CustomPageContentFragment = {
  __typename?: 'CustomPage'
  id: string
  title: string
  defaultLocale?: string | null
  iconId?: string | null
  isHomePage?: boolean | null
  published: PublicationStatus
  widgetsType?: WidgetsType | null
  applicationId: string
  position: number
  backgroundVideoId?: string | null
  backgroundImageId?: string | null
  menuBannerImageId?: string | null
  showInNavigationHeader?: boolean | null
  customPageApplicationDesignId?: string | null
  frontPageId?: string | null
  titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
  widgets?: Array<{
    __typename?: 'CustomPageWidget'
    name: CustomPageWidgetName
    position: number
    display?: CustomPageDisplayTypes | null
    contentDisplay?: ContentSetContentDisplay | null
    cardsDisplay?: CardsDisplay | null
    options?: {
      __typename?: 'CustomPageWidgetOptions'
      editedCategoryId?: string | null
      sportItemSetType?: SportItemSetType | null
      replays?: {
        __typename?: 'ReplaysWidget'
        defaultLocale: string
        maxReplays?: number | null
        depthInDays?: number | null
        nameT: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }>
      } | null
      externalContent?: {
        __typename?: 'ExternalContentWidget'
        name?: string | null
        defaultLocale: string
        maxItemsCount: number
        source: WidgetExternalContentSource
        nameT: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }>
        period?: { __typename?: 'StartEndDates'; startDate: Date; endDate: Date } | null
        wecContent?: {
          __typename?: 'WECNewsContent'
          teamECMIds?: Array<string> | null
          driverECMIds?: Array<string> | null
          carECMIds?: Array<string> | null
          categoryECMIds?: Array<string> | null
          raceECMIds?: Array<string> | null
        } | null
        rssContent?: { __typename?: 'RSSFeedContent'; feedUrl: string } | null
      } | null
    } | null
  }> | null
  externalWidgets?: {
    __typename?: 'ExternalWidgets'
    liveScoreAccount?: string | null
    iframeUrl?: string | null
    linkUrl?: string | null
  } | null
}

export type CustomPageQueryVariables = Exact<{
  customPageId: Scalars['ID']
}>

export type CustomPageQuery = {
  __typename?: 'Query'
  customPage?: {
    __typename?: 'CustomPage'
    id: string
    title: string
    defaultLocale?: string | null
    iconId?: string | null
    isHomePage?: boolean | null
    published: PublicationStatus
    widgetsType?: WidgetsType | null
    applicationId: string
    position: number
    backgroundVideoId?: string | null
    backgroundImageId?: string | null
    menuBannerImageId?: string | null
    showInNavigationHeader?: boolean | null
    customPageApplicationDesignId?: string | null
    frontPageId?: string | null
    titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    widgets?: Array<{
      __typename?: 'CustomPageWidget'
      name: CustomPageWidgetName
      position: number
      display?: CustomPageDisplayTypes | null
      contentDisplay?: ContentSetContentDisplay | null
      cardsDisplay?: CardsDisplay | null
      options?: {
        __typename?: 'CustomPageWidgetOptions'
        editedCategoryId?: string | null
        sportItemSetType?: SportItemSetType | null
        replays?: {
          __typename?: 'ReplaysWidget'
          defaultLocale: string
          maxReplays?: number | null
          depthInDays?: number | null
          nameT: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }>
        } | null
        externalContent?: {
          __typename?: 'ExternalContentWidget'
          name?: string | null
          defaultLocale: string
          maxItemsCount: number
          source: WidgetExternalContentSource
          nameT: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }>
          period?: { __typename?: 'StartEndDates'; startDate: Date; endDate: Date } | null
          wecContent?: {
            __typename?: 'WECNewsContent'
            teamECMIds?: Array<string> | null
            driverECMIds?: Array<string> | null
            carECMIds?: Array<string> | null
            categoryECMIds?: Array<string> | null
            raceECMIds?: Array<string> | null
          } | null
          rssContent?: { __typename?: 'RSSFeedContent'; feedUrl: string } | null
        } | null
      } | null
    }> | null
    externalWidgets?: {
      __typename?: 'ExternalWidgets'
      liveScoreAccount?: string | null
      iframeUrl?: string | null
      linkUrl?: string | null
    } | null
  } | null
}

export type CustomPagesQueryVariables = Exact<{
  filters?: InputMaybe<CustomPageFilters>
}>

export type CustomPagesQuery = {
  __typename?: 'Query'
  customPages?: {
    __typename?: 'CustomPagePageResult'
    totalCount?: number | null
    customPages: Array<{
      __typename?: 'CustomPage'
      id: string
      title: string
      defaultLocale?: string | null
      iconId?: string | null
      isHomePage?: boolean | null
      published: PublicationStatus
      widgetsType?: WidgetsType | null
      applicationId: string
      position: number
      backgroundVideoId?: string | null
      backgroundImageId?: string | null
      menuBannerImageId?: string | null
      showInNavigationHeader?: boolean | null
      customPageApplicationDesignId?: string | null
      frontPageId?: string | null
      titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
      widgets?: Array<{
        __typename?: 'CustomPageWidget'
        name: CustomPageWidgetName
        position: number
        display?: CustomPageDisplayTypes | null
        contentDisplay?: ContentSetContentDisplay | null
        cardsDisplay?: CardsDisplay | null
        options?: {
          __typename?: 'CustomPageWidgetOptions'
          editedCategoryId?: string | null
          sportItemSetType?: SportItemSetType | null
          replays?: {
            __typename?: 'ReplaysWidget'
            defaultLocale: string
            maxReplays?: number | null
            depthInDays?: number | null
            nameT: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }>
          } | null
          externalContent?: {
            __typename?: 'ExternalContentWidget'
            name?: string | null
            defaultLocale: string
            maxItemsCount: number
            source: WidgetExternalContentSource
            nameT: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }>
            period?: { __typename?: 'StartEndDates'; startDate: Date; endDate: Date } | null
            wecContent?: {
              __typename?: 'WECNewsContent'
              teamECMIds?: Array<string> | null
              driverECMIds?: Array<string> | null
              carECMIds?: Array<string> | null
              categoryECMIds?: Array<string> | null
              raceECMIds?: Array<string> | null
            } | null
            rssContent?: { __typename?: 'RSSFeedContent'; feedUrl: string } | null
          } | null
        } | null
      }> | null
      externalWidgets?: {
        __typename?: 'ExternalWidgets'
        liveScoreAccount?: string | null
        iframeUrl?: string | null
        linkUrl?: string | null
      } | null
    }>
  } | null
}

export type CreateCustomPageMutationVariables = Exact<{
  input: CreateCustomPageInput
}>

export type CreateCustomPageMutation = {
  __typename?: 'Mutation'
  createCustomPage: {
    __typename?: 'CustomPage'
    id: string
    title: string
    defaultLocale?: string | null
    iconId?: string | null
    isHomePage?: boolean | null
    published: PublicationStatus
    widgetsType?: WidgetsType | null
    applicationId: string
    position: number
    backgroundVideoId?: string | null
    backgroundImageId?: string | null
    menuBannerImageId?: string | null
    showInNavigationHeader?: boolean | null
    customPageApplicationDesignId?: string | null
    frontPageId?: string | null
    titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    widgets?: Array<{
      __typename?: 'CustomPageWidget'
      name: CustomPageWidgetName
      position: number
      display?: CustomPageDisplayTypes | null
      contentDisplay?: ContentSetContentDisplay | null
      cardsDisplay?: CardsDisplay | null
      options?: {
        __typename?: 'CustomPageWidgetOptions'
        editedCategoryId?: string | null
        sportItemSetType?: SportItemSetType | null
        replays?: {
          __typename?: 'ReplaysWidget'
          defaultLocale: string
          maxReplays?: number | null
          depthInDays?: number | null
          nameT: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }>
        } | null
        externalContent?: {
          __typename?: 'ExternalContentWidget'
          name?: string | null
          defaultLocale: string
          maxItemsCount: number
          source: WidgetExternalContentSource
          nameT: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }>
          period?: { __typename?: 'StartEndDates'; startDate: Date; endDate: Date } | null
          wecContent?: {
            __typename?: 'WECNewsContent'
            teamECMIds?: Array<string> | null
            driverECMIds?: Array<string> | null
            carECMIds?: Array<string> | null
            categoryECMIds?: Array<string> | null
            raceECMIds?: Array<string> | null
          } | null
          rssContent?: { __typename?: 'RSSFeedContent'; feedUrl: string } | null
        } | null
      } | null
    }> | null
    externalWidgets?: {
      __typename?: 'ExternalWidgets'
      liveScoreAccount?: string | null
      iframeUrl?: string | null
      linkUrl?: string | null
    } | null
  }
}

export type UpdateCustomPageMutationVariables = Exact<{
  input: UpdateCustomPageInput
}>

export type UpdateCustomPageMutation = {
  __typename?: 'Mutation'
  updateCustomPage: {
    __typename?: 'CustomPage'
    id: string
    title: string
    defaultLocale?: string | null
    iconId?: string | null
    isHomePage?: boolean | null
    published: PublicationStatus
    widgetsType?: WidgetsType | null
    applicationId: string
    position: number
    backgroundVideoId?: string | null
    backgroundImageId?: string | null
    menuBannerImageId?: string | null
    showInNavigationHeader?: boolean | null
    customPageApplicationDesignId?: string | null
    frontPageId?: string | null
    titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    widgets?: Array<{
      __typename?: 'CustomPageWidget'
      name: CustomPageWidgetName
      position: number
      display?: CustomPageDisplayTypes | null
      contentDisplay?: ContentSetContentDisplay | null
      cardsDisplay?: CardsDisplay | null
      options?: {
        __typename?: 'CustomPageWidgetOptions'
        editedCategoryId?: string | null
        sportItemSetType?: SportItemSetType | null
        replays?: {
          __typename?: 'ReplaysWidget'
          defaultLocale: string
          maxReplays?: number | null
          depthInDays?: number | null
          nameT: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }>
        } | null
        externalContent?: {
          __typename?: 'ExternalContentWidget'
          name?: string | null
          defaultLocale: string
          maxItemsCount: number
          source: WidgetExternalContentSource
          nameT: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }>
          period?: { __typename?: 'StartEndDates'; startDate: Date; endDate: Date } | null
          wecContent?: {
            __typename?: 'WECNewsContent'
            teamECMIds?: Array<string> | null
            driverECMIds?: Array<string> | null
            carECMIds?: Array<string> | null
            categoryECMIds?: Array<string> | null
            raceECMIds?: Array<string> | null
          } | null
          rssContent?: { __typename?: 'RSSFeedContent'; feedUrl: string } | null
        } | null
      } | null
    }> | null
    externalWidgets?: {
      __typename?: 'ExternalWidgets'
      liveScoreAccount?: string | null
      iframeUrl?: string | null
      linkUrl?: string | null
    } | null
  }
}

export type DeleteCustomPageMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type DeleteCustomPageMutation = {
  __typename?: 'Mutation'
  deleteCustomPage?: { __typename?: 'CustomPage'; id: string } | null
}

export type MoveCustomPageMutationVariables = Exact<{
  input: MoveCustomPageInput
}>

export type MoveCustomPageMutation = {
  __typename?: 'Mutation'
  moveCustomPage: Array<{ __typename?: 'CustomPage'; id: string; position: number }>
}

export type SetHomePageMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type SetHomePageMutation = {
  __typename?: 'Mutation'
  setHomePage: Array<{ __typename?: 'CustomPage'; id: string }>
}

export type DefaultConfigContentFragment = {
  __typename?: 'DefaultConfig'
  sportItemDefaultCover: string
  liveStreamDefaultDuration: number
  liveDefaultTemplate: string
  clipDefaultTemplate: string
  androidMinVersion: string
  appleMinVersion: string
  videoGridUrl?: string | null
  sveChannelDelayHour: { __typename?: 'SveChannelDelayHour'; mounting: number; unmounting: number }
  emailingConfig: {
    __typename?: 'EmailingConfig'
    startliveStream: number
    startliveStreamTest: number
    endliveStream: number
  }
}

export type DefaultConfigQueryVariables = Exact<{ [key: string]: never }>

export type DefaultConfigQuery = {
  __typename?: 'Query'
  defaultConfig: {
    __typename?: 'DefaultConfig'
    sportItemDefaultCover: string
    liveStreamDefaultDuration: number
    liveDefaultTemplate: string
    clipDefaultTemplate: string
    androidMinVersion: string
    appleMinVersion: string
    videoGridUrl?: string | null
    sveChannelDelayHour: { __typename?: 'SveChannelDelayHour'; mounting: number; unmounting: number }
    emailingConfig: {
      __typename?: 'EmailingConfig'
      startliveStream: number
      startliveStreamTest: number
      endliveStream: number
    }
  }
}

export type InitDefaultConfigMutationVariables = Exact<{
  input: InitDefaultConfigInput
}>

export type InitDefaultConfigMutation = {
  __typename?: 'Mutation'
  initDefaultConfig: {
    __typename?: 'DefaultConfig'
    sportItemDefaultCover: string
    liveStreamDefaultDuration: number
    liveDefaultTemplate: string
    clipDefaultTemplate: string
    androidMinVersion: string
    appleMinVersion: string
    videoGridUrl?: string | null
    sveChannelDelayHour: { __typename?: 'SveChannelDelayHour'; mounting: number; unmounting: number }
    emailingConfig: {
      __typename?: 'EmailingConfig'
      startliveStream: number
      startliveStreamTest: number
      endliveStream: number
    }
  }
}

export type UpdateDefaultConfigMutationVariables = Exact<{
  input: UpdateDefaultConfigInput
}>

export type UpdateDefaultConfigMutation = {
  __typename?: 'Mutation'
  updateDefaultConfig: {
    __typename?: 'DefaultConfig'
    sportItemDefaultCover: string
    liveStreamDefaultDuration: number
    liveDefaultTemplate: string
    clipDefaultTemplate: string
    androidMinVersion: string
    appleMinVersion: string
    videoGridUrl?: string | null
    sveChannelDelayHour: { __typename?: 'SveChannelDelayHour'; mounting: number; unmounting: number }
    emailingConfig: {
      __typename?: 'EmailingConfig'
      startliveStream: number
      startliveStreamTest: number
      endliveStream: number
    }
  }
}

export type EditedCategoryContentFragment = {
  __typename?: 'EditedCategory'
  id: string
  organismId?: string | null
  title: string
  defaultLocale?: string | null
  published: PublicationStatus
  position: number
  titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
  items: Array<{
    __typename?: 'EditedCategoryItem'
    sportItemId?: string | null
    sportItemSetId?: string | null
    newsId?: string | null
    linkId?: string | null
  }>
}

export type EditedCategoriesQueryVariables = Exact<{
  filters?: InputMaybe<EditedCategoryFilters>
}>

export type EditedCategoriesQuery = {
  __typename?: 'Query'
  editedCategories?: {
    __typename?: 'EditedCategoriesPageResult'
    totalCount?: number | null
    editedCategories: Array<{
      __typename?: 'EditedCategory'
      id: string
      organismId?: string | null
      title: string
      defaultLocale?: string | null
      published: PublicationStatus
      position: number
      titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
      items: Array<{
        __typename?: 'EditedCategoryItem'
        sportItemId?: string | null
        sportItemSetId?: string | null
        newsId?: string | null
        linkId?: string | null
      }>
    }>
  } | null
}

export type EditedCategoryQueryVariables = Exact<{
  editedCategoryId: Scalars['ID']
}>

export type EditedCategoryQuery = {
  __typename?: 'Query'
  editedCategory: {
    __typename?: 'EditedCategory'
    id: string
    organismId?: string | null
    title: string
    defaultLocale?: string | null
    published: PublicationStatus
    position: number
    titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    items: Array<{
      __typename?: 'EditedCategoryItem'
      sportItemId?: string | null
      sportItemSetId?: string | null
      newsId?: string | null
      linkId?: string | null
    }>
  }
}

export type CreateEditedCategoryMutationVariables = Exact<{
  input: CreateEditedCategoryInput
}>

export type CreateEditedCategoryMutation = {
  __typename?: 'Mutation'
  createEditedCategory: {
    __typename?: 'EditedCategory'
    id: string
    organismId?: string | null
    title: string
    defaultLocale?: string | null
    published: PublicationStatus
    position: number
    titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    items: Array<{
      __typename?: 'EditedCategoryItem'
      sportItemId?: string | null
      sportItemSetId?: string | null
      newsId?: string | null
      linkId?: string | null
    }>
  }
}

export type UpdateEditedCategoryMutationVariables = Exact<{
  id: Scalars['ID']
  input: UpdateEditedCategoryInput
}>

export type UpdateEditedCategoryMutation = {
  __typename?: 'Mutation'
  updateEditedCategory: {
    __typename?: 'EditedCategory'
    id: string
    organismId?: string | null
    title: string
    defaultLocale?: string | null
    published: PublicationStatus
    position: number
    titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    items: Array<{
      __typename?: 'EditedCategoryItem'
      sportItemId?: string | null
      sportItemSetId?: string | null
      newsId?: string | null
      linkId?: string | null
    }>
  }
}

export type DeleteEditedCategoryMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type DeleteEditedCategoryMutation = {
  __typename?: 'Mutation'
  deleteEditedCategory?: { __typename?: 'EditedCategory'; id: string } | null
}

export type MoveEditedCategoryMutationVariables = Exact<{
  input: MoveEditedCategoryInput
}>

export type MoveEditedCategoryMutation = {
  __typename?: 'Mutation'
  moveEditedCategory: Array<{ __typename?: 'EditedCategory'; id: string; position: number }>
}

export type FileContentFragment = {
  __typename?: 'File'
  id: string
  filename: string
  size: number
  mimetype: string
  downloadUrl: string
  thumbUrl?: string | null
  createdAt: Date
  metadata?: {
    __typename?: 'FileMetadata'
    title?: string | null
    description?: string | null
    aspect?: number | null
    width?: number | null
    height?: number | null
    sourceFileId?: string | null
  } | null
}

export type FileImageUrlQueryVariables = Exact<{
  fileId: Scalars['ID']
}>

export type FileImageUrlQuery = { __typename?: 'Query'; fileImageUrl: string }

export type FilesQueryVariables = Exact<{
  mimetype?: InputMaybe<Scalars['String']>
  search?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}>

export type FilesQuery = {
  __typename?: 'Query'
  files?: {
    __typename?: 'FilePageResult'
    totalCount?: number | null
    files: Array<{
      __typename?: 'File'
      id: string
      filename: string
      size: number
      mimetype: string
      downloadUrl: string
      thumbUrl?: string | null
      createdAt: Date
      metadata?: {
        __typename?: 'FileMetadata'
        title?: string | null
        description?: string | null
        aspect?: number | null
        width?: number | null
        height?: number | null
        sourceFileId?: string | null
      } | null
    }>
  } | null
}

export type FileQueryVariables = Exact<{
  fileId: Scalars['ID']
}>

export type FileQuery = {
  __typename?: 'Query'
  file?: {
    __typename?: 'File'
    id: string
    filename: string
    size: number
    mimetype: string
    downloadUrl: string
    thumbUrl?: string | null
    createdAt: Date
    metadata?: {
      __typename?: 'FileMetadata'
      title?: string | null
      description?: string | null
      aspect?: number | null
      width?: number | null
      height?: number | null
      sourceFileId?: string | null
    } | null
  } | null
}

export type FrontPageContentFragment = {
  __typename?: 'FrontPage'
  id: string
  start: Date
  end?: Date | null
  state: FrontPageState
  sportItemSetId: string
  sportItemId?: string | null
  coverImageId: string
  coverVideoId?: string | null
  titleImageId: string
  redirectsAutomatically?: boolean | null
  title: string
  description?: string | null
  tags: Array<string>
  publicationStatus: PublicationStatus
  position: number
  sportItemSet?: {
    __typename?: 'SportItemSet'
    id: string
    title: string
    type: SportItemSetType
    ownerOrganism?: { __typename?: 'Organism'; id: string; title: string } | null
  } | null
  sportItem?: { __typename?: 'SportItem'; id: string; title: string } | null
  coverImage?: {
    __typename?: 'Media'
    id: string
    image?: { __typename?: 'File'; id: string; thumbUrl?: string | null; downloadUrl: string } | null
  } | null
  coverVideo?: {
    __typename?: 'Media'
    id: string
    videoClip?: { __typename?: 'VideoClip'; id: string; playbackUrl: string } | null
  } | null
  titleImage?: {
    __typename?: 'Media'
    id: string
    image?: { __typename?: 'File'; id: string; thumbUrl?: string | null; downloadUrl: string } | null
  } | null
}

export type FrontPagesQueryVariables = Exact<{
  current?: InputMaybe<Scalars['Boolean']>
}>

export type FrontPagesQuery = {
  __typename?: 'Query'
  frontPages: Array<{
    __typename?: 'FrontPage'
    id: string
    start: Date
    end?: Date | null
    state: FrontPageState
    sportItemSetId: string
    sportItemId?: string | null
    coverImageId: string
    coverVideoId?: string | null
    titleImageId: string
    redirectsAutomatically?: boolean | null
    title: string
    description?: string | null
    tags: Array<string>
    publicationStatus: PublicationStatus
    position: number
    sportItemSet?: {
      __typename?: 'SportItemSet'
      id: string
      title: string
      type: SportItemSetType
      ownerOrganism?: { __typename?: 'Organism'; id: string; title: string } | null
    } | null
    sportItem?: { __typename?: 'SportItem'; id: string; title: string } | null
    coverImage?: {
      __typename?: 'Media'
      id: string
      image?: { __typename?: 'File'; id: string; thumbUrl?: string | null; downloadUrl: string } | null
    } | null
    coverVideo?: {
      __typename?: 'Media'
      id: string
      videoClip?: { __typename?: 'VideoClip'; id: string; playbackUrl: string } | null
    } | null
    titleImage?: {
      __typename?: 'Media'
      id: string
      image?: { __typename?: 'File'; id: string; thumbUrl?: string | null; downloadUrl: string } | null
    } | null
  }>
}

export type CreateFrontPageMutationVariables = Exact<{
  input: CreateFrontPageInput
}>

export type CreateFrontPageMutation = {
  __typename?: 'Mutation'
  createFrontPage: {
    __typename?: 'FrontPage'
    id: string
    start: Date
    end?: Date | null
    state: FrontPageState
    sportItemSetId: string
    sportItemId?: string | null
    coverImageId: string
    coverVideoId?: string | null
    titleImageId: string
    redirectsAutomatically?: boolean | null
    title: string
    description?: string | null
    tags: Array<string>
    publicationStatus: PublicationStatus
    position: number
    sportItemSet?: {
      __typename?: 'SportItemSet'
      id: string
      title: string
      type: SportItemSetType
      ownerOrganism?: { __typename?: 'Organism'; id: string; title: string } | null
    } | null
    sportItem?: { __typename?: 'SportItem'; id: string; title: string } | null
    coverImage?: {
      __typename?: 'Media'
      id: string
      image?: { __typename?: 'File'; id: string; thumbUrl?: string | null; downloadUrl: string } | null
    } | null
    coverVideo?: {
      __typename?: 'Media'
      id: string
      videoClip?: { __typename?: 'VideoClip'; id: string; playbackUrl: string } | null
    } | null
    titleImage?: {
      __typename?: 'Media'
      id: string
      image?: { __typename?: 'File'; id: string; thumbUrl?: string | null; downloadUrl: string } | null
    } | null
  }
}

export type UpdateFrontPageMutationVariables = Exact<{
  id: Scalars['ID']
  input: UpdateFrontPageInput
}>

export type UpdateFrontPageMutation = {
  __typename?: 'Mutation'
  updateFrontPage: {
    __typename?: 'FrontPage'
    id: string
    start: Date
    end?: Date | null
    state: FrontPageState
    sportItemSetId: string
    sportItemId?: string | null
    coverImageId: string
    coverVideoId?: string | null
    titleImageId: string
    redirectsAutomatically?: boolean | null
    title: string
    description?: string | null
    tags: Array<string>
    publicationStatus: PublicationStatus
    position: number
    sportItemSet?: {
      __typename?: 'SportItemSet'
      id: string
      title: string
      type: SportItemSetType
      ownerOrganism?: { __typename?: 'Organism'; id: string; title: string } | null
    } | null
    sportItem?: { __typename?: 'SportItem'; id: string; title: string } | null
    coverImage?: {
      __typename?: 'Media'
      id: string
      image?: { __typename?: 'File'; id: string; thumbUrl?: string | null; downloadUrl: string } | null
    } | null
    coverVideo?: {
      __typename?: 'Media'
      id: string
      videoClip?: { __typename?: 'VideoClip'; id: string; playbackUrl: string } | null
    } | null
    titleImage?: {
      __typename?: 'Media'
      id: string
      image?: { __typename?: 'File'; id: string; thumbUrl?: string | null; downloadUrl: string } | null
    } | null
  }
}

export type DeleteFrontPageMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type DeleteFrontPageMutation = { __typename?: 'Mutation'; deleteFrontPage: boolean }

export type SportItemSetFrontPageContentFragment = {
  __typename?: 'SportItemSet'
  id: string
  type: SportItemSetType
  title: string
  ownerOrganismId?: string | null
  onFront: boolean
  timeOnFront: number
  ownerOrganism?: { __typename?: 'Organism'; id: string; title: string } | null
  frontPages: Array<{ __typename?: 'FrontPage'; id: string; start: Date; end?: Date | null }>
}

export type FrontPageSportItemSetsQueryVariables = Exact<{
  filters?: InputMaybe<SportItemSetFilters>
  orderBy?: InputMaybe<Array<SportItemSetSort> | SportItemSetSort>
}>

export type FrontPageSportItemSetsQuery = {
  __typename?: 'Query'
  sportItemSets: {
    __typename?: 'SportItemSetsPageResult'
    totalCount: number
    sportItemSets: Array<{
      __typename?: 'SportItemSet'
      id: string
      type: SportItemSetType
      title: string
      ownerOrganismId?: string | null
      onFront: boolean
      timeOnFront: number
      ownerOrganism?: { __typename?: 'Organism'; id: string; title: string } | null
      frontPages: Array<{ __typename?: 'FrontPage'; id: string; start: Date; end?: Date | null }>
    }>
  }
}

export type LegalRestrictionContentFragment = {
  __typename?: 'LegalRestriction'
  id: string
  active: boolean
  countries: Array<string>
  mature?: MatureFilter | null
  organismId?: string | null
  sportItemSetIds?: Array<string> | null
  offerIds?: Array<string> | null
  restrictionTarget?: RestrictionTargetFilter | null
  sportItemIds?: Array<string> | null
  type: RestrictionType
  description?: string | null
  organism?: { __typename?: 'Organism'; id: string; title: string } | null
  sportItemSets?: Array<{
    __typename?: 'SportItemSet'
    id: string
    title: string
    ownerOrganismId?: string | null
  }> | null
  offers?: Array<{ __typename?: 'PurchasableOffer'; id: string; title: string }> | null
  timeSlots?: { __typename?: 'TimeSlots'; from: string; to: string } | null
}

export type LegalRestrictionsQueryVariables = Exact<{ [key: string]: never }>

export type LegalRestrictionsQuery = {
  __typename?: 'Query'
  legalRestrictions: Array<{
    __typename?: 'LegalRestriction'
    id: string
    active: boolean
    countries: Array<string>
    mature?: MatureFilter | null
    organismId?: string | null
    sportItemSetIds?: Array<string> | null
    offerIds?: Array<string> | null
    restrictionTarget?: RestrictionTargetFilter | null
    sportItemIds?: Array<string> | null
    type: RestrictionType
    description?: string | null
    organism?: { __typename?: 'Organism'; id: string; title: string } | null
    sportItemSets?: Array<{
      __typename?: 'SportItemSet'
      id: string
      title: string
      ownerOrganismId?: string | null
    }> | null
    offers?: Array<{ __typename?: 'PurchasableOffer'; id: string; title: string }> | null
    timeSlots?: { __typename?: 'TimeSlots'; from: string; to: string } | null
  }>
}

export type CreateLegalRestrictionMutationVariables = Exact<{
  input: CreateLegalRestrictionInput
}>

export type CreateLegalRestrictionMutation = {
  __typename?: 'Mutation'
  createLegalRestriction: {
    __typename?: 'LegalRestriction'
    id: string
    active: boolean
    countries: Array<string>
    mature?: MatureFilter | null
    organismId?: string | null
    sportItemSetIds?: Array<string> | null
    offerIds?: Array<string> | null
    restrictionTarget?: RestrictionTargetFilter | null
    sportItemIds?: Array<string> | null
    type: RestrictionType
    description?: string | null
    organism?: { __typename?: 'Organism'; id: string; title: string } | null
    sportItemSets?: Array<{
      __typename?: 'SportItemSet'
      id: string
      title: string
      ownerOrganismId?: string | null
    }> | null
    offers?: Array<{ __typename?: 'PurchasableOffer'; id: string; title: string }> | null
    timeSlots?: { __typename?: 'TimeSlots'; from: string; to: string } | null
  }
}

export type UpdateLegalRestrictionMutationVariables = Exact<{
  id: Scalars['ID']
  input: UpdateLegalRestrictionInput
}>

export type UpdateLegalRestrictionMutation = {
  __typename?: 'Mutation'
  updateLegalRestriction: {
    __typename?: 'LegalRestriction'
    id: string
    active: boolean
    countries: Array<string>
    mature?: MatureFilter | null
    organismId?: string | null
    sportItemSetIds?: Array<string> | null
    offerIds?: Array<string> | null
    restrictionTarget?: RestrictionTargetFilter | null
    sportItemIds?: Array<string> | null
    type: RestrictionType
    description?: string | null
    organism?: { __typename?: 'Organism'; id: string; title: string } | null
    sportItemSets?: Array<{
      __typename?: 'SportItemSet'
      id: string
      title: string
      ownerOrganismId?: string | null
    }> | null
    offers?: Array<{ __typename?: 'PurchasableOffer'; id: string; title: string }> | null
    timeSlots?: { __typename?: 'TimeSlots'; from: string; to: string } | null
  }
}

export type DeleteLegalRestrictionMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type DeleteLegalRestrictionMutation = { __typename?: 'Mutation'; deleteLegalRestriction: boolean }

export type LinkItemContentFragment = {
  __typename: 'Link'
  id: string
  ownerOrganismIds: Array<string>
  tag?: LinkTag | null
  title: string
  subtitle?: string | null
  description?: string | null
  defaultLocale?: string | null
  clicks: number
  uniqueClicks: number
  views: number
  countries: Array<string | null>
  uniqueViews: number
  teaserVideoId?: string | null
  gamTagUrl?: string | null
  billboardId?: string | null
  url?: string | null
  imageId: string
  categoriesIds: Array<string>
  publicationStatus: PublicationStatus
  ownerOrganisms?: Array<{
    __typename?: 'Organism'
    id: string
    type: OrganismType
    whiteLabel: boolean
    title: string
    description?: string | null
    publicationStatus: PublicationStatus
    defaultLocale?: string | null
    onFront: boolean
    frontPosition?: number | null
    officialWebSiteUrl: string
    sportIds: Array<string>
    forbiddenKeyWords?: Array<string> | null
    enableExternalSources?: boolean | null
    availableExternalSources?: Array<WidgetExternalContentSource> | null
    streamsIds?: Array<string> | null
    wmProfileId?: string | null
    sveChannelIds?: Array<string> | null
    organismPageApplicationDesignId?: string | null
    contentPageApplicationDesignId?: string | null
    adminsAutomatedEmails?: boolean | null
    visibleInMultisportApplication?: boolean | null
    titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    descriptionT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    logo: {
      __typename?: 'Logo'
      coloredImage?: {
        __typename?: 'Media'
        id: string
        createdAt: Date
        updatedAt: Date
        title: string
        type: MediaType
        publicationStatus: PublicationStatus
        tags: Array<MediaTags>
        date?: Date | null
        startTrim?: number | null
        endTrim?: number | null
        liveStreamId?: string | null
        videoClipId?: string | null
        imageId?: string | null
        thumbnailId?: string | null
        ownerOrganismId?: string | null
        pinned?: boolean | null
        liveStream?: {
          __typename?: 'LiveStream'
          id: string
          playbackUrl: string
          ingestUrl?: string | null
          wildmokaEventID?: string | null
          videoEngine: VideoEngine
          state: LiveStreamState
          streamId?: string | null
          sveBroadcastId?: string | null
          sveChannelId?: string | null
          externalFeedProvider?: ExternalFeedType | null
          externalFeedUrl?: string | null
          event?: {
            __typename?: 'WMEvent'
            id: string
            name: string
            description?: string | null
            testStartDate: Date
            editoStartDate: Date
            editoStopDate: Date
            stopDate: Date
            state: WmEventState
            notifEmail?: string | null
            clipCreationAtEnd: boolean
            clipPublicationAtEnd: boolean
            livePublication: boolean
            stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
            live?: {
              __typename?: 'WMEventLive'
              id: string
              publishStatus: WmLiveState
              type: WmLiveType
              autoAnnounceDate?: Date | null
              template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
            } | null
            clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
          } | null
          sveBroadcast?: {
            __typename?: 'SVEBroadcast'
            id: string
            status: SveBroadcastState
            channelId: string
            startDate?: Date | null
            clipStartDate?: Date | null
            stopDate?: Date | null
            scheduledStartDate: Date
            scheduledClipStartDate: Date
            scheduledStopDate: Date
          } | null
          sveLiveMonitoring?: {
            __typename?: 'SVELiveMonitoring'
            alerts?: {
              __typename?: 'SVELiveMonitoringAlerts'
              audioNotDetected?: string | null
              initialProbeTakingLonger?: string | null
              videoNotDetected?: string | null
              RTMPHasNoAudioVideo?: string | null
              RTMPStreamNotFound?: string | null
            } | null
            stats?: {
              __typename?: 'SVELiveMonitoringStats'
              networkIn?: string | null
              droppedFrames?: string | null
              inputVideoFrameRate?: string | null
              outputAudioLevelLkfs?: string | null
            } | null
          } | null
        } | null
        videoClip?: {
          __typename?: 'VideoClip'
          id: string
          playbackUrl: string
          downloadLink: string
          status?: VideoClipStatus | null
          updatedAt: Date
          createdAt: Date
          fileId?: string | null
          error?: {
            __typename?: 'VideoClipError'
            guid: string
            workflowStatus: string
            workflowErrorAt: string
            errorMessage: string
            errorDetails: string
          } | null
          captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
          externalFeed?: {
            __typename?: 'ExternalFeed'
            externalFeedProvider: ExternalFeedType
            externalFeedUrl: string
          } | null
        } | null
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
        thumbnail?: {
          __typename?: 'Media'
          id: string
          image?: {
            __typename?: 'File'
            id: string
            filename: string
            size: number
            mimetype: string
            downloadUrl: string
            thumbUrl?: string | null
            createdAt: Date
            metadata?: {
              __typename?: 'FileMetadata'
              title?: string | null
              description?: string | null
              aspect?: number | null
              width?: number | null
              height?: number | null
              sourceFileId?: string | null
            } | null
          } | null
        } | null
        timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
      } | null
      transparentDarkImage?: {
        __typename?: 'Media'
        id: string
        createdAt: Date
        updatedAt: Date
        title: string
        type: MediaType
        publicationStatus: PublicationStatus
        tags: Array<MediaTags>
        date?: Date | null
        startTrim?: number | null
        endTrim?: number | null
        liveStreamId?: string | null
        videoClipId?: string | null
        imageId?: string | null
        thumbnailId?: string | null
        ownerOrganismId?: string | null
        pinned?: boolean | null
        liveStream?: {
          __typename?: 'LiveStream'
          id: string
          playbackUrl: string
          ingestUrl?: string | null
          wildmokaEventID?: string | null
          videoEngine: VideoEngine
          state: LiveStreamState
          streamId?: string | null
          sveBroadcastId?: string | null
          sveChannelId?: string | null
          externalFeedProvider?: ExternalFeedType | null
          externalFeedUrl?: string | null
          event?: {
            __typename?: 'WMEvent'
            id: string
            name: string
            description?: string | null
            testStartDate: Date
            editoStartDate: Date
            editoStopDate: Date
            stopDate: Date
            state: WmEventState
            notifEmail?: string | null
            clipCreationAtEnd: boolean
            clipPublicationAtEnd: boolean
            livePublication: boolean
            stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
            live?: {
              __typename?: 'WMEventLive'
              id: string
              publishStatus: WmLiveState
              type: WmLiveType
              autoAnnounceDate?: Date | null
              template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
            } | null
            clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
          } | null
          sveBroadcast?: {
            __typename?: 'SVEBroadcast'
            id: string
            status: SveBroadcastState
            channelId: string
            startDate?: Date | null
            clipStartDate?: Date | null
            stopDate?: Date | null
            scheduledStartDate: Date
            scheduledClipStartDate: Date
            scheduledStopDate: Date
          } | null
          sveLiveMonitoring?: {
            __typename?: 'SVELiveMonitoring'
            alerts?: {
              __typename?: 'SVELiveMonitoringAlerts'
              audioNotDetected?: string | null
              initialProbeTakingLonger?: string | null
              videoNotDetected?: string | null
              RTMPHasNoAudioVideo?: string | null
              RTMPStreamNotFound?: string | null
            } | null
            stats?: {
              __typename?: 'SVELiveMonitoringStats'
              networkIn?: string | null
              droppedFrames?: string | null
              inputVideoFrameRate?: string | null
              outputAudioLevelLkfs?: string | null
            } | null
          } | null
        } | null
        videoClip?: {
          __typename?: 'VideoClip'
          id: string
          playbackUrl: string
          downloadLink: string
          status?: VideoClipStatus | null
          updatedAt: Date
          createdAt: Date
          fileId?: string | null
          error?: {
            __typename?: 'VideoClipError'
            guid: string
            workflowStatus: string
            workflowErrorAt: string
            errorMessage: string
            errorDetails: string
          } | null
          captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
          externalFeed?: {
            __typename?: 'ExternalFeed'
            externalFeedProvider: ExternalFeedType
            externalFeedUrl: string
          } | null
        } | null
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
        thumbnail?: {
          __typename?: 'Media'
          id: string
          image?: {
            __typename?: 'File'
            id: string
            filename: string
            size: number
            mimetype: string
            downloadUrl: string
            thumbUrl?: string | null
            createdAt: Date
            metadata?: {
              __typename?: 'FileMetadata'
              title?: string | null
              description?: string | null
              aspect?: number | null
              width?: number | null
              height?: number | null
              sourceFileId?: string | null
            } | null
          } | null
        } | null
        timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
      } | null
      transparentLightImage?: {
        __typename?: 'Media'
        id: string
        createdAt: Date
        updatedAt: Date
        title: string
        type: MediaType
        publicationStatus: PublicationStatus
        tags: Array<MediaTags>
        date?: Date | null
        startTrim?: number | null
        endTrim?: number | null
        liveStreamId?: string | null
        videoClipId?: string | null
        imageId?: string | null
        thumbnailId?: string | null
        ownerOrganismId?: string | null
        pinned?: boolean | null
        liveStream?: {
          __typename?: 'LiveStream'
          id: string
          playbackUrl: string
          ingestUrl?: string | null
          wildmokaEventID?: string | null
          videoEngine: VideoEngine
          state: LiveStreamState
          streamId?: string | null
          sveBroadcastId?: string | null
          sveChannelId?: string | null
          externalFeedProvider?: ExternalFeedType | null
          externalFeedUrl?: string | null
          event?: {
            __typename?: 'WMEvent'
            id: string
            name: string
            description?: string | null
            testStartDate: Date
            editoStartDate: Date
            editoStopDate: Date
            stopDate: Date
            state: WmEventState
            notifEmail?: string | null
            clipCreationAtEnd: boolean
            clipPublicationAtEnd: boolean
            livePublication: boolean
            stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
            live?: {
              __typename?: 'WMEventLive'
              id: string
              publishStatus: WmLiveState
              type: WmLiveType
              autoAnnounceDate?: Date | null
              template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
            } | null
            clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
          } | null
          sveBroadcast?: {
            __typename?: 'SVEBroadcast'
            id: string
            status: SveBroadcastState
            channelId: string
            startDate?: Date | null
            clipStartDate?: Date | null
            stopDate?: Date | null
            scheduledStartDate: Date
            scheduledClipStartDate: Date
            scheduledStopDate: Date
          } | null
          sveLiveMonitoring?: {
            __typename?: 'SVELiveMonitoring'
            alerts?: {
              __typename?: 'SVELiveMonitoringAlerts'
              audioNotDetected?: string | null
              initialProbeTakingLonger?: string | null
              videoNotDetected?: string | null
              RTMPHasNoAudioVideo?: string | null
              RTMPStreamNotFound?: string | null
            } | null
            stats?: {
              __typename?: 'SVELiveMonitoringStats'
              networkIn?: string | null
              droppedFrames?: string | null
              inputVideoFrameRate?: string | null
              outputAudioLevelLkfs?: string | null
            } | null
          } | null
        } | null
        videoClip?: {
          __typename?: 'VideoClip'
          id: string
          playbackUrl: string
          downloadLink: string
          status?: VideoClipStatus | null
          updatedAt: Date
          createdAt: Date
          fileId?: string | null
          error?: {
            __typename?: 'VideoClipError'
            guid: string
            workflowStatus: string
            workflowErrorAt: string
            errorMessage: string
            errorDetails: string
          } | null
          captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
          externalFeed?: {
            __typename?: 'ExternalFeed'
            externalFeedProvider: ExternalFeedType
            externalFeedUrl: string
          } | null
        } | null
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
        thumbnail?: {
          __typename?: 'Media'
          id: string
          image?: {
            __typename?: 'File'
            id: string
            filename: string
            size: number
            mimetype: string
            downloadUrl: string
            thumbUrl?: string | null
            createdAt: Date
            metadata?: {
              __typename?: 'FileMetadata'
              title?: string | null
              description?: string | null
              aspect?: number | null
              width?: number | null
              height?: number | null
              sourceFileId?: string | null
            } | null
          } | null
        } | null
        timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
      } | null
    }
    sports: Array<{ __typename?: 'Sport'; id: string }>
    bannerImage?: {
      __typename?: 'Media'
      id: string
      createdAt: Date
      updatedAt: Date
      title: string
      type: MediaType
      publicationStatus: PublicationStatus
      tags: Array<MediaTags>
      date?: Date | null
      startTrim?: number | null
      endTrim?: number | null
      liveStreamId?: string | null
      videoClipId?: string | null
      imageId?: string | null
      thumbnailId?: string | null
      ownerOrganismId?: string | null
      pinned?: boolean | null
      liveStream?: {
        __typename?: 'LiveStream'
        id: string
        playbackUrl: string
        ingestUrl?: string | null
        wildmokaEventID?: string | null
        videoEngine: VideoEngine
        state: LiveStreamState
        streamId?: string | null
        sveBroadcastId?: string | null
        sveChannelId?: string | null
        externalFeedProvider?: ExternalFeedType | null
        externalFeedUrl?: string | null
        event?: {
          __typename?: 'WMEvent'
          id: string
          name: string
          description?: string | null
          testStartDate: Date
          editoStartDate: Date
          editoStopDate: Date
          stopDate: Date
          state: WmEventState
          notifEmail?: string | null
          clipCreationAtEnd: boolean
          clipPublicationAtEnd: boolean
          livePublication: boolean
          stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
          live?: {
            __typename?: 'WMEventLive'
            id: string
            publishStatus: WmLiveState
            type: WmLiveType
            autoAnnounceDate?: Date | null
            template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
          } | null
          clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
        } | null
        sveBroadcast?: {
          __typename?: 'SVEBroadcast'
          id: string
          status: SveBroadcastState
          channelId: string
          startDate?: Date | null
          clipStartDate?: Date | null
          stopDate?: Date | null
          scheduledStartDate: Date
          scheduledClipStartDate: Date
          scheduledStopDate: Date
        } | null
        sveLiveMonitoring?: {
          __typename?: 'SVELiveMonitoring'
          alerts?: {
            __typename?: 'SVELiveMonitoringAlerts'
            audioNotDetected?: string | null
            initialProbeTakingLonger?: string | null
            videoNotDetected?: string | null
            RTMPHasNoAudioVideo?: string | null
            RTMPStreamNotFound?: string | null
          } | null
          stats?: {
            __typename?: 'SVELiveMonitoringStats'
            networkIn?: string | null
            droppedFrames?: string | null
            inputVideoFrameRate?: string | null
            outputAudioLevelLkfs?: string | null
          } | null
        } | null
      } | null
      videoClip?: {
        __typename?: 'VideoClip'
        id: string
        playbackUrl: string
        downloadLink: string
        status?: VideoClipStatus | null
        updatedAt: Date
        createdAt: Date
        fileId?: string | null
        error?: {
          __typename?: 'VideoClipError'
          guid: string
          workflowStatus: string
          workflowErrorAt: string
          errorMessage: string
          errorDetails: string
        } | null
        captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
        externalFeed?: {
          __typename?: 'ExternalFeed'
          externalFeedProvider: ExternalFeedType
          externalFeedUrl: string
        } | null
      } | null
      image?: {
        __typename?: 'File'
        id: string
        filename: string
        size: number
        mimetype: string
        downloadUrl: string
        thumbUrl?: string | null
        createdAt: Date
        metadata?: {
          __typename?: 'FileMetadata'
          title?: string | null
          description?: string | null
          aspect?: number | null
          width?: number | null
          height?: number | null
          sourceFileId?: string | null
        } | null
      } | null
      thumbnail?: {
        __typename?: 'Media'
        id: string
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
      } | null
      timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
    } | null
    adminBadge?: {
      __typename?: 'Media'
      id: string
      createdAt: Date
      updatedAt: Date
      title: string
      type: MediaType
      publicationStatus: PublicationStatus
      tags: Array<MediaTags>
      date?: Date | null
      startTrim?: number | null
      endTrim?: number | null
      liveStreamId?: string | null
      videoClipId?: string | null
      imageId?: string | null
      thumbnailId?: string | null
      ownerOrganismId?: string | null
      pinned?: boolean | null
      liveStream?: {
        __typename?: 'LiveStream'
        id: string
        playbackUrl: string
        ingestUrl?: string | null
        wildmokaEventID?: string | null
        videoEngine: VideoEngine
        state: LiveStreamState
        streamId?: string | null
        sveBroadcastId?: string | null
        sveChannelId?: string | null
        externalFeedProvider?: ExternalFeedType | null
        externalFeedUrl?: string | null
        event?: {
          __typename?: 'WMEvent'
          id: string
          name: string
          description?: string | null
          testStartDate: Date
          editoStartDate: Date
          editoStopDate: Date
          stopDate: Date
          state: WmEventState
          notifEmail?: string | null
          clipCreationAtEnd: boolean
          clipPublicationAtEnd: boolean
          livePublication: boolean
          stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
          live?: {
            __typename?: 'WMEventLive'
            id: string
            publishStatus: WmLiveState
            type: WmLiveType
            autoAnnounceDate?: Date | null
            template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
          } | null
          clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
        } | null
        sveBroadcast?: {
          __typename?: 'SVEBroadcast'
          id: string
          status: SveBroadcastState
          channelId: string
          startDate?: Date | null
          clipStartDate?: Date | null
          stopDate?: Date | null
          scheduledStartDate: Date
          scheduledClipStartDate: Date
          scheduledStopDate: Date
        } | null
        sveLiveMonitoring?: {
          __typename?: 'SVELiveMonitoring'
          alerts?: {
            __typename?: 'SVELiveMonitoringAlerts'
            audioNotDetected?: string | null
            initialProbeTakingLonger?: string | null
            videoNotDetected?: string | null
            RTMPHasNoAudioVideo?: string | null
            RTMPStreamNotFound?: string | null
          } | null
          stats?: {
            __typename?: 'SVELiveMonitoringStats'
            networkIn?: string | null
            droppedFrames?: string | null
            inputVideoFrameRate?: string | null
            outputAudioLevelLkfs?: string | null
          } | null
        } | null
      } | null
      videoClip?: {
        __typename?: 'VideoClip'
        id: string
        playbackUrl: string
        downloadLink: string
        status?: VideoClipStatus | null
        updatedAt: Date
        createdAt: Date
        fileId?: string | null
        error?: {
          __typename?: 'VideoClipError'
          guid: string
          workflowStatus: string
          workflowErrorAt: string
          errorMessage: string
          errorDetails: string
        } | null
        captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
        externalFeed?: {
          __typename?: 'ExternalFeed'
          externalFeedProvider: ExternalFeedType
          externalFeedUrl: string
        } | null
      } | null
      image?: {
        __typename?: 'File'
        id: string
        filename: string
        size: number
        mimetype: string
        downloadUrl: string
        thumbUrl?: string | null
        createdAt: Date
        metadata?: {
          __typename?: 'FileMetadata'
          title?: string | null
          description?: string | null
          aspect?: number | null
          width?: number | null
          height?: number | null
          sourceFileId?: string | null
        } | null
      } | null
      thumbnail?: {
        __typename?: 'Media'
        id: string
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
      } | null
      timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
    } | null
    bannerVideo?: {
      __typename?: 'Media'
      id: string
      videoClip?: { __typename?: 'VideoClip'; id: string; playbackUrl: string } | null
    } | null
    pageWidgets?: Array<{
      __typename?: 'CustomPageWidget'
      name: CustomPageWidgetName
      position: number
      display?: CustomPageDisplayTypes | null
      contentDisplay?: ContentSetContentDisplay | null
      cardsDisplay?: CardsDisplay | null
      options?: {
        __typename?: 'CustomPageWidgetOptions'
        editedCategoryId?: string | null
        sportItemSetType?: SportItemSetType | null
        replays?: {
          __typename?: 'ReplaysWidget'
          defaultLocale: string
          maxReplays?: number | null
          depthInDays?: number | null
          nameT: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }>
        } | null
        externalContent?: {
          __typename?: 'ExternalContentWidget'
          name?: string | null
          defaultLocale: string
          maxItemsCount: number
          source: WidgetExternalContentSource
          nameT: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }>
          period?: { __typename?: 'StartEndDates'; startDate: Date; endDate: Date } | null
          wecContent?: {
            __typename?: 'WECNewsContent'
            teamECMIds?: Array<string> | null
            driverECMIds?: Array<string> | null
            carECMIds?: Array<string> | null
            categoryECMIds?: Array<string> | null
            raceECMIds?: Array<string> | null
          } | null
          rssContent?: { __typename?: 'RSSFeedContent'; feedUrl: string } | null
        } | null
      } | null
    }> | null
    offers: Array<{
      __typename?: 'PurchasableOffer'
      id: string
      mode: OfferMode
      delay?: number | null
      expiredAt?: Date | null
      publicationStatus: PublicationStatus
      title: string
      defaultLocale?: string | null
      url?: string | null
      bannerImageId?: string | null
      thumbnailImageId?: string | null
      freeWithLicense: boolean
      position?: number | null
      titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
      description: Array<{ __typename?: 'OfferDescription'; header: string; content: string }>
      descriptionT?: Array<{
        __typename?: 'ContentTranslationDescriptionPurchasableOffer'
        locale: string
        content?: Array<{ __typename?: 'OfferDescription'; header: string; content: string }> | null
      }> | null
      products: Array<{
        __typename?: 'Product'
        productId: string
        platform: Platform
        period?: Period | null
        publicationStatus: PublicationStatus
        purchasableOfferId?: string | null
        licensedUserOnly?: boolean | null
        premiumOf?: Array<string> | null
        prices: Array<{ __typename?: 'Price'; value: number; currency: string }>
      }>
    }>
    license?: { __typename?: 'OrganismLicense'; enabled: boolean; api?: LicenseApi | null } | null
    tags?: Array<{ __typename?: 'Tag'; id: string; title: string; subTags?: Array<string> | null }> | null
  }> | null
  titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
  subtitleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
  descriptionT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
  billboard?: {
    __typename?: 'Media'
    id: string
    videoClip?: { __typename?: 'VideoClip'; id: string; playbackUrl: string } | null
  } | null
  teaserVideo?: {
    __typename?: 'Media'
    id: string
    videoClip?: { __typename?: 'VideoClip'; id: string; playbackUrl: string } | null
  } | null
  image?: {
    __typename?: 'Media'
    id: string
    image?: { __typename?: 'File'; id: string; thumbUrl?: string | null; downloadUrl: string } | null
  } | null
  categories: Array<{ __typename?: 'Category'; id: string; title: string }>
}

export type CreateLinkMutationVariables = Exact<{
  input: CreateLinkInput
}>

export type CreateLinkMutation = {
  __typename?: 'Mutation'
  createLink: {
    __typename: 'Link'
    id: string
    ownerOrganismIds: Array<string>
    tag?: LinkTag | null
    title: string
    subtitle?: string | null
    description?: string | null
    defaultLocale?: string | null
    clicks: number
    uniqueClicks: number
    views: number
    countries: Array<string | null>
    uniqueViews: number
    teaserVideoId?: string | null
    gamTagUrl?: string | null
    billboardId?: string | null
    url?: string | null
    imageId: string
    categoriesIds: Array<string>
    publicationStatus: PublicationStatus
    ownerOrganisms?: Array<{
      __typename?: 'Organism'
      id: string
      type: OrganismType
      whiteLabel: boolean
      title: string
      description?: string | null
      publicationStatus: PublicationStatus
      defaultLocale?: string | null
      onFront: boolean
      frontPosition?: number | null
      officialWebSiteUrl: string
      sportIds: Array<string>
      forbiddenKeyWords?: Array<string> | null
      enableExternalSources?: boolean | null
      availableExternalSources?: Array<WidgetExternalContentSource> | null
      streamsIds?: Array<string> | null
      wmProfileId?: string | null
      sveChannelIds?: Array<string> | null
      organismPageApplicationDesignId?: string | null
      contentPageApplicationDesignId?: string | null
      adminsAutomatedEmails?: boolean | null
      visibleInMultisportApplication?: boolean | null
      titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
      descriptionT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
      logo: {
        __typename?: 'Logo'
        coloredImage?: {
          __typename?: 'Media'
          id: string
          createdAt: Date
          updatedAt: Date
          title: string
          type: MediaType
          publicationStatus: PublicationStatus
          tags: Array<MediaTags>
          date?: Date | null
          startTrim?: number | null
          endTrim?: number | null
          liveStreamId?: string | null
          videoClipId?: string | null
          imageId?: string | null
          thumbnailId?: string | null
          ownerOrganismId?: string | null
          pinned?: boolean | null
          liveStream?: {
            __typename?: 'LiveStream'
            id: string
            playbackUrl: string
            ingestUrl?: string | null
            wildmokaEventID?: string | null
            videoEngine: VideoEngine
            state: LiveStreamState
            streamId?: string | null
            sveBroadcastId?: string | null
            sveChannelId?: string | null
            externalFeedProvider?: ExternalFeedType | null
            externalFeedUrl?: string | null
            event?: {
              __typename?: 'WMEvent'
              id: string
              name: string
              description?: string | null
              testStartDate: Date
              editoStartDate: Date
              editoStopDate: Date
              stopDate: Date
              state: WmEventState
              notifEmail?: string | null
              clipCreationAtEnd: boolean
              clipPublicationAtEnd: boolean
              livePublication: boolean
              stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
              live?: {
                __typename?: 'WMEventLive'
                id: string
                publishStatus: WmLiveState
                type: WmLiveType
                autoAnnounceDate?: Date | null
                template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
              } | null
              clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
            } | null
            sveBroadcast?: {
              __typename?: 'SVEBroadcast'
              id: string
              status: SveBroadcastState
              channelId: string
              startDate?: Date | null
              clipStartDate?: Date | null
              stopDate?: Date | null
              scheduledStartDate: Date
              scheduledClipStartDate: Date
              scheduledStopDate: Date
            } | null
            sveLiveMonitoring?: {
              __typename?: 'SVELiveMonitoring'
              alerts?: {
                __typename?: 'SVELiveMonitoringAlerts'
                audioNotDetected?: string | null
                initialProbeTakingLonger?: string | null
                videoNotDetected?: string | null
                RTMPHasNoAudioVideo?: string | null
                RTMPStreamNotFound?: string | null
              } | null
              stats?: {
                __typename?: 'SVELiveMonitoringStats'
                networkIn?: string | null
                droppedFrames?: string | null
                inputVideoFrameRate?: string | null
                outputAudioLevelLkfs?: string | null
              } | null
            } | null
          } | null
          videoClip?: {
            __typename?: 'VideoClip'
            id: string
            playbackUrl: string
            downloadLink: string
            status?: VideoClipStatus | null
            updatedAt: Date
            createdAt: Date
            fileId?: string | null
            error?: {
              __typename?: 'VideoClipError'
              guid: string
              workflowStatus: string
              workflowErrorAt: string
              errorMessage: string
              errorDetails: string
            } | null
            captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
            externalFeed?: {
              __typename?: 'ExternalFeed'
              externalFeedProvider: ExternalFeedType
              externalFeedUrl: string
            } | null
          } | null
          image?: {
            __typename?: 'File'
            id: string
            filename: string
            size: number
            mimetype: string
            downloadUrl: string
            thumbUrl?: string | null
            createdAt: Date
            metadata?: {
              __typename?: 'FileMetadata'
              title?: string | null
              description?: string | null
              aspect?: number | null
              width?: number | null
              height?: number | null
              sourceFileId?: string | null
            } | null
          } | null
          thumbnail?: {
            __typename?: 'Media'
            id: string
            image?: {
              __typename?: 'File'
              id: string
              filename: string
              size: number
              mimetype: string
              downloadUrl: string
              thumbUrl?: string | null
              createdAt: Date
              metadata?: {
                __typename?: 'FileMetadata'
                title?: string | null
                description?: string | null
                aspect?: number | null
                width?: number | null
                height?: number | null
                sourceFileId?: string | null
              } | null
            } | null
          } | null
          timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
        } | null
        transparentDarkImage?: {
          __typename?: 'Media'
          id: string
          createdAt: Date
          updatedAt: Date
          title: string
          type: MediaType
          publicationStatus: PublicationStatus
          tags: Array<MediaTags>
          date?: Date | null
          startTrim?: number | null
          endTrim?: number | null
          liveStreamId?: string | null
          videoClipId?: string | null
          imageId?: string | null
          thumbnailId?: string | null
          ownerOrganismId?: string | null
          pinned?: boolean | null
          liveStream?: {
            __typename?: 'LiveStream'
            id: string
            playbackUrl: string
            ingestUrl?: string | null
            wildmokaEventID?: string | null
            videoEngine: VideoEngine
            state: LiveStreamState
            streamId?: string | null
            sveBroadcastId?: string | null
            sveChannelId?: string | null
            externalFeedProvider?: ExternalFeedType | null
            externalFeedUrl?: string | null
            event?: {
              __typename?: 'WMEvent'
              id: string
              name: string
              description?: string | null
              testStartDate: Date
              editoStartDate: Date
              editoStopDate: Date
              stopDate: Date
              state: WmEventState
              notifEmail?: string | null
              clipCreationAtEnd: boolean
              clipPublicationAtEnd: boolean
              livePublication: boolean
              stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
              live?: {
                __typename?: 'WMEventLive'
                id: string
                publishStatus: WmLiveState
                type: WmLiveType
                autoAnnounceDate?: Date | null
                template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
              } | null
              clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
            } | null
            sveBroadcast?: {
              __typename?: 'SVEBroadcast'
              id: string
              status: SveBroadcastState
              channelId: string
              startDate?: Date | null
              clipStartDate?: Date | null
              stopDate?: Date | null
              scheduledStartDate: Date
              scheduledClipStartDate: Date
              scheduledStopDate: Date
            } | null
            sveLiveMonitoring?: {
              __typename?: 'SVELiveMonitoring'
              alerts?: {
                __typename?: 'SVELiveMonitoringAlerts'
                audioNotDetected?: string | null
                initialProbeTakingLonger?: string | null
                videoNotDetected?: string | null
                RTMPHasNoAudioVideo?: string | null
                RTMPStreamNotFound?: string | null
              } | null
              stats?: {
                __typename?: 'SVELiveMonitoringStats'
                networkIn?: string | null
                droppedFrames?: string | null
                inputVideoFrameRate?: string | null
                outputAudioLevelLkfs?: string | null
              } | null
            } | null
          } | null
          videoClip?: {
            __typename?: 'VideoClip'
            id: string
            playbackUrl: string
            downloadLink: string
            status?: VideoClipStatus | null
            updatedAt: Date
            createdAt: Date
            fileId?: string | null
            error?: {
              __typename?: 'VideoClipError'
              guid: string
              workflowStatus: string
              workflowErrorAt: string
              errorMessage: string
              errorDetails: string
            } | null
            captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
            externalFeed?: {
              __typename?: 'ExternalFeed'
              externalFeedProvider: ExternalFeedType
              externalFeedUrl: string
            } | null
          } | null
          image?: {
            __typename?: 'File'
            id: string
            filename: string
            size: number
            mimetype: string
            downloadUrl: string
            thumbUrl?: string | null
            createdAt: Date
            metadata?: {
              __typename?: 'FileMetadata'
              title?: string | null
              description?: string | null
              aspect?: number | null
              width?: number | null
              height?: number | null
              sourceFileId?: string | null
            } | null
          } | null
          thumbnail?: {
            __typename?: 'Media'
            id: string
            image?: {
              __typename?: 'File'
              id: string
              filename: string
              size: number
              mimetype: string
              downloadUrl: string
              thumbUrl?: string | null
              createdAt: Date
              metadata?: {
                __typename?: 'FileMetadata'
                title?: string | null
                description?: string | null
                aspect?: number | null
                width?: number | null
                height?: number | null
                sourceFileId?: string | null
              } | null
            } | null
          } | null
          timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
        } | null
        transparentLightImage?: {
          __typename?: 'Media'
          id: string
          createdAt: Date
          updatedAt: Date
          title: string
          type: MediaType
          publicationStatus: PublicationStatus
          tags: Array<MediaTags>
          date?: Date | null
          startTrim?: number | null
          endTrim?: number | null
          liveStreamId?: string | null
          videoClipId?: string | null
          imageId?: string | null
          thumbnailId?: string | null
          ownerOrganismId?: string | null
          pinned?: boolean | null
          liveStream?: {
            __typename?: 'LiveStream'
            id: string
            playbackUrl: string
            ingestUrl?: string | null
            wildmokaEventID?: string | null
            videoEngine: VideoEngine
            state: LiveStreamState
            streamId?: string | null
            sveBroadcastId?: string | null
            sveChannelId?: string | null
            externalFeedProvider?: ExternalFeedType | null
            externalFeedUrl?: string | null
            event?: {
              __typename?: 'WMEvent'
              id: string
              name: string
              description?: string | null
              testStartDate: Date
              editoStartDate: Date
              editoStopDate: Date
              stopDate: Date
              state: WmEventState
              notifEmail?: string | null
              clipCreationAtEnd: boolean
              clipPublicationAtEnd: boolean
              livePublication: boolean
              stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
              live?: {
                __typename?: 'WMEventLive'
                id: string
                publishStatus: WmLiveState
                type: WmLiveType
                autoAnnounceDate?: Date | null
                template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
              } | null
              clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
            } | null
            sveBroadcast?: {
              __typename?: 'SVEBroadcast'
              id: string
              status: SveBroadcastState
              channelId: string
              startDate?: Date | null
              clipStartDate?: Date | null
              stopDate?: Date | null
              scheduledStartDate: Date
              scheduledClipStartDate: Date
              scheduledStopDate: Date
            } | null
            sveLiveMonitoring?: {
              __typename?: 'SVELiveMonitoring'
              alerts?: {
                __typename?: 'SVELiveMonitoringAlerts'
                audioNotDetected?: string | null
                initialProbeTakingLonger?: string | null
                videoNotDetected?: string | null
                RTMPHasNoAudioVideo?: string | null
                RTMPStreamNotFound?: string | null
              } | null
              stats?: {
                __typename?: 'SVELiveMonitoringStats'
                networkIn?: string | null
                droppedFrames?: string | null
                inputVideoFrameRate?: string | null
                outputAudioLevelLkfs?: string | null
              } | null
            } | null
          } | null
          videoClip?: {
            __typename?: 'VideoClip'
            id: string
            playbackUrl: string
            downloadLink: string
            status?: VideoClipStatus | null
            updatedAt: Date
            createdAt: Date
            fileId?: string | null
            error?: {
              __typename?: 'VideoClipError'
              guid: string
              workflowStatus: string
              workflowErrorAt: string
              errorMessage: string
              errorDetails: string
            } | null
            captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
            externalFeed?: {
              __typename?: 'ExternalFeed'
              externalFeedProvider: ExternalFeedType
              externalFeedUrl: string
            } | null
          } | null
          image?: {
            __typename?: 'File'
            id: string
            filename: string
            size: number
            mimetype: string
            downloadUrl: string
            thumbUrl?: string | null
            createdAt: Date
            metadata?: {
              __typename?: 'FileMetadata'
              title?: string | null
              description?: string | null
              aspect?: number | null
              width?: number | null
              height?: number | null
              sourceFileId?: string | null
            } | null
          } | null
          thumbnail?: {
            __typename?: 'Media'
            id: string
            image?: {
              __typename?: 'File'
              id: string
              filename: string
              size: number
              mimetype: string
              downloadUrl: string
              thumbUrl?: string | null
              createdAt: Date
              metadata?: {
                __typename?: 'FileMetadata'
                title?: string | null
                description?: string | null
                aspect?: number | null
                width?: number | null
                height?: number | null
                sourceFileId?: string | null
              } | null
            } | null
          } | null
          timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
        } | null
      }
      sports: Array<{ __typename?: 'Sport'; id: string }>
      bannerImage?: {
        __typename?: 'Media'
        id: string
        createdAt: Date
        updatedAt: Date
        title: string
        type: MediaType
        publicationStatus: PublicationStatus
        tags: Array<MediaTags>
        date?: Date | null
        startTrim?: number | null
        endTrim?: number | null
        liveStreamId?: string | null
        videoClipId?: string | null
        imageId?: string | null
        thumbnailId?: string | null
        ownerOrganismId?: string | null
        pinned?: boolean | null
        liveStream?: {
          __typename?: 'LiveStream'
          id: string
          playbackUrl: string
          ingestUrl?: string | null
          wildmokaEventID?: string | null
          videoEngine: VideoEngine
          state: LiveStreamState
          streamId?: string | null
          sveBroadcastId?: string | null
          sveChannelId?: string | null
          externalFeedProvider?: ExternalFeedType | null
          externalFeedUrl?: string | null
          event?: {
            __typename?: 'WMEvent'
            id: string
            name: string
            description?: string | null
            testStartDate: Date
            editoStartDate: Date
            editoStopDate: Date
            stopDate: Date
            state: WmEventState
            notifEmail?: string | null
            clipCreationAtEnd: boolean
            clipPublicationAtEnd: boolean
            livePublication: boolean
            stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
            live?: {
              __typename?: 'WMEventLive'
              id: string
              publishStatus: WmLiveState
              type: WmLiveType
              autoAnnounceDate?: Date | null
              template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
            } | null
            clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
          } | null
          sveBroadcast?: {
            __typename?: 'SVEBroadcast'
            id: string
            status: SveBroadcastState
            channelId: string
            startDate?: Date | null
            clipStartDate?: Date | null
            stopDate?: Date | null
            scheduledStartDate: Date
            scheduledClipStartDate: Date
            scheduledStopDate: Date
          } | null
          sveLiveMonitoring?: {
            __typename?: 'SVELiveMonitoring'
            alerts?: {
              __typename?: 'SVELiveMonitoringAlerts'
              audioNotDetected?: string | null
              initialProbeTakingLonger?: string | null
              videoNotDetected?: string | null
              RTMPHasNoAudioVideo?: string | null
              RTMPStreamNotFound?: string | null
            } | null
            stats?: {
              __typename?: 'SVELiveMonitoringStats'
              networkIn?: string | null
              droppedFrames?: string | null
              inputVideoFrameRate?: string | null
              outputAudioLevelLkfs?: string | null
            } | null
          } | null
        } | null
        videoClip?: {
          __typename?: 'VideoClip'
          id: string
          playbackUrl: string
          downloadLink: string
          status?: VideoClipStatus | null
          updatedAt: Date
          createdAt: Date
          fileId?: string | null
          error?: {
            __typename?: 'VideoClipError'
            guid: string
            workflowStatus: string
            workflowErrorAt: string
            errorMessage: string
            errorDetails: string
          } | null
          captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
          externalFeed?: {
            __typename?: 'ExternalFeed'
            externalFeedProvider: ExternalFeedType
            externalFeedUrl: string
          } | null
        } | null
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
        thumbnail?: {
          __typename?: 'Media'
          id: string
          image?: {
            __typename?: 'File'
            id: string
            filename: string
            size: number
            mimetype: string
            downloadUrl: string
            thumbUrl?: string | null
            createdAt: Date
            metadata?: {
              __typename?: 'FileMetadata'
              title?: string | null
              description?: string | null
              aspect?: number | null
              width?: number | null
              height?: number | null
              sourceFileId?: string | null
            } | null
          } | null
        } | null
        timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
      } | null
      adminBadge?: {
        __typename?: 'Media'
        id: string
        createdAt: Date
        updatedAt: Date
        title: string
        type: MediaType
        publicationStatus: PublicationStatus
        tags: Array<MediaTags>
        date?: Date | null
        startTrim?: number | null
        endTrim?: number | null
        liveStreamId?: string | null
        videoClipId?: string | null
        imageId?: string | null
        thumbnailId?: string | null
        ownerOrganismId?: string | null
        pinned?: boolean | null
        liveStream?: {
          __typename?: 'LiveStream'
          id: string
          playbackUrl: string
          ingestUrl?: string | null
          wildmokaEventID?: string | null
          videoEngine: VideoEngine
          state: LiveStreamState
          streamId?: string | null
          sveBroadcastId?: string | null
          sveChannelId?: string | null
          externalFeedProvider?: ExternalFeedType | null
          externalFeedUrl?: string | null
          event?: {
            __typename?: 'WMEvent'
            id: string
            name: string
            description?: string | null
            testStartDate: Date
            editoStartDate: Date
            editoStopDate: Date
            stopDate: Date
            state: WmEventState
            notifEmail?: string | null
            clipCreationAtEnd: boolean
            clipPublicationAtEnd: boolean
            livePublication: boolean
            stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
            live?: {
              __typename?: 'WMEventLive'
              id: string
              publishStatus: WmLiveState
              type: WmLiveType
              autoAnnounceDate?: Date | null
              template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
            } | null
            clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
          } | null
          sveBroadcast?: {
            __typename?: 'SVEBroadcast'
            id: string
            status: SveBroadcastState
            channelId: string
            startDate?: Date | null
            clipStartDate?: Date | null
            stopDate?: Date | null
            scheduledStartDate: Date
            scheduledClipStartDate: Date
            scheduledStopDate: Date
          } | null
          sveLiveMonitoring?: {
            __typename?: 'SVELiveMonitoring'
            alerts?: {
              __typename?: 'SVELiveMonitoringAlerts'
              audioNotDetected?: string | null
              initialProbeTakingLonger?: string | null
              videoNotDetected?: string | null
              RTMPHasNoAudioVideo?: string | null
              RTMPStreamNotFound?: string | null
            } | null
            stats?: {
              __typename?: 'SVELiveMonitoringStats'
              networkIn?: string | null
              droppedFrames?: string | null
              inputVideoFrameRate?: string | null
              outputAudioLevelLkfs?: string | null
            } | null
          } | null
        } | null
        videoClip?: {
          __typename?: 'VideoClip'
          id: string
          playbackUrl: string
          downloadLink: string
          status?: VideoClipStatus | null
          updatedAt: Date
          createdAt: Date
          fileId?: string | null
          error?: {
            __typename?: 'VideoClipError'
            guid: string
            workflowStatus: string
            workflowErrorAt: string
            errorMessage: string
            errorDetails: string
          } | null
          captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
          externalFeed?: {
            __typename?: 'ExternalFeed'
            externalFeedProvider: ExternalFeedType
            externalFeedUrl: string
          } | null
        } | null
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
        thumbnail?: {
          __typename?: 'Media'
          id: string
          image?: {
            __typename?: 'File'
            id: string
            filename: string
            size: number
            mimetype: string
            downloadUrl: string
            thumbUrl?: string | null
            createdAt: Date
            metadata?: {
              __typename?: 'FileMetadata'
              title?: string | null
              description?: string | null
              aspect?: number | null
              width?: number | null
              height?: number | null
              sourceFileId?: string | null
            } | null
          } | null
        } | null
        timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
      } | null
      bannerVideo?: {
        __typename?: 'Media'
        id: string
        videoClip?: { __typename?: 'VideoClip'; id: string; playbackUrl: string } | null
      } | null
      pageWidgets?: Array<{
        __typename?: 'CustomPageWidget'
        name: CustomPageWidgetName
        position: number
        display?: CustomPageDisplayTypes | null
        contentDisplay?: ContentSetContentDisplay | null
        cardsDisplay?: CardsDisplay | null
        options?: {
          __typename?: 'CustomPageWidgetOptions'
          editedCategoryId?: string | null
          sportItemSetType?: SportItemSetType | null
          replays?: {
            __typename?: 'ReplaysWidget'
            defaultLocale: string
            maxReplays?: number | null
            depthInDays?: number | null
            nameT: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }>
          } | null
          externalContent?: {
            __typename?: 'ExternalContentWidget'
            name?: string | null
            defaultLocale: string
            maxItemsCount: number
            source: WidgetExternalContentSource
            nameT: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }>
            period?: { __typename?: 'StartEndDates'; startDate: Date; endDate: Date } | null
            wecContent?: {
              __typename?: 'WECNewsContent'
              teamECMIds?: Array<string> | null
              driverECMIds?: Array<string> | null
              carECMIds?: Array<string> | null
              categoryECMIds?: Array<string> | null
              raceECMIds?: Array<string> | null
            } | null
            rssContent?: { __typename?: 'RSSFeedContent'; feedUrl: string } | null
          } | null
        } | null
      }> | null
      offers: Array<{
        __typename?: 'PurchasableOffer'
        id: string
        mode: OfferMode
        delay?: number | null
        expiredAt?: Date | null
        publicationStatus: PublicationStatus
        title: string
        defaultLocale?: string | null
        url?: string | null
        bannerImageId?: string | null
        thumbnailImageId?: string | null
        freeWithLicense: boolean
        position?: number | null
        titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
        description: Array<{ __typename?: 'OfferDescription'; header: string; content: string }>
        descriptionT?: Array<{
          __typename?: 'ContentTranslationDescriptionPurchasableOffer'
          locale: string
          content?: Array<{ __typename?: 'OfferDescription'; header: string; content: string }> | null
        }> | null
        products: Array<{
          __typename?: 'Product'
          productId: string
          platform: Platform
          period?: Period | null
          publicationStatus: PublicationStatus
          purchasableOfferId?: string | null
          licensedUserOnly?: boolean | null
          premiumOf?: Array<string> | null
          prices: Array<{ __typename?: 'Price'; value: number; currency: string }>
        }>
      }>
      license?: { __typename?: 'OrganismLicense'; enabled: boolean; api?: LicenseApi | null } | null
      tags?: Array<{ __typename?: 'Tag'; id: string; title: string; subTags?: Array<string> | null }> | null
    }> | null
    titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    subtitleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    descriptionT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    billboard?: {
      __typename?: 'Media'
      id: string
      videoClip?: { __typename?: 'VideoClip'; id: string; playbackUrl: string } | null
    } | null
    teaserVideo?: {
      __typename?: 'Media'
      id: string
      videoClip?: { __typename?: 'VideoClip'; id: string; playbackUrl: string } | null
    } | null
    image?: {
      __typename?: 'Media'
      id: string
      image?: { __typename?: 'File'; id: string; thumbUrl?: string | null; downloadUrl: string } | null
    } | null
    categories: Array<{ __typename?: 'Category'; id: string; title: string }>
  }
}

export type UpdateLinkMutationVariables = Exact<{
  input: UpdateLinkInput
}>

export type UpdateLinkMutation = {
  __typename?: 'Mutation'
  updateLink: {
    __typename: 'Link'
    id: string
    ownerOrganismIds: Array<string>
    tag?: LinkTag | null
    title: string
    subtitle?: string | null
    description?: string | null
    defaultLocale?: string | null
    clicks: number
    uniqueClicks: number
    views: number
    countries: Array<string | null>
    uniqueViews: number
    teaserVideoId?: string | null
    gamTagUrl?: string | null
    billboardId?: string | null
    url?: string | null
    imageId: string
    categoriesIds: Array<string>
    publicationStatus: PublicationStatus
    ownerOrganisms?: Array<{
      __typename?: 'Organism'
      id: string
      type: OrganismType
      whiteLabel: boolean
      title: string
      description?: string | null
      publicationStatus: PublicationStatus
      defaultLocale?: string | null
      onFront: boolean
      frontPosition?: number | null
      officialWebSiteUrl: string
      sportIds: Array<string>
      forbiddenKeyWords?: Array<string> | null
      enableExternalSources?: boolean | null
      availableExternalSources?: Array<WidgetExternalContentSource> | null
      streamsIds?: Array<string> | null
      wmProfileId?: string | null
      sveChannelIds?: Array<string> | null
      organismPageApplicationDesignId?: string | null
      contentPageApplicationDesignId?: string | null
      adminsAutomatedEmails?: boolean | null
      visibleInMultisportApplication?: boolean | null
      titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
      descriptionT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
      logo: {
        __typename?: 'Logo'
        coloredImage?: {
          __typename?: 'Media'
          id: string
          createdAt: Date
          updatedAt: Date
          title: string
          type: MediaType
          publicationStatus: PublicationStatus
          tags: Array<MediaTags>
          date?: Date | null
          startTrim?: number | null
          endTrim?: number | null
          liveStreamId?: string | null
          videoClipId?: string | null
          imageId?: string | null
          thumbnailId?: string | null
          ownerOrganismId?: string | null
          pinned?: boolean | null
          liveStream?: {
            __typename?: 'LiveStream'
            id: string
            playbackUrl: string
            ingestUrl?: string | null
            wildmokaEventID?: string | null
            videoEngine: VideoEngine
            state: LiveStreamState
            streamId?: string | null
            sveBroadcastId?: string | null
            sveChannelId?: string | null
            externalFeedProvider?: ExternalFeedType | null
            externalFeedUrl?: string | null
            event?: {
              __typename?: 'WMEvent'
              id: string
              name: string
              description?: string | null
              testStartDate: Date
              editoStartDate: Date
              editoStopDate: Date
              stopDate: Date
              state: WmEventState
              notifEmail?: string | null
              clipCreationAtEnd: boolean
              clipPublicationAtEnd: boolean
              livePublication: boolean
              stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
              live?: {
                __typename?: 'WMEventLive'
                id: string
                publishStatus: WmLiveState
                type: WmLiveType
                autoAnnounceDate?: Date | null
                template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
              } | null
              clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
            } | null
            sveBroadcast?: {
              __typename?: 'SVEBroadcast'
              id: string
              status: SveBroadcastState
              channelId: string
              startDate?: Date | null
              clipStartDate?: Date | null
              stopDate?: Date | null
              scheduledStartDate: Date
              scheduledClipStartDate: Date
              scheduledStopDate: Date
            } | null
            sveLiveMonitoring?: {
              __typename?: 'SVELiveMonitoring'
              alerts?: {
                __typename?: 'SVELiveMonitoringAlerts'
                audioNotDetected?: string | null
                initialProbeTakingLonger?: string | null
                videoNotDetected?: string | null
                RTMPHasNoAudioVideo?: string | null
                RTMPStreamNotFound?: string | null
              } | null
              stats?: {
                __typename?: 'SVELiveMonitoringStats'
                networkIn?: string | null
                droppedFrames?: string | null
                inputVideoFrameRate?: string | null
                outputAudioLevelLkfs?: string | null
              } | null
            } | null
          } | null
          videoClip?: {
            __typename?: 'VideoClip'
            id: string
            playbackUrl: string
            downloadLink: string
            status?: VideoClipStatus | null
            updatedAt: Date
            createdAt: Date
            fileId?: string | null
            error?: {
              __typename?: 'VideoClipError'
              guid: string
              workflowStatus: string
              workflowErrorAt: string
              errorMessage: string
              errorDetails: string
            } | null
            captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
            externalFeed?: {
              __typename?: 'ExternalFeed'
              externalFeedProvider: ExternalFeedType
              externalFeedUrl: string
            } | null
          } | null
          image?: {
            __typename?: 'File'
            id: string
            filename: string
            size: number
            mimetype: string
            downloadUrl: string
            thumbUrl?: string | null
            createdAt: Date
            metadata?: {
              __typename?: 'FileMetadata'
              title?: string | null
              description?: string | null
              aspect?: number | null
              width?: number | null
              height?: number | null
              sourceFileId?: string | null
            } | null
          } | null
          thumbnail?: {
            __typename?: 'Media'
            id: string
            image?: {
              __typename?: 'File'
              id: string
              filename: string
              size: number
              mimetype: string
              downloadUrl: string
              thumbUrl?: string | null
              createdAt: Date
              metadata?: {
                __typename?: 'FileMetadata'
                title?: string | null
                description?: string | null
                aspect?: number | null
                width?: number | null
                height?: number | null
                sourceFileId?: string | null
              } | null
            } | null
          } | null
          timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
        } | null
        transparentDarkImage?: {
          __typename?: 'Media'
          id: string
          createdAt: Date
          updatedAt: Date
          title: string
          type: MediaType
          publicationStatus: PublicationStatus
          tags: Array<MediaTags>
          date?: Date | null
          startTrim?: number | null
          endTrim?: number | null
          liveStreamId?: string | null
          videoClipId?: string | null
          imageId?: string | null
          thumbnailId?: string | null
          ownerOrganismId?: string | null
          pinned?: boolean | null
          liveStream?: {
            __typename?: 'LiveStream'
            id: string
            playbackUrl: string
            ingestUrl?: string | null
            wildmokaEventID?: string | null
            videoEngine: VideoEngine
            state: LiveStreamState
            streamId?: string | null
            sveBroadcastId?: string | null
            sveChannelId?: string | null
            externalFeedProvider?: ExternalFeedType | null
            externalFeedUrl?: string | null
            event?: {
              __typename?: 'WMEvent'
              id: string
              name: string
              description?: string | null
              testStartDate: Date
              editoStartDate: Date
              editoStopDate: Date
              stopDate: Date
              state: WmEventState
              notifEmail?: string | null
              clipCreationAtEnd: boolean
              clipPublicationAtEnd: boolean
              livePublication: boolean
              stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
              live?: {
                __typename?: 'WMEventLive'
                id: string
                publishStatus: WmLiveState
                type: WmLiveType
                autoAnnounceDate?: Date | null
                template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
              } | null
              clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
            } | null
            sveBroadcast?: {
              __typename?: 'SVEBroadcast'
              id: string
              status: SveBroadcastState
              channelId: string
              startDate?: Date | null
              clipStartDate?: Date | null
              stopDate?: Date | null
              scheduledStartDate: Date
              scheduledClipStartDate: Date
              scheduledStopDate: Date
            } | null
            sveLiveMonitoring?: {
              __typename?: 'SVELiveMonitoring'
              alerts?: {
                __typename?: 'SVELiveMonitoringAlerts'
                audioNotDetected?: string | null
                initialProbeTakingLonger?: string | null
                videoNotDetected?: string | null
                RTMPHasNoAudioVideo?: string | null
                RTMPStreamNotFound?: string | null
              } | null
              stats?: {
                __typename?: 'SVELiveMonitoringStats'
                networkIn?: string | null
                droppedFrames?: string | null
                inputVideoFrameRate?: string | null
                outputAudioLevelLkfs?: string | null
              } | null
            } | null
          } | null
          videoClip?: {
            __typename?: 'VideoClip'
            id: string
            playbackUrl: string
            downloadLink: string
            status?: VideoClipStatus | null
            updatedAt: Date
            createdAt: Date
            fileId?: string | null
            error?: {
              __typename?: 'VideoClipError'
              guid: string
              workflowStatus: string
              workflowErrorAt: string
              errorMessage: string
              errorDetails: string
            } | null
            captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
            externalFeed?: {
              __typename?: 'ExternalFeed'
              externalFeedProvider: ExternalFeedType
              externalFeedUrl: string
            } | null
          } | null
          image?: {
            __typename?: 'File'
            id: string
            filename: string
            size: number
            mimetype: string
            downloadUrl: string
            thumbUrl?: string | null
            createdAt: Date
            metadata?: {
              __typename?: 'FileMetadata'
              title?: string | null
              description?: string | null
              aspect?: number | null
              width?: number | null
              height?: number | null
              sourceFileId?: string | null
            } | null
          } | null
          thumbnail?: {
            __typename?: 'Media'
            id: string
            image?: {
              __typename?: 'File'
              id: string
              filename: string
              size: number
              mimetype: string
              downloadUrl: string
              thumbUrl?: string | null
              createdAt: Date
              metadata?: {
                __typename?: 'FileMetadata'
                title?: string | null
                description?: string | null
                aspect?: number | null
                width?: number | null
                height?: number | null
                sourceFileId?: string | null
              } | null
            } | null
          } | null
          timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
        } | null
        transparentLightImage?: {
          __typename?: 'Media'
          id: string
          createdAt: Date
          updatedAt: Date
          title: string
          type: MediaType
          publicationStatus: PublicationStatus
          tags: Array<MediaTags>
          date?: Date | null
          startTrim?: number | null
          endTrim?: number | null
          liveStreamId?: string | null
          videoClipId?: string | null
          imageId?: string | null
          thumbnailId?: string | null
          ownerOrganismId?: string | null
          pinned?: boolean | null
          liveStream?: {
            __typename?: 'LiveStream'
            id: string
            playbackUrl: string
            ingestUrl?: string | null
            wildmokaEventID?: string | null
            videoEngine: VideoEngine
            state: LiveStreamState
            streamId?: string | null
            sveBroadcastId?: string | null
            sveChannelId?: string | null
            externalFeedProvider?: ExternalFeedType | null
            externalFeedUrl?: string | null
            event?: {
              __typename?: 'WMEvent'
              id: string
              name: string
              description?: string | null
              testStartDate: Date
              editoStartDate: Date
              editoStopDate: Date
              stopDate: Date
              state: WmEventState
              notifEmail?: string | null
              clipCreationAtEnd: boolean
              clipPublicationAtEnd: boolean
              livePublication: boolean
              stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
              live?: {
                __typename?: 'WMEventLive'
                id: string
                publishStatus: WmLiveState
                type: WmLiveType
                autoAnnounceDate?: Date | null
                template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
              } | null
              clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
            } | null
            sveBroadcast?: {
              __typename?: 'SVEBroadcast'
              id: string
              status: SveBroadcastState
              channelId: string
              startDate?: Date | null
              clipStartDate?: Date | null
              stopDate?: Date | null
              scheduledStartDate: Date
              scheduledClipStartDate: Date
              scheduledStopDate: Date
            } | null
            sveLiveMonitoring?: {
              __typename?: 'SVELiveMonitoring'
              alerts?: {
                __typename?: 'SVELiveMonitoringAlerts'
                audioNotDetected?: string | null
                initialProbeTakingLonger?: string | null
                videoNotDetected?: string | null
                RTMPHasNoAudioVideo?: string | null
                RTMPStreamNotFound?: string | null
              } | null
              stats?: {
                __typename?: 'SVELiveMonitoringStats'
                networkIn?: string | null
                droppedFrames?: string | null
                inputVideoFrameRate?: string | null
                outputAudioLevelLkfs?: string | null
              } | null
            } | null
          } | null
          videoClip?: {
            __typename?: 'VideoClip'
            id: string
            playbackUrl: string
            downloadLink: string
            status?: VideoClipStatus | null
            updatedAt: Date
            createdAt: Date
            fileId?: string | null
            error?: {
              __typename?: 'VideoClipError'
              guid: string
              workflowStatus: string
              workflowErrorAt: string
              errorMessage: string
              errorDetails: string
            } | null
            captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
            externalFeed?: {
              __typename?: 'ExternalFeed'
              externalFeedProvider: ExternalFeedType
              externalFeedUrl: string
            } | null
          } | null
          image?: {
            __typename?: 'File'
            id: string
            filename: string
            size: number
            mimetype: string
            downloadUrl: string
            thumbUrl?: string | null
            createdAt: Date
            metadata?: {
              __typename?: 'FileMetadata'
              title?: string | null
              description?: string | null
              aspect?: number | null
              width?: number | null
              height?: number | null
              sourceFileId?: string | null
            } | null
          } | null
          thumbnail?: {
            __typename?: 'Media'
            id: string
            image?: {
              __typename?: 'File'
              id: string
              filename: string
              size: number
              mimetype: string
              downloadUrl: string
              thumbUrl?: string | null
              createdAt: Date
              metadata?: {
                __typename?: 'FileMetadata'
                title?: string | null
                description?: string | null
                aspect?: number | null
                width?: number | null
                height?: number | null
                sourceFileId?: string | null
              } | null
            } | null
          } | null
          timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
        } | null
      }
      sports: Array<{ __typename?: 'Sport'; id: string }>
      bannerImage?: {
        __typename?: 'Media'
        id: string
        createdAt: Date
        updatedAt: Date
        title: string
        type: MediaType
        publicationStatus: PublicationStatus
        tags: Array<MediaTags>
        date?: Date | null
        startTrim?: number | null
        endTrim?: number | null
        liveStreamId?: string | null
        videoClipId?: string | null
        imageId?: string | null
        thumbnailId?: string | null
        ownerOrganismId?: string | null
        pinned?: boolean | null
        liveStream?: {
          __typename?: 'LiveStream'
          id: string
          playbackUrl: string
          ingestUrl?: string | null
          wildmokaEventID?: string | null
          videoEngine: VideoEngine
          state: LiveStreamState
          streamId?: string | null
          sveBroadcastId?: string | null
          sveChannelId?: string | null
          externalFeedProvider?: ExternalFeedType | null
          externalFeedUrl?: string | null
          event?: {
            __typename?: 'WMEvent'
            id: string
            name: string
            description?: string | null
            testStartDate: Date
            editoStartDate: Date
            editoStopDate: Date
            stopDate: Date
            state: WmEventState
            notifEmail?: string | null
            clipCreationAtEnd: boolean
            clipPublicationAtEnd: boolean
            livePublication: boolean
            stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
            live?: {
              __typename?: 'WMEventLive'
              id: string
              publishStatus: WmLiveState
              type: WmLiveType
              autoAnnounceDate?: Date | null
              template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
            } | null
            clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
          } | null
          sveBroadcast?: {
            __typename?: 'SVEBroadcast'
            id: string
            status: SveBroadcastState
            channelId: string
            startDate?: Date | null
            clipStartDate?: Date | null
            stopDate?: Date | null
            scheduledStartDate: Date
            scheduledClipStartDate: Date
            scheduledStopDate: Date
          } | null
          sveLiveMonitoring?: {
            __typename?: 'SVELiveMonitoring'
            alerts?: {
              __typename?: 'SVELiveMonitoringAlerts'
              audioNotDetected?: string | null
              initialProbeTakingLonger?: string | null
              videoNotDetected?: string | null
              RTMPHasNoAudioVideo?: string | null
              RTMPStreamNotFound?: string | null
            } | null
            stats?: {
              __typename?: 'SVELiveMonitoringStats'
              networkIn?: string | null
              droppedFrames?: string | null
              inputVideoFrameRate?: string | null
              outputAudioLevelLkfs?: string | null
            } | null
          } | null
        } | null
        videoClip?: {
          __typename?: 'VideoClip'
          id: string
          playbackUrl: string
          downloadLink: string
          status?: VideoClipStatus | null
          updatedAt: Date
          createdAt: Date
          fileId?: string | null
          error?: {
            __typename?: 'VideoClipError'
            guid: string
            workflowStatus: string
            workflowErrorAt: string
            errorMessage: string
            errorDetails: string
          } | null
          captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
          externalFeed?: {
            __typename?: 'ExternalFeed'
            externalFeedProvider: ExternalFeedType
            externalFeedUrl: string
          } | null
        } | null
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
        thumbnail?: {
          __typename?: 'Media'
          id: string
          image?: {
            __typename?: 'File'
            id: string
            filename: string
            size: number
            mimetype: string
            downloadUrl: string
            thumbUrl?: string | null
            createdAt: Date
            metadata?: {
              __typename?: 'FileMetadata'
              title?: string | null
              description?: string | null
              aspect?: number | null
              width?: number | null
              height?: number | null
              sourceFileId?: string | null
            } | null
          } | null
        } | null
        timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
      } | null
      adminBadge?: {
        __typename?: 'Media'
        id: string
        createdAt: Date
        updatedAt: Date
        title: string
        type: MediaType
        publicationStatus: PublicationStatus
        tags: Array<MediaTags>
        date?: Date | null
        startTrim?: number | null
        endTrim?: number | null
        liveStreamId?: string | null
        videoClipId?: string | null
        imageId?: string | null
        thumbnailId?: string | null
        ownerOrganismId?: string | null
        pinned?: boolean | null
        liveStream?: {
          __typename?: 'LiveStream'
          id: string
          playbackUrl: string
          ingestUrl?: string | null
          wildmokaEventID?: string | null
          videoEngine: VideoEngine
          state: LiveStreamState
          streamId?: string | null
          sveBroadcastId?: string | null
          sveChannelId?: string | null
          externalFeedProvider?: ExternalFeedType | null
          externalFeedUrl?: string | null
          event?: {
            __typename?: 'WMEvent'
            id: string
            name: string
            description?: string | null
            testStartDate: Date
            editoStartDate: Date
            editoStopDate: Date
            stopDate: Date
            state: WmEventState
            notifEmail?: string | null
            clipCreationAtEnd: boolean
            clipPublicationAtEnd: boolean
            livePublication: boolean
            stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
            live?: {
              __typename?: 'WMEventLive'
              id: string
              publishStatus: WmLiveState
              type: WmLiveType
              autoAnnounceDate?: Date | null
              template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
            } | null
            clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
          } | null
          sveBroadcast?: {
            __typename?: 'SVEBroadcast'
            id: string
            status: SveBroadcastState
            channelId: string
            startDate?: Date | null
            clipStartDate?: Date | null
            stopDate?: Date | null
            scheduledStartDate: Date
            scheduledClipStartDate: Date
            scheduledStopDate: Date
          } | null
          sveLiveMonitoring?: {
            __typename?: 'SVELiveMonitoring'
            alerts?: {
              __typename?: 'SVELiveMonitoringAlerts'
              audioNotDetected?: string | null
              initialProbeTakingLonger?: string | null
              videoNotDetected?: string | null
              RTMPHasNoAudioVideo?: string | null
              RTMPStreamNotFound?: string | null
            } | null
            stats?: {
              __typename?: 'SVELiveMonitoringStats'
              networkIn?: string | null
              droppedFrames?: string | null
              inputVideoFrameRate?: string | null
              outputAudioLevelLkfs?: string | null
            } | null
          } | null
        } | null
        videoClip?: {
          __typename?: 'VideoClip'
          id: string
          playbackUrl: string
          downloadLink: string
          status?: VideoClipStatus | null
          updatedAt: Date
          createdAt: Date
          fileId?: string | null
          error?: {
            __typename?: 'VideoClipError'
            guid: string
            workflowStatus: string
            workflowErrorAt: string
            errorMessage: string
            errorDetails: string
          } | null
          captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
          externalFeed?: {
            __typename?: 'ExternalFeed'
            externalFeedProvider: ExternalFeedType
            externalFeedUrl: string
          } | null
        } | null
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
        thumbnail?: {
          __typename?: 'Media'
          id: string
          image?: {
            __typename?: 'File'
            id: string
            filename: string
            size: number
            mimetype: string
            downloadUrl: string
            thumbUrl?: string | null
            createdAt: Date
            metadata?: {
              __typename?: 'FileMetadata'
              title?: string | null
              description?: string | null
              aspect?: number | null
              width?: number | null
              height?: number | null
              sourceFileId?: string | null
            } | null
          } | null
        } | null
        timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
      } | null
      bannerVideo?: {
        __typename?: 'Media'
        id: string
        videoClip?: { __typename?: 'VideoClip'; id: string; playbackUrl: string } | null
      } | null
      pageWidgets?: Array<{
        __typename?: 'CustomPageWidget'
        name: CustomPageWidgetName
        position: number
        display?: CustomPageDisplayTypes | null
        contentDisplay?: ContentSetContentDisplay | null
        cardsDisplay?: CardsDisplay | null
        options?: {
          __typename?: 'CustomPageWidgetOptions'
          editedCategoryId?: string | null
          sportItemSetType?: SportItemSetType | null
          replays?: {
            __typename?: 'ReplaysWidget'
            defaultLocale: string
            maxReplays?: number | null
            depthInDays?: number | null
            nameT: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }>
          } | null
          externalContent?: {
            __typename?: 'ExternalContentWidget'
            name?: string | null
            defaultLocale: string
            maxItemsCount: number
            source: WidgetExternalContentSource
            nameT: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }>
            period?: { __typename?: 'StartEndDates'; startDate: Date; endDate: Date } | null
            wecContent?: {
              __typename?: 'WECNewsContent'
              teamECMIds?: Array<string> | null
              driverECMIds?: Array<string> | null
              carECMIds?: Array<string> | null
              categoryECMIds?: Array<string> | null
              raceECMIds?: Array<string> | null
            } | null
            rssContent?: { __typename?: 'RSSFeedContent'; feedUrl: string } | null
          } | null
        } | null
      }> | null
      offers: Array<{
        __typename?: 'PurchasableOffer'
        id: string
        mode: OfferMode
        delay?: number | null
        expiredAt?: Date | null
        publicationStatus: PublicationStatus
        title: string
        defaultLocale?: string | null
        url?: string | null
        bannerImageId?: string | null
        thumbnailImageId?: string | null
        freeWithLicense: boolean
        position?: number | null
        titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
        description: Array<{ __typename?: 'OfferDescription'; header: string; content: string }>
        descriptionT?: Array<{
          __typename?: 'ContentTranslationDescriptionPurchasableOffer'
          locale: string
          content?: Array<{ __typename?: 'OfferDescription'; header: string; content: string }> | null
        }> | null
        products: Array<{
          __typename?: 'Product'
          productId: string
          platform: Platform
          period?: Period | null
          publicationStatus: PublicationStatus
          purchasableOfferId?: string | null
          licensedUserOnly?: boolean | null
          premiumOf?: Array<string> | null
          prices: Array<{ __typename?: 'Price'; value: number; currency: string }>
        }>
      }>
      license?: { __typename?: 'OrganismLicense'; enabled: boolean; api?: LicenseApi | null } | null
      tags?: Array<{ __typename?: 'Tag'; id: string; title: string; subTags?: Array<string> | null }> | null
    }> | null
    titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    subtitleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    descriptionT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    billboard?: {
      __typename?: 'Media'
      id: string
      videoClip?: { __typename?: 'VideoClip'; id: string; playbackUrl: string } | null
    } | null
    teaserVideo?: {
      __typename?: 'Media'
      id: string
      videoClip?: { __typename?: 'VideoClip'; id: string; playbackUrl: string } | null
    } | null
    image?: {
      __typename?: 'Media'
      id: string
      image?: { __typename?: 'File'; id: string; thumbUrl?: string | null; downloadUrl: string } | null
    } | null
    categories: Array<{ __typename?: 'Category'; id: string; title: string }>
  }
}

export type DeleteLinkMutationVariables = Exact<{
  linkId: Scalars['ID']
}>

export type DeleteLinkMutation = { __typename?: 'Mutation'; deleteLink?: { __typename?: 'Link'; id: string } | null }

export type GetLinksQueryVariables = Exact<{
  filters?: InputMaybe<LinkFilters>
  offset?: InputMaybe<Scalars['Int']>
  first?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<LinkSort>
}>

export type GetLinksQuery = {
  __typename?: 'Query'
  links?: {
    __typename?: 'LinkPageResult'
    totalCount?: number | null
    links: Array<{
      __typename: 'Link'
      id: string
      ownerOrganismIds: Array<string>
      tag?: LinkTag | null
      title: string
      subtitle?: string | null
      description?: string | null
      defaultLocale?: string | null
      clicks: number
      uniqueClicks: number
      views: number
      countries: Array<string | null>
      uniqueViews: number
      teaserVideoId?: string | null
      gamTagUrl?: string | null
      billboardId?: string | null
      url?: string | null
      imageId: string
      categoriesIds: Array<string>
      publicationStatus: PublicationStatus
      ownerOrganisms?: Array<{
        __typename?: 'Organism'
        id: string
        type: OrganismType
        whiteLabel: boolean
        title: string
        description?: string | null
        publicationStatus: PublicationStatus
        defaultLocale?: string | null
        onFront: boolean
        frontPosition?: number | null
        officialWebSiteUrl: string
        sportIds: Array<string>
        forbiddenKeyWords?: Array<string> | null
        enableExternalSources?: boolean | null
        availableExternalSources?: Array<WidgetExternalContentSource> | null
        streamsIds?: Array<string> | null
        wmProfileId?: string | null
        sveChannelIds?: Array<string> | null
        organismPageApplicationDesignId?: string | null
        contentPageApplicationDesignId?: string | null
        adminsAutomatedEmails?: boolean | null
        visibleInMultisportApplication?: boolean | null
        titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
        descriptionT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
        logo: {
          __typename?: 'Logo'
          coloredImage?: {
            __typename?: 'Media'
            id: string
            createdAt: Date
            updatedAt: Date
            title: string
            type: MediaType
            publicationStatus: PublicationStatus
            tags: Array<MediaTags>
            date?: Date | null
            startTrim?: number | null
            endTrim?: number | null
            liveStreamId?: string | null
            videoClipId?: string | null
            imageId?: string | null
            thumbnailId?: string | null
            ownerOrganismId?: string | null
            pinned?: boolean | null
            liveStream?: {
              __typename?: 'LiveStream'
              id: string
              playbackUrl: string
              ingestUrl?: string | null
              wildmokaEventID?: string | null
              videoEngine: VideoEngine
              state: LiveStreamState
              streamId?: string | null
              sveBroadcastId?: string | null
              sveChannelId?: string | null
              externalFeedProvider?: ExternalFeedType | null
              externalFeedUrl?: string | null
              event?: {
                __typename?: 'WMEvent'
                id: string
                name: string
                description?: string | null
                testStartDate: Date
                editoStartDate: Date
                editoStopDate: Date
                stopDate: Date
                state: WmEventState
                notifEmail?: string | null
                clipCreationAtEnd: boolean
                clipPublicationAtEnd: boolean
                livePublication: boolean
                stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
                live?: {
                  __typename?: 'WMEventLive'
                  id: string
                  publishStatus: WmLiveState
                  type: WmLiveType
                  autoAnnounceDate?: Date | null
                  template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
                } | null
                clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
              } | null
              sveBroadcast?: {
                __typename?: 'SVEBroadcast'
                id: string
                status: SveBroadcastState
                channelId: string
                startDate?: Date | null
                clipStartDate?: Date | null
                stopDate?: Date | null
                scheduledStartDate: Date
                scheduledClipStartDate: Date
                scheduledStopDate: Date
              } | null
              sveLiveMonitoring?: {
                __typename?: 'SVELiveMonitoring'
                alerts?: {
                  __typename?: 'SVELiveMonitoringAlerts'
                  audioNotDetected?: string | null
                  initialProbeTakingLonger?: string | null
                  videoNotDetected?: string | null
                  RTMPHasNoAudioVideo?: string | null
                  RTMPStreamNotFound?: string | null
                } | null
                stats?: {
                  __typename?: 'SVELiveMonitoringStats'
                  networkIn?: string | null
                  droppedFrames?: string | null
                  inputVideoFrameRate?: string | null
                  outputAudioLevelLkfs?: string | null
                } | null
              } | null
            } | null
            videoClip?: {
              __typename?: 'VideoClip'
              id: string
              playbackUrl: string
              downloadLink: string
              status?: VideoClipStatus | null
              updatedAt: Date
              createdAt: Date
              fileId?: string | null
              error?: {
                __typename?: 'VideoClipError'
                guid: string
                workflowStatus: string
                workflowErrorAt: string
                errorMessage: string
                errorDetails: string
              } | null
              captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
              externalFeed?: {
                __typename?: 'ExternalFeed'
                externalFeedProvider: ExternalFeedType
                externalFeedUrl: string
              } | null
            } | null
            image?: {
              __typename?: 'File'
              id: string
              filename: string
              size: number
              mimetype: string
              downloadUrl: string
              thumbUrl?: string | null
              createdAt: Date
              metadata?: {
                __typename?: 'FileMetadata'
                title?: string | null
                description?: string | null
                aspect?: number | null
                width?: number | null
                height?: number | null
                sourceFileId?: string | null
              } | null
            } | null
            thumbnail?: {
              __typename?: 'Media'
              id: string
              image?: {
                __typename?: 'File'
                id: string
                filename: string
                size: number
                mimetype: string
                downloadUrl: string
                thumbUrl?: string | null
                createdAt: Date
                metadata?: {
                  __typename?: 'FileMetadata'
                  title?: string | null
                  description?: string | null
                  aspect?: number | null
                  width?: number | null
                  height?: number | null
                  sourceFileId?: string | null
                } | null
              } | null
            } | null
            timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
          } | null
          transparentDarkImage?: {
            __typename?: 'Media'
            id: string
            createdAt: Date
            updatedAt: Date
            title: string
            type: MediaType
            publicationStatus: PublicationStatus
            tags: Array<MediaTags>
            date?: Date | null
            startTrim?: number | null
            endTrim?: number | null
            liveStreamId?: string | null
            videoClipId?: string | null
            imageId?: string | null
            thumbnailId?: string | null
            ownerOrganismId?: string | null
            pinned?: boolean | null
            liveStream?: {
              __typename?: 'LiveStream'
              id: string
              playbackUrl: string
              ingestUrl?: string | null
              wildmokaEventID?: string | null
              videoEngine: VideoEngine
              state: LiveStreamState
              streamId?: string | null
              sveBroadcastId?: string | null
              sveChannelId?: string | null
              externalFeedProvider?: ExternalFeedType | null
              externalFeedUrl?: string | null
              event?: {
                __typename?: 'WMEvent'
                id: string
                name: string
                description?: string | null
                testStartDate: Date
                editoStartDate: Date
                editoStopDate: Date
                stopDate: Date
                state: WmEventState
                notifEmail?: string | null
                clipCreationAtEnd: boolean
                clipPublicationAtEnd: boolean
                livePublication: boolean
                stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
                live?: {
                  __typename?: 'WMEventLive'
                  id: string
                  publishStatus: WmLiveState
                  type: WmLiveType
                  autoAnnounceDate?: Date | null
                  template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
                } | null
                clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
              } | null
              sveBroadcast?: {
                __typename?: 'SVEBroadcast'
                id: string
                status: SveBroadcastState
                channelId: string
                startDate?: Date | null
                clipStartDate?: Date | null
                stopDate?: Date | null
                scheduledStartDate: Date
                scheduledClipStartDate: Date
                scheduledStopDate: Date
              } | null
              sveLiveMonitoring?: {
                __typename?: 'SVELiveMonitoring'
                alerts?: {
                  __typename?: 'SVELiveMonitoringAlerts'
                  audioNotDetected?: string | null
                  initialProbeTakingLonger?: string | null
                  videoNotDetected?: string | null
                  RTMPHasNoAudioVideo?: string | null
                  RTMPStreamNotFound?: string | null
                } | null
                stats?: {
                  __typename?: 'SVELiveMonitoringStats'
                  networkIn?: string | null
                  droppedFrames?: string | null
                  inputVideoFrameRate?: string | null
                  outputAudioLevelLkfs?: string | null
                } | null
              } | null
            } | null
            videoClip?: {
              __typename?: 'VideoClip'
              id: string
              playbackUrl: string
              downloadLink: string
              status?: VideoClipStatus | null
              updatedAt: Date
              createdAt: Date
              fileId?: string | null
              error?: {
                __typename?: 'VideoClipError'
                guid: string
                workflowStatus: string
                workflowErrorAt: string
                errorMessage: string
                errorDetails: string
              } | null
              captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
              externalFeed?: {
                __typename?: 'ExternalFeed'
                externalFeedProvider: ExternalFeedType
                externalFeedUrl: string
              } | null
            } | null
            image?: {
              __typename?: 'File'
              id: string
              filename: string
              size: number
              mimetype: string
              downloadUrl: string
              thumbUrl?: string | null
              createdAt: Date
              metadata?: {
                __typename?: 'FileMetadata'
                title?: string | null
                description?: string | null
                aspect?: number | null
                width?: number | null
                height?: number | null
                sourceFileId?: string | null
              } | null
            } | null
            thumbnail?: {
              __typename?: 'Media'
              id: string
              image?: {
                __typename?: 'File'
                id: string
                filename: string
                size: number
                mimetype: string
                downloadUrl: string
                thumbUrl?: string | null
                createdAt: Date
                metadata?: {
                  __typename?: 'FileMetadata'
                  title?: string | null
                  description?: string | null
                  aspect?: number | null
                  width?: number | null
                  height?: number | null
                  sourceFileId?: string | null
                } | null
              } | null
            } | null
            timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
          } | null
          transparentLightImage?: {
            __typename?: 'Media'
            id: string
            createdAt: Date
            updatedAt: Date
            title: string
            type: MediaType
            publicationStatus: PublicationStatus
            tags: Array<MediaTags>
            date?: Date | null
            startTrim?: number | null
            endTrim?: number | null
            liveStreamId?: string | null
            videoClipId?: string | null
            imageId?: string | null
            thumbnailId?: string | null
            ownerOrganismId?: string | null
            pinned?: boolean | null
            liveStream?: {
              __typename?: 'LiveStream'
              id: string
              playbackUrl: string
              ingestUrl?: string | null
              wildmokaEventID?: string | null
              videoEngine: VideoEngine
              state: LiveStreamState
              streamId?: string | null
              sveBroadcastId?: string | null
              sveChannelId?: string | null
              externalFeedProvider?: ExternalFeedType | null
              externalFeedUrl?: string | null
              event?: {
                __typename?: 'WMEvent'
                id: string
                name: string
                description?: string | null
                testStartDate: Date
                editoStartDate: Date
                editoStopDate: Date
                stopDate: Date
                state: WmEventState
                notifEmail?: string | null
                clipCreationAtEnd: boolean
                clipPublicationAtEnd: boolean
                livePublication: boolean
                stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
                live?: {
                  __typename?: 'WMEventLive'
                  id: string
                  publishStatus: WmLiveState
                  type: WmLiveType
                  autoAnnounceDate?: Date | null
                  template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
                } | null
                clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
              } | null
              sveBroadcast?: {
                __typename?: 'SVEBroadcast'
                id: string
                status: SveBroadcastState
                channelId: string
                startDate?: Date | null
                clipStartDate?: Date | null
                stopDate?: Date | null
                scheduledStartDate: Date
                scheduledClipStartDate: Date
                scheduledStopDate: Date
              } | null
              sveLiveMonitoring?: {
                __typename?: 'SVELiveMonitoring'
                alerts?: {
                  __typename?: 'SVELiveMonitoringAlerts'
                  audioNotDetected?: string | null
                  initialProbeTakingLonger?: string | null
                  videoNotDetected?: string | null
                  RTMPHasNoAudioVideo?: string | null
                  RTMPStreamNotFound?: string | null
                } | null
                stats?: {
                  __typename?: 'SVELiveMonitoringStats'
                  networkIn?: string | null
                  droppedFrames?: string | null
                  inputVideoFrameRate?: string | null
                  outputAudioLevelLkfs?: string | null
                } | null
              } | null
            } | null
            videoClip?: {
              __typename?: 'VideoClip'
              id: string
              playbackUrl: string
              downloadLink: string
              status?: VideoClipStatus | null
              updatedAt: Date
              createdAt: Date
              fileId?: string | null
              error?: {
                __typename?: 'VideoClipError'
                guid: string
                workflowStatus: string
                workflowErrorAt: string
                errorMessage: string
                errorDetails: string
              } | null
              captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
              externalFeed?: {
                __typename?: 'ExternalFeed'
                externalFeedProvider: ExternalFeedType
                externalFeedUrl: string
              } | null
            } | null
            image?: {
              __typename?: 'File'
              id: string
              filename: string
              size: number
              mimetype: string
              downloadUrl: string
              thumbUrl?: string | null
              createdAt: Date
              metadata?: {
                __typename?: 'FileMetadata'
                title?: string | null
                description?: string | null
                aspect?: number | null
                width?: number | null
                height?: number | null
                sourceFileId?: string | null
              } | null
            } | null
            thumbnail?: {
              __typename?: 'Media'
              id: string
              image?: {
                __typename?: 'File'
                id: string
                filename: string
                size: number
                mimetype: string
                downloadUrl: string
                thumbUrl?: string | null
                createdAt: Date
                metadata?: {
                  __typename?: 'FileMetadata'
                  title?: string | null
                  description?: string | null
                  aspect?: number | null
                  width?: number | null
                  height?: number | null
                  sourceFileId?: string | null
                } | null
              } | null
            } | null
            timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
          } | null
        }
        sports: Array<{ __typename?: 'Sport'; id: string }>
        bannerImage?: {
          __typename?: 'Media'
          id: string
          createdAt: Date
          updatedAt: Date
          title: string
          type: MediaType
          publicationStatus: PublicationStatus
          tags: Array<MediaTags>
          date?: Date | null
          startTrim?: number | null
          endTrim?: number | null
          liveStreamId?: string | null
          videoClipId?: string | null
          imageId?: string | null
          thumbnailId?: string | null
          ownerOrganismId?: string | null
          pinned?: boolean | null
          liveStream?: {
            __typename?: 'LiveStream'
            id: string
            playbackUrl: string
            ingestUrl?: string | null
            wildmokaEventID?: string | null
            videoEngine: VideoEngine
            state: LiveStreamState
            streamId?: string | null
            sveBroadcastId?: string | null
            sveChannelId?: string | null
            externalFeedProvider?: ExternalFeedType | null
            externalFeedUrl?: string | null
            event?: {
              __typename?: 'WMEvent'
              id: string
              name: string
              description?: string | null
              testStartDate: Date
              editoStartDate: Date
              editoStopDate: Date
              stopDate: Date
              state: WmEventState
              notifEmail?: string | null
              clipCreationAtEnd: boolean
              clipPublicationAtEnd: boolean
              livePublication: boolean
              stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
              live?: {
                __typename?: 'WMEventLive'
                id: string
                publishStatus: WmLiveState
                type: WmLiveType
                autoAnnounceDate?: Date | null
                template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
              } | null
              clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
            } | null
            sveBroadcast?: {
              __typename?: 'SVEBroadcast'
              id: string
              status: SveBroadcastState
              channelId: string
              startDate?: Date | null
              clipStartDate?: Date | null
              stopDate?: Date | null
              scheduledStartDate: Date
              scheduledClipStartDate: Date
              scheduledStopDate: Date
            } | null
            sveLiveMonitoring?: {
              __typename?: 'SVELiveMonitoring'
              alerts?: {
                __typename?: 'SVELiveMonitoringAlerts'
                audioNotDetected?: string | null
                initialProbeTakingLonger?: string | null
                videoNotDetected?: string | null
                RTMPHasNoAudioVideo?: string | null
                RTMPStreamNotFound?: string | null
              } | null
              stats?: {
                __typename?: 'SVELiveMonitoringStats'
                networkIn?: string | null
                droppedFrames?: string | null
                inputVideoFrameRate?: string | null
                outputAudioLevelLkfs?: string | null
              } | null
            } | null
          } | null
          videoClip?: {
            __typename?: 'VideoClip'
            id: string
            playbackUrl: string
            downloadLink: string
            status?: VideoClipStatus | null
            updatedAt: Date
            createdAt: Date
            fileId?: string | null
            error?: {
              __typename?: 'VideoClipError'
              guid: string
              workflowStatus: string
              workflowErrorAt: string
              errorMessage: string
              errorDetails: string
            } | null
            captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
            externalFeed?: {
              __typename?: 'ExternalFeed'
              externalFeedProvider: ExternalFeedType
              externalFeedUrl: string
            } | null
          } | null
          image?: {
            __typename?: 'File'
            id: string
            filename: string
            size: number
            mimetype: string
            downloadUrl: string
            thumbUrl?: string | null
            createdAt: Date
            metadata?: {
              __typename?: 'FileMetadata'
              title?: string | null
              description?: string | null
              aspect?: number | null
              width?: number | null
              height?: number | null
              sourceFileId?: string | null
            } | null
          } | null
          thumbnail?: {
            __typename?: 'Media'
            id: string
            image?: {
              __typename?: 'File'
              id: string
              filename: string
              size: number
              mimetype: string
              downloadUrl: string
              thumbUrl?: string | null
              createdAt: Date
              metadata?: {
                __typename?: 'FileMetadata'
                title?: string | null
                description?: string | null
                aspect?: number | null
                width?: number | null
                height?: number | null
                sourceFileId?: string | null
              } | null
            } | null
          } | null
          timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
        } | null
        adminBadge?: {
          __typename?: 'Media'
          id: string
          createdAt: Date
          updatedAt: Date
          title: string
          type: MediaType
          publicationStatus: PublicationStatus
          tags: Array<MediaTags>
          date?: Date | null
          startTrim?: number | null
          endTrim?: number | null
          liveStreamId?: string | null
          videoClipId?: string | null
          imageId?: string | null
          thumbnailId?: string | null
          ownerOrganismId?: string | null
          pinned?: boolean | null
          liveStream?: {
            __typename?: 'LiveStream'
            id: string
            playbackUrl: string
            ingestUrl?: string | null
            wildmokaEventID?: string | null
            videoEngine: VideoEngine
            state: LiveStreamState
            streamId?: string | null
            sveBroadcastId?: string | null
            sveChannelId?: string | null
            externalFeedProvider?: ExternalFeedType | null
            externalFeedUrl?: string | null
            event?: {
              __typename?: 'WMEvent'
              id: string
              name: string
              description?: string | null
              testStartDate: Date
              editoStartDate: Date
              editoStopDate: Date
              stopDate: Date
              state: WmEventState
              notifEmail?: string | null
              clipCreationAtEnd: boolean
              clipPublicationAtEnd: boolean
              livePublication: boolean
              stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
              live?: {
                __typename?: 'WMEventLive'
                id: string
                publishStatus: WmLiveState
                type: WmLiveType
                autoAnnounceDate?: Date | null
                template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
              } | null
              clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
            } | null
            sveBroadcast?: {
              __typename?: 'SVEBroadcast'
              id: string
              status: SveBroadcastState
              channelId: string
              startDate?: Date | null
              clipStartDate?: Date | null
              stopDate?: Date | null
              scheduledStartDate: Date
              scheduledClipStartDate: Date
              scheduledStopDate: Date
            } | null
            sveLiveMonitoring?: {
              __typename?: 'SVELiveMonitoring'
              alerts?: {
                __typename?: 'SVELiveMonitoringAlerts'
                audioNotDetected?: string | null
                initialProbeTakingLonger?: string | null
                videoNotDetected?: string | null
                RTMPHasNoAudioVideo?: string | null
                RTMPStreamNotFound?: string | null
              } | null
              stats?: {
                __typename?: 'SVELiveMonitoringStats'
                networkIn?: string | null
                droppedFrames?: string | null
                inputVideoFrameRate?: string | null
                outputAudioLevelLkfs?: string | null
              } | null
            } | null
          } | null
          videoClip?: {
            __typename?: 'VideoClip'
            id: string
            playbackUrl: string
            downloadLink: string
            status?: VideoClipStatus | null
            updatedAt: Date
            createdAt: Date
            fileId?: string | null
            error?: {
              __typename?: 'VideoClipError'
              guid: string
              workflowStatus: string
              workflowErrorAt: string
              errorMessage: string
              errorDetails: string
            } | null
            captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
            externalFeed?: {
              __typename?: 'ExternalFeed'
              externalFeedProvider: ExternalFeedType
              externalFeedUrl: string
            } | null
          } | null
          image?: {
            __typename?: 'File'
            id: string
            filename: string
            size: number
            mimetype: string
            downloadUrl: string
            thumbUrl?: string | null
            createdAt: Date
            metadata?: {
              __typename?: 'FileMetadata'
              title?: string | null
              description?: string | null
              aspect?: number | null
              width?: number | null
              height?: number | null
              sourceFileId?: string | null
            } | null
          } | null
          thumbnail?: {
            __typename?: 'Media'
            id: string
            image?: {
              __typename?: 'File'
              id: string
              filename: string
              size: number
              mimetype: string
              downloadUrl: string
              thumbUrl?: string | null
              createdAt: Date
              metadata?: {
                __typename?: 'FileMetadata'
                title?: string | null
                description?: string | null
                aspect?: number | null
                width?: number | null
                height?: number | null
                sourceFileId?: string | null
              } | null
            } | null
          } | null
          timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
        } | null
        bannerVideo?: {
          __typename?: 'Media'
          id: string
          videoClip?: { __typename?: 'VideoClip'; id: string; playbackUrl: string } | null
        } | null
        pageWidgets?: Array<{
          __typename?: 'CustomPageWidget'
          name: CustomPageWidgetName
          position: number
          display?: CustomPageDisplayTypes | null
          contentDisplay?: ContentSetContentDisplay | null
          cardsDisplay?: CardsDisplay | null
          options?: {
            __typename?: 'CustomPageWidgetOptions'
            editedCategoryId?: string | null
            sportItemSetType?: SportItemSetType | null
            replays?: {
              __typename?: 'ReplaysWidget'
              defaultLocale: string
              maxReplays?: number | null
              depthInDays?: number | null
              nameT: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }>
            } | null
            externalContent?: {
              __typename?: 'ExternalContentWidget'
              name?: string | null
              defaultLocale: string
              maxItemsCount: number
              source: WidgetExternalContentSource
              nameT: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }>
              period?: { __typename?: 'StartEndDates'; startDate: Date; endDate: Date } | null
              wecContent?: {
                __typename?: 'WECNewsContent'
                teamECMIds?: Array<string> | null
                driverECMIds?: Array<string> | null
                carECMIds?: Array<string> | null
                categoryECMIds?: Array<string> | null
                raceECMIds?: Array<string> | null
              } | null
              rssContent?: { __typename?: 'RSSFeedContent'; feedUrl: string } | null
            } | null
          } | null
        }> | null
        offers: Array<{
          __typename?: 'PurchasableOffer'
          id: string
          mode: OfferMode
          delay?: number | null
          expiredAt?: Date | null
          publicationStatus: PublicationStatus
          title: string
          defaultLocale?: string | null
          url?: string | null
          bannerImageId?: string | null
          thumbnailImageId?: string | null
          freeWithLicense: boolean
          position?: number | null
          titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
          description: Array<{ __typename?: 'OfferDescription'; header: string; content: string }>
          descriptionT?: Array<{
            __typename?: 'ContentTranslationDescriptionPurchasableOffer'
            locale: string
            content?: Array<{ __typename?: 'OfferDescription'; header: string; content: string }> | null
          }> | null
          products: Array<{
            __typename?: 'Product'
            productId: string
            platform: Platform
            period?: Period | null
            publicationStatus: PublicationStatus
            purchasableOfferId?: string | null
            licensedUserOnly?: boolean | null
            premiumOf?: Array<string> | null
            prices: Array<{ __typename?: 'Price'; value: number; currency: string }>
          }>
        }>
        license?: { __typename?: 'OrganismLicense'; enabled: boolean; api?: LicenseApi | null } | null
        tags?: Array<{ __typename?: 'Tag'; id: string; title: string; subTags?: Array<string> | null }> | null
      }> | null
      titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
      subtitleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
      descriptionT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
      billboard?: {
        __typename?: 'Media'
        id: string
        videoClip?: { __typename?: 'VideoClip'; id: string; playbackUrl: string } | null
      } | null
      teaserVideo?: {
        __typename?: 'Media'
        id: string
        videoClip?: { __typename?: 'VideoClip'; id: string; playbackUrl: string } | null
      } | null
      image?: {
        __typename?: 'Media'
        id: string
        image?: { __typename?: 'File'; id: string; thumbUrl?: string | null; downloadUrl: string } | null
      } | null
      categories: Array<{ __typename?: 'Category'; id: string; title: string }>
    }>
  } | null
}

export type LinkQueryVariables = Exact<{
  linkId: Scalars['ID']
}>

export type LinkQuery = {
  __typename?: 'Query'
  link: {
    __typename: 'Link'
    id: string
    ownerOrganismIds: Array<string>
    tag?: LinkTag | null
    title: string
    subtitle?: string | null
    description?: string | null
    defaultLocale?: string | null
    clicks: number
    uniqueClicks: number
    views: number
    countries: Array<string | null>
    uniqueViews: number
    teaserVideoId?: string | null
    gamTagUrl?: string | null
    billboardId?: string | null
    url?: string | null
    imageId: string
    categoriesIds: Array<string>
    publicationStatus: PublicationStatus
    ownerOrganisms?: Array<{
      __typename?: 'Organism'
      id: string
      type: OrganismType
      whiteLabel: boolean
      title: string
      description?: string | null
      publicationStatus: PublicationStatus
      defaultLocale?: string | null
      onFront: boolean
      frontPosition?: number | null
      officialWebSiteUrl: string
      sportIds: Array<string>
      forbiddenKeyWords?: Array<string> | null
      enableExternalSources?: boolean | null
      availableExternalSources?: Array<WidgetExternalContentSource> | null
      streamsIds?: Array<string> | null
      wmProfileId?: string | null
      sveChannelIds?: Array<string> | null
      organismPageApplicationDesignId?: string | null
      contentPageApplicationDesignId?: string | null
      adminsAutomatedEmails?: boolean | null
      visibleInMultisportApplication?: boolean | null
      titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
      descriptionT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
      logo: {
        __typename?: 'Logo'
        coloredImage?: {
          __typename?: 'Media'
          id: string
          createdAt: Date
          updatedAt: Date
          title: string
          type: MediaType
          publicationStatus: PublicationStatus
          tags: Array<MediaTags>
          date?: Date | null
          startTrim?: number | null
          endTrim?: number | null
          liveStreamId?: string | null
          videoClipId?: string | null
          imageId?: string | null
          thumbnailId?: string | null
          ownerOrganismId?: string | null
          pinned?: boolean | null
          liveStream?: {
            __typename?: 'LiveStream'
            id: string
            playbackUrl: string
            ingestUrl?: string | null
            wildmokaEventID?: string | null
            videoEngine: VideoEngine
            state: LiveStreamState
            streamId?: string | null
            sveBroadcastId?: string | null
            sveChannelId?: string | null
            externalFeedProvider?: ExternalFeedType | null
            externalFeedUrl?: string | null
            event?: {
              __typename?: 'WMEvent'
              id: string
              name: string
              description?: string | null
              testStartDate: Date
              editoStartDate: Date
              editoStopDate: Date
              stopDate: Date
              state: WmEventState
              notifEmail?: string | null
              clipCreationAtEnd: boolean
              clipPublicationAtEnd: boolean
              livePublication: boolean
              stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
              live?: {
                __typename?: 'WMEventLive'
                id: string
                publishStatus: WmLiveState
                type: WmLiveType
                autoAnnounceDate?: Date | null
                template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
              } | null
              clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
            } | null
            sveBroadcast?: {
              __typename?: 'SVEBroadcast'
              id: string
              status: SveBroadcastState
              channelId: string
              startDate?: Date | null
              clipStartDate?: Date | null
              stopDate?: Date | null
              scheduledStartDate: Date
              scheduledClipStartDate: Date
              scheduledStopDate: Date
            } | null
            sveLiveMonitoring?: {
              __typename?: 'SVELiveMonitoring'
              alerts?: {
                __typename?: 'SVELiveMonitoringAlerts'
                audioNotDetected?: string | null
                initialProbeTakingLonger?: string | null
                videoNotDetected?: string | null
                RTMPHasNoAudioVideo?: string | null
                RTMPStreamNotFound?: string | null
              } | null
              stats?: {
                __typename?: 'SVELiveMonitoringStats'
                networkIn?: string | null
                droppedFrames?: string | null
                inputVideoFrameRate?: string | null
                outputAudioLevelLkfs?: string | null
              } | null
            } | null
          } | null
          videoClip?: {
            __typename?: 'VideoClip'
            id: string
            playbackUrl: string
            downloadLink: string
            status?: VideoClipStatus | null
            updatedAt: Date
            createdAt: Date
            fileId?: string | null
            error?: {
              __typename?: 'VideoClipError'
              guid: string
              workflowStatus: string
              workflowErrorAt: string
              errorMessage: string
              errorDetails: string
            } | null
            captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
            externalFeed?: {
              __typename?: 'ExternalFeed'
              externalFeedProvider: ExternalFeedType
              externalFeedUrl: string
            } | null
          } | null
          image?: {
            __typename?: 'File'
            id: string
            filename: string
            size: number
            mimetype: string
            downloadUrl: string
            thumbUrl?: string | null
            createdAt: Date
            metadata?: {
              __typename?: 'FileMetadata'
              title?: string | null
              description?: string | null
              aspect?: number | null
              width?: number | null
              height?: number | null
              sourceFileId?: string | null
            } | null
          } | null
          thumbnail?: {
            __typename?: 'Media'
            id: string
            image?: {
              __typename?: 'File'
              id: string
              filename: string
              size: number
              mimetype: string
              downloadUrl: string
              thumbUrl?: string | null
              createdAt: Date
              metadata?: {
                __typename?: 'FileMetadata'
                title?: string | null
                description?: string | null
                aspect?: number | null
                width?: number | null
                height?: number | null
                sourceFileId?: string | null
              } | null
            } | null
          } | null
          timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
        } | null
        transparentDarkImage?: {
          __typename?: 'Media'
          id: string
          createdAt: Date
          updatedAt: Date
          title: string
          type: MediaType
          publicationStatus: PublicationStatus
          tags: Array<MediaTags>
          date?: Date | null
          startTrim?: number | null
          endTrim?: number | null
          liveStreamId?: string | null
          videoClipId?: string | null
          imageId?: string | null
          thumbnailId?: string | null
          ownerOrganismId?: string | null
          pinned?: boolean | null
          liveStream?: {
            __typename?: 'LiveStream'
            id: string
            playbackUrl: string
            ingestUrl?: string | null
            wildmokaEventID?: string | null
            videoEngine: VideoEngine
            state: LiveStreamState
            streamId?: string | null
            sveBroadcastId?: string | null
            sveChannelId?: string | null
            externalFeedProvider?: ExternalFeedType | null
            externalFeedUrl?: string | null
            event?: {
              __typename?: 'WMEvent'
              id: string
              name: string
              description?: string | null
              testStartDate: Date
              editoStartDate: Date
              editoStopDate: Date
              stopDate: Date
              state: WmEventState
              notifEmail?: string | null
              clipCreationAtEnd: boolean
              clipPublicationAtEnd: boolean
              livePublication: boolean
              stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
              live?: {
                __typename?: 'WMEventLive'
                id: string
                publishStatus: WmLiveState
                type: WmLiveType
                autoAnnounceDate?: Date | null
                template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
              } | null
              clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
            } | null
            sveBroadcast?: {
              __typename?: 'SVEBroadcast'
              id: string
              status: SveBroadcastState
              channelId: string
              startDate?: Date | null
              clipStartDate?: Date | null
              stopDate?: Date | null
              scheduledStartDate: Date
              scheduledClipStartDate: Date
              scheduledStopDate: Date
            } | null
            sveLiveMonitoring?: {
              __typename?: 'SVELiveMonitoring'
              alerts?: {
                __typename?: 'SVELiveMonitoringAlerts'
                audioNotDetected?: string | null
                initialProbeTakingLonger?: string | null
                videoNotDetected?: string | null
                RTMPHasNoAudioVideo?: string | null
                RTMPStreamNotFound?: string | null
              } | null
              stats?: {
                __typename?: 'SVELiveMonitoringStats'
                networkIn?: string | null
                droppedFrames?: string | null
                inputVideoFrameRate?: string | null
                outputAudioLevelLkfs?: string | null
              } | null
            } | null
          } | null
          videoClip?: {
            __typename?: 'VideoClip'
            id: string
            playbackUrl: string
            downloadLink: string
            status?: VideoClipStatus | null
            updatedAt: Date
            createdAt: Date
            fileId?: string | null
            error?: {
              __typename?: 'VideoClipError'
              guid: string
              workflowStatus: string
              workflowErrorAt: string
              errorMessage: string
              errorDetails: string
            } | null
            captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
            externalFeed?: {
              __typename?: 'ExternalFeed'
              externalFeedProvider: ExternalFeedType
              externalFeedUrl: string
            } | null
          } | null
          image?: {
            __typename?: 'File'
            id: string
            filename: string
            size: number
            mimetype: string
            downloadUrl: string
            thumbUrl?: string | null
            createdAt: Date
            metadata?: {
              __typename?: 'FileMetadata'
              title?: string | null
              description?: string | null
              aspect?: number | null
              width?: number | null
              height?: number | null
              sourceFileId?: string | null
            } | null
          } | null
          thumbnail?: {
            __typename?: 'Media'
            id: string
            image?: {
              __typename?: 'File'
              id: string
              filename: string
              size: number
              mimetype: string
              downloadUrl: string
              thumbUrl?: string | null
              createdAt: Date
              metadata?: {
                __typename?: 'FileMetadata'
                title?: string | null
                description?: string | null
                aspect?: number | null
                width?: number | null
                height?: number | null
                sourceFileId?: string | null
              } | null
            } | null
          } | null
          timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
        } | null
        transparentLightImage?: {
          __typename?: 'Media'
          id: string
          createdAt: Date
          updatedAt: Date
          title: string
          type: MediaType
          publicationStatus: PublicationStatus
          tags: Array<MediaTags>
          date?: Date | null
          startTrim?: number | null
          endTrim?: number | null
          liveStreamId?: string | null
          videoClipId?: string | null
          imageId?: string | null
          thumbnailId?: string | null
          ownerOrganismId?: string | null
          pinned?: boolean | null
          liveStream?: {
            __typename?: 'LiveStream'
            id: string
            playbackUrl: string
            ingestUrl?: string | null
            wildmokaEventID?: string | null
            videoEngine: VideoEngine
            state: LiveStreamState
            streamId?: string | null
            sveBroadcastId?: string | null
            sveChannelId?: string | null
            externalFeedProvider?: ExternalFeedType | null
            externalFeedUrl?: string | null
            event?: {
              __typename?: 'WMEvent'
              id: string
              name: string
              description?: string | null
              testStartDate: Date
              editoStartDate: Date
              editoStopDate: Date
              stopDate: Date
              state: WmEventState
              notifEmail?: string | null
              clipCreationAtEnd: boolean
              clipPublicationAtEnd: boolean
              livePublication: boolean
              stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
              live?: {
                __typename?: 'WMEventLive'
                id: string
                publishStatus: WmLiveState
                type: WmLiveType
                autoAnnounceDate?: Date | null
                template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
              } | null
              clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
            } | null
            sveBroadcast?: {
              __typename?: 'SVEBroadcast'
              id: string
              status: SveBroadcastState
              channelId: string
              startDate?: Date | null
              clipStartDate?: Date | null
              stopDate?: Date | null
              scheduledStartDate: Date
              scheduledClipStartDate: Date
              scheduledStopDate: Date
            } | null
            sveLiveMonitoring?: {
              __typename?: 'SVELiveMonitoring'
              alerts?: {
                __typename?: 'SVELiveMonitoringAlerts'
                audioNotDetected?: string | null
                initialProbeTakingLonger?: string | null
                videoNotDetected?: string | null
                RTMPHasNoAudioVideo?: string | null
                RTMPStreamNotFound?: string | null
              } | null
              stats?: {
                __typename?: 'SVELiveMonitoringStats'
                networkIn?: string | null
                droppedFrames?: string | null
                inputVideoFrameRate?: string | null
                outputAudioLevelLkfs?: string | null
              } | null
            } | null
          } | null
          videoClip?: {
            __typename?: 'VideoClip'
            id: string
            playbackUrl: string
            downloadLink: string
            status?: VideoClipStatus | null
            updatedAt: Date
            createdAt: Date
            fileId?: string | null
            error?: {
              __typename?: 'VideoClipError'
              guid: string
              workflowStatus: string
              workflowErrorAt: string
              errorMessage: string
              errorDetails: string
            } | null
            captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
            externalFeed?: {
              __typename?: 'ExternalFeed'
              externalFeedProvider: ExternalFeedType
              externalFeedUrl: string
            } | null
          } | null
          image?: {
            __typename?: 'File'
            id: string
            filename: string
            size: number
            mimetype: string
            downloadUrl: string
            thumbUrl?: string | null
            createdAt: Date
            metadata?: {
              __typename?: 'FileMetadata'
              title?: string | null
              description?: string | null
              aspect?: number | null
              width?: number | null
              height?: number | null
              sourceFileId?: string | null
            } | null
          } | null
          thumbnail?: {
            __typename?: 'Media'
            id: string
            image?: {
              __typename?: 'File'
              id: string
              filename: string
              size: number
              mimetype: string
              downloadUrl: string
              thumbUrl?: string | null
              createdAt: Date
              metadata?: {
                __typename?: 'FileMetadata'
                title?: string | null
                description?: string | null
                aspect?: number | null
                width?: number | null
                height?: number | null
                sourceFileId?: string | null
              } | null
            } | null
          } | null
          timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
        } | null
      }
      sports: Array<{ __typename?: 'Sport'; id: string }>
      bannerImage?: {
        __typename?: 'Media'
        id: string
        createdAt: Date
        updatedAt: Date
        title: string
        type: MediaType
        publicationStatus: PublicationStatus
        tags: Array<MediaTags>
        date?: Date | null
        startTrim?: number | null
        endTrim?: number | null
        liveStreamId?: string | null
        videoClipId?: string | null
        imageId?: string | null
        thumbnailId?: string | null
        ownerOrganismId?: string | null
        pinned?: boolean | null
        liveStream?: {
          __typename?: 'LiveStream'
          id: string
          playbackUrl: string
          ingestUrl?: string | null
          wildmokaEventID?: string | null
          videoEngine: VideoEngine
          state: LiveStreamState
          streamId?: string | null
          sveBroadcastId?: string | null
          sveChannelId?: string | null
          externalFeedProvider?: ExternalFeedType | null
          externalFeedUrl?: string | null
          event?: {
            __typename?: 'WMEvent'
            id: string
            name: string
            description?: string | null
            testStartDate: Date
            editoStartDate: Date
            editoStopDate: Date
            stopDate: Date
            state: WmEventState
            notifEmail?: string | null
            clipCreationAtEnd: boolean
            clipPublicationAtEnd: boolean
            livePublication: boolean
            stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
            live?: {
              __typename?: 'WMEventLive'
              id: string
              publishStatus: WmLiveState
              type: WmLiveType
              autoAnnounceDate?: Date | null
              template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
            } | null
            clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
          } | null
          sveBroadcast?: {
            __typename?: 'SVEBroadcast'
            id: string
            status: SveBroadcastState
            channelId: string
            startDate?: Date | null
            clipStartDate?: Date | null
            stopDate?: Date | null
            scheduledStartDate: Date
            scheduledClipStartDate: Date
            scheduledStopDate: Date
          } | null
          sveLiveMonitoring?: {
            __typename?: 'SVELiveMonitoring'
            alerts?: {
              __typename?: 'SVELiveMonitoringAlerts'
              audioNotDetected?: string | null
              initialProbeTakingLonger?: string | null
              videoNotDetected?: string | null
              RTMPHasNoAudioVideo?: string | null
              RTMPStreamNotFound?: string | null
            } | null
            stats?: {
              __typename?: 'SVELiveMonitoringStats'
              networkIn?: string | null
              droppedFrames?: string | null
              inputVideoFrameRate?: string | null
              outputAudioLevelLkfs?: string | null
            } | null
          } | null
        } | null
        videoClip?: {
          __typename?: 'VideoClip'
          id: string
          playbackUrl: string
          downloadLink: string
          status?: VideoClipStatus | null
          updatedAt: Date
          createdAt: Date
          fileId?: string | null
          error?: {
            __typename?: 'VideoClipError'
            guid: string
            workflowStatus: string
            workflowErrorAt: string
            errorMessage: string
            errorDetails: string
          } | null
          captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
          externalFeed?: {
            __typename?: 'ExternalFeed'
            externalFeedProvider: ExternalFeedType
            externalFeedUrl: string
          } | null
        } | null
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
        thumbnail?: {
          __typename?: 'Media'
          id: string
          image?: {
            __typename?: 'File'
            id: string
            filename: string
            size: number
            mimetype: string
            downloadUrl: string
            thumbUrl?: string | null
            createdAt: Date
            metadata?: {
              __typename?: 'FileMetadata'
              title?: string | null
              description?: string | null
              aspect?: number | null
              width?: number | null
              height?: number | null
              sourceFileId?: string | null
            } | null
          } | null
        } | null
        timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
      } | null
      adminBadge?: {
        __typename?: 'Media'
        id: string
        createdAt: Date
        updatedAt: Date
        title: string
        type: MediaType
        publicationStatus: PublicationStatus
        tags: Array<MediaTags>
        date?: Date | null
        startTrim?: number | null
        endTrim?: number | null
        liveStreamId?: string | null
        videoClipId?: string | null
        imageId?: string | null
        thumbnailId?: string | null
        ownerOrganismId?: string | null
        pinned?: boolean | null
        liveStream?: {
          __typename?: 'LiveStream'
          id: string
          playbackUrl: string
          ingestUrl?: string | null
          wildmokaEventID?: string | null
          videoEngine: VideoEngine
          state: LiveStreamState
          streamId?: string | null
          sveBroadcastId?: string | null
          sveChannelId?: string | null
          externalFeedProvider?: ExternalFeedType | null
          externalFeedUrl?: string | null
          event?: {
            __typename?: 'WMEvent'
            id: string
            name: string
            description?: string | null
            testStartDate: Date
            editoStartDate: Date
            editoStopDate: Date
            stopDate: Date
            state: WmEventState
            notifEmail?: string | null
            clipCreationAtEnd: boolean
            clipPublicationAtEnd: boolean
            livePublication: boolean
            stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
            live?: {
              __typename?: 'WMEventLive'
              id: string
              publishStatus: WmLiveState
              type: WmLiveType
              autoAnnounceDate?: Date | null
              template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
            } | null
            clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
          } | null
          sveBroadcast?: {
            __typename?: 'SVEBroadcast'
            id: string
            status: SveBroadcastState
            channelId: string
            startDate?: Date | null
            clipStartDate?: Date | null
            stopDate?: Date | null
            scheduledStartDate: Date
            scheduledClipStartDate: Date
            scheduledStopDate: Date
          } | null
          sveLiveMonitoring?: {
            __typename?: 'SVELiveMonitoring'
            alerts?: {
              __typename?: 'SVELiveMonitoringAlerts'
              audioNotDetected?: string | null
              initialProbeTakingLonger?: string | null
              videoNotDetected?: string | null
              RTMPHasNoAudioVideo?: string | null
              RTMPStreamNotFound?: string | null
            } | null
            stats?: {
              __typename?: 'SVELiveMonitoringStats'
              networkIn?: string | null
              droppedFrames?: string | null
              inputVideoFrameRate?: string | null
              outputAudioLevelLkfs?: string | null
            } | null
          } | null
        } | null
        videoClip?: {
          __typename?: 'VideoClip'
          id: string
          playbackUrl: string
          downloadLink: string
          status?: VideoClipStatus | null
          updatedAt: Date
          createdAt: Date
          fileId?: string | null
          error?: {
            __typename?: 'VideoClipError'
            guid: string
            workflowStatus: string
            workflowErrorAt: string
            errorMessage: string
            errorDetails: string
          } | null
          captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
          externalFeed?: {
            __typename?: 'ExternalFeed'
            externalFeedProvider: ExternalFeedType
            externalFeedUrl: string
          } | null
        } | null
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
        thumbnail?: {
          __typename?: 'Media'
          id: string
          image?: {
            __typename?: 'File'
            id: string
            filename: string
            size: number
            mimetype: string
            downloadUrl: string
            thumbUrl?: string | null
            createdAt: Date
            metadata?: {
              __typename?: 'FileMetadata'
              title?: string | null
              description?: string | null
              aspect?: number | null
              width?: number | null
              height?: number | null
              sourceFileId?: string | null
            } | null
          } | null
        } | null
        timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
      } | null
      bannerVideo?: {
        __typename?: 'Media'
        id: string
        videoClip?: { __typename?: 'VideoClip'; id: string; playbackUrl: string } | null
      } | null
      pageWidgets?: Array<{
        __typename?: 'CustomPageWidget'
        name: CustomPageWidgetName
        position: number
        display?: CustomPageDisplayTypes | null
        contentDisplay?: ContentSetContentDisplay | null
        cardsDisplay?: CardsDisplay | null
        options?: {
          __typename?: 'CustomPageWidgetOptions'
          editedCategoryId?: string | null
          sportItemSetType?: SportItemSetType | null
          replays?: {
            __typename?: 'ReplaysWidget'
            defaultLocale: string
            maxReplays?: number | null
            depthInDays?: number | null
            nameT: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }>
          } | null
          externalContent?: {
            __typename?: 'ExternalContentWidget'
            name?: string | null
            defaultLocale: string
            maxItemsCount: number
            source: WidgetExternalContentSource
            nameT: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }>
            period?: { __typename?: 'StartEndDates'; startDate: Date; endDate: Date } | null
            wecContent?: {
              __typename?: 'WECNewsContent'
              teamECMIds?: Array<string> | null
              driverECMIds?: Array<string> | null
              carECMIds?: Array<string> | null
              categoryECMIds?: Array<string> | null
              raceECMIds?: Array<string> | null
            } | null
            rssContent?: { __typename?: 'RSSFeedContent'; feedUrl: string } | null
          } | null
        } | null
      }> | null
      offers: Array<{
        __typename?: 'PurchasableOffer'
        id: string
        mode: OfferMode
        delay?: number | null
        expiredAt?: Date | null
        publicationStatus: PublicationStatus
        title: string
        defaultLocale?: string | null
        url?: string | null
        bannerImageId?: string | null
        thumbnailImageId?: string | null
        freeWithLicense: boolean
        position?: number | null
        titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
        description: Array<{ __typename?: 'OfferDescription'; header: string; content: string }>
        descriptionT?: Array<{
          __typename?: 'ContentTranslationDescriptionPurchasableOffer'
          locale: string
          content?: Array<{ __typename?: 'OfferDescription'; header: string; content: string }> | null
        }> | null
        products: Array<{
          __typename?: 'Product'
          productId: string
          platform: Platform
          period?: Period | null
          publicationStatus: PublicationStatus
          purchasableOfferId?: string | null
          licensedUserOnly?: boolean | null
          premiumOf?: Array<string> | null
          prices: Array<{ __typename?: 'Price'; value: number; currency: string }>
        }>
      }>
      license?: { __typename?: 'OrganismLicense'; enabled: boolean; api?: LicenseApi | null } | null
      tags?: Array<{ __typename?: 'Tag'; id: string; title: string; subTags?: Array<string> | null }> | null
    }> | null
    titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    subtitleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    descriptionT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    billboard?: {
      __typename?: 'Media'
      id: string
      videoClip?: { __typename?: 'VideoClip'; id: string; playbackUrl: string } | null
    } | null
    teaserVideo?: {
      __typename?: 'Media'
      id: string
      videoClip?: { __typename?: 'VideoClip'; id: string; playbackUrl: string } | null
    } | null
    image?: {
      __typename?: 'Media'
      id: string
      image?: { __typename?: 'File'; id: string; thumbUrl?: string | null; downloadUrl: string } | null
    } | null
    categories: Array<{ __typename?: 'Category'; id: string; title: string }>
  }
}

export type LiveStreamContentFragment = {
  __typename?: 'LiveStream'
  id: string
  playbackUrl: string
  ingestUrl?: string | null
  wildmokaEventID?: string | null
  videoEngine: VideoEngine
  state: LiveStreamState
  streamId?: string | null
  sveBroadcastId?: string | null
  sveChannelId?: string | null
  externalFeedProvider?: ExternalFeedType | null
  externalFeedUrl?: string | null
  event?: {
    __typename?: 'WMEvent'
    id: string
    name: string
    description?: string | null
    testStartDate: Date
    editoStartDate: Date
    editoStopDate: Date
    stopDate: Date
    state: WmEventState
    notifEmail?: string | null
    clipCreationAtEnd: boolean
    clipPublicationAtEnd: boolean
    livePublication: boolean
    stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
    live?: {
      __typename?: 'WMEventLive'
      id: string
      publishStatus: WmLiveState
      type: WmLiveType
      autoAnnounceDate?: Date | null
      template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
    } | null
    clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
  } | null
  sveBroadcast?: {
    __typename?: 'SVEBroadcast'
    id: string
    status: SveBroadcastState
    channelId: string
    startDate?: Date | null
    clipStartDate?: Date | null
    stopDate?: Date | null
    scheduledStartDate: Date
    scheduledClipStartDate: Date
    scheduledStopDate: Date
  } | null
  sveLiveMonitoring?: {
    __typename?: 'SVELiveMonitoring'
    alerts?: {
      __typename?: 'SVELiveMonitoringAlerts'
      audioNotDetected?: string | null
      initialProbeTakingLonger?: string | null
      videoNotDetected?: string | null
      RTMPHasNoAudioVideo?: string | null
      RTMPStreamNotFound?: string | null
    } | null
    stats?: {
      __typename?: 'SVELiveMonitoringStats'
      networkIn?: string | null
      droppedFrames?: string | null
      inputVideoFrameRate?: string | null
      outputAudioLevelLkfs?: string | null
    } | null
  } | null
}

export type UpdateLiveStreamMutationVariables = Exact<{
  input: UpdateWildmokaLiveStreamInput
}>

export type UpdateLiveStreamMutation = {
  __typename?: 'Mutation'
  updateLiveStream: {
    __typename?: 'LiveStream'
    id: string
    playbackUrl: string
    ingestUrl?: string | null
    wildmokaEventID?: string | null
    videoEngine: VideoEngine
    state: LiveStreamState
    streamId?: string | null
    sveBroadcastId?: string | null
    sveChannelId?: string | null
    externalFeedProvider?: ExternalFeedType | null
    externalFeedUrl?: string | null
    event?: {
      __typename?: 'WMEvent'
      id: string
      name: string
      description?: string | null
      testStartDate: Date
      editoStartDate: Date
      editoStopDate: Date
      stopDate: Date
      state: WmEventState
      notifEmail?: string | null
      clipCreationAtEnd: boolean
      clipPublicationAtEnd: boolean
      livePublication: boolean
      stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
      live?: {
        __typename?: 'WMEventLive'
        id: string
        publishStatus: WmLiveState
        type: WmLiveType
        autoAnnounceDate?: Date | null
        template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
      } | null
      clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
    } | null
    sveBroadcast?: {
      __typename?: 'SVEBroadcast'
      id: string
      status: SveBroadcastState
      channelId: string
      startDate?: Date | null
      clipStartDate?: Date | null
      stopDate?: Date | null
      scheduledStartDate: Date
      scheduledClipStartDate: Date
      scheduledStopDate: Date
    } | null
    sveLiveMonitoring?: {
      __typename?: 'SVELiveMonitoring'
      alerts?: {
        __typename?: 'SVELiveMonitoringAlerts'
        audioNotDetected?: string | null
        initialProbeTakingLonger?: string | null
        videoNotDetected?: string | null
        RTMPHasNoAudioVideo?: string | null
        RTMPStreamNotFound?: string | null
      } | null
      stats?: {
        __typename?: 'SVELiveMonitoringStats'
        networkIn?: string | null
        droppedFrames?: string | null
        inputVideoFrameRate?: string | null
        outputAudioLevelLkfs?: string | null
      } | null
    } | null
  }
}

export type UpdateLiveStreamStateMutationVariables = Exact<{
  id: Scalars['ID']
  state: WmEventState
}>

export type UpdateLiveStreamStateMutation = {
  __typename?: 'Mutation'
  updateLiveStreamState: {
    __typename?: 'LiveStream'
    id: string
    playbackUrl: string
    ingestUrl?: string | null
    wildmokaEventID?: string | null
    videoEngine: VideoEngine
    state: LiveStreamState
    streamId?: string | null
    sveBroadcastId?: string | null
    sveChannelId?: string | null
    externalFeedProvider?: ExternalFeedType | null
    externalFeedUrl?: string | null
    event?: {
      __typename?: 'WMEvent'
      id: string
      name: string
      description?: string | null
      testStartDate: Date
      editoStartDate: Date
      editoStopDate: Date
      stopDate: Date
      state: WmEventState
      notifEmail?: string | null
      clipCreationAtEnd: boolean
      clipPublicationAtEnd: boolean
      livePublication: boolean
      stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
      live?: {
        __typename?: 'WMEventLive'
        id: string
        publishStatus: WmLiveState
        type: WmLiveType
        autoAnnounceDate?: Date | null
        template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
      } | null
      clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
    } | null
    sveBroadcast?: {
      __typename?: 'SVEBroadcast'
      id: string
      status: SveBroadcastState
      channelId: string
      startDate?: Date | null
      clipStartDate?: Date | null
      stopDate?: Date | null
      scheduledStartDate: Date
      scheduledClipStartDate: Date
      scheduledStopDate: Date
    } | null
    sveLiveMonitoring?: {
      __typename?: 'SVELiveMonitoring'
      alerts?: {
        __typename?: 'SVELiveMonitoringAlerts'
        audioNotDetected?: string | null
        initialProbeTakingLonger?: string | null
        videoNotDetected?: string | null
        RTMPHasNoAudioVideo?: string | null
        RTMPStreamNotFound?: string | null
      } | null
      stats?: {
        __typename?: 'SVELiveMonitoringStats'
        networkIn?: string | null
        droppedFrames?: string | null
        inputVideoFrameRate?: string | null
        outputAudioLevelLkfs?: string | null
      } | null
    } | null
  }
}

export type SetLiveStreamStateMutationVariables = Exact<{
  id: Scalars['ID']
  state: LiveStreamState
}>

export type SetLiveStreamStateMutation = {
  __typename?: 'Mutation'
  setLiveStreamState: {
    __typename?: 'LiveStream'
    id: string
    playbackUrl: string
    ingestUrl?: string | null
    wildmokaEventID?: string | null
    videoEngine: VideoEngine
    state: LiveStreamState
    streamId?: string | null
    sveBroadcastId?: string | null
    sveChannelId?: string | null
    externalFeedProvider?: ExternalFeedType | null
    externalFeedUrl?: string | null
    event?: {
      __typename?: 'WMEvent'
      id: string
      name: string
      description?: string | null
      testStartDate: Date
      editoStartDate: Date
      editoStopDate: Date
      stopDate: Date
      state: WmEventState
      notifEmail?: string | null
      clipCreationAtEnd: boolean
      clipPublicationAtEnd: boolean
      livePublication: boolean
      stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
      live?: {
        __typename?: 'WMEventLive'
        id: string
        publishStatus: WmLiveState
        type: WmLiveType
        autoAnnounceDate?: Date | null
        template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
      } | null
      clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
    } | null
    sveBroadcast?: {
      __typename?: 'SVEBroadcast'
      id: string
      status: SveBroadcastState
      channelId: string
      startDate?: Date | null
      clipStartDate?: Date | null
      stopDate?: Date | null
      scheduledStartDate: Date
      scheduledClipStartDate: Date
      scheduledStopDate: Date
    } | null
    sveLiveMonitoring?: {
      __typename?: 'SVELiveMonitoring'
      alerts?: {
        __typename?: 'SVELiveMonitoringAlerts'
        audioNotDetected?: string | null
        initialProbeTakingLonger?: string | null
        videoNotDetected?: string | null
        RTMPHasNoAudioVideo?: string | null
        RTMPStreamNotFound?: string | null
      } | null
      stats?: {
        __typename?: 'SVELiveMonitoringStats'
        networkIn?: string | null
        droppedFrames?: string | null
        inputVideoFrameRate?: string | null
        outputAudioLevelLkfs?: string | null
      } | null
    } | null
  }
}

export type ActiveLiveStreamsQueryVariables = Exact<{ [key: string]: never }>

export type ActiveLiveStreamsQuery = {
  __typename?: 'Query'
  activeLiveStreams?: {
    __typename?: 'ActiveLiveStreamsResult'
    liveStreams: Array<{ __typename?: 'LiveStream'; id: string }>
    sportItems: Array<{ __typename?: 'SportItem'; id: string }>
  } | null
}

export type LiveStreamStreamConflictContentFragment = {
  __typename?: 'LiveStreamStreamConflict'
  liveStream: {
    __typename?: 'LiveStream'
    id: string
    sportItem?: {
      __typename?: 'SportItem'
      id: string
      title: string
      medias: Array<{ __typename?: 'Media'; id: string; title: string; liveStreamId?: string | null }>
    } | null
  }
}

export type CheckStreamUnusedQueryVariables = Exact<{
  streamId: Scalars['WildmokaID']
  sportItemSetId: Scalars['ID']
  from: Scalars['Date']
  to: Scalars['Date']
}>

export type CheckStreamUnusedQuery = {
  __typename?: 'Query'
  checkStreamUnused: Array<{
    __typename?: 'LiveStreamStreamConflict'
    liveStream: {
      __typename?: 'LiveStream'
      id: string
      sportItem?: {
        __typename?: 'SportItem'
        id: string
        title: string
        medias: Array<{ __typename?: 'Media'; id: string; title: string; liveStreamId?: string | null }>
      } | null
    }
  }>
}

export type GetLivestreamPlaybackUrlQueryVariables = Exact<{
  liveStreamId: Scalars['ID']
  expirationInSeconds?: InputMaybe<Scalars['Int']>
}>

export type GetLivestreamPlaybackUrlQuery = {
  __typename?: 'Query'
  liveStream?: { __typename?: 'LiveStream'; id: string; playbackUrl: string } | null
}

export type UpdateLiveStreamStatesCacheMutationVariables = Exact<{ [key: string]: never }>

export type UpdateLiveStreamStatesCacheMutation = { __typename?: 'Mutation'; updateLiveStreamStatesCache: boolean }

export type MediaContentFragment = {
  __typename?: 'Media'
  id: string
  createdAt: Date
  updatedAt: Date
  title: string
  type: MediaType
  publicationStatus: PublicationStatus
  tags: Array<MediaTags>
  date?: Date | null
  startTrim?: number | null
  endTrim?: number | null
  liveStreamId?: string | null
  videoClipId?: string | null
  imageId?: string | null
  thumbnailId?: string | null
  ownerOrganismId?: string | null
  pinned?: boolean | null
  liveStream?: {
    __typename?: 'LiveStream'
    id: string
    playbackUrl: string
    ingestUrl?: string | null
    wildmokaEventID?: string | null
    videoEngine: VideoEngine
    state: LiveStreamState
    streamId?: string | null
    sveBroadcastId?: string | null
    sveChannelId?: string | null
    externalFeedProvider?: ExternalFeedType | null
    externalFeedUrl?: string | null
    event?: {
      __typename?: 'WMEvent'
      id: string
      name: string
      description?: string | null
      testStartDate: Date
      editoStartDate: Date
      editoStopDate: Date
      stopDate: Date
      state: WmEventState
      notifEmail?: string | null
      clipCreationAtEnd: boolean
      clipPublicationAtEnd: boolean
      livePublication: boolean
      stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
      live?: {
        __typename?: 'WMEventLive'
        id: string
        publishStatus: WmLiveState
        type: WmLiveType
        autoAnnounceDate?: Date | null
        template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
      } | null
      clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
    } | null
    sveBroadcast?: {
      __typename?: 'SVEBroadcast'
      id: string
      status: SveBroadcastState
      channelId: string
      startDate?: Date | null
      clipStartDate?: Date | null
      stopDate?: Date | null
      scheduledStartDate: Date
      scheduledClipStartDate: Date
      scheduledStopDate: Date
    } | null
    sveLiveMonitoring?: {
      __typename?: 'SVELiveMonitoring'
      alerts?: {
        __typename?: 'SVELiveMonitoringAlerts'
        audioNotDetected?: string | null
        initialProbeTakingLonger?: string | null
        videoNotDetected?: string | null
        RTMPHasNoAudioVideo?: string | null
        RTMPStreamNotFound?: string | null
      } | null
      stats?: {
        __typename?: 'SVELiveMonitoringStats'
        networkIn?: string | null
        droppedFrames?: string | null
        inputVideoFrameRate?: string | null
        outputAudioLevelLkfs?: string | null
      } | null
    } | null
  } | null
  videoClip?: {
    __typename?: 'VideoClip'
    id: string
    playbackUrl: string
    downloadLink: string
    status?: VideoClipStatus | null
    updatedAt: Date
    createdAt: Date
    fileId?: string | null
    error?: {
      __typename?: 'VideoClipError'
      guid: string
      workflowStatus: string
      workflowErrorAt: string
      errorMessage: string
      errorDetails: string
    } | null
    captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
    externalFeed?: {
      __typename?: 'ExternalFeed'
      externalFeedProvider: ExternalFeedType
      externalFeedUrl: string
    } | null
  } | null
  image?: {
    __typename?: 'File'
    id: string
    filename: string
    size: number
    mimetype: string
    downloadUrl: string
    thumbUrl?: string | null
    createdAt: Date
    metadata?: {
      __typename?: 'FileMetadata'
      title?: string | null
      description?: string | null
      aspect?: number | null
      width?: number | null
      height?: number | null
      sourceFileId?: string | null
    } | null
  } | null
  thumbnail?: {
    __typename?: 'Media'
    id: string
    image?: {
      __typename?: 'File'
      id: string
      filename: string
      size: number
      mimetype: string
      downloadUrl: string
      thumbUrl?: string | null
      createdAt: Date
      metadata?: {
        __typename?: 'FileMetadata'
        title?: string | null
        description?: string | null
        aspect?: number | null
        width?: number | null
        height?: number | null
        sourceFileId?: string | null
      } | null
    } | null
  } | null
  timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
}

export type MediaLightContentFragment = {
  __typename?: 'Media'
  id: string
  title: string
  type: MediaType
  publicationStatus: PublicationStatus
  tags: Array<MediaTags>
  date?: Date | null
  startTrim?: number | null
  endTrim?: number | null
  thumbnailId?: string | null
  ownerOrganismId?: string | null
  pinned?: boolean | null
  timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
}

export type GenerateMediaReplayMutationVariables = Exact<{
  id: Scalars['ID']
  input?: InputMaybe<GenerateMediaReplayInput>
}>

export type GenerateMediaReplayMutation = { __typename?: 'Mutation'; generateMediaReplay: boolean }

export type DeleteMediaMutationVariables = Exact<{
  mediaId: Scalars['ID']
}>

export type DeleteMediaMutation = { __typename?: 'Mutation'; deleteMedia: boolean }

export type UpdateMediaMutationVariables = Exact<{
  mediaId: Scalars['ID']
  input: UpdateMediaInput
}>

export type UpdateMediaMutation = {
  __typename?: 'Mutation'
  updateMedia: {
    __typename?: 'Media'
    id: string
    title: string
    type: MediaType
    publicationStatus: PublicationStatus
    tags: Array<MediaTags>
    date?: Date | null
    startTrim?: number | null
    endTrim?: number | null
    thumbnailId?: string | null
    ownerOrganismId?: string | null
    pinned?: boolean | null
    timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
  }
}

export type MediasQueryVariables = Exact<{
  type?: InputMaybe<MediaType>
  search?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  organismId?: InputMaybe<Scalars['ID']>
}>

export type MediasQuery = {
  __typename?: 'Query'
  medias: {
    __typename?: 'MediaPageResult'
    totalCount: number
    medias: Array<{
      __typename?: 'Media'
      id: string
      createdAt: Date
      updatedAt: Date
      title: string
      type: MediaType
      publicationStatus: PublicationStatus
      tags: Array<MediaTags>
      date?: Date | null
      startTrim?: number | null
      endTrim?: number | null
      liveStreamId?: string | null
      videoClipId?: string | null
      imageId?: string | null
      thumbnailId?: string | null
      ownerOrganismId?: string | null
      pinned?: boolean | null
      liveStream?: {
        __typename?: 'LiveStream'
        id: string
        playbackUrl: string
        ingestUrl?: string | null
        wildmokaEventID?: string | null
        videoEngine: VideoEngine
        state: LiveStreamState
        streamId?: string | null
        sveBroadcastId?: string | null
        sveChannelId?: string | null
        externalFeedProvider?: ExternalFeedType | null
        externalFeedUrl?: string | null
        event?: {
          __typename?: 'WMEvent'
          id: string
          name: string
          description?: string | null
          testStartDate: Date
          editoStartDate: Date
          editoStopDate: Date
          stopDate: Date
          state: WmEventState
          notifEmail?: string | null
          clipCreationAtEnd: boolean
          clipPublicationAtEnd: boolean
          livePublication: boolean
          stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
          live?: {
            __typename?: 'WMEventLive'
            id: string
            publishStatus: WmLiveState
            type: WmLiveType
            autoAnnounceDate?: Date | null
            template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
          } | null
          clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
        } | null
        sveBroadcast?: {
          __typename?: 'SVEBroadcast'
          id: string
          status: SveBroadcastState
          channelId: string
          startDate?: Date | null
          clipStartDate?: Date | null
          stopDate?: Date | null
          scheduledStartDate: Date
          scheduledClipStartDate: Date
          scheduledStopDate: Date
        } | null
        sveLiveMonitoring?: {
          __typename?: 'SVELiveMonitoring'
          alerts?: {
            __typename?: 'SVELiveMonitoringAlerts'
            audioNotDetected?: string | null
            initialProbeTakingLonger?: string | null
            videoNotDetected?: string | null
            RTMPHasNoAudioVideo?: string | null
            RTMPStreamNotFound?: string | null
          } | null
          stats?: {
            __typename?: 'SVELiveMonitoringStats'
            networkIn?: string | null
            droppedFrames?: string | null
            inputVideoFrameRate?: string | null
            outputAudioLevelLkfs?: string | null
          } | null
        } | null
      } | null
      videoClip?: {
        __typename?: 'VideoClip'
        id: string
        playbackUrl: string
        downloadLink: string
        status?: VideoClipStatus | null
        updatedAt: Date
        createdAt: Date
        fileId?: string | null
        error?: {
          __typename?: 'VideoClipError'
          guid: string
          workflowStatus: string
          workflowErrorAt: string
          errorMessage: string
          errorDetails: string
        } | null
        captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
        externalFeed?: {
          __typename?: 'ExternalFeed'
          externalFeedProvider: ExternalFeedType
          externalFeedUrl: string
        } | null
      } | null
      image?: {
        __typename?: 'File'
        id: string
        filename: string
        size: number
        mimetype: string
        downloadUrl: string
        thumbUrl?: string | null
        createdAt: Date
        metadata?: {
          __typename?: 'FileMetadata'
          title?: string | null
          description?: string | null
          aspect?: number | null
          width?: number | null
          height?: number | null
          sourceFileId?: string | null
        } | null
      } | null
      thumbnail?: {
        __typename?: 'Media'
        id: string
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
      } | null
      timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
    }>
  }
}

export type MediaQueryVariables = Exact<{
  mediaId: Scalars['ID']
}>

export type MediaQuery = {
  __typename?: 'Query'
  media?: {
    __typename?: 'Media'
    id: string
    createdAt: Date
    updatedAt: Date
    title: string
    type: MediaType
    publicationStatus: PublicationStatus
    tags: Array<MediaTags>
    date?: Date | null
    startTrim?: number | null
    endTrim?: number | null
    liveStreamId?: string | null
    videoClipId?: string | null
    imageId?: string | null
    thumbnailId?: string | null
    ownerOrganismId?: string | null
    pinned?: boolean | null
    liveStream?: {
      __typename?: 'LiveStream'
      id: string
      playbackUrl: string
      ingestUrl?: string | null
      wildmokaEventID?: string | null
      videoEngine: VideoEngine
      state: LiveStreamState
      streamId?: string | null
      sveBroadcastId?: string | null
      sveChannelId?: string | null
      externalFeedProvider?: ExternalFeedType | null
      externalFeedUrl?: string | null
      event?: {
        __typename?: 'WMEvent'
        id: string
        name: string
        description?: string | null
        testStartDate: Date
        editoStartDate: Date
        editoStopDate: Date
        stopDate: Date
        state: WmEventState
        notifEmail?: string | null
        clipCreationAtEnd: boolean
        clipPublicationAtEnd: boolean
        livePublication: boolean
        stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
        live?: {
          __typename?: 'WMEventLive'
          id: string
          publishStatus: WmLiveState
          type: WmLiveType
          autoAnnounceDate?: Date | null
          template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
        } | null
        clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
      } | null
      sveBroadcast?: {
        __typename?: 'SVEBroadcast'
        id: string
        status: SveBroadcastState
        channelId: string
        startDate?: Date | null
        clipStartDate?: Date | null
        stopDate?: Date | null
        scheduledStartDate: Date
        scheduledClipStartDate: Date
        scheduledStopDate: Date
      } | null
      sveLiveMonitoring?: {
        __typename?: 'SVELiveMonitoring'
        alerts?: {
          __typename?: 'SVELiveMonitoringAlerts'
          audioNotDetected?: string | null
          initialProbeTakingLonger?: string | null
          videoNotDetected?: string | null
          RTMPHasNoAudioVideo?: string | null
          RTMPStreamNotFound?: string | null
        } | null
        stats?: {
          __typename?: 'SVELiveMonitoringStats'
          networkIn?: string | null
          droppedFrames?: string | null
          inputVideoFrameRate?: string | null
          outputAudioLevelLkfs?: string | null
        } | null
      } | null
    } | null
    videoClip?: {
      __typename?: 'VideoClip'
      id: string
      playbackUrl: string
      downloadLink: string
      status?: VideoClipStatus | null
      updatedAt: Date
      createdAt: Date
      fileId?: string | null
      error?: {
        __typename?: 'VideoClipError'
        guid: string
        workflowStatus: string
        workflowErrorAt: string
        errorMessage: string
        errorDetails: string
      } | null
      captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
      externalFeed?: {
        __typename?: 'ExternalFeed'
        externalFeedProvider: ExternalFeedType
        externalFeedUrl: string
      } | null
    } | null
    image?: {
      __typename?: 'File'
      id: string
      filename: string
      size: number
      mimetype: string
      downloadUrl: string
      thumbUrl?: string | null
      createdAt: Date
      metadata?: {
        __typename?: 'FileMetadata'
        title?: string | null
        description?: string | null
        aspect?: number | null
        width?: number | null
        height?: number | null
        sourceFileId?: string | null
      } | null
    } | null
    thumbnail?: {
      __typename?: 'Media'
      id: string
      image?: {
        __typename?: 'File'
        id: string
        filename: string
        size: number
        mimetype: string
        downloadUrl: string
        thumbUrl?: string | null
        createdAt: Date
        metadata?: {
          __typename?: 'FileMetadata'
          title?: string | null
          description?: string | null
          aspect?: number | null
          width?: number | null
          height?: number | null
          sourceFileId?: string | null
        } | null
      } | null
    } | null
    timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
  } | null
}

export type CreateWildmokaMediaLiveStreamMutationVariables = Exact<{
  input: CreateWildmokaMediaLiveStreamInput
}>

export type CreateWildmokaMediaLiveStreamMutation = {
  __typename?: 'Mutation'
  createWildmokaMediaLiveStream: {
    __typename?: 'Media'
    id: string
    createdAt: Date
    updatedAt: Date
    title: string
    type: MediaType
    publicationStatus: PublicationStatus
    tags: Array<MediaTags>
    date?: Date | null
    startTrim?: number | null
    endTrim?: number | null
    liveStreamId?: string | null
    videoClipId?: string | null
    imageId?: string | null
    thumbnailId?: string | null
    ownerOrganismId?: string | null
    pinned?: boolean | null
    liveStream?: {
      __typename?: 'LiveStream'
      id: string
      playbackUrl: string
      ingestUrl?: string | null
      wildmokaEventID?: string | null
      videoEngine: VideoEngine
      state: LiveStreamState
      streamId?: string | null
      sveBroadcastId?: string | null
      sveChannelId?: string | null
      externalFeedProvider?: ExternalFeedType | null
      externalFeedUrl?: string | null
      event?: {
        __typename?: 'WMEvent'
        id: string
        name: string
        description?: string | null
        testStartDate: Date
        editoStartDate: Date
        editoStopDate: Date
        stopDate: Date
        state: WmEventState
        notifEmail?: string | null
        clipCreationAtEnd: boolean
        clipPublicationAtEnd: boolean
        livePublication: boolean
        stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
        live?: {
          __typename?: 'WMEventLive'
          id: string
          publishStatus: WmLiveState
          type: WmLiveType
          autoAnnounceDate?: Date | null
          template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
        } | null
        clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
      } | null
      sveBroadcast?: {
        __typename?: 'SVEBroadcast'
        id: string
        status: SveBroadcastState
        channelId: string
        startDate?: Date | null
        clipStartDate?: Date | null
        stopDate?: Date | null
        scheduledStartDate: Date
        scheduledClipStartDate: Date
        scheduledStopDate: Date
      } | null
      sveLiveMonitoring?: {
        __typename?: 'SVELiveMonitoring'
        alerts?: {
          __typename?: 'SVELiveMonitoringAlerts'
          audioNotDetected?: string | null
          initialProbeTakingLonger?: string | null
          videoNotDetected?: string | null
          RTMPHasNoAudioVideo?: string | null
          RTMPStreamNotFound?: string | null
        } | null
        stats?: {
          __typename?: 'SVELiveMonitoringStats'
          networkIn?: string | null
          droppedFrames?: string | null
          inputVideoFrameRate?: string | null
          outputAudioLevelLkfs?: string | null
        } | null
      } | null
    } | null
    videoClip?: {
      __typename?: 'VideoClip'
      id: string
      playbackUrl: string
      downloadLink: string
      status?: VideoClipStatus | null
      updatedAt: Date
      createdAt: Date
      fileId?: string | null
      error?: {
        __typename?: 'VideoClipError'
        guid: string
        workflowStatus: string
        workflowErrorAt: string
        errorMessage: string
        errorDetails: string
      } | null
      captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
      externalFeed?: {
        __typename?: 'ExternalFeed'
        externalFeedProvider: ExternalFeedType
        externalFeedUrl: string
      } | null
    } | null
    image?: {
      __typename?: 'File'
      id: string
      filename: string
      size: number
      mimetype: string
      downloadUrl: string
      thumbUrl?: string | null
      createdAt: Date
      metadata?: {
        __typename?: 'FileMetadata'
        title?: string | null
        description?: string | null
        aspect?: number | null
        width?: number | null
        height?: number | null
        sourceFileId?: string | null
      } | null
    } | null
    thumbnail?: {
      __typename?: 'Media'
      id: string
      image?: {
        __typename?: 'File'
        id: string
        filename: string
        size: number
        mimetype: string
        downloadUrl: string
        thumbUrl?: string | null
        createdAt: Date
        metadata?: {
          __typename?: 'FileMetadata'
          title?: string | null
          description?: string | null
          aspect?: number | null
          width?: number | null
          height?: number | null
          sourceFileId?: string | null
        } | null
      } | null
    } | null
    timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
  }
}

export type CreateSveMediaLiveStreamMutationVariables = Exact<{
  input: CreateSveMediaLiveStreamInput
}>

export type CreateSveMediaLiveStreamMutation = {
  __typename?: 'Mutation'
  createSVEMediaLiveStream: {
    __typename?: 'Media'
    id: string
    createdAt: Date
    updatedAt: Date
    title: string
    type: MediaType
    publicationStatus: PublicationStatus
    tags: Array<MediaTags>
    date?: Date | null
    startTrim?: number | null
    endTrim?: number | null
    liveStreamId?: string | null
    videoClipId?: string | null
    imageId?: string | null
    thumbnailId?: string | null
    ownerOrganismId?: string | null
    pinned?: boolean | null
    liveStream?: {
      __typename?: 'LiveStream'
      id: string
      playbackUrl: string
      ingestUrl?: string | null
      wildmokaEventID?: string | null
      videoEngine: VideoEngine
      state: LiveStreamState
      streamId?: string | null
      sveBroadcastId?: string | null
      sveChannelId?: string | null
      externalFeedProvider?: ExternalFeedType | null
      externalFeedUrl?: string | null
      event?: {
        __typename?: 'WMEvent'
        id: string
        name: string
        description?: string | null
        testStartDate: Date
        editoStartDate: Date
        editoStopDate: Date
        stopDate: Date
        state: WmEventState
        notifEmail?: string | null
        clipCreationAtEnd: boolean
        clipPublicationAtEnd: boolean
        livePublication: boolean
        stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
        live?: {
          __typename?: 'WMEventLive'
          id: string
          publishStatus: WmLiveState
          type: WmLiveType
          autoAnnounceDate?: Date | null
          template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
        } | null
        clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
      } | null
      sveBroadcast?: {
        __typename?: 'SVEBroadcast'
        id: string
        status: SveBroadcastState
        channelId: string
        startDate?: Date | null
        clipStartDate?: Date | null
        stopDate?: Date | null
        scheduledStartDate: Date
        scheduledClipStartDate: Date
        scheduledStopDate: Date
      } | null
      sveLiveMonitoring?: {
        __typename?: 'SVELiveMonitoring'
        alerts?: {
          __typename?: 'SVELiveMonitoringAlerts'
          audioNotDetected?: string | null
          initialProbeTakingLonger?: string | null
          videoNotDetected?: string | null
          RTMPHasNoAudioVideo?: string | null
          RTMPStreamNotFound?: string | null
        } | null
        stats?: {
          __typename?: 'SVELiveMonitoringStats'
          networkIn?: string | null
          droppedFrames?: string | null
          inputVideoFrameRate?: string | null
          outputAudioLevelLkfs?: string | null
        } | null
      } | null
    } | null
    videoClip?: {
      __typename?: 'VideoClip'
      id: string
      playbackUrl: string
      downloadLink: string
      status?: VideoClipStatus | null
      updatedAt: Date
      createdAt: Date
      fileId?: string | null
      error?: {
        __typename?: 'VideoClipError'
        guid: string
        workflowStatus: string
        workflowErrorAt: string
        errorMessage: string
        errorDetails: string
      } | null
      captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
      externalFeed?: {
        __typename?: 'ExternalFeed'
        externalFeedProvider: ExternalFeedType
        externalFeedUrl: string
      } | null
    } | null
    image?: {
      __typename?: 'File'
      id: string
      filename: string
      size: number
      mimetype: string
      downloadUrl: string
      thumbUrl?: string | null
      createdAt: Date
      metadata?: {
        __typename?: 'FileMetadata'
        title?: string | null
        description?: string | null
        aspect?: number | null
        width?: number | null
        height?: number | null
        sourceFileId?: string | null
      } | null
    } | null
    thumbnail?: {
      __typename?: 'Media'
      id: string
      image?: {
        __typename?: 'File'
        id: string
        filename: string
        size: number
        mimetype: string
        downloadUrl: string
        thumbUrl?: string | null
        createdAt: Date
        metadata?: {
          __typename?: 'FileMetadata'
          title?: string | null
          description?: string | null
          aspect?: number | null
          width?: number | null
          height?: number | null
          sourceFileId?: string | null
        } | null
      } | null
    } | null
    timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
  }
}

export type CreateExternalFeedMediaLiveStreamMutationVariables = Exact<{
  input: CreateExternalFeedMediaLiveStreamInput
}>

export type CreateExternalFeedMediaLiveStreamMutation = {
  __typename?: 'Mutation'
  createExternalFeedMediaLiveStream: {
    __typename?: 'Media'
    id: string
    createdAt: Date
    updatedAt: Date
    title: string
    type: MediaType
    publicationStatus: PublicationStatus
    tags: Array<MediaTags>
    date?: Date | null
    startTrim?: number | null
    endTrim?: number | null
    liveStreamId?: string | null
    videoClipId?: string | null
    imageId?: string | null
    thumbnailId?: string | null
    ownerOrganismId?: string | null
    pinned?: boolean | null
    liveStream?: {
      __typename?: 'LiveStream'
      id: string
      playbackUrl: string
      ingestUrl?: string | null
      wildmokaEventID?: string | null
      videoEngine: VideoEngine
      state: LiveStreamState
      streamId?: string | null
      sveBroadcastId?: string | null
      sveChannelId?: string | null
      externalFeedProvider?: ExternalFeedType | null
      externalFeedUrl?: string | null
      event?: {
        __typename?: 'WMEvent'
        id: string
        name: string
        description?: string | null
        testStartDate: Date
        editoStartDate: Date
        editoStopDate: Date
        stopDate: Date
        state: WmEventState
        notifEmail?: string | null
        clipCreationAtEnd: boolean
        clipPublicationAtEnd: boolean
        livePublication: boolean
        stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
        live?: {
          __typename?: 'WMEventLive'
          id: string
          publishStatus: WmLiveState
          type: WmLiveType
          autoAnnounceDate?: Date | null
          template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
        } | null
        clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
      } | null
      sveBroadcast?: {
        __typename?: 'SVEBroadcast'
        id: string
        status: SveBroadcastState
        channelId: string
        startDate?: Date | null
        clipStartDate?: Date | null
        stopDate?: Date | null
        scheduledStartDate: Date
        scheduledClipStartDate: Date
        scheduledStopDate: Date
      } | null
      sveLiveMonitoring?: {
        __typename?: 'SVELiveMonitoring'
        alerts?: {
          __typename?: 'SVELiveMonitoringAlerts'
          audioNotDetected?: string | null
          initialProbeTakingLonger?: string | null
          videoNotDetected?: string | null
          RTMPHasNoAudioVideo?: string | null
          RTMPStreamNotFound?: string | null
        } | null
        stats?: {
          __typename?: 'SVELiveMonitoringStats'
          networkIn?: string | null
          droppedFrames?: string | null
          inputVideoFrameRate?: string | null
          outputAudioLevelLkfs?: string | null
        } | null
      } | null
    } | null
    videoClip?: {
      __typename?: 'VideoClip'
      id: string
      playbackUrl: string
      downloadLink: string
      status?: VideoClipStatus | null
      updatedAt: Date
      createdAt: Date
      fileId?: string | null
      error?: {
        __typename?: 'VideoClipError'
        guid: string
        workflowStatus: string
        workflowErrorAt: string
        errorMessage: string
        errorDetails: string
      } | null
      captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
      externalFeed?: {
        __typename?: 'ExternalFeed'
        externalFeedProvider: ExternalFeedType
        externalFeedUrl: string
      } | null
    } | null
    image?: {
      __typename?: 'File'
      id: string
      filename: string
      size: number
      mimetype: string
      downloadUrl: string
      thumbUrl?: string | null
      createdAt: Date
      metadata?: {
        __typename?: 'FileMetadata'
        title?: string | null
        description?: string | null
        aspect?: number | null
        width?: number | null
        height?: number | null
        sourceFileId?: string | null
      } | null
    } | null
    thumbnail?: {
      __typename?: 'Media'
      id: string
      image?: {
        __typename?: 'File'
        id: string
        filename: string
        size: number
        mimetype: string
        downloadUrl: string
        thumbUrl?: string | null
        createdAt: Date
        metadata?: {
          __typename?: 'FileMetadata'
          title?: string | null
          description?: string | null
          aspect?: number | null
          width?: number | null
          height?: number | null
          sourceFileId?: string | null
        } | null
      } | null
    } | null
    timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
  }
}

export type CreateMediaVideoClipMutationVariables = Exact<{
  input: CreateMediaVideoClipInput
}>

export type CreateMediaVideoClipMutation = {
  __typename?: 'Mutation'
  createMediaVideoClip: {
    __typename?: 'Media'
    id: string
    createdAt: Date
    updatedAt: Date
    title: string
    type: MediaType
    publicationStatus: PublicationStatus
    tags: Array<MediaTags>
    date?: Date | null
    startTrim?: number | null
    endTrim?: number | null
    liveStreamId?: string | null
    videoClipId?: string | null
    imageId?: string | null
    thumbnailId?: string | null
    ownerOrganismId?: string | null
    pinned?: boolean | null
    liveStream?: {
      __typename?: 'LiveStream'
      id: string
      playbackUrl: string
      ingestUrl?: string | null
      wildmokaEventID?: string | null
      videoEngine: VideoEngine
      state: LiveStreamState
      streamId?: string | null
      sveBroadcastId?: string | null
      sveChannelId?: string | null
      externalFeedProvider?: ExternalFeedType | null
      externalFeedUrl?: string | null
      event?: {
        __typename?: 'WMEvent'
        id: string
        name: string
        description?: string | null
        testStartDate: Date
        editoStartDate: Date
        editoStopDate: Date
        stopDate: Date
        state: WmEventState
        notifEmail?: string | null
        clipCreationAtEnd: boolean
        clipPublicationAtEnd: boolean
        livePublication: boolean
        stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
        live?: {
          __typename?: 'WMEventLive'
          id: string
          publishStatus: WmLiveState
          type: WmLiveType
          autoAnnounceDate?: Date | null
          template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
        } | null
        clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
      } | null
      sveBroadcast?: {
        __typename?: 'SVEBroadcast'
        id: string
        status: SveBroadcastState
        channelId: string
        startDate?: Date | null
        clipStartDate?: Date | null
        stopDate?: Date | null
        scheduledStartDate: Date
        scheduledClipStartDate: Date
        scheduledStopDate: Date
      } | null
      sveLiveMonitoring?: {
        __typename?: 'SVELiveMonitoring'
        alerts?: {
          __typename?: 'SVELiveMonitoringAlerts'
          audioNotDetected?: string | null
          initialProbeTakingLonger?: string | null
          videoNotDetected?: string | null
          RTMPHasNoAudioVideo?: string | null
          RTMPStreamNotFound?: string | null
        } | null
        stats?: {
          __typename?: 'SVELiveMonitoringStats'
          networkIn?: string | null
          droppedFrames?: string | null
          inputVideoFrameRate?: string | null
          outputAudioLevelLkfs?: string | null
        } | null
      } | null
    } | null
    videoClip?: {
      __typename?: 'VideoClip'
      id: string
      playbackUrl: string
      downloadLink: string
      status?: VideoClipStatus | null
      updatedAt: Date
      createdAt: Date
      fileId?: string | null
      error?: {
        __typename?: 'VideoClipError'
        guid: string
        workflowStatus: string
        workflowErrorAt: string
        errorMessage: string
        errorDetails: string
      } | null
      captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
      externalFeed?: {
        __typename?: 'ExternalFeed'
        externalFeedProvider: ExternalFeedType
        externalFeedUrl: string
      } | null
    } | null
    image?: {
      __typename?: 'File'
      id: string
      filename: string
      size: number
      mimetype: string
      downloadUrl: string
      thumbUrl?: string | null
      createdAt: Date
      metadata?: {
        __typename?: 'FileMetadata'
        title?: string | null
        description?: string | null
        aspect?: number | null
        width?: number | null
        height?: number | null
        sourceFileId?: string | null
      } | null
    } | null
    thumbnail?: {
      __typename?: 'Media'
      id: string
      image?: {
        __typename?: 'File'
        id: string
        filename: string
        size: number
        mimetype: string
        downloadUrl: string
        thumbUrl?: string | null
        createdAt: Date
        metadata?: {
          __typename?: 'FileMetadata'
          title?: string | null
          description?: string | null
          aspect?: number | null
          width?: number | null
          height?: number | null
          sourceFileId?: string | null
        } | null
      } | null
    } | null
    timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
  }
}

export type CreateMediaVideoClipExternalFeedMutationVariables = Exact<{
  input: CreateMediaVideoClipExternalFeedInput
}>

export type CreateMediaVideoClipExternalFeedMutation = {
  __typename?: 'Mutation'
  createMediaVideoClipExternalFeed: {
    __typename?: 'Media'
    id: string
    createdAt: Date
    updatedAt: Date
    title: string
    type: MediaType
    publicationStatus: PublicationStatus
    tags: Array<MediaTags>
    date?: Date | null
    startTrim?: number | null
    endTrim?: number | null
    liveStreamId?: string | null
    videoClipId?: string | null
    imageId?: string | null
    thumbnailId?: string | null
    ownerOrganismId?: string | null
    pinned?: boolean | null
    liveStream?: {
      __typename?: 'LiveStream'
      id: string
      playbackUrl: string
      ingestUrl?: string | null
      wildmokaEventID?: string | null
      videoEngine: VideoEngine
      state: LiveStreamState
      streamId?: string | null
      sveBroadcastId?: string | null
      sveChannelId?: string | null
      externalFeedProvider?: ExternalFeedType | null
      externalFeedUrl?: string | null
      event?: {
        __typename?: 'WMEvent'
        id: string
        name: string
        description?: string | null
        testStartDate: Date
        editoStartDate: Date
        editoStopDate: Date
        stopDate: Date
        state: WmEventState
        notifEmail?: string | null
        clipCreationAtEnd: boolean
        clipPublicationAtEnd: boolean
        livePublication: boolean
        stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
        live?: {
          __typename?: 'WMEventLive'
          id: string
          publishStatus: WmLiveState
          type: WmLiveType
          autoAnnounceDate?: Date | null
          template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
        } | null
        clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
      } | null
      sveBroadcast?: {
        __typename?: 'SVEBroadcast'
        id: string
        status: SveBroadcastState
        channelId: string
        startDate?: Date | null
        clipStartDate?: Date | null
        stopDate?: Date | null
        scheduledStartDate: Date
        scheduledClipStartDate: Date
        scheduledStopDate: Date
      } | null
      sveLiveMonitoring?: {
        __typename?: 'SVELiveMonitoring'
        alerts?: {
          __typename?: 'SVELiveMonitoringAlerts'
          audioNotDetected?: string | null
          initialProbeTakingLonger?: string | null
          videoNotDetected?: string | null
          RTMPHasNoAudioVideo?: string | null
          RTMPStreamNotFound?: string | null
        } | null
        stats?: {
          __typename?: 'SVELiveMonitoringStats'
          networkIn?: string | null
          droppedFrames?: string | null
          inputVideoFrameRate?: string | null
          outputAudioLevelLkfs?: string | null
        } | null
      } | null
    } | null
    videoClip?: {
      __typename?: 'VideoClip'
      id: string
      playbackUrl: string
      downloadLink: string
      status?: VideoClipStatus | null
      updatedAt: Date
      createdAt: Date
      fileId?: string | null
      error?: {
        __typename?: 'VideoClipError'
        guid: string
        workflowStatus: string
        workflowErrorAt: string
        errorMessage: string
        errorDetails: string
      } | null
      captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
      externalFeed?: {
        __typename?: 'ExternalFeed'
        externalFeedProvider: ExternalFeedType
        externalFeedUrl: string
      } | null
    } | null
    image?: {
      __typename?: 'File'
      id: string
      filename: string
      size: number
      mimetype: string
      downloadUrl: string
      thumbUrl?: string | null
      createdAt: Date
      metadata?: {
        __typename?: 'FileMetadata'
        title?: string | null
        description?: string | null
        aspect?: number | null
        width?: number | null
        height?: number | null
        sourceFileId?: string | null
      } | null
    } | null
    thumbnail?: {
      __typename?: 'Media'
      id: string
      image?: {
        __typename?: 'File'
        id: string
        filename: string
        size: number
        mimetype: string
        downloadUrl: string
        thumbUrl?: string | null
        createdAt: Date
        metadata?: {
          __typename?: 'FileMetadata'
          title?: string | null
          description?: string | null
          aspect?: number | null
          width?: number | null
          height?: number | null
          sourceFileId?: string | null
        } | null
      } | null
    } | null
    timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
  }
}

export type CreateMediaFileMutationVariables = Exact<{
  input: CreateMediaFileInput
}>

export type CreateMediaFileMutation = {
  __typename?: 'Mutation'
  createMediaFile: {
    __typename?: 'Media'
    id: string
    createdAt: Date
    updatedAt: Date
    title: string
    type: MediaType
    publicationStatus: PublicationStatus
    tags: Array<MediaTags>
    date?: Date | null
    startTrim?: number | null
    endTrim?: number | null
    liveStreamId?: string | null
    videoClipId?: string | null
    imageId?: string | null
    thumbnailId?: string | null
    ownerOrganismId?: string | null
    pinned?: boolean | null
    liveStream?: {
      __typename?: 'LiveStream'
      id: string
      playbackUrl: string
      ingestUrl?: string | null
      wildmokaEventID?: string | null
      videoEngine: VideoEngine
      state: LiveStreamState
      streamId?: string | null
      sveBroadcastId?: string | null
      sveChannelId?: string | null
      externalFeedProvider?: ExternalFeedType | null
      externalFeedUrl?: string | null
      event?: {
        __typename?: 'WMEvent'
        id: string
        name: string
        description?: string | null
        testStartDate: Date
        editoStartDate: Date
        editoStopDate: Date
        stopDate: Date
        state: WmEventState
        notifEmail?: string | null
        clipCreationAtEnd: boolean
        clipPublicationAtEnd: boolean
        livePublication: boolean
        stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
        live?: {
          __typename?: 'WMEventLive'
          id: string
          publishStatus: WmLiveState
          type: WmLiveType
          autoAnnounceDate?: Date | null
          template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
        } | null
        clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
      } | null
      sveBroadcast?: {
        __typename?: 'SVEBroadcast'
        id: string
        status: SveBroadcastState
        channelId: string
        startDate?: Date | null
        clipStartDate?: Date | null
        stopDate?: Date | null
        scheduledStartDate: Date
        scheduledClipStartDate: Date
        scheduledStopDate: Date
      } | null
      sveLiveMonitoring?: {
        __typename?: 'SVELiveMonitoring'
        alerts?: {
          __typename?: 'SVELiveMonitoringAlerts'
          audioNotDetected?: string | null
          initialProbeTakingLonger?: string | null
          videoNotDetected?: string | null
          RTMPHasNoAudioVideo?: string | null
          RTMPStreamNotFound?: string | null
        } | null
        stats?: {
          __typename?: 'SVELiveMonitoringStats'
          networkIn?: string | null
          droppedFrames?: string | null
          inputVideoFrameRate?: string | null
          outputAudioLevelLkfs?: string | null
        } | null
      } | null
    } | null
    videoClip?: {
      __typename?: 'VideoClip'
      id: string
      playbackUrl: string
      downloadLink: string
      status?: VideoClipStatus | null
      updatedAt: Date
      createdAt: Date
      fileId?: string | null
      error?: {
        __typename?: 'VideoClipError'
        guid: string
        workflowStatus: string
        workflowErrorAt: string
        errorMessage: string
        errorDetails: string
      } | null
      captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
      externalFeed?: {
        __typename?: 'ExternalFeed'
        externalFeedProvider: ExternalFeedType
        externalFeedUrl: string
      } | null
    } | null
    image?: {
      __typename?: 'File'
      id: string
      filename: string
      size: number
      mimetype: string
      downloadUrl: string
      thumbUrl?: string | null
      createdAt: Date
      metadata?: {
        __typename?: 'FileMetadata'
        title?: string | null
        description?: string | null
        aspect?: number | null
        width?: number | null
        height?: number | null
        sourceFileId?: string | null
      } | null
    } | null
    thumbnail?: {
      __typename?: 'Media'
      id: string
      image?: {
        __typename?: 'File'
        id: string
        filename: string
        size: number
        mimetype: string
        downloadUrl: string
        thumbUrl?: string | null
        createdAt: Date
        metadata?: {
          __typename?: 'FileMetadata'
          title?: string | null
          description?: string | null
          aspect?: number | null
          width?: number | null
          height?: number | null
          sourceFileId?: string | null
        } | null
      } | null
    } | null
    timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
  }
}

export type TogglePinMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type TogglePinMutation = { __typename?: 'Mutation'; togglePin: boolean }

export type NewsFragment = {
  __typename: 'News'
  id: string
  organismId?: string | null
  defaultLocale?: string | null
  title: string
  subtitle: string
  description: string
  content: string
  rawContent: string
  publicationStatus: PublicationStatus
  coverImageId: string
  createdAt: Date
  updatedAt: Date
  organism?: {
    __typename?: 'Organism'
    id: string
    type: OrganismType
    title: string
    description?: string | null
    officialWebSiteUrl: string
  } | null
  titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
  subtitleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
  descriptionT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
  contentT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
  rawContentT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
  coverImage?: {
    __typename?: 'Media'
    id: string
    image?: { __typename?: 'File'; id: string; thumbUrl?: string | null; downloadUrl: string } | null
  } | null
}

export type NewssQueryVariables = Exact<{
  filters?: InputMaybe<NewsFilters>
  offset?: InputMaybe<Scalars['Int']>
  first?: InputMaybe<Scalars['Int']>
  search?: InputMaybe<Scalars['String']>
}>

export type NewssQuery = {
  __typename?: 'Query'
  newss?: {
    __typename?: 'NewsPageResult'
    totalCount?: number | null
    news: Array<{
      __typename: 'News'
      id: string
      organismId?: string | null
      defaultLocale?: string | null
      title: string
      subtitle: string
      description: string
      content: string
      rawContent: string
      publicationStatus: PublicationStatus
      coverImageId: string
      createdAt: Date
      updatedAt: Date
      organism?: {
        __typename?: 'Organism'
        id: string
        type: OrganismType
        title: string
        description?: string | null
        officialWebSiteUrl: string
      } | null
      titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
      subtitleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
      descriptionT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
      contentT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
      rawContentT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
      coverImage?: {
        __typename?: 'Media'
        id: string
        image?: { __typename?: 'File'; id: string; thumbUrl?: string | null; downloadUrl: string } | null
      } | null
    }>
  } | null
}

export type NewsQueryVariables = Exact<{
  newsId: Scalars['ID']
}>

export type NewsQuery = {
  __typename?: 'Query'
  news?: {
    __typename: 'News'
    id: string
    organismId?: string | null
    defaultLocale?: string | null
    title: string
    subtitle: string
    description: string
    content: string
    rawContent: string
    publicationStatus: PublicationStatus
    coverImageId: string
    createdAt: Date
    updatedAt: Date
    organism?: {
      __typename?: 'Organism'
      id: string
      type: OrganismType
      title: string
      description?: string | null
      officialWebSiteUrl: string
    } | null
    titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    subtitleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    descriptionT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    contentT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    rawContentT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    coverImage?: {
      __typename?: 'Media'
      id: string
      image?: { __typename?: 'File'; id: string; thumbUrl?: string | null; downloadUrl: string } | null
    } | null
  } | null
}

export type CreateNewsMutationVariables = Exact<{
  input: CreateNewsInput
}>

export type CreateNewsMutation = {
  __typename?: 'Mutation'
  createNews: {
    __typename: 'News'
    id: string
    organismId?: string | null
    defaultLocale?: string | null
    title: string
    subtitle: string
    description: string
    content: string
    rawContent: string
    publicationStatus: PublicationStatus
    coverImageId: string
    createdAt: Date
    updatedAt: Date
    organism?: {
      __typename?: 'Organism'
      id: string
      type: OrganismType
      title: string
      description?: string | null
      officialWebSiteUrl: string
    } | null
    titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    subtitleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    descriptionT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    contentT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    rawContentT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    coverImage?: {
      __typename?: 'Media'
      id: string
      image?: { __typename?: 'File'; id: string; thumbUrl?: string | null; downloadUrl: string } | null
    } | null
  }
}

export type UpdateNewsMutationVariables = Exact<{
  input: UpdateNewsInput
}>

export type UpdateNewsMutation = {
  __typename?: 'Mutation'
  updateNews: {
    __typename: 'News'
    id: string
    organismId?: string | null
    defaultLocale?: string | null
    title: string
    subtitle: string
    description: string
    content: string
    rawContent: string
    publicationStatus: PublicationStatus
    coverImageId: string
    createdAt: Date
    updatedAt: Date
    organism?: {
      __typename?: 'Organism'
      id: string
      type: OrganismType
      title: string
      description?: string | null
      officialWebSiteUrl: string
    } | null
    titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    subtitleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    descriptionT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    contentT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    rawContentT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    coverImage?: {
      __typename?: 'Media'
      id: string
      image?: { __typename?: 'File'; id: string; thumbUrl?: string | null; downloadUrl: string } | null
    } | null
  }
}

export type DeleteNewsMutationVariables = Exact<{
  newsId: Scalars['ID']
}>

export type DeleteNewsMutation = { __typename?: 'Mutation'; deleteNews?: { __typename?: 'News'; id: string } | null }

export type CreateTwitterWidgetMutationVariables = Exact<{
  tweetUrl: Scalars['String']
}>

export type CreateTwitterWidgetMutation = { __typename?: 'Mutation'; createTwitterWidget: string }

export type OrganismContentFragment = {
  __typename?: 'Organism'
  id: string
  type: OrganismType
  whiteLabel: boolean
  title: string
  description?: string | null
  publicationStatus: PublicationStatus
  defaultLocale?: string | null
  onFront: boolean
  frontPosition?: number | null
  officialWebSiteUrl: string
  sportIds: Array<string>
  forbiddenKeyWords?: Array<string> | null
  enableExternalSources?: boolean | null
  availableExternalSources?: Array<WidgetExternalContentSource> | null
  streamsIds?: Array<string> | null
  wmProfileId?: string | null
  sveChannelIds?: Array<string> | null
  organismPageApplicationDesignId?: string | null
  contentPageApplicationDesignId?: string | null
  adminsAutomatedEmails?: boolean | null
  visibleInMultisportApplication?: boolean | null
  titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
  descriptionT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
  logo: {
    __typename?: 'Logo'
    coloredImage?: {
      __typename?: 'Media'
      id: string
      createdAt: Date
      updatedAt: Date
      title: string
      type: MediaType
      publicationStatus: PublicationStatus
      tags: Array<MediaTags>
      date?: Date | null
      startTrim?: number | null
      endTrim?: number | null
      liveStreamId?: string | null
      videoClipId?: string | null
      imageId?: string | null
      thumbnailId?: string | null
      ownerOrganismId?: string | null
      pinned?: boolean | null
      liveStream?: {
        __typename?: 'LiveStream'
        id: string
        playbackUrl: string
        ingestUrl?: string | null
        wildmokaEventID?: string | null
        videoEngine: VideoEngine
        state: LiveStreamState
        streamId?: string | null
        sveBroadcastId?: string | null
        sveChannelId?: string | null
        externalFeedProvider?: ExternalFeedType | null
        externalFeedUrl?: string | null
        event?: {
          __typename?: 'WMEvent'
          id: string
          name: string
          description?: string | null
          testStartDate: Date
          editoStartDate: Date
          editoStopDate: Date
          stopDate: Date
          state: WmEventState
          notifEmail?: string | null
          clipCreationAtEnd: boolean
          clipPublicationAtEnd: boolean
          livePublication: boolean
          stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
          live?: {
            __typename?: 'WMEventLive'
            id: string
            publishStatus: WmLiveState
            type: WmLiveType
            autoAnnounceDate?: Date | null
            template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
          } | null
          clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
        } | null
        sveBroadcast?: {
          __typename?: 'SVEBroadcast'
          id: string
          status: SveBroadcastState
          channelId: string
          startDate?: Date | null
          clipStartDate?: Date | null
          stopDate?: Date | null
          scheduledStartDate: Date
          scheduledClipStartDate: Date
          scheduledStopDate: Date
        } | null
        sveLiveMonitoring?: {
          __typename?: 'SVELiveMonitoring'
          alerts?: {
            __typename?: 'SVELiveMonitoringAlerts'
            audioNotDetected?: string | null
            initialProbeTakingLonger?: string | null
            videoNotDetected?: string | null
            RTMPHasNoAudioVideo?: string | null
            RTMPStreamNotFound?: string | null
          } | null
          stats?: {
            __typename?: 'SVELiveMonitoringStats'
            networkIn?: string | null
            droppedFrames?: string | null
            inputVideoFrameRate?: string | null
            outputAudioLevelLkfs?: string | null
          } | null
        } | null
      } | null
      videoClip?: {
        __typename?: 'VideoClip'
        id: string
        playbackUrl: string
        downloadLink: string
        status?: VideoClipStatus | null
        updatedAt: Date
        createdAt: Date
        fileId?: string | null
        error?: {
          __typename?: 'VideoClipError'
          guid: string
          workflowStatus: string
          workflowErrorAt: string
          errorMessage: string
          errorDetails: string
        } | null
        captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
        externalFeed?: {
          __typename?: 'ExternalFeed'
          externalFeedProvider: ExternalFeedType
          externalFeedUrl: string
        } | null
      } | null
      image?: {
        __typename?: 'File'
        id: string
        filename: string
        size: number
        mimetype: string
        downloadUrl: string
        thumbUrl?: string | null
        createdAt: Date
        metadata?: {
          __typename?: 'FileMetadata'
          title?: string | null
          description?: string | null
          aspect?: number | null
          width?: number | null
          height?: number | null
          sourceFileId?: string | null
        } | null
      } | null
      thumbnail?: {
        __typename?: 'Media'
        id: string
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
      } | null
      timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
    } | null
    transparentDarkImage?: {
      __typename?: 'Media'
      id: string
      createdAt: Date
      updatedAt: Date
      title: string
      type: MediaType
      publicationStatus: PublicationStatus
      tags: Array<MediaTags>
      date?: Date | null
      startTrim?: number | null
      endTrim?: number | null
      liveStreamId?: string | null
      videoClipId?: string | null
      imageId?: string | null
      thumbnailId?: string | null
      ownerOrganismId?: string | null
      pinned?: boolean | null
      liveStream?: {
        __typename?: 'LiveStream'
        id: string
        playbackUrl: string
        ingestUrl?: string | null
        wildmokaEventID?: string | null
        videoEngine: VideoEngine
        state: LiveStreamState
        streamId?: string | null
        sveBroadcastId?: string | null
        sveChannelId?: string | null
        externalFeedProvider?: ExternalFeedType | null
        externalFeedUrl?: string | null
        event?: {
          __typename?: 'WMEvent'
          id: string
          name: string
          description?: string | null
          testStartDate: Date
          editoStartDate: Date
          editoStopDate: Date
          stopDate: Date
          state: WmEventState
          notifEmail?: string | null
          clipCreationAtEnd: boolean
          clipPublicationAtEnd: boolean
          livePublication: boolean
          stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
          live?: {
            __typename?: 'WMEventLive'
            id: string
            publishStatus: WmLiveState
            type: WmLiveType
            autoAnnounceDate?: Date | null
            template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
          } | null
          clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
        } | null
        sveBroadcast?: {
          __typename?: 'SVEBroadcast'
          id: string
          status: SveBroadcastState
          channelId: string
          startDate?: Date | null
          clipStartDate?: Date | null
          stopDate?: Date | null
          scheduledStartDate: Date
          scheduledClipStartDate: Date
          scheduledStopDate: Date
        } | null
        sveLiveMonitoring?: {
          __typename?: 'SVELiveMonitoring'
          alerts?: {
            __typename?: 'SVELiveMonitoringAlerts'
            audioNotDetected?: string | null
            initialProbeTakingLonger?: string | null
            videoNotDetected?: string | null
            RTMPHasNoAudioVideo?: string | null
            RTMPStreamNotFound?: string | null
          } | null
          stats?: {
            __typename?: 'SVELiveMonitoringStats'
            networkIn?: string | null
            droppedFrames?: string | null
            inputVideoFrameRate?: string | null
            outputAudioLevelLkfs?: string | null
          } | null
        } | null
      } | null
      videoClip?: {
        __typename?: 'VideoClip'
        id: string
        playbackUrl: string
        downloadLink: string
        status?: VideoClipStatus | null
        updatedAt: Date
        createdAt: Date
        fileId?: string | null
        error?: {
          __typename?: 'VideoClipError'
          guid: string
          workflowStatus: string
          workflowErrorAt: string
          errorMessage: string
          errorDetails: string
        } | null
        captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
        externalFeed?: {
          __typename?: 'ExternalFeed'
          externalFeedProvider: ExternalFeedType
          externalFeedUrl: string
        } | null
      } | null
      image?: {
        __typename?: 'File'
        id: string
        filename: string
        size: number
        mimetype: string
        downloadUrl: string
        thumbUrl?: string | null
        createdAt: Date
        metadata?: {
          __typename?: 'FileMetadata'
          title?: string | null
          description?: string | null
          aspect?: number | null
          width?: number | null
          height?: number | null
          sourceFileId?: string | null
        } | null
      } | null
      thumbnail?: {
        __typename?: 'Media'
        id: string
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
      } | null
      timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
    } | null
    transparentLightImage?: {
      __typename?: 'Media'
      id: string
      createdAt: Date
      updatedAt: Date
      title: string
      type: MediaType
      publicationStatus: PublicationStatus
      tags: Array<MediaTags>
      date?: Date | null
      startTrim?: number | null
      endTrim?: number | null
      liveStreamId?: string | null
      videoClipId?: string | null
      imageId?: string | null
      thumbnailId?: string | null
      ownerOrganismId?: string | null
      pinned?: boolean | null
      liveStream?: {
        __typename?: 'LiveStream'
        id: string
        playbackUrl: string
        ingestUrl?: string | null
        wildmokaEventID?: string | null
        videoEngine: VideoEngine
        state: LiveStreamState
        streamId?: string | null
        sveBroadcastId?: string | null
        sveChannelId?: string | null
        externalFeedProvider?: ExternalFeedType | null
        externalFeedUrl?: string | null
        event?: {
          __typename?: 'WMEvent'
          id: string
          name: string
          description?: string | null
          testStartDate: Date
          editoStartDate: Date
          editoStopDate: Date
          stopDate: Date
          state: WmEventState
          notifEmail?: string | null
          clipCreationAtEnd: boolean
          clipPublicationAtEnd: boolean
          livePublication: boolean
          stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
          live?: {
            __typename?: 'WMEventLive'
            id: string
            publishStatus: WmLiveState
            type: WmLiveType
            autoAnnounceDate?: Date | null
            template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
          } | null
          clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
        } | null
        sveBroadcast?: {
          __typename?: 'SVEBroadcast'
          id: string
          status: SveBroadcastState
          channelId: string
          startDate?: Date | null
          clipStartDate?: Date | null
          stopDate?: Date | null
          scheduledStartDate: Date
          scheduledClipStartDate: Date
          scheduledStopDate: Date
        } | null
        sveLiveMonitoring?: {
          __typename?: 'SVELiveMonitoring'
          alerts?: {
            __typename?: 'SVELiveMonitoringAlerts'
            audioNotDetected?: string | null
            initialProbeTakingLonger?: string | null
            videoNotDetected?: string | null
            RTMPHasNoAudioVideo?: string | null
            RTMPStreamNotFound?: string | null
          } | null
          stats?: {
            __typename?: 'SVELiveMonitoringStats'
            networkIn?: string | null
            droppedFrames?: string | null
            inputVideoFrameRate?: string | null
            outputAudioLevelLkfs?: string | null
          } | null
        } | null
      } | null
      videoClip?: {
        __typename?: 'VideoClip'
        id: string
        playbackUrl: string
        downloadLink: string
        status?: VideoClipStatus | null
        updatedAt: Date
        createdAt: Date
        fileId?: string | null
        error?: {
          __typename?: 'VideoClipError'
          guid: string
          workflowStatus: string
          workflowErrorAt: string
          errorMessage: string
          errorDetails: string
        } | null
        captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
        externalFeed?: {
          __typename?: 'ExternalFeed'
          externalFeedProvider: ExternalFeedType
          externalFeedUrl: string
        } | null
      } | null
      image?: {
        __typename?: 'File'
        id: string
        filename: string
        size: number
        mimetype: string
        downloadUrl: string
        thumbUrl?: string | null
        createdAt: Date
        metadata?: {
          __typename?: 'FileMetadata'
          title?: string | null
          description?: string | null
          aspect?: number | null
          width?: number | null
          height?: number | null
          sourceFileId?: string | null
        } | null
      } | null
      thumbnail?: {
        __typename?: 'Media'
        id: string
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
      } | null
      timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
    } | null
  }
  sports: Array<{ __typename?: 'Sport'; id: string }>
  bannerImage?: {
    __typename?: 'Media'
    id: string
    createdAt: Date
    updatedAt: Date
    title: string
    type: MediaType
    publicationStatus: PublicationStatus
    tags: Array<MediaTags>
    date?: Date | null
    startTrim?: number | null
    endTrim?: number | null
    liveStreamId?: string | null
    videoClipId?: string | null
    imageId?: string | null
    thumbnailId?: string | null
    ownerOrganismId?: string | null
    pinned?: boolean | null
    liveStream?: {
      __typename?: 'LiveStream'
      id: string
      playbackUrl: string
      ingestUrl?: string | null
      wildmokaEventID?: string | null
      videoEngine: VideoEngine
      state: LiveStreamState
      streamId?: string | null
      sveBroadcastId?: string | null
      sveChannelId?: string | null
      externalFeedProvider?: ExternalFeedType | null
      externalFeedUrl?: string | null
      event?: {
        __typename?: 'WMEvent'
        id: string
        name: string
        description?: string | null
        testStartDate: Date
        editoStartDate: Date
        editoStopDate: Date
        stopDate: Date
        state: WmEventState
        notifEmail?: string | null
        clipCreationAtEnd: boolean
        clipPublicationAtEnd: boolean
        livePublication: boolean
        stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
        live?: {
          __typename?: 'WMEventLive'
          id: string
          publishStatus: WmLiveState
          type: WmLiveType
          autoAnnounceDate?: Date | null
          template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
        } | null
        clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
      } | null
      sveBroadcast?: {
        __typename?: 'SVEBroadcast'
        id: string
        status: SveBroadcastState
        channelId: string
        startDate?: Date | null
        clipStartDate?: Date | null
        stopDate?: Date | null
        scheduledStartDate: Date
        scheduledClipStartDate: Date
        scheduledStopDate: Date
      } | null
      sveLiveMonitoring?: {
        __typename?: 'SVELiveMonitoring'
        alerts?: {
          __typename?: 'SVELiveMonitoringAlerts'
          audioNotDetected?: string | null
          initialProbeTakingLonger?: string | null
          videoNotDetected?: string | null
          RTMPHasNoAudioVideo?: string | null
          RTMPStreamNotFound?: string | null
        } | null
        stats?: {
          __typename?: 'SVELiveMonitoringStats'
          networkIn?: string | null
          droppedFrames?: string | null
          inputVideoFrameRate?: string | null
          outputAudioLevelLkfs?: string | null
        } | null
      } | null
    } | null
    videoClip?: {
      __typename?: 'VideoClip'
      id: string
      playbackUrl: string
      downloadLink: string
      status?: VideoClipStatus | null
      updatedAt: Date
      createdAt: Date
      fileId?: string | null
      error?: {
        __typename?: 'VideoClipError'
        guid: string
        workflowStatus: string
        workflowErrorAt: string
        errorMessage: string
        errorDetails: string
      } | null
      captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
      externalFeed?: {
        __typename?: 'ExternalFeed'
        externalFeedProvider: ExternalFeedType
        externalFeedUrl: string
      } | null
    } | null
    image?: {
      __typename?: 'File'
      id: string
      filename: string
      size: number
      mimetype: string
      downloadUrl: string
      thumbUrl?: string | null
      createdAt: Date
      metadata?: {
        __typename?: 'FileMetadata'
        title?: string | null
        description?: string | null
        aspect?: number | null
        width?: number | null
        height?: number | null
        sourceFileId?: string | null
      } | null
    } | null
    thumbnail?: {
      __typename?: 'Media'
      id: string
      image?: {
        __typename?: 'File'
        id: string
        filename: string
        size: number
        mimetype: string
        downloadUrl: string
        thumbUrl?: string | null
        createdAt: Date
        metadata?: {
          __typename?: 'FileMetadata'
          title?: string | null
          description?: string | null
          aspect?: number | null
          width?: number | null
          height?: number | null
          sourceFileId?: string | null
        } | null
      } | null
    } | null
    timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
  } | null
  adminBadge?: {
    __typename?: 'Media'
    id: string
    createdAt: Date
    updatedAt: Date
    title: string
    type: MediaType
    publicationStatus: PublicationStatus
    tags: Array<MediaTags>
    date?: Date | null
    startTrim?: number | null
    endTrim?: number | null
    liveStreamId?: string | null
    videoClipId?: string | null
    imageId?: string | null
    thumbnailId?: string | null
    ownerOrganismId?: string | null
    pinned?: boolean | null
    liveStream?: {
      __typename?: 'LiveStream'
      id: string
      playbackUrl: string
      ingestUrl?: string | null
      wildmokaEventID?: string | null
      videoEngine: VideoEngine
      state: LiveStreamState
      streamId?: string | null
      sveBroadcastId?: string | null
      sveChannelId?: string | null
      externalFeedProvider?: ExternalFeedType | null
      externalFeedUrl?: string | null
      event?: {
        __typename?: 'WMEvent'
        id: string
        name: string
        description?: string | null
        testStartDate: Date
        editoStartDate: Date
        editoStopDate: Date
        stopDate: Date
        state: WmEventState
        notifEmail?: string | null
        clipCreationAtEnd: boolean
        clipPublicationAtEnd: boolean
        livePublication: boolean
        stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
        live?: {
          __typename?: 'WMEventLive'
          id: string
          publishStatus: WmLiveState
          type: WmLiveType
          autoAnnounceDate?: Date | null
          template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
        } | null
        clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
      } | null
      sveBroadcast?: {
        __typename?: 'SVEBroadcast'
        id: string
        status: SveBroadcastState
        channelId: string
        startDate?: Date | null
        clipStartDate?: Date | null
        stopDate?: Date | null
        scheduledStartDate: Date
        scheduledClipStartDate: Date
        scheduledStopDate: Date
      } | null
      sveLiveMonitoring?: {
        __typename?: 'SVELiveMonitoring'
        alerts?: {
          __typename?: 'SVELiveMonitoringAlerts'
          audioNotDetected?: string | null
          initialProbeTakingLonger?: string | null
          videoNotDetected?: string | null
          RTMPHasNoAudioVideo?: string | null
          RTMPStreamNotFound?: string | null
        } | null
        stats?: {
          __typename?: 'SVELiveMonitoringStats'
          networkIn?: string | null
          droppedFrames?: string | null
          inputVideoFrameRate?: string | null
          outputAudioLevelLkfs?: string | null
        } | null
      } | null
    } | null
    videoClip?: {
      __typename?: 'VideoClip'
      id: string
      playbackUrl: string
      downloadLink: string
      status?: VideoClipStatus | null
      updatedAt: Date
      createdAt: Date
      fileId?: string | null
      error?: {
        __typename?: 'VideoClipError'
        guid: string
        workflowStatus: string
        workflowErrorAt: string
        errorMessage: string
        errorDetails: string
      } | null
      captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
      externalFeed?: {
        __typename?: 'ExternalFeed'
        externalFeedProvider: ExternalFeedType
        externalFeedUrl: string
      } | null
    } | null
    image?: {
      __typename?: 'File'
      id: string
      filename: string
      size: number
      mimetype: string
      downloadUrl: string
      thumbUrl?: string | null
      createdAt: Date
      metadata?: {
        __typename?: 'FileMetadata'
        title?: string | null
        description?: string | null
        aspect?: number | null
        width?: number | null
        height?: number | null
        sourceFileId?: string | null
      } | null
    } | null
    thumbnail?: {
      __typename?: 'Media'
      id: string
      image?: {
        __typename?: 'File'
        id: string
        filename: string
        size: number
        mimetype: string
        downloadUrl: string
        thumbUrl?: string | null
        createdAt: Date
        metadata?: {
          __typename?: 'FileMetadata'
          title?: string | null
          description?: string | null
          aspect?: number | null
          width?: number | null
          height?: number | null
          sourceFileId?: string | null
        } | null
      } | null
    } | null
    timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
  } | null
  bannerVideo?: {
    __typename?: 'Media'
    id: string
    videoClip?: { __typename?: 'VideoClip'; id: string; playbackUrl: string } | null
  } | null
  pageWidgets?: Array<{
    __typename?: 'CustomPageWidget'
    name: CustomPageWidgetName
    position: number
    display?: CustomPageDisplayTypes | null
    contentDisplay?: ContentSetContentDisplay | null
    cardsDisplay?: CardsDisplay | null
    options?: {
      __typename?: 'CustomPageWidgetOptions'
      editedCategoryId?: string | null
      sportItemSetType?: SportItemSetType | null
      replays?: {
        __typename?: 'ReplaysWidget'
        defaultLocale: string
        maxReplays?: number | null
        depthInDays?: number | null
        nameT: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }>
      } | null
      externalContent?: {
        __typename?: 'ExternalContentWidget'
        name?: string | null
        defaultLocale: string
        maxItemsCount: number
        source: WidgetExternalContentSource
        nameT: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }>
        period?: { __typename?: 'StartEndDates'; startDate: Date; endDate: Date } | null
        wecContent?: {
          __typename?: 'WECNewsContent'
          teamECMIds?: Array<string> | null
          driverECMIds?: Array<string> | null
          carECMIds?: Array<string> | null
          categoryECMIds?: Array<string> | null
          raceECMIds?: Array<string> | null
        } | null
        rssContent?: { __typename?: 'RSSFeedContent'; feedUrl: string } | null
      } | null
    } | null
  }> | null
  offers: Array<{
    __typename?: 'PurchasableOffer'
    id: string
    mode: OfferMode
    delay?: number | null
    expiredAt?: Date | null
    publicationStatus: PublicationStatus
    title: string
    defaultLocale?: string | null
    url?: string | null
    bannerImageId?: string | null
    thumbnailImageId?: string | null
    freeWithLicense: boolean
    position?: number | null
    titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    description: Array<{ __typename?: 'OfferDescription'; header: string; content: string }>
    descriptionT?: Array<{
      __typename?: 'ContentTranslationDescriptionPurchasableOffer'
      locale: string
      content?: Array<{ __typename?: 'OfferDescription'; header: string; content: string }> | null
    }> | null
    products: Array<{
      __typename?: 'Product'
      productId: string
      platform: Platform
      period?: Period | null
      publicationStatus: PublicationStatus
      purchasableOfferId?: string | null
      licensedUserOnly?: boolean | null
      premiumOf?: Array<string> | null
      prices: Array<{ __typename?: 'Price'; value: number; currency: string }>
    }>
  }>
  license?: { __typename?: 'OrganismLicense'; enabled: boolean; api?: LicenseApi | null } | null
  tags?: Array<{ __typename?: 'Tag'; id: string; title: string; subTags?: Array<string> | null }> | null
}

export type OrganismsQueryVariables = Exact<{
  filters?: InputMaybe<OrganismFilters>
}>

export type OrganismsQuery = {
  __typename?: 'Query'
  organisms?: {
    __typename?: 'OrganismPageResult'
    totalCount?: number | null
    organisms: Array<{
      __typename?: 'Organism'
      id: string
      type: OrganismType
      whiteLabel: boolean
      title: string
      description?: string | null
      publicationStatus: PublicationStatus
      defaultLocale?: string | null
      onFront: boolean
      frontPosition?: number | null
      officialWebSiteUrl: string
      sportIds: Array<string>
      forbiddenKeyWords?: Array<string> | null
      enableExternalSources?: boolean | null
      availableExternalSources?: Array<WidgetExternalContentSource> | null
      streamsIds?: Array<string> | null
      wmProfileId?: string | null
      sveChannelIds?: Array<string> | null
      organismPageApplicationDesignId?: string | null
      contentPageApplicationDesignId?: string | null
      adminsAutomatedEmails?: boolean | null
      visibleInMultisportApplication?: boolean | null
      titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
      descriptionT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
      logo: {
        __typename?: 'Logo'
        coloredImage?: {
          __typename?: 'Media'
          id: string
          createdAt: Date
          updatedAt: Date
          title: string
          type: MediaType
          publicationStatus: PublicationStatus
          tags: Array<MediaTags>
          date?: Date | null
          startTrim?: number | null
          endTrim?: number | null
          liveStreamId?: string | null
          videoClipId?: string | null
          imageId?: string | null
          thumbnailId?: string | null
          ownerOrganismId?: string | null
          pinned?: boolean | null
          liveStream?: {
            __typename?: 'LiveStream'
            id: string
            playbackUrl: string
            ingestUrl?: string | null
            wildmokaEventID?: string | null
            videoEngine: VideoEngine
            state: LiveStreamState
            streamId?: string | null
            sveBroadcastId?: string | null
            sveChannelId?: string | null
            externalFeedProvider?: ExternalFeedType | null
            externalFeedUrl?: string | null
            event?: {
              __typename?: 'WMEvent'
              id: string
              name: string
              description?: string | null
              testStartDate: Date
              editoStartDate: Date
              editoStopDate: Date
              stopDate: Date
              state: WmEventState
              notifEmail?: string | null
              clipCreationAtEnd: boolean
              clipPublicationAtEnd: boolean
              livePublication: boolean
              stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
              live?: {
                __typename?: 'WMEventLive'
                id: string
                publishStatus: WmLiveState
                type: WmLiveType
                autoAnnounceDate?: Date | null
                template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
              } | null
              clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
            } | null
            sveBroadcast?: {
              __typename?: 'SVEBroadcast'
              id: string
              status: SveBroadcastState
              channelId: string
              startDate?: Date | null
              clipStartDate?: Date | null
              stopDate?: Date | null
              scheduledStartDate: Date
              scheduledClipStartDate: Date
              scheduledStopDate: Date
            } | null
            sveLiveMonitoring?: {
              __typename?: 'SVELiveMonitoring'
              alerts?: {
                __typename?: 'SVELiveMonitoringAlerts'
                audioNotDetected?: string | null
                initialProbeTakingLonger?: string | null
                videoNotDetected?: string | null
                RTMPHasNoAudioVideo?: string | null
                RTMPStreamNotFound?: string | null
              } | null
              stats?: {
                __typename?: 'SVELiveMonitoringStats'
                networkIn?: string | null
                droppedFrames?: string | null
                inputVideoFrameRate?: string | null
                outputAudioLevelLkfs?: string | null
              } | null
            } | null
          } | null
          videoClip?: {
            __typename?: 'VideoClip'
            id: string
            playbackUrl: string
            downloadLink: string
            status?: VideoClipStatus | null
            updatedAt: Date
            createdAt: Date
            fileId?: string | null
            error?: {
              __typename?: 'VideoClipError'
              guid: string
              workflowStatus: string
              workflowErrorAt: string
              errorMessage: string
              errorDetails: string
            } | null
            captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
            externalFeed?: {
              __typename?: 'ExternalFeed'
              externalFeedProvider: ExternalFeedType
              externalFeedUrl: string
            } | null
          } | null
          image?: {
            __typename?: 'File'
            id: string
            filename: string
            size: number
            mimetype: string
            downloadUrl: string
            thumbUrl?: string | null
            createdAt: Date
            metadata?: {
              __typename?: 'FileMetadata'
              title?: string | null
              description?: string | null
              aspect?: number | null
              width?: number | null
              height?: number | null
              sourceFileId?: string | null
            } | null
          } | null
          thumbnail?: {
            __typename?: 'Media'
            id: string
            image?: {
              __typename?: 'File'
              id: string
              filename: string
              size: number
              mimetype: string
              downloadUrl: string
              thumbUrl?: string | null
              createdAt: Date
              metadata?: {
                __typename?: 'FileMetadata'
                title?: string | null
                description?: string | null
                aspect?: number | null
                width?: number | null
                height?: number | null
                sourceFileId?: string | null
              } | null
            } | null
          } | null
          timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
        } | null
        transparentDarkImage?: {
          __typename?: 'Media'
          id: string
          createdAt: Date
          updatedAt: Date
          title: string
          type: MediaType
          publicationStatus: PublicationStatus
          tags: Array<MediaTags>
          date?: Date | null
          startTrim?: number | null
          endTrim?: number | null
          liveStreamId?: string | null
          videoClipId?: string | null
          imageId?: string | null
          thumbnailId?: string | null
          ownerOrganismId?: string | null
          pinned?: boolean | null
          liveStream?: {
            __typename?: 'LiveStream'
            id: string
            playbackUrl: string
            ingestUrl?: string | null
            wildmokaEventID?: string | null
            videoEngine: VideoEngine
            state: LiveStreamState
            streamId?: string | null
            sveBroadcastId?: string | null
            sveChannelId?: string | null
            externalFeedProvider?: ExternalFeedType | null
            externalFeedUrl?: string | null
            event?: {
              __typename?: 'WMEvent'
              id: string
              name: string
              description?: string | null
              testStartDate: Date
              editoStartDate: Date
              editoStopDate: Date
              stopDate: Date
              state: WmEventState
              notifEmail?: string | null
              clipCreationAtEnd: boolean
              clipPublicationAtEnd: boolean
              livePublication: boolean
              stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
              live?: {
                __typename?: 'WMEventLive'
                id: string
                publishStatus: WmLiveState
                type: WmLiveType
                autoAnnounceDate?: Date | null
                template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
              } | null
              clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
            } | null
            sveBroadcast?: {
              __typename?: 'SVEBroadcast'
              id: string
              status: SveBroadcastState
              channelId: string
              startDate?: Date | null
              clipStartDate?: Date | null
              stopDate?: Date | null
              scheduledStartDate: Date
              scheduledClipStartDate: Date
              scheduledStopDate: Date
            } | null
            sveLiveMonitoring?: {
              __typename?: 'SVELiveMonitoring'
              alerts?: {
                __typename?: 'SVELiveMonitoringAlerts'
                audioNotDetected?: string | null
                initialProbeTakingLonger?: string | null
                videoNotDetected?: string | null
                RTMPHasNoAudioVideo?: string | null
                RTMPStreamNotFound?: string | null
              } | null
              stats?: {
                __typename?: 'SVELiveMonitoringStats'
                networkIn?: string | null
                droppedFrames?: string | null
                inputVideoFrameRate?: string | null
                outputAudioLevelLkfs?: string | null
              } | null
            } | null
          } | null
          videoClip?: {
            __typename?: 'VideoClip'
            id: string
            playbackUrl: string
            downloadLink: string
            status?: VideoClipStatus | null
            updatedAt: Date
            createdAt: Date
            fileId?: string | null
            error?: {
              __typename?: 'VideoClipError'
              guid: string
              workflowStatus: string
              workflowErrorAt: string
              errorMessage: string
              errorDetails: string
            } | null
            captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
            externalFeed?: {
              __typename?: 'ExternalFeed'
              externalFeedProvider: ExternalFeedType
              externalFeedUrl: string
            } | null
          } | null
          image?: {
            __typename?: 'File'
            id: string
            filename: string
            size: number
            mimetype: string
            downloadUrl: string
            thumbUrl?: string | null
            createdAt: Date
            metadata?: {
              __typename?: 'FileMetadata'
              title?: string | null
              description?: string | null
              aspect?: number | null
              width?: number | null
              height?: number | null
              sourceFileId?: string | null
            } | null
          } | null
          thumbnail?: {
            __typename?: 'Media'
            id: string
            image?: {
              __typename?: 'File'
              id: string
              filename: string
              size: number
              mimetype: string
              downloadUrl: string
              thumbUrl?: string | null
              createdAt: Date
              metadata?: {
                __typename?: 'FileMetadata'
                title?: string | null
                description?: string | null
                aspect?: number | null
                width?: number | null
                height?: number | null
                sourceFileId?: string | null
              } | null
            } | null
          } | null
          timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
        } | null
        transparentLightImage?: {
          __typename?: 'Media'
          id: string
          createdAt: Date
          updatedAt: Date
          title: string
          type: MediaType
          publicationStatus: PublicationStatus
          tags: Array<MediaTags>
          date?: Date | null
          startTrim?: number | null
          endTrim?: number | null
          liveStreamId?: string | null
          videoClipId?: string | null
          imageId?: string | null
          thumbnailId?: string | null
          ownerOrganismId?: string | null
          pinned?: boolean | null
          liveStream?: {
            __typename?: 'LiveStream'
            id: string
            playbackUrl: string
            ingestUrl?: string | null
            wildmokaEventID?: string | null
            videoEngine: VideoEngine
            state: LiveStreamState
            streamId?: string | null
            sveBroadcastId?: string | null
            sveChannelId?: string | null
            externalFeedProvider?: ExternalFeedType | null
            externalFeedUrl?: string | null
            event?: {
              __typename?: 'WMEvent'
              id: string
              name: string
              description?: string | null
              testStartDate: Date
              editoStartDate: Date
              editoStopDate: Date
              stopDate: Date
              state: WmEventState
              notifEmail?: string | null
              clipCreationAtEnd: boolean
              clipPublicationAtEnd: boolean
              livePublication: boolean
              stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
              live?: {
                __typename?: 'WMEventLive'
                id: string
                publishStatus: WmLiveState
                type: WmLiveType
                autoAnnounceDate?: Date | null
                template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
              } | null
              clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
            } | null
            sveBroadcast?: {
              __typename?: 'SVEBroadcast'
              id: string
              status: SveBroadcastState
              channelId: string
              startDate?: Date | null
              clipStartDate?: Date | null
              stopDate?: Date | null
              scheduledStartDate: Date
              scheduledClipStartDate: Date
              scheduledStopDate: Date
            } | null
            sveLiveMonitoring?: {
              __typename?: 'SVELiveMonitoring'
              alerts?: {
                __typename?: 'SVELiveMonitoringAlerts'
                audioNotDetected?: string | null
                initialProbeTakingLonger?: string | null
                videoNotDetected?: string | null
                RTMPHasNoAudioVideo?: string | null
                RTMPStreamNotFound?: string | null
              } | null
              stats?: {
                __typename?: 'SVELiveMonitoringStats'
                networkIn?: string | null
                droppedFrames?: string | null
                inputVideoFrameRate?: string | null
                outputAudioLevelLkfs?: string | null
              } | null
            } | null
          } | null
          videoClip?: {
            __typename?: 'VideoClip'
            id: string
            playbackUrl: string
            downloadLink: string
            status?: VideoClipStatus | null
            updatedAt: Date
            createdAt: Date
            fileId?: string | null
            error?: {
              __typename?: 'VideoClipError'
              guid: string
              workflowStatus: string
              workflowErrorAt: string
              errorMessage: string
              errorDetails: string
            } | null
            captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
            externalFeed?: {
              __typename?: 'ExternalFeed'
              externalFeedProvider: ExternalFeedType
              externalFeedUrl: string
            } | null
          } | null
          image?: {
            __typename?: 'File'
            id: string
            filename: string
            size: number
            mimetype: string
            downloadUrl: string
            thumbUrl?: string | null
            createdAt: Date
            metadata?: {
              __typename?: 'FileMetadata'
              title?: string | null
              description?: string | null
              aspect?: number | null
              width?: number | null
              height?: number | null
              sourceFileId?: string | null
            } | null
          } | null
          thumbnail?: {
            __typename?: 'Media'
            id: string
            image?: {
              __typename?: 'File'
              id: string
              filename: string
              size: number
              mimetype: string
              downloadUrl: string
              thumbUrl?: string | null
              createdAt: Date
              metadata?: {
                __typename?: 'FileMetadata'
                title?: string | null
                description?: string | null
                aspect?: number | null
                width?: number | null
                height?: number | null
                sourceFileId?: string | null
              } | null
            } | null
          } | null
          timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
        } | null
      }
      sports: Array<{ __typename?: 'Sport'; id: string }>
      bannerImage?: {
        __typename?: 'Media'
        id: string
        createdAt: Date
        updatedAt: Date
        title: string
        type: MediaType
        publicationStatus: PublicationStatus
        tags: Array<MediaTags>
        date?: Date | null
        startTrim?: number | null
        endTrim?: number | null
        liveStreamId?: string | null
        videoClipId?: string | null
        imageId?: string | null
        thumbnailId?: string | null
        ownerOrganismId?: string | null
        pinned?: boolean | null
        liveStream?: {
          __typename?: 'LiveStream'
          id: string
          playbackUrl: string
          ingestUrl?: string | null
          wildmokaEventID?: string | null
          videoEngine: VideoEngine
          state: LiveStreamState
          streamId?: string | null
          sveBroadcastId?: string | null
          sveChannelId?: string | null
          externalFeedProvider?: ExternalFeedType | null
          externalFeedUrl?: string | null
          event?: {
            __typename?: 'WMEvent'
            id: string
            name: string
            description?: string | null
            testStartDate: Date
            editoStartDate: Date
            editoStopDate: Date
            stopDate: Date
            state: WmEventState
            notifEmail?: string | null
            clipCreationAtEnd: boolean
            clipPublicationAtEnd: boolean
            livePublication: boolean
            stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
            live?: {
              __typename?: 'WMEventLive'
              id: string
              publishStatus: WmLiveState
              type: WmLiveType
              autoAnnounceDate?: Date | null
              template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
            } | null
            clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
          } | null
          sveBroadcast?: {
            __typename?: 'SVEBroadcast'
            id: string
            status: SveBroadcastState
            channelId: string
            startDate?: Date | null
            clipStartDate?: Date | null
            stopDate?: Date | null
            scheduledStartDate: Date
            scheduledClipStartDate: Date
            scheduledStopDate: Date
          } | null
          sveLiveMonitoring?: {
            __typename?: 'SVELiveMonitoring'
            alerts?: {
              __typename?: 'SVELiveMonitoringAlerts'
              audioNotDetected?: string | null
              initialProbeTakingLonger?: string | null
              videoNotDetected?: string | null
              RTMPHasNoAudioVideo?: string | null
              RTMPStreamNotFound?: string | null
            } | null
            stats?: {
              __typename?: 'SVELiveMonitoringStats'
              networkIn?: string | null
              droppedFrames?: string | null
              inputVideoFrameRate?: string | null
              outputAudioLevelLkfs?: string | null
            } | null
          } | null
        } | null
        videoClip?: {
          __typename?: 'VideoClip'
          id: string
          playbackUrl: string
          downloadLink: string
          status?: VideoClipStatus | null
          updatedAt: Date
          createdAt: Date
          fileId?: string | null
          error?: {
            __typename?: 'VideoClipError'
            guid: string
            workflowStatus: string
            workflowErrorAt: string
            errorMessage: string
            errorDetails: string
          } | null
          captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
          externalFeed?: {
            __typename?: 'ExternalFeed'
            externalFeedProvider: ExternalFeedType
            externalFeedUrl: string
          } | null
        } | null
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
        thumbnail?: {
          __typename?: 'Media'
          id: string
          image?: {
            __typename?: 'File'
            id: string
            filename: string
            size: number
            mimetype: string
            downloadUrl: string
            thumbUrl?: string | null
            createdAt: Date
            metadata?: {
              __typename?: 'FileMetadata'
              title?: string | null
              description?: string | null
              aspect?: number | null
              width?: number | null
              height?: number | null
              sourceFileId?: string | null
            } | null
          } | null
        } | null
        timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
      } | null
      adminBadge?: {
        __typename?: 'Media'
        id: string
        createdAt: Date
        updatedAt: Date
        title: string
        type: MediaType
        publicationStatus: PublicationStatus
        tags: Array<MediaTags>
        date?: Date | null
        startTrim?: number | null
        endTrim?: number | null
        liveStreamId?: string | null
        videoClipId?: string | null
        imageId?: string | null
        thumbnailId?: string | null
        ownerOrganismId?: string | null
        pinned?: boolean | null
        liveStream?: {
          __typename?: 'LiveStream'
          id: string
          playbackUrl: string
          ingestUrl?: string | null
          wildmokaEventID?: string | null
          videoEngine: VideoEngine
          state: LiveStreamState
          streamId?: string | null
          sveBroadcastId?: string | null
          sveChannelId?: string | null
          externalFeedProvider?: ExternalFeedType | null
          externalFeedUrl?: string | null
          event?: {
            __typename?: 'WMEvent'
            id: string
            name: string
            description?: string | null
            testStartDate: Date
            editoStartDate: Date
            editoStopDate: Date
            stopDate: Date
            state: WmEventState
            notifEmail?: string | null
            clipCreationAtEnd: boolean
            clipPublicationAtEnd: boolean
            livePublication: boolean
            stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
            live?: {
              __typename?: 'WMEventLive'
              id: string
              publishStatus: WmLiveState
              type: WmLiveType
              autoAnnounceDate?: Date | null
              template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
            } | null
            clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
          } | null
          sveBroadcast?: {
            __typename?: 'SVEBroadcast'
            id: string
            status: SveBroadcastState
            channelId: string
            startDate?: Date | null
            clipStartDate?: Date | null
            stopDate?: Date | null
            scheduledStartDate: Date
            scheduledClipStartDate: Date
            scheduledStopDate: Date
          } | null
          sveLiveMonitoring?: {
            __typename?: 'SVELiveMonitoring'
            alerts?: {
              __typename?: 'SVELiveMonitoringAlerts'
              audioNotDetected?: string | null
              initialProbeTakingLonger?: string | null
              videoNotDetected?: string | null
              RTMPHasNoAudioVideo?: string | null
              RTMPStreamNotFound?: string | null
            } | null
            stats?: {
              __typename?: 'SVELiveMonitoringStats'
              networkIn?: string | null
              droppedFrames?: string | null
              inputVideoFrameRate?: string | null
              outputAudioLevelLkfs?: string | null
            } | null
          } | null
        } | null
        videoClip?: {
          __typename?: 'VideoClip'
          id: string
          playbackUrl: string
          downloadLink: string
          status?: VideoClipStatus | null
          updatedAt: Date
          createdAt: Date
          fileId?: string | null
          error?: {
            __typename?: 'VideoClipError'
            guid: string
            workflowStatus: string
            workflowErrorAt: string
            errorMessage: string
            errorDetails: string
          } | null
          captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
          externalFeed?: {
            __typename?: 'ExternalFeed'
            externalFeedProvider: ExternalFeedType
            externalFeedUrl: string
          } | null
        } | null
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
        thumbnail?: {
          __typename?: 'Media'
          id: string
          image?: {
            __typename?: 'File'
            id: string
            filename: string
            size: number
            mimetype: string
            downloadUrl: string
            thumbUrl?: string | null
            createdAt: Date
            metadata?: {
              __typename?: 'FileMetadata'
              title?: string | null
              description?: string | null
              aspect?: number | null
              width?: number | null
              height?: number | null
              sourceFileId?: string | null
            } | null
          } | null
        } | null
        timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
      } | null
      bannerVideo?: {
        __typename?: 'Media'
        id: string
        videoClip?: { __typename?: 'VideoClip'; id: string; playbackUrl: string } | null
      } | null
      pageWidgets?: Array<{
        __typename?: 'CustomPageWidget'
        name: CustomPageWidgetName
        position: number
        display?: CustomPageDisplayTypes | null
        contentDisplay?: ContentSetContentDisplay | null
        cardsDisplay?: CardsDisplay | null
        options?: {
          __typename?: 'CustomPageWidgetOptions'
          editedCategoryId?: string | null
          sportItemSetType?: SportItemSetType | null
          replays?: {
            __typename?: 'ReplaysWidget'
            defaultLocale: string
            maxReplays?: number | null
            depthInDays?: number | null
            nameT: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }>
          } | null
          externalContent?: {
            __typename?: 'ExternalContentWidget'
            name?: string | null
            defaultLocale: string
            maxItemsCount: number
            source: WidgetExternalContentSource
            nameT: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }>
            period?: { __typename?: 'StartEndDates'; startDate: Date; endDate: Date } | null
            wecContent?: {
              __typename?: 'WECNewsContent'
              teamECMIds?: Array<string> | null
              driverECMIds?: Array<string> | null
              carECMIds?: Array<string> | null
              categoryECMIds?: Array<string> | null
              raceECMIds?: Array<string> | null
            } | null
            rssContent?: { __typename?: 'RSSFeedContent'; feedUrl: string } | null
          } | null
        } | null
      }> | null
      offers: Array<{
        __typename?: 'PurchasableOffer'
        id: string
        mode: OfferMode
        delay?: number | null
        expiredAt?: Date | null
        publicationStatus: PublicationStatus
        title: string
        defaultLocale?: string | null
        url?: string | null
        bannerImageId?: string | null
        thumbnailImageId?: string | null
        freeWithLicense: boolean
        position?: number | null
        titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
        description: Array<{ __typename?: 'OfferDescription'; header: string; content: string }>
        descriptionT?: Array<{
          __typename?: 'ContentTranslationDescriptionPurchasableOffer'
          locale: string
          content?: Array<{ __typename?: 'OfferDescription'; header: string; content: string }> | null
        }> | null
        products: Array<{
          __typename?: 'Product'
          productId: string
          platform: Platform
          period?: Period | null
          publicationStatus: PublicationStatus
          purchasableOfferId?: string | null
          licensedUserOnly?: boolean | null
          premiumOf?: Array<string> | null
          prices: Array<{ __typename?: 'Price'; value: number; currency: string }>
        }>
      }>
      license?: { __typename?: 'OrganismLicense'; enabled: boolean; api?: LicenseApi | null } | null
      tags?: Array<{ __typename?: 'Tag'; id: string; title: string; subTags?: Array<string> | null }> | null
    }>
  } | null
}

export type CreateOrganismMutationVariables = Exact<{
  input: CreateOrganismInput
}>

export type CreateOrganismMutation = {
  __typename?: 'Mutation'
  createOrganism: {
    __typename?: 'Organism'
    id: string
    type: OrganismType
    whiteLabel: boolean
    title: string
    description?: string | null
    publicationStatus: PublicationStatus
    defaultLocale?: string | null
    onFront: boolean
    frontPosition?: number | null
    officialWebSiteUrl: string
    sportIds: Array<string>
    forbiddenKeyWords?: Array<string> | null
    enableExternalSources?: boolean | null
    availableExternalSources?: Array<WidgetExternalContentSource> | null
    streamsIds?: Array<string> | null
    wmProfileId?: string | null
    sveChannelIds?: Array<string> | null
    organismPageApplicationDesignId?: string | null
    contentPageApplicationDesignId?: string | null
    adminsAutomatedEmails?: boolean | null
    visibleInMultisportApplication?: boolean | null
    titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    descriptionT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    logo: {
      __typename?: 'Logo'
      coloredImage?: {
        __typename?: 'Media'
        id: string
        createdAt: Date
        updatedAt: Date
        title: string
        type: MediaType
        publicationStatus: PublicationStatus
        tags: Array<MediaTags>
        date?: Date | null
        startTrim?: number | null
        endTrim?: number | null
        liveStreamId?: string | null
        videoClipId?: string | null
        imageId?: string | null
        thumbnailId?: string | null
        ownerOrganismId?: string | null
        pinned?: boolean | null
        liveStream?: {
          __typename?: 'LiveStream'
          id: string
          playbackUrl: string
          ingestUrl?: string | null
          wildmokaEventID?: string | null
          videoEngine: VideoEngine
          state: LiveStreamState
          streamId?: string | null
          sveBroadcastId?: string | null
          sveChannelId?: string | null
          externalFeedProvider?: ExternalFeedType | null
          externalFeedUrl?: string | null
          event?: {
            __typename?: 'WMEvent'
            id: string
            name: string
            description?: string | null
            testStartDate: Date
            editoStartDate: Date
            editoStopDate: Date
            stopDate: Date
            state: WmEventState
            notifEmail?: string | null
            clipCreationAtEnd: boolean
            clipPublicationAtEnd: boolean
            livePublication: boolean
            stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
            live?: {
              __typename?: 'WMEventLive'
              id: string
              publishStatus: WmLiveState
              type: WmLiveType
              autoAnnounceDate?: Date | null
              template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
            } | null
            clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
          } | null
          sveBroadcast?: {
            __typename?: 'SVEBroadcast'
            id: string
            status: SveBroadcastState
            channelId: string
            startDate?: Date | null
            clipStartDate?: Date | null
            stopDate?: Date | null
            scheduledStartDate: Date
            scheduledClipStartDate: Date
            scheduledStopDate: Date
          } | null
          sveLiveMonitoring?: {
            __typename?: 'SVELiveMonitoring'
            alerts?: {
              __typename?: 'SVELiveMonitoringAlerts'
              audioNotDetected?: string | null
              initialProbeTakingLonger?: string | null
              videoNotDetected?: string | null
              RTMPHasNoAudioVideo?: string | null
              RTMPStreamNotFound?: string | null
            } | null
            stats?: {
              __typename?: 'SVELiveMonitoringStats'
              networkIn?: string | null
              droppedFrames?: string | null
              inputVideoFrameRate?: string | null
              outputAudioLevelLkfs?: string | null
            } | null
          } | null
        } | null
        videoClip?: {
          __typename?: 'VideoClip'
          id: string
          playbackUrl: string
          downloadLink: string
          status?: VideoClipStatus | null
          updatedAt: Date
          createdAt: Date
          fileId?: string | null
          error?: {
            __typename?: 'VideoClipError'
            guid: string
            workflowStatus: string
            workflowErrorAt: string
            errorMessage: string
            errorDetails: string
          } | null
          captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
          externalFeed?: {
            __typename?: 'ExternalFeed'
            externalFeedProvider: ExternalFeedType
            externalFeedUrl: string
          } | null
        } | null
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
        thumbnail?: {
          __typename?: 'Media'
          id: string
          image?: {
            __typename?: 'File'
            id: string
            filename: string
            size: number
            mimetype: string
            downloadUrl: string
            thumbUrl?: string | null
            createdAt: Date
            metadata?: {
              __typename?: 'FileMetadata'
              title?: string | null
              description?: string | null
              aspect?: number | null
              width?: number | null
              height?: number | null
              sourceFileId?: string | null
            } | null
          } | null
        } | null
        timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
      } | null
      transparentDarkImage?: {
        __typename?: 'Media'
        id: string
        createdAt: Date
        updatedAt: Date
        title: string
        type: MediaType
        publicationStatus: PublicationStatus
        tags: Array<MediaTags>
        date?: Date | null
        startTrim?: number | null
        endTrim?: number | null
        liveStreamId?: string | null
        videoClipId?: string | null
        imageId?: string | null
        thumbnailId?: string | null
        ownerOrganismId?: string | null
        pinned?: boolean | null
        liveStream?: {
          __typename?: 'LiveStream'
          id: string
          playbackUrl: string
          ingestUrl?: string | null
          wildmokaEventID?: string | null
          videoEngine: VideoEngine
          state: LiveStreamState
          streamId?: string | null
          sveBroadcastId?: string | null
          sveChannelId?: string | null
          externalFeedProvider?: ExternalFeedType | null
          externalFeedUrl?: string | null
          event?: {
            __typename?: 'WMEvent'
            id: string
            name: string
            description?: string | null
            testStartDate: Date
            editoStartDate: Date
            editoStopDate: Date
            stopDate: Date
            state: WmEventState
            notifEmail?: string | null
            clipCreationAtEnd: boolean
            clipPublicationAtEnd: boolean
            livePublication: boolean
            stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
            live?: {
              __typename?: 'WMEventLive'
              id: string
              publishStatus: WmLiveState
              type: WmLiveType
              autoAnnounceDate?: Date | null
              template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
            } | null
            clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
          } | null
          sveBroadcast?: {
            __typename?: 'SVEBroadcast'
            id: string
            status: SveBroadcastState
            channelId: string
            startDate?: Date | null
            clipStartDate?: Date | null
            stopDate?: Date | null
            scheduledStartDate: Date
            scheduledClipStartDate: Date
            scheduledStopDate: Date
          } | null
          sveLiveMonitoring?: {
            __typename?: 'SVELiveMonitoring'
            alerts?: {
              __typename?: 'SVELiveMonitoringAlerts'
              audioNotDetected?: string | null
              initialProbeTakingLonger?: string | null
              videoNotDetected?: string | null
              RTMPHasNoAudioVideo?: string | null
              RTMPStreamNotFound?: string | null
            } | null
            stats?: {
              __typename?: 'SVELiveMonitoringStats'
              networkIn?: string | null
              droppedFrames?: string | null
              inputVideoFrameRate?: string | null
              outputAudioLevelLkfs?: string | null
            } | null
          } | null
        } | null
        videoClip?: {
          __typename?: 'VideoClip'
          id: string
          playbackUrl: string
          downloadLink: string
          status?: VideoClipStatus | null
          updatedAt: Date
          createdAt: Date
          fileId?: string | null
          error?: {
            __typename?: 'VideoClipError'
            guid: string
            workflowStatus: string
            workflowErrorAt: string
            errorMessage: string
            errorDetails: string
          } | null
          captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
          externalFeed?: {
            __typename?: 'ExternalFeed'
            externalFeedProvider: ExternalFeedType
            externalFeedUrl: string
          } | null
        } | null
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
        thumbnail?: {
          __typename?: 'Media'
          id: string
          image?: {
            __typename?: 'File'
            id: string
            filename: string
            size: number
            mimetype: string
            downloadUrl: string
            thumbUrl?: string | null
            createdAt: Date
            metadata?: {
              __typename?: 'FileMetadata'
              title?: string | null
              description?: string | null
              aspect?: number | null
              width?: number | null
              height?: number | null
              sourceFileId?: string | null
            } | null
          } | null
        } | null
        timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
      } | null
      transparentLightImage?: {
        __typename?: 'Media'
        id: string
        createdAt: Date
        updatedAt: Date
        title: string
        type: MediaType
        publicationStatus: PublicationStatus
        tags: Array<MediaTags>
        date?: Date | null
        startTrim?: number | null
        endTrim?: number | null
        liveStreamId?: string | null
        videoClipId?: string | null
        imageId?: string | null
        thumbnailId?: string | null
        ownerOrganismId?: string | null
        pinned?: boolean | null
        liveStream?: {
          __typename?: 'LiveStream'
          id: string
          playbackUrl: string
          ingestUrl?: string | null
          wildmokaEventID?: string | null
          videoEngine: VideoEngine
          state: LiveStreamState
          streamId?: string | null
          sveBroadcastId?: string | null
          sveChannelId?: string | null
          externalFeedProvider?: ExternalFeedType | null
          externalFeedUrl?: string | null
          event?: {
            __typename?: 'WMEvent'
            id: string
            name: string
            description?: string | null
            testStartDate: Date
            editoStartDate: Date
            editoStopDate: Date
            stopDate: Date
            state: WmEventState
            notifEmail?: string | null
            clipCreationAtEnd: boolean
            clipPublicationAtEnd: boolean
            livePublication: boolean
            stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
            live?: {
              __typename?: 'WMEventLive'
              id: string
              publishStatus: WmLiveState
              type: WmLiveType
              autoAnnounceDate?: Date | null
              template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
            } | null
            clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
          } | null
          sveBroadcast?: {
            __typename?: 'SVEBroadcast'
            id: string
            status: SveBroadcastState
            channelId: string
            startDate?: Date | null
            clipStartDate?: Date | null
            stopDate?: Date | null
            scheduledStartDate: Date
            scheduledClipStartDate: Date
            scheduledStopDate: Date
          } | null
          sveLiveMonitoring?: {
            __typename?: 'SVELiveMonitoring'
            alerts?: {
              __typename?: 'SVELiveMonitoringAlerts'
              audioNotDetected?: string | null
              initialProbeTakingLonger?: string | null
              videoNotDetected?: string | null
              RTMPHasNoAudioVideo?: string | null
              RTMPStreamNotFound?: string | null
            } | null
            stats?: {
              __typename?: 'SVELiveMonitoringStats'
              networkIn?: string | null
              droppedFrames?: string | null
              inputVideoFrameRate?: string | null
              outputAudioLevelLkfs?: string | null
            } | null
          } | null
        } | null
        videoClip?: {
          __typename?: 'VideoClip'
          id: string
          playbackUrl: string
          downloadLink: string
          status?: VideoClipStatus | null
          updatedAt: Date
          createdAt: Date
          fileId?: string | null
          error?: {
            __typename?: 'VideoClipError'
            guid: string
            workflowStatus: string
            workflowErrorAt: string
            errorMessage: string
            errorDetails: string
          } | null
          captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
          externalFeed?: {
            __typename?: 'ExternalFeed'
            externalFeedProvider: ExternalFeedType
            externalFeedUrl: string
          } | null
        } | null
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
        thumbnail?: {
          __typename?: 'Media'
          id: string
          image?: {
            __typename?: 'File'
            id: string
            filename: string
            size: number
            mimetype: string
            downloadUrl: string
            thumbUrl?: string | null
            createdAt: Date
            metadata?: {
              __typename?: 'FileMetadata'
              title?: string | null
              description?: string | null
              aspect?: number | null
              width?: number | null
              height?: number | null
              sourceFileId?: string | null
            } | null
          } | null
        } | null
        timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
      } | null
    }
    sports: Array<{ __typename?: 'Sport'; id: string }>
    bannerImage?: {
      __typename?: 'Media'
      id: string
      createdAt: Date
      updatedAt: Date
      title: string
      type: MediaType
      publicationStatus: PublicationStatus
      tags: Array<MediaTags>
      date?: Date | null
      startTrim?: number | null
      endTrim?: number | null
      liveStreamId?: string | null
      videoClipId?: string | null
      imageId?: string | null
      thumbnailId?: string | null
      ownerOrganismId?: string | null
      pinned?: boolean | null
      liveStream?: {
        __typename?: 'LiveStream'
        id: string
        playbackUrl: string
        ingestUrl?: string | null
        wildmokaEventID?: string | null
        videoEngine: VideoEngine
        state: LiveStreamState
        streamId?: string | null
        sveBroadcastId?: string | null
        sveChannelId?: string | null
        externalFeedProvider?: ExternalFeedType | null
        externalFeedUrl?: string | null
        event?: {
          __typename?: 'WMEvent'
          id: string
          name: string
          description?: string | null
          testStartDate: Date
          editoStartDate: Date
          editoStopDate: Date
          stopDate: Date
          state: WmEventState
          notifEmail?: string | null
          clipCreationAtEnd: boolean
          clipPublicationAtEnd: boolean
          livePublication: boolean
          stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
          live?: {
            __typename?: 'WMEventLive'
            id: string
            publishStatus: WmLiveState
            type: WmLiveType
            autoAnnounceDate?: Date | null
            template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
          } | null
          clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
        } | null
        sveBroadcast?: {
          __typename?: 'SVEBroadcast'
          id: string
          status: SveBroadcastState
          channelId: string
          startDate?: Date | null
          clipStartDate?: Date | null
          stopDate?: Date | null
          scheduledStartDate: Date
          scheduledClipStartDate: Date
          scheduledStopDate: Date
        } | null
        sveLiveMonitoring?: {
          __typename?: 'SVELiveMonitoring'
          alerts?: {
            __typename?: 'SVELiveMonitoringAlerts'
            audioNotDetected?: string | null
            initialProbeTakingLonger?: string | null
            videoNotDetected?: string | null
            RTMPHasNoAudioVideo?: string | null
            RTMPStreamNotFound?: string | null
          } | null
          stats?: {
            __typename?: 'SVELiveMonitoringStats'
            networkIn?: string | null
            droppedFrames?: string | null
            inputVideoFrameRate?: string | null
            outputAudioLevelLkfs?: string | null
          } | null
        } | null
      } | null
      videoClip?: {
        __typename?: 'VideoClip'
        id: string
        playbackUrl: string
        downloadLink: string
        status?: VideoClipStatus | null
        updatedAt: Date
        createdAt: Date
        fileId?: string | null
        error?: {
          __typename?: 'VideoClipError'
          guid: string
          workflowStatus: string
          workflowErrorAt: string
          errorMessage: string
          errorDetails: string
        } | null
        captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
        externalFeed?: {
          __typename?: 'ExternalFeed'
          externalFeedProvider: ExternalFeedType
          externalFeedUrl: string
        } | null
      } | null
      image?: {
        __typename?: 'File'
        id: string
        filename: string
        size: number
        mimetype: string
        downloadUrl: string
        thumbUrl?: string | null
        createdAt: Date
        metadata?: {
          __typename?: 'FileMetadata'
          title?: string | null
          description?: string | null
          aspect?: number | null
          width?: number | null
          height?: number | null
          sourceFileId?: string | null
        } | null
      } | null
      thumbnail?: {
        __typename?: 'Media'
        id: string
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
      } | null
      timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
    } | null
    adminBadge?: {
      __typename?: 'Media'
      id: string
      createdAt: Date
      updatedAt: Date
      title: string
      type: MediaType
      publicationStatus: PublicationStatus
      tags: Array<MediaTags>
      date?: Date | null
      startTrim?: number | null
      endTrim?: number | null
      liveStreamId?: string | null
      videoClipId?: string | null
      imageId?: string | null
      thumbnailId?: string | null
      ownerOrganismId?: string | null
      pinned?: boolean | null
      liveStream?: {
        __typename?: 'LiveStream'
        id: string
        playbackUrl: string
        ingestUrl?: string | null
        wildmokaEventID?: string | null
        videoEngine: VideoEngine
        state: LiveStreamState
        streamId?: string | null
        sveBroadcastId?: string | null
        sveChannelId?: string | null
        externalFeedProvider?: ExternalFeedType | null
        externalFeedUrl?: string | null
        event?: {
          __typename?: 'WMEvent'
          id: string
          name: string
          description?: string | null
          testStartDate: Date
          editoStartDate: Date
          editoStopDate: Date
          stopDate: Date
          state: WmEventState
          notifEmail?: string | null
          clipCreationAtEnd: boolean
          clipPublicationAtEnd: boolean
          livePublication: boolean
          stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
          live?: {
            __typename?: 'WMEventLive'
            id: string
            publishStatus: WmLiveState
            type: WmLiveType
            autoAnnounceDate?: Date | null
            template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
          } | null
          clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
        } | null
        sveBroadcast?: {
          __typename?: 'SVEBroadcast'
          id: string
          status: SveBroadcastState
          channelId: string
          startDate?: Date | null
          clipStartDate?: Date | null
          stopDate?: Date | null
          scheduledStartDate: Date
          scheduledClipStartDate: Date
          scheduledStopDate: Date
        } | null
        sveLiveMonitoring?: {
          __typename?: 'SVELiveMonitoring'
          alerts?: {
            __typename?: 'SVELiveMonitoringAlerts'
            audioNotDetected?: string | null
            initialProbeTakingLonger?: string | null
            videoNotDetected?: string | null
            RTMPHasNoAudioVideo?: string | null
            RTMPStreamNotFound?: string | null
          } | null
          stats?: {
            __typename?: 'SVELiveMonitoringStats'
            networkIn?: string | null
            droppedFrames?: string | null
            inputVideoFrameRate?: string | null
            outputAudioLevelLkfs?: string | null
          } | null
        } | null
      } | null
      videoClip?: {
        __typename?: 'VideoClip'
        id: string
        playbackUrl: string
        downloadLink: string
        status?: VideoClipStatus | null
        updatedAt: Date
        createdAt: Date
        fileId?: string | null
        error?: {
          __typename?: 'VideoClipError'
          guid: string
          workflowStatus: string
          workflowErrorAt: string
          errorMessage: string
          errorDetails: string
        } | null
        captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
        externalFeed?: {
          __typename?: 'ExternalFeed'
          externalFeedProvider: ExternalFeedType
          externalFeedUrl: string
        } | null
      } | null
      image?: {
        __typename?: 'File'
        id: string
        filename: string
        size: number
        mimetype: string
        downloadUrl: string
        thumbUrl?: string | null
        createdAt: Date
        metadata?: {
          __typename?: 'FileMetadata'
          title?: string | null
          description?: string | null
          aspect?: number | null
          width?: number | null
          height?: number | null
          sourceFileId?: string | null
        } | null
      } | null
      thumbnail?: {
        __typename?: 'Media'
        id: string
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
      } | null
      timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
    } | null
    bannerVideo?: {
      __typename?: 'Media'
      id: string
      videoClip?: { __typename?: 'VideoClip'; id: string; playbackUrl: string } | null
    } | null
    pageWidgets?: Array<{
      __typename?: 'CustomPageWidget'
      name: CustomPageWidgetName
      position: number
      display?: CustomPageDisplayTypes | null
      contentDisplay?: ContentSetContentDisplay | null
      cardsDisplay?: CardsDisplay | null
      options?: {
        __typename?: 'CustomPageWidgetOptions'
        editedCategoryId?: string | null
        sportItemSetType?: SportItemSetType | null
        replays?: {
          __typename?: 'ReplaysWidget'
          defaultLocale: string
          maxReplays?: number | null
          depthInDays?: number | null
          nameT: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }>
        } | null
        externalContent?: {
          __typename?: 'ExternalContentWidget'
          name?: string | null
          defaultLocale: string
          maxItemsCount: number
          source: WidgetExternalContentSource
          nameT: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }>
          period?: { __typename?: 'StartEndDates'; startDate: Date; endDate: Date } | null
          wecContent?: {
            __typename?: 'WECNewsContent'
            teamECMIds?: Array<string> | null
            driverECMIds?: Array<string> | null
            carECMIds?: Array<string> | null
            categoryECMIds?: Array<string> | null
            raceECMIds?: Array<string> | null
          } | null
          rssContent?: { __typename?: 'RSSFeedContent'; feedUrl: string } | null
        } | null
      } | null
    }> | null
    offers: Array<{
      __typename?: 'PurchasableOffer'
      id: string
      mode: OfferMode
      delay?: number | null
      expiredAt?: Date | null
      publicationStatus: PublicationStatus
      title: string
      defaultLocale?: string | null
      url?: string | null
      bannerImageId?: string | null
      thumbnailImageId?: string | null
      freeWithLicense: boolean
      position?: number | null
      titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
      description: Array<{ __typename?: 'OfferDescription'; header: string; content: string }>
      descriptionT?: Array<{
        __typename?: 'ContentTranslationDescriptionPurchasableOffer'
        locale: string
        content?: Array<{ __typename?: 'OfferDescription'; header: string; content: string }> | null
      }> | null
      products: Array<{
        __typename?: 'Product'
        productId: string
        platform: Platform
        period?: Period | null
        publicationStatus: PublicationStatus
        purchasableOfferId?: string | null
        licensedUserOnly?: boolean | null
        premiumOf?: Array<string> | null
        prices: Array<{ __typename?: 'Price'; value: number; currency: string }>
      }>
    }>
    license?: { __typename?: 'OrganismLicense'; enabled: boolean; api?: LicenseApi | null } | null
    tags?: Array<{ __typename?: 'Tag'; id: string; title: string; subTags?: Array<string> | null }> | null
  }
}

export type UpdateOrganismMutationVariables = Exact<{
  input: UpdateOrganismInput
}>

export type UpdateOrganismMutation = {
  __typename?: 'Mutation'
  updateOrganism: {
    __typename?: 'Organism'
    id: string
    type: OrganismType
    whiteLabel: boolean
    title: string
    description?: string | null
    publicationStatus: PublicationStatus
    defaultLocale?: string | null
    onFront: boolean
    frontPosition?: number | null
    officialWebSiteUrl: string
    sportIds: Array<string>
    forbiddenKeyWords?: Array<string> | null
    enableExternalSources?: boolean | null
    availableExternalSources?: Array<WidgetExternalContentSource> | null
    streamsIds?: Array<string> | null
    wmProfileId?: string | null
    sveChannelIds?: Array<string> | null
    organismPageApplicationDesignId?: string | null
    contentPageApplicationDesignId?: string | null
    adminsAutomatedEmails?: boolean | null
    visibleInMultisportApplication?: boolean | null
    titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    descriptionT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    logo: {
      __typename?: 'Logo'
      coloredImage?: {
        __typename?: 'Media'
        id: string
        createdAt: Date
        updatedAt: Date
        title: string
        type: MediaType
        publicationStatus: PublicationStatus
        tags: Array<MediaTags>
        date?: Date | null
        startTrim?: number | null
        endTrim?: number | null
        liveStreamId?: string | null
        videoClipId?: string | null
        imageId?: string | null
        thumbnailId?: string | null
        ownerOrganismId?: string | null
        pinned?: boolean | null
        liveStream?: {
          __typename?: 'LiveStream'
          id: string
          playbackUrl: string
          ingestUrl?: string | null
          wildmokaEventID?: string | null
          videoEngine: VideoEngine
          state: LiveStreamState
          streamId?: string | null
          sveBroadcastId?: string | null
          sveChannelId?: string | null
          externalFeedProvider?: ExternalFeedType | null
          externalFeedUrl?: string | null
          event?: {
            __typename?: 'WMEvent'
            id: string
            name: string
            description?: string | null
            testStartDate: Date
            editoStartDate: Date
            editoStopDate: Date
            stopDate: Date
            state: WmEventState
            notifEmail?: string | null
            clipCreationAtEnd: boolean
            clipPublicationAtEnd: boolean
            livePublication: boolean
            stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
            live?: {
              __typename?: 'WMEventLive'
              id: string
              publishStatus: WmLiveState
              type: WmLiveType
              autoAnnounceDate?: Date | null
              template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
            } | null
            clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
          } | null
          sveBroadcast?: {
            __typename?: 'SVEBroadcast'
            id: string
            status: SveBroadcastState
            channelId: string
            startDate?: Date | null
            clipStartDate?: Date | null
            stopDate?: Date | null
            scheduledStartDate: Date
            scheduledClipStartDate: Date
            scheduledStopDate: Date
          } | null
          sveLiveMonitoring?: {
            __typename?: 'SVELiveMonitoring'
            alerts?: {
              __typename?: 'SVELiveMonitoringAlerts'
              audioNotDetected?: string | null
              initialProbeTakingLonger?: string | null
              videoNotDetected?: string | null
              RTMPHasNoAudioVideo?: string | null
              RTMPStreamNotFound?: string | null
            } | null
            stats?: {
              __typename?: 'SVELiveMonitoringStats'
              networkIn?: string | null
              droppedFrames?: string | null
              inputVideoFrameRate?: string | null
              outputAudioLevelLkfs?: string | null
            } | null
          } | null
        } | null
        videoClip?: {
          __typename?: 'VideoClip'
          id: string
          playbackUrl: string
          downloadLink: string
          status?: VideoClipStatus | null
          updatedAt: Date
          createdAt: Date
          fileId?: string | null
          error?: {
            __typename?: 'VideoClipError'
            guid: string
            workflowStatus: string
            workflowErrorAt: string
            errorMessage: string
            errorDetails: string
          } | null
          captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
          externalFeed?: {
            __typename?: 'ExternalFeed'
            externalFeedProvider: ExternalFeedType
            externalFeedUrl: string
          } | null
        } | null
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
        thumbnail?: {
          __typename?: 'Media'
          id: string
          image?: {
            __typename?: 'File'
            id: string
            filename: string
            size: number
            mimetype: string
            downloadUrl: string
            thumbUrl?: string | null
            createdAt: Date
            metadata?: {
              __typename?: 'FileMetadata'
              title?: string | null
              description?: string | null
              aspect?: number | null
              width?: number | null
              height?: number | null
              sourceFileId?: string | null
            } | null
          } | null
        } | null
        timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
      } | null
      transparentDarkImage?: {
        __typename?: 'Media'
        id: string
        createdAt: Date
        updatedAt: Date
        title: string
        type: MediaType
        publicationStatus: PublicationStatus
        tags: Array<MediaTags>
        date?: Date | null
        startTrim?: number | null
        endTrim?: number | null
        liveStreamId?: string | null
        videoClipId?: string | null
        imageId?: string | null
        thumbnailId?: string | null
        ownerOrganismId?: string | null
        pinned?: boolean | null
        liveStream?: {
          __typename?: 'LiveStream'
          id: string
          playbackUrl: string
          ingestUrl?: string | null
          wildmokaEventID?: string | null
          videoEngine: VideoEngine
          state: LiveStreamState
          streamId?: string | null
          sveBroadcastId?: string | null
          sveChannelId?: string | null
          externalFeedProvider?: ExternalFeedType | null
          externalFeedUrl?: string | null
          event?: {
            __typename?: 'WMEvent'
            id: string
            name: string
            description?: string | null
            testStartDate: Date
            editoStartDate: Date
            editoStopDate: Date
            stopDate: Date
            state: WmEventState
            notifEmail?: string | null
            clipCreationAtEnd: boolean
            clipPublicationAtEnd: boolean
            livePublication: boolean
            stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
            live?: {
              __typename?: 'WMEventLive'
              id: string
              publishStatus: WmLiveState
              type: WmLiveType
              autoAnnounceDate?: Date | null
              template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
            } | null
            clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
          } | null
          sveBroadcast?: {
            __typename?: 'SVEBroadcast'
            id: string
            status: SveBroadcastState
            channelId: string
            startDate?: Date | null
            clipStartDate?: Date | null
            stopDate?: Date | null
            scheduledStartDate: Date
            scheduledClipStartDate: Date
            scheduledStopDate: Date
          } | null
          sveLiveMonitoring?: {
            __typename?: 'SVELiveMonitoring'
            alerts?: {
              __typename?: 'SVELiveMonitoringAlerts'
              audioNotDetected?: string | null
              initialProbeTakingLonger?: string | null
              videoNotDetected?: string | null
              RTMPHasNoAudioVideo?: string | null
              RTMPStreamNotFound?: string | null
            } | null
            stats?: {
              __typename?: 'SVELiveMonitoringStats'
              networkIn?: string | null
              droppedFrames?: string | null
              inputVideoFrameRate?: string | null
              outputAudioLevelLkfs?: string | null
            } | null
          } | null
        } | null
        videoClip?: {
          __typename?: 'VideoClip'
          id: string
          playbackUrl: string
          downloadLink: string
          status?: VideoClipStatus | null
          updatedAt: Date
          createdAt: Date
          fileId?: string | null
          error?: {
            __typename?: 'VideoClipError'
            guid: string
            workflowStatus: string
            workflowErrorAt: string
            errorMessage: string
            errorDetails: string
          } | null
          captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
          externalFeed?: {
            __typename?: 'ExternalFeed'
            externalFeedProvider: ExternalFeedType
            externalFeedUrl: string
          } | null
        } | null
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
        thumbnail?: {
          __typename?: 'Media'
          id: string
          image?: {
            __typename?: 'File'
            id: string
            filename: string
            size: number
            mimetype: string
            downloadUrl: string
            thumbUrl?: string | null
            createdAt: Date
            metadata?: {
              __typename?: 'FileMetadata'
              title?: string | null
              description?: string | null
              aspect?: number | null
              width?: number | null
              height?: number | null
              sourceFileId?: string | null
            } | null
          } | null
        } | null
        timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
      } | null
      transparentLightImage?: {
        __typename?: 'Media'
        id: string
        createdAt: Date
        updatedAt: Date
        title: string
        type: MediaType
        publicationStatus: PublicationStatus
        tags: Array<MediaTags>
        date?: Date | null
        startTrim?: number | null
        endTrim?: number | null
        liveStreamId?: string | null
        videoClipId?: string | null
        imageId?: string | null
        thumbnailId?: string | null
        ownerOrganismId?: string | null
        pinned?: boolean | null
        liveStream?: {
          __typename?: 'LiveStream'
          id: string
          playbackUrl: string
          ingestUrl?: string | null
          wildmokaEventID?: string | null
          videoEngine: VideoEngine
          state: LiveStreamState
          streamId?: string | null
          sveBroadcastId?: string | null
          sveChannelId?: string | null
          externalFeedProvider?: ExternalFeedType | null
          externalFeedUrl?: string | null
          event?: {
            __typename?: 'WMEvent'
            id: string
            name: string
            description?: string | null
            testStartDate: Date
            editoStartDate: Date
            editoStopDate: Date
            stopDate: Date
            state: WmEventState
            notifEmail?: string | null
            clipCreationAtEnd: boolean
            clipPublicationAtEnd: boolean
            livePublication: boolean
            stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
            live?: {
              __typename?: 'WMEventLive'
              id: string
              publishStatus: WmLiveState
              type: WmLiveType
              autoAnnounceDate?: Date | null
              template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
            } | null
            clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
          } | null
          sveBroadcast?: {
            __typename?: 'SVEBroadcast'
            id: string
            status: SveBroadcastState
            channelId: string
            startDate?: Date | null
            clipStartDate?: Date | null
            stopDate?: Date | null
            scheduledStartDate: Date
            scheduledClipStartDate: Date
            scheduledStopDate: Date
          } | null
          sveLiveMonitoring?: {
            __typename?: 'SVELiveMonitoring'
            alerts?: {
              __typename?: 'SVELiveMonitoringAlerts'
              audioNotDetected?: string | null
              initialProbeTakingLonger?: string | null
              videoNotDetected?: string | null
              RTMPHasNoAudioVideo?: string | null
              RTMPStreamNotFound?: string | null
            } | null
            stats?: {
              __typename?: 'SVELiveMonitoringStats'
              networkIn?: string | null
              droppedFrames?: string | null
              inputVideoFrameRate?: string | null
              outputAudioLevelLkfs?: string | null
            } | null
          } | null
        } | null
        videoClip?: {
          __typename?: 'VideoClip'
          id: string
          playbackUrl: string
          downloadLink: string
          status?: VideoClipStatus | null
          updatedAt: Date
          createdAt: Date
          fileId?: string | null
          error?: {
            __typename?: 'VideoClipError'
            guid: string
            workflowStatus: string
            workflowErrorAt: string
            errorMessage: string
            errorDetails: string
          } | null
          captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
          externalFeed?: {
            __typename?: 'ExternalFeed'
            externalFeedProvider: ExternalFeedType
            externalFeedUrl: string
          } | null
        } | null
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
        thumbnail?: {
          __typename?: 'Media'
          id: string
          image?: {
            __typename?: 'File'
            id: string
            filename: string
            size: number
            mimetype: string
            downloadUrl: string
            thumbUrl?: string | null
            createdAt: Date
            metadata?: {
              __typename?: 'FileMetadata'
              title?: string | null
              description?: string | null
              aspect?: number | null
              width?: number | null
              height?: number | null
              sourceFileId?: string | null
            } | null
          } | null
        } | null
        timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
      } | null
    }
    sports: Array<{ __typename?: 'Sport'; id: string }>
    bannerImage?: {
      __typename?: 'Media'
      id: string
      createdAt: Date
      updatedAt: Date
      title: string
      type: MediaType
      publicationStatus: PublicationStatus
      tags: Array<MediaTags>
      date?: Date | null
      startTrim?: number | null
      endTrim?: number | null
      liveStreamId?: string | null
      videoClipId?: string | null
      imageId?: string | null
      thumbnailId?: string | null
      ownerOrganismId?: string | null
      pinned?: boolean | null
      liveStream?: {
        __typename?: 'LiveStream'
        id: string
        playbackUrl: string
        ingestUrl?: string | null
        wildmokaEventID?: string | null
        videoEngine: VideoEngine
        state: LiveStreamState
        streamId?: string | null
        sveBroadcastId?: string | null
        sveChannelId?: string | null
        externalFeedProvider?: ExternalFeedType | null
        externalFeedUrl?: string | null
        event?: {
          __typename?: 'WMEvent'
          id: string
          name: string
          description?: string | null
          testStartDate: Date
          editoStartDate: Date
          editoStopDate: Date
          stopDate: Date
          state: WmEventState
          notifEmail?: string | null
          clipCreationAtEnd: boolean
          clipPublicationAtEnd: boolean
          livePublication: boolean
          stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
          live?: {
            __typename?: 'WMEventLive'
            id: string
            publishStatus: WmLiveState
            type: WmLiveType
            autoAnnounceDate?: Date | null
            template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
          } | null
          clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
        } | null
        sveBroadcast?: {
          __typename?: 'SVEBroadcast'
          id: string
          status: SveBroadcastState
          channelId: string
          startDate?: Date | null
          clipStartDate?: Date | null
          stopDate?: Date | null
          scheduledStartDate: Date
          scheduledClipStartDate: Date
          scheduledStopDate: Date
        } | null
        sveLiveMonitoring?: {
          __typename?: 'SVELiveMonitoring'
          alerts?: {
            __typename?: 'SVELiveMonitoringAlerts'
            audioNotDetected?: string | null
            initialProbeTakingLonger?: string | null
            videoNotDetected?: string | null
            RTMPHasNoAudioVideo?: string | null
            RTMPStreamNotFound?: string | null
          } | null
          stats?: {
            __typename?: 'SVELiveMonitoringStats'
            networkIn?: string | null
            droppedFrames?: string | null
            inputVideoFrameRate?: string | null
            outputAudioLevelLkfs?: string | null
          } | null
        } | null
      } | null
      videoClip?: {
        __typename?: 'VideoClip'
        id: string
        playbackUrl: string
        downloadLink: string
        status?: VideoClipStatus | null
        updatedAt: Date
        createdAt: Date
        fileId?: string | null
        error?: {
          __typename?: 'VideoClipError'
          guid: string
          workflowStatus: string
          workflowErrorAt: string
          errorMessage: string
          errorDetails: string
        } | null
        captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
        externalFeed?: {
          __typename?: 'ExternalFeed'
          externalFeedProvider: ExternalFeedType
          externalFeedUrl: string
        } | null
      } | null
      image?: {
        __typename?: 'File'
        id: string
        filename: string
        size: number
        mimetype: string
        downloadUrl: string
        thumbUrl?: string | null
        createdAt: Date
        metadata?: {
          __typename?: 'FileMetadata'
          title?: string | null
          description?: string | null
          aspect?: number | null
          width?: number | null
          height?: number | null
          sourceFileId?: string | null
        } | null
      } | null
      thumbnail?: {
        __typename?: 'Media'
        id: string
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
      } | null
      timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
    } | null
    adminBadge?: {
      __typename?: 'Media'
      id: string
      createdAt: Date
      updatedAt: Date
      title: string
      type: MediaType
      publicationStatus: PublicationStatus
      tags: Array<MediaTags>
      date?: Date | null
      startTrim?: number | null
      endTrim?: number | null
      liveStreamId?: string | null
      videoClipId?: string | null
      imageId?: string | null
      thumbnailId?: string | null
      ownerOrganismId?: string | null
      pinned?: boolean | null
      liveStream?: {
        __typename?: 'LiveStream'
        id: string
        playbackUrl: string
        ingestUrl?: string | null
        wildmokaEventID?: string | null
        videoEngine: VideoEngine
        state: LiveStreamState
        streamId?: string | null
        sveBroadcastId?: string | null
        sveChannelId?: string | null
        externalFeedProvider?: ExternalFeedType | null
        externalFeedUrl?: string | null
        event?: {
          __typename?: 'WMEvent'
          id: string
          name: string
          description?: string | null
          testStartDate: Date
          editoStartDate: Date
          editoStopDate: Date
          stopDate: Date
          state: WmEventState
          notifEmail?: string | null
          clipCreationAtEnd: boolean
          clipPublicationAtEnd: boolean
          livePublication: boolean
          stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
          live?: {
            __typename?: 'WMEventLive'
            id: string
            publishStatus: WmLiveState
            type: WmLiveType
            autoAnnounceDate?: Date | null
            template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
          } | null
          clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
        } | null
        sveBroadcast?: {
          __typename?: 'SVEBroadcast'
          id: string
          status: SveBroadcastState
          channelId: string
          startDate?: Date | null
          clipStartDate?: Date | null
          stopDate?: Date | null
          scheduledStartDate: Date
          scheduledClipStartDate: Date
          scheduledStopDate: Date
        } | null
        sveLiveMonitoring?: {
          __typename?: 'SVELiveMonitoring'
          alerts?: {
            __typename?: 'SVELiveMonitoringAlerts'
            audioNotDetected?: string | null
            initialProbeTakingLonger?: string | null
            videoNotDetected?: string | null
            RTMPHasNoAudioVideo?: string | null
            RTMPStreamNotFound?: string | null
          } | null
          stats?: {
            __typename?: 'SVELiveMonitoringStats'
            networkIn?: string | null
            droppedFrames?: string | null
            inputVideoFrameRate?: string | null
            outputAudioLevelLkfs?: string | null
          } | null
        } | null
      } | null
      videoClip?: {
        __typename?: 'VideoClip'
        id: string
        playbackUrl: string
        downloadLink: string
        status?: VideoClipStatus | null
        updatedAt: Date
        createdAt: Date
        fileId?: string | null
        error?: {
          __typename?: 'VideoClipError'
          guid: string
          workflowStatus: string
          workflowErrorAt: string
          errorMessage: string
          errorDetails: string
        } | null
        captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
        externalFeed?: {
          __typename?: 'ExternalFeed'
          externalFeedProvider: ExternalFeedType
          externalFeedUrl: string
        } | null
      } | null
      image?: {
        __typename?: 'File'
        id: string
        filename: string
        size: number
        mimetype: string
        downloadUrl: string
        thumbUrl?: string | null
        createdAt: Date
        metadata?: {
          __typename?: 'FileMetadata'
          title?: string | null
          description?: string | null
          aspect?: number | null
          width?: number | null
          height?: number | null
          sourceFileId?: string | null
        } | null
      } | null
      thumbnail?: {
        __typename?: 'Media'
        id: string
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
      } | null
      timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
    } | null
    bannerVideo?: {
      __typename?: 'Media'
      id: string
      videoClip?: { __typename?: 'VideoClip'; id: string; playbackUrl: string } | null
    } | null
    pageWidgets?: Array<{
      __typename?: 'CustomPageWidget'
      name: CustomPageWidgetName
      position: number
      display?: CustomPageDisplayTypes | null
      contentDisplay?: ContentSetContentDisplay | null
      cardsDisplay?: CardsDisplay | null
      options?: {
        __typename?: 'CustomPageWidgetOptions'
        editedCategoryId?: string | null
        sportItemSetType?: SportItemSetType | null
        replays?: {
          __typename?: 'ReplaysWidget'
          defaultLocale: string
          maxReplays?: number | null
          depthInDays?: number | null
          nameT: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }>
        } | null
        externalContent?: {
          __typename?: 'ExternalContentWidget'
          name?: string | null
          defaultLocale: string
          maxItemsCount: number
          source: WidgetExternalContentSource
          nameT: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }>
          period?: { __typename?: 'StartEndDates'; startDate: Date; endDate: Date } | null
          wecContent?: {
            __typename?: 'WECNewsContent'
            teamECMIds?: Array<string> | null
            driverECMIds?: Array<string> | null
            carECMIds?: Array<string> | null
            categoryECMIds?: Array<string> | null
            raceECMIds?: Array<string> | null
          } | null
          rssContent?: { __typename?: 'RSSFeedContent'; feedUrl: string } | null
        } | null
      } | null
    }> | null
    offers: Array<{
      __typename?: 'PurchasableOffer'
      id: string
      mode: OfferMode
      delay?: number | null
      expiredAt?: Date | null
      publicationStatus: PublicationStatus
      title: string
      defaultLocale?: string | null
      url?: string | null
      bannerImageId?: string | null
      thumbnailImageId?: string | null
      freeWithLicense: boolean
      position?: number | null
      titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
      description: Array<{ __typename?: 'OfferDescription'; header: string; content: string }>
      descriptionT?: Array<{
        __typename?: 'ContentTranslationDescriptionPurchasableOffer'
        locale: string
        content?: Array<{ __typename?: 'OfferDescription'; header: string; content: string }> | null
      }> | null
      products: Array<{
        __typename?: 'Product'
        productId: string
        platform: Platform
        period?: Period | null
        publicationStatus: PublicationStatus
        purchasableOfferId?: string | null
        licensedUserOnly?: boolean | null
        premiumOf?: Array<string> | null
        prices: Array<{ __typename?: 'Price'; value: number; currency: string }>
      }>
    }>
    license?: { __typename?: 'OrganismLicense'; enabled: boolean; api?: LicenseApi | null } | null
    tags?: Array<{ __typename?: 'Tag'; id: string; title: string; subTags?: Array<string> | null }> | null
  }
}

export type OrganismQueryVariables = Exact<{
  organismId: Scalars['ID']
}>

export type OrganismQuery = {
  __typename?: 'Query'
  organism?: {
    __typename?: 'Organism'
    id: string
    type: OrganismType
    whiteLabel: boolean
    title: string
    description?: string | null
    publicationStatus: PublicationStatus
    defaultLocale?: string | null
    onFront: boolean
    frontPosition?: number | null
    officialWebSiteUrl: string
    sportIds: Array<string>
    forbiddenKeyWords?: Array<string> | null
    enableExternalSources?: boolean | null
    availableExternalSources?: Array<WidgetExternalContentSource> | null
    streamsIds?: Array<string> | null
    wmProfileId?: string | null
    sveChannelIds?: Array<string> | null
    organismPageApplicationDesignId?: string | null
    contentPageApplicationDesignId?: string | null
    adminsAutomatedEmails?: boolean | null
    visibleInMultisportApplication?: boolean | null
    titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    descriptionT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    logo: {
      __typename?: 'Logo'
      coloredImage?: {
        __typename?: 'Media'
        id: string
        createdAt: Date
        updatedAt: Date
        title: string
        type: MediaType
        publicationStatus: PublicationStatus
        tags: Array<MediaTags>
        date?: Date | null
        startTrim?: number | null
        endTrim?: number | null
        liveStreamId?: string | null
        videoClipId?: string | null
        imageId?: string | null
        thumbnailId?: string | null
        ownerOrganismId?: string | null
        pinned?: boolean | null
        liveStream?: {
          __typename?: 'LiveStream'
          id: string
          playbackUrl: string
          ingestUrl?: string | null
          wildmokaEventID?: string | null
          videoEngine: VideoEngine
          state: LiveStreamState
          streamId?: string | null
          sveBroadcastId?: string | null
          sveChannelId?: string | null
          externalFeedProvider?: ExternalFeedType | null
          externalFeedUrl?: string | null
          event?: {
            __typename?: 'WMEvent'
            id: string
            name: string
            description?: string | null
            testStartDate: Date
            editoStartDate: Date
            editoStopDate: Date
            stopDate: Date
            state: WmEventState
            notifEmail?: string | null
            clipCreationAtEnd: boolean
            clipPublicationAtEnd: boolean
            livePublication: boolean
            stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
            live?: {
              __typename?: 'WMEventLive'
              id: string
              publishStatus: WmLiveState
              type: WmLiveType
              autoAnnounceDate?: Date | null
              template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
            } | null
            clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
          } | null
          sveBroadcast?: {
            __typename?: 'SVEBroadcast'
            id: string
            status: SveBroadcastState
            channelId: string
            startDate?: Date | null
            clipStartDate?: Date | null
            stopDate?: Date | null
            scheduledStartDate: Date
            scheduledClipStartDate: Date
            scheduledStopDate: Date
          } | null
          sveLiveMonitoring?: {
            __typename?: 'SVELiveMonitoring'
            alerts?: {
              __typename?: 'SVELiveMonitoringAlerts'
              audioNotDetected?: string | null
              initialProbeTakingLonger?: string | null
              videoNotDetected?: string | null
              RTMPHasNoAudioVideo?: string | null
              RTMPStreamNotFound?: string | null
            } | null
            stats?: {
              __typename?: 'SVELiveMonitoringStats'
              networkIn?: string | null
              droppedFrames?: string | null
              inputVideoFrameRate?: string | null
              outputAudioLevelLkfs?: string | null
            } | null
          } | null
        } | null
        videoClip?: {
          __typename?: 'VideoClip'
          id: string
          playbackUrl: string
          downloadLink: string
          status?: VideoClipStatus | null
          updatedAt: Date
          createdAt: Date
          fileId?: string | null
          error?: {
            __typename?: 'VideoClipError'
            guid: string
            workflowStatus: string
            workflowErrorAt: string
            errorMessage: string
            errorDetails: string
          } | null
          captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
          externalFeed?: {
            __typename?: 'ExternalFeed'
            externalFeedProvider: ExternalFeedType
            externalFeedUrl: string
          } | null
        } | null
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
        thumbnail?: {
          __typename?: 'Media'
          id: string
          image?: {
            __typename?: 'File'
            id: string
            filename: string
            size: number
            mimetype: string
            downloadUrl: string
            thumbUrl?: string | null
            createdAt: Date
            metadata?: {
              __typename?: 'FileMetadata'
              title?: string | null
              description?: string | null
              aspect?: number | null
              width?: number | null
              height?: number | null
              sourceFileId?: string | null
            } | null
          } | null
        } | null
        timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
      } | null
      transparentDarkImage?: {
        __typename?: 'Media'
        id: string
        createdAt: Date
        updatedAt: Date
        title: string
        type: MediaType
        publicationStatus: PublicationStatus
        tags: Array<MediaTags>
        date?: Date | null
        startTrim?: number | null
        endTrim?: number | null
        liveStreamId?: string | null
        videoClipId?: string | null
        imageId?: string | null
        thumbnailId?: string | null
        ownerOrganismId?: string | null
        pinned?: boolean | null
        liveStream?: {
          __typename?: 'LiveStream'
          id: string
          playbackUrl: string
          ingestUrl?: string | null
          wildmokaEventID?: string | null
          videoEngine: VideoEngine
          state: LiveStreamState
          streamId?: string | null
          sveBroadcastId?: string | null
          sveChannelId?: string | null
          externalFeedProvider?: ExternalFeedType | null
          externalFeedUrl?: string | null
          event?: {
            __typename?: 'WMEvent'
            id: string
            name: string
            description?: string | null
            testStartDate: Date
            editoStartDate: Date
            editoStopDate: Date
            stopDate: Date
            state: WmEventState
            notifEmail?: string | null
            clipCreationAtEnd: boolean
            clipPublicationAtEnd: boolean
            livePublication: boolean
            stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
            live?: {
              __typename?: 'WMEventLive'
              id: string
              publishStatus: WmLiveState
              type: WmLiveType
              autoAnnounceDate?: Date | null
              template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
            } | null
            clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
          } | null
          sveBroadcast?: {
            __typename?: 'SVEBroadcast'
            id: string
            status: SveBroadcastState
            channelId: string
            startDate?: Date | null
            clipStartDate?: Date | null
            stopDate?: Date | null
            scheduledStartDate: Date
            scheduledClipStartDate: Date
            scheduledStopDate: Date
          } | null
          sveLiveMonitoring?: {
            __typename?: 'SVELiveMonitoring'
            alerts?: {
              __typename?: 'SVELiveMonitoringAlerts'
              audioNotDetected?: string | null
              initialProbeTakingLonger?: string | null
              videoNotDetected?: string | null
              RTMPHasNoAudioVideo?: string | null
              RTMPStreamNotFound?: string | null
            } | null
            stats?: {
              __typename?: 'SVELiveMonitoringStats'
              networkIn?: string | null
              droppedFrames?: string | null
              inputVideoFrameRate?: string | null
              outputAudioLevelLkfs?: string | null
            } | null
          } | null
        } | null
        videoClip?: {
          __typename?: 'VideoClip'
          id: string
          playbackUrl: string
          downloadLink: string
          status?: VideoClipStatus | null
          updatedAt: Date
          createdAt: Date
          fileId?: string | null
          error?: {
            __typename?: 'VideoClipError'
            guid: string
            workflowStatus: string
            workflowErrorAt: string
            errorMessage: string
            errorDetails: string
          } | null
          captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
          externalFeed?: {
            __typename?: 'ExternalFeed'
            externalFeedProvider: ExternalFeedType
            externalFeedUrl: string
          } | null
        } | null
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
        thumbnail?: {
          __typename?: 'Media'
          id: string
          image?: {
            __typename?: 'File'
            id: string
            filename: string
            size: number
            mimetype: string
            downloadUrl: string
            thumbUrl?: string | null
            createdAt: Date
            metadata?: {
              __typename?: 'FileMetadata'
              title?: string | null
              description?: string | null
              aspect?: number | null
              width?: number | null
              height?: number | null
              sourceFileId?: string | null
            } | null
          } | null
        } | null
        timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
      } | null
      transparentLightImage?: {
        __typename?: 'Media'
        id: string
        createdAt: Date
        updatedAt: Date
        title: string
        type: MediaType
        publicationStatus: PublicationStatus
        tags: Array<MediaTags>
        date?: Date | null
        startTrim?: number | null
        endTrim?: number | null
        liveStreamId?: string | null
        videoClipId?: string | null
        imageId?: string | null
        thumbnailId?: string | null
        ownerOrganismId?: string | null
        pinned?: boolean | null
        liveStream?: {
          __typename?: 'LiveStream'
          id: string
          playbackUrl: string
          ingestUrl?: string | null
          wildmokaEventID?: string | null
          videoEngine: VideoEngine
          state: LiveStreamState
          streamId?: string | null
          sveBroadcastId?: string | null
          sveChannelId?: string | null
          externalFeedProvider?: ExternalFeedType | null
          externalFeedUrl?: string | null
          event?: {
            __typename?: 'WMEvent'
            id: string
            name: string
            description?: string | null
            testStartDate: Date
            editoStartDate: Date
            editoStopDate: Date
            stopDate: Date
            state: WmEventState
            notifEmail?: string | null
            clipCreationAtEnd: boolean
            clipPublicationAtEnd: boolean
            livePublication: boolean
            stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
            live?: {
              __typename?: 'WMEventLive'
              id: string
              publishStatus: WmLiveState
              type: WmLiveType
              autoAnnounceDate?: Date | null
              template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
            } | null
            clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
          } | null
          sveBroadcast?: {
            __typename?: 'SVEBroadcast'
            id: string
            status: SveBroadcastState
            channelId: string
            startDate?: Date | null
            clipStartDate?: Date | null
            stopDate?: Date | null
            scheduledStartDate: Date
            scheduledClipStartDate: Date
            scheduledStopDate: Date
          } | null
          sveLiveMonitoring?: {
            __typename?: 'SVELiveMonitoring'
            alerts?: {
              __typename?: 'SVELiveMonitoringAlerts'
              audioNotDetected?: string | null
              initialProbeTakingLonger?: string | null
              videoNotDetected?: string | null
              RTMPHasNoAudioVideo?: string | null
              RTMPStreamNotFound?: string | null
            } | null
            stats?: {
              __typename?: 'SVELiveMonitoringStats'
              networkIn?: string | null
              droppedFrames?: string | null
              inputVideoFrameRate?: string | null
              outputAudioLevelLkfs?: string | null
            } | null
          } | null
        } | null
        videoClip?: {
          __typename?: 'VideoClip'
          id: string
          playbackUrl: string
          downloadLink: string
          status?: VideoClipStatus | null
          updatedAt: Date
          createdAt: Date
          fileId?: string | null
          error?: {
            __typename?: 'VideoClipError'
            guid: string
            workflowStatus: string
            workflowErrorAt: string
            errorMessage: string
            errorDetails: string
          } | null
          captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
          externalFeed?: {
            __typename?: 'ExternalFeed'
            externalFeedProvider: ExternalFeedType
            externalFeedUrl: string
          } | null
        } | null
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
        thumbnail?: {
          __typename?: 'Media'
          id: string
          image?: {
            __typename?: 'File'
            id: string
            filename: string
            size: number
            mimetype: string
            downloadUrl: string
            thumbUrl?: string | null
            createdAt: Date
            metadata?: {
              __typename?: 'FileMetadata'
              title?: string | null
              description?: string | null
              aspect?: number | null
              width?: number | null
              height?: number | null
              sourceFileId?: string | null
            } | null
          } | null
        } | null
        timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
      } | null
    }
    sports: Array<{ __typename?: 'Sport'; id: string }>
    bannerImage?: {
      __typename?: 'Media'
      id: string
      createdAt: Date
      updatedAt: Date
      title: string
      type: MediaType
      publicationStatus: PublicationStatus
      tags: Array<MediaTags>
      date?: Date | null
      startTrim?: number | null
      endTrim?: number | null
      liveStreamId?: string | null
      videoClipId?: string | null
      imageId?: string | null
      thumbnailId?: string | null
      ownerOrganismId?: string | null
      pinned?: boolean | null
      liveStream?: {
        __typename?: 'LiveStream'
        id: string
        playbackUrl: string
        ingestUrl?: string | null
        wildmokaEventID?: string | null
        videoEngine: VideoEngine
        state: LiveStreamState
        streamId?: string | null
        sveBroadcastId?: string | null
        sveChannelId?: string | null
        externalFeedProvider?: ExternalFeedType | null
        externalFeedUrl?: string | null
        event?: {
          __typename?: 'WMEvent'
          id: string
          name: string
          description?: string | null
          testStartDate: Date
          editoStartDate: Date
          editoStopDate: Date
          stopDate: Date
          state: WmEventState
          notifEmail?: string | null
          clipCreationAtEnd: boolean
          clipPublicationAtEnd: boolean
          livePublication: boolean
          stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
          live?: {
            __typename?: 'WMEventLive'
            id: string
            publishStatus: WmLiveState
            type: WmLiveType
            autoAnnounceDate?: Date | null
            template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
          } | null
          clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
        } | null
        sveBroadcast?: {
          __typename?: 'SVEBroadcast'
          id: string
          status: SveBroadcastState
          channelId: string
          startDate?: Date | null
          clipStartDate?: Date | null
          stopDate?: Date | null
          scheduledStartDate: Date
          scheduledClipStartDate: Date
          scheduledStopDate: Date
        } | null
        sveLiveMonitoring?: {
          __typename?: 'SVELiveMonitoring'
          alerts?: {
            __typename?: 'SVELiveMonitoringAlerts'
            audioNotDetected?: string | null
            initialProbeTakingLonger?: string | null
            videoNotDetected?: string | null
            RTMPHasNoAudioVideo?: string | null
            RTMPStreamNotFound?: string | null
          } | null
          stats?: {
            __typename?: 'SVELiveMonitoringStats'
            networkIn?: string | null
            droppedFrames?: string | null
            inputVideoFrameRate?: string | null
            outputAudioLevelLkfs?: string | null
          } | null
        } | null
      } | null
      videoClip?: {
        __typename?: 'VideoClip'
        id: string
        playbackUrl: string
        downloadLink: string
        status?: VideoClipStatus | null
        updatedAt: Date
        createdAt: Date
        fileId?: string | null
        error?: {
          __typename?: 'VideoClipError'
          guid: string
          workflowStatus: string
          workflowErrorAt: string
          errorMessage: string
          errorDetails: string
        } | null
        captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
        externalFeed?: {
          __typename?: 'ExternalFeed'
          externalFeedProvider: ExternalFeedType
          externalFeedUrl: string
        } | null
      } | null
      image?: {
        __typename?: 'File'
        id: string
        filename: string
        size: number
        mimetype: string
        downloadUrl: string
        thumbUrl?: string | null
        createdAt: Date
        metadata?: {
          __typename?: 'FileMetadata'
          title?: string | null
          description?: string | null
          aspect?: number | null
          width?: number | null
          height?: number | null
          sourceFileId?: string | null
        } | null
      } | null
      thumbnail?: {
        __typename?: 'Media'
        id: string
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
      } | null
      timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
    } | null
    adminBadge?: {
      __typename?: 'Media'
      id: string
      createdAt: Date
      updatedAt: Date
      title: string
      type: MediaType
      publicationStatus: PublicationStatus
      tags: Array<MediaTags>
      date?: Date | null
      startTrim?: number | null
      endTrim?: number | null
      liveStreamId?: string | null
      videoClipId?: string | null
      imageId?: string | null
      thumbnailId?: string | null
      ownerOrganismId?: string | null
      pinned?: boolean | null
      liveStream?: {
        __typename?: 'LiveStream'
        id: string
        playbackUrl: string
        ingestUrl?: string | null
        wildmokaEventID?: string | null
        videoEngine: VideoEngine
        state: LiveStreamState
        streamId?: string | null
        sveBroadcastId?: string | null
        sveChannelId?: string | null
        externalFeedProvider?: ExternalFeedType | null
        externalFeedUrl?: string | null
        event?: {
          __typename?: 'WMEvent'
          id: string
          name: string
          description?: string | null
          testStartDate: Date
          editoStartDate: Date
          editoStopDate: Date
          stopDate: Date
          state: WmEventState
          notifEmail?: string | null
          clipCreationAtEnd: boolean
          clipPublicationAtEnd: boolean
          livePublication: boolean
          stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
          live?: {
            __typename?: 'WMEventLive'
            id: string
            publishStatus: WmLiveState
            type: WmLiveType
            autoAnnounceDate?: Date | null
            template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
          } | null
          clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
        } | null
        sveBroadcast?: {
          __typename?: 'SVEBroadcast'
          id: string
          status: SveBroadcastState
          channelId: string
          startDate?: Date | null
          clipStartDate?: Date | null
          stopDate?: Date | null
          scheduledStartDate: Date
          scheduledClipStartDate: Date
          scheduledStopDate: Date
        } | null
        sveLiveMonitoring?: {
          __typename?: 'SVELiveMonitoring'
          alerts?: {
            __typename?: 'SVELiveMonitoringAlerts'
            audioNotDetected?: string | null
            initialProbeTakingLonger?: string | null
            videoNotDetected?: string | null
            RTMPHasNoAudioVideo?: string | null
            RTMPStreamNotFound?: string | null
          } | null
          stats?: {
            __typename?: 'SVELiveMonitoringStats'
            networkIn?: string | null
            droppedFrames?: string | null
            inputVideoFrameRate?: string | null
            outputAudioLevelLkfs?: string | null
          } | null
        } | null
      } | null
      videoClip?: {
        __typename?: 'VideoClip'
        id: string
        playbackUrl: string
        downloadLink: string
        status?: VideoClipStatus | null
        updatedAt: Date
        createdAt: Date
        fileId?: string | null
        error?: {
          __typename?: 'VideoClipError'
          guid: string
          workflowStatus: string
          workflowErrorAt: string
          errorMessage: string
          errorDetails: string
        } | null
        captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
        externalFeed?: {
          __typename?: 'ExternalFeed'
          externalFeedProvider: ExternalFeedType
          externalFeedUrl: string
        } | null
      } | null
      image?: {
        __typename?: 'File'
        id: string
        filename: string
        size: number
        mimetype: string
        downloadUrl: string
        thumbUrl?: string | null
        createdAt: Date
        metadata?: {
          __typename?: 'FileMetadata'
          title?: string | null
          description?: string | null
          aspect?: number | null
          width?: number | null
          height?: number | null
          sourceFileId?: string | null
        } | null
      } | null
      thumbnail?: {
        __typename?: 'Media'
        id: string
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
      } | null
      timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
    } | null
    bannerVideo?: {
      __typename?: 'Media'
      id: string
      videoClip?: { __typename?: 'VideoClip'; id: string; playbackUrl: string } | null
    } | null
    pageWidgets?: Array<{
      __typename?: 'CustomPageWidget'
      name: CustomPageWidgetName
      position: number
      display?: CustomPageDisplayTypes | null
      contentDisplay?: ContentSetContentDisplay | null
      cardsDisplay?: CardsDisplay | null
      options?: {
        __typename?: 'CustomPageWidgetOptions'
        editedCategoryId?: string | null
        sportItemSetType?: SportItemSetType | null
        replays?: {
          __typename?: 'ReplaysWidget'
          defaultLocale: string
          maxReplays?: number | null
          depthInDays?: number | null
          nameT: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }>
        } | null
        externalContent?: {
          __typename?: 'ExternalContentWidget'
          name?: string | null
          defaultLocale: string
          maxItemsCount: number
          source: WidgetExternalContentSource
          nameT: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }>
          period?: { __typename?: 'StartEndDates'; startDate: Date; endDate: Date } | null
          wecContent?: {
            __typename?: 'WECNewsContent'
            teamECMIds?: Array<string> | null
            driverECMIds?: Array<string> | null
            carECMIds?: Array<string> | null
            categoryECMIds?: Array<string> | null
            raceECMIds?: Array<string> | null
          } | null
          rssContent?: { __typename?: 'RSSFeedContent'; feedUrl: string } | null
        } | null
      } | null
    }> | null
    offers: Array<{
      __typename?: 'PurchasableOffer'
      id: string
      mode: OfferMode
      delay?: number | null
      expiredAt?: Date | null
      publicationStatus: PublicationStatus
      title: string
      defaultLocale?: string | null
      url?: string | null
      bannerImageId?: string | null
      thumbnailImageId?: string | null
      freeWithLicense: boolean
      position?: number | null
      titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
      description: Array<{ __typename?: 'OfferDescription'; header: string; content: string }>
      descriptionT?: Array<{
        __typename?: 'ContentTranslationDescriptionPurchasableOffer'
        locale: string
        content?: Array<{ __typename?: 'OfferDescription'; header: string; content: string }> | null
      }> | null
      products: Array<{
        __typename?: 'Product'
        productId: string
        platform: Platform
        period?: Period | null
        publicationStatus: PublicationStatus
        purchasableOfferId?: string | null
        licensedUserOnly?: boolean | null
        premiumOf?: Array<string> | null
        prices: Array<{ __typename?: 'Price'; value: number; currency: string }>
      }>
    }>
    license?: { __typename?: 'OrganismLicense'; enabled: boolean; api?: LicenseApi | null } | null
    tags?: Array<{ __typename?: 'Tag'; id: string; title: string; subTags?: Array<string> | null }> | null
  } | null
}

export type LightOrganismContentFragment = {
  __typename?: 'Organism'
  id: string
  type: OrganismType
  whiteLabel: boolean
  title: string
  description?: string | null
  officialWebSiteUrl: string
  fansCount: number
  license?: { __typename?: 'OrganismLicense'; enabled: boolean; api?: LicenseApi | null } | null
}

export type LightOrganismQueryVariables = Exact<{
  organismId: Scalars['ID']
}>

export type LightOrganismQuery = {
  __typename?: 'Query'
  organism?: {
    __typename?: 'Organism'
    id: string
    type: OrganismType
    whiteLabel: boolean
    title: string
    description?: string | null
    officialWebSiteUrl: string
    fansCount: number
    license?: { __typename?: 'OrganismLicense'; enabled: boolean; api?: LicenseApi | null } | null
  } | null
}

export type OrganismWithSubscriptionsQueryVariables = Exact<{
  organismId: Scalars['ID']
}>

export type OrganismWithSubscriptionsQuery = {
  __typename?: 'Query'
  organism?: {
    __typename?: 'Organism'
    id: string
    type: OrganismType
    whiteLabel: boolean
    title: string
    description?: string | null
    publicationStatus: PublicationStatus
    defaultLocale?: string | null
    onFront: boolean
    frontPosition?: number | null
    officialWebSiteUrl: string
    sportIds: Array<string>
    forbiddenKeyWords?: Array<string> | null
    enableExternalSources?: boolean | null
    availableExternalSources?: Array<WidgetExternalContentSource> | null
    streamsIds?: Array<string> | null
    wmProfileId?: string | null
    sveChannelIds?: Array<string> | null
    organismPageApplicationDesignId?: string | null
    contentPageApplicationDesignId?: string | null
    adminsAutomatedEmails?: boolean | null
    visibleInMultisportApplication?: boolean | null
    offers: Array<{
      __typename?: 'PurchasableOffer'
      nbValidSubscriptions?: number | null
      id: string
      mode: OfferMode
      delay?: number | null
      expiredAt?: Date | null
      publicationStatus: PublicationStatus
      title: string
      defaultLocale?: string | null
      url?: string | null
      bannerImageId?: string | null
      thumbnailImageId?: string | null
      freeWithLicense: boolean
      position?: number | null
      titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
      description: Array<{ __typename?: 'OfferDescription'; header: string; content: string }>
      descriptionT?: Array<{
        __typename?: 'ContentTranslationDescriptionPurchasableOffer'
        locale: string
        content?: Array<{ __typename?: 'OfferDescription'; header: string; content: string }> | null
      }> | null
      products: Array<{
        __typename?: 'Product'
        productId: string
        platform: Platform
        period?: Period | null
        publicationStatus: PublicationStatus
        purchasableOfferId?: string | null
        licensedUserOnly?: boolean | null
        premiumOf?: Array<string> | null
        prices: Array<{ __typename?: 'Price'; value: number; currency: string }>
      }>
    }>
    titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    descriptionT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    logo: {
      __typename?: 'Logo'
      coloredImage?: {
        __typename?: 'Media'
        id: string
        createdAt: Date
        updatedAt: Date
        title: string
        type: MediaType
        publicationStatus: PublicationStatus
        tags: Array<MediaTags>
        date?: Date | null
        startTrim?: number | null
        endTrim?: number | null
        liveStreamId?: string | null
        videoClipId?: string | null
        imageId?: string | null
        thumbnailId?: string | null
        ownerOrganismId?: string | null
        pinned?: boolean | null
        liveStream?: {
          __typename?: 'LiveStream'
          id: string
          playbackUrl: string
          ingestUrl?: string | null
          wildmokaEventID?: string | null
          videoEngine: VideoEngine
          state: LiveStreamState
          streamId?: string | null
          sveBroadcastId?: string | null
          sveChannelId?: string | null
          externalFeedProvider?: ExternalFeedType | null
          externalFeedUrl?: string | null
          event?: {
            __typename?: 'WMEvent'
            id: string
            name: string
            description?: string | null
            testStartDate: Date
            editoStartDate: Date
            editoStopDate: Date
            stopDate: Date
            state: WmEventState
            notifEmail?: string | null
            clipCreationAtEnd: boolean
            clipPublicationAtEnd: boolean
            livePublication: boolean
            stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
            live?: {
              __typename?: 'WMEventLive'
              id: string
              publishStatus: WmLiveState
              type: WmLiveType
              autoAnnounceDate?: Date | null
              template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
            } | null
            clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
          } | null
          sveBroadcast?: {
            __typename?: 'SVEBroadcast'
            id: string
            status: SveBroadcastState
            channelId: string
            startDate?: Date | null
            clipStartDate?: Date | null
            stopDate?: Date | null
            scheduledStartDate: Date
            scheduledClipStartDate: Date
            scheduledStopDate: Date
          } | null
          sveLiveMonitoring?: {
            __typename?: 'SVELiveMonitoring'
            alerts?: {
              __typename?: 'SVELiveMonitoringAlerts'
              audioNotDetected?: string | null
              initialProbeTakingLonger?: string | null
              videoNotDetected?: string | null
              RTMPHasNoAudioVideo?: string | null
              RTMPStreamNotFound?: string | null
            } | null
            stats?: {
              __typename?: 'SVELiveMonitoringStats'
              networkIn?: string | null
              droppedFrames?: string | null
              inputVideoFrameRate?: string | null
              outputAudioLevelLkfs?: string | null
            } | null
          } | null
        } | null
        videoClip?: {
          __typename?: 'VideoClip'
          id: string
          playbackUrl: string
          downloadLink: string
          status?: VideoClipStatus | null
          updatedAt: Date
          createdAt: Date
          fileId?: string | null
          error?: {
            __typename?: 'VideoClipError'
            guid: string
            workflowStatus: string
            workflowErrorAt: string
            errorMessage: string
            errorDetails: string
          } | null
          captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
          externalFeed?: {
            __typename?: 'ExternalFeed'
            externalFeedProvider: ExternalFeedType
            externalFeedUrl: string
          } | null
        } | null
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
        thumbnail?: {
          __typename?: 'Media'
          id: string
          image?: {
            __typename?: 'File'
            id: string
            filename: string
            size: number
            mimetype: string
            downloadUrl: string
            thumbUrl?: string | null
            createdAt: Date
            metadata?: {
              __typename?: 'FileMetadata'
              title?: string | null
              description?: string | null
              aspect?: number | null
              width?: number | null
              height?: number | null
              sourceFileId?: string | null
            } | null
          } | null
        } | null
        timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
      } | null
      transparentDarkImage?: {
        __typename?: 'Media'
        id: string
        createdAt: Date
        updatedAt: Date
        title: string
        type: MediaType
        publicationStatus: PublicationStatus
        tags: Array<MediaTags>
        date?: Date | null
        startTrim?: number | null
        endTrim?: number | null
        liveStreamId?: string | null
        videoClipId?: string | null
        imageId?: string | null
        thumbnailId?: string | null
        ownerOrganismId?: string | null
        pinned?: boolean | null
        liveStream?: {
          __typename?: 'LiveStream'
          id: string
          playbackUrl: string
          ingestUrl?: string | null
          wildmokaEventID?: string | null
          videoEngine: VideoEngine
          state: LiveStreamState
          streamId?: string | null
          sveBroadcastId?: string | null
          sveChannelId?: string | null
          externalFeedProvider?: ExternalFeedType | null
          externalFeedUrl?: string | null
          event?: {
            __typename?: 'WMEvent'
            id: string
            name: string
            description?: string | null
            testStartDate: Date
            editoStartDate: Date
            editoStopDate: Date
            stopDate: Date
            state: WmEventState
            notifEmail?: string | null
            clipCreationAtEnd: boolean
            clipPublicationAtEnd: boolean
            livePublication: boolean
            stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
            live?: {
              __typename?: 'WMEventLive'
              id: string
              publishStatus: WmLiveState
              type: WmLiveType
              autoAnnounceDate?: Date | null
              template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
            } | null
            clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
          } | null
          sveBroadcast?: {
            __typename?: 'SVEBroadcast'
            id: string
            status: SveBroadcastState
            channelId: string
            startDate?: Date | null
            clipStartDate?: Date | null
            stopDate?: Date | null
            scheduledStartDate: Date
            scheduledClipStartDate: Date
            scheduledStopDate: Date
          } | null
          sveLiveMonitoring?: {
            __typename?: 'SVELiveMonitoring'
            alerts?: {
              __typename?: 'SVELiveMonitoringAlerts'
              audioNotDetected?: string | null
              initialProbeTakingLonger?: string | null
              videoNotDetected?: string | null
              RTMPHasNoAudioVideo?: string | null
              RTMPStreamNotFound?: string | null
            } | null
            stats?: {
              __typename?: 'SVELiveMonitoringStats'
              networkIn?: string | null
              droppedFrames?: string | null
              inputVideoFrameRate?: string | null
              outputAudioLevelLkfs?: string | null
            } | null
          } | null
        } | null
        videoClip?: {
          __typename?: 'VideoClip'
          id: string
          playbackUrl: string
          downloadLink: string
          status?: VideoClipStatus | null
          updatedAt: Date
          createdAt: Date
          fileId?: string | null
          error?: {
            __typename?: 'VideoClipError'
            guid: string
            workflowStatus: string
            workflowErrorAt: string
            errorMessage: string
            errorDetails: string
          } | null
          captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
          externalFeed?: {
            __typename?: 'ExternalFeed'
            externalFeedProvider: ExternalFeedType
            externalFeedUrl: string
          } | null
        } | null
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
        thumbnail?: {
          __typename?: 'Media'
          id: string
          image?: {
            __typename?: 'File'
            id: string
            filename: string
            size: number
            mimetype: string
            downloadUrl: string
            thumbUrl?: string | null
            createdAt: Date
            metadata?: {
              __typename?: 'FileMetadata'
              title?: string | null
              description?: string | null
              aspect?: number | null
              width?: number | null
              height?: number | null
              sourceFileId?: string | null
            } | null
          } | null
        } | null
        timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
      } | null
      transparentLightImage?: {
        __typename?: 'Media'
        id: string
        createdAt: Date
        updatedAt: Date
        title: string
        type: MediaType
        publicationStatus: PublicationStatus
        tags: Array<MediaTags>
        date?: Date | null
        startTrim?: number | null
        endTrim?: number | null
        liveStreamId?: string | null
        videoClipId?: string | null
        imageId?: string | null
        thumbnailId?: string | null
        ownerOrganismId?: string | null
        pinned?: boolean | null
        liveStream?: {
          __typename?: 'LiveStream'
          id: string
          playbackUrl: string
          ingestUrl?: string | null
          wildmokaEventID?: string | null
          videoEngine: VideoEngine
          state: LiveStreamState
          streamId?: string | null
          sveBroadcastId?: string | null
          sveChannelId?: string | null
          externalFeedProvider?: ExternalFeedType | null
          externalFeedUrl?: string | null
          event?: {
            __typename?: 'WMEvent'
            id: string
            name: string
            description?: string | null
            testStartDate: Date
            editoStartDate: Date
            editoStopDate: Date
            stopDate: Date
            state: WmEventState
            notifEmail?: string | null
            clipCreationAtEnd: boolean
            clipPublicationAtEnd: boolean
            livePublication: boolean
            stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
            live?: {
              __typename?: 'WMEventLive'
              id: string
              publishStatus: WmLiveState
              type: WmLiveType
              autoAnnounceDate?: Date | null
              template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
            } | null
            clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
          } | null
          sveBroadcast?: {
            __typename?: 'SVEBroadcast'
            id: string
            status: SveBroadcastState
            channelId: string
            startDate?: Date | null
            clipStartDate?: Date | null
            stopDate?: Date | null
            scheduledStartDate: Date
            scheduledClipStartDate: Date
            scheduledStopDate: Date
          } | null
          sveLiveMonitoring?: {
            __typename?: 'SVELiveMonitoring'
            alerts?: {
              __typename?: 'SVELiveMonitoringAlerts'
              audioNotDetected?: string | null
              initialProbeTakingLonger?: string | null
              videoNotDetected?: string | null
              RTMPHasNoAudioVideo?: string | null
              RTMPStreamNotFound?: string | null
            } | null
            stats?: {
              __typename?: 'SVELiveMonitoringStats'
              networkIn?: string | null
              droppedFrames?: string | null
              inputVideoFrameRate?: string | null
              outputAudioLevelLkfs?: string | null
            } | null
          } | null
        } | null
        videoClip?: {
          __typename?: 'VideoClip'
          id: string
          playbackUrl: string
          downloadLink: string
          status?: VideoClipStatus | null
          updatedAt: Date
          createdAt: Date
          fileId?: string | null
          error?: {
            __typename?: 'VideoClipError'
            guid: string
            workflowStatus: string
            workflowErrorAt: string
            errorMessage: string
            errorDetails: string
          } | null
          captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
          externalFeed?: {
            __typename?: 'ExternalFeed'
            externalFeedProvider: ExternalFeedType
            externalFeedUrl: string
          } | null
        } | null
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
        thumbnail?: {
          __typename?: 'Media'
          id: string
          image?: {
            __typename?: 'File'
            id: string
            filename: string
            size: number
            mimetype: string
            downloadUrl: string
            thumbUrl?: string | null
            createdAt: Date
            metadata?: {
              __typename?: 'FileMetadata'
              title?: string | null
              description?: string | null
              aspect?: number | null
              width?: number | null
              height?: number | null
              sourceFileId?: string | null
            } | null
          } | null
        } | null
        timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
      } | null
    }
    sports: Array<{ __typename?: 'Sport'; id: string }>
    bannerImage?: {
      __typename?: 'Media'
      id: string
      createdAt: Date
      updatedAt: Date
      title: string
      type: MediaType
      publicationStatus: PublicationStatus
      tags: Array<MediaTags>
      date?: Date | null
      startTrim?: number | null
      endTrim?: number | null
      liveStreamId?: string | null
      videoClipId?: string | null
      imageId?: string | null
      thumbnailId?: string | null
      ownerOrganismId?: string | null
      pinned?: boolean | null
      liveStream?: {
        __typename?: 'LiveStream'
        id: string
        playbackUrl: string
        ingestUrl?: string | null
        wildmokaEventID?: string | null
        videoEngine: VideoEngine
        state: LiveStreamState
        streamId?: string | null
        sveBroadcastId?: string | null
        sveChannelId?: string | null
        externalFeedProvider?: ExternalFeedType | null
        externalFeedUrl?: string | null
        event?: {
          __typename?: 'WMEvent'
          id: string
          name: string
          description?: string | null
          testStartDate: Date
          editoStartDate: Date
          editoStopDate: Date
          stopDate: Date
          state: WmEventState
          notifEmail?: string | null
          clipCreationAtEnd: boolean
          clipPublicationAtEnd: boolean
          livePublication: boolean
          stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
          live?: {
            __typename?: 'WMEventLive'
            id: string
            publishStatus: WmLiveState
            type: WmLiveType
            autoAnnounceDate?: Date | null
            template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
          } | null
          clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
        } | null
        sveBroadcast?: {
          __typename?: 'SVEBroadcast'
          id: string
          status: SveBroadcastState
          channelId: string
          startDate?: Date | null
          clipStartDate?: Date | null
          stopDate?: Date | null
          scheduledStartDate: Date
          scheduledClipStartDate: Date
          scheduledStopDate: Date
        } | null
        sveLiveMonitoring?: {
          __typename?: 'SVELiveMonitoring'
          alerts?: {
            __typename?: 'SVELiveMonitoringAlerts'
            audioNotDetected?: string | null
            initialProbeTakingLonger?: string | null
            videoNotDetected?: string | null
            RTMPHasNoAudioVideo?: string | null
            RTMPStreamNotFound?: string | null
          } | null
          stats?: {
            __typename?: 'SVELiveMonitoringStats'
            networkIn?: string | null
            droppedFrames?: string | null
            inputVideoFrameRate?: string | null
            outputAudioLevelLkfs?: string | null
          } | null
        } | null
      } | null
      videoClip?: {
        __typename?: 'VideoClip'
        id: string
        playbackUrl: string
        downloadLink: string
        status?: VideoClipStatus | null
        updatedAt: Date
        createdAt: Date
        fileId?: string | null
        error?: {
          __typename?: 'VideoClipError'
          guid: string
          workflowStatus: string
          workflowErrorAt: string
          errorMessage: string
          errorDetails: string
        } | null
        captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
        externalFeed?: {
          __typename?: 'ExternalFeed'
          externalFeedProvider: ExternalFeedType
          externalFeedUrl: string
        } | null
      } | null
      image?: {
        __typename?: 'File'
        id: string
        filename: string
        size: number
        mimetype: string
        downloadUrl: string
        thumbUrl?: string | null
        createdAt: Date
        metadata?: {
          __typename?: 'FileMetadata'
          title?: string | null
          description?: string | null
          aspect?: number | null
          width?: number | null
          height?: number | null
          sourceFileId?: string | null
        } | null
      } | null
      thumbnail?: {
        __typename?: 'Media'
        id: string
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
      } | null
      timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
    } | null
    adminBadge?: {
      __typename?: 'Media'
      id: string
      createdAt: Date
      updatedAt: Date
      title: string
      type: MediaType
      publicationStatus: PublicationStatus
      tags: Array<MediaTags>
      date?: Date | null
      startTrim?: number | null
      endTrim?: number | null
      liveStreamId?: string | null
      videoClipId?: string | null
      imageId?: string | null
      thumbnailId?: string | null
      ownerOrganismId?: string | null
      pinned?: boolean | null
      liveStream?: {
        __typename?: 'LiveStream'
        id: string
        playbackUrl: string
        ingestUrl?: string | null
        wildmokaEventID?: string | null
        videoEngine: VideoEngine
        state: LiveStreamState
        streamId?: string | null
        sveBroadcastId?: string | null
        sveChannelId?: string | null
        externalFeedProvider?: ExternalFeedType | null
        externalFeedUrl?: string | null
        event?: {
          __typename?: 'WMEvent'
          id: string
          name: string
          description?: string | null
          testStartDate: Date
          editoStartDate: Date
          editoStopDate: Date
          stopDate: Date
          state: WmEventState
          notifEmail?: string | null
          clipCreationAtEnd: boolean
          clipPublicationAtEnd: boolean
          livePublication: boolean
          stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
          live?: {
            __typename?: 'WMEventLive'
            id: string
            publishStatus: WmLiveState
            type: WmLiveType
            autoAnnounceDate?: Date | null
            template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
          } | null
          clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
        } | null
        sveBroadcast?: {
          __typename?: 'SVEBroadcast'
          id: string
          status: SveBroadcastState
          channelId: string
          startDate?: Date | null
          clipStartDate?: Date | null
          stopDate?: Date | null
          scheduledStartDate: Date
          scheduledClipStartDate: Date
          scheduledStopDate: Date
        } | null
        sveLiveMonitoring?: {
          __typename?: 'SVELiveMonitoring'
          alerts?: {
            __typename?: 'SVELiveMonitoringAlerts'
            audioNotDetected?: string | null
            initialProbeTakingLonger?: string | null
            videoNotDetected?: string | null
            RTMPHasNoAudioVideo?: string | null
            RTMPStreamNotFound?: string | null
          } | null
          stats?: {
            __typename?: 'SVELiveMonitoringStats'
            networkIn?: string | null
            droppedFrames?: string | null
            inputVideoFrameRate?: string | null
            outputAudioLevelLkfs?: string | null
          } | null
        } | null
      } | null
      videoClip?: {
        __typename?: 'VideoClip'
        id: string
        playbackUrl: string
        downloadLink: string
        status?: VideoClipStatus | null
        updatedAt: Date
        createdAt: Date
        fileId?: string | null
        error?: {
          __typename?: 'VideoClipError'
          guid: string
          workflowStatus: string
          workflowErrorAt: string
          errorMessage: string
          errorDetails: string
        } | null
        captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
        externalFeed?: {
          __typename?: 'ExternalFeed'
          externalFeedProvider: ExternalFeedType
          externalFeedUrl: string
        } | null
      } | null
      image?: {
        __typename?: 'File'
        id: string
        filename: string
        size: number
        mimetype: string
        downloadUrl: string
        thumbUrl?: string | null
        createdAt: Date
        metadata?: {
          __typename?: 'FileMetadata'
          title?: string | null
          description?: string | null
          aspect?: number | null
          width?: number | null
          height?: number | null
          sourceFileId?: string | null
        } | null
      } | null
      thumbnail?: {
        __typename?: 'Media'
        id: string
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
      } | null
      timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
    } | null
    bannerVideo?: {
      __typename?: 'Media'
      id: string
      videoClip?: { __typename?: 'VideoClip'; id: string; playbackUrl: string } | null
    } | null
    pageWidgets?: Array<{
      __typename?: 'CustomPageWidget'
      name: CustomPageWidgetName
      position: number
      display?: CustomPageDisplayTypes | null
      contentDisplay?: ContentSetContentDisplay | null
      cardsDisplay?: CardsDisplay | null
      options?: {
        __typename?: 'CustomPageWidgetOptions'
        editedCategoryId?: string | null
        sportItemSetType?: SportItemSetType | null
        replays?: {
          __typename?: 'ReplaysWidget'
          defaultLocale: string
          maxReplays?: number | null
          depthInDays?: number | null
          nameT: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }>
        } | null
        externalContent?: {
          __typename?: 'ExternalContentWidget'
          name?: string | null
          defaultLocale: string
          maxItemsCount: number
          source: WidgetExternalContentSource
          nameT: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }>
          period?: { __typename?: 'StartEndDates'; startDate: Date; endDate: Date } | null
          wecContent?: {
            __typename?: 'WECNewsContent'
            teamECMIds?: Array<string> | null
            driverECMIds?: Array<string> | null
            carECMIds?: Array<string> | null
            categoryECMIds?: Array<string> | null
            raceECMIds?: Array<string> | null
          } | null
          rssContent?: { __typename?: 'RSSFeedContent'; feedUrl: string } | null
        } | null
      } | null
    }> | null
    license?: { __typename?: 'OrganismLicense'; enabled: boolean; api?: LicenseApi | null } | null
    tags?: Array<{ __typename?: 'Tag'; id: string; title: string; subTags?: Array<string> | null }> | null
  } | null
}

export type OrganismWithStreamsAndSveChannelsQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type OrganismWithStreamsAndSveChannelsQuery = {
  __typename?: 'Query'
  organism?: {
    __typename?: 'Organism'
    id: string
    type: OrganismType
    whiteLabel: boolean
    title: string
    description?: string | null
    publicationStatus: PublicationStatus
    defaultLocale?: string | null
    onFront: boolean
    frontPosition?: number | null
    officialWebSiteUrl: string
    sportIds: Array<string>
    forbiddenKeyWords?: Array<string> | null
    enableExternalSources?: boolean | null
    availableExternalSources?: Array<WidgetExternalContentSource> | null
    streamsIds?: Array<string> | null
    wmProfileId?: string | null
    sveChannelIds?: Array<string> | null
    organismPageApplicationDesignId?: string | null
    contentPageApplicationDesignId?: string | null
    adminsAutomatedEmails?: boolean | null
    visibleInMultisportApplication?: boolean | null
    streams?: Array<{
      __typename?: 'WMStream'
      id: string
      name: string
      ingestionStatus: WmIngestionStatus
      inputUrl: string
      outputUrl: string
      map?: { __typename?: 'StreamMap'; id: string; channelArn: string } | null
    }> | null
    sveChannels?: Array<{
      __typename?: 'SVEChannel'
      id: string
      name: string
      status: SveChannelState
      logoUrl?: string | null
      inputLossImageUrl?: string | null
      socialOutputUrl?: string | null
      socialOutputStreamName?: string | null
      endpoints?: {
        __typename?: 'SVEChannelEndpoints'
        rtmpInputUrl?: string | null
        cmafEndpointUrl?: string | null
        hlsEndpointUrl?: string | null
      } | null
    }> | null
    titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    descriptionT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    logo: {
      __typename?: 'Logo'
      coloredImage?: {
        __typename?: 'Media'
        id: string
        createdAt: Date
        updatedAt: Date
        title: string
        type: MediaType
        publicationStatus: PublicationStatus
        tags: Array<MediaTags>
        date?: Date | null
        startTrim?: number | null
        endTrim?: number | null
        liveStreamId?: string | null
        videoClipId?: string | null
        imageId?: string | null
        thumbnailId?: string | null
        ownerOrganismId?: string | null
        pinned?: boolean | null
        liveStream?: {
          __typename?: 'LiveStream'
          id: string
          playbackUrl: string
          ingestUrl?: string | null
          wildmokaEventID?: string | null
          videoEngine: VideoEngine
          state: LiveStreamState
          streamId?: string | null
          sveBroadcastId?: string | null
          sveChannelId?: string | null
          externalFeedProvider?: ExternalFeedType | null
          externalFeedUrl?: string | null
          event?: {
            __typename?: 'WMEvent'
            id: string
            name: string
            description?: string | null
            testStartDate: Date
            editoStartDate: Date
            editoStopDate: Date
            stopDate: Date
            state: WmEventState
            notifEmail?: string | null
            clipCreationAtEnd: boolean
            clipPublicationAtEnd: boolean
            livePublication: boolean
            stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
            live?: {
              __typename?: 'WMEventLive'
              id: string
              publishStatus: WmLiveState
              type: WmLiveType
              autoAnnounceDate?: Date | null
              template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
            } | null
            clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
          } | null
          sveBroadcast?: {
            __typename?: 'SVEBroadcast'
            id: string
            status: SveBroadcastState
            channelId: string
            startDate?: Date | null
            clipStartDate?: Date | null
            stopDate?: Date | null
            scheduledStartDate: Date
            scheduledClipStartDate: Date
            scheduledStopDate: Date
          } | null
          sveLiveMonitoring?: {
            __typename?: 'SVELiveMonitoring'
            alerts?: {
              __typename?: 'SVELiveMonitoringAlerts'
              audioNotDetected?: string | null
              initialProbeTakingLonger?: string | null
              videoNotDetected?: string | null
              RTMPHasNoAudioVideo?: string | null
              RTMPStreamNotFound?: string | null
            } | null
            stats?: {
              __typename?: 'SVELiveMonitoringStats'
              networkIn?: string | null
              droppedFrames?: string | null
              inputVideoFrameRate?: string | null
              outputAudioLevelLkfs?: string | null
            } | null
          } | null
        } | null
        videoClip?: {
          __typename?: 'VideoClip'
          id: string
          playbackUrl: string
          downloadLink: string
          status?: VideoClipStatus | null
          updatedAt: Date
          createdAt: Date
          fileId?: string | null
          error?: {
            __typename?: 'VideoClipError'
            guid: string
            workflowStatus: string
            workflowErrorAt: string
            errorMessage: string
            errorDetails: string
          } | null
          captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
          externalFeed?: {
            __typename?: 'ExternalFeed'
            externalFeedProvider: ExternalFeedType
            externalFeedUrl: string
          } | null
        } | null
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
        thumbnail?: {
          __typename?: 'Media'
          id: string
          image?: {
            __typename?: 'File'
            id: string
            filename: string
            size: number
            mimetype: string
            downloadUrl: string
            thumbUrl?: string | null
            createdAt: Date
            metadata?: {
              __typename?: 'FileMetadata'
              title?: string | null
              description?: string | null
              aspect?: number | null
              width?: number | null
              height?: number | null
              sourceFileId?: string | null
            } | null
          } | null
        } | null
        timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
      } | null
      transparentDarkImage?: {
        __typename?: 'Media'
        id: string
        createdAt: Date
        updatedAt: Date
        title: string
        type: MediaType
        publicationStatus: PublicationStatus
        tags: Array<MediaTags>
        date?: Date | null
        startTrim?: number | null
        endTrim?: number | null
        liveStreamId?: string | null
        videoClipId?: string | null
        imageId?: string | null
        thumbnailId?: string | null
        ownerOrganismId?: string | null
        pinned?: boolean | null
        liveStream?: {
          __typename?: 'LiveStream'
          id: string
          playbackUrl: string
          ingestUrl?: string | null
          wildmokaEventID?: string | null
          videoEngine: VideoEngine
          state: LiveStreamState
          streamId?: string | null
          sveBroadcastId?: string | null
          sveChannelId?: string | null
          externalFeedProvider?: ExternalFeedType | null
          externalFeedUrl?: string | null
          event?: {
            __typename?: 'WMEvent'
            id: string
            name: string
            description?: string | null
            testStartDate: Date
            editoStartDate: Date
            editoStopDate: Date
            stopDate: Date
            state: WmEventState
            notifEmail?: string | null
            clipCreationAtEnd: boolean
            clipPublicationAtEnd: boolean
            livePublication: boolean
            stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
            live?: {
              __typename?: 'WMEventLive'
              id: string
              publishStatus: WmLiveState
              type: WmLiveType
              autoAnnounceDate?: Date | null
              template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
            } | null
            clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
          } | null
          sveBroadcast?: {
            __typename?: 'SVEBroadcast'
            id: string
            status: SveBroadcastState
            channelId: string
            startDate?: Date | null
            clipStartDate?: Date | null
            stopDate?: Date | null
            scheduledStartDate: Date
            scheduledClipStartDate: Date
            scheduledStopDate: Date
          } | null
          sveLiveMonitoring?: {
            __typename?: 'SVELiveMonitoring'
            alerts?: {
              __typename?: 'SVELiveMonitoringAlerts'
              audioNotDetected?: string | null
              initialProbeTakingLonger?: string | null
              videoNotDetected?: string | null
              RTMPHasNoAudioVideo?: string | null
              RTMPStreamNotFound?: string | null
            } | null
            stats?: {
              __typename?: 'SVELiveMonitoringStats'
              networkIn?: string | null
              droppedFrames?: string | null
              inputVideoFrameRate?: string | null
              outputAudioLevelLkfs?: string | null
            } | null
          } | null
        } | null
        videoClip?: {
          __typename?: 'VideoClip'
          id: string
          playbackUrl: string
          downloadLink: string
          status?: VideoClipStatus | null
          updatedAt: Date
          createdAt: Date
          fileId?: string | null
          error?: {
            __typename?: 'VideoClipError'
            guid: string
            workflowStatus: string
            workflowErrorAt: string
            errorMessage: string
            errorDetails: string
          } | null
          captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
          externalFeed?: {
            __typename?: 'ExternalFeed'
            externalFeedProvider: ExternalFeedType
            externalFeedUrl: string
          } | null
        } | null
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
        thumbnail?: {
          __typename?: 'Media'
          id: string
          image?: {
            __typename?: 'File'
            id: string
            filename: string
            size: number
            mimetype: string
            downloadUrl: string
            thumbUrl?: string | null
            createdAt: Date
            metadata?: {
              __typename?: 'FileMetadata'
              title?: string | null
              description?: string | null
              aspect?: number | null
              width?: number | null
              height?: number | null
              sourceFileId?: string | null
            } | null
          } | null
        } | null
        timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
      } | null
      transparentLightImage?: {
        __typename?: 'Media'
        id: string
        createdAt: Date
        updatedAt: Date
        title: string
        type: MediaType
        publicationStatus: PublicationStatus
        tags: Array<MediaTags>
        date?: Date | null
        startTrim?: number | null
        endTrim?: number | null
        liveStreamId?: string | null
        videoClipId?: string | null
        imageId?: string | null
        thumbnailId?: string | null
        ownerOrganismId?: string | null
        pinned?: boolean | null
        liveStream?: {
          __typename?: 'LiveStream'
          id: string
          playbackUrl: string
          ingestUrl?: string | null
          wildmokaEventID?: string | null
          videoEngine: VideoEngine
          state: LiveStreamState
          streamId?: string | null
          sveBroadcastId?: string | null
          sveChannelId?: string | null
          externalFeedProvider?: ExternalFeedType | null
          externalFeedUrl?: string | null
          event?: {
            __typename?: 'WMEvent'
            id: string
            name: string
            description?: string | null
            testStartDate: Date
            editoStartDate: Date
            editoStopDate: Date
            stopDate: Date
            state: WmEventState
            notifEmail?: string | null
            clipCreationAtEnd: boolean
            clipPublicationAtEnd: boolean
            livePublication: boolean
            stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
            live?: {
              __typename?: 'WMEventLive'
              id: string
              publishStatus: WmLiveState
              type: WmLiveType
              autoAnnounceDate?: Date | null
              template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
            } | null
            clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
          } | null
          sveBroadcast?: {
            __typename?: 'SVEBroadcast'
            id: string
            status: SveBroadcastState
            channelId: string
            startDate?: Date | null
            clipStartDate?: Date | null
            stopDate?: Date | null
            scheduledStartDate: Date
            scheduledClipStartDate: Date
            scheduledStopDate: Date
          } | null
          sveLiveMonitoring?: {
            __typename?: 'SVELiveMonitoring'
            alerts?: {
              __typename?: 'SVELiveMonitoringAlerts'
              audioNotDetected?: string | null
              initialProbeTakingLonger?: string | null
              videoNotDetected?: string | null
              RTMPHasNoAudioVideo?: string | null
              RTMPStreamNotFound?: string | null
            } | null
            stats?: {
              __typename?: 'SVELiveMonitoringStats'
              networkIn?: string | null
              droppedFrames?: string | null
              inputVideoFrameRate?: string | null
              outputAudioLevelLkfs?: string | null
            } | null
          } | null
        } | null
        videoClip?: {
          __typename?: 'VideoClip'
          id: string
          playbackUrl: string
          downloadLink: string
          status?: VideoClipStatus | null
          updatedAt: Date
          createdAt: Date
          fileId?: string | null
          error?: {
            __typename?: 'VideoClipError'
            guid: string
            workflowStatus: string
            workflowErrorAt: string
            errorMessage: string
            errorDetails: string
          } | null
          captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
          externalFeed?: {
            __typename?: 'ExternalFeed'
            externalFeedProvider: ExternalFeedType
            externalFeedUrl: string
          } | null
        } | null
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
        thumbnail?: {
          __typename?: 'Media'
          id: string
          image?: {
            __typename?: 'File'
            id: string
            filename: string
            size: number
            mimetype: string
            downloadUrl: string
            thumbUrl?: string | null
            createdAt: Date
            metadata?: {
              __typename?: 'FileMetadata'
              title?: string | null
              description?: string | null
              aspect?: number | null
              width?: number | null
              height?: number | null
              sourceFileId?: string | null
            } | null
          } | null
        } | null
        timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
      } | null
    }
    sports: Array<{ __typename?: 'Sport'; id: string }>
    bannerImage?: {
      __typename?: 'Media'
      id: string
      createdAt: Date
      updatedAt: Date
      title: string
      type: MediaType
      publicationStatus: PublicationStatus
      tags: Array<MediaTags>
      date?: Date | null
      startTrim?: number | null
      endTrim?: number | null
      liveStreamId?: string | null
      videoClipId?: string | null
      imageId?: string | null
      thumbnailId?: string | null
      ownerOrganismId?: string | null
      pinned?: boolean | null
      liveStream?: {
        __typename?: 'LiveStream'
        id: string
        playbackUrl: string
        ingestUrl?: string | null
        wildmokaEventID?: string | null
        videoEngine: VideoEngine
        state: LiveStreamState
        streamId?: string | null
        sveBroadcastId?: string | null
        sveChannelId?: string | null
        externalFeedProvider?: ExternalFeedType | null
        externalFeedUrl?: string | null
        event?: {
          __typename?: 'WMEvent'
          id: string
          name: string
          description?: string | null
          testStartDate: Date
          editoStartDate: Date
          editoStopDate: Date
          stopDate: Date
          state: WmEventState
          notifEmail?: string | null
          clipCreationAtEnd: boolean
          clipPublicationAtEnd: boolean
          livePublication: boolean
          stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
          live?: {
            __typename?: 'WMEventLive'
            id: string
            publishStatus: WmLiveState
            type: WmLiveType
            autoAnnounceDate?: Date | null
            template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
          } | null
          clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
        } | null
        sveBroadcast?: {
          __typename?: 'SVEBroadcast'
          id: string
          status: SveBroadcastState
          channelId: string
          startDate?: Date | null
          clipStartDate?: Date | null
          stopDate?: Date | null
          scheduledStartDate: Date
          scheduledClipStartDate: Date
          scheduledStopDate: Date
        } | null
        sveLiveMonitoring?: {
          __typename?: 'SVELiveMonitoring'
          alerts?: {
            __typename?: 'SVELiveMonitoringAlerts'
            audioNotDetected?: string | null
            initialProbeTakingLonger?: string | null
            videoNotDetected?: string | null
            RTMPHasNoAudioVideo?: string | null
            RTMPStreamNotFound?: string | null
          } | null
          stats?: {
            __typename?: 'SVELiveMonitoringStats'
            networkIn?: string | null
            droppedFrames?: string | null
            inputVideoFrameRate?: string | null
            outputAudioLevelLkfs?: string | null
          } | null
        } | null
      } | null
      videoClip?: {
        __typename?: 'VideoClip'
        id: string
        playbackUrl: string
        downloadLink: string
        status?: VideoClipStatus | null
        updatedAt: Date
        createdAt: Date
        fileId?: string | null
        error?: {
          __typename?: 'VideoClipError'
          guid: string
          workflowStatus: string
          workflowErrorAt: string
          errorMessage: string
          errorDetails: string
        } | null
        captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
        externalFeed?: {
          __typename?: 'ExternalFeed'
          externalFeedProvider: ExternalFeedType
          externalFeedUrl: string
        } | null
      } | null
      image?: {
        __typename?: 'File'
        id: string
        filename: string
        size: number
        mimetype: string
        downloadUrl: string
        thumbUrl?: string | null
        createdAt: Date
        metadata?: {
          __typename?: 'FileMetadata'
          title?: string | null
          description?: string | null
          aspect?: number | null
          width?: number | null
          height?: number | null
          sourceFileId?: string | null
        } | null
      } | null
      thumbnail?: {
        __typename?: 'Media'
        id: string
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
      } | null
      timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
    } | null
    adminBadge?: {
      __typename?: 'Media'
      id: string
      createdAt: Date
      updatedAt: Date
      title: string
      type: MediaType
      publicationStatus: PublicationStatus
      tags: Array<MediaTags>
      date?: Date | null
      startTrim?: number | null
      endTrim?: number | null
      liveStreamId?: string | null
      videoClipId?: string | null
      imageId?: string | null
      thumbnailId?: string | null
      ownerOrganismId?: string | null
      pinned?: boolean | null
      liveStream?: {
        __typename?: 'LiveStream'
        id: string
        playbackUrl: string
        ingestUrl?: string | null
        wildmokaEventID?: string | null
        videoEngine: VideoEngine
        state: LiveStreamState
        streamId?: string | null
        sveBroadcastId?: string | null
        sveChannelId?: string | null
        externalFeedProvider?: ExternalFeedType | null
        externalFeedUrl?: string | null
        event?: {
          __typename?: 'WMEvent'
          id: string
          name: string
          description?: string | null
          testStartDate: Date
          editoStartDate: Date
          editoStopDate: Date
          stopDate: Date
          state: WmEventState
          notifEmail?: string | null
          clipCreationAtEnd: boolean
          clipPublicationAtEnd: boolean
          livePublication: boolean
          stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
          live?: {
            __typename?: 'WMEventLive'
            id: string
            publishStatus: WmLiveState
            type: WmLiveType
            autoAnnounceDate?: Date | null
            template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
          } | null
          clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
        } | null
        sveBroadcast?: {
          __typename?: 'SVEBroadcast'
          id: string
          status: SveBroadcastState
          channelId: string
          startDate?: Date | null
          clipStartDate?: Date | null
          stopDate?: Date | null
          scheduledStartDate: Date
          scheduledClipStartDate: Date
          scheduledStopDate: Date
        } | null
        sveLiveMonitoring?: {
          __typename?: 'SVELiveMonitoring'
          alerts?: {
            __typename?: 'SVELiveMonitoringAlerts'
            audioNotDetected?: string | null
            initialProbeTakingLonger?: string | null
            videoNotDetected?: string | null
            RTMPHasNoAudioVideo?: string | null
            RTMPStreamNotFound?: string | null
          } | null
          stats?: {
            __typename?: 'SVELiveMonitoringStats'
            networkIn?: string | null
            droppedFrames?: string | null
            inputVideoFrameRate?: string | null
            outputAudioLevelLkfs?: string | null
          } | null
        } | null
      } | null
      videoClip?: {
        __typename?: 'VideoClip'
        id: string
        playbackUrl: string
        downloadLink: string
        status?: VideoClipStatus | null
        updatedAt: Date
        createdAt: Date
        fileId?: string | null
        error?: {
          __typename?: 'VideoClipError'
          guid: string
          workflowStatus: string
          workflowErrorAt: string
          errorMessage: string
          errorDetails: string
        } | null
        captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
        externalFeed?: {
          __typename?: 'ExternalFeed'
          externalFeedProvider: ExternalFeedType
          externalFeedUrl: string
        } | null
      } | null
      image?: {
        __typename?: 'File'
        id: string
        filename: string
        size: number
        mimetype: string
        downloadUrl: string
        thumbUrl?: string | null
        createdAt: Date
        metadata?: {
          __typename?: 'FileMetadata'
          title?: string | null
          description?: string | null
          aspect?: number | null
          width?: number | null
          height?: number | null
          sourceFileId?: string | null
        } | null
      } | null
      thumbnail?: {
        __typename?: 'Media'
        id: string
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
      } | null
      timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
    } | null
    bannerVideo?: {
      __typename?: 'Media'
      id: string
      videoClip?: { __typename?: 'VideoClip'; id: string; playbackUrl: string } | null
    } | null
    pageWidgets?: Array<{
      __typename?: 'CustomPageWidget'
      name: CustomPageWidgetName
      position: number
      display?: CustomPageDisplayTypes | null
      contentDisplay?: ContentSetContentDisplay | null
      cardsDisplay?: CardsDisplay | null
      options?: {
        __typename?: 'CustomPageWidgetOptions'
        editedCategoryId?: string | null
        sportItemSetType?: SportItemSetType | null
        replays?: {
          __typename?: 'ReplaysWidget'
          defaultLocale: string
          maxReplays?: number | null
          depthInDays?: number | null
          nameT: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }>
        } | null
        externalContent?: {
          __typename?: 'ExternalContentWidget'
          name?: string | null
          defaultLocale: string
          maxItemsCount: number
          source: WidgetExternalContentSource
          nameT: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }>
          period?: { __typename?: 'StartEndDates'; startDate: Date; endDate: Date } | null
          wecContent?: {
            __typename?: 'WECNewsContent'
            teamECMIds?: Array<string> | null
            driverECMIds?: Array<string> | null
            carECMIds?: Array<string> | null
            categoryECMIds?: Array<string> | null
            raceECMIds?: Array<string> | null
          } | null
          rssContent?: { __typename?: 'RSSFeedContent'; feedUrl: string } | null
        } | null
      } | null
    }> | null
    offers: Array<{
      __typename?: 'PurchasableOffer'
      id: string
      mode: OfferMode
      delay?: number | null
      expiredAt?: Date | null
      publicationStatus: PublicationStatus
      title: string
      defaultLocale?: string | null
      url?: string | null
      bannerImageId?: string | null
      thumbnailImageId?: string | null
      freeWithLicense: boolean
      position?: number | null
      titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
      description: Array<{ __typename?: 'OfferDescription'; header: string; content: string }>
      descriptionT?: Array<{
        __typename?: 'ContentTranslationDescriptionPurchasableOffer'
        locale: string
        content?: Array<{ __typename?: 'OfferDescription'; header: string; content: string }> | null
      }> | null
      products: Array<{
        __typename?: 'Product'
        productId: string
        platform: Platform
        period?: Period | null
        publicationStatus: PublicationStatus
        purchasableOfferId?: string | null
        licensedUserOnly?: boolean | null
        premiumOf?: Array<string> | null
        prices: Array<{ __typename?: 'Price'; value: number; currency: string }>
      }>
    }>
    license?: { __typename?: 'OrganismLicense'; enabled: boolean; api?: LicenseApi | null } | null
    tags?: Array<{ __typename?: 'Tag'; id: string; title: string; subTags?: Array<string> | null }> | null
  } | null
}

export type OrganismWithStreamsAndSveChannelsAndAvailableSveChannelsAndWmStreamsQueryVariables = Exact<{
  id: Scalars['ID']
  input?: InputMaybe<OrganismAvailableSveChannelsInput>
}>

export type OrganismWithStreamsAndSveChannelsAndAvailableSveChannelsAndWmStreamsQuery = {
  __typename?: 'Query'
  organism?: {
    __typename?: 'Organism'
    id: string
    type: OrganismType
    whiteLabel: boolean
    title: string
    description?: string | null
    publicationStatus: PublicationStatus
    defaultLocale?: string | null
    onFront: boolean
    frontPosition?: number | null
    officialWebSiteUrl: string
    sportIds: Array<string>
    forbiddenKeyWords?: Array<string> | null
    enableExternalSources?: boolean | null
    availableExternalSources?: Array<WidgetExternalContentSource> | null
    streamsIds?: Array<string> | null
    wmProfileId?: string | null
    sveChannelIds?: Array<string> | null
    organismPageApplicationDesignId?: string | null
    contentPageApplicationDesignId?: string | null
    adminsAutomatedEmails?: boolean | null
    visibleInMultisportApplication?: boolean | null
    streams?: Array<{
      __typename?: 'WMStream'
      id: string
      name: string
      ingestionStatus: WmIngestionStatus
      inputUrl: string
      outputUrl: string
      map?: { __typename?: 'StreamMap'; id: string; channelArn: string } | null
    }> | null
    sveChannels?: Array<{
      __typename?: 'SVEChannel'
      id: string
      name: string
      status: SveChannelState
      logoUrl?: string | null
      inputLossImageUrl?: string | null
      socialOutputUrl?: string | null
      socialOutputStreamName?: string | null
      endpoints?: {
        __typename?: 'SVEChannelEndpoints'
        rtmpInputUrl?: string | null
        cmafEndpointUrl?: string | null
        hlsEndpointUrl?: string | null
      } | null
    }> | null
    availableSVEChannels: Array<{
      __typename?: 'SVEChannel'
      id: string
      name: string
      status: SveChannelState
      logoUrl?: string | null
      inputLossImageUrl?: string | null
      socialOutputUrl?: string | null
      socialOutputStreamName?: string | null
      endpoints?: {
        __typename?: 'SVEChannelEndpoints'
        rtmpInputUrl?: string | null
        cmafEndpointUrl?: string | null
        hlsEndpointUrl?: string | null
      } | null
    }>
    availableWMStreams: Array<{
      __typename?: 'WMStream'
      id: string
      name: string
      ingestionStatus: WmIngestionStatus
      inputUrl: string
      outputUrl: string
      map?: { __typename?: 'StreamMap'; id: string; channelArn: string } | null
    }>
    titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    descriptionT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    logo: {
      __typename?: 'Logo'
      coloredImage?: {
        __typename?: 'Media'
        id: string
        createdAt: Date
        updatedAt: Date
        title: string
        type: MediaType
        publicationStatus: PublicationStatus
        tags: Array<MediaTags>
        date?: Date | null
        startTrim?: number | null
        endTrim?: number | null
        liveStreamId?: string | null
        videoClipId?: string | null
        imageId?: string | null
        thumbnailId?: string | null
        ownerOrganismId?: string | null
        pinned?: boolean | null
        liveStream?: {
          __typename?: 'LiveStream'
          id: string
          playbackUrl: string
          ingestUrl?: string | null
          wildmokaEventID?: string | null
          videoEngine: VideoEngine
          state: LiveStreamState
          streamId?: string | null
          sveBroadcastId?: string | null
          sveChannelId?: string | null
          externalFeedProvider?: ExternalFeedType | null
          externalFeedUrl?: string | null
          event?: {
            __typename?: 'WMEvent'
            id: string
            name: string
            description?: string | null
            testStartDate: Date
            editoStartDate: Date
            editoStopDate: Date
            stopDate: Date
            state: WmEventState
            notifEmail?: string | null
            clipCreationAtEnd: boolean
            clipPublicationAtEnd: boolean
            livePublication: boolean
            stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
            live?: {
              __typename?: 'WMEventLive'
              id: string
              publishStatus: WmLiveState
              type: WmLiveType
              autoAnnounceDate?: Date | null
              template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
            } | null
            clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
          } | null
          sveBroadcast?: {
            __typename?: 'SVEBroadcast'
            id: string
            status: SveBroadcastState
            channelId: string
            startDate?: Date | null
            clipStartDate?: Date | null
            stopDate?: Date | null
            scheduledStartDate: Date
            scheduledClipStartDate: Date
            scheduledStopDate: Date
          } | null
          sveLiveMonitoring?: {
            __typename?: 'SVELiveMonitoring'
            alerts?: {
              __typename?: 'SVELiveMonitoringAlerts'
              audioNotDetected?: string | null
              initialProbeTakingLonger?: string | null
              videoNotDetected?: string | null
              RTMPHasNoAudioVideo?: string | null
              RTMPStreamNotFound?: string | null
            } | null
            stats?: {
              __typename?: 'SVELiveMonitoringStats'
              networkIn?: string | null
              droppedFrames?: string | null
              inputVideoFrameRate?: string | null
              outputAudioLevelLkfs?: string | null
            } | null
          } | null
        } | null
        videoClip?: {
          __typename?: 'VideoClip'
          id: string
          playbackUrl: string
          downloadLink: string
          status?: VideoClipStatus | null
          updatedAt: Date
          createdAt: Date
          fileId?: string | null
          error?: {
            __typename?: 'VideoClipError'
            guid: string
            workflowStatus: string
            workflowErrorAt: string
            errorMessage: string
            errorDetails: string
          } | null
          captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
          externalFeed?: {
            __typename?: 'ExternalFeed'
            externalFeedProvider: ExternalFeedType
            externalFeedUrl: string
          } | null
        } | null
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
        thumbnail?: {
          __typename?: 'Media'
          id: string
          image?: {
            __typename?: 'File'
            id: string
            filename: string
            size: number
            mimetype: string
            downloadUrl: string
            thumbUrl?: string | null
            createdAt: Date
            metadata?: {
              __typename?: 'FileMetadata'
              title?: string | null
              description?: string | null
              aspect?: number | null
              width?: number | null
              height?: number | null
              sourceFileId?: string | null
            } | null
          } | null
        } | null
        timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
      } | null
      transparentDarkImage?: {
        __typename?: 'Media'
        id: string
        createdAt: Date
        updatedAt: Date
        title: string
        type: MediaType
        publicationStatus: PublicationStatus
        tags: Array<MediaTags>
        date?: Date | null
        startTrim?: number | null
        endTrim?: number | null
        liveStreamId?: string | null
        videoClipId?: string | null
        imageId?: string | null
        thumbnailId?: string | null
        ownerOrganismId?: string | null
        pinned?: boolean | null
        liveStream?: {
          __typename?: 'LiveStream'
          id: string
          playbackUrl: string
          ingestUrl?: string | null
          wildmokaEventID?: string | null
          videoEngine: VideoEngine
          state: LiveStreamState
          streamId?: string | null
          sveBroadcastId?: string | null
          sveChannelId?: string | null
          externalFeedProvider?: ExternalFeedType | null
          externalFeedUrl?: string | null
          event?: {
            __typename?: 'WMEvent'
            id: string
            name: string
            description?: string | null
            testStartDate: Date
            editoStartDate: Date
            editoStopDate: Date
            stopDate: Date
            state: WmEventState
            notifEmail?: string | null
            clipCreationAtEnd: boolean
            clipPublicationAtEnd: boolean
            livePublication: boolean
            stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
            live?: {
              __typename?: 'WMEventLive'
              id: string
              publishStatus: WmLiveState
              type: WmLiveType
              autoAnnounceDate?: Date | null
              template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
            } | null
            clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
          } | null
          sveBroadcast?: {
            __typename?: 'SVEBroadcast'
            id: string
            status: SveBroadcastState
            channelId: string
            startDate?: Date | null
            clipStartDate?: Date | null
            stopDate?: Date | null
            scheduledStartDate: Date
            scheduledClipStartDate: Date
            scheduledStopDate: Date
          } | null
          sveLiveMonitoring?: {
            __typename?: 'SVELiveMonitoring'
            alerts?: {
              __typename?: 'SVELiveMonitoringAlerts'
              audioNotDetected?: string | null
              initialProbeTakingLonger?: string | null
              videoNotDetected?: string | null
              RTMPHasNoAudioVideo?: string | null
              RTMPStreamNotFound?: string | null
            } | null
            stats?: {
              __typename?: 'SVELiveMonitoringStats'
              networkIn?: string | null
              droppedFrames?: string | null
              inputVideoFrameRate?: string | null
              outputAudioLevelLkfs?: string | null
            } | null
          } | null
        } | null
        videoClip?: {
          __typename?: 'VideoClip'
          id: string
          playbackUrl: string
          downloadLink: string
          status?: VideoClipStatus | null
          updatedAt: Date
          createdAt: Date
          fileId?: string | null
          error?: {
            __typename?: 'VideoClipError'
            guid: string
            workflowStatus: string
            workflowErrorAt: string
            errorMessage: string
            errorDetails: string
          } | null
          captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
          externalFeed?: {
            __typename?: 'ExternalFeed'
            externalFeedProvider: ExternalFeedType
            externalFeedUrl: string
          } | null
        } | null
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
        thumbnail?: {
          __typename?: 'Media'
          id: string
          image?: {
            __typename?: 'File'
            id: string
            filename: string
            size: number
            mimetype: string
            downloadUrl: string
            thumbUrl?: string | null
            createdAt: Date
            metadata?: {
              __typename?: 'FileMetadata'
              title?: string | null
              description?: string | null
              aspect?: number | null
              width?: number | null
              height?: number | null
              sourceFileId?: string | null
            } | null
          } | null
        } | null
        timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
      } | null
      transparentLightImage?: {
        __typename?: 'Media'
        id: string
        createdAt: Date
        updatedAt: Date
        title: string
        type: MediaType
        publicationStatus: PublicationStatus
        tags: Array<MediaTags>
        date?: Date | null
        startTrim?: number | null
        endTrim?: number | null
        liveStreamId?: string | null
        videoClipId?: string | null
        imageId?: string | null
        thumbnailId?: string | null
        ownerOrganismId?: string | null
        pinned?: boolean | null
        liveStream?: {
          __typename?: 'LiveStream'
          id: string
          playbackUrl: string
          ingestUrl?: string | null
          wildmokaEventID?: string | null
          videoEngine: VideoEngine
          state: LiveStreamState
          streamId?: string | null
          sveBroadcastId?: string | null
          sveChannelId?: string | null
          externalFeedProvider?: ExternalFeedType | null
          externalFeedUrl?: string | null
          event?: {
            __typename?: 'WMEvent'
            id: string
            name: string
            description?: string | null
            testStartDate: Date
            editoStartDate: Date
            editoStopDate: Date
            stopDate: Date
            state: WmEventState
            notifEmail?: string | null
            clipCreationAtEnd: boolean
            clipPublicationAtEnd: boolean
            livePublication: boolean
            stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
            live?: {
              __typename?: 'WMEventLive'
              id: string
              publishStatus: WmLiveState
              type: WmLiveType
              autoAnnounceDate?: Date | null
              template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
            } | null
            clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
          } | null
          sveBroadcast?: {
            __typename?: 'SVEBroadcast'
            id: string
            status: SveBroadcastState
            channelId: string
            startDate?: Date | null
            clipStartDate?: Date | null
            stopDate?: Date | null
            scheduledStartDate: Date
            scheduledClipStartDate: Date
            scheduledStopDate: Date
          } | null
          sveLiveMonitoring?: {
            __typename?: 'SVELiveMonitoring'
            alerts?: {
              __typename?: 'SVELiveMonitoringAlerts'
              audioNotDetected?: string | null
              initialProbeTakingLonger?: string | null
              videoNotDetected?: string | null
              RTMPHasNoAudioVideo?: string | null
              RTMPStreamNotFound?: string | null
            } | null
            stats?: {
              __typename?: 'SVELiveMonitoringStats'
              networkIn?: string | null
              droppedFrames?: string | null
              inputVideoFrameRate?: string | null
              outputAudioLevelLkfs?: string | null
            } | null
          } | null
        } | null
        videoClip?: {
          __typename?: 'VideoClip'
          id: string
          playbackUrl: string
          downloadLink: string
          status?: VideoClipStatus | null
          updatedAt: Date
          createdAt: Date
          fileId?: string | null
          error?: {
            __typename?: 'VideoClipError'
            guid: string
            workflowStatus: string
            workflowErrorAt: string
            errorMessage: string
            errorDetails: string
          } | null
          captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
          externalFeed?: {
            __typename?: 'ExternalFeed'
            externalFeedProvider: ExternalFeedType
            externalFeedUrl: string
          } | null
        } | null
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
        thumbnail?: {
          __typename?: 'Media'
          id: string
          image?: {
            __typename?: 'File'
            id: string
            filename: string
            size: number
            mimetype: string
            downloadUrl: string
            thumbUrl?: string | null
            createdAt: Date
            metadata?: {
              __typename?: 'FileMetadata'
              title?: string | null
              description?: string | null
              aspect?: number | null
              width?: number | null
              height?: number | null
              sourceFileId?: string | null
            } | null
          } | null
        } | null
        timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
      } | null
    }
    sports: Array<{ __typename?: 'Sport'; id: string }>
    bannerImage?: {
      __typename?: 'Media'
      id: string
      createdAt: Date
      updatedAt: Date
      title: string
      type: MediaType
      publicationStatus: PublicationStatus
      tags: Array<MediaTags>
      date?: Date | null
      startTrim?: number | null
      endTrim?: number | null
      liveStreamId?: string | null
      videoClipId?: string | null
      imageId?: string | null
      thumbnailId?: string | null
      ownerOrganismId?: string | null
      pinned?: boolean | null
      liveStream?: {
        __typename?: 'LiveStream'
        id: string
        playbackUrl: string
        ingestUrl?: string | null
        wildmokaEventID?: string | null
        videoEngine: VideoEngine
        state: LiveStreamState
        streamId?: string | null
        sveBroadcastId?: string | null
        sveChannelId?: string | null
        externalFeedProvider?: ExternalFeedType | null
        externalFeedUrl?: string | null
        event?: {
          __typename?: 'WMEvent'
          id: string
          name: string
          description?: string | null
          testStartDate: Date
          editoStartDate: Date
          editoStopDate: Date
          stopDate: Date
          state: WmEventState
          notifEmail?: string | null
          clipCreationAtEnd: boolean
          clipPublicationAtEnd: boolean
          livePublication: boolean
          stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
          live?: {
            __typename?: 'WMEventLive'
            id: string
            publishStatus: WmLiveState
            type: WmLiveType
            autoAnnounceDate?: Date | null
            template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
          } | null
          clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
        } | null
        sveBroadcast?: {
          __typename?: 'SVEBroadcast'
          id: string
          status: SveBroadcastState
          channelId: string
          startDate?: Date | null
          clipStartDate?: Date | null
          stopDate?: Date | null
          scheduledStartDate: Date
          scheduledClipStartDate: Date
          scheduledStopDate: Date
        } | null
        sveLiveMonitoring?: {
          __typename?: 'SVELiveMonitoring'
          alerts?: {
            __typename?: 'SVELiveMonitoringAlerts'
            audioNotDetected?: string | null
            initialProbeTakingLonger?: string | null
            videoNotDetected?: string | null
            RTMPHasNoAudioVideo?: string | null
            RTMPStreamNotFound?: string | null
          } | null
          stats?: {
            __typename?: 'SVELiveMonitoringStats'
            networkIn?: string | null
            droppedFrames?: string | null
            inputVideoFrameRate?: string | null
            outputAudioLevelLkfs?: string | null
          } | null
        } | null
      } | null
      videoClip?: {
        __typename?: 'VideoClip'
        id: string
        playbackUrl: string
        downloadLink: string
        status?: VideoClipStatus | null
        updatedAt: Date
        createdAt: Date
        fileId?: string | null
        error?: {
          __typename?: 'VideoClipError'
          guid: string
          workflowStatus: string
          workflowErrorAt: string
          errorMessage: string
          errorDetails: string
        } | null
        captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
        externalFeed?: {
          __typename?: 'ExternalFeed'
          externalFeedProvider: ExternalFeedType
          externalFeedUrl: string
        } | null
      } | null
      image?: {
        __typename?: 'File'
        id: string
        filename: string
        size: number
        mimetype: string
        downloadUrl: string
        thumbUrl?: string | null
        createdAt: Date
        metadata?: {
          __typename?: 'FileMetadata'
          title?: string | null
          description?: string | null
          aspect?: number | null
          width?: number | null
          height?: number | null
          sourceFileId?: string | null
        } | null
      } | null
      thumbnail?: {
        __typename?: 'Media'
        id: string
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
      } | null
      timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
    } | null
    adminBadge?: {
      __typename?: 'Media'
      id: string
      createdAt: Date
      updatedAt: Date
      title: string
      type: MediaType
      publicationStatus: PublicationStatus
      tags: Array<MediaTags>
      date?: Date | null
      startTrim?: number | null
      endTrim?: number | null
      liveStreamId?: string | null
      videoClipId?: string | null
      imageId?: string | null
      thumbnailId?: string | null
      ownerOrganismId?: string | null
      pinned?: boolean | null
      liveStream?: {
        __typename?: 'LiveStream'
        id: string
        playbackUrl: string
        ingestUrl?: string | null
        wildmokaEventID?: string | null
        videoEngine: VideoEngine
        state: LiveStreamState
        streamId?: string | null
        sveBroadcastId?: string | null
        sveChannelId?: string | null
        externalFeedProvider?: ExternalFeedType | null
        externalFeedUrl?: string | null
        event?: {
          __typename?: 'WMEvent'
          id: string
          name: string
          description?: string | null
          testStartDate: Date
          editoStartDate: Date
          editoStopDate: Date
          stopDate: Date
          state: WmEventState
          notifEmail?: string | null
          clipCreationAtEnd: boolean
          clipPublicationAtEnd: boolean
          livePublication: boolean
          stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
          live?: {
            __typename?: 'WMEventLive'
            id: string
            publishStatus: WmLiveState
            type: WmLiveType
            autoAnnounceDate?: Date | null
            template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
          } | null
          clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
        } | null
        sveBroadcast?: {
          __typename?: 'SVEBroadcast'
          id: string
          status: SveBroadcastState
          channelId: string
          startDate?: Date | null
          clipStartDate?: Date | null
          stopDate?: Date | null
          scheduledStartDate: Date
          scheduledClipStartDate: Date
          scheduledStopDate: Date
        } | null
        sveLiveMonitoring?: {
          __typename?: 'SVELiveMonitoring'
          alerts?: {
            __typename?: 'SVELiveMonitoringAlerts'
            audioNotDetected?: string | null
            initialProbeTakingLonger?: string | null
            videoNotDetected?: string | null
            RTMPHasNoAudioVideo?: string | null
            RTMPStreamNotFound?: string | null
          } | null
          stats?: {
            __typename?: 'SVELiveMonitoringStats'
            networkIn?: string | null
            droppedFrames?: string | null
            inputVideoFrameRate?: string | null
            outputAudioLevelLkfs?: string | null
          } | null
        } | null
      } | null
      videoClip?: {
        __typename?: 'VideoClip'
        id: string
        playbackUrl: string
        downloadLink: string
        status?: VideoClipStatus | null
        updatedAt: Date
        createdAt: Date
        fileId?: string | null
        error?: {
          __typename?: 'VideoClipError'
          guid: string
          workflowStatus: string
          workflowErrorAt: string
          errorMessage: string
          errorDetails: string
        } | null
        captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
        externalFeed?: {
          __typename?: 'ExternalFeed'
          externalFeedProvider: ExternalFeedType
          externalFeedUrl: string
        } | null
      } | null
      image?: {
        __typename?: 'File'
        id: string
        filename: string
        size: number
        mimetype: string
        downloadUrl: string
        thumbUrl?: string | null
        createdAt: Date
        metadata?: {
          __typename?: 'FileMetadata'
          title?: string | null
          description?: string | null
          aspect?: number | null
          width?: number | null
          height?: number | null
          sourceFileId?: string | null
        } | null
      } | null
      thumbnail?: {
        __typename?: 'Media'
        id: string
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
      } | null
      timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
    } | null
    bannerVideo?: {
      __typename?: 'Media'
      id: string
      videoClip?: { __typename?: 'VideoClip'; id: string; playbackUrl: string } | null
    } | null
    pageWidgets?: Array<{
      __typename?: 'CustomPageWidget'
      name: CustomPageWidgetName
      position: number
      display?: CustomPageDisplayTypes | null
      contentDisplay?: ContentSetContentDisplay | null
      cardsDisplay?: CardsDisplay | null
      options?: {
        __typename?: 'CustomPageWidgetOptions'
        editedCategoryId?: string | null
        sportItemSetType?: SportItemSetType | null
        replays?: {
          __typename?: 'ReplaysWidget'
          defaultLocale: string
          maxReplays?: number | null
          depthInDays?: number | null
          nameT: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }>
        } | null
        externalContent?: {
          __typename?: 'ExternalContentWidget'
          name?: string | null
          defaultLocale: string
          maxItemsCount: number
          source: WidgetExternalContentSource
          nameT: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }>
          period?: { __typename?: 'StartEndDates'; startDate: Date; endDate: Date } | null
          wecContent?: {
            __typename?: 'WECNewsContent'
            teamECMIds?: Array<string> | null
            driverECMIds?: Array<string> | null
            carECMIds?: Array<string> | null
            categoryECMIds?: Array<string> | null
            raceECMIds?: Array<string> | null
          } | null
          rssContent?: { __typename?: 'RSSFeedContent'; feedUrl: string } | null
        } | null
      } | null
    }> | null
    offers: Array<{
      __typename?: 'PurchasableOffer'
      id: string
      mode: OfferMode
      delay?: number | null
      expiredAt?: Date | null
      publicationStatus: PublicationStatus
      title: string
      defaultLocale?: string | null
      url?: string | null
      bannerImageId?: string | null
      thumbnailImageId?: string | null
      freeWithLicense: boolean
      position?: number | null
      titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
      description: Array<{ __typename?: 'OfferDescription'; header: string; content: string }>
      descriptionT?: Array<{
        __typename?: 'ContentTranslationDescriptionPurchasableOffer'
        locale: string
        content?: Array<{ __typename?: 'OfferDescription'; header: string; content: string }> | null
      }> | null
      products: Array<{
        __typename?: 'Product'
        productId: string
        platform: Platform
        period?: Period | null
        publicationStatus: PublicationStatus
        purchasableOfferId?: string | null
        licensedUserOnly?: boolean | null
        premiumOf?: Array<string> | null
        prices: Array<{ __typename?: 'Price'; value: number; currency: string }>
      }>
    }>
    license?: { __typename?: 'OrganismLicense'; enabled: boolean; api?: LicenseApi | null } | null
    tags?: Array<{ __typename?: 'Tag'; id: string; title: string; subTags?: Array<string> | null }> | null
  } | null
}

export type OrganismsApplicationsQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type OrganismsApplicationsQuery = {
  __typename?: 'Query'
  organism?: {
    __typename?: 'Organism'
    id: string
    whiteLabel: boolean
    applications: Array<{
      __typename?: 'Application'
      id: string
      title?: string | null
      applicationId: string
      type: ApplicationType
      url?: string | null
      sponsoringUrl?: string | null
      sponsorImageFileId?: string | null
      organismId?: string | null
      publicationStatus: PublicationStatus
      packageName?: string | null
      appleSharedSecret?: string | null
      organism?: { __typename?: 'Organism'; whiteLabel: boolean; id: string; title: string } | null
      cac?: { __typename?: 'ContentAccessControl'; userAuthenticationRule?: CacUserAuthenticationRule | null } | null
      excludedContent: {
        __typename?: 'ExcludedContent'
        sportItemSetIds: Array<string>
        sportItemIds: Array<string>
        sportItemSets: Array<{ __typename?: 'SportItemSet'; id: string; title: string }>
        sportItems: Array<{ __typename?: 'SportItem'; id: string; title: string }>
      }
    }>
  } | null
}

export type OrganismsOffersQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type OrganismsOffersQuery = {
  __typename?: 'Query'
  organism?: {
    __typename?: 'Organism'
    id: string
    offers: Array<{ __typename?: 'PurchasableOffer'; id: string; title: string; publicationStatus: PublicationStatus }>
  } | null
}

export type DeleteOrganismMutationVariables = Exact<{
  organismId: Scalars['ID']
}>

export type DeleteOrganismMutation = {
  __typename?: 'Mutation'
  deleteOrganism?: { __typename?: 'Organism'; id: string } | null
}

export type MoveFrontPageMutationVariables = Exact<{
  input: MoveFrontPageInput
}>

export type MoveFrontPageMutation = {
  __typename?: 'Mutation'
  moveFrontPage: Array<{ __typename?: 'Organism'; id: string; frontPosition?: number | null }>
}

export type RevokeAllLicensesMutationVariables = Exact<{
  organismId: Scalars['ID']
}>

export type RevokeAllLicensesMutation = { __typename?: 'Mutation'; revokeAllLicenses: number }

export type PurchasableOfferProductContentFragment = {
  __typename?: 'Product'
  productId: string
  platform: Platform
  period?: Period | null
  publicationStatus: PublicationStatus
  purchasableOfferId?: string | null
  licensedUserOnly?: boolean | null
  premiumOf?: Array<string> | null
  prices: Array<{ __typename?: 'Price'; value: number; currency: string }>
}

export type PurchasableOfferContentFragment = {
  __typename?: 'PurchasableOffer'
  id: string
  mode: OfferMode
  delay?: number | null
  expiredAt?: Date | null
  publicationStatus: PublicationStatus
  title: string
  defaultLocale?: string | null
  url?: string | null
  bannerImageId?: string | null
  thumbnailImageId?: string | null
  freeWithLicense: boolean
  position?: number | null
  titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
  description: Array<{ __typename?: 'OfferDescription'; header: string; content: string }>
  descriptionT?: Array<{
    __typename?: 'ContentTranslationDescriptionPurchasableOffer'
    locale: string
    content?: Array<{ __typename?: 'OfferDescription'; header: string; content: string }> | null
  }> | null
  products: Array<{
    __typename?: 'Product'
    productId: string
    platform: Platform
    period?: Period | null
    publicationStatus: PublicationStatus
    purchasableOfferId?: string | null
    licensedUserOnly?: boolean | null
    premiumOf?: Array<string> | null
    prices: Array<{ __typename?: 'Price'; value: number; currency: string }>
  }>
}

export type PurchasableOffersQueryVariables = Exact<{ [key: string]: never }>

export type PurchasableOffersQuery = {
  __typename?: 'Query'
  purchasableOffers: Array<{
    __typename?: 'PurchasableOffer'
    nbValidSubscriptions?: number | null
    id: string
    mode: OfferMode
    delay?: number | null
    expiredAt?: Date | null
    publicationStatus: PublicationStatus
    title: string
    defaultLocale?: string | null
    url?: string | null
    bannerImageId?: string | null
    thumbnailImageId?: string | null
    freeWithLicense: boolean
    position?: number | null
    titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    description: Array<{ __typename?: 'OfferDescription'; header: string; content: string }>
    descriptionT?: Array<{
      __typename?: 'ContentTranslationDescriptionPurchasableOffer'
      locale: string
      content?: Array<{ __typename?: 'OfferDescription'; header: string; content: string }> | null
    }> | null
    products: Array<{
      __typename?: 'Product'
      productId: string
      platform: Platform
      period?: Period | null
      publicationStatus: PublicationStatus
      purchasableOfferId?: string | null
      licensedUserOnly?: boolean | null
      premiumOf?: Array<string> | null
      prices: Array<{ __typename?: 'Price'; value: number; currency: string }>
    }>
  }>
}

export type PurchasableOfferQueryVariables = Exact<{
  purchasableOfferId: Scalars['ID']
}>

export type PurchasableOfferQuery = {
  __typename?: 'Query'
  purchasableOffer?: {
    __typename?: 'PurchasableOffer'
    id: string
    mode: OfferMode
    delay?: number | null
    expiredAt?: Date | null
    publicationStatus: PublicationStatus
    title: string
    defaultLocale?: string | null
    url?: string | null
    bannerImageId?: string | null
    thumbnailImageId?: string | null
    freeWithLicense: boolean
    position?: number | null
    titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    description: Array<{ __typename?: 'OfferDescription'; header: string; content: string }>
    descriptionT?: Array<{
      __typename?: 'ContentTranslationDescriptionPurchasableOffer'
      locale: string
      content?: Array<{ __typename?: 'OfferDescription'; header: string; content: string }> | null
    }> | null
    products: Array<{
      __typename?: 'Product'
      productId: string
      platform: Platform
      period?: Period | null
      publicationStatus: PublicationStatus
      purchasableOfferId?: string | null
      licensedUserOnly?: boolean | null
      premiumOf?: Array<string> | null
      prices: Array<{ __typename?: 'Price'; value: number; currency: string }>
    }>
  } | null
}

export type CreatePurchasableOfferMutationVariables = Exact<{
  input: CreatePurchasableOfferInput
}>

export type CreatePurchasableOfferMutation = {
  __typename?: 'Mutation'
  createPurchasableOffer: {
    __typename?: 'PurchasableOffer'
    id: string
    mode: OfferMode
    delay?: number | null
    expiredAt?: Date | null
    publicationStatus: PublicationStatus
    title: string
    defaultLocale?: string | null
    url?: string | null
    bannerImageId?: string | null
    thumbnailImageId?: string | null
    freeWithLicense: boolean
    position?: number | null
    titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    description: Array<{ __typename?: 'OfferDescription'; header: string; content: string }>
    descriptionT?: Array<{
      __typename?: 'ContentTranslationDescriptionPurchasableOffer'
      locale: string
      content?: Array<{ __typename?: 'OfferDescription'; header: string; content: string }> | null
    }> | null
    products: Array<{
      __typename?: 'Product'
      productId: string
      platform: Platform
      period?: Period | null
      publicationStatus: PublicationStatus
      purchasableOfferId?: string | null
      licensedUserOnly?: boolean | null
      premiumOf?: Array<string> | null
      prices: Array<{ __typename?: 'Price'; value: number; currency: string }>
    }>
  }
}

export type UpdatePurchasableOfferMutationVariables = Exact<{
  id: Scalars['ID']
  input: UpdatePurchasableOfferInput
}>

export type UpdatePurchasableOfferMutation = {
  __typename?: 'Mutation'
  updatePurchasableOffer: {
    __typename?: 'PurchasableOffer'
    id: string
    mode: OfferMode
    delay?: number | null
    expiredAt?: Date | null
    publicationStatus: PublicationStatus
    title: string
    defaultLocale?: string | null
    url?: string | null
    bannerImageId?: string | null
    thumbnailImageId?: string | null
    freeWithLicense: boolean
    position?: number | null
    titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    description: Array<{ __typename?: 'OfferDescription'; header: string; content: string }>
    descriptionT?: Array<{
      __typename?: 'ContentTranslationDescriptionPurchasableOffer'
      locale: string
      content?: Array<{ __typename?: 'OfferDescription'; header: string; content: string }> | null
    }> | null
    products: Array<{
      __typename?: 'Product'
      productId: string
      platform: Platform
      period?: Period | null
      publicationStatus: PublicationStatus
      purchasableOfferId?: string | null
      licensedUserOnly?: boolean | null
      premiumOf?: Array<string> | null
      prices: Array<{ __typename?: 'Price'; value: number; currency: string }>
    }>
  }
}

export type UpdateProductMutationVariables = Exact<{
  id: Scalars['ID']
  productId: Scalars['String']
  input: UpdateProductInput
}>

export type UpdateProductMutation = {
  __typename?: 'Mutation'
  updateProduct: {
    __typename?: 'Product'
    productId: string
    platform: Platform
    period?: Period | null
    publicationStatus: PublicationStatus
    purchasableOfferId?: string | null
    licensedUserOnly?: boolean | null
    premiumOf?: Array<string> | null
    prices: Array<{ __typename?: 'Price'; value: number; currency: string }>
  }
}

export type LatestPurchaseExportDownloadUrlQueryVariables = Exact<{ [key: string]: never }>

export type LatestPurchaseExportDownloadUrlQuery = {
  __typename?: 'Query'
  latestPurchaseExportDownloadUrl?: string | null
}

export type DeleteProductMutationVariables = Exact<{
  id: Scalars['ID']
  input: DeleteProductInput
}>

export type DeleteProductMutation = { __typename?: 'Mutation'; deleteProduct: boolean }

export type DeletePurchasableOfferMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type DeletePurchasableOfferMutation = { __typename?: 'Mutation'; deletePurchasableOffer: boolean }

export type SchedulePurchaseExportMutationVariables = Exact<{ [key: string]: never }>

export type SchedulePurchaseExportMutation = { __typename?: 'Mutation'; schedulePurchaseExport: boolean }

export type MovePurchasableOfferMutationVariables = Exact<{
  input: MovePurchasableOfferInput
}>

export type MovePurchasableOfferMutation = {
  __typename?: 'Mutation'
  movePurchasableOffer: Array<{ __typename?: 'PurchasableOffer'; id: string; position?: number | null }>
}

export type UpdateRedButtonConfigMutationVariables = Exact<{ [key: string]: never }>

export type UpdateRedButtonConfigMutation = {
  __typename?: 'Mutation'
  updateRedButtonConfig: {
    __typename?: 'RedButtonConfig'
    lives: Array<{ __typename?: 'LiveConfig'; id: string; title: string; published: boolean; expiryDate: Date }>
  }
}

export type CleanCacheGlobalMutationVariables = Exact<{ [key: string]: never }>

export type CleanCacheGlobalMutation = { __typename?: 'Mutation'; cleanCacheGlobal?: boolean | null }

export type CleanCacheDatasourcesMutationVariables = Exact<{ [key: string]: never }>

export type CleanCacheDatasourcesMutation = { __typename?: 'Mutation'; cleanCacheDatasources?: boolean | null }

export type CleanCacheGraphqlMutationVariables = Exact<{ [key: string]: never }>

export type CleanCacheGraphqlMutation = { __typename?: 'Mutation'; cleanCacheGraphql?: boolean | null }

export type CreateSportMutationVariables = Exact<{
  input: CreateSportInput
}>

export type CreateSportMutation = {
  __typename?: 'Mutation'
  createSport: {
    __typename?: 'Sport'
    id: string
    title: string
    color: string
    cardImage?: {
      __typename?: 'Media'
      id: string
      createdAt: Date
      updatedAt: Date
      title: string
      type: MediaType
      publicationStatus: PublicationStatus
      tags: Array<MediaTags>
      date?: Date | null
      startTrim?: number | null
      endTrim?: number | null
      liveStreamId?: string | null
      videoClipId?: string | null
      imageId?: string | null
      thumbnailId?: string | null
      ownerOrganismId?: string | null
      pinned?: boolean | null
      liveStream?: {
        __typename?: 'LiveStream'
        id: string
        playbackUrl: string
        ingestUrl?: string | null
        wildmokaEventID?: string | null
        videoEngine: VideoEngine
        state: LiveStreamState
        streamId?: string | null
        sveBroadcastId?: string | null
        sveChannelId?: string | null
        externalFeedProvider?: ExternalFeedType | null
        externalFeedUrl?: string | null
        event?: {
          __typename?: 'WMEvent'
          id: string
          name: string
          description?: string | null
          testStartDate: Date
          editoStartDate: Date
          editoStopDate: Date
          stopDate: Date
          state: WmEventState
          notifEmail?: string | null
          clipCreationAtEnd: boolean
          clipPublicationAtEnd: boolean
          livePublication: boolean
          stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
          live?: {
            __typename?: 'WMEventLive'
            id: string
            publishStatus: WmLiveState
            type: WmLiveType
            autoAnnounceDate?: Date | null
            template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
          } | null
          clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
        } | null
        sveBroadcast?: {
          __typename?: 'SVEBroadcast'
          id: string
          status: SveBroadcastState
          channelId: string
          startDate?: Date | null
          clipStartDate?: Date | null
          stopDate?: Date | null
          scheduledStartDate: Date
          scheduledClipStartDate: Date
          scheduledStopDate: Date
        } | null
        sveLiveMonitoring?: {
          __typename?: 'SVELiveMonitoring'
          alerts?: {
            __typename?: 'SVELiveMonitoringAlerts'
            audioNotDetected?: string | null
            initialProbeTakingLonger?: string | null
            videoNotDetected?: string | null
            RTMPHasNoAudioVideo?: string | null
            RTMPStreamNotFound?: string | null
          } | null
          stats?: {
            __typename?: 'SVELiveMonitoringStats'
            networkIn?: string | null
            droppedFrames?: string | null
            inputVideoFrameRate?: string | null
            outputAudioLevelLkfs?: string | null
          } | null
        } | null
      } | null
      videoClip?: {
        __typename?: 'VideoClip'
        id: string
        playbackUrl: string
        downloadLink: string
        status?: VideoClipStatus | null
        updatedAt: Date
        createdAt: Date
        fileId?: string | null
        error?: {
          __typename?: 'VideoClipError'
          guid: string
          workflowStatus: string
          workflowErrorAt: string
          errorMessage: string
          errorDetails: string
        } | null
        captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
        externalFeed?: {
          __typename?: 'ExternalFeed'
          externalFeedProvider: ExternalFeedType
          externalFeedUrl: string
        } | null
      } | null
      image?: {
        __typename?: 'File'
        id: string
        filename: string
        size: number
        mimetype: string
        downloadUrl: string
        thumbUrl?: string | null
        createdAt: Date
        metadata?: {
          __typename?: 'FileMetadata'
          title?: string | null
          description?: string | null
          aspect?: number | null
          width?: number | null
          height?: number | null
          sourceFileId?: string | null
        } | null
      } | null
      thumbnail?: {
        __typename?: 'Media'
        id: string
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
      } | null
      timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
    } | null
    bannerImage?: {
      __typename?: 'Media'
      id: string
      createdAt: Date
      updatedAt: Date
      title: string
      type: MediaType
      publicationStatus: PublicationStatus
      tags: Array<MediaTags>
      date?: Date | null
      startTrim?: number | null
      endTrim?: number | null
      liveStreamId?: string | null
      videoClipId?: string | null
      imageId?: string | null
      thumbnailId?: string | null
      ownerOrganismId?: string | null
      pinned?: boolean | null
      liveStream?: {
        __typename?: 'LiveStream'
        id: string
        playbackUrl: string
        ingestUrl?: string | null
        wildmokaEventID?: string | null
        videoEngine: VideoEngine
        state: LiveStreamState
        streamId?: string | null
        sveBroadcastId?: string | null
        sveChannelId?: string | null
        externalFeedProvider?: ExternalFeedType | null
        externalFeedUrl?: string | null
        event?: {
          __typename?: 'WMEvent'
          id: string
          name: string
          description?: string | null
          testStartDate: Date
          editoStartDate: Date
          editoStopDate: Date
          stopDate: Date
          state: WmEventState
          notifEmail?: string | null
          clipCreationAtEnd: boolean
          clipPublicationAtEnd: boolean
          livePublication: boolean
          stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
          live?: {
            __typename?: 'WMEventLive'
            id: string
            publishStatus: WmLiveState
            type: WmLiveType
            autoAnnounceDate?: Date | null
            template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
          } | null
          clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
        } | null
        sveBroadcast?: {
          __typename?: 'SVEBroadcast'
          id: string
          status: SveBroadcastState
          channelId: string
          startDate?: Date | null
          clipStartDate?: Date | null
          stopDate?: Date | null
          scheduledStartDate: Date
          scheduledClipStartDate: Date
          scheduledStopDate: Date
        } | null
        sveLiveMonitoring?: {
          __typename?: 'SVELiveMonitoring'
          alerts?: {
            __typename?: 'SVELiveMonitoringAlerts'
            audioNotDetected?: string | null
            initialProbeTakingLonger?: string | null
            videoNotDetected?: string | null
            RTMPHasNoAudioVideo?: string | null
            RTMPStreamNotFound?: string | null
          } | null
          stats?: {
            __typename?: 'SVELiveMonitoringStats'
            networkIn?: string | null
            droppedFrames?: string | null
            inputVideoFrameRate?: string | null
            outputAudioLevelLkfs?: string | null
          } | null
        } | null
      } | null
      videoClip?: {
        __typename?: 'VideoClip'
        id: string
        playbackUrl: string
        downloadLink: string
        status?: VideoClipStatus | null
        updatedAt: Date
        createdAt: Date
        fileId?: string | null
        error?: {
          __typename?: 'VideoClipError'
          guid: string
          workflowStatus: string
          workflowErrorAt: string
          errorMessage: string
          errorDetails: string
        } | null
        captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
        externalFeed?: {
          __typename?: 'ExternalFeed'
          externalFeedProvider: ExternalFeedType
          externalFeedUrl: string
        } | null
      } | null
      image?: {
        __typename?: 'File'
        id: string
        filename: string
        size: number
        mimetype: string
        downloadUrl: string
        thumbUrl?: string | null
        createdAt: Date
        metadata?: {
          __typename?: 'FileMetadata'
          title?: string | null
          description?: string | null
          aspect?: number | null
          width?: number | null
          height?: number | null
          sourceFileId?: string | null
        } | null
      } | null
      thumbnail?: {
        __typename?: 'Media'
        id: string
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
      } | null
      timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
    } | null
    bannerVideo?: {
      __typename?: 'Media'
      id: string
      videoClip?: { __typename?: 'VideoClip'; id: string; playbackUrl: string } | null
    } | null
    iconImage?: {
      __typename?: 'Media'
      id: string
      createdAt: Date
      updatedAt: Date
      title: string
      type: MediaType
      publicationStatus: PublicationStatus
      tags: Array<MediaTags>
      date?: Date | null
      startTrim?: number | null
      endTrim?: number | null
      liveStreamId?: string | null
      videoClipId?: string | null
      imageId?: string | null
      thumbnailId?: string | null
      ownerOrganismId?: string | null
      pinned?: boolean | null
      liveStream?: {
        __typename?: 'LiveStream'
        id: string
        playbackUrl: string
        ingestUrl?: string | null
        wildmokaEventID?: string | null
        videoEngine: VideoEngine
        state: LiveStreamState
        streamId?: string | null
        sveBroadcastId?: string | null
        sveChannelId?: string | null
        externalFeedProvider?: ExternalFeedType | null
        externalFeedUrl?: string | null
        event?: {
          __typename?: 'WMEvent'
          id: string
          name: string
          description?: string | null
          testStartDate: Date
          editoStartDate: Date
          editoStopDate: Date
          stopDate: Date
          state: WmEventState
          notifEmail?: string | null
          clipCreationAtEnd: boolean
          clipPublicationAtEnd: boolean
          livePublication: boolean
          stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
          live?: {
            __typename?: 'WMEventLive'
            id: string
            publishStatus: WmLiveState
            type: WmLiveType
            autoAnnounceDate?: Date | null
            template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
          } | null
          clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
        } | null
        sveBroadcast?: {
          __typename?: 'SVEBroadcast'
          id: string
          status: SveBroadcastState
          channelId: string
          startDate?: Date | null
          clipStartDate?: Date | null
          stopDate?: Date | null
          scheduledStartDate: Date
          scheduledClipStartDate: Date
          scheduledStopDate: Date
        } | null
        sveLiveMonitoring?: {
          __typename?: 'SVELiveMonitoring'
          alerts?: {
            __typename?: 'SVELiveMonitoringAlerts'
            audioNotDetected?: string | null
            initialProbeTakingLonger?: string | null
            videoNotDetected?: string | null
            RTMPHasNoAudioVideo?: string | null
            RTMPStreamNotFound?: string | null
          } | null
          stats?: {
            __typename?: 'SVELiveMonitoringStats'
            networkIn?: string | null
            droppedFrames?: string | null
            inputVideoFrameRate?: string | null
            outputAudioLevelLkfs?: string | null
          } | null
        } | null
      } | null
      videoClip?: {
        __typename?: 'VideoClip'
        id: string
        playbackUrl: string
        downloadLink: string
        status?: VideoClipStatus | null
        updatedAt: Date
        createdAt: Date
        fileId?: string | null
        error?: {
          __typename?: 'VideoClipError'
          guid: string
          workflowStatus: string
          workflowErrorAt: string
          errorMessage: string
          errorDetails: string
        } | null
        captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
        externalFeed?: {
          __typename?: 'ExternalFeed'
          externalFeedProvider: ExternalFeedType
          externalFeedUrl: string
        } | null
      } | null
      image?: {
        __typename?: 'File'
        id: string
        filename: string
        size: number
        mimetype: string
        downloadUrl: string
        thumbUrl?: string | null
        createdAt: Date
        metadata?: {
          __typename?: 'FileMetadata'
          title?: string | null
          description?: string | null
          aspect?: number | null
          width?: number | null
          height?: number | null
          sourceFileId?: string | null
        } | null
      } | null
      thumbnail?: {
        __typename?: 'Media'
        id: string
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
      } | null
      timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
    } | null
    organisms: Array<{ __typename?: 'Organism'; id: string }>
  }
}

export type UpdateSportMutationVariables = Exact<{
  input: UpdateSportInput
}>

export type UpdateSportMutation = {
  __typename?: 'Mutation'
  updateSport: {
    __typename?: 'Sport'
    id: string
    title: string
    color: string
    onFront: boolean
    frontPosition?: number | null
    cardImage?: {
      __typename?: 'Media'
      id: string
      createdAt: Date
      updatedAt: Date
      title: string
      type: MediaType
      publicationStatus: PublicationStatus
      tags: Array<MediaTags>
      date?: Date | null
      startTrim?: number | null
      endTrim?: number | null
      liveStreamId?: string | null
      videoClipId?: string | null
      imageId?: string | null
      thumbnailId?: string | null
      ownerOrganismId?: string | null
      pinned?: boolean | null
      liveStream?: {
        __typename?: 'LiveStream'
        id: string
        playbackUrl: string
        ingestUrl?: string | null
        wildmokaEventID?: string | null
        videoEngine: VideoEngine
        state: LiveStreamState
        streamId?: string | null
        sveBroadcastId?: string | null
        sveChannelId?: string | null
        externalFeedProvider?: ExternalFeedType | null
        externalFeedUrl?: string | null
        event?: {
          __typename?: 'WMEvent'
          id: string
          name: string
          description?: string | null
          testStartDate: Date
          editoStartDate: Date
          editoStopDate: Date
          stopDate: Date
          state: WmEventState
          notifEmail?: string | null
          clipCreationAtEnd: boolean
          clipPublicationAtEnd: boolean
          livePublication: boolean
          stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
          live?: {
            __typename?: 'WMEventLive'
            id: string
            publishStatus: WmLiveState
            type: WmLiveType
            autoAnnounceDate?: Date | null
            template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
          } | null
          clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
        } | null
        sveBroadcast?: {
          __typename?: 'SVEBroadcast'
          id: string
          status: SveBroadcastState
          channelId: string
          startDate?: Date | null
          clipStartDate?: Date | null
          stopDate?: Date | null
          scheduledStartDate: Date
          scheduledClipStartDate: Date
          scheduledStopDate: Date
        } | null
        sveLiveMonitoring?: {
          __typename?: 'SVELiveMonitoring'
          alerts?: {
            __typename?: 'SVELiveMonitoringAlerts'
            audioNotDetected?: string | null
            initialProbeTakingLonger?: string | null
            videoNotDetected?: string | null
            RTMPHasNoAudioVideo?: string | null
            RTMPStreamNotFound?: string | null
          } | null
          stats?: {
            __typename?: 'SVELiveMonitoringStats'
            networkIn?: string | null
            droppedFrames?: string | null
            inputVideoFrameRate?: string | null
            outputAudioLevelLkfs?: string | null
          } | null
        } | null
      } | null
      videoClip?: {
        __typename?: 'VideoClip'
        id: string
        playbackUrl: string
        downloadLink: string
        status?: VideoClipStatus | null
        updatedAt: Date
        createdAt: Date
        fileId?: string | null
        error?: {
          __typename?: 'VideoClipError'
          guid: string
          workflowStatus: string
          workflowErrorAt: string
          errorMessage: string
          errorDetails: string
        } | null
        captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
        externalFeed?: {
          __typename?: 'ExternalFeed'
          externalFeedProvider: ExternalFeedType
          externalFeedUrl: string
        } | null
      } | null
      image?: {
        __typename?: 'File'
        id: string
        filename: string
        size: number
        mimetype: string
        downloadUrl: string
        thumbUrl?: string | null
        createdAt: Date
        metadata?: {
          __typename?: 'FileMetadata'
          title?: string | null
          description?: string | null
          aspect?: number | null
          width?: number | null
          height?: number | null
          sourceFileId?: string | null
        } | null
      } | null
      thumbnail?: {
        __typename?: 'Media'
        id: string
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
      } | null
      timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
    } | null
    bannerImage?: {
      __typename?: 'Media'
      id: string
      createdAt: Date
      updatedAt: Date
      title: string
      type: MediaType
      publicationStatus: PublicationStatus
      tags: Array<MediaTags>
      date?: Date | null
      startTrim?: number | null
      endTrim?: number | null
      liveStreamId?: string | null
      videoClipId?: string | null
      imageId?: string | null
      thumbnailId?: string | null
      ownerOrganismId?: string | null
      pinned?: boolean | null
      liveStream?: {
        __typename?: 'LiveStream'
        id: string
        playbackUrl: string
        ingestUrl?: string | null
        wildmokaEventID?: string | null
        videoEngine: VideoEngine
        state: LiveStreamState
        streamId?: string | null
        sveBroadcastId?: string | null
        sveChannelId?: string | null
        externalFeedProvider?: ExternalFeedType | null
        externalFeedUrl?: string | null
        event?: {
          __typename?: 'WMEvent'
          id: string
          name: string
          description?: string | null
          testStartDate: Date
          editoStartDate: Date
          editoStopDate: Date
          stopDate: Date
          state: WmEventState
          notifEmail?: string | null
          clipCreationAtEnd: boolean
          clipPublicationAtEnd: boolean
          livePublication: boolean
          stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
          live?: {
            __typename?: 'WMEventLive'
            id: string
            publishStatus: WmLiveState
            type: WmLiveType
            autoAnnounceDate?: Date | null
            template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
          } | null
          clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
        } | null
        sveBroadcast?: {
          __typename?: 'SVEBroadcast'
          id: string
          status: SveBroadcastState
          channelId: string
          startDate?: Date | null
          clipStartDate?: Date | null
          stopDate?: Date | null
          scheduledStartDate: Date
          scheduledClipStartDate: Date
          scheduledStopDate: Date
        } | null
        sveLiveMonitoring?: {
          __typename?: 'SVELiveMonitoring'
          alerts?: {
            __typename?: 'SVELiveMonitoringAlerts'
            audioNotDetected?: string | null
            initialProbeTakingLonger?: string | null
            videoNotDetected?: string | null
            RTMPHasNoAudioVideo?: string | null
            RTMPStreamNotFound?: string | null
          } | null
          stats?: {
            __typename?: 'SVELiveMonitoringStats'
            networkIn?: string | null
            droppedFrames?: string | null
            inputVideoFrameRate?: string | null
            outputAudioLevelLkfs?: string | null
          } | null
        } | null
      } | null
      videoClip?: {
        __typename?: 'VideoClip'
        id: string
        playbackUrl: string
        downloadLink: string
        status?: VideoClipStatus | null
        updatedAt: Date
        createdAt: Date
        fileId?: string | null
        error?: {
          __typename?: 'VideoClipError'
          guid: string
          workflowStatus: string
          workflowErrorAt: string
          errorMessage: string
          errorDetails: string
        } | null
        captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
        externalFeed?: {
          __typename?: 'ExternalFeed'
          externalFeedProvider: ExternalFeedType
          externalFeedUrl: string
        } | null
      } | null
      image?: {
        __typename?: 'File'
        id: string
        filename: string
        size: number
        mimetype: string
        downloadUrl: string
        thumbUrl?: string | null
        createdAt: Date
        metadata?: {
          __typename?: 'FileMetadata'
          title?: string | null
          description?: string | null
          aspect?: number | null
          width?: number | null
          height?: number | null
          sourceFileId?: string | null
        } | null
      } | null
      thumbnail?: {
        __typename?: 'Media'
        id: string
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
      } | null
      timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
    } | null
    bannerVideo?: {
      __typename?: 'Media'
      id: string
      videoClip?: { __typename?: 'VideoClip'; id: string; playbackUrl: string } | null
    } | null
    iconImage?: {
      __typename?: 'Media'
      id: string
      createdAt: Date
      updatedAt: Date
      title: string
      type: MediaType
      publicationStatus: PublicationStatus
      tags: Array<MediaTags>
      date?: Date | null
      startTrim?: number | null
      endTrim?: number | null
      liveStreamId?: string | null
      videoClipId?: string | null
      imageId?: string | null
      thumbnailId?: string | null
      ownerOrganismId?: string | null
      pinned?: boolean | null
      liveStream?: {
        __typename?: 'LiveStream'
        id: string
        playbackUrl: string
        ingestUrl?: string | null
        wildmokaEventID?: string | null
        videoEngine: VideoEngine
        state: LiveStreamState
        streamId?: string | null
        sveBroadcastId?: string | null
        sveChannelId?: string | null
        externalFeedProvider?: ExternalFeedType | null
        externalFeedUrl?: string | null
        event?: {
          __typename?: 'WMEvent'
          id: string
          name: string
          description?: string | null
          testStartDate: Date
          editoStartDate: Date
          editoStopDate: Date
          stopDate: Date
          state: WmEventState
          notifEmail?: string | null
          clipCreationAtEnd: boolean
          clipPublicationAtEnd: boolean
          livePublication: boolean
          stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
          live?: {
            __typename?: 'WMEventLive'
            id: string
            publishStatus: WmLiveState
            type: WmLiveType
            autoAnnounceDate?: Date | null
            template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
          } | null
          clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
        } | null
        sveBroadcast?: {
          __typename?: 'SVEBroadcast'
          id: string
          status: SveBroadcastState
          channelId: string
          startDate?: Date | null
          clipStartDate?: Date | null
          stopDate?: Date | null
          scheduledStartDate: Date
          scheduledClipStartDate: Date
          scheduledStopDate: Date
        } | null
        sveLiveMonitoring?: {
          __typename?: 'SVELiveMonitoring'
          alerts?: {
            __typename?: 'SVELiveMonitoringAlerts'
            audioNotDetected?: string | null
            initialProbeTakingLonger?: string | null
            videoNotDetected?: string | null
            RTMPHasNoAudioVideo?: string | null
            RTMPStreamNotFound?: string | null
          } | null
          stats?: {
            __typename?: 'SVELiveMonitoringStats'
            networkIn?: string | null
            droppedFrames?: string | null
            inputVideoFrameRate?: string | null
            outputAudioLevelLkfs?: string | null
          } | null
        } | null
      } | null
      videoClip?: {
        __typename?: 'VideoClip'
        id: string
        playbackUrl: string
        downloadLink: string
        status?: VideoClipStatus | null
        updatedAt: Date
        createdAt: Date
        fileId?: string | null
        error?: {
          __typename?: 'VideoClipError'
          guid: string
          workflowStatus: string
          workflowErrorAt: string
          errorMessage: string
          errorDetails: string
        } | null
        captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
        externalFeed?: {
          __typename?: 'ExternalFeed'
          externalFeedProvider: ExternalFeedType
          externalFeedUrl: string
        } | null
      } | null
      image?: {
        __typename?: 'File'
        id: string
        filename: string
        size: number
        mimetype: string
        downloadUrl: string
        thumbUrl?: string | null
        createdAt: Date
        metadata?: {
          __typename?: 'FileMetadata'
          title?: string | null
          description?: string | null
          aspect?: number | null
          width?: number | null
          height?: number | null
          sourceFileId?: string | null
        } | null
      } | null
      thumbnail?: {
        __typename?: 'Media'
        id: string
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
      } | null
      timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
    } | null
  }
}

export type DeleteSportMutationVariables = Exact<{
  sportId: Scalars['ID']
}>

export type DeleteSportMutation = { __typename?: 'Mutation'; deleteSport?: { __typename?: 'Sport'; id: string } | null }

export type GetSportsQueryVariables = Exact<{ [key: string]: never }>

export type GetSportsQuery = {
  __typename?: 'Query'
  sports?: {
    __typename?: 'SportPageResult'
    totalCount?: number | null
    sports: Array<{
      __typename?: 'Sport'
      id: string
      title: string
      color: string
      onFront: boolean
      frontPosition?: number | null
      organisms: Array<{ __typename?: 'Organism'; id: string; title: string }>
      bannerImage?: {
        __typename?: 'Media'
        id: string
        createdAt: Date
        updatedAt: Date
        title: string
        type: MediaType
        publicationStatus: PublicationStatus
        tags: Array<MediaTags>
        date?: Date | null
        startTrim?: number | null
        endTrim?: number | null
        liveStreamId?: string | null
        videoClipId?: string | null
        imageId?: string | null
        thumbnailId?: string | null
        ownerOrganismId?: string | null
        pinned?: boolean | null
        liveStream?: {
          __typename?: 'LiveStream'
          id: string
          playbackUrl: string
          ingestUrl?: string | null
          wildmokaEventID?: string | null
          videoEngine: VideoEngine
          state: LiveStreamState
          streamId?: string | null
          sveBroadcastId?: string | null
          sveChannelId?: string | null
          externalFeedProvider?: ExternalFeedType | null
          externalFeedUrl?: string | null
          event?: {
            __typename?: 'WMEvent'
            id: string
            name: string
            description?: string | null
            testStartDate: Date
            editoStartDate: Date
            editoStopDate: Date
            stopDate: Date
            state: WmEventState
            notifEmail?: string | null
            clipCreationAtEnd: boolean
            clipPublicationAtEnd: boolean
            livePublication: boolean
            stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
            live?: {
              __typename?: 'WMEventLive'
              id: string
              publishStatus: WmLiveState
              type: WmLiveType
              autoAnnounceDate?: Date | null
              template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
            } | null
            clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
          } | null
          sveBroadcast?: {
            __typename?: 'SVEBroadcast'
            id: string
            status: SveBroadcastState
            channelId: string
            startDate?: Date | null
            clipStartDate?: Date | null
            stopDate?: Date | null
            scheduledStartDate: Date
            scheduledClipStartDate: Date
            scheduledStopDate: Date
          } | null
          sveLiveMonitoring?: {
            __typename?: 'SVELiveMonitoring'
            alerts?: {
              __typename?: 'SVELiveMonitoringAlerts'
              audioNotDetected?: string | null
              initialProbeTakingLonger?: string | null
              videoNotDetected?: string | null
              RTMPHasNoAudioVideo?: string | null
              RTMPStreamNotFound?: string | null
            } | null
            stats?: {
              __typename?: 'SVELiveMonitoringStats'
              networkIn?: string | null
              droppedFrames?: string | null
              inputVideoFrameRate?: string | null
              outputAudioLevelLkfs?: string | null
            } | null
          } | null
        } | null
        videoClip?: {
          __typename?: 'VideoClip'
          id: string
          playbackUrl: string
          downloadLink: string
          status?: VideoClipStatus | null
          updatedAt: Date
          createdAt: Date
          fileId?: string | null
          error?: {
            __typename?: 'VideoClipError'
            guid: string
            workflowStatus: string
            workflowErrorAt: string
            errorMessage: string
            errorDetails: string
          } | null
          captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
          externalFeed?: {
            __typename?: 'ExternalFeed'
            externalFeedProvider: ExternalFeedType
            externalFeedUrl: string
          } | null
        } | null
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
        thumbnail?: {
          __typename?: 'Media'
          id: string
          image?: {
            __typename?: 'File'
            id: string
            filename: string
            size: number
            mimetype: string
            downloadUrl: string
            thumbUrl?: string | null
            createdAt: Date
            metadata?: {
              __typename?: 'FileMetadata'
              title?: string | null
              description?: string | null
              aspect?: number | null
              width?: number | null
              height?: number | null
              sourceFileId?: string | null
            } | null
          } | null
        } | null
        timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
      } | null
      bannerVideo?: {
        __typename?: 'Media'
        id: string
        videoClip?: { __typename?: 'VideoClip'; id: string; playbackUrl: string } | null
      } | null
      iconImage?: {
        __typename?: 'Media'
        id: string
        createdAt: Date
        updatedAt: Date
        title: string
        type: MediaType
        publicationStatus: PublicationStatus
        tags: Array<MediaTags>
        date?: Date | null
        startTrim?: number | null
        endTrim?: number | null
        liveStreamId?: string | null
        videoClipId?: string | null
        imageId?: string | null
        thumbnailId?: string | null
        ownerOrganismId?: string | null
        pinned?: boolean | null
        liveStream?: {
          __typename?: 'LiveStream'
          id: string
          playbackUrl: string
          ingestUrl?: string | null
          wildmokaEventID?: string | null
          videoEngine: VideoEngine
          state: LiveStreamState
          streamId?: string | null
          sveBroadcastId?: string | null
          sveChannelId?: string | null
          externalFeedProvider?: ExternalFeedType | null
          externalFeedUrl?: string | null
          event?: {
            __typename?: 'WMEvent'
            id: string
            name: string
            description?: string | null
            testStartDate: Date
            editoStartDate: Date
            editoStopDate: Date
            stopDate: Date
            state: WmEventState
            notifEmail?: string | null
            clipCreationAtEnd: boolean
            clipPublicationAtEnd: boolean
            livePublication: boolean
            stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
            live?: {
              __typename?: 'WMEventLive'
              id: string
              publishStatus: WmLiveState
              type: WmLiveType
              autoAnnounceDate?: Date | null
              template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
            } | null
            clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
          } | null
          sveBroadcast?: {
            __typename?: 'SVEBroadcast'
            id: string
            status: SveBroadcastState
            channelId: string
            startDate?: Date | null
            clipStartDate?: Date | null
            stopDate?: Date | null
            scheduledStartDate: Date
            scheduledClipStartDate: Date
            scheduledStopDate: Date
          } | null
          sveLiveMonitoring?: {
            __typename?: 'SVELiveMonitoring'
            alerts?: {
              __typename?: 'SVELiveMonitoringAlerts'
              audioNotDetected?: string | null
              initialProbeTakingLonger?: string | null
              videoNotDetected?: string | null
              RTMPHasNoAudioVideo?: string | null
              RTMPStreamNotFound?: string | null
            } | null
            stats?: {
              __typename?: 'SVELiveMonitoringStats'
              networkIn?: string | null
              droppedFrames?: string | null
              inputVideoFrameRate?: string | null
              outputAudioLevelLkfs?: string | null
            } | null
          } | null
        } | null
        videoClip?: {
          __typename?: 'VideoClip'
          id: string
          playbackUrl: string
          downloadLink: string
          status?: VideoClipStatus | null
          updatedAt: Date
          createdAt: Date
          fileId?: string | null
          error?: {
            __typename?: 'VideoClipError'
            guid: string
            workflowStatus: string
            workflowErrorAt: string
            errorMessage: string
            errorDetails: string
          } | null
          captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
          externalFeed?: {
            __typename?: 'ExternalFeed'
            externalFeedProvider: ExternalFeedType
            externalFeedUrl: string
          } | null
        } | null
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
        thumbnail?: {
          __typename?: 'Media'
          id: string
          image?: {
            __typename?: 'File'
            id: string
            filename: string
            size: number
            mimetype: string
            downloadUrl: string
            thumbUrl?: string | null
            createdAt: Date
            metadata?: {
              __typename?: 'FileMetadata'
              title?: string | null
              description?: string | null
              aspect?: number | null
              width?: number | null
              height?: number | null
              sourceFileId?: string | null
            } | null
          } | null
        } | null
        timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
      } | null
      cardImage?: {
        __typename?: 'Media'
        id: string
        createdAt: Date
        updatedAt: Date
        title: string
        type: MediaType
        publicationStatus: PublicationStatus
        tags: Array<MediaTags>
        date?: Date | null
        startTrim?: number | null
        endTrim?: number | null
        liveStreamId?: string | null
        videoClipId?: string | null
        imageId?: string | null
        thumbnailId?: string | null
        ownerOrganismId?: string | null
        pinned?: boolean | null
        liveStream?: {
          __typename?: 'LiveStream'
          id: string
          playbackUrl: string
          ingestUrl?: string | null
          wildmokaEventID?: string | null
          videoEngine: VideoEngine
          state: LiveStreamState
          streamId?: string | null
          sveBroadcastId?: string | null
          sveChannelId?: string | null
          externalFeedProvider?: ExternalFeedType | null
          externalFeedUrl?: string | null
          event?: {
            __typename?: 'WMEvent'
            id: string
            name: string
            description?: string | null
            testStartDate: Date
            editoStartDate: Date
            editoStopDate: Date
            stopDate: Date
            state: WmEventState
            notifEmail?: string | null
            clipCreationAtEnd: boolean
            clipPublicationAtEnd: boolean
            livePublication: boolean
            stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
            live?: {
              __typename?: 'WMEventLive'
              id: string
              publishStatus: WmLiveState
              type: WmLiveType
              autoAnnounceDate?: Date | null
              template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
            } | null
            clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
          } | null
          sveBroadcast?: {
            __typename?: 'SVEBroadcast'
            id: string
            status: SveBroadcastState
            channelId: string
            startDate?: Date | null
            clipStartDate?: Date | null
            stopDate?: Date | null
            scheduledStartDate: Date
            scheduledClipStartDate: Date
            scheduledStopDate: Date
          } | null
          sveLiveMonitoring?: {
            __typename?: 'SVELiveMonitoring'
            alerts?: {
              __typename?: 'SVELiveMonitoringAlerts'
              audioNotDetected?: string | null
              initialProbeTakingLonger?: string | null
              videoNotDetected?: string | null
              RTMPHasNoAudioVideo?: string | null
              RTMPStreamNotFound?: string | null
            } | null
            stats?: {
              __typename?: 'SVELiveMonitoringStats'
              networkIn?: string | null
              droppedFrames?: string | null
              inputVideoFrameRate?: string | null
              outputAudioLevelLkfs?: string | null
            } | null
          } | null
        } | null
        videoClip?: {
          __typename?: 'VideoClip'
          id: string
          playbackUrl: string
          downloadLink: string
          status?: VideoClipStatus | null
          updatedAt: Date
          createdAt: Date
          fileId?: string | null
          error?: {
            __typename?: 'VideoClipError'
            guid: string
            workflowStatus: string
            workflowErrorAt: string
            errorMessage: string
            errorDetails: string
          } | null
          captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
          externalFeed?: {
            __typename?: 'ExternalFeed'
            externalFeedProvider: ExternalFeedType
            externalFeedUrl: string
          } | null
        } | null
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
        thumbnail?: {
          __typename?: 'Media'
          id: string
          image?: {
            __typename?: 'File'
            id: string
            filename: string
            size: number
            mimetype: string
            downloadUrl: string
            thumbUrl?: string | null
            createdAt: Date
            metadata?: {
              __typename?: 'FileMetadata'
              title?: string | null
              description?: string | null
              aspect?: number | null
              width?: number | null
              height?: number | null
              sourceFileId?: string | null
            } | null
          } | null
        } | null
        timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
      } | null
    }>
  } | null
}

export type SportsQueryVariables = Exact<{ [key: string]: never }>

export type SportsQuery = {
  __typename?: 'Query'
  sports?: {
    __typename?: 'SportPageResult'
    totalCount?: number | null
    sports: Array<{ __typename?: 'Sport'; id: string; title: string }>
  } | null
}

export type SportQueryVariables = Exact<{
  sportId: Scalars['ID']
}>

export type SportQuery = {
  __typename?: 'Query'
  sport?: {
    __typename?: 'Sport'
    id: string
    title: string
    defaultLocale?: string | null
    color: string
    onFront: boolean
    frontPosition?: number | null
    titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    cardImage?: {
      __typename?: 'Media'
      id: string
      createdAt: Date
      updatedAt: Date
      title: string
      type: MediaType
      publicationStatus: PublicationStatus
      tags: Array<MediaTags>
      date?: Date | null
      startTrim?: number | null
      endTrim?: number | null
      liveStreamId?: string | null
      videoClipId?: string | null
      imageId?: string | null
      thumbnailId?: string | null
      ownerOrganismId?: string | null
      pinned?: boolean | null
      liveStream?: {
        __typename?: 'LiveStream'
        id: string
        playbackUrl: string
        ingestUrl?: string | null
        wildmokaEventID?: string | null
        videoEngine: VideoEngine
        state: LiveStreamState
        streamId?: string | null
        sveBroadcastId?: string | null
        sveChannelId?: string | null
        externalFeedProvider?: ExternalFeedType | null
        externalFeedUrl?: string | null
        event?: {
          __typename?: 'WMEvent'
          id: string
          name: string
          description?: string | null
          testStartDate: Date
          editoStartDate: Date
          editoStopDate: Date
          stopDate: Date
          state: WmEventState
          notifEmail?: string | null
          clipCreationAtEnd: boolean
          clipPublicationAtEnd: boolean
          livePublication: boolean
          stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
          live?: {
            __typename?: 'WMEventLive'
            id: string
            publishStatus: WmLiveState
            type: WmLiveType
            autoAnnounceDate?: Date | null
            template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
          } | null
          clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
        } | null
        sveBroadcast?: {
          __typename?: 'SVEBroadcast'
          id: string
          status: SveBroadcastState
          channelId: string
          startDate?: Date | null
          clipStartDate?: Date | null
          stopDate?: Date | null
          scheduledStartDate: Date
          scheduledClipStartDate: Date
          scheduledStopDate: Date
        } | null
        sveLiveMonitoring?: {
          __typename?: 'SVELiveMonitoring'
          alerts?: {
            __typename?: 'SVELiveMonitoringAlerts'
            audioNotDetected?: string | null
            initialProbeTakingLonger?: string | null
            videoNotDetected?: string | null
            RTMPHasNoAudioVideo?: string | null
            RTMPStreamNotFound?: string | null
          } | null
          stats?: {
            __typename?: 'SVELiveMonitoringStats'
            networkIn?: string | null
            droppedFrames?: string | null
            inputVideoFrameRate?: string | null
            outputAudioLevelLkfs?: string | null
          } | null
        } | null
      } | null
      videoClip?: {
        __typename?: 'VideoClip'
        id: string
        playbackUrl: string
        downloadLink: string
        status?: VideoClipStatus | null
        updatedAt: Date
        createdAt: Date
        fileId?: string | null
        error?: {
          __typename?: 'VideoClipError'
          guid: string
          workflowStatus: string
          workflowErrorAt: string
          errorMessage: string
          errorDetails: string
        } | null
        captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
        externalFeed?: {
          __typename?: 'ExternalFeed'
          externalFeedProvider: ExternalFeedType
          externalFeedUrl: string
        } | null
      } | null
      image?: {
        __typename?: 'File'
        id: string
        filename: string
        size: number
        mimetype: string
        downloadUrl: string
        thumbUrl?: string | null
        createdAt: Date
        metadata?: {
          __typename?: 'FileMetadata'
          title?: string | null
          description?: string | null
          aspect?: number | null
          width?: number | null
          height?: number | null
          sourceFileId?: string | null
        } | null
      } | null
      thumbnail?: {
        __typename?: 'Media'
        id: string
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
      } | null
      timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
    } | null
    bannerImage?: {
      __typename?: 'Media'
      id: string
      createdAt: Date
      updatedAt: Date
      title: string
      type: MediaType
      publicationStatus: PublicationStatus
      tags: Array<MediaTags>
      date?: Date | null
      startTrim?: number | null
      endTrim?: number | null
      liveStreamId?: string | null
      videoClipId?: string | null
      imageId?: string | null
      thumbnailId?: string | null
      ownerOrganismId?: string | null
      pinned?: boolean | null
      liveStream?: {
        __typename?: 'LiveStream'
        id: string
        playbackUrl: string
        ingestUrl?: string | null
        wildmokaEventID?: string | null
        videoEngine: VideoEngine
        state: LiveStreamState
        streamId?: string | null
        sveBroadcastId?: string | null
        sveChannelId?: string | null
        externalFeedProvider?: ExternalFeedType | null
        externalFeedUrl?: string | null
        event?: {
          __typename?: 'WMEvent'
          id: string
          name: string
          description?: string | null
          testStartDate: Date
          editoStartDate: Date
          editoStopDate: Date
          stopDate: Date
          state: WmEventState
          notifEmail?: string | null
          clipCreationAtEnd: boolean
          clipPublicationAtEnd: boolean
          livePublication: boolean
          stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
          live?: {
            __typename?: 'WMEventLive'
            id: string
            publishStatus: WmLiveState
            type: WmLiveType
            autoAnnounceDate?: Date | null
            template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
          } | null
          clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
        } | null
        sveBroadcast?: {
          __typename?: 'SVEBroadcast'
          id: string
          status: SveBroadcastState
          channelId: string
          startDate?: Date | null
          clipStartDate?: Date | null
          stopDate?: Date | null
          scheduledStartDate: Date
          scheduledClipStartDate: Date
          scheduledStopDate: Date
        } | null
        sveLiveMonitoring?: {
          __typename?: 'SVELiveMonitoring'
          alerts?: {
            __typename?: 'SVELiveMonitoringAlerts'
            audioNotDetected?: string | null
            initialProbeTakingLonger?: string | null
            videoNotDetected?: string | null
            RTMPHasNoAudioVideo?: string | null
            RTMPStreamNotFound?: string | null
          } | null
          stats?: {
            __typename?: 'SVELiveMonitoringStats'
            networkIn?: string | null
            droppedFrames?: string | null
            inputVideoFrameRate?: string | null
            outputAudioLevelLkfs?: string | null
          } | null
        } | null
      } | null
      videoClip?: {
        __typename?: 'VideoClip'
        id: string
        playbackUrl: string
        downloadLink: string
        status?: VideoClipStatus | null
        updatedAt: Date
        createdAt: Date
        fileId?: string | null
        error?: {
          __typename?: 'VideoClipError'
          guid: string
          workflowStatus: string
          workflowErrorAt: string
          errorMessage: string
          errorDetails: string
        } | null
        captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
        externalFeed?: {
          __typename?: 'ExternalFeed'
          externalFeedProvider: ExternalFeedType
          externalFeedUrl: string
        } | null
      } | null
      image?: {
        __typename?: 'File'
        id: string
        filename: string
        size: number
        mimetype: string
        downloadUrl: string
        thumbUrl?: string | null
        createdAt: Date
        metadata?: {
          __typename?: 'FileMetadata'
          title?: string | null
          description?: string | null
          aspect?: number | null
          width?: number | null
          height?: number | null
          sourceFileId?: string | null
        } | null
      } | null
      thumbnail?: {
        __typename?: 'Media'
        id: string
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
      } | null
      timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
    } | null
    bannerVideo?: {
      __typename?: 'Media'
      id: string
      videoClip?: { __typename?: 'VideoClip'; id: string; playbackUrl: string } | null
    } | null
    iconImage?: {
      __typename?: 'Media'
      id: string
      createdAt: Date
      updatedAt: Date
      title: string
      type: MediaType
      publicationStatus: PublicationStatus
      tags: Array<MediaTags>
      date?: Date | null
      startTrim?: number | null
      endTrim?: number | null
      liveStreamId?: string | null
      videoClipId?: string | null
      imageId?: string | null
      thumbnailId?: string | null
      ownerOrganismId?: string | null
      pinned?: boolean | null
      liveStream?: {
        __typename?: 'LiveStream'
        id: string
        playbackUrl: string
        ingestUrl?: string | null
        wildmokaEventID?: string | null
        videoEngine: VideoEngine
        state: LiveStreamState
        streamId?: string | null
        sveBroadcastId?: string | null
        sveChannelId?: string | null
        externalFeedProvider?: ExternalFeedType | null
        externalFeedUrl?: string | null
        event?: {
          __typename?: 'WMEvent'
          id: string
          name: string
          description?: string | null
          testStartDate: Date
          editoStartDate: Date
          editoStopDate: Date
          stopDate: Date
          state: WmEventState
          notifEmail?: string | null
          clipCreationAtEnd: boolean
          clipPublicationAtEnd: boolean
          livePublication: boolean
          stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
          live?: {
            __typename?: 'WMEventLive'
            id: string
            publishStatus: WmLiveState
            type: WmLiveType
            autoAnnounceDate?: Date | null
            template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
          } | null
          clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
        } | null
        sveBroadcast?: {
          __typename?: 'SVEBroadcast'
          id: string
          status: SveBroadcastState
          channelId: string
          startDate?: Date | null
          clipStartDate?: Date | null
          stopDate?: Date | null
          scheduledStartDate: Date
          scheduledClipStartDate: Date
          scheduledStopDate: Date
        } | null
        sveLiveMonitoring?: {
          __typename?: 'SVELiveMonitoring'
          alerts?: {
            __typename?: 'SVELiveMonitoringAlerts'
            audioNotDetected?: string | null
            initialProbeTakingLonger?: string | null
            videoNotDetected?: string | null
            RTMPHasNoAudioVideo?: string | null
            RTMPStreamNotFound?: string | null
          } | null
          stats?: {
            __typename?: 'SVELiveMonitoringStats'
            networkIn?: string | null
            droppedFrames?: string | null
            inputVideoFrameRate?: string | null
            outputAudioLevelLkfs?: string | null
          } | null
        } | null
      } | null
      videoClip?: {
        __typename?: 'VideoClip'
        id: string
        playbackUrl: string
        downloadLink: string
        status?: VideoClipStatus | null
        updatedAt: Date
        createdAt: Date
        fileId?: string | null
        error?: {
          __typename?: 'VideoClipError'
          guid: string
          workflowStatus: string
          workflowErrorAt: string
          errorMessage: string
          errorDetails: string
        } | null
        captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
        externalFeed?: {
          __typename?: 'ExternalFeed'
          externalFeedProvider: ExternalFeedType
          externalFeedUrl: string
        } | null
      } | null
      image?: {
        __typename?: 'File'
        id: string
        filename: string
        size: number
        mimetype: string
        downloadUrl: string
        thumbUrl?: string | null
        createdAt: Date
        metadata?: {
          __typename?: 'FileMetadata'
          title?: string | null
          description?: string | null
          aspect?: number | null
          width?: number | null
          height?: number | null
          sourceFileId?: string | null
        } | null
      } | null
      thumbnail?: {
        __typename?: 'Media'
        id: string
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
      } | null
      timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
    } | null
  } | null
}

export type MoveSportFrontPageMutationVariables = Exact<{
  input: MoveFrontPageInput
}>

export type MoveSportFrontPageMutation = {
  __typename?: 'Mutation'
  moveSportFrontPage: Array<{ __typename?: 'Sport'; id: string; frontPosition?: number | null }>
}

export type SportItemContentFragment = {
  __typename?: 'SportItem'
  id: string
  title: string
  subtitle?: string | null
  description?: string | null
  defaultLocale?: string | null
  videoContentType: VideoContentType
  publicationStatus: PublicationStatus
  contentType: SportItemContentType
  sportItemSetId: string
  reverseRestriction?: boolean | null
  coverImageFileId: string
  matureContent: boolean
  mediasIds: Array<string>
  overrideTabs: boolean
  contentPushServiceNamesExcluded?: Array<ContentPushStreamServiceName> | null
  modeRadio?: boolean | null
  titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
  subtitleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
  descriptionT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
  sportItemSet?: {
    __typename?: 'SportItemSet'
    id: string
    streamsIds?: Array<string> | null
    ownerOrganismId?: string | null
    streams?: Array<{ __typename?: 'WMStream'; id: string; name: string }> | null
    sportEvent?: {
      __typename?: 'SportEvent'
      startDate: Date
      endDate: Date
      state: SportEventState
      liveTemplateId?: string | null
      clipTemplateId?: string | null
      backstageTemplateId?: string | null
    } | null
  } | null
  coverImage?: {
    __typename?: 'Media'
    id: string
    image?: { __typename?: 'File'; id: string; thumbUrl?: string | null; downloadUrl: string } | null
  } | null
  medias: Array<{
    __typename?: 'Media'
    id: string
    createdAt: Date
    updatedAt: Date
    title: string
    type: MediaType
    publicationStatus: PublicationStatus
    tags: Array<MediaTags>
    date?: Date | null
    startTrim?: number | null
    endTrim?: number | null
    liveStreamId?: string | null
    videoClipId?: string | null
    imageId?: string | null
    thumbnailId?: string | null
    ownerOrganismId?: string | null
    pinned?: boolean | null
    liveStream?: {
      __typename?: 'LiveStream'
      id: string
      playbackUrl: string
      ingestUrl?: string | null
      wildmokaEventID?: string | null
      videoEngine: VideoEngine
      state: LiveStreamState
      streamId?: string | null
      sveBroadcastId?: string | null
      sveChannelId?: string | null
      externalFeedProvider?: ExternalFeedType | null
      externalFeedUrl?: string | null
      event?: {
        __typename?: 'WMEvent'
        id: string
        name: string
        description?: string | null
        testStartDate: Date
        editoStartDate: Date
        editoStopDate: Date
        stopDate: Date
        state: WmEventState
        notifEmail?: string | null
        clipCreationAtEnd: boolean
        clipPublicationAtEnd: boolean
        livePublication: boolean
        stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
        live?: {
          __typename?: 'WMEventLive'
          id: string
          publishStatus: WmLiveState
          type: WmLiveType
          autoAnnounceDate?: Date | null
          template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
        } | null
        clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
      } | null
      sveBroadcast?: {
        __typename?: 'SVEBroadcast'
        id: string
        status: SveBroadcastState
        channelId: string
        startDate?: Date | null
        clipStartDate?: Date | null
        stopDate?: Date | null
        scheduledStartDate: Date
        scheduledClipStartDate: Date
        scheduledStopDate: Date
      } | null
      sveLiveMonitoring?: {
        __typename?: 'SVELiveMonitoring'
        alerts?: {
          __typename?: 'SVELiveMonitoringAlerts'
          audioNotDetected?: string | null
          initialProbeTakingLonger?: string | null
          videoNotDetected?: string | null
          RTMPHasNoAudioVideo?: string | null
          RTMPStreamNotFound?: string | null
        } | null
        stats?: {
          __typename?: 'SVELiveMonitoringStats'
          networkIn?: string | null
          droppedFrames?: string | null
          inputVideoFrameRate?: string | null
          outputAudioLevelLkfs?: string | null
        } | null
      } | null
    } | null
    videoClip?: {
      __typename?: 'VideoClip'
      id: string
      playbackUrl: string
      downloadLink: string
      status?: VideoClipStatus | null
      updatedAt: Date
      createdAt: Date
      fileId?: string | null
      error?: {
        __typename?: 'VideoClipError'
        guid: string
        workflowStatus: string
        workflowErrorAt: string
        errorMessage: string
        errorDetails: string
      } | null
      captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
      externalFeed?: {
        __typename?: 'ExternalFeed'
        externalFeedProvider: ExternalFeedType
        externalFeedUrl: string
      } | null
    } | null
    image?: {
      __typename?: 'File'
      id: string
      filename: string
      size: number
      mimetype: string
      downloadUrl: string
      thumbUrl?: string | null
      createdAt: Date
      metadata?: {
        __typename?: 'FileMetadata'
        title?: string | null
        description?: string | null
        aspect?: number | null
        width?: number | null
        height?: number | null
        sourceFileId?: string | null
      } | null
    } | null
    thumbnail?: {
      __typename?: 'Media'
      id: string
      image?: {
        __typename?: 'File'
        id: string
        filename: string
        size: number
        mimetype: string
        downloadUrl: string
        thumbUrl?: string | null
        createdAt: Date
        metadata?: {
          __typename?: 'FileMetadata'
          title?: string | null
          description?: string | null
          aspect?: number | null
          width?: number | null
          height?: number | null
          sourceFileId?: string | null
        } | null
      } | null
    } | null
    timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
  }>
  tabs?: Array<{
    __typename?: 'Tab'
    title: string
    url: string
    position: TabPosition
    blank?: boolean | null
    titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    icon: { __typename?: 'Icon'; title: string; svg: string }
  }> | null
  sportEventItem?: {
    __typename?: 'SportEventItem'
    startDate: Date
    stopDate: Date
    testDate: Date
    publishAutoreplay: boolean
  } | null
  cac?: { __typename?: 'ContentAccessControl'; userAuthenticationRule?: CacUserAuthenticationRule | null } | null
}

export type SportItemLightContentFragment = {
  __typename?: 'SportItem'
  id: string
  title: string
  subtitle?: string | null
  description?: string | null
  videoContentType: VideoContentType
  publicationStatus: PublicationStatus
  contentType: SportItemContentType
  sportItemSetId: string
  coverImageFileId: string
  matureContent: boolean
  mediasIds: Array<string>
  reverseRestriction?: boolean | null
  coverImage?: {
    __typename?: 'Media'
    id: string
    image?: { __typename?: 'File'; id: string; thumbUrl?: string | null; downloadUrl: string } | null
  } | null
  medias: Array<{
    __typename?: 'Media'
    id: string
    title: string
    type: MediaType
    publicationStatus: PublicationStatus
    tags: Array<MediaTags>
    date?: Date | null
    startTrim?: number | null
    endTrim?: number | null
    thumbnailId?: string | null
    ownerOrganismId?: string | null
    pinned?: boolean | null
    timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
  }>
  sportEventItem?: { __typename?: 'SportEventItem'; startDate: Date; stopDate: Date } | null
}

export type SportItemsQueryVariables = Exact<{
  sportItemSetId: Scalars['ID']
  filters?: InputMaybe<SportItemFilters>
}>

export type SportItemsQuery = {
  __typename?: 'Query'
  sportItems?: {
    __typename?: 'SportItemsPageResult'
    totalCount?: number | null
    sportItems: Array<{
      __typename?: 'SportItem'
      id: string
      title: string
      subtitle?: string | null
      description?: string | null
      videoContentType: VideoContentType
      publicationStatus: PublicationStatus
      contentType: SportItemContentType
      sportItemSetId: string
      coverImageFileId: string
      matureContent: boolean
      mediasIds: Array<string>
      reverseRestriction?: boolean | null
      coverImage?: {
        __typename?: 'Media'
        id: string
        image?: { __typename?: 'File'; id: string; thumbUrl?: string | null; downloadUrl: string } | null
      } | null
      medias: Array<{
        __typename?: 'Media'
        id: string
        title: string
        type: MediaType
        publicationStatus: PublicationStatus
        tags: Array<MediaTags>
        date?: Date | null
        startTrim?: number | null
        endTrim?: number | null
        thumbnailId?: string | null
        ownerOrganismId?: string | null
        pinned?: boolean | null
        timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
      }>
      sportEventItem?: { __typename?: 'SportEventItem'; startDate: Date; stopDate: Date } | null
    }>
    sportItemSet?: { __typename?: 'SportItemSet'; id: string } | null
  } | null
}

export type SportItemQueryVariables = Exact<{
  sportItemId: Scalars['ID']
}>

export type SportItemQuery = {
  __typename?: 'Query'
  sportItem?: {
    __typename?: 'SportItem'
    id: string
    title: string
    subtitle?: string | null
    description?: string | null
    defaultLocale?: string | null
    videoContentType: VideoContentType
    publicationStatus: PublicationStatus
    contentType: SportItemContentType
    sportItemSetId: string
    reverseRestriction?: boolean | null
    coverImageFileId: string
    matureContent: boolean
    mediasIds: Array<string>
    overrideTabs: boolean
    contentPushServiceNamesExcluded?: Array<ContentPushStreamServiceName> | null
    modeRadio?: boolean | null
    titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    subtitleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    descriptionT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    sportItemSet?: {
      __typename?: 'SportItemSet'
      id: string
      streamsIds?: Array<string> | null
      ownerOrganismId?: string | null
      streams?: Array<{ __typename?: 'WMStream'; id: string; name: string }> | null
      sportEvent?: {
        __typename?: 'SportEvent'
        startDate: Date
        endDate: Date
        state: SportEventState
        liveTemplateId?: string | null
        clipTemplateId?: string | null
        backstageTemplateId?: string | null
      } | null
    } | null
    coverImage?: {
      __typename?: 'Media'
      id: string
      image?: { __typename?: 'File'; id: string; thumbUrl?: string | null; downloadUrl: string } | null
    } | null
    medias: Array<{
      __typename?: 'Media'
      id: string
      createdAt: Date
      updatedAt: Date
      title: string
      type: MediaType
      publicationStatus: PublicationStatus
      tags: Array<MediaTags>
      date?: Date | null
      startTrim?: number | null
      endTrim?: number | null
      liveStreamId?: string | null
      videoClipId?: string | null
      imageId?: string | null
      thumbnailId?: string | null
      ownerOrganismId?: string | null
      pinned?: boolean | null
      liveStream?: {
        __typename?: 'LiveStream'
        id: string
        playbackUrl: string
        ingestUrl?: string | null
        wildmokaEventID?: string | null
        videoEngine: VideoEngine
        state: LiveStreamState
        streamId?: string | null
        sveBroadcastId?: string | null
        sveChannelId?: string | null
        externalFeedProvider?: ExternalFeedType | null
        externalFeedUrl?: string | null
        event?: {
          __typename?: 'WMEvent'
          id: string
          name: string
          description?: string | null
          testStartDate: Date
          editoStartDate: Date
          editoStopDate: Date
          stopDate: Date
          state: WmEventState
          notifEmail?: string | null
          clipCreationAtEnd: boolean
          clipPublicationAtEnd: boolean
          livePublication: boolean
          stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
          live?: {
            __typename?: 'WMEventLive'
            id: string
            publishStatus: WmLiveState
            type: WmLiveType
            autoAnnounceDate?: Date | null
            template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
          } | null
          clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
        } | null
        sveBroadcast?: {
          __typename?: 'SVEBroadcast'
          id: string
          status: SveBroadcastState
          channelId: string
          startDate?: Date | null
          clipStartDate?: Date | null
          stopDate?: Date | null
          scheduledStartDate: Date
          scheduledClipStartDate: Date
          scheduledStopDate: Date
        } | null
        sveLiveMonitoring?: {
          __typename?: 'SVELiveMonitoring'
          alerts?: {
            __typename?: 'SVELiveMonitoringAlerts'
            audioNotDetected?: string | null
            initialProbeTakingLonger?: string | null
            videoNotDetected?: string | null
            RTMPHasNoAudioVideo?: string | null
            RTMPStreamNotFound?: string | null
          } | null
          stats?: {
            __typename?: 'SVELiveMonitoringStats'
            networkIn?: string | null
            droppedFrames?: string | null
            inputVideoFrameRate?: string | null
            outputAudioLevelLkfs?: string | null
          } | null
        } | null
      } | null
      videoClip?: {
        __typename?: 'VideoClip'
        id: string
        playbackUrl: string
        downloadLink: string
        status?: VideoClipStatus | null
        updatedAt: Date
        createdAt: Date
        fileId?: string | null
        error?: {
          __typename?: 'VideoClipError'
          guid: string
          workflowStatus: string
          workflowErrorAt: string
          errorMessage: string
          errorDetails: string
        } | null
        captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
        externalFeed?: {
          __typename?: 'ExternalFeed'
          externalFeedProvider: ExternalFeedType
          externalFeedUrl: string
        } | null
      } | null
      image?: {
        __typename?: 'File'
        id: string
        filename: string
        size: number
        mimetype: string
        downloadUrl: string
        thumbUrl?: string | null
        createdAt: Date
        metadata?: {
          __typename?: 'FileMetadata'
          title?: string | null
          description?: string | null
          aspect?: number | null
          width?: number | null
          height?: number | null
          sourceFileId?: string | null
        } | null
      } | null
      thumbnail?: {
        __typename?: 'Media'
        id: string
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
      } | null
      timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
    }>
    tabs?: Array<{
      __typename?: 'Tab'
      title: string
      url: string
      position: TabPosition
      blank?: boolean | null
      titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
      icon: { __typename?: 'Icon'; title: string; svg: string }
    }> | null
    sportEventItem?: {
      __typename?: 'SportEventItem'
      startDate: Date
      stopDate: Date
      testDate: Date
      publishAutoreplay: boolean
    } | null
    cac?: { __typename?: 'ContentAccessControl'; userAuthenticationRule?: CacUserAuthenticationRule | null } | null
  } | null
}

export type SportItemWithSportItemSetQueryVariables = Exact<{
  sportItemId: Scalars['ID']
}>

export type SportItemWithSportItemSetQuery = {
  __typename?: 'Query'
  sportItem?: {
    __typename?: 'SportItem'
    id: string
    title: string
    subtitle?: string | null
    description?: string | null
    defaultLocale?: string | null
    videoContentType: VideoContentType
    publicationStatus: PublicationStatus
    contentType: SportItemContentType
    sportItemSetId: string
    reverseRestriction?: boolean | null
    coverImageFileId: string
    matureContent: boolean
    mediasIds: Array<string>
    overrideTabs: boolean
    contentPushServiceNamesExcluded?: Array<ContentPushStreamServiceName> | null
    modeRadio?: boolean | null
    sportItemSet?: {
      __typename: 'SportItemSet'
      id: string
      streamsIds?: Array<string> | null
      ownerOrganismId?: string | null
      sportIds: Array<string>
      type: SportItemSetType
      title: string
      subtitle?: string | null
      description?: string | null
      defaultLocale?: string | null
      publicationStatus: PublicationStatus
      offerIds: Array<string>
      restrictionLevel: PremiumAccess
      websiteUrl?: string | null
      lockPublishingLive?: boolean | null
      lockPublishingClip?: boolean | null
      bannerImageFileId?: string | null
      bannerVideoId?: string | null
      testPeriodEnd?: Date | null
      categoriesIds: Array<string>
      videoEngine: VideoEngine
      wmClippingTemplateId?: string | null
      sveInputLossImageMediaId?: string | null
      sveOverlayLogoImageMediaId?: string | null
      visibleInMultisportApplication: boolean
      activateSportItemChatByDefault: boolean
      contentPushServiceNames?: Array<ContentPushStreamServiceName> | null
      subTags?: Array<string> | null
      activateGreenPlayback?: boolean | null
      adCampaignId?: string | null
      contentPushServiceGenericStreamIds?: Array<string> | null
      contentPushServiceLiveEventIds?: Array<string> | null
      disableReadParallelLivestreamsLimit?: boolean | null
      streams?: Array<{ __typename?: 'WMStream'; id: string; name: string }> | null
      sportEvent?: {
        __typename?: 'SportEvent'
        startDate: Date
        endDate: Date
        state: SportEventState
        liveTemplateId?: string | null
        clipTemplateId?: string | null
        backstageTemplateId?: string | null
      } | null
      sports: Array<{ __typename?: 'Sport'; id: string; title: string; color: string }>
      titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
      subtitleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
      descriptionT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
      ownerOrganism?: { __typename?: 'Organism'; title: string } | null
      offers: Array<{ __typename?: 'PurchasableOffer'; id: string; title: string }>
      tabs?: Array<{
        __typename?: 'Tab'
        title: string
        url: string
        position: TabPosition
        blank?: boolean | null
        titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
        icon: { __typename?: 'Icon'; title: string; svg: string }
      }> | null
      sponsorPlatform?: {
        __typename?: 'SponsorPlatform'
        linkId?: string | null
        display?: Array<SponsorPlatformDisplayOptions> | null
        videoPreroll?: Array<SponsorPlatformPrerollOptions> | null
        link?: {
          __typename: 'Link'
          id: string
          ownerOrganismIds: Array<string>
          tag?: LinkTag | null
          title: string
          subtitle?: string | null
          description?: string | null
          defaultLocale?: string | null
          clicks: number
          uniqueClicks: number
          views: number
          countries: Array<string | null>
          uniqueViews: number
          teaserVideoId?: string | null
          gamTagUrl?: string | null
          billboardId?: string | null
          url?: string | null
          imageId: string
          categoriesIds: Array<string>
          publicationStatus: PublicationStatus
          ownerOrganisms?: Array<{
            __typename?: 'Organism'
            id: string
            type: OrganismType
            whiteLabel: boolean
            title: string
            description?: string | null
            publicationStatus: PublicationStatus
            defaultLocale?: string | null
            onFront: boolean
            frontPosition?: number | null
            officialWebSiteUrl: string
            sportIds: Array<string>
            forbiddenKeyWords?: Array<string> | null
            enableExternalSources?: boolean | null
            availableExternalSources?: Array<WidgetExternalContentSource> | null
            streamsIds?: Array<string> | null
            wmProfileId?: string | null
            sveChannelIds?: Array<string> | null
            organismPageApplicationDesignId?: string | null
            contentPageApplicationDesignId?: string | null
            adminsAutomatedEmails?: boolean | null
            visibleInMultisportApplication?: boolean | null
            titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
            descriptionT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
            logo: {
              __typename?: 'Logo'
              coloredImage?: {
                __typename?: 'Media'
                id: string
                createdAt: Date
                updatedAt: Date
                title: string
                type: MediaType
                publicationStatus: PublicationStatus
                tags: Array<MediaTags>
                date?: Date | null
                startTrim?: number | null
                endTrim?: number | null
                liveStreamId?: string | null
                videoClipId?: string | null
                imageId?: string | null
                thumbnailId?: string | null
                ownerOrganismId?: string | null
                pinned?: boolean | null
                liveStream?: {
                  __typename?: 'LiveStream'
                  id: string
                  playbackUrl: string
                  ingestUrl?: string | null
                  wildmokaEventID?: string | null
                  videoEngine: VideoEngine
                  state: LiveStreamState
                  streamId?: string | null
                  sveBroadcastId?: string | null
                  sveChannelId?: string | null
                  externalFeedProvider?: ExternalFeedType | null
                  externalFeedUrl?: string | null
                  event?: {
                    __typename?: 'WMEvent'
                    id: string
                    name: string
                    description?: string | null
                    testStartDate: Date
                    editoStartDate: Date
                    editoStopDate: Date
                    stopDate: Date
                    state: WmEventState
                    notifEmail?: string | null
                    clipCreationAtEnd: boolean
                    clipPublicationAtEnd: boolean
                    livePublication: boolean
                    stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
                    live?: {
                      __typename?: 'WMEventLive'
                      id: string
                      publishStatus: WmLiveState
                      type: WmLiveType
                      autoAnnounceDate?: Date | null
                      template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
                    } | null
                    clipTemplate?: {
                      __typename?: 'WMEventTemplate'
                      id: string
                      name: string
                      type: WmTemplateType
                    } | null
                  } | null
                  sveBroadcast?: {
                    __typename?: 'SVEBroadcast'
                    id: string
                    status: SveBroadcastState
                    channelId: string
                    startDate?: Date | null
                    clipStartDate?: Date | null
                    stopDate?: Date | null
                    scheduledStartDate: Date
                    scheduledClipStartDate: Date
                    scheduledStopDate: Date
                  } | null
                  sveLiveMonitoring?: {
                    __typename?: 'SVELiveMonitoring'
                    alerts?: {
                      __typename?: 'SVELiveMonitoringAlerts'
                      audioNotDetected?: string | null
                      initialProbeTakingLonger?: string | null
                      videoNotDetected?: string | null
                      RTMPHasNoAudioVideo?: string | null
                      RTMPStreamNotFound?: string | null
                    } | null
                    stats?: {
                      __typename?: 'SVELiveMonitoringStats'
                      networkIn?: string | null
                      droppedFrames?: string | null
                      inputVideoFrameRate?: string | null
                      outputAudioLevelLkfs?: string | null
                    } | null
                  } | null
                } | null
                videoClip?: {
                  __typename?: 'VideoClip'
                  id: string
                  playbackUrl: string
                  downloadLink: string
                  status?: VideoClipStatus | null
                  updatedAt: Date
                  createdAt: Date
                  fileId?: string | null
                  error?: {
                    __typename?: 'VideoClipError'
                    guid: string
                    workflowStatus: string
                    workflowErrorAt: string
                    errorMessage: string
                    errorDetails: string
                  } | null
                  captions?: Array<{
                    __typename?: 'Caption'
                    srcFileId: string
                    language: string
                    title: string
                  }> | null
                  externalFeed?: {
                    __typename?: 'ExternalFeed'
                    externalFeedProvider: ExternalFeedType
                    externalFeedUrl: string
                  } | null
                } | null
                image?: {
                  __typename?: 'File'
                  id: string
                  filename: string
                  size: number
                  mimetype: string
                  downloadUrl: string
                  thumbUrl?: string | null
                  createdAt: Date
                  metadata?: {
                    __typename?: 'FileMetadata'
                    title?: string | null
                    description?: string | null
                    aspect?: number | null
                    width?: number | null
                    height?: number | null
                    sourceFileId?: string | null
                  } | null
                } | null
                thumbnail?: {
                  __typename?: 'Media'
                  id: string
                  image?: {
                    __typename?: 'File'
                    id: string
                    filename: string
                    size: number
                    mimetype: string
                    downloadUrl: string
                    thumbUrl?: string | null
                    createdAt: Date
                    metadata?: {
                      __typename?: 'FileMetadata'
                      title?: string | null
                      description?: string | null
                      aspect?: number | null
                      width?: number | null
                      height?: number | null
                      sourceFileId?: string | null
                    } | null
                  } | null
                } | null
                timelineMarkers?: Array<{
                  __typename?: 'Marker'
                  title: string
                  time: number
                  icon?: string | null
                }> | null
              } | null
              transparentDarkImage?: {
                __typename?: 'Media'
                id: string
                createdAt: Date
                updatedAt: Date
                title: string
                type: MediaType
                publicationStatus: PublicationStatus
                tags: Array<MediaTags>
                date?: Date | null
                startTrim?: number | null
                endTrim?: number | null
                liveStreamId?: string | null
                videoClipId?: string | null
                imageId?: string | null
                thumbnailId?: string | null
                ownerOrganismId?: string | null
                pinned?: boolean | null
                liveStream?: {
                  __typename?: 'LiveStream'
                  id: string
                  playbackUrl: string
                  ingestUrl?: string | null
                  wildmokaEventID?: string | null
                  videoEngine: VideoEngine
                  state: LiveStreamState
                  streamId?: string | null
                  sveBroadcastId?: string | null
                  sveChannelId?: string | null
                  externalFeedProvider?: ExternalFeedType | null
                  externalFeedUrl?: string | null
                  event?: {
                    __typename?: 'WMEvent'
                    id: string
                    name: string
                    description?: string | null
                    testStartDate: Date
                    editoStartDate: Date
                    editoStopDate: Date
                    stopDate: Date
                    state: WmEventState
                    notifEmail?: string | null
                    clipCreationAtEnd: boolean
                    clipPublicationAtEnd: boolean
                    livePublication: boolean
                    stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
                    live?: {
                      __typename?: 'WMEventLive'
                      id: string
                      publishStatus: WmLiveState
                      type: WmLiveType
                      autoAnnounceDate?: Date | null
                      template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
                    } | null
                    clipTemplate?: {
                      __typename?: 'WMEventTemplate'
                      id: string
                      name: string
                      type: WmTemplateType
                    } | null
                  } | null
                  sveBroadcast?: {
                    __typename?: 'SVEBroadcast'
                    id: string
                    status: SveBroadcastState
                    channelId: string
                    startDate?: Date | null
                    clipStartDate?: Date | null
                    stopDate?: Date | null
                    scheduledStartDate: Date
                    scheduledClipStartDate: Date
                    scheduledStopDate: Date
                  } | null
                  sveLiveMonitoring?: {
                    __typename?: 'SVELiveMonitoring'
                    alerts?: {
                      __typename?: 'SVELiveMonitoringAlerts'
                      audioNotDetected?: string | null
                      initialProbeTakingLonger?: string | null
                      videoNotDetected?: string | null
                      RTMPHasNoAudioVideo?: string | null
                      RTMPStreamNotFound?: string | null
                    } | null
                    stats?: {
                      __typename?: 'SVELiveMonitoringStats'
                      networkIn?: string | null
                      droppedFrames?: string | null
                      inputVideoFrameRate?: string | null
                      outputAudioLevelLkfs?: string | null
                    } | null
                  } | null
                } | null
                videoClip?: {
                  __typename?: 'VideoClip'
                  id: string
                  playbackUrl: string
                  downloadLink: string
                  status?: VideoClipStatus | null
                  updatedAt: Date
                  createdAt: Date
                  fileId?: string | null
                  error?: {
                    __typename?: 'VideoClipError'
                    guid: string
                    workflowStatus: string
                    workflowErrorAt: string
                    errorMessage: string
                    errorDetails: string
                  } | null
                  captions?: Array<{
                    __typename?: 'Caption'
                    srcFileId: string
                    language: string
                    title: string
                  }> | null
                  externalFeed?: {
                    __typename?: 'ExternalFeed'
                    externalFeedProvider: ExternalFeedType
                    externalFeedUrl: string
                  } | null
                } | null
                image?: {
                  __typename?: 'File'
                  id: string
                  filename: string
                  size: number
                  mimetype: string
                  downloadUrl: string
                  thumbUrl?: string | null
                  createdAt: Date
                  metadata?: {
                    __typename?: 'FileMetadata'
                    title?: string | null
                    description?: string | null
                    aspect?: number | null
                    width?: number | null
                    height?: number | null
                    sourceFileId?: string | null
                  } | null
                } | null
                thumbnail?: {
                  __typename?: 'Media'
                  id: string
                  image?: {
                    __typename?: 'File'
                    id: string
                    filename: string
                    size: number
                    mimetype: string
                    downloadUrl: string
                    thumbUrl?: string | null
                    createdAt: Date
                    metadata?: {
                      __typename?: 'FileMetadata'
                      title?: string | null
                      description?: string | null
                      aspect?: number | null
                      width?: number | null
                      height?: number | null
                      sourceFileId?: string | null
                    } | null
                  } | null
                } | null
                timelineMarkers?: Array<{
                  __typename?: 'Marker'
                  title: string
                  time: number
                  icon?: string | null
                }> | null
              } | null
              transparentLightImage?: {
                __typename?: 'Media'
                id: string
                createdAt: Date
                updatedAt: Date
                title: string
                type: MediaType
                publicationStatus: PublicationStatus
                tags: Array<MediaTags>
                date?: Date | null
                startTrim?: number | null
                endTrim?: number | null
                liveStreamId?: string | null
                videoClipId?: string | null
                imageId?: string | null
                thumbnailId?: string | null
                ownerOrganismId?: string | null
                pinned?: boolean | null
                liveStream?: {
                  __typename?: 'LiveStream'
                  id: string
                  playbackUrl: string
                  ingestUrl?: string | null
                  wildmokaEventID?: string | null
                  videoEngine: VideoEngine
                  state: LiveStreamState
                  streamId?: string | null
                  sveBroadcastId?: string | null
                  sveChannelId?: string | null
                  externalFeedProvider?: ExternalFeedType | null
                  externalFeedUrl?: string | null
                  event?: {
                    __typename?: 'WMEvent'
                    id: string
                    name: string
                    description?: string | null
                    testStartDate: Date
                    editoStartDate: Date
                    editoStopDate: Date
                    stopDate: Date
                    state: WmEventState
                    notifEmail?: string | null
                    clipCreationAtEnd: boolean
                    clipPublicationAtEnd: boolean
                    livePublication: boolean
                    stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
                    live?: {
                      __typename?: 'WMEventLive'
                      id: string
                      publishStatus: WmLiveState
                      type: WmLiveType
                      autoAnnounceDate?: Date | null
                      template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
                    } | null
                    clipTemplate?: {
                      __typename?: 'WMEventTemplate'
                      id: string
                      name: string
                      type: WmTemplateType
                    } | null
                  } | null
                  sveBroadcast?: {
                    __typename?: 'SVEBroadcast'
                    id: string
                    status: SveBroadcastState
                    channelId: string
                    startDate?: Date | null
                    clipStartDate?: Date | null
                    stopDate?: Date | null
                    scheduledStartDate: Date
                    scheduledClipStartDate: Date
                    scheduledStopDate: Date
                  } | null
                  sveLiveMonitoring?: {
                    __typename?: 'SVELiveMonitoring'
                    alerts?: {
                      __typename?: 'SVELiveMonitoringAlerts'
                      audioNotDetected?: string | null
                      initialProbeTakingLonger?: string | null
                      videoNotDetected?: string | null
                      RTMPHasNoAudioVideo?: string | null
                      RTMPStreamNotFound?: string | null
                    } | null
                    stats?: {
                      __typename?: 'SVELiveMonitoringStats'
                      networkIn?: string | null
                      droppedFrames?: string | null
                      inputVideoFrameRate?: string | null
                      outputAudioLevelLkfs?: string | null
                    } | null
                  } | null
                } | null
                videoClip?: {
                  __typename?: 'VideoClip'
                  id: string
                  playbackUrl: string
                  downloadLink: string
                  status?: VideoClipStatus | null
                  updatedAt: Date
                  createdAt: Date
                  fileId?: string | null
                  error?: {
                    __typename?: 'VideoClipError'
                    guid: string
                    workflowStatus: string
                    workflowErrorAt: string
                    errorMessage: string
                    errorDetails: string
                  } | null
                  captions?: Array<{
                    __typename?: 'Caption'
                    srcFileId: string
                    language: string
                    title: string
                  }> | null
                  externalFeed?: {
                    __typename?: 'ExternalFeed'
                    externalFeedProvider: ExternalFeedType
                    externalFeedUrl: string
                  } | null
                } | null
                image?: {
                  __typename?: 'File'
                  id: string
                  filename: string
                  size: number
                  mimetype: string
                  downloadUrl: string
                  thumbUrl?: string | null
                  createdAt: Date
                  metadata?: {
                    __typename?: 'FileMetadata'
                    title?: string | null
                    description?: string | null
                    aspect?: number | null
                    width?: number | null
                    height?: number | null
                    sourceFileId?: string | null
                  } | null
                } | null
                thumbnail?: {
                  __typename?: 'Media'
                  id: string
                  image?: {
                    __typename?: 'File'
                    id: string
                    filename: string
                    size: number
                    mimetype: string
                    downloadUrl: string
                    thumbUrl?: string | null
                    createdAt: Date
                    metadata?: {
                      __typename?: 'FileMetadata'
                      title?: string | null
                      description?: string | null
                      aspect?: number | null
                      width?: number | null
                      height?: number | null
                      sourceFileId?: string | null
                    } | null
                  } | null
                } | null
                timelineMarkers?: Array<{
                  __typename?: 'Marker'
                  title: string
                  time: number
                  icon?: string | null
                }> | null
              } | null
            }
            sports: Array<{ __typename?: 'Sport'; id: string }>
            bannerImage?: {
              __typename?: 'Media'
              id: string
              createdAt: Date
              updatedAt: Date
              title: string
              type: MediaType
              publicationStatus: PublicationStatus
              tags: Array<MediaTags>
              date?: Date | null
              startTrim?: number | null
              endTrim?: number | null
              liveStreamId?: string | null
              videoClipId?: string | null
              imageId?: string | null
              thumbnailId?: string | null
              ownerOrganismId?: string | null
              pinned?: boolean | null
              liveStream?: {
                __typename?: 'LiveStream'
                id: string
                playbackUrl: string
                ingestUrl?: string | null
                wildmokaEventID?: string | null
                videoEngine: VideoEngine
                state: LiveStreamState
                streamId?: string | null
                sveBroadcastId?: string | null
                sveChannelId?: string | null
                externalFeedProvider?: ExternalFeedType | null
                externalFeedUrl?: string | null
                event?: {
                  __typename?: 'WMEvent'
                  id: string
                  name: string
                  description?: string | null
                  testStartDate: Date
                  editoStartDate: Date
                  editoStopDate: Date
                  stopDate: Date
                  state: WmEventState
                  notifEmail?: string | null
                  clipCreationAtEnd: boolean
                  clipPublicationAtEnd: boolean
                  livePublication: boolean
                  stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
                  live?: {
                    __typename?: 'WMEventLive'
                    id: string
                    publishStatus: WmLiveState
                    type: WmLiveType
                    autoAnnounceDate?: Date | null
                    template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
                  } | null
                  clipTemplate?: {
                    __typename?: 'WMEventTemplate'
                    id: string
                    name: string
                    type: WmTemplateType
                  } | null
                } | null
                sveBroadcast?: {
                  __typename?: 'SVEBroadcast'
                  id: string
                  status: SveBroadcastState
                  channelId: string
                  startDate?: Date | null
                  clipStartDate?: Date | null
                  stopDate?: Date | null
                  scheduledStartDate: Date
                  scheduledClipStartDate: Date
                  scheduledStopDate: Date
                } | null
                sveLiveMonitoring?: {
                  __typename?: 'SVELiveMonitoring'
                  alerts?: {
                    __typename?: 'SVELiveMonitoringAlerts'
                    audioNotDetected?: string | null
                    initialProbeTakingLonger?: string | null
                    videoNotDetected?: string | null
                    RTMPHasNoAudioVideo?: string | null
                    RTMPStreamNotFound?: string | null
                  } | null
                  stats?: {
                    __typename?: 'SVELiveMonitoringStats'
                    networkIn?: string | null
                    droppedFrames?: string | null
                    inputVideoFrameRate?: string | null
                    outputAudioLevelLkfs?: string | null
                  } | null
                } | null
              } | null
              videoClip?: {
                __typename?: 'VideoClip'
                id: string
                playbackUrl: string
                downloadLink: string
                status?: VideoClipStatus | null
                updatedAt: Date
                createdAt: Date
                fileId?: string | null
                error?: {
                  __typename?: 'VideoClipError'
                  guid: string
                  workflowStatus: string
                  workflowErrorAt: string
                  errorMessage: string
                  errorDetails: string
                } | null
                captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
                externalFeed?: {
                  __typename?: 'ExternalFeed'
                  externalFeedProvider: ExternalFeedType
                  externalFeedUrl: string
                } | null
              } | null
              image?: {
                __typename?: 'File'
                id: string
                filename: string
                size: number
                mimetype: string
                downloadUrl: string
                thumbUrl?: string | null
                createdAt: Date
                metadata?: {
                  __typename?: 'FileMetadata'
                  title?: string | null
                  description?: string | null
                  aspect?: number | null
                  width?: number | null
                  height?: number | null
                  sourceFileId?: string | null
                } | null
              } | null
              thumbnail?: {
                __typename?: 'Media'
                id: string
                image?: {
                  __typename?: 'File'
                  id: string
                  filename: string
                  size: number
                  mimetype: string
                  downloadUrl: string
                  thumbUrl?: string | null
                  createdAt: Date
                  metadata?: {
                    __typename?: 'FileMetadata'
                    title?: string | null
                    description?: string | null
                    aspect?: number | null
                    width?: number | null
                    height?: number | null
                    sourceFileId?: string | null
                  } | null
                } | null
              } | null
              timelineMarkers?: Array<{
                __typename?: 'Marker'
                title: string
                time: number
                icon?: string | null
              }> | null
            } | null
            adminBadge?: {
              __typename?: 'Media'
              id: string
              createdAt: Date
              updatedAt: Date
              title: string
              type: MediaType
              publicationStatus: PublicationStatus
              tags: Array<MediaTags>
              date?: Date | null
              startTrim?: number | null
              endTrim?: number | null
              liveStreamId?: string | null
              videoClipId?: string | null
              imageId?: string | null
              thumbnailId?: string | null
              ownerOrganismId?: string | null
              pinned?: boolean | null
              liveStream?: {
                __typename?: 'LiveStream'
                id: string
                playbackUrl: string
                ingestUrl?: string | null
                wildmokaEventID?: string | null
                videoEngine: VideoEngine
                state: LiveStreamState
                streamId?: string | null
                sveBroadcastId?: string | null
                sveChannelId?: string | null
                externalFeedProvider?: ExternalFeedType | null
                externalFeedUrl?: string | null
                event?: {
                  __typename?: 'WMEvent'
                  id: string
                  name: string
                  description?: string | null
                  testStartDate: Date
                  editoStartDate: Date
                  editoStopDate: Date
                  stopDate: Date
                  state: WmEventState
                  notifEmail?: string | null
                  clipCreationAtEnd: boolean
                  clipPublicationAtEnd: boolean
                  livePublication: boolean
                  stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
                  live?: {
                    __typename?: 'WMEventLive'
                    id: string
                    publishStatus: WmLiveState
                    type: WmLiveType
                    autoAnnounceDate?: Date | null
                    template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
                  } | null
                  clipTemplate?: {
                    __typename?: 'WMEventTemplate'
                    id: string
                    name: string
                    type: WmTemplateType
                  } | null
                } | null
                sveBroadcast?: {
                  __typename?: 'SVEBroadcast'
                  id: string
                  status: SveBroadcastState
                  channelId: string
                  startDate?: Date | null
                  clipStartDate?: Date | null
                  stopDate?: Date | null
                  scheduledStartDate: Date
                  scheduledClipStartDate: Date
                  scheduledStopDate: Date
                } | null
                sveLiveMonitoring?: {
                  __typename?: 'SVELiveMonitoring'
                  alerts?: {
                    __typename?: 'SVELiveMonitoringAlerts'
                    audioNotDetected?: string | null
                    initialProbeTakingLonger?: string | null
                    videoNotDetected?: string | null
                    RTMPHasNoAudioVideo?: string | null
                    RTMPStreamNotFound?: string | null
                  } | null
                  stats?: {
                    __typename?: 'SVELiveMonitoringStats'
                    networkIn?: string | null
                    droppedFrames?: string | null
                    inputVideoFrameRate?: string | null
                    outputAudioLevelLkfs?: string | null
                  } | null
                } | null
              } | null
              videoClip?: {
                __typename?: 'VideoClip'
                id: string
                playbackUrl: string
                downloadLink: string
                status?: VideoClipStatus | null
                updatedAt: Date
                createdAt: Date
                fileId?: string | null
                error?: {
                  __typename?: 'VideoClipError'
                  guid: string
                  workflowStatus: string
                  workflowErrorAt: string
                  errorMessage: string
                  errorDetails: string
                } | null
                captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
                externalFeed?: {
                  __typename?: 'ExternalFeed'
                  externalFeedProvider: ExternalFeedType
                  externalFeedUrl: string
                } | null
              } | null
              image?: {
                __typename?: 'File'
                id: string
                filename: string
                size: number
                mimetype: string
                downloadUrl: string
                thumbUrl?: string | null
                createdAt: Date
                metadata?: {
                  __typename?: 'FileMetadata'
                  title?: string | null
                  description?: string | null
                  aspect?: number | null
                  width?: number | null
                  height?: number | null
                  sourceFileId?: string | null
                } | null
              } | null
              thumbnail?: {
                __typename?: 'Media'
                id: string
                image?: {
                  __typename?: 'File'
                  id: string
                  filename: string
                  size: number
                  mimetype: string
                  downloadUrl: string
                  thumbUrl?: string | null
                  createdAt: Date
                  metadata?: {
                    __typename?: 'FileMetadata'
                    title?: string | null
                    description?: string | null
                    aspect?: number | null
                    width?: number | null
                    height?: number | null
                    sourceFileId?: string | null
                  } | null
                } | null
              } | null
              timelineMarkers?: Array<{
                __typename?: 'Marker'
                title: string
                time: number
                icon?: string | null
              }> | null
            } | null
            bannerVideo?: {
              __typename?: 'Media'
              id: string
              videoClip?: { __typename?: 'VideoClip'; id: string; playbackUrl: string } | null
            } | null
            pageWidgets?: Array<{
              __typename?: 'CustomPageWidget'
              name: CustomPageWidgetName
              position: number
              display?: CustomPageDisplayTypes | null
              contentDisplay?: ContentSetContentDisplay | null
              cardsDisplay?: CardsDisplay | null
              options?: {
                __typename?: 'CustomPageWidgetOptions'
                editedCategoryId?: string | null
                sportItemSetType?: SportItemSetType | null
                replays?: {
                  __typename?: 'ReplaysWidget'
                  defaultLocale: string
                  maxReplays?: number | null
                  depthInDays?: number | null
                  nameT: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }>
                } | null
                externalContent?: {
                  __typename?: 'ExternalContentWidget'
                  name?: string | null
                  defaultLocale: string
                  maxItemsCount: number
                  source: WidgetExternalContentSource
                  nameT: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }>
                  period?: { __typename?: 'StartEndDates'; startDate: Date; endDate: Date } | null
                  wecContent?: {
                    __typename?: 'WECNewsContent'
                    teamECMIds?: Array<string> | null
                    driverECMIds?: Array<string> | null
                    carECMIds?: Array<string> | null
                    categoryECMIds?: Array<string> | null
                    raceECMIds?: Array<string> | null
                  } | null
                  rssContent?: { __typename?: 'RSSFeedContent'; feedUrl: string } | null
                } | null
              } | null
            }> | null
            offers: Array<{
              __typename?: 'PurchasableOffer'
              id: string
              mode: OfferMode
              delay?: number | null
              expiredAt?: Date | null
              publicationStatus: PublicationStatus
              title: string
              defaultLocale?: string | null
              url?: string | null
              bannerImageId?: string | null
              thumbnailImageId?: string | null
              freeWithLicense: boolean
              position?: number | null
              titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
              description: Array<{ __typename?: 'OfferDescription'; header: string; content: string }>
              descriptionT?: Array<{
                __typename?: 'ContentTranslationDescriptionPurchasableOffer'
                locale: string
                content?: Array<{ __typename?: 'OfferDescription'; header: string; content: string }> | null
              }> | null
              products: Array<{
                __typename?: 'Product'
                productId: string
                platform: Platform
                period?: Period | null
                publicationStatus: PublicationStatus
                purchasableOfferId?: string | null
                licensedUserOnly?: boolean | null
                premiumOf?: Array<string> | null
                prices: Array<{ __typename?: 'Price'; value: number; currency: string }>
              }>
            }>
            license?: { __typename?: 'OrganismLicense'; enabled: boolean; api?: LicenseApi | null } | null
            tags?: Array<{ __typename?: 'Tag'; id: string; title: string; subTags?: Array<string> | null }> | null
          }> | null
          titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
          subtitleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
          descriptionT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
          billboard?: {
            __typename?: 'Media'
            id: string
            videoClip?: { __typename?: 'VideoClip'; id: string; playbackUrl: string } | null
          } | null
          teaserVideo?: {
            __typename?: 'Media'
            id: string
            videoClip?: { __typename?: 'VideoClip'; id: string; playbackUrl: string } | null
          } | null
          image?: {
            __typename?: 'Media'
            id: string
            image?: { __typename?: 'File'; id: string; thumbUrl?: string | null; downloadUrl: string } | null
          } | null
          categories: Array<{ __typename?: 'Category'; id: string; title: string }>
        } | null
      } | null
      items?: Array<{ __typename?: 'SportItem'; id: string; title: string }> | null
      bannerImage?: {
        __typename?: 'Media'
        id: string
        image?: { __typename?: 'File'; id: string; thumbUrl?: string | null; downloadUrl: string } | null
      } | null
      bannerVideo?: {
        __typename?: 'Media'
        id: string
        videoClip?: { __typename?: 'VideoClip'; id: string; playbackUrl: string } | null
      } | null
      categories: Array<{ __typename?: 'Category'; id: string; title: string }>
      legalRestrictions: Array<{
        __typename?: 'LegalRestriction'
        id: string
        description?: string | null
        countries: Array<string>
        type: RestrictionType
        timeSlots?: { __typename?: 'TimeSlots'; from: string; to: string } | null
      }>
      cac?: {
        __typename?: 'ContentAccessControl'
        userAuthenticationRule?: CacUserAuthenticationRule | null
        userLocationRule?: {
          __typename?: 'CACUserLocationRule'
          distance: number
          condition: UserLocationCondition
          location: { __typename?: 'GeoLocation'; latitude: number; longitude: number }
        } | null
      } | null
      sveStreams: Array<{
        __typename?: 'SVEStream'
        sveChannelId: string
        socialNetworkWMStreamId?: string | null
        outputStreamId?: string | null
      }>
      tag?: { __typename?: 'Tag'; id: string; title: string } | null
      contentPushServiceGenericStreams?: Array<{
        __typename?: 'ContentPushStream'
        id: string
        name: string
        createdAt: Date
        updatedAt: Date
      }> | null
      noSpoilerMode?: { __typename?: 'NoSpoilerMode'; enabled: boolean; minutesBeforeDisabling: number } | null
    } | null
    titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    subtitleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    descriptionT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    coverImage?: {
      __typename?: 'Media'
      id: string
      image?: { __typename?: 'File'; id: string; thumbUrl?: string | null; downloadUrl: string } | null
    } | null
    medias: Array<{
      __typename?: 'Media'
      id: string
      createdAt: Date
      updatedAt: Date
      title: string
      type: MediaType
      publicationStatus: PublicationStatus
      tags: Array<MediaTags>
      date?: Date | null
      startTrim?: number | null
      endTrim?: number | null
      liveStreamId?: string | null
      videoClipId?: string | null
      imageId?: string | null
      thumbnailId?: string | null
      ownerOrganismId?: string | null
      pinned?: boolean | null
      liveStream?: {
        __typename?: 'LiveStream'
        id: string
        playbackUrl: string
        ingestUrl?: string | null
        wildmokaEventID?: string | null
        videoEngine: VideoEngine
        state: LiveStreamState
        streamId?: string | null
        sveBroadcastId?: string | null
        sveChannelId?: string | null
        externalFeedProvider?: ExternalFeedType | null
        externalFeedUrl?: string | null
        event?: {
          __typename?: 'WMEvent'
          id: string
          name: string
          description?: string | null
          testStartDate: Date
          editoStartDate: Date
          editoStopDate: Date
          stopDate: Date
          state: WmEventState
          notifEmail?: string | null
          clipCreationAtEnd: boolean
          clipPublicationAtEnd: boolean
          livePublication: boolean
          stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
          live?: {
            __typename?: 'WMEventLive'
            id: string
            publishStatus: WmLiveState
            type: WmLiveType
            autoAnnounceDate?: Date | null
            template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
          } | null
          clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
        } | null
        sveBroadcast?: {
          __typename?: 'SVEBroadcast'
          id: string
          status: SveBroadcastState
          channelId: string
          startDate?: Date | null
          clipStartDate?: Date | null
          stopDate?: Date | null
          scheduledStartDate: Date
          scheduledClipStartDate: Date
          scheduledStopDate: Date
        } | null
        sveLiveMonitoring?: {
          __typename?: 'SVELiveMonitoring'
          alerts?: {
            __typename?: 'SVELiveMonitoringAlerts'
            audioNotDetected?: string | null
            initialProbeTakingLonger?: string | null
            videoNotDetected?: string | null
            RTMPHasNoAudioVideo?: string | null
            RTMPStreamNotFound?: string | null
          } | null
          stats?: {
            __typename?: 'SVELiveMonitoringStats'
            networkIn?: string | null
            droppedFrames?: string | null
            inputVideoFrameRate?: string | null
            outputAudioLevelLkfs?: string | null
          } | null
        } | null
      } | null
      videoClip?: {
        __typename?: 'VideoClip'
        id: string
        playbackUrl: string
        downloadLink: string
        status?: VideoClipStatus | null
        updatedAt: Date
        createdAt: Date
        fileId?: string | null
        error?: {
          __typename?: 'VideoClipError'
          guid: string
          workflowStatus: string
          workflowErrorAt: string
          errorMessage: string
          errorDetails: string
        } | null
        captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
        externalFeed?: {
          __typename?: 'ExternalFeed'
          externalFeedProvider: ExternalFeedType
          externalFeedUrl: string
        } | null
      } | null
      image?: {
        __typename?: 'File'
        id: string
        filename: string
        size: number
        mimetype: string
        downloadUrl: string
        thumbUrl?: string | null
        createdAt: Date
        metadata?: {
          __typename?: 'FileMetadata'
          title?: string | null
          description?: string | null
          aspect?: number | null
          width?: number | null
          height?: number | null
          sourceFileId?: string | null
        } | null
      } | null
      thumbnail?: {
        __typename?: 'Media'
        id: string
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
      } | null
      timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
    }>
    tabs?: Array<{
      __typename?: 'Tab'
      title: string
      url: string
      position: TabPosition
      blank?: boolean | null
      titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
      icon: { __typename?: 'Icon'; title: string; svg: string }
    }> | null
    sportEventItem?: {
      __typename?: 'SportEventItem'
      startDate: Date
      stopDate: Date
      testDate: Date
      publishAutoreplay: boolean
    } | null
    cac?: { __typename?: 'ContentAccessControl'; userAuthenticationRule?: CacUserAuthenticationRule | null } | null
  } | null
}

export type SportItemByChatRoomQueryVariables = Exact<{
  chatRoomId: Scalars['ID']
}>

export type SportItemByChatRoomQuery = {
  __typename?: 'Query'
  sportItemByChatRoom?: {
    __typename?: 'SportItem'
    id: string
    title: string
    subtitle?: string | null
    description?: string | null
    defaultLocale?: string | null
    videoContentType: VideoContentType
    publicationStatus: PublicationStatus
    contentType: SportItemContentType
    sportItemSetId: string
    reverseRestriction?: boolean | null
    coverImageFileId: string
    matureContent: boolean
    mediasIds: Array<string>
    overrideTabs: boolean
    contentPushServiceNamesExcluded?: Array<ContentPushStreamServiceName> | null
    modeRadio?: boolean | null
    titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    subtitleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    descriptionT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    sportItemSet?: {
      __typename?: 'SportItemSet'
      id: string
      streamsIds?: Array<string> | null
      ownerOrganismId?: string | null
      streams?: Array<{ __typename?: 'WMStream'; id: string; name: string }> | null
      sportEvent?: {
        __typename?: 'SportEvent'
        startDate: Date
        endDate: Date
        state: SportEventState
        liveTemplateId?: string | null
        clipTemplateId?: string | null
        backstageTemplateId?: string | null
      } | null
    } | null
    coverImage?: {
      __typename?: 'Media'
      id: string
      image?: { __typename?: 'File'; id: string; thumbUrl?: string | null; downloadUrl: string } | null
    } | null
    medias: Array<{
      __typename?: 'Media'
      id: string
      createdAt: Date
      updatedAt: Date
      title: string
      type: MediaType
      publicationStatus: PublicationStatus
      tags: Array<MediaTags>
      date?: Date | null
      startTrim?: number | null
      endTrim?: number | null
      liveStreamId?: string | null
      videoClipId?: string | null
      imageId?: string | null
      thumbnailId?: string | null
      ownerOrganismId?: string | null
      pinned?: boolean | null
      liveStream?: {
        __typename?: 'LiveStream'
        id: string
        playbackUrl: string
        ingestUrl?: string | null
        wildmokaEventID?: string | null
        videoEngine: VideoEngine
        state: LiveStreamState
        streamId?: string | null
        sveBroadcastId?: string | null
        sveChannelId?: string | null
        externalFeedProvider?: ExternalFeedType | null
        externalFeedUrl?: string | null
        event?: {
          __typename?: 'WMEvent'
          id: string
          name: string
          description?: string | null
          testStartDate: Date
          editoStartDate: Date
          editoStopDate: Date
          stopDate: Date
          state: WmEventState
          notifEmail?: string | null
          clipCreationAtEnd: boolean
          clipPublicationAtEnd: boolean
          livePublication: boolean
          stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
          live?: {
            __typename?: 'WMEventLive'
            id: string
            publishStatus: WmLiveState
            type: WmLiveType
            autoAnnounceDate?: Date | null
            template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
          } | null
          clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
        } | null
        sveBroadcast?: {
          __typename?: 'SVEBroadcast'
          id: string
          status: SveBroadcastState
          channelId: string
          startDate?: Date | null
          clipStartDate?: Date | null
          stopDate?: Date | null
          scheduledStartDate: Date
          scheduledClipStartDate: Date
          scheduledStopDate: Date
        } | null
        sveLiveMonitoring?: {
          __typename?: 'SVELiveMonitoring'
          alerts?: {
            __typename?: 'SVELiveMonitoringAlerts'
            audioNotDetected?: string | null
            initialProbeTakingLonger?: string | null
            videoNotDetected?: string | null
            RTMPHasNoAudioVideo?: string | null
            RTMPStreamNotFound?: string | null
          } | null
          stats?: {
            __typename?: 'SVELiveMonitoringStats'
            networkIn?: string | null
            droppedFrames?: string | null
            inputVideoFrameRate?: string | null
            outputAudioLevelLkfs?: string | null
          } | null
        } | null
      } | null
      videoClip?: {
        __typename?: 'VideoClip'
        id: string
        playbackUrl: string
        downloadLink: string
        status?: VideoClipStatus | null
        updatedAt: Date
        createdAt: Date
        fileId?: string | null
        error?: {
          __typename?: 'VideoClipError'
          guid: string
          workflowStatus: string
          workflowErrorAt: string
          errorMessage: string
          errorDetails: string
        } | null
        captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
        externalFeed?: {
          __typename?: 'ExternalFeed'
          externalFeedProvider: ExternalFeedType
          externalFeedUrl: string
        } | null
      } | null
      image?: {
        __typename?: 'File'
        id: string
        filename: string
        size: number
        mimetype: string
        downloadUrl: string
        thumbUrl?: string | null
        createdAt: Date
        metadata?: {
          __typename?: 'FileMetadata'
          title?: string | null
          description?: string | null
          aspect?: number | null
          width?: number | null
          height?: number | null
          sourceFileId?: string | null
        } | null
      } | null
      thumbnail?: {
        __typename?: 'Media'
        id: string
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
      } | null
      timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
    }>
    tabs?: Array<{
      __typename?: 'Tab'
      title: string
      url: string
      position: TabPosition
      blank?: boolean | null
      titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
      icon: { __typename?: 'Icon'; title: string; svg: string }
    }> | null
    sportEventItem?: {
      __typename?: 'SportEventItem'
      startDate: Date
      stopDate: Date
      testDate: Date
      publishAutoreplay: boolean
    } | null
    cac?: { __typename?: 'ContentAccessControl'; userAuthenticationRule?: CacUserAuthenticationRule | null } | null
  } | null
}

export type CheckSportItemStreamsMovableQueryVariables = Exact<{
  sportItemId: Scalars['ID']
  from: Scalars['Date']
  to: Scalars['Date']
}>

export type CheckSportItemStreamsMovableQuery = {
  __typename?: 'Query'
  checkSportItemStreamsMovable: Array<{
    __typename?: 'SportItemStreamConflict'
    stream?: { __typename?: 'WMEventStream'; id: string; name: string } | null
    sportItem: { __typename?: 'SportItem'; id: string; title: string }
  }>
}

export type MonitoringDashboardQueryVariables = Exact<{
  date: Scalars['Date']
  organismId?: InputMaybe<Scalars['ID']>
}>

export type MonitoringDashboardQuery = {
  __typename?: 'Query'
  monitoringDashboard?: Array<{
    __typename?: 'SportItem'
    id: string
    title: string
    subtitle?: string | null
    description?: string | null
    defaultLocale?: string | null
    videoContentType: VideoContentType
    publicationStatus: PublicationStatus
    contentType: SportItemContentType
    sportItemSetId: string
    reverseRestriction?: boolean | null
    coverImageFileId: string
    matureContent: boolean
    mediasIds: Array<string>
    overrideTabs: boolean
    contentPushServiceNamesExcluded?: Array<ContentPushStreamServiceName> | null
    modeRadio?: boolean | null
    sportItemSet?: {
      __typename: 'SportItemSet'
      id: string
      streamsIds?: Array<string> | null
      ownerOrganismId?: string | null
      sportIds: Array<string>
      type: SportItemSetType
      title: string
      subtitle?: string | null
      description?: string | null
      defaultLocale?: string | null
      publicationStatus: PublicationStatus
      offerIds: Array<string>
      restrictionLevel: PremiumAccess
      websiteUrl?: string | null
      lockPublishingLive?: boolean | null
      lockPublishingClip?: boolean | null
      bannerImageFileId?: string | null
      bannerVideoId?: string | null
      testPeriodEnd?: Date | null
      categoriesIds: Array<string>
      videoEngine: VideoEngine
      wmClippingTemplateId?: string | null
      sveInputLossImageMediaId?: string | null
      sveOverlayLogoImageMediaId?: string | null
      visibleInMultisportApplication: boolean
      activateSportItemChatByDefault: boolean
      contentPushServiceNames?: Array<ContentPushStreamServiceName> | null
      subTags?: Array<string> | null
      activateGreenPlayback?: boolean | null
      adCampaignId?: string | null
      contentPushServiceGenericStreamIds?: Array<string> | null
      contentPushServiceLiveEventIds?: Array<string> | null
      disableReadParallelLivestreamsLimit?: boolean | null
      streams?: Array<{ __typename?: 'WMStream'; id: string; name: string }> | null
      sportEvent?: {
        __typename?: 'SportEvent'
        startDate: Date
        endDate: Date
        state: SportEventState
        liveTemplateId?: string | null
        clipTemplateId?: string | null
        backstageTemplateId?: string | null
      } | null
      sports: Array<{ __typename?: 'Sport'; id: string; title: string; color: string }>
      titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
      subtitleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
      descriptionT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
      ownerOrganism?: { __typename?: 'Organism'; title: string } | null
      offers: Array<{ __typename?: 'PurchasableOffer'; id: string; title: string }>
      tabs?: Array<{
        __typename?: 'Tab'
        title: string
        url: string
        position: TabPosition
        blank?: boolean | null
        titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
        icon: { __typename?: 'Icon'; title: string; svg: string }
      }> | null
      sponsorPlatform?: {
        __typename?: 'SponsorPlatform'
        linkId?: string | null
        display?: Array<SponsorPlatformDisplayOptions> | null
        videoPreroll?: Array<SponsorPlatformPrerollOptions> | null
        link?: {
          __typename: 'Link'
          id: string
          ownerOrganismIds: Array<string>
          tag?: LinkTag | null
          title: string
          subtitle?: string | null
          description?: string | null
          defaultLocale?: string | null
          clicks: number
          uniqueClicks: number
          views: number
          countries: Array<string | null>
          uniqueViews: number
          teaserVideoId?: string | null
          gamTagUrl?: string | null
          billboardId?: string | null
          url?: string | null
          imageId: string
          categoriesIds: Array<string>
          publicationStatus: PublicationStatus
          ownerOrganisms?: Array<{
            __typename?: 'Organism'
            id: string
            type: OrganismType
            whiteLabel: boolean
            title: string
            description?: string | null
            publicationStatus: PublicationStatus
            defaultLocale?: string | null
            onFront: boolean
            frontPosition?: number | null
            officialWebSiteUrl: string
            sportIds: Array<string>
            forbiddenKeyWords?: Array<string> | null
            enableExternalSources?: boolean | null
            availableExternalSources?: Array<WidgetExternalContentSource> | null
            streamsIds?: Array<string> | null
            wmProfileId?: string | null
            sveChannelIds?: Array<string> | null
            organismPageApplicationDesignId?: string | null
            contentPageApplicationDesignId?: string | null
            adminsAutomatedEmails?: boolean | null
            visibleInMultisportApplication?: boolean | null
            titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
            descriptionT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
            logo: {
              __typename?: 'Logo'
              coloredImage?: {
                __typename?: 'Media'
                id: string
                createdAt: Date
                updatedAt: Date
                title: string
                type: MediaType
                publicationStatus: PublicationStatus
                tags: Array<MediaTags>
                date?: Date | null
                startTrim?: number | null
                endTrim?: number | null
                liveStreamId?: string | null
                videoClipId?: string | null
                imageId?: string | null
                thumbnailId?: string | null
                ownerOrganismId?: string | null
                pinned?: boolean | null
                liveStream?: {
                  __typename?: 'LiveStream'
                  id: string
                  playbackUrl: string
                  ingestUrl?: string | null
                  wildmokaEventID?: string | null
                  videoEngine: VideoEngine
                  state: LiveStreamState
                  streamId?: string | null
                  sveBroadcastId?: string | null
                  sveChannelId?: string | null
                  externalFeedProvider?: ExternalFeedType | null
                  externalFeedUrl?: string | null
                  event?: {
                    __typename?: 'WMEvent'
                    id: string
                    name: string
                    description?: string | null
                    testStartDate: Date
                    editoStartDate: Date
                    editoStopDate: Date
                    stopDate: Date
                    state: WmEventState
                    notifEmail?: string | null
                    clipCreationAtEnd: boolean
                    clipPublicationAtEnd: boolean
                    livePublication: boolean
                    stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
                    live?: {
                      __typename?: 'WMEventLive'
                      id: string
                      publishStatus: WmLiveState
                      type: WmLiveType
                      autoAnnounceDate?: Date | null
                      template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
                    } | null
                    clipTemplate?: {
                      __typename?: 'WMEventTemplate'
                      id: string
                      name: string
                      type: WmTemplateType
                    } | null
                  } | null
                  sveBroadcast?: {
                    __typename?: 'SVEBroadcast'
                    id: string
                    status: SveBroadcastState
                    channelId: string
                    startDate?: Date | null
                    clipStartDate?: Date | null
                    stopDate?: Date | null
                    scheduledStartDate: Date
                    scheduledClipStartDate: Date
                    scheduledStopDate: Date
                  } | null
                  sveLiveMonitoring?: {
                    __typename?: 'SVELiveMonitoring'
                    alerts?: {
                      __typename?: 'SVELiveMonitoringAlerts'
                      audioNotDetected?: string | null
                      initialProbeTakingLonger?: string | null
                      videoNotDetected?: string | null
                      RTMPHasNoAudioVideo?: string | null
                      RTMPStreamNotFound?: string | null
                    } | null
                    stats?: {
                      __typename?: 'SVELiveMonitoringStats'
                      networkIn?: string | null
                      droppedFrames?: string | null
                      inputVideoFrameRate?: string | null
                      outputAudioLevelLkfs?: string | null
                    } | null
                  } | null
                } | null
                videoClip?: {
                  __typename?: 'VideoClip'
                  id: string
                  playbackUrl: string
                  downloadLink: string
                  status?: VideoClipStatus | null
                  updatedAt: Date
                  createdAt: Date
                  fileId?: string | null
                  error?: {
                    __typename?: 'VideoClipError'
                    guid: string
                    workflowStatus: string
                    workflowErrorAt: string
                    errorMessage: string
                    errorDetails: string
                  } | null
                  captions?: Array<{
                    __typename?: 'Caption'
                    srcFileId: string
                    language: string
                    title: string
                  }> | null
                  externalFeed?: {
                    __typename?: 'ExternalFeed'
                    externalFeedProvider: ExternalFeedType
                    externalFeedUrl: string
                  } | null
                } | null
                image?: {
                  __typename?: 'File'
                  id: string
                  filename: string
                  size: number
                  mimetype: string
                  downloadUrl: string
                  thumbUrl?: string | null
                  createdAt: Date
                  metadata?: {
                    __typename?: 'FileMetadata'
                    title?: string | null
                    description?: string | null
                    aspect?: number | null
                    width?: number | null
                    height?: number | null
                    sourceFileId?: string | null
                  } | null
                } | null
                thumbnail?: {
                  __typename?: 'Media'
                  id: string
                  image?: {
                    __typename?: 'File'
                    id: string
                    filename: string
                    size: number
                    mimetype: string
                    downloadUrl: string
                    thumbUrl?: string | null
                    createdAt: Date
                    metadata?: {
                      __typename?: 'FileMetadata'
                      title?: string | null
                      description?: string | null
                      aspect?: number | null
                      width?: number | null
                      height?: number | null
                      sourceFileId?: string | null
                    } | null
                  } | null
                } | null
                timelineMarkers?: Array<{
                  __typename?: 'Marker'
                  title: string
                  time: number
                  icon?: string | null
                }> | null
              } | null
              transparentDarkImage?: {
                __typename?: 'Media'
                id: string
                createdAt: Date
                updatedAt: Date
                title: string
                type: MediaType
                publicationStatus: PublicationStatus
                tags: Array<MediaTags>
                date?: Date | null
                startTrim?: number | null
                endTrim?: number | null
                liveStreamId?: string | null
                videoClipId?: string | null
                imageId?: string | null
                thumbnailId?: string | null
                ownerOrganismId?: string | null
                pinned?: boolean | null
                liveStream?: {
                  __typename?: 'LiveStream'
                  id: string
                  playbackUrl: string
                  ingestUrl?: string | null
                  wildmokaEventID?: string | null
                  videoEngine: VideoEngine
                  state: LiveStreamState
                  streamId?: string | null
                  sveBroadcastId?: string | null
                  sveChannelId?: string | null
                  externalFeedProvider?: ExternalFeedType | null
                  externalFeedUrl?: string | null
                  event?: {
                    __typename?: 'WMEvent'
                    id: string
                    name: string
                    description?: string | null
                    testStartDate: Date
                    editoStartDate: Date
                    editoStopDate: Date
                    stopDate: Date
                    state: WmEventState
                    notifEmail?: string | null
                    clipCreationAtEnd: boolean
                    clipPublicationAtEnd: boolean
                    livePublication: boolean
                    stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
                    live?: {
                      __typename?: 'WMEventLive'
                      id: string
                      publishStatus: WmLiveState
                      type: WmLiveType
                      autoAnnounceDate?: Date | null
                      template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
                    } | null
                    clipTemplate?: {
                      __typename?: 'WMEventTemplate'
                      id: string
                      name: string
                      type: WmTemplateType
                    } | null
                  } | null
                  sveBroadcast?: {
                    __typename?: 'SVEBroadcast'
                    id: string
                    status: SveBroadcastState
                    channelId: string
                    startDate?: Date | null
                    clipStartDate?: Date | null
                    stopDate?: Date | null
                    scheduledStartDate: Date
                    scheduledClipStartDate: Date
                    scheduledStopDate: Date
                  } | null
                  sveLiveMonitoring?: {
                    __typename?: 'SVELiveMonitoring'
                    alerts?: {
                      __typename?: 'SVELiveMonitoringAlerts'
                      audioNotDetected?: string | null
                      initialProbeTakingLonger?: string | null
                      videoNotDetected?: string | null
                      RTMPHasNoAudioVideo?: string | null
                      RTMPStreamNotFound?: string | null
                    } | null
                    stats?: {
                      __typename?: 'SVELiveMonitoringStats'
                      networkIn?: string | null
                      droppedFrames?: string | null
                      inputVideoFrameRate?: string | null
                      outputAudioLevelLkfs?: string | null
                    } | null
                  } | null
                } | null
                videoClip?: {
                  __typename?: 'VideoClip'
                  id: string
                  playbackUrl: string
                  downloadLink: string
                  status?: VideoClipStatus | null
                  updatedAt: Date
                  createdAt: Date
                  fileId?: string | null
                  error?: {
                    __typename?: 'VideoClipError'
                    guid: string
                    workflowStatus: string
                    workflowErrorAt: string
                    errorMessage: string
                    errorDetails: string
                  } | null
                  captions?: Array<{
                    __typename?: 'Caption'
                    srcFileId: string
                    language: string
                    title: string
                  }> | null
                  externalFeed?: {
                    __typename?: 'ExternalFeed'
                    externalFeedProvider: ExternalFeedType
                    externalFeedUrl: string
                  } | null
                } | null
                image?: {
                  __typename?: 'File'
                  id: string
                  filename: string
                  size: number
                  mimetype: string
                  downloadUrl: string
                  thumbUrl?: string | null
                  createdAt: Date
                  metadata?: {
                    __typename?: 'FileMetadata'
                    title?: string | null
                    description?: string | null
                    aspect?: number | null
                    width?: number | null
                    height?: number | null
                    sourceFileId?: string | null
                  } | null
                } | null
                thumbnail?: {
                  __typename?: 'Media'
                  id: string
                  image?: {
                    __typename?: 'File'
                    id: string
                    filename: string
                    size: number
                    mimetype: string
                    downloadUrl: string
                    thumbUrl?: string | null
                    createdAt: Date
                    metadata?: {
                      __typename?: 'FileMetadata'
                      title?: string | null
                      description?: string | null
                      aspect?: number | null
                      width?: number | null
                      height?: number | null
                      sourceFileId?: string | null
                    } | null
                  } | null
                } | null
                timelineMarkers?: Array<{
                  __typename?: 'Marker'
                  title: string
                  time: number
                  icon?: string | null
                }> | null
              } | null
              transparentLightImage?: {
                __typename?: 'Media'
                id: string
                createdAt: Date
                updatedAt: Date
                title: string
                type: MediaType
                publicationStatus: PublicationStatus
                tags: Array<MediaTags>
                date?: Date | null
                startTrim?: number | null
                endTrim?: number | null
                liveStreamId?: string | null
                videoClipId?: string | null
                imageId?: string | null
                thumbnailId?: string | null
                ownerOrganismId?: string | null
                pinned?: boolean | null
                liveStream?: {
                  __typename?: 'LiveStream'
                  id: string
                  playbackUrl: string
                  ingestUrl?: string | null
                  wildmokaEventID?: string | null
                  videoEngine: VideoEngine
                  state: LiveStreamState
                  streamId?: string | null
                  sveBroadcastId?: string | null
                  sveChannelId?: string | null
                  externalFeedProvider?: ExternalFeedType | null
                  externalFeedUrl?: string | null
                  event?: {
                    __typename?: 'WMEvent'
                    id: string
                    name: string
                    description?: string | null
                    testStartDate: Date
                    editoStartDate: Date
                    editoStopDate: Date
                    stopDate: Date
                    state: WmEventState
                    notifEmail?: string | null
                    clipCreationAtEnd: boolean
                    clipPublicationAtEnd: boolean
                    livePublication: boolean
                    stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
                    live?: {
                      __typename?: 'WMEventLive'
                      id: string
                      publishStatus: WmLiveState
                      type: WmLiveType
                      autoAnnounceDate?: Date | null
                      template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
                    } | null
                    clipTemplate?: {
                      __typename?: 'WMEventTemplate'
                      id: string
                      name: string
                      type: WmTemplateType
                    } | null
                  } | null
                  sveBroadcast?: {
                    __typename?: 'SVEBroadcast'
                    id: string
                    status: SveBroadcastState
                    channelId: string
                    startDate?: Date | null
                    clipStartDate?: Date | null
                    stopDate?: Date | null
                    scheduledStartDate: Date
                    scheduledClipStartDate: Date
                    scheduledStopDate: Date
                  } | null
                  sveLiveMonitoring?: {
                    __typename?: 'SVELiveMonitoring'
                    alerts?: {
                      __typename?: 'SVELiveMonitoringAlerts'
                      audioNotDetected?: string | null
                      initialProbeTakingLonger?: string | null
                      videoNotDetected?: string | null
                      RTMPHasNoAudioVideo?: string | null
                      RTMPStreamNotFound?: string | null
                    } | null
                    stats?: {
                      __typename?: 'SVELiveMonitoringStats'
                      networkIn?: string | null
                      droppedFrames?: string | null
                      inputVideoFrameRate?: string | null
                      outputAudioLevelLkfs?: string | null
                    } | null
                  } | null
                } | null
                videoClip?: {
                  __typename?: 'VideoClip'
                  id: string
                  playbackUrl: string
                  downloadLink: string
                  status?: VideoClipStatus | null
                  updatedAt: Date
                  createdAt: Date
                  fileId?: string | null
                  error?: {
                    __typename?: 'VideoClipError'
                    guid: string
                    workflowStatus: string
                    workflowErrorAt: string
                    errorMessage: string
                    errorDetails: string
                  } | null
                  captions?: Array<{
                    __typename?: 'Caption'
                    srcFileId: string
                    language: string
                    title: string
                  }> | null
                  externalFeed?: {
                    __typename?: 'ExternalFeed'
                    externalFeedProvider: ExternalFeedType
                    externalFeedUrl: string
                  } | null
                } | null
                image?: {
                  __typename?: 'File'
                  id: string
                  filename: string
                  size: number
                  mimetype: string
                  downloadUrl: string
                  thumbUrl?: string | null
                  createdAt: Date
                  metadata?: {
                    __typename?: 'FileMetadata'
                    title?: string | null
                    description?: string | null
                    aspect?: number | null
                    width?: number | null
                    height?: number | null
                    sourceFileId?: string | null
                  } | null
                } | null
                thumbnail?: {
                  __typename?: 'Media'
                  id: string
                  image?: {
                    __typename?: 'File'
                    id: string
                    filename: string
                    size: number
                    mimetype: string
                    downloadUrl: string
                    thumbUrl?: string | null
                    createdAt: Date
                    metadata?: {
                      __typename?: 'FileMetadata'
                      title?: string | null
                      description?: string | null
                      aspect?: number | null
                      width?: number | null
                      height?: number | null
                      sourceFileId?: string | null
                    } | null
                  } | null
                } | null
                timelineMarkers?: Array<{
                  __typename?: 'Marker'
                  title: string
                  time: number
                  icon?: string | null
                }> | null
              } | null
            }
            sports: Array<{ __typename?: 'Sport'; id: string }>
            bannerImage?: {
              __typename?: 'Media'
              id: string
              createdAt: Date
              updatedAt: Date
              title: string
              type: MediaType
              publicationStatus: PublicationStatus
              tags: Array<MediaTags>
              date?: Date | null
              startTrim?: number | null
              endTrim?: number | null
              liveStreamId?: string | null
              videoClipId?: string | null
              imageId?: string | null
              thumbnailId?: string | null
              ownerOrganismId?: string | null
              pinned?: boolean | null
              liveStream?: {
                __typename?: 'LiveStream'
                id: string
                playbackUrl: string
                ingestUrl?: string | null
                wildmokaEventID?: string | null
                videoEngine: VideoEngine
                state: LiveStreamState
                streamId?: string | null
                sveBroadcastId?: string | null
                sveChannelId?: string | null
                externalFeedProvider?: ExternalFeedType | null
                externalFeedUrl?: string | null
                event?: {
                  __typename?: 'WMEvent'
                  id: string
                  name: string
                  description?: string | null
                  testStartDate: Date
                  editoStartDate: Date
                  editoStopDate: Date
                  stopDate: Date
                  state: WmEventState
                  notifEmail?: string | null
                  clipCreationAtEnd: boolean
                  clipPublicationAtEnd: boolean
                  livePublication: boolean
                  stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
                  live?: {
                    __typename?: 'WMEventLive'
                    id: string
                    publishStatus: WmLiveState
                    type: WmLiveType
                    autoAnnounceDate?: Date | null
                    template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
                  } | null
                  clipTemplate?: {
                    __typename?: 'WMEventTemplate'
                    id: string
                    name: string
                    type: WmTemplateType
                  } | null
                } | null
                sveBroadcast?: {
                  __typename?: 'SVEBroadcast'
                  id: string
                  status: SveBroadcastState
                  channelId: string
                  startDate?: Date | null
                  clipStartDate?: Date | null
                  stopDate?: Date | null
                  scheduledStartDate: Date
                  scheduledClipStartDate: Date
                  scheduledStopDate: Date
                } | null
                sveLiveMonitoring?: {
                  __typename?: 'SVELiveMonitoring'
                  alerts?: {
                    __typename?: 'SVELiveMonitoringAlerts'
                    audioNotDetected?: string | null
                    initialProbeTakingLonger?: string | null
                    videoNotDetected?: string | null
                    RTMPHasNoAudioVideo?: string | null
                    RTMPStreamNotFound?: string | null
                  } | null
                  stats?: {
                    __typename?: 'SVELiveMonitoringStats'
                    networkIn?: string | null
                    droppedFrames?: string | null
                    inputVideoFrameRate?: string | null
                    outputAudioLevelLkfs?: string | null
                  } | null
                } | null
              } | null
              videoClip?: {
                __typename?: 'VideoClip'
                id: string
                playbackUrl: string
                downloadLink: string
                status?: VideoClipStatus | null
                updatedAt: Date
                createdAt: Date
                fileId?: string | null
                error?: {
                  __typename?: 'VideoClipError'
                  guid: string
                  workflowStatus: string
                  workflowErrorAt: string
                  errorMessage: string
                  errorDetails: string
                } | null
                captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
                externalFeed?: {
                  __typename?: 'ExternalFeed'
                  externalFeedProvider: ExternalFeedType
                  externalFeedUrl: string
                } | null
              } | null
              image?: {
                __typename?: 'File'
                id: string
                filename: string
                size: number
                mimetype: string
                downloadUrl: string
                thumbUrl?: string | null
                createdAt: Date
                metadata?: {
                  __typename?: 'FileMetadata'
                  title?: string | null
                  description?: string | null
                  aspect?: number | null
                  width?: number | null
                  height?: number | null
                  sourceFileId?: string | null
                } | null
              } | null
              thumbnail?: {
                __typename?: 'Media'
                id: string
                image?: {
                  __typename?: 'File'
                  id: string
                  filename: string
                  size: number
                  mimetype: string
                  downloadUrl: string
                  thumbUrl?: string | null
                  createdAt: Date
                  metadata?: {
                    __typename?: 'FileMetadata'
                    title?: string | null
                    description?: string | null
                    aspect?: number | null
                    width?: number | null
                    height?: number | null
                    sourceFileId?: string | null
                  } | null
                } | null
              } | null
              timelineMarkers?: Array<{
                __typename?: 'Marker'
                title: string
                time: number
                icon?: string | null
              }> | null
            } | null
            adminBadge?: {
              __typename?: 'Media'
              id: string
              createdAt: Date
              updatedAt: Date
              title: string
              type: MediaType
              publicationStatus: PublicationStatus
              tags: Array<MediaTags>
              date?: Date | null
              startTrim?: number | null
              endTrim?: number | null
              liveStreamId?: string | null
              videoClipId?: string | null
              imageId?: string | null
              thumbnailId?: string | null
              ownerOrganismId?: string | null
              pinned?: boolean | null
              liveStream?: {
                __typename?: 'LiveStream'
                id: string
                playbackUrl: string
                ingestUrl?: string | null
                wildmokaEventID?: string | null
                videoEngine: VideoEngine
                state: LiveStreamState
                streamId?: string | null
                sveBroadcastId?: string | null
                sveChannelId?: string | null
                externalFeedProvider?: ExternalFeedType | null
                externalFeedUrl?: string | null
                event?: {
                  __typename?: 'WMEvent'
                  id: string
                  name: string
                  description?: string | null
                  testStartDate: Date
                  editoStartDate: Date
                  editoStopDate: Date
                  stopDate: Date
                  state: WmEventState
                  notifEmail?: string | null
                  clipCreationAtEnd: boolean
                  clipPublicationAtEnd: boolean
                  livePublication: boolean
                  stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
                  live?: {
                    __typename?: 'WMEventLive'
                    id: string
                    publishStatus: WmLiveState
                    type: WmLiveType
                    autoAnnounceDate?: Date | null
                    template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
                  } | null
                  clipTemplate?: {
                    __typename?: 'WMEventTemplate'
                    id: string
                    name: string
                    type: WmTemplateType
                  } | null
                } | null
                sveBroadcast?: {
                  __typename?: 'SVEBroadcast'
                  id: string
                  status: SveBroadcastState
                  channelId: string
                  startDate?: Date | null
                  clipStartDate?: Date | null
                  stopDate?: Date | null
                  scheduledStartDate: Date
                  scheduledClipStartDate: Date
                  scheduledStopDate: Date
                } | null
                sveLiveMonitoring?: {
                  __typename?: 'SVELiveMonitoring'
                  alerts?: {
                    __typename?: 'SVELiveMonitoringAlerts'
                    audioNotDetected?: string | null
                    initialProbeTakingLonger?: string | null
                    videoNotDetected?: string | null
                    RTMPHasNoAudioVideo?: string | null
                    RTMPStreamNotFound?: string | null
                  } | null
                  stats?: {
                    __typename?: 'SVELiveMonitoringStats'
                    networkIn?: string | null
                    droppedFrames?: string | null
                    inputVideoFrameRate?: string | null
                    outputAudioLevelLkfs?: string | null
                  } | null
                } | null
              } | null
              videoClip?: {
                __typename?: 'VideoClip'
                id: string
                playbackUrl: string
                downloadLink: string
                status?: VideoClipStatus | null
                updatedAt: Date
                createdAt: Date
                fileId?: string | null
                error?: {
                  __typename?: 'VideoClipError'
                  guid: string
                  workflowStatus: string
                  workflowErrorAt: string
                  errorMessage: string
                  errorDetails: string
                } | null
                captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
                externalFeed?: {
                  __typename?: 'ExternalFeed'
                  externalFeedProvider: ExternalFeedType
                  externalFeedUrl: string
                } | null
              } | null
              image?: {
                __typename?: 'File'
                id: string
                filename: string
                size: number
                mimetype: string
                downloadUrl: string
                thumbUrl?: string | null
                createdAt: Date
                metadata?: {
                  __typename?: 'FileMetadata'
                  title?: string | null
                  description?: string | null
                  aspect?: number | null
                  width?: number | null
                  height?: number | null
                  sourceFileId?: string | null
                } | null
              } | null
              thumbnail?: {
                __typename?: 'Media'
                id: string
                image?: {
                  __typename?: 'File'
                  id: string
                  filename: string
                  size: number
                  mimetype: string
                  downloadUrl: string
                  thumbUrl?: string | null
                  createdAt: Date
                  metadata?: {
                    __typename?: 'FileMetadata'
                    title?: string | null
                    description?: string | null
                    aspect?: number | null
                    width?: number | null
                    height?: number | null
                    sourceFileId?: string | null
                  } | null
                } | null
              } | null
              timelineMarkers?: Array<{
                __typename?: 'Marker'
                title: string
                time: number
                icon?: string | null
              }> | null
            } | null
            bannerVideo?: {
              __typename?: 'Media'
              id: string
              videoClip?: { __typename?: 'VideoClip'; id: string; playbackUrl: string } | null
            } | null
            pageWidgets?: Array<{
              __typename?: 'CustomPageWidget'
              name: CustomPageWidgetName
              position: number
              display?: CustomPageDisplayTypes | null
              contentDisplay?: ContentSetContentDisplay | null
              cardsDisplay?: CardsDisplay | null
              options?: {
                __typename?: 'CustomPageWidgetOptions'
                editedCategoryId?: string | null
                sportItemSetType?: SportItemSetType | null
                replays?: {
                  __typename?: 'ReplaysWidget'
                  defaultLocale: string
                  maxReplays?: number | null
                  depthInDays?: number | null
                  nameT: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }>
                } | null
                externalContent?: {
                  __typename?: 'ExternalContentWidget'
                  name?: string | null
                  defaultLocale: string
                  maxItemsCount: number
                  source: WidgetExternalContentSource
                  nameT: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }>
                  period?: { __typename?: 'StartEndDates'; startDate: Date; endDate: Date } | null
                  wecContent?: {
                    __typename?: 'WECNewsContent'
                    teamECMIds?: Array<string> | null
                    driverECMIds?: Array<string> | null
                    carECMIds?: Array<string> | null
                    categoryECMIds?: Array<string> | null
                    raceECMIds?: Array<string> | null
                  } | null
                  rssContent?: { __typename?: 'RSSFeedContent'; feedUrl: string } | null
                } | null
              } | null
            }> | null
            offers: Array<{
              __typename?: 'PurchasableOffer'
              id: string
              mode: OfferMode
              delay?: number | null
              expiredAt?: Date | null
              publicationStatus: PublicationStatus
              title: string
              defaultLocale?: string | null
              url?: string | null
              bannerImageId?: string | null
              thumbnailImageId?: string | null
              freeWithLicense: boolean
              position?: number | null
              titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
              description: Array<{ __typename?: 'OfferDescription'; header: string; content: string }>
              descriptionT?: Array<{
                __typename?: 'ContentTranslationDescriptionPurchasableOffer'
                locale: string
                content?: Array<{ __typename?: 'OfferDescription'; header: string; content: string }> | null
              }> | null
              products: Array<{
                __typename?: 'Product'
                productId: string
                platform: Platform
                period?: Period | null
                publicationStatus: PublicationStatus
                purchasableOfferId?: string | null
                licensedUserOnly?: boolean | null
                premiumOf?: Array<string> | null
                prices: Array<{ __typename?: 'Price'; value: number; currency: string }>
              }>
            }>
            license?: { __typename?: 'OrganismLicense'; enabled: boolean; api?: LicenseApi | null } | null
            tags?: Array<{ __typename?: 'Tag'; id: string; title: string; subTags?: Array<string> | null }> | null
          }> | null
          titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
          subtitleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
          descriptionT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
          billboard?: {
            __typename?: 'Media'
            id: string
            videoClip?: { __typename?: 'VideoClip'; id: string; playbackUrl: string } | null
          } | null
          teaserVideo?: {
            __typename?: 'Media'
            id: string
            videoClip?: { __typename?: 'VideoClip'; id: string; playbackUrl: string } | null
          } | null
          image?: {
            __typename?: 'Media'
            id: string
            image?: { __typename?: 'File'; id: string; thumbUrl?: string | null; downloadUrl: string } | null
          } | null
          categories: Array<{ __typename?: 'Category'; id: string; title: string }>
        } | null
      } | null
      items?: Array<{ __typename?: 'SportItem'; id: string; title: string }> | null
      bannerImage?: {
        __typename?: 'Media'
        id: string
        image?: { __typename?: 'File'; id: string; thumbUrl?: string | null; downloadUrl: string } | null
      } | null
      bannerVideo?: {
        __typename?: 'Media'
        id: string
        videoClip?: { __typename?: 'VideoClip'; id: string; playbackUrl: string } | null
      } | null
      categories: Array<{ __typename?: 'Category'; id: string; title: string }>
      legalRestrictions: Array<{
        __typename?: 'LegalRestriction'
        id: string
        description?: string | null
        countries: Array<string>
        type: RestrictionType
        timeSlots?: { __typename?: 'TimeSlots'; from: string; to: string } | null
      }>
      cac?: {
        __typename?: 'ContentAccessControl'
        userAuthenticationRule?: CacUserAuthenticationRule | null
        userLocationRule?: {
          __typename?: 'CACUserLocationRule'
          distance: number
          condition: UserLocationCondition
          location: { __typename?: 'GeoLocation'; latitude: number; longitude: number }
        } | null
      } | null
      sveStreams: Array<{
        __typename?: 'SVEStream'
        sveChannelId: string
        socialNetworkWMStreamId?: string | null
        outputStreamId?: string | null
      }>
      tag?: { __typename?: 'Tag'; id: string; title: string } | null
      contentPushServiceGenericStreams?: Array<{
        __typename?: 'ContentPushStream'
        id: string
        name: string
        createdAt: Date
        updatedAt: Date
      }> | null
      noSpoilerMode?: { __typename?: 'NoSpoilerMode'; enabled: boolean; minutesBeforeDisabling: number } | null
    } | null
    titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    subtitleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    descriptionT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    coverImage?: {
      __typename?: 'Media'
      id: string
      image?: { __typename?: 'File'; id: string; thumbUrl?: string | null; downloadUrl: string } | null
    } | null
    medias: Array<{
      __typename?: 'Media'
      id: string
      createdAt: Date
      updatedAt: Date
      title: string
      type: MediaType
      publicationStatus: PublicationStatus
      tags: Array<MediaTags>
      date?: Date | null
      startTrim?: number | null
      endTrim?: number | null
      liveStreamId?: string | null
      videoClipId?: string | null
      imageId?: string | null
      thumbnailId?: string | null
      ownerOrganismId?: string | null
      pinned?: boolean | null
      liveStream?: {
        __typename?: 'LiveStream'
        id: string
        playbackUrl: string
        ingestUrl?: string | null
        wildmokaEventID?: string | null
        videoEngine: VideoEngine
        state: LiveStreamState
        streamId?: string | null
        sveBroadcastId?: string | null
        sveChannelId?: string | null
        externalFeedProvider?: ExternalFeedType | null
        externalFeedUrl?: string | null
        event?: {
          __typename?: 'WMEvent'
          id: string
          name: string
          description?: string | null
          testStartDate: Date
          editoStartDate: Date
          editoStopDate: Date
          stopDate: Date
          state: WmEventState
          notifEmail?: string | null
          clipCreationAtEnd: boolean
          clipPublicationAtEnd: boolean
          livePublication: boolean
          stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
          live?: {
            __typename?: 'WMEventLive'
            id: string
            publishStatus: WmLiveState
            type: WmLiveType
            autoAnnounceDate?: Date | null
            template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
          } | null
          clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
        } | null
        sveBroadcast?: {
          __typename?: 'SVEBroadcast'
          id: string
          status: SveBroadcastState
          channelId: string
          startDate?: Date | null
          clipStartDate?: Date | null
          stopDate?: Date | null
          scheduledStartDate: Date
          scheduledClipStartDate: Date
          scheduledStopDate: Date
        } | null
        sveLiveMonitoring?: {
          __typename?: 'SVELiveMonitoring'
          alerts?: {
            __typename?: 'SVELiveMonitoringAlerts'
            audioNotDetected?: string | null
            initialProbeTakingLonger?: string | null
            videoNotDetected?: string | null
            RTMPHasNoAudioVideo?: string | null
            RTMPStreamNotFound?: string | null
          } | null
          stats?: {
            __typename?: 'SVELiveMonitoringStats'
            networkIn?: string | null
            droppedFrames?: string | null
            inputVideoFrameRate?: string | null
            outputAudioLevelLkfs?: string | null
          } | null
        } | null
      } | null
      videoClip?: {
        __typename?: 'VideoClip'
        id: string
        playbackUrl: string
        downloadLink: string
        status?: VideoClipStatus | null
        updatedAt: Date
        createdAt: Date
        fileId?: string | null
        error?: {
          __typename?: 'VideoClipError'
          guid: string
          workflowStatus: string
          workflowErrorAt: string
          errorMessage: string
          errorDetails: string
        } | null
        captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
        externalFeed?: {
          __typename?: 'ExternalFeed'
          externalFeedProvider: ExternalFeedType
          externalFeedUrl: string
        } | null
      } | null
      image?: {
        __typename?: 'File'
        id: string
        filename: string
        size: number
        mimetype: string
        downloadUrl: string
        thumbUrl?: string | null
        createdAt: Date
        metadata?: {
          __typename?: 'FileMetadata'
          title?: string | null
          description?: string | null
          aspect?: number | null
          width?: number | null
          height?: number | null
          sourceFileId?: string | null
        } | null
      } | null
      thumbnail?: {
        __typename?: 'Media'
        id: string
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
      } | null
      timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
    }>
    tabs?: Array<{
      __typename?: 'Tab'
      title: string
      url: string
      position: TabPosition
      blank?: boolean | null
      titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
      icon: { __typename?: 'Icon'; title: string; svg: string }
    }> | null
    sportEventItem?: {
      __typename?: 'SportEventItem'
      startDate: Date
      stopDate: Date
      testDate: Date
      publishAutoreplay: boolean
    } | null
    cac?: { __typename?: 'ContentAccessControl'; userAuthenticationRule?: CacUserAuthenticationRule | null } | null
  }> | null
}

export type CreateSportItemMutationVariables = Exact<{
  input: CreateSportItemInput
}>

export type CreateSportItemMutation = {
  __typename?: 'Mutation'
  createSportItem: {
    __typename?: 'SportItem'
    id: string
    title: string
    subtitle?: string | null
    description?: string | null
    defaultLocale?: string | null
    videoContentType: VideoContentType
    publicationStatus: PublicationStatus
    contentType: SportItemContentType
    sportItemSetId: string
    reverseRestriction?: boolean | null
    coverImageFileId: string
    matureContent: boolean
    mediasIds: Array<string>
    overrideTabs: boolean
    contentPushServiceNamesExcluded?: Array<ContentPushStreamServiceName> | null
    modeRadio?: boolean | null
    titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    subtitleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    descriptionT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    sportItemSet?: {
      __typename?: 'SportItemSet'
      id: string
      streamsIds?: Array<string> | null
      ownerOrganismId?: string | null
      streams?: Array<{ __typename?: 'WMStream'; id: string; name: string }> | null
      sportEvent?: {
        __typename?: 'SportEvent'
        startDate: Date
        endDate: Date
        state: SportEventState
        liveTemplateId?: string | null
        clipTemplateId?: string | null
        backstageTemplateId?: string | null
      } | null
    } | null
    coverImage?: {
      __typename?: 'Media'
      id: string
      image?: { __typename?: 'File'; id: string; thumbUrl?: string | null; downloadUrl: string } | null
    } | null
    medias: Array<{
      __typename?: 'Media'
      id: string
      createdAt: Date
      updatedAt: Date
      title: string
      type: MediaType
      publicationStatus: PublicationStatus
      tags: Array<MediaTags>
      date?: Date | null
      startTrim?: number | null
      endTrim?: number | null
      liveStreamId?: string | null
      videoClipId?: string | null
      imageId?: string | null
      thumbnailId?: string | null
      ownerOrganismId?: string | null
      pinned?: boolean | null
      liveStream?: {
        __typename?: 'LiveStream'
        id: string
        playbackUrl: string
        ingestUrl?: string | null
        wildmokaEventID?: string | null
        videoEngine: VideoEngine
        state: LiveStreamState
        streamId?: string | null
        sveBroadcastId?: string | null
        sveChannelId?: string | null
        externalFeedProvider?: ExternalFeedType | null
        externalFeedUrl?: string | null
        event?: {
          __typename?: 'WMEvent'
          id: string
          name: string
          description?: string | null
          testStartDate: Date
          editoStartDate: Date
          editoStopDate: Date
          stopDate: Date
          state: WmEventState
          notifEmail?: string | null
          clipCreationAtEnd: boolean
          clipPublicationAtEnd: boolean
          livePublication: boolean
          stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
          live?: {
            __typename?: 'WMEventLive'
            id: string
            publishStatus: WmLiveState
            type: WmLiveType
            autoAnnounceDate?: Date | null
            template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
          } | null
          clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
        } | null
        sveBroadcast?: {
          __typename?: 'SVEBroadcast'
          id: string
          status: SveBroadcastState
          channelId: string
          startDate?: Date | null
          clipStartDate?: Date | null
          stopDate?: Date | null
          scheduledStartDate: Date
          scheduledClipStartDate: Date
          scheduledStopDate: Date
        } | null
        sveLiveMonitoring?: {
          __typename?: 'SVELiveMonitoring'
          alerts?: {
            __typename?: 'SVELiveMonitoringAlerts'
            audioNotDetected?: string | null
            initialProbeTakingLonger?: string | null
            videoNotDetected?: string | null
            RTMPHasNoAudioVideo?: string | null
            RTMPStreamNotFound?: string | null
          } | null
          stats?: {
            __typename?: 'SVELiveMonitoringStats'
            networkIn?: string | null
            droppedFrames?: string | null
            inputVideoFrameRate?: string | null
            outputAudioLevelLkfs?: string | null
          } | null
        } | null
      } | null
      videoClip?: {
        __typename?: 'VideoClip'
        id: string
        playbackUrl: string
        downloadLink: string
        status?: VideoClipStatus | null
        updatedAt: Date
        createdAt: Date
        fileId?: string | null
        error?: {
          __typename?: 'VideoClipError'
          guid: string
          workflowStatus: string
          workflowErrorAt: string
          errorMessage: string
          errorDetails: string
        } | null
        captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
        externalFeed?: {
          __typename?: 'ExternalFeed'
          externalFeedProvider: ExternalFeedType
          externalFeedUrl: string
        } | null
      } | null
      image?: {
        __typename?: 'File'
        id: string
        filename: string
        size: number
        mimetype: string
        downloadUrl: string
        thumbUrl?: string | null
        createdAt: Date
        metadata?: {
          __typename?: 'FileMetadata'
          title?: string | null
          description?: string | null
          aspect?: number | null
          width?: number | null
          height?: number | null
          sourceFileId?: string | null
        } | null
      } | null
      thumbnail?: {
        __typename?: 'Media'
        id: string
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
      } | null
      timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
    }>
    tabs?: Array<{
      __typename?: 'Tab'
      title: string
      url: string
      position: TabPosition
      blank?: boolean | null
      titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
      icon: { __typename?: 'Icon'; title: string; svg: string }
    }> | null
    sportEventItem?: {
      __typename?: 'SportEventItem'
      startDate: Date
      stopDate: Date
      testDate: Date
      publishAutoreplay: boolean
    } | null
    cac?: { __typename?: 'ContentAccessControl'; userAuthenticationRule?: CacUserAuthenticationRule | null } | null
  }
}

export type UpdateSportItemMutationVariables = Exact<{
  input: UpdateSportItemInput
}>

export type UpdateSportItemMutation = {
  __typename?: 'Mutation'
  updateSportItem?: {
    __typename?: 'SportItem'
    id: string
    title: string
    subtitle?: string | null
    description?: string | null
    defaultLocale?: string | null
    videoContentType: VideoContentType
    publicationStatus: PublicationStatus
    contentType: SportItemContentType
    sportItemSetId: string
    reverseRestriction?: boolean | null
    coverImageFileId: string
    matureContent: boolean
    mediasIds: Array<string>
    overrideTabs: boolean
    contentPushServiceNamesExcluded?: Array<ContentPushStreamServiceName> | null
    modeRadio?: boolean | null
    titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    subtitleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    descriptionT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    sportItemSet?: {
      __typename?: 'SportItemSet'
      id: string
      streamsIds?: Array<string> | null
      ownerOrganismId?: string | null
      streams?: Array<{ __typename?: 'WMStream'; id: string; name: string }> | null
      sportEvent?: {
        __typename?: 'SportEvent'
        startDate: Date
        endDate: Date
        state: SportEventState
        liveTemplateId?: string | null
        clipTemplateId?: string | null
        backstageTemplateId?: string | null
      } | null
    } | null
    coverImage?: {
      __typename?: 'Media'
      id: string
      image?: { __typename?: 'File'; id: string; thumbUrl?: string | null; downloadUrl: string } | null
    } | null
    medias: Array<{
      __typename?: 'Media'
      id: string
      createdAt: Date
      updatedAt: Date
      title: string
      type: MediaType
      publicationStatus: PublicationStatus
      tags: Array<MediaTags>
      date?: Date | null
      startTrim?: number | null
      endTrim?: number | null
      liveStreamId?: string | null
      videoClipId?: string | null
      imageId?: string | null
      thumbnailId?: string | null
      ownerOrganismId?: string | null
      pinned?: boolean | null
      liveStream?: {
        __typename?: 'LiveStream'
        id: string
        playbackUrl: string
        ingestUrl?: string | null
        wildmokaEventID?: string | null
        videoEngine: VideoEngine
        state: LiveStreamState
        streamId?: string | null
        sveBroadcastId?: string | null
        sveChannelId?: string | null
        externalFeedProvider?: ExternalFeedType | null
        externalFeedUrl?: string | null
        event?: {
          __typename?: 'WMEvent'
          id: string
          name: string
          description?: string | null
          testStartDate: Date
          editoStartDate: Date
          editoStopDate: Date
          stopDate: Date
          state: WmEventState
          notifEmail?: string | null
          clipCreationAtEnd: boolean
          clipPublicationAtEnd: boolean
          livePublication: boolean
          stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
          live?: {
            __typename?: 'WMEventLive'
            id: string
            publishStatus: WmLiveState
            type: WmLiveType
            autoAnnounceDate?: Date | null
            template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
          } | null
          clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
        } | null
        sveBroadcast?: {
          __typename?: 'SVEBroadcast'
          id: string
          status: SveBroadcastState
          channelId: string
          startDate?: Date | null
          clipStartDate?: Date | null
          stopDate?: Date | null
          scheduledStartDate: Date
          scheduledClipStartDate: Date
          scheduledStopDate: Date
        } | null
        sveLiveMonitoring?: {
          __typename?: 'SVELiveMonitoring'
          alerts?: {
            __typename?: 'SVELiveMonitoringAlerts'
            audioNotDetected?: string | null
            initialProbeTakingLonger?: string | null
            videoNotDetected?: string | null
            RTMPHasNoAudioVideo?: string | null
            RTMPStreamNotFound?: string | null
          } | null
          stats?: {
            __typename?: 'SVELiveMonitoringStats'
            networkIn?: string | null
            droppedFrames?: string | null
            inputVideoFrameRate?: string | null
            outputAudioLevelLkfs?: string | null
          } | null
        } | null
      } | null
      videoClip?: {
        __typename?: 'VideoClip'
        id: string
        playbackUrl: string
        downloadLink: string
        status?: VideoClipStatus | null
        updatedAt: Date
        createdAt: Date
        fileId?: string | null
        error?: {
          __typename?: 'VideoClipError'
          guid: string
          workflowStatus: string
          workflowErrorAt: string
          errorMessage: string
          errorDetails: string
        } | null
        captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
        externalFeed?: {
          __typename?: 'ExternalFeed'
          externalFeedProvider: ExternalFeedType
          externalFeedUrl: string
        } | null
      } | null
      image?: {
        __typename?: 'File'
        id: string
        filename: string
        size: number
        mimetype: string
        downloadUrl: string
        thumbUrl?: string | null
        createdAt: Date
        metadata?: {
          __typename?: 'FileMetadata'
          title?: string | null
          description?: string | null
          aspect?: number | null
          width?: number | null
          height?: number | null
          sourceFileId?: string | null
        } | null
      } | null
      thumbnail?: {
        __typename?: 'Media'
        id: string
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
      } | null
      timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
    }>
    tabs?: Array<{
      __typename?: 'Tab'
      title: string
      url: string
      position: TabPosition
      blank?: boolean | null
      titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
      icon: { __typename?: 'Icon'; title: string; svg: string }
    }> | null
    sportEventItem?: {
      __typename?: 'SportEventItem'
      startDate: Date
      stopDate: Date
      testDate: Date
      publishAutoreplay: boolean
    } | null
    cac?: { __typename?: 'ContentAccessControl'; userAuthenticationRule?: CacUserAuthenticationRule | null } | null
  } | null
}

export type DeleteSportItemMutationVariables = Exact<{
  sportItemId: Scalars['ID']
}>

export type DeleteSportItemMutation = {
  __typename?: 'Mutation'
  deleteSportItem?: { __typename?: 'SportItem'; id: string } | null
}

export type StopSportItemLiveMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type StopSportItemLiveMutation = {
  __typename?: 'Mutation'
  stopSportItemLive?: {
    __typename?: 'SportItem'
    id: string
    title: string
    subtitle?: string | null
    description?: string | null
    defaultLocale?: string | null
    videoContentType: VideoContentType
    publicationStatus: PublicationStatus
    contentType: SportItemContentType
    sportItemSetId: string
    reverseRestriction?: boolean | null
    coverImageFileId: string
    matureContent: boolean
    mediasIds: Array<string>
    overrideTabs: boolean
    contentPushServiceNamesExcluded?: Array<ContentPushStreamServiceName> | null
    modeRadio?: boolean | null
    titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    subtitleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    descriptionT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    sportItemSet?: {
      __typename?: 'SportItemSet'
      id: string
      streamsIds?: Array<string> | null
      ownerOrganismId?: string | null
      streams?: Array<{ __typename?: 'WMStream'; id: string; name: string }> | null
      sportEvent?: {
        __typename?: 'SportEvent'
        startDate: Date
        endDate: Date
        state: SportEventState
        liveTemplateId?: string | null
        clipTemplateId?: string | null
        backstageTemplateId?: string | null
      } | null
    } | null
    coverImage?: {
      __typename?: 'Media'
      id: string
      image?: { __typename?: 'File'; id: string; thumbUrl?: string | null; downloadUrl: string } | null
    } | null
    medias: Array<{
      __typename?: 'Media'
      id: string
      createdAt: Date
      updatedAt: Date
      title: string
      type: MediaType
      publicationStatus: PublicationStatus
      tags: Array<MediaTags>
      date?: Date | null
      startTrim?: number | null
      endTrim?: number | null
      liveStreamId?: string | null
      videoClipId?: string | null
      imageId?: string | null
      thumbnailId?: string | null
      ownerOrganismId?: string | null
      pinned?: boolean | null
      liveStream?: {
        __typename?: 'LiveStream'
        id: string
        playbackUrl: string
        ingestUrl?: string | null
        wildmokaEventID?: string | null
        videoEngine: VideoEngine
        state: LiveStreamState
        streamId?: string | null
        sveBroadcastId?: string | null
        sveChannelId?: string | null
        externalFeedProvider?: ExternalFeedType | null
        externalFeedUrl?: string | null
        event?: {
          __typename?: 'WMEvent'
          id: string
          name: string
          description?: string | null
          testStartDate: Date
          editoStartDate: Date
          editoStopDate: Date
          stopDate: Date
          state: WmEventState
          notifEmail?: string | null
          clipCreationAtEnd: boolean
          clipPublicationAtEnd: boolean
          livePublication: boolean
          stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
          live?: {
            __typename?: 'WMEventLive'
            id: string
            publishStatus: WmLiveState
            type: WmLiveType
            autoAnnounceDate?: Date | null
            template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
          } | null
          clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
        } | null
        sveBroadcast?: {
          __typename?: 'SVEBroadcast'
          id: string
          status: SveBroadcastState
          channelId: string
          startDate?: Date | null
          clipStartDate?: Date | null
          stopDate?: Date | null
          scheduledStartDate: Date
          scheduledClipStartDate: Date
          scheduledStopDate: Date
        } | null
        sveLiveMonitoring?: {
          __typename?: 'SVELiveMonitoring'
          alerts?: {
            __typename?: 'SVELiveMonitoringAlerts'
            audioNotDetected?: string | null
            initialProbeTakingLonger?: string | null
            videoNotDetected?: string | null
            RTMPHasNoAudioVideo?: string | null
            RTMPStreamNotFound?: string | null
          } | null
          stats?: {
            __typename?: 'SVELiveMonitoringStats'
            networkIn?: string | null
            droppedFrames?: string | null
            inputVideoFrameRate?: string | null
            outputAudioLevelLkfs?: string | null
          } | null
        } | null
      } | null
      videoClip?: {
        __typename?: 'VideoClip'
        id: string
        playbackUrl: string
        downloadLink: string
        status?: VideoClipStatus | null
        updatedAt: Date
        createdAt: Date
        fileId?: string | null
        error?: {
          __typename?: 'VideoClipError'
          guid: string
          workflowStatus: string
          workflowErrorAt: string
          errorMessage: string
          errorDetails: string
        } | null
        captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
        externalFeed?: {
          __typename?: 'ExternalFeed'
          externalFeedProvider: ExternalFeedType
          externalFeedUrl: string
        } | null
      } | null
      image?: {
        __typename?: 'File'
        id: string
        filename: string
        size: number
        mimetype: string
        downloadUrl: string
        thumbUrl?: string | null
        createdAt: Date
        metadata?: {
          __typename?: 'FileMetadata'
          title?: string | null
          description?: string | null
          aspect?: number | null
          width?: number | null
          height?: number | null
          sourceFileId?: string | null
        } | null
      } | null
      thumbnail?: {
        __typename?: 'Media'
        id: string
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
      } | null
      timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
    }>
    tabs?: Array<{
      __typename?: 'Tab'
      title: string
      url: string
      position: TabPosition
      blank?: boolean | null
      titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
      icon: { __typename?: 'Icon'; title: string; svg: string }
    }> | null
    sportEventItem?: {
      __typename?: 'SportEventItem'
      startDate: Date
      stopDate: Date
      testDate: Date
      publishAutoreplay: boolean
    } | null
    cac?: { __typename?: 'ContentAccessControl'; userAuthenticationRule?: CacUserAuthenticationRule | null } | null
  } | null
}

export type MovePlaylistItemMutationVariables = Exact<{
  input: MovePlaylistItemInput
}>

export type MovePlaylistItemMutation = {
  __typename?: 'Mutation'
  movePlaylistItem: Array<{
    __typename?: 'SportItem'
    id: string
    title: string
    subtitle?: string | null
    description?: string | null
    videoContentType: VideoContentType
    publicationStatus: PublicationStatus
    contentType: SportItemContentType
    sportItemSetId: string
    coverImageFileId: string
    matureContent: boolean
    mediasIds: Array<string>
    reverseRestriction?: boolean | null
    coverImage?: {
      __typename?: 'Media'
      id: string
      image?: { __typename?: 'File'; id: string; thumbUrl?: string | null; downloadUrl: string } | null
    } | null
    medias: Array<{
      __typename?: 'Media'
      id: string
      title: string
      type: MediaType
      publicationStatus: PublicationStatus
      tags: Array<MediaTags>
      date?: Date | null
      startTrim?: number | null
      endTrim?: number | null
      thumbnailId?: string | null
      ownerOrganismId?: string | null
      pinned?: boolean | null
      timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
    }>
    sportEventItem?: { __typename?: 'SportEventItem'; startDate: Date; stopDate: Date } | null
  }>
}

export type SveStreamContentFragment = {
  __typename?: 'SVEStream'
  sveChannelId: string
  socialNetworkWMStreamId?: string | null
  outputStreamId?: string | null
}

export type SportItemSetContentFragment = {
  __typename: 'SportItemSet'
  id: string
  sportIds: Array<string>
  type: SportItemSetType
  title: string
  subtitle?: string | null
  description?: string | null
  defaultLocale?: string | null
  publicationStatus: PublicationStatus
  ownerOrganismId?: string | null
  offerIds: Array<string>
  restrictionLevel: PremiumAccess
  websiteUrl?: string | null
  lockPublishingLive?: boolean | null
  lockPublishingClip?: boolean | null
  bannerImageFileId?: string | null
  bannerVideoId?: string | null
  testPeriodEnd?: Date | null
  categoriesIds: Array<string>
  videoEngine: VideoEngine
  streamsIds?: Array<string> | null
  wmClippingTemplateId?: string | null
  sveInputLossImageMediaId?: string | null
  sveOverlayLogoImageMediaId?: string | null
  visibleInMultisportApplication: boolean
  activateSportItemChatByDefault: boolean
  contentPushServiceNames?: Array<ContentPushStreamServiceName> | null
  subTags?: Array<string> | null
  activateGreenPlayback?: boolean | null
  adCampaignId?: string | null
  contentPushServiceGenericStreamIds?: Array<string> | null
  contentPushServiceLiveEventIds?: Array<string> | null
  disableReadParallelLivestreamsLimit?: boolean | null
  sports: Array<{ __typename?: 'Sport'; id: string; title: string; color: string }>
  titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
  subtitleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
  descriptionT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
  ownerOrganism?: { __typename?: 'Organism'; title: string } | null
  offers: Array<{ __typename?: 'PurchasableOffer'; id: string; title: string }>
  tabs?: Array<{
    __typename?: 'Tab'
    title: string
    url: string
    position: TabPosition
    blank?: boolean | null
    titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    icon: { __typename?: 'Icon'; title: string; svg: string }
  }> | null
  sponsorPlatform?: {
    __typename?: 'SponsorPlatform'
    linkId?: string | null
    display?: Array<SponsorPlatformDisplayOptions> | null
    videoPreroll?: Array<SponsorPlatformPrerollOptions> | null
    link?: {
      __typename: 'Link'
      id: string
      ownerOrganismIds: Array<string>
      tag?: LinkTag | null
      title: string
      subtitle?: string | null
      description?: string | null
      defaultLocale?: string | null
      clicks: number
      uniqueClicks: number
      views: number
      countries: Array<string | null>
      uniqueViews: number
      teaserVideoId?: string | null
      gamTagUrl?: string | null
      billboardId?: string | null
      url?: string | null
      imageId: string
      categoriesIds: Array<string>
      publicationStatus: PublicationStatus
      ownerOrganisms?: Array<{
        __typename?: 'Organism'
        id: string
        type: OrganismType
        whiteLabel: boolean
        title: string
        description?: string | null
        publicationStatus: PublicationStatus
        defaultLocale?: string | null
        onFront: boolean
        frontPosition?: number | null
        officialWebSiteUrl: string
        sportIds: Array<string>
        forbiddenKeyWords?: Array<string> | null
        enableExternalSources?: boolean | null
        availableExternalSources?: Array<WidgetExternalContentSource> | null
        streamsIds?: Array<string> | null
        wmProfileId?: string | null
        sveChannelIds?: Array<string> | null
        organismPageApplicationDesignId?: string | null
        contentPageApplicationDesignId?: string | null
        adminsAutomatedEmails?: boolean | null
        visibleInMultisportApplication?: boolean | null
        titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
        descriptionT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
        logo: {
          __typename?: 'Logo'
          coloredImage?: {
            __typename?: 'Media'
            id: string
            createdAt: Date
            updatedAt: Date
            title: string
            type: MediaType
            publicationStatus: PublicationStatus
            tags: Array<MediaTags>
            date?: Date | null
            startTrim?: number | null
            endTrim?: number | null
            liveStreamId?: string | null
            videoClipId?: string | null
            imageId?: string | null
            thumbnailId?: string | null
            ownerOrganismId?: string | null
            pinned?: boolean | null
            liveStream?: {
              __typename?: 'LiveStream'
              id: string
              playbackUrl: string
              ingestUrl?: string | null
              wildmokaEventID?: string | null
              videoEngine: VideoEngine
              state: LiveStreamState
              streamId?: string | null
              sveBroadcastId?: string | null
              sveChannelId?: string | null
              externalFeedProvider?: ExternalFeedType | null
              externalFeedUrl?: string | null
              event?: {
                __typename?: 'WMEvent'
                id: string
                name: string
                description?: string | null
                testStartDate: Date
                editoStartDate: Date
                editoStopDate: Date
                stopDate: Date
                state: WmEventState
                notifEmail?: string | null
                clipCreationAtEnd: boolean
                clipPublicationAtEnd: boolean
                livePublication: boolean
                stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
                live?: {
                  __typename?: 'WMEventLive'
                  id: string
                  publishStatus: WmLiveState
                  type: WmLiveType
                  autoAnnounceDate?: Date | null
                  template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
                } | null
                clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
              } | null
              sveBroadcast?: {
                __typename?: 'SVEBroadcast'
                id: string
                status: SveBroadcastState
                channelId: string
                startDate?: Date | null
                clipStartDate?: Date | null
                stopDate?: Date | null
                scheduledStartDate: Date
                scheduledClipStartDate: Date
                scheduledStopDate: Date
              } | null
              sveLiveMonitoring?: {
                __typename?: 'SVELiveMonitoring'
                alerts?: {
                  __typename?: 'SVELiveMonitoringAlerts'
                  audioNotDetected?: string | null
                  initialProbeTakingLonger?: string | null
                  videoNotDetected?: string | null
                  RTMPHasNoAudioVideo?: string | null
                  RTMPStreamNotFound?: string | null
                } | null
                stats?: {
                  __typename?: 'SVELiveMonitoringStats'
                  networkIn?: string | null
                  droppedFrames?: string | null
                  inputVideoFrameRate?: string | null
                  outputAudioLevelLkfs?: string | null
                } | null
              } | null
            } | null
            videoClip?: {
              __typename?: 'VideoClip'
              id: string
              playbackUrl: string
              downloadLink: string
              status?: VideoClipStatus | null
              updatedAt: Date
              createdAt: Date
              fileId?: string | null
              error?: {
                __typename?: 'VideoClipError'
                guid: string
                workflowStatus: string
                workflowErrorAt: string
                errorMessage: string
                errorDetails: string
              } | null
              captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
              externalFeed?: {
                __typename?: 'ExternalFeed'
                externalFeedProvider: ExternalFeedType
                externalFeedUrl: string
              } | null
            } | null
            image?: {
              __typename?: 'File'
              id: string
              filename: string
              size: number
              mimetype: string
              downloadUrl: string
              thumbUrl?: string | null
              createdAt: Date
              metadata?: {
                __typename?: 'FileMetadata'
                title?: string | null
                description?: string | null
                aspect?: number | null
                width?: number | null
                height?: number | null
                sourceFileId?: string | null
              } | null
            } | null
            thumbnail?: {
              __typename?: 'Media'
              id: string
              image?: {
                __typename?: 'File'
                id: string
                filename: string
                size: number
                mimetype: string
                downloadUrl: string
                thumbUrl?: string | null
                createdAt: Date
                metadata?: {
                  __typename?: 'FileMetadata'
                  title?: string | null
                  description?: string | null
                  aspect?: number | null
                  width?: number | null
                  height?: number | null
                  sourceFileId?: string | null
                } | null
              } | null
            } | null
            timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
          } | null
          transparentDarkImage?: {
            __typename?: 'Media'
            id: string
            createdAt: Date
            updatedAt: Date
            title: string
            type: MediaType
            publicationStatus: PublicationStatus
            tags: Array<MediaTags>
            date?: Date | null
            startTrim?: number | null
            endTrim?: number | null
            liveStreamId?: string | null
            videoClipId?: string | null
            imageId?: string | null
            thumbnailId?: string | null
            ownerOrganismId?: string | null
            pinned?: boolean | null
            liveStream?: {
              __typename?: 'LiveStream'
              id: string
              playbackUrl: string
              ingestUrl?: string | null
              wildmokaEventID?: string | null
              videoEngine: VideoEngine
              state: LiveStreamState
              streamId?: string | null
              sveBroadcastId?: string | null
              sveChannelId?: string | null
              externalFeedProvider?: ExternalFeedType | null
              externalFeedUrl?: string | null
              event?: {
                __typename?: 'WMEvent'
                id: string
                name: string
                description?: string | null
                testStartDate: Date
                editoStartDate: Date
                editoStopDate: Date
                stopDate: Date
                state: WmEventState
                notifEmail?: string | null
                clipCreationAtEnd: boolean
                clipPublicationAtEnd: boolean
                livePublication: boolean
                stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
                live?: {
                  __typename?: 'WMEventLive'
                  id: string
                  publishStatus: WmLiveState
                  type: WmLiveType
                  autoAnnounceDate?: Date | null
                  template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
                } | null
                clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
              } | null
              sveBroadcast?: {
                __typename?: 'SVEBroadcast'
                id: string
                status: SveBroadcastState
                channelId: string
                startDate?: Date | null
                clipStartDate?: Date | null
                stopDate?: Date | null
                scheduledStartDate: Date
                scheduledClipStartDate: Date
                scheduledStopDate: Date
              } | null
              sveLiveMonitoring?: {
                __typename?: 'SVELiveMonitoring'
                alerts?: {
                  __typename?: 'SVELiveMonitoringAlerts'
                  audioNotDetected?: string | null
                  initialProbeTakingLonger?: string | null
                  videoNotDetected?: string | null
                  RTMPHasNoAudioVideo?: string | null
                  RTMPStreamNotFound?: string | null
                } | null
                stats?: {
                  __typename?: 'SVELiveMonitoringStats'
                  networkIn?: string | null
                  droppedFrames?: string | null
                  inputVideoFrameRate?: string | null
                  outputAudioLevelLkfs?: string | null
                } | null
              } | null
            } | null
            videoClip?: {
              __typename?: 'VideoClip'
              id: string
              playbackUrl: string
              downloadLink: string
              status?: VideoClipStatus | null
              updatedAt: Date
              createdAt: Date
              fileId?: string | null
              error?: {
                __typename?: 'VideoClipError'
                guid: string
                workflowStatus: string
                workflowErrorAt: string
                errorMessage: string
                errorDetails: string
              } | null
              captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
              externalFeed?: {
                __typename?: 'ExternalFeed'
                externalFeedProvider: ExternalFeedType
                externalFeedUrl: string
              } | null
            } | null
            image?: {
              __typename?: 'File'
              id: string
              filename: string
              size: number
              mimetype: string
              downloadUrl: string
              thumbUrl?: string | null
              createdAt: Date
              metadata?: {
                __typename?: 'FileMetadata'
                title?: string | null
                description?: string | null
                aspect?: number | null
                width?: number | null
                height?: number | null
                sourceFileId?: string | null
              } | null
            } | null
            thumbnail?: {
              __typename?: 'Media'
              id: string
              image?: {
                __typename?: 'File'
                id: string
                filename: string
                size: number
                mimetype: string
                downloadUrl: string
                thumbUrl?: string | null
                createdAt: Date
                metadata?: {
                  __typename?: 'FileMetadata'
                  title?: string | null
                  description?: string | null
                  aspect?: number | null
                  width?: number | null
                  height?: number | null
                  sourceFileId?: string | null
                } | null
              } | null
            } | null
            timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
          } | null
          transparentLightImage?: {
            __typename?: 'Media'
            id: string
            createdAt: Date
            updatedAt: Date
            title: string
            type: MediaType
            publicationStatus: PublicationStatus
            tags: Array<MediaTags>
            date?: Date | null
            startTrim?: number | null
            endTrim?: number | null
            liveStreamId?: string | null
            videoClipId?: string | null
            imageId?: string | null
            thumbnailId?: string | null
            ownerOrganismId?: string | null
            pinned?: boolean | null
            liveStream?: {
              __typename?: 'LiveStream'
              id: string
              playbackUrl: string
              ingestUrl?: string | null
              wildmokaEventID?: string | null
              videoEngine: VideoEngine
              state: LiveStreamState
              streamId?: string | null
              sveBroadcastId?: string | null
              sveChannelId?: string | null
              externalFeedProvider?: ExternalFeedType | null
              externalFeedUrl?: string | null
              event?: {
                __typename?: 'WMEvent'
                id: string
                name: string
                description?: string | null
                testStartDate: Date
                editoStartDate: Date
                editoStopDate: Date
                stopDate: Date
                state: WmEventState
                notifEmail?: string | null
                clipCreationAtEnd: boolean
                clipPublicationAtEnd: boolean
                livePublication: boolean
                stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
                live?: {
                  __typename?: 'WMEventLive'
                  id: string
                  publishStatus: WmLiveState
                  type: WmLiveType
                  autoAnnounceDate?: Date | null
                  template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
                } | null
                clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
              } | null
              sveBroadcast?: {
                __typename?: 'SVEBroadcast'
                id: string
                status: SveBroadcastState
                channelId: string
                startDate?: Date | null
                clipStartDate?: Date | null
                stopDate?: Date | null
                scheduledStartDate: Date
                scheduledClipStartDate: Date
                scheduledStopDate: Date
              } | null
              sveLiveMonitoring?: {
                __typename?: 'SVELiveMonitoring'
                alerts?: {
                  __typename?: 'SVELiveMonitoringAlerts'
                  audioNotDetected?: string | null
                  initialProbeTakingLonger?: string | null
                  videoNotDetected?: string | null
                  RTMPHasNoAudioVideo?: string | null
                  RTMPStreamNotFound?: string | null
                } | null
                stats?: {
                  __typename?: 'SVELiveMonitoringStats'
                  networkIn?: string | null
                  droppedFrames?: string | null
                  inputVideoFrameRate?: string | null
                  outputAudioLevelLkfs?: string | null
                } | null
              } | null
            } | null
            videoClip?: {
              __typename?: 'VideoClip'
              id: string
              playbackUrl: string
              downloadLink: string
              status?: VideoClipStatus | null
              updatedAt: Date
              createdAt: Date
              fileId?: string | null
              error?: {
                __typename?: 'VideoClipError'
                guid: string
                workflowStatus: string
                workflowErrorAt: string
                errorMessage: string
                errorDetails: string
              } | null
              captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
              externalFeed?: {
                __typename?: 'ExternalFeed'
                externalFeedProvider: ExternalFeedType
                externalFeedUrl: string
              } | null
            } | null
            image?: {
              __typename?: 'File'
              id: string
              filename: string
              size: number
              mimetype: string
              downloadUrl: string
              thumbUrl?: string | null
              createdAt: Date
              metadata?: {
                __typename?: 'FileMetadata'
                title?: string | null
                description?: string | null
                aspect?: number | null
                width?: number | null
                height?: number | null
                sourceFileId?: string | null
              } | null
            } | null
            thumbnail?: {
              __typename?: 'Media'
              id: string
              image?: {
                __typename?: 'File'
                id: string
                filename: string
                size: number
                mimetype: string
                downloadUrl: string
                thumbUrl?: string | null
                createdAt: Date
                metadata?: {
                  __typename?: 'FileMetadata'
                  title?: string | null
                  description?: string | null
                  aspect?: number | null
                  width?: number | null
                  height?: number | null
                  sourceFileId?: string | null
                } | null
              } | null
            } | null
            timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
          } | null
        }
        sports: Array<{ __typename?: 'Sport'; id: string }>
        bannerImage?: {
          __typename?: 'Media'
          id: string
          createdAt: Date
          updatedAt: Date
          title: string
          type: MediaType
          publicationStatus: PublicationStatus
          tags: Array<MediaTags>
          date?: Date | null
          startTrim?: number | null
          endTrim?: number | null
          liveStreamId?: string | null
          videoClipId?: string | null
          imageId?: string | null
          thumbnailId?: string | null
          ownerOrganismId?: string | null
          pinned?: boolean | null
          liveStream?: {
            __typename?: 'LiveStream'
            id: string
            playbackUrl: string
            ingestUrl?: string | null
            wildmokaEventID?: string | null
            videoEngine: VideoEngine
            state: LiveStreamState
            streamId?: string | null
            sveBroadcastId?: string | null
            sveChannelId?: string | null
            externalFeedProvider?: ExternalFeedType | null
            externalFeedUrl?: string | null
            event?: {
              __typename?: 'WMEvent'
              id: string
              name: string
              description?: string | null
              testStartDate: Date
              editoStartDate: Date
              editoStopDate: Date
              stopDate: Date
              state: WmEventState
              notifEmail?: string | null
              clipCreationAtEnd: boolean
              clipPublicationAtEnd: boolean
              livePublication: boolean
              stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
              live?: {
                __typename?: 'WMEventLive'
                id: string
                publishStatus: WmLiveState
                type: WmLiveType
                autoAnnounceDate?: Date | null
                template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
              } | null
              clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
            } | null
            sveBroadcast?: {
              __typename?: 'SVEBroadcast'
              id: string
              status: SveBroadcastState
              channelId: string
              startDate?: Date | null
              clipStartDate?: Date | null
              stopDate?: Date | null
              scheduledStartDate: Date
              scheduledClipStartDate: Date
              scheduledStopDate: Date
            } | null
            sveLiveMonitoring?: {
              __typename?: 'SVELiveMonitoring'
              alerts?: {
                __typename?: 'SVELiveMonitoringAlerts'
                audioNotDetected?: string | null
                initialProbeTakingLonger?: string | null
                videoNotDetected?: string | null
                RTMPHasNoAudioVideo?: string | null
                RTMPStreamNotFound?: string | null
              } | null
              stats?: {
                __typename?: 'SVELiveMonitoringStats'
                networkIn?: string | null
                droppedFrames?: string | null
                inputVideoFrameRate?: string | null
                outputAudioLevelLkfs?: string | null
              } | null
            } | null
          } | null
          videoClip?: {
            __typename?: 'VideoClip'
            id: string
            playbackUrl: string
            downloadLink: string
            status?: VideoClipStatus | null
            updatedAt: Date
            createdAt: Date
            fileId?: string | null
            error?: {
              __typename?: 'VideoClipError'
              guid: string
              workflowStatus: string
              workflowErrorAt: string
              errorMessage: string
              errorDetails: string
            } | null
            captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
            externalFeed?: {
              __typename?: 'ExternalFeed'
              externalFeedProvider: ExternalFeedType
              externalFeedUrl: string
            } | null
          } | null
          image?: {
            __typename?: 'File'
            id: string
            filename: string
            size: number
            mimetype: string
            downloadUrl: string
            thumbUrl?: string | null
            createdAt: Date
            metadata?: {
              __typename?: 'FileMetadata'
              title?: string | null
              description?: string | null
              aspect?: number | null
              width?: number | null
              height?: number | null
              sourceFileId?: string | null
            } | null
          } | null
          thumbnail?: {
            __typename?: 'Media'
            id: string
            image?: {
              __typename?: 'File'
              id: string
              filename: string
              size: number
              mimetype: string
              downloadUrl: string
              thumbUrl?: string | null
              createdAt: Date
              metadata?: {
                __typename?: 'FileMetadata'
                title?: string | null
                description?: string | null
                aspect?: number | null
                width?: number | null
                height?: number | null
                sourceFileId?: string | null
              } | null
            } | null
          } | null
          timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
        } | null
        adminBadge?: {
          __typename?: 'Media'
          id: string
          createdAt: Date
          updatedAt: Date
          title: string
          type: MediaType
          publicationStatus: PublicationStatus
          tags: Array<MediaTags>
          date?: Date | null
          startTrim?: number | null
          endTrim?: number | null
          liveStreamId?: string | null
          videoClipId?: string | null
          imageId?: string | null
          thumbnailId?: string | null
          ownerOrganismId?: string | null
          pinned?: boolean | null
          liveStream?: {
            __typename?: 'LiveStream'
            id: string
            playbackUrl: string
            ingestUrl?: string | null
            wildmokaEventID?: string | null
            videoEngine: VideoEngine
            state: LiveStreamState
            streamId?: string | null
            sveBroadcastId?: string | null
            sveChannelId?: string | null
            externalFeedProvider?: ExternalFeedType | null
            externalFeedUrl?: string | null
            event?: {
              __typename?: 'WMEvent'
              id: string
              name: string
              description?: string | null
              testStartDate: Date
              editoStartDate: Date
              editoStopDate: Date
              stopDate: Date
              state: WmEventState
              notifEmail?: string | null
              clipCreationAtEnd: boolean
              clipPublicationAtEnd: boolean
              livePublication: boolean
              stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
              live?: {
                __typename?: 'WMEventLive'
                id: string
                publishStatus: WmLiveState
                type: WmLiveType
                autoAnnounceDate?: Date | null
                template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
              } | null
              clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
            } | null
            sveBroadcast?: {
              __typename?: 'SVEBroadcast'
              id: string
              status: SveBroadcastState
              channelId: string
              startDate?: Date | null
              clipStartDate?: Date | null
              stopDate?: Date | null
              scheduledStartDate: Date
              scheduledClipStartDate: Date
              scheduledStopDate: Date
            } | null
            sveLiveMonitoring?: {
              __typename?: 'SVELiveMonitoring'
              alerts?: {
                __typename?: 'SVELiveMonitoringAlerts'
                audioNotDetected?: string | null
                initialProbeTakingLonger?: string | null
                videoNotDetected?: string | null
                RTMPHasNoAudioVideo?: string | null
                RTMPStreamNotFound?: string | null
              } | null
              stats?: {
                __typename?: 'SVELiveMonitoringStats'
                networkIn?: string | null
                droppedFrames?: string | null
                inputVideoFrameRate?: string | null
                outputAudioLevelLkfs?: string | null
              } | null
            } | null
          } | null
          videoClip?: {
            __typename?: 'VideoClip'
            id: string
            playbackUrl: string
            downloadLink: string
            status?: VideoClipStatus | null
            updatedAt: Date
            createdAt: Date
            fileId?: string | null
            error?: {
              __typename?: 'VideoClipError'
              guid: string
              workflowStatus: string
              workflowErrorAt: string
              errorMessage: string
              errorDetails: string
            } | null
            captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
            externalFeed?: {
              __typename?: 'ExternalFeed'
              externalFeedProvider: ExternalFeedType
              externalFeedUrl: string
            } | null
          } | null
          image?: {
            __typename?: 'File'
            id: string
            filename: string
            size: number
            mimetype: string
            downloadUrl: string
            thumbUrl?: string | null
            createdAt: Date
            metadata?: {
              __typename?: 'FileMetadata'
              title?: string | null
              description?: string | null
              aspect?: number | null
              width?: number | null
              height?: number | null
              sourceFileId?: string | null
            } | null
          } | null
          thumbnail?: {
            __typename?: 'Media'
            id: string
            image?: {
              __typename?: 'File'
              id: string
              filename: string
              size: number
              mimetype: string
              downloadUrl: string
              thumbUrl?: string | null
              createdAt: Date
              metadata?: {
                __typename?: 'FileMetadata'
                title?: string | null
                description?: string | null
                aspect?: number | null
                width?: number | null
                height?: number | null
                sourceFileId?: string | null
              } | null
            } | null
          } | null
          timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
        } | null
        bannerVideo?: {
          __typename?: 'Media'
          id: string
          videoClip?: { __typename?: 'VideoClip'; id: string; playbackUrl: string } | null
        } | null
        pageWidgets?: Array<{
          __typename?: 'CustomPageWidget'
          name: CustomPageWidgetName
          position: number
          display?: CustomPageDisplayTypes | null
          contentDisplay?: ContentSetContentDisplay | null
          cardsDisplay?: CardsDisplay | null
          options?: {
            __typename?: 'CustomPageWidgetOptions'
            editedCategoryId?: string | null
            sportItemSetType?: SportItemSetType | null
            replays?: {
              __typename?: 'ReplaysWidget'
              defaultLocale: string
              maxReplays?: number | null
              depthInDays?: number | null
              nameT: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }>
            } | null
            externalContent?: {
              __typename?: 'ExternalContentWidget'
              name?: string | null
              defaultLocale: string
              maxItemsCount: number
              source: WidgetExternalContentSource
              nameT: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }>
              period?: { __typename?: 'StartEndDates'; startDate: Date; endDate: Date } | null
              wecContent?: {
                __typename?: 'WECNewsContent'
                teamECMIds?: Array<string> | null
                driverECMIds?: Array<string> | null
                carECMIds?: Array<string> | null
                categoryECMIds?: Array<string> | null
                raceECMIds?: Array<string> | null
              } | null
              rssContent?: { __typename?: 'RSSFeedContent'; feedUrl: string } | null
            } | null
          } | null
        }> | null
        offers: Array<{
          __typename?: 'PurchasableOffer'
          id: string
          mode: OfferMode
          delay?: number | null
          expiredAt?: Date | null
          publicationStatus: PublicationStatus
          title: string
          defaultLocale?: string | null
          url?: string | null
          bannerImageId?: string | null
          thumbnailImageId?: string | null
          freeWithLicense: boolean
          position?: number | null
          titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
          description: Array<{ __typename?: 'OfferDescription'; header: string; content: string }>
          descriptionT?: Array<{
            __typename?: 'ContentTranslationDescriptionPurchasableOffer'
            locale: string
            content?: Array<{ __typename?: 'OfferDescription'; header: string; content: string }> | null
          }> | null
          products: Array<{
            __typename?: 'Product'
            productId: string
            platform: Platform
            period?: Period | null
            publicationStatus: PublicationStatus
            purchasableOfferId?: string | null
            licensedUserOnly?: boolean | null
            premiumOf?: Array<string> | null
            prices: Array<{ __typename?: 'Price'; value: number; currency: string }>
          }>
        }>
        license?: { __typename?: 'OrganismLicense'; enabled: boolean; api?: LicenseApi | null } | null
        tags?: Array<{ __typename?: 'Tag'; id: string; title: string; subTags?: Array<string> | null }> | null
      }> | null
      titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
      subtitleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
      descriptionT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
      billboard?: {
        __typename?: 'Media'
        id: string
        videoClip?: { __typename?: 'VideoClip'; id: string; playbackUrl: string } | null
      } | null
      teaserVideo?: {
        __typename?: 'Media'
        id: string
        videoClip?: { __typename?: 'VideoClip'; id: string; playbackUrl: string } | null
      } | null
      image?: {
        __typename?: 'Media'
        id: string
        image?: { __typename?: 'File'; id: string; thumbUrl?: string | null; downloadUrl: string } | null
      } | null
      categories: Array<{ __typename?: 'Category'; id: string; title: string }>
    } | null
  } | null
  items?: Array<{ __typename?: 'SportItem'; id: string; title: string }> | null
  bannerImage?: {
    __typename?: 'Media'
    id: string
    image?: { __typename?: 'File'; id: string; thumbUrl?: string | null; downloadUrl: string } | null
  } | null
  bannerVideo?: {
    __typename?: 'Media'
    id: string
    videoClip?: { __typename?: 'VideoClip'; id: string; playbackUrl: string } | null
  } | null
  sportEvent?: {
    __typename?: 'SportEvent'
    startDate: Date
    endDate: Date
    state: SportEventState
    liveTemplateId?: string | null
    backstageTemplateId?: string | null
    clipTemplateId?: string | null
  } | null
  categories: Array<{ __typename?: 'Category'; id: string; title: string }>
  legalRestrictions: Array<{
    __typename?: 'LegalRestriction'
    id: string
    description?: string | null
    countries: Array<string>
    type: RestrictionType
    timeSlots?: { __typename?: 'TimeSlots'; from: string; to: string } | null
  }>
  cac?: {
    __typename?: 'ContentAccessControl'
    userAuthenticationRule?: CacUserAuthenticationRule | null
    userLocationRule?: {
      __typename?: 'CACUserLocationRule'
      distance: number
      condition: UserLocationCondition
      location: { __typename?: 'GeoLocation'; latitude: number; longitude: number }
    } | null
  } | null
  streams?: Array<{ __typename?: 'WMStream'; id: string; name: string }> | null
  sveStreams: Array<{
    __typename?: 'SVEStream'
    sveChannelId: string
    socialNetworkWMStreamId?: string | null
    outputStreamId?: string | null
  }>
  tag?: { __typename?: 'Tag'; id: string; title: string } | null
  contentPushServiceGenericStreams?: Array<{
    __typename?: 'ContentPushStream'
    id: string
    name: string
    createdAt: Date
    updatedAt: Date
  }> | null
  noSpoilerMode?: { __typename?: 'NoSpoilerMode'; enabled: boolean; minutesBeforeDisabling: number } | null
}

export type LightSportItemSetContentFragment = {
  __typename?: 'SportItemSet'
  id: string
  sportIds: Array<string>
  type: SportItemSetType
  title: string
  subtitle?: string | null
  publicationStatus: PublicationStatus
  ownerOrganismId?: string | null
  restrictionLevel: PremiumAccess
  websiteUrl?: string | null
  bannerImageFileId?: string | null
  bannerVideoId?: string | null
  streamsIds?: Array<string> | null
  contentPushServiceNames?: Array<ContentPushStreamServiceName> | null
  sports: Array<{ __typename?: 'Sport'; id: string; title: string; color: string }>
  tabs?: Array<{
    __typename?: 'Tab'
    title: string
    url: string
    position: TabPosition
    blank?: boolean | null
    titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    icon: { __typename?: 'Icon'; title: string; svg: string }
  }> | null
  bannerImage?: {
    __typename?: 'Media'
    id: string
    image?: { __typename?: 'File'; id: string; thumbUrl?: string | null; downloadUrl: string } | null
  } | null
  bannerVideo?: {
    __typename?: 'Media'
    id: string
    videoClip?: { __typename?: 'VideoClip'; id: string; playbackUrl: string } | null
  } | null
  sportEvent?: { __typename?: 'SportEvent'; startDate: Date; endDate: Date; state: SportEventState } | null
  legalRestrictions: Array<{
    __typename?: 'LegalRestriction'
    id: string
    description?: string | null
    countries: Array<string>
    type: RestrictionType
    timeSlots?: { __typename?: 'TimeSlots'; from: string; to: string } | null
  }>
}

export type SportItemSetWithOrganismContentFragment = {
  __typename: 'SportItemSet'
  id: string
  sportIds: Array<string>
  type: SportItemSetType
  title: string
  subtitle?: string | null
  description?: string | null
  defaultLocale?: string | null
  publicationStatus: PublicationStatus
  ownerOrganismId?: string | null
  offerIds: Array<string>
  restrictionLevel: PremiumAccess
  websiteUrl?: string | null
  lockPublishingLive?: boolean | null
  lockPublishingClip?: boolean | null
  bannerImageFileId?: string | null
  bannerVideoId?: string | null
  testPeriodEnd?: Date | null
  categoriesIds: Array<string>
  videoEngine: VideoEngine
  streamsIds?: Array<string> | null
  wmClippingTemplateId?: string | null
  sveInputLossImageMediaId?: string | null
  sveOverlayLogoImageMediaId?: string | null
  visibleInMultisportApplication: boolean
  activateSportItemChatByDefault: boolean
  contentPushServiceNames?: Array<ContentPushStreamServiceName> | null
  subTags?: Array<string> | null
  activateGreenPlayback?: boolean | null
  adCampaignId?: string | null
  contentPushServiceGenericStreamIds?: Array<string> | null
  contentPushServiceLiveEventIds?: Array<string> | null
  disableReadParallelLivestreamsLimit?: boolean | null
  ownerOrganism?: {
    __typename?: 'Organism'
    title: string
    id: string
    type: OrganismType
    whiteLabel: boolean
    description?: string | null
    publicationStatus: PublicationStatus
    defaultLocale?: string | null
    onFront: boolean
    frontPosition?: number | null
    officialWebSiteUrl: string
    sportIds: Array<string>
    forbiddenKeyWords?: Array<string> | null
    enableExternalSources?: boolean | null
    availableExternalSources?: Array<WidgetExternalContentSource> | null
    streamsIds?: Array<string> | null
    wmProfileId?: string | null
    sveChannelIds?: Array<string> | null
    organismPageApplicationDesignId?: string | null
    contentPageApplicationDesignId?: string | null
    adminsAutomatedEmails?: boolean | null
    visibleInMultisportApplication?: boolean | null
    titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    descriptionT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    logo: {
      __typename?: 'Logo'
      coloredImage?: {
        __typename?: 'Media'
        id: string
        createdAt: Date
        updatedAt: Date
        title: string
        type: MediaType
        publicationStatus: PublicationStatus
        tags: Array<MediaTags>
        date?: Date | null
        startTrim?: number | null
        endTrim?: number | null
        liveStreamId?: string | null
        videoClipId?: string | null
        imageId?: string | null
        thumbnailId?: string | null
        ownerOrganismId?: string | null
        pinned?: boolean | null
        liveStream?: {
          __typename?: 'LiveStream'
          id: string
          playbackUrl: string
          ingestUrl?: string | null
          wildmokaEventID?: string | null
          videoEngine: VideoEngine
          state: LiveStreamState
          streamId?: string | null
          sveBroadcastId?: string | null
          sveChannelId?: string | null
          externalFeedProvider?: ExternalFeedType | null
          externalFeedUrl?: string | null
          event?: {
            __typename?: 'WMEvent'
            id: string
            name: string
            description?: string | null
            testStartDate: Date
            editoStartDate: Date
            editoStopDate: Date
            stopDate: Date
            state: WmEventState
            notifEmail?: string | null
            clipCreationAtEnd: boolean
            clipPublicationAtEnd: boolean
            livePublication: boolean
            stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
            live?: {
              __typename?: 'WMEventLive'
              id: string
              publishStatus: WmLiveState
              type: WmLiveType
              autoAnnounceDate?: Date | null
              template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
            } | null
            clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
          } | null
          sveBroadcast?: {
            __typename?: 'SVEBroadcast'
            id: string
            status: SveBroadcastState
            channelId: string
            startDate?: Date | null
            clipStartDate?: Date | null
            stopDate?: Date | null
            scheduledStartDate: Date
            scheduledClipStartDate: Date
            scheduledStopDate: Date
          } | null
          sveLiveMonitoring?: {
            __typename?: 'SVELiveMonitoring'
            alerts?: {
              __typename?: 'SVELiveMonitoringAlerts'
              audioNotDetected?: string | null
              initialProbeTakingLonger?: string | null
              videoNotDetected?: string | null
              RTMPHasNoAudioVideo?: string | null
              RTMPStreamNotFound?: string | null
            } | null
            stats?: {
              __typename?: 'SVELiveMonitoringStats'
              networkIn?: string | null
              droppedFrames?: string | null
              inputVideoFrameRate?: string | null
              outputAudioLevelLkfs?: string | null
            } | null
          } | null
        } | null
        videoClip?: {
          __typename?: 'VideoClip'
          id: string
          playbackUrl: string
          downloadLink: string
          status?: VideoClipStatus | null
          updatedAt: Date
          createdAt: Date
          fileId?: string | null
          error?: {
            __typename?: 'VideoClipError'
            guid: string
            workflowStatus: string
            workflowErrorAt: string
            errorMessage: string
            errorDetails: string
          } | null
          captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
          externalFeed?: {
            __typename?: 'ExternalFeed'
            externalFeedProvider: ExternalFeedType
            externalFeedUrl: string
          } | null
        } | null
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
        thumbnail?: {
          __typename?: 'Media'
          id: string
          image?: {
            __typename?: 'File'
            id: string
            filename: string
            size: number
            mimetype: string
            downloadUrl: string
            thumbUrl?: string | null
            createdAt: Date
            metadata?: {
              __typename?: 'FileMetadata'
              title?: string | null
              description?: string | null
              aspect?: number | null
              width?: number | null
              height?: number | null
              sourceFileId?: string | null
            } | null
          } | null
        } | null
        timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
      } | null
      transparentDarkImage?: {
        __typename?: 'Media'
        id: string
        createdAt: Date
        updatedAt: Date
        title: string
        type: MediaType
        publicationStatus: PublicationStatus
        tags: Array<MediaTags>
        date?: Date | null
        startTrim?: number | null
        endTrim?: number | null
        liveStreamId?: string | null
        videoClipId?: string | null
        imageId?: string | null
        thumbnailId?: string | null
        ownerOrganismId?: string | null
        pinned?: boolean | null
        liveStream?: {
          __typename?: 'LiveStream'
          id: string
          playbackUrl: string
          ingestUrl?: string | null
          wildmokaEventID?: string | null
          videoEngine: VideoEngine
          state: LiveStreamState
          streamId?: string | null
          sveBroadcastId?: string | null
          sveChannelId?: string | null
          externalFeedProvider?: ExternalFeedType | null
          externalFeedUrl?: string | null
          event?: {
            __typename?: 'WMEvent'
            id: string
            name: string
            description?: string | null
            testStartDate: Date
            editoStartDate: Date
            editoStopDate: Date
            stopDate: Date
            state: WmEventState
            notifEmail?: string | null
            clipCreationAtEnd: boolean
            clipPublicationAtEnd: boolean
            livePublication: boolean
            stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
            live?: {
              __typename?: 'WMEventLive'
              id: string
              publishStatus: WmLiveState
              type: WmLiveType
              autoAnnounceDate?: Date | null
              template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
            } | null
            clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
          } | null
          sveBroadcast?: {
            __typename?: 'SVEBroadcast'
            id: string
            status: SveBroadcastState
            channelId: string
            startDate?: Date | null
            clipStartDate?: Date | null
            stopDate?: Date | null
            scheduledStartDate: Date
            scheduledClipStartDate: Date
            scheduledStopDate: Date
          } | null
          sveLiveMonitoring?: {
            __typename?: 'SVELiveMonitoring'
            alerts?: {
              __typename?: 'SVELiveMonitoringAlerts'
              audioNotDetected?: string | null
              initialProbeTakingLonger?: string | null
              videoNotDetected?: string | null
              RTMPHasNoAudioVideo?: string | null
              RTMPStreamNotFound?: string | null
            } | null
            stats?: {
              __typename?: 'SVELiveMonitoringStats'
              networkIn?: string | null
              droppedFrames?: string | null
              inputVideoFrameRate?: string | null
              outputAudioLevelLkfs?: string | null
            } | null
          } | null
        } | null
        videoClip?: {
          __typename?: 'VideoClip'
          id: string
          playbackUrl: string
          downloadLink: string
          status?: VideoClipStatus | null
          updatedAt: Date
          createdAt: Date
          fileId?: string | null
          error?: {
            __typename?: 'VideoClipError'
            guid: string
            workflowStatus: string
            workflowErrorAt: string
            errorMessage: string
            errorDetails: string
          } | null
          captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
          externalFeed?: {
            __typename?: 'ExternalFeed'
            externalFeedProvider: ExternalFeedType
            externalFeedUrl: string
          } | null
        } | null
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
        thumbnail?: {
          __typename?: 'Media'
          id: string
          image?: {
            __typename?: 'File'
            id: string
            filename: string
            size: number
            mimetype: string
            downloadUrl: string
            thumbUrl?: string | null
            createdAt: Date
            metadata?: {
              __typename?: 'FileMetadata'
              title?: string | null
              description?: string | null
              aspect?: number | null
              width?: number | null
              height?: number | null
              sourceFileId?: string | null
            } | null
          } | null
        } | null
        timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
      } | null
      transparentLightImage?: {
        __typename?: 'Media'
        id: string
        createdAt: Date
        updatedAt: Date
        title: string
        type: MediaType
        publicationStatus: PublicationStatus
        tags: Array<MediaTags>
        date?: Date | null
        startTrim?: number | null
        endTrim?: number | null
        liveStreamId?: string | null
        videoClipId?: string | null
        imageId?: string | null
        thumbnailId?: string | null
        ownerOrganismId?: string | null
        pinned?: boolean | null
        liveStream?: {
          __typename?: 'LiveStream'
          id: string
          playbackUrl: string
          ingestUrl?: string | null
          wildmokaEventID?: string | null
          videoEngine: VideoEngine
          state: LiveStreamState
          streamId?: string | null
          sveBroadcastId?: string | null
          sveChannelId?: string | null
          externalFeedProvider?: ExternalFeedType | null
          externalFeedUrl?: string | null
          event?: {
            __typename?: 'WMEvent'
            id: string
            name: string
            description?: string | null
            testStartDate: Date
            editoStartDate: Date
            editoStopDate: Date
            stopDate: Date
            state: WmEventState
            notifEmail?: string | null
            clipCreationAtEnd: boolean
            clipPublicationAtEnd: boolean
            livePublication: boolean
            stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
            live?: {
              __typename?: 'WMEventLive'
              id: string
              publishStatus: WmLiveState
              type: WmLiveType
              autoAnnounceDate?: Date | null
              template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
            } | null
            clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
          } | null
          sveBroadcast?: {
            __typename?: 'SVEBroadcast'
            id: string
            status: SveBroadcastState
            channelId: string
            startDate?: Date | null
            clipStartDate?: Date | null
            stopDate?: Date | null
            scheduledStartDate: Date
            scheduledClipStartDate: Date
            scheduledStopDate: Date
          } | null
          sveLiveMonitoring?: {
            __typename?: 'SVELiveMonitoring'
            alerts?: {
              __typename?: 'SVELiveMonitoringAlerts'
              audioNotDetected?: string | null
              initialProbeTakingLonger?: string | null
              videoNotDetected?: string | null
              RTMPHasNoAudioVideo?: string | null
              RTMPStreamNotFound?: string | null
            } | null
            stats?: {
              __typename?: 'SVELiveMonitoringStats'
              networkIn?: string | null
              droppedFrames?: string | null
              inputVideoFrameRate?: string | null
              outputAudioLevelLkfs?: string | null
            } | null
          } | null
        } | null
        videoClip?: {
          __typename?: 'VideoClip'
          id: string
          playbackUrl: string
          downloadLink: string
          status?: VideoClipStatus | null
          updatedAt: Date
          createdAt: Date
          fileId?: string | null
          error?: {
            __typename?: 'VideoClipError'
            guid: string
            workflowStatus: string
            workflowErrorAt: string
            errorMessage: string
            errorDetails: string
          } | null
          captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
          externalFeed?: {
            __typename?: 'ExternalFeed'
            externalFeedProvider: ExternalFeedType
            externalFeedUrl: string
          } | null
        } | null
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
        thumbnail?: {
          __typename?: 'Media'
          id: string
          image?: {
            __typename?: 'File'
            id: string
            filename: string
            size: number
            mimetype: string
            downloadUrl: string
            thumbUrl?: string | null
            createdAt: Date
            metadata?: {
              __typename?: 'FileMetadata'
              title?: string | null
              description?: string | null
              aspect?: number | null
              width?: number | null
              height?: number | null
              sourceFileId?: string | null
            } | null
          } | null
        } | null
        timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
      } | null
    }
    sports: Array<{ __typename?: 'Sport'; id: string }>
    bannerImage?: {
      __typename?: 'Media'
      id: string
      createdAt: Date
      updatedAt: Date
      title: string
      type: MediaType
      publicationStatus: PublicationStatus
      tags: Array<MediaTags>
      date?: Date | null
      startTrim?: number | null
      endTrim?: number | null
      liveStreamId?: string | null
      videoClipId?: string | null
      imageId?: string | null
      thumbnailId?: string | null
      ownerOrganismId?: string | null
      pinned?: boolean | null
      liveStream?: {
        __typename?: 'LiveStream'
        id: string
        playbackUrl: string
        ingestUrl?: string | null
        wildmokaEventID?: string | null
        videoEngine: VideoEngine
        state: LiveStreamState
        streamId?: string | null
        sveBroadcastId?: string | null
        sveChannelId?: string | null
        externalFeedProvider?: ExternalFeedType | null
        externalFeedUrl?: string | null
        event?: {
          __typename?: 'WMEvent'
          id: string
          name: string
          description?: string | null
          testStartDate: Date
          editoStartDate: Date
          editoStopDate: Date
          stopDate: Date
          state: WmEventState
          notifEmail?: string | null
          clipCreationAtEnd: boolean
          clipPublicationAtEnd: boolean
          livePublication: boolean
          stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
          live?: {
            __typename?: 'WMEventLive'
            id: string
            publishStatus: WmLiveState
            type: WmLiveType
            autoAnnounceDate?: Date | null
            template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
          } | null
          clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
        } | null
        sveBroadcast?: {
          __typename?: 'SVEBroadcast'
          id: string
          status: SveBroadcastState
          channelId: string
          startDate?: Date | null
          clipStartDate?: Date | null
          stopDate?: Date | null
          scheduledStartDate: Date
          scheduledClipStartDate: Date
          scheduledStopDate: Date
        } | null
        sveLiveMonitoring?: {
          __typename?: 'SVELiveMonitoring'
          alerts?: {
            __typename?: 'SVELiveMonitoringAlerts'
            audioNotDetected?: string | null
            initialProbeTakingLonger?: string | null
            videoNotDetected?: string | null
            RTMPHasNoAudioVideo?: string | null
            RTMPStreamNotFound?: string | null
          } | null
          stats?: {
            __typename?: 'SVELiveMonitoringStats'
            networkIn?: string | null
            droppedFrames?: string | null
            inputVideoFrameRate?: string | null
            outputAudioLevelLkfs?: string | null
          } | null
        } | null
      } | null
      videoClip?: {
        __typename?: 'VideoClip'
        id: string
        playbackUrl: string
        downloadLink: string
        status?: VideoClipStatus | null
        updatedAt: Date
        createdAt: Date
        fileId?: string | null
        error?: {
          __typename?: 'VideoClipError'
          guid: string
          workflowStatus: string
          workflowErrorAt: string
          errorMessage: string
          errorDetails: string
        } | null
        captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
        externalFeed?: {
          __typename?: 'ExternalFeed'
          externalFeedProvider: ExternalFeedType
          externalFeedUrl: string
        } | null
      } | null
      image?: {
        __typename?: 'File'
        id: string
        filename: string
        size: number
        mimetype: string
        downloadUrl: string
        thumbUrl?: string | null
        createdAt: Date
        metadata?: {
          __typename?: 'FileMetadata'
          title?: string | null
          description?: string | null
          aspect?: number | null
          width?: number | null
          height?: number | null
          sourceFileId?: string | null
        } | null
      } | null
      thumbnail?: {
        __typename?: 'Media'
        id: string
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
      } | null
      timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
    } | null
    adminBadge?: {
      __typename?: 'Media'
      id: string
      createdAt: Date
      updatedAt: Date
      title: string
      type: MediaType
      publicationStatus: PublicationStatus
      tags: Array<MediaTags>
      date?: Date | null
      startTrim?: number | null
      endTrim?: number | null
      liveStreamId?: string | null
      videoClipId?: string | null
      imageId?: string | null
      thumbnailId?: string | null
      ownerOrganismId?: string | null
      pinned?: boolean | null
      liveStream?: {
        __typename?: 'LiveStream'
        id: string
        playbackUrl: string
        ingestUrl?: string | null
        wildmokaEventID?: string | null
        videoEngine: VideoEngine
        state: LiveStreamState
        streamId?: string | null
        sveBroadcastId?: string | null
        sveChannelId?: string | null
        externalFeedProvider?: ExternalFeedType | null
        externalFeedUrl?: string | null
        event?: {
          __typename?: 'WMEvent'
          id: string
          name: string
          description?: string | null
          testStartDate: Date
          editoStartDate: Date
          editoStopDate: Date
          stopDate: Date
          state: WmEventState
          notifEmail?: string | null
          clipCreationAtEnd: boolean
          clipPublicationAtEnd: boolean
          livePublication: boolean
          stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
          live?: {
            __typename?: 'WMEventLive'
            id: string
            publishStatus: WmLiveState
            type: WmLiveType
            autoAnnounceDate?: Date | null
            template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
          } | null
          clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
        } | null
        sveBroadcast?: {
          __typename?: 'SVEBroadcast'
          id: string
          status: SveBroadcastState
          channelId: string
          startDate?: Date | null
          clipStartDate?: Date | null
          stopDate?: Date | null
          scheduledStartDate: Date
          scheduledClipStartDate: Date
          scheduledStopDate: Date
        } | null
        sveLiveMonitoring?: {
          __typename?: 'SVELiveMonitoring'
          alerts?: {
            __typename?: 'SVELiveMonitoringAlerts'
            audioNotDetected?: string | null
            initialProbeTakingLonger?: string | null
            videoNotDetected?: string | null
            RTMPHasNoAudioVideo?: string | null
            RTMPStreamNotFound?: string | null
          } | null
          stats?: {
            __typename?: 'SVELiveMonitoringStats'
            networkIn?: string | null
            droppedFrames?: string | null
            inputVideoFrameRate?: string | null
            outputAudioLevelLkfs?: string | null
          } | null
        } | null
      } | null
      videoClip?: {
        __typename?: 'VideoClip'
        id: string
        playbackUrl: string
        downloadLink: string
        status?: VideoClipStatus | null
        updatedAt: Date
        createdAt: Date
        fileId?: string | null
        error?: {
          __typename?: 'VideoClipError'
          guid: string
          workflowStatus: string
          workflowErrorAt: string
          errorMessage: string
          errorDetails: string
        } | null
        captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
        externalFeed?: {
          __typename?: 'ExternalFeed'
          externalFeedProvider: ExternalFeedType
          externalFeedUrl: string
        } | null
      } | null
      image?: {
        __typename?: 'File'
        id: string
        filename: string
        size: number
        mimetype: string
        downloadUrl: string
        thumbUrl?: string | null
        createdAt: Date
        metadata?: {
          __typename?: 'FileMetadata'
          title?: string | null
          description?: string | null
          aspect?: number | null
          width?: number | null
          height?: number | null
          sourceFileId?: string | null
        } | null
      } | null
      thumbnail?: {
        __typename?: 'Media'
        id: string
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
      } | null
      timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
    } | null
    bannerVideo?: {
      __typename?: 'Media'
      id: string
      videoClip?: { __typename?: 'VideoClip'; id: string; playbackUrl: string } | null
    } | null
    pageWidgets?: Array<{
      __typename?: 'CustomPageWidget'
      name: CustomPageWidgetName
      position: number
      display?: CustomPageDisplayTypes | null
      contentDisplay?: ContentSetContentDisplay | null
      cardsDisplay?: CardsDisplay | null
      options?: {
        __typename?: 'CustomPageWidgetOptions'
        editedCategoryId?: string | null
        sportItemSetType?: SportItemSetType | null
        replays?: {
          __typename?: 'ReplaysWidget'
          defaultLocale: string
          maxReplays?: number | null
          depthInDays?: number | null
          nameT: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }>
        } | null
        externalContent?: {
          __typename?: 'ExternalContentWidget'
          name?: string | null
          defaultLocale: string
          maxItemsCount: number
          source: WidgetExternalContentSource
          nameT: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }>
          period?: { __typename?: 'StartEndDates'; startDate: Date; endDate: Date } | null
          wecContent?: {
            __typename?: 'WECNewsContent'
            teamECMIds?: Array<string> | null
            driverECMIds?: Array<string> | null
            carECMIds?: Array<string> | null
            categoryECMIds?: Array<string> | null
            raceECMIds?: Array<string> | null
          } | null
          rssContent?: { __typename?: 'RSSFeedContent'; feedUrl: string } | null
        } | null
      } | null
    }> | null
    offers: Array<{
      __typename?: 'PurchasableOffer'
      id: string
      mode: OfferMode
      delay?: number | null
      expiredAt?: Date | null
      publicationStatus: PublicationStatus
      title: string
      defaultLocale?: string | null
      url?: string | null
      bannerImageId?: string | null
      thumbnailImageId?: string | null
      freeWithLicense: boolean
      position?: number | null
      titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
      description: Array<{ __typename?: 'OfferDescription'; header: string; content: string }>
      descriptionT?: Array<{
        __typename?: 'ContentTranslationDescriptionPurchasableOffer'
        locale: string
        content?: Array<{ __typename?: 'OfferDescription'; header: string; content: string }> | null
      }> | null
      products: Array<{
        __typename?: 'Product'
        productId: string
        platform: Platform
        period?: Period | null
        publicationStatus: PublicationStatus
        purchasableOfferId?: string | null
        licensedUserOnly?: boolean | null
        premiumOf?: Array<string> | null
        prices: Array<{ __typename?: 'Price'; value: number; currency: string }>
      }>
    }>
    license?: { __typename?: 'OrganismLicense'; enabled: boolean; api?: LicenseApi | null } | null
    tags?: Array<{ __typename?: 'Tag'; id: string; title: string; subTags?: Array<string> | null }> | null
  } | null
  sports: Array<{ __typename?: 'Sport'; id: string; title: string; color: string }>
  titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
  subtitleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
  descriptionT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
  offers: Array<{ __typename?: 'PurchasableOffer'; id: string; title: string }>
  tabs?: Array<{
    __typename?: 'Tab'
    title: string
    url: string
    position: TabPosition
    blank?: boolean | null
    titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    icon: { __typename?: 'Icon'; title: string; svg: string }
  }> | null
  sponsorPlatform?: {
    __typename?: 'SponsorPlatform'
    linkId?: string | null
    display?: Array<SponsorPlatformDisplayOptions> | null
    videoPreroll?: Array<SponsorPlatformPrerollOptions> | null
    link?: {
      __typename: 'Link'
      id: string
      ownerOrganismIds: Array<string>
      tag?: LinkTag | null
      title: string
      subtitle?: string | null
      description?: string | null
      defaultLocale?: string | null
      clicks: number
      uniqueClicks: number
      views: number
      countries: Array<string | null>
      uniqueViews: number
      teaserVideoId?: string | null
      gamTagUrl?: string | null
      billboardId?: string | null
      url?: string | null
      imageId: string
      categoriesIds: Array<string>
      publicationStatus: PublicationStatus
      ownerOrganisms?: Array<{
        __typename?: 'Organism'
        id: string
        type: OrganismType
        whiteLabel: boolean
        title: string
        description?: string | null
        publicationStatus: PublicationStatus
        defaultLocale?: string | null
        onFront: boolean
        frontPosition?: number | null
        officialWebSiteUrl: string
        sportIds: Array<string>
        forbiddenKeyWords?: Array<string> | null
        enableExternalSources?: boolean | null
        availableExternalSources?: Array<WidgetExternalContentSource> | null
        streamsIds?: Array<string> | null
        wmProfileId?: string | null
        sveChannelIds?: Array<string> | null
        organismPageApplicationDesignId?: string | null
        contentPageApplicationDesignId?: string | null
        adminsAutomatedEmails?: boolean | null
        visibleInMultisportApplication?: boolean | null
        titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
        descriptionT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
        logo: {
          __typename?: 'Logo'
          coloredImage?: {
            __typename?: 'Media'
            id: string
            createdAt: Date
            updatedAt: Date
            title: string
            type: MediaType
            publicationStatus: PublicationStatus
            tags: Array<MediaTags>
            date?: Date | null
            startTrim?: number | null
            endTrim?: number | null
            liveStreamId?: string | null
            videoClipId?: string | null
            imageId?: string | null
            thumbnailId?: string | null
            ownerOrganismId?: string | null
            pinned?: boolean | null
            liveStream?: {
              __typename?: 'LiveStream'
              id: string
              playbackUrl: string
              ingestUrl?: string | null
              wildmokaEventID?: string | null
              videoEngine: VideoEngine
              state: LiveStreamState
              streamId?: string | null
              sveBroadcastId?: string | null
              sveChannelId?: string | null
              externalFeedProvider?: ExternalFeedType | null
              externalFeedUrl?: string | null
              event?: {
                __typename?: 'WMEvent'
                id: string
                name: string
                description?: string | null
                testStartDate: Date
                editoStartDate: Date
                editoStopDate: Date
                stopDate: Date
                state: WmEventState
                notifEmail?: string | null
                clipCreationAtEnd: boolean
                clipPublicationAtEnd: boolean
                livePublication: boolean
                stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
                live?: {
                  __typename?: 'WMEventLive'
                  id: string
                  publishStatus: WmLiveState
                  type: WmLiveType
                  autoAnnounceDate?: Date | null
                  template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
                } | null
                clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
              } | null
              sveBroadcast?: {
                __typename?: 'SVEBroadcast'
                id: string
                status: SveBroadcastState
                channelId: string
                startDate?: Date | null
                clipStartDate?: Date | null
                stopDate?: Date | null
                scheduledStartDate: Date
                scheduledClipStartDate: Date
                scheduledStopDate: Date
              } | null
              sveLiveMonitoring?: {
                __typename?: 'SVELiveMonitoring'
                alerts?: {
                  __typename?: 'SVELiveMonitoringAlerts'
                  audioNotDetected?: string | null
                  initialProbeTakingLonger?: string | null
                  videoNotDetected?: string | null
                  RTMPHasNoAudioVideo?: string | null
                  RTMPStreamNotFound?: string | null
                } | null
                stats?: {
                  __typename?: 'SVELiveMonitoringStats'
                  networkIn?: string | null
                  droppedFrames?: string | null
                  inputVideoFrameRate?: string | null
                  outputAudioLevelLkfs?: string | null
                } | null
              } | null
            } | null
            videoClip?: {
              __typename?: 'VideoClip'
              id: string
              playbackUrl: string
              downloadLink: string
              status?: VideoClipStatus | null
              updatedAt: Date
              createdAt: Date
              fileId?: string | null
              error?: {
                __typename?: 'VideoClipError'
                guid: string
                workflowStatus: string
                workflowErrorAt: string
                errorMessage: string
                errorDetails: string
              } | null
              captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
              externalFeed?: {
                __typename?: 'ExternalFeed'
                externalFeedProvider: ExternalFeedType
                externalFeedUrl: string
              } | null
            } | null
            image?: {
              __typename?: 'File'
              id: string
              filename: string
              size: number
              mimetype: string
              downloadUrl: string
              thumbUrl?: string | null
              createdAt: Date
              metadata?: {
                __typename?: 'FileMetadata'
                title?: string | null
                description?: string | null
                aspect?: number | null
                width?: number | null
                height?: number | null
                sourceFileId?: string | null
              } | null
            } | null
            thumbnail?: {
              __typename?: 'Media'
              id: string
              image?: {
                __typename?: 'File'
                id: string
                filename: string
                size: number
                mimetype: string
                downloadUrl: string
                thumbUrl?: string | null
                createdAt: Date
                metadata?: {
                  __typename?: 'FileMetadata'
                  title?: string | null
                  description?: string | null
                  aspect?: number | null
                  width?: number | null
                  height?: number | null
                  sourceFileId?: string | null
                } | null
              } | null
            } | null
            timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
          } | null
          transparentDarkImage?: {
            __typename?: 'Media'
            id: string
            createdAt: Date
            updatedAt: Date
            title: string
            type: MediaType
            publicationStatus: PublicationStatus
            tags: Array<MediaTags>
            date?: Date | null
            startTrim?: number | null
            endTrim?: number | null
            liveStreamId?: string | null
            videoClipId?: string | null
            imageId?: string | null
            thumbnailId?: string | null
            ownerOrganismId?: string | null
            pinned?: boolean | null
            liveStream?: {
              __typename?: 'LiveStream'
              id: string
              playbackUrl: string
              ingestUrl?: string | null
              wildmokaEventID?: string | null
              videoEngine: VideoEngine
              state: LiveStreamState
              streamId?: string | null
              sveBroadcastId?: string | null
              sveChannelId?: string | null
              externalFeedProvider?: ExternalFeedType | null
              externalFeedUrl?: string | null
              event?: {
                __typename?: 'WMEvent'
                id: string
                name: string
                description?: string | null
                testStartDate: Date
                editoStartDate: Date
                editoStopDate: Date
                stopDate: Date
                state: WmEventState
                notifEmail?: string | null
                clipCreationAtEnd: boolean
                clipPublicationAtEnd: boolean
                livePublication: boolean
                stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
                live?: {
                  __typename?: 'WMEventLive'
                  id: string
                  publishStatus: WmLiveState
                  type: WmLiveType
                  autoAnnounceDate?: Date | null
                  template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
                } | null
                clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
              } | null
              sveBroadcast?: {
                __typename?: 'SVEBroadcast'
                id: string
                status: SveBroadcastState
                channelId: string
                startDate?: Date | null
                clipStartDate?: Date | null
                stopDate?: Date | null
                scheduledStartDate: Date
                scheduledClipStartDate: Date
                scheduledStopDate: Date
              } | null
              sveLiveMonitoring?: {
                __typename?: 'SVELiveMonitoring'
                alerts?: {
                  __typename?: 'SVELiveMonitoringAlerts'
                  audioNotDetected?: string | null
                  initialProbeTakingLonger?: string | null
                  videoNotDetected?: string | null
                  RTMPHasNoAudioVideo?: string | null
                  RTMPStreamNotFound?: string | null
                } | null
                stats?: {
                  __typename?: 'SVELiveMonitoringStats'
                  networkIn?: string | null
                  droppedFrames?: string | null
                  inputVideoFrameRate?: string | null
                  outputAudioLevelLkfs?: string | null
                } | null
              } | null
            } | null
            videoClip?: {
              __typename?: 'VideoClip'
              id: string
              playbackUrl: string
              downloadLink: string
              status?: VideoClipStatus | null
              updatedAt: Date
              createdAt: Date
              fileId?: string | null
              error?: {
                __typename?: 'VideoClipError'
                guid: string
                workflowStatus: string
                workflowErrorAt: string
                errorMessage: string
                errorDetails: string
              } | null
              captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
              externalFeed?: {
                __typename?: 'ExternalFeed'
                externalFeedProvider: ExternalFeedType
                externalFeedUrl: string
              } | null
            } | null
            image?: {
              __typename?: 'File'
              id: string
              filename: string
              size: number
              mimetype: string
              downloadUrl: string
              thumbUrl?: string | null
              createdAt: Date
              metadata?: {
                __typename?: 'FileMetadata'
                title?: string | null
                description?: string | null
                aspect?: number | null
                width?: number | null
                height?: number | null
                sourceFileId?: string | null
              } | null
            } | null
            thumbnail?: {
              __typename?: 'Media'
              id: string
              image?: {
                __typename?: 'File'
                id: string
                filename: string
                size: number
                mimetype: string
                downloadUrl: string
                thumbUrl?: string | null
                createdAt: Date
                metadata?: {
                  __typename?: 'FileMetadata'
                  title?: string | null
                  description?: string | null
                  aspect?: number | null
                  width?: number | null
                  height?: number | null
                  sourceFileId?: string | null
                } | null
              } | null
            } | null
            timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
          } | null
          transparentLightImage?: {
            __typename?: 'Media'
            id: string
            createdAt: Date
            updatedAt: Date
            title: string
            type: MediaType
            publicationStatus: PublicationStatus
            tags: Array<MediaTags>
            date?: Date | null
            startTrim?: number | null
            endTrim?: number | null
            liveStreamId?: string | null
            videoClipId?: string | null
            imageId?: string | null
            thumbnailId?: string | null
            ownerOrganismId?: string | null
            pinned?: boolean | null
            liveStream?: {
              __typename?: 'LiveStream'
              id: string
              playbackUrl: string
              ingestUrl?: string | null
              wildmokaEventID?: string | null
              videoEngine: VideoEngine
              state: LiveStreamState
              streamId?: string | null
              sveBroadcastId?: string | null
              sveChannelId?: string | null
              externalFeedProvider?: ExternalFeedType | null
              externalFeedUrl?: string | null
              event?: {
                __typename?: 'WMEvent'
                id: string
                name: string
                description?: string | null
                testStartDate: Date
                editoStartDate: Date
                editoStopDate: Date
                stopDate: Date
                state: WmEventState
                notifEmail?: string | null
                clipCreationAtEnd: boolean
                clipPublicationAtEnd: boolean
                livePublication: boolean
                stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
                live?: {
                  __typename?: 'WMEventLive'
                  id: string
                  publishStatus: WmLiveState
                  type: WmLiveType
                  autoAnnounceDate?: Date | null
                  template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
                } | null
                clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
              } | null
              sveBroadcast?: {
                __typename?: 'SVEBroadcast'
                id: string
                status: SveBroadcastState
                channelId: string
                startDate?: Date | null
                clipStartDate?: Date | null
                stopDate?: Date | null
                scheduledStartDate: Date
                scheduledClipStartDate: Date
                scheduledStopDate: Date
              } | null
              sveLiveMonitoring?: {
                __typename?: 'SVELiveMonitoring'
                alerts?: {
                  __typename?: 'SVELiveMonitoringAlerts'
                  audioNotDetected?: string | null
                  initialProbeTakingLonger?: string | null
                  videoNotDetected?: string | null
                  RTMPHasNoAudioVideo?: string | null
                  RTMPStreamNotFound?: string | null
                } | null
                stats?: {
                  __typename?: 'SVELiveMonitoringStats'
                  networkIn?: string | null
                  droppedFrames?: string | null
                  inputVideoFrameRate?: string | null
                  outputAudioLevelLkfs?: string | null
                } | null
              } | null
            } | null
            videoClip?: {
              __typename?: 'VideoClip'
              id: string
              playbackUrl: string
              downloadLink: string
              status?: VideoClipStatus | null
              updatedAt: Date
              createdAt: Date
              fileId?: string | null
              error?: {
                __typename?: 'VideoClipError'
                guid: string
                workflowStatus: string
                workflowErrorAt: string
                errorMessage: string
                errorDetails: string
              } | null
              captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
              externalFeed?: {
                __typename?: 'ExternalFeed'
                externalFeedProvider: ExternalFeedType
                externalFeedUrl: string
              } | null
            } | null
            image?: {
              __typename?: 'File'
              id: string
              filename: string
              size: number
              mimetype: string
              downloadUrl: string
              thumbUrl?: string | null
              createdAt: Date
              metadata?: {
                __typename?: 'FileMetadata'
                title?: string | null
                description?: string | null
                aspect?: number | null
                width?: number | null
                height?: number | null
                sourceFileId?: string | null
              } | null
            } | null
            thumbnail?: {
              __typename?: 'Media'
              id: string
              image?: {
                __typename?: 'File'
                id: string
                filename: string
                size: number
                mimetype: string
                downloadUrl: string
                thumbUrl?: string | null
                createdAt: Date
                metadata?: {
                  __typename?: 'FileMetadata'
                  title?: string | null
                  description?: string | null
                  aspect?: number | null
                  width?: number | null
                  height?: number | null
                  sourceFileId?: string | null
                } | null
              } | null
            } | null
            timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
          } | null
        }
        sports: Array<{ __typename?: 'Sport'; id: string }>
        bannerImage?: {
          __typename?: 'Media'
          id: string
          createdAt: Date
          updatedAt: Date
          title: string
          type: MediaType
          publicationStatus: PublicationStatus
          tags: Array<MediaTags>
          date?: Date | null
          startTrim?: number | null
          endTrim?: number | null
          liveStreamId?: string | null
          videoClipId?: string | null
          imageId?: string | null
          thumbnailId?: string | null
          ownerOrganismId?: string | null
          pinned?: boolean | null
          liveStream?: {
            __typename?: 'LiveStream'
            id: string
            playbackUrl: string
            ingestUrl?: string | null
            wildmokaEventID?: string | null
            videoEngine: VideoEngine
            state: LiveStreamState
            streamId?: string | null
            sveBroadcastId?: string | null
            sveChannelId?: string | null
            externalFeedProvider?: ExternalFeedType | null
            externalFeedUrl?: string | null
            event?: {
              __typename?: 'WMEvent'
              id: string
              name: string
              description?: string | null
              testStartDate: Date
              editoStartDate: Date
              editoStopDate: Date
              stopDate: Date
              state: WmEventState
              notifEmail?: string | null
              clipCreationAtEnd: boolean
              clipPublicationAtEnd: boolean
              livePublication: boolean
              stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
              live?: {
                __typename?: 'WMEventLive'
                id: string
                publishStatus: WmLiveState
                type: WmLiveType
                autoAnnounceDate?: Date | null
                template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
              } | null
              clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
            } | null
            sveBroadcast?: {
              __typename?: 'SVEBroadcast'
              id: string
              status: SveBroadcastState
              channelId: string
              startDate?: Date | null
              clipStartDate?: Date | null
              stopDate?: Date | null
              scheduledStartDate: Date
              scheduledClipStartDate: Date
              scheduledStopDate: Date
            } | null
            sveLiveMonitoring?: {
              __typename?: 'SVELiveMonitoring'
              alerts?: {
                __typename?: 'SVELiveMonitoringAlerts'
                audioNotDetected?: string | null
                initialProbeTakingLonger?: string | null
                videoNotDetected?: string | null
                RTMPHasNoAudioVideo?: string | null
                RTMPStreamNotFound?: string | null
              } | null
              stats?: {
                __typename?: 'SVELiveMonitoringStats'
                networkIn?: string | null
                droppedFrames?: string | null
                inputVideoFrameRate?: string | null
                outputAudioLevelLkfs?: string | null
              } | null
            } | null
          } | null
          videoClip?: {
            __typename?: 'VideoClip'
            id: string
            playbackUrl: string
            downloadLink: string
            status?: VideoClipStatus | null
            updatedAt: Date
            createdAt: Date
            fileId?: string | null
            error?: {
              __typename?: 'VideoClipError'
              guid: string
              workflowStatus: string
              workflowErrorAt: string
              errorMessage: string
              errorDetails: string
            } | null
            captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
            externalFeed?: {
              __typename?: 'ExternalFeed'
              externalFeedProvider: ExternalFeedType
              externalFeedUrl: string
            } | null
          } | null
          image?: {
            __typename?: 'File'
            id: string
            filename: string
            size: number
            mimetype: string
            downloadUrl: string
            thumbUrl?: string | null
            createdAt: Date
            metadata?: {
              __typename?: 'FileMetadata'
              title?: string | null
              description?: string | null
              aspect?: number | null
              width?: number | null
              height?: number | null
              sourceFileId?: string | null
            } | null
          } | null
          thumbnail?: {
            __typename?: 'Media'
            id: string
            image?: {
              __typename?: 'File'
              id: string
              filename: string
              size: number
              mimetype: string
              downloadUrl: string
              thumbUrl?: string | null
              createdAt: Date
              metadata?: {
                __typename?: 'FileMetadata'
                title?: string | null
                description?: string | null
                aspect?: number | null
                width?: number | null
                height?: number | null
                sourceFileId?: string | null
              } | null
            } | null
          } | null
          timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
        } | null
        adminBadge?: {
          __typename?: 'Media'
          id: string
          createdAt: Date
          updatedAt: Date
          title: string
          type: MediaType
          publicationStatus: PublicationStatus
          tags: Array<MediaTags>
          date?: Date | null
          startTrim?: number | null
          endTrim?: number | null
          liveStreamId?: string | null
          videoClipId?: string | null
          imageId?: string | null
          thumbnailId?: string | null
          ownerOrganismId?: string | null
          pinned?: boolean | null
          liveStream?: {
            __typename?: 'LiveStream'
            id: string
            playbackUrl: string
            ingestUrl?: string | null
            wildmokaEventID?: string | null
            videoEngine: VideoEngine
            state: LiveStreamState
            streamId?: string | null
            sveBroadcastId?: string | null
            sveChannelId?: string | null
            externalFeedProvider?: ExternalFeedType | null
            externalFeedUrl?: string | null
            event?: {
              __typename?: 'WMEvent'
              id: string
              name: string
              description?: string | null
              testStartDate: Date
              editoStartDate: Date
              editoStopDate: Date
              stopDate: Date
              state: WmEventState
              notifEmail?: string | null
              clipCreationAtEnd: boolean
              clipPublicationAtEnd: boolean
              livePublication: boolean
              stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
              live?: {
                __typename?: 'WMEventLive'
                id: string
                publishStatus: WmLiveState
                type: WmLiveType
                autoAnnounceDate?: Date | null
                template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
              } | null
              clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
            } | null
            sveBroadcast?: {
              __typename?: 'SVEBroadcast'
              id: string
              status: SveBroadcastState
              channelId: string
              startDate?: Date | null
              clipStartDate?: Date | null
              stopDate?: Date | null
              scheduledStartDate: Date
              scheduledClipStartDate: Date
              scheduledStopDate: Date
            } | null
            sveLiveMonitoring?: {
              __typename?: 'SVELiveMonitoring'
              alerts?: {
                __typename?: 'SVELiveMonitoringAlerts'
                audioNotDetected?: string | null
                initialProbeTakingLonger?: string | null
                videoNotDetected?: string | null
                RTMPHasNoAudioVideo?: string | null
                RTMPStreamNotFound?: string | null
              } | null
              stats?: {
                __typename?: 'SVELiveMonitoringStats'
                networkIn?: string | null
                droppedFrames?: string | null
                inputVideoFrameRate?: string | null
                outputAudioLevelLkfs?: string | null
              } | null
            } | null
          } | null
          videoClip?: {
            __typename?: 'VideoClip'
            id: string
            playbackUrl: string
            downloadLink: string
            status?: VideoClipStatus | null
            updatedAt: Date
            createdAt: Date
            fileId?: string | null
            error?: {
              __typename?: 'VideoClipError'
              guid: string
              workflowStatus: string
              workflowErrorAt: string
              errorMessage: string
              errorDetails: string
            } | null
            captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
            externalFeed?: {
              __typename?: 'ExternalFeed'
              externalFeedProvider: ExternalFeedType
              externalFeedUrl: string
            } | null
          } | null
          image?: {
            __typename?: 'File'
            id: string
            filename: string
            size: number
            mimetype: string
            downloadUrl: string
            thumbUrl?: string | null
            createdAt: Date
            metadata?: {
              __typename?: 'FileMetadata'
              title?: string | null
              description?: string | null
              aspect?: number | null
              width?: number | null
              height?: number | null
              sourceFileId?: string | null
            } | null
          } | null
          thumbnail?: {
            __typename?: 'Media'
            id: string
            image?: {
              __typename?: 'File'
              id: string
              filename: string
              size: number
              mimetype: string
              downloadUrl: string
              thumbUrl?: string | null
              createdAt: Date
              metadata?: {
                __typename?: 'FileMetadata'
                title?: string | null
                description?: string | null
                aspect?: number | null
                width?: number | null
                height?: number | null
                sourceFileId?: string | null
              } | null
            } | null
          } | null
          timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
        } | null
        bannerVideo?: {
          __typename?: 'Media'
          id: string
          videoClip?: { __typename?: 'VideoClip'; id: string; playbackUrl: string } | null
        } | null
        pageWidgets?: Array<{
          __typename?: 'CustomPageWidget'
          name: CustomPageWidgetName
          position: number
          display?: CustomPageDisplayTypes | null
          contentDisplay?: ContentSetContentDisplay | null
          cardsDisplay?: CardsDisplay | null
          options?: {
            __typename?: 'CustomPageWidgetOptions'
            editedCategoryId?: string | null
            sportItemSetType?: SportItemSetType | null
            replays?: {
              __typename?: 'ReplaysWidget'
              defaultLocale: string
              maxReplays?: number | null
              depthInDays?: number | null
              nameT: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }>
            } | null
            externalContent?: {
              __typename?: 'ExternalContentWidget'
              name?: string | null
              defaultLocale: string
              maxItemsCount: number
              source: WidgetExternalContentSource
              nameT: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }>
              period?: { __typename?: 'StartEndDates'; startDate: Date; endDate: Date } | null
              wecContent?: {
                __typename?: 'WECNewsContent'
                teamECMIds?: Array<string> | null
                driverECMIds?: Array<string> | null
                carECMIds?: Array<string> | null
                categoryECMIds?: Array<string> | null
                raceECMIds?: Array<string> | null
              } | null
              rssContent?: { __typename?: 'RSSFeedContent'; feedUrl: string } | null
            } | null
          } | null
        }> | null
        offers: Array<{
          __typename?: 'PurchasableOffer'
          id: string
          mode: OfferMode
          delay?: number | null
          expiredAt?: Date | null
          publicationStatus: PublicationStatus
          title: string
          defaultLocale?: string | null
          url?: string | null
          bannerImageId?: string | null
          thumbnailImageId?: string | null
          freeWithLicense: boolean
          position?: number | null
          titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
          description: Array<{ __typename?: 'OfferDescription'; header: string; content: string }>
          descriptionT?: Array<{
            __typename?: 'ContentTranslationDescriptionPurchasableOffer'
            locale: string
            content?: Array<{ __typename?: 'OfferDescription'; header: string; content: string }> | null
          }> | null
          products: Array<{
            __typename?: 'Product'
            productId: string
            platform: Platform
            period?: Period | null
            publicationStatus: PublicationStatus
            purchasableOfferId?: string | null
            licensedUserOnly?: boolean | null
            premiumOf?: Array<string> | null
            prices: Array<{ __typename?: 'Price'; value: number; currency: string }>
          }>
        }>
        license?: { __typename?: 'OrganismLicense'; enabled: boolean; api?: LicenseApi | null } | null
        tags?: Array<{ __typename?: 'Tag'; id: string; title: string; subTags?: Array<string> | null }> | null
      }> | null
      titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
      subtitleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
      descriptionT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
      billboard?: {
        __typename?: 'Media'
        id: string
        videoClip?: { __typename?: 'VideoClip'; id: string; playbackUrl: string } | null
      } | null
      teaserVideo?: {
        __typename?: 'Media'
        id: string
        videoClip?: { __typename?: 'VideoClip'; id: string; playbackUrl: string } | null
      } | null
      image?: {
        __typename?: 'Media'
        id: string
        image?: { __typename?: 'File'; id: string; thumbUrl?: string | null; downloadUrl: string } | null
      } | null
      categories: Array<{ __typename?: 'Category'; id: string; title: string }>
    } | null
  } | null
  items?: Array<{ __typename?: 'SportItem'; id: string; title: string }> | null
  bannerImage?: {
    __typename?: 'Media'
    id: string
    image?: { __typename?: 'File'; id: string; thumbUrl?: string | null; downloadUrl: string } | null
  } | null
  bannerVideo?: {
    __typename?: 'Media'
    id: string
    videoClip?: { __typename?: 'VideoClip'; id: string; playbackUrl: string } | null
  } | null
  sportEvent?: {
    __typename?: 'SportEvent'
    startDate: Date
    endDate: Date
    state: SportEventState
    liveTemplateId?: string | null
    backstageTemplateId?: string | null
    clipTemplateId?: string | null
  } | null
  categories: Array<{ __typename?: 'Category'; id: string; title: string }>
  legalRestrictions: Array<{
    __typename?: 'LegalRestriction'
    id: string
    description?: string | null
    countries: Array<string>
    type: RestrictionType
    timeSlots?: { __typename?: 'TimeSlots'; from: string; to: string } | null
  }>
  cac?: {
    __typename?: 'ContentAccessControl'
    userAuthenticationRule?: CacUserAuthenticationRule | null
    userLocationRule?: {
      __typename?: 'CACUserLocationRule'
      distance: number
      condition: UserLocationCondition
      location: { __typename?: 'GeoLocation'; latitude: number; longitude: number }
    } | null
  } | null
  streams?: Array<{ __typename?: 'WMStream'; id: string; name: string }> | null
  sveStreams: Array<{
    __typename?: 'SVEStream'
    sveChannelId: string
    socialNetworkWMStreamId?: string | null
    outputStreamId?: string | null
  }>
  tag?: { __typename?: 'Tag'; id: string; title: string } | null
  contentPushServiceGenericStreams?: Array<{
    __typename?: 'ContentPushStream'
    id: string
    name: string
    createdAt: Date
    updatedAt: Date
  }> | null
  noSpoilerMode?: { __typename?: 'NoSpoilerMode'; enabled: boolean; minutesBeforeDisabling: number } | null
}

export type SportItemSetsQueryVariables = Exact<{
  filters?: InputMaybe<SportItemSetFilters>
  first?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<SportItemSetSort> | SportItemSetSort>
  withItems?: InputMaybe<Scalars['Boolean']>
}>

export type SportItemSetsQuery = {
  __typename?: 'Query'
  sportItemSets: {
    __typename?: 'SportItemSetsPageResult'
    totalCount: number
    sportItemSets: Array<{
      __typename?: 'SportItemSet'
      id: string
      sportIds: Array<string>
      type: SportItemSetType
      title: string
      subtitle?: string | null
      publicationStatus: PublicationStatus
      ownerOrganismId?: string | null
      restrictionLevel: PremiumAccess
      websiteUrl?: string | null
      bannerImageFileId?: string | null
      bannerVideoId?: string | null
      streamsIds?: Array<string> | null
      contentPushServiceNames?: Array<ContentPushStreamServiceName> | null
      items?: Array<{ __typename?: 'SportItem'; id: string; title: string }> | null
      sports: Array<{ __typename?: 'Sport'; id: string; title: string; color: string }>
      tabs?: Array<{
        __typename?: 'Tab'
        title: string
        url: string
        position: TabPosition
        blank?: boolean | null
        titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
        icon: { __typename?: 'Icon'; title: string; svg: string }
      }> | null
      bannerImage?: {
        __typename?: 'Media'
        id: string
        image?: { __typename?: 'File'; id: string; thumbUrl?: string | null; downloadUrl: string } | null
      } | null
      bannerVideo?: {
        __typename?: 'Media'
        id: string
        videoClip?: { __typename?: 'VideoClip'; id: string; playbackUrl: string } | null
      } | null
      sportEvent?: { __typename?: 'SportEvent'; startDate: Date; endDate: Date; state: SportEventState } | null
      legalRestrictions: Array<{
        __typename?: 'LegalRestriction'
        id: string
        description?: string | null
        countries: Array<string>
        type: RestrictionType
        timeSlots?: { __typename?: 'TimeSlots'; from: string; to: string } | null
      }>
    }>
  }
}

export type SportItemSetQueryVariables = Exact<{
  sportItemSetId: Scalars['ID']
}>

export type SportItemSetQuery = {
  __typename?: 'Query'
  sportItemSet?: {
    __typename: 'SportItemSet'
    id: string
    sportIds: Array<string>
    type: SportItemSetType
    title: string
    subtitle?: string | null
    description?: string | null
    defaultLocale?: string | null
    publicationStatus: PublicationStatus
    ownerOrganismId?: string | null
    offerIds: Array<string>
    restrictionLevel: PremiumAccess
    websiteUrl?: string | null
    lockPublishingLive?: boolean | null
    lockPublishingClip?: boolean | null
    bannerImageFileId?: string | null
    bannerVideoId?: string | null
    testPeriodEnd?: Date | null
    categoriesIds: Array<string>
    videoEngine: VideoEngine
    streamsIds?: Array<string> | null
    wmClippingTemplateId?: string | null
    sveInputLossImageMediaId?: string | null
    sveOverlayLogoImageMediaId?: string | null
    visibleInMultisportApplication: boolean
    activateSportItemChatByDefault: boolean
    contentPushServiceNames?: Array<ContentPushStreamServiceName> | null
    subTags?: Array<string> | null
    activateGreenPlayback?: boolean | null
    adCampaignId?: string | null
    contentPushServiceGenericStreamIds?: Array<string> | null
    contentPushServiceLiveEventIds?: Array<string> | null
    disableReadParallelLivestreamsLimit?: boolean | null
    sports: Array<{ __typename?: 'Sport'; id: string; title: string; color: string }>
    titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    subtitleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    descriptionT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    ownerOrganism?: { __typename?: 'Organism'; title: string } | null
    offers: Array<{ __typename?: 'PurchasableOffer'; id: string; title: string }>
    tabs?: Array<{
      __typename?: 'Tab'
      title: string
      url: string
      position: TabPosition
      blank?: boolean | null
      titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
      icon: { __typename?: 'Icon'; title: string; svg: string }
    }> | null
    sponsorPlatform?: {
      __typename?: 'SponsorPlatform'
      linkId?: string | null
      display?: Array<SponsorPlatformDisplayOptions> | null
      videoPreroll?: Array<SponsorPlatformPrerollOptions> | null
      link?: {
        __typename: 'Link'
        id: string
        ownerOrganismIds: Array<string>
        tag?: LinkTag | null
        title: string
        subtitle?: string | null
        description?: string | null
        defaultLocale?: string | null
        clicks: number
        uniqueClicks: number
        views: number
        countries: Array<string | null>
        uniqueViews: number
        teaserVideoId?: string | null
        gamTagUrl?: string | null
        billboardId?: string | null
        url?: string | null
        imageId: string
        categoriesIds: Array<string>
        publicationStatus: PublicationStatus
        ownerOrganisms?: Array<{
          __typename?: 'Organism'
          id: string
          type: OrganismType
          whiteLabel: boolean
          title: string
          description?: string | null
          publicationStatus: PublicationStatus
          defaultLocale?: string | null
          onFront: boolean
          frontPosition?: number | null
          officialWebSiteUrl: string
          sportIds: Array<string>
          forbiddenKeyWords?: Array<string> | null
          enableExternalSources?: boolean | null
          availableExternalSources?: Array<WidgetExternalContentSource> | null
          streamsIds?: Array<string> | null
          wmProfileId?: string | null
          sveChannelIds?: Array<string> | null
          organismPageApplicationDesignId?: string | null
          contentPageApplicationDesignId?: string | null
          adminsAutomatedEmails?: boolean | null
          visibleInMultisportApplication?: boolean | null
          titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
          descriptionT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
          logo: {
            __typename?: 'Logo'
            coloredImage?: {
              __typename?: 'Media'
              id: string
              createdAt: Date
              updatedAt: Date
              title: string
              type: MediaType
              publicationStatus: PublicationStatus
              tags: Array<MediaTags>
              date?: Date | null
              startTrim?: number | null
              endTrim?: number | null
              liveStreamId?: string | null
              videoClipId?: string | null
              imageId?: string | null
              thumbnailId?: string | null
              ownerOrganismId?: string | null
              pinned?: boolean | null
              liveStream?: {
                __typename?: 'LiveStream'
                id: string
                playbackUrl: string
                ingestUrl?: string | null
                wildmokaEventID?: string | null
                videoEngine: VideoEngine
                state: LiveStreamState
                streamId?: string | null
                sveBroadcastId?: string | null
                sveChannelId?: string | null
                externalFeedProvider?: ExternalFeedType | null
                externalFeedUrl?: string | null
                event?: {
                  __typename?: 'WMEvent'
                  id: string
                  name: string
                  description?: string | null
                  testStartDate: Date
                  editoStartDate: Date
                  editoStopDate: Date
                  stopDate: Date
                  state: WmEventState
                  notifEmail?: string | null
                  clipCreationAtEnd: boolean
                  clipPublicationAtEnd: boolean
                  livePublication: boolean
                  stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
                  live?: {
                    __typename?: 'WMEventLive'
                    id: string
                    publishStatus: WmLiveState
                    type: WmLiveType
                    autoAnnounceDate?: Date | null
                    template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
                  } | null
                  clipTemplate?: {
                    __typename?: 'WMEventTemplate'
                    id: string
                    name: string
                    type: WmTemplateType
                  } | null
                } | null
                sveBroadcast?: {
                  __typename?: 'SVEBroadcast'
                  id: string
                  status: SveBroadcastState
                  channelId: string
                  startDate?: Date | null
                  clipStartDate?: Date | null
                  stopDate?: Date | null
                  scheduledStartDate: Date
                  scheduledClipStartDate: Date
                  scheduledStopDate: Date
                } | null
                sveLiveMonitoring?: {
                  __typename?: 'SVELiveMonitoring'
                  alerts?: {
                    __typename?: 'SVELiveMonitoringAlerts'
                    audioNotDetected?: string | null
                    initialProbeTakingLonger?: string | null
                    videoNotDetected?: string | null
                    RTMPHasNoAudioVideo?: string | null
                    RTMPStreamNotFound?: string | null
                  } | null
                  stats?: {
                    __typename?: 'SVELiveMonitoringStats'
                    networkIn?: string | null
                    droppedFrames?: string | null
                    inputVideoFrameRate?: string | null
                    outputAudioLevelLkfs?: string | null
                  } | null
                } | null
              } | null
              videoClip?: {
                __typename?: 'VideoClip'
                id: string
                playbackUrl: string
                downloadLink: string
                status?: VideoClipStatus | null
                updatedAt: Date
                createdAt: Date
                fileId?: string | null
                error?: {
                  __typename?: 'VideoClipError'
                  guid: string
                  workflowStatus: string
                  workflowErrorAt: string
                  errorMessage: string
                  errorDetails: string
                } | null
                captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
                externalFeed?: {
                  __typename?: 'ExternalFeed'
                  externalFeedProvider: ExternalFeedType
                  externalFeedUrl: string
                } | null
              } | null
              image?: {
                __typename?: 'File'
                id: string
                filename: string
                size: number
                mimetype: string
                downloadUrl: string
                thumbUrl?: string | null
                createdAt: Date
                metadata?: {
                  __typename?: 'FileMetadata'
                  title?: string | null
                  description?: string | null
                  aspect?: number | null
                  width?: number | null
                  height?: number | null
                  sourceFileId?: string | null
                } | null
              } | null
              thumbnail?: {
                __typename?: 'Media'
                id: string
                image?: {
                  __typename?: 'File'
                  id: string
                  filename: string
                  size: number
                  mimetype: string
                  downloadUrl: string
                  thumbUrl?: string | null
                  createdAt: Date
                  metadata?: {
                    __typename?: 'FileMetadata'
                    title?: string | null
                    description?: string | null
                    aspect?: number | null
                    width?: number | null
                    height?: number | null
                    sourceFileId?: string | null
                  } | null
                } | null
              } | null
              timelineMarkers?: Array<{
                __typename?: 'Marker'
                title: string
                time: number
                icon?: string | null
              }> | null
            } | null
            transparentDarkImage?: {
              __typename?: 'Media'
              id: string
              createdAt: Date
              updatedAt: Date
              title: string
              type: MediaType
              publicationStatus: PublicationStatus
              tags: Array<MediaTags>
              date?: Date | null
              startTrim?: number | null
              endTrim?: number | null
              liveStreamId?: string | null
              videoClipId?: string | null
              imageId?: string | null
              thumbnailId?: string | null
              ownerOrganismId?: string | null
              pinned?: boolean | null
              liveStream?: {
                __typename?: 'LiveStream'
                id: string
                playbackUrl: string
                ingestUrl?: string | null
                wildmokaEventID?: string | null
                videoEngine: VideoEngine
                state: LiveStreamState
                streamId?: string | null
                sveBroadcastId?: string | null
                sveChannelId?: string | null
                externalFeedProvider?: ExternalFeedType | null
                externalFeedUrl?: string | null
                event?: {
                  __typename?: 'WMEvent'
                  id: string
                  name: string
                  description?: string | null
                  testStartDate: Date
                  editoStartDate: Date
                  editoStopDate: Date
                  stopDate: Date
                  state: WmEventState
                  notifEmail?: string | null
                  clipCreationAtEnd: boolean
                  clipPublicationAtEnd: boolean
                  livePublication: boolean
                  stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
                  live?: {
                    __typename?: 'WMEventLive'
                    id: string
                    publishStatus: WmLiveState
                    type: WmLiveType
                    autoAnnounceDate?: Date | null
                    template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
                  } | null
                  clipTemplate?: {
                    __typename?: 'WMEventTemplate'
                    id: string
                    name: string
                    type: WmTemplateType
                  } | null
                } | null
                sveBroadcast?: {
                  __typename?: 'SVEBroadcast'
                  id: string
                  status: SveBroadcastState
                  channelId: string
                  startDate?: Date | null
                  clipStartDate?: Date | null
                  stopDate?: Date | null
                  scheduledStartDate: Date
                  scheduledClipStartDate: Date
                  scheduledStopDate: Date
                } | null
                sveLiveMonitoring?: {
                  __typename?: 'SVELiveMonitoring'
                  alerts?: {
                    __typename?: 'SVELiveMonitoringAlerts'
                    audioNotDetected?: string | null
                    initialProbeTakingLonger?: string | null
                    videoNotDetected?: string | null
                    RTMPHasNoAudioVideo?: string | null
                    RTMPStreamNotFound?: string | null
                  } | null
                  stats?: {
                    __typename?: 'SVELiveMonitoringStats'
                    networkIn?: string | null
                    droppedFrames?: string | null
                    inputVideoFrameRate?: string | null
                    outputAudioLevelLkfs?: string | null
                  } | null
                } | null
              } | null
              videoClip?: {
                __typename?: 'VideoClip'
                id: string
                playbackUrl: string
                downloadLink: string
                status?: VideoClipStatus | null
                updatedAt: Date
                createdAt: Date
                fileId?: string | null
                error?: {
                  __typename?: 'VideoClipError'
                  guid: string
                  workflowStatus: string
                  workflowErrorAt: string
                  errorMessage: string
                  errorDetails: string
                } | null
                captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
                externalFeed?: {
                  __typename?: 'ExternalFeed'
                  externalFeedProvider: ExternalFeedType
                  externalFeedUrl: string
                } | null
              } | null
              image?: {
                __typename?: 'File'
                id: string
                filename: string
                size: number
                mimetype: string
                downloadUrl: string
                thumbUrl?: string | null
                createdAt: Date
                metadata?: {
                  __typename?: 'FileMetadata'
                  title?: string | null
                  description?: string | null
                  aspect?: number | null
                  width?: number | null
                  height?: number | null
                  sourceFileId?: string | null
                } | null
              } | null
              thumbnail?: {
                __typename?: 'Media'
                id: string
                image?: {
                  __typename?: 'File'
                  id: string
                  filename: string
                  size: number
                  mimetype: string
                  downloadUrl: string
                  thumbUrl?: string | null
                  createdAt: Date
                  metadata?: {
                    __typename?: 'FileMetadata'
                    title?: string | null
                    description?: string | null
                    aspect?: number | null
                    width?: number | null
                    height?: number | null
                    sourceFileId?: string | null
                  } | null
                } | null
              } | null
              timelineMarkers?: Array<{
                __typename?: 'Marker'
                title: string
                time: number
                icon?: string | null
              }> | null
            } | null
            transparentLightImage?: {
              __typename?: 'Media'
              id: string
              createdAt: Date
              updatedAt: Date
              title: string
              type: MediaType
              publicationStatus: PublicationStatus
              tags: Array<MediaTags>
              date?: Date | null
              startTrim?: number | null
              endTrim?: number | null
              liveStreamId?: string | null
              videoClipId?: string | null
              imageId?: string | null
              thumbnailId?: string | null
              ownerOrganismId?: string | null
              pinned?: boolean | null
              liveStream?: {
                __typename?: 'LiveStream'
                id: string
                playbackUrl: string
                ingestUrl?: string | null
                wildmokaEventID?: string | null
                videoEngine: VideoEngine
                state: LiveStreamState
                streamId?: string | null
                sveBroadcastId?: string | null
                sveChannelId?: string | null
                externalFeedProvider?: ExternalFeedType | null
                externalFeedUrl?: string | null
                event?: {
                  __typename?: 'WMEvent'
                  id: string
                  name: string
                  description?: string | null
                  testStartDate: Date
                  editoStartDate: Date
                  editoStopDate: Date
                  stopDate: Date
                  state: WmEventState
                  notifEmail?: string | null
                  clipCreationAtEnd: boolean
                  clipPublicationAtEnd: boolean
                  livePublication: boolean
                  stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
                  live?: {
                    __typename?: 'WMEventLive'
                    id: string
                    publishStatus: WmLiveState
                    type: WmLiveType
                    autoAnnounceDate?: Date | null
                    template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
                  } | null
                  clipTemplate?: {
                    __typename?: 'WMEventTemplate'
                    id: string
                    name: string
                    type: WmTemplateType
                  } | null
                } | null
                sveBroadcast?: {
                  __typename?: 'SVEBroadcast'
                  id: string
                  status: SveBroadcastState
                  channelId: string
                  startDate?: Date | null
                  clipStartDate?: Date | null
                  stopDate?: Date | null
                  scheduledStartDate: Date
                  scheduledClipStartDate: Date
                  scheduledStopDate: Date
                } | null
                sveLiveMonitoring?: {
                  __typename?: 'SVELiveMonitoring'
                  alerts?: {
                    __typename?: 'SVELiveMonitoringAlerts'
                    audioNotDetected?: string | null
                    initialProbeTakingLonger?: string | null
                    videoNotDetected?: string | null
                    RTMPHasNoAudioVideo?: string | null
                    RTMPStreamNotFound?: string | null
                  } | null
                  stats?: {
                    __typename?: 'SVELiveMonitoringStats'
                    networkIn?: string | null
                    droppedFrames?: string | null
                    inputVideoFrameRate?: string | null
                    outputAudioLevelLkfs?: string | null
                  } | null
                } | null
              } | null
              videoClip?: {
                __typename?: 'VideoClip'
                id: string
                playbackUrl: string
                downloadLink: string
                status?: VideoClipStatus | null
                updatedAt: Date
                createdAt: Date
                fileId?: string | null
                error?: {
                  __typename?: 'VideoClipError'
                  guid: string
                  workflowStatus: string
                  workflowErrorAt: string
                  errorMessage: string
                  errorDetails: string
                } | null
                captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
                externalFeed?: {
                  __typename?: 'ExternalFeed'
                  externalFeedProvider: ExternalFeedType
                  externalFeedUrl: string
                } | null
              } | null
              image?: {
                __typename?: 'File'
                id: string
                filename: string
                size: number
                mimetype: string
                downloadUrl: string
                thumbUrl?: string | null
                createdAt: Date
                metadata?: {
                  __typename?: 'FileMetadata'
                  title?: string | null
                  description?: string | null
                  aspect?: number | null
                  width?: number | null
                  height?: number | null
                  sourceFileId?: string | null
                } | null
              } | null
              thumbnail?: {
                __typename?: 'Media'
                id: string
                image?: {
                  __typename?: 'File'
                  id: string
                  filename: string
                  size: number
                  mimetype: string
                  downloadUrl: string
                  thumbUrl?: string | null
                  createdAt: Date
                  metadata?: {
                    __typename?: 'FileMetadata'
                    title?: string | null
                    description?: string | null
                    aspect?: number | null
                    width?: number | null
                    height?: number | null
                    sourceFileId?: string | null
                  } | null
                } | null
              } | null
              timelineMarkers?: Array<{
                __typename?: 'Marker'
                title: string
                time: number
                icon?: string | null
              }> | null
            } | null
          }
          sports: Array<{ __typename?: 'Sport'; id: string }>
          bannerImage?: {
            __typename?: 'Media'
            id: string
            createdAt: Date
            updatedAt: Date
            title: string
            type: MediaType
            publicationStatus: PublicationStatus
            tags: Array<MediaTags>
            date?: Date | null
            startTrim?: number | null
            endTrim?: number | null
            liveStreamId?: string | null
            videoClipId?: string | null
            imageId?: string | null
            thumbnailId?: string | null
            ownerOrganismId?: string | null
            pinned?: boolean | null
            liveStream?: {
              __typename?: 'LiveStream'
              id: string
              playbackUrl: string
              ingestUrl?: string | null
              wildmokaEventID?: string | null
              videoEngine: VideoEngine
              state: LiveStreamState
              streamId?: string | null
              sveBroadcastId?: string | null
              sveChannelId?: string | null
              externalFeedProvider?: ExternalFeedType | null
              externalFeedUrl?: string | null
              event?: {
                __typename?: 'WMEvent'
                id: string
                name: string
                description?: string | null
                testStartDate: Date
                editoStartDate: Date
                editoStopDate: Date
                stopDate: Date
                state: WmEventState
                notifEmail?: string | null
                clipCreationAtEnd: boolean
                clipPublicationAtEnd: boolean
                livePublication: boolean
                stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
                live?: {
                  __typename?: 'WMEventLive'
                  id: string
                  publishStatus: WmLiveState
                  type: WmLiveType
                  autoAnnounceDate?: Date | null
                  template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
                } | null
                clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
              } | null
              sveBroadcast?: {
                __typename?: 'SVEBroadcast'
                id: string
                status: SveBroadcastState
                channelId: string
                startDate?: Date | null
                clipStartDate?: Date | null
                stopDate?: Date | null
                scheduledStartDate: Date
                scheduledClipStartDate: Date
                scheduledStopDate: Date
              } | null
              sveLiveMonitoring?: {
                __typename?: 'SVELiveMonitoring'
                alerts?: {
                  __typename?: 'SVELiveMonitoringAlerts'
                  audioNotDetected?: string | null
                  initialProbeTakingLonger?: string | null
                  videoNotDetected?: string | null
                  RTMPHasNoAudioVideo?: string | null
                  RTMPStreamNotFound?: string | null
                } | null
                stats?: {
                  __typename?: 'SVELiveMonitoringStats'
                  networkIn?: string | null
                  droppedFrames?: string | null
                  inputVideoFrameRate?: string | null
                  outputAudioLevelLkfs?: string | null
                } | null
              } | null
            } | null
            videoClip?: {
              __typename?: 'VideoClip'
              id: string
              playbackUrl: string
              downloadLink: string
              status?: VideoClipStatus | null
              updatedAt: Date
              createdAt: Date
              fileId?: string | null
              error?: {
                __typename?: 'VideoClipError'
                guid: string
                workflowStatus: string
                workflowErrorAt: string
                errorMessage: string
                errorDetails: string
              } | null
              captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
              externalFeed?: {
                __typename?: 'ExternalFeed'
                externalFeedProvider: ExternalFeedType
                externalFeedUrl: string
              } | null
            } | null
            image?: {
              __typename?: 'File'
              id: string
              filename: string
              size: number
              mimetype: string
              downloadUrl: string
              thumbUrl?: string | null
              createdAt: Date
              metadata?: {
                __typename?: 'FileMetadata'
                title?: string | null
                description?: string | null
                aspect?: number | null
                width?: number | null
                height?: number | null
                sourceFileId?: string | null
              } | null
            } | null
            thumbnail?: {
              __typename?: 'Media'
              id: string
              image?: {
                __typename?: 'File'
                id: string
                filename: string
                size: number
                mimetype: string
                downloadUrl: string
                thumbUrl?: string | null
                createdAt: Date
                metadata?: {
                  __typename?: 'FileMetadata'
                  title?: string | null
                  description?: string | null
                  aspect?: number | null
                  width?: number | null
                  height?: number | null
                  sourceFileId?: string | null
                } | null
              } | null
            } | null
            timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
          } | null
          adminBadge?: {
            __typename?: 'Media'
            id: string
            createdAt: Date
            updatedAt: Date
            title: string
            type: MediaType
            publicationStatus: PublicationStatus
            tags: Array<MediaTags>
            date?: Date | null
            startTrim?: number | null
            endTrim?: number | null
            liveStreamId?: string | null
            videoClipId?: string | null
            imageId?: string | null
            thumbnailId?: string | null
            ownerOrganismId?: string | null
            pinned?: boolean | null
            liveStream?: {
              __typename?: 'LiveStream'
              id: string
              playbackUrl: string
              ingestUrl?: string | null
              wildmokaEventID?: string | null
              videoEngine: VideoEngine
              state: LiveStreamState
              streamId?: string | null
              sveBroadcastId?: string | null
              sveChannelId?: string | null
              externalFeedProvider?: ExternalFeedType | null
              externalFeedUrl?: string | null
              event?: {
                __typename?: 'WMEvent'
                id: string
                name: string
                description?: string | null
                testStartDate: Date
                editoStartDate: Date
                editoStopDate: Date
                stopDate: Date
                state: WmEventState
                notifEmail?: string | null
                clipCreationAtEnd: boolean
                clipPublicationAtEnd: boolean
                livePublication: boolean
                stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
                live?: {
                  __typename?: 'WMEventLive'
                  id: string
                  publishStatus: WmLiveState
                  type: WmLiveType
                  autoAnnounceDate?: Date | null
                  template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
                } | null
                clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
              } | null
              sveBroadcast?: {
                __typename?: 'SVEBroadcast'
                id: string
                status: SveBroadcastState
                channelId: string
                startDate?: Date | null
                clipStartDate?: Date | null
                stopDate?: Date | null
                scheduledStartDate: Date
                scheduledClipStartDate: Date
                scheduledStopDate: Date
              } | null
              sveLiveMonitoring?: {
                __typename?: 'SVELiveMonitoring'
                alerts?: {
                  __typename?: 'SVELiveMonitoringAlerts'
                  audioNotDetected?: string | null
                  initialProbeTakingLonger?: string | null
                  videoNotDetected?: string | null
                  RTMPHasNoAudioVideo?: string | null
                  RTMPStreamNotFound?: string | null
                } | null
                stats?: {
                  __typename?: 'SVELiveMonitoringStats'
                  networkIn?: string | null
                  droppedFrames?: string | null
                  inputVideoFrameRate?: string | null
                  outputAudioLevelLkfs?: string | null
                } | null
              } | null
            } | null
            videoClip?: {
              __typename?: 'VideoClip'
              id: string
              playbackUrl: string
              downloadLink: string
              status?: VideoClipStatus | null
              updatedAt: Date
              createdAt: Date
              fileId?: string | null
              error?: {
                __typename?: 'VideoClipError'
                guid: string
                workflowStatus: string
                workflowErrorAt: string
                errorMessage: string
                errorDetails: string
              } | null
              captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
              externalFeed?: {
                __typename?: 'ExternalFeed'
                externalFeedProvider: ExternalFeedType
                externalFeedUrl: string
              } | null
            } | null
            image?: {
              __typename?: 'File'
              id: string
              filename: string
              size: number
              mimetype: string
              downloadUrl: string
              thumbUrl?: string | null
              createdAt: Date
              metadata?: {
                __typename?: 'FileMetadata'
                title?: string | null
                description?: string | null
                aspect?: number | null
                width?: number | null
                height?: number | null
                sourceFileId?: string | null
              } | null
            } | null
            thumbnail?: {
              __typename?: 'Media'
              id: string
              image?: {
                __typename?: 'File'
                id: string
                filename: string
                size: number
                mimetype: string
                downloadUrl: string
                thumbUrl?: string | null
                createdAt: Date
                metadata?: {
                  __typename?: 'FileMetadata'
                  title?: string | null
                  description?: string | null
                  aspect?: number | null
                  width?: number | null
                  height?: number | null
                  sourceFileId?: string | null
                } | null
              } | null
            } | null
            timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
          } | null
          bannerVideo?: {
            __typename?: 'Media'
            id: string
            videoClip?: { __typename?: 'VideoClip'; id: string; playbackUrl: string } | null
          } | null
          pageWidgets?: Array<{
            __typename?: 'CustomPageWidget'
            name: CustomPageWidgetName
            position: number
            display?: CustomPageDisplayTypes | null
            contentDisplay?: ContentSetContentDisplay | null
            cardsDisplay?: CardsDisplay | null
            options?: {
              __typename?: 'CustomPageWidgetOptions'
              editedCategoryId?: string | null
              sportItemSetType?: SportItemSetType | null
              replays?: {
                __typename?: 'ReplaysWidget'
                defaultLocale: string
                maxReplays?: number | null
                depthInDays?: number | null
                nameT: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }>
              } | null
              externalContent?: {
                __typename?: 'ExternalContentWidget'
                name?: string | null
                defaultLocale: string
                maxItemsCount: number
                source: WidgetExternalContentSource
                nameT: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }>
                period?: { __typename?: 'StartEndDates'; startDate: Date; endDate: Date } | null
                wecContent?: {
                  __typename?: 'WECNewsContent'
                  teamECMIds?: Array<string> | null
                  driverECMIds?: Array<string> | null
                  carECMIds?: Array<string> | null
                  categoryECMIds?: Array<string> | null
                  raceECMIds?: Array<string> | null
                } | null
                rssContent?: { __typename?: 'RSSFeedContent'; feedUrl: string } | null
              } | null
            } | null
          }> | null
          offers: Array<{
            __typename?: 'PurchasableOffer'
            id: string
            mode: OfferMode
            delay?: number | null
            expiredAt?: Date | null
            publicationStatus: PublicationStatus
            title: string
            defaultLocale?: string | null
            url?: string | null
            bannerImageId?: string | null
            thumbnailImageId?: string | null
            freeWithLicense: boolean
            position?: number | null
            titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
            description: Array<{ __typename?: 'OfferDescription'; header: string; content: string }>
            descriptionT?: Array<{
              __typename?: 'ContentTranslationDescriptionPurchasableOffer'
              locale: string
              content?: Array<{ __typename?: 'OfferDescription'; header: string; content: string }> | null
            }> | null
            products: Array<{
              __typename?: 'Product'
              productId: string
              platform: Platform
              period?: Period | null
              publicationStatus: PublicationStatus
              purchasableOfferId?: string | null
              licensedUserOnly?: boolean | null
              premiumOf?: Array<string> | null
              prices: Array<{ __typename?: 'Price'; value: number; currency: string }>
            }>
          }>
          license?: { __typename?: 'OrganismLicense'; enabled: boolean; api?: LicenseApi | null } | null
          tags?: Array<{ __typename?: 'Tag'; id: string; title: string; subTags?: Array<string> | null }> | null
        }> | null
        titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
        subtitleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
        descriptionT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
        billboard?: {
          __typename?: 'Media'
          id: string
          videoClip?: { __typename?: 'VideoClip'; id: string; playbackUrl: string } | null
        } | null
        teaserVideo?: {
          __typename?: 'Media'
          id: string
          videoClip?: { __typename?: 'VideoClip'; id: string; playbackUrl: string } | null
        } | null
        image?: {
          __typename?: 'Media'
          id: string
          image?: { __typename?: 'File'; id: string; thumbUrl?: string | null; downloadUrl: string } | null
        } | null
        categories: Array<{ __typename?: 'Category'; id: string; title: string }>
      } | null
    } | null
    items?: Array<{ __typename?: 'SportItem'; id: string; title: string }> | null
    bannerImage?: {
      __typename?: 'Media'
      id: string
      image?: { __typename?: 'File'; id: string; thumbUrl?: string | null; downloadUrl: string } | null
    } | null
    bannerVideo?: {
      __typename?: 'Media'
      id: string
      videoClip?: { __typename?: 'VideoClip'; id: string; playbackUrl: string } | null
    } | null
    sportEvent?: {
      __typename?: 'SportEvent'
      startDate: Date
      endDate: Date
      state: SportEventState
      liveTemplateId?: string | null
      backstageTemplateId?: string | null
      clipTemplateId?: string | null
    } | null
    categories: Array<{ __typename?: 'Category'; id: string; title: string }>
    legalRestrictions: Array<{
      __typename?: 'LegalRestriction'
      id: string
      description?: string | null
      countries: Array<string>
      type: RestrictionType
      timeSlots?: { __typename?: 'TimeSlots'; from: string; to: string } | null
    }>
    cac?: {
      __typename?: 'ContentAccessControl'
      userAuthenticationRule?: CacUserAuthenticationRule | null
      userLocationRule?: {
        __typename?: 'CACUserLocationRule'
        distance: number
        condition: UserLocationCondition
        location: { __typename?: 'GeoLocation'; latitude: number; longitude: number }
      } | null
    } | null
    streams?: Array<{ __typename?: 'WMStream'; id: string; name: string }> | null
    sveStreams: Array<{
      __typename?: 'SVEStream'
      sveChannelId: string
      socialNetworkWMStreamId?: string | null
      outputStreamId?: string | null
    }>
    tag?: { __typename?: 'Tag'; id: string; title: string } | null
    contentPushServiceGenericStreams?: Array<{
      __typename?: 'ContentPushStream'
      id: string
      name: string
      createdAt: Date
      updatedAt: Date
    }> | null
    noSpoilerMode?: { __typename?: 'NoSpoilerMode'; enabled: boolean; minutesBeforeDisabling: number } | null
  } | null
}

export type SportItemSetWithOrganismQueryVariables = Exact<{
  sportItemSetId: Scalars['ID']
}>

export type SportItemSetWithOrganismQuery = {
  __typename?: 'Query'
  sportItemSet?: {
    __typename: 'SportItemSet'
    id: string
    sportIds: Array<string>
    type: SportItemSetType
    title: string
    subtitle?: string | null
    description?: string | null
    defaultLocale?: string | null
    publicationStatus: PublicationStatus
    ownerOrganismId?: string | null
    offerIds: Array<string>
    restrictionLevel: PremiumAccess
    websiteUrl?: string | null
    lockPublishingLive?: boolean | null
    lockPublishingClip?: boolean | null
    bannerImageFileId?: string | null
    bannerVideoId?: string | null
    testPeriodEnd?: Date | null
    categoriesIds: Array<string>
    videoEngine: VideoEngine
    streamsIds?: Array<string> | null
    wmClippingTemplateId?: string | null
    sveInputLossImageMediaId?: string | null
    sveOverlayLogoImageMediaId?: string | null
    visibleInMultisportApplication: boolean
    activateSportItemChatByDefault: boolean
    contentPushServiceNames?: Array<ContentPushStreamServiceName> | null
    subTags?: Array<string> | null
    activateGreenPlayback?: boolean | null
    adCampaignId?: string | null
    contentPushServiceGenericStreamIds?: Array<string> | null
    contentPushServiceLiveEventIds?: Array<string> | null
    disableReadParallelLivestreamsLimit?: boolean | null
    ownerOrganism?: {
      __typename?: 'Organism'
      title: string
      id: string
      type: OrganismType
      whiteLabel: boolean
      description?: string | null
      publicationStatus: PublicationStatus
      defaultLocale?: string | null
      onFront: boolean
      frontPosition?: number | null
      officialWebSiteUrl: string
      sportIds: Array<string>
      forbiddenKeyWords?: Array<string> | null
      enableExternalSources?: boolean | null
      availableExternalSources?: Array<WidgetExternalContentSource> | null
      streamsIds?: Array<string> | null
      wmProfileId?: string | null
      sveChannelIds?: Array<string> | null
      organismPageApplicationDesignId?: string | null
      contentPageApplicationDesignId?: string | null
      adminsAutomatedEmails?: boolean | null
      visibleInMultisportApplication?: boolean | null
      titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
      descriptionT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
      logo: {
        __typename?: 'Logo'
        coloredImage?: {
          __typename?: 'Media'
          id: string
          createdAt: Date
          updatedAt: Date
          title: string
          type: MediaType
          publicationStatus: PublicationStatus
          tags: Array<MediaTags>
          date?: Date | null
          startTrim?: number | null
          endTrim?: number | null
          liveStreamId?: string | null
          videoClipId?: string | null
          imageId?: string | null
          thumbnailId?: string | null
          ownerOrganismId?: string | null
          pinned?: boolean | null
          liveStream?: {
            __typename?: 'LiveStream'
            id: string
            playbackUrl: string
            ingestUrl?: string | null
            wildmokaEventID?: string | null
            videoEngine: VideoEngine
            state: LiveStreamState
            streamId?: string | null
            sveBroadcastId?: string | null
            sveChannelId?: string | null
            externalFeedProvider?: ExternalFeedType | null
            externalFeedUrl?: string | null
            event?: {
              __typename?: 'WMEvent'
              id: string
              name: string
              description?: string | null
              testStartDate: Date
              editoStartDate: Date
              editoStopDate: Date
              stopDate: Date
              state: WmEventState
              notifEmail?: string | null
              clipCreationAtEnd: boolean
              clipPublicationAtEnd: boolean
              livePublication: boolean
              stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
              live?: {
                __typename?: 'WMEventLive'
                id: string
                publishStatus: WmLiveState
                type: WmLiveType
                autoAnnounceDate?: Date | null
                template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
              } | null
              clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
            } | null
            sveBroadcast?: {
              __typename?: 'SVEBroadcast'
              id: string
              status: SveBroadcastState
              channelId: string
              startDate?: Date | null
              clipStartDate?: Date | null
              stopDate?: Date | null
              scheduledStartDate: Date
              scheduledClipStartDate: Date
              scheduledStopDate: Date
            } | null
            sveLiveMonitoring?: {
              __typename?: 'SVELiveMonitoring'
              alerts?: {
                __typename?: 'SVELiveMonitoringAlerts'
                audioNotDetected?: string | null
                initialProbeTakingLonger?: string | null
                videoNotDetected?: string | null
                RTMPHasNoAudioVideo?: string | null
                RTMPStreamNotFound?: string | null
              } | null
              stats?: {
                __typename?: 'SVELiveMonitoringStats'
                networkIn?: string | null
                droppedFrames?: string | null
                inputVideoFrameRate?: string | null
                outputAudioLevelLkfs?: string | null
              } | null
            } | null
          } | null
          videoClip?: {
            __typename?: 'VideoClip'
            id: string
            playbackUrl: string
            downloadLink: string
            status?: VideoClipStatus | null
            updatedAt: Date
            createdAt: Date
            fileId?: string | null
            error?: {
              __typename?: 'VideoClipError'
              guid: string
              workflowStatus: string
              workflowErrorAt: string
              errorMessage: string
              errorDetails: string
            } | null
            captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
            externalFeed?: {
              __typename?: 'ExternalFeed'
              externalFeedProvider: ExternalFeedType
              externalFeedUrl: string
            } | null
          } | null
          image?: {
            __typename?: 'File'
            id: string
            filename: string
            size: number
            mimetype: string
            downloadUrl: string
            thumbUrl?: string | null
            createdAt: Date
            metadata?: {
              __typename?: 'FileMetadata'
              title?: string | null
              description?: string | null
              aspect?: number | null
              width?: number | null
              height?: number | null
              sourceFileId?: string | null
            } | null
          } | null
          thumbnail?: {
            __typename?: 'Media'
            id: string
            image?: {
              __typename?: 'File'
              id: string
              filename: string
              size: number
              mimetype: string
              downloadUrl: string
              thumbUrl?: string | null
              createdAt: Date
              metadata?: {
                __typename?: 'FileMetadata'
                title?: string | null
                description?: string | null
                aspect?: number | null
                width?: number | null
                height?: number | null
                sourceFileId?: string | null
              } | null
            } | null
          } | null
          timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
        } | null
        transparentDarkImage?: {
          __typename?: 'Media'
          id: string
          createdAt: Date
          updatedAt: Date
          title: string
          type: MediaType
          publicationStatus: PublicationStatus
          tags: Array<MediaTags>
          date?: Date | null
          startTrim?: number | null
          endTrim?: number | null
          liveStreamId?: string | null
          videoClipId?: string | null
          imageId?: string | null
          thumbnailId?: string | null
          ownerOrganismId?: string | null
          pinned?: boolean | null
          liveStream?: {
            __typename?: 'LiveStream'
            id: string
            playbackUrl: string
            ingestUrl?: string | null
            wildmokaEventID?: string | null
            videoEngine: VideoEngine
            state: LiveStreamState
            streamId?: string | null
            sveBroadcastId?: string | null
            sveChannelId?: string | null
            externalFeedProvider?: ExternalFeedType | null
            externalFeedUrl?: string | null
            event?: {
              __typename?: 'WMEvent'
              id: string
              name: string
              description?: string | null
              testStartDate: Date
              editoStartDate: Date
              editoStopDate: Date
              stopDate: Date
              state: WmEventState
              notifEmail?: string | null
              clipCreationAtEnd: boolean
              clipPublicationAtEnd: boolean
              livePublication: boolean
              stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
              live?: {
                __typename?: 'WMEventLive'
                id: string
                publishStatus: WmLiveState
                type: WmLiveType
                autoAnnounceDate?: Date | null
                template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
              } | null
              clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
            } | null
            sveBroadcast?: {
              __typename?: 'SVEBroadcast'
              id: string
              status: SveBroadcastState
              channelId: string
              startDate?: Date | null
              clipStartDate?: Date | null
              stopDate?: Date | null
              scheduledStartDate: Date
              scheduledClipStartDate: Date
              scheduledStopDate: Date
            } | null
            sveLiveMonitoring?: {
              __typename?: 'SVELiveMonitoring'
              alerts?: {
                __typename?: 'SVELiveMonitoringAlerts'
                audioNotDetected?: string | null
                initialProbeTakingLonger?: string | null
                videoNotDetected?: string | null
                RTMPHasNoAudioVideo?: string | null
                RTMPStreamNotFound?: string | null
              } | null
              stats?: {
                __typename?: 'SVELiveMonitoringStats'
                networkIn?: string | null
                droppedFrames?: string | null
                inputVideoFrameRate?: string | null
                outputAudioLevelLkfs?: string | null
              } | null
            } | null
          } | null
          videoClip?: {
            __typename?: 'VideoClip'
            id: string
            playbackUrl: string
            downloadLink: string
            status?: VideoClipStatus | null
            updatedAt: Date
            createdAt: Date
            fileId?: string | null
            error?: {
              __typename?: 'VideoClipError'
              guid: string
              workflowStatus: string
              workflowErrorAt: string
              errorMessage: string
              errorDetails: string
            } | null
            captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
            externalFeed?: {
              __typename?: 'ExternalFeed'
              externalFeedProvider: ExternalFeedType
              externalFeedUrl: string
            } | null
          } | null
          image?: {
            __typename?: 'File'
            id: string
            filename: string
            size: number
            mimetype: string
            downloadUrl: string
            thumbUrl?: string | null
            createdAt: Date
            metadata?: {
              __typename?: 'FileMetadata'
              title?: string | null
              description?: string | null
              aspect?: number | null
              width?: number | null
              height?: number | null
              sourceFileId?: string | null
            } | null
          } | null
          thumbnail?: {
            __typename?: 'Media'
            id: string
            image?: {
              __typename?: 'File'
              id: string
              filename: string
              size: number
              mimetype: string
              downloadUrl: string
              thumbUrl?: string | null
              createdAt: Date
              metadata?: {
                __typename?: 'FileMetadata'
                title?: string | null
                description?: string | null
                aspect?: number | null
                width?: number | null
                height?: number | null
                sourceFileId?: string | null
              } | null
            } | null
          } | null
          timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
        } | null
        transparentLightImage?: {
          __typename?: 'Media'
          id: string
          createdAt: Date
          updatedAt: Date
          title: string
          type: MediaType
          publicationStatus: PublicationStatus
          tags: Array<MediaTags>
          date?: Date | null
          startTrim?: number | null
          endTrim?: number | null
          liveStreamId?: string | null
          videoClipId?: string | null
          imageId?: string | null
          thumbnailId?: string | null
          ownerOrganismId?: string | null
          pinned?: boolean | null
          liveStream?: {
            __typename?: 'LiveStream'
            id: string
            playbackUrl: string
            ingestUrl?: string | null
            wildmokaEventID?: string | null
            videoEngine: VideoEngine
            state: LiveStreamState
            streamId?: string | null
            sveBroadcastId?: string | null
            sveChannelId?: string | null
            externalFeedProvider?: ExternalFeedType | null
            externalFeedUrl?: string | null
            event?: {
              __typename?: 'WMEvent'
              id: string
              name: string
              description?: string | null
              testStartDate: Date
              editoStartDate: Date
              editoStopDate: Date
              stopDate: Date
              state: WmEventState
              notifEmail?: string | null
              clipCreationAtEnd: boolean
              clipPublicationAtEnd: boolean
              livePublication: boolean
              stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
              live?: {
                __typename?: 'WMEventLive'
                id: string
                publishStatus: WmLiveState
                type: WmLiveType
                autoAnnounceDate?: Date | null
                template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
              } | null
              clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
            } | null
            sveBroadcast?: {
              __typename?: 'SVEBroadcast'
              id: string
              status: SveBroadcastState
              channelId: string
              startDate?: Date | null
              clipStartDate?: Date | null
              stopDate?: Date | null
              scheduledStartDate: Date
              scheduledClipStartDate: Date
              scheduledStopDate: Date
            } | null
            sveLiveMonitoring?: {
              __typename?: 'SVELiveMonitoring'
              alerts?: {
                __typename?: 'SVELiveMonitoringAlerts'
                audioNotDetected?: string | null
                initialProbeTakingLonger?: string | null
                videoNotDetected?: string | null
                RTMPHasNoAudioVideo?: string | null
                RTMPStreamNotFound?: string | null
              } | null
              stats?: {
                __typename?: 'SVELiveMonitoringStats'
                networkIn?: string | null
                droppedFrames?: string | null
                inputVideoFrameRate?: string | null
                outputAudioLevelLkfs?: string | null
              } | null
            } | null
          } | null
          videoClip?: {
            __typename?: 'VideoClip'
            id: string
            playbackUrl: string
            downloadLink: string
            status?: VideoClipStatus | null
            updatedAt: Date
            createdAt: Date
            fileId?: string | null
            error?: {
              __typename?: 'VideoClipError'
              guid: string
              workflowStatus: string
              workflowErrorAt: string
              errorMessage: string
              errorDetails: string
            } | null
            captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
            externalFeed?: {
              __typename?: 'ExternalFeed'
              externalFeedProvider: ExternalFeedType
              externalFeedUrl: string
            } | null
          } | null
          image?: {
            __typename?: 'File'
            id: string
            filename: string
            size: number
            mimetype: string
            downloadUrl: string
            thumbUrl?: string | null
            createdAt: Date
            metadata?: {
              __typename?: 'FileMetadata'
              title?: string | null
              description?: string | null
              aspect?: number | null
              width?: number | null
              height?: number | null
              sourceFileId?: string | null
            } | null
          } | null
          thumbnail?: {
            __typename?: 'Media'
            id: string
            image?: {
              __typename?: 'File'
              id: string
              filename: string
              size: number
              mimetype: string
              downloadUrl: string
              thumbUrl?: string | null
              createdAt: Date
              metadata?: {
                __typename?: 'FileMetadata'
                title?: string | null
                description?: string | null
                aspect?: number | null
                width?: number | null
                height?: number | null
                sourceFileId?: string | null
              } | null
            } | null
          } | null
          timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
        } | null
      }
      sports: Array<{ __typename?: 'Sport'; id: string }>
      bannerImage?: {
        __typename?: 'Media'
        id: string
        createdAt: Date
        updatedAt: Date
        title: string
        type: MediaType
        publicationStatus: PublicationStatus
        tags: Array<MediaTags>
        date?: Date | null
        startTrim?: number | null
        endTrim?: number | null
        liveStreamId?: string | null
        videoClipId?: string | null
        imageId?: string | null
        thumbnailId?: string | null
        ownerOrganismId?: string | null
        pinned?: boolean | null
        liveStream?: {
          __typename?: 'LiveStream'
          id: string
          playbackUrl: string
          ingestUrl?: string | null
          wildmokaEventID?: string | null
          videoEngine: VideoEngine
          state: LiveStreamState
          streamId?: string | null
          sveBroadcastId?: string | null
          sveChannelId?: string | null
          externalFeedProvider?: ExternalFeedType | null
          externalFeedUrl?: string | null
          event?: {
            __typename?: 'WMEvent'
            id: string
            name: string
            description?: string | null
            testStartDate: Date
            editoStartDate: Date
            editoStopDate: Date
            stopDate: Date
            state: WmEventState
            notifEmail?: string | null
            clipCreationAtEnd: boolean
            clipPublicationAtEnd: boolean
            livePublication: boolean
            stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
            live?: {
              __typename?: 'WMEventLive'
              id: string
              publishStatus: WmLiveState
              type: WmLiveType
              autoAnnounceDate?: Date | null
              template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
            } | null
            clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
          } | null
          sveBroadcast?: {
            __typename?: 'SVEBroadcast'
            id: string
            status: SveBroadcastState
            channelId: string
            startDate?: Date | null
            clipStartDate?: Date | null
            stopDate?: Date | null
            scheduledStartDate: Date
            scheduledClipStartDate: Date
            scheduledStopDate: Date
          } | null
          sveLiveMonitoring?: {
            __typename?: 'SVELiveMonitoring'
            alerts?: {
              __typename?: 'SVELiveMonitoringAlerts'
              audioNotDetected?: string | null
              initialProbeTakingLonger?: string | null
              videoNotDetected?: string | null
              RTMPHasNoAudioVideo?: string | null
              RTMPStreamNotFound?: string | null
            } | null
            stats?: {
              __typename?: 'SVELiveMonitoringStats'
              networkIn?: string | null
              droppedFrames?: string | null
              inputVideoFrameRate?: string | null
              outputAudioLevelLkfs?: string | null
            } | null
          } | null
        } | null
        videoClip?: {
          __typename?: 'VideoClip'
          id: string
          playbackUrl: string
          downloadLink: string
          status?: VideoClipStatus | null
          updatedAt: Date
          createdAt: Date
          fileId?: string | null
          error?: {
            __typename?: 'VideoClipError'
            guid: string
            workflowStatus: string
            workflowErrorAt: string
            errorMessage: string
            errorDetails: string
          } | null
          captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
          externalFeed?: {
            __typename?: 'ExternalFeed'
            externalFeedProvider: ExternalFeedType
            externalFeedUrl: string
          } | null
        } | null
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
        thumbnail?: {
          __typename?: 'Media'
          id: string
          image?: {
            __typename?: 'File'
            id: string
            filename: string
            size: number
            mimetype: string
            downloadUrl: string
            thumbUrl?: string | null
            createdAt: Date
            metadata?: {
              __typename?: 'FileMetadata'
              title?: string | null
              description?: string | null
              aspect?: number | null
              width?: number | null
              height?: number | null
              sourceFileId?: string | null
            } | null
          } | null
        } | null
        timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
      } | null
      adminBadge?: {
        __typename?: 'Media'
        id: string
        createdAt: Date
        updatedAt: Date
        title: string
        type: MediaType
        publicationStatus: PublicationStatus
        tags: Array<MediaTags>
        date?: Date | null
        startTrim?: number | null
        endTrim?: number | null
        liveStreamId?: string | null
        videoClipId?: string | null
        imageId?: string | null
        thumbnailId?: string | null
        ownerOrganismId?: string | null
        pinned?: boolean | null
        liveStream?: {
          __typename?: 'LiveStream'
          id: string
          playbackUrl: string
          ingestUrl?: string | null
          wildmokaEventID?: string | null
          videoEngine: VideoEngine
          state: LiveStreamState
          streamId?: string | null
          sveBroadcastId?: string | null
          sveChannelId?: string | null
          externalFeedProvider?: ExternalFeedType | null
          externalFeedUrl?: string | null
          event?: {
            __typename?: 'WMEvent'
            id: string
            name: string
            description?: string | null
            testStartDate: Date
            editoStartDate: Date
            editoStopDate: Date
            stopDate: Date
            state: WmEventState
            notifEmail?: string | null
            clipCreationAtEnd: boolean
            clipPublicationAtEnd: boolean
            livePublication: boolean
            stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
            live?: {
              __typename?: 'WMEventLive'
              id: string
              publishStatus: WmLiveState
              type: WmLiveType
              autoAnnounceDate?: Date | null
              template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
            } | null
            clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
          } | null
          sveBroadcast?: {
            __typename?: 'SVEBroadcast'
            id: string
            status: SveBroadcastState
            channelId: string
            startDate?: Date | null
            clipStartDate?: Date | null
            stopDate?: Date | null
            scheduledStartDate: Date
            scheduledClipStartDate: Date
            scheduledStopDate: Date
          } | null
          sveLiveMonitoring?: {
            __typename?: 'SVELiveMonitoring'
            alerts?: {
              __typename?: 'SVELiveMonitoringAlerts'
              audioNotDetected?: string | null
              initialProbeTakingLonger?: string | null
              videoNotDetected?: string | null
              RTMPHasNoAudioVideo?: string | null
              RTMPStreamNotFound?: string | null
            } | null
            stats?: {
              __typename?: 'SVELiveMonitoringStats'
              networkIn?: string | null
              droppedFrames?: string | null
              inputVideoFrameRate?: string | null
              outputAudioLevelLkfs?: string | null
            } | null
          } | null
        } | null
        videoClip?: {
          __typename?: 'VideoClip'
          id: string
          playbackUrl: string
          downloadLink: string
          status?: VideoClipStatus | null
          updatedAt: Date
          createdAt: Date
          fileId?: string | null
          error?: {
            __typename?: 'VideoClipError'
            guid: string
            workflowStatus: string
            workflowErrorAt: string
            errorMessage: string
            errorDetails: string
          } | null
          captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
          externalFeed?: {
            __typename?: 'ExternalFeed'
            externalFeedProvider: ExternalFeedType
            externalFeedUrl: string
          } | null
        } | null
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
        thumbnail?: {
          __typename?: 'Media'
          id: string
          image?: {
            __typename?: 'File'
            id: string
            filename: string
            size: number
            mimetype: string
            downloadUrl: string
            thumbUrl?: string | null
            createdAt: Date
            metadata?: {
              __typename?: 'FileMetadata'
              title?: string | null
              description?: string | null
              aspect?: number | null
              width?: number | null
              height?: number | null
              sourceFileId?: string | null
            } | null
          } | null
        } | null
        timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
      } | null
      bannerVideo?: {
        __typename?: 'Media'
        id: string
        videoClip?: { __typename?: 'VideoClip'; id: string; playbackUrl: string } | null
      } | null
      pageWidgets?: Array<{
        __typename?: 'CustomPageWidget'
        name: CustomPageWidgetName
        position: number
        display?: CustomPageDisplayTypes | null
        contentDisplay?: ContentSetContentDisplay | null
        cardsDisplay?: CardsDisplay | null
        options?: {
          __typename?: 'CustomPageWidgetOptions'
          editedCategoryId?: string | null
          sportItemSetType?: SportItemSetType | null
          replays?: {
            __typename?: 'ReplaysWidget'
            defaultLocale: string
            maxReplays?: number | null
            depthInDays?: number | null
            nameT: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }>
          } | null
          externalContent?: {
            __typename?: 'ExternalContentWidget'
            name?: string | null
            defaultLocale: string
            maxItemsCount: number
            source: WidgetExternalContentSource
            nameT: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }>
            period?: { __typename?: 'StartEndDates'; startDate: Date; endDate: Date } | null
            wecContent?: {
              __typename?: 'WECNewsContent'
              teamECMIds?: Array<string> | null
              driverECMIds?: Array<string> | null
              carECMIds?: Array<string> | null
              categoryECMIds?: Array<string> | null
              raceECMIds?: Array<string> | null
            } | null
            rssContent?: { __typename?: 'RSSFeedContent'; feedUrl: string } | null
          } | null
        } | null
      }> | null
      offers: Array<{
        __typename?: 'PurchasableOffer'
        id: string
        mode: OfferMode
        delay?: number | null
        expiredAt?: Date | null
        publicationStatus: PublicationStatus
        title: string
        defaultLocale?: string | null
        url?: string | null
        bannerImageId?: string | null
        thumbnailImageId?: string | null
        freeWithLicense: boolean
        position?: number | null
        titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
        description: Array<{ __typename?: 'OfferDescription'; header: string; content: string }>
        descriptionT?: Array<{
          __typename?: 'ContentTranslationDescriptionPurchasableOffer'
          locale: string
          content?: Array<{ __typename?: 'OfferDescription'; header: string; content: string }> | null
        }> | null
        products: Array<{
          __typename?: 'Product'
          productId: string
          platform: Platform
          period?: Period | null
          publicationStatus: PublicationStatus
          purchasableOfferId?: string | null
          licensedUserOnly?: boolean | null
          premiumOf?: Array<string> | null
          prices: Array<{ __typename?: 'Price'; value: number; currency: string }>
        }>
      }>
      license?: { __typename?: 'OrganismLicense'; enabled: boolean; api?: LicenseApi | null } | null
      tags?: Array<{ __typename?: 'Tag'; id: string; title: string; subTags?: Array<string> | null }> | null
    } | null
    sports: Array<{ __typename?: 'Sport'; id: string; title: string; color: string }>
    titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    subtitleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    descriptionT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    offers: Array<{ __typename?: 'PurchasableOffer'; id: string; title: string }>
    tabs?: Array<{
      __typename?: 'Tab'
      title: string
      url: string
      position: TabPosition
      blank?: boolean | null
      titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
      icon: { __typename?: 'Icon'; title: string; svg: string }
    }> | null
    sponsorPlatform?: {
      __typename?: 'SponsorPlatform'
      linkId?: string | null
      display?: Array<SponsorPlatformDisplayOptions> | null
      videoPreroll?: Array<SponsorPlatformPrerollOptions> | null
      link?: {
        __typename: 'Link'
        id: string
        ownerOrganismIds: Array<string>
        tag?: LinkTag | null
        title: string
        subtitle?: string | null
        description?: string | null
        defaultLocale?: string | null
        clicks: number
        uniqueClicks: number
        views: number
        countries: Array<string | null>
        uniqueViews: number
        teaserVideoId?: string | null
        gamTagUrl?: string | null
        billboardId?: string | null
        url?: string | null
        imageId: string
        categoriesIds: Array<string>
        publicationStatus: PublicationStatus
        ownerOrganisms?: Array<{
          __typename?: 'Organism'
          id: string
          type: OrganismType
          whiteLabel: boolean
          title: string
          description?: string | null
          publicationStatus: PublicationStatus
          defaultLocale?: string | null
          onFront: boolean
          frontPosition?: number | null
          officialWebSiteUrl: string
          sportIds: Array<string>
          forbiddenKeyWords?: Array<string> | null
          enableExternalSources?: boolean | null
          availableExternalSources?: Array<WidgetExternalContentSource> | null
          streamsIds?: Array<string> | null
          wmProfileId?: string | null
          sveChannelIds?: Array<string> | null
          organismPageApplicationDesignId?: string | null
          contentPageApplicationDesignId?: string | null
          adminsAutomatedEmails?: boolean | null
          visibleInMultisportApplication?: boolean | null
          titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
          descriptionT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
          logo: {
            __typename?: 'Logo'
            coloredImage?: {
              __typename?: 'Media'
              id: string
              createdAt: Date
              updatedAt: Date
              title: string
              type: MediaType
              publicationStatus: PublicationStatus
              tags: Array<MediaTags>
              date?: Date | null
              startTrim?: number | null
              endTrim?: number | null
              liveStreamId?: string | null
              videoClipId?: string | null
              imageId?: string | null
              thumbnailId?: string | null
              ownerOrganismId?: string | null
              pinned?: boolean | null
              liveStream?: {
                __typename?: 'LiveStream'
                id: string
                playbackUrl: string
                ingestUrl?: string | null
                wildmokaEventID?: string | null
                videoEngine: VideoEngine
                state: LiveStreamState
                streamId?: string | null
                sveBroadcastId?: string | null
                sveChannelId?: string | null
                externalFeedProvider?: ExternalFeedType | null
                externalFeedUrl?: string | null
                event?: {
                  __typename?: 'WMEvent'
                  id: string
                  name: string
                  description?: string | null
                  testStartDate: Date
                  editoStartDate: Date
                  editoStopDate: Date
                  stopDate: Date
                  state: WmEventState
                  notifEmail?: string | null
                  clipCreationAtEnd: boolean
                  clipPublicationAtEnd: boolean
                  livePublication: boolean
                  stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
                  live?: {
                    __typename?: 'WMEventLive'
                    id: string
                    publishStatus: WmLiveState
                    type: WmLiveType
                    autoAnnounceDate?: Date | null
                    template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
                  } | null
                  clipTemplate?: {
                    __typename?: 'WMEventTemplate'
                    id: string
                    name: string
                    type: WmTemplateType
                  } | null
                } | null
                sveBroadcast?: {
                  __typename?: 'SVEBroadcast'
                  id: string
                  status: SveBroadcastState
                  channelId: string
                  startDate?: Date | null
                  clipStartDate?: Date | null
                  stopDate?: Date | null
                  scheduledStartDate: Date
                  scheduledClipStartDate: Date
                  scheduledStopDate: Date
                } | null
                sveLiveMonitoring?: {
                  __typename?: 'SVELiveMonitoring'
                  alerts?: {
                    __typename?: 'SVELiveMonitoringAlerts'
                    audioNotDetected?: string | null
                    initialProbeTakingLonger?: string | null
                    videoNotDetected?: string | null
                    RTMPHasNoAudioVideo?: string | null
                    RTMPStreamNotFound?: string | null
                  } | null
                  stats?: {
                    __typename?: 'SVELiveMonitoringStats'
                    networkIn?: string | null
                    droppedFrames?: string | null
                    inputVideoFrameRate?: string | null
                    outputAudioLevelLkfs?: string | null
                  } | null
                } | null
              } | null
              videoClip?: {
                __typename?: 'VideoClip'
                id: string
                playbackUrl: string
                downloadLink: string
                status?: VideoClipStatus | null
                updatedAt: Date
                createdAt: Date
                fileId?: string | null
                error?: {
                  __typename?: 'VideoClipError'
                  guid: string
                  workflowStatus: string
                  workflowErrorAt: string
                  errorMessage: string
                  errorDetails: string
                } | null
                captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
                externalFeed?: {
                  __typename?: 'ExternalFeed'
                  externalFeedProvider: ExternalFeedType
                  externalFeedUrl: string
                } | null
              } | null
              image?: {
                __typename?: 'File'
                id: string
                filename: string
                size: number
                mimetype: string
                downloadUrl: string
                thumbUrl?: string | null
                createdAt: Date
                metadata?: {
                  __typename?: 'FileMetadata'
                  title?: string | null
                  description?: string | null
                  aspect?: number | null
                  width?: number | null
                  height?: number | null
                  sourceFileId?: string | null
                } | null
              } | null
              thumbnail?: {
                __typename?: 'Media'
                id: string
                image?: {
                  __typename?: 'File'
                  id: string
                  filename: string
                  size: number
                  mimetype: string
                  downloadUrl: string
                  thumbUrl?: string | null
                  createdAt: Date
                  metadata?: {
                    __typename?: 'FileMetadata'
                    title?: string | null
                    description?: string | null
                    aspect?: number | null
                    width?: number | null
                    height?: number | null
                    sourceFileId?: string | null
                  } | null
                } | null
              } | null
              timelineMarkers?: Array<{
                __typename?: 'Marker'
                title: string
                time: number
                icon?: string | null
              }> | null
            } | null
            transparentDarkImage?: {
              __typename?: 'Media'
              id: string
              createdAt: Date
              updatedAt: Date
              title: string
              type: MediaType
              publicationStatus: PublicationStatus
              tags: Array<MediaTags>
              date?: Date | null
              startTrim?: number | null
              endTrim?: number | null
              liveStreamId?: string | null
              videoClipId?: string | null
              imageId?: string | null
              thumbnailId?: string | null
              ownerOrganismId?: string | null
              pinned?: boolean | null
              liveStream?: {
                __typename?: 'LiveStream'
                id: string
                playbackUrl: string
                ingestUrl?: string | null
                wildmokaEventID?: string | null
                videoEngine: VideoEngine
                state: LiveStreamState
                streamId?: string | null
                sveBroadcastId?: string | null
                sveChannelId?: string | null
                externalFeedProvider?: ExternalFeedType | null
                externalFeedUrl?: string | null
                event?: {
                  __typename?: 'WMEvent'
                  id: string
                  name: string
                  description?: string | null
                  testStartDate: Date
                  editoStartDate: Date
                  editoStopDate: Date
                  stopDate: Date
                  state: WmEventState
                  notifEmail?: string | null
                  clipCreationAtEnd: boolean
                  clipPublicationAtEnd: boolean
                  livePublication: boolean
                  stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
                  live?: {
                    __typename?: 'WMEventLive'
                    id: string
                    publishStatus: WmLiveState
                    type: WmLiveType
                    autoAnnounceDate?: Date | null
                    template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
                  } | null
                  clipTemplate?: {
                    __typename?: 'WMEventTemplate'
                    id: string
                    name: string
                    type: WmTemplateType
                  } | null
                } | null
                sveBroadcast?: {
                  __typename?: 'SVEBroadcast'
                  id: string
                  status: SveBroadcastState
                  channelId: string
                  startDate?: Date | null
                  clipStartDate?: Date | null
                  stopDate?: Date | null
                  scheduledStartDate: Date
                  scheduledClipStartDate: Date
                  scheduledStopDate: Date
                } | null
                sveLiveMonitoring?: {
                  __typename?: 'SVELiveMonitoring'
                  alerts?: {
                    __typename?: 'SVELiveMonitoringAlerts'
                    audioNotDetected?: string | null
                    initialProbeTakingLonger?: string | null
                    videoNotDetected?: string | null
                    RTMPHasNoAudioVideo?: string | null
                    RTMPStreamNotFound?: string | null
                  } | null
                  stats?: {
                    __typename?: 'SVELiveMonitoringStats'
                    networkIn?: string | null
                    droppedFrames?: string | null
                    inputVideoFrameRate?: string | null
                    outputAudioLevelLkfs?: string | null
                  } | null
                } | null
              } | null
              videoClip?: {
                __typename?: 'VideoClip'
                id: string
                playbackUrl: string
                downloadLink: string
                status?: VideoClipStatus | null
                updatedAt: Date
                createdAt: Date
                fileId?: string | null
                error?: {
                  __typename?: 'VideoClipError'
                  guid: string
                  workflowStatus: string
                  workflowErrorAt: string
                  errorMessage: string
                  errorDetails: string
                } | null
                captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
                externalFeed?: {
                  __typename?: 'ExternalFeed'
                  externalFeedProvider: ExternalFeedType
                  externalFeedUrl: string
                } | null
              } | null
              image?: {
                __typename?: 'File'
                id: string
                filename: string
                size: number
                mimetype: string
                downloadUrl: string
                thumbUrl?: string | null
                createdAt: Date
                metadata?: {
                  __typename?: 'FileMetadata'
                  title?: string | null
                  description?: string | null
                  aspect?: number | null
                  width?: number | null
                  height?: number | null
                  sourceFileId?: string | null
                } | null
              } | null
              thumbnail?: {
                __typename?: 'Media'
                id: string
                image?: {
                  __typename?: 'File'
                  id: string
                  filename: string
                  size: number
                  mimetype: string
                  downloadUrl: string
                  thumbUrl?: string | null
                  createdAt: Date
                  metadata?: {
                    __typename?: 'FileMetadata'
                    title?: string | null
                    description?: string | null
                    aspect?: number | null
                    width?: number | null
                    height?: number | null
                    sourceFileId?: string | null
                  } | null
                } | null
              } | null
              timelineMarkers?: Array<{
                __typename?: 'Marker'
                title: string
                time: number
                icon?: string | null
              }> | null
            } | null
            transparentLightImage?: {
              __typename?: 'Media'
              id: string
              createdAt: Date
              updatedAt: Date
              title: string
              type: MediaType
              publicationStatus: PublicationStatus
              tags: Array<MediaTags>
              date?: Date | null
              startTrim?: number | null
              endTrim?: number | null
              liveStreamId?: string | null
              videoClipId?: string | null
              imageId?: string | null
              thumbnailId?: string | null
              ownerOrganismId?: string | null
              pinned?: boolean | null
              liveStream?: {
                __typename?: 'LiveStream'
                id: string
                playbackUrl: string
                ingestUrl?: string | null
                wildmokaEventID?: string | null
                videoEngine: VideoEngine
                state: LiveStreamState
                streamId?: string | null
                sveBroadcastId?: string | null
                sveChannelId?: string | null
                externalFeedProvider?: ExternalFeedType | null
                externalFeedUrl?: string | null
                event?: {
                  __typename?: 'WMEvent'
                  id: string
                  name: string
                  description?: string | null
                  testStartDate: Date
                  editoStartDate: Date
                  editoStopDate: Date
                  stopDate: Date
                  state: WmEventState
                  notifEmail?: string | null
                  clipCreationAtEnd: boolean
                  clipPublicationAtEnd: boolean
                  livePublication: boolean
                  stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
                  live?: {
                    __typename?: 'WMEventLive'
                    id: string
                    publishStatus: WmLiveState
                    type: WmLiveType
                    autoAnnounceDate?: Date | null
                    template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
                  } | null
                  clipTemplate?: {
                    __typename?: 'WMEventTemplate'
                    id: string
                    name: string
                    type: WmTemplateType
                  } | null
                } | null
                sveBroadcast?: {
                  __typename?: 'SVEBroadcast'
                  id: string
                  status: SveBroadcastState
                  channelId: string
                  startDate?: Date | null
                  clipStartDate?: Date | null
                  stopDate?: Date | null
                  scheduledStartDate: Date
                  scheduledClipStartDate: Date
                  scheduledStopDate: Date
                } | null
                sveLiveMonitoring?: {
                  __typename?: 'SVELiveMonitoring'
                  alerts?: {
                    __typename?: 'SVELiveMonitoringAlerts'
                    audioNotDetected?: string | null
                    initialProbeTakingLonger?: string | null
                    videoNotDetected?: string | null
                    RTMPHasNoAudioVideo?: string | null
                    RTMPStreamNotFound?: string | null
                  } | null
                  stats?: {
                    __typename?: 'SVELiveMonitoringStats'
                    networkIn?: string | null
                    droppedFrames?: string | null
                    inputVideoFrameRate?: string | null
                    outputAudioLevelLkfs?: string | null
                  } | null
                } | null
              } | null
              videoClip?: {
                __typename?: 'VideoClip'
                id: string
                playbackUrl: string
                downloadLink: string
                status?: VideoClipStatus | null
                updatedAt: Date
                createdAt: Date
                fileId?: string | null
                error?: {
                  __typename?: 'VideoClipError'
                  guid: string
                  workflowStatus: string
                  workflowErrorAt: string
                  errorMessage: string
                  errorDetails: string
                } | null
                captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
                externalFeed?: {
                  __typename?: 'ExternalFeed'
                  externalFeedProvider: ExternalFeedType
                  externalFeedUrl: string
                } | null
              } | null
              image?: {
                __typename?: 'File'
                id: string
                filename: string
                size: number
                mimetype: string
                downloadUrl: string
                thumbUrl?: string | null
                createdAt: Date
                metadata?: {
                  __typename?: 'FileMetadata'
                  title?: string | null
                  description?: string | null
                  aspect?: number | null
                  width?: number | null
                  height?: number | null
                  sourceFileId?: string | null
                } | null
              } | null
              thumbnail?: {
                __typename?: 'Media'
                id: string
                image?: {
                  __typename?: 'File'
                  id: string
                  filename: string
                  size: number
                  mimetype: string
                  downloadUrl: string
                  thumbUrl?: string | null
                  createdAt: Date
                  metadata?: {
                    __typename?: 'FileMetadata'
                    title?: string | null
                    description?: string | null
                    aspect?: number | null
                    width?: number | null
                    height?: number | null
                    sourceFileId?: string | null
                  } | null
                } | null
              } | null
              timelineMarkers?: Array<{
                __typename?: 'Marker'
                title: string
                time: number
                icon?: string | null
              }> | null
            } | null
          }
          sports: Array<{ __typename?: 'Sport'; id: string }>
          bannerImage?: {
            __typename?: 'Media'
            id: string
            createdAt: Date
            updatedAt: Date
            title: string
            type: MediaType
            publicationStatus: PublicationStatus
            tags: Array<MediaTags>
            date?: Date | null
            startTrim?: number | null
            endTrim?: number | null
            liveStreamId?: string | null
            videoClipId?: string | null
            imageId?: string | null
            thumbnailId?: string | null
            ownerOrganismId?: string | null
            pinned?: boolean | null
            liveStream?: {
              __typename?: 'LiveStream'
              id: string
              playbackUrl: string
              ingestUrl?: string | null
              wildmokaEventID?: string | null
              videoEngine: VideoEngine
              state: LiveStreamState
              streamId?: string | null
              sveBroadcastId?: string | null
              sveChannelId?: string | null
              externalFeedProvider?: ExternalFeedType | null
              externalFeedUrl?: string | null
              event?: {
                __typename?: 'WMEvent'
                id: string
                name: string
                description?: string | null
                testStartDate: Date
                editoStartDate: Date
                editoStopDate: Date
                stopDate: Date
                state: WmEventState
                notifEmail?: string | null
                clipCreationAtEnd: boolean
                clipPublicationAtEnd: boolean
                livePublication: boolean
                stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
                live?: {
                  __typename?: 'WMEventLive'
                  id: string
                  publishStatus: WmLiveState
                  type: WmLiveType
                  autoAnnounceDate?: Date | null
                  template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
                } | null
                clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
              } | null
              sveBroadcast?: {
                __typename?: 'SVEBroadcast'
                id: string
                status: SveBroadcastState
                channelId: string
                startDate?: Date | null
                clipStartDate?: Date | null
                stopDate?: Date | null
                scheduledStartDate: Date
                scheduledClipStartDate: Date
                scheduledStopDate: Date
              } | null
              sveLiveMonitoring?: {
                __typename?: 'SVELiveMonitoring'
                alerts?: {
                  __typename?: 'SVELiveMonitoringAlerts'
                  audioNotDetected?: string | null
                  initialProbeTakingLonger?: string | null
                  videoNotDetected?: string | null
                  RTMPHasNoAudioVideo?: string | null
                  RTMPStreamNotFound?: string | null
                } | null
                stats?: {
                  __typename?: 'SVELiveMonitoringStats'
                  networkIn?: string | null
                  droppedFrames?: string | null
                  inputVideoFrameRate?: string | null
                  outputAudioLevelLkfs?: string | null
                } | null
              } | null
            } | null
            videoClip?: {
              __typename?: 'VideoClip'
              id: string
              playbackUrl: string
              downloadLink: string
              status?: VideoClipStatus | null
              updatedAt: Date
              createdAt: Date
              fileId?: string | null
              error?: {
                __typename?: 'VideoClipError'
                guid: string
                workflowStatus: string
                workflowErrorAt: string
                errorMessage: string
                errorDetails: string
              } | null
              captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
              externalFeed?: {
                __typename?: 'ExternalFeed'
                externalFeedProvider: ExternalFeedType
                externalFeedUrl: string
              } | null
            } | null
            image?: {
              __typename?: 'File'
              id: string
              filename: string
              size: number
              mimetype: string
              downloadUrl: string
              thumbUrl?: string | null
              createdAt: Date
              metadata?: {
                __typename?: 'FileMetadata'
                title?: string | null
                description?: string | null
                aspect?: number | null
                width?: number | null
                height?: number | null
                sourceFileId?: string | null
              } | null
            } | null
            thumbnail?: {
              __typename?: 'Media'
              id: string
              image?: {
                __typename?: 'File'
                id: string
                filename: string
                size: number
                mimetype: string
                downloadUrl: string
                thumbUrl?: string | null
                createdAt: Date
                metadata?: {
                  __typename?: 'FileMetadata'
                  title?: string | null
                  description?: string | null
                  aspect?: number | null
                  width?: number | null
                  height?: number | null
                  sourceFileId?: string | null
                } | null
              } | null
            } | null
            timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
          } | null
          adminBadge?: {
            __typename?: 'Media'
            id: string
            createdAt: Date
            updatedAt: Date
            title: string
            type: MediaType
            publicationStatus: PublicationStatus
            tags: Array<MediaTags>
            date?: Date | null
            startTrim?: number | null
            endTrim?: number | null
            liveStreamId?: string | null
            videoClipId?: string | null
            imageId?: string | null
            thumbnailId?: string | null
            ownerOrganismId?: string | null
            pinned?: boolean | null
            liveStream?: {
              __typename?: 'LiveStream'
              id: string
              playbackUrl: string
              ingestUrl?: string | null
              wildmokaEventID?: string | null
              videoEngine: VideoEngine
              state: LiveStreamState
              streamId?: string | null
              sveBroadcastId?: string | null
              sveChannelId?: string | null
              externalFeedProvider?: ExternalFeedType | null
              externalFeedUrl?: string | null
              event?: {
                __typename?: 'WMEvent'
                id: string
                name: string
                description?: string | null
                testStartDate: Date
                editoStartDate: Date
                editoStopDate: Date
                stopDate: Date
                state: WmEventState
                notifEmail?: string | null
                clipCreationAtEnd: boolean
                clipPublicationAtEnd: boolean
                livePublication: boolean
                stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
                live?: {
                  __typename?: 'WMEventLive'
                  id: string
                  publishStatus: WmLiveState
                  type: WmLiveType
                  autoAnnounceDate?: Date | null
                  template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
                } | null
                clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
              } | null
              sveBroadcast?: {
                __typename?: 'SVEBroadcast'
                id: string
                status: SveBroadcastState
                channelId: string
                startDate?: Date | null
                clipStartDate?: Date | null
                stopDate?: Date | null
                scheduledStartDate: Date
                scheduledClipStartDate: Date
                scheduledStopDate: Date
              } | null
              sveLiveMonitoring?: {
                __typename?: 'SVELiveMonitoring'
                alerts?: {
                  __typename?: 'SVELiveMonitoringAlerts'
                  audioNotDetected?: string | null
                  initialProbeTakingLonger?: string | null
                  videoNotDetected?: string | null
                  RTMPHasNoAudioVideo?: string | null
                  RTMPStreamNotFound?: string | null
                } | null
                stats?: {
                  __typename?: 'SVELiveMonitoringStats'
                  networkIn?: string | null
                  droppedFrames?: string | null
                  inputVideoFrameRate?: string | null
                  outputAudioLevelLkfs?: string | null
                } | null
              } | null
            } | null
            videoClip?: {
              __typename?: 'VideoClip'
              id: string
              playbackUrl: string
              downloadLink: string
              status?: VideoClipStatus | null
              updatedAt: Date
              createdAt: Date
              fileId?: string | null
              error?: {
                __typename?: 'VideoClipError'
                guid: string
                workflowStatus: string
                workflowErrorAt: string
                errorMessage: string
                errorDetails: string
              } | null
              captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
              externalFeed?: {
                __typename?: 'ExternalFeed'
                externalFeedProvider: ExternalFeedType
                externalFeedUrl: string
              } | null
            } | null
            image?: {
              __typename?: 'File'
              id: string
              filename: string
              size: number
              mimetype: string
              downloadUrl: string
              thumbUrl?: string | null
              createdAt: Date
              metadata?: {
                __typename?: 'FileMetadata'
                title?: string | null
                description?: string | null
                aspect?: number | null
                width?: number | null
                height?: number | null
                sourceFileId?: string | null
              } | null
            } | null
            thumbnail?: {
              __typename?: 'Media'
              id: string
              image?: {
                __typename?: 'File'
                id: string
                filename: string
                size: number
                mimetype: string
                downloadUrl: string
                thumbUrl?: string | null
                createdAt: Date
                metadata?: {
                  __typename?: 'FileMetadata'
                  title?: string | null
                  description?: string | null
                  aspect?: number | null
                  width?: number | null
                  height?: number | null
                  sourceFileId?: string | null
                } | null
              } | null
            } | null
            timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
          } | null
          bannerVideo?: {
            __typename?: 'Media'
            id: string
            videoClip?: { __typename?: 'VideoClip'; id: string; playbackUrl: string } | null
          } | null
          pageWidgets?: Array<{
            __typename?: 'CustomPageWidget'
            name: CustomPageWidgetName
            position: number
            display?: CustomPageDisplayTypes | null
            contentDisplay?: ContentSetContentDisplay | null
            cardsDisplay?: CardsDisplay | null
            options?: {
              __typename?: 'CustomPageWidgetOptions'
              editedCategoryId?: string | null
              sportItemSetType?: SportItemSetType | null
              replays?: {
                __typename?: 'ReplaysWidget'
                defaultLocale: string
                maxReplays?: number | null
                depthInDays?: number | null
                nameT: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }>
              } | null
              externalContent?: {
                __typename?: 'ExternalContentWidget'
                name?: string | null
                defaultLocale: string
                maxItemsCount: number
                source: WidgetExternalContentSource
                nameT: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }>
                period?: { __typename?: 'StartEndDates'; startDate: Date; endDate: Date } | null
                wecContent?: {
                  __typename?: 'WECNewsContent'
                  teamECMIds?: Array<string> | null
                  driverECMIds?: Array<string> | null
                  carECMIds?: Array<string> | null
                  categoryECMIds?: Array<string> | null
                  raceECMIds?: Array<string> | null
                } | null
                rssContent?: { __typename?: 'RSSFeedContent'; feedUrl: string } | null
              } | null
            } | null
          }> | null
          offers: Array<{
            __typename?: 'PurchasableOffer'
            id: string
            mode: OfferMode
            delay?: number | null
            expiredAt?: Date | null
            publicationStatus: PublicationStatus
            title: string
            defaultLocale?: string | null
            url?: string | null
            bannerImageId?: string | null
            thumbnailImageId?: string | null
            freeWithLicense: boolean
            position?: number | null
            titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
            description: Array<{ __typename?: 'OfferDescription'; header: string; content: string }>
            descriptionT?: Array<{
              __typename?: 'ContentTranslationDescriptionPurchasableOffer'
              locale: string
              content?: Array<{ __typename?: 'OfferDescription'; header: string; content: string }> | null
            }> | null
            products: Array<{
              __typename?: 'Product'
              productId: string
              platform: Platform
              period?: Period | null
              publicationStatus: PublicationStatus
              purchasableOfferId?: string | null
              licensedUserOnly?: boolean | null
              premiumOf?: Array<string> | null
              prices: Array<{ __typename?: 'Price'; value: number; currency: string }>
            }>
          }>
          license?: { __typename?: 'OrganismLicense'; enabled: boolean; api?: LicenseApi | null } | null
          tags?: Array<{ __typename?: 'Tag'; id: string; title: string; subTags?: Array<string> | null }> | null
        }> | null
        titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
        subtitleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
        descriptionT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
        billboard?: {
          __typename?: 'Media'
          id: string
          videoClip?: { __typename?: 'VideoClip'; id: string; playbackUrl: string } | null
        } | null
        teaserVideo?: {
          __typename?: 'Media'
          id: string
          videoClip?: { __typename?: 'VideoClip'; id: string; playbackUrl: string } | null
        } | null
        image?: {
          __typename?: 'Media'
          id: string
          image?: { __typename?: 'File'; id: string; thumbUrl?: string | null; downloadUrl: string } | null
        } | null
        categories: Array<{ __typename?: 'Category'; id: string; title: string }>
      } | null
    } | null
    items?: Array<{ __typename?: 'SportItem'; id: string; title: string }> | null
    bannerImage?: {
      __typename?: 'Media'
      id: string
      image?: { __typename?: 'File'; id: string; thumbUrl?: string | null; downloadUrl: string } | null
    } | null
    bannerVideo?: {
      __typename?: 'Media'
      id: string
      videoClip?: { __typename?: 'VideoClip'; id: string; playbackUrl: string } | null
    } | null
    sportEvent?: {
      __typename?: 'SportEvent'
      startDate: Date
      endDate: Date
      state: SportEventState
      liveTemplateId?: string | null
      backstageTemplateId?: string | null
      clipTemplateId?: string | null
    } | null
    categories: Array<{ __typename?: 'Category'; id: string; title: string }>
    legalRestrictions: Array<{
      __typename?: 'LegalRestriction'
      id: string
      description?: string | null
      countries: Array<string>
      type: RestrictionType
      timeSlots?: { __typename?: 'TimeSlots'; from: string; to: string } | null
    }>
    cac?: {
      __typename?: 'ContentAccessControl'
      userAuthenticationRule?: CacUserAuthenticationRule | null
      userLocationRule?: {
        __typename?: 'CACUserLocationRule'
        distance: number
        condition: UserLocationCondition
        location: { __typename?: 'GeoLocation'; latitude: number; longitude: number }
      } | null
    } | null
    streams?: Array<{ __typename?: 'WMStream'; id: string; name: string }> | null
    sveStreams: Array<{
      __typename?: 'SVEStream'
      sveChannelId: string
      socialNetworkWMStreamId?: string | null
      outputStreamId?: string | null
    }>
    tag?: { __typename?: 'Tag'; id: string; title: string } | null
    contentPushServiceGenericStreams?: Array<{
      __typename?: 'ContentPushStream'
      id: string
      name: string
      createdAt: Date
      updatedAt: Date
    }> | null
    noSpoilerMode?: { __typename?: 'NoSpoilerMode'; enabled: boolean; minutesBeforeDisabling: number } | null
  } | null
}

export type SportItemSetsWithChildrenQueryVariables = Exact<{
  filters?: InputMaybe<SportItemSetFilters>
}>

export type SportItemSetsWithChildrenQuery = {
  __typename?: 'Query'
  sportItemSets: {
    __typename?: 'SportItemSetsPageResult'
    totalCount: number
    sportItemSets: Array<{
      __typename?: 'SportItemSet'
      id: string
      type: SportItemSetType
      ownerOrganismId?: string | null
      title: string
      items?: Array<{ __typename?: 'SportItem'; id: string; videoContentType: VideoContentType; title: string }> | null
    }>
  }
}

export type SportItemSetHasLivesRunningQueryVariables = Exact<{
  sportItemSetId?: InputMaybe<Scalars['ID']>
  filters?: InputMaybe<SportItemFilters>
}>

export type SportItemSetHasLivesRunningQuery = {
  __typename?: 'Query'
  sportItemSet?: {
    __typename?: 'SportItemSet'
    id: string
    items?: Array<{ __typename?: 'SportItem'; id: string }> | null
  } | null
}

export type SportItemSetAvailableSveChannelIdsQueryVariables = Exact<{
  sportItemSetId: Scalars['ID']
}>

export type SportItemSetAvailableSveChannelIdsQuery = {
  __typename?: 'Query'
  sportItemSet?: { __typename?: 'SportItemSet'; availableSVEChannelIds: Array<string> } | null
}

export type CreateSportItemSetMutationVariables = Exact<{
  input: CreateSportItemSetInput
}>

export type CreateSportItemSetMutation = {
  __typename?: 'Mutation'
  createSportItemSet: {
    __typename: 'SportItemSet'
    id: string
    sportIds: Array<string>
    type: SportItemSetType
    title: string
    subtitle?: string | null
    description?: string | null
    defaultLocale?: string | null
    publicationStatus: PublicationStatus
    ownerOrganismId?: string | null
    offerIds: Array<string>
    restrictionLevel: PremiumAccess
    websiteUrl?: string | null
    lockPublishingLive?: boolean | null
    lockPublishingClip?: boolean | null
    bannerImageFileId?: string | null
    bannerVideoId?: string | null
    testPeriodEnd?: Date | null
    categoriesIds: Array<string>
    videoEngine: VideoEngine
    streamsIds?: Array<string> | null
    wmClippingTemplateId?: string | null
    sveInputLossImageMediaId?: string | null
    sveOverlayLogoImageMediaId?: string | null
    visibleInMultisportApplication: boolean
    activateSportItemChatByDefault: boolean
    contentPushServiceNames?: Array<ContentPushStreamServiceName> | null
    subTags?: Array<string> | null
    activateGreenPlayback?: boolean | null
    adCampaignId?: string | null
    contentPushServiceGenericStreamIds?: Array<string> | null
    contentPushServiceLiveEventIds?: Array<string> | null
    disableReadParallelLivestreamsLimit?: boolean | null
    sports: Array<{ __typename?: 'Sport'; id: string; title: string; color: string }>
    titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    subtitleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    descriptionT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    ownerOrganism?: { __typename?: 'Organism'; title: string } | null
    offers: Array<{ __typename?: 'PurchasableOffer'; id: string; title: string }>
    tabs?: Array<{
      __typename?: 'Tab'
      title: string
      url: string
      position: TabPosition
      blank?: boolean | null
      titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
      icon: { __typename?: 'Icon'; title: string; svg: string }
    }> | null
    sponsorPlatform?: {
      __typename?: 'SponsorPlatform'
      linkId?: string | null
      display?: Array<SponsorPlatformDisplayOptions> | null
      videoPreroll?: Array<SponsorPlatformPrerollOptions> | null
      link?: {
        __typename: 'Link'
        id: string
        ownerOrganismIds: Array<string>
        tag?: LinkTag | null
        title: string
        subtitle?: string | null
        description?: string | null
        defaultLocale?: string | null
        clicks: number
        uniqueClicks: number
        views: number
        countries: Array<string | null>
        uniqueViews: number
        teaserVideoId?: string | null
        gamTagUrl?: string | null
        billboardId?: string | null
        url?: string | null
        imageId: string
        categoriesIds: Array<string>
        publicationStatus: PublicationStatus
        ownerOrganisms?: Array<{
          __typename?: 'Organism'
          id: string
          type: OrganismType
          whiteLabel: boolean
          title: string
          description?: string | null
          publicationStatus: PublicationStatus
          defaultLocale?: string | null
          onFront: boolean
          frontPosition?: number | null
          officialWebSiteUrl: string
          sportIds: Array<string>
          forbiddenKeyWords?: Array<string> | null
          enableExternalSources?: boolean | null
          availableExternalSources?: Array<WidgetExternalContentSource> | null
          streamsIds?: Array<string> | null
          wmProfileId?: string | null
          sveChannelIds?: Array<string> | null
          organismPageApplicationDesignId?: string | null
          contentPageApplicationDesignId?: string | null
          adminsAutomatedEmails?: boolean | null
          visibleInMultisportApplication?: boolean | null
          titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
          descriptionT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
          logo: {
            __typename?: 'Logo'
            coloredImage?: {
              __typename?: 'Media'
              id: string
              createdAt: Date
              updatedAt: Date
              title: string
              type: MediaType
              publicationStatus: PublicationStatus
              tags: Array<MediaTags>
              date?: Date | null
              startTrim?: number | null
              endTrim?: number | null
              liveStreamId?: string | null
              videoClipId?: string | null
              imageId?: string | null
              thumbnailId?: string | null
              ownerOrganismId?: string | null
              pinned?: boolean | null
              liveStream?: {
                __typename?: 'LiveStream'
                id: string
                playbackUrl: string
                ingestUrl?: string | null
                wildmokaEventID?: string | null
                videoEngine: VideoEngine
                state: LiveStreamState
                streamId?: string | null
                sveBroadcastId?: string | null
                sveChannelId?: string | null
                externalFeedProvider?: ExternalFeedType | null
                externalFeedUrl?: string | null
                event?: {
                  __typename?: 'WMEvent'
                  id: string
                  name: string
                  description?: string | null
                  testStartDate: Date
                  editoStartDate: Date
                  editoStopDate: Date
                  stopDate: Date
                  state: WmEventState
                  notifEmail?: string | null
                  clipCreationAtEnd: boolean
                  clipPublicationAtEnd: boolean
                  livePublication: boolean
                  stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
                  live?: {
                    __typename?: 'WMEventLive'
                    id: string
                    publishStatus: WmLiveState
                    type: WmLiveType
                    autoAnnounceDate?: Date | null
                    template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
                  } | null
                  clipTemplate?: {
                    __typename?: 'WMEventTemplate'
                    id: string
                    name: string
                    type: WmTemplateType
                  } | null
                } | null
                sveBroadcast?: {
                  __typename?: 'SVEBroadcast'
                  id: string
                  status: SveBroadcastState
                  channelId: string
                  startDate?: Date | null
                  clipStartDate?: Date | null
                  stopDate?: Date | null
                  scheduledStartDate: Date
                  scheduledClipStartDate: Date
                  scheduledStopDate: Date
                } | null
                sveLiveMonitoring?: {
                  __typename?: 'SVELiveMonitoring'
                  alerts?: {
                    __typename?: 'SVELiveMonitoringAlerts'
                    audioNotDetected?: string | null
                    initialProbeTakingLonger?: string | null
                    videoNotDetected?: string | null
                    RTMPHasNoAudioVideo?: string | null
                    RTMPStreamNotFound?: string | null
                  } | null
                  stats?: {
                    __typename?: 'SVELiveMonitoringStats'
                    networkIn?: string | null
                    droppedFrames?: string | null
                    inputVideoFrameRate?: string | null
                    outputAudioLevelLkfs?: string | null
                  } | null
                } | null
              } | null
              videoClip?: {
                __typename?: 'VideoClip'
                id: string
                playbackUrl: string
                downloadLink: string
                status?: VideoClipStatus | null
                updatedAt: Date
                createdAt: Date
                fileId?: string | null
                error?: {
                  __typename?: 'VideoClipError'
                  guid: string
                  workflowStatus: string
                  workflowErrorAt: string
                  errorMessage: string
                  errorDetails: string
                } | null
                captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
                externalFeed?: {
                  __typename?: 'ExternalFeed'
                  externalFeedProvider: ExternalFeedType
                  externalFeedUrl: string
                } | null
              } | null
              image?: {
                __typename?: 'File'
                id: string
                filename: string
                size: number
                mimetype: string
                downloadUrl: string
                thumbUrl?: string | null
                createdAt: Date
                metadata?: {
                  __typename?: 'FileMetadata'
                  title?: string | null
                  description?: string | null
                  aspect?: number | null
                  width?: number | null
                  height?: number | null
                  sourceFileId?: string | null
                } | null
              } | null
              thumbnail?: {
                __typename?: 'Media'
                id: string
                image?: {
                  __typename?: 'File'
                  id: string
                  filename: string
                  size: number
                  mimetype: string
                  downloadUrl: string
                  thumbUrl?: string | null
                  createdAt: Date
                  metadata?: {
                    __typename?: 'FileMetadata'
                    title?: string | null
                    description?: string | null
                    aspect?: number | null
                    width?: number | null
                    height?: number | null
                    sourceFileId?: string | null
                  } | null
                } | null
              } | null
              timelineMarkers?: Array<{
                __typename?: 'Marker'
                title: string
                time: number
                icon?: string | null
              }> | null
            } | null
            transparentDarkImage?: {
              __typename?: 'Media'
              id: string
              createdAt: Date
              updatedAt: Date
              title: string
              type: MediaType
              publicationStatus: PublicationStatus
              tags: Array<MediaTags>
              date?: Date | null
              startTrim?: number | null
              endTrim?: number | null
              liveStreamId?: string | null
              videoClipId?: string | null
              imageId?: string | null
              thumbnailId?: string | null
              ownerOrganismId?: string | null
              pinned?: boolean | null
              liveStream?: {
                __typename?: 'LiveStream'
                id: string
                playbackUrl: string
                ingestUrl?: string | null
                wildmokaEventID?: string | null
                videoEngine: VideoEngine
                state: LiveStreamState
                streamId?: string | null
                sveBroadcastId?: string | null
                sveChannelId?: string | null
                externalFeedProvider?: ExternalFeedType | null
                externalFeedUrl?: string | null
                event?: {
                  __typename?: 'WMEvent'
                  id: string
                  name: string
                  description?: string | null
                  testStartDate: Date
                  editoStartDate: Date
                  editoStopDate: Date
                  stopDate: Date
                  state: WmEventState
                  notifEmail?: string | null
                  clipCreationAtEnd: boolean
                  clipPublicationAtEnd: boolean
                  livePublication: boolean
                  stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
                  live?: {
                    __typename?: 'WMEventLive'
                    id: string
                    publishStatus: WmLiveState
                    type: WmLiveType
                    autoAnnounceDate?: Date | null
                    template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
                  } | null
                  clipTemplate?: {
                    __typename?: 'WMEventTemplate'
                    id: string
                    name: string
                    type: WmTemplateType
                  } | null
                } | null
                sveBroadcast?: {
                  __typename?: 'SVEBroadcast'
                  id: string
                  status: SveBroadcastState
                  channelId: string
                  startDate?: Date | null
                  clipStartDate?: Date | null
                  stopDate?: Date | null
                  scheduledStartDate: Date
                  scheduledClipStartDate: Date
                  scheduledStopDate: Date
                } | null
                sveLiveMonitoring?: {
                  __typename?: 'SVELiveMonitoring'
                  alerts?: {
                    __typename?: 'SVELiveMonitoringAlerts'
                    audioNotDetected?: string | null
                    initialProbeTakingLonger?: string | null
                    videoNotDetected?: string | null
                    RTMPHasNoAudioVideo?: string | null
                    RTMPStreamNotFound?: string | null
                  } | null
                  stats?: {
                    __typename?: 'SVELiveMonitoringStats'
                    networkIn?: string | null
                    droppedFrames?: string | null
                    inputVideoFrameRate?: string | null
                    outputAudioLevelLkfs?: string | null
                  } | null
                } | null
              } | null
              videoClip?: {
                __typename?: 'VideoClip'
                id: string
                playbackUrl: string
                downloadLink: string
                status?: VideoClipStatus | null
                updatedAt: Date
                createdAt: Date
                fileId?: string | null
                error?: {
                  __typename?: 'VideoClipError'
                  guid: string
                  workflowStatus: string
                  workflowErrorAt: string
                  errorMessage: string
                  errorDetails: string
                } | null
                captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
                externalFeed?: {
                  __typename?: 'ExternalFeed'
                  externalFeedProvider: ExternalFeedType
                  externalFeedUrl: string
                } | null
              } | null
              image?: {
                __typename?: 'File'
                id: string
                filename: string
                size: number
                mimetype: string
                downloadUrl: string
                thumbUrl?: string | null
                createdAt: Date
                metadata?: {
                  __typename?: 'FileMetadata'
                  title?: string | null
                  description?: string | null
                  aspect?: number | null
                  width?: number | null
                  height?: number | null
                  sourceFileId?: string | null
                } | null
              } | null
              thumbnail?: {
                __typename?: 'Media'
                id: string
                image?: {
                  __typename?: 'File'
                  id: string
                  filename: string
                  size: number
                  mimetype: string
                  downloadUrl: string
                  thumbUrl?: string | null
                  createdAt: Date
                  metadata?: {
                    __typename?: 'FileMetadata'
                    title?: string | null
                    description?: string | null
                    aspect?: number | null
                    width?: number | null
                    height?: number | null
                    sourceFileId?: string | null
                  } | null
                } | null
              } | null
              timelineMarkers?: Array<{
                __typename?: 'Marker'
                title: string
                time: number
                icon?: string | null
              }> | null
            } | null
            transparentLightImage?: {
              __typename?: 'Media'
              id: string
              createdAt: Date
              updatedAt: Date
              title: string
              type: MediaType
              publicationStatus: PublicationStatus
              tags: Array<MediaTags>
              date?: Date | null
              startTrim?: number | null
              endTrim?: number | null
              liveStreamId?: string | null
              videoClipId?: string | null
              imageId?: string | null
              thumbnailId?: string | null
              ownerOrganismId?: string | null
              pinned?: boolean | null
              liveStream?: {
                __typename?: 'LiveStream'
                id: string
                playbackUrl: string
                ingestUrl?: string | null
                wildmokaEventID?: string | null
                videoEngine: VideoEngine
                state: LiveStreamState
                streamId?: string | null
                sveBroadcastId?: string | null
                sveChannelId?: string | null
                externalFeedProvider?: ExternalFeedType | null
                externalFeedUrl?: string | null
                event?: {
                  __typename?: 'WMEvent'
                  id: string
                  name: string
                  description?: string | null
                  testStartDate: Date
                  editoStartDate: Date
                  editoStopDate: Date
                  stopDate: Date
                  state: WmEventState
                  notifEmail?: string | null
                  clipCreationAtEnd: boolean
                  clipPublicationAtEnd: boolean
                  livePublication: boolean
                  stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
                  live?: {
                    __typename?: 'WMEventLive'
                    id: string
                    publishStatus: WmLiveState
                    type: WmLiveType
                    autoAnnounceDate?: Date | null
                    template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
                  } | null
                  clipTemplate?: {
                    __typename?: 'WMEventTemplate'
                    id: string
                    name: string
                    type: WmTemplateType
                  } | null
                } | null
                sveBroadcast?: {
                  __typename?: 'SVEBroadcast'
                  id: string
                  status: SveBroadcastState
                  channelId: string
                  startDate?: Date | null
                  clipStartDate?: Date | null
                  stopDate?: Date | null
                  scheduledStartDate: Date
                  scheduledClipStartDate: Date
                  scheduledStopDate: Date
                } | null
                sveLiveMonitoring?: {
                  __typename?: 'SVELiveMonitoring'
                  alerts?: {
                    __typename?: 'SVELiveMonitoringAlerts'
                    audioNotDetected?: string | null
                    initialProbeTakingLonger?: string | null
                    videoNotDetected?: string | null
                    RTMPHasNoAudioVideo?: string | null
                    RTMPStreamNotFound?: string | null
                  } | null
                  stats?: {
                    __typename?: 'SVELiveMonitoringStats'
                    networkIn?: string | null
                    droppedFrames?: string | null
                    inputVideoFrameRate?: string | null
                    outputAudioLevelLkfs?: string | null
                  } | null
                } | null
              } | null
              videoClip?: {
                __typename?: 'VideoClip'
                id: string
                playbackUrl: string
                downloadLink: string
                status?: VideoClipStatus | null
                updatedAt: Date
                createdAt: Date
                fileId?: string | null
                error?: {
                  __typename?: 'VideoClipError'
                  guid: string
                  workflowStatus: string
                  workflowErrorAt: string
                  errorMessage: string
                  errorDetails: string
                } | null
                captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
                externalFeed?: {
                  __typename?: 'ExternalFeed'
                  externalFeedProvider: ExternalFeedType
                  externalFeedUrl: string
                } | null
              } | null
              image?: {
                __typename?: 'File'
                id: string
                filename: string
                size: number
                mimetype: string
                downloadUrl: string
                thumbUrl?: string | null
                createdAt: Date
                metadata?: {
                  __typename?: 'FileMetadata'
                  title?: string | null
                  description?: string | null
                  aspect?: number | null
                  width?: number | null
                  height?: number | null
                  sourceFileId?: string | null
                } | null
              } | null
              thumbnail?: {
                __typename?: 'Media'
                id: string
                image?: {
                  __typename?: 'File'
                  id: string
                  filename: string
                  size: number
                  mimetype: string
                  downloadUrl: string
                  thumbUrl?: string | null
                  createdAt: Date
                  metadata?: {
                    __typename?: 'FileMetadata'
                    title?: string | null
                    description?: string | null
                    aspect?: number | null
                    width?: number | null
                    height?: number | null
                    sourceFileId?: string | null
                  } | null
                } | null
              } | null
              timelineMarkers?: Array<{
                __typename?: 'Marker'
                title: string
                time: number
                icon?: string | null
              }> | null
            } | null
          }
          sports: Array<{ __typename?: 'Sport'; id: string }>
          bannerImage?: {
            __typename?: 'Media'
            id: string
            createdAt: Date
            updatedAt: Date
            title: string
            type: MediaType
            publicationStatus: PublicationStatus
            tags: Array<MediaTags>
            date?: Date | null
            startTrim?: number | null
            endTrim?: number | null
            liveStreamId?: string | null
            videoClipId?: string | null
            imageId?: string | null
            thumbnailId?: string | null
            ownerOrganismId?: string | null
            pinned?: boolean | null
            liveStream?: {
              __typename?: 'LiveStream'
              id: string
              playbackUrl: string
              ingestUrl?: string | null
              wildmokaEventID?: string | null
              videoEngine: VideoEngine
              state: LiveStreamState
              streamId?: string | null
              sveBroadcastId?: string | null
              sveChannelId?: string | null
              externalFeedProvider?: ExternalFeedType | null
              externalFeedUrl?: string | null
              event?: {
                __typename?: 'WMEvent'
                id: string
                name: string
                description?: string | null
                testStartDate: Date
                editoStartDate: Date
                editoStopDate: Date
                stopDate: Date
                state: WmEventState
                notifEmail?: string | null
                clipCreationAtEnd: boolean
                clipPublicationAtEnd: boolean
                livePublication: boolean
                stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
                live?: {
                  __typename?: 'WMEventLive'
                  id: string
                  publishStatus: WmLiveState
                  type: WmLiveType
                  autoAnnounceDate?: Date | null
                  template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
                } | null
                clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
              } | null
              sveBroadcast?: {
                __typename?: 'SVEBroadcast'
                id: string
                status: SveBroadcastState
                channelId: string
                startDate?: Date | null
                clipStartDate?: Date | null
                stopDate?: Date | null
                scheduledStartDate: Date
                scheduledClipStartDate: Date
                scheduledStopDate: Date
              } | null
              sveLiveMonitoring?: {
                __typename?: 'SVELiveMonitoring'
                alerts?: {
                  __typename?: 'SVELiveMonitoringAlerts'
                  audioNotDetected?: string | null
                  initialProbeTakingLonger?: string | null
                  videoNotDetected?: string | null
                  RTMPHasNoAudioVideo?: string | null
                  RTMPStreamNotFound?: string | null
                } | null
                stats?: {
                  __typename?: 'SVELiveMonitoringStats'
                  networkIn?: string | null
                  droppedFrames?: string | null
                  inputVideoFrameRate?: string | null
                  outputAudioLevelLkfs?: string | null
                } | null
              } | null
            } | null
            videoClip?: {
              __typename?: 'VideoClip'
              id: string
              playbackUrl: string
              downloadLink: string
              status?: VideoClipStatus | null
              updatedAt: Date
              createdAt: Date
              fileId?: string | null
              error?: {
                __typename?: 'VideoClipError'
                guid: string
                workflowStatus: string
                workflowErrorAt: string
                errorMessage: string
                errorDetails: string
              } | null
              captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
              externalFeed?: {
                __typename?: 'ExternalFeed'
                externalFeedProvider: ExternalFeedType
                externalFeedUrl: string
              } | null
            } | null
            image?: {
              __typename?: 'File'
              id: string
              filename: string
              size: number
              mimetype: string
              downloadUrl: string
              thumbUrl?: string | null
              createdAt: Date
              metadata?: {
                __typename?: 'FileMetadata'
                title?: string | null
                description?: string | null
                aspect?: number | null
                width?: number | null
                height?: number | null
                sourceFileId?: string | null
              } | null
            } | null
            thumbnail?: {
              __typename?: 'Media'
              id: string
              image?: {
                __typename?: 'File'
                id: string
                filename: string
                size: number
                mimetype: string
                downloadUrl: string
                thumbUrl?: string | null
                createdAt: Date
                metadata?: {
                  __typename?: 'FileMetadata'
                  title?: string | null
                  description?: string | null
                  aspect?: number | null
                  width?: number | null
                  height?: number | null
                  sourceFileId?: string | null
                } | null
              } | null
            } | null
            timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
          } | null
          adminBadge?: {
            __typename?: 'Media'
            id: string
            createdAt: Date
            updatedAt: Date
            title: string
            type: MediaType
            publicationStatus: PublicationStatus
            tags: Array<MediaTags>
            date?: Date | null
            startTrim?: number | null
            endTrim?: number | null
            liveStreamId?: string | null
            videoClipId?: string | null
            imageId?: string | null
            thumbnailId?: string | null
            ownerOrganismId?: string | null
            pinned?: boolean | null
            liveStream?: {
              __typename?: 'LiveStream'
              id: string
              playbackUrl: string
              ingestUrl?: string | null
              wildmokaEventID?: string | null
              videoEngine: VideoEngine
              state: LiveStreamState
              streamId?: string | null
              sveBroadcastId?: string | null
              sveChannelId?: string | null
              externalFeedProvider?: ExternalFeedType | null
              externalFeedUrl?: string | null
              event?: {
                __typename?: 'WMEvent'
                id: string
                name: string
                description?: string | null
                testStartDate: Date
                editoStartDate: Date
                editoStopDate: Date
                stopDate: Date
                state: WmEventState
                notifEmail?: string | null
                clipCreationAtEnd: boolean
                clipPublicationAtEnd: boolean
                livePublication: boolean
                stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
                live?: {
                  __typename?: 'WMEventLive'
                  id: string
                  publishStatus: WmLiveState
                  type: WmLiveType
                  autoAnnounceDate?: Date | null
                  template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
                } | null
                clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
              } | null
              sveBroadcast?: {
                __typename?: 'SVEBroadcast'
                id: string
                status: SveBroadcastState
                channelId: string
                startDate?: Date | null
                clipStartDate?: Date | null
                stopDate?: Date | null
                scheduledStartDate: Date
                scheduledClipStartDate: Date
                scheduledStopDate: Date
              } | null
              sveLiveMonitoring?: {
                __typename?: 'SVELiveMonitoring'
                alerts?: {
                  __typename?: 'SVELiveMonitoringAlerts'
                  audioNotDetected?: string | null
                  initialProbeTakingLonger?: string | null
                  videoNotDetected?: string | null
                  RTMPHasNoAudioVideo?: string | null
                  RTMPStreamNotFound?: string | null
                } | null
                stats?: {
                  __typename?: 'SVELiveMonitoringStats'
                  networkIn?: string | null
                  droppedFrames?: string | null
                  inputVideoFrameRate?: string | null
                  outputAudioLevelLkfs?: string | null
                } | null
              } | null
            } | null
            videoClip?: {
              __typename?: 'VideoClip'
              id: string
              playbackUrl: string
              downloadLink: string
              status?: VideoClipStatus | null
              updatedAt: Date
              createdAt: Date
              fileId?: string | null
              error?: {
                __typename?: 'VideoClipError'
                guid: string
                workflowStatus: string
                workflowErrorAt: string
                errorMessage: string
                errorDetails: string
              } | null
              captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
              externalFeed?: {
                __typename?: 'ExternalFeed'
                externalFeedProvider: ExternalFeedType
                externalFeedUrl: string
              } | null
            } | null
            image?: {
              __typename?: 'File'
              id: string
              filename: string
              size: number
              mimetype: string
              downloadUrl: string
              thumbUrl?: string | null
              createdAt: Date
              metadata?: {
                __typename?: 'FileMetadata'
                title?: string | null
                description?: string | null
                aspect?: number | null
                width?: number | null
                height?: number | null
                sourceFileId?: string | null
              } | null
            } | null
            thumbnail?: {
              __typename?: 'Media'
              id: string
              image?: {
                __typename?: 'File'
                id: string
                filename: string
                size: number
                mimetype: string
                downloadUrl: string
                thumbUrl?: string | null
                createdAt: Date
                metadata?: {
                  __typename?: 'FileMetadata'
                  title?: string | null
                  description?: string | null
                  aspect?: number | null
                  width?: number | null
                  height?: number | null
                  sourceFileId?: string | null
                } | null
              } | null
            } | null
            timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
          } | null
          bannerVideo?: {
            __typename?: 'Media'
            id: string
            videoClip?: { __typename?: 'VideoClip'; id: string; playbackUrl: string } | null
          } | null
          pageWidgets?: Array<{
            __typename?: 'CustomPageWidget'
            name: CustomPageWidgetName
            position: number
            display?: CustomPageDisplayTypes | null
            contentDisplay?: ContentSetContentDisplay | null
            cardsDisplay?: CardsDisplay | null
            options?: {
              __typename?: 'CustomPageWidgetOptions'
              editedCategoryId?: string | null
              sportItemSetType?: SportItemSetType | null
              replays?: {
                __typename?: 'ReplaysWidget'
                defaultLocale: string
                maxReplays?: number | null
                depthInDays?: number | null
                nameT: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }>
              } | null
              externalContent?: {
                __typename?: 'ExternalContentWidget'
                name?: string | null
                defaultLocale: string
                maxItemsCount: number
                source: WidgetExternalContentSource
                nameT: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }>
                period?: { __typename?: 'StartEndDates'; startDate: Date; endDate: Date } | null
                wecContent?: {
                  __typename?: 'WECNewsContent'
                  teamECMIds?: Array<string> | null
                  driverECMIds?: Array<string> | null
                  carECMIds?: Array<string> | null
                  categoryECMIds?: Array<string> | null
                  raceECMIds?: Array<string> | null
                } | null
                rssContent?: { __typename?: 'RSSFeedContent'; feedUrl: string } | null
              } | null
            } | null
          }> | null
          offers: Array<{
            __typename?: 'PurchasableOffer'
            id: string
            mode: OfferMode
            delay?: number | null
            expiredAt?: Date | null
            publicationStatus: PublicationStatus
            title: string
            defaultLocale?: string | null
            url?: string | null
            bannerImageId?: string | null
            thumbnailImageId?: string | null
            freeWithLicense: boolean
            position?: number | null
            titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
            description: Array<{ __typename?: 'OfferDescription'; header: string; content: string }>
            descriptionT?: Array<{
              __typename?: 'ContentTranslationDescriptionPurchasableOffer'
              locale: string
              content?: Array<{ __typename?: 'OfferDescription'; header: string; content: string }> | null
            }> | null
            products: Array<{
              __typename?: 'Product'
              productId: string
              platform: Platform
              period?: Period | null
              publicationStatus: PublicationStatus
              purchasableOfferId?: string | null
              licensedUserOnly?: boolean | null
              premiumOf?: Array<string> | null
              prices: Array<{ __typename?: 'Price'; value: number; currency: string }>
            }>
          }>
          license?: { __typename?: 'OrganismLicense'; enabled: boolean; api?: LicenseApi | null } | null
          tags?: Array<{ __typename?: 'Tag'; id: string; title: string; subTags?: Array<string> | null }> | null
        }> | null
        titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
        subtitleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
        descriptionT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
        billboard?: {
          __typename?: 'Media'
          id: string
          videoClip?: { __typename?: 'VideoClip'; id: string; playbackUrl: string } | null
        } | null
        teaserVideo?: {
          __typename?: 'Media'
          id: string
          videoClip?: { __typename?: 'VideoClip'; id: string; playbackUrl: string } | null
        } | null
        image?: {
          __typename?: 'Media'
          id: string
          image?: { __typename?: 'File'; id: string; thumbUrl?: string | null; downloadUrl: string } | null
        } | null
        categories: Array<{ __typename?: 'Category'; id: string; title: string }>
      } | null
    } | null
    items?: Array<{ __typename?: 'SportItem'; id: string; title: string }> | null
    bannerImage?: {
      __typename?: 'Media'
      id: string
      image?: { __typename?: 'File'; id: string; thumbUrl?: string | null; downloadUrl: string } | null
    } | null
    bannerVideo?: {
      __typename?: 'Media'
      id: string
      videoClip?: { __typename?: 'VideoClip'; id: string; playbackUrl: string } | null
    } | null
    sportEvent?: {
      __typename?: 'SportEvent'
      startDate: Date
      endDate: Date
      state: SportEventState
      liveTemplateId?: string | null
      backstageTemplateId?: string | null
      clipTemplateId?: string | null
    } | null
    categories: Array<{ __typename?: 'Category'; id: string; title: string }>
    legalRestrictions: Array<{
      __typename?: 'LegalRestriction'
      id: string
      description?: string | null
      countries: Array<string>
      type: RestrictionType
      timeSlots?: { __typename?: 'TimeSlots'; from: string; to: string } | null
    }>
    cac?: {
      __typename?: 'ContentAccessControl'
      userAuthenticationRule?: CacUserAuthenticationRule | null
      userLocationRule?: {
        __typename?: 'CACUserLocationRule'
        distance: number
        condition: UserLocationCondition
        location: { __typename?: 'GeoLocation'; latitude: number; longitude: number }
      } | null
    } | null
    streams?: Array<{ __typename?: 'WMStream'; id: string; name: string }> | null
    sveStreams: Array<{
      __typename?: 'SVEStream'
      sveChannelId: string
      socialNetworkWMStreamId?: string | null
      outputStreamId?: string | null
    }>
    tag?: { __typename?: 'Tag'; id: string; title: string } | null
    contentPushServiceGenericStreams?: Array<{
      __typename?: 'ContentPushStream'
      id: string
      name: string
      createdAt: Date
      updatedAt: Date
    }> | null
    noSpoilerMode?: { __typename?: 'NoSpoilerMode'; enabled: boolean; minutesBeforeDisabling: number } | null
  }
}

export type UpdateSportItemSetMutationVariables = Exact<{
  input: UpdateSportItemSetInput
}>

export type UpdateSportItemSetMutation = {
  __typename?: 'Mutation'
  updateSportItemSet?: {
    __typename: 'SportItemSet'
    id: string
    sportIds: Array<string>
    type: SportItemSetType
    title: string
    subtitle?: string | null
    description?: string | null
    defaultLocale?: string | null
    publicationStatus: PublicationStatus
    ownerOrganismId?: string | null
    offerIds: Array<string>
    restrictionLevel: PremiumAccess
    websiteUrl?: string | null
    lockPublishingLive?: boolean | null
    lockPublishingClip?: boolean | null
    bannerImageFileId?: string | null
    bannerVideoId?: string | null
    testPeriodEnd?: Date | null
    categoriesIds: Array<string>
    videoEngine: VideoEngine
    streamsIds?: Array<string> | null
    wmClippingTemplateId?: string | null
    sveInputLossImageMediaId?: string | null
    sveOverlayLogoImageMediaId?: string | null
    visibleInMultisportApplication: boolean
    activateSportItemChatByDefault: boolean
    contentPushServiceNames?: Array<ContentPushStreamServiceName> | null
    subTags?: Array<string> | null
    activateGreenPlayback?: boolean | null
    adCampaignId?: string | null
    contentPushServiceGenericStreamIds?: Array<string> | null
    contentPushServiceLiveEventIds?: Array<string> | null
    disableReadParallelLivestreamsLimit?: boolean | null
    sports: Array<{ __typename?: 'Sport'; id: string; title: string; color: string }>
    titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    subtitleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    descriptionT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
    ownerOrganism?: { __typename?: 'Organism'; title: string } | null
    offers: Array<{ __typename?: 'PurchasableOffer'; id: string; title: string }>
    tabs?: Array<{
      __typename?: 'Tab'
      title: string
      url: string
      position: TabPosition
      blank?: boolean | null
      titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
      icon: { __typename?: 'Icon'; title: string; svg: string }
    }> | null
    sponsorPlatform?: {
      __typename?: 'SponsorPlatform'
      linkId?: string | null
      display?: Array<SponsorPlatformDisplayOptions> | null
      videoPreroll?: Array<SponsorPlatformPrerollOptions> | null
      link?: {
        __typename: 'Link'
        id: string
        ownerOrganismIds: Array<string>
        tag?: LinkTag | null
        title: string
        subtitle?: string | null
        description?: string | null
        defaultLocale?: string | null
        clicks: number
        uniqueClicks: number
        views: number
        countries: Array<string | null>
        uniqueViews: number
        teaserVideoId?: string | null
        gamTagUrl?: string | null
        billboardId?: string | null
        url?: string | null
        imageId: string
        categoriesIds: Array<string>
        publicationStatus: PublicationStatus
        ownerOrganisms?: Array<{
          __typename?: 'Organism'
          id: string
          type: OrganismType
          whiteLabel: boolean
          title: string
          description?: string | null
          publicationStatus: PublicationStatus
          defaultLocale?: string | null
          onFront: boolean
          frontPosition?: number | null
          officialWebSiteUrl: string
          sportIds: Array<string>
          forbiddenKeyWords?: Array<string> | null
          enableExternalSources?: boolean | null
          availableExternalSources?: Array<WidgetExternalContentSource> | null
          streamsIds?: Array<string> | null
          wmProfileId?: string | null
          sveChannelIds?: Array<string> | null
          organismPageApplicationDesignId?: string | null
          contentPageApplicationDesignId?: string | null
          adminsAutomatedEmails?: boolean | null
          visibleInMultisportApplication?: boolean | null
          titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
          descriptionT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
          logo: {
            __typename?: 'Logo'
            coloredImage?: {
              __typename?: 'Media'
              id: string
              createdAt: Date
              updatedAt: Date
              title: string
              type: MediaType
              publicationStatus: PublicationStatus
              tags: Array<MediaTags>
              date?: Date | null
              startTrim?: number | null
              endTrim?: number | null
              liveStreamId?: string | null
              videoClipId?: string | null
              imageId?: string | null
              thumbnailId?: string | null
              ownerOrganismId?: string | null
              pinned?: boolean | null
              liveStream?: {
                __typename?: 'LiveStream'
                id: string
                playbackUrl: string
                ingestUrl?: string | null
                wildmokaEventID?: string | null
                videoEngine: VideoEngine
                state: LiveStreamState
                streamId?: string | null
                sveBroadcastId?: string | null
                sveChannelId?: string | null
                externalFeedProvider?: ExternalFeedType | null
                externalFeedUrl?: string | null
                event?: {
                  __typename?: 'WMEvent'
                  id: string
                  name: string
                  description?: string | null
                  testStartDate: Date
                  editoStartDate: Date
                  editoStopDate: Date
                  stopDate: Date
                  state: WmEventState
                  notifEmail?: string | null
                  clipCreationAtEnd: boolean
                  clipPublicationAtEnd: boolean
                  livePublication: boolean
                  stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
                  live?: {
                    __typename?: 'WMEventLive'
                    id: string
                    publishStatus: WmLiveState
                    type: WmLiveType
                    autoAnnounceDate?: Date | null
                    template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
                  } | null
                  clipTemplate?: {
                    __typename?: 'WMEventTemplate'
                    id: string
                    name: string
                    type: WmTemplateType
                  } | null
                } | null
                sveBroadcast?: {
                  __typename?: 'SVEBroadcast'
                  id: string
                  status: SveBroadcastState
                  channelId: string
                  startDate?: Date | null
                  clipStartDate?: Date | null
                  stopDate?: Date | null
                  scheduledStartDate: Date
                  scheduledClipStartDate: Date
                  scheduledStopDate: Date
                } | null
                sveLiveMonitoring?: {
                  __typename?: 'SVELiveMonitoring'
                  alerts?: {
                    __typename?: 'SVELiveMonitoringAlerts'
                    audioNotDetected?: string | null
                    initialProbeTakingLonger?: string | null
                    videoNotDetected?: string | null
                    RTMPHasNoAudioVideo?: string | null
                    RTMPStreamNotFound?: string | null
                  } | null
                  stats?: {
                    __typename?: 'SVELiveMonitoringStats'
                    networkIn?: string | null
                    droppedFrames?: string | null
                    inputVideoFrameRate?: string | null
                    outputAudioLevelLkfs?: string | null
                  } | null
                } | null
              } | null
              videoClip?: {
                __typename?: 'VideoClip'
                id: string
                playbackUrl: string
                downloadLink: string
                status?: VideoClipStatus | null
                updatedAt: Date
                createdAt: Date
                fileId?: string | null
                error?: {
                  __typename?: 'VideoClipError'
                  guid: string
                  workflowStatus: string
                  workflowErrorAt: string
                  errorMessage: string
                  errorDetails: string
                } | null
                captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
                externalFeed?: {
                  __typename?: 'ExternalFeed'
                  externalFeedProvider: ExternalFeedType
                  externalFeedUrl: string
                } | null
              } | null
              image?: {
                __typename?: 'File'
                id: string
                filename: string
                size: number
                mimetype: string
                downloadUrl: string
                thumbUrl?: string | null
                createdAt: Date
                metadata?: {
                  __typename?: 'FileMetadata'
                  title?: string | null
                  description?: string | null
                  aspect?: number | null
                  width?: number | null
                  height?: number | null
                  sourceFileId?: string | null
                } | null
              } | null
              thumbnail?: {
                __typename?: 'Media'
                id: string
                image?: {
                  __typename?: 'File'
                  id: string
                  filename: string
                  size: number
                  mimetype: string
                  downloadUrl: string
                  thumbUrl?: string | null
                  createdAt: Date
                  metadata?: {
                    __typename?: 'FileMetadata'
                    title?: string | null
                    description?: string | null
                    aspect?: number | null
                    width?: number | null
                    height?: number | null
                    sourceFileId?: string | null
                  } | null
                } | null
              } | null
              timelineMarkers?: Array<{
                __typename?: 'Marker'
                title: string
                time: number
                icon?: string | null
              }> | null
            } | null
            transparentDarkImage?: {
              __typename?: 'Media'
              id: string
              createdAt: Date
              updatedAt: Date
              title: string
              type: MediaType
              publicationStatus: PublicationStatus
              tags: Array<MediaTags>
              date?: Date | null
              startTrim?: number | null
              endTrim?: number | null
              liveStreamId?: string | null
              videoClipId?: string | null
              imageId?: string | null
              thumbnailId?: string | null
              ownerOrganismId?: string | null
              pinned?: boolean | null
              liveStream?: {
                __typename?: 'LiveStream'
                id: string
                playbackUrl: string
                ingestUrl?: string | null
                wildmokaEventID?: string | null
                videoEngine: VideoEngine
                state: LiveStreamState
                streamId?: string | null
                sveBroadcastId?: string | null
                sveChannelId?: string | null
                externalFeedProvider?: ExternalFeedType | null
                externalFeedUrl?: string | null
                event?: {
                  __typename?: 'WMEvent'
                  id: string
                  name: string
                  description?: string | null
                  testStartDate: Date
                  editoStartDate: Date
                  editoStopDate: Date
                  stopDate: Date
                  state: WmEventState
                  notifEmail?: string | null
                  clipCreationAtEnd: boolean
                  clipPublicationAtEnd: boolean
                  livePublication: boolean
                  stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
                  live?: {
                    __typename?: 'WMEventLive'
                    id: string
                    publishStatus: WmLiveState
                    type: WmLiveType
                    autoAnnounceDate?: Date | null
                    template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
                  } | null
                  clipTemplate?: {
                    __typename?: 'WMEventTemplate'
                    id: string
                    name: string
                    type: WmTemplateType
                  } | null
                } | null
                sveBroadcast?: {
                  __typename?: 'SVEBroadcast'
                  id: string
                  status: SveBroadcastState
                  channelId: string
                  startDate?: Date | null
                  clipStartDate?: Date | null
                  stopDate?: Date | null
                  scheduledStartDate: Date
                  scheduledClipStartDate: Date
                  scheduledStopDate: Date
                } | null
                sveLiveMonitoring?: {
                  __typename?: 'SVELiveMonitoring'
                  alerts?: {
                    __typename?: 'SVELiveMonitoringAlerts'
                    audioNotDetected?: string | null
                    initialProbeTakingLonger?: string | null
                    videoNotDetected?: string | null
                    RTMPHasNoAudioVideo?: string | null
                    RTMPStreamNotFound?: string | null
                  } | null
                  stats?: {
                    __typename?: 'SVELiveMonitoringStats'
                    networkIn?: string | null
                    droppedFrames?: string | null
                    inputVideoFrameRate?: string | null
                    outputAudioLevelLkfs?: string | null
                  } | null
                } | null
              } | null
              videoClip?: {
                __typename?: 'VideoClip'
                id: string
                playbackUrl: string
                downloadLink: string
                status?: VideoClipStatus | null
                updatedAt: Date
                createdAt: Date
                fileId?: string | null
                error?: {
                  __typename?: 'VideoClipError'
                  guid: string
                  workflowStatus: string
                  workflowErrorAt: string
                  errorMessage: string
                  errorDetails: string
                } | null
                captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
                externalFeed?: {
                  __typename?: 'ExternalFeed'
                  externalFeedProvider: ExternalFeedType
                  externalFeedUrl: string
                } | null
              } | null
              image?: {
                __typename?: 'File'
                id: string
                filename: string
                size: number
                mimetype: string
                downloadUrl: string
                thumbUrl?: string | null
                createdAt: Date
                metadata?: {
                  __typename?: 'FileMetadata'
                  title?: string | null
                  description?: string | null
                  aspect?: number | null
                  width?: number | null
                  height?: number | null
                  sourceFileId?: string | null
                } | null
              } | null
              thumbnail?: {
                __typename?: 'Media'
                id: string
                image?: {
                  __typename?: 'File'
                  id: string
                  filename: string
                  size: number
                  mimetype: string
                  downloadUrl: string
                  thumbUrl?: string | null
                  createdAt: Date
                  metadata?: {
                    __typename?: 'FileMetadata'
                    title?: string | null
                    description?: string | null
                    aspect?: number | null
                    width?: number | null
                    height?: number | null
                    sourceFileId?: string | null
                  } | null
                } | null
              } | null
              timelineMarkers?: Array<{
                __typename?: 'Marker'
                title: string
                time: number
                icon?: string | null
              }> | null
            } | null
            transparentLightImage?: {
              __typename?: 'Media'
              id: string
              createdAt: Date
              updatedAt: Date
              title: string
              type: MediaType
              publicationStatus: PublicationStatus
              tags: Array<MediaTags>
              date?: Date | null
              startTrim?: number | null
              endTrim?: number | null
              liveStreamId?: string | null
              videoClipId?: string | null
              imageId?: string | null
              thumbnailId?: string | null
              ownerOrganismId?: string | null
              pinned?: boolean | null
              liveStream?: {
                __typename?: 'LiveStream'
                id: string
                playbackUrl: string
                ingestUrl?: string | null
                wildmokaEventID?: string | null
                videoEngine: VideoEngine
                state: LiveStreamState
                streamId?: string | null
                sveBroadcastId?: string | null
                sveChannelId?: string | null
                externalFeedProvider?: ExternalFeedType | null
                externalFeedUrl?: string | null
                event?: {
                  __typename?: 'WMEvent'
                  id: string
                  name: string
                  description?: string | null
                  testStartDate: Date
                  editoStartDate: Date
                  editoStopDate: Date
                  stopDate: Date
                  state: WmEventState
                  notifEmail?: string | null
                  clipCreationAtEnd: boolean
                  clipPublicationAtEnd: boolean
                  livePublication: boolean
                  stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
                  live?: {
                    __typename?: 'WMEventLive'
                    id: string
                    publishStatus: WmLiveState
                    type: WmLiveType
                    autoAnnounceDate?: Date | null
                    template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
                  } | null
                  clipTemplate?: {
                    __typename?: 'WMEventTemplate'
                    id: string
                    name: string
                    type: WmTemplateType
                  } | null
                } | null
                sveBroadcast?: {
                  __typename?: 'SVEBroadcast'
                  id: string
                  status: SveBroadcastState
                  channelId: string
                  startDate?: Date | null
                  clipStartDate?: Date | null
                  stopDate?: Date | null
                  scheduledStartDate: Date
                  scheduledClipStartDate: Date
                  scheduledStopDate: Date
                } | null
                sveLiveMonitoring?: {
                  __typename?: 'SVELiveMonitoring'
                  alerts?: {
                    __typename?: 'SVELiveMonitoringAlerts'
                    audioNotDetected?: string | null
                    initialProbeTakingLonger?: string | null
                    videoNotDetected?: string | null
                    RTMPHasNoAudioVideo?: string | null
                    RTMPStreamNotFound?: string | null
                  } | null
                  stats?: {
                    __typename?: 'SVELiveMonitoringStats'
                    networkIn?: string | null
                    droppedFrames?: string | null
                    inputVideoFrameRate?: string | null
                    outputAudioLevelLkfs?: string | null
                  } | null
                } | null
              } | null
              videoClip?: {
                __typename?: 'VideoClip'
                id: string
                playbackUrl: string
                downloadLink: string
                status?: VideoClipStatus | null
                updatedAt: Date
                createdAt: Date
                fileId?: string | null
                error?: {
                  __typename?: 'VideoClipError'
                  guid: string
                  workflowStatus: string
                  workflowErrorAt: string
                  errorMessage: string
                  errorDetails: string
                } | null
                captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
                externalFeed?: {
                  __typename?: 'ExternalFeed'
                  externalFeedProvider: ExternalFeedType
                  externalFeedUrl: string
                } | null
              } | null
              image?: {
                __typename?: 'File'
                id: string
                filename: string
                size: number
                mimetype: string
                downloadUrl: string
                thumbUrl?: string | null
                createdAt: Date
                metadata?: {
                  __typename?: 'FileMetadata'
                  title?: string | null
                  description?: string | null
                  aspect?: number | null
                  width?: number | null
                  height?: number | null
                  sourceFileId?: string | null
                } | null
              } | null
              thumbnail?: {
                __typename?: 'Media'
                id: string
                image?: {
                  __typename?: 'File'
                  id: string
                  filename: string
                  size: number
                  mimetype: string
                  downloadUrl: string
                  thumbUrl?: string | null
                  createdAt: Date
                  metadata?: {
                    __typename?: 'FileMetadata'
                    title?: string | null
                    description?: string | null
                    aspect?: number | null
                    width?: number | null
                    height?: number | null
                    sourceFileId?: string | null
                  } | null
                } | null
              } | null
              timelineMarkers?: Array<{
                __typename?: 'Marker'
                title: string
                time: number
                icon?: string | null
              }> | null
            } | null
          }
          sports: Array<{ __typename?: 'Sport'; id: string }>
          bannerImage?: {
            __typename?: 'Media'
            id: string
            createdAt: Date
            updatedAt: Date
            title: string
            type: MediaType
            publicationStatus: PublicationStatus
            tags: Array<MediaTags>
            date?: Date | null
            startTrim?: number | null
            endTrim?: number | null
            liveStreamId?: string | null
            videoClipId?: string | null
            imageId?: string | null
            thumbnailId?: string | null
            ownerOrganismId?: string | null
            pinned?: boolean | null
            liveStream?: {
              __typename?: 'LiveStream'
              id: string
              playbackUrl: string
              ingestUrl?: string | null
              wildmokaEventID?: string | null
              videoEngine: VideoEngine
              state: LiveStreamState
              streamId?: string | null
              sveBroadcastId?: string | null
              sveChannelId?: string | null
              externalFeedProvider?: ExternalFeedType | null
              externalFeedUrl?: string | null
              event?: {
                __typename?: 'WMEvent'
                id: string
                name: string
                description?: string | null
                testStartDate: Date
                editoStartDate: Date
                editoStopDate: Date
                stopDate: Date
                state: WmEventState
                notifEmail?: string | null
                clipCreationAtEnd: boolean
                clipPublicationAtEnd: boolean
                livePublication: boolean
                stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
                live?: {
                  __typename?: 'WMEventLive'
                  id: string
                  publishStatus: WmLiveState
                  type: WmLiveType
                  autoAnnounceDate?: Date | null
                  template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
                } | null
                clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
              } | null
              sveBroadcast?: {
                __typename?: 'SVEBroadcast'
                id: string
                status: SveBroadcastState
                channelId: string
                startDate?: Date | null
                clipStartDate?: Date | null
                stopDate?: Date | null
                scheduledStartDate: Date
                scheduledClipStartDate: Date
                scheduledStopDate: Date
              } | null
              sveLiveMonitoring?: {
                __typename?: 'SVELiveMonitoring'
                alerts?: {
                  __typename?: 'SVELiveMonitoringAlerts'
                  audioNotDetected?: string | null
                  initialProbeTakingLonger?: string | null
                  videoNotDetected?: string | null
                  RTMPHasNoAudioVideo?: string | null
                  RTMPStreamNotFound?: string | null
                } | null
                stats?: {
                  __typename?: 'SVELiveMonitoringStats'
                  networkIn?: string | null
                  droppedFrames?: string | null
                  inputVideoFrameRate?: string | null
                  outputAudioLevelLkfs?: string | null
                } | null
              } | null
            } | null
            videoClip?: {
              __typename?: 'VideoClip'
              id: string
              playbackUrl: string
              downloadLink: string
              status?: VideoClipStatus | null
              updatedAt: Date
              createdAt: Date
              fileId?: string | null
              error?: {
                __typename?: 'VideoClipError'
                guid: string
                workflowStatus: string
                workflowErrorAt: string
                errorMessage: string
                errorDetails: string
              } | null
              captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
              externalFeed?: {
                __typename?: 'ExternalFeed'
                externalFeedProvider: ExternalFeedType
                externalFeedUrl: string
              } | null
            } | null
            image?: {
              __typename?: 'File'
              id: string
              filename: string
              size: number
              mimetype: string
              downloadUrl: string
              thumbUrl?: string | null
              createdAt: Date
              metadata?: {
                __typename?: 'FileMetadata'
                title?: string | null
                description?: string | null
                aspect?: number | null
                width?: number | null
                height?: number | null
                sourceFileId?: string | null
              } | null
            } | null
            thumbnail?: {
              __typename?: 'Media'
              id: string
              image?: {
                __typename?: 'File'
                id: string
                filename: string
                size: number
                mimetype: string
                downloadUrl: string
                thumbUrl?: string | null
                createdAt: Date
                metadata?: {
                  __typename?: 'FileMetadata'
                  title?: string | null
                  description?: string | null
                  aspect?: number | null
                  width?: number | null
                  height?: number | null
                  sourceFileId?: string | null
                } | null
              } | null
            } | null
            timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
          } | null
          adminBadge?: {
            __typename?: 'Media'
            id: string
            createdAt: Date
            updatedAt: Date
            title: string
            type: MediaType
            publicationStatus: PublicationStatus
            tags: Array<MediaTags>
            date?: Date | null
            startTrim?: number | null
            endTrim?: number | null
            liveStreamId?: string | null
            videoClipId?: string | null
            imageId?: string | null
            thumbnailId?: string | null
            ownerOrganismId?: string | null
            pinned?: boolean | null
            liveStream?: {
              __typename?: 'LiveStream'
              id: string
              playbackUrl: string
              ingestUrl?: string | null
              wildmokaEventID?: string | null
              videoEngine: VideoEngine
              state: LiveStreamState
              streamId?: string | null
              sveBroadcastId?: string | null
              sveChannelId?: string | null
              externalFeedProvider?: ExternalFeedType | null
              externalFeedUrl?: string | null
              event?: {
                __typename?: 'WMEvent'
                id: string
                name: string
                description?: string | null
                testStartDate: Date
                editoStartDate: Date
                editoStopDate: Date
                stopDate: Date
                state: WmEventState
                notifEmail?: string | null
                clipCreationAtEnd: boolean
                clipPublicationAtEnd: boolean
                livePublication: boolean
                stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
                live?: {
                  __typename?: 'WMEventLive'
                  id: string
                  publishStatus: WmLiveState
                  type: WmLiveType
                  autoAnnounceDate?: Date | null
                  template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
                } | null
                clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
              } | null
              sveBroadcast?: {
                __typename?: 'SVEBroadcast'
                id: string
                status: SveBroadcastState
                channelId: string
                startDate?: Date | null
                clipStartDate?: Date | null
                stopDate?: Date | null
                scheduledStartDate: Date
                scheduledClipStartDate: Date
                scheduledStopDate: Date
              } | null
              sveLiveMonitoring?: {
                __typename?: 'SVELiveMonitoring'
                alerts?: {
                  __typename?: 'SVELiveMonitoringAlerts'
                  audioNotDetected?: string | null
                  initialProbeTakingLonger?: string | null
                  videoNotDetected?: string | null
                  RTMPHasNoAudioVideo?: string | null
                  RTMPStreamNotFound?: string | null
                } | null
                stats?: {
                  __typename?: 'SVELiveMonitoringStats'
                  networkIn?: string | null
                  droppedFrames?: string | null
                  inputVideoFrameRate?: string | null
                  outputAudioLevelLkfs?: string | null
                } | null
              } | null
            } | null
            videoClip?: {
              __typename?: 'VideoClip'
              id: string
              playbackUrl: string
              downloadLink: string
              status?: VideoClipStatus | null
              updatedAt: Date
              createdAt: Date
              fileId?: string | null
              error?: {
                __typename?: 'VideoClipError'
                guid: string
                workflowStatus: string
                workflowErrorAt: string
                errorMessage: string
                errorDetails: string
              } | null
              captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
              externalFeed?: {
                __typename?: 'ExternalFeed'
                externalFeedProvider: ExternalFeedType
                externalFeedUrl: string
              } | null
            } | null
            image?: {
              __typename?: 'File'
              id: string
              filename: string
              size: number
              mimetype: string
              downloadUrl: string
              thumbUrl?: string | null
              createdAt: Date
              metadata?: {
                __typename?: 'FileMetadata'
                title?: string | null
                description?: string | null
                aspect?: number | null
                width?: number | null
                height?: number | null
                sourceFileId?: string | null
              } | null
            } | null
            thumbnail?: {
              __typename?: 'Media'
              id: string
              image?: {
                __typename?: 'File'
                id: string
                filename: string
                size: number
                mimetype: string
                downloadUrl: string
                thumbUrl?: string | null
                createdAt: Date
                metadata?: {
                  __typename?: 'FileMetadata'
                  title?: string | null
                  description?: string | null
                  aspect?: number | null
                  width?: number | null
                  height?: number | null
                  sourceFileId?: string | null
                } | null
              } | null
            } | null
            timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
          } | null
          bannerVideo?: {
            __typename?: 'Media'
            id: string
            videoClip?: { __typename?: 'VideoClip'; id: string; playbackUrl: string } | null
          } | null
          pageWidgets?: Array<{
            __typename?: 'CustomPageWidget'
            name: CustomPageWidgetName
            position: number
            display?: CustomPageDisplayTypes | null
            contentDisplay?: ContentSetContentDisplay | null
            cardsDisplay?: CardsDisplay | null
            options?: {
              __typename?: 'CustomPageWidgetOptions'
              editedCategoryId?: string | null
              sportItemSetType?: SportItemSetType | null
              replays?: {
                __typename?: 'ReplaysWidget'
                defaultLocale: string
                maxReplays?: number | null
                depthInDays?: number | null
                nameT: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }>
              } | null
              externalContent?: {
                __typename?: 'ExternalContentWidget'
                name?: string | null
                defaultLocale: string
                maxItemsCount: number
                source: WidgetExternalContentSource
                nameT: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }>
                period?: { __typename?: 'StartEndDates'; startDate: Date; endDate: Date } | null
                wecContent?: {
                  __typename?: 'WECNewsContent'
                  teamECMIds?: Array<string> | null
                  driverECMIds?: Array<string> | null
                  carECMIds?: Array<string> | null
                  categoryECMIds?: Array<string> | null
                  raceECMIds?: Array<string> | null
                } | null
                rssContent?: { __typename?: 'RSSFeedContent'; feedUrl: string } | null
              } | null
            } | null
          }> | null
          offers: Array<{
            __typename?: 'PurchasableOffer'
            id: string
            mode: OfferMode
            delay?: number | null
            expiredAt?: Date | null
            publicationStatus: PublicationStatus
            title: string
            defaultLocale?: string | null
            url?: string | null
            bannerImageId?: string | null
            thumbnailImageId?: string | null
            freeWithLicense: boolean
            position?: number | null
            titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
            description: Array<{ __typename?: 'OfferDescription'; header: string; content: string }>
            descriptionT?: Array<{
              __typename?: 'ContentTranslationDescriptionPurchasableOffer'
              locale: string
              content?: Array<{ __typename?: 'OfferDescription'; header: string; content: string }> | null
            }> | null
            products: Array<{
              __typename?: 'Product'
              productId: string
              platform: Platform
              period?: Period | null
              publicationStatus: PublicationStatus
              purchasableOfferId?: string | null
              licensedUserOnly?: boolean | null
              premiumOf?: Array<string> | null
              prices: Array<{ __typename?: 'Price'; value: number; currency: string }>
            }>
          }>
          license?: { __typename?: 'OrganismLicense'; enabled: boolean; api?: LicenseApi | null } | null
          tags?: Array<{ __typename?: 'Tag'; id: string; title: string; subTags?: Array<string> | null }> | null
        }> | null
        titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
        subtitleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
        descriptionT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
        billboard?: {
          __typename?: 'Media'
          id: string
          videoClip?: { __typename?: 'VideoClip'; id: string; playbackUrl: string } | null
        } | null
        teaserVideo?: {
          __typename?: 'Media'
          id: string
          videoClip?: { __typename?: 'VideoClip'; id: string; playbackUrl: string } | null
        } | null
        image?: {
          __typename?: 'Media'
          id: string
          image?: { __typename?: 'File'; id: string; thumbUrl?: string | null; downloadUrl: string } | null
        } | null
        categories: Array<{ __typename?: 'Category'; id: string; title: string }>
      } | null
    } | null
    items?: Array<{ __typename?: 'SportItem'; id: string; title: string }> | null
    bannerImage?: {
      __typename?: 'Media'
      id: string
      image?: { __typename?: 'File'; id: string; thumbUrl?: string | null; downloadUrl: string } | null
    } | null
    bannerVideo?: {
      __typename?: 'Media'
      id: string
      videoClip?: { __typename?: 'VideoClip'; id: string; playbackUrl: string } | null
    } | null
    sportEvent?: {
      __typename?: 'SportEvent'
      startDate: Date
      endDate: Date
      state: SportEventState
      liveTemplateId?: string | null
      backstageTemplateId?: string | null
      clipTemplateId?: string | null
    } | null
    categories: Array<{ __typename?: 'Category'; id: string; title: string }>
    legalRestrictions: Array<{
      __typename?: 'LegalRestriction'
      id: string
      description?: string | null
      countries: Array<string>
      type: RestrictionType
      timeSlots?: { __typename?: 'TimeSlots'; from: string; to: string } | null
    }>
    cac?: {
      __typename?: 'ContentAccessControl'
      userAuthenticationRule?: CacUserAuthenticationRule | null
      userLocationRule?: {
        __typename?: 'CACUserLocationRule'
        distance: number
        condition: UserLocationCondition
        location: { __typename?: 'GeoLocation'; latitude: number; longitude: number }
      } | null
    } | null
    streams?: Array<{ __typename?: 'WMStream'; id: string; name: string }> | null
    sveStreams: Array<{
      __typename?: 'SVEStream'
      sveChannelId: string
      socialNetworkWMStreamId?: string | null
      outputStreamId?: string | null
    }>
    tag?: { __typename?: 'Tag'; id: string; title: string } | null
    contentPushServiceGenericStreams?: Array<{
      __typename?: 'ContentPushStream'
      id: string
      name: string
      createdAt: Date
      updatedAt: Date
    }> | null
    noSpoilerMode?: { __typename?: 'NoSpoilerMode'; enabled: boolean; minutesBeforeDisabling: number } | null
  } | null
}

export type DeleteSportItemSetMutationVariables = Exact<{
  sportItemSetId: Scalars['ID']
}>

export type DeleteSportItemSetMutation = {
  __typename?: 'Mutation'
  deleteSportItemSet?: { __typename?: 'SportItemSet'; id: string } | null
}

export type CheckStreamsAvailableQueryVariables = Exact<{
  streamsIds: Array<Scalars['WildmokaID']> | Scalars['WildmokaID']
  from: Scalars['Date']
  to: Scalars['Date']
}>

export type CheckStreamsAvailableQuery = {
  __typename?: 'Query'
  checkStreamsAvailable: Array<{
    __typename?: 'SportItemSetStreamConflict'
    streamId: string
    sportItemSet: { __typename?: 'SportItemSet'; id: string; title: string }
  }>
}

export type CheckSveChannelsAvailableQueryVariables = Exact<{
  input: CheckSveChannelsAvailableInput
}>

export type CheckSveChannelsAvailableQuery = {
  __typename?: 'Query'
  checkSVEChannelsAvailable: Array<{
    __typename?: 'SportItemSetSVEChannelAvailability'
    id: string
    name: string
    status: SveChannelState
    available: boolean
  }>
}

export type SveChannelContentFragment = {
  __typename?: 'SVEChannel'
  id: string
  name: string
  status: SveChannelState
  logoUrl?: string | null
  inputLossImageUrl?: string | null
  socialOutputUrl?: string | null
  socialOutputStreamName?: string | null
  endpoints?: {
    __typename?: 'SVEChannelEndpoints'
    rtmpInputUrl?: string | null
    cmafEndpointUrl?: string | null
    hlsEndpointUrl?: string | null
  } | null
}

export type SveBroadcastContentFragment = {
  __typename?: 'SVEBroadcast'
  id: string
  status: SveBroadcastState
  channelId: string
  startDate?: Date | null
  clipStartDate?: Date | null
  stopDate?: Date | null
  scheduledStartDate: Date
  scheduledClipStartDate: Date
  scheduledStopDate: Date
}

export type SveLiveMonitoringContentFragment = {
  __typename?: 'SVELiveMonitoring'
  alerts?: {
    __typename?: 'SVELiveMonitoringAlerts'
    audioNotDetected?: string | null
    initialProbeTakingLonger?: string | null
    videoNotDetected?: string | null
    RTMPHasNoAudioVideo?: string | null
    RTMPStreamNotFound?: string | null
  } | null
  stats?: {
    __typename?: 'SVELiveMonitoringStats'
    networkIn?: string | null
    droppedFrames?: string | null
    inputVideoFrameRate?: string | null
    outputAudioLevelLkfs?: string | null
  } | null
}

export type CreateSveChannelMutationVariables = Exact<{
  input: CreateSveChannelInput
}>

export type CreateSveChannelMutation = {
  __typename?: 'Mutation'
  createSVEChannel: {
    __typename?: 'SVEChannel'
    id: string
    name: string
    status: SveChannelState
    logoUrl?: string | null
    inputLossImageUrl?: string | null
    socialOutputUrl?: string | null
    socialOutputStreamName?: string | null
    endpoints?: {
      __typename?: 'SVEChannelEndpoints'
      rtmpInputUrl?: string | null
      cmafEndpointUrl?: string | null
      hlsEndpointUrl?: string | null
    } | null
  }
}

export type DeleteSveChannelMutationVariables = Exact<{
  channelId: Scalars['ID']
}>

export type DeleteSveChannelMutation = { __typename?: 'Mutation'; deleteSVEChannel?: boolean | null }

export type SveBroadcastQueryVariables = Exact<{
  broadcastId: Scalars['ID']
}>

export type SveBroadcastQuery = {
  __typename?: 'Query'
  sveBroadcast?: {
    __typename?: 'SVEBroadcast'
    id: string
    status: SveBroadcastState
    channelId: string
    startDate?: Date | null
    clipStartDate?: Date | null
    stopDate?: Date | null
    scheduledStartDate: Date
    scheduledClipStartDate: Date
    scheduledStopDate: Date
  } | null
}

export type SveChannelQueryVariables = Exact<{
  channelId: Scalars['ID']
}>

export type SveChannelQuery = {
  __typename?: 'Query'
  sveChannel: {
    __typename?: 'SVEChannel'
    id: string
    name: string
    status: SveChannelState
    logoUrl?: string | null
    inputLossImageUrl?: string | null
    socialOutputUrl?: string | null
    socialOutputStreamName?: string | null
    endpoints?: {
      __typename?: 'SVEChannelEndpoints'
      rtmpInputUrl?: string | null
      cmafEndpointUrl?: string | null
      hlsEndpointUrl?: string | null
    } | null
  }
}

export type SveChannelWithBroadcastsQueryVariables = Exact<{
  channelId: Scalars['ID']
}>

export type SveChannelWithBroadcastsQuery = {
  __typename?: 'Query'
  sveChannel: {
    __typename?: 'SVEChannel'
    id: string
    name: string
    status: SveChannelState
    logoUrl?: string | null
    inputLossImageUrl?: string | null
    socialOutputUrl?: string | null
    socialOutputStreamName?: string | null
    broadcasts: Array<{
      __typename?: 'SVEBroadcast'
      id: string
      status: SveBroadcastState
      channelId: string
      startDate?: Date | null
      clipStartDate?: Date | null
      stopDate?: Date | null
      scheduledStartDate: Date
      scheduledClipStartDate: Date
      scheduledStopDate: Date
    }>
    endpoints?: {
      __typename?: 'SVEChannelEndpoints'
      rtmpInputUrl?: string | null
      cmafEndpointUrl?: string | null
      hlsEndpointUrl?: string | null
    } | null
  }
}

export type SveChannelsQueryVariables = Exact<{
  input: SveChannelsInput
}>

export type SveChannelsQuery = {
  __typename?: 'Query'
  sveChannels: {
    __typename?: 'SVEChannelsPageResult'
    totalCount: number
    sveChannels: Array<{
      __typename?: 'SVEChannel'
      id: string
      name: string
      status: SveChannelState
      logoUrl?: string | null
      inputLossImageUrl?: string | null
      socialOutputUrl?: string | null
      socialOutputStreamName?: string | null
      endpoints?: {
        __typename?: 'SVEChannelEndpoints'
        rtmpInputUrl?: string | null
        cmafEndpointUrl?: string | null
        hlsEndpointUrl?: string | null
      } | null
    }>
  }
}

export type SveChannelsWithBroadcastsQueryVariables = Exact<{
  input: SveChannelsInput
}>

export type SveChannelsWithBroadcastsQuery = {
  __typename?: 'Query'
  sveChannels: {
    __typename?: 'SVEChannelsPageResult'
    totalCount: number
    sveChannels: Array<{
      __typename?: 'SVEChannel'
      id: string
      name: string
      status: SveChannelState
      logoUrl?: string | null
      inputLossImageUrl?: string | null
      socialOutputUrl?: string | null
      socialOutputStreamName?: string | null
      broadcasts: Array<{
        __typename?: 'SVEBroadcast'
        id: string
        status: SveBroadcastState
        channelId: string
        startDate?: Date | null
        clipStartDate?: Date | null
        stopDate?: Date | null
        scheduledStartDate: Date
        scheduledClipStartDate: Date
        scheduledStopDate: Date
      }>
      endpoints?: {
        __typename?: 'SVEChannelEndpoints'
        rtmpInputUrl?: string | null
        cmafEndpointUrl?: string | null
        hlsEndpointUrl?: string | null
      } | null
    }>
  }
}

export type UnmountSveChannelMutationVariables = Exact<{
  channelId: Scalars['ID']
}>

export type UnmountSveChannelMutation = {
  __typename?: 'Mutation'
  unmountSVEChannel: {
    __typename?: 'SVEChannel'
    id: string
    name: string
    status: SveChannelState
    logoUrl?: string | null
    inputLossImageUrl?: string | null
    socialOutputUrl?: string | null
    socialOutputStreamName?: string | null
    endpoints?: {
      __typename?: 'SVEChannelEndpoints'
      rtmpInputUrl?: string | null
      cmafEndpointUrl?: string | null
      hlsEndpointUrl?: string | null
    } | null
  }
}

export type UpdateSveChannelMutationVariables = Exact<{
  input: UpdateSveChannelInput
}>

export type UpdateSveChannelMutation = {
  __typename?: 'Mutation'
  updateSVEChannel: {
    __typename?: 'SVEChannel'
    id: string
    name: string
    status: SveChannelState
    logoUrl?: string | null
    inputLossImageUrl?: string | null
    socialOutputUrl?: string | null
    socialOutputStreamName?: string | null
    endpoints?: {
      __typename?: 'SVEChannelEndpoints'
      rtmpInputUrl?: string | null
      cmafEndpointUrl?: string | null
      hlsEndpointUrl?: string | null
    } | null
  }
}

export type TabContentFragment = {
  __typename?: 'Tab'
  title: string
  url: string
  position: TabPosition
  blank?: boolean | null
  titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
  icon: { __typename?: 'Icon'; title: string; svg: string }
}

export type ThirdPartyApplicationContentFragment = {
  __typename?: 'ThirdPartyApplication'
  id: string
  name: string
  organismId?: string | null
  description: string
  keys: Array<{
    __typename?: 'ApplicationKey'
    id: string
    name: string
    key: string
    type: ApplicationKeyType
    enabled: boolean
    expirationDate: Date
  }>
  organism?: {
    __typename?: 'Organism'
    id: string
    type: OrganismType
    whiteLabel: boolean
    title: string
    description?: string | null
    officialWebSiteUrl: string
    fansCount: number
    license?: { __typename?: 'OrganismLicense'; enabled: boolean; api?: LicenseApi | null } | null
  } | null
}

export type ThirdPartyApplicationsQueryVariables = Exact<{
  filters?: InputMaybe<ThirdPartyApplicationsFilters>
}>

export type ThirdPartyApplicationsQuery = {
  __typename?: 'Query'
  thirdPartyApplications?: {
    __typename?: 'ThirdPartyApplicationPageResult'
    totalCount?: number | null
    thirdPartyApplications: Array<{
      __typename?: 'ThirdPartyApplication'
      id: string
      name: string
      organismId?: string | null
      description: string
      keys: Array<{
        __typename?: 'ApplicationKey'
        id: string
        name: string
        key: string
        type: ApplicationKeyType
        enabled: boolean
        expirationDate: Date
      }>
      organism?: {
        __typename?: 'Organism'
        id: string
        type: OrganismType
        whiteLabel: boolean
        title: string
        description?: string | null
        officialWebSiteUrl: string
        fansCount: number
        license?: { __typename?: 'OrganismLicense'; enabled: boolean; api?: LicenseApi | null } | null
      } | null
    }>
  } | null
}

export type CreateThirdPartyApplicationMutationVariables = Exact<{
  input: CreateThirdPartyApplicationInput
}>

export type CreateThirdPartyApplicationMutation = {
  __typename?: 'Mutation'
  createThirdPartyApplication: {
    __typename?: 'ThirdPartyApplication'
    id: string
    name: string
    organismId?: string | null
    description: string
    keys: Array<{
      __typename?: 'ApplicationKey'
      id: string
      name: string
      key: string
      type: ApplicationKeyType
      enabled: boolean
      expirationDate: Date
    }>
    organism?: {
      __typename?: 'Organism'
      id: string
      type: OrganismType
      whiteLabel: boolean
      title: string
      description?: string | null
      officialWebSiteUrl: string
      fansCount: number
      license?: { __typename?: 'OrganismLicense'; enabled: boolean; api?: LicenseApi | null } | null
    } | null
  }
}

export type UpdateThirdPartyApplicationMutationVariables = Exact<{
  input: UpdateThirdPartyApplicationInput
}>

export type UpdateThirdPartyApplicationMutation = {
  __typename?: 'Mutation'
  updateThirdPartyApplication: {
    __typename?: 'ThirdPartyApplication'
    id: string
    name: string
    organismId?: string | null
    description: string
    keys: Array<{
      __typename?: 'ApplicationKey'
      id: string
      name: string
      key: string
      type: ApplicationKeyType
      enabled: boolean
      expirationDate: Date
    }>
    organism?: {
      __typename?: 'Organism'
      id: string
      type: OrganismType
      whiteLabel: boolean
      title: string
      description?: string | null
      officialWebSiteUrl: string
      fansCount: number
      license?: { __typename?: 'OrganismLicense'; enabled: boolean; api?: LicenseApi | null } | null
    } | null
  }
}

export type DeleteThirdPartyApplicationMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type DeleteThirdPartyApplicationMutation = {
  __typename?: 'Mutation'
  deleteThirdPartyApplication?: { __typename?: 'ThirdPartyApplication'; id: string } | null
}

export type LightUserContentFragment = {
  __typename?: 'User'
  id: string
  email: string
  firstName: string
  lastName: string
  chatAuthorName: string
  organismIds?: Array<string> | null
  roleIds: Array<UserRoleId>
  birthdayDate?: Date | null
  gender?: Gender | null
  createdAt: Date
  lastActivity?: Date | null
  cguAccepted?: Date | null
  signUpOrigin?: SignUpOrigin | null
  environment: UserEnvironment
  verified: boolean
  receipts: Array<{
    __typename?: 'UserReceipt'
    offerId: string
    type: SubscriptionType
    status: ReceiptStatus
    productId?: string | null
    offer?: { __typename?: 'PurchasableOffer'; title: string } | null
  }>
  organisms?: Array<{ __typename?: 'Organism'; id: string; title: string }> | null
  rights?: {
    __typename?: 'UserRight'
    generalPermissions: Array<Permission>
    organismsPermissions: Array<{
      __typename?: 'OrganismPermissions'
      organismId: string
      permissions: Array<Permission>
    }>
  } | null
  location?: { __typename?: 'Location'; country: string; postalCode?: string | null } | null
  applicationOrigin?: { __typename?: 'Application'; id: string; title?: string | null } | null
  fanPreferences?: {
    __typename?: 'FanPreferences'
    followedOrganisms: Array<{ __typename?: 'Organism'; id: string; title: string }>
  } | null
  licenses?: Array<{
    __typename?: 'LicenseInfo'
    organismId: string
    isLicensed: boolean
    registrationDate: Date
    organism?: { __typename?: 'Organism'; id: string; title: string } | null
  }> | null
}

export type UsersQueryVariables = Exact<{
  filters?: InputMaybe<UserFilters>
  offset?: InputMaybe<Scalars['Int']>
  first?: InputMaybe<Scalars['Int']>
  sortBy?: InputMaybe<SortBy>
}>

export type UsersQuery = {
  __typename?: 'Query'
  users?: {
    __typename?: 'UserPageResult'
    totalCount?: number | null
    users: Array<{
      __typename?: 'User'
      id: string
      email: string
      firstName: string
      lastName: string
      chatAuthorName: string
      organismIds?: Array<string> | null
      roleIds: Array<UserRoleId>
      birthdayDate?: Date | null
      gender?: Gender | null
      createdAt: Date
      lastActivity?: Date | null
      cguAccepted?: Date | null
      signUpOrigin?: SignUpOrigin | null
      environment: UserEnvironment
      verified: boolean
      receipts: Array<{
        __typename?: 'UserReceipt'
        offerId: string
        type: SubscriptionType
        status: ReceiptStatus
        productId?: string | null
        offer?: { __typename?: 'PurchasableOffer'; title: string } | null
      }>
      organisms?: Array<{ __typename?: 'Organism'; id: string; title: string }> | null
      rights?: {
        __typename?: 'UserRight'
        generalPermissions: Array<Permission>
        organismsPermissions: Array<{
          __typename?: 'OrganismPermissions'
          organismId: string
          permissions: Array<Permission>
        }>
      } | null
      location?: { __typename?: 'Location'; country: string; postalCode?: string | null } | null
      applicationOrigin?: { __typename?: 'Application'; id: string; title?: string | null } | null
      fanPreferences?: {
        __typename?: 'FanPreferences'
        followedOrganisms: Array<{ __typename?: 'Organism'; id: string; title: string }>
      } | null
      licenses?: Array<{
        __typename?: 'LicenseInfo'
        organismId: string
        isLicensed: boolean
        registrationDate: Date
        organism?: { __typename?: 'Organism'; id: string; title: string } | null
      }> | null
    }>
  } | null
}

export type UserQueryVariables = Exact<{
  userId: Scalars['ID']
}>

export type UserQuery = {
  __typename?: 'Query'
  user?: {
    __typename?: 'User'
    id: string
    email: string
    firstName: string
    lastName: string
    chatAuthorName: string
    organismIds?: Array<string> | null
    roleIds: Array<UserRoleId>
    rights?: {
      __typename?: 'UserRight'
      generalPermissions: Array<Permission>
      organismsPermissions: Array<{
        __typename?: 'OrganismPermissions'
        organismId: string
        permissions: Array<Permission>
      }>
    } | null
  } | null
}

export type MeQueryVariables = Exact<{ [key: string]: never }>

export type MeQuery = {
  __typename?: 'Query'
  me?: {
    __typename?: 'User'
    id: string
    email: string
    firstName: string
    lastName: string
    organismIds?: Array<string> | null
    roleIds: Array<UserRoleId>
    avatarUrl?: string | null
    rights?: {
      __typename?: 'UserRight'
      generalPermissions: Array<Permission>
      organismsPermissions: Array<{
        __typename?: 'OrganismPermissions'
        organismId: string
        permissions: Array<Permission>
      }>
    } | null
    allPermissions: {
      __typename?: 'UserRight'
      generalPermissions: Array<Permission>
      organismsPermissions: Array<{
        __typename?: 'OrganismPermissions'
        organismId: string
        permissions: Array<Permission>
      }>
    }
  } | null
}

export type UserRgpdDataQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type UserRgpdDataQuery = {
  __typename?: 'Query'
  user?: { __typename?: 'User'; id: string; RGPDFormattedData: string } | null
}

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput
}>

export type CreateUserMutation = {
  __typename?: 'Mutation'
  createUser: { __typename?: 'User'; id: string; email: string; firstName: string; lastName: string }
}

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput
}>

export type UpdateUserMutation = {
  __typename?: 'Mutation'
  updateUser: {
    __typename?: 'User'
    id: string
    firstName: string
    lastName: string
    email: string
    organismIds?: Array<string> | null
    roleIds: Array<UserRoleId>
    rights?: {
      __typename?: 'UserRight'
      generalPermissions: Array<Permission>
      organismsPermissions: Array<{
        __typename?: 'OrganismPermissions'
        organismId: string
        permissions: Array<Permission>
      }>
    } | null
  }
}

export type UploadAvatarMutationVariables = Exact<{
  file: Scalars['Upload']
}>

export type UploadAvatarMutation = {
  __typename?: 'Mutation'
  uploadAvatar: { __typename?: 'User'; id: string; avatarUrl?: string | null }
}

export type DeleteUserMutationVariables = Exact<{
  userId: Scalars['ID']
}>

export type DeleteUserMutation = { __typename?: 'Mutation'; deleteUser?: { __typename?: 'User'; id: string } | null }

export type MyOrganismsQueryVariables = Exact<{ [key: string]: never }>

export type MyOrganismsQuery = {
  __typename?: 'Query'
  me?: {
    __typename?: 'User'
    id: string
    organisms?: Array<{
      __typename?: 'Organism'
      id: string
      type: OrganismType
      whiteLabel: boolean
      title: string
      description?: string | null
      publicationStatus: PublicationStatus
      defaultLocale?: string | null
      onFront: boolean
      frontPosition?: number | null
      officialWebSiteUrl: string
      sportIds: Array<string>
      forbiddenKeyWords?: Array<string> | null
      enableExternalSources?: boolean | null
      availableExternalSources?: Array<WidgetExternalContentSource> | null
      streamsIds?: Array<string> | null
      wmProfileId?: string | null
      sveChannelIds?: Array<string> | null
      organismPageApplicationDesignId?: string | null
      contentPageApplicationDesignId?: string | null
      adminsAutomatedEmails?: boolean | null
      visibleInMultisportApplication?: boolean | null
      titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
      descriptionT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
      logo: {
        __typename?: 'Logo'
        coloredImage?: {
          __typename?: 'Media'
          id: string
          createdAt: Date
          updatedAt: Date
          title: string
          type: MediaType
          publicationStatus: PublicationStatus
          tags: Array<MediaTags>
          date?: Date | null
          startTrim?: number | null
          endTrim?: number | null
          liveStreamId?: string | null
          videoClipId?: string | null
          imageId?: string | null
          thumbnailId?: string | null
          ownerOrganismId?: string | null
          pinned?: boolean | null
          liveStream?: {
            __typename?: 'LiveStream'
            id: string
            playbackUrl: string
            ingestUrl?: string | null
            wildmokaEventID?: string | null
            videoEngine: VideoEngine
            state: LiveStreamState
            streamId?: string | null
            sveBroadcastId?: string | null
            sveChannelId?: string | null
            externalFeedProvider?: ExternalFeedType | null
            externalFeedUrl?: string | null
            event?: {
              __typename?: 'WMEvent'
              id: string
              name: string
              description?: string | null
              testStartDate: Date
              editoStartDate: Date
              editoStopDate: Date
              stopDate: Date
              state: WmEventState
              notifEmail?: string | null
              clipCreationAtEnd: boolean
              clipPublicationAtEnd: boolean
              livePublication: boolean
              stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
              live?: {
                __typename?: 'WMEventLive'
                id: string
                publishStatus: WmLiveState
                type: WmLiveType
                autoAnnounceDate?: Date | null
                template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
              } | null
              clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
            } | null
            sveBroadcast?: {
              __typename?: 'SVEBroadcast'
              id: string
              status: SveBroadcastState
              channelId: string
              startDate?: Date | null
              clipStartDate?: Date | null
              stopDate?: Date | null
              scheduledStartDate: Date
              scheduledClipStartDate: Date
              scheduledStopDate: Date
            } | null
            sveLiveMonitoring?: {
              __typename?: 'SVELiveMonitoring'
              alerts?: {
                __typename?: 'SVELiveMonitoringAlerts'
                audioNotDetected?: string | null
                initialProbeTakingLonger?: string | null
                videoNotDetected?: string | null
                RTMPHasNoAudioVideo?: string | null
                RTMPStreamNotFound?: string | null
              } | null
              stats?: {
                __typename?: 'SVELiveMonitoringStats'
                networkIn?: string | null
                droppedFrames?: string | null
                inputVideoFrameRate?: string | null
                outputAudioLevelLkfs?: string | null
              } | null
            } | null
          } | null
          videoClip?: {
            __typename?: 'VideoClip'
            id: string
            playbackUrl: string
            downloadLink: string
            status?: VideoClipStatus | null
            updatedAt: Date
            createdAt: Date
            fileId?: string | null
            error?: {
              __typename?: 'VideoClipError'
              guid: string
              workflowStatus: string
              workflowErrorAt: string
              errorMessage: string
              errorDetails: string
            } | null
            captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
            externalFeed?: {
              __typename?: 'ExternalFeed'
              externalFeedProvider: ExternalFeedType
              externalFeedUrl: string
            } | null
          } | null
          image?: {
            __typename?: 'File'
            id: string
            filename: string
            size: number
            mimetype: string
            downloadUrl: string
            thumbUrl?: string | null
            createdAt: Date
            metadata?: {
              __typename?: 'FileMetadata'
              title?: string | null
              description?: string | null
              aspect?: number | null
              width?: number | null
              height?: number | null
              sourceFileId?: string | null
            } | null
          } | null
          thumbnail?: {
            __typename?: 'Media'
            id: string
            image?: {
              __typename?: 'File'
              id: string
              filename: string
              size: number
              mimetype: string
              downloadUrl: string
              thumbUrl?: string | null
              createdAt: Date
              metadata?: {
                __typename?: 'FileMetadata'
                title?: string | null
                description?: string | null
                aspect?: number | null
                width?: number | null
                height?: number | null
                sourceFileId?: string | null
              } | null
            } | null
          } | null
          timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
        } | null
        transparentDarkImage?: {
          __typename?: 'Media'
          id: string
          createdAt: Date
          updatedAt: Date
          title: string
          type: MediaType
          publicationStatus: PublicationStatus
          tags: Array<MediaTags>
          date?: Date | null
          startTrim?: number | null
          endTrim?: number | null
          liveStreamId?: string | null
          videoClipId?: string | null
          imageId?: string | null
          thumbnailId?: string | null
          ownerOrganismId?: string | null
          pinned?: boolean | null
          liveStream?: {
            __typename?: 'LiveStream'
            id: string
            playbackUrl: string
            ingestUrl?: string | null
            wildmokaEventID?: string | null
            videoEngine: VideoEngine
            state: LiveStreamState
            streamId?: string | null
            sveBroadcastId?: string | null
            sveChannelId?: string | null
            externalFeedProvider?: ExternalFeedType | null
            externalFeedUrl?: string | null
            event?: {
              __typename?: 'WMEvent'
              id: string
              name: string
              description?: string | null
              testStartDate: Date
              editoStartDate: Date
              editoStopDate: Date
              stopDate: Date
              state: WmEventState
              notifEmail?: string | null
              clipCreationAtEnd: boolean
              clipPublicationAtEnd: boolean
              livePublication: boolean
              stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
              live?: {
                __typename?: 'WMEventLive'
                id: string
                publishStatus: WmLiveState
                type: WmLiveType
                autoAnnounceDate?: Date | null
                template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
              } | null
              clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
            } | null
            sveBroadcast?: {
              __typename?: 'SVEBroadcast'
              id: string
              status: SveBroadcastState
              channelId: string
              startDate?: Date | null
              clipStartDate?: Date | null
              stopDate?: Date | null
              scheduledStartDate: Date
              scheduledClipStartDate: Date
              scheduledStopDate: Date
            } | null
            sveLiveMonitoring?: {
              __typename?: 'SVELiveMonitoring'
              alerts?: {
                __typename?: 'SVELiveMonitoringAlerts'
                audioNotDetected?: string | null
                initialProbeTakingLonger?: string | null
                videoNotDetected?: string | null
                RTMPHasNoAudioVideo?: string | null
                RTMPStreamNotFound?: string | null
              } | null
              stats?: {
                __typename?: 'SVELiveMonitoringStats'
                networkIn?: string | null
                droppedFrames?: string | null
                inputVideoFrameRate?: string | null
                outputAudioLevelLkfs?: string | null
              } | null
            } | null
          } | null
          videoClip?: {
            __typename?: 'VideoClip'
            id: string
            playbackUrl: string
            downloadLink: string
            status?: VideoClipStatus | null
            updatedAt: Date
            createdAt: Date
            fileId?: string | null
            error?: {
              __typename?: 'VideoClipError'
              guid: string
              workflowStatus: string
              workflowErrorAt: string
              errorMessage: string
              errorDetails: string
            } | null
            captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
            externalFeed?: {
              __typename?: 'ExternalFeed'
              externalFeedProvider: ExternalFeedType
              externalFeedUrl: string
            } | null
          } | null
          image?: {
            __typename?: 'File'
            id: string
            filename: string
            size: number
            mimetype: string
            downloadUrl: string
            thumbUrl?: string | null
            createdAt: Date
            metadata?: {
              __typename?: 'FileMetadata'
              title?: string | null
              description?: string | null
              aspect?: number | null
              width?: number | null
              height?: number | null
              sourceFileId?: string | null
            } | null
          } | null
          thumbnail?: {
            __typename?: 'Media'
            id: string
            image?: {
              __typename?: 'File'
              id: string
              filename: string
              size: number
              mimetype: string
              downloadUrl: string
              thumbUrl?: string | null
              createdAt: Date
              metadata?: {
                __typename?: 'FileMetadata'
                title?: string | null
                description?: string | null
                aspect?: number | null
                width?: number | null
                height?: number | null
                sourceFileId?: string | null
              } | null
            } | null
          } | null
          timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
        } | null
        transparentLightImage?: {
          __typename?: 'Media'
          id: string
          createdAt: Date
          updatedAt: Date
          title: string
          type: MediaType
          publicationStatus: PublicationStatus
          tags: Array<MediaTags>
          date?: Date | null
          startTrim?: number | null
          endTrim?: number | null
          liveStreamId?: string | null
          videoClipId?: string | null
          imageId?: string | null
          thumbnailId?: string | null
          ownerOrganismId?: string | null
          pinned?: boolean | null
          liveStream?: {
            __typename?: 'LiveStream'
            id: string
            playbackUrl: string
            ingestUrl?: string | null
            wildmokaEventID?: string | null
            videoEngine: VideoEngine
            state: LiveStreamState
            streamId?: string | null
            sveBroadcastId?: string | null
            sveChannelId?: string | null
            externalFeedProvider?: ExternalFeedType | null
            externalFeedUrl?: string | null
            event?: {
              __typename?: 'WMEvent'
              id: string
              name: string
              description?: string | null
              testStartDate: Date
              editoStartDate: Date
              editoStopDate: Date
              stopDate: Date
              state: WmEventState
              notifEmail?: string | null
              clipCreationAtEnd: boolean
              clipPublicationAtEnd: boolean
              livePublication: boolean
              stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
              live?: {
                __typename?: 'WMEventLive'
                id: string
                publishStatus: WmLiveState
                type: WmLiveType
                autoAnnounceDate?: Date | null
                template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
              } | null
              clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
            } | null
            sveBroadcast?: {
              __typename?: 'SVEBroadcast'
              id: string
              status: SveBroadcastState
              channelId: string
              startDate?: Date | null
              clipStartDate?: Date | null
              stopDate?: Date | null
              scheduledStartDate: Date
              scheduledClipStartDate: Date
              scheduledStopDate: Date
            } | null
            sveLiveMonitoring?: {
              __typename?: 'SVELiveMonitoring'
              alerts?: {
                __typename?: 'SVELiveMonitoringAlerts'
                audioNotDetected?: string | null
                initialProbeTakingLonger?: string | null
                videoNotDetected?: string | null
                RTMPHasNoAudioVideo?: string | null
                RTMPStreamNotFound?: string | null
              } | null
              stats?: {
                __typename?: 'SVELiveMonitoringStats'
                networkIn?: string | null
                droppedFrames?: string | null
                inputVideoFrameRate?: string | null
                outputAudioLevelLkfs?: string | null
              } | null
            } | null
          } | null
          videoClip?: {
            __typename?: 'VideoClip'
            id: string
            playbackUrl: string
            downloadLink: string
            status?: VideoClipStatus | null
            updatedAt: Date
            createdAt: Date
            fileId?: string | null
            error?: {
              __typename?: 'VideoClipError'
              guid: string
              workflowStatus: string
              workflowErrorAt: string
              errorMessage: string
              errorDetails: string
            } | null
            captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
            externalFeed?: {
              __typename?: 'ExternalFeed'
              externalFeedProvider: ExternalFeedType
              externalFeedUrl: string
            } | null
          } | null
          image?: {
            __typename?: 'File'
            id: string
            filename: string
            size: number
            mimetype: string
            downloadUrl: string
            thumbUrl?: string | null
            createdAt: Date
            metadata?: {
              __typename?: 'FileMetadata'
              title?: string | null
              description?: string | null
              aspect?: number | null
              width?: number | null
              height?: number | null
              sourceFileId?: string | null
            } | null
          } | null
          thumbnail?: {
            __typename?: 'Media'
            id: string
            image?: {
              __typename?: 'File'
              id: string
              filename: string
              size: number
              mimetype: string
              downloadUrl: string
              thumbUrl?: string | null
              createdAt: Date
              metadata?: {
                __typename?: 'FileMetadata'
                title?: string | null
                description?: string | null
                aspect?: number | null
                width?: number | null
                height?: number | null
                sourceFileId?: string | null
              } | null
            } | null
          } | null
          timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
        } | null
      }
      sports: Array<{ __typename?: 'Sport'; id: string }>
      bannerImage?: {
        __typename?: 'Media'
        id: string
        createdAt: Date
        updatedAt: Date
        title: string
        type: MediaType
        publicationStatus: PublicationStatus
        tags: Array<MediaTags>
        date?: Date | null
        startTrim?: number | null
        endTrim?: number | null
        liveStreamId?: string | null
        videoClipId?: string | null
        imageId?: string | null
        thumbnailId?: string | null
        ownerOrganismId?: string | null
        pinned?: boolean | null
        liveStream?: {
          __typename?: 'LiveStream'
          id: string
          playbackUrl: string
          ingestUrl?: string | null
          wildmokaEventID?: string | null
          videoEngine: VideoEngine
          state: LiveStreamState
          streamId?: string | null
          sveBroadcastId?: string | null
          sveChannelId?: string | null
          externalFeedProvider?: ExternalFeedType | null
          externalFeedUrl?: string | null
          event?: {
            __typename?: 'WMEvent'
            id: string
            name: string
            description?: string | null
            testStartDate: Date
            editoStartDate: Date
            editoStopDate: Date
            stopDate: Date
            state: WmEventState
            notifEmail?: string | null
            clipCreationAtEnd: boolean
            clipPublicationAtEnd: boolean
            livePublication: boolean
            stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
            live?: {
              __typename?: 'WMEventLive'
              id: string
              publishStatus: WmLiveState
              type: WmLiveType
              autoAnnounceDate?: Date | null
              template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
            } | null
            clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
          } | null
          sveBroadcast?: {
            __typename?: 'SVEBroadcast'
            id: string
            status: SveBroadcastState
            channelId: string
            startDate?: Date | null
            clipStartDate?: Date | null
            stopDate?: Date | null
            scheduledStartDate: Date
            scheduledClipStartDate: Date
            scheduledStopDate: Date
          } | null
          sveLiveMonitoring?: {
            __typename?: 'SVELiveMonitoring'
            alerts?: {
              __typename?: 'SVELiveMonitoringAlerts'
              audioNotDetected?: string | null
              initialProbeTakingLonger?: string | null
              videoNotDetected?: string | null
              RTMPHasNoAudioVideo?: string | null
              RTMPStreamNotFound?: string | null
            } | null
            stats?: {
              __typename?: 'SVELiveMonitoringStats'
              networkIn?: string | null
              droppedFrames?: string | null
              inputVideoFrameRate?: string | null
              outputAudioLevelLkfs?: string | null
            } | null
          } | null
        } | null
        videoClip?: {
          __typename?: 'VideoClip'
          id: string
          playbackUrl: string
          downloadLink: string
          status?: VideoClipStatus | null
          updatedAt: Date
          createdAt: Date
          fileId?: string | null
          error?: {
            __typename?: 'VideoClipError'
            guid: string
            workflowStatus: string
            workflowErrorAt: string
            errorMessage: string
            errorDetails: string
          } | null
          captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
          externalFeed?: {
            __typename?: 'ExternalFeed'
            externalFeedProvider: ExternalFeedType
            externalFeedUrl: string
          } | null
        } | null
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
        thumbnail?: {
          __typename?: 'Media'
          id: string
          image?: {
            __typename?: 'File'
            id: string
            filename: string
            size: number
            mimetype: string
            downloadUrl: string
            thumbUrl?: string | null
            createdAt: Date
            metadata?: {
              __typename?: 'FileMetadata'
              title?: string | null
              description?: string | null
              aspect?: number | null
              width?: number | null
              height?: number | null
              sourceFileId?: string | null
            } | null
          } | null
        } | null
        timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
      } | null
      adminBadge?: {
        __typename?: 'Media'
        id: string
        createdAt: Date
        updatedAt: Date
        title: string
        type: MediaType
        publicationStatus: PublicationStatus
        tags: Array<MediaTags>
        date?: Date | null
        startTrim?: number | null
        endTrim?: number | null
        liveStreamId?: string | null
        videoClipId?: string | null
        imageId?: string | null
        thumbnailId?: string | null
        ownerOrganismId?: string | null
        pinned?: boolean | null
        liveStream?: {
          __typename?: 'LiveStream'
          id: string
          playbackUrl: string
          ingestUrl?: string | null
          wildmokaEventID?: string | null
          videoEngine: VideoEngine
          state: LiveStreamState
          streamId?: string | null
          sveBroadcastId?: string | null
          sveChannelId?: string | null
          externalFeedProvider?: ExternalFeedType | null
          externalFeedUrl?: string | null
          event?: {
            __typename?: 'WMEvent'
            id: string
            name: string
            description?: string | null
            testStartDate: Date
            editoStartDate: Date
            editoStopDate: Date
            stopDate: Date
            state: WmEventState
            notifEmail?: string | null
            clipCreationAtEnd: boolean
            clipPublicationAtEnd: boolean
            livePublication: boolean
            stream: { __typename?: 'WMEventStream'; id: string; name: string; tags: Array<string> }
            live?: {
              __typename?: 'WMEventLive'
              id: string
              publishStatus: WmLiveState
              type: WmLiveType
              autoAnnounceDate?: Date | null
              template: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType }
            } | null
            clipTemplate?: { __typename?: 'WMEventTemplate'; id: string; name: string; type: WmTemplateType } | null
          } | null
          sveBroadcast?: {
            __typename?: 'SVEBroadcast'
            id: string
            status: SveBroadcastState
            channelId: string
            startDate?: Date | null
            clipStartDate?: Date | null
            stopDate?: Date | null
            scheduledStartDate: Date
            scheduledClipStartDate: Date
            scheduledStopDate: Date
          } | null
          sveLiveMonitoring?: {
            __typename?: 'SVELiveMonitoring'
            alerts?: {
              __typename?: 'SVELiveMonitoringAlerts'
              audioNotDetected?: string | null
              initialProbeTakingLonger?: string | null
              videoNotDetected?: string | null
              RTMPHasNoAudioVideo?: string | null
              RTMPStreamNotFound?: string | null
            } | null
            stats?: {
              __typename?: 'SVELiveMonitoringStats'
              networkIn?: string | null
              droppedFrames?: string | null
              inputVideoFrameRate?: string | null
              outputAudioLevelLkfs?: string | null
            } | null
          } | null
        } | null
        videoClip?: {
          __typename?: 'VideoClip'
          id: string
          playbackUrl: string
          downloadLink: string
          status?: VideoClipStatus | null
          updatedAt: Date
          createdAt: Date
          fileId?: string | null
          error?: {
            __typename?: 'VideoClipError'
            guid: string
            workflowStatus: string
            workflowErrorAt: string
            errorMessage: string
            errorDetails: string
          } | null
          captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
          externalFeed?: {
            __typename?: 'ExternalFeed'
            externalFeedProvider: ExternalFeedType
            externalFeedUrl: string
          } | null
        } | null
        image?: {
          __typename?: 'File'
          id: string
          filename: string
          size: number
          mimetype: string
          downloadUrl: string
          thumbUrl?: string | null
          createdAt: Date
          metadata?: {
            __typename?: 'FileMetadata'
            title?: string | null
            description?: string | null
            aspect?: number | null
            width?: number | null
            height?: number | null
            sourceFileId?: string | null
          } | null
        } | null
        thumbnail?: {
          __typename?: 'Media'
          id: string
          image?: {
            __typename?: 'File'
            id: string
            filename: string
            size: number
            mimetype: string
            downloadUrl: string
            thumbUrl?: string | null
            createdAt: Date
            metadata?: {
              __typename?: 'FileMetadata'
              title?: string | null
              description?: string | null
              aspect?: number | null
              width?: number | null
              height?: number | null
              sourceFileId?: string | null
            } | null
          } | null
        } | null
        timelineMarkers?: Array<{ __typename?: 'Marker'; title: string; time: number; icon?: string | null }> | null
      } | null
      bannerVideo?: {
        __typename?: 'Media'
        id: string
        videoClip?: { __typename?: 'VideoClip'; id: string; playbackUrl: string } | null
      } | null
      pageWidgets?: Array<{
        __typename?: 'CustomPageWidget'
        name: CustomPageWidgetName
        position: number
        display?: CustomPageDisplayTypes | null
        contentDisplay?: ContentSetContentDisplay | null
        cardsDisplay?: CardsDisplay | null
        options?: {
          __typename?: 'CustomPageWidgetOptions'
          editedCategoryId?: string | null
          sportItemSetType?: SportItemSetType | null
          replays?: {
            __typename?: 'ReplaysWidget'
            defaultLocale: string
            maxReplays?: number | null
            depthInDays?: number | null
            nameT: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }>
          } | null
          externalContent?: {
            __typename?: 'ExternalContentWidget'
            name?: string | null
            defaultLocale: string
            maxItemsCount: number
            source: WidgetExternalContentSource
            nameT: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }>
            period?: { __typename?: 'StartEndDates'; startDate: Date; endDate: Date } | null
            wecContent?: {
              __typename?: 'WECNewsContent'
              teamECMIds?: Array<string> | null
              driverECMIds?: Array<string> | null
              carECMIds?: Array<string> | null
              categoryECMIds?: Array<string> | null
              raceECMIds?: Array<string> | null
            } | null
            rssContent?: { __typename?: 'RSSFeedContent'; feedUrl: string } | null
          } | null
        } | null
      }> | null
      offers: Array<{
        __typename?: 'PurchasableOffer'
        id: string
        mode: OfferMode
        delay?: number | null
        expiredAt?: Date | null
        publicationStatus: PublicationStatus
        title: string
        defaultLocale?: string | null
        url?: string | null
        bannerImageId?: string | null
        thumbnailImageId?: string | null
        freeWithLicense: boolean
        position?: number | null
        titleT?: Array<{ __typename?: 'ContentTranslation'; locale: string; content: string }> | null
        description: Array<{ __typename?: 'OfferDescription'; header: string; content: string }>
        descriptionT?: Array<{
          __typename?: 'ContentTranslationDescriptionPurchasableOffer'
          locale: string
          content?: Array<{ __typename?: 'OfferDescription'; header: string; content: string }> | null
        }> | null
        products: Array<{
          __typename?: 'Product'
          productId: string
          platform: Platform
          period?: Period | null
          publicationStatus: PublicationStatus
          purchasableOfferId?: string | null
          licensedUserOnly?: boolean | null
          premiumOf?: Array<string> | null
          prices: Array<{ __typename?: 'Price'; value: number; currency: string }>
        }>
      }>
      license?: { __typename?: 'OrganismLicense'; enabled: boolean; api?: LicenseApi | null } | null
      tags?: Array<{ __typename?: 'Tag'; id: string; title: string; subTags?: Array<string> | null }> | null
    }> | null
  } | null
}

export type UpdateCacheReceiptMutationVariables = Exact<{
  userId?: InputMaybe<Scalars['ID']>
}>

export type UpdateCacheReceiptMutation = { __typename?: 'Mutation'; updateCacheReceipt: boolean }

export type OrganismLicensedUsersQueryVariables = Exact<{
  organismId: Scalars['ID']
  offset?: InputMaybe<Scalars['Int']>
  first?: InputMaybe<Scalars['Int']>
  sortBy?: InputMaybe<SortBy>
}>

export type OrganismLicensedUsersQuery = {
  __typename?: 'Query'
  users?: {
    __typename?: 'UserPageResult'
    totalCount?: number | null
    users: Array<{
      __typename?: 'User'
      id: string
      email: string
      firstName: string
      lastName: string
      gender?: Gender | null
      roleIds: Array<UserRoleId>
      createdAt: Date
      lastActivity?: Date | null
      cguAccepted?: Date | null
      signUpOrigin?: SignUpOrigin | null
      verified: boolean
      location?: { __typename?: 'Location'; country: string; postalCode?: string | null } | null
      applicationOrigin?: { __typename?: 'Application'; id: string; title?: string | null } | null
    }>
  } | null
}

export type ImportUsersFansFromCsvMutationVariables = Exact<{
  file: Scalars['Upload']
  options?: InputMaybe<ImportUsersOptions>
}>

export type ImportUsersFansFromCsvMutation = { __typename?: 'Mutation'; importUsersFansFromCsv?: boolean | null }

export type RevokeLicenseMutationVariables = Exact<{
  userId: Scalars['ID']
  organismId: Scalars['ID']
}>

export type RevokeLicenseMutation = {
  __typename?: 'Mutation'
  revokeUserLicense: {
    __typename?: 'User'
    id: string
    licenses?: Array<{ __typename?: 'LicenseInfo'; organismId: string; isLicensed: boolean }> | null
  }
}

export type LatestUsersExportDownloadUrlQueryVariables = Exact<{
  maxDate?: InputMaybe<Scalars['Date']>
}>

export type LatestUsersExportDownloadUrlQuery = { __typename?: 'Query'; latestUsersExportDownloadUrl?: string | null }

export type UsersExportStateQueryVariables = Exact<{ [key: string]: never }>

export type UsersExportStateQuery = { __typename?: 'Query'; usersExportState: UsersExportState }

export type ScheduleUsersExportMutationVariables = Exact<{
  input: UsersExportInput
}>

export type ScheduleUsersExportMutation = { __typename?: 'Mutation'; scheduleUsersExport: boolean }

export type UpdateTokenPayloadMutationVariables = Exact<{ [key: string]: never }>

export type UpdateTokenPayloadMutation = { __typename?: 'Mutation'; updateTokenPayload: string }

export type UserRoleFragment = {
  __typename?: 'UserRole'
  id: UserRoleId
  title: string
  shortTitle: string
  description: string
  generalBasePermissions: Array<Permission>
  organismsBasePermissions: Array<Permission>
}

export type UserRolesQueryVariables = Exact<{ [key: string]: never }>

export type UserRolesQuery = {
  __typename?: 'Query'
  userRoles?: Array<{
    __typename?: 'UserRole'
    id: UserRoleId
    title: string
    shortTitle: string
    description: string
    generalBasePermissions: Array<Permission>
    organismsBasePermissions: Array<Permission>
  }> | null
}

export type UserRoleQueryVariables = Exact<{
  id: UserRoleId
}>

export type UserRoleQuery = {
  __typename?: 'Query'
  userRole?: {
    __typename?: 'UserRole'
    id: UserRoleId
    title: string
    shortTitle: string
    description: string
    generalBasePermissions: Array<Permission>
    organismsBasePermissions: Array<Permission>
  } | null
}

export type VideoClipContentFragment = {
  __typename?: 'VideoClip'
  id: string
  playbackUrl: string
  downloadLink: string
  status?: VideoClipStatus | null
  updatedAt: Date
  createdAt: Date
  fileId?: string | null
  error?: {
    __typename?: 'VideoClipError'
    guid: string
    workflowStatus: string
    workflowErrorAt: string
    errorMessage: string
    errorDetails: string
  } | null
  captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
  externalFeed?: { __typename?: 'ExternalFeed'; externalFeedProvider: ExternalFeedType; externalFeedUrl: string } | null
}

export type VideoClipQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type VideoClipQuery = {
  __typename?: 'Query'
  videoClip?: {
    __typename?: 'VideoClip'
    id: string
    playbackUrl: string
    downloadLink: string
    status?: VideoClipStatus | null
    updatedAt: Date
    createdAt: Date
    fileId?: string | null
    error?: {
      __typename?: 'VideoClipError'
      guid: string
      workflowStatus: string
      workflowErrorAt: string
      errorMessage: string
      errorDetails: string
    } | null
    captions?: Array<{ __typename?: 'Caption'; srcFileId: string; language: string; title: string }> | null
    externalFeed?: {
      __typename?: 'ExternalFeed'
      externalFeedProvider: ExternalFeedType
      externalFeedUrl: string
    } | null
  } | null
}

export type CreateMultipartQueryVariables = Exact<{
  hash: Scalars['String']
  fileName?: InputMaybe<Scalars['String']>
  extension?: InputMaybe<Scalars['String']>
}>

export type CreateMultipartQuery = {
  __typename?: 'Query'
  createMultipart?: { __typename?: 'Multipart'; uploadId: string; key: string; currentPart: number } | null
}

export type UploadPartMutationVariables = Exact<{
  input: UploadPartInput
}>

export type UploadPartMutation = { __typename?: 'Mutation'; uploadPart: boolean }

export type CompleteMultipartMutationVariables = Exact<{
  input: CompleteMultipartInput
}>

export type CompleteMultipartMutation = { __typename?: 'Mutation'; completeMultipart: boolean }

export type SignedUrlQueryVariables = Exact<{
  sportItemId?: InputMaybe<Scalars['ID']>
  fileName?: InputMaybe<Scalars['String']>
  extension?: InputMaybe<Scalars['String']>
}>

export type SignedUrlQuery = {
  __typename?: 'Query'
  signedUrl?: { __typename?: 'SignedUrl'; url: string; key: string } | null
}

export type WmStreamContentFragment = {
  __typename?: 'WMStream'
  id: string
  name: string
  ingestionStatus: WmIngestionStatus
  inputUrl: string
  outputUrl: string
  map?: { __typename?: 'StreamMap'; id: string; channelArn: string } | null
}

export type ListStreamsQueryVariables = Exact<{
  filters?: InputMaybe<StreamFilters>
}>

export type ListStreamsQuery = {
  __typename?: 'Query'
  listStreams: Array<{
    __typename?: 'WMStreamLight'
    id: string
    state: WmStreamState
    name: string
    status: WmStreamStatus
    tags?: Array<string> | null
    outputUrl: string
    map?: { __typename?: 'StreamMap'; id: string; playbackUrl: string; outputUrl: string; channelArn: string } | null
  }>
}

export type GetStreamQueryVariables = Exact<{
  id: Scalars['WildmokaID']
}>

export type GetStreamQuery = {
  __typename?: 'Query'
  getStream: {
    __typename?: 'WMStream'
    id: string
    name: string
    ingestionStatus: WmIngestionStatus
    inputUrl: string
    outputUrl: string
    map?: { __typename?: 'StreamMap'; id: string; channelArn: string } | null
  }
}

export type ListTemplatesQueryVariables = Exact<{
  type?: InputMaybe<WmTemplateType>
  organismId?: InputMaybe<Scalars['ID']>
}>

export type ListTemplatesQuery = {
  __typename?: 'Query'
  listTemplates: Array<{ __typename?: 'WMTemplate'; id: string; name: string; type: WmTemplateType }>
}

export type ListProfilesQueryVariables = Exact<{ [key: string]: never }>

export type ListProfilesQuery = {
  __typename?: 'Query'
  listProfiles: Array<{ __typename?: 'WMProfile'; id: string; name: string; tags: Array<string> }>
}

export type WriteStreamMapMutationVariables = Exact<{
  input: WriteStreamMapInput
}>

export type WriteStreamMapMutation = {
  __typename?: 'Mutation'
  writeStreamMap: { __typename?: 'StreamMap'; id: string; streamId: string; playbackUrl: string }
}

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  BigInt: number
  Date: Date
  JSON: any
  URL: string
  Upload: any
  WildmokaID: string
}

export enum AccessibleContent {
  All = 'all',
  Published = 'published',
}

export type ActiveLiveStreamsResult = {
  __typename?: 'ActiveLiveStreamsResult'
  liveStreams: Array<LiveStream>
  sportItems: Array<SportItem>
}

export type AdCampaign = {
  __typename?: 'AdCampaign'
  affiliates: Array<Affiliate>
  affiliatesGroupedByCategory?: Maybe<AffiliatesGroupedByCategory>
  createdAt: Scalars['Date']
  deletedAt?: Maybe<Scalars['Date']>
  displayedItemsCountMax: Scalars['Int']
  id: Scalars['ID']
  organismId: Scalars['ID']
  prerollConfigs: Array<PrerollConfig>
  publicationStatus: PublicationStatus
  /** @deprecated Use createdAt, updatedAt and deletedAt directly now. This resolver will be removed. */
  timestamps: Timestamps
  title: Scalars['String']
  updatedAt: Scalars['Date']
}

export type AdCampaignFilters = {
  organismId?: InputMaybe<Scalars['ID']>
}

export type AdCampaignResult = {
  __typename?: 'AdCampaignResult'
  adCampaigns: Array<AdCampaign>
  totalCount: Scalars['Int']
}

export type Affiliate = {
  __typename?: 'Affiliate'
  displayTypes: Array<AffiliateDisplayType>
  displayWeight: Scalars['Int']
  link?: Maybe<Link>
  linkId: Scalars['ID']
  type: AffiliateType
}

export enum AffiliateDisplayType {
  Ad = 'ad',
  Billboard = 'billboard',
  Button = 'button',
  Thumbnail = 'thumbnail',
}

export type AffiliateInput = {
  displayTypes: Array<AffiliateDisplayType>
  displayWeight: Scalars['Int']
  linkId: Scalars['ID']
  type: AffiliateType
}

export enum AffiliateType {
  GoogleAdManager = 'googleAdManager',
  Link = 'link',
}

export type AffiliatesGroupedByCategory = {
  __typename?: 'AffiliatesGroupedByCategory'
  button?: Maybe<Affiliate>
  prerolls?: Maybe<Array<AffiliatesGroupedByCategoryPrerolls>>
  thumbnails: Array<Affiliate>
}

export type AffiliatesGroupedByCategoryPrerolls = {
  __typename?: 'AffiliatesGroupedByCategoryPrerolls'
  ads: Array<Affiliate>
  billboards: Array<Affiliate>
  videoType: PrerollVideoType
}

export type AppleSubscriptionReceiptInput = {
  receipt: Scalars['String']
}

export type Application = {
  __typename?: 'Application'
  appleSharedSecret?: Maybe<Scalars['String']>
  applicationId: Scalars['String']
  cac?: Maybe<ContentAccessControl>
  createdAt: Scalars['Date']
  deletedAt?: Maybe<Scalars['Date']>
  excludedContent: ExcludedContent
  forgotPasswordCallbackUrl?: Maybe<Scalars['String']>
  id: Scalars['ID']
  loginCallbackUrl?: Maybe<Scalars['String']>
  organism?: Maybe<Organism>
  organismId?: Maybe<Scalars['ID']>
  packageName?: Maybe<Scalars['String']>
  publicationStatus: PublicationStatus
  resetPasswordCallbackUrl?: Maybe<Scalars['String']>
  sponsorImage?: Maybe<Media>
  sponsorImageFileId?: Maybe<Scalars['ID']>
  sponsoringUrl?: Maybe<Scalars['String']>
  /** @deprecated Use createdAt, updatedAt and deletedAt directly now. This resolver will be removed. */
  timestamps: Timestamps
  title?: Maybe<Scalars['String']>
  type: ApplicationType
  updatedAt: Scalars['Date']
  url?: Maybe<Scalars['String']>
  verifyCallbackUrl?: Maybe<Scalars['String']>
}

export type ApplicationDesign = {
  __typename?: 'ApplicationDesign'
  createdAt: Scalars['Date']
  deletedAt?: Maybe<Scalars['Date']>
  description: Scalars['String']
  id: Scalars['ID']
  organismId: Scalars['ID']
  theme: ApplicationDesignTheme
  /** @deprecated Use createdAt, updatedAt and deletedAt directly now. This resolver will be removed. */
  timestamps: Timestamps
  title: Scalars['String']
  updatedAt: Scalars['Date']
}

export type ApplicationDesignFilters = {
  id?: InputMaybe<Scalars['ID']>
  organismId?: InputMaybe<Scalars['ID']>
}

export type ApplicationDesignPageResult = {
  __typename?: 'ApplicationDesignPageResult'
  applicationDesigns: Array<ApplicationDesign>
  totalCount: Scalars['Int']
}

export type ApplicationDesignTheme = {
  __typename?: 'ApplicationDesignTheme'
  onPrimaryColor: Scalars['String']
  onSurfaceAlternateColor: Scalars['String']
  onSurfaceColor: Scalars['String']
  primaryColor: Scalars['String']
  surfaceColor: Scalars['String']
  top10BorderColor?: Maybe<Scalars['String']>
  top10Color: Scalars['String']
}

export type ApplicationDesignThemeInput = {
  onPrimaryColor: Scalars['String']
  onSurfaceAlternateColor: Scalars['String']
  onSurfaceColor: Scalars['String']
  primaryColor: Scalars['String']
  surfaceColor: Scalars['String']
  top10BorderColor?: InputMaybe<Scalars['String']>
  top10Color: Scalars['String']
}

export type ApplicationKey = {
  __typename?: 'ApplicationKey'
  enabled: Scalars['Boolean']
  expirationDate: Scalars['Date']
  id: Scalars['ID']
  key: Scalars['String']
  name: Scalars['String']
  type: ApplicationKeyType
}

export enum ApplicationKeyType {
  IframePlayerAccess = 'IframePlayerAccess',
  IframePlayerAccessWithLive = 'IframePlayerAccessWithLive',
}

export type ApplicationPageResult = {
  __typename?: 'ApplicationPageResult'
  applications: Array<Application>
  totalCount?: Maybe<Scalars['Int']>
}

export enum ApplicationType {
  MobileApp = 'mobileApp',
  WebApp = 'webApp',
}

export type ApplicationsFilters = {
  multiOrganism?: InputMaybe<Scalars['Boolean']>
  organismId?: InputMaybe<Scalars['ID']>
  type?: InputMaybe<ApplicationType>
}

export type AvailableContentPushService = {
  __typename?: 'AvailableContentPushService'
  customDestination: Scalars['Boolean']
  type: Scalars['String']
}

export enum CacUserAuthenticationRule {
  NotRequired = 'notRequired',
  Required = 'required',
}

export type CacUserLocationRule = {
  __typename?: 'CACUserLocationRule'
  condition: UserLocationCondition
  distance: Scalars['Float']
  location: GeoLocation
}

export type CacUserLocationRuleInput = {
  condition: UserLocationCondition
  distance: Scalars['Float']
  location: GeoLocationInput
}

export enum CacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
}

export type CacheEntry = {
  __typename?: 'CacheEntry'
  id: Scalars['ID']
  ttl?: Maybe<Scalars['Int']>
  value?: Maybe<Scalars['JSON']>
}

export type Caption = {
  __typename?: 'Caption'
  language: Scalars['String']
  srcFileId: Scalars['String']
  title: Scalars['String']
}

export type CaptionInput = {
  language: Scalars['String']
  srcFileId: Scalars['String']
  title: Scalars['String']
}

export enum CardsDisplay {
  Carousel = 'carousel',
  Grid = 'grid',
}

export type Category = {
  __typename?: 'Category'
  createdAt: Scalars['Date']
  deletedAt?: Maybe<Scalars['Date']>
  id: Scalars['ID']
  /** @deprecated Use createdAt, updatedAt and deletedAt directly now. This resolver will be removed. */
  timestamps: Timestamps
  title: Scalars['String']
  updatedAt: Scalars['Date']
}

export type ChatBanning = {
  __typename?: 'ChatBanning'
  endDate?: Maybe<Scalars['Date']>
  startDate: Scalars['Date']
}

export type ChatMessage = {
  __typename?: 'ChatMessage'
  author: Scalars['String']
  avatarUrl: Scalars['String']
  content: Scalars['String']
  createdAt: Scalars['String']
  id: Scalars['ID']
  isAdmin: Scalars['Boolean']
  moderation?: Maybe<ChatMessageModeration>
  uid: Scalars['ID']
  updatedAt?: Maybe<Scalars['String']>
  visible: Scalars['Boolean']
}

export type ChatMessageModeration = {
  __typename?: 'ChatMessageModeration'
  date: Scalars['String']
  reason: Scalars['String']
  userId: Scalars['ID']
}

export type CheckSveChannelsAvailableInput = {
  channelIds: Array<Scalars['String']>
  from: Scalars['Date']
  sportItemSetId?: InputMaybe<Scalars['ID']>
  to: Scalars['Date']
}

export type CheckStripeCouponResult = {
  __typename?: 'CheckStripeCouponResult'
  discount?: Maybe<StripeDiscount>
  error?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type CompleteMultipartInput = {
  hash: Scalars['String']
  uploadId: Scalars['String']
}

export enum ContentAccess {
  AccessKeyInvalid = 'AccessKeyInvalid',
  Granted = 'Granted',
  OutOfValidAreaLocation = 'OutOfValidAreaLocation',
  UserLocationRequired = 'UserLocationRequired',
}

export type ContentAccessControl = {
  __typename?: 'ContentAccessControl'
  accessKeyRule?: Maybe<ApplicationKey>
  userAuthenticationRule?: Maybe<CacUserAuthenticationRule>
  userLocationRule?: Maybe<CacUserLocationRule>
}

export type ContentAccessControlInput = {
  userAuthenticationRule?: InputMaybe<CacUserAuthenticationRule>
  userLocationRule?: InputMaybe<CacUserLocationRuleInput>
}

export type ContentDeliveryDestination = {
  __typename?: 'ContentDeliveryDestination'
  accessKey: Scalars['String']
  bucketName: Scalars['String']
  bucketPath?: Maybe<Scalars['String']>
  endpointUrl?: Maybe<Scalars['String']>
  region: Scalars['String']
  secretKey: Scalars['String']
}

export type ContentDeliveryDestinationInput = {
  accessKey: Scalars['String']
  bucketName: Scalars['String']
  bucketPath?: InputMaybe<Scalars['String']>
  endpointUrl?: InputMaybe<Scalars['String']>
  region: Scalars['String']
  secretKey: Scalars['String']
}

export enum ContentDeliveryFrequency {
  Daily = 'daily',
  Monthly = 'monthly',
  Weekly = 'weekly',
}

export type ContentDeliveryPeriodicity = {
  __typename?: 'ContentDeliveryPeriodicity'
  firstSendDate?: Maybe<Scalars['Date']>
  frequency?: Maybe<ContentDeliveryFrequency>
}

export type ContentDeliveryPeriodicityInput = {
  firstSendDate?: InputMaybe<Scalars['Date']>
  frequency?: InputMaybe<ContentDeliveryFrequency>
}

export type ContentDeliveryServiceConfig = {
  __typename?: 'ContentDeliveryServiceConfig'
  destination?: Maybe<ContentDeliveryDestination>
  periodicity?: Maybe<ContentDeliveryPeriodicity>
  retentionDurationDays?: Maybe<Scalars['Int']>
  type?: Maybe<Scalars['String']>
}

export type ContentDeliveryServiceConfigInput = {
  destination?: InputMaybe<ContentDeliveryDestinationInput>
  periodicity: ContentDeliveryPeriodicityInput
  retentionDurationDays?: InputMaybe<Scalars['Int']>
  type: Scalars['String']
}

export enum ContentDeliveryStatus {
  Failed = 'failed',
  InProgress = 'inProgress',
  Succeeded = 'succeeded',
}

export enum ContentIdMappingFormat {
  Csv = 'CSV',
  Json = 'JSON',
}

export type ContentImport = {
  __typename?: 'ContentImport'
  data: Scalars['JSON']
  date: Scalars['Date']
  id: Scalars['String']
}

export type ContentImportResult = {
  __typename?: 'ContentImportResult'
  createdSportItemSetsCount: Scalars['Int']
  updatedSportItemSetsCount: Scalars['Int']
}

export enum ContentImportType {
  Ffr = 'FFR',
  Sportall = 'Sportall',
}

export type ContentLink = {
  __typename?: 'ContentLink'
  backgroundColor?: Maybe<Scalars['String']>
  bannerImageUrl?: Maybe<Scalars['String']>
  coverImageUrl?: Maybe<Scalars['String']>
  coverVideoUrl?: Maybe<Scalars['String']>
  details: ContentLinkDetails
  id: Scalars['ID']
  subtitle?: Maybe<Scalars['String']>
  subtitleColor?: Maybe<Scalars['String']>
  subtitleT?: Maybe<Array<ContentTranslation>>
  thumbnailUrl?: Maybe<Scalars['String']>
  title: Scalars['String']
  titleColor?: Maybe<Scalars['String']>
  titleT?: Maybe<Array<ContentTranslation>>
}

export type ContentLinkDetails =
  | LinkDetails
  | MediaDetails
  | NewsDetails
  | OrganismDetails
  | RssFeedContent
  | SportItemDetails
  | SportItemSetDetails
  | WecNewsContent

export enum ContentPage {
  Discover = 'discover',
  DiscoverWithTop10 = 'discoverWithTop10',
  /** @deprecated Use favorites query, this one is public and won't have expected results */
  Favorites = 'favorites',
}

export type ContentPushServiceStreamDelivery = {
  __typename?: 'ContentPushServiceStreamDelivery'
  status?: Maybe<Scalars['String']>
  statusDetails?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
}

export type ContentPushStream = {
  __typename?: 'ContentPushStream'
  contentDelivery?: Maybe<ContentPushServiceStreamDelivery>
  contentDeliveryServiceConfig: ContentDeliveryServiceConfig
  createdAt: Scalars['Date']
  deletedAt?: Maybe<Scalars['Date']>
  downloadExportUrl?: Maybe<Scalars['String']>
  id: Scalars['ID']
  name: Scalars['String']
  /** @deprecated Use createdAt, updatedAt and deletedAt directly now. This resolver will be removed. */
  timestamps: Timestamps
  updatedAt: Scalars['Date']
}

export enum ContentPushStreamServiceName {
  GenericStream = 'GenericStream',
  LiveEvent = 'LiveEvent',
  Molotov = 'Molotov',
  Screach = 'Screach',
}

export type ContentPushStreamsFiltersQuery = {
  types?: InputMaybe<Array<InputMaybe<ContentPushStreamServiceName>>>
}

export type ContentSet = {
  __typename?: 'ContentSet'
  backgroundColor?: Maybe<Scalars['String']>
  cardsDisplay: CardsDisplay
  contentDisplay: ContentSetContentDisplay
  contentLinks?: Maybe<Array<ContentLink>>
  display: ContentSetDisplay
  externalContent?: Maybe<ExternalContentWidget>
  id: Scalars['ID']
  infinite?: Maybe<Scalars['Boolean']>
  numbered?: Maybe<Numbered>
  title: Scalars['String']
  titleColor?: Maybe<Scalars['String']>
  titleT?: Maybe<Array<ContentTranslation>>
}

export enum ContentSetContentDisplay {
  All = 'all',
  ImageOnly = 'imageOnly',
  ImageWithTitle = 'imageWithTitle',
}

export enum ContentSetDisplay {
  SecondClass = 'SecondClass',
  Classic = 'classic',
  FirstClass = 'firstClass',
  FrontPage = 'frontPage',
}

export type ContentSource = {
  __typename?: 'ContentSource'
  id: Scalars['String']
  idPrefix: Scalars['String']
  import?: Maybe<ContentImport>
}

export type ContentTranslation = {
  __typename?: 'ContentTranslation'
  content: Scalars['String']
  locale: Scalars['String']
}

export type ContentTranslationDescriptionPurchasableOffer = {
  __typename?: 'ContentTranslationDescriptionPurchasableOffer'
  content?: Maybe<Array<OfferDescription>>
  locale: Scalars['String']
}

export type ContentTranslationDescriptionPurchasableOfferInput = {
  content?: InputMaybe<Array<OfferDescriptionInput>>
  locale: Scalars['String']
}

export type ContentTranslationInput = {
  content: Scalars['String']
  locale: Scalars['String']
}

export type CreateAdCampaignInput = {
  affiliates: Array<AffiliateInput>
  displayedItemsCountMax: Scalars['Int']
  organismId: Scalars['ID']
  prerollConfigs: Array<PrerollConfigInput>
  publicationStatus: PublicationStatus
  title: Scalars['String']
}

export type CreateApplicationDesignInput = {
  description: Scalars['String']
  organismId: Scalars['ID']
  theme: ApplicationDesignThemeInput
  title: Scalars['String']
}

export type CreateApplicationInput = {
  appleSharedSecret?: InputMaybe<Scalars['String']>
  applicationId: Scalars['String']
  cac?: InputMaybe<ContentAccessControlInput>
  forgotPasswordCallbackUrl?: InputMaybe<Scalars['String']>
  loginCallbackUrl?: InputMaybe<Scalars['String']>
  organismId?: InputMaybe<Scalars['ID']>
  packageName?: InputMaybe<Scalars['String']>
  publicationStatus?: InputMaybe<PublicationStatus>
  resetPasswordCallbackUrl?: InputMaybe<Scalars['String']>
  sponsorImageFileId?: InputMaybe<Scalars['ID']>
  sponsoringUrl?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  type: ApplicationType
  url?: InputMaybe<Scalars['String']>
  verifyCallbackUrl?: InputMaybe<Scalars['String']>
}

export type CreateCategoryInput = {
  title: Scalars['String']
}

export type CreateChatMessageInput = {
  chatRoomId: Scalars['ID']
  content: Scalars['String']
}

export type CreateContentPushStreamInput = {
  contentDeliveryServiceConfig: ContentDeliveryServiceConfigInput
  name: Scalars['String']
}

export type CreateCustomPageInput = {
  applicationId: Scalars['ID']
  backgroundImageId?: InputMaybe<Scalars['ID']>
  backgroundVideoId?: InputMaybe<Scalars['ID']>
  customPageApplicationDesignId?: InputMaybe<Scalars['ID']>
  externalWidgets?: InputMaybe<ExternalWidgetsInput>
  frontPageId?: InputMaybe<Scalars['ID']>
  iconId?: InputMaybe<Scalars['ID']>
  isHomePage?: InputMaybe<Scalars['Boolean']>
  menuBannerImageId?: InputMaybe<Scalars['ID']>
  position?: InputMaybe<Scalars['Int']>
  published: PublicationStatus
  showInNavigationHeader: Scalars['Boolean']
  title: Scalars['String']
  titleT?: InputMaybe<Array<ContentTranslationInput>>
  widgets?: InputMaybe<Array<CustomPageWidgetInput>>
  widgetsType?: InputMaybe<WidgetsType>
}

export type CreateEditedCategoryInput = {
  items?: InputMaybe<Array<EditedCategoryItemInput>>
  organismId?: InputMaybe<Scalars['String']>
  position?: InputMaybe<Scalars['Int']>
  published?: InputMaybe<PublicationStatus>
  title: Scalars['String']
  titleT?: InputMaybe<Array<ContentTranslationInput>>
}

export type CreateExternalFeedMediaLiveStreamInput = {
  externalFeedProvider: ExternalFeedType
  externalFeedUrl: Scalars['String']
  media: CreateMediaInput
}

export type CreateFileMetadataInput = {
  aspect?: InputMaybe<Scalars['Float']>
  description?: InputMaybe<Scalars['String']>
  height?: InputMaybe<Scalars['Float']>
  sourceFileId?: InputMaybe<Scalars['ID']>
  title?: InputMaybe<Scalars['String']>
  width?: InputMaybe<Scalars['Float']>
}

export type CreateFrontPageInput = {
  applicationId?: InputMaybe<Scalars['ID']>
  coverImageId: Scalars['ID']
  coverVideoId?: InputMaybe<Scalars['ID']>
  description?: InputMaybe<Scalars['String']>
  end?: InputMaybe<Scalars['Date']>
  position?: InputMaybe<Scalars['Int']>
  publicationStatus?: InputMaybe<PublicationStatus>
  publishInTop?: InputMaybe<Scalars['Boolean']>
  redirectsAutomatically?: InputMaybe<Scalars['Boolean']>
  sportItemId?: InputMaybe<Scalars['ID']>
  sportItemSetId: Scalars['ID']
  start: Scalars['Date']
  tags?: InputMaybe<Array<Scalars['String']>>
  title?: InputMaybe<Scalars['String']>
  titleImageId: Scalars['ID']
}

export type CreateLegalRestrictionInput = {
  active?: InputMaybe<Scalars['Boolean']>
  countries: Array<Scalars['String']>
  description?: InputMaybe<Scalars['String']>
  mature?: InputMaybe<MatureFilter>
  offerIds?: InputMaybe<Array<Scalars['ID']>>
  organismId?: InputMaybe<Scalars['ID']>
  restrictionTarget?: InputMaybe<RestrictionTargetFilter>
  sportItemIds?: InputMaybe<Array<Scalars['ID']>>
  sportItemSetIds?: InputMaybe<Array<Scalars['ID']>>
  timeSlots?: InputMaybe<TimeSlotsInput>
  type: RestrictionType
}

export type CreateLinkInput = {
  billboardId?: InputMaybe<Scalars['ID']>
  categoriesIds: Array<Scalars['ID']>
  countries: Array<InputMaybe<Scalars['String']>>
  description?: InputMaybe<Scalars['String']>
  descriptionT?: InputMaybe<Array<ContentTranslationInput>>
  gamTagUrl?: InputMaybe<Scalars['String']>
  imageId?: InputMaybe<Scalars['ID']>
  ownerOrganismIds: Array<Scalars['ID']>
  publicationStatus: PublicationStatus
  subtitle?: InputMaybe<Scalars['String']>
  subtitleT?: InputMaybe<Array<ContentTranslationInput>>
  tag?: InputMaybe<LinkTag>
  teaserVideoId?: InputMaybe<Scalars['ID']>
  title: Scalars['String']
  titleT?: InputMaybe<Array<ContentTranslationInput>>
  url?: InputMaybe<Scalars['String']>
}

export type CreateMediaFileInput = {
  file: Scalars['Upload']
  media: CreateMediaInput
  metadata?: InputMaybe<CreateFileMetadataInput>
}

export type CreateMediaInput = {
  date?: InputMaybe<Scalars['Date']>
  ownerOrganismId?: InputMaybe<Scalars['ID']>
  publicationStatus?: InputMaybe<PublicationStatus>
  sportItemId?: InputMaybe<Scalars['ID']>
  tags?: InputMaybe<Array<MediaTags>>
  thumbnailId?: InputMaybe<Scalars['ID']>
  title: Scalars['String']
}

export type CreateMediaLiveStreamInput = {
  externalFeedInput?: InputMaybe<CreateExternalFeedMediaLiveStreamInput>
  media: CreateMediaInput
  sveInput?: InputMaybe<CreateSveMediaLiveStreamInput>
  videoEngine?: InputMaybe<VideoEngine>
  wildmokaInput?: InputMaybe<CreateWildmokaMediaLiveStreamInput>
}

export type CreateMediaVideoClipExternalFeedInput = {
  externalFeedProvider: ExternalFeedType
  externalFeedUrl: Scalars['String']
  media: CreateMediaInput
}

export type CreateMediaVideoClipInput = {
  captions?: InputMaybe<Array<CaptionInput>>
  fileId: Scalars['String']
  media: CreateMediaInput
}

export type CreateNewsInput = {
  content: Scalars['String']
  contentT?: InputMaybe<Array<ContentTranslationInput>>
  coverImageId: Scalars['ID']
  description: Scalars['String']
  descriptionT?: InputMaybe<Array<ContentTranslationInput>>
  organismId: Scalars['String']
  publicationStatus: PublicationStatus
  rawContent: Scalars['String']
  rawContentT?: InputMaybe<Array<ContentTranslationInput>>
  subtitle: Scalars['String']
  subtitleT?: InputMaybe<Array<ContentTranslationInput>>
  title: Scalars['String']
  titleT?: InputMaybe<Array<ContentTranslationInput>>
}

export type CreateOrganismInput = {
  adminBadgeId?: InputMaybe<Scalars['ID']>
  adminsAutomatedEmails?: InputMaybe<Scalars['Boolean']>
  availableExternalSources?: InputMaybe<Array<WidgetExternalContentSource>>
  availableWidgetNames?: InputMaybe<Array<CustomPageWidgetName>>
  bannerImageFileId?: InputMaybe<Scalars['ID']>
  bannerVideoId?: InputMaybe<Scalars['ID']>
  contentPageApplicationDesignId?: InputMaybe<Scalars['ID']>
  description?: InputMaybe<Scalars['String']>
  descriptionT?: InputMaybe<Array<ContentTranslationInput>>
  enableExternalSources?: InputMaybe<Scalars['Boolean']>
  forbiddenKeyWords?: InputMaybe<Array<Scalars['String']>>
  frontPosition?: InputMaybe<Scalars['Int']>
  license?: InputMaybe<OrganismLicenseInput>
  logo: LogoCreateUpdateInput
  officialWebSiteUrl: Scalars['URL']
  onFront?: InputMaybe<Scalars['Boolean']>
  organismPageApplicationDesignId?: InputMaybe<Scalars['ID']>
  publicationStatus?: InputMaybe<PublicationStatus>
  sportIds: Array<Scalars['ID']>
  streamsIds?: InputMaybe<Array<Scalars['WildmokaID']>>
  sveChannelIds?: InputMaybe<Array<Scalars['ID']>>
  tags?: InputMaybe<Array<TagInput>>
  title: Scalars['String']
  titleT?: InputMaybe<Array<ContentTranslationInput>>
  type: OrganismType
  visibleInMultisportApplication?: InputMaybe<Scalars['Boolean']>
  whiteLabel?: InputMaybe<Scalars['Boolean']>
  wmProfileId?: InputMaybe<Scalars['WildmokaID']>
}

export type CreatePurchasableOfferInput = {
  bannerImageId?: InputMaybe<Scalars['ID']>
  delay?: InputMaybe<Scalars['Int']>
  description?: InputMaybe<Array<OfferDescriptionInput>>
  descriptionT?: InputMaybe<Array<ContentTranslationDescriptionPurchasableOfferInput>>
  expiredAt?: InputMaybe<Scalars['Date']>
  freeWithLicense?: InputMaybe<Scalars['Boolean']>
  mode: OfferMode
  organismId: Scalars['ID']
  position?: InputMaybe<Scalars['Int']>
  products: Array<ProductInput>
  publicationStatus?: InputMaybe<PublicationStatus>
  thumbnailImageId?: InputMaybe<Scalars['ID']>
  title?: InputMaybe<Scalars['String']>
  titleT?: InputMaybe<Array<ContentTranslationInput>>
  url?: InputMaybe<Scalars['String']>
}

export type CreateSveChannelInput = {
  inputLossImageUrl?: InputMaybe<Scalars['String']>
  logoUrl?: InputMaybe<Scalars['String']>
  name: Scalars['String']
  socialOutputStreamName?: InputMaybe<Scalars['String']>
  socialOutputUrl?: InputMaybe<Scalars['String']>
}

export type CreateSveMediaLiveStreamInput = {
  media: CreateMediaInput
  sveChannelId: Scalars['ID']
  wmClippingTemplateId?: InputMaybe<Scalars['WildmokaID']>
}

export type CreateSportInput = {
  bannerImageFileId: Scalars['ID']
  bannerVideoId?: InputMaybe<Scalars['ID']>
  cardImageFileId: Scalars['ID']
  color: Scalars['String']
  frontPosition?: InputMaybe<Scalars['Int']>
  iconImageFileId: Scalars['ID']
  onFront?: InputMaybe<Scalars['Boolean']>
  title: Scalars['String']
  titleT?: InputMaybe<Array<ContentTranslationInput>>
}

export type CreateSportItemInput = {
  cac?: InputMaybe<ContentAccessControlInput>
  chatActivated: Scalars['Boolean']
  chatRoomId?: InputMaybe<Scalars['String']>
  contentPushServiceNamesExcluded?: InputMaybe<Array<ContentPushStreamServiceName>>
  contentType: SportItemContentType
  coverImageFileId: Scalars['ID']
  description?: InputMaybe<Scalars['String']>
  descriptionT?: InputMaybe<Array<ContentTranslationInput>>
  matureContent?: InputMaybe<Scalars['Boolean']>
  modeRadio: Scalars['Boolean']
  playlistPosition?: InputMaybe<Scalars['Int']>
  publicationStatus?: InputMaybe<PublicationStatus>
  reverseRestriction?: InputMaybe<Scalars['Boolean']>
  sportEventItem?: InputMaybe<SportEventItemCreateInput>
  sportItemSetId: Scalars['ID']
  subtitle?: InputMaybe<Scalars['String']>
  subtitleT?: InputMaybe<Array<ContentTranslationInput>>
  tabs?: InputMaybe<Array<TabInput>>
  title: Scalars['String']
  titleT?: InputMaybe<Array<ContentTranslationInput>>
  type: SportItemType
  videoContentType: VideoContentType
}

export type CreateSportItemSetInput = {
  activateGreenPlayback?: InputMaybe<Scalars['Boolean']>
  activateSportItemChatByDefault: Scalars['Boolean']
  adCampaignId?: InputMaybe<Scalars['ID']>
  bannerImageFileId: Scalars['ID']
  bannerVideoId?: InputMaybe<Scalars['ID']>
  cac?: InputMaybe<ContentAccessControlInput>
  categoriesIds?: InputMaybe<Array<Scalars['ID']>>
  contentPushServiceGenericStreamIds?: InputMaybe<Array<Scalars['ID']>>
  contentPushServiceLiveEventIds?: InputMaybe<Array<Scalars['ID']>>
  contentPushServiceNames?: InputMaybe<Array<ContentPushStreamServiceName>>
  description?: InputMaybe<Scalars['String']>
  descriptionT?: InputMaybe<Array<ContentTranslationInput>>
  disableReadParallelLivestreamsLimit?: InputMaybe<Scalars['Boolean']>
  ecmSessionId?: InputMaybe<Scalars['String']>
  lockPublishingClip?: InputMaybe<Scalars['Boolean']>
  lockPublishingLive?: InputMaybe<Scalars['Boolean']>
  noSpoilerMode?: InputMaybe<NoSpoilerModeInput>
  offerIds?: InputMaybe<Array<Scalars['ID']>>
  ownerOrganismId?: InputMaybe<Scalars['ID']>
  publicationStatus?: InputMaybe<PublicationStatus>
  restrictionLevel?: InputMaybe<PremiumAccess>
  sponsorOrganismIds?: InputMaybe<Array<Scalars['ID']>>
  sponsorPlatform?: InputMaybe<SponsorPlatformInput>
  sportEvent?: InputMaybe<SportEventCreateInput>
  sportIds: Array<Scalars['ID']>
  streamsIds?: InputMaybe<Array<Scalars['WildmokaID']>>
  subTags?: InputMaybe<Array<Scalars['String']>>
  subtitle?: InputMaybe<Scalars['String']>
  subtitleT?: InputMaybe<Array<ContentTranslationInput>>
  sveInputLossImageMediaId?: InputMaybe<Scalars['ID']>
  sveOverlayLogoImageMediaId?: InputMaybe<Scalars['ID']>
  sveStreams: Array<SveStreamInput>
  tabs?: InputMaybe<Array<TabInput>>
  tagId?: InputMaybe<Scalars['ID']>
  testPeriodEnd?: InputMaybe<Scalars['Date']>
  title: Scalars['String']
  titleT?: InputMaybe<Array<ContentTranslationInput>>
  type: SportItemSetType
  videoEngine: VideoEngine
  visibleInMultisportApplication?: InputMaybe<Scalars['Boolean']>
  websiteUrl?: InputMaybe<Scalars['URL']>
  wmClippingTemplateId?: InputMaybe<Scalars['WildmokaID']>
}

export type CreateStripePaymentResult = {
  __typename?: 'CreateStripePaymentResult'
  error?: Maybe<Scalars['String']>
  paymentIntent?: Maybe<PaymentIntent>
  purchase?: Maybe<UserPurchase>
}

export type CreateStripeSubscriptionInput = {
  coupon?: InputMaybe<Scalars['String']>
  productId: Scalars['String']
}

export type CreateStripeSubscriptionResult = {
  __typename?: 'CreateStripeSubscriptionResult'
  error?: Maybe<Scalars['String']>
  purchase?: Maybe<UserPurchase>
  subscription?: Maybe<StripeSubscription>
  success: Scalars['Boolean']
}

export type CreateThirdPartyApplicationInput = {
  description: Scalars['String']
  keys: Array<UpdateThirdApplicationKeyInput>
  name: Scalars['String']
  organismId?: InputMaybe<Scalars['ID']>
}

export type CreateUserInput = {
  chatAuthorName?: InputMaybe<Scalars['String']>
  email: Scalars['String']
  firstName: Scalars['String']
  lastName: Scalars['String']
  organismIds?: InputMaybe<Array<Scalars['ID']>>
  password: Scalars['String']
  rights?: InputMaybe<UserRightInput>
  roleIds?: InputMaybe<Array<UserRoleId>>
}

export type CreateWildmokaMediaLiveStreamInput = {
  autoAnnounceDate?: InputMaybe<Scalars['Date']>
  createClipAtEndOfEvent?: InputMaybe<Scalars['Boolean']>
  description?: InputMaybe<Scalars['String']>
  media: CreateMediaInput
  notificationEmail?: InputMaybe<Scalars['String']>
  passthrough?: InputMaybe<Scalars['Boolean']>
  stream: Scalars['WildmokaID']
  templateClip?: InputMaybe<Scalars['WildmokaID']>
  templateLive?: InputMaybe<Scalars['WildmokaID']>
}

export type CustomPage = {
  __typename?: 'CustomPage'
  application: Application
  applicationId: Scalars['ID']
  backgroundColor?: Maybe<Scalars['String']>
  backgroundImage?: Maybe<Media>
  backgroundImageId?: Maybe<Scalars['ID']>
  backgroundVideo?: Maybe<Media>
  backgroundVideoId?: Maybe<Scalars['ID']>
  content?: Maybe<Array<ContentSet>>
  createdAt: Scalars['Date']
  customPageApplicationDesignId?: Maybe<Scalars['ID']>
  defaultLocale?: Maybe<Scalars['String']>
  deletedAt?: Maybe<Scalars['Date']>
  /** @deprecated Use externalWidgets.iframeUrl directly, this resolver will be removed. */
  externalIframeWidgets?: Maybe<ExternalIframeWidgetsBackward>
  /** @deprecated Use externalWidgets.linkUrl directly, this resolver will be removed. */
  externalLinkWidgets?: Maybe<ExternalLinkWidgetsBackward>
  externalWidgets?: Maybe<ExternalWidgets>
  frontPageId?: Maybe<Scalars['ID']>
  icon?: Maybe<Media>
  iconId?: Maybe<Scalars['ID']>
  id: Scalars['ID']
  isExternalLink?: Maybe<Scalars['Boolean']>
  isHomePage?: Maybe<Scalars['Boolean']>
  menuBannerImage?: Maybe<Media>
  menuBannerImageId?: Maybe<Scalars['ID']>
  position: Scalars['Int']
  published: PublicationStatus
  showInNavigationHeader?: Maybe<Scalars['Boolean']>
  /** @deprecated Use createdAt, updatedAt and deletedAt directly now. This resolver will be removed. */
  timestamps: Timestamps
  title: Scalars['String']
  titleColor?: Maybe<Scalars['String']>
  titleT?: Maybe<Array<ContentTranslation>>
  updatedAt: Scalars['Date']
  widgets?: Maybe<Array<CustomPageWidget>>
  widgetsType?: Maybe<WidgetsType>
}

export type CustomPageContentArgs = {
  includeNews?: Scalars['Boolean']
}

export enum CustomPageDisplayTypes {
  SecondClass = 'SecondClass',
  Classic = 'classic',
  FirstClass = 'firstClass',
}

export type CustomPageFilters = {
  applicationId?: InputMaybe<Scalars['String']>
  applicationMongoId?: InputMaybe<Scalars['ID']>
  published?: InputMaybe<PublicationStatus>
  withNotShownInNavigationHeader?: InputMaybe<Scalars['Boolean']>
}

export type CustomPagePageResult = {
  __typename?: 'CustomPagePageResult'
  customPages: Array<CustomPage>
  totalCount?: Maybe<Scalars['Int']>
}

export type CustomPageWidget = {
  __typename?: 'CustomPageWidget'
  cardsDisplay?: Maybe<CardsDisplay>
  contentDisplay?: Maybe<ContentSetContentDisplay>
  display?: Maybe<CustomPageDisplayTypes>
  name: CustomPageWidgetName
  options?: Maybe<CustomPageWidgetOptions>
  position: Scalars['Int']
}

export type CustomPageWidgetInput = {
  cardsDisplay?: InputMaybe<CardsDisplay>
  contentDisplay?: InputMaybe<ContentSetContentDisplay>
  display?: InputMaybe<CustomPageDisplayTypes>
  name: CustomPageWidgetName
  options?: InputMaybe<CustomPageWidgetOptionsInput>
  position: Scalars['Int']
}

export enum CustomPageWidgetName {
  AllContent = 'allContent',
  EditedCategories = 'editedCategories',
  ExternalContent = 'externalContent',
  Live = 'live',
  ReadingList = 'readingList',
  Replays = 'replays',
  Top10 = 'top10',
  Upcoming = 'upcoming',
}

export type CustomPageWidgetOptions = {
  __typename?: 'CustomPageWidgetOptions'
  editedCategoryId?: Maybe<Scalars['ID']>
  externalContent?: Maybe<ExternalContentWidget>
  replays?: Maybe<ReplaysWidget>
  sportItemSetType?: Maybe<SportItemSetType>
}

export type CustomPageWidgetOptionsInput = {
  editedCategoryId?: InputMaybe<Scalars['ID']>
  externalContent?: InputMaybe<ExternalContentWidgetInput>
  replays?: InputMaybe<ReplaysWidgetInput>
  sportItemSetType?: InputMaybe<SportItemSetType>
}

export type DateRangeInput = {
  endDate: Scalars['Date']
  startDate: Scalars['Date']
}

export type DefaultConfig = {
  __typename?: 'DefaultConfig'
  androidMinVersion: Scalars['String']
  appleMinVersion: Scalars['String']
  clipDefaultTemplate: Scalars['WildmokaID']
  createdAt: Scalars['Date']
  deletedAt?: Maybe<Scalars['Date']>
  emailingConfig: EmailingConfig
  id: Scalars['ID']
  liveDefaultTemplate: Scalars['WildmokaID']
  liveStreamDefaultDuration: Scalars['Int']
  sportItemDefaultCover: Scalars['ID']
  sveChannelDelayHour: SveChannelDelayHour
  /** @deprecated Use createdAt, updatedAt and deletedAt directly now. This resolver will be removed. */
  timestamps: Timestamps
  updatedAt: Scalars['Date']
  videoGridUrl?: Maybe<Scalars['String']>
}

export type DeleteProductInput = {
  productId: Scalars['String']
}

export type DeleteStripeSubscriptionInput = {
  id: Scalars['String']
}

export type DeleteStripeSubscriptionResult = {
  __typename?: 'DeleteStripeSubscriptionResult'
  error?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type EditedCategoriesPageResult = {
  __typename?: 'EditedCategoriesPageResult'
  editedCategories: Array<EditedCategory>
  totalCount?: Maybe<Scalars['Int']>
}

export type EditedCategory = {
  __typename?: 'EditedCategory'
  createdAt: Scalars['Date']
  defaultLocale?: Maybe<Scalars['String']>
  deletedAt?: Maybe<Scalars['Date']>
  id: Scalars['ID']
  items: Array<EditedCategoryItem>
  organism: Organism
  organismId?: Maybe<Scalars['ID']>
  position: Scalars['Int']
  published: PublicationStatus
  /** @deprecated Use createdAt, updatedAt and deletedAt directly now. This resolver will be removed. */
  timestamps: Timestamps
  title: Scalars['String']
  titleT?: Maybe<Array<ContentTranslation>>
  updatedAt: Scalars['Date']
}

export type EditedCategoryFilters = {
  organismId?: InputMaybe<Scalars['ID']>
  published?: InputMaybe<Scalars['Boolean']>
}

export type EditedCategoryItem = {
  __typename?: 'EditedCategoryItem'
  link?: Maybe<Link>
  linkId?: Maybe<Scalars['ID']>
  news?: Maybe<News>
  newsId?: Maybe<Scalars['ID']>
  sportItem?: Maybe<SportItem>
  sportItemId?: Maybe<Scalars['ID']>
  sportItemSet?: Maybe<SportItemSet>
  sportItemSetId?: Maybe<Scalars['ID']>
}

export type EditedCategoryItemInput = {
  linkId?: InputMaybe<Scalars['ID']>
  newsId?: InputMaybe<Scalars['ID']>
  sportItemId?: InputMaybe<Scalars['ID']>
  sportItemSetId?: InputMaybe<Scalars['ID']>
}

export type EmailingConfig = {
  __typename?: 'EmailingConfig'
  endliveStream: Scalars['Int']
  startliveStream: Scalars['Int']
  startliveStreamTest: Scalars['Int']
}

export type EmailingConfigInput = {
  endliveStream: Scalars['Int']
  startliveStream: Scalars['Int']
  startliveStreamTest: Scalars['Int']
}

export type ExcludedContent = {
  __typename?: 'ExcludedContent'
  sportItemIds: Array<Scalars['String']>
  sportItemSetIds: Array<Scalars['String']>
  sportItemSets: Array<SportItemSet>
  sportItems: Array<SportItem>
}

export type ExternalContentWidget = {
  __typename?: 'ExternalContentWidget'
  defaultLocale: Scalars['String']
  maxItemsCount: Scalars['Int']
  name?: Maybe<Scalars['String']>
  nameT: Array<ContentTranslation>
  period?: Maybe<StartEndDates>
  rssContent?: Maybe<RssFeedContent>
  source: WidgetExternalContentSource
  wecContent?: Maybe<WecNewsContent>
}

export type ExternalContentWidgetInput = {
  defaultLocale: Scalars['String']
  maxItemsCount?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  nameT: Array<ContentTranslationInput>
  period?: InputMaybe<StartEndDatesInput>
  rssContent?: InputMaybe<RssFeedContentInput>
  source: WidgetExternalContentSource
  wecContent?: InputMaybe<WecNewsContentInput>
}

export type ExternalFeed = {
  __typename?: 'ExternalFeed'
  externalFeedProvider: ExternalFeedType
  externalFeedUrl: Scalars['String']
}

export enum ExternalFeedType {
  CustomUrl = 'customUrl',
  Dailymotion = 'dailymotion',
  Youtube = 'youtube',
}

export type ExternalIframeWidgetsBackward = {
  __typename?: 'ExternalIframeWidgetsBackward'
  iframeWidgets?: Maybe<IframeWidgetsBackward>
}

export type ExternalLinkWidgetsBackward = {
  __typename?: 'ExternalLinkWidgetsBackward'
  linkWidgets?: Maybe<LinkWidgetsBackward>
}

export type ExternalWidgets = {
  __typename?: 'ExternalWidgets'
  iframeUrl?: Maybe<Scalars['String']>
  linkUrl?: Maybe<Scalars['String']>
  liveScoreAccount?: Maybe<Scalars['String']>
  /** @deprecated Use externalWidgets.iframeUrl directly, this resolver will be removed. */
  liveScoreWidgets?: Maybe<LiveScoreWidgetsBackward>
}

export type ExternalWidgetsInput = {
  iframeUrl?: InputMaybe<Scalars['String']>
  linkUrl?: InputMaybe<Scalars['String']>
  liveScoreAccount?: InputMaybe<Scalars['String']>
}

export type FfAthleLicenseInput = {
  numlic: Scalars['String']
  passffa: Scalars['String']
}

export type FffLicenseInput = {
  authorizationCode: Scalars['String']
  redirectUrl: Scalars['String']
}

export type FfrLicenseInput = {
  code: Scalars['String']
  firstname: Scalars['String']
  lastname: Scalars['String']
  license: Scalars['String']
}

export type FanPreferences = {
  __typename?: 'FanPreferences'
  followedOrganisms: Array<Organism>
  followedOrganismsIds: Array<Scalars['ID']>
}

export type File = {
  __typename?: 'File'
  createdAt: Scalars['Date']
  deletedAt?: Maybe<Scalars['Date']>
  downloadUrl: Scalars['String']
  encoding: Scalars['String']
  filename: Scalars['String']
  id: Scalars['ID']
  location?: Maybe<FileLocation>
  metadata?: Maybe<FileMetadata>
  mimetype: Scalars['String']
  size: Scalars['Int']
  thumbUrl?: Maybe<Scalars['String']>
  /** @deprecated Use createdAt, updatedAt and deletedAt directly now. This resolver will be removed. */
  timestamps: Timestamps
  updatedAt: Scalars['Date']
}

export type FileThumbUrlArgs = {
  height?: InputMaybe<Scalars['Int']>
  width?: InputMaybe<Scalars['Int']>
}

export type FileLocation = {
  __typename?: 'FileLocation'
  s3: FileS3
}

export type FileMetadata = {
  __typename?: 'FileMetadata'
  aspect?: Maybe<Scalars['Float']>
  description?: Maybe<Scalars['String']>
  height?: Maybe<Scalars['Float']>
  sourceFileId?: Maybe<Scalars['ID']>
  title?: Maybe<Scalars['String']>
  width?: Maybe<Scalars['Float']>
}

export type FilePageResult = {
  __typename?: 'FilePageResult'
  files: Array<File>
  totalCount?: Maybe<Scalars['Int']>
}

export type FileS3 = {
  __typename?: 'FileS3'
  key: Scalars['String']
}

export type FrontPage = {
  __typename?: 'FrontPage'
  application?: Maybe<Application>
  applicationId?: Maybe<Scalars['ID']>
  coverImage?: Maybe<Media>
  coverImageId: Scalars['ID']
  coverVideo?: Maybe<Media>
  coverVideoId?: Maybe<Scalars['ID']>
  createdAt: Scalars['Date']
  deletedAt?: Maybe<Scalars['Date']>
  description?: Maybe<Scalars['String']>
  end?: Maybe<Scalars['Date']>
  id: Scalars['ID']
  position: Scalars['Int']
  publicationStatus: PublicationStatus
  publishInTop?: Maybe<Scalars['Boolean']>
  redirectToSportItem?: Maybe<SportItem>
  redirectsAutomatically?: Maybe<Scalars['Boolean']>
  sportItem?: Maybe<SportItem>
  sportItemId?: Maybe<Scalars['ID']>
  sportItemSet?: Maybe<SportItemSet>
  sportItemSetId: Scalars['ID']
  start: Scalars['Date']
  state: FrontPageState
  tags: Array<Scalars['String']>
  /** @deprecated Use createdAt, updatedAt and deletedAt directly now. This resolver will be removed. */
  timestamps: Timestamps
  title: Scalars['String']
  titleImage?: Maybe<Media>
  titleImageId: Scalars['ID']
  updatedAt: Scalars['Date']
}

export enum FrontPageState {
  Active = 'active',
  Finished = 'finished',
  Upcoming = 'upcoming',
}

export enum Gender {
  Female = 'female',
  Male = 'male',
  NotSpecified = 'notSpecified',
}

export type GenerateMediaPlaybackUrlInput = {
  expirationInSeconds?: InputMaybe<Scalars['Int']>
  mediaId: Scalars['ID']
}

export type GenerateMediaReplayInput = {
  endTrim?: InputMaybe<Scalars['Float']>
  startTrim?: InputMaybe<Scalars['Float']>
}

export type GenerateSportItemMediaPlaybackUrlInput = {
  activateRadioMode?: InputMaybe<Scalars['Boolean']>
  expirationInSeconds?: InputMaybe<Scalars['Int']>
  location?: InputMaybe<GeoLocationInput>
  mediaId: Scalars['ID']
  sportItemId: Scalars['ID']
}

export type GenerateSportItemMediaPlaybackUrlResult = {
  __typename?: 'GenerateSportItemMediaPlaybackUrlResult'
  access: ContentAccess
  /** @deprecated handled by bitmovin */
  liveControlEnabled: Scalars['Boolean']
  message: Scalars['String']
  url?: Maybe<Scalars['URL']>
}

export type GeoLocation = {
  __typename?: 'GeoLocation'
  accuracyRadius?: Maybe<Scalars['Float']>
  latitude: Scalars['Float']
  longitude: Scalars['Float']
}

export type GeoLocationInput = {
  accuracyRadius?: InputMaybe<Scalars['Float']>
  latitude: Scalars['Float']
  longitude: Scalars['Float']
}

export type GetContentIdMappingInput = {
  format: ContentIdMappingFormat
  ownerOrganismId: Scalars['ID']
  sourceIdPrefix?: InputMaybe<Scalars['String']>
}

export type GetContentIdMappingResult = {
  __typename?: 'GetContentIdMappingResult'
  data: Scalars['JSON']
  ownerOrganismId: Scalars['ID']
}

export type GoogleSubscriptionReceiptInput = {
  subscriptionId: Scalars['String']
  token: Scalars['String']
}

export enum HasOfferFilter {
  All = 'all',
  ValidOnly = 'validOnly',
}

export type Icon = {
  __typename?: 'Icon'
  svg: Scalars['String']
  title: Scalars['String']
}

export type IconInput = {
  svg: Scalars['String']
  title: Scalars['String']
}

export type IframeWidgetsBackward = {
  __typename?: 'IframeWidgetsBackward'
  iframeUrl: Scalars['String']
}

export type ImportContentInput = {
  file: Scalars['Upload']
  organismId: Scalars['ID']
  type: ContentImportType
}

export type ImportUsersOptions = {
  licensedForOrganismId?: InputMaybe<Scalars['ID']>
  organismIds?: InputMaybe<Array<Scalars['ID']>>
  revokeLicense?: InputMaybe<Scalars['Boolean']>
}

export type ImportUsersStatus = {
  __typename?: 'ImportUsersStatus'
  error?: Maybe<Scalars['String']>
  progress?: Maybe<Scalars['Int']>
  status: LogStatus
}

export type InitDefaultConfigInput = {
  androidMinVersion: Scalars['String']
  appleMinVersion: Scalars['String']
  clipDefaultTemplate: Scalars['WildmokaID']
  emailingConfig: EmailingConfigInput
  liveDefaultTemplate: Scalars['WildmokaID']
  liveStreamDefaultDuration: Scalars['Int']
  sportItemDefaultCover: Scalars['ID']
  sveChannelDelayHour: SveChannelDelayHourInput
  videoGridUrl?: InputMaybe<Scalars['String']>
}

export type LegalBlock = {
  __typename?: 'LegalBlock'
  blocked: Scalars['Boolean']
  from?: Maybe<Scalars['Date']>
  message?: Maybe<Scalars['String']>
  to?: Maybe<Scalars['Date']>
}

export type LegalRestriction = {
  __typename?: 'LegalRestriction'
  active: Scalars['Boolean']
  countries: Array<Scalars['String']>
  createdAt: Scalars['Date']
  deletedAt?: Maybe<Scalars['Date']>
  description?: Maybe<Scalars['String']>
  id: Scalars['ID']
  mature?: Maybe<MatureFilter>
  offerIds?: Maybe<Array<Scalars['ID']>>
  offers?: Maybe<Array<PurchasableOffer>>
  organism?: Maybe<Organism>
  organismId?: Maybe<Scalars['ID']>
  restrictionTarget?: Maybe<RestrictionTargetFilter>
  sportItemIds?: Maybe<Array<Scalars['ID']>>
  sportItemSetIds?: Maybe<Array<Scalars['ID']>>
  sportItemSets?: Maybe<Array<SportItemSet>>
  timeSlots?: Maybe<TimeSlots>
  /** @deprecated Use createdAt, updatedAt and deletedAt directly now. This resolver will be removed. */
  timestamps: Timestamps
  type: RestrictionType
  updatedAt: Scalars['Date']
}

export enum LicenseApi {
  FfAthle = 'FFAthle',
  Fff = 'FFF',
  Ffr = 'FFR',
}

export type LicenseData = {
  __typename?: 'LicenseData'
  type: LicenseApi
}

export type LicenseInfo = {
  __typename?: 'LicenseInfo'
  data?: Maybe<LicenseData>
  isLicensed: Scalars['Boolean']
  organism?: Maybe<Organism>
  organismId: Scalars['ID']
  registrationDate: Scalars['Date']
}

export type Link = {
  __typename?: 'Link'
  backgroundColor?: Maybe<Scalars['String']>
  billboard?: Maybe<Media>
  billboardId?: Maybe<Scalars['ID']>
  categories: Array<Category>
  categoriesIds: Array<Scalars['ID']>
  clicks: Scalars['Int']
  countries: Array<Maybe<Scalars['String']>>
  createdAt: Scalars['Date']
  defaultLocale?: Maybe<Scalars['String']>
  deletedAt?: Maybe<Scalars['Date']>
  description?: Maybe<Scalars['String']>
  descriptionT?: Maybe<Array<ContentTranslation>>
  gamTagUrl?: Maybe<Scalars['String']>
  id: Scalars['ID']
  image?: Maybe<Media>
  imageId: Scalars['ID']
  ownerOrganismIds: Array<Scalars['ID']>
  ownerOrganisms?: Maybe<Array<Organism>>
  publicationStatus: PublicationStatus
  subtitle?: Maybe<Scalars['String']>
  subtitleColor?: Maybe<Scalars['String']>
  subtitleT?: Maybe<Array<ContentTranslation>>
  tag?: Maybe<LinkTag>
  teaserVideo?: Maybe<Media>
  teaserVideoId?: Maybe<Scalars['ID']>
  /** @deprecated Use createdAt, updatedAt and deletedAt directly now. This resolver will be removed. */
  timestamps: Timestamps
  title: Scalars['String']
  titleColor?: Maybe<Scalars['String']>
  titleT?: Maybe<Array<ContentTranslation>>
  uniqueClicks: Scalars['Int']
  uniqueViews: Scalars['Int']
  updatedAt: Scalars['Date']
  url?: Maybe<Scalars['String']>
  userClickIds: Array<Scalars['ID']>
  userViewIds: Array<Scalars['ID']>
  views: Scalars['Int']
}

export type LinkDetails = {
  __typename?: 'LinkDetails'
  link: Link
  linkId: Scalars['ID']
}

export type LinkFilters = {
  organismId?: InputMaybe<Scalars['ID']>
  published?: InputMaybe<Scalars['Boolean']>
  search?: InputMaybe<Scalars['String']>
  tag?: InputMaybe<LinkTag>
}

export type LinkPageResult = {
  __typename?: 'LinkPageResult'
  links: Array<Link>
  totalCount?: Maybe<Scalars['Int']>
}

export type LinkSort = {
  direction?: InputMaybe<SortDirection>
  property: LinkSortProperty
}

export enum LinkSortProperty {
  Clicks = 'clicks',
  UniqueClicks = 'uniqueClicks',
  UniqueViews = 'uniqueViews',
  Views = 'views',
}

export enum LinkTag {
  Google = 'google',
  Sponsoring = 'sponsoring',
}

export type LinkWidgetsBackward = {
  __typename?: 'LinkWidgetsBackward'
  linkUrl: Scalars['String']
}

export type LiveConfig = {
  __typename?: 'LiveConfig'
  endDate: Scalars['Date']
  expiryDate: Scalars['Date']
  id: Scalars['ID']
  image: Scalars['String']
  liveStartDate: Scalars['Date']
  published: Scalars['Boolean']
  startDate: Scalars['Date']
  title: Scalars['String']
  url: Scalars['String']
}

export type LiveScoreWidgetsBackward = {
  __typename?: 'LiveScoreWidgetsBackward'
  liveScoreAccount: Scalars['String']
}

export type LiveStatus = {
  __typename?: 'LiveStatus'
  live: Scalars['Boolean']
  onAir: Scalars['Boolean']
}

export type LiveStream = {
  __typename?: 'LiveStream'
  createdAt: Scalars['Date']
  deletedAt?: Maybe<Scalars['Date']>
  event?: Maybe<WmEvent>
  externalFeedProvider?: Maybe<ExternalFeedType>
  externalFeedUrl?: Maybe<Scalars['String']>
  id: Scalars['ID']
  ingestUrl?: Maybe<Scalars['URL']>
  playbackUrl: Scalars['URL']
  sportItem?: Maybe<SportItem>
  state: LiveStreamState
  streamId?: Maybe<Scalars['ID']>
  streamingUser?: Maybe<User>
  streamingUserId?: Maybe<Scalars['ID']>
  sveBroadcast?: Maybe<SveBroadcast>
  sveBroadcastId?: Maybe<Scalars['ID']>
  sveChannelId?: Maybe<Scalars['ID']>
  sveLiveMonitoring?: Maybe<SveLiveMonitoring>
  /** @deprecated Use createdAt, updatedAt and deletedAt directly now. This resolver will be removed. */
  timestamps: Timestamps
  updatedAt: Scalars['Date']
  videoEngine: VideoEngine
  wildmokaEventID?: Maybe<Scalars['WildmokaID']>
}

export type LiveStreamPlaybackUrlArgs = {
  expirationInSeconds?: InputMaybe<Scalars['Int']>
}

export enum LiveStreamState {
  Finished = 'finished',
  NotStarted = 'notStarted',
  Started = 'started',
  Testing = 'testing',
}

export type LiveStreamStreamConflict = {
  __typename?: 'LiveStreamStreamConflict'
  liveStream: LiveStream
}

export type LiveStreamsPageResult = {
  __typename?: 'LiveStreamsPageResult'
  liveStreams: Array<LiveStream>
  totalCount?: Maybe<Scalars['Int']>
}

export type Location = {
  __typename?: 'Location'
  country: Scalars['String']
  postalCode?: Maybe<Scalars['String']>
}

export type LocationInput = {
  country: Scalars['String']
  postalCode?: InputMaybe<Scalars['String']>
}

export enum LogStatus {
  Error = 'error',
  Finished = 'finished',
  Running = 'running',
  Starting = 'starting',
  Success = 'success',
}

export enum LogType {
  ImportUsers = 'importUsers',
}

export type Logo = {
  __typename?: 'Logo'
  coloredImage?: Maybe<Media>
  transparentDarkImage?: Maybe<Media>
  transparentLightImage?: Maybe<Media>
}

export type LogoCreateUpdateInput = {
  coloredImageFileId?: InputMaybe<Scalars['ID']>
  transparentDarkImageFileId?: InputMaybe<Scalars['ID']>
  transparentLightImageFileId?: InputMaybe<Scalars['ID']>
}

export type Marker = {
  __typename?: 'Marker'
  icon?: Maybe<Scalars['String']>
  time: Scalars['Float']
  title: Scalars['String']
}

export type MarkerInput = {
  icon?: InputMaybe<Scalars['String']>
  time: Scalars['Float']
  title: Scalars['String']
}

export enum MatureFilter {
  Free = 'free',
  Paid = 'paid',
}

export type Media = {
  __typename?: 'Media'
  createdAt: Scalars['Date']
  date?: Maybe<Scalars['Date']>
  deletedAt?: Maybe<Scalars['Date']>
  endTrim?: Maybe<Scalars['Float']>
  id: Scalars['ID']
  image?: Maybe<File>
  imageId?: Maybe<Scalars['ID']>
  liveStream?: Maybe<LiveStream>
  liveStreamId?: Maybe<Scalars['ID']>
  ownerOrganism?: Maybe<Organism>
  ownerOrganismId?: Maybe<Scalars['ID']>
  pinned?: Maybe<Scalars['Boolean']>
  publicationStatus: PublicationStatus
  startTrim?: Maybe<Scalars['Float']>
  tags: Array<MediaTags>
  thumbnail?: Maybe<Media>
  thumbnailId?: Maybe<Scalars['ID']>
  timelineMarkers?: Maybe<Array<Marker>>
  /** @deprecated Use createdAt, updatedAt and deletedAt directly now. This resolver will be removed. */
  timestamps: Timestamps
  title: Scalars['String']
  type: MediaType
  updatedAt: Scalars['Date']
  videoClip?: Maybe<VideoClip>
  videoClipId?: Maybe<Scalars['ID']>
}

export type MediaDetails = {
  __typename?: 'MediaDetails'
  live: Scalars['Boolean']
  mediaId: Scalars['ID']
  mediaType: MediaType
  onAir: Scalars['Boolean']
  percentProgress?: Maybe<Scalars['Float']>
  premiumLocked: Scalars['Boolean']
  progression?: Maybe<Scalars['Float']>
  sportItemId: Scalars['ID']
  sportItemTitle: Scalars['String']
}

export type MediaFilters = {
  includeInactive?: InputMaybe<Scalars['Boolean']>
  includeUnavailable?: InputMaybe<Scalars['Boolean']>
}

export type MediaPageResult = {
  __typename?: 'MediaPageResult'
  medias: Array<Media>
  totalCount: Scalars['Int']
}

export enum MediaTags {
  Interview = 'interview',
  Live = 'live',
  Replay = 'replay',
  Teaser = 'teaser',
}

export enum MediaType {
  Image = 'image',
  LiveStream = 'liveStream',
  VideoClip = 'videoClip',
}

export type ModerateChatMessageInput = {
  chatRoomId: Scalars['ID']
  id: Scalars['ID']
  reason: Scalars['String']
}

export type MonitoringDashboardInput = {
  date: Scalars['Date']
  organismId?: InputMaybe<Scalars['ID']>
}

export type MoveCustomPageInput = {
  id: Scalars['ID']
  position: Scalars['Int']
}

export type MoveEditedCategoryInput = {
  id: Scalars['ID']
  position: Scalars['Int']
}

export type MoveFrontPageInput = {
  frontPosition: Scalars['Int']
  id: Scalars['ID']
}

export type MoveFrontPagePositionInput = {
  id: Scalars['ID']
  position: Scalars['Int']
}

export type MovePlaylistItemInput = {
  id: Scalars['ID']
  position: Scalars['Int']
}

export type MovePurchasableOfferInput = {
  id: Scalars['ID']
  position: Scalars['Int']
}

export type Multipart = {
  __typename?: 'Multipart'
  currentPart: Scalars['Int']
  key: Scalars['String']
  uploadId: Scalars['String']
}

export type Mutation = {
  __typename?: 'Mutation'
  banUserFromChat: User
  cleanCacheDatasources?: Maybe<Scalars['Boolean']>
  cleanCacheGlobal?: Maybe<Scalars['Boolean']>
  cleanCacheGraphql?: Maybe<Scalars['Boolean']>
  cleanCacheKeys?: Maybe<Scalars['Boolean']>
  completeMultipart: Scalars['Boolean']
  createAdCampaign: AdCampaign
  createApplication: Application
  createApplicationDesign: ApplicationDesign
  createCategory: Category
  createChatMessage: Scalars['Boolean']
  createContentPushStream: Scalars['Boolean']
  createCustomPage: CustomPage
  createEditedCategory: EditedCategory
  createExternalFeedMediaLiveStream: Media
  createFrontPage: FrontPage
  createLegalRestriction: LegalRestriction
  createLink: Link
  createMediaFile: Media
  createMediaVideoClip: Media
  createMediaVideoClipExternalFeed: Media
  createNews: News
  createOrganism: Organism
  createPurchasableOffer: PurchasableOffer
  createSVEChannel: SveChannel
  createSVEMediaLiveStream: Media
  createSport: Sport
  createSportItem: SportItem
  createSportItemSet: SportItemSet
  createStripePayment: CreateStripePaymentResult
  createStripeSubscription: CreateStripeSubscriptionResult
  createStripeUser: User
  createThirdPartyApplication: ThirdPartyApplication
  createTwitterWidget: Scalars['String']
  createUser: User
  createWildmokaMediaLiveStream: Media
  deleteAdCampaign?: Maybe<AdCampaign>
  deleteApplication?: Maybe<Application>
  deleteApplicationDesign?: Maybe<ApplicationDesign>
  deleteCategory: Scalars['Boolean']
  deleteContentPushStream: Scalars['Boolean']
  deleteCustomPage?: Maybe<CustomPage>
  deleteEditedCategory?: Maybe<EditedCategory>
  deleteFrontPage: Scalars['Boolean']
  deleteLegalRestriction: Scalars['Boolean']
  deleteLink?: Maybe<Link>
  deleteMedia: Scalars['Boolean']
  deleteNews?: Maybe<News>
  deleteOrganism?: Maybe<Organism>
  deleteProduct: Scalars['Boolean']
  deletePurchasableOffer: Scalars['Boolean']
  deleteSVEChannel?: Maybe<Scalars['Boolean']>
  deleteSport?: Maybe<Sport>
  deleteSportItem?: Maybe<SportItem>
  deleteSportItemSet?: Maybe<SportItemSet>
  deleteStripeSubscription?: Maybe<DeleteStripeSubscriptionResult>
  deleteThirdPartyApplication?: Maybe<ThirdPartyApplication>
  deleteUser?: Maybe<User>
  finishStreaming?: Maybe<LiveStream>
  /** @deprecated Use generateSportItemMediaPlaybackUrl */
  generateMediaPlaybackUrl?: Maybe<Scalars['URL']>
  generateMediaReplay: Scalars['Boolean']
  generateSportItemMediaPlaybackUrl: GenerateSportItemMediaPlaybackUrlResult
  importContent: ContentImportResult
  importUsersFansFromCsv?: Maybe<Scalars['Boolean']>
  initDefaultConfig: DefaultConfig
  moderateChatMessage: Scalars['Boolean']
  moveCustomPage: Array<CustomPage>
  moveEditedCategory: Array<EditedCategory>
  moveFrontPage: Array<Organism>
  moveFrontPagePosition: Array<FrontPage>
  movePlaylistItem: Array<SportItem>
  movePurchasableOffer: Array<PurchasableOffer>
  moveSportFrontPage: Array<Sport>
  pinnedChatMessage: Scalars['Boolean']
  registerFCMToken: Scalars['Boolean']
  registerMyLicense: User
  removeFCMToken: Scalars['Boolean']
  removePlaybackState?: Maybe<Scalars['Boolean']>
  removeStreamMap?: Maybe<Scalars['Boolean']>
  revokeAllLicenses: Scalars['Int']
  revokeUserLicense: User
  root?: Maybe<Scalars['String']>
  schedulePurchaseExport: Scalars['Boolean']
  scheduleUsersExport: Scalars['Boolean']
  setHomePage: Array<CustomPage>
  setLiveStreamState: LiveStream
  /** @deprecated Nb views will be removed */
  startMediaPlayback?: Maybe<PlaybackState>
  startStreaming: LiveStream
  stopSportItemLive?: Maybe<SportItem>
  syncNow: Scalars['Boolean']
  togglePin: Scalars['Boolean']
  trackClickEvent?: Maybe<Link>
  trackSponsorLinkClickEvent?: Maybe<SponsorPlatform>
  trackViewEvent?: Maybe<Link>
  unmountSVEChannel: SveChannel
  updateAdCampaign: AdCampaign
  updateApplication: Application
  updateApplicationDesign: ApplicationDesign
  updateCacheReceipt: Scalars['Boolean']
  updateCategory: Category
  updateChatMessage: Scalars['Boolean']
  updateContentPushStream: Scalars['Boolean']
  updateCustomPage: CustomPage
  updateDefaultConfig: DefaultConfig
  updateEditedCategory: EditedCategory
  updateFrontPage: FrontPage
  updateLegalRestriction: LegalRestriction
  updateLink: Link
  updateLiveStream: LiveStream
  updateLiveStreamState: LiveStream
  updateLiveStreamStatesCache: Scalars['Boolean']
  updateMe: User
  updateMedia: Media
  updateMyCacheReceipt: User
  updateMyFanPreferences: FanPreferences
  updateMyPassword: Scalars['Boolean']
  updateNews: News
  updateOrganism: Organism
  updateOutputStreams: Scalars['Boolean']
  updatePlaybackState?: Maybe<PlaybackState>
  updateProduct: Product
  updatePurchasableOffer: PurchasableOffer
  updatePurchasableReceipt: UpdatePurchasableReceiptResult
  updateRedButtonConfig: RedButtonConfig
  updateSVEChannel: SveChannel
  updateSport: Sport
  updateSportItem?: Maybe<SportItem>
  updateSportItemSet?: Maybe<SportItemSet>
  updateThirdPartyApplication: ThirdPartyApplication
  updateTokenPayload: Scalars['String']
  updateUser: User
  uploadAvatar: User
  uploadPart: Scalars['Boolean']
  writeStreamMap: StreamMap
}

export type MutationBanUserFromChatArgs = {
  chatRoomId?: InputMaybe<Scalars['ID']>
  duration?: InputMaybe<Scalars['Int']>
  userId: Scalars['ID']
}

export type MutationCleanCacheKeysArgs = {
  pattern: Scalars['String']
}

export type MutationCompleteMultipartArgs = {
  input: CompleteMultipartInput
}

export type MutationCreateAdCampaignArgs = {
  input: CreateAdCampaignInput
}

export type MutationCreateApplicationArgs = {
  input: CreateApplicationInput
}

export type MutationCreateApplicationDesignArgs = {
  input: CreateApplicationDesignInput
}

export type MutationCreateCategoryArgs = {
  input: CreateCategoryInput
}

export type MutationCreateChatMessageArgs = {
  input: CreateChatMessageInput
}

export type MutationCreateContentPushStreamArgs = {
  input: CreateContentPushStreamInput
}

export type MutationCreateCustomPageArgs = {
  input: CreateCustomPageInput
}

export type MutationCreateEditedCategoryArgs = {
  input: CreateEditedCategoryInput
}

export type MutationCreateExternalFeedMediaLiveStreamArgs = {
  input: CreateExternalFeedMediaLiveStreamInput
}

export type MutationCreateFrontPageArgs = {
  input: CreateFrontPageInput
}

export type MutationCreateLegalRestrictionArgs = {
  input: CreateLegalRestrictionInput
}

export type MutationCreateLinkArgs = {
  input: CreateLinkInput
}

export type MutationCreateMediaFileArgs = {
  input: CreateMediaFileInput
}

export type MutationCreateMediaVideoClipArgs = {
  input: CreateMediaVideoClipInput
}

export type MutationCreateMediaVideoClipExternalFeedArgs = {
  input: CreateMediaVideoClipExternalFeedInput
}

export type MutationCreateNewsArgs = {
  input: CreateNewsInput
}

export type MutationCreateOrganismArgs = {
  input: CreateOrganismInput
}

export type MutationCreatePurchasableOfferArgs = {
  input: CreatePurchasableOfferInput
}

export type MutationCreateSveChannelArgs = {
  input: CreateSveChannelInput
}

export type MutationCreateSveMediaLiveStreamArgs = {
  input: CreateSveMediaLiveStreamInput
}

export type MutationCreateSportArgs = {
  input: CreateSportInput
}

export type MutationCreateSportItemArgs = {
  input: CreateSportItemInput
}

export type MutationCreateSportItemSetArgs = {
  input: CreateSportItemSetInput
}

export type MutationCreateStripePaymentArgs = {
  input: CreateStripeSubscriptionInput
}

export type MutationCreateStripeSubscriptionArgs = {
  input: CreateStripeSubscriptionInput
}

export type MutationCreateThirdPartyApplicationArgs = {
  input: CreateThirdPartyApplicationInput
}

export type MutationCreateTwitterWidgetArgs = {
  tweetUrl: Scalars['String']
}

export type MutationCreateUserArgs = {
  input: CreateUserInput
}

export type MutationCreateWildmokaMediaLiveStreamArgs = {
  input: CreateWildmokaMediaLiveStreamInput
}

export type MutationDeleteAdCampaignArgs = {
  id: Scalars['ID']
}

export type MutationDeleteApplicationArgs = {
  id: Scalars['ID']
}

export type MutationDeleteApplicationDesignArgs = {
  id: Scalars['ID']
}

export type MutationDeleteCategoryArgs = {
  id: Scalars['ID']
}

export type MutationDeleteContentPushStreamArgs = {
  id: Scalars['ID']
}

export type MutationDeleteCustomPageArgs = {
  id: Scalars['ID']
}

export type MutationDeleteEditedCategoryArgs = {
  id: Scalars['ID']
}

export type MutationDeleteFrontPageArgs = {
  id: Scalars['ID']
}

export type MutationDeleteLegalRestrictionArgs = {
  id: Scalars['ID']
}

export type MutationDeleteLinkArgs = {
  id: Scalars['ID']
}

export type MutationDeleteMediaArgs = {
  id: Scalars['ID']
}

export type MutationDeleteNewsArgs = {
  id: Scalars['ID']
}

export type MutationDeleteOrganismArgs = {
  id: Scalars['ID']
}

export type MutationDeleteProductArgs = {
  id: Scalars['ID']
  input: DeleteProductInput
}

export type MutationDeletePurchasableOfferArgs = {
  id: Scalars['ID']
}

export type MutationDeleteSveChannelArgs = {
  channelId: Scalars['ID']
}

export type MutationDeleteSportArgs = {
  id: Scalars['ID']
}

export type MutationDeleteSportItemArgs = {
  id: Scalars['ID']
}

export type MutationDeleteSportItemSetArgs = {
  id: Scalars['ID']
}

export type MutationDeleteStripeSubscriptionArgs = {
  input: DeleteStripeSubscriptionInput
}

export type MutationDeleteThirdPartyApplicationArgs = {
  id: Scalars['ID']
}

export type MutationDeleteUserArgs = {
  id: Scalars['ID']
}

export type MutationGenerateMediaPlaybackUrlArgs = {
  input: GenerateMediaPlaybackUrlInput
}

export type MutationGenerateMediaReplayArgs = {
  id: Scalars['ID']
  input?: InputMaybe<GenerateMediaReplayInput>
}

export type MutationGenerateSportItemMediaPlaybackUrlArgs = {
  input: GenerateSportItemMediaPlaybackUrlInput
}

export type MutationImportContentArgs = {
  input: ImportContentInput
}

export type MutationImportUsersFansFromCsvArgs = {
  file: Scalars['Upload']
  options?: InputMaybe<ImportUsersOptions>
}

export type MutationInitDefaultConfigArgs = {
  input: InitDefaultConfigInput
}

export type MutationModerateChatMessageArgs = {
  input: ModerateChatMessageInput
}

export type MutationMoveCustomPageArgs = {
  input: MoveCustomPageInput
}

export type MutationMoveEditedCategoryArgs = {
  input: MoveEditedCategoryInput
}

export type MutationMoveFrontPageArgs = {
  input: MoveFrontPageInput
}

export type MutationMoveFrontPagePositionArgs = {
  input: MoveFrontPagePositionInput
}

export type MutationMovePlaylistItemArgs = {
  input: MovePlaylistItemInput
}

export type MutationMovePurchasableOfferArgs = {
  input: MovePurchasableOfferInput
}

export type MutationMoveSportFrontPageArgs = {
  input: MoveFrontPageInput
}

export type MutationPinnedChatMessageArgs = {
  input: PinnedChatMessageInput
}

export type MutationRegisterFcmTokenArgs = {
  FCMToken: Scalars['String']
}

export type MutationRegisterMyLicenseArgs = {
  licenseForm: RegisterLicenseInput
  organismId: Scalars['ID']
}

export type MutationRemoveFcmTokenArgs = {
  FCMToken: Scalars['String']
}

export type MutationRemovePlaybackStateArgs = {
  input: RemovePlaybackStateInput
}

export type MutationRemoveStreamMapArgs = {
  streamId: Scalars['WildmokaID']
}

export type MutationRevokeAllLicensesArgs = {
  organismId: Scalars['ID']
}

export type MutationRevokeUserLicenseArgs = {
  organismId: Scalars['ID']
  userId: Scalars['ID']
}

export type MutationScheduleUsersExportArgs = {
  input: UsersExportInput
}

export type MutationSetHomePageArgs = {
  id: Scalars['ID']
}

export type MutationSetLiveStreamStateArgs = {
  id: Scalars['ID']
  state: LiveStreamState
}

export type MutationStartMediaPlaybackArgs = {
  input: StartMediaPlaybackInput
}

export type MutationStartStreamingArgs = {
  liveId: Scalars['ID']
}

export type MutationStopSportItemLiveArgs = {
  id: Scalars['ID']
}

export type MutationSyncNowArgs = {
  id: Scalars['ID']
}

export type MutationTogglePinArgs = {
  id: Scalars['ID']
}

export type MutationTrackClickEventArgs = {
  id: Scalars['ID']
}

export type MutationTrackSponsorLinkClickEventArgs = {
  sportItemSetId: Scalars['ID']
}

export type MutationTrackViewEventArgs = {
  id: Scalars['ID']
}

export type MutationUnmountSveChannelArgs = {
  channelId: Scalars['ID']
}

export type MutationUpdateAdCampaignArgs = {
  input: UpdateAdCampaignInput
}

export type MutationUpdateApplicationArgs = {
  input: UpdateApplicationInput
}

export type MutationUpdateApplicationDesignArgs = {
  input: UpdateApplicationDesignInput
}

export type MutationUpdateCacheReceiptArgs = {
  userId?: InputMaybe<Scalars['ID']>
}

export type MutationUpdateCategoryArgs = {
  input: UpdateCategoryInput
}

export type MutationUpdateChatMessageArgs = {
  input: UpdateChatMessageInput
}

export type MutationUpdateContentPushStreamArgs = {
  input: UpdateContentPushStreamInput
}

export type MutationUpdateCustomPageArgs = {
  input: UpdateCustomPageInput
}

export type MutationUpdateDefaultConfigArgs = {
  input: UpdateDefaultConfigInput
}

export type MutationUpdateEditedCategoryArgs = {
  id: Scalars['ID']
  input: UpdateEditedCategoryInput
}

export type MutationUpdateFrontPageArgs = {
  id: Scalars['ID']
  input: UpdateFrontPageInput
}

export type MutationUpdateLegalRestrictionArgs = {
  id: Scalars['ID']
  input: UpdateLegalRestrictionInput
}

export type MutationUpdateLinkArgs = {
  input: UpdateLinkInput
}

export type MutationUpdateLiveStreamArgs = {
  input: UpdateWildmokaLiveStreamInput
}

export type MutationUpdateLiveStreamStateArgs = {
  id: Scalars['ID']
  state: WmEventState
}

export type MutationUpdateMeArgs = {
  input: UpdateMeInput
}

export type MutationUpdateMediaArgs = {
  id: Scalars['ID']
  input: UpdateMediaInput
}

export type MutationUpdateMyFanPreferencesArgs = {
  input: UpdateFanPreferencesInput
}

export type MutationUpdateMyPasswordArgs = {
  input: UpdateMyPasswordInput
}

export type MutationUpdateNewsArgs = {
  input: UpdateNewsInput
}

export type MutationUpdateOrganismArgs = {
  input: UpdateOrganismInput
}

export type MutationUpdateOutputStreamsArgs = {
  input: UpdateOutputStreamsInput
}

export type MutationUpdatePlaybackStateArgs = {
  input: UpdatePlaybackStateInput
}

export type MutationUpdateProductArgs = {
  id: Scalars['ID']
  input: UpdateProductInput
  productId: Scalars['String']
}

export type MutationUpdatePurchasableOfferArgs = {
  id: Scalars['ID']
  input: UpdatePurchasableOfferInput
}

export type MutationUpdatePurchasableReceiptArgs = {
  input: UpdatePurchasableReceiptInput
}

export type MutationUpdateSveChannelArgs = {
  input: UpdateSveChannelInput
}

export type MutationUpdateSportArgs = {
  input: UpdateSportInput
}

export type MutationUpdateSportItemArgs = {
  input: UpdateSportItemInput
}

export type MutationUpdateSportItemSetArgs = {
  input: UpdateSportItemSetInput
}

export type MutationUpdateThirdPartyApplicationArgs = {
  input: UpdateThirdPartyApplicationInput
}

export type MutationUpdateUserArgs = {
  input: UpdateUserInput
}

export type MutationUploadAvatarArgs = {
  file: Scalars['Upload']
}

export type MutationUploadPartArgs = {
  input: UploadPartInput
}

export type MutationWriteStreamMapArgs = {
  input: WriteStreamMapInput
}

export type News = {
  __typename?: 'News'
  content: Scalars['String']
  contentT?: Maybe<Array<ContentTranslation>>
  coverImage?: Maybe<Media>
  coverImageId: Scalars['ID']
  createdAt: Scalars['Date']
  defaultLocale?: Maybe<Scalars['String']>
  deletedAt?: Maybe<Scalars['Date']>
  description: Scalars['String']
  descriptionT?: Maybe<Array<ContentTranslation>>
  id: Scalars['ID']
  organism?: Maybe<Organism>
  organismId?: Maybe<Scalars['ID']>
  publicationStatus: PublicationStatus
  rawContent: Scalars['String']
  rawContentT?: Maybe<Array<ContentTranslation>>
  subtitle: Scalars['String']
  subtitleT?: Maybe<Array<ContentTranslation>>
  /** @deprecated Use createdAt, updatedAt and deletedAt directly now. This resolver will be removed. */
  timestamps: Timestamps
  title: Scalars['String']
  titleT?: Maybe<Array<ContentTranslation>>
  updatedAt: Scalars['Date']
}

export type NewsDetails = {
  __typename?: 'NewsDetails'
  news: News
  newsId: Scalars['ID']
}

export type NewsFilters = {
  organismId?: InputMaybe<Scalars['ID']>
}

export type NewsPageResult = {
  __typename?: 'NewsPageResult'
  news: Array<News>
  totalCount?: Maybe<Scalars['Int']>
}

export type NoSpoilerMode = {
  __typename?: 'NoSpoilerMode'
  active: Scalars['Boolean']
  enabled: Scalars['Boolean']
  minutesBeforeDisabling: Scalars['Int']
}

export type NoSpoilerModeInput = {
  enabled: Scalars['Boolean']
  minutesBeforeDisabling: Scalars['Int']
}

export type Numbered = {
  __typename?: 'Numbered'
  backgroundColor?: Maybe<Scalars['String']>
  borderColor?: Maybe<Scalars['String']>
}

export enum OfferAcquisition {
  License = 'License',
  None = 'None',
  Purchase = 'Purchase',
}

export type OfferDescription = {
  __typename?: 'OfferDescription'
  content: Scalars['String']
  header: Scalars['String']
}

export type OfferDescriptionInput = {
  content?: InputMaybe<Scalars['String']>
  header: Scalars['String']
}

export enum OfferMode {
  Pass = 'pass',
  Subscription = 'subscription',
}

export type Organism = {
  __typename?: 'Organism'
  adminBadge?: Maybe<Media>
  adminBadgeId?: Maybe<Scalars['ID']>
  adminsAutomatedEmails?: Maybe<Scalars['Boolean']>
  applications: Array<Application>
  availableExternalSources?: Maybe<Array<WidgetExternalContentSource>>
  availableSVEChannels: Array<SveChannel>
  availableWMStreams: Array<WmStream>
  availableWidgets: Array<CustomPageWidget>
  bannerImage?: Maybe<Media>
  bannerImageFileId?: Maybe<Scalars['ID']>
  bannerVideo?: Maybe<Media>
  bannerVideoId?: Maybe<Scalars['ID']>
  content: Array<ContentSet>
  contentPageApplicationDesignId?: Maybe<Scalars['ID']>
  createdAt: Scalars['Date']
  defaultLocale?: Maybe<Scalars['String']>
  deletedAt?: Maybe<Scalars['Date']>
  description?: Maybe<Scalars['String']>
  descriptionT?: Maybe<Array<ContentTranslation>>
  editedCategories: Array<EditedCategory>
  enableExternalSources?: Maybe<Scalars['Boolean']>
  fansCount: Scalars['Int']
  forbiddenKeyWords?: Maybe<Array<Scalars['String']>>
  frontPosition?: Maybe<Scalars['Int']>
  id: Scalars['ID']
  license?: Maybe<OrganismLicense>
  logo: Logo
  offers: Array<PurchasableOffer>
  officialWebSiteUrl: Scalars['URL']
  onFront: Scalars['Boolean']
  organismPageApplicationDesignId?: Maybe<Scalars['ID']>
  pageWidgets?: Maybe<Array<CustomPageWidget>>
  publicationStatus: PublicationStatus
  sportIds: Array<Scalars['ID']>
  sports: Array<Sport>
  streams?: Maybe<Array<WmStream>>
  streamsIds?: Maybe<Array<Scalars['WildmokaID']>>
  sveChannelIds?: Maybe<Array<Scalars['ID']>>
  sveChannels?: Maybe<Array<SveChannel>>
  tagIds?: Maybe<Array<Scalars['ID']>>
  tags?: Maybe<Array<Tag>>
  /** @deprecated Use createdAt, updatedAt and deletedAt directly now. This resolver will be removed. */
  timestamps: Timestamps
  title: Scalars['String']
  titleT?: Maybe<Array<ContentTranslation>>
  type: OrganismType
  updatedAt: Scalars['Date']
  userIsLicensed: Scalars['Boolean']
  visibleInMultisportApplication?: Maybe<Scalars['Boolean']>
  whiteLabel: Scalars['Boolean']
  wmProfile?: Maybe<WmProfile>
  wmProfileId?: Maybe<Scalars['WildmokaID']>
}

export type OrganismAvailableSveChannelsArgs = {
  input?: InputMaybe<OrganismAvailableSveChannelsInput>
}

export type OrganismContentArgs = {
  includeNews?: Scalars['Boolean']
}

export type OrganismAvailableSveChannelsInput = {
  forDateRange?: InputMaybe<DateRangeInput>
}

export type OrganismDetails = {
  __typename?: 'OrganismDetails'
  organism: Organism
  organismId: Scalars['ID']
}

export type OrganismFilters = {
  license?: InputMaybe<Scalars['Boolean']>
  onFront?: InputMaybe<Scalars['Boolean']>
  publicationStatus?: InputMaybe<PublicationStatus>
  search?: InputMaybe<Scalars['String']>
  type?: InputMaybe<OrganismType>
  whiteLabel?: InputMaybe<Scalars['Boolean']>
}

export type OrganismLicense = {
  __typename?: 'OrganismLicense'
  api?: Maybe<LicenseApi>
  enabled: Scalars['Boolean']
}

export type OrganismLicenseInput = {
  api?: InputMaybe<LicenseApi>
  enabled: Scalars['Boolean']
}

export type OrganismLogoUpdateInput = {
  coloredImageFileId?: InputMaybe<Scalars['ID']>
  transparentDarkImageFileId?: InputMaybe<Scalars['ID']>
  transparentLightImageFileId?: InputMaybe<Scalars['ID']>
}

export type OrganismPageResult = {
  __typename?: 'OrganismPageResult'
  organisms: Array<Organism>
  totalCount?: Maybe<Scalars['Int']>
}

export type OrganismPermissions = {
  __typename?: 'OrganismPermissions'
  organismId: Scalars['ID']
  permissions: Array<Permission>
}

export type OrganismPermissionsInput = {
  organismId: Scalars['ID']
  permissions: Array<Permission>
}

export enum OrganismType {
  Athlete = 'athlete',
  Club = 'club',
  Federation = 'federation',
  Sponsor = 'sponsor',
}

export type OutputStream = {
  __typename?: 'OutputStream'
  id: Scalars['ID']
  name: Scalars['String']
  outputUrl: Scalars['String']
}

export type OutputStreamInput = {
  id?: InputMaybe<Scalars['ID']>
  name: Scalars['String']
  outputUrl: Scalars['String']
}

export type PaymentIntent = {
  __typename?: 'PaymentIntent'
  client_secret: Scalars['String']
  status: StripePaymentIntentStatus
}

export enum Period {
  Day = 'day',
  Month = 'month',
  Week = 'week',
  Year = 'year',
}

export type PeriodInput = {
  from?: InputMaybe<Scalars['Date']>
  to?: InputMaybe<Scalars['Date']>
}

export enum Permission {
  AdCampaignCreate = 'AdCampaignCreate',
  BypassLegalRestriction = 'BypassLegalRestriction',
  CategoryCreate = 'CategoryCreate',
  CategoryDelete = 'CategoryDelete',
  CategoryUpdate = 'CategoryUpdate',
  ChatRoomsAdminBadge = 'ChatRoomsAdminBadge',
  FrontPageCreate = 'FrontPageCreate',
  FrontPageDelete = 'FrontPageDelete',
  FrontPageRead = 'FrontPageRead',
  FrontPageReadUnpublished = 'FrontPageReadUnpublished',
  FrontPageUpdate = 'FrontPageUpdate',
  LegalRestrictionRead = 'LegalRestrictionRead',
  LegalRestrictionWrite = 'LegalRestrictionWrite',
  LicencedPhoneEdit = 'LicencedPhoneEdit',
  LicencedPhoneRead = 'LicencedPhoneRead',
  LinksStatisticsRead = 'LinksStatisticsRead',
  OrganismCreateForbiddenKeyWords = 'OrganismCreateForbiddenKeyWords',
  PurchasableOfferRead = 'PurchasableOfferRead',
  PurchasableOfferWrite = 'PurchasableOfferWrite',
  PurchasesSnapshotCreate = 'PurchasesSnapshotCreate',
  PurchasesSnapshotExportRead = 'PurchasesSnapshotExportRead',
  SveChannelCreate = 'SVEChannelCreate',
  SveChannelDelete = 'SVEChannelDelete',
  SveChannelRead = 'SVEChannelRead',
  SveChannelUpdate = 'SVEChannelUpdate',
  SveChannelsManagement = 'SVEChannelsManagement',
  SportItemSetCategoryEdition = 'SportItemSetCategoryEdition',
  ActivateGreenPlayback = 'activateGreenPlayback',
  AdCampaignDelete = 'adCampaignDelete',
  AdCampaignRead = 'adCampaignRead',
  AdCampaignUpdate = 'adCampaignUpdate',
  AnalyticsRead = 'analyticsRead',
  AnalyticsSnapshotCreate = 'analyticsSnapshotCreate',
  ApplicationContentAccessControlUpdate = 'applicationContentAccessControlUpdate',
  ApplicationCreate = 'applicationCreate',
  ApplicationDelete = 'applicationDelete',
  ApplicationDesignCreate = 'applicationDesignCreate',
  ApplicationDesignDelete = 'applicationDesignDelete',
  ApplicationDesignRead = 'applicationDesignRead',
  ApplicationDesignUpdate = 'applicationDesignUpdate',
  ApplicationRead = 'applicationRead',
  ApplicationReadUnpublished = 'applicationReadUnpublished',
  ApplicationUpdate = 'applicationUpdate',
  BoAccess = 'boAccess',
  CacheKeysClean = 'cacheKeysClean',
  CacheKeysRead = 'cacheKeysRead',
  CastAppAccess = 'castAppAccess',
  ChatRoomsCreate = 'chatRoomsCreate',
  ChatRoomsDelete = 'chatRoomsDelete',
  ChatRoomsRead = 'chatRoomsRead',
  ChatRoomsUpdate = 'chatRoomsUpdate',
  CleanCacheDatasources = 'cleanCacheDatasources',
  CleanCacheGlobal = 'cleanCacheGlobal',
  CleanCacheGraphql = 'cleanCacheGraphql',
  ContentIdMappingRead = 'contentIdMappingRead',
  ContentPushStreamCreate = 'contentPushStreamCreate',
  ContentPushStreamDelete = 'contentPushStreamDelete',
  ContentPushStreamRead = 'contentPushStreamRead',
  ContentPushStreamUpdate = 'contentPushStreamUpdate',
  CreateClipFromLive = 'createClipFromLive',
  CustomPageCreate = 'customPageCreate',
  CustomPageDelete = 'customPageDelete',
  CustomPageRead = 'customPageRead',
  CustomPageReadUnpublished = 'customPageReadUnpublished',
  CustomPageUpdate = 'customPageUpdate',
  DefaultConfigUpdate = 'defaultConfigUpdate',
  /** @deprecated will be removed soon */
  DisplayAdminInfos = 'displayAdminInfos',
  EditLiveTokenExpiration = 'editLiveTokenExpiration',
  EditOrganismWhiteLabel = 'editOrganismWhiteLabel',
  EditedCategoryCreate = 'editedCategoryCreate',
  EditedCategoryDelete = 'editedCategoryDelete',
  EditedCategoryRead = 'editedCategoryRead',
  EditedCategoryReadUnpublished = 'editedCategoryReadUnpublished',
  EditedCategoryUpdate = 'editedCategoryUpdate',
  FansRead = 'fansRead',
  FileCreate = 'fileCreate',
  FileDelete = 'fileDelete',
  FileRead = 'fileRead',
  FileUpdate = 'fileUpdate',
  HasOfferAccess = 'hasOfferAccess',
  IncludeContentStream = 'includeContentStream',
  LinkCreate = 'linkCreate',
  LinkDelete = 'linkDelete',
  LinkIncrementClicks = 'linkIncrementClicks',
  LinkIncrementViews = 'linkIncrementViews',
  LinkManageClicks = 'linkManageClicks',
  LinkManageViews = 'linkManageViews',
  LinkRead = 'linkRead',
  LinkUpdate = 'linkUpdate',
  LiveStreamCacheUpdate = 'liveStreamCacheUpdate',
  LiveStreamCreate = 'liveStreamCreate',
  LiveStreamDelete = 'liveStreamDelete',
  LiveStreamRead = 'liveStreamRead',
  LiveStreamUpdate = 'liveStreamUpdate',
  MapWmStream = 'mapWMStream',
  MediaGenerateReplay = 'mediaGenerateReplay',
  MediaPin = 'mediaPin',
  MediaReadUnpublished = 'mediaReadUnpublished',
  MediaUpdateOrganism = 'mediaUpdateOrganism',
  ModerateChat = 'moderateChat',
  NewsCreate = 'newsCreate',
  NewsDelete = 'newsDelete',
  NewsRead = 'newsRead',
  NewsReadUnpublished = 'newsReadUnpublished',
  NewsUpdate = 'newsUpdate',
  OrganismCreate = 'organismCreate',
  OrganismCreateTag = 'organismCreateTag',
  OrganismDelete = 'organismDelete',
  OrganismPageCreate = 'organismPageCreate',
  OrganismPageDelete = 'organismPageDelete',
  OrganismPageRead = 'organismPageRead',
  OrganismPageUpdate = 'organismPageUpdate',
  OrganismRead = 'organismRead',
  OrganismReadUnpublished = 'organismReadUnpublished',
  OrganismUpdate = 'organismUpdate',
  PinnedMessage = 'pinnedMessage',
  PurchasableOffersExport = 'purchasableOffersExport',
  ReadInactiveLive = 'readInactiveLive',
  ReadParallelLivestreams = 'readParallelLivestreams',
  ReadPlaylistsFromWmProfile = 'readPlaylistsFromWMProfile',
  ReadTestingLivestream = 'readTestingLivestream',
  ReadUnpublishedPurchasableOffer = 'readUnpublishedPurchasableOffer',
  ReadWmProfiles = 'readWMProfiles',
  ReadWmStreamStatus = 'readWMStreamStatus',
  ReadWmStreams = 'readWMStreams',
  ReadWmTemplates = 'readWMTemplates',
  RegisterLicense = 'registerLicense',
  RevokeLicenses = 'revokeLicenses',
  SportCreate = 'sportCreate',
  SportDelete = 'sportDelete',
  SportItemAccessPremium = 'sportItemAccessPremium',
  SportItemCreate = 'sportItemCreate',
  SportItemDelete = 'sportItemDelete',
  SportItemPostUpdate = 'sportItemPostUpdate',
  SportItemPublicationStateUpdate = 'sportItemPublicationStateUpdate',
  SportItemRead = 'sportItemRead',
  SportItemReadUnpublished = 'sportItemReadUnpublished',
  SportItemSetAddTag = 'sportItemSetAddTag',
  SportItemSetContentAccessControlUpdate = 'sportItemSetContentAccessControlUpdate',
  SportItemSetCreate = 'sportItemSetCreate',
  SportItemSetDelete = 'sportItemSetDelete',
  SportItemSetIncrementSponsorPlatform = 'sportItemSetIncrementSponsorPlatform',
  SportItemSetManagePublication = 'sportItemSetManagePublication',
  SportItemSetManageSponsorPlatform = 'sportItemSetManageSponsorPlatform',
  SportItemSetPublicationStateUpdate = 'sportItemSetPublicationStateUpdate',
  SportItemSetRead = 'sportItemSetRead',
  SportItemSetReadUnpublished = 'sportItemSetReadUnpublished',
  SportItemSetUpdate = 'sportItemSetUpdate',
  SportItemUpdate = 'sportItemUpdate',
  SportRead = 'sportRead',
  SportUpdate = 'sportUpdate',
  StatisticsRead = 'statisticsRead',
  StreamLive = 'streamLive',
  TestSportItemSetsRead = 'testSportItemSetsRead',
  ThirdPartyApplicationCreate = 'thirdPartyApplicationCreate',
  ThirdPartyApplicationDelete = 'thirdPartyApplicationDelete',
  ThirdPartyApplicationRead = 'thirdPartyApplicationRead',
  ThirdPartyApplicationUpdate = 'thirdPartyApplicationUpdate',
  UpdateCacheReceipt = 'updateCacheReceipt',
  UpdateMyFanPreferences = 'updateMyFanPreferences',
  UpdateMyself = 'updateMyself',
  UpdateOtherFanPreferences = 'updateOtherFanPreferences',
  UpdateRedButton = 'updateRedButton',
  UpdateSportallPrivateFields = 'updateSportallPrivateFields',
  UserAccessRgpdData = 'userAccessRGPDData',
  UserActiveFeaturesUpdate = 'userActiveFeaturesUpdate',
  UserCreate = 'userCreate',
  UserDelete = 'userDelete',
  UserOrganismsUpdate = 'userOrganismsUpdate',
  UserRead = 'userRead',
  UserRightsUpdate = 'userRightsUpdate',
  UserRoleSuperAdminUpdate = 'userRoleSuperAdminUpdate',
  UserUpdate = 'userUpdate',
  UserUserRolesUpdate = 'userUserRolesUpdate',
  UsersExport = 'usersExport',
  VideoClipCreate = 'videoClipCreate',
  VideoClipDelete = 'videoClipDelete',
  VideoClipRead = 'videoClipRead',
  VideoClipUpdate = 'videoClipUpdate',
  VideoGridRead = 'videoGridRead',
}

export type Place = {
  __typename?: 'Place'
  city?: Maybe<Scalars['String']>
  country?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
}

export enum Platform {
  Android = 'android',
  Apple = 'apple',
  Stripe = 'stripe',
}

export type PlaybackMedia = {
  __typename?: 'PlaybackMedia'
  mediaId: Scalars['ID']
  url: Scalars['String']
}

export type PlaybackState = {
  __typename?: 'PlaybackState'
  createdAt: Scalars['Date']
  deletedAt?: Maybe<Scalars['Date']>
  id: Scalars['ID']
  media: Media
  mediaId: Scalars['ID']
  nbViews: Scalars['Int']
  percentProgress?: Maybe<Scalars['Float']>
  progression?: Maybe<Scalars['Float']>
  sportItem: SportItem
  sportItemId: Scalars['ID']
  /** @deprecated Use createdAt, updatedAt and deletedAt directly now. This resolver will be removed. */
  timestamps: Timestamps
  updatedAt: Scalars['Date']
  userId: Scalars['ID']
  viewedDuration?: Maybe<Scalars['Float']>
}

export enum PremiumAccess {
  All = 'all',
  Live = 'live',
  LiveReplay = 'liveReplay',
  None = 'none',
}

export type PrerollConfig = {
  __typename?: 'PrerollConfig'
  adDisplayMaxCount: Scalars['Int']
  billboardDisplayMaxCount: Scalars['Int']
  videoType: PrerollVideoType
}

export type PrerollConfigInput = {
  adDisplayMaxCount: Scalars['Int']
  billboardDisplayMaxCount: Scalars['Int']
  videoType: PrerollVideoType
}

export enum PrerollVideoType {
  Clip = 'clip',
  LiveAndReplay = 'liveAndReplay',
  Vod = 'vod',
}

export type Price = {
  __typename?: 'Price'
  currency: Scalars['String']
  value: Scalars['Float']
}

export type PriceInput = {
  currency?: InputMaybe<Scalars['String']>
  value: Scalars['Float']
}

export type Product = {
  __typename?: 'Product'
  licensedUserOnly?: Maybe<Scalars['Boolean']>
  period?: Maybe<Period>
  platform: Platform
  premiumOf?: Maybe<Array<Scalars['String']>>
  prices: Array<Price>
  productId: Scalars['String']
  publicationStatus: PublicationStatus
  purchasableOffer?: Maybe<PurchasableOffer>
  purchasableOfferId?: Maybe<Scalars['ID']>
}

export type ProductInput = {
  licensedUserOnly?: InputMaybe<Scalars['Boolean']>
  period?: InputMaybe<Period>
  platform: Platform
  premiumOf?: InputMaybe<Array<Scalars['String']>>
  prices: Array<PriceInput>
  productId: Scalars['String']
  publicationStatus?: InputMaybe<PublicationStatus>
  purchasableOfferId?: InputMaybe<Scalars['ID']>
}

export enum PublicationStatus {
  Draft = 'draft',
  Published = 'published',
}

export enum PurchasableMode {
  Pass = 'pass',
  Subscription = 'subscription',
}

export type PurchasableOffer = {
  __typename?: 'PurchasableOffer'
  banner?: Maybe<Media>
  bannerImageId?: Maybe<Scalars['ID']>
  createdAt: Scalars['Date']
  defaultLocale?: Maybe<Scalars['String']>
  delay?: Maybe<Scalars['Int']>
  deletedAt?: Maybe<Scalars['Date']>
  description: Array<OfferDescription>
  descriptionT?: Maybe<Array<ContentTranslationDescriptionPurchasableOffer>>
  expiredAt?: Maybe<Scalars['Date']>
  freeWithLicense: Scalars['Boolean']
  hasLicensePromotion: Scalars['Boolean']
  /** @deprecated No longer supported */
  hasVipAccess: Scalars['Boolean']
  id: Scalars['ID']
  legal: LegalBlock
  mode: OfferMode
  nbValidSubscriptions?: Maybe<Scalars['Int']>
  organism: Organism
  organismId: Scalars['ID']
  position?: Maybe<Scalars['Int']>
  products: Array<Product>
  publicationStatus: PublicationStatus
  thumbnail?: Maybe<Media>
  thumbnailImageId?: Maybe<Scalars['ID']>
  /** @deprecated Use createdAt, updatedAt and deletedAt directly now. This resolver will be removed. */
  timestamps: Timestamps
  title: Scalars['String']
  titleT?: Maybe<Array<ContentTranslation>>
  updatedAt: Scalars['Date']
  url?: Maybe<Scalars['String']>
  userAcquisition: OfferAcquisition
  userReceipt?: Maybe<UserReceipt>
}

export type PurchasableOfferUserReceiptArgs = {
  valid?: InputMaybe<Scalars['Boolean']>
}

export type PurchasableOffersFilters = {
  onlyAvailable?: InputMaybe<Scalars['Boolean']>
}

export type QuanteecEnergy = {
  __typename?: 'QuanteecEnergy'
  energy?: Maybe<Scalars['Int']>
  energySavedInCO2g?: Maybe<Scalars['Int']>
  energySavedInLight?: Maybe<Scalars['Int']>
  energySavedInPourcent?: Maybe<Scalars['Int']>
  energySavedInWaterL?: Maybe<Scalars['Int']>
  videoId: Scalars['ID']
}

export type Query = {
  __typename?: 'Query'
  activeLiveStreams?: Maybe<ActiveLiveStreamsResult>
  adCampaign?: Maybe<AdCampaign>
  adCampaigns?: Maybe<AdCampaignResult>
  application?: Maybe<Application>
  applicationDesign?: Maybe<ApplicationDesign>
  applicationDesigns?: Maybe<ApplicationDesignPageResult>
  applicationFromApplicationId?: Maybe<Application>
  applications?: Maybe<ApplicationPageResult>
  availableContentPushServices: Array<AvailableContentPushService>
  cacheEntries?: Maybe<Array<Maybe<CacheEntry>>>
  cacheKeys?: Maybe<Array<Maybe<Scalars['String']>>>
  categories: Array<Category>
  checkSVEChannelsAvailable: Array<SportItemSetSveChannelAvailability>
  checkSportItemStreamsMovable: Array<SportItemStreamConflict>
  checkStreamUnused: Array<LiveStreamStreamConflict>
  checkStreamsAvailable: Array<SportItemSetStreamConflict>
  checkStripeCoupon: CheckStripeCouponResult
  contentFrontPage: Array<ContentSet>
  contentPushStreamExports?: Maybe<SportItemSetsPageResult>
  contentPushStreams: Array<ContentPushStream>
  createMultipart?: Maybe<Multipart>
  customPage?: Maybe<CustomPage>
  customPages?: Maybe<CustomPagePageResult>
  defaultConfig: DefaultConfig
  editedCategories?: Maybe<EditedCategoriesPageResult>
  editedCategory: EditedCategory
  favorites: Array<ContentSet>
  file?: Maybe<File>
  fileImageUrl: Scalars['URL']
  files?: Maybe<FilePageResult>
  frontPage: FrontPage
  frontPages: Array<FrontPage>
  getContentIdMapping: GetContentIdMappingResult
  getSportItemSetContentItemSets?: Maybe<ContentSet>
  getStream: WmStream
  getStreamIngestionStatus: WmIngestionStatus
  hasChatRoomPrivilegedRights?: Maybe<Scalars['Boolean']>
  isAccessKeyValid: Scalars['Boolean']
  latestPurchaseExportDownloadUrl?: Maybe<Scalars['String']>
  latestUsersExportDownloadUrl?: Maybe<Scalars['String']>
  legalRestriction?: Maybe<LegalRestriction>
  legalRestrictions: Array<LegalRestriction>
  link: Link
  links?: Maybe<LinkPageResult>
  listProfiles: Array<WmProfile>
  listStreams: Array<WmStreamLight>
  listTemplates: Array<WmTemplate>
  liveStream?: Maybe<LiveStream>
  liveStreamIngestionStatus: WmIngestionStatus
  liveStreams?: Maybe<LiveStreamsPageResult>
  me?: Maybe<User>
  media?: Maybe<Media>
  medias: MediaPageResult
  monitoringDashboard?: Maybe<Array<SportItem>>
  news?: Maybe<News>
  newss?: Maybe<NewsPageResult>
  noSpoilerMode?: Maybe<SportItemSet>
  organism?: Maybe<Organism>
  organisms?: Maybe<OrganismPageResult>
  outputStreams: Array<OutputStream>
  playbackState?: Maybe<PlaybackState>
  purchasableOffer?: Maybe<PurchasableOffer>
  purchasableOffers: Array<PurchasableOffer>
  quanteecEnergy?: Maybe<QuanteecEnergy>
  root?: Maybe<Scalars['String']>
  signedUrl?: Maybe<SignedUrl>
  sport?: Maybe<Sport>
  sportItem?: Maybe<SportItem>
  sportItemByChatRoom?: Maybe<SportItem>
  sportItemSet?: Maybe<SportItemSet>
  sportItemSetBySource?: Maybe<SportItemSet>
  sportItemSets: SportItemSetsPageResult
  sportItems?: Maybe<SportItemsPageResult>
  sports?: Maybe<SportPageResult>
  sveBroadcast?: Maybe<SveBroadcast>
  sveChannel: SveChannel
  sveChannels: SveChannelsPageResult
  thirdPartyApplication?: Maybe<ThirdPartyApplication>
  thirdPartyApplications?: Maybe<ThirdPartyApplicationPageResult>
  uploadedFiles?: Maybe<Array<Maybe<File>>>
  user?: Maybe<User>
  userRole?: Maybe<UserRole>
  userRoles?: Maybe<Array<UserRole>>
  users?: Maybe<UserPageResult>
  usersExportState: UsersExportState
  videoClip?: Maybe<VideoClip>
  videoClips?: Maybe<VideoClipPageResult>
}

export type QueryAdCampaignArgs = {
  id: Scalars['ID']
}

export type QueryAdCampaignsArgs = {
  filters?: InputMaybe<AdCampaignFilters>
  first?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type QueryApplicationArgs = {
  id: Scalars['ID']
}

export type QueryApplicationDesignArgs = {
  id: Scalars['ID']
}

export type QueryApplicationDesignsArgs = {
  filters?: InputMaybe<ApplicationDesignFilters>
  first?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type QueryApplicationFromApplicationIdArgs = {
  id: Scalars['ID']
}

export type QueryApplicationsArgs = {
  filters?: InputMaybe<ApplicationsFilters>
  first?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type QueryCacheEntriesArgs = {
  pattern: Scalars['String']
}

export type QueryCacheKeysArgs = {
  pattern: Scalars['String']
}

export type QueryCheckSveChannelsAvailableArgs = {
  input: CheckSveChannelsAvailableInput
}

export type QueryCheckSportItemStreamsMovableArgs = {
  from: Scalars['Date']
  sportItemId: Scalars['ID']
  to: Scalars['Date']
}

export type QueryCheckStreamUnusedArgs = {
  from: Scalars['Date']
  sportItemSetId: Scalars['ID']
  streamId: Scalars['WildmokaID']
  to: Scalars['Date']
}

export type QueryCheckStreamsAvailableArgs = {
  from: Scalars['Date']
  streamsIds: Array<Scalars['WildmokaID']>
  to: Scalars['Date']
}

export type QueryCheckStripeCouponArgs = {
  coupon: Scalars['String']
  offerId: Scalars['String']
  productId: Scalars['String']
}

export type QueryContentFrontPageArgs = {
  page: ContentPage
}

export type QueryContentPushStreamExportsArgs = {
  contentPushStreamId: Scalars['ID']
  first?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type QueryContentPushStreamsArgs = {
  filters?: InputMaybe<ContentPushStreamsFiltersQuery>
}

export type QueryCreateMultipartArgs = {
  extension?: InputMaybe<Scalars['String']>
  fileName?: InputMaybe<Scalars['String']>
  hash: Scalars['String']
}

export type QueryCustomPageArgs = {
  backward?: Scalars['Boolean']
  id: Scalars['ID']
}

export type QueryCustomPagesArgs = {
  backward?: Scalars['Boolean']
  filters?: InputMaybe<CustomPageFilters>
  first?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type QueryEditedCategoriesArgs = {
  filters?: InputMaybe<EditedCategoryFilters>
}

export type QueryEditedCategoryArgs = {
  id: Scalars['ID']
}

export type QueryFileArgs = {
  id: Scalars['ID']
}

export type QueryFileImageUrlArgs = {
  fileId: Scalars['ID']
}

export type QueryFilesArgs = {
  first?: InputMaybe<Scalars['Int']>
  mimetype?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['Int']>
  search?: InputMaybe<Scalars['String']>
}

export type QueryFrontPageArgs = {
  id: Scalars['ID']
}

export type QueryFrontPagesArgs = {
  applicationId?: InputMaybe<Scalars['String']>
  current?: InputMaybe<Scalars['Boolean']>
  withDeleted?: InputMaybe<Scalars['Boolean']>
}

export type QueryGetContentIdMappingArgs = {
  input: GetContentIdMappingInput
}

export type QueryGetSportItemSetContentItemSetsArgs = {
  sportItemSetId: Scalars['ID']
}

export type QueryGetStreamArgs = {
  id: Scalars['WildmokaID']
}

export type QueryGetStreamIngestionStatusArgs = {
  id: Scalars['WildmokaID']
}

export type QueryHasChatRoomPrivilegedRightsArgs = {
  chatRoomId: Scalars['String']
}

export type QueryIsAccessKeyValidArgs = {
  accessKey: Scalars['String']
}

export type QueryLatestUsersExportDownloadUrlArgs = {
  maxDate?: InputMaybe<Scalars['Date']>
}

export type QueryLegalRestrictionArgs = {
  id: Scalars['ID']
}

export type QueryLinkArgs = {
  id: Scalars['ID']
  organismId?: InputMaybe<Scalars['ID']>
}

export type QueryLinksArgs = {
  filters?: InputMaybe<LinkFilters>
  first?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<LinkSort>
}

export type QueryListStreamsArgs = {
  filters?: InputMaybe<StreamFilters>
}

export type QueryListTemplatesArgs = {
  organismId?: InputMaybe<Scalars['ID']>
  type?: InputMaybe<WmTemplateType>
}

export type QueryLiveStreamArgs = {
  id: Scalars['ID']
}

export type QueryLiveStreamIngestionStatusArgs = {
  id: Scalars['ID']
}

export type QueryLiveStreamsArgs = {
  first?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type QueryMediaArgs = {
  id?: InputMaybe<Scalars['ID']>
}

export type QueryMediasArgs = {
  first?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  organismId?: InputMaybe<Scalars['ID']>
  published?: InputMaybe<Scalars['Boolean']>
  search?: InputMaybe<Scalars['String']>
  tags?: InputMaybe<Array<MediaTags>>
  type?: InputMaybe<MediaType>
}

export type QueryMonitoringDashboardArgs = {
  date: Scalars['Date']
  organismId?: InputMaybe<Scalars['ID']>
}

export type QueryNewsArgs = {
  id: Scalars['ID']
  language?: InputMaybe<Scalars['String']>
}

export type QueryNewssArgs = {
  filters?: InputMaybe<NewsFilters>
  first?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  search?: InputMaybe<Scalars['String']>
}

export type QueryNoSpoilerModeArgs = {
  customPageId?: InputMaybe<Scalars['ID']>
}

export type QueryOrganismArgs = {
  applicationId?: InputMaybe<Scalars['ID']>
  id?: InputMaybe<Scalars['ID']>
}

export type QueryOrganismsArgs = {
  filters?: InputMaybe<OrganismFilters>
  first?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type QueryPlaybackStateArgs = {
  mediaId: Scalars['ID']
}

export type QueryPurchasableOfferArgs = {
  id: Scalars['ID']
}

export type QueryPurchasableOffersArgs = {
  filters?: InputMaybe<PurchasableOffersFilters>
  ids?: InputMaybe<Array<Scalars['ID']>>
}

export type QueryQuanteecEnergyArgs = {
  videoId: Scalars['String']
}

export type QuerySignedUrlArgs = {
  extension?: InputMaybe<Scalars['String']>
  fileName?: InputMaybe<Scalars['String']>
  sportItemId?: InputMaybe<Scalars['ID']>
}

export type QuerySportArgs = {
  id: Scalars['ID']
}

export type QuerySportItemArgs = {
  id?: InputMaybe<Scalars['ID']>
}

export type QuerySportItemByChatRoomArgs = {
  chatRoomId: Scalars['ID']
}

export type QuerySportItemSetArgs = {
  id?: InputMaybe<Scalars['ID']>
}

export type QuerySportItemSetBySourceArgs = {
  input: SportItemSetBySourceIdInput
}

export type QuerySportItemSetsArgs = {
  filters?: InputMaybe<SportItemSetFilters>
  first?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Array<SportItemSetSort>>
  withItems?: InputMaybe<Scalars['Boolean']>
}

export type QuerySportItemsArgs = {
  filters?: InputMaybe<SportItemFilters>
  first?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  sportItemSetId: Scalars['ID']
}

export type QuerySportsArgs = {
  first?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  onFront?: InputMaybe<Scalars['Boolean']>
}

export type QuerySveBroadcastArgs = {
  broadcastId: Scalars['ID']
}

export type QuerySveChannelArgs = {
  channelId: Scalars['ID']
}

export type QuerySveChannelsArgs = {
  input?: InputMaybe<SveChannelsInput>
}

export type QueryThirdPartyApplicationArgs = {
  id: Scalars['ID']
}

export type QueryThirdPartyApplicationsArgs = {
  filters?: InputMaybe<ThirdPartyApplicationsFilters>
  first?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type QueryUserArgs = {
  id: Scalars['ID']
}

export type QueryUserRoleArgs = {
  id: UserRoleId
}

export type QueryUsersArgs = {
  filters?: InputMaybe<UserFilters>
  first?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  sortBy?: InputMaybe<SortBy>
}

export type QueryVideoClipArgs = {
  id: Scalars['ID']
}

export type QueryVideoClipsArgs = {
  first?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type RssFeedContent = {
  __typename?: 'RSSFeedContent'
  feedUrl: Scalars['String']
}

export type RssFeedContentInput = {
  feedUrl: Scalars['String']
}

export enum ReceiptStatus {
  Active = 'active',
  Canceled = 'canceled',
  Expired = 'expired',
  Invalid = 'invalid',
}

export type RedButtonConfig = {
  __typename?: 'RedButtonConfig'
  lives: Array<LiveConfig>
}

export type RegisterLicenseInput = {
  ffAthle?: InputMaybe<FfAthleLicenseInput>
  fff?: InputMaybe<FffLicenseInput>
  ffr?: InputMaybe<FfrLicenseInput>
}

export type RemovePlaybackStateInput = {
  mediaId: Scalars['ID']
  sportItemId: Scalars['ID']
}

export type ReplaysWidget = {
  __typename?: 'ReplaysWidget'
  defaultLocale: Scalars['String']
  depthInDays?: Maybe<Scalars['Int']>
  maxReplays?: Maybe<Scalars['Int']>
  nameT: Array<ContentTranslation>
}

export type ReplaysWidgetInput = {
  defaultLocale: Scalars['String']
  depthInDays?: InputMaybe<Scalars['Int']>
  maxReplays?: InputMaybe<Scalars['Int']>
  nameT: Array<ContentTranslationInput>
}

export type RequiredUserFieldMapping = {
  column: Scalars['String']
}

export enum RestrictionTargetFilter {
  Media = 'media',
  Offer = 'offer',
}

export enum RestrictionType {
  Forbidden = 'forbidden',
  OnlyAllowed = 'onlyAllowed',
  TimeSlots = 'timeSlots',
}

export type SveBroadcast = {
  __typename?: 'SVEBroadcast'
  channelId: Scalars['String']
  clipStartDate?: Maybe<Scalars['Date']>
  hlsEndpointUrl?: Maybe<Scalars['String']>
  id: Scalars['ID']
  scheduledClipStartDate: Scalars['Date']
  scheduledStartDate: Scalars['Date']
  scheduledStopDate: Scalars['Date']
  startDate?: Maybe<Scalars['Date']>
  status: SveBroadcastState
  stopDate?: Maybe<Scalars['Date']>
}

export enum SveBroadcastState {
  Running = 'running',
  Scheduled = 'scheduled',
  Starting = 'starting',
  Stopped = 'stopped',
  Stopping = 'stopping',
}

export type SveChannel = {
  __typename?: 'SVEChannel'
  broadcasts: Array<SveBroadcast>
  endpoints?: Maybe<SveChannelEndpoints>
  id: Scalars['ID']
  inputLossImageUrl?: Maybe<Scalars['String']>
  logoUrl?: Maybe<Scalars['String']>
  name: Scalars['String']
  screachOutputStreamName?: Maybe<Scalars['String']>
  screachRTMPOutputUrl?: Maybe<Scalars['String']>
  socialOutputStreamName?: Maybe<Scalars['String']>
  socialOutputUrl?: Maybe<Scalars['String']>
  status: SveChannelState
}

export type SveChannelEndpoints = {
  __typename?: 'SVEChannelEndpoints'
  cmafEndpointUrl?: Maybe<Scalars['String']>
  hlsEndpointUrl?: Maybe<Scalars['String']>
  rtmpInputUrl?: Maybe<Scalars['String']>
}

export enum SveChannelState {
  Deleted = 'deleted',
  Idle = 'idle',
  Mounting = 'mounting',
  Ready = 'ready',
  Unmounting = 'unmounting',
  Updating = 'updating',
}

export type SveChannelsFilters = {
  channelIds?: InputMaybe<Array<Scalars['ID']>>
}

export type SveChannelsInput = {
  filters?: InputMaybe<SveChannelsFilters>
  first?: InputMaybe<Scalars['Int']>
  includeDeleted?: InputMaybe<Scalars['Boolean']>
  offset?: InputMaybe<Scalars['Int']>
}

export type SveChannelsPageResult = {
  __typename?: 'SVEChannelsPageResult'
  sveChannels: Array<SveChannel>
  totalCount: Scalars['Int']
}

export type SveLiveMonitoring = {
  __typename?: 'SVELiveMonitoring'
  alerts?: Maybe<SveLiveMonitoringAlerts>
  stats?: Maybe<SveLiveMonitoringStats>
}

export type SveLiveMonitoringAlerts = {
  __typename?: 'SVELiveMonitoringAlerts'
  RTMPHasNoAudioVideo?: Maybe<Scalars['String']>
  RTMPStreamNotFound?: Maybe<Scalars['String']>
  audioNotDetected?: Maybe<Scalars['String']>
  initialProbeTakingLonger?: Maybe<Scalars['String']>
  videoNotDetected?: Maybe<Scalars['String']>
}

export type SveLiveMonitoringStats = {
  __typename?: 'SVELiveMonitoringStats'
  droppedFrames?: Maybe<Scalars['String']>
  inputVideoFrameRate?: Maybe<Scalars['String']>
  networkIn?: Maybe<Scalars['String']>
  outputAudioLevelLkfs?: Maybe<Scalars['String']>
}

export type SveStream = {
  __typename?: 'SVEStream'
  outputStreamId?: Maybe<Scalars['ID']>
  socialNetworkWMStreamId?: Maybe<Scalars['ID']>
  sveChannelId: Scalars['ID']
}

export type SveStreamInput = {
  outputStreamId?: InputMaybe<Scalars['ID']>
  socialNetworkWMStreamId?: InputMaybe<Scalars['ID']>
  sveChannelId: Scalars['ID']
}

export enum SignUpOrigin {
  Apple = 'apple',
  Email = 'email',
  External = 'external',
  Facebook = 'facebook',
  Google = 'google',
}

export type SignedUrl = {
  __typename?: 'SignedUrl'
  key: Scalars['String']
  url: Scalars['String']
}

export type SortBy = {
  column: Scalars['String']
  direction: Scalars['Int']
}

export enum SortDirection {
  Ascending = 'ascending',
  Descending = 'descending',
}

export type SponsorPlatform = {
  __typename?: 'SponsorPlatform'
  /** @deprecated Use link */
  clicks?: Maybe<Scalars['Int']>
  display?: Maybe<Array<SponsorPlatformDisplayOptions>>
  /** @deprecated Use link */
  label?: Maybe<Scalars['String']>
  link?: Maybe<Link>
  linkId?: Maybe<Scalars['ID']>
  /** @deprecated Use link */
  uniqueClicks?: Maybe<Scalars['Int']>
  /** @deprecated Use link */
  url?: Maybe<Scalars['URL']>
  videoPreroll?: Maybe<Array<SponsorPlatformPrerollOptions>>
}

export enum SponsorPlatformDisplayOptions {
  Button = 'button',
  Vignette = 'vignette',
}

export type SponsorPlatformInput = {
  display: Array<SponsorPlatformDisplayOptions>
  linkId: Scalars['ID']
  videoPreroll: Array<SponsorPlatformPrerollOptions>
}

export enum SponsorPlatformPrerollOptions {
  Vod = 'VOD',
  Live = 'live',
  OnFront = 'onFront',
  Replay = 'replay',
}

export type Sport = {
  __typename?: 'Sport'
  bannerImage?: Maybe<Media>
  bannerVideo?: Maybe<Media>
  bannerVideoId?: Maybe<Scalars['ID']>
  cardImage?: Maybe<Media>
  color: Scalars['String']
  createdAt: Scalars['Date']
  defaultLocale?: Maybe<Scalars['String']>
  deletedAt?: Maybe<Scalars['Date']>
  frontPosition?: Maybe<Scalars['Int']>
  iconImage?: Maybe<Media>
  id: Scalars['ID']
  onFront: Scalars['Boolean']
  organisms: Array<Organism>
  sportItemSets: Array<SportItemSet>
  /** @deprecated Use createdAt, updatedAt and deletedAt directly now. This resolver will be removed. */
  timestamps: Timestamps
  title: Scalars['String']
  titleT?: Maybe<Array<ContentTranslation>>
  updatedAt: Scalars['Date']
}

export type SportSportItemSetsArgs = {
  filters?: InputMaybe<SportItemSetFilters>
  orderBy?: InputMaybe<Array<SportItemSetSort>>
}

export type SportEvent = {
  __typename?: 'SportEvent'
  backstageTemplate?: Maybe<WmTemplate>
  backstageTemplateId?: Maybe<Scalars['WildmokaID']>
  clipTemplate?: Maybe<WmTemplate>
  clipTemplateId?: Maybe<Scalars['WildmokaID']>
  endDate: Scalars['Date']
  liveTemplate?: Maybe<WmTemplate>
  liveTemplateId?: Maybe<Scalars['WildmokaID']>
  ownerOrganismId?: Maybe<Scalars['ID']>
  startDate: Scalars['Date']
  state: SportEventState
}

export type SportEventCreateInput = {
  backstageTemplateId?: InputMaybe<Scalars['WildmokaID']>
  clipTemplateId?: InputMaybe<Scalars['WildmokaID']>
  endDate: Scalars['Date']
  liveTemplateId?: InputMaybe<Scalars['WildmokaID']>
  startDate: Scalars['Date']
}

export type SportEventItem = {
  __typename?: 'SportEventItem'
  publishAutoreplay: Scalars['Boolean']
  startDate: Scalars['Date']
  stopDate: Scalars['Date']
  testDate: Scalars['Date']
}

export type SportEventItemCreateInput = {
  publishAutoreplay?: InputMaybe<Scalars['Boolean']>
  startDate: Scalars['Date']
  stopDate: Scalars['Date']
  testDate?: InputMaybe<Scalars['Date']>
}

export type SportEventItemUpdateInput = {
  publishAutoreplay?: InputMaybe<Scalars['Boolean']>
  startDate?: InputMaybe<Scalars['Date']>
  stopDate?: InputMaybe<Scalars['Date']>
  testDate?: InputMaybe<Scalars['Date']>
}

export enum SportEventState {
  Finished = 'finished',
  NotStarted = 'notStarted',
  Running = 'running',
}

export type SportEventUpdateInput = {
  backstageTemplateId?: InputMaybe<Scalars['WildmokaID']>
  clipTemplateId?: InputMaybe<Scalars['WildmokaID']>
  endDate?: InputMaybe<Scalars['Date']>
  liveTemplateId?: InputMaybe<Scalars['WildmokaID']>
  startDate?: InputMaybe<Scalars['Date']>
}

export type SportItem = {
  __typename?: 'SportItem'
  accessRequiresUserAuthentication?: Maybe<Scalars['Boolean']>
  cac?: Maybe<ContentAccessControl>
  chatRoomId?: Maybe<Scalars['String']>
  contentPushServiceNamesExcluded?: Maybe<Array<ContentPushStreamServiceName>>
  contentType: SportItemContentType
  coverImage?: Maybe<Media>
  coverImageFileId: Scalars['ID']
  createdAt: Scalars['Date']
  defaultLocale?: Maybe<Scalars['String']>
  deletedAt?: Maybe<Scalars['Date']>
  description?: Maybe<Scalars['String']>
  descriptionT?: Maybe<Array<ContentTranslation>>
  dynamicTabs?: Maybe<Array<Tab>>
  id: Scalars['ID']
  legal: LegalBlock
  liveStatus: LiveStatus
  lockedByUserLocation: Scalars['Boolean']
  matureContent: Scalars['Boolean']
  mediaToRead?: Maybe<Media>
  medias: Array<Media>
  mediasIds: Array<Scalars['ID']>
  modeRadio?: Maybe<Scalars['Boolean']>
  overrideTabs: Scalars['Boolean']
  playlistPosition?: Maybe<Scalars['Int']>
  premiumLocked: Scalars['Boolean']
  publicationStatus: PublicationStatus
  reverseRestriction?: Maybe<Scalars['Boolean']>
  /** @deprecated Use dynamicTabs */
  showGriiip?: Maybe<Scalars['Boolean']>
  sportEventItem?: Maybe<SportEventItem>
  sportItemSet?: Maybe<SportItemSet>
  sportItemSetId: Scalars['ID']
  subtitle?: Maybe<Scalars['String']>
  subtitleT?: Maybe<Array<ContentTranslation>>
  tabs?: Maybe<Array<Tab>>
  /** @deprecated Use createdAt, updatedAt and deletedAt directly now. This resolver will be removed. */
  timestamps: Timestamps
  title: Scalars['String']
  titleT?: Maybe<Array<ContentTranslation>>
  /** @deprecated Use videoContentType */
  type: SportItemType
  unlockedBy: Array<UserPurchase>
  updatedAt: Scalars['Date']
  userPlaybackState?: Maybe<PlaybackState>
  videoContentType: VideoContentType
  visibleInMultisportApplication?: Maybe<Scalars['Boolean']>
}

export type SportItemMediaToReadArgs = {
  modeRadio?: InputMaybe<Scalars['Boolean']>
}

export type SportItemMediasArgs = {
  filters?: InputMaybe<MediaFilters>
}

export enum SportItemContentType {
  Free = 'free',
  Interview = 'interview',
  Match = 'match',
  Post = 'post',
}

export type SportItemDetails = {
  __typename?: 'SportItemDetails'
  /** @deprecated Use sportItem resolver */
  live: Scalars['Boolean']
  /** @deprecated Use sportItem resolver */
  onAir: Scalars['Boolean']
  /** @deprecated Use sportItem resolver */
  premiumLocked: Scalars['Boolean']
  /** @deprecated Use sportItem resolver */
  publicationStatus: PublicationStatus
  sportItem: SportItem
  sportItemId: Scalars['ID']
  /** @deprecated Use sportItem resolver */
  startDate?: Maybe<Scalars['Date']>
  /** @deprecated Use sportItem resolver */
  stopDate?: Maybe<Scalars['Date']>
}

export type SportItemFilters = {
  active?: InputMaybe<Scalars['Boolean']>
  live?: InputMaybe<Scalars['Boolean']>
  published?: InputMaybe<Scalars['Boolean']>
}

export type SportItemMediaPost = {
  __typename?: 'SportItemMediaPost'
  bannerImage: File
  bannerVideoUrl?: Maybe<Scalars['URL']>
  content: Scalars['String']
  date?: Maybe<Scalars['Date']>
  exerpt?: Maybe<Scalars['String']>
  id: Scalars['ID']
  title: Scalars['String']
}

export type SportItemMediaPostCreateInput = {
  bannerImageFileId: Scalars['ID']
  bannerVideoUrl?: InputMaybe<Scalars['URL']>
  content: Scalars['String']
  date: Scalars['Date']
  exerpt?: InputMaybe<Scalars['String']>
  title: Scalars['String']
}

export type SportItemMediaPostUpdateInput = {
  bannerImageFileId?: InputMaybe<Scalars['ID']>
  bannerVideoUrl?: InputMaybe<Scalars['URL']>
  content?: InputMaybe<Scalars['String']>
  date?: InputMaybe<Scalars['Date']>
  exerpt?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  title?: InputMaybe<Scalars['String']>
}

export type SportItemSet = {
  __typename?: 'SportItemSet'
  activateGreenPlayback?: Maybe<Scalars['Boolean']>
  activateSportItemChatByDefault: Scalars['Boolean']
  adCampaign?: Maybe<AdCampaign>
  adCampaignId?: Maybe<Scalars['ID']>
  availableSVEChannelIds: Array<Scalars['ID']>
  bannerImage?: Maybe<Media>
  bannerImageFileId?: Maybe<Scalars['ID']>
  bannerVideo?: Maybe<Media>
  bannerVideoId?: Maybe<Scalars['ID']>
  cac?: Maybe<ContentAccessControl>
  categories: Array<Category>
  categoriesIds: Array<Scalars['ID']>
  contentPushServiceGenericStreamIds?: Maybe<Array<Scalars['ID']>>
  contentPushServiceGenericStreams?: Maybe<Array<ContentPushStream>>
  contentPushServiceLiveEvent?: Maybe<Array<ContentPushStream>>
  contentPushServiceLiveEventIds?: Maybe<Array<Scalars['ID']>>
  contentPushServiceNames?: Maybe<Array<ContentPushStreamServiceName>>
  contentSource?: Maybe<ContentSource>
  createdAt: Scalars['Date']
  defaultLocale?: Maybe<Scalars['String']>
  deletedAt?: Maybe<Scalars['Date']>
  description?: Maybe<Scalars['String']>
  descriptionT?: Maybe<Array<ContentTranslation>>
  disableReadParallelLivestreamsLimit?: Maybe<Scalars['Boolean']>
  dynamicTabs?: Maybe<Array<Tab>>
  ecmSessionId?: Maybe<Scalars['String']>
  frontPages: Array<FrontPage>
  /** @deprecated Use dynamicTabs */
  griiipUrl?: Maybe<Scalars['String']>
  id: Scalars['ID']
  items?: Maybe<Array<SportItem>>
  legalRestrictions: Array<LegalRestriction>
  /** @deprecated Use dynamicTabs */
  liveStatsUrl?: Maybe<Scalars['String']>
  liveStatus: LiveStatus
  lockPublishingClip?: Maybe<Scalars['Boolean']>
  lockPublishingLive?: Maybe<Scalars['Boolean']>
  lockedByUserLocation: Scalars['Boolean']
  /** @deprecated Use dynamicTabs */
  matsportUrl?: Maybe<Scalars['String']>
  noSpoilerMode?: Maybe<NoSpoilerMode>
  offerIds: Array<Scalars['ID']>
  offers: Array<PurchasableOffer>
  onFront: Scalars['Boolean']
  ownerOrganism?: Maybe<Organism>
  ownerOrganismId?: Maybe<Scalars['ID']>
  publicationStatus: PublicationStatus
  restrictionLevel: PremiumAccess
  sponsorOrganismIds?: Maybe<Array<Scalars['String']>>
  sponsorPlatform?: Maybe<SponsorPlatform>
  sponsors?: Maybe<Array<Organism>>
  sportEvent?: Maybe<SportEvent>
  sportIds: Array<Scalars['ID']>
  sports: Array<Sport>
  streams?: Maybe<Array<WmStream>>
  streamsIds?: Maybe<Array<Scalars['WildmokaID']>>
  subTags?: Maybe<Array<Scalars['String']>>
  subtitle?: Maybe<Scalars['String']>
  subtitleT?: Maybe<Array<ContentTranslation>>
  sveInputLossImage?: Maybe<Media>
  sveInputLossImageMediaId?: Maybe<Scalars['ID']>
  sveOverlayLogoImage?: Maybe<Media>
  sveOverlayLogoImageMediaId?: Maybe<Scalars['ID']>
  sveStreams: Array<SveStream>
  tabs?: Maybe<Array<Tab>>
  tag?: Maybe<Tag>
  teaser?: Maybe<Media>
  testPeriodEnd?: Maybe<Scalars['Date']>
  timeOnFront: Scalars['BigInt']
  /** @deprecated Use createdAt, updatedAt and deletedAt directly now. This resolver will be removed. */
  timestamps: Timestamps
  title: Scalars['String']
  titleT?: Maybe<Array<ContentTranslation>>
  type: SportItemSetType
  updatedAt: Scalars['Date']
  /** Warning: this resolver can impact performances, it needs to load all sportItems related */
  videoContentTypes: Array<VideoContentType>
  videoEngine: VideoEngine
  visibleInMultisportApplication: Scalars['Boolean']
  websiteUrl?: Maybe<Scalars['URL']>
  wmClippingTemplateId?: Maybe<Scalars['WildmokaID']>
}

export type SportItemSetFrontPagesArgs = {
  current?: InputMaybe<Scalars['Boolean']>
  withDeleted?: InputMaybe<Scalars['Boolean']>
}

export type SportItemSetItemsArgs = {
  filters?: InputMaybe<SportItemFilters>
}

export type SportItemSetBySourceIdInput = {
  id: Scalars['ID']
  idPrefix: Scalars['ID']
  ownerOrganismId?: InputMaybe<Scalars['ID']>
}

export type SportItemSetDetails = {
  __typename?: 'SportItemSetDetails'
  /** @deprecated Use sportItemSet resolver */
  endDate?: Maybe<Scalars['Date']>
  /** @deprecated Use sportItemSet resolver */
  live: Scalars['Boolean']
  /** @deprecated Use sportItemSet resolver */
  onAir: Scalars['Boolean']
  /** @deprecated Use sportItemSet resolver */
  publicationStatus: PublicationStatus
  /** @deprecated Use sportItemSet resolver */
  restrictionLevel: PremiumAccess
  sportItemSet: SportItemSet
  sportItemSetId: Scalars['ID']
  /** @deprecated Use sportItemSet resolver */
  startDate?: Maybe<Scalars['Date']>
}

export type SportItemSetFilters = {
  adCampaignId?: InputMaybe<Scalars['ID']>
  castable?: InputMaybe<Scalars['Boolean']>
  categoryId?: InputMaybe<Scalars['ID']>
  favorite?: InputMaybe<FavoriteFilter>
  frontPage?: InputMaybe<Scalars['Boolean']>
  organismId?: InputMaybe<Scalars['ID']>
  published?: InputMaybe<Scalars['Boolean']>
  search?: InputMaybe<Scalars['String']>
  sourceIdPrefix?: InputMaybe<Scalars['String']>
  sportEventState?: InputMaybe<SportEventState>
  sportId?: InputMaybe<Scalars['ID']>
  sportItemSetId?: InputMaybe<Scalars['ID']>
  sportItemSetIds?: InputMaybe<Array<Scalars['ID']>>
  type?: InputMaybe<SportItemSetType>
}

export type SportItemSetSveChannelAvailability = {
  __typename?: 'SportItemSetSVEChannelAvailability'
  available: Scalars['Boolean']
  id: Scalars['String']
  name: Scalars['String']
  status: SveChannelState
}

export type SportItemSetSort = {
  direction?: InputMaybe<SortDirection>
  property: SportItemSetSortProperty
}

export enum SportItemSetSortProperty {
  ClosestSportEvent = 'closestSportEvent',
  PublicationStatus = 'publicationStatus',
  RunningSportEvent = 'runningSportEvent',
  TimeOnFront = 'timeOnFront',
  Title = 'title',
  Type = 'type',
}

export type SportItemSetStreamConflict = {
  __typename?: 'SportItemSetStreamConflict'
  sportItemSet: SportItemSet
  streamId: Scalars['WildmokaID']
}

export enum SportItemSetType {
  Playlist = 'playlist',
  SportEvent = 'sportEvent',
}

export type SportItemSetsPageResult = {
  __typename?: 'SportItemSetsPageResult'
  sportItemSets: Array<SportItemSet>
  totalCount: Scalars['Int']
}

export type SportItemStreamConflict = {
  __typename?: 'SportItemStreamConflict'
  sportItem: SportItem
  stream?: Maybe<WmEventStream>
  sveBroadcast?: Maybe<SveBroadcast>
  videoEngine: VideoEngine
}

export enum SportItemType {
  PlaylistItem = 'playlistItem',
  SportEventItem = 'sportEventItem',
}

export type SportItemsPageResult = {
  __typename?: 'SportItemsPageResult'
  sportItemSet?: Maybe<SportItemSet>
  sportItems: Array<SportItem>
  totalCount?: Maybe<Scalars['Int']>
}

export type SportPageResult = {
  __typename?: 'SportPageResult'
  sports: Array<Sport>
  totalCount?: Maybe<Scalars['Int']>
}

export type StartEndDates = {
  __typename?: 'StartEndDates'
  endDate: Scalars['Date']
  startDate: Scalars['Date']
}

export type StartEndDatesInput = {
  endDate: Scalars['Date']
  startDate: Scalars['Date']
}

export type StartMediaPlaybackInput = {
  mediaId: Scalars['ID']
  sportItemId: Scalars['ID']
}

export type StreamFilters = {
  mapped?: InputMaybe<Scalars['Boolean']>
  pool?: InputMaybe<Scalars['Boolean']>
  state?: InputMaybe<WmStreamState>
}

export type StreamMap = {
  __typename?: 'StreamMap'
  channelArn: Scalars['String']
  id: Scalars['ID']
  outputUrl: Scalars['String']
  playbackUrl: Scalars['String']
  streamId: Scalars['WildmokaID']
}

export type StripeCouponInfo = {
  __typename?: 'StripeCouponInfo'
  amountOff?: Maybe<Scalars['String']>
  percentOff?: Maybe<Scalars['String']>
}

export type StripeDiscount = {
  __typename?: 'StripeDiscount'
  amountOff?: Maybe<Scalars['Float']>
  percentOff?: Maybe<Scalars['Float']>
}

export enum StripePaymentIntentStatus {
  Canceled = 'canceled',
  Processing = 'processing',
  RequiresAction = 'requires_action',
  RequiresCapture = 'requires_capture',
  RequiresConfirmation = 'requires_confirmation',
  RequiresPaymentMethod = 'requires_payment_method',
  Succeeded = 'succeeded',
}

export enum StripeStatus {
  Active = 'active',
  Canceled = 'canceled',
  Incomplete = 'incomplete',
  IncompleteExpired = 'incomplete_expired',
  PastDue = 'past_due',
  Trialing = 'trialing',
  Unpaid = 'unpaid',
}

export type StripeSubscription = {
  __typename?: 'StripeSubscription'
  client_secret: Scalars['String']
  id: Scalars['String']
  status: StripeStatus
}

export type StripeSubscriptionReceiptInput = {
  subscriptionId: Scalars['String']
}

export type Subscription = {
  __typename?: 'Subscription'
  importUsersStatus: ImportUsersStatus
  liveStream: LiveStream
  playbackUrlAllowed: Scalars['Boolean']
  root?: Maybe<Scalars['String']>
}

export type SubscriptionLiveStreamArgs = {
  liveStreamId: Scalars['ID']
}

export type SubscriptionPlaybackUrlAllowedArgs = {
  mediaId?: InputMaybe<Scalars['ID']>
  playbackUrl: Scalars['String']
}

export enum SubscriptionType {
  Apple = 'apple',
  Google = 'google',
  Stripe = 'stripe',
}

export type Subtag = {
  __typename?: 'Subtag'
  id: Scalars['ID']
  title: Scalars['String']
}

export type SveChannelDelayHour = {
  __typename?: 'SveChannelDelayHour'
  mounting: Scalars['Int']
  unmounting: Scalars['Int']
}

export type SveChannelDelayHourInput = {
  mounting: Scalars['Int']
  unmounting: Scalars['Int']
}

export type Tab = {
  __typename?: 'Tab'
  blank?: Maybe<Scalars['Boolean']>
  icon: Icon
  position: TabPosition
  title: Scalars['String']
  titleT?: Maybe<Array<ContentTranslation>>
  url: Scalars['String']
}

export type TabInput = {
  blank?: InputMaybe<Scalars['Boolean']>
  icon: IconInput
  position: TabPosition
  title: Scalars['String']
  titleT?: InputMaybe<Array<ContentTranslationInput>>
  url: Scalars['String']
}

export enum TabPosition {
  Below = 'below',
  Embed = 'embed',
}

export type Tag = {
  __typename?: 'Tag'
  createdAt: Scalars['Date']
  deletedAt?: Maybe<Scalars['Date']>
  id: Scalars['ID']
  subTags?: Maybe<Array<Scalars['String']>>
  /** @deprecated Use createdAt, updatedAt and deletedAt directly now. This resolver will be removed. */
  timestamps: Timestamps
  title: Scalars['String']
  updatedAt: Scalars['Date']
}

export type TagInput = {
  id?: InputMaybe<Scalars['ID']>
  subTags?: InputMaybe<Array<Scalars['String']>>
  title: Scalars['String']
}

export type ThirdPartyApplication = {
  __typename?: 'ThirdPartyApplication'
  description: Scalars['String']
  id: Scalars['ID']
  keys: Array<ApplicationKey>
  name: Scalars['String']
  organism?: Maybe<Organism>
  organismId?: Maybe<Scalars['ID']>
}

export type ThirdPartyApplicationPageResult = {
  __typename?: 'ThirdPartyApplicationPageResult'
  thirdPartyApplications: Array<ThirdPartyApplication>
  totalCount?: Maybe<Scalars['Int']>
}

export type ThirdPartyApplicationsFilters = {
  organismId?: InputMaybe<Scalars['ID']>
}

export type TimeSlots = {
  __typename?: 'TimeSlots'
  from: Scalars['String']
  to: Scalars['String']
}

export type TimeSlotsInput = {
  from: Scalars['String']
  to: Scalars['String']
}

export type Timestamps = {
  __typename?: 'Timestamps'
  createdAt: Scalars['Date']
  deletedAt?: Maybe<Scalars['Date']>
  updatedAt: Scalars['Date']
}

export type UpdateAdCampaignInput = {
  affiliates: Array<AffiliateInput>
  displayedItemsCountMax: Scalars['Int']
  id: Scalars['ID']
  organismId: Scalars['ID']
  prerollConfigs: Array<PrerollConfigInput>
  publicationStatus: PublicationStatus
  title: Scalars['String']
}

export type UpdateApplicationDesignInput = {
  description?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  organismId: Scalars['ID']
  theme?: InputMaybe<ApplicationDesignThemeInput>
  title?: InputMaybe<Scalars['String']>
}

export type UpdateApplicationInput = {
  appleSharedSecret?: InputMaybe<Scalars['String']>
  applicationId?: InputMaybe<Scalars['String']>
  cac?: InputMaybe<ContentAccessControlInput>
  forgotPasswordCallbackUrl?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  loginCallbackUrl?: InputMaybe<Scalars['String']>
  organismId?: InputMaybe<Scalars['ID']>
  packageName?: InputMaybe<Scalars['String']>
  publicationStatus?: InputMaybe<PublicationStatus>
  resetPasswordCallbackUrl?: InputMaybe<Scalars['String']>
  sponsorImageFileId?: InputMaybe<Scalars['ID']>
  sponsoringUrl?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  type?: InputMaybe<ApplicationType>
  url?: InputMaybe<Scalars['String']>
  verifyCallbackUrl?: InputMaybe<Scalars['String']>
}

export type UpdateCategoryInput = {
  id: Scalars['ID']
  title: Scalars['String']
}

export type UpdateChatMessageInput = {
  id: Scalars['ID']
}

export type UpdateContentPushStreamInput = {
  contentDeliveryServiceConfig: ContentDeliveryServiceConfigInput
  id: Scalars['ID']
  name: Scalars['String']
}

export type UpdateCustomPageInput = {
  backgroundImageId?: InputMaybe<Scalars['ID']>
  backgroundVideoId?: InputMaybe<Scalars['ID']>
  customPageApplicationDesignId?: InputMaybe<Scalars['ID']>
  externalWidgets?: InputMaybe<ExternalWidgetsInput>
  frontPageId?: InputMaybe<Scalars['ID']>
  iconId?: InputMaybe<Scalars['ID']>
  id: Scalars['ID']
  menuBannerImageId?: InputMaybe<Scalars['ID']>
  position?: InputMaybe<Scalars['Int']>
  published?: InputMaybe<PublicationStatus>
  showInNavigationHeader?: InputMaybe<Scalars['Boolean']>
  title?: InputMaybe<Scalars['String']>
  titleT?: InputMaybe<Array<ContentTranslationInput>>
  widgets?: InputMaybe<Array<CustomPageWidgetInput>>
  widgetsType?: InputMaybe<WidgetsType>
}

export type UpdateDefaultConfigInput = {
  androidMinVersion?: InputMaybe<Scalars['String']>
  appleMinVersion?: InputMaybe<Scalars['String']>
  clipDefaultTemplate?: InputMaybe<Scalars['WildmokaID']>
  emailingConfig?: InputMaybe<EmailingConfigInput>
  liveDefaultTemplate?: InputMaybe<Scalars['WildmokaID']>
  liveStreamDefaultDuration?: InputMaybe<Scalars['Int']>
  sportItemDefaultCover?: InputMaybe<Scalars['ID']>
  sveChannelDelayHour?: InputMaybe<SveChannelDelayHourInput>
  videoGridUrl?: InputMaybe<Scalars['String']>
}

export type UpdateEditedCategoryInput = {
  items?: InputMaybe<Array<EditedCategoryItemInput>>
  position?: InputMaybe<Scalars['Int']>
  published?: InputMaybe<PublicationStatus>
  title?: InputMaybe<Scalars['String']>
  titleT?: InputMaybe<Array<ContentTranslationInput>>
}

export type UpdateFanPreferencesInput = {
  followedOrganismsIds?: InputMaybe<Array<Scalars['ID']>>
}

export type UpdateFrontPageInput = {
  applicationId?: InputMaybe<Scalars['ID']>
  coverImageId?: InputMaybe<Scalars['ID']>
  coverVideoId?: InputMaybe<Scalars['ID']>
  description?: InputMaybe<Scalars['String']>
  end?: InputMaybe<Scalars['Date']>
  position?: InputMaybe<Scalars['Int']>
  publicationStatus?: InputMaybe<PublicationStatus>
  publishInTop?: InputMaybe<Scalars['Boolean']>
  redirectsAutomatically?: InputMaybe<Scalars['Boolean']>
  sportItemId?: InputMaybe<Scalars['ID']>
  sportItemSetId?: InputMaybe<Scalars['ID']>
  start?: InputMaybe<Scalars['Date']>
  tags?: InputMaybe<Array<Scalars['String']>>
  title?: InputMaybe<Scalars['String']>
  titleImageId?: InputMaybe<Scalars['ID']>
}

export type UpdateLegalRestrictionInput = {
  active?: InputMaybe<Scalars['Boolean']>
  countries?: InputMaybe<Array<Scalars['String']>>
  description?: InputMaybe<Scalars['String']>
  mature?: InputMaybe<MatureFilter>
  offerIds?: InputMaybe<Array<Scalars['ID']>>
  organismId?: InputMaybe<Scalars['ID']>
  restrictionTarget?: InputMaybe<RestrictionTargetFilter>
  sportItemIds?: InputMaybe<Array<Scalars['ID']>>
  sportItemSetIds?: InputMaybe<Array<Scalars['ID']>>
  timeSlots?: InputMaybe<TimeSlotsInput>
  type?: InputMaybe<RestrictionType>
}

export type UpdateLinkInput = {
  billboardId?: InputMaybe<Scalars['ID']>
  categoriesIds: Array<Scalars['ID']>
  countries: Array<InputMaybe<Scalars['String']>>
  description?: InputMaybe<Scalars['String']>
  descriptionT?: InputMaybe<Array<ContentTranslationInput>>
  gamTagUrl?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  imageId?: InputMaybe<Scalars['ID']>
  ownerOrganismIds: Array<Scalars['ID']>
  publicationStatus: PublicationStatus
  subtitle?: InputMaybe<Scalars['String']>
  subtitleT?: InputMaybe<Array<ContentTranslationInput>>
  teaserVideoId?: InputMaybe<Scalars['ID']>
  title: Scalars['String']
  titleT?: InputMaybe<Array<ContentTranslationInput>>
  url?: InputMaybe<Scalars['String']>
}

export type UpdateMeInput = {
  birthdayDate?: InputMaybe<Scalars['Date']>
  cguAccepted?: InputMaybe<Scalars['Date']>
  chatAuthorName?: InputMaybe<Scalars['String']>
  firstName?: InputMaybe<Scalars['String']>
  followedOrganismsIds?: InputMaybe<Array<Scalars['ID']>>
  gender?: InputMaybe<Gender>
  lastName?: InputMaybe<Scalars['String']>
  locale?: InputMaybe<Scalars['String']>
  location?: InputMaybe<LocationInput>
  personalDataShareAccepted?: InputMaybe<Scalars['Boolean']>
  stripePaymentMethodId?: InputMaybe<Scalars['String']>
}

export type UpdateMediaInput = {
  date?: InputMaybe<Scalars['Date']>
  endTrim?: InputMaybe<Scalars['Float']>
  ownerOrganismId?: InputMaybe<Scalars['ID']>
  publicationStatus?: InputMaybe<PublicationStatus>
  startTrim?: InputMaybe<Scalars['Float']>
  tags?: InputMaybe<Array<MediaTags>>
  thumbnailId?: InputMaybe<Scalars['ID']>
  timelineMarkers?: InputMaybe<Array<MarkerInput>>
  title?: InputMaybe<Scalars['String']>
}

export type UpdateMyPasswordInput = {
  newPassword: Scalars['String']
  oldPassword?: InputMaybe<Scalars['String']>
}

export type UpdateNewsInput = {
  content: Scalars['String']
  contentT?: InputMaybe<Array<ContentTranslationInput>>
  coverImageId: Scalars['ID']
  description: Scalars['String']
  descriptionT?: InputMaybe<Array<ContentTranslationInput>>
  id: Scalars['ID']
  organismId: Scalars['String']
  publicationStatus: PublicationStatus
  rawContent: Scalars['String']
  rawContentT?: InputMaybe<Array<ContentTranslationInput>>
  subtitle: Scalars['String']
  subtitleT?: InputMaybe<Array<ContentTranslationInput>>
  title: Scalars['String']
  titleT?: InputMaybe<Array<ContentTranslationInput>>
}

export type UpdateOrganismInput = {
  adminBadgeId?: InputMaybe<Scalars['ID']>
  adminsAutomatedEmails?: InputMaybe<Scalars['Boolean']>
  availableExternalSources?: InputMaybe<Array<WidgetExternalContentSource>>
  availableWidgetNames?: InputMaybe<Array<CustomPageWidgetName>>
  bannerImageFileId?: InputMaybe<Scalars['ID']>
  bannerVideoId?: InputMaybe<Scalars['ID']>
  contentPageApplicationDesignId?: InputMaybe<Scalars['ID']>
  description?: InputMaybe<Scalars['String']>
  descriptionT?: InputMaybe<Array<ContentTranslationInput>>
  enableExternalSources?: InputMaybe<Scalars['Boolean']>
  forbiddenKeyWords?: InputMaybe<Array<Scalars['String']>>
  frontPosition?: InputMaybe<Scalars['Int']>
  id: Scalars['ID']
  license?: InputMaybe<OrganismLicenseInput>
  logo?: InputMaybe<LogoCreateUpdateInput>
  officialWebSiteUrl?: InputMaybe<Scalars['URL']>
  onFront?: InputMaybe<Scalars['Boolean']>
  organismPageApplicationDesignId?: InputMaybe<Scalars['ID']>
  pageWidgets?: InputMaybe<Array<CustomPageWidgetInput>>
  publicationStatus?: InputMaybe<PublicationStatus>
  sportIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  streamsIds?: InputMaybe<Array<Scalars['WildmokaID']>>
  sveChannelIds?: InputMaybe<Array<Scalars['ID']>>
  tags?: InputMaybe<Array<TagInput>>
  title?: InputMaybe<Scalars['String']>
  titleT?: InputMaybe<Array<ContentTranslationInput>>
  type?: InputMaybe<OrganismType>
  visibleInMultisportApplication?: InputMaybe<Scalars['Boolean']>
  whiteLabel?: InputMaybe<Scalars['Boolean']>
  wmProfileId?: InputMaybe<Scalars['WildmokaID']>
}

export type UpdateOutputStreamsInput = {
  outputStreams: Array<InputMaybe<OutputStreamInput>>
}

export type UpdatePlaybackStateInput = {
  mediaId: Scalars['ID']
  percentProgress?: InputMaybe<Scalars['Float']>
  progression?: InputMaybe<Scalars['Float']>
  sportItemId: Scalars['ID']
  viewedDuration?: InputMaybe<Scalars['Float']>
}

export type UpdateProductInput = {
  licensedUserOnly?: InputMaybe<Scalars['Boolean']>
  period?: InputMaybe<Period>
  platform?: InputMaybe<Platform>
  premiumOf?: InputMaybe<Array<Scalars['String']>>
  prices?: InputMaybe<Array<PriceInput>>
  productId?: InputMaybe<Scalars['String']>
  publicationStatus?: InputMaybe<PublicationStatus>
  purchasableOfferId?: InputMaybe<Scalars['ID']>
}

export type UpdatePurchasableOfferInput = {
  bannerImageId?: InputMaybe<Scalars['ID']>
  delay?: InputMaybe<Scalars['Int']>
  description?: InputMaybe<Array<OfferDescriptionInput>>
  descriptionT?: InputMaybe<Array<ContentTranslationDescriptionPurchasableOfferInput>>
  expiredAt?: InputMaybe<Scalars['Date']>
  freeWithLicense?: InputMaybe<Scalars['Boolean']>
  mode?: InputMaybe<OfferMode>
  position?: InputMaybe<Scalars['Int']>
  products?: InputMaybe<Array<ProductInput>>
  publicationStatus?: InputMaybe<PublicationStatus>
  thumbnailImageId?: InputMaybe<Scalars['ID']>
  title?: InputMaybe<Scalars['String']>
  titleT?: InputMaybe<Array<ContentTranslationInput>>
  url?: InputMaybe<Scalars['String']>
}

export type UpdatePurchasableReceiptInput = {
  apple?: InputMaybe<AppleSubscriptionReceiptInput>
  google?: InputMaybe<GoogleSubscriptionReceiptInput>
  offerId?: InputMaybe<Scalars['ID']>
  productId?: InputMaybe<Scalars['String']>
}

export type UpdatePurchasableReceiptResult = {
  __typename?: 'UpdatePurchasableReceiptResult'
  error?: Maybe<Scalars['String']>
  purchase?: Maybe<UserPurchase>
  success: Scalars['Boolean']
}

export type UpdateSveChannelInput = {
  channelId: Scalars['ID']
  name?: InputMaybe<Scalars['String']>
}

export type UpdateSportInput = {
  bannerImageFileId?: InputMaybe<Scalars['ID']>
  bannerVideoId?: InputMaybe<Scalars['ID']>
  cardImageFileId?: InputMaybe<Scalars['ID']>
  color?: InputMaybe<Scalars['String']>
  frontPosition?: InputMaybe<Scalars['Int']>
  iconImageFileId?: InputMaybe<Scalars['ID']>
  id: Scalars['ID']
  onFront?: InputMaybe<Scalars['Boolean']>
  title?: InputMaybe<Scalars['String']>
  titleT?: InputMaybe<Array<ContentTranslationInput>>
}

export type UpdateSportItemInput = {
  cac?: InputMaybe<ContentAccessControlInput>
  chatActivated?: InputMaybe<Scalars['Boolean']>
  contentPushServiceNamesExcluded?: InputMaybe<Array<ContentPushStreamServiceName>>
  contentType?: InputMaybe<SportItemContentType>
  coverImageFileId?: InputMaybe<Scalars['ID']>
  description?: InputMaybe<Scalars['String']>
  descriptionT?: InputMaybe<Array<ContentTranslationInput>>
  id: Scalars['ID']
  matureContent?: InputMaybe<Scalars['Boolean']>
  mediasIds?: InputMaybe<Array<Scalars['ID']>>
  modeRadio?: InputMaybe<Scalars['Boolean']>
  playlistPosition?: InputMaybe<Scalars['Int']>
  publicationStatus?: InputMaybe<PublicationStatus>
  reverseRestriction?: InputMaybe<Scalars['Boolean']>
  sportEventItem?: InputMaybe<SportEventItemUpdateInput>
  subtitle?: InputMaybe<Scalars['String']>
  subtitleT?: InputMaybe<Array<ContentTranslationInput>>
  tabs?: InputMaybe<Array<TabInput>>
  title?: InputMaybe<Scalars['String']>
  titleT?: InputMaybe<Array<ContentTranslationInput>>
  type?: InputMaybe<SportItemType>
  videoContentType?: InputMaybe<VideoContentType>
}

export type UpdateSportItemSetInput = {
  activateGreenPlayback?: InputMaybe<Scalars['Boolean']>
  activateSportItemChatByDefault: Scalars['Boolean']
  adCampaignId?: InputMaybe<Scalars['ID']>
  bannerImageFileId?: InputMaybe<Scalars['ID']>
  bannerVideoId?: InputMaybe<Scalars['ID']>
  cac?: InputMaybe<ContentAccessControlInput>
  categoriesIds?: InputMaybe<Array<Scalars['ID']>>
  contentPushServiceGenericStreamIds?: InputMaybe<Array<Scalars['ID']>>
  contentPushServiceLiveEventIds?: InputMaybe<Array<Scalars['ID']>>
  contentPushServiceNames?: InputMaybe<Array<ContentPushStreamServiceName>>
  description?: InputMaybe<Scalars['String']>
  descriptionT?: InputMaybe<Array<ContentTranslationInput>>
  disableReadParallelLivestreamsLimit?: InputMaybe<Scalars['Boolean']>
  ecmSessionId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  lockPublishingClip?: InputMaybe<Scalars['Boolean']>
  lockPublishingLive?: InputMaybe<Scalars['Boolean']>
  noSpoilerMode?: InputMaybe<NoSpoilerModeInput>
  offerIds?: InputMaybe<Array<Scalars['ID']>>
  ownerOrganismId?: InputMaybe<Scalars['ID']>
  publicationStatus?: InputMaybe<PublicationStatus>
  restrictionLevel?: InputMaybe<PremiumAccess>
  sponsorOrganismIds?: InputMaybe<Array<Scalars['ID']>>
  sponsorPlatform?: InputMaybe<SponsorPlatformInput>
  sportEvent?: InputMaybe<SportEventUpdateInput>
  sportIds?: InputMaybe<Array<Scalars['ID']>>
  streamsIds?: InputMaybe<Array<Scalars['WildmokaID']>>
  subTags?: InputMaybe<Array<Scalars['String']>>
  subtitle?: InputMaybe<Scalars['String']>
  subtitleT?: InputMaybe<Array<ContentTranslationInput>>
  sveInputLossImageMediaId?: InputMaybe<Scalars['ID']>
  sveOverlayLogoImageMediaId?: InputMaybe<Scalars['ID']>
  sveStreams?: InputMaybe<Array<SveStreamInput>>
  tabs?: InputMaybe<Array<TabInput>>
  tagId?: InputMaybe<Scalars['ID']>
  testPeriodEnd?: InputMaybe<Scalars['Date']>
  title?: InputMaybe<Scalars['String']>
  titleT?: InputMaybe<Array<ContentTranslationInput>>
  type?: InputMaybe<SportItemSetType>
  visibleInMultisportApplication?: InputMaybe<Scalars['Boolean']>
  websiteUrl?: InputMaybe<Scalars['URL']>
  wmClippingTemplateId?: InputMaybe<Scalars['WildmokaID']>
}

export type UpdateThirdApplicationKeyInput = {
  enabled: Scalars['Boolean']
  expirationDate: Scalars['Date']
  id?: InputMaybe<Scalars['ID']>
  key: Scalars['String']
  name: Scalars['String']
  type: ApplicationKeyType
}

export type UpdateThirdPartyApplicationInput = {
  description?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  keys?: InputMaybe<Array<UpdateThirdApplicationKeyInput>>
  name?: InputMaybe<Scalars['String']>
  organismId?: InputMaybe<Scalars['ID']>
}

export type UpdateUserInput = {
  birthdayDate?: InputMaybe<Scalars['Date']>
  chatAuthorName: Scalars['String']
  email?: InputMaybe<Scalars['String']>
  firstName?: InputMaybe<Scalars['String']>
  gender?: InputMaybe<Gender>
  id: Scalars['ID']
  lastName?: InputMaybe<Scalars['String']>
  locale?: InputMaybe<Scalars['String']>
  location?: InputMaybe<LocationInput>
  notifiedAt?: InputMaybe<Scalars['Date']>
  organismIds?: InputMaybe<Array<Scalars['ID']>>
  password?: InputMaybe<Scalars['String']>
  resetDate?: InputMaybe<Scalars['Date']>
  resetToken?: InputMaybe<Scalars['String']>
  rights?: InputMaybe<UserRightInput>
  roleIds?: InputMaybe<Array<UserRoleId>>
  stripePaymentMethodId?: InputMaybe<Scalars['String']>
}

export type UpdateWildmokaLiveStreamInput = {
  autoAnnounceDate?: InputMaybe<Scalars['Date']>
  createClipAtEndOfEvent?: InputMaybe<Scalars['Boolean']>
  description?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  name?: InputMaybe<Scalars['String']>
  notificationEmail?: InputMaybe<Scalars['String']>
  passthrough?: InputMaybe<Scalars['Boolean']>
  stream?: InputMaybe<Scalars['WildmokaID']>
  templateClip?: InputMaybe<Scalars['WildmokaID']>
  templateLive?: InputMaybe<Scalars['WildmokaID']>
}

export type UploadPartInput = {
  chunk: Scalars['Upload']
  hash: Scalars['String']
  key: Scalars['String']
  part: Scalars['Int']
  size: Scalars['Int']
  uploadId: Scalars['String']
}

export type UploadedPartInput = {
  ETag: Scalars['String']
  PartNumber: Scalars['Int']
}

export type User = {
  __typename?: 'User'
  RGPDFormattedData: Scalars['String']
  accessibleContent: AccessibleContent
  allPermissions: UserRight
  applicationOrigin?: Maybe<Application>
  applicationOriginId?: Maybe<Scalars['ID']>
  avatarUrl?: Maybe<Scalars['URL']>
  birthdayDate?: Maybe<Scalars['Date']>
  /** @deprecated will be removed soon. Fan front apps should not display admin info */
  canShowAdminInfos: Scalars['Boolean']
  cguAccepted?: Maybe<Scalars['Date']>
  chatAuthorName: Scalars['String']
  chatBanning?: Maybe<ChatBanning>
  createdAt: Scalars['Date']
  deletedAt?: Maybe<Scalars['Date']>
  email: Scalars['String']
  environment: UserEnvironment
  fanPreferences?: Maybe<FanPreferences>
  firstName: Scalars['String']
  gender?: Maybe<Gender>
  id: Scalars['ID']
  lastActivity?: Maybe<Scalars['Date']>
  lastName: Scalars['String']
  licenses?: Maybe<Array<LicenseInfo>>
  locale?: Maybe<Scalars['String']>
  location?: Maybe<Location>
  notifiedAt?: Maybe<Scalars['Date']>
  organismIds?: Maybe<Array<Scalars['ID']>>
  organisms?: Maybe<Array<Organism>>
  personalDataShareAccepted?: Maybe<Scalars['Boolean']>
  /** @deprecated use playbackState query */
  playbackStates: Array<PlaybackState>
  purchasableReceipts?: Maybe<Array<UserPurchasableReceipt>>
  purchases: Array<UserPurchase>
  receipts: Array<UserReceipt>
  resetDate?: Maybe<Scalars['Date']>
  resetToken?: Maybe<Scalars['String']>
  rights?: Maybe<UserRight>
  roleIds: Array<UserRoleId>
  serviceAccount?: Maybe<Scalars['Boolean']>
  signUpOrigin?: Maybe<SignUpOrigin>
  stripeCustomerId?: Maybe<Scalars['String']>
  stripePaymentMethodId?: Maybe<Scalars['String']>
  /** @deprecated Use createdAt, updatedAt and deletedAt directly now. This resolver will be removed. */
  timestamps: Timestamps
  updatedAt: Scalars['Date']
  verified: Scalars['Boolean']
}

export enum UserEnvironment {
  Other = 'other',
}

export type UserFilters = {
  fans?: InputMaybe<Scalars['Boolean']>
  hasOffer?: InputMaybe<HasOfferFilter>
  licensedForOrganismId?: InputMaybe<Scalars['ID']>
  organismId?: InputMaybe<Scalars['ID']>
  roleIds?: InputMaybe<Array<UserRoleId>>
  search?: InputMaybe<Scalars['String']>
}

export enum UserLocationCondition {
  InArea = 'inArea',
  OutOfArea = 'outOfArea',
}

export type UserPageResult = {
  __typename?: 'UserPageResult'
  totalCount?: Maybe<Scalars['Int']>
  users: Array<User>
}

export type UserPurchasableReceipt = {
  __typename?: 'UserPurchasableReceipt'
  applicationId?: Maybe<Scalars['String']>
  canceledAt?: Maybe<Scalars['Date']>
  consumedAt?: Maybe<Scalars['Date']>
  expiredAt?: Maybe<Scalars['Date']>
  freeProductId?: Maybe<Scalars['String']>
  mode?: Maybe<PurchasableMode>
  offerId: Scalars['ID']
  receipt?: Maybe<Scalars['String']>
  receiptUrl?: Maybe<Scalars['String']>
  subscriptionId?: Maybe<Scalars['String']>
  token?: Maybe<Scalars['String']>
  type: SubscriptionType
}

export type UserPurchase = {
  __typename?: 'UserPurchase'
  fromLicense?: Maybe<Scalars['Boolean']>
  fromProductId?: Maybe<Scalars['String']>
  offer?: Maybe<PurchasableOffer>
  offerId: Scalars['ID']
}

export type UserReceipt = {
  __typename?: 'UserReceipt'
  applicationId?: Maybe<Scalars['String']>
  canceledAt?: Maybe<Scalars['Date']>
  consumedAt?: Maybe<Scalars['Date']>
  expiredAt?: Maybe<Scalars['Date']>
  mode?: Maybe<OfferMode>
  offer?: Maybe<PurchasableOffer>
  offerId: Scalars['ID']
  productId?: Maybe<Scalars['String']>
  receiptUrl?: Maybe<Scalars['String']>
  status: ReceiptStatus
  type: SubscriptionType
}

export type UserRight = {
  __typename?: 'UserRight'
  generalPermissions: Array<Permission>
  organismsPermissions: Array<OrganismPermissions>
}

export type UserRightInput = {
  generalPermissions: Array<Permission>
  organismsPermissions: Array<OrganismPermissionsInput>
}

export type UserRole = {
  __typename?: 'UserRole'
  description: Scalars['String']
  generalBasePermissions: Array<Permission>
  id: UserRoleId
  organismsBasePermissions: Array<Permission>
  shortTitle: Scalars['String']
  title: Scalars['String']
}

export enum UserRoleId {
  Admin = 'admin',
  Anonymous = 'anonymous',
  Fan = 'fan',
  OrganismAdministrator = 'organismAdministrator',
  OrganismEditor = 'organismEditor',
  OrganismMember = 'organismMember',
  OrganismTester = 'organismTester',
  Procaster = 'procaster',
  SuperAdmin = 'superAdmin',
  Vip = 'vip',
  Wildmoka = 'wildmoka',
}

export enum UsersExportFormat {
  Csv = 'CSV',
  Json = 'JSON',
}

export type UsersExportInput = {
  filters?: InputMaybe<UserFilters>
  view: UsersExportView
}

export enum UsersExportState {
  Idle = 'idle',
  Running = 'running',
  Scheduled = 'scheduled',
}

export enum UsersExportView {
  Admin = 'Admin',
  Fan = 'Fan',
}

export type VipRegistrationResult = {
  __typename?: 'VIPRegistrationResult'
  message: Scalars['String']
  success: Scalars['Boolean']
}

export type VideoClip = {
  __typename?: 'VideoClip'
  awsJobId?: Maybe<Scalars['String']>
  captions?: Maybe<Array<Caption>>
  createdAt: Scalars['Date']
  deletedAt?: Maybe<Scalars['Date']>
  downloadLink: Scalars['URL']
  /** @deprecated Use downloadLink, to remove when app release force version (April 2024) */
  downloadUrl: Scalars['URL']
  error?: Maybe<VideoClipError>
  externalFeed?: Maybe<ExternalFeed>
  fileId?: Maybe<Scalars['String']>
  id: Scalars['ID']
  playbackUrl: Scalars['URL']
  status?: Maybe<VideoClipStatus>
  /** @deprecated Use createdAt, updatedAt and deletedAt directly now. This resolver will be removed. */
  timestamps: Timestamps
  updatedAt: Scalars['Date']
}

export type VideoClipError = {
  __typename?: 'VideoClipError'
  errorDetails: Scalars['String']
  errorMessage: Scalars['String']
  guid: Scalars['String']
  workflowErrorAt: Scalars['String']
  workflowStatus: Scalars['String']
}

export type VideoClipPageResult = {
  __typename?: 'VideoClipPageResult'
  totalCount?: Maybe<Scalars['Int']>
  videoClips: Array<VideoClip>
}

export enum VideoClipStatus {
  Available = 'available',
  Encoding = 'encoding',
  Error = 'error',
  Uploaded = 'uploaded',
}

export enum VideoContentType {
  Live = 'live',
  Replay = 'replay',
  Vod = 'vod',
}

export enum VideoEngine {
  ExternalFeed = 'externalFeed',
  Sportall = 'sportall',
  Wildmoka = 'wildmoka',
}

export type WecNewsContent = {
  __typename?: 'WECNewsContent'
  carECMIds?: Maybe<Array<Scalars['String']>>
  categoryECMIds?: Maybe<Array<Scalars['String']>>
  driverECMIds?: Maybe<Array<Scalars['String']>>
  raceECMIds?: Maybe<Array<Scalars['String']>>
  teamECMIds?: Maybe<Array<Scalars['String']>>
}

export type WecNewsContentInput = {
  carECMIds?: InputMaybe<Array<Scalars['String']>>
  categoryECMIds?: InputMaybe<Array<Scalars['String']>>
  driverECMIds?: InputMaybe<Array<Scalars['String']>>
  raceECMIds?: InputMaybe<Array<Scalars['String']>>
  teamECMIds?: InputMaybe<Array<Scalars['String']>>
}

export enum WmConnectionStatus {
  Connected = 'connected',
  Disconnected = 'disconnected',
}

export type WmEvent = {
  __typename?: 'WMEvent'
  clipCreationAtEnd: Scalars['Boolean']
  clipPublicationAtEnd: Scalars['Boolean']
  clipTemplate?: Maybe<WmEventTemplate>
  description?: Maybe<Scalars['String']>
  editoStartDate: Scalars['Date']
  editoStopDate: Scalars['Date']
  exposition?: Maybe<WmEventExposition>
  id: Scalars['WildmokaID']
  live?: Maybe<WmEventLive>
  livePublication: Scalars['Boolean']
  name: Scalars['String']
  notifEmail?: Maybe<Scalars['String']>
  profiles?: Maybe<Array<WmProfile>>
  state: WmEventState
  stopDate: Scalars['Date']
  stream: WmEventStream
  tags?: Maybe<Array<Scalars['String']>>
  testStartDate: Scalars['Date']
  thumbnail?: Maybe<WmEventThumbnail>
}

export type WmEventDestination = {
  __typename?: 'WMEventDestination'
  id: Scalars['WildmokaID']
  name: Scalars['String']
  type: Scalars['String']
}

export type WmEventExposition = {
  __typename?: 'WMEventExposition'
  date?: Maybe<Scalars['Date']>
  duration?: Maybe<Scalars['Int']>
}

export type WmEventLive = {
  __typename?: 'WMEventLive'
  autoAnnounceDate?: Maybe<Scalars['Date']>
  autoLifecycle?: Maybe<Scalars['Boolean']>
  autoSyncEdito?: Maybe<Scalars['Boolean']>
  id: Scalars['WildmokaID']
  publishOn?: Maybe<Array<WmEventDestination>>
  publishStatus: WmLiveState
  startEditoManual?: Maybe<Scalars['Boolean']>
  stopEditoManual?: Maybe<Scalars['Boolean']>
  template: WmEventTemplate
  type: WmLiveType
}

export enum WmEventState {
  Ended = 'ended',
  Pending = 'pending',
  Started = 'started',
  Stopped = 'stopped',
  Testing = 'testing',
}

export type WmEventStream = {
  __typename?: 'WMEventStream'
  id: Scalars['WildmokaID']
  name: Scalars['String']
  tags: Array<Scalars['String']>
}

export type WmEventTemplate = {
  __typename?: 'WMEventTemplate'
  id: Scalars['WildmokaID']
  name: Scalars['String']
  type: WmTemplateType
}

export type WmEventThumbnail = {
  __typename?: 'WMEventThumbnail'
  clipId?: Maybe<Scalars['WildmokaID']>
  url?: Maybe<Scalars['String']>
}

export enum WmIngestionStatus {
  Inactive = 'inactive',
  Ingesting = 'ingesting',
  Stopped = 'stopped',
  Unknown = 'unknown',
  Waiting = 'waiting',
}

export type WmLive = {
  __typename?: 'WMLive'
  autoAnounce: Scalars['String']
  id: Scalars['WildmokaID']
  profiles?: Maybe<Array<Scalars['Int']>>
  state: WmLiveState
  streamId: Scalars['WildmokaID']
  template?: Maybe<Scalars['ID']>
  title: Scalars['String']
  type: WmLiveType
}

export enum WmLiveState {
  Pending = 'pending',
  Started = 'started',
  Stopped = 'stopped',
  Testing = 'testing',
}

export enum WmLiveType {
  EditableManyToMany = 'editable_many_to_many',
  EditableOneToMany = 'editable_one_to_many',
  Passthrough = 'passthrough',
}

export type WmProfile = {
  __typename?: 'WMProfile'
  id: Scalars['WildmokaID']
  name: Scalars['String']
  tags: Array<Scalars['String']>
}

export type WmStream = {
  __typename?: 'WMStream'
  active: Scalars['Boolean']
  activeSince?: Maybe<Scalars['Int']>
  connectionStatuses?: Maybe<Array<WmStreamConnectionStatus>>
  id: Scalars['WildmokaID']
  ingestionStatus: WmIngestionStatus
  inputUrl: Scalars['String']
  map?: Maybe<StreamMap>
  name: Scalars['String']
  outputUrl: Scalars['String']
  tags?: Maybe<Array<Scalars['String']>>
}

export type WmStreamConnectionStatus = {
  __typename?: 'WMStreamConnectionStatus'
  description: WmStreamConnectionStatusType
  inputStatus?: Maybe<WmStreamInputStatus>
  status: WmConnectionStatus
  uri: Scalars['String']
}

export enum WmStreamConnectionStatusType {
  Backup = 'backup',
  Main = 'main',
}

export enum WmStreamInputStatus {
  Error = 'error',
}

export type WmStreamLight = {
  __typename?: 'WMStreamLight'
  id: Scalars['WildmokaID']
  map?: Maybe<StreamMap>
  name: Scalars['String']
  outputUrl: Scalars['String']
  state: WmStreamState
  status: WmStreamStatus
  tags?: Maybe<Array<Scalars['String']>>
}

export enum WmStreamState {
  Started = 'started',
  Stopped = 'stopped',
}

export enum WmStreamStatus {
  Error = 'error',
  Inactive = 'inactive',
  Ingesting = 'ingesting',
  Ok = 'ok',
  Waiting = 'waiting',
}

export type WmTemplate = {
  __typename?: 'WMTemplate'
  id: Scalars['WildmokaID']
  name: Scalars['String']
  profiles: Array<WmProfile>
  type: WmTemplateType
}

export enum WmTemplateType {
  Animation = 'animation',
  Image = 'image',
  Live = 'live',
  Video = 'video',
}

export type WmWebhook = {
  __typename?: 'WMWebhook'
  endpointUrl: Scalars['String']
  eventSubscriptionName: Scalars['String']
  eventType?: Maybe<Array<WmWebhookEventType>>
  subject: Scalars['String']
}

export enum WmWebhookEventType {
  All = 'all',
  Create = 'create',
  Delete = 'delete',
}

export enum WidgetExternalContentSource {
  LeMansNews = 'LeMansNews',
  RssFeed = 'RSSFeed',
  WecNews = 'WECNews',
}

export enum WidgetsType {
  External = 'external',
  ExternalIframe = 'externalIframe',
  ExternalLink = 'externalLink',
  Internal = 'internal',
}

export type WriteStreamMapInput = {
  channelArn: Scalars['String']
  playbackUrl: Scalars['String']
  streamId: Scalars['WildmokaID']
}

export enum FavoriteFilter {
  All = 'all',
  Events = 'events',
  Organisms = 'organisms',
  Sports = 'sports',
}

export type PinnedChatMessageInput = {
  chatRoomId: Scalars['ID']
  id: Scalars['ID']
}

export const AdCampaignContentFragmentDoc = gql`
  fragment adCampaignContent on AdCampaign {
    publicationStatus
    prerollConfigs {
      videoType
      adDisplayMaxCount
      billboardDisplayMaxCount
    }
    organismId
    displayedItemsCountMax
    affiliates {
      linkId
      displayWeight
      displayTypes
      type
    }
    title
    id
  }
`
export const ApplicationContentFragmentDoc = gql`
  fragment applicationContent on Application {
    id
    title
    applicationId
    type
    url
    sponsoringUrl
    sponsorImageFileId
    organismId
    organism {
      whiteLabel
      id
      title
    }
    cac {
      userAuthenticationRule
    }
    publicationStatus
    excludedContent {
      sportItemSetIds
      sportItemSets {
        id
        title
      }
      sportItemIds
      sportItems {
        id
        title
      }
    }
    packageName
    appleSharedSecret
  }
`
export const ApplicationDesignContentFragmentDoc = gql`
  fragment applicationDesignContent on ApplicationDesign {
    id
    title
    description
    organismId
    theme {
      primaryColor
      onPrimaryColor
      surfaceColor
      onSurfaceColor
      onSurfaceAlternateColor
      top10Color
      top10BorderColor
    }
  }
`
export const ContentPushStreamContentFragmentDoc = gql`
  fragment contentPushStreamContent on ContentPushStream {
    id
    name
    contentDeliveryServiceConfig {
      type
      periodicity {
        frequency
        firstSendDate
      }
      destination {
        bucketName
        region
        bucketPath
        accessKey
        secretKey
        endpointUrl
      }
      retentionDurationDays
    }
    downloadExportUrl
    contentDelivery {
      type
      status
      statusDetails
    }
  }
`
export const OutputStreamContentFragmentDoc = gql`
  fragment outputStreamContent on OutputStream {
    id
    name
    outputUrl
  }
`
export const ContentTranslationContentFragmentDoc = gql`
  fragment contentTranslationContent on ContentTranslation {
    locale
    content
  }
`
export const CustomPageWidgetFragmentDoc = gql`
  fragment customPageWidget on CustomPageWidget {
    name
    position
    display
    contentDisplay
    cardsDisplay
    options {
      editedCategoryId
      sportItemSetType
      replays {
        nameT {
          ...contentTranslationContent
        }
        defaultLocale
        maxReplays
        depthInDays
      }
      externalContent {
        name
        nameT {
          locale
          content
        }
        defaultLocale
        maxItemsCount
        period {
          startDate
          endDate
        }
        source
        wecContent {
          teamECMIds
          driverECMIds
          carECMIds
          categoryECMIds
          raceECMIds
        }
        rssContent {
          feedUrl
        }
      }
    }
  }
  ${ContentTranslationContentFragmentDoc}
`
export const CustomPageContentFragmentDoc = gql`
  fragment customPageContent on CustomPage {
    id
    title
    titleT {
      ...contentTranslationContent
    }
    defaultLocale
    iconId
    isHomePage
    published
    widgetsType
    widgets {
      ...customPageWidget
    }
    externalWidgets {
      liveScoreAccount
      iframeUrl
      linkUrl
    }
    applicationId
    position
    backgroundVideoId
    backgroundImageId
    menuBannerImageId
    showInNavigationHeader
    customPageApplicationDesignId
    frontPageId
  }
  ${ContentTranslationContentFragmentDoc}
  ${CustomPageWidgetFragmentDoc}
`
export const DefaultConfigContentFragmentDoc = gql`
  fragment defaultConfigContent on DefaultConfig {
    sportItemDefaultCover
    liveStreamDefaultDuration
    liveDefaultTemplate
    clipDefaultTemplate
    androidMinVersion
    appleMinVersion
    sveChannelDelayHour {
      mounting
      unmounting
    }
    emailingConfig {
      startliveStream
      startliveStreamTest
      endliveStream
    }
    videoGridUrl
  }
`
export const EditedCategoryContentFragmentDoc = gql`
  fragment editedCategoryContent on EditedCategory {
    id
    organismId
    title
    titleT {
      ...contentTranslationContent
    }
    defaultLocale
    published
    position
    items {
      sportItemId
      sportItemSetId
      newsId
      linkId
    }
  }
  ${ContentTranslationContentFragmentDoc}
`
export const FrontPageContentFragmentDoc = gql`
  fragment frontPageContent on FrontPage {
    id
    start
    end
    state
    sportItemSetId
    sportItemSet {
      id
      title
      type
      ownerOrganism {
        id
        title
      }
    }
    sportItemId
    sportItem {
      id
      title
    }
    coverImageId
    coverImage {
      id
      image {
        id
        thumbUrl
        downloadUrl
      }
    }
    coverVideoId
    coverVideo {
      id
      videoClip {
        id
        playbackUrl
      }
    }
    titleImageId
    titleImage {
      id
      image {
        id
        thumbUrl
        downloadUrl
      }
    }
    redirectsAutomatically
    title
    description
    tags
    publicationStatus
    position
  }
`
export const SportItemSetFrontPageContentFragmentDoc = gql`
  fragment sportItemSetFrontPageContent on SportItemSet {
    id
    type
    title
    ownerOrganismId
    ownerOrganism {
      id
      title
    }
    frontPages(withDeleted: true) {
      id
      start
      end
    }
    onFront
    timeOnFront
  }
`
export const LegalRestrictionContentFragmentDoc = gql`
  fragment legalRestrictionContent on LegalRestriction {
    id
    active
    countries
    mature
    organismId
    organism {
      id
      title
    }
    sportItemSetIds
    sportItemSets {
      id
      title
      ownerOrganismId
    }
    offers {
      id
      title
    }
    offerIds
    restrictionTarget
    sportItemIds
    type
    description
    timeSlots {
      from
      to
    }
  }
`
export const LiveStreamStreamConflictContentFragmentDoc = gql`
  fragment liveStreamStreamConflictContent on LiveStreamStreamConflict {
    liveStream {
      id
      sportItem {
        id
        title
        medias {
          id
          title
          liveStreamId
        }
      }
    }
  }
`
export const NewsFragmentDoc = gql`
  fragment news on News {
    __typename
    id
    organismId
    organism {
      id
      type
      title
      description
      officialWebSiteUrl
    }
    defaultLocale
    title
    titleT {
      ...contentTranslationContent
    }
    subtitle
    subtitleT {
      ...contentTranslationContent
    }
    description
    descriptionT {
      ...contentTranslationContent
    }
    content
    contentT {
      ...contentTranslationContent
    }
    rawContent
    rawContentT {
      ...contentTranslationContent
    }
    publicationStatus
    coverImageId
    coverImage {
      id
      image {
        id
        thumbUrl
        downloadUrl
      }
    }
    createdAt
    updatedAt
  }
  ${ContentTranslationContentFragmentDoc}
`
export const SveBroadcastContentFragmentDoc = gql`
  fragment sveBroadcastContent on SVEBroadcast {
    id
    status
    channelId
    startDate
    clipStartDate
    stopDate
    scheduledStartDate
    scheduledClipStartDate
    scheduledStopDate
  }
`
export const SveLiveMonitoringContentFragmentDoc = gql`
  fragment sveLiveMonitoringContent on SVELiveMonitoring {
    alerts {
      audioNotDetected
      initialProbeTakingLonger
      videoNotDetected
      RTMPHasNoAudioVideo
      RTMPStreamNotFound
    }
    stats {
      networkIn
      droppedFrames
      inputVideoFrameRate
      outputAudioLevelLkfs
    }
  }
`
export const LiveStreamContentFragmentDoc = gql`
  fragment liveStreamContent on LiveStream {
    id
    playbackUrl(expirationInSeconds: 21600)
    ingestUrl
    wildmokaEventID
    videoEngine
    state
    streamId
    event {
      id
      name
      description
      testStartDate
      editoStartDate
      editoStopDate
      stopDate
      state
      notifEmail
      clipCreationAtEnd
      clipPublicationAtEnd
      stream {
        id
        name
        tags
      }
      live {
        id
        publishStatus
        type
        template {
          id
          name
          type
        }
        autoAnnounceDate
      }
      livePublication
      clipTemplate {
        id
        name
        type
      }
    }
    sveBroadcastId
    sveBroadcast {
      ...sveBroadcastContent
    }
    sveChannelId
    sveLiveMonitoring {
      ...sveLiveMonitoringContent
    }
    externalFeedProvider
    externalFeedUrl
  }
  ${SveBroadcastContentFragmentDoc}
  ${SveLiveMonitoringContentFragmentDoc}
`
export const VideoClipContentFragmentDoc = gql`
  fragment videoClipContent on VideoClip {
    id
    playbackUrl
    downloadLink
    status
    updatedAt
    createdAt
    fileId
    error {
      guid
      workflowStatus
      workflowErrorAt
      errorMessage
      errorDetails
    }
    captions {
      srcFileId
      language
      title
    }
    externalFeed {
      externalFeedProvider
      externalFeedUrl
    }
    createdAt
    updatedAt
  }
`
export const FileContentFragmentDoc = gql`
  fragment fileContent on File {
    id
    filename
    size
    mimetype
    downloadUrl
    thumbUrl
    createdAt
    metadata {
      title
      description
      aspect
      width
      height
      sourceFileId
    }
  }
`
export const MediaContentFragmentDoc = gql`
  fragment mediaContent on Media {
    id
    createdAt
    updatedAt
    title
    type
    publicationStatus
    tags
    date
    startTrim
    endTrim
    liveStreamId
    liveStream {
      ...liveStreamContent
    }
    videoClipId
    videoClip {
      ...videoClipContent
    }
    imageId
    image {
      ...fileContent
    }
    thumbnailId
    thumbnail {
      id
      image {
        ...fileContent
      }
    }
    ownerOrganismId
    pinned
    timelineMarkers {
      title
      time
      icon
    }
  }
  ${LiveStreamContentFragmentDoc}
  ${VideoClipContentFragmentDoc}
  ${FileContentFragmentDoc}
`
export const TabContentFragmentDoc = gql`
  fragment tabContent on Tab {
    title
    titleT {
      ...contentTranslationContent
    }
    url
    position
    icon {
      title
      svg
    }
    blank
  }
  ${ContentTranslationContentFragmentDoc}
`
export const SportItemContentFragmentDoc = gql`
  fragment sportItemContent on SportItem {
    id
    title
    subtitle
    description
    titleT {
      ...contentTranslationContent
    }
    subtitleT {
      ...contentTranslationContent
    }
    descriptionT {
      ...contentTranslationContent
    }
    defaultLocale
    videoContentType
    publicationStatus
    contentType
    sportItemSetId
    reverseRestriction
    sportItemSet {
      id
      streamsIds
      streams {
        id
        name
      }
      ownerOrganismId
      sportEvent {
        startDate
        endDate
        state
        liveTemplateId
        clipTemplateId
        backstageTemplateId
      }
    }
    coverImageFileId
    coverImage {
      id
      image {
        id
        thumbUrl
        downloadUrl
      }
    }
    matureContent
    mediasIds
    medias(filters: { includeUnavailable: true, includeInactive: true }) {
      ...mediaContent
    }
    overrideTabs
    tabs {
      ...tabContent
    }
    sportEventItem {
      startDate
      stopDate
      testDate
      publishAutoreplay
    }
    cac {
      userAuthenticationRule
    }
    contentPushServiceNamesExcluded
    modeRadio
  }
  ${ContentTranslationContentFragmentDoc}
  ${MediaContentFragmentDoc}
  ${TabContentFragmentDoc}
`
export const MediaLightContentFragmentDoc = gql`
  fragment mediaLightContent on Media {
    id
    title
    type
    publicationStatus
    tags
    date
    startTrim
    endTrim
    thumbnailId
    ownerOrganismId
    pinned
    timelineMarkers {
      title
      time
      icon
    }
  }
`
export const SportItemLightContentFragmentDoc = gql`
  fragment sportItemLightContent on SportItem {
    id
    title
    subtitle
    description
    videoContentType
    publicationStatus
    contentType
    sportItemSetId
    coverImageFileId
    coverImage {
      id
      image {
        id
        thumbUrl
        downloadUrl
      }
    }
    matureContent
    mediasIds
    reverseRestriction
    medias(filters: { includeUnavailable: true, includeInactive: true }) {
      ...mediaLightContent
    }
    sportEventItem {
      startDate
      stopDate
    }
  }
  ${MediaLightContentFragmentDoc}
`
export const LightSportItemSetContentFragmentDoc = gql`
  fragment lightSportItemSetContent on SportItemSet {
    id
    sportIds
    sports {
      id
      title
      color
    }
    type
    title
    subtitle
    publicationStatus
    ownerOrganismId
    restrictionLevel
    tabs {
      ...tabContent
    }
    websiteUrl
    bannerImageFileId
    bannerImage {
      id
      image {
        id
        thumbUrl
        downloadUrl
      }
    }
    bannerVideoId
    bannerVideo {
      id
      videoClip {
        id
        playbackUrl
      }
    }
    sportEvent {
      startDate
      endDate
      state
    }
    streamsIds
    contentPushServiceNames
    legalRestrictions {
      id
      description
      countries
      type
      timeSlots {
        from
        to
      }
    }
  }
  ${TabContentFragmentDoc}
`
export const PurchasableOfferProductContentFragmentDoc = gql`
  fragment purchasableOfferProductContent on Product {
    productId
    platform
    period
    publicationStatus
    purchasableOfferId
    licensedUserOnly
    premiumOf
    prices {
      value
      currency
    }
  }
`
export const PurchasableOfferContentFragmentDoc = gql`
  fragment purchasableOfferContent on PurchasableOffer {
    id
    mode
    delay
    expiredAt
    publicationStatus
    title
    titleT {
      ...contentTranslationContent
    }
    description {
      header
      content
    }
    descriptionT {
      locale
      content {
        header
        content
      }
    }
    defaultLocale
    url
    bannerImageId
    thumbnailImageId
    freeWithLicense
    products {
      ...purchasableOfferProductContent
    }
    position
  }
  ${ContentTranslationContentFragmentDoc}
  ${PurchasableOfferProductContentFragmentDoc}
`
export const OrganismContentFragmentDoc = gql`
  fragment organismContent on Organism {
    id
    type
    whiteLabel
    title
    description
    publicationStatus
    titleT {
      ...contentTranslationContent
    }
    descriptionT {
      ...contentTranslationContent
    }
    defaultLocale
    onFront
    frontPosition
    logo {
      coloredImage {
        ...mediaContent
      }
      transparentDarkImage {
        ...mediaContent
      }
      transparentLightImage {
        ...mediaContent
      }
    }
    officialWebSiteUrl
    sportIds
    forbiddenKeyWords
    enableExternalSources
    availableExternalSources
    sports {
      id
    }
    bannerImage {
      ...mediaContent
    }
    adminBadge {
      ...mediaContent
    }
    bannerVideo {
      id
      videoClip {
        id
        playbackUrl
      }
    }
    whiteLabel
    pageWidgets {
      ...customPageWidget
    }
    offers {
      ...purchasableOfferContent
    }
    license {
      enabled
      api
    }
    streamsIds
    wmProfileId
    sveChannelIds
    organismPageApplicationDesignId
    contentPageApplicationDesignId
    adminsAutomatedEmails
    tags {
      id
      title
      subTags
    }
    visibleInMultisportApplication
  }
  ${ContentTranslationContentFragmentDoc}
  ${MediaContentFragmentDoc}
  ${CustomPageWidgetFragmentDoc}
  ${PurchasableOfferContentFragmentDoc}
`
export const LinkItemContentFragmentDoc = gql`
  fragment linkItemContent on Link {
    __typename
    id
    ownerOrganismIds
    ownerOrganisms {
      ...organismContent
    }
    tag
    title
    titleT {
      ...contentTranslationContent
    }
    subtitle
    subtitleT {
      ...contentTranslationContent
    }
    description
    descriptionT {
      ...contentTranslationContent
    }
    defaultLocale
    clicks
    uniqueClicks
    views
    countries
    uniqueViews
    teaserVideoId
    gamTagUrl
    billboardId
    billboard {
      id
      videoClip {
        id
        playbackUrl
      }
    }
    teaserVideo {
      id
      videoClip {
        id
        playbackUrl
      }
    }
    url
    imageId
    image {
      id
      image {
        id
        thumbUrl
        downloadUrl
      }
    }
    categoriesIds
    categories {
      id
      title
    }
    publicationStatus
  }
  ${OrganismContentFragmentDoc}
  ${ContentTranslationContentFragmentDoc}
`
export const SveStreamContentFragmentDoc = gql`
  fragment sveStreamContent on SVEStream {
    sveChannelId
    socialNetworkWMStreamId
    outputStreamId
  }
`
export const SportItemSetContentFragmentDoc = gql`
  fragment sportItemSetContent on SportItemSet {
    __typename
    id
    sportIds
    sports {
      id
      title
      color
    }
    type
    title
    subtitle
    description
    titleT {
      ...contentTranslationContent
    }
    subtitleT {
      ...contentTranslationContent
    }
    descriptionT {
      ...contentTranslationContent
    }
    defaultLocale
    publicationStatus
    ownerOrganismId
    ownerOrganism {
      title
    }
    offerIds
    offers {
      id
      title
    }
    restrictionLevel
    tabs {
      ...tabContent
    }
    websiteUrl
    lockPublishingLive
    lockPublishingClip
    sponsorPlatform {
      link {
        ...linkItemContent
      }
      linkId
      display
      videoPreroll
    }
    items {
      id
      title
    }
    bannerImageFileId
    bannerImage {
      id
      image {
        id
        thumbUrl
        downloadUrl
      }
    }
    bannerVideoId
    bannerVideo {
      id
      videoClip {
        id
        playbackUrl
      }
    }
    testPeriodEnd
    sportEvent {
      startDate
      endDate
      state
      liveTemplateId
      backstageTemplateId
      clipTemplateId
    }
    categoriesIds
    categories {
      id
      title
    }
    legalRestrictions {
      id
      description
      countries
      type
      timeSlots {
        from
        to
      }
    }
    cac {
      userLocationRule {
        location {
          latitude
          longitude
        }
        distance
        condition
      }
      userAuthenticationRule
    }
    videoEngine
    streams {
      id
      name
    }
    streamsIds
    sveStreams {
      ...sveStreamContent
    }
    wmClippingTemplateId
    sveInputLossImageMediaId
    sveOverlayLogoImageMediaId
    visibleInMultisportApplication
    activateSportItemChatByDefault
    contentPushServiceNames
    tag {
      id
      title
    }
    subTags
    activateGreenPlayback
    adCampaignId
    contentPushServiceGenericStreamIds
    contentPushServiceLiveEventIds
    contentPushServiceGenericStreams {
      id
      name
      createdAt
      updatedAt
    }
    noSpoilerMode {
      enabled
      minutesBeforeDisabling
    }
    disableReadParallelLivestreamsLimit
  }
  ${ContentTranslationContentFragmentDoc}
  ${TabContentFragmentDoc}
  ${LinkItemContentFragmentDoc}
  ${SveStreamContentFragmentDoc}
`
export const SportItemSetWithOrganismContentFragmentDoc = gql`
  fragment sportItemSetWithOrganismContent on SportItemSet {
    ...sportItemSetContent
    ownerOrganism {
      ...organismContent
    }
  }
  ${SportItemSetContentFragmentDoc}
  ${OrganismContentFragmentDoc}
`
export const SveChannelContentFragmentDoc = gql`
  fragment sveChannelContent on SVEChannel {
    id
    name
    status
    logoUrl
    inputLossImageUrl
    socialOutputUrl
    socialOutputStreamName
    endpoints {
      rtmpInputUrl
      cmafEndpointUrl
      hlsEndpointUrl
    }
  }
`
export const LightOrganismContentFragmentDoc = gql`
  fragment lightOrganismContent on Organism {
    id
    type
    whiteLabel
    title
    description
    officialWebSiteUrl
    whiteLabel
    fansCount
    license {
      enabled
      api
    }
  }
`
export const ThirdPartyApplicationContentFragmentDoc = gql`
  fragment thirdPartyApplicationContent on ThirdPartyApplication {
    id
    name
    organismId
    keys {
      id
      name
      key
      type
      enabled
      expirationDate
    }
    description
    organism {
      ...lightOrganismContent
    }
  }
  ${LightOrganismContentFragmentDoc}
`
export const LightUserContentFragmentDoc = gql`
  fragment lightUserContent on User {
    id
    email
    firstName
    lastName
    chatAuthorName
    receipts {
      offerId
      offer {
        title
      }
      type
      status
      productId
    }
    organismIds
    organisms {
      id
      title
    }
    roleIds
    rights {
      generalPermissions
      organismsPermissions {
        organismId
        permissions
      }
    }
    birthdayDate
    gender
    location {
      country
      postalCode
    }
    createdAt
    lastActivity
    cguAccepted
    signUpOrigin
    environment
    verified
    applicationOrigin {
      id
      title
    }
    fanPreferences {
      followedOrganisms {
        id
        title
      }
    }
    licenses {
      organismId
      organism {
        id
        title
      }
      isLicensed
      registrationDate
    }
  }
`
export const UserRoleFragmentDoc = gql`
  fragment userRole on UserRole {
    id
    title
    shortTitle
    description
    generalBasePermissions
    organismsBasePermissions
  }
`
export const WmStreamContentFragmentDoc = gql`
  fragment wmStreamContent on WMStream {
    id
    name
    ingestionStatus
    inputUrl
    outputUrl
    map {
      id
      channelArn
    }
  }
`
export const AdCampaignsDocument = gql`
  query adCampaigns($first: Int, $offset: Int, $filters: AdCampaignFilters) {
    adCampaigns(first: $first, offset: $offset, filters: $filters) {
      adCampaigns {
        ...adCampaignContent
      }
      totalCount
    }
  }
  ${AdCampaignContentFragmentDoc}
`

/**
 * __useAdCampaignsQuery__
 *
 * To run a query within a React component, call `useAdCampaignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdCampaignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdCampaignsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useAdCampaignsQuery(
  baseOptions?: Apollo.QueryHookOptions<AdCampaignsQuery, AdCampaignsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AdCampaignsQuery, AdCampaignsQueryVariables>(AdCampaignsDocument, options)
}
export function useAdCampaignsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AdCampaignsQuery, AdCampaignsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AdCampaignsQuery, AdCampaignsQueryVariables>(AdCampaignsDocument, options)
}
export type AdCampaignsQueryHookResult = ReturnType<typeof useAdCampaignsQuery>
export type AdCampaignsLazyQueryHookResult = ReturnType<typeof useAdCampaignsLazyQuery>
export type AdCampaignsQueryResult = Apollo.QueryResult<AdCampaignsQuery, AdCampaignsQueryVariables>
export const CreateAdCampaignDocument = gql`
  mutation createAdCampaign($input: CreateAdCampaignInput!) {
    createAdCampaign(input: $input) {
      ...adCampaignContent
    }
  }
  ${AdCampaignContentFragmentDoc}
`
export type CreateAdCampaignMutationFn = Apollo.MutationFunction<
  CreateAdCampaignMutation,
  CreateAdCampaignMutationVariables
>

/**
 * __useCreateAdCampaignMutation__
 *
 * To run a mutation, you first call `useCreateAdCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAdCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAdCampaignMutation, { data, loading, error }] = useCreateAdCampaignMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAdCampaignMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateAdCampaignMutation, CreateAdCampaignMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateAdCampaignMutation, CreateAdCampaignMutationVariables>(
    CreateAdCampaignDocument,
    options,
  )
}
export type CreateAdCampaignMutationHookResult = ReturnType<typeof useCreateAdCampaignMutation>
export type CreateAdCampaignMutationResult = Apollo.MutationResult<CreateAdCampaignMutation>
export type CreateAdCampaignMutationOptions = Apollo.BaseMutationOptions<
  CreateAdCampaignMutation,
  CreateAdCampaignMutationVariables
>
export const UpdateAdCampaignDocument = gql`
  mutation updateAdCampaign($input: UpdateAdCampaignInput!) {
    updateAdCampaign(input: $input) {
      ...adCampaignContent
    }
  }
  ${AdCampaignContentFragmentDoc}
`
export type UpdateAdCampaignMutationFn = Apollo.MutationFunction<
  UpdateAdCampaignMutation,
  UpdateAdCampaignMutationVariables
>

/**
 * __useUpdateAdCampaignMutation__
 *
 * To run a mutation, you first call `useUpdateAdCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdCampaignMutation, { data, loading, error }] = useUpdateAdCampaignMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAdCampaignMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateAdCampaignMutation, UpdateAdCampaignMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateAdCampaignMutation, UpdateAdCampaignMutationVariables>(
    UpdateAdCampaignDocument,
    options,
  )
}
export type UpdateAdCampaignMutationHookResult = ReturnType<typeof useUpdateAdCampaignMutation>
export type UpdateAdCampaignMutationResult = Apollo.MutationResult<UpdateAdCampaignMutation>
export type UpdateAdCampaignMutationOptions = Apollo.BaseMutationOptions<
  UpdateAdCampaignMutation,
  UpdateAdCampaignMutationVariables
>
export const DeleteAdCampaignDocument = gql`
  mutation deleteAdCampaign($id: ID!) {
    deleteAdCampaign(id: $id) {
      id
    }
  }
`
export type DeleteAdCampaignMutationFn = Apollo.MutationFunction<
  DeleteAdCampaignMutation,
  DeleteAdCampaignMutationVariables
>

/**
 * __useDeleteAdCampaignMutation__
 *
 * To run a mutation, you first call `useDeleteAdCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAdCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAdCampaignMutation, { data, loading, error }] = useDeleteAdCampaignMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAdCampaignMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteAdCampaignMutation, DeleteAdCampaignMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteAdCampaignMutation, DeleteAdCampaignMutationVariables>(
    DeleteAdCampaignDocument,
    options,
  )
}
export type DeleteAdCampaignMutationHookResult = ReturnType<typeof useDeleteAdCampaignMutation>
export type DeleteAdCampaignMutationResult = Apollo.MutationResult<DeleteAdCampaignMutation>
export type DeleteAdCampaignMutationOptions = Apollo.BaseMutationOptions<
  DeleteAdCampaignMutation,
  DeleteAdCampaignMutationVariables
>
export const ApplicationDocument = gql`
  query application($applicationId: ID!) {
    application(id: $applicationId) {
      ...applicationContent
    }
  }
  ${ApplicationContentFragmentDoc}
`

/**
 * __useApplicationQuery__
 *
 * To run a query within a React component, call `useApplicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationQuery({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *   },
 * });
 */
export function useApplicationQuery(baseOptions: Apollo.QueryHookOptions<ApplicationQuery, ApplicationQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ApplicationQuery, ApplicationQueryVariables>(ApplicationDocument, options)
}
export function useApplicationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ApplicationQuery, ApplicationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ApplicationQuery, ApplicationQueryVariables>(ApplicationDocument, options)
}
export type ApplicationQueryHookResult = ReturnType<typeof useApplicationQuery>
export type ApplicationLazyQueryHookResult = ReturnType<typeof useApplicationLazyQuery>
export type ApplicationQueryResult = Apollo.QueryResult<ApplicationQuery, ApplicationQueryVariables>
export const ApplicationFromApplicationIdDocument = gql`
  query applicationFromApplicationId($applicationId: ID!) {
    applicationFromApplicationId(id: $applicationId) {
      ...applicationContent
    }
  }
  ${ApplicationContentFragmentDoc}
`

/**
 * __useApplicationFromApplicationIdQuery__
 *
 * To run a query within a React component, call `useApplicationFromApplicationIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationFromApplicationIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationFromApplicationIdQuery({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *   },
 * });
 */
export function useApplicationFromApplicationIdQuery(
  baseOptions: Apollo.QueryHookOptions<ApplicationFromApplicationIdQuery, ApplicationFromApplicationIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ApplicationFromApplicationIdQuery, ApplicationFromApplicationIdQueryVariables>(
    ApplicationFromApplicationIdDocument,
    options,
  )
}
export function useApplicationFromApplicationIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApplicationFromApplicationIdQuery,
    ApplicationFromApplicationIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ApplicationFromApplicationIdQuery, ApplicationFromApplicationIdQueryVariables>(
    ApplicationFromApplicationIdDocument,
    options,
  )
}
export type ApplicationFromApplicationIdQueryHookResult = ReturnType<typeof useApplicationFromApplicationIdQuery>
export type ApplicationFromApplicationIdLazyQueryHookResult = ReturnType<
  typeof useApplicationFromApplicationIdLazyQuery
>
export type ApplicationFromApplicationIdQueryResult = Apollo.QueryResult<
  ApplicationFromApplicationIdQuery,
  ApplicationFromApplicationIdQueryVariables
>
export const ApplicationsDocument = gql`
  query applications($filters: ApplicationsFilters) {
    applications(filters: $filters) {
      applications {
        ...applicationContent
      }
      totalCount
    }
  }
  ${ApplicationContentFragmentDoc}
`

/**
 * __useApplicationsQuery__
 *
 * To run a query within a React component, call `useApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useApplicationsQuery(
  baseOptions?: Apollo.QueryHookOptions<ApplicationsQuery, ApplicationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ApplicationsQuery, ApplicationsQueryVariables>(ApplicationsDocument, options)
}
export function useApplicationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ApplicationsQuery, ApplicationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ApplicationsQuery, ApplicationsQueryVariables>(ApplicationsDocument, options)
}
export type ApplicationsQueryHookResult = ReturnType<typeof useApplicationsQuery>
export type ApplicationsLazyQueryHookResult = ReturnType<typeof useApplicationsLazyQuery>
export type ApplicationsQueryResult = Apollo.QueryResult<ApplicationsQuery, ApplicationsQueryVariables>
export const CreateApplicationDocument = gql`
  mutation createApplication($input: CreateApplicationInput!) {
    createApplication(input: $input) {
      ...applicationContent
    }
  }
  ${ApplicationContentFragmentDoc}
`
export type CreateApplicationMutationFn = Apollo.MutationFunction<
  CreateApplicationMutation,
  CreateApplicationMutationVariables
>

/**
 * __useCreateApplicationMutation__
 *
 * To run a mutation, you first call `useCreateApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApplicationMutation, { data, loading, error }] = useCreateApplicationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateApplicationMutation, CreateApplicationMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateApplicationMutation, CreateApplicationMutationVariables>(
    CreateApplicationDocument,
    options,
  )
}
export type CreateApplicationMutationHookResult = ReturnType<typeof useCreateApplicationMutation>
export type CreateApplicationMutationResult = Apollo.MutationResult<CreateApplicationMutation>
export type CreateApplicationMutationOptions = Apollo.BaseMutationOptions<
  CreateApplicationMutation,
  CreateApplicationMutationVariables
>
export const UpdateApplicationDocument = gql`
  mutation updateApplication($input: UpdateApplicationInput!) {
    updateApplication(input: $input) {
      ...applicationContent
    }
  }
  ${ApplicationContentFragmentDoc}
`
export type UpdateApplicationMutationFn = Apollo.MutationFunction<
  UpdateApplicationMutation,
  UpdateApplicationMutationVariables
>

/**
 * __useUpdateApplicationMutation__
 *
 * To run a mutation, you first call `useUpdateApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApplicationMutation, { data, loading, error }] = useUpdateApplicationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateApplicationMutation, UpdateApplicationMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateApplicationMutation, UpdateApplicationMutationVariables>(
    UpdateApplicationDocument,
    options,
  )
}
export type UpdateApplicationMutationHookResult = ReturnType<typeof useUpdateApplicationMutation>
export type UpdateApplicationMutationResult = Apollo.MutationResult<UpdateApplicationMutation>
export type UpdateApplicationMutationOptions = Apollo.BaseMutationOptions<
  UpdateApplicationMutation,
  UpdateApplicationMutationVariables
>
export const DeleteApplicationDocument = gql`
  mutation deleteApplication($id: ID!) {
    deleteApplication(id: $id) {
      id
    }
  }
`
export type DeleteApplicationMutationFn = Apollo.MutationFunction<
  DeleteApplicationMutation,
  DeleteApplicationMutationVariables
>

/**
 * __useDeleteApplicationMutation__
 *
 * To run a mutation, you first call `useDeleteApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApplicationMutation, { data, loading, error }] = useDeleteApplicationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteApplicationMutation, DeleteApplicationMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteApplicationMutation, DeleteApplicationMutationVariables>(
    DeleteApplicationDocument,
    options,
  )
}
export type DeleteApplicationMutationHookResult = ReturnType<typeof useDeleteApplicationMutation>
export type DeleteApplicationMutationResult = Apollo.MutationResult<DeleteApplicationMutation>
export type DeleteApplicationMutationOptions = Apollo.BaseMutationOptions<
  DeleteApplicationMutation,
  DeleteApplicationMutationVariables
>
export const ApplicationDesignsDocument = gql`
  query applicationDesigns($first: Int, $offset: Int, $filters: ApplicationDesignFilters) {
    applicationDesigns(first: $first, offset: $offset, filters: $filters) {
      applicationDesigns {
        ...applicationDesignContent
      }
      totalCount
    }
  }
  ${ApplicationDesignContentFragmentDoc}
`

/**
 * __useApplicationDesignsQuery__
 *
 * To run a query within a React component, call `useApplicationDesignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationDesignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationDesignsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useApplicationDesignsQuery(
  baseOptions?: Apollo.QueryHookOptions<ApplicationDesignsQuery, ApplicationDesignsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ApplicationDesignsQuery, ApplicationDesignsQueryVariables>(ApplicationDesignsDocument, options)
}
export function useApplicationDesignsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ApplicationDesignsQuery, ApplicationDesignsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ApplicationDesignsQuery, ApplicationDesignsQueryVariables>(
    ApplicationDesignsDocument,
    options,
  )
}
export type ApplicationDesignsQueryHookResult = ReturnType<typeof useApplicationDesignsQuery>
export type ApplicationDesignsLazyQueryHookResult = ReturnType<typeof useApplicationDesignsLazyQuery>
export type ApplicationDesignsQueryResult = Apollo.QueryResult<
  ApplicationDesignsQuery,
  ApplicationDesignsQueryVariables
>
export const CreateApplicationDesignDocument = gql`
  mutation createApplicationDesign($input: CreateApplicationDesignInput!) {
    createApplicationDesign(input: $input) {
      ...applicationDesignContent
    }
  }
  ${ApplicationDesignContentFragmentDoc}
`
export type CreateApplicationDesignMutationFn = Apollo.MutationFunction<
  CreateApplicationDesignMutation,
  CreateApplicationDesignMutationVariables
>

/**
 * __useCreateApplicationDesignMutation__
 *
 * To run a mutation, you first call `useCreateApplicationDesignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApplicationDesignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApplicationDesignMutation, { data, loading, error }] = useCreateApplicationDesignMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateApplicationDesignMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateApplicationDesignMutation, CreateApplicationDesignMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateApplicationDesignMutation, CreateApplicationDesignMutationVariables>(
    CreateApplicationDesignDocument,
    options,
  )
}
export type CreateApplicationDesignMutationHookResult = ReturnType<typeof useCreateApplicationDesignMutation>
export type CreateApplicationDesignMutationResult = Apollo.MutationResult<CreateApplicationDesignMutation>
export type CreateApplicationDesignMutationOptions = Apollo.BaseMutationOptions<
  CreateApplicationDesignMutation,
  CreateApplicationDesignMutationVariables
>
export const UpdateApplicationDesignDocument = gql`
  mutation updateApplicationDesign($input: UpdateApplicationDesignInput!) {
    updateApplicationDesign(input: $input) {
      ...applicationDesignContent
    }
  }
  ${ApplicationDesignContentFragmentDoc}
`
export type UpdateApplicationDesignMutationFn = Apollo.MutationFunction<
  UpdateApplicationDesignMutation,
  UpdateApplicationDesignMutationVariables
>

/**
 * __useUpdateApplicationDesignMutation__
 *
 * To run a mutation, you first call `useUpdateApplicationDesignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicationDesignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApplicationDesignMutation, { data, loading, error }] = useUpdateApplicationDesignMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateApplicationDesignMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateApplicationDesignMutation, UpdateApplicationDesignMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateApplicationDesignMutation, UpdateApplicationDesignMutationVariables>(
    UpdateApplicationDesignDocument,
    options,
  )
}
export type UpdateApplicationDesignMutationHookResult = ReturnType<typeof useUpdateApplicationDesignMutation>
export type UpdateApplicationDesignMutationResult = Apollo.MutationResult<UpdateApplicationDesignMutation>
export type UpdateApplicationDesignMutationOptions = Apollo.BaseMutationOptions<
  UpdateApplicationDesignMutation,
  UpdateApplicationDesignMutationVariables
>
export const DeleteApplicationDesignDocument = gql`
  mutation deleteApplicationDesign($id: ID!) {
    deleteApplicationDesign(id: $id) {
      id
    }
  }
`
export type DeleteApplicationDesignMutationFn = Apollo.MutationFunction<
  DeleteApplicationDesignMutation,
  DeleteApplicationDesignMutationVariables
>

/**
 * __useDeleteApplicationDesignMutation__
 *
 * To run a mutation, you first call `useDeleteApplicationDesignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApplicationDesignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApplicationDesignMutation, { data, loading, error }] = useDeleteApplicationDesignMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteApplicationDesignMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteApplicationDesignMutation, DeleteApplicationDesignMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteApplicationDesignMutation, DeleteApplicationDesignMutationVariables>(
    DeleteApplicationDesignDocument,
    options,
  )
}
export type DeleteApplicationDesignMutationHookResult = ReturnType<typeof useDeleteApplicationDesignMutation>
export type DeleteApplicationDesignMutationResult = Apollo.MutationResult<DeleteApplicationDesignMutation>
export type DeleteApplicationDesignMutationOptions = Apollo.BaseMutationOptions<
  DeleteApplicationDesignMutation,
  DeleteApplicationDesignMutationVariables
>
export const CategoriesDocument = gql`
  query categories {
    categories {
      id
      title
    }
  }
`

/**
 * __useCategoriesQuery__
 *
 * To run a query within a React component, call `useCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<CategoriesQuery, CategoriesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, options)
}
export function useCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CategoriesQuery, CategoriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, options)
}
export type CategoriesQueryHookResult = ReturnType<typeof useCategoriesQuery>
export type CategoriesLazyQueryHookResult = ReturnType<typeof useCategoriesLazyQuery>
export type CategoriesQueryResult = Apollo.QueryResult<CategoriesQuery, CategoriesQueryVariables>
export const CreateCategoryDocument = gql`
  mutation createCategory($input: CreateCategoryInput!) {
    createCategory(input: $input) {
      id
      title
    }
  }
`
export type CreateCategoryMutationFn = Apollo.MutationFunction<CreateCategoryMutation, CreateCategoryMutationVariables>

/**
 * __useCreateCategoryMutation__
 *
 * To run a mutation, you first call `useCreateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCategoryMutation, { data, loading, error }] = useCreateCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateCategoryMutation, CreateCategoryMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateCategoryMutation, CreateCategoryMutationVariables>(CreateCategoryDocument, options)
}
export type CreateCategoryMutationHookResult = ReturnType<typeof useCreateCategoryMutation>
export type CreateCategoryMutationResult = Apollo.MutationResult<CreateCategoryMutation>
export type CreateCategoryMutationOptions = Apollo.BaseMutationOptions<
  CreateCategoryMutation,
  CreateCategoryMutationVariables
>
export const UpdateCategoryDocument = gql`
  mutation updateCategory($input: UpdateCategoryInput!) {
    updateCategory(input: $input) {
      id
      title
    }
  }
`
export type UpdateCategoryMutationFn = Apollo.MutationFunction<UpdateCategoryMutation, UpdateCategoryMutationVariables>

/**
 * __useUpdateCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCategoryMutation, { data, loading, error }] = useUpdateCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCategoryMutation, UpdateCategoryMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateCategoryMutation, UpdateCategoryMutationVariables>(UpdateCategoryDocument, options)
}
export type UpdateCategoryMutationHookResult = ReturnType<typeof useUpdateCategoryMutation>
export type UpdateCategoryMutationResult = Apollo.MutationResult<UpdateCategoryMutation>
export type UpdateCategoryMutationOptions = Apollo.BaseMutationOptions<
  UpdateCategoryMutation,
  UpdateCategoryMutationVariables
>
export const DeleteCategoryDocument = gql`
  mutation deleteCategory($id: ID!) {
    deleteCategory(id: $id)
  }
`
export type DeleteCategoryMutationFn = Apollo.MutationFunction<DeleteCategoryMutation, DeleteCategoryMutationVariables>

/**
 * __useDeleteCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCategoryMutation, { data, loading, error }] = useDeleteCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteCategoryMutation, DeleteCategoryMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteCategoryMutation, DeleteCategoryMutationVariables>(DeleteCategoryDocument, options)
}
export type DeleteCategoryMutationHookResult = ReturnType<typeof useDeleteCategoryMutation>
export type DeleteCategoryMutationResult = Apollo.MutationResult<DeleteCategoryMutation>
export type DeleteCategoryMutationOptions = Apollo.BaseMutationOptions<
  DeleteCategoryMutation,
  DeleteCategoryMutationVariables
>
export const ContentPushStreamExportsDocument = gql`
  query contentPushStreamExports($first: Int, $offset: Int, $contentPushStreamId: ID!) {
    contentPushStreamExports(first: $first, offset: $offset, contentPushStreamId: $contentPushStreamId) {
      sportItemSets {
        id
        title
        type
        ownerOrganism {
          title
        }
      }
      totalCount
    }
  }
`

/**
 * __useContentPushStreamExportsQuery__
 *
 * To run a query within a React component, call `useContentPushStreamExportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentPushStreamExportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentPushStreamExportsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *      contentPushStreamId: // value for 'contentPushStreamId'
 *   },
 * });
 */
export function useContentPushStreamExportsQuery(
  baseOptions: Apollo.QueryHookOptions<ContentPushStreamExportsQuery, ContentPushStreamExportsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ContentPushStreamExportsQuery, ContentPushStreamExportsQueryVariables>(
    ContentPushStreamExportsDocument,
    options,
  )
}
export function useContentPushStreamExportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ContentPushStreamExportsQuery, ContentPushStreamExportsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ContentPushStreamExportsQuery, ContentPushStreamExportsQueryVariables>(
    ContentPushStreamExportsDocument,
    options,
  )
}
export type ContentPushStreamExportsQueryHookResult = ReturnType<typeof useContentPushStreamExportsQuery>
export type ContentPushStreamExportsLazyQueryHookResult = ReturnType<typeof useContentPushStreamExportsLazyQuery>
export type ContentPushStreamExportsQueryResult = Apollo.QueryResult<
  ContentPushStreamExportsQuery,
  ContentPushStreamExportsQueryVariables
>
export const ContentPushStreamsDocument = gql`
  query contentPushStreams($filters: ContentPushStreamsFiltersQuery) {
    contentPushStreams(filters: $filters) {
      ...contentPushStreamContent
    }
  }
  ${ContentPushStreamContentFragmentDoc}
`

/**
 * __useContentPushStreamsQuery__
 *
 * To run a query within a React component, call `useContentPushStreamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentPushStreamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentPushStreamsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useContentPushStreamsQuery(
  baseOptions?: Apollo.QueryHookOptions<ContentPushStreamsQuery, ContentPushStreamsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ContentPushStreamsQuery, ContentPushStreamsQueryVariables>(ContentPushStreamsDocument, options)
}
export function useContentPushStreamsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ContentPushStreamsQuery, ContentPushStreamsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ContentPushStreamsQuery, ContentPushStreamsQueryVariables>(
    ContentPushStreamsDocument,
    options,
  )
}
export type ContentPushStreamsQueryHookResult = ReturnType<typeof useContentPushStreamsQuery>
export type ContentPushStreamsLazyQueryHookResult = ReturnType<typeof useContentPushStreamsLazyQuery>
export type ContentPushStreamsQueryResult = Apollo.QueryResult<
  ContentPushStreamsQuery,
  ContentPushStreamsQueryVariables
>
export const AvailableContentPushServicesDocument = gql`
  query availableContentPushServices {
    availableContentPushServices {
      type
      customDestination
    }
  }
`

/**
 * __useAvailableContentPushServicesQuery__
 *
 * To run a query within a React component, call `useAvailableContentPushServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableContentPushServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableContentPushServicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAvailableContentPushServicesQuery(
  baseOptions?: Apollo.QueryHookOptions<AvailableContentPushServicesQuery, AvailableContentPushServicesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AvailableContentPushServicesQuery, AvailableContentPushServicesQueryVariables>(
    AvailableContentPushServicesDocument,
    options,
  )
}
export function useAvailableContentPushServicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AvailableContentPushServicesQuery,
    AvailableContentPushServicesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AvailableContentPushServicesQuery, AvailableContentPushServicesQueryVariables>(
    AvailableContentPushServicesDocument,
    options,
  )
}
export type AvailableContentPushServicesQueryHookResult = ReturnType<typeof useAvailableContentPushServicesQuery>
export type AvailableContentPushServicesLazyQueryHookResult = ReturnType<
  typeof useAvailableContentPushServicesLazyQuery
>
export type AvailableContentPushServicesQueryResult = Apollo.QueryResult<
  AvailableContentPushServicesQuery,
  AvailableContentPushServicesQueryVariables
>
export const OutputStreamsDocument = gql`
  query outputStreams {
    outputStreams {
      ...outputStreamContent
    }
  }
  ${OutputStreamContentFragmentDoc}
`

/**
 * __useOutputStreamsQuery__
 *
 * To run a query within a React component, call `useOutputStreamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOutputStreamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOutputStreamsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOutputStreamsQuery(
  baseOptions?: Apollo.QueryHookOptions<OutputStreamsQuery, OutputStreamsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<OutputStreamsQuery, OutputStreamsQueryVariables>(OutputStreamsDocument, options)
}
export function useOutputStreamsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OutputStreamsQuery, OutputStreamsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<OutputStreamsQuery, OutputStreamsQueryVariables>(OutputStreamsDocument, options)
}
export type OutputStreamsQueryHookResult = ReturnType<typeof useOutputStreamsQuery>
export type OutputStreamsLazyQueryHookResult = ReturnType<typeof useOutputStreamsLazyQuery>
export type OutputStreamsQueryResult = Apollo.QueryResult<OutputStreamsQuery, OutputStreamsQueryVariables>
export const CreateContentPushStreamDocument = gql`
  mutation createContentPushStream($input: CreateContentPushStreamInput!) {
    createContentPushStream(input: $input)
  }
`
export type CreateContentPushStreamMutationFn = Apollo.MutationFunction<
  CreateContentPushStreamMutation,
  CreateContentPushStreamMutationVariables
>

/**
 * __useCreateContentPushStreamMutation__
 *
 * To run a mutation, you first call `useCreateContentPushStreamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContentPushStreamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContentPushStreamMutation, { data, loading, error }] = useCreateContentPushStreamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContentPushStreamMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateContentPushStreamMutation, CreateContentPushStreamMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateContentPushStreamMutation, CreateContentPushStreamMutationVariables>(
    CreateContentPushStreamDocument,
    options,
  )
}
export type CreateContentPushStreamMutationHookResult = ReturnType<typeof useCreateContentPushStreamMutation>
export type CreateContentPushStreamMutationResult = Apollo.MutationResult<CreateContentPushStreamMutation>
export type CreateContentPushStreamMutationOptions = Apollo.BaseMutationOptions<
  CreateContentPushStreamMutation,
  CreateContentPushStreamMutationVariables
>
export const UpdateContentPushStreamDocument = gql`
  mutation updateContentPushStream($input: UpdateContentPushStreamInput!) {
    updateContentPushStream(input: $input)
  }
`
export type UpdateContentPushStreamMutationFn = Apollo.MutationFunction<
  UpdateContentPushStreamMutation,
  UpdateContentPushStreamMutationVariables
>

/**
 * __useUpdateContentPushStreamMutation__
 *
 * To run a mutation, you first call `useUpdateContentPushStreamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContentPushStreamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContentPushStreamMutation, { data, loading, error }] = useUpdateContentPushStreamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContentPushStreamMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateContentPushStreamMutation, UpdateContentPushStreamMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateContentPushStreamMutation, UpdateContentPushStreamMutationVariables>(
    UpdateContentPushStreamDocument,
    options,
  )
}
export type UpdateContentPushStreamMutationHookResult = ReturnType<typeof useUpdateContentPushStreamMutation>
export type UpdateContentPushStreamMutationResult = Apollo.MutationResult<UpdateContentPushStreamMutation>
export type UpdateContentPushStreamMutationOptions = Apollo.BaseMutationOptions<
  UpdateContentPushStreamMutation,
  UpdateContentPushStreamMutationVariables
>
export const DeleteContentPushStreamDocument = gql`
  mutation deleteContentPushStream($id: ID!) {
    deleteContentPushStream(id: $id)
  }
`
export type DeleteContentPushStreamMutationFn = Apollo.MutationFunction<
  DeleteContentPushStreamMutation,
  DeleteContentPushStreamMutationVariables
>

/**
 * __useDeleteContentPushStreamMutation__
 *
 * To run a mutation, you first call `useDeleteContentPushStreamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContentPushStreamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContentPushStreamMutation, { data, loading, error }] = useDeleteContentPushStreamMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteContentPushStreamMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteContentPushStreamMutation, DeleteContentPushStreamMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteContentPushStreamMutation, DeleteContentPushStreamMutationVariables>(
    DeleteContentPushStreamDocument,
    options,
  )
}
export type DeleteContentPushStreamMutationHookResult = ReturnType<typeof useDeleteContentPushStreamMutation>
export type DeleteContentPushStreamMutationResult = Apollo.MutationResult<DeleteContentPushStreamMutation>
export type DeleteContentPushStreamMutationOptions = Apollo.BaseMutationOptions<
  DeleteContentPushStreamMutation,
  DeleteContentPushStreamMutationVariables
>
export const UpdateOutputStreamsDocument = gql`
  mutation updateOutputStreams($input: UpdateOutputStreamsInput!) {
    updateOutputStreams(input: $input)
  }
`
export type UpdateOutputStreamsMutationFn = Apollo.MutationFunction<
  UpdateOutputStreamsMutation,
  UpdateOutputStreamsMutationVariables
>

/**
 * __useUpdateOutputStreamsMutation__
 *
 * To run a mutation, you first call `useUpdateOutputStreamsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOutputStreamsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOutputStreamsMutation, { data, loading, error }] = useUpdateOutputStreamsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOutputStreamsMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateOutputStreamsMutation, UpdateOutputStreamsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateOutputStreamsMutation, UpdateOutputStreamsMutationVariables>(
    UpdateOutputStreamsDocument,
    options,
  )
}
export type UpdateOutputStreamsMutationHookResult = ReturnType<typeof useUpdateOutputStreamsMutation>
export type UpdateOutputStreamsMutationResult = Apollo.MutationResult<UpdateOutputStreamsMutation>
export type UpdateOutputStreamsMutationOptions = Apollo.BaseMutationOptions<
  UpdateOutputStreamsMutation,
  UpdateOutputStreamsMutationVariables
>
export const SyncNowDocument = gql`
  mutation syncNow($id: ID!) {
    syncNow(id: $id)
  }
`
export type SyncNowMutationFn = Apollo.MutationFunction<SyncNowMutation, SyncNowMutationVariables>

/**
 * __useSyncNowMutation__
 *
 * To run a mutation, you first call `useSyncNowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncNowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncNowMutation, { data, loading, error }] = useSyncNowMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSyncNowMutation(
  baseOptions?: Apollo.MutationHookOptions<SyncNowMutation, SyncNowMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SyncNowMutation, SyncNowMutationVariables>(SyncNowDocument, options)
}
export type SyncNowMutationHookResult = ReturnType<typeof useSyncNowMutation>
export type SyncNowMutationResult = Apollo.MutationResult<SyncNowMutation>
export type SyncNowMutationOptions = Apollo.BaseMutationOptions<SyncNowMutation, SyncNowMutationVariables>
export const CustomPageDocument = gql`
  query customPage($customPageId: ID!) {
    customPage(id: $customPageId) {
      ...customPageContent
    }
  }
  ${CustomPageContentFragmentDoc}
`

/**
 * __useCustomPageQuery__
 *
 * To run a query within a React component, call `useCustomPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomPageQuery({
 *   variables: {
 *      customPageId: // value for 'customPageId'
 *   },
 * });
 */
export function useCustomPageQuery(baseOptions: Apollo.QueryHookOptions<CustomPageQuery, CustomPageQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CustomPageQuery, CustomPageQueryVariables>(CustomPageDocument, options)
}
export function useCustomPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CustomPageQuery, CustomPageQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CustomPageQuery, CustomPageQueryVariables>(CustomPageDocument, options)
}
export type CustomPageQueryHookResult = ReturnType<typeof useCustomPageQuery>
export type CustomPageLazyQueryHookResult = ReturnType<typeof useCustomPageLazyQuery>
export type CustomPageQueryResult = Apollo.QueryResult<CustomPageQuery, CustomPageQueryVariables>
export const CustomPagesDocument = gql`
  query customPages($filters: CustomPageFilters) {
    customPages(filters: $filters) {
      customPages {
        ...customPageContent
      }
      totalCount
    }
  }
  ${CustomPageContentFragmentDoc}
`

/**
 * __useCustomPagesQuery__
 *
 * To run a query within a React component, call `useCustomPagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomPagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomPagesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useCustomPagesQuery(
  baseOptions?: Apollo.QueryHookOptions<CustomPagesQuery, CustomPagesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CustomPagesQuery, CustomPagesQueryVariables>(CustomPagesDocument, options)
}
export function useCustomPagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CustomPagesQuery, CustomPagesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CustomPagesQuery, CustomPagesQueryVariables>(CustomPagesDocument, options)
}
export type CustomPagesQueryHookResult = ReturnType<typeof useCustomPagesQuery>
export type CustomPagesLazyQueryHookResult = ReturnType<typeof useCustomPagesLazyQuery>
export type CustomPagesQueryResult = Apollo.QueryResult<CustomPagesQuery, CustomPagesQueryVariables>
export const CreateCustomPageDocument = gql`
  mutation createCustomPage($input: CreateCustomPageInput!) {
    createCustomPage(input: $input) {
      ...customPageContent
    }
  }
  ${CustomPageContentFragmentDoc}
`
export type CreateCustomPageMutationFn = Apollo.MutationFunction<
  CreateCustomPageMutation,
  CreateCustomPageMutationVariables
>

/**
 * __useCreateCustomPageMutation__
 *
 * To run a mutation, you first call `useCreateCustomPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomPageMutation, { data, loading, error }] = useCreateCustomPageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomPageMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateCustomPageMutation, CreateCustomPageMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateCustomPageMutation, CreateCustomPageMutationVariables>(
    CreateCustomPageDocument,
    options,
  )
}
export type CreateCustomPageMutationHookResult = ReturnType<typeof useCreateCustomPageMutation>
export type CreateCustomPageMutationResult = Apollo.MutationResult<CreateCustomPageMutation>
export type CreateCustomPageMutationOptions = Apollo.BaseMutationOptions<
  CreateCustomPageMutation,
  CreateCustomPageMutationVariables
>
export const UpdateCustomPageDocument = gql`
  mutation updateCustomPage($input: UpdateCustomPageInput!) {
    updateCustomPage(input: $input) {
      ...customPageContent
    }
  }
  ${CustomPageContentFragmentDoc}
`
export type UpdateCustomPageMutationFn = Apollo.MutationFunction<
  UpdateCustomPageMutation,
  UpdateCustomPageMutationVariables
>

/**
 * __useUpdateCustomPageMutation__
 *
 * To run a mutation, you first call `useUpdateCustomPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomPageMutation, { data, loading, error }] = useUpdateCustomPageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomPageMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCustomPageMutation, UpdateCustomPageMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateCustomPageMutation, UpdateCustomPageMutationVariables>(
    UpdateCustomPageDocument,
    options,
  )
}
export type UpdateCustomPageMutationHookResult = ReturnType<typeof useUpdateCustomPageMutation>
export type UpdateCustomPageMutationResult = Apollo.MutationResult<UpdateCustomPageMutation>
export type UpdateCustomPageMutationOptions = Apollo.BaseMutationOptions<
  UpdateCustomPageMutation,
  UpdateCustomPageMutationVariables
>
export const DeleteCustomPageDocument = gql`
  mutation deleteCustomPage($id: ID!) {
    deleteCustomPage(id: $id) {
      id
    }
  }
`
export type DeleteCustomPageMutationFn = Apollo.MutationFunction<
  DeleteCustomPageMutation,
  DeleteCustomPageMutationVariables
>

/**
 * __useDeleteCustomPageMutation__
 *
 * To run a mutation, you first call `useDeleteCustomPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomPageMutation, { data, loading, error }] = useDeleteCustomPageMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCustomPageMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteCustomPageMutation, DeleteCustomPageMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteCustomPageMutation, DeleteCustomPageMutationVariables>(
    DeleteCustomPageDocument,
    options,
  )
}
export type DeleteCustomPageMutationHookResult = ReturnType<typeof useDeleteCustomPageMutation>
export type DeleteCustomPageMutationResult = Apollo.MutationResult<DeleteCustomPageMutation>
export type DeleteCustomPageMutationOptions = Apollo.BaseMutationOptions<
  DeleteCustomPageMutation,
  DeleteCustomPageMutationVariables
>
export const MoveCustomPageDocument = gql`
  mutation moveCustomPage($input: MoveCustomPageInput!) {
    moveCustomPage(input: $input) {
      id
      position
    }
  }
`
export type MoveCustomPageMutationFn = Apollo.MutationFunction<MoveCustomPageMutation, MoveCustomPageMutationVariables>

/**
 * __useMoveCustomPageMutation__
 *
 * To run a mutation, you first call `useMoveCustomPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveCustomPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveCustomPageMutation, { data, loading, error }] = useMoveCustomPageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMoveCustomPageMutation(
  baseOptions?: Apollo.MutationHookOptions<MoveCustomPageMutation, MoveCustomPageMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<MoveCustomPageMutation, MoveCustomPageMutationVariables>(MoveCustomPageDocument, options)
}
export type MoveCustomPageMutationHookResult = ReturnType<typeof useMoveCustomPageMutation>
export type MoveCustomPageMutationResult = Apollo.MutationResult<MoveCustomPageMutation>
export type MoveCustomPageMutationOptions = Apollo.BaseMutationOptions<
  MoveCustomPageMutation,
  MoveCustomPageMutationVariables
>
export const SetHomePageDocument = gql`
  mutation setHomePage($id: ID!) {
    setHomePage(id: $id) {
      id
    }
  }
`
export type SetHomePageMutationFn = Apollo.MutationFunction<SetHomePageMutation, SetHomePageMutationVariables>

/**
 * __useSetHomePageMutation__
 *
 * To run a mutation, you first call `useSetHomePageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetHomePageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setHomePageMutation, { data, loading, error }] = useSetHomePageMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSetHomePageMutation(
  baseOptions?: Apollo.MutationHookOptions<SetHomePageMutation, SetHomePageMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SetHomePageMutation, SetHomePageMutationVariables>(SetHomePageDocument, options)
}
export type SetHomePageMutationHookResult = ReturnType<typeof useSetHomePageMutation>
export type SetHomePageMutationResult = Apollo.MutationResult<SetHomePageMutation>
export type SetHomePageMutationOptions = Apollo.BaseMutationOptions<SetHomePageMutation, SetHomePageMutationVariables>
export const DefaultConfigDocument = gql`
  query defaultConfig {
    defaultConfig {
      ...defaultConfigContent
    }
  }
  ${DefaultConfigContentFragmentDoc}
`

/**
 * __useDefaultConfigQuery__
 *
 * To run a query within a React component, call `useDefaultConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useDefaultConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDefaultConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useDefaultConfigQuery(
  baseOptions?: Apollo.QueryHookOptions<DefaultConfigQuery, DefaultConfigQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<DefaultConfigQuery, DefaultConfigQueryVariables>(DefaultConfigDocument, options)
}
export function useDefaultConfigLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DefaultConfigQuery, DefaultConfigQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<DefaultConfigQuery, DefaultConfigQueryVariables>(DefaultConfigDocument, options)
}
export type DefaultConfigQueryHookResult = ReturnType<typeof useDefaultConfigQuery>
export type DefaultConfigLazyQueryHookResult = ReturnType<typeof useDefaultConfigLazyQuery>
export type DefaultConfigQueryResult = Apollo.QueryResult<DefaultConfigQuery, DefaultConfigQueryVariables>
export const InitDefaultConfigDocument = gql`
  mutation initDefaultConfig($input: InitDefaultConfigInput!) {
    initDefaultConfig(input: $input) {
      ...defaultConfigContent
    }
  }
  ${DefaultConfigContentFragmentDoc}
`
export type InitDefaultConfigMutationFn = Apollo.MutationFunction<
  InitDefaultConfigMutation,
  InitDefaultConfigMutationVariables
>

/**
 * __useInitDefaultConfigMutation__
 *
 * To run a mutation, you first call `useInitDefaultConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitDefaultConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initDefaultConfigMutation, { data, loading, error }] = useInitDefaultConfigMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInitDefaultConfigMutation(
  baseOptions?: Apollo.MutationHookOptions<InitDefaultConfigMutation, InitDefaultConfigMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<InitDefaultConfigMutation, InitDefaultConfigMutationVariables>(
    InitDefaultConfigDocument,
    options,
  )
}
export type InitDefaultConfigMutationHookResult = ReturnType<typeof useInitDefaultConfigMutation>
export type InitDefaultConfigMutationResult = Apollo.MutationResult<InitDefaultConfigMutation>
export type InitDefaultConfigMutationOptions = Apollo.BaseMutationOptions<
  InitDefaultConfigMutation,
  InitDefaultConfigMutationVariables
>
export const UpdateDefaultConfigDocument = gql`
  mutation updateDefaultConfig($input: UpdateDefaultConfigInput!) {
    updateDefaultConfig(input: $input) {
      ...defaultConfigContent
    }
  }
  ${DefaultConfigContentFragmentDoc}
`
export type UpdateDefaultConfigMutationFn = Apollo.MutationFunction<
  UpdateDefaultConfigMutation,
  UpdateDefaultConfigMutationVariables
>

/**
 * __useUpdateDefaultConfigMutation__
 *
 * To run a mutation, you first call `useUpdateDefaultConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDefaultConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDefaultConfigMutation, { data, loading, error }] = useUpdateDefaultConfigMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDefaultConfigMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateDefaultConfigMutation, UpdateDefaultConfigMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateDefaultConfigMutation, UpdateDefaultConfigMutationVariables>(
    UpdateDefaultConfigDocument,
    options,
  )
}
export type UpdateDefaultConfigMutationHookResult = ReturnType<typeof useUpdateDefaultConfigMutation>
export type UpdateDefaultConfigMutationResult = Apollo.MutationResult<UpdateDefaultConfigMutation>
export type UpdateDefaultConfigMutationOptions = Apollo.BaseMutationOptions<
  UpdateDefaultConfigMutation,
  UpdateDefaultConfigMutationVariables
>
export const EditedCategoriesDocument = gql`
  query editedCategories($filters: EditedCategoryFilters) {
    editedCategories(filters: $filters) {
      editedCategories {
        ...editedCategoryContent
      }
      totalCount
    }
  }
  ${EditedCategoryContentFragmentDoc}
`

/**
 * __useEditedCategoriesQuery__
 *
 * To run a query within a React component, call `useEditedCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditedCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditedCategoriesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useEditedCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<EditedCategoriesQuery, EditedCategoriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<EditedCategoriesQuery, EditedCategoriesQueryVariables>(EditedCategoriesDocument, options)
}
export function useEditedCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EditedCategoriesQuery, EditedCategoriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<EditedCategoriesQuery, EditedCategoriesQueryVariables>(EditedCategoriesDocument, options)
}
export type EditedCategoriesQueryHookResult = ReturnType<typeof useEditedCategoriesQuery>
export type EditedCategoriesLazyQueryHookResult = ReturnType<typeof useEditedCategoriesLazyQuery>
export type EditedCategoriesQueryResult = Apollo.QueryResult<EditedCategoriesQuery, EditedCategoriesQueryVariables>
export const EditedCategoryDocument = gql`
  query editedCategory($editedCategoryId: ID!) {
    editedCategory(id: $editedCategoryId) {
      ...editedCategoryContent
    }
  }
  ${EditedCategoryContentFragmentDoc}
`

/**
 * __useEditedCategoryQuery__
 *
 * To run a query within a React component, call `useEditedCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditedCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditedCategoryQuery({
 *   variables: {
 *      editedCategoryId: // value for 'editedCategoryId'
 *   },
 * });
 */
export function useEditedCategoryQuery(
  baseOptions: Apollo.QueryHookOptions<EditedCategoryQuery, EditedCategoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<EditedCategoryQuery, EditedCategoryQueryVariables>(EditedCategoryDocument, options)
}
export function useEditedCategoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EditedCategoryQuery, EditedCategoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<EditedCategoryQuery, EditedCategoryQueryVariables>(EditedCategoryDocument, options)
}
export type EditedCategoryQueryHookResult = ReturnType<typeof useEditedCategoryQuery>
export type EditedCategoryLazyQueryHookResult = ReturnType<typeof useEditedCategoryLazyQuery>
export type EditedCategoryQueryResult = Apollo.QueryResult<EditedCategoryQuery, EditedCategoryQueryVariables>
export const CreateEditedCategoryDocument = gql`
  mutation createEditedCategory($input: CreateEditedCategoryInput!) {
    createEditedCategory(input: $input) {
      ...editedCategoryContent
    }
  }
  ${EditedCategoryContentFragmentDoc}
`
export type CreateEditedCategoryMutationFn = Apollo.MutationFunction<
  CreateEditedCategoryMutation,
  CreateEditedCategoryMutationVariables
>

/**
 * __useCreateEditedCategoryMutation__
 *
 * To run a mutation, you first call `useCreateEditedCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEditedCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEditedCategoryMutation, { data, loading, error }] = useCreateEditedCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEditedCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateEditedCategoryMutation, CreateEditedCategoryMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateEditedCategoryMutation, CreateEditedCategoryMutationVariables>(
    CreateEditedCategoryDocument,
    options,
  )
}
export type CreateEditedCategoryMutationHookResult = ReturnType<typeof useCreateEditedCategoryMutation>
export type CreateEditedCategoryMutationResult = Apollo.MutationResult<CreateEditedCategoryMutation>
export type CreateEditedCategoryMutationOptions = Apollo.BaseMutationOptions<
  CreateEditedCategoryMutation,
  CreateEditedCategoryMutationVariables
>
export const UpdateEditedCategoryDocument = gql`
  mutation updateEditedCategory($id: ID!, $input: UpdateEditedCategoryInput!) {
    updateEditedCategory(id: $id, input: $input) {
      ...editedCategoryContent
    }
  }
  ${EditedCategoryContentFragmentDoc}
`
export type UpdateEditedCategoryMutationFn = Apollo.MutationFunction<
  UpdateEditedCategoryMutation,
  UpdateEditedCategoryMutationVariables
>

/**
 * __useUpdateEditedCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateEditedCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEditedCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEditedCategoryMutation, { data, loading, error }] = useUpdateEditedCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEditedCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateEditedCategoryMutation, UpdateEditedCategoryMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateEditedCategoryMutation, UpdateEditedCategoryMutationVariables>(
    UpdateEditedCategoryDocument,
    options,
  )
}
export type UpdateEditedCategoryMutationHookResult = ReturnType<typeof useUpdateEditedCategoryMutation>
export type UpdateEditedCategoryMutationResult = Apollo.MutationResult<UpdateEditedCategoryMutation>
export type UpdateEditedCategoryMutationOptions = Apollo.BaseMutationOptions<
  UpdateEditedCategoryMutation,
  UpdateEditedCategoryMutationVariables
>
export const DeleteEditedCategoryDocument = gql`
  mutation deleteEditedCategory($id: ID!) {
    deleteEditedCategory(id: $id) {
      id
    }
  }
`
export type DeleteEditedCategoryMutationFn = Apollo.MutationFunction<
  DeleteEditedCategoryMutation,
  DeleteEditedCategoryMutationVariables
>

/**
 * __useDeleteEditedCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteEditedCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEditedCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEditedCategoryMutation, { data, loading, error }] = useDeleteEditedCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEditedCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteEditedCategoryMutation, DeleteEditedCategoryMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteEditedCategoryMutation, DeleteEditedCategoryMutationVariables>(
    DeleteEditedCategoryDocument,
    options,
  )
}
export type DeleteEditedCategoryMutationHookResult = ReturnType<typeof useDeleteEditedCategoryMutation>
export type DeleteEditedCategoryMutationResult = Apollo.MutationResult<DeleteEditedCategoryMutation>
export type DeleteEditedCategoryMutationOptions = Apollo.BaseMutationOptions<
  DeleteEditedCategoryMutation,
  DeleteEditedCategoryMutationVariables
>
export const MoveEditedCategoryDocument = gql`
  mutation moveEditedCategory($input: MoveEditedCategoryInput!) {
    moveEditedCategory(input: $input) {
      id
      position
    }
  }
`
export type MoveEditedCategoryMutationFn = Apollo.MutationFunction<
  MoveEditedCategoryMutation,
  MoveEditedCategoryMutationVariables
>

/**
 * __useMoveEditedCategoryMutation__
 *
 * To run a mutation, you first call `useMoveEditedCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveEditedCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveEditedCategoryMutation, { data, loading, error }] = useMoveEditedCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMoveEditedCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<MoveEditedCategoryMutation, MoveEditedCategoryMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<MoveEditedCategoryMutation, MoveEditedCategoryMutationVariables>(
    MoveEditedCategoryDocument,
    options,
  )
}
export type MoveEditedCategoryMutationHookResult = ReturnType<typeof useMoveEditedCategoryMutation>
export type MoveEditedCategoryMutationResult = Apollo.MutationResult<MoveEditedCategoryMutation>
export type MoveEditedCategoryMutationOptions = Apollo.BaseMutationOptions<
  MoveEditedCategoryMutation,
  MoveEditedCategoryMutationVariables
>
export const FileImageUrlDocument = gql`
  query fileImageUrl($fileId: ID!) {
    fileImageUrl(fileId: $fileId)
  }
`

/**
 * __useFileImageUrlQuery__
 *
 * To run a query within a React component, call `useFileImageUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useFileImageUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFileImageUrlQuery({
 *   variables: {
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useFileImageUrlQuery(
  baseOptions: Apollo.QueryHookOptions<FileImageUrlQuery, FileImageUrlQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FileImageUrlQuery, FileImageUrlQueryVariables>(FileImageUrlDocument, options)
}
export function useFileImageUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FileImageUrlQuery, FileImageUrlQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FileImageUrlQuery, FileImageUrlQueryVariables>(FileImageUrlDocument, options)
}
export type FileImageUrlQueryHookResult = ReturnType<typeof useFileImageUrlQuery>
export type FileImageUrlLazyQueryHookResult = ReturnType<typeof useFileImageUrlLazyQuery>
export type FileImageUrlQueryResult = Apollo.QueryResult<FileImageUrlQuery, FileImageUrlQueryVariables>
export const FilesDocument = gql`
  query files($mimetype: String, $search: String, $first: Int, $offset: Int) {
    files(mimetype: $mimetype, search: $search, first: $first, offset: $offset) {
      totalCount
      files {
        ...fileContent
      }
    }
  }
  ${FileContentFragmentDoc}
`

/**
 * __useFilesQuery__
 *
 * To run a query within a React component, call `useFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilesQuery({
 *   variables: {
 *      mimetype: // value for 'mimetype'
 *      search: // value for 'search'
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useFilesQuery(baseOptions?: Apollo.QueryHookOptions<FilesQuery, FilesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FilesQuery, FilesQueryVariables>(FilesDocument, options)
}
export function useFilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FilesQuery, FilesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FilesQuery, FilesQueryVariables>(FilesDocument, options)
}
export type FilesQueryHookResult = ReturnType<typeof useFilesQuery>
export type FilesLazyQueryHookResult = ReturnType<typeof useFilesLazyQuery>
export type FilesQueryResult = Apollo.QueryResult<FilesQuery, FilesQueryVariables>
export const FileDocument = gql`
  query file($fileId: ID!) {
    file(id: $fileId) {
      ...fileContent
    }
  }
  ${FileContentFragmentDoc}
`

/**
 * __useFileQuery__
 *
 * To run a query within a React component, call `useFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFileQuery({
 *   variables: {
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useFileQuery(baseOptions: Apollo.QueryHookOptions<FileQuery, FileQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FileQuery, FileQueryVariables>(FileDocument, options)
}
export function useFileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FileQuery, FileQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FileQuery, FileQueryVariables>(FileDocument, options)
}
export type FileQueryHookResult = ReturnType<typeof useFileQuery>
export type FileLazyQueryHookResult = ReturnType<typeof useFileLazyQuery>
export type FileQueryResult = Apollo.QueryResult<FileQuery, FileQueryVariables>
export const FrontPagesDocument = gql`
  query frontPages($current: Boolean) {
    frontPages(current: $current) {
      ...frontPageContent
    }
  }
  ${FrontPageContentFragmentDoc}
`

/**
 * __useFrontPagesQuery__
 *
 * To run a query within a React component, call `useFrontPagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFrontPagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFrontPagesQuery({
 *   variables: {
 *      current: // value for 'current'
 *   },
 * });
 */
export function useFrontPagesQuery(baseOptions?: Apollo.QueryHookOptions<FrontPagesQuery, FrontPagesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FrontPagesQuery, FrontPagesQueryVariables>(FrontPagesDocument, options)
}
export function useFrontPagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FrontPagesQuery, FrontPagesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FrontPagesQuery, FrontPagesQueryVariables>(FrontPagesDocument, options)
}
export type FrontPagesQueryHookResult = ReturnType<typeof useFrontPagesQuery>
export type FrontPagesLazyQueryHookResult = ReturnType<typeof useFrontPagesLazyQuery>
export type FrontPagesQueryResult = Apollo.QueryResult<FrontPagesQuery, FrontPagesQueryVariables>
export const CreateFrontPageDocument = gql`
  mutation createFrontPage($input: CreateFrontPageInput!) {
    createFrontPage(input: $input) {
      ...frontPageContent
    }
  }
  ${FrontPageContentFragmentDoc}
`
export type CreateFrontPageMutationFn = Apollo.MutationFunction<
  CreateFrontPageMutation,
  CreateFrontPageMutationVariables
>

/**
 * __useCreateFrontPageMutation__
 *
 * To run a mutation, you first call `useCreateFrontPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFrontPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFrontPageMutation, { data, loading, error }] = useCreateFrontPageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFrontPageMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateFrontPageMutation, CreateFrontPageMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateFrontPageMutation, CreateFrontPageMutationVariables>(CreateFrontPageDocument, options)
}
export type CreateFrontPageMutationHookResult = ReturnType<typeof useCreateFrontPageMutation>
export type CreateFrontPageMutationResult = Apollo.MutationResult<CreateFrontPageMutation>
export type CreateFrontPageMutationOptions = Apollo.BaseMutationOptions<
  CreateFrontPageMutation,
  CreateFrontPageMutationVariables
>
export const UpdateFrontPageDocument = gql`
  mutation updateFrontPage($id: ID!, $input: UpdateFrontPageInput!) {
    updateFrontPage(id: $id, input: $input) {
      ...frontPageContent
    }
  }
  ${FrontPageContentFragmentDoc}
`
export type UpdateFrontPageMutationFn = Apollo.MutationFunction<
  UpdateFrontPageMutation,
  UpdateFrontPageMutationVariables
>

/**
 * __useUpdateFrontPageMutation__
 *
 * To run a mutation, you first call `useUpdateFrontPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFrontPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFrontPageMutation, { data, loading, error }] = useUpdateFrontPageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFrontPageMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateFrontPageMutation, UpdateFrontPageMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateFrontPageMutation, UpdateFrontPageMutationVariables>(UpdateFrontPageDocument, options)
}
export type UpdateFrontPageMutationHookResult = ReturnType<typeof useUpdateFrontPageMutation>
export type UpdateFrontPageMutationResult = Apollo.MutationResult<UpdateFrontPageMutation>
export type UpdateFrontPageMutationOptions = Apollo.BaseMutationOptions<
  UpdateFrontPageMutation,
  UpdateFrontPageMutationVariables
>
export const DeleteFrontPageDocument = gql`
  mutation deleteFrontPage($id: ID!) {
    deleteFrontPage(id: $id)
  }
`
export type DeleteFrontPageMutationFn = Apollo.MutationFunction<
  DeleteFrontPageMutation,
  DeleteFrontPageMutationVariables
>

/**
 * __useDeleteFrontPageMutation__
 *
 * To run a mutation, you first call `useDeleteFrontPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFrontPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFrontPageMutation, { data, loading, error }] = useDeleteFrontPageMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFrontPageMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteFrontPageMutation, DeleteFrontPageMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteFrontPageMutation, DeleteFrontPageMutationVariables>(DeleteFrontPageDocument, options)
}
export type DeleteFrontPageMutationHookResult = ReturnType<typeof useDeleteFrontPageMutation>
export type DeleteFrontPageMutationResult = Apollo.MutationResult<DeleteFrontPageMutation>
export type DeleteFrontPageMutationOptions = Apollo.BaseMutationOptions<
  DeleteFrontPageMutation,
  DeleteFrontPageMutationVariables
>
export const FrontPageSportItemSetsDocument = gql`
  query frontPageSportItemSets($filters: SportItemSetFilters, $orderBy: [SportItemSetSort!]) {
    sportItemSets(filters: $filters, orderBy: $orderBy) {
      sportItemSets {
        ...sportItemSetFrontPageContent
      }
      totalCount
    }
  }
  ${SportItemSetFrontPageContentFragmentDoc}
`

/**
 * __useFrontPageSportItemSetsQuery__
 *
 * To run a query within a React component, call `useFrontPageSportItemSetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFrontPageSportItemSetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFrontPageSportItemSetsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useFrontPageSportItemSetsQuery(
  baseOptions?: Apollo.QueryHookOptions<FrontPageSportItemSetsQuery, FrontPageSportItemSetsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FrontPageSportItemSetsQuery, FrontPageSportItemSetsQueryVariables>(
    FrontPageSportItemSetsDocument,
    options,
  )
}
export function useFrontPageSportItemSetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FrontPageSportItemSetsQuery, FrontPageSportItemSetsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FrontPageSportItemSetsQuery, FrontPageSportItemSetsQueryVariables>(
    FrontPageSportItemSetsDocument,
    options,
  )
}
export type FrontPageSportItemSetsQueryHookResult = ReturnType<typeof useFrontPageSportItemSetsQuery>
export type FrontPageSportItemSetsLazyQueryHookResult = ReturnType<typeof useFrontPageSportItemSetsLazyQuery>
export type FrontPageSportItemSetsQueryResult = Apollo.QueryResult<
  FrontPageSportItemSetsQuery,
  FrontPageSportItemSetsQueryVariables
>
export const LegalRestrictionsDocument = gql`
  query legalRestrictions {
    legalRestrictions {
      ...legalRestrictionContent
    }
  }
  ${LegalRestrictionContentFragmentDoc}
`

/**
 * __useLegalRestrictionsQuery__
 *
 * To run a query within a React component, call `useLegalRestrictionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLegalRestrictionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLegalRestrictionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useLegalRestrictionsQuery(
  baseOptions?: Apollo.QueryHookOptions<LegalRestrictionsQuery, LegalRestrictionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<LegalRestrictionsQuery, LegalRestrictionsQueryVariables>(LegalRestrictionsDocument, options)
}
export function useLegalRestrictionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LegalRestrictionsQuery, LegalRestrictionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<LegalRestrictionsQuery, LegalRestrictionsQueryVariables>(
    LegalRestrictionsDocument,
    options,
  )
}
export type LegalRestrictionsQueryHookResult = ReturnType<typeof useLegalRestrictionsQuery>
export type LegalRestrictionsLazyQueryHookResult = ReturnType<typeof useLegalRestrictionsLazyQuery>
export type LegalRestrictionsQueryResult = Apollo.QueryResult<LegalRestrictionsQuery, LegalRestrictionsQueryVariables>
export const CreateLegalRestrictionDocument = gql`
  mutation createLegalRestriction($input: CreateLegalRestrictionInput!) {
    createLegalRestriction(input: $input) {
      ...legalRestrictionContent
    }
  }
  ${LegalRestrictionContentFragmentDoc}
`
export type CreateLegalRestrictionMutationFn = Apollo.MutationFunction<
  CreateLegalRestrictionMutation,
  CreateLegalRestrictionMutationVariables
>

/**
 * __useCreateLegalRestrictionMutation__
 *
 * To run a mutation, you first call `useCreateLegalRestrictionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLegalRestrictionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLegalRestrictionMutation, { data, loading, error }] = useCreateLegalRestrictionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLegalRestrictionMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateLegalRestrictionMutation, CreateLegalRestrictionMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateLegalRestrictionMutation, CreateLegalRestrictionMutationVariables>(
    CreateLegalRestrictionDocument,
    options,
  )
}
export type CreateLegalRestrictionMutationHookResult = ReturnType<typeof useCreateLegalRestrictionMutation>
export type CreateLegalRestrictionMutationResult = Apollo.MutationResult<CreateLegalRestrictionMutation>
export type CreateLegalRestrictionMutationOptions = Apollo.BaseMutationOptions<
  CreateLegalRestrictionMutation,
  CreateLegalRestrictionMutationVariables
>
export const UpdateLegalRestrictionDocument = gql`
  mutation updateLegalRestriction($id: ID!, $input: UpdateLegalRestrictionInput!) {
    updateLegalRestriction(id: $id, input: $input) {
      ...legalRestrictionContent
    }
  }
  ${LegalRestrictionContentFragmentDoc}
`
export type UpdateLegalRestrictionMutationFn = Apollo.MutationFunction<
  UpdateLegalRestrictionMutation,
  UpdateLegalRestrictionMutationVariables
>

/**
 * __useUpdateLegalRestrictionMutation__
 *
 * To run a mutation, you first call `useUpdateLegalRestrictionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLegalRestrictionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLegalRestrictionMutation, { data, loading, error }] = useUpdateLegalRestrictionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLegalRestrictionMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateLegalRestrictionMutation, UpdateLegalRestrictionMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateLegalRestrictionMutation, UpdateLegalRestrictionMutationVariables>(
    UpdateLegalRestrictionDocument,
    options,
  )
}
export type UpdateLegalRestrictionMutationHookResult = ReturnType<typeof useUpdateLegalRestrictionMutation>
export type UpdateLegalRestrictionMutationResult = Apollo.MutationResult<UpdateLegalRestrictionMutation>
export type UpdateLegalRestrictionMutationOptions = Apollo.BaseMutationOptions<
  UpdateLegalRestrictionMutation,
  UpdateLegalRestrictionMutationVariables
>
export const DeleteLegalRestrictionDocument = gql`
  mutation deleteLegalRestriction($id: ID!) {
    deleteLegalRestriction(id: $id)
  }
`
export type DeleteLegalRestrictionMutationFn = Apollo.MutationFunction<
  DeleteLegalRestrictionMutation,
  DeleteLegalRestrictionMutationVariables
>

/**
 * __useDeleteLegalRestrictionMutation__
 *
 * To run a mutation, you first call `useDeleteLegalRestrictionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLegalRestrictionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLegalRestrictionMutation, { data, loading, error }] = useDeleteLegalRestrictionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLegalRestrictionMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteLegalRestrictionMutation, DeleteLegalRestrictionMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteLegalRestrictionMutation, DeleteLegalRestrictionMutationVariables>(
    DeleteLegalRestrictionDocument,
    options,
  )
}
export type DeleteLegalRestrictionMutationHookResult = ReturnType<typeof useDeleteLegalRestrictionMutation>
export type DeleteLegalRestrictionMutationResult = Apollo.MutationResult<DeleteLegalRestrictionMutation>
export type DeleteLegalRestrictionMutationOptions = Apollo.BaseMutationOptions<
  DeleteLegalRestrictionMutation,
  DeleteLegalRestrictionMutationVariables
>
export const CreateLinkDocument = gql`
  mutation createLink($input: CreateLinkInput!) {
    createLink(input: $input) {
      ...linkItemContent
    }
  }
  ${LinkItemContentFragmentDoc}
`
export type CreateLinkMutationFn = Apollo.MutationFunction<CreateLinkMutation, CreateLinkMutationVariables>

/**
 * __useCreateLinkMutation__
 *
 * To run a mutation, you first call `useCreateLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLinkMutation, { data, loading, error }] = useCreateLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateLinkMutation, CreateLinkMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateLinkMutation, CreateLinkMutationVariables>(CreateLinkDocument, options)
}
export type CreateLinkMutationHookResult = ReturnType<typeof useCreateLinkMutation>
export type CreateLinkMutationResult = Apollo.MutationResult<CreateLinkMutation>
export type CreateLinkMutationOptions = Apollo.BaseMutationOptions<CreateLinkMutation, CreateLinkMutationVariables>
export const UpdateLinkDocument = gql`
  mutation updateLink($input: UpdateLinkInput!) {
    updateLink(input: $input) {
      ...linkItemContent
    }
  }
  ${LinkItemContentFragmentDoc}
`
export type UpdateLinkMutationFn = Apollo.MutationFunction<UpdateLinkMutation, UpdateLinkMutationVariables>

/**
 * __useUpdateLinkMutation__
 *
 * To run a mutation, you first call `useUpdateLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLinkMutation, { data, loading, error }] = useUpdateLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateLinkMutation, UpdateLinkMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateLinkMutation, UpdateLinkMutationVariables>(UpdateLinkDocument, options)
}
export type UpdateLinkMutationHookResult = ReturnType<typeof useUpdateLinkMutation>
export type UpdateLinkMutationResult = Apollo.MutationResult<UpdateLinkMutation>
export type UpdateLinkMutationOptions = Apollo.BaseMutationOptions<UpdateLinkMutation, UpdateLinkMutationVariables>
export const DeleteLinkDocument = gql`
  mutation deleteLink($linkId: ID!) {
    deleteLink(id: $linkId) {
      id
    }
  }
`
export type DeleteLinkMutationFn = Apollo.MutationFunction<DeleteLinkMutation, DeleteLinkMutationVariables>

/**
 * __useDeleteLinkMutation__
 *
 * To run a mutation, you first call `useDeleteLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLinkMutation, { data, loading, error }] = useDeleteLinkMutation({
 *   variables: {
 *      linkId: // value for 'linkId'
 *   },
 * });
 */
export function useDeleteLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteLinkMutation, DeleteLinkMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteLinkMutation, DeleteLinkMutationVariables>(DeleteLinkDocument, options)
}
export type DeleteLinkMutationHookResult = ReturnType<typeof useDeleteLinkMutation>
export type DeleteLinkMutationResult = Apollo.MutationResult<DeleteLinkMutation>
export type DeleteLinkMutationOptions = Apollo.BaseMutationOptions<DeleteLinkMutation, DeleteLinkMutationVariables>
export const GetLinksDocument = gql`
  query getLinks($filters: LinkFilters, $offset: Int, $first: Int, $orderBy: LinkSort) {
    links(filters: $filters, offset: $offset, first: $first, orderBy: $orderBy) {
      links {
        ...linkItemContent
      }
      totalCount
    }
  }
  ${LinkItemContentFragmentDoc}
`

/**
 * __useGetLinksQuery__
 *
 * To run a query within a React component, call `useGetLinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLinksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLinksQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      offset: // value for 'offset'
 *      first: // value for 'first'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetLinksQuery(baseOptions?: Apollo.QueryHookOptions<GetLinksQuery, GetLinksQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetLinksQuery, GetLinksQueryVariables>(GetLinksDocument, options)
}
export function useGetLinksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLinksQuery, GetLinksQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetLinksQuery, GetLinksQueryVariables>(GetLinksDocument, options)
}
export type GetLinksQueryHookResult = ReturnType<typeof useGetLinksQuery>
export type GetLinksLazyQueryHookResult = ReturnType<typeof useGetLinksLazyQuery>
export type GetLinksQueryResult = Apollo.QueryResult<GetLinksQuery, GetLinksQueryVariables>
export const LinkDocument = gql`
  query link($linkId: ID!) {
    link(id: $linkId) {
      ...linkItemContent
    }
  }
  ${LinkItemContentFragmentDoc}
`

/**
 * __useLinkQuery__
 *
 * To run a query within a React component, call `useLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinkQuery({
 *   variables: {
 *      linkId: // value for 'linkId'
 *   },
 * });
 */
export function useLinkQuery(baseOptions: Apollo.QueryHookOptions<LinkQuery, LinkQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<LinkQuery, LinkQueryVariables>(LinkDocument, options)
}
export function useLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LinkQuery, LinkQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<LinkQuery, LinkQueryVariables>(LinkDocument, options)
}
export type LinkQueryHookResult = ReturnType<typeof useLinkQuery>
export type LinkLazyQueryHookResult = ReturnType<typeof useLinkLazyQuery>
export type LinkQueryResult = Apollo.QueryResult<LinkQuery, LinkQueryVariables>
export const UpdateLiveStreamDocument = gql`
  mutation updateLiveStream($input: UpdateWildmokaLiveStreamInput!) {
    updateLiveStream(input: $input) {
      ...liveStreamContent
    }
  }
  ${LiveStreamContentFragmentDoc}
`
export type UpdateLiveStreamMutationFn = Apollo.MutationFunction<
  UpdateLiveStreamMutation,
  UpdateLiveStreamMutationVariables
>

/**
 * __useUpdateLiveStreamMutation__
 *
 * To run a mutation, you first call `useUpdateLiveStreamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLiveStreamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLiveStreamMutation, { data, loading, error }] = useUpdateLiveStreamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLiveStreamMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateLiveStreamMutation, UpdateLiveStreamMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateLiveStreamMutation, UpdateLiveStreamMutationVariables>(
    UpdateLiveStreamDocument,
    options,
  )
}
export type UpdateLiveStreamMutationHookResult = ReturnType<typeof useUpdateLiveStreamMutation>
export type UpdateLiveStreamMutationResult = Apollo.MutationResult<UpdateLiveStreamMutation>
export type UpdateLiveStreamMutationOptions = Apollo.BaseMutationOptions<
  UpdateLiveStreamMutation,
  UpdateLiveStreamMutationVariables
>
export const UpdateLiveStreamStateDocument = gql`
  mutation updateLiveStreamState($id: ID!, $state: WMEventState!) {
    updateLiveStreamState(id: $id, state: $state) {
      ...liveStreamContent
    }
  }
  ${LiveStreamContentFragmentDoc}
`
export type UpdateLiveStreamStateMutationFn = Apollo.MutationFunction<
  UpdateLiveStreamStateMutation,
  UpdateLiveStreamStateMutationVariables
>

/**
 * __useUpdateLiveStreamStateMutation__
 *
 * To run a mutation, you first call `useUpdateLiveStreamStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLiveStreamStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLiveStreamStateMutation, { data, loading, error }] = useUpdateLiveStreamStateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useUpdateLiveStreamStateMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateLiveStreamStateMutation, UpdateLiveStreamStateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateLiveStreamStateMutation, UpdateLiveStreamStateMutationVariables>(
    UpdateLiveStreamStateDocument,
    options,
  )
}
export type UpdateLiveStreamStateMutationHookResult = ReturnType<typeof useUpdateLiveStreamStateMutation>
export type UpdateLiveStreamStateMutationResult = Apollo.MutationResult<UpdateLiveStreamStateMutation>
export type UpdateLiveStreamStateMutationOptions = Apollo.BaseMutationOptions<
  UpdateLiveStreamStateMutation,
  UpdateLiveStreamStateMutationVariables
>
export const SetLiveStreamStateDocument = gql`
  mutation setLiveStreamState($id: ID!, $state: LiveStreamState!) {
    setLiveStreamState(id: $id, state: $state) {
      ...liveStreamContent
    }
  }
  ${LiveStreamContentFragmentDoc}
`
export type SetLiveStreamStateMutationFn = Apollo.MutationFunction<
  SetLiveStreamStateMutation,
  SetLiveStreamStateMutationVariables
>

/**
 * __useSetLiveStreamStateMutation__
 *
 * To run a mutation, you first call `useSetLiveStreamStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetLiveStreamStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setLiveStreamStateMutation, { data, loading, error }] = useSetLiveStreamStateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useSetLiveStreamStateMutation(
  baseOptions?: Apollo.MutationHookOptions<SetLiveStreamStateMutation, SetLiveStreamStateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SetLiveStreamStateMutation, SetLiveStreamStateMutationVariables>(
    SetLiveStreamStateDocument,
    options,
  )
}
export type SetLiveStreamStateMutationHookResult = ReturnType<typeof useSetLiveStreamStateMutation>
export type SetLiveStreamStateMutationResult = Apollo.MutationResult<SetLiveStreamStateMutation>
export type SetLiveStreamStateMutationOptions = Apollo.BaseMutationOptions<
  SetLiveStreamStateMutation,
  SetLiveStreamStateMutationVariables
>
export const ActiveLiveStreamsDocument = gql`
  query activeLiveStreams {
    activeLiveStreams {
      liveStreams {
        id
      }
      sportItems {
        id
      }
    }
  }
`

/**
 * __useActiveLiveStreamsQuery__
 *
 * To run a query within a React component, call `useActiveLiveStreamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveLiveStreamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveLiveStreamsQuery({
 *   variables: {
 *   },
 * });
 */
export function useActiveLiveStreamsQuery(
  baseOptions?: Apollo.QueryHookOptions<ActiveLiveStreamsQuery, ActiveLiveStreamsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ActiveLiveStreamsQuery, ActiveLiveStreamsQueryVariables>(ActiveLiveStreamsDocument, options)
}
export function useActiveLiveStreamsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ActiveLiveStreamsQuery, ActiveLiveStreamsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ActiveLiveStreamsQuery, ActiveLiveStreamsQueryVariables>(
    ActiveLiveStreamsDocument,
    options,
  )
}
export type ActiveLiveStreamsQueryHookResult = ReturnType<typeof useActiveLiveStreamsQuery>
export type ActiveLiveStreamsLazyQueryHookResult = ReturnType<typeof useActiveLiveStreamsLazyQuery>
export type ActiveLiveStreamsQueryResult = Apollo.QueryResult<ActiveLiveStreamsQuery, ActiveLiveStreamsQueryVariables>
export const CheckStreamUnusedDocument = gql`
  query checkStreamUnused($streamId: WildmokaID!, $sportItemSetId: ID!, $from: Date!, $to: Date!) {
    checkStreamUnused(streamId: $streamId, sportItemSetId: $sportItemSetId, from: $from, to: $to) {
      ...liveStreamStreamConflictContent
    }
  }
  ${LiveStreamStreamConflictContentFragmentDoc}
`

/**
 * __useCheckStreamUnusedQuery__
 *
 * To run a query within a React component, call `useCheckStreamUnusedQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckStreamUnusedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckStreamUnusedQuery({
 *   variables: {
 *      streamId: // value for 'streamId'
 *      sportItemSetId: // value for 'sportItemSetId'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useCheckStreamUnusedQuery(
  baseOptions: Apollo.QueryHookOptions<CheckStreamUnusedQuery, CheckStreamUnusedQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CheckStreamUnusedQuery, CheckStreamUnusedQueryVariables>(CheckStreamUnusedDocument, options)
}
export function useCheckStreamUnusedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CheckStreamUnusedQuery, CheckStreamUnusedQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CheckStreamUnusedQuery, CheckStreamUnusedQueryVariables>(
    CheckStreamUnusedDocument,
    options,
  )
}
export type CheckStreamUnusedQueryHookResult = ReturnType<typeof useCheckStreamUnusedQuery>
export type CheckStreamUnusedLazyQueryHookResult = ReturnType<typeof useCheckStreamUnusedLazyQuery>
export type CheckStreamUnusedQueryResult = Apollo.QueryResult<CheckStreamUnusedQuery, CheckStreamUnusedQueryVariables>
export const GetLivestreamPlaybackUrlDocument = gql`
  query getLivestreamPlaybackUrl($liveStreamId: ID!, $expirationInSeconds: Int) {
    liveStream(id: $liveStreamId) {
      id
      playbackUrl(expirationInSeconds: $expirationInSeconds)
    }
  }
`

/**
 * __useGetLivestreamPlaybackUrlQuery__
 *
 * To run a query within a React component, call `useGetLivestreamPlaybackUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLivestreamPlaybackUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLivestreamPlaybackUrlQuery({
 *   variables: {
 *      liveStreamId: // value for 'liveStreamId'
 *      expirationInSeconds: // value for 'expirationInSeconds'
 *   },
 * });
 */
export function useGetLivestreamPlaybackUrlQuery(
  baseOptions: Apollo.QueryHookOptions<GetLivestreamPlaybackUrlQuery, GetLivestreamPlaybackUrlQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetLivestreamPlaybackUrlQuery, GetLivestreamPlaybackUrlQueryVariables>(
    GetLivestreamPlaybackUrlDocument,
    options,
  )
}
export function useGetLivestreamPlaybackUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetLivestreamPlaybackUrlQuery, GetLivestreamPlaybackUrlQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetLivestreamPlaybackUrlQuery, GetLivestreamPlaybackUrlQueryVariables>(
    GetLivestreamPlaybackUrlDocument,
    options,
  )
}
export type GetLivestreamPlaybackUrlQueryHookResult = ReturnType<typeof useGetLivestreamPlaybackUrlQuery>
export type GetLivestreamPlaybackUrlLazyQueryHookResult = ReturnType<typeof useGetLivestreamPlaybackUrlLazyQuery>
export type GetLivestreamPlaybackUrlQueryResult = Apollo.QueryResult<
  GetLivestreamPlaybackUrlQuery,
  GetLivestreamPlaybackUrlQueryVariables
>
export const UpdateLiveStreamStatesCacheDocument = gql`
  mutation updateLiveStreamStatesCache {
    updateLiveStreamStatesCache
  }
`
export type UpdateLiveStreamStatesCacheMutationFn = Apollo.MutationFunction<
  UpdateLiveStreamStatesCacheMutation,
  UpdateLiveStreamStatesCacheMutationVariables
>

/**
 * __useUpdateLiveStreamStatesCacheMutation__
 *
 * To run a mutation, you first call `useUpdateLiveStreamStatesCacheMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLiveStreamStatesCacheMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLiveStreamStatesCacheMutation, { data, loading, error }] = useUpdateLiveStreamStatesCacheMutation({
 *   variables: {
 *   },
 * });
 */
export function useUpdateLiveStreamStatesCacheMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLiveStreamStatesCacheMutation,
    UpdateLiveStreamStatesCacheMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateLiveStreamStatesCacheMutation, UpdateLiveStreamStatesCacheMutationVariables>(
    UpdateLiveStreamStatesCacheDocument,
    options,
  )
}
export type UpdateLiveStreamStatesCacheMutationHookResult = ReturnType<typeof useUpdateLiveStreamStatesCacheMutation>
export type UpdateLiveStreamStatesCacheMutationResult = Apollo.MutationResult<UpdateLiveStreamStatesCacheMutation>
export type UpdateLiveStreamStatesCacheMutationOptions = Apollo.BaseMutationOptions<
  UpdateLiveStreamStatesCacheMutation,
  UpdateLiveStreamStatesCacheMutationVariables
>
export const GenerateMediaReplayDocument = gql`
  mutation generateMediaReplay($id: ID!, $input: GenerateMediaReplayInput) {
    generateMediaReplay(id: $id, input: $input)
  }
`
export type GenerateMediaReplayMutationFn = Apollo.MutationFunction<
  GenerateMediaReplayMutation,
  GenerateMediaReplayMutationVariables
>

/**
 * __useGenerateMediaReplayMutation__
 *
 * To run a mutation, you first call `useGenerateMediaReplayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateMediaReplayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateMediaReplayMutation, { data, loading, error }] = useGenerateMediaReplayMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateMediaReplayMutation(
  baseOptions?: Apollo.MutationHookOptions<GenerateMediaReplayMutation, GenerateMediaReplayMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<GenerateMediaReplayMutation, GenerateMediaReplayMutationVariables>(
    GenerateMediaReplayDocument,
    options,
  )
}
export type GenerateMediaReplayMutationHookResult = ReturnType<typeof useGenerateMediaReplayMutation>
export type GenerateMediaReplayMutationResult = Apollo.MutationResult<GenerateMediaReplayMutation>
export type GenerateMediaReplayMutationOptions = Apollo.BaseMutationOptions<
  GenerateMediaReplayMutation,
  GenerateMediaReplayMutationVariables
>
export const DeleteMediaDocument = gql`
  mutation deleteMedia($mediaId: ID!) {
    deleteMedia(id: $mediaId)
  }
`
export type DeleteMediaMutationFn = Apollo.MutationFunction<DeleteMediaMutation, DeleteMediaMutationVariables>

/**
 * __useDeleteMediaMutation__
 *
 * To run a mutation, you first call `useDeleteMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMediaMutation, { data, loading, error }] = useDeleteMediaMutation({
 *   variables: {
 *      mediaId: // value for 'mediaId'
 *   },
 * });
 */
export function useDeleteMediaMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteMediaMutation, DeleteMediaMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteMediaMutation, DeleteMediaMutationVariables>(DeleteMediaDocument, options)
}
export type DeleteMediaMutationHookResult = ReturnType<typeof useDeleteMediaMutation>
export type DeleteMediaMutationResult = Apollo.MutationResult<DeleteMediaMutation>
export type DeleteMediaMutationOptions = Apollo.BaseMutationOptions<DeleteMediaMutation, DeleteMediaMutationVariables>
export const UpdateMediaDocument = gql`
  mutation updateMedia($mediaId: ID!, $input: UpdateMediaInput!) {
    updateMedia(id: $mediaId, input: $input) {
      ...mediaLightContent
    }
  }
  ${MediaLightContentFragmentDoc}
`
export type UpdateMediaMutationFn = Apollo.MutationFunction<UpdateMediaMutation, UpdateMediaMutationVariables>

/**
 * __useUpdateMediaMutation__
 *
 * To run a mutation, you first call `useUpdateMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMediaMutation, { data, loading, error }] = useUpdateMediaMutation({
 *   variables: {
 *      mediaId: // value for 'mediaId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMediaMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateMediaMutation, UpdateMediaMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateMediaMutation, UpdateMediaMutationVariables>(UpdateMediaDocument, options)
}
export type UpdateMediaMutationHookResult = ReturnType<typeof useUpdateMediaMutation>
export type UpdateMediaMutationResult = Apollo.MutationResult<UpdateMediaMutation>
export type UpdateMediaMutationOptions = Apollo.BaseMutationOptions<UpdateMediaMutation, UpdateMediaMutationVariables>
export const MediasDocument = gql`
  query medias($type: MediaType, $search: String, $first: Int, $offset: Int, $organismId: ID) {
    medias(type: $type, search: $search, first: $first, offset: $offset, organismId: $organismId) {
      totalCount
      medias {
        ...mediaContent
      }
    }
  }
  ${MediaContentFragmentDoc}
`

/**
 * __useMediasQuery__
 *
 * To run a query within a React component, call `useMediasQuery` and pass it any options that fit your needs.
 * When your component renders, `useMediasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMediasQuery({
 *   variables: {
 *      type: // value for 'type'
 *      search: // value for 'search'
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *      organismId: // value for 'organismId'
 *   },
 * });
 */
export function useMediasQuery(baseOptions?: Apollo.QueryHookOptions<MediasQuery, MediasQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MediasQuery, MediasQueryVariables>(MediasDocument, options)
}
export function useMediasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MediasQuery, MediasQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<MediasQuery, MediasQueryVariables>(MediasDocument, options)
}
export type MediasQueryHookResult = ReturnType<typeof useMediasQuery>
export type MediasLazyQueryHookResult = ReturnType<typeof useMediasLazyQuery>
export type MediasQueryResult = Apollo.QueryResult<MediasQuery, MediasQueryVariables>
export const MediaDocument = gql`
  query media($mediaId: ID!) {
    media(id: $mediaId) {
      ...mediaContent
    }
  }
  ${MediaContentFragmentDoc}
`

/**
 * __useMediaQuery__
 *
 * To run a query within a React component, call `useMediaQuery` and pass it any options that fit your needs.
 * When your component renders, `useMediaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMediaQuery({
 *   variables: {
 *      mediaId: // value for 'mediaId'
 *   },
 * });
 */
export function useMediaQuery(baseOptions: Apollo.QueryHookOptions<MediaQuery, MediaQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MediaQuery, MediaQueryVariables>(MediaDocument, options)
}
export function useMediaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MediaQuery, MediaQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<MediaQuery, MediaQueryVariables>(MediaDocument, options)
}
export type MediaQueryHookResult = ReturnType<typeof useMediaQuery>
export type MediaLazyQueryHookResult = ReturnType<typeof useMediaLazyQuery>
export type MediaQueryResult = Apollo.QueryResult<MediaQuery, MediaQueryVariables>
export const CreateWildmokaMediaLiveStreamDocument = gql`
  mutation createWildmokaMediaLiveStream($input: CreateWildmokaMediaLiveStreamInput!) {
    createWildmokaMediaLiveStream(input: $input) {
      ...mediaContent
    }
  }
  ${MediaContentFragmentDoc}
`
export type CreateWildmokaMediaLiveStreamMutationFn = Apollo.MutationFunction<
  CreateWildmokaMediaLiveStreamMutation,
  CreateWildmokaMediaLiveStreamMutationVariables
>

/**
 * __useCreateWildmokaMediaLiveStreamMutation__
 *
 * To run a mutation, you first call `useCreateWildmokaMediaLiveStreamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWildmokaMediaLiveStreamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWildmokaMediaLiveStreamMutation, { data, loading, error }] = useCreateWildmokaMediaLiveStreamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWildmokaMediaLiveStreamMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateWildmokaMediaLiveStreamMutation,
    CreateWildmokaMediaLiveStreamMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateWildmokaMediaLiveStreamMutation, CreateWildmokaMediaLiveStreamMutationVariables>(
    CreateWildmokaMediaLiveStreamDocument,
    options,
  )
}
export type CreateWildmokaMediaLiveStreamMutationHookResult = ReturnType<
  typeof useCreateWildmokaMediaLiveStreamMutation
>
export type CreateWildmokaMediaLiveStreamMutationResult = Apollo.MutationResult<CreateWildmokaMediaLiveStreamMutation>
export type CreateWildmokaMediaLiveStreamMutationOptions = Apollo.BaseMutationOptions<
  CreateWildmokaMediaLiveStreamMutation,
  CreateWildmokaMediaLiveStreamMutationVariables
>
export const CreateSveMediaLiveStreamDocument = gql`
  mutation createSVEMediaLiveStream($input: CreateSVEMediaLiveStreamInput!) {
    createSVEMediaLiveStream(input: $input) {
      ...mediaContent
    }
  }
  ${MediaContentFragmentDoc}
`
export type CreateSveMediaLiveStreamMutationFn = Apollo.MutationFunction<
  CreateSveMediaLiveStreamMutation,
  CreateSveMediaLiveStreamMutationVariables
>

/**
 * __useCreateSveMediaLiveStreamMutation__
 *
 * To run a mutation, you first call `useCreateSveMediaLiveStreamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSveMediaLiveStreamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSveMediaLiveStreamMutation, { data, loading, error }] = useCreateSveMediaLiveStreamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSveMediaLiveStreamMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateSveMediaLiveStreamMutation, CreateSveMediaLiveStreamMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateSveMediaLiveStreamMutation, CreateSveMediaLiveStreamMutationVariables>(
    CreateSveMediaLiveStreamDocument,
    options,
  )
}
export type CreateSveMediaLiveStreamMutationHookResult = ReturnType<typeof useCreateSveMediaLiveStreamMutation>
export type CreateSveMediaLiveStreamMutationResult = Apollo.MutationResult<CreateSveMediaLiveStreamMutation>
export type CreateSveMediaLiveStreamMutationOptions = Apollo.BaseMutationOptions<
  CreateSveMediaLiveStreamMutation,
  CreateSveMediaLiveStreamMutationVariables
>
export const CreateExternalFeedMediaLiveStreamDocument = gql`
  mutation createExternalFeedMediaLiveStream($input: CreateExternalFeedMediaLiveStreamInput!) {
    createExternalFeedMediaLiveStream(input: $input) {
      ...mediaContent
    }
  }
  ${MediaContentFragmentDoc}
`
export type CreateExternalFeedMediaLiveStreamMutationFn = Apollo.MutationFunction<
  CreateExternalFeedMediaLiveStreamMutation,
  CreateExternalFeedMediaLiveStreamMutationVariables
>

/**
 * __useCreateExternalFeedMediaLiveStreamMutation__
 *
 * To run a mutation, you first call `useCreateExternalFeedMediaLiveStreamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExternalFeedMediaLiveStreamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExternalFeedMediaLiveStreamMutation, { data, loading, error }] = useCreateExternalFeedMediaLiveStreamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateExternalFeedMediaLiveStreamMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateExternalFeedMediaLiveStreamMutation,
    CreateExternalFeedMediaLiveStreamMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateExternalFeedMediaLiveStreamMutation,
    CreateExternalFeedMediaLiveStreamMutationVariables
  >(CreateExternalFeedMediaLiveStreamDocument, options)
}
export type CreateExternalFeedMediaLiveStreamMutationHookResult = ReturnType<
  typeof useCreateExternalFeedMediaLiveStreamMutation
>
export type CreateExternalFeedMediaLiveStreamMutationResult =
  Apollo.MutationResult<CreateExternalFeedMediaLiveStreamMutation>
export type CreateExternalFeedMediaLiveStreamMutationOptions = Apollo.BaseMutationOptions<
  CreateExternalFeedMediaLiveStreamMutation,
  CreateExternalFeedMediaLiveStreamMutationVariables
>
export const CreateMediaVideoClipDocument = gql`
  mutation createMediaVideoClip($input: CreateMediaVideoClipInput!) {
    createMediaVideoClip(input: $input) {
      ...mediaContent
    }
  }
  ${MediaContentFragmentDoc}
`
export type CreateMediaVideoClipMutationFn = Apollo.MutationFunction<
  CreateMediaVideoClipMutation,
  CreateMediaVideoClipMutationVariables
>

/**
 * __useCreateMediaVideoClipMutation__
 *
 * To run a mutation, you first call `useCreateMediaVideoClipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMediaVideoClipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMediaVideoClipMutation, { data, loading, error }] = useCreateMediaVideoClipMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMediaVideoClipMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateMediaVideoClipMutation, CreateMediaVideoClipMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateMediaVideoClipMutation, CreateMediaVideoClipMutationVariables>(
    CreateMediaVideoClipDocument,
    options,
  )
}
export type CreateMediaVideoClipMutationHookResult = ReturnType<typeof useCreateMediaVideoClipMutation>
export type CreateMediaVideoClipMutationResult = Apollo.MutationResult<CreateMediaVideoClipMutation>
export type CreateMediaVideoClipMutationOptions = Apollo.BaseMutationOptions<
  CreateMediaVideoClipMutation,
  CreateMediaVideoClipMutationVariables
>
export const CreateMediaVideoClipExternalFeedDocument = gql`
  mutation createMediaVideoClipExternalFeed($input: CreateMediaVideoClipExternalFeedInput!) {
    createMediaVideoClipExternalFeed(input: $input) {
      ...mediaContent
    }
  }
  ${MediaContentFragmentDoc}
`
export type CreateMediaVideoClipExternalFeedMutationFn = Apollo.MutationFunction<
  CreateMediaVideoClipExternalFeedMutation,
  CreateMediaVideoClipExternalFeedMutationVariables
>

/**
 * __useCreateMediaVideoClipExternalFeedMutation__
 *
 * To run a mutation, you first call `useCreateMediaVideoClipExternalFeedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMediaVideoClipExternalFeedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMediaVideoClipExternalFeedMutation, { data, loading, error }] = useCreateMediaVideoClipExternalFeedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMediaVideoClipExternalFeedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMediaVideoClipExternalFeedMutation,
    CreateMediaVideoClipExternalFeedMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateMediaVideoClipExternalFeedMutation,
    CreateMediaVideoClipExternalFeedMutationVariables
  >(CreateMediaVideoClipExternalFeedDocument, options)
}
export type CreateMediaVideoClipExternalFeedMutationHookResult = ReturnType<
  typeof useCreateMediaVideoClipExternalFeedMutation
>
export type CreateMediaVideoClipExternalFeedMutationResult =
  Apollo.MutationResult<CreateMediaVideoClipExternalFeedMutation>
export type CreateMediaVideoClipExternalFeedMutationOptions = Apollo.BaseMutationOptions<
  CreateMediaVideoClipExternalFeedMutation,
  CreateMediaVideoClipExternalFeedMutationVariables
>
export const CreateMediaFileDocument = gql`
  mutation createMediaFile($input: CreateMediaFileInput!) {
    createMediaFile(input: $input) {
      ...mediaContent
    }
  }
  ${MediaContentFragmentDoc}
`
export type CreateMediaFileMutationFn = Apollo.MutationFunction<
  CreateMediaFileMutation,
  CreateMediaFileMutationVariables
>

/**
 * __useCreateMediaFileMutation__
 *
 * To run a mutation, you first call `useCreateMediaFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMediaFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMediaFileMutation, { data, loading, error }] = useCreateMediaFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMediaFileMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateMediaFileMutation, CreateMediaFileMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateMediaFileMutation, CreateMediaFileMutationVariables>(CreateMediaFileDocument, options)
}
export type CreateMediaFileMutationHookResult = ReturnType<typeof useCreateMediaFileMutation>
export type CreateMediaFileMutationResult = Apollo.MutationResult<CreateMediaFileMutation>
export type CreateMediaFileMutationOptions = Apollo.BaseMutationOptions<
  CreateMediaFileMutation,
  CreateMediaFileMutationVariables
>
export const TogglePinDocument = gql`
  mutation togglePin($id: ID!) {
    togglePin(id: $id)
  }
`
export type TogglePinMutationFn = Apollo.MutationFunction<TogglePinMutation, TogglePinMutationVariables>

/**
 * __useTogglePinMutation__
 *
 * To run a mutation, you first call `useTogglePinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTogglePinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [togglePinMutation, { data, loading, error }] = useTogglePinMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTogglePinMutation(
  baseOptions?: Apollo.MutationHookOptions<TogglePinMutation, TogglePinMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<TogglePinMutation, TogglePinMutationVariables>(TogglePinDocument, options)
}
export type TogglePinMutationHookResult = ReturnType<typeof useTogglePinMutation>
export type TogglePinMutationResult = Apollo.MutationResult<TogglePinMutation>
export type TogglePinMutationOptions = Apollo.BaseMutationOptions<TogglePinMutation, TogglePinMutationVariables>
export const NewssDocument = gql`
  query newss($filters: NewsFilters, $offset: Int, $first: Int, $search: String) {
    newss(filters: $filters, offset: $offset, first: $first, search: $search) {
      news {
        ...news
      }
      totalCount
    }
  }
  ${NewsFragmentDoc}
`

/**
 * __useNewssQuery__
 *
 * To run a query within a React component, call `useNewssQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewssQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewssQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      offset: // value for 'offset'
 *      first: // value for 'first'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useNewssQuery(baseOptions?: Apollo.QueryHookOptions<NewssQuery, NewssQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<NewssQuery, NewssQueryVariables>(NewssDocument, options)
}
export function useNewssLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NewssQuery, NewssQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<NewssQuery, NewssQueryVariables>(NewssDocument, options)
}
export type NewssQueryHookResult = ReturnType<typeof useNewssQuery>
export type NewssLazyQueryHookResult = ReturnType<typeof useNewssLazyQuery>
export type NewssQueryResult = Apollo.QueryResult<NewssQuery, NewssQueryVariables>
export const NewsDocument = gql`
  query news($newsId: ID!) {
    news(id: $newsId) {
      ...news
    }
  }
  ${NewsFragmentDoc}
`

/**
 * __useNewsQuery__
 *
 * To run a query within a React component, call `useNewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewsQuery({
 *   variables: {
 *      newsId: // value for 'newsId'
 *   },
 * });
 */
export function useNewsQuery(baseOptions: Apollo.QueryHookOptions<NewsQuery, NewsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<NewsQuery, NewsQueryVariables>(NewsDocument, options)
}
export function useNewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NewsQuery, NewsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<NewsQuery, NewsQueryVariables>(NewsDocument, options)
}
export type NewsQueryHookResult = ReturnType<typeof useNewsQuery>
export type NewsLazyQueryHookResult = ReturnType<typeof useNewsLazyQuery>
export type NewsQueryResult = Apollo.QueryResult<NewsQuery, NewsQueryVariables>
export const CreateNewsDocument = gql`
  mutation createNews($input: CreateNewsInput!) {
    createNews(input: $input) {
      ...news
    }
  }
  ${NewsFragmentDoc}
`
export type CreateNewsMutationFn = Apollo.MutationFunction<CreateNewsMutation, CreateNewsMutationVariables>

/**
 * __useCreateNewsMutation__
 *
 * To run a mutation, you first call `useCreateNewsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewsMutation, { data, loading, error }] = useCreateNewsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNewsMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateNewsMutation, CreateNewsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateNewsMutation, CreateNewsMutationVariables>(CreateNewsDocument, options)
}
export type CreateNewsMutationHookResult = ReturnType<typeof useCreateNewsMutation>
export type CreateNewsMutationResult = Apollo.MutationResult<CreateNewsMutation>
export type CreateNewsMutationOptions = Apollo.BaseMutationOptions<CreateNewsMutation, CreateNewsMutationVariables>
export const UpdateNewsDocument = gql`
  mutation updateNews($input: UpdateNewsInput!) {
    updateNews(input: $input) {
      ...news
    }
  }
  ${NewsFragmentDoc}
`
export type UpdateNewsMutationFn = Apollo.MutationFunction<UpdateNewsMutation, UpdateNewsMutationVariables>

/**
 * __useUpdateNewsMutation__
 *
 * To run a mutation, you first call `useUpdateNewsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNewsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNewsMutation, { data, loading, error }] = useUpdateNewsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateNewsMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateNewsMutation, UpdateNewsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateNewsMutation, UpdateNewsMutationVariables>(UpdateNewsDocument, options)
}
export type UpdateNewsMutationHookResult = ReturnType<typeof useUpdateNewsMutation>
export type UpdateNewsMutationResult = Apollo.MutationResult<UpdateNewsMutation>
export type UpdateNewsMutationOptions = Apollo.BaseMutationOptions<UpdateNewsMutation, UpdateNewsMutationVariables>
export const DeleteNewsDocument = gql`
  mutation deleteNews($newsId: ID!) {
    deleteNews(id: $newsId) {
      id
    }
  }
`
export type DeleteNewsMutationFn = Apollo.MutationFunction<DeleteNewsMutation, DeleteNewsMutationVariables>

/**
 * __useDeleteNewsMutation__
 *
 * To run a mutation, you first call `useDeleteNewsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNewsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNewsMutation, { data, loading, error }] = useDeleteNewsMutation({
 *   variables: {
 *      newsId: // value for 'newsId'
 *   },
 * });
 */
export function useDeleteNewsMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteNewsMutation, DeleteNewsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteNewsMutation, DeleteNewsMutationVariables>(DeleteNewsDocument, options)
}
export type DeleteNewsMutationHookResult = ReturnType<typeof useDeleteNewsMutation>
export type DeleteNewsMutationResult = Apollo.MutationResult<DeleteNewsMutation>
export type DeleteNewsMutationOptions = Apollo.BaseMutationOptions<DeleteNewsMutation, DeleteNewsMutationVariables>
export const CreateTwitterWidgetDocument = gql`
  mutation createTwitterWidget($tweetUrl: String!) {
    createTwitterWidget(tweetUrl: $tweetUrl)
  }
`
export type CreateTwitterWidgetMutationFn = Apollo.MutationFunction<
  CreateTwitterWidgetMutation,
  CreateTwitterWidgetMutationVariables
>

/**
 * __useCreateTwitterWidgetMutation__
 *
 * To run a mutation, you first call `useCreateTwitterWidgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTwitterWidgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTwitterWidgetMutation, { data, loading, error }] = useCreateTwitterWidgetMutation({
 *   variables: {
 *      tweetUrl: // value for 'tweetUrl'
 *   },
 * });
 */
export function useCreateTwitterWidgetMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTwitterWidgetMutation, CreateTwitterWidgetMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateTwitterWidgetMutation, CreateTwitterWidgetMutationVariables>(
    CreateTwitterWidgetDocument,
    options,
  )
}
export type CreateTwitterWidgetMutationHookResult = ReturnType<typeof useCreateTwitterWidgetMutation>
export type CreateTwitterWidgetMutationResult = Apollo.MutationResult<CreateTwitterWidgetMutation>
export type CreateTwitterWidgetMutationOptions = Apollo.BaseMutationOptions<
  CreateTwitterWidgetMutation,
  CreateTwitterWidgetMutationVariables
>
export const OrganismsDocument = gql`
  query organisms($filters: OrganismFilters) {
    organisms(filters: $filters) {
      organisms {
        ...organismContent
      }
      totalCount
    }
  }
  ${OrganismContentFragmentDoc}
`

/**
 * __useOrganismsQuery__
 *
 * To run a query within a React component, call `useOrganismsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganismsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganismsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useOrganismsQuery(baseOptions?: Apollo.QueryHookOptions<OrganismsQuery, OrganismsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<OrganismsQuery, OrganismsQueryVariables>(OrganismsDocument, options)
}
export function useOrganismsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrganismsQuery, OrganismsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<OrganismsQuery, OrganismsQueryVariables>(OrganismsDocument, options)
}
export type OrganismsQueryHookResult = ReturnType<typeof useOrganismsQuery>
export type OrganismsLazyQueryHookResult = ReturnType<typeof useOrganismsLazyQuery>
export type OrganismsQueryResult = Apollo.QueryResult<OrganismsQuery, OrganismsQueryVariables>
export const CreateOrganismDocument = gql`
  mutation createOrganism($input: CreateOrganismInput!) {
    createOrganism(input: $input) {
      ...organismContent
    }
  }
  ${OrganismContentFragmentDoc}
`
export type CreateOrganismMutationFn = Apollo.MutationFunction<CreateOrganismMutation, CreateOrganismMutationVariables>

/**
 * __useCreateOrganismMutation__
 *
 * To run a mutation, you first call `useCreateOrganismMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganismMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganismMutation, { data, loading, error }] = useCreateOrganismMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrganismMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateOrganismMutation, CreateOrganismMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateOrganismMutation, CreateOrganismMutationVariables>(CreateOrganismDocument, options)
}
export type CreateOrganismMutationHookResult = ReturnType<typeof useCreateOrganismMutation>
export type CreateOrganismMutationResult = Apollo.MutationResult<CreateOrganismMutation>
export type CreateOrganismMutationOptions = Apollo.BaseMutationOptions<
  CreateOrganismMutation,
  CreateOrganismMutationVariables
>
export const UpdateOrganismDocument = gql`
  mutation updateOrganism($input: UpdateOrganismInput!) {
    updateOrganism(input: $input) {
      ...organismContent
    }
  }
  ${OrganismContentFragmentDoc}
`
export type UpdateOrganismMutationFn = Apollo.MutationFunction<UpdateOrganismMutation, UpdateOrganismMutationVariables>

/**
 * __useUpdateOrganismMutation__
 *
 * To run a mutation, you first call `useUpdateOrganismMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganismMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganismMutation, { data, loading, error }] = useUpdateOrganismMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrganismMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateOrganismMutation, UpdateOrganismMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateOrganismMutation, UpdateOrganismMutationVariables>(UpdateOrganismDocument, options)
}
export type UpdateOrganismMutationHookResult = ReturnType<typeof useUpdateOrganismMutation>
export type UpdateOrganismMutationResult = Apollo.MutationResult<UpdateOrganismMutation>
export type UpdateOrganismMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrganismMutation,
  UpdateOrganismMutationVariables
>
export const OrganismDocument = gql`
  query organism($organismId: ID!) {
    organism(id: $organismId) {
      ...organismContent
    }
  }
  ${OrganismContentFragmentDoc}
`

/**
 * __useOrganismQuery__
 *
 * To run a query within a React component, call `useOrganismQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganismQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganismQuery({
 *   variables: {
 *      organismId: // value for 'organismId'
 *   },
 * });
 */
export function useOrganismQuery(baseOptions: Apollo.QueryHookOptions<OrganismQuery, OrganismQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<OrganismQuery, OrganismQueryVariables>(OrganismDocument, options)
}
export function useOrganismLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganismQuery, OrganismQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<OrganismQuery, OrganismQueryVariables>(OrganismDocument, options)
}
export type OrganismQueryHookResult = ReturnType<typeof useOrganismQuery>
export type OrganismLazyQueryHookResult = ReturnType<typeof useOrganismLazyQuery>
export type OrganismQueryResult = Apollo.QueryResult<OrganismQuery, OrganismQueryVariables>
export const LightOrganismDocument = gql`
  query lightOrganism($organismId: ID!) {
    organism(id: $organismId) {
      ...lightOrganismContent
    }
  }
  ${LightOrganismContentFragmentDoc}
`

/**
 * __useLightOrganismQuery__
 *
 * To run a query within a React component, call `useLightOrganismQuery` and pass it any options that fit your needs.
 * When your component renders, `useLightOrganismQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLightOrganismQuery({
 *   variables: {
 *      organismId: // value for 'organismId'
 *   },
 * });
 */
export function useLightOrganismQuery(
  baseOptions: Apollo.QueryHookOptions<LightOrganismQuery, LightOrganismQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<LightOrganismQuery, LightOrganismQueryVariables>(LightOrganismDocument, options)
}
export function useLightOrganismLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LightOrganismQuery, LightOrganismQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<LightOrganismQuery, LightOrganismQueryVariables>(LightOrganismDocument, options)
}
export type LightOrganismQueryHookResult = ReturnType<typeof useLightOrganismQuery>
export type LightOrganismLazyQueryHookResult = ReturnType<typeof useLightOrganismLazyQuery>
export type LightOrganismQueryResult = Apollo.QueryResult<LightOrganismQuery, LightOrganismQueryVariables>
export const OrganismWithSubscriptionsDocument = gql`
  query organismWithSubscriptions($organismId: ID!) {
    organism(id: $organismId) {
      ...organismContent
      offers {
        ...purchasableOfferContent
        nbValidSubscriptions
      }
    }
  }
  ${OrganismContentFragmentDoc}
  ${PurchasableOfferContentFragmentDoc}
`

/**
 * __useOrganismWithSubscriptionsQuery__
 *
 * To run a query within a React component, call `useOrganismWithSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganismWithSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganismWithSubscriptionsQuery({
 *   variables: {
 *      organismId: // value for 'organismId'
 *   },
 * });
 */
export function useOrganismWithSubscriptionsQuery(
  baseOptions: Apollo.QueryHookOptions<OrganismWithSubscriptionsQuery, OrganismWithSubscriptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<OrganismWithSubscriptionsQuery, OrganismWithSubscriptionsQueryVariables>(
    OrganismWithSubscriptionsDocument,
    options,
  )
}
export function useOrganismWithSubscriptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrganismWithSubscriptionsQuery, OrganismWithSubscriptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<OrganismWithSubscriptionsQuery, OrganismWithSubscriptionsQueryVariables>(
    OrganismWithSubscriptionsDocument,
    options,
  )
}
export type OrganismWithSubscriptionsQueryHookResult = ReturnType<typeof useOrganismWithSubscriptionsQuery>
export type OrganismWithSubscriptionsLazyQueryHookResult = ReturnType<typeof useOrganismWithSubscriptionsLazyQuery>
export type OrganismWithSubscriptionsQueryResult = Apollo.QueryResult<
  OrganismWithSubscriptionsQuery,
  OrganismWithSubscriptionsQueryVariables
>
export const OrganismWithStreamsAndSveChannelsDocument = gql`
  query organismWithStreamsAndSVEChannels($id: ID!) {
    organism(id: $id) {
      ...organismContent
      streams {
        ...wmStreamContent
      }
      sveChannels {
        ...sveChannelContent
      }
    }
  }
  ${OrganismContentFragmentDoc}
  ${WmStreamContentFragmentDoc}
  ${SveChannelContentFragmentDoc}
`

/**
 * __useOrganismWithStreamsAndSveChannelsQuery__
 *
 * To run a query within a React component, call `useOrganismWithStreamsAndSveChannelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganismWithStreamsAndSveChannelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganismWithStreamsAndSveChannelsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganismWithStreamsAndSveChannelsQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrganismWithStreamsAndSveChannelsQuery,
    OrganismWithStreamsAndSveChannelsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<OrganismWithStreamsAndSveChannelsQuery, OrganismWithStreamsAndSveChannelsQueryVariables>(
    OrganismWithStreamsAndSveChannelsDocument,
    options,
  )
}
export function useOrganismWithStreamsAndSveChannelsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrganismWithStreamsAndSveChannelsQuery,
    OrganismWithStreamsAndSveChannelsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<OrganismWithStreamsAndSveChannelsQuery, OrganismWithStreamsAndSveChannelsQueryVariables>(
    OrganismWithStreamsAndSveChannelsDocument,
    options,
  )
}
export type OrganismWithStreamsAndSveChannelsQueryHookResult = ReturnType<
  typeof useOrganismWithStreamsAndSveChannelsQuery
>
export type OrganismWithStreamsAndSveChannelsLazyQueryHookResult = ReturnType<
  typeof useOrganismWithStreamsAndSveChannelsLazyQuery
>
export type OrganismWithStreamsAndSveChannelsQueryResult = Apollo.QueryResult<
  OrganismWithStreamsAndSveChannelsQuery,
  OrganismWithStreamsAndSveChannelsQueryVariables
>
export const OrganismWithStreamsAndSveChannelsAndAvailableSveChannelsAndWmStreamsDocument = gql`
  query organismWithStreamsAndSVEChannelsAndAvailableSVEChannelsAndWMStreams(
    $id: ID!
    $input: OrganismAvailableSVEChannelsInput
  ) {
    organism(id: $id) {
      ...organismContent
      streams {
        ...wmStreamContent
      }
      sveChannels {
        ...sveChannelContent
      }
      availableSVEChannels(input: $input) {
        ...sveChannelContent
      }
      availableWMStreams {
        ...wmStreamContent
      }
    }
  }
  ${OrganismContentFragmentDoc}
  ${WmStreamContentFragmentDoc}
  ${SveChannelContentFragmentDoc}
`

/**
 * __useOrganismWithStreamsAndSveChannelsAndAvailableSveChannelsAndWmStreamsQuery__
 *
 * To run a query within a React component, call `useOrganismWithStreamsAndSveChannelsAndAvailableSveChannelsAndWmStreamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganismWithStreamsAndSveChannelsAndAvailableSveChannelsAndWmStreamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganismWithStreamsAndSveChannelsAndAvailableSveChannelsAndWmStreamsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrganismWithStreamsAndSveChannelsAndAvailableSveChannelsAndWmStreamsQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrganismWithStreamsAndSveChannelsAndAvailableSveChannelsAndWmStreamsQuery,
    OrganismWithStreamsAndSveChannelsAndAvailableSveChannelsAndWmStreamsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    OrganismWithStreamsAndSveChannelsAndAvailableSveChannelsAndWmStreamsQuery,
    OrganismWithStreamsAndSveChannelsAndAvailableSveChannelsAndWmStreamsQueryVariables
  >(OrganismWithStreamsAndSveChannelsAndAvailableSveChannelsAndWmStreamsDocument, options)
}
export function useOrganismWithStreamsAndSveChannelsAndAvailableSveChannelsAndWmStreamsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrganismWithStreamsAndSveChannelsAndAvailableSveChannelsAndWmStreamsQuery,
    OrganismWithStreamsAndSveChannelsAndAvailableSveChannelsAndWmStreamsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    OrganismWithStreamsAndSveChannelsAndAvailableSveChannelsAndWmStreamsQuery,
    OrganismWithStreamsAndSveChannelsAndAvailableSveChannelsAndWmStreamsQueryVariables
  >(OrganismWithStreamsAndSveChannelsAndAvailableSveChannelsAndWmStreamsDocument, options)
}
export type OrganismWithStreamsAndSveChannelsAndAvailableSveChannelsAndWmStreamsQueryHookResult = ReturnType<
  typeof useOrganismWithStreamsAndSveChannelsAndAvailableSveChannelsAndWmStreamsQuery
>
export type OrganismWithStreamsAndSveChannelsAndAvailableSveChannelsAndWmStreamsLazyQueryHookResult = ReturnType<
  typeof useOrganismWithStreamsAndSveChannelsAndAvailableSveChannelsAndWmStreamsLazyQuery
>
export type OrganismWithStreamsAndSveChannelsAndAvailableSveChannelsAndWmStreamsQueryResult = Apollo.QueryResult<
  OrganismWithStreamsAndSveChannelsAndAvailableSveChannelsAndWmStreamsQuery,
  OrganismWithStreamsAndSveChannelsAndAvailableSveChannelsAndWmStreamsQueryVariables
>
export const OrganismsApplicationsDocument = gql`
  query organismsApplications($id: ID!) {
    organism(id: $id) {
      id
      whiteLabel
      applications {
        ...applicationContent
      }
    }
  }
  ${ApplicationContentFragmentDoc}
`

/**
 * __useOrganismsApplicationsQuery__
 *
 * To run a query within a React component, call `useOrganismsApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganismsApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganismsApplicationsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganismsApplicationsQuery(
  baseOptions: Apollo.QueryHookOptions<OrganismsApplicationsQuery, OrganismsApplicationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<OrganismsApplicationsQuery, OrganismsApplicationsQueryVariables>(
    OrganismsApplicationsDocument,
    options,
  )
}
export function useOrganismsApplicationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrganismsApplicationsQuery, OrganismsApplicationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<OrganismsApplicationsQuery, OrganismsApplicationsQueryVariables>(
    OrganismsApplicationsDocument,
    options,
  )
}
export type OrganismsApplicationsQueryHookResult = ReturnType<typeof useOrganismsApplicationsQuery>
export type OrganismsApplicationsLazyQueryHookResult = ReturnType<typeof useOrganismsApplicationsLazyQuery>
export type OrganismsApplicationsQueryResult = Apollo.QueryResult<
  OrganismsApplicationsQuery,
  OrganismsApplicationsQueryVariables
>
export const OrganismsOffersDocument = gql`
  query organismsOffers($id: ID!) {
    organism(id: $id) {
      id
      offers {
        id
        title
        publicationStatus
      }
    }
  }
`

/**
 * __useOrganismsOffersQuery__
 *
 * To run a query within a React component, call `useOrganismsOffersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganismsOffersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganismsOffersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganismsOffersQuery(
  baseOptions: Apollo.QueryHookOptions<OrganismsOffersQuery, OrganismsOffersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<OrganismsOffersQuery, OrganismsOffersQueryVariables>(OrganismsOffersDocument, options)
}
export function useOrganismsOffersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrganismsOffersQuery, OrganismsOffersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<OrganismsOffersQuery, OrganismsOffersQueryVariables>(OrganismsOffersDocument, options)
}
export type OrganismsOffersQueryHookResult = ReturnType<typeof useOrganismsOffersQuery>
export type OrganismsOffersLazyQueryHookResult = ReturnType<typeof useOrganismsOffersLazyQuery>
export type OrganismsOffersQueryResult = Apollo.QueryResult<OrganismsOffersQuery, OrganismsOffersQueryVariables>
export const DeleteOrganismDocument = gql`
  mutation deleteOrganism($organismId: ID!) {
    deleteOrganism(id: $organismId) {
      id
    }
  }
`
export type DeleteOrganismMutationFn = Apollo.MutationFunction<DeleteOrganismMutation, DeleteOrganismMutationVariables>

/**
 * __useDeleteOrganismMutation__
 *
 * To run a mutation, you first call `useDeleteOrganismMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrganismMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrganismMutation, { data, loading, error }] = useDeleteOrganismMutation({
 *   variables: {
 *      organismId: // value for 'organismId'
 *   },
 * });
 */
export function useDeleteOrganismMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteOrganismMutation, DeleteOrganismMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteOrganismMutation, DeleteOrganismMutationVariables>(DeleteOrganismDocument, options)
}
export type DeleteOrganismMutationHookResult = ReturnType<typeof useDeleteOrganismMutation>
export type DeleteOrganismMutationResult = Apollo.MutationResult<DeleteOrganismMutation>
export type DeleteOrganismMutationOptions = Apollo.BaseMutationOptions<
  DeleteOrganismMutation,
  DeleteOrganismMutationVariables
>
export const MoveFrontPageDocument = gql`
  mutation moveFrontPage($input: MoveFrontPageInput!) {
    moveFrontPage(input: $input) {
      id
      frontPosition
    }
  }
`
export type MoveFrontPageMutationFn = Apollo.MutationFunction<MoveFrontPageMutation, MoveFrontPageMutationVariables>

/**
 * __useMoveFrontPageMutation__
 *
 * To run a mutation, you first call `useMoveFrontPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveFrontPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveFrontPageMutation, { data, loading, error }] = useMoveFrontPageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMoveFrontPageMutation(
  baseOptions?: Apollo.MutationHookOptions<MoveFrontPageMutation, MoveFrontPageMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<MoveFrontPageMutation, MoveFrontPageMutationVariables>(MoveFrontPageDocument, options)
}
export type MoveFrontPageMutationHookResult = ReturnType<typeof useMoveFrontPageMutation>
export type MoveFrontPageMutationResult = Apollo.MutationResult<MoveFrontPageMutation>
export type MoveFrontPageMutationOptions = Apollo.BaseMutationOptions<
  MoveFrontPageMutation,
  MoveFrontPageMutationVariables
>
export const RevokeAllLicensesDocument = gql`
  mutation revokeAllLicenses($organismId: ID!) {
    revokeAllLicenses(organismId: $organismId)
  }
`
export type RevokeAllLicensesMutationFn = Apollo.MutationFunction<
  RevokeAllLicensesMutation,
  RevokeAllLicensesMutationVariables
>

/**
 * __useRevokeAllLicensesMutation__
 *
 * To run a mutation, you first call `useRevokeAllLicensesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeAllLicensesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeAllLicensesMutation, { data, loading, error }] = useRevokeAllLicensesMutation({
 *   variables: {
 *      organismId: // value for 'organismId'
 *   },
 * });
 */
export function useRevokeAllLicensesMutation(
  baseOptions?: Apollo.MutationHookOptions<RevokeAllLicensesMutation, RevokeAllLicensesMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RevokeAllLicensesMutation, RevokeAllLicensesMutationVariables>(
    RevokeAllLicensesDocument,
    options,
  )
}
export type RevokeAllLicensesMutationHookResult = ReturnType<typeof useRevokeAllLicensesMutation>
export type RevokeAllLicensesMutationResult = Apollo.MutationResult<RevokeAllLicensesMutation>
export type RevokeAllLicensesMutationOptions = Apollo.BaseMutationOptions<
  RevokeAllLicensesMutation,
  RevokeAllLicensesMutationVariables
>
export const PurchasableOffersDocument = gql`
  query purchasableOffers {
    purchasableOffers {
      ...purchasableOfferContent
      nbValidSubscriptions
    }
  }
  ${PurchasableOfferContentFragmentDoc}
`

/**
 * __usePurchasableOffersQuery__
 *
 * To run a query within a React component, call `usePurchasableOffersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePurchasableOffersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePurchasableOffersQuery({
 *   variables: {
 *   },
 * });
 */
export function usePurchasableOffersQuery(
  baseOptions?: Apollo.QueryHookOptions<PurchasableOffersQuery, PurchasableOffersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PurchasableOffersQuery, PurchasableOffersQueryVariables>(PurchasableOffersDocument, options)
}
export function usePurchasableOffersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PurchasableOffersQuery, PurchasableOffersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PurchasableOffersQuery, PurchasableOffersQueryVariables>(
    PurchasableOffersDocument,
    options,
  )
}
export type PurchasableOffersQueryHookResult = ReturnType<typeof usePurchasableOffersQuery>
export type PurchasableOffersLazyQueryHookResult = ReturnType<typeof usePurchasableOffersLazyQuery>
export type PurchasableOffersQueryResult = Apollo.QueryResult<PurchasableOffersQuery, PurchasableOffersQueryVariables>
export const PurchasableOfferDocument = gql`
  query purchasableOffer($purchasableOfferId: ID!) {
    purchasableOffer(id: $purchasableOfferId) {
      ...purchasableOfferContent
    }
  }
  ${PurchasableOfferContentFragmentDoc}
`

/**
 * __usePurchasableOfferQuery__
 *
 * To run a query within a React component, call `usePurchasableOfferQuery` and pass it any options that fit your needs.
 * When your component renders, `usePurchasableOfferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePurchasableOfferQuery({
 *   variables: {
 *      purchasableOfferId: // value for 'purchasableOfferId'
 *   },
 * });
 */
export function usePurchasableOfferQuery(
  baseOptions: Apollo.QueryHookOptions<PurchasableOfferQuery, PurchasableOfferQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PurchasableOfferQuery, PurchasableOfferQueryVariables>(PurchasableOfferDocument, options)
}
export function usePurchasableOfferLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PurchasableOfferQuery, PurchasableOfferQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PurchasableOfferQuery, PurchasableOfferQueryVariables>(PurchasableOfferDocument, options)
}
export type PurchasableOfferQueryHookResult = ReturnType<typeof usePurchasableOfferQuery>
export type PurchasableOfferLazyQueryHookResult = ReturnType<typeof usePurchasableOfferLazyQuery>
export type PurchasableOfferQueryResult = Apollo.QueryResult<PurchasableOfferQuery, PurchasableOfferQueryVariables>
export const CreatePurchasableOfferDocument = gql`
  mutation createPurchasableOffer($input: CreatePurchasableOfferInput!) {
    createPurchasableOffer(input: $input) {
      ...purchasableOfferContent
    }
  }
  ${PurchasableOfferContentFragmentDoc}
`
export type CreatePurchasableOfferMutationFn = Apollo.MutationFunction<
  CreatePurchasableOfferMutation,
  CreatePurchasableOfferMutationVariables
>

/**
 * __useCreatePurchasableOfferMutation__
 *
 * To run a mutation, you first call `useCreatePurchasableOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePurchasableOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPurchasableOfferMutation, { data, loading, error }] = useCreatePurchasableOfferMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePurchasableOfferMutation(
  baseOptions?: Apollo.MutationHookOptions<CreatePurchasableOfferMutation, CreatePurchasableOfferMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreatePurchasableOfferMutation, CreatePurchasableOfferMutationVariables>(
    CreatePurchasableOfferDocument,
    options,
  )
}
export type CreatePurchasableOfferMutationHookResult = ReturnType<typeof useCreatePurchasableOfferMutation>
export type CreatePurchasableOfferMutationResult = Apollo.MutationResult<CreatePurchasableOfferMutation>
export type CreatePurchasableOfferMutationOptions = Apollo.BaseMutationOptions<
  CreatePurchasableOfferMutation,
  CreatePurchasableOfferMutationVariables
>
export const UpdatePurchasableOfferDocument = gql`
  mutation updatePurchasableOffer($id: ID!, $input: UpdatePurchasableOfferInput!) {
    updatePurchasableOffer(id: $id, input: $input) {
      ...purchasableOfferContent
    }
  }
  ${PurchasableOfferContentFragmentDoc}
`
export type UpdatePurchasableOfferMutationFn = Apollo.MutationFunction<
  UpdatePurchasableOfferMutation,
  UpdatePurchasableOfferMutationVariables
>

/**
 * __useUpdatePurchasableOfferMutation__
 *
 * To run a mutation, you first call `useUpdatePurchasableOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePurchasableOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePurchasableOfferMutation, { data, loading, error }] = useUpdatePurchasableOfferMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePurchasableOfferMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePurchasableOfferMutation, UpdatePurchasableOfferMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdatePurchasableOfferMutation, UpdatePurchasableOfferMutationVariables>(
    UpdatePurchasableOfferDocument,
    options,
  )
}
export type UpdatePurchasableOfferMutationHookResult = ReturnType<typeof useUpdatePurchasableOfferMutation>
export type UpdatePurchasableOfferMutationResult = Apollo.MutationResult<UpdatePurchasableOfferMutation>
export type UpdatePurchasableOfferMutationOptions = Apollo.BaseMutationOptions<
  UpdatePurchasableOfferMutation,
  UpdatePurchasableOfferMutationVariables
>
export const UpdateProductDocument = gql`
  mutation updateProduct($id: ID!, $productId: String!, $input: UpdateProductInput!) {
    updateProduct(id: $id, productId: $productId, input: $input) {
      ...purchasableOfferProductContent
    }
  }
  ${PurchasableOfferProductContentFragmentDoc}
`
export type UpdateProductMutationFn = Apollo.MutationFunction<UpdateProductMutation, UpdateProductMutationVariables>

/**
 * __useUpdateProductMutation__
 *
 * To run a mutation, you first call `useUpdateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductMutation, { data, loading, error }] = useUpdateProductMutation({
 *   variables: {
 *      id: // value for 'id'
 *      productId: // value for 'productId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProductMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateProductMutation, UpdateProductMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateProductMutation, UpdateProductMutationVariables>(UpdateProductDocument, options)
}
export type UpdateProductMutationHookResult = ReturnType<typeof useUpdateProductMutation>
export type UpdateProductMutationResult = Apollo.MutationResult<UpdateProductMutation>
export type UpdateProductMutationOptions = Apollo.BaseMutationOptions<
  UpdateProductMutation,
  UpdateProductMutationVariables
>
export const LatestPurchaseExportDownloadUrlDocument = gql`
  query latestPurchaseExportDownloadUrl {
    latestPurchaseExportDownloadUrl
  }
`

/**
 * __useLatestPurchaseExportDownloadUrlQuery__
 *
 * To run a query within a React component, call `useLatestPurchaseExportDownloadUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestPurchaseExportDownloadUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestPurchaseExportDownloadUrlQuery({
 *   variables: {
 *   },
 * });
 */
export function useLatestPurchaseExportDownloadUrlQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LatestPurchaseExportDownloadUrlQuery,
    LatestPurchaseExportDownloadUrlQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<LatestPurchaseExportDownloadUrlQuery, LatestPurchaseExportDownloadUrlQueryVariables>(
    LatestPurchaseExportDownloadUrlDocument,
    options,
  )
}
export function useLatestPurchaseExportDownloadUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LatestPurchaseExportDownloadUrlQuery,
    LatestPurchaseExportDownloadUrlQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<LatestPurchaseExportDownloadUrlQuery, LatestPurchaseExportDownloadUrlQueryVariables>(
    LatestPurchaseExportDownloadUrlDocument,
    options,
  )
}
export type LatestPurchaseExportDownloadUrlQueryHookResult = ReturnType<typeof useLatestPurchaseExportDownloadUrlQuery>
export type LatestPurchaseExportDownloadUrlLazyQueryHookResult = ReturnType<
  typeof useLatestPurchaseExportDownloadUrlLazyQuery
>
export type LatestPurchaseExportDownloadUrlQueryResult = Apollo.QueryResult<
  LatestPurchaseExportDownloadUrlQuery,
  LatestPurchaseExportDownloadUrlQueryVariables
>
export const DeleteProductDocument = gql`
  mutation deleteProduct($id: ID!, $input: DeleteProductInput!) {
    deleteProduct(id: $id, input: $input)
  }
`
export type DeleteProductMutationFn = Apollo.MutationFunction<DeleteProductMutation, DeleteProductMutationVariables>

/**
 * __useDeleteProductMutation__
 *
 * To run a mutation, you first call `useDeleteProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductMutation, { data, loading, error }] = useDeleteProductMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteProductMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteProductMutation, DeleteProductMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteProductMutation, DeleteProductMutationVariables>(DeleteProductDocument, options)
}
export type DeleteProductMutationHookResult = ReturnType<typeof useDeleteProductMutation>
export type DeleteProductMutationResult = Apollo.MutationResult<DeleteProductMutation>
export type DeleteProductMutationOptions = Apollo.BaseMutationOptions<
  DeleteProductMutation,
  DeleteProductMutationVariables
>
export const DeletePurchasableOfferDocument = gql`
  mutation deletePurchasableOffer($id: ID!) {
    deletePurchasableOffer(id: $id)
  }
`
export type DeletePurchasableOfferMutationFn = Apollo.MutationFunction<
  DeletePurchasableOfferMutation,
  DeletePurchasableOfferMutationVariables
>

/**
 * __useDeletePurchasableOfferMutation__
 *
 * To run a mutation, you first call `useDeletePurchasableOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePurchasableOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePurchasableOfferMutation, { data, loading, error }] = useDeletePurchasableOfferMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePurchasableOfferMutation(
  baseOptions?: Apollo.MutationHookOptions<DeletePurchasableOfferMutation, DeletePurchasableOfferMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeletePurchasableOfferMutation, DeletePurchasableOfferMutationVariables>(
    DeletePurchasableOfferDocument,
    options,
  )
}
export type DeletePurchasableOfferMutationHookResult = ReturnType<typeof useDeletePurchasableOfferMutation>
export type DeletePurchasableOfferMutationResult = Apollo.MutationResult<DeletePurchasableOfferMutation>
export type DeletePurchasableOfferMutationOptions = Apollo.BaseMutationOptions<
  DeletePurchasableOfferMutation,
  DeletePurchasableOfferMutationVariables
>
export const SchedulePurchaseExportDocument = gql`
  mutation schedulePurchaseExport {
    schedulePurchaseExport
  }
`
export type SchedulePurchaseExportMutationFn = Apollo.MutationFunction<
  SchedulePurchaseExportMutation,
  SchedulePurchaseExportMutationVariables
>

/**
 * __useSchedulePurchaseExportMutation__
 *
 * To run a mutation, you first call `useSchedulePurchaseExportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSchedulePurchaseExportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [schedulePurchaseExportMutation, { data, loading, error }] = useSchedulePurchaseExportMutation({
 *   variables: {
 *   },
 * });
 */
export function useSchedulePurchaseExportMutation(
  baseOptions?: Apollo.MutationHookOptions<SchedulePurchaseExportMutation, SchedulePurchaseExportMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SchedulePurchaseExportMutation, SchedulePurchaseExportMutationVariables>(
    SchedulePurchaseExportDocument,
    options,
  )
}
export type SchedulePurchaseExportMutationHookResult = ReturnType<typeof useSchedulePurchaseExportMutation>
export type SchedulePurchaseExportMutationResult = Apollo.MutationResult<SchedulePurchaseExportMutation>
export type SchedulePurchaseExportMutationOptions = Apollo.BaseMutationOptions<
  SchedulePurchaseExportMutation,
  SchedulePurchaseExportMutationVariables
>
export const MovePurchasableOfferDocument = gql`
  mutation movePurchasableOffer($input: MovePurchasableOfferInput!) {
    movePurchasableOffer(input: $input) {
      id
      position
    }
  }
`
export type MovePurchasableOfferMutationFn = Apollo.MutationFunction<
  MovePurchasableOfferMutation,
  MovePurchasableOfferMutationVariables
>

/**
 * __useMovePurchasableOfferMutation__
 *
 * To run a mutation, you first call `useMovePurchasableOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMovePurchasableOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [movePurchasableOfferMutation, { data, loading, error }] = useMovePurchasableOfferMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMovePurchasableOfferMutation(
  baseOptions?: Apollo.MutationHookOptions<MovePurchasableOfferMutation, MovePurchasableOfferMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<MovePurchasableOfferMutation, MovePurchasableOfferMutationVariables>(
    MovePurchasableOfferDocument,
    options,
  )
}
export type MovePurchasableOfferMutationHookResult = ReturnType<typeof useMovePurchasableOfferMutation>
export type MovePurchasableOfferMutationResult = Apollo.MutationResult<MovePurchasableOfferMutation>
export type MovePurchasableOfferMutationOptions = Apollo.BaseMutationOptions<
  MovePurchasableOfferMutation,
  MovePurchasableOfferMutationVariables
>
export const UpdateRedButtonConfigDocument = gql`
  mutation updateRedButtonConfig {
    updateRedButtonConfig {
      lives {
        id
        title
        published
        expiryDate
      }
    }
  }
`
export type UpdateRedButtonConfigMutationFn = Apollo.MutationFunction<
  UpdateRedButtonConfigMutation,
  UpdateRedButtonConfigMutationVariables
>

/**
 * __useUpdateRedButtonConfigMutation__
 *
 * To run a mutation, you first call `useUpdateRedButtonConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRedButtonConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRedButtonConfigMutation, { data, loading, error }] = useUpdateRedButtonConfigMutation({
 *   variables: {
 *   },
 * });
 */
export function useUpdateRedButtonConfigMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateRedButtonConfigMutation, UpdateRedButtonConfigMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateRedButtonConfigMutation, UpdateRedButtonConfigMutationVariables>(
    UpdateRedButtonConfigDocument,
    options,
  )
}
export type UpdateRedButtonConfigMutationHookResult = ReturnType<typeof useUpdateRedButtonConfigMutation>
export type UpdateRedButtonConfigMutationResult = Apollo.MutationResult<UpdateRedButtonConfigMutation>
export type UpdateRedButtonConfigMutationOptions = Apollo.BaseMutationOptions<
  UpdateRedButtonConfigMutation,
  UpdateRedButtonConfigMutationVariables
>
export const CleanCacheGlobalDocument = gql`
  mutation cleanCacheGlobal {
    cleanCacheGlobal
  }
`
export type CleanCacheGlobalMutationFn = Apollo.MutationFunction<
  CleanCacheGlobalMutation,
  CleanCacheGlobalMutationVariables
>

/**
 * __useCleanCacheGlobalMutation__
 *
 * To run a mutation, you first call `useCleanCacheGlobalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCleanCacheGlobalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cleanCacheGlobalMutation, { data, loading, error }] = useCleanCacheGlobalMutation({
 *   variables: {
 *   },
 * });
 */
export function useCleanCacheGlobalMutation(
  baseOptions?: Apollo.MutationHookOptions<CleanCacheGlobalMutation, CleanCacheGlobalMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CleanCacheGlobalMutation, CleanCacheGlobalMutationVariables>(
    CleanCacheGlobalDocument,
    options,
  )
}
export type CleanCacheGlobalMutationHookResult = ReturnType<typeof useCleanCacheGlobalMutation>
export type CleanCacheGlobalMutationResult = Apollo.MutationResult<CleanCacheGlobalMutation>
export type CleanCacheGlobalMutationOptions = Apollo.BaseMutationOptions<
  CleanCacheGlobalMutation,
  CleanCacheGlobalMutationVariables
>
export const CleanCacheDatasourcesDocument = gql`
  mutation cleanCacheDatasources {
    cleanCacheDatasources
  }
`
export type CleanCacheDatasourcesMutationFn = Apollo.MutationFunction<
  CleanCacheDatasourcesMutation,
  CleanCacheDatasourcesMutationVariables
>

/**
 * __useCleanCacheDatasourcesMutation__
 *
 * To run a mutation, you first call `useCleanCacheDatasourcesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCleanCacheDatasourcesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cleanCacheDatasourcesMutation, { data, loading, error }] = useCleanCacheDatasourcesMutation({
 *   variables: {
 *   },
 * });
 */
export function useCleanCacheDatasourcesMutation(
  baseOptions?: Apollo.MutationHookOptions<CleanCacheDatasourcesMutation, CleanCacheDatasourcesMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CleanCacheDatasourcesMutation, CleanCacheDatasourcesMutationVariables>(
    CleanCacheDatasourcesDocument,
    options,
  )
}
export type CleanCacheDatasourcesMutationHookResult = ReturnType<typeof useCleanCacheDatasourcesMutation>
export type CleanCacheDatasourcesMutationResult = Apollo.MutationResult<CleanCacheDatasourcesMutation>
export type CleanCacheDatasourcesMutationOptions = Apollo.BaseMutationOptions<
  CleanCacheDatasourcesMutation,
  CleanCacheDatasourcesMutationVariables
>
export const CleanCacheGraphqlDocument = gql`
  mutation cleanCacheGraphql {
    cleanCacheGraphql
  }
`
export type CleanCacheGraphqlMutationFn = Apollo.MutationFunction<
  CleanCacheGraphqlMutation,
  CleanCacheGraphqlMutationVariables
>

/**
 * __useCleanCacheGraphqlMutation__
 *
 * To run a mutation, you first call `useCleanCacheGraphqlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCleanCacheGraphqlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cleanCacheGraphqlMutation, { data, loading, error }] = useCleanCacheGraphqlMutation({
 *   variables: {
 *   },
 * });
 */
export function useCleanCacheGraphqlMutation(
  baseOptions?: Apollo.MutationHookOptions<CleanCacheGraphqlMutation, CleanCacheGraphqlMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CleanCacheGraphqlMutation, CleanCacheGraphqlMutationVariables>(
    CleanCacheGraphqlDocument,
    options,
  )
}
export type CleanCacheGraphqlMutationHookResult = ReturnType<typeof useCleanCacheGraphqlMutation>
export type CleanCacheGraphqlMutationResult = Apollo.MutationResult<CleanCacheGraphqlMutation>
export type CleanCacheGraphqlMutationOptions = Apollo.BaseMutationOptions<
  CleanCacheGraphqlMutation,
  CleanCacheGraphqlMutationVariables
>
export const CreateSportDocument = gql`
  mutation createSport($input: CreateSportInput!) {
    createSport(input: $input) {
      id
      title
      color
      cardImage {
        ...mediaContent
      }
      bannerImage {
        ...mediaContent
      }
      bannerVideo {
        id
        videoClip {
          id
          playbackUrl
        }
      }
      iconImage {
        ...mediaContent
      }
      organisms {
        id
      }
    }
  }
  ${MediaContentFragmentDoc}
`
export type CreateSportMutationFn = Apollo.MutationFunction<CreateSportMutation, CreateSportMutationVariables>

/**
 * __useCreateSportMutation__
 *
 * To run a mutation, you first call `useCreateSportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSportMutation, { data, loading, error }] = useCreateSportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSportMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateSportMutation, CreateSportMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateSportMutation, CreateSportMutationVariables>(CreateSportDocument, options)
}
export type CreateSportMutationHookResult = ReturnType<typeof useCreateSportMutation>
export type CreateSportMutationResult = Apollo.MutationResult<CreateSportMutation>
export type CreateSportMutationOptions = Apollo.BaseMutationOptions<CreateSportMutation, CreateSportMutationVariables>
export const UpdateSportDocument = gql`
  mutation updateSport($input: UpdateSportInput!) {
    updateSport(input: $input) {
      id
      title
      color
      onFront
      frontPosition
      cardImage {
        ...mediaContent
      }
      bannerImage {
        ...mediaContent
      }
      bannerVideo {
        id
        videoClip {
          id
          playbackUrl
        }
      }
      iconImage {
        ...mediaContent
      }
    }
  }
  ${MediaContentFragmentDoc}
`
export type UpdateSportMutationFn = Apollo.MutationFunction<UpdateSportMutation, UpdateSportMutationVariables>

/**
 * __useUpdateSportMutation__
 *
 * To run a mutation, you first call `useUpdateSportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSportMutation, { data, loading, error }] = useUpdateSportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSportMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateSportMutation, UpdateSportMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateSportMutation, UpdateSportMutationVariables>(UpdateSportDocument, options)
}
export type UpdateSportMutationHookResult = ReturnType<typeof useUpdateSportMutation>
export type UpdateSportMutationResult = Apollo.MutationResult<UpdateSportMutation>
export type UpdateSportMutationOptions = Apollo.BaseMutationOptions<UpdateSportMutation, UpdateSportMutationVariables>
export const DeleteSportDocument = gql`
  mutation deleteSport($sportId: ID!) {
    deleteSport(id: $sportId) {
      id
    }
  }
`
export type DeleteSportMutationFn = Apollo.MutationFunction<DeleteSportMutation, DeleteSportMutationVariables>

/**
 * __useDeleteSportMutation__
 *
 * To run a mutation, you first call `useDeleteSportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSportMutation, { data, loading, error }] = useDeleteSportMutation({
 *   variables: {
 *      sportId: // value for 'sportId'
 *   },
 * });
 */
export function useDeleteSportMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteSportMutation, DeleteSportMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteSportMutation, DeleteSportMutationVariables>(DeleteSportDocument, options)
}
export type DeleteSportMutationHookResult = ReturnType<typeof useDeleteSportMutation>
export type DeleteSportMutationResult = Apollo.MutationResult<DeleteSportMutation>
export type DeleteSportMutationOptions = Apollo.BaseMutationOptions<DeleteSportMutation, DeleteSportMutationVariables>
export const GetSportsDocument = gql`
  query getSports {
    sports {
      sports {
        id
        title
        color
        organisms {
          id
          title
        }
        onFront
        frontPosition
        bannerImage {
          ...mediaContent
        }
        bannerVideo {
          id
          videoClip {
            id
            playbackUrl
          }
        }
        iconImage {
          ...mediaContent
        }
        cardImage {
          ...mediaContent
        }
      }
      totalCount
    }
  }
  ${MediaContentFragmentDoc}
`

/**
 * __useGetSportsQuery__
 *
 * To run a query within a React component, call `useGetSportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSportsQuery(baseOptions?: Apollo.QueryHookOptions<GetSportsQuery, GetSportsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetSportsQuery, GetSportsQueryVariables>(GetSportsDocument, options)
}
export function useGetSportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSportsQuery, GetSportsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetSportsQuery, GetSportsQueryVariables>(GetSportsDocument, options)
}
export type GetSportsQueryHookResult = ReturnType<typeof useGetSportsQuery>
export type GetSportsLazyQueryHookResult = ReturnType<typeof useGetSportsLazyQuery>
export type GetSportsQueryResult = Apollo.QueryResult<GetSportsQuery, GetSportsQueryVariables>
export const SportsDocument = gql`
  query sports {
    sports {
      sports {
        id
        title
      }
      totalCount
    }
  }
`

/**
 * __useSportsQuery__
 *
 * To run a query within a React component, call `useSportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSportsQuery(baseOptions?: Apollo.QueryHookOptions<SportsQuery, SportsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SportsQuery, SportsQueryVariables>(SportsDocument, options)
}
export function useSportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SportsQuery, SportsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SportsQuery, SportsQueryVariables>(SportsDocument, options)
}
export type SportsQueryHookResult = ReturnType<typeof useSportsQuery>
export type SportsLazyQueryHookResult = ReturnType<typeof useSportsLazyQuery>
export type SportsQueryResult = Apollo.QueryResult<SportsQuery, SportsQueryVariables>
export const SportDocument = gql`
  query sport($sportId: ID!) {
    sport(id: $sportId) {
      id
      title
      titleT {
        ...contentTranslationContent
      }
      defaultLocale
      color
      onFront
      frontPosition
      cardImage {
        ...mediaContent
      }
      bannerImage {
        ...mediaContent
      }
      bannerVideo {
        id
        videoClip {
          id
          playbackUrl
        }
      }
      iconImage {
        ...mediaContent
      }
    }
  }
  ${ContentTranslationContentFragmentDoc}
  ${MediaContentFragmentDoc}
`

/**
 * __useSportQuery__
 *
 * To run a query within a React component, call `useSportQuery` and pass it any options that fit your needs.
 * When your component renders, `useSportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSportQuery({
 *   variables: {
 *      sportId: // value for 'sportId'
 *   },
 * });
 */
export function useSportQuery(baseOptions: Apollo.QueryHookOptions<SportQuery, SportQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SportQuery, SportQueryVariables>(SportDocument, options)
}
export function useSportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SportQuery, SportQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SportQuery, SportQueryVariables>(SportDocument, options)
}
export type SportQueryHookResult = ReturnType<typeof useSportQuery>
export type SportLazyQueryHookResult = ReturnType<typeof useSportLazyQuery>
export type SportQueryResult = Apollo.QueryResult<SportQuery, SportQueryVariables>
export const MoveSportFrontPageDocument = gql`
  mutation moveSportFrontPage($input: MoveFrontPageInput!) {
    moveSportFrontPage(input: $input) {
      id
      frontPosition
    }
  }
`
export type MoveSportFrontPageMutationFn = Apollo.MutationFunction<
  MoveSportFrontPageMutation,
  MoveSportFrontPageMutationVariables
>

/**
 * __useMoveSportFrontPageMutation__
 *
 * To run a mutation, you first call `useMoveSportFrontPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveSportFrontPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveSportFrontPageMutation, { data, loading, error }] = useMoveSportFrontPageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMoveSportFrontPageMutation(
  baseOptions?: Apollo.MutationHookOptions<MoveSportFrontPageMutation, MoveSportFrontPageMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<MoveSportFrontPageMutation, MoveSportFrontPageMutationVariables>(
    MoveSportFrontPageDocument,
    options,
  )
}
export type MoveSportFrontPageMutationHookResult = ReturnType<typeof useMoveSportFrontPageMutation>
export type MoveSportFrontPageMutationResult = Apollo.MutationResult<MoveSportFrontPageMutation>
export type MoveSportFrontPageMutationOptions = Apollo.BaseMutationOptions<
  MoveSportFrontPageMutation,
  MoveSportFrontPageMutationVariables
>
export const SportItemsDocument = gql`
  query sportItems($sportItemSetId: ID!, $filters: SportItemFilters) {
    sportItems(sportItemSetId: $sportItemSetId, filters: $filters) {
      sportItems {
        ...sportItemLightContent
      }
      sportItemSet {
        id
      }
      totalCount
    }
  }
  ${SportItemLightContentFragmentDoc}
`

/**
 * __useSportItemsQuery__
 *
 * To run a query within a React component, call `useSportItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSportItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSportItemsQuery({
 *   variables: {
 *      sportItemSetId: // value for 'sportItemSetId'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useSportItemsQuery(baseOptions: Apollo.QueryHookOptions<SportItemsQuery, SportItemsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SportItemsQuery, SportItemsQueryVariables>(SportItemsDocument, options)
}
export function useSportItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SportItemsQuery, SportItemsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SportItemsQuery, SportItemsQueryVariables>(SportItemsDocument, options)
}
export type SportItemsQueryHookResult = ReturnType<typeof useSportItemsQuery>
export type SportItemsLazyQueryHookResult = ReturnType<typeof useSportItemsLazyQuery>
export type SportItemsQueryResult = Apollo.QueryResult<SportItemsQuery, SportItemsQueryVariables>
export const SportItemDocument = gql`
  query sportItem($sportItemId: ID!) {
    sportItem(id: $sportItemId) {
      ...sportItemContent
    }
  }
  ${SportItemContentFragmentDoc}
`

/**
 * __useSportItemQuery__
 *
 * To run a query within a React component, call `useSportItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useSportItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSportItemQuery({
 *   variables: {
 *      sportItemId: // value for 'sportItemId'
 *   },
 * });
 */
export function useSportItemQuery(baseOptions: Apollo.QueryHookOptions<SportItemQuery, SportItemQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SportItemQuery, SportItemQueryVariables>(SportItemDocument, options)
}
export function useSportItemLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SportItemQuery, SportItemQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SportItemQuery, SportItemQueryVariables>(SportItemDocument, options)
}
export type SportItemQueryHookResult = ReturnType<typeof useSportItemQuery>
export type SportItemLazyQueryHookResult = ReturnType<typeof useSportItemLazyQuery>
export type SportItemQueryResult = Apollo.QueryResult<SportItemQuery, SportItemQueryVariables>
export const SportItemWithSportItemSetDocument = gql`
  query sportItemWithSportItemSet($sportItemId: ID!) {
    sportItem(id: $sportItemId) {
      ...sportItemContent
      sportItemSet {
        ...sportItemSetContent
      }
    }
  }
  ${SportItemContentFragmentDoc}
  ${SportItemSetContentFragmentDoc}
`

/**
 * __useSportItemWithSportItemSetQuery__
 *
 * To run a query within a React component, call `useSportItemWithSportItemSetQuery` and pass it any options that fit your needs.
 * When your component renders, `useSportItemWithSportItemSetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSportItemWithSportItemSetQuery({
 *   variables: {
 *      sportItemId: // value for 'sportItemId'
 *   },
 * });
 */
export function useSportItemWithSportItemSetQuery(
  baseOptions: Apollo.QueryHookOptions<SportItemWithSportItemSetQuery, SportItemWithSportItemSetQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SportItemWithSportItemSetQuery, SportItemWithSportItemSetQueryVariables>(
    SportItemWithSportItemSetDocument,
    options,
  )
}
export function useSportItemWithSportItemSetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SportItemWithSportItemSetQuery, SportItemWithSportItemSetQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SportItemWithSportItemSetQuery, SportItemWithSportItemSetQueryVariables>(
    SportItemWithSportItemSetDocument,
    options,
  )
}
export type SportItemWithSportItemSetQueryHookResult = ReturnType<typeof useSportItemWithSportItemSetQuery>
export type SportItemWithSportItemSetLazyQueryHookResult = ReturnType<typeof useSportItemWithSportItemSetLazyQuery>
export type SportItemWithSportItemSetQueryResult = Apollo.QueryResult<
  SportItemWithSportItemSetQuery,
  SportItemWithSportItemSetQueryVariables
>
export const SportItemByChatRoomDocument = gql`
  query sportItemByChatRoom($chatRoomId: ID!) {
    sportItemByChatRoom(chatRoomId: $chatRoomId) {
      ...sportItemContent
    }
  }
  ${SportItemContentFragmentDoc}
`

/**
 * __useSportItemByChatRoomQuery__
 *
 * To run a query within a React component, call `useSportItemByChatRoomQuery` and pass it any options that fit your needs.
 * When your component renders, `useSportItemByChatRoomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSportItemByChatRoomQuery({
 *   variables: {
 *      chatRoomId: // value for 'chatRoomId'
 *   },
 * });
 */
export function useSportItemByChatRoomQuery(
  baseOptions: Apollo.QueryHookOptions<SportItemByChatRoomQuery, SportItemByChatRoomQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SportItemByChatRoomQuery, SportItemByChatRoomQueryVariables>(
    SportItemByChatRoomDocument,
    options,
  )
}
export function useSportItemByChatRoomLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SportItemByChatRoomQuery, SportItemByChatRoomQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SportItemByChatRoomQuery, SportItemByChatRoomQueryVariables>(
    SportItemByChatRoomDocument,
    options,
  )
}
export type SportItemByChatRoomQueryHookResult = ReturnType<typeof useSportItemByChatRoomQuery>
export type SportItemByChatRoomLazyQueryHookResult = ReturnType<typeof useSportItemByChatRoomLazyQuery>
export type SportItemByChatRoomQueryResult = Apollo.QueryResult<
  SportItemByChatRoomQuery,
  SportItemByChatRoomQueryVariables
>
export const CheckSportItemStreamsMovableDocument = gql`
  query checkSportItemStreamsMovable($sportItemId: ID!, $from: Date!, $to: Date!) {
    checkSportItemStreamsMovable(sportItemId: $sportItemId, from: $from, to: $to) {
      stream {
        id
        name
      }
      sportItem {
        id
        title
      }
    }
  }
`

/**
 * __useCheckSportItemStreamsMovableQuery__
 *
 * To run a query within a React component, call `useCheckSportItemStreamsMovableQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckSportItemStreamsMovableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckSportItemStreamsMovableQuery({
 *   variables: {
 *      sportItemId: // value for 'sportItemId'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useCheckSportItemStreamsMovableQuery(
  baseOptions: Apollo.QueryHookOptions<CheckSportItemStreamsMovableQuery, CheckSportItemStreamsMovableQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CheckSportItemStreamsMovableQuery, CheckSportItemStreamsMovableQueryVariables>(
    CheckSportItemStreamsMovableDocument,
    options,
  )
}
export function useCheckSportItemStreamsMovableLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CheckSportItemStreamsMovableQuery,
    CheckSportItemStreamsMovableQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CheckSportItemStreamsMovableQuery, CheckSportItemStreamsMovableQueryVariables>(
    CheckSportItemStreamsMovableDocument,
    options,
  )
}
export type CheckSportItemStreamsMovableQueryHookResult = ReturnType<typeof useCheckSportItemStreamsMovableQuery>
export type CheckSportItemStreamsMovableLazyQueryHookResult = ReturnType<
  typeof useCheckSportItemStreamsMovableLazyQuery
>
export type CheckSportItemStreamsMovableQueryResult = Apollo.QueryResult<
  CheckSportItemStreamsMovableQuery,
  CheckSportItemStreamsMovableQueryVariables
>
export const MonitoringDashboardDocument = gql`
  query monitoringDashboard($date: Date!, $organismId: ID) {
    monitoringDashboard(date: $date, organismId: $organismId) {
      ...sportItemContent
      sportItemSet {
        ...sportItemSetContent
      }
    }
  }
  ${SportItemContentFragmentDoc}
  ${SportItemSetContentFragmentDoc}
`

/**
 * __useMonitoringDashboardQuery__
 *
 * To run a query within a React component, call `useMonitoringDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useMonitoringDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMonitoringDashboardQuery({
 *   variables: {
 *      date: // value for 'date'
 *      organismId: // value for 'organismId'
 *   },
 * });
 */
export function useMonitoringDashboardQuery(
  baseOptions: Apollo.QueryHookOptions<MonitoringDashboardQuery, MonitoringDashboardQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MonitoringDashboardQuery, MonitoringDashboardQueryVariables>(
    MonitoringDashboardDocument,
    options,
  )
}
export function useMonitoringDashboardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MonitoringDashboardQuery, MonitoringDashboardQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<MonitoringDashboardQuery, MonitoringDashboardQueryVariables>(
    MonitoringDashboardDocument,
    options,
  )
}
export type MonitoringDashboardQueryHookResult = ReturnType<typeof useMonitoringDashboardQuery>
export type MonitoringDashboardLazyQueryHookResult = ReturnType<typeof useMonitoringDashboardLazyQuery>
export type MonitoringDashboardQueryResult = Apollo.QueryResult<
  MonitoringDashboardQuery,
  MonitoringDashboardQueryVariables
>
export const CreateSportItemDocument = gql`
  mutation createSportItem($input: CreateSportItemInput!) {
    createSportItem(input: $input) {
      ...sportItemContent
    }
  }
  ${SportItemContentFragmentDoc}
`
export type CreateSportItemMutationFn = Apollo.MutationFunction<
  CreateSportItemMutation,
  CreateSportItemMutationVariables
>

/**
 * __useCreateSportItemMutation__
 *
 * To run a mutation, you first call `useCreateSportItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSportItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSportItemMutation, { data, loading, error }] = useCreateSportItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSportItemMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateSportItemMutation, CreateSportItemMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateSportItemMutation, CreateSportItemMutationVariables>(CreateSportItemDocument, options)
}
export type CreateSportItemMutationHookResult = ReturnType<typeof useCreateSportItemMutation>
export type CreateSportItemMutationResult = Apollo.MutationResult<CreateSportItemMutation>
export type CreateSportItemMutationOptions = Apollo.BaseMutationOptions<
  CreateSportItemMutation,
  CreateSportItemMutationVariables
>
export const UpdateSportItemDocument = gql`
  mutation updateSportItem($input: UpdateSportItemInput!) {
    updateSportItem(input: $input) {
      ...sportItemContent
    }
  }
  ${SportItemContentFragmentDoc}
`
export type UpdateSportItemMutationFn = Apollo.MutationFunction<
  UpdateSportItemMutation,
  UpdateSportItemMutationVariables
>

/**
 * __useUpdateSportItemMutation__
 *
 * To run a mutation, you first call `useUpdateSportItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSportItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSportItemMutation, { data, loading, error }] = useUpdateSportItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSportItemMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateSportItemMutation, UpdateSportItemMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateSportItemMutation, UpdateSportItemMutationVariables>(UpdateSportItemDocument, options)
}
export type UpdateSportItemMutationHookResult = ReturnType<typeof useUpdateSportItemMutation>
export type UpdateSportItemMutationResult = Apollo.MutationResult<UpdateSportItemMutation>
export type UpdateSportItemMutationOptions = Apollo.BaseMutationOptions<
  UpdateSportItemMutation,
  UpdateSportItemMutationVariables
>
export const DeleteSportItemDocument = gql`
  mutation deleteSportItem($sportItemId: ID!) {
    deleteSportItem(id: $sportItemId) {
      id
    }
  }
`
export type DeleteSportItemMutationFn = Apollo.MutationFunction<
  DeleteSportItemMutation,
  DeleteSportItemMutationVariables
>

/**
 * __useDeleteSportItemMutation__
 *
 * To run a mutation, you first call `useDeleteSportItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSportItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSportItemMutation, { data, loading, error }] = useDeleteSportItemMutation({
 *   variables: {
 *      sportItemId: // value for 'sportItemId'
 *   },
 * });
 */
export function useDeleteSportItemMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteSportItemMutation, DeleteSportItemMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteSportItemMutation, DeleteSportItemMutationVariables>(DeleteSportItemDocument, options)
}
export type DeleteSportItemMutationHookResult = ReturnType<typeof useDeleteSportItemMutation>
export type DeleteSportItemMutationResult = Apollo.MutationResult<DeleteSportItemMutation>
export type DeleteSportItemMutationOptions = Apollo.BaseMutationOptions<
  DeleteSportItemMutation,
  DeleteSportItemMutationVariables
>
export const StopSportItemLiveDocument = gql`
  mutation stopSportItemLive($id: ID!) {
    stopSportItemLive(id: $id) {
      ...sportItemContent
    }
  }
  ${SportItemContentFragmentDoc}
`
export type StopSportItemLiveMutationFn = Apollo.MutationFunction<
  StopSportItemLiveMutation,
  StopSportItemLiveMutationVariables
>

/**
 * __useStopSportItemLiveMutation__
 *
 * To run a mutation, you first call `useStopSportItemLiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStopSportItemLiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stopSportItemLiveMutation, { data, loading, error }] = useStopSportItemLiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStopSportItemLiveMutation(
  baseOptions?: Apollo.MutationHookOptions<StopSportItemLiveMutation, StopSportItemLiveMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<StopSportItemLiveMutation, StopSportItemLiveMutationVariables>(
    StopSportItemLiveDocument,
    options,
  )
}
export type StopSportItemLiveMutationHookResult = ReturnType<typeof useStopSportItemLiveMutation>
export type StopSportItemLiveMutationResult = Apollo.MutationResult<StopSportItemLiveMutation>
export type StopSportItemLiveMutationOptions = Apollo.BaseMutationOptions<
  StopSportItemLiveMutation,
  StopSportItemLiveMutationVariables
>
export const MovePlaylistItemDocument = gql`
  mutation movePlaylistItem($input: MovePlaylistItemInput!) {
    movePlaylistItem(input: $input) {
      ...sportItemLightContent
    }
  }
  ${SportItemLightContentFragmentDoc}
`
export type MovePlaylistItemMutationFn = Apollo.MutationFunction<
  MovePlaylistItemMutation,
  MovePlaylistItemMutationVariables
>

/**
 * __useMovePlaylistItemMutation__
 *
 * To run a mutation, you first call `useMovePlaylistItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMovePlaylistItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [movePlaylistItemMutation, { data, loading, error }] = useMovePlaylistItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMovePlaylistItemMutation(
  baseOptions?: Apollo.MutationHookOptions<MovePlaylistItemMutation, MovePlaylistItemMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<MovePlaylistItemMutation, MovePlaylistItemMutationVariables>(
    MovePlaylistItemDocument,
    options,
  )
}
export type MovePlaylistItemMutationHookResult = ReturnType<typeof useMovePlaylistItemMutation>
export type MovePlaylistItemMutationResult = Apollo.MutationResult<MovePlaylistItemMutation>
export type MovePlaylistItemMutationOptions = Apollo.BaseMutationOptions<
  MovePlaylistItemMutation,
  MovePlaylistItemMutationVariables
>
export const SportItemSetsDocument = gql`
  query sportItemSets(
    $filters: SportItemSetFilters
    $first: Int
    $offset: Int
    $orderBy: [SportItemSetSort!]
    $withItems: Boolean
  ) {
    sportItemSets(filters: $filters, first: $first, offset: $offset, orderBy: $orderBy, withItems: $withItems) {
      sportItemSets {
        ...lightSportItemSetContent
        items {
          id
          title
        }
      }
      totalCount
    }
  }
  ${LightSportItemSetContentFragmentDoc}
`

/**
 * __useSportItemSetsQuery__
 *
 * To run a query within a React component, call `useSportItemSetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSportItemSetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSportItemSetsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      withItems: // value for 'withItems'
 *   },
 * });
 */
export function useSportItemSetsQuery(
  baseOptions?: Apollo.QueryHookOptions<SportItemSetsQuery, SportItemSetsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SportItemSetsQuery, SportItemSetsQueryVariables>(SportItemSetsDocument, options)
}
export function useSportItemSetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SportItemSetsQuery, SportItemSetsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SportItemSetsQuery, SportItemSetsQueryVariables>(SportItemSetsDocument, options)
}
export type SportItemSetsQueryHookResult = ReturnType<typeof useSportItemSetsQuery>
export type SportItemSetsLazyQueryHookResult = ReturnType<typeof useSportItemSetsLazyQuery>
export type SportItemSetsQueryResult = Apollo.QueryResult<SportItemSetsQuery, SportItemSetsQueryVariables>
export const SportItemSetDocument = gql`
  query sportItemSet($sportItemSetId: ID!) {
    sportItemSet(id: $sportItemSetId) {
      ...sportItemSetContent
    }
  }
  ${SportItemSetContentFragmentDoc}
`

/**
 * __useSportItemSetQuery__
 *
 * To run a query within a React component, call `useSportItemSetQuery` and pass it any options that fit your needs.
 * When your component renders, `useSportItemSetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSportItemSetQuery({
 *   variables: {
 *      sportItemSetId: // value for 'sportItemSetId'
 *   },
 * });
 */
export function useSportItemSetQuery(
  baseOptions: Apollo.QueryHookOptions<SportItemSetQuery, SportItemSetQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SportItemSetQuery, SportItemSetQueryVariables>(SportItemSetDocument, options)
}
export function useSportItemSetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SportItemSetQuery, SportItemSetQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SportItemSetQuery, SportItemSetQueryVariables>(SportItemSetDocument, options)
}
export type SportItemSetQueryHookResult = ReturnType<typeof useSportItemSetQuery>
export type SportItemSetLazyQueryHookResult = ReturnType<typeof useSportItemSetLazyQuery>
export type SportItemSetQueryResult = Apollo.QueryResult<SportItemSetQuery, SportItemSetQueryVariables>
export const SportItemSetWithOrganismDocument = gql`
  query sportItemSetWithOrganism($sportItemSetId: ID!) {
    sportItemSet(id: $sportItemSetId) {
      ...sportItemSetWithOrganismContent
    }
  }
  ${SportItemSetWithOrganismContentFragmentDoc}
`

/**
 * __useSportItemSetWithOrganismQuery__
 *
 * To run a query within a React component, call `useSportItemSetWithOrganismQuery` and pass it any options that fit your needs.
 * When your component renders, `useSportItemSetWithOrganismQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSportItemSetWithOrganismQuery({
 *   variables: {
 *      sportItemSetId: // value for 'sportItemSetId'
 *   },
 * });
 */
export function useSportItemSetWithOrganismQuery(
  baseOptions: Apollo.QueryHookOptions<SportItemSetWithOrganismQuery, SportItemSetWithOrganismQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SportItemSetWithOrganismQuery, SportItemSetWithOrganismQueryVariables>(
    SportItemSetWithOrganismDocument,
    options,
  )
}
export function useSportItemSetWithOrganismLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SportItemSetWithOrganismQuery, SportItemSetWithOrganismQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SportItemSetWithOrganismQuery, SportItemSetWithOrganismQueryVariables>(
    SportItemSetWithOrganismDocument,
    options,
  )
}
export type SportItemSetWithOrganismQueryHookResult = ReturnType<typeof useSportItemSetWithOrganismQuery>
export type SportItemSetWithOrganismLazyQueryHookResult = ReturnType<typeof useSportItemSetWithOrganismLazyQuery>
export type SportItemSetWithOrganismQueryResult = Apollo.QueryResult<
  SportItemSetWithOrganismQuery,
  SportItemSetWithOrganismQueryVariables
>
export const SportItemSetsWithChildrenDocument = gql`
  query sportItemSetsWithChildren($filters: SportItemSetFilters) {
    sportItemSets(filters: $filters) {
      sportItemSets {
        id
        type
        ownerOrganismId
        title
        items {
          id
          videoContentType
          title
        }
      }
      totalCount
    }
  }
`

/**
 * __useSportItemSetsWithChildrenQuery__
 *
 * To run a query within a React component, call `useSportItemSetsWithChildrenQuery` and pass it any options that fit your needs.
 * When your component renders, `useSportItemSetsWithChildrenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSportItemSetsWithChildrenQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useSportItemSetsWithChildrenQuery(
  baseOptions?: Apollo.QueryHookOptions<SportItemSetsWithChildrenQuery, SportItemSetsWithChildrenQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SportItemSetsWithChildrenQuery, SportItemSetsWithChildrenQueryVariables>(
    SportItemSetsWithChildrenDocument,
    options,
  )
}
export function useSportItemSetsWithChildrenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SportItemSetsWithChildrenQuery, SportItemSetsWithChildrenQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SportItemSetsWithChildrenQuery, SportItemSetsWithChildrenQueryVariables>(
    SportItemSetsWithChildrenDocument,
    options,
  )
}
export type SportItemSetsWithChildrenQueryHookResult = ReturnType<typeof useSportItemSetsWithChildrenQuery>
export type SportItemSetsWithChildrenLazyQueryHookResult = ReturnType<typeof useSportItemSetsWithChildrenLazyQuery>
export type SportItemSetsWithChildrenQueryResult = Apollo.QueryResult<
  SportItemSetsWithChildrenQuery,
  SportItemSetsWithChildrenQueryVariables
>
export const SportItemSetHasLivesRunningDocument = gql`
  query sportItemSetHasLivesRunning($sportItemSetId: ID, $filters: SportItemFilters) {
    sportItemSet(id: $sportItemSetId) {
      id
      items(filters: $filters) {
        id
      }
    }
  }
`

/**
 * __useSportItemSetHasLivesRunningQuery__
 *
 * To run a query within a React component, call `useSportItemSetHasLivesRunningQuery` and pass it any options that fit your needs.
 * When your component renders, `useSportItemSetHasLivesRunningQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSportItemSetHasLivesRunningQuery({
 *   variables: {
 *      sportItemSetId: // value for 'sportItemSetId'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useSportItemSetHasLivesRunningQuery(
  baseOptions?: Apollo.QueryHookOptions<SportItemSetHasLivesRunningQuery, SportItemSetHasLivesRunningQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SportItemSetHasLivesRunningQuery, SportItemSetHasLivesRunningQueryVariables>(
    SportItemSetHasLivesRunningDocument,
    options,
  )
}
export function useSportItemSetHasLivesRunningLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SportItemSetHasLivesRunningQuery,
    SportItemSetHasLivesRunningQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SportItemSetHasLivesRunningQuery, SportItemSetHasLivesRunningQueryVariables>(
    SportItemSetHasLivesRunningDocument,
    options,
  )
}
export type SportItemSetHasLivesRunningQueryHookResult = ReturnType<typeof useSportItemSetHasLivesRunningQuery>
export type SportItemSetHasLivesRunningLazyQueryHookResult = ReturnType<typeof useSportItemSetHasLivesRunningLazyQuery>
export type SportItemSetHasLivesRunningQueryResult = Apollo.QueryResult<
  SportItemSetHasLivesRunningQuery,
  SportItemSetHasLivesRunningQueryVariables
>
export const SportItemSetAvailableSveChannelIdsDocument = gql`
  query sportItemSetAvailableSVEChannelIds($sportItemSetId: ID!) {
    sportItemSet(id: $sportItemSetId) {
      availableSVEChannelIds
    }
  }
`

/**
 * __useSportItemSetAvailableSveChannelIdsQuery__
 *
 * To run a query within a React component, call `useSportItemSetAvailableSveChannelIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSportItemSetAvailableSveChannelIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSportItemSetAvailableSveChannelIdsQuery({
 *   variables: {
 *      sportItemSetId: // value for 'sportItemSetId'
 *   },
 * });
 */
export function useSportItemSetAvailableSveChannelIdsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SportItemSetAvailableSveChannelIdsQuery,
    SportItemSetAvailableSveChannelIdsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SportItemSetAvailableSveChannelIdsQuery, SportItemSetAvailableSveChannelIdsQueryVariables>(
    SportItemSetAvailableSveChannelIdsDocument,
    options,
  )
}
export function useSportItemSetAvailableSveChannelIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SportItemSetAvailableSveChannelIdsQuery,
    SportItemSetAvailableSveChannelIdsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SportItemSetAvailableSveChannelIdsQuery, SportItemSetAvailableSveChannelIdsQueryVariables>(
    SportItemSetAvailableSveChannelIdsDocument,
    options,
  )
}
export type SportItemSetAvailableSveChannelIdsQueryHookResult = ReturnType<
  typeof useSportItemSetAvailableSveChannelIdsQuery
>
export type SportItemSetAvailableSveChannelIdsLazyQueryHookResult = ReturnType<
  typeof useSportItemSetAvailableSveChannelIdsLazyQuery
>
export type SportItemSetAvailableSveChannelIdsQueryResult = Apollo.QueryResult<
  SportItemSetAvailableSveChannelIdsQuery,
  SportItemSetAvailableSveChannelIdsQueryVariables
>
export const CreateSportItemSetDocument = gql`
  mutation createSportItemSet($input: CreateSportItemSetInput!) {
    createSportItemSet(input: $input) {
      ...sportItemSetContent
    }
  }
  ${SportItemSetContentFragmentDoc}
`
export type CreateSportItemSetMutationFn = Apollo.MutationFunction<
  CreateSportItemSetMutation,
  CreateSportItemSetMutationVariables
>

/**
 * __useCreateSportItemSetMutation__
 *
 * To run a mutation, you first call `useCreateSportItemSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSportItemSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSportItemSetMutation, { data, loading, error }] = useCreateSportItemSetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSportItemSetMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateSportItemSetMutation, CreateSportItemSetMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateSportItemSetMutation, CreateSportItemSetMutationVariables>(
    CreateSportItemSetDocument,
    options,
  )
}
export type CreateSportItemSetMutationHookResult = ReturnType<typeof useCreateSportItemSetMutation>
export type CreateSportItemSetMutationResult = Apollo.MutationResult<CreateSportItemSetMutation>
export type CreateSportItemSetMutationOptions = Apollo.BaseMutationOptions<
  CreateSportItemSetMutation,
  CreateSportItemSetMutationVariables
>
export const UpdateSportItemSetDocument = gql`
  mutation updateSportItemSet($input: UpdateSportItemSetInput!) {
    updateSportItemSet(input: $input) {
      ...sportItemSetContent
    }
  }
  ${SportItemSetContentFragmentDoc}
`
export type UpdateSportItemSetMutationFn = Apollo.MutationFunction<
  UpdateSportItemSetMutation,
  UpdateSportItemSetMutationVariables
>

/**
 * __useUpdateSportItemSetMutation__
 *
 * To run a mutation, you first call `useUpdateSportItemSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSportItemSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSportItemSetMutation, { data, loading, error }] = useUpdateSportItemSetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSportItemSetMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateSportItemSetMutation, UpdateSportItemSetMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateSportItemSetMutation, UpdateSportItemSetMutationVariables>(
    UpdateSportItemSetDocument,
    options,
  )
}
export type UpdateSportItemSetMutationHookResult = ReturnType<typeof useUpdateSportItemSetMutation>
export type UpdateSportItemSetMutationResult = Apollo.MutationResult<UpdateSportItemSetMutation>
export type UpdateSportItemSetMutationOptions = Apollo.BaseMutationOptions<
  UpdateSportItemSetMutation,
  UpdateSportItemSetMutationVariables
>
export const DeleteSportItemSetDocument = gql`
  mutation deleteSportItemSet($sportItemSetId: ID!) {
    deleteSportItemSet(id: $sportItemSetId) {
      id
    }
  }
`
export type DeleteSportItemSetMutationFn = Apollo.MutationFunction<
  DeleteSportItemSetMutation,
  DeleteSportItemSetMutationVariables
>

/**
 * __useDeleteSportItemSetMutation__
 *
 * To run a mutation, you first call `useDeleteSportItemSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSportItemSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSportItemSetMutation, { data, loading, error }] = useDeleteSportItemSetMutation({
 *   variables: {
 *      sportItemSetId: // value for 'sportItemSetId'
 *   },
 * });
 */
export function useDeleteSportItemSetMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteSportItemSetMutation, DeleteSportItemSetMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteSportItemSetMutation, DeleteSportItemSetMutationVariables>(
    DeleteSportItemSetDocument,
    options,
  )
}
export type DeleteSportItemSetMutationHookResult = ReturnType<typeof useDeleteSportItemSetMutation>
export type DeleteSportItemSetMutationResult = Apollo.MutationResult<DeleteSportItemSetMutation>
export type DeleteSportItemSetMutationOptions = Apollo.BaseMutationOptions<
  DeleteSportItemSetMutation,
  DeleteSportItemSetMutationVariables
>
export const CheckStreamsAvailableDocument = gql`
  query checkStreamsAvailable($streamsIds: [WildmokaID!]!, $from: Date!, $to: Date!) {
    checkStreamsAvailable(streamsIds: $streamsIds, from: $from, to: $to) {
      streamId
      sportItemSet {
        id
        title
      }
    }
  }
`

/**
 * __useCheckStreamsAvailableQuery__
 *
 * To run a query within a React component, call `useCheckStreamsAvailableQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckStreamsAvailableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckStreamsAvailableQuery({
 *   variables: {
 *      streamsIds: // value for 'streamsIds'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useCheckStreamsAvailableQuery(
  baseOptions: Apollo.QueryHookOptions<CheckStreamsAvailableQuery, CheckStreamsAvailableQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CheckStreamsAvailableQuery, CheckStreamsAvailableQueryVariables>(
    CheckStreamsAvailableDocument,
    options,
  )
}
export function useCheckStreamsAvailableLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CheckStreamsAvailableQuery, CheckStreamsAvailableQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CheckStreamsAvailableQuery, CheckStreamsAvailableQueryVariables>(
    CheckStreamsAvailableDocument,
    options,
  )
}
export type CheckStreamsAvailableQueryHookResult = ReturnType<typeof useCheckStreamsAvailableQuery>
export type CheckStreamsAvailableLazyQueryHookResult = ReturnType<typeof useCheckStreamsAvailableLazyQuery>
export type CheckStreamsAvailableQueryResult = Apollo.QueryResult<
  CheckStreamsAvailableQuery,
  CheckStreamsAvailableQueryVariables
>
export const CheckSveChannelsAvailableDocument = gql`
  query checkSVEChannelsAvailable($input: CheckSVEChannelsAvailableInput!) {
    checkSVEChannelsAvailable(input: $input) {
      id
      name
      status
      available
    }
  }
`

/**
 * __useCheckSveChannelsAvailableQuery__
 *
 * To run a query within a React component, call `useCheckSveChannelsAvailableQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckSveChannelsAvailableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckSveChannelsAvailableQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCheckSveChannelsAvailableQuery(
  baseOptions: Apollo.QueryHookOptions<CheckSveChannelsAvailableQuery, CheckSveChannelsAvailableQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CheckSveChannelsAvailableQuery, CheckSveChannelsAvailableQueryVariables>(
    CheckSveChannelsAvailableDocument,
    options,
  )
}
export function useCheckSveChannelsAvailableLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CheckSveChannelsAvailableQuery, CheckSveChannelsAvailableQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CheckSveChannelsAvailableQuery, CheckSveChannelsAvailableQueryVariables>(
    CheckSveChannelsAvailableDocument,
    options,
  )
}
export type CheckSveChannelsAvailableQueryHookResult = ReturnType<typeof useCheckSveChannelsAvailableQuery>
export type CheckSveChannelsAvailableLazyQueryHookResult = ReturnType<typeof useCheckSveChannelsAvailableLazyQuery>
export type CheckSveChannelsAvailableQueryResult = Apollo.QueryResult<
  CheckSveChannelsAvailableQuery,
  CheckSveChannelsAvailableQueryVariables
>
export const CreateSveChannelDocument = gql`
  mutation createSVEChannel($input: CreateSVEChannelInput!) {
    createSVEChannel(input: $input) {
      ...sveChannelContent
    }
  }
  ${SveChannelContentFragmentDoc}
`
export type CreateSveChannelMutationFn = Apollo.MutationFunction<
  CreateSveChannelMutation,
  CreateSveChannelMutationVariables
>

/**
 * __useCreateSveChannelMutation__
 *
 * To run a mutation, you first call `useCreateSveChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSveChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSveChannelMutation, { data, loading, error }] = useCreateSveChannelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSveChannelMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateSveChannelMutation, CreateSveChannelMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateSveChannelMutation, CreateSveChannelMutationVariables>(
    CreateSveChannelDocument,
    options,
  )
}
export type CreateSveChannelMutationHookResult = ReturnType<typeof useCreateSveChannelMutation>
export type CreateSveChannelMutationResult = Apollo.MutationResult<CreateSveChannelMutation>
export type CreateSveChannelMutationOptions = Apollo.BaseMutationOptions<
  CreateSveChannelMutation,
  CreateSveChannelMutationVariables
>
export const DeleteSveChannelDocument = gql`
  mutation deleteSVEChannel($channelId: ID!) {
    deleteSVEChannel(channelId: $channelId)
  }
`
export type DeleteSveChannelMutationFn = Apollo.MutationFunction<
  DeleteSveChannelMutation,
  DeleteSveChannelMutationVariables
>

/**
 * __useDeleteSveChannelMutation__
 *
 * To run a mutation, you first call `useDeleteSveChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSveChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSveChannelMutation, { data, loading, error }] = useDeleteSveChannelMutation({
 *   variables: {
 *      channelId: // value for 'channelId'
 *   },
 * });
 */
export function useDeleteSveChannelMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteSveChannelMutation, DeleteSveChannelMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteSveChannelMutation, DeleteSveChannelMutationVariables>(
    DeleteSveChannelDocument,
    options,
  )
}
export type DeleteSveChannelMutationHookResult = ReturnType<typeof useDeleteSveChannelMutation>
export type DeleteSveChannelMutationResult = Apollo.MutationResult<DeleteSveChannelMutation>
export type DeleteSveChannelMutationOptions = Apollo.BaseMutationOptions<
  DeleteSveChannelMutation,
  DeleteSveChannelMutationVariables
>
export const SveBroadcastDocument = gql`
  query sveBroadcast($broadcastId: ID!) {
    sveBroadcast(broadcastId: $broadcastId) {
      ...sveBroadcastContent
    }
  }
  ${SveBroadcastContentFragmentDoc}
`

/**
 * __useSveBroadcastQuery__
 *
 * To run a query within a React component, call `useSveBroadcastQuery` and pass it any options that fit your needs.
 * When your component renders, `useSveBroadcastQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSveBroadcastQuery({
 *   variables: {
 *      broadcastId: // value for 'broadcastId'
 *   },
 * });
 */
export function useSveBroadcastQuery(
  baseOptions: Apollo.QueryHookOptions<SveBroadcastQuery, SveBroadcastQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SveBroadcastQuery, SveBroadcastQueryVariables>(SveBroadcastDocument, options)
}
export function useSveBroadcastLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SveBroadcastQuery, SveBroadcastQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SveBroadcastQuery, SveBroadcastQueryVariables>(SveBroadcastDocument, options)
}
export type SveBroadcastQueryHookResult = ReturnType<typeof useSveBroadcastQuery>
export type SveBroadcastLazyQueryHookResult = ReturnType<typeof useSveBroadcastLazyQuery>
export type SveBroadcastQueryResult = Apollo.QueryResult<SveBroadcastQuery, SveBroadcastQueryVariables>
export const SveChannelDocument = gql`
  query sveChannel($channelId: ID!) {
    sveChannel(channelId: $channelId) {
      ...sveChannelContent
    }
  }
  ${SveChannelContentFragmentDoc}
`

/**
 * __useSveChannelQuery__
 *
 * To run a query within a React component, call `useSveChannelQuery` and pass it any options that fit your needs.
 * When your component renders, `useSveChannelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSveChannelQuery({
 *   variables: {
 *      channelId: // value for 'channelId'
 *   },
 * });
 */
export function useSveChannelQuery(baseOptions: Apollo.QueryHookOptions<SveChannelQuery, SveChannelQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SveChannelQuery, SveChannelQueryVariables>(SveChannelDocument, options)
}
export function useSveChannelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SveChannelQuery, SveChannelQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SveChannelQuery, SveChannelQueryVariables>(SveChannelDocument, options)
}
export type SveChannelQueryHookResult = ReturnType<typeof useSveChannelQuery>
export type SveChannelLazyQueryHookResult = ReturnType<typeof useSveChannelLazyQuery>
export type SveChannelQueryResult = Apollo.QueryResult<SveChannelQuery, SveChannelQueryVariables>
export const SveChannelWithBroadcastsDocument = gql`
  query sveChannelWithBroadcasts($channelId: ID!) {
    sveChannel(channelId: $channelId) {
      ...sveChannelContent
      broadcasts {
        ...sveBroadcastContent
      }
    }
  }
  ${SveChannelContentFragmentDoc}
  ${SveBroadcastContentFragmentDoc}
`

/**
 * __useSveChannelWithBroadcastsQuery__
 *
 * To run a query within a React component, call `useSveChannelWithBroadcastsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSveChannelWithBroadcastsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSveChannelWithBroadcastsQuery({
 *   variables: {
 *      channelId: // value for 'channelId'
 *   },
 * });
 */
export function useSveChannelWithBroadcastsQuery(
  baseOptions: Apollo.QueryHookOptions<SveChannelWithBroadcastsQuery, SveChannelWithBroadcastsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SveChannelWithBroadcastsQuery, SveChannelWithBroadcastsQueryVariables>(
    SveChannelWithBroadcastsDocument,
    options,
  )
}
export function useSveChannelWithBroadcastsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SveChannelWithBroadcastsQuery, SveChannelWithBroadcastsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SveChannelWithBroadcastsQuery, SveChannelWithBroadcastsQueryVariables>(
    SveChannelWithBroadcastsDocument,
    options,
  )
}
export type SveChannelWithBroadcastsQueryHookResult = ReturnType<typeof useSveChannelWithBroadcastsQuery>
export type SveChannelWithBroadcastsLazyQueryHookResult = ReturnType<typeof useSveChannelWithBroadcastsLazyQuery>
export type SveChannelWithBroadcastsQueryResult = Apollo.QueryResult<
  SveChannelWithBroadcastsQuery,
  SveChannelWithBroadcastsQueryVariables
>
export const SveChannelsDocument = gql`
  query sveChannels($input: SVEChannelsInput!) {
    sveChannels(input: $input) {
      sveChannels {
        ...sveChannelContent
      }
      totalCount
    }
  }
  ${SveChannelContentFragmentDoc}
`

/**
 * __useSveChannelsQuery__
 *
 * To run a query within a React component, call `useSveChannelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSveChannelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSveChannelsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSveChannelsQuery(baseOptions: Apollo.QueryHookOptions<SveChannelsQuery, SveChannelsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SveChannelsQuery, SveChannelsQueryVariables>(SveChannelsDocument, options)
}
export function useSveChannelsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SveChannelsQuery, SveChannelsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SveChannelsQuery, SveChannelsQueryVariables>(SveChannelsDocument, options)
}
export type SveChannelsQueryHookResult = ReturnType<typeof useSveChannelsQuery>
export type SveChannelsLazyQueryHookResult = ReturnType<typeof useSveChannelsLazyQuery>
export type SveChannelsQueryResult = Apollo.QueryResult<SveChannelsQuery, SveChannelsQueryVariables>
export const SveChannelsWithBroadcastsDocument = gql`
  query sveChannelsWithBroadcasts($input: SVEChannelsInput!) {
    sveChannels(input: $input) {
      sveChannels {
        ...sveChannelContent
        broadcasts {
          ...sveBroadcastContent
        }
      }
      totalCount
    }
  }
  ${SveChannelContentFragmentDoc}
  ${SveBroadcastContentFragmentDoc}
`

/**
 * __useSveChannelsWithBroadcastsQuery__
 *
 * To run a query within a React component, call `useSveChannelsWithBroadcastsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSveChannelsWithBroadcastsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSveChannelsWithBroadcastsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSveChannelsWithBroadcastsQuery(
  baseOptions: Apollo.QueryHookOptions<SveChannelsWithBroadcastsQuery, SveChannelsWithBroadcastsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SveChannelsWithBroadcastsQuery, SveChannelsWithBroadcastsQueryVariables>(
    SveChannelsWithBroadcastsDocument,
    options,
  )
}
export function useSveChannelsWithBroadcastsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SveChannelsWithBroadcastsQuery, SveChannelsWithBroadcastsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SveChannelsWithBroadcastsQuery, SveChannelsWithBroadcastsQueryVariables>(
    SveChannelsWithBroadcastsDocument,
    options,
  )
}
export type SveChannelsWithBroadcastsQueryHookResult = ReturnType<typeof useSveChannelsWithBroadcastsQuery>
export type SveChannelsWithBroadcastsLazyQueryHookResult = ReturnType<typeof useSveChannelsWithBroadcastsLazyQuery>
export type SveChannelsWithBroadcastsQueryResult = Apollo.QueryResult<
  SveChannelsWithBroadcastsQuery,
  SveChannelsWithBroadcastsQueryVariables
>
export const UnmountSveChannelDocument = gql`
  mutation unmountSVEChannel($channelId: ID!) {
    unmountSVEChannel(channelId: $channelId) {
      ...sveChannelContent
    }
  }
  ${SveChannelContentFragmentDoc}
`
export type UnmountSveChannelMutationFn = Apollo.MutationFunction<
  UnmountSveChannelMutation,
  UnmountSveChannelMutationVariables
>

/**
 * __useUnmountSveChannelMutation__
 *
 * To run a mutation, you first call `useUnmountSveChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnmountSveChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unmountSveChannelMutation, { data, loading, error }] = useUnmountSveChannelMutation({
 *   variables: {
 *      channelId: // value for 'channelId'
 *   },
 * });
 */
export function useUnmountSveChannelMutation(
  baseOptions?: Apollo.MutationHookOptions<UnmountSveChannelMutation, UnmountSveChannelMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UnmountSveChannelMutation, UnmountSveChannelMutationVariables>(
    UnmountSveChannelDocument,
    options,
  )
}
export type UnmountSveChannelMutationHookResult = ReturnType<typeof useUnmountSveChannelMutation>
export type UnmountSveChannelMutationResult = Apollo.MutationResult<UnmountSveChannelMutation>
export type UnmountSveChannelMutationOptions = Apollo.BaseMutationOptions<
  UnmountSveChannelMutation,
  UnmountSveChannelMutationVariables
>
export const UpdateSveChannelDocument = gql`
  mutation updateSVEChannel($input: UpdateSVEChannelInput!) {
    updateSVEChannel(input: $input) {
      ...sveChannelContent
    }
  }
  ${SveChannelContentFragmentDoc}
`
export type UpdateSveChannelMutationFn = Apollo.MutationFunction<
  UpdateSveChannelMutation,
  UpdateSveChannelMutationVariables
>

/**
 * __useUpdateSveChannelMutation__
 *
 * To run a mutation, you first call `useUpdateSveChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSveChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSveChannelMutation, { data, loading, error }] = useUpdateSveChannelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSveChannelMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateSveChannelMutation, UpdateSveChannelMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateSveChannelMutation, UpdateSveChannelMutationVariables>(
    UpdateSveChannelDocument,
    options,
  )
}
export type UpdateSveChannelMutationHookResult = ReturnType<typeof useUpdateSveChannelMutation>
export type UpdateSveChannelMutationResult = Apollo.MutationResult<UpdateSveChannelMutation>
export type UpdateSveChannelMutationOptions = Apollo.BaseMutationOptions<
  UpdateSveChannelMutation,
  UpdateSveChannelMutationVariables
>
export const ThirdPartyApplicationsDocument = gql`
  query thirdPartyApplications($filters: ThirdPartyApplicationsFilters) {
    thirdPartyApplications(filters: $filters) {
      thirdPartyApplications {
        ...thirdPartyApplicationContent
      }
      totalCount
    }
  }
  ${ThirdPartyApplicationContentFragmentDoc}
`

/**
 * __useThirdPartyApplicationsQuery__
 *
 * To run a query within a React component, call `useThirdPartyApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useThirdPartyApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThirdPartyApplicationsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useThirdPartyApplicationsQuery(
  baseOptions?: Apollo.QueryHookOptions<ThirdPartyApplicationsQuery, ThirdPartyApplicationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ThirdPartyApplicationsQuery, ThirdPartyApplicationsQueryVariables>(
    ThirdPartyApplicationsDocument,
    options,
  )
}
export function useThirdPartyApplicationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ThirdPartyApplicationsQuery, ThirdPartyApplicationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ThirdPartyApplicationsQuery, ThirdPartyApplicationsQueryVariables>(
    ThirdPartyApplicationsDocument,
    options,
  )
}
export type ThirdPartyApplicationsQueryHookResult = ReturnType<typeof useThirdPartyApplicationsQuery>
export type ThirdPartyApplicationsLazyQueryHookResult = ReturnType<typeof useThirdPartyApplicationsLazyQuery>
export type ThirdPartyApplicationsQueryResult = Apollo.QueryResult<
  ThirdPartyApplicationsQuery,
  ThirdPartyApplicationsQueryVariables
>
export const CreateThirdPartyApplicationDocument = gql`
  mutation createThirdPartyApplication($input: CreateThirdPartyApplicationInput!) {
    createThirdPartyApplication(input: $input) {
      ...thirdPartyApplicationContent
    }
  }
  ${ThirdPartyApplicationContentFragmentDoc}
`
export type CreateThirdPartyApplicationMutationFn = Apollo.MutationFunction<
  CreateThirdPartyApplicationMutation,
  CreateThirdPartyApplicationMutationVariables
>

/**
 * __useCreateThirdPartyApplicationMutation__
 *
 * To run a mutation, you first call `useCreateThirdPartyApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateThirdPartyApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createThirdPartyApplicationMutation, { data, loading, error }] = useCreateThirdPartyApplicationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateThirdPartyApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateThirdPartyApplicationMutation,
    CreateThirdPartyApplicationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateThirdPartyApplicationMutation, CreateThirdPartyApplicationMutationVariables>(
    CreateThirdPartyApplicationDocument,
    options,
  )
}
export type CreateThirdPartyApplicationMutationHookResult = ReturnType<typeof useCreateThirdPartyApplicationMutation>
export type CreateThirdPartyApplicationMutationResult = Apollo.MutationResult<CreateThirdPartyApplicationMutation>
export type CreateThirdPartyApplicationMutationOptions = Apollo.BaseMutationOptions<
  CreateThirdPartyApplicationMutation,
  CreateThirdPartyApplicationMutationVariables
>
export const UpdateThirdPartyApplicationDocument = gql`
  mutation updateThirdPartyApplication($input: UpdateThirdPartyApplicationInput!) {
    updateThirdPartyApplication(input: $input) {
      ...thirdPartyApplicationContent
    }
  }
  ${ThirdPartyApplicationContentFragmentDoc}
`
export type UpdateThirdPartyApplicationMutationFn = Apollo.MutationFunction<
  UpdateThirdPartyApplicationMutation,
  UpdateThirdPartyApplicationMutationVariables
>

/**
 * __useUpdateThirdPartyApplicationMutation__
 *
 * To run a mutation, you first call `useUpdateThirdPartyApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateThirdPartyApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateThirdPartyApplicationMutation, { data, loading, error }] = useUpdateThirdPartyApplicationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateThirdPartyApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateThirdPartyApplicationMutation,
    UpdateThirdPartyApplicationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateThirdPartyApplicationMutation, UpdateThirdPartyApplicationMutationVariables>(
    UpdateThirdPartyApplicationDocument,
    options,
  )
}
export type UpdateThirdPartyApplicationMutationHookResult = ReturnType<typeof useUpdateThirdPartyApplicationMutation>
export type UpdateThirdPartyApplicationMutationResult = Apollo.MutationResult<UpdateThirdPartyApplicationMutation>
export type UpdateThirdPartyApplicationMutationOptions = Apollo.BaseMutationOptions<
  UpdateThirdPartyApplicationMutation,
  UpdateThirdPartyApplicationMutationVariables
>
export const DeleteThirdPartyApplicationDocument = gql`
  mutation deleteThirdPartyApplication($id: ID!) {
    deleteThirdPartyApplication(id: $id) {
      id
    }
  }
`
export type DeleteThirdPartyApplicationMutationFn = Apollo.MutationFunction<
  DeleteThirdPartyApplicationMutation,
  DeleteThirdPartyApplicationMutationVariables
>

/**
 * __useDeleteThirdPartyApplicationMutation__
 *
 * To run a mutation, you first call `useDeleteThirdPartyApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteThirdPartyApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteThirdPartyApplicationMutation, { data, loading, error }] = useDeleteThirdPartyApplicationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteThirdPartyApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteThirdPartyApplicationMutation,
    DeleteThirdPartyApplicationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteThirdPartyApplicationMutation, DeleteThirdPartyApplicationMutationVariables>(
    DeleteThirdPartyApplicationDocument,
    options,
  )
}
export type DeleteThirdPartyApplicationMutationHookResult = ReturnType<typeof useDeleteThirdPartyApplicationMutation>
export type DeleteThirdPartyApplicationMutationResult = Apollo.MutationResult<DeleteThirdPartyApplicationMutation>
export type DeleteThirdPartyApplicationMutationOptions = Apollo.BaseMutationOptions<
  DeleteThirdPartyApplicationMutation,
  DeleteThirdPartyApplicationMutationVariables
>
export const UsersDocument = gql`
  query users($filters: UserFilters, $offset: Int, $first: Int, $sortBy: SortBy) {
    users(filters: $filters, offset: $offset, first: $first, sortBy: $sortBy) {
      users {
        ...lightUserContent
      }
      totalCount
    }
  }
  ${LightUserContentFragmentDoc}
`

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      offset: // value for 'offset'
 *      first: // value for 'first'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options)
}
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options)
}
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>
export const UserDocument = gql`
  query user($userId: ID!) {
    user(id: $userId) {
      id
      email
      firstName
      lastName
      chatAuthorName
      organismIds
      roleIds
      rights {
        generalPermissions
        organismsPermissions {
          organismId
          permissions
        }
      }
    }
  }
`

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options)
}
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options)
}
export type UserQueryHookResult = ReturnType<typeof useUserQuery>
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>
export const MeDocument = gql`
  query me {
    me {
      id
      email
      firstName
      lastName
      organismIds
      roleIds
      avatarUrl
      rights {
        generalPermissions
        organismsPermissions {
          organismId
          permissions
        }
      }
      allPermissions {
        generalPermissions
        organismsPermissions {
          organismId
          permissions
        }
      }
    }
  }
`

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options)
}
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options)
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>
export const UserRgpdDataDocument = gql`
  query userRGPDData($id: ID!) {
    user(id: $id) {
      id
      RGPDFormattedData
    }
  }
`

/**
 * __useUserRgpdDataQuery__
 *
 * To run a query within a React component, call `useUserRgpdDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserRgpdDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserRgpdDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserRgpdDataQuery(
  baseOptions: Apollo.QueryHookOptions<UserRgpdDataQuery, UserRgpdDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UserRgpdDataQuery, UserRgpdDataQueryVariables>(UserRgpdDataDocument, options)
}
export function useUserRgpdDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserRgpdDataQuery, UserRgpdDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UserRgpdDataQuery, UserRgpdDataQueryVariables>(UserRgpdDataDocument, options)
}
export type UserRgpdDataQueryHookResult = ReturnType<typeof useUserRgpdDataQuery>
export type UserRgpdDataLazyQueryHookResult = ReturnType<typeof useUserRgpdDataLazyQuery>
export type UserRgpdDataQueryResult = Apollo.QueryResult<UserRgpdDataQuery, UserRgpdDataQueryVariables>
export const CreateUserDocument = gql`
  mutation createUser($input: CreateUserInput!) {
    createUser(input: $input) {
      id
      email
      firstName
      lastName
    }
  }
`
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options)
}
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>
export const UpdateUserDocument = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      firstName
      lastName
      email
      organismIds
      roleIds
      rights {
        generalPermissions
        organismsPermissions {
          organismId
          permissions
        }
      }
    }
  }
`
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options)
}
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>
export const UploadAvatarDocument = gql`
  mutation uploadAvatar($file: Upload!) {
    uploadAvatar(file: $file) {
      id
      avatarUrl
    }
  }
`
export type UploadAvatarMutationFn = Apollo.MutationFunction<UploadAvatarMutation, UploadAvatarMutationVariables>

/**
 * __useUploadAvatarMutation__
 *
 * To run a mutation, you first call `useUploadAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadAvatarMutation, { data, loading, error }] = useUploadAvatarMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadAvatarMutation(
  baseOptions?: Apollo.MutationHookOptions<UploadAvatarMutation, UploadAvatarMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UploadAvatarMutation, UploadAvatarMutationVariables>(UploadAvatarDocument, options)
}
export type UploadAvatarMutationHookResult = ReturnType<typeof useUploadAvatarMutation>
export type UploadAvatarMutationResult = Apollo.MutationResult<UploadAvatarMutation>
export type UploadAvatarMutationOptions = Apollo.BaseMutationOptions<
  UploadAvatarMutation,
  UploadAvatarMutationVariables
>
export const DeleteUserDocument = gql`
  mutation deleteUser($userId: ID!) {
    deleteUser(id: $userId) {
      id
    }
  }
`
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeleteUserMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options)
}
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>
export const MyOrganismsDocument = gql`
  query myOrganisms {
    me {
      id
      organisms {
        ...organismContent
      }
    }
  }
  ${OrganismContentFragmentDoc}
`

/**
 * __useMyOrganismsQuery__
 *
 * To run a query within a React component, call `useMyOrganismsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyOrganismsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyOrganismsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyOrganismsQuery(
  baseOptions?: Apollo.QueryHookOptions<MyOrganismsQuery, MyOrganismsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MyOrganismsQuery, MyOrganismsQueryVariables>(MyOrganismsDocument, options)
}
export function useMyOrganismsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MyOrganismsQuery, MyOrganismsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<MyOrganismsQuery, MyOrganismsQueryVariables>(MyOrganismsDocument, options)
}
export type MyOrganismsQueryHookResult = ReturnType<typeof useMyOrganismsQuery>
export type MyOrganismsLazyQueryHookResult = ReturnType<typeof useMyOrganismsLazyQuery>
export type MyOrganismsQueryResult = Apollo.QueryResult<MyOrganismsQuery, MyOrganismsQueryVariables>
export const UpdateCacheReceiptDocument = gql`
  mutation updateCacheReceipt($userId: ID) {
    updateCacheReceipt(userId: $userId)
  }
`
export type UpdateCacheReceiptMutationFn = Apollo.MutationFunction<
  UpdateCacheReceiptMutation,
  UpdateCacheReceiptMutationVariables
>

/**
 * __useUpdateCacheReceiptMutation__
 *
 * To run a mutation, you first call `useUpdateCacheReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCacheReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCacheReceiptMutation, { data, loading, error }] = useUpdateCacheReceiptMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUpdateCacheReceiptMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCacheReceiptMutation, UpdateCacheReceiptMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateCacheReceiptMutation, UpdateCacheReceiptMutationVariables>(
    UpdateCacheReceiptDocument,
    options,
  )
}
export type UpdateCacheReceiptMutationHookResult = ReturnType<typeof useUpdateCacheReceiptMutation>
export type UpdateCacheReceiptMutationResult = Apollo.MutationResult<UpdateCacheReceiptMutation>
export type UpdateCacheReceiptMutationOptions = Apollo.BaseMutationOptions<
  UpdateCacheReceiptMutation,
  UpdateCacheReceiptMutationVariables
>
export const OrganismLicensedUsersDocument = gql`
  query organismLicensedUsers($organismId: ID!, $offset: Int, $first: Int, $sortBy: SortBy) {
    users(filters: { licensedForOrganismId: $organismId }, offset: $offset, first: $first, sortBy: $sortBy) {
      users {
        id
        email
        firstName
        lastName
        gender
        roleIds
        location {
          country
          postalCode
        }
        createdAt
        lastActivity
        cguAccepted
        signUpOrigin
        verified
        applicationOrigin {
          id
          title
        }
      }
      totalCount
    }
  }
`

/**
 * __useOrganismLicensedUsersQuery__
 *
 * To run a query within a React component, call `useOrganismLicensedUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganismLicensedUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganismLicensedUsersQuery({
 *   variables: {
 *      organismId: // value for 'organismId'
 *      offset: // value for 'offset'
 *      first: // value for 'first'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useOrganismLicensedUsersQuery(
  baseOptions: Apollo.QueryHookOptions<OrganismLicensedUsersQuery, OrganismLicensedUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<OrganismLicensedUsersQuery, OrganismLicensedUsersQueryVariables>(
    OrganismLicensedUsersDocument,
    options,
  )
}
export function useOrganismLicensedUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrganismLicensedUsersQuery, OrganismLicensedUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<OrganismLicensedUsersQuery, OrganismLicensedUsersQueryVariables>(
    OrganismLicensedUsersDocument,
    options,
  )
}
export type OrganismLicensedUsersQueryHookResult = ReturnType<typeof useOrganismLicensedUsersQuery>
export type OrganismLicensedUsersLazyQueryHookResult = ReturnType<typeof useOrganismLicensedUsersLazyQuery>
export type OrganismLicensedUsersQueryResult = Apollo.QueryResult<
  OrganismLicensedUsersQuery,
  OrganismLicensedUsersQueryVariables
>
export const ImportUsersFansFromCsvDocument = gql`
  mutation importUsersFansFromCsv($file: Upload!, $options: ImportUsersOptions) {
    importUsersFansFromCsv(file: $file, options: $options)
  }
`
export type ImportUsersFansFromCsvMutationFn = Apollo.MutationFunction<
  ImportUsersFansFromCsvMutation,
  ImportUsersFansFromCsvMutationVariables
>

/**
 * __useImportUsersFansFromCsvMutation__
 *
 * To run a mutation, you first call `useImportUsersFansFromCsvMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportUsersFansFromCsvMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importUsersFansFromCsvMutation, { data, loading, error }] = useImportUsersFansFromCsvMutation({
 *   variables: {
 *      file: // value for 'file'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useImportUsersFansFromCsvMutation(
  baseOptions?: Apollo.MutationHookOptions<ImportUsersFansFromCsvMutation, ImportUsersFansFromCsvMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ImportUsersFansFromCsvMutation, ImportUsersFansFromCsvMutationVariables>(
    ImportUsersFansFromCsvDocument,
    options,
  )
}
export type ImportUsersFansFromCsvMutationHookResult = ReturnType<typeof useImportUsersFansFromCsvMutation>
export type ImportUsersFansFromCsvMutationResult = Apollo.MutationResult<ImportUsersFansFromCsvMutation>
export type ImportUsersFansFromCsvMutationOptions = Apollo.BaseMutationOptions<
  ImportUsersFansFromCsvMutation,
  ImportUsersFansFromCsvMutationVariables
>
export const RevokeLicenseDocument = gql`
  mutation revokeLicense($userId: ID!, $organismId: ID!) {
    revokeUserLicense(userId: $userId, organismId: $organismId) {
      id
      licenses {
        organismId
        isLicensed
      }
    }
  }
`
export type RevokeLicenseMutationFn = Apollo.MutationFunction<RevokeLicenseMutation, RevokeLicenseMutationVariables>

/**
 * __useRevokeLicenseMutation__
 *
 * To run a mutation, you first call `useRevokeLicenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeLicenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeLicenseMutation, { data, loading, error }] = useRevokeLicenseMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      organismId: // value for 'organismId'
 *   },
 * });
 */
export function useRevokeLicenseMutation(
  baseOptions?: Apollo.MutationHookOptions<RevokeLicenseMutation, RevokeLicenseMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RevokeLicenseMutation, RevokeLicenseMutationVariables>(RevokeLicenseDocument, options)
}
export type RevokeLicenseMutationHookResult = ReturnType<typeof useRevokeLicenseMutation>
export type RevokeLicenseMutationResult = Apollo.MutationResult<RevokeLicenseMutation>
export type RevokeLicenseMutationOptions = Apollo.BaseMutationOptions<
  RevokeLicenseMutation,
  RevokeLicenseMutationVariables
>
export const LatestUsersExportDownloadUrlDocument = gql`
  query latestUsersExportDownloadUrl($maxDate: Date) {
    latestUsersExportDownloadUrl(maxDate: $maxDate)
  }
`

/**
 * __useLatestUsersExportDownloadUrlQuery__
 *
 * To run a query within a React component, call `useLatestUsersExportDownloadUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestUsersExportDownloadUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestUsersExportDownloadUrlQuery({
 *   variables: {
 *      maxDate: // value for 'maxDate'
 *   },
 * });
 */
export function useLatestUsersExportDownloadUrlQuery(
  baseOptions?: Apollo.QueryHookOptions<LatestUsersExportDownloadUrlQuery, LatestUsersExportDownloadUrlQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<LatestUsersExportDownloadUrlQuery, LatestUsersExportDownloadUrlQueryVariables>(
    LatestUsersExportDownloadUrlDocument,
    options,
  )
}
export function useLatestUsersExportDownloadUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LatestUsersExportDownloadUrlQuery,
    LatestUsersExportDownloadUrlQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<LatestUsersExportDownloadUrlQuery, LatestUsersExportDownloadUrlQueryVariables>(
    LatestUsersExportDownloadUrlDocument,
    options,
  )
}
export type LatestUsersExportDownloadUrlQueryHookResult = ReturnType<typeof useLatestUsersExportDownloadUrlQuery>
export type LatestUsersExportDownloadUrlLazyQueryHookResult = ReturnType<
  typeof useLatestUsersExportDownloadUrlLazyQuery
>
export type LatestUsersExportDownloadUrlQueryResult = Apollo.QueryResult<
  LatestUsersExportDownloadUrlQuery,
  LatestUsersExportDownloadUrlQueryVariables
>
export const UsersExportStateDocument = gql`
  query usersExportState {
    usersExportState
  }
`

/**
 * __useUsersExportStateQuery__
 *
 * To run a query within a React component, call `useUsersExportStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersExportStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersExportStateQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersExportStateQuery(
  baseOptions?: Apollo.QueryHookOptions<UsersExportStateQuery, UsersExportStateQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UsersExportStateQuery, UsersExportStateQueryVariables>(UsersExportStateDocument, options)
}
export function useUsersExportStateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UsersExportStateQuery, UsersExportStateQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UsersExportStateQuery, UsersExportStateQueryVariables>(UsersExportStateDocument, options)
}
export type UsersExportStateQueryHookResult = ReturnType<typeof useUsersExportStateQuery>
export type UsersExportStateLazyQueryHookResult = ReturnType<typeof useUsersExportStateLazyQuery>
export type UsersExportStateQueryResult = Apollo.QueryResult<UsersExportStateQuery, UsersExportStateQueryVariables>
export const ScheduleUsersExportDocument = gql`
  mutation scheduleUsersExport($input: UsersExportInput!) {
    scheduleUsersExport(input: $input)
  }
`
export type ScheduleUsersExportMutationFn = Apollo.MutationFunction<
  ScheduleUsersExportMutation,
  ScheduleUsersExportMutationVariables
>

/**
 * __useScheduleUsersExportMutation__
 *
 * To run a mutation, you first call `useScheduleUsersExportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScheduleUsersExportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scheduleUsersExportMutation, { data, loading, error }] = useScheduleUsersExportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useScheduleUsersExportMutation(
  baseOptions?: Apollo.MutationHookOptions<ScheduleUsersExportMutation, ScheduleUsersExportMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ScheduleUsersExportMutation, ScheduleUsersExportMutationVariables>(
    ScheduleUsersExportDocument,
    options,
  )
}
export type ScheduleUsersExportMutationHookResult = ReturnType<typeof useScheduleUsersExportMutation>
export type ScheduleUsersExportMutationResult = Apollo.MutationResult<ScheduleUsersExportMutation>
export type ScheduleUsersExportMutationOptions = Apollo.BaseMutationOptions<
  ScheduleUsersExportMutation,
  ScheduleUsersExportMutationVariables
>
export const UpdateTokenPayloadDocument = gql`
  mutation updateTokenPayload {
    updateTokenPayload
  }
`
export type UpdateTokenPayloadMutationFn = Apollo.MutationFunction<
  UpdateTokenPayloadMutation,
  UpdateTokenPayloadMutationVariables
>

/**
 * __useUpdateTokenPayloadMutation__
 *
 * To run a mutation, you first call `useUpdateTokenPayloadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTokenPayloadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTokenPayloadMutation, { data, loading, error }] = useUpdateTokenPayloadMutation({
 *   variables: {
 *   },
 * });
 */
export function useUpdateTokenPayloadMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateTokenPayloadMutation, UpdateTokenPayloadMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateTokenPayloadMutation, UpdateTokenPayloadMutationVariables>(
    UpdateTokenPayloadDocument,
    options,
  )
}
export type UpdateTokenPayloadMutationHookResult = ReturnType<typeof useUpdateTokenPayloadMutation>
export type UpdateTokenPayloadMutationResult = Apollo.MutationResult<UpdateTokenPayloadMutation>
export type UpdateTokenPayloadMutationOptions = Apollo.BaseMutationOptions<
  UpdateTokenPayloadMutation,
  UpdateTokenPayloadMutationVariables
>
export const UserRolesDocument = gql`
  query userRoles {
    userRoles {
      ...userRole
    }
  }
  ${UserRoleFragmentDoc}
`

/**
 * __useUserRolesQuery__
 *
 * To run a query within a React component, call `useUserRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserRolesQuery(baseOptions?: Apollo.QueryHookOptions<UserRolesQuery, UserRolesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UserRolesQuery, UserRolesQueryVariables>(UserRolesDocument, options)
}
export function useUserRolesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserRolesQuery, UserRolesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UserRolesQuery, UserRolesQueryVariables>(UserRolesDocument, options)
}
export type UserRolesQueryHookResult = ReturnType<typeof useUserRolesQuery>
export type UserRolesLazyQueryHookResult = ReturnType<typeof useUserRolesLazyQuery>
export type UserRolesQueryResult = Apollo.QueryResult<UserRolesQuery, UserRolesQueryVariables>
export const UserRoleDocument = gql`
  query userRole($id: UserRoleID!) {
    userRole(id: $id) {
      ...userRole
    }
  }
  ${UserRoleFragmentDoc}
`

/**
 * __useUserRoleQuery__
 *
 * To run a query within a React component, call `useUserRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserRoleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserRoleQuery(baseOptions: Apollo.QueryHookOptions<UserRoleQuery, UserRoleQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UserRoleQuery, UserRoleQueryVariables>(UserRoleDocument, options)
}
export function useUserRoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserRoleQuery, UserRoleQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UserRoleQuery, UserRoleQueryVariables>(UserRoleDocument, options)
}
export type UserRoleQueryHookResult = ReturnType<typeof useUserRoleQuery>
export type UserRoleLazyQueryHookResult = ReturnType<typeof useUserRoleLazyQuery>
export type UserRoleQueryResult = Apollo.QueryResult<UserRoleQuery, UserRoleQueryVariables>
export const VideoClipDocument = gql`
  query videoClip($id: ID!) {
    videoClip(id: $id) {
      ...videoClipContent
    }
  }
  ${VideoClipContentFragmentDoc}
`

/**
 * __useVideoClipQuery__
 *
 * To run a query within a React component, call `useVideoClipQuery` and pass it any options that fit your needs.
 * When your component renders, `useVideoClipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVideoClipQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVideoClipQuery(baseOptions: Apollo.QueryHookOptions<VideoClipQuery, VideoClipQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<VideoClipQuery, VideoClipQueryVariables>(VideoClipDocument, options)
}
export function useVideoClipLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<VideoClipQuery, VideoClipQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<VideoClipQuery, VideoClipQueryVariables>(VideoClipDocument, options)
}
export type VideoClipQueryHookResult = ReturnType<typeof useVideoClipQuery>
export type VideoClipLazyQueryHookResult = ReturnType<typeof useVideoClipLazyQuery>
export type VideoClipQueryResult = Apollo.QueryResult<VideoClipQuery, VideoClipQueryVariables>
export const CreateMultipartDocument = gql`
  query createMultipart($hash: String!, $fileName: String, $extension: String) {
    createMultipart(hash: $hash, fileName: $fileName, extension: $extension) {
      uploadId
      key
      currentPart
    }
  }
`

/**
 * __useCreateMultipartQuery__
 *
 * To run a query within a React component, call `useCreateMultipartQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreateMultipartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateMultipartQuery({
 *   variables: {
 *      hash: // value for 'hash'
 *      fileName: // value for 'fileName'
 *      extension: // value for 'extension'
 *   },
 * });
 */
export function useCreateMultipartQuery(
  baseOptions: Apollo.QueryHookOptions<CreateMultipartQuery, CreateMultipartQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CreateMultipartQuery, CreateMultipartQueryVariables>(CreateMultipartDocument, options)
}
export function useCreateMultipartLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CreateMultipartQuery, CreateMultipartQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CreateMultipartQuery, CreateMultipartQueryVariables>(CreateMultipartDocument, options)
}
export type CreateMultipartQueryHookResult = ReturnType<typeof useCreateMultipartQuery>
export type CreateMultipartLazyQueryHookResult = ReturnType<typeof useCreateMultipartLazyQuery>
export type CreateMultipartQueryResult = Apollo.QueryResult<CreateMultipartQuery, CreateMultipartQueryVariables>
export const UploadPartDocument = gql`
  mutation uploadPart($input: UploadPartInput!) {
    uploadPart(input: $input)
  }
`
export type UploadPartMutationFn = Apollo.MutationFunction<UploadPartMutation, UploadPartMutationVariables>

/**
 * __useUploadPartMutation__
 *
 * To run a mutation, you first call `useUploadPartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadPartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadPartMutation, { data, loading, error }] = useUploadPartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadPartMutation(
  baseOptions?: Apollo.MutationHookOptions<UploadPartMutation, UploadPartMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UploadPartMutation, UploadPartMutationVariables>(UploadPartDocument, options)
}
export type UploadPartMutationHookResult = ReturnType<typeof useUploadPartMutation>
export type UploadPartMutationResult = Apollo.MutationResult<UploadPartMutation>
export type UploadPartMutationOptions = Apollo.BaseMutationOptions<UploadPartMutation, UploadPartMutationVariables>
export const CompleteMultipartDocument = gql`
  mutation completeMultipart($input: CompleteMultipartInput!) {
    completeMultipart(input: $input)
  }
`
export type CompleteMultipartMutationFn = Apollo.MutationFunction<
  CompleteMultipartMutation,
  CompleteMultipartMutationVariables
>

/**
 * __useCompleteMultipartMutation__
 *
 * To run a mutation, you first call `useCompleteMultipartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteMultipartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeMultipartMutation, { data, loading, error }] = useCompleteMultipartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteMultipartMutation(
  baseOptions?: Apollo.MutationHookOptions<CompleteMultipartMutation, CompleteMultipartMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CompleteMultipartMutation, CompleteMultipartMutationVariables>(
    CompleteMultipartDocument,
    options,
  )
}
export type CompleteMultipartMutationHookResult = ReturnType<typeof useCompleteMultipartMutation>
export type CompleteMultipartMutationResult = Apollo.MutationResult<CompleteMultipartMutation>
export type CompleteMultipartMutationOptions = Apollo.BaseMutationOptions<
  CompleteMultipartMutation,
  CompleteMultipartMutationVariables
>
export const SignedUrlDocument = gql`
  query signedUrl($sportItemId: ID, $fileName: String, $extension: String) {
    signedUrl(sportItemId: $sportItemId, fileName: $fileName, extension: $extension) {
      url
      key
    }
  }
`

/**
 * __useSignedUrlQuery__
 *
 * To run a query within a React component, call `useSignedUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useSignedUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSignedUrlQuery({
 *   variables: {
 *      sportItemId: // value for 'sportItemId'
 *      fileName: // value for 'fileName'
 *      extension: // value for 'extension'
 *   },
 * });
 */
export function useSignedUrlQuery(baseOptions?: Apollo.QueryHookOptions<SignedUrlQuery, SignedUrlQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SignedUrlQuery, SignedUrlQueryVariables>(SignedUrlDocument, options)
}
export function useSignedUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SignedUrlQuery, SignedUrlQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SignedUrlQuery, SignedUrlQueryVariables>(SignedUrlDocument, options)
}
export type SignedUrlQueryHookResult = ReturnType<typeof useSignedUrlQuery>
export type SignedUrlLazyQueryHookResult = ReturnType<typeof useSignedUrlLazyQuery>
export type SignedUrlQueryResult = Apollo.QueryResult<SignedUrlQuery, SignedUrlQueryVariables>
export const ListStreamsDocument = gql`
  query listStreams($filters: StreamFilters) {
    listStreams(filters: $filters) {
      id
      state
      name
      status
      tags
      outputUrl
      map {
        id
        playbackUrl
        outputUrl
        channelArn
      }
    }
  }
`

/**
 * __useListStreamsQuery__
 *
 * To run a query within a React component, call `useListStreamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListStreamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListStreamsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useListStreamsQuery(
  baseOptions?: Apollo.QueryHookOptions<ListStreamsQuery, ListStreamsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ListStreamsQuery, ListStreamsQueryVariables>(ListStreamsDocument, options)
}
export function useListStreamsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListStreamsQuery, ListStreamsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ListStreamsQuery, ListStreamsQueryVariables>(ListStreamsDocument, options)
}
export type ListStreamsQueryHookResult = ReturnType<typeof useListStreamsQuery>
export type ListStreamsLazyQueryHookResult = ReturnType<typeof useListStreamsLazyQuery>
export type ListStreamsQueryResult = Apollo.QueryResult<ListStreamsQuery, ListStreamsQueryVariables>
export const GetStreamDocument = gql`
  query getStream($id: WildmokaID!) {
    getStream(id: $id) {
      ...wmStreamContent
    }
  }
  ${WmStreamContentFragmentDoc}
`

/**
 * __useGetStreamQuery__
 *
 * To run a query within a React component, call `useGetStreamQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStreamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStreamQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetStreamQuery(baseOptions: Apollo.QueryHookOptions<GetStreamQuery, GetStreamQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetStreamQuery, GetStreamQueryVariables>(GetStreamDocument, options)
}
export function useGetStreamLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetStreamQuery, GetStreamQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetStreamQuery, GetStreamQueryVariables>(GetStreamDocument, options)
}
export type GetStreamQueryHookResult = ReturnType<typeof useGetStreamQuery>
export type GetStreamLazyQueryHookResult = ReturnType<typeof useGetStreamLazyQuery>
export type GetStreamQueryResult = Apollo.QueryResult<GetStreamQuery, GetStreamQueryVariables>
export const ListTemplatesDocument = gql`
  query listTemplates($type: WMTemplateType, $organismId: ID) {
    listTemplates(type: $type, organismId: $organismId) {
      id
      name
      type
    }
  }
`

/**
 * __useListTemplatesQuery__
 *
 * To run a query within a React component, call `useListTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTemplatesQuery({
 *   variables: {
 *      type: // value for 'type'
 *      organismId: // value for 'organismId'
 *   },
 * });
 */
export function useListTemplatesQuery(
  baseOptions?: Apollo.QueryHookOptions<ListTemplatesQuery, ListTemplatesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ListTemplatesQuery, ListTemplatesQueryVariables>(ListTemplatesDocument, options)
}
export function useListTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListTemplatesQuery, ListTemplatesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ListTemplatesQuery, ListTemplatesQueryVariables>(ListTemplatesDocument, options)
}
export type ListTemplatesQueryHookResult = ReturnType<typeof useListTemplatesQuery>
export type ListTemplatesLazyQueryHookResult = ReturnType<typeof useListTemplatesLazyQuery>
export type ListTemplatesQueryResult = Apollo.QueryResult<ListTemplatesQuery, ListTemplatesQueryVariables>
export const ListProfilesDocument = gql`
  query listProfiles {
    listProfiles {
      id
      name
      tags
    }
  }
`

/**
 * __useListProfilesQuery__
 *
 * To run a query within a React component, call `useListProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListProfilesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListProfilesQuery(
  baseOptions?: Apollo.QueryHookOptions<ListProfilesQuery, ListProfilesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ListProfilesQuery, ListProfilesQueryVariables>(ListProfilesDocument, options)
}
export function useListProfilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListProfilesQuery, ListProfilesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ListProfilesQuery, ListProfilesQueryVariables>(ListProfilesDocument, options)
}
export type ListProfilesQueryHookResult = ReturnType<typeof useListProfilesQuery>
export type ListProfilesLazyQueryHookResult = ReturnType<typeof useListProfilesLazyQuery>
export type ListProfilesQueryResult = Apollo.QueryResult<ListProfilesQuery, ListProfilesQueryVariables>
export const WriteStreamMapDocument = gql`
  mutation writeStreamMap($input: WriteStreamMapInput!) {
    writeStreamMap(input: $input) {
      id
      streamId
      playbackUrl
    }
  }
`
export type WriteStreamMapMutationFn = Apollo.MutationFunction<WriteStreamMapMutation, WriteStreamMapMutationVariables>

/**
 * __useWriteStreamMapMutation__
 *
 * To run a mutation, you first call `useWriteStreamMapMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWriteStreamMapMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [writeStreamMapMutation, { data, loading, error }] = useWriteStreamMapMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWriteStreamMapMutation(
  baseOptions?: Apollo.MutationHookOptions<WriteStreamMapMutation, WriteStreamMapMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<WriteStreamMapMutation, WriteStreamMapMutationVariables>(WriteStreamMapDocument, options)
}
export type WriteStreamMapMutationHookResult = ReturnType<typeof useWriteStreamMapMutation>
export type WriteStreamMapMutationResult = Apollo.MutationResult<WriteStreamMapMutation>
export type WriteStreamMapMutationOptions = Apollo.BaseMutationOptions<
  WriteStreamMapMutation,
  WriteStreamMapMutationVariables
>
