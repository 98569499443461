import React, { FC, useCallback } from 'react'
import { Select, SelectProps } from 'semantic-ui-react'

import { useEditorCommand, useEditorState } from '../useEditor'

const options = [
  {
    value: 'paragraph',
    icon: 'text cursor',
    text: 'Normal',
  },
  // {
  //   value: 'h1',
  //   icon: 'heading',
  //   text: 'Titre 1',
  // },
  // {
  //   value: 'h2',
  //   icon: 'heading',
  //   text: 'Titre 2',
  // },
  {
    value: 'ul',
    icon: 'list ul',
    text: 'Liste à puces',
  },
  {
    value: 'ol',
    icon: 'list ol',
    text: 'Liste ordonnée',
  },
  {
    value: 'quote',
    icon: 'quote left',
    text: 'Citation',
  },
]

const BlockTypeSelect: FC = () => {
  const [{ blockType }, setState] = useEditorState()
  const [, dispatch] = useEditorCommand()

  const onChange = useCallback<NonNullable<SelectProps['onChange']>>(
    (event, data) => {
      setState({ blockType: data.value as any })
      dispatch('blockType', data.value)
    },
    [dispatch, setState],
  )

  return <Select className="flex-1" options={options} value={blockType} onChange={onChange} />
}

export default BlockTypeSelect
