import InvisibleButton from 'Components/Button/InvisibleButton'
import SROnly from 'Components/Button/SROnly'
import { DatePicker, DatePickerProps } from 'antd'
import moment from 'moment'
import React, { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { Icon } from 'semantic-ui-react'
import styled from 'styled-components'
import { isDateInRange } from 'tools/date'

const Container = styled.div`
  display: flex;
  align-items: center;
  > .field {
    flex: 1;
    margin: 0 !important;
  }
  > * + * {
    margin-left: 0.4rem;
  }
`

export type SelectDateInputProps = Omit<DatePickerProps, 'value' | 'onChange'> & {
  value: Date | undefined | null
  onChange: (value: Date | undefined | null) => void
  datetime?: boolean
  minDate?: Date
  maxDate?: Date
  bordered?: boolean
  hideHours?: boolean
}

export const SelectDateInput: React.FC<SelectDateInputProps> = ({
  value,
  onChange,
  datetime,
  minDate,
  maxDate,
  hideHours,
  disabled,
  bordered = false,
  ...props
}: SelectDateInputProps) => {
  // onChange in deps allow to set a new date every time we trigger it
  // instead of static date
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const now = useMemo(() => new Date(), [onChange])

  return (
    <Container>
      <DatePicker
        format={hideHours ? 'DD/MM/YYYY' : 'DD/MM/YYYY HH:mm'}
        bordered={bordered}
        value={value ? moment(value) : null}
        onChange={dateMoment => {
          onChange(dateMoment?.toDate() ?? null)
        }}
        showTime={datetime}
        disabledDate={date => !isDateInRange({ date: date.toDate(), minDate, maxDate })}
        disabled={disabled}
        {...props}
      />
      <InvisibleButton onClick={() => onChange(now)} type="button" disabled={!!disabled}>
        <Icon name="wait" />
        <SROnly>
          <FormattedMessage id="selectDateInput.now" />
        </SROnly>
      </InvisibleButton>
    </Container>
  )
}
