import { gql } from '@apollo/client'

// Fragments

export const CONTENT_PUSH_STREAM_FRAGMENT = gql`
  fragment contentPushStreamContent on ContentPushStream {
    id
    name
    contentDeliveryServiceConfig {
      type
      periodicity {
        frequency
        firstSendDate
      }
      destination {
        bucketName
        region
        bucketPath
        accessKey
        secretKey
        endpointUrl
      }
      retentionDurationDays
    }
    downloadExportUrl
    contentDelivery {
      type
      status
      statusDetails
    }
  }
`

export const OUTPUT_STREAM_FRAGMENT = gql`
  fragment outputStreamContent on OutputStream {
    id
    name
    outputUrl
  }
`

// Queries

export const GET_CONTENT_PUSH_STREAM_EXPORTS = gql`
  query contentPushStreamExports($first: Int, $offset: Int, $contentPushStreamId: ID!) {
    contentPushStreamExports(first: $first, offset: $offset, contentPushStreamId: $contentPushStreamId) {
      sportItemSets {
        id
        title
        type
        ownerOrganism {
          title
        }
      }
      totalCount
    }
  }
`

export const GET_CONTENT_PUSH_STREAMS_QUERY = gql`
  query contentPushStreams($filters: ContentPushStreamsFiltersQuery) {
    contentPushStreams(filters: $filters) {
      ...contentPushStreamContent
    }
  }
  ${CONTENT_PUSH_STREAM_FRAGMENT}
`

export const GET_AVAILABLE_CONTENT_PUSH_SERVICES_QUERY = gql`
  query availableContentPushServices {
    availableContentPushServices {
      type
      customDestination
    }
  }
`

export const GET_OUTPUT_STREAMS = gql`
  query outputStreams {
    outputStreams {
      ...outputStreamContent
    }
  }
  ${OUTPUT_STREAM_FRAGMENT}
`

// Mutations

export const CREATE_CONTENT_PUSH_STREAM_MUTATION = gql`
  mutation createContentPushStream($input: CreateContentPushStreamInput!) {
    createContentPushStream(input: $input)
  }
`

export const UPDATE_CONTENT_PUSH_STREAM_MUTATION = gql`
  mutation updateContentPushStream($input: UpdateContentPushStreamInput!) {
    updateContentPushStream(input: $input)
  }
`

export const DELETE_CONTENT_PUSH_STREAM_MUTATION = gql`
  mutation deleteContentPushStream($id: ID!) {
    deleteContentPushStream(id: $id)
  }
`

export const UPDATE_OUTPUT_STREAMS_MUTATION = gql`
  mutation updateOutputStreams($input: UpdateOutputStreamsInput!) {
    updateOutputStreams(input: $input)
  }
`

export const SYNC_NOW_MUTATION = gql`
  mutation syncNow($id: ID!) {
    syncNow(id: $id)
  }
`
