import { useMutation, useQuery } from '@apollo/client'
import {
  DefaultConfig,
  GET_DEFAULT_CONFIG_QUERY,
  InitDefaultConfigInput,
  INIT_DEFAULT_CONFIG_MUTATION,
  UpdateDefaultConfigInput,
  UPDATE_DEFAULT_CONFIG_MUTATION,
} from 'services/api/graphql'

export const useGetDefaultConfig = () => {
  const { data, ...query } = useQuery<{ defaultConfig: DefaultConfig }>(GET_DEFAULT_CONFIG_QUERY)
  const defaultConfig = data && data.defaultConfig
  return { defaultConfig, ...query }
}

export const useInitDefaultConfig = () =>
  useMutation<{ initDefaultConfig: DefaultConfig }, { input: InitDefaultConfigInput }>(INIT_DEFAULT_CONFIG_MUTATION)
export const useUpdateDefaultConfig = () =>
  useMutation<{ updateDefaultConfig: DefaultConfig }, { input: UpdateDefaultConfigInput }>(
    UPDATE_DEFAULT_CONFIG_MUTATION,
  )
