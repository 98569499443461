import { QueryHookOptions, useMutation, useQuery } from '@apollo/client'
import {
  CreatePurchasableOfferInput,
  CREATE_PURCHASABLE_OFFER_MUTATION,
  DeleteProductInput,
  DELETE_PURCHASABLE_OFFER_PRODUCT_MUTATION,
  DELETE_PURCHASABLE_OFFER_MUTATION,
  GET_PURCHASABLE_OFFERS_QUERY,
  GET_PURCHASABLE_OFFER_QUERY,
  MutationDeletePurchasableOfferArgs,
  Product,
  PurchasableOffer,
  QueryPurchasableOfferArgs,
  UpdateProductInput,
  UpdatePurchasableOfferInput,
  UPDATE_PURCHASABLE_OFFER_PRODUCT_MUTATION,
  UPDATE_PURCHASABLE_OFFER_MUTATION,
  SCHEDULE_PURCHASE_EXPORT,
  MovePurchasableOfferInput,
  MOVE_PURCHASABLE_OFFER_MUTATION,
} from 'services/api/graphql'
import { useShortMutation } from 'tools/graphql'

export type GetPurchasableOffersResult = {
  purchasableOffers: PurchasableOffer[]
  totalCount: number
}
export const useGetPurchasableOffers = (
  options?: QueryHookOptions<GetPurchasableOffersResult, QueryPurchasableOfferArgs>,
) => {
  const { data, ...rest } = useQuery(GET_PURCHASABLE_OFFERS_QUERY, options)
  return { data: data && data.purchasableOffers, ...rest }
}

export type GetPurchasableOfferResult = {
  purchasableOffer: PurchasableOffer
}
export const useGetPurchasableOffer = (
  purchasableOfferId: PurchasableOffer['id'],
  options?: QueryHookOptions<GetPurchasableOfferResult>,
) => {
  const { data, ...rest } = useQuery(GET_PURCHASABLE_OFFER_QUERY, {
    variables: { purchasableOfferId },
    ...options,
  })
  return { data: data && data.purchasableOffer, ...rest }
}

export const useSchedulePurchaseExport = () => useMutation<{ success: boolean }>(SCHEDULE_PURCHASE_EXPORT)

export const useCreatePurchasableOffer = () =>
  useMutation<{ createPurchasableOffer: PurchasableOffer }, { input: CreatePurchasableOfferInput }>(
    CREATE_PURCHASABLE_OFFER_MUTATION,
  )

export const useUpdatePurchasableOffer = () =>
  useMutation<
    { updatePurchasableOffer: PurchasableOffer },
    { id: PurchasableOffer['id']; input: UpdatePurchasableOfferInput }
  >(UPDATE_PURCHASABLE_OFFER_MUTATION)

// PRODUCTS
export const useUpdatePurchasableOfferProduct = () =>
  useMutation<
    { UPDATE_PURCHASABLE_OFFER_PRODUCT: Product },
    {
      id: PurchasableOffer['id']
      productId: Product['productId']
      input: UpdateProductInput
    }
  >(UPDATE_PURCHASABLE_OFFER_PRODUCT_MUTATION)

export const useDeletePurchableOfferProduct = () =>
  useMutation<{ deleteProduct: boolean }, { id: PurchasableOffer['id']; input: DeleteProductInput }>(
    DELETE_PURCHASABLE_OFFER_PRODUCT_MUTATION,
  )

export const useDeletePurchableOffer = () =>
  useShortMutation<{ deletePurchasableOffer: boolean }, MutationDeletePurchasableOfferArgs>(
    DELETE_PURCHASABLE_OFFER_MUTATION,
  )

export const useMovePurchasableOffer = () => {
  return useMutation<{ movePurchasableOffer: PurchasableOffer[] }, { input: MovePurchasableOfferInput }>(
    MOVE_PURCHASABLE_OFFER_MUTATION,
  )
}
