import { graphql } from 'gql'

import { SportItemFragment } from './SportItemForm.transformers'

export const GetSportItemQuery = graphql(
  `
    query sportItem($id: ID!) {
      sportItem(id: $id) {
        ...sportItem
      }
    }
  `,
  [SportItemFragment],
)
