import { DragDropContext, Droppable } from '@hello-pangea/dnd'
import DraggableTableRow from 'Components/DraggableTableRow/DraggableTableRow'
import GenericForm from 'Components/Form/GenericForm'
import { SelectLocalInputProps } from 'Components/Form/SelectLocalInput'
import React, { useState } from 'react'
import { FieldInputProps } from 'react-final-form'
import { useIntl } from 'react-intl'
import { Button, Icon, Ref, Table } from 'semantic-ui-react'

import { TabEdit } from './single'
import { TabForm } from './utils'

export const TabEditMultiple = ({
  value: tabs,
  onChange,
  ...localeProps
}: FieldInputProps<TabForm[] | undefined, HTMLElement> & SelectLocalInputProps) => {
  const { defaultLocale } = localeProps
  const intl = useIntl()
  const { activeTranslation } = localeProps
  const [openIndex, setOpenIndex] = useState<number>()

  return (
    <DragDropContext
      onDragEnd={({ source, destination, reason }) => {
        if (!tabs || !destination || reason === 'CANCEL' || destination.index === source.index) return

        const newTabs = [...tabs]
        const tab = tabs[source.index]
        newTabs.splice(source.index, 1)
        newTabs.splice(destination.index, 0, tab)
        onChange(newTabs)
      }}
    >
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={1}></Table.HeaderCell>
            <Table.HeaderCell width={4}>{intl.formatMessage({ id: 'common.title' })}</Table.HeaderCell>
            <Table.HeaderCell width={4}>{intl.formatMessage({ id: 'tabs.position.title' })}</Table.HeaderCell>
            <Table.HeaderCell width={2} textAlign="right">
              {intl.formatMessage({ id: 'common.actions' })}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Droppable droppableId="tabs">
          {provided => (
            <Ref innerRef={provided.innerRef}>
              <Table.Body className="relative" {...provided.droppableProps}>
                {tabs &&
                  tabs.map(({ titleT, url, position }, index) => (
                    <DraggableTableRow key={`tab-${index}`} draggableId={`tab-${index}`} index={index}>
                      {({ dragHandleProps }) => (
                        <>
                          {/* Drag and drop button */}
                          <Table.Cell width={1}>
                            <Icon {...dragHandleProps} name="bars" />
                          </Table.Cell>

                          {/* Title */}
                          <Table.Cell width={4}>
                            {titleT && (
                              <a className="flex items-center" href={url} target="_blank" rel="noreferrer">
                                <span className="text-lg mr-2">{(titleT as any)?.[activeTranslation as string]}</span>

                                <Icon name="external" size="small" />
                              </a>
                            )}
                          </Table.Cell>

                          {/* Position */}
                          <Table.Cell width={4}>
                            <span>{position && intl.formatMessage({ id: `tabs.position.${position}` })}</span>
                          </Table.Cell>

                          <Table.Cell width={2} textAlign="right">
                            {/* Edit button */}
                            <Button
                              icon="edit"
                              onClick={e => {
                                e.preventDefault()
                                setOpenIndex(index)
                              }}
                            />

                            {/* Delete button */}
                            <Button
                              inverted
                              icon="delete"
                              color="red"
                              onClick={e => {
                                e.preventDefault()
                                onChange(tabs.filter((_, i) => i !== index))
                              }}
                            />
                          </Table.Cell>
                        </>
                      )}
                    </DraggableTableRow>
                  ))}
              </Table.Body>
            </Ref>
          )}
        </Droppable>
      </Table>

      <GenericForm<{ tabs: TabForm[] | null }>
        initialValues={{ tabs }}
        open={typeof openIndex === 'number'}
        onSubmit={({ tabs }) => {
          setOpenIndex(undefined)
          if (!tabs) return

          const newTabs = [...tabs]
          const currentTab = newTabs[openIndex as number]
          newTabs[openIndex as number] = { ...currentTab, title: currentTab.titleT[defaultLocale] }

          onChange(newTabs)
        }}
        onCancel={() => setOpenIndex(undefined)}
      >
        <TabEdit index={openIndex as number} {...localeProps} />
      </GenericForm>

      <div className="flex justify-end mx-4 my-2">
        <Button
          circular
          icon="plus"
          onClick={e => {
            e.preventDefault()
            setOpenIndex(tabs ? tabs.length : 0)
          }}
        />
      </div>
    </DragDropContext>
  )
}
