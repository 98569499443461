import GenericForm from 'Components/Form/GenericForm'
import SAFormField from 'Components/Form/SAFormField'
import SelectOrganismsInput from 'Containers/Form/SelectOrganismsInput'
import SelectPermissionsInput from 'Containers/Form/SelectPermissionsInput'
import SelectUserRolesInput from 'Containers/Form/SelectUserRolesInput'
import _ from 'lodash'
import { useGetOrganismsMap } from 'models'
import React, { Fragment, useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { Form } from 'semantic-ui-react'
import { Organism, OrganismPermissions, Permission, UserRoleId } from 'services/api/graphql'
import { useCurrentUser } from 'stores'
import { composeValidators, validateEmail, validatePassword, validateRequired } from 'tools/formValidators'

type OrganismPermissionsMap = {
  [key: string]: Permission[]
}
const organismPermissionsMapFromOrganismPermissions = (organisms: OrganismPermissions[]): OrganismPermissionsMap => {
  const organismPermissionsMap: OrganismPermissionsMap = {}
  organisms.forEach(organism => (organismPermissionsMap[organism.organismId] = organism.permissions))
  return organismPermissionsMap
}
const organismPermissionsFromOrganismPermissionsMap = (
  organismPermissionsMap: OrganismPermissionsMap,
): OrganismPermissions[] => {
  return _.map(organismPermissionsMap, (permissions, organismId) => ({
    organismId,
    permissions,
  }))
}

interface UserFormRootValues {
  id?: string // must be set when editing
  email: string
  firstName: string
  lastName: string
  chatAuthorName: string
  password: string
  roleIds?: UserRoleId[]
  organismIds?: Array<Organism['id']>
}

export type UserFormSimplifiedValues = UserFormRootValues & {
  rights?: {
    generalPermissions: Permission[]
    organismsPermissions: OrganismPermissionsMap
  }
}

interface UserFormSimplifiedProps {
  title: string
  edit: boolean // if true => Update User otherwise Add User
  initialValues?: UserFormSimplifiedValues
  onSubmit: (values: UserFormSimplifiedValues) => Promise<void> | void
  onCancel?: () => void
  loading: boolean
}
const UserFormSimplifiedForm = ({
  initialValues,
  onSubmit,
  onCancel,
  loading: parentLoading,
  title,
  edit,
}: UserFormSimplifiedProps) => {
  const intl = useIntl()
  const currentUser = useCurrentUser()
  const { organisms, loading: organismsLoading } = useGetOrganismsMap()
  const userIsMe = useMemo(() => {
    const loggedUser = currentUser.loggedUser
    if (!loggedUser) return false

    // If editing my user => cannot change rights
    const userId = initialValues && initialValues.id
    return userId && loggedUser.id === userId
  }, [currentUser, initialValues])
  const canEditRights = useMemo(() => {
    if (!edit) return true
    // If editing my user => cannot change rights
    if (userIsMe) return false
    return currentUser.can(Permission.UserRightsUpdate, undefined, true)
  }, [edit, currentUser, userIsMe])
  const canEditUserRoles = useMemo(() => {
    if (!edit) return true
    // If editing my user => cannot change rights
    if (userIsMe) return false
    return currentUser.can(Permission.UserUserRolesUpdate)
  }, [edit, currentUser, userIsMe])
  const canEditOrganisms = useMemo(
    () => !userIsMe && currentUser.can(Permission.UserOrganismsUpdate),
    [currentUser, userIsMe],
  )

  const loading = organismsLoading || parentLoading

  const submit = useCallback(
    ({ rights, roleIds, organismIds, ...values }: UserFormSimplifiedValues) => {
      const finalValues: UserFormSimplifiedValues = {
        ...values,
        ...(canEditRights ? { rights } : {}),
        ...(canEditUserRoles ? { roleIds } : {}),
        ...(canEditOrganisms ? { organismIds } : {}),
      }
      onSubmit(finalValues)
    },
    [onSubmit, canEditRights, canEditOrganisms, canEditUserRoles],
  )

  return (
    <GenericForm header={title} initialValues={initialValues} onCancel={onCancel} onSubmit={submit} loading={loading}>
      {({ values }) => (
        <Fragment>
          <SAFormField
            name="email"
            validate={composeValidators<UserFormSimplifiedValues['email']>([validateRequired, validateEmail])}
            render={({ input, meta }) => (
              <Form.Input
                {...input}
                disabled={edit}
                required
                error={!!meta.touched && !!meta.error}
                type="email"
                label={intl.formatMessage({ id: 'common.email' })}
                placeholder="name@email.com"
              />
            )}
          />
          <SAFormField
            name="password"
            validate={
              !edit
                ? composeValidators<UserFormSimplifiedValues['password']>([validateRequired, validatePassword])
                : undefined
            }
            render={({ input, meta }) => (
              <Form.Input
                {...input}
                required={!edit}
                error={!!meta.touched && !!meta.error}
                type="password"
                label={intl.formatMessage({ id: 'common.password' })}
              />
            )}
          />
          <SAFormField
            name="firstName"
            validate={validateRequired}
            render={({ input, meta }) => (
              <Form.Input
                {...input}
                required
                error={!!meta.touched && !!meta.error}
                label={intl.formatMessage({ id: 'common.firstName' })}
              />
            )}
          />
          <SAFormField
            name="lastName"
            validate={validateRequired}
            render={({ input, meta }) => (
              <Form.Input
                {...input}
                required
                error={!!meta.touched && !!meta.error}
                label={intl.formatMessage({ id: 'common.lastName' })}
              />
            )}
          />
          <SAFormField
            name="chatAuthorName"
            validate={validateRequired}
            render={({ input, meta }) => (
              <Form.Input
                {...input}
                required
                error={!!meta.touched && !!meta.error}
                label={intl.formatMessage({ id: 'common.chatAuthorName' })}
              />
            )}
          />
          <SAFormField
            name="organismIds"
            render={({ input, meta }) => (
              <Form.Field
                control={SelectOrganismsInput}
                multiple
                {...input}
                disabled={!canEditOrganisms}
                error={!!meta.touched && !!meta.error}
                label={intl.formatMessage({ id: 'common.organisms' })}
              />
            )}
          />
          <SAFormField
            name="roleIds"
            validate={validateRequired}
            render={({ input, meta }) => (
              <Form.Field
                control={SelectUserRolesInput}
                {...input}
                disabled={!canEditUserRoles}
                required
                error={!!meta.touched && !!meta.error}
                label={intl.formatMessage({ id: 'common.roles' })}
              />
            )}
          />
          {canEditRights && (
            <>
              <SAFormField
                name="rights.generalPermissions"
                render={({ input, meta }) => (
                  <Form.Field
                    control={SelectPermissionsInput}
                    {...input}
                    error={!!meta.touched && !!meta.error}
                    label={intl.formatMessage({ id: 'common.generalRights' })}
                  />
                )}
              />
              {values.organismIds &&
                values.organismIds.map(organismId => {
                  const organism = organisms[organismId]
                  if (!organism) return null
                  return (
                    <SAFormField
                      key={organismId}
                      name={`rights.organismsPermissions.${organismId}`}
                      render={({ input, meta }) => (
                        <Form.Field
                          control={SelectPermissionsInput}
                          {...input}
                          error={!!meta.touched && !!meta.error}
                          label={intl.formatMessage({ id: 'common.rightsForOrganism' }, { organism: organism.title })}
                        />
                      )}
                    />
                  )
                })}
            </>
          )}
        </Fragment>
      )}
    </GenericForm>
  )
}

export type UserFormValues = UserFormRootValues & {
  rights?: {
    generalPermissions: Permission[]
    organismsPermissions: OrganismPermissions[]
  }
}

interface UserFormProps {
  title: string
  edit: boolean // if true => Update User otherwise Add User
  initialValues?: UserFormValues
  onSubmit: (values: UserFormValues) => Promise<void> | void
  onCancel?: () => void
  loading: boolean
}
const UserForm = ({ initialValues, onSubmit, ...props }: UserFormProps) => {
  const rights = initialValues ? initialValues.rights : { generalPermissions: [], organismsPermissions: [] }
  const initValues: UserFormSimplifiedValues = {
    ...(initialValues || {
      email: '',
      firstName: '',
      lastName: '',
      chatAuthorName: '',
      password: '',
      roleIds: [],
      organismIds: [],
    }),
    rights: rights && {
      generalPermissions: rights.generalPermissions,
      organismsPermissions: organismPermissionsMapFromOrganismPermissions(rights.organismsPermissions),
    },
  }
  const submit = useCallback(
    ({ rights: userRights, ..._values }: UserFormSimplifiedValues) => {
      const values: UserFormValues = {
        ..._values,
        ...(userRights
          ? {
              rights: {
                generalPermissions: userRights.generalPermissions,
                organismsPermissions:
                  organismPermissionsFromOrganismPermissionsMap(userRights.organismsPermissions) || [],
              },
            }
          : {}),
      }
      onSubmit(values)
    },
    [onSubmit],
  )

  return <UserFormSimplifiedForm initialValues={initValues} onSubmit={submit} {...props} />
}

export default UserForm
