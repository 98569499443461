import GenericForm from 'Components/Form/GenericForm'
import SAFormField from 'Components/Form/SAFormField'
import SelectLocalInput, { SelectedTranslationsType } from 'Components/Form/SelectLocalInput'
import Config from 'config'
import arrayMutators from 'final-form-arrays'
import { ContentTranslation, ReplaysWidget, ReplaysWidgetInput } from 'models'
import React, { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { Form, Input, Message } from 'semantic-ui-react'
import { composeValidators, validateRequired } from 'tools/formValidators'

export type ReplaysProps = {
  onCancel: () => void
  onSubmit: (replays: ReplaysWidgetInput) => void
  values?: ReplaysWidget
}

export const Replays = ({ onCancel, onSubmit, values }: ReplaysProps) => {
  const intl = useIntl()

  const initialValues = useMemo(
    () =>
      values ?? {
        nameT: [{ locale: Config.availableLocalesOptions[0].value, content: '' }],
        defaultLocale: Config.availableLocalesOptions[0].value,
      },
    [values],
  ) as ReplaysWidgetInput

  const [selectedTranslations, setSelectedTranslations] = useState<SelectedTranslationsType[]>(
    initialValues.nameT.map(t => ({
      value: t.locale,
      text: Config.availableLocalesOptions.find(l => l.value === t.locale)?.text,
    })),
  )
  const [defaultLocale, setDefaultLocale] = useState<string>(
    initialValues?.defaultLocale || Config.availableLocalesOptions[0].value,
  )
  const [activeTranslation, setActiveTranslation] = useState<string | undefined>(defaultLocale)
  const [indexTranslation, setIndexTranslation] = useState<number | undefined>(0)

  useEffect(() => {
    setIndexTranslation(selectedTranslations.findIndex(t => t.value === activeTranslation))
  }, [activeTranslation, selectedTranslations])

  return (
    <GenericForm
      header={intl.formatMessage({ id: 'customPage.widgets.replays' })}
      initialValues={initialValues}
      mutators={{
        ...arrayMutators,
      }}
      onCancel={onCancel}
      onSubmit={onSubmit}
      validate={async ({ maxReplays, depthInDays }) => {
        if (!maxReplays && !depthInDays) {
          return { global: intl.formatMessage({ id: 'widget.replays.validation.replaysOrDepthRequired' }) }
        }

        return
      }}
      loading={false}
    >
      {({ values: { nameT }, form, touched, errors }) => {
        return (
          <>
            {/* Locale */}
            <SAFormField
              name="locale"
              render={({ input }) => (
                <Form.Field
                  {...input}
                  disabled={false}
                  label={intl.formatMessage({ id: 'locale' })}
                  control={() => (
                    <SelectLocalInput
                      defaultLocale={defaultLocale}
                      onChangeDefaultLocale={setDefaultLocale}
                      activeTranslation={activeTranslation}
                      onChangeActiveTranslation={setActiveTranslation}
                      selectedTranslations={selectedTranslations}
                      onChangeSelectedTranslations={newSelectionTranslations => {
                        form.change(
                          'nameT',
                          newSelectionTranslations.map(st => ({
                            locale: st.value,
                            content: nameT?.find(t => t.locale === st.value)?.content as string,
                          })),
                        )
                        setSelectedTranslations(newSelectionTranslations)
                      }}
                      errors={errors?.['nameT']}
                    />
                  )}
                />
              )}
            />

            {/* Name */}
            {nameT.map((_, i) => (
              <SAFormField<ContentTranslation, HTMLElement>
                key={i}
                name={`nameT[${i}].content`}
                validate={validateRequired}
                render={({ input, meta }) =>
                  indexTranslation === i && (
                    <Form.Field
                      control={Input}
                      {...input}
                      required
                      error={!!meta.touched && !!meta.error}
                      label={intl.formatMessage({ id: 'common.name' })}
                    />
                  )
                }
              />
            ))}

            {/* Max replays */}
            <SAFormField<number, HTMLInputElement>
              name="maxReplays"
              validate={composeValidators([
                v =>
                  // Number must be greather than 0
                  !v || v > 0 ? undefined : intl.formatMessage({ id: 'common.validation.number-gt-zero' }),
              ])}
              parse={v => parseInt(v.toString(), 10)}
              render={({ input, meta }) => (
                <Form.Field
                  control={Input}
                  {...input}
                  type="number"
                  min="1"
                  placeholder={10}
                  // Prevent putting a minus sign
                  onKeyDown={(e: KeyboardEvent) => e.key === '-' && e.preventDefault()}
                  error={!!meta.touched && !!meta.error}
                  label={intl.formatMessage({ id: 'widget.replays.maxReplays' })}
                />
              )}
            />

            {/* Depth in days */}
            <SAFormField<number, HTMLInputElement>
              name="depthInDays"
              validate={composeValidators([
                v =>
                  // Number must be greather than 0
                  !v || v > 0 ? undefined : intl.formatMessage({ id: 'common.validation.number-gt-zero' }),
              ])}
              parse={v => parseInt(v.toString(), 10)}
              render={({ input, meta }) => (
                <Form.Field
                  control={Input}
                  {...input}
                  type="number"
                  min="1"
                  // Prevent putting a minus sign
                  onKeyDown={(e: KeyboardEvent) => e.key === '-' && e.preventDefault()}
                  error={!!meta.touched && !!meta.error}
                  label={intl.formatMessage({ id: 'widget.replays.depthInDays' })}
                />
              )}
            />

            {(touched?.['maxReplays'] || touched?.['depthInDays']) && errors?.global && (
              <Message negative>{errors.global}</Message>
            )}
          </>
        )
      }}
    </GenericForm>
  )
}
