import Versions from 'Containers/Versions'
import classnames from 'classnames'
import React, { useMemo, FC, useState, useCallback, MouseEventHandler } from 'react'
import { useIntl } from 'react-intl'
import { Link, useLocation } from 'react-router-dom'
import { Menu, Accordion, Icon } from 'semantic-ui-react'
import { canAccess } from 'services/api'
import { useCurrentUser } from 'stores'
import styled from 'styled-components'

import { SidebarMenuItem, menuItems } from './sidebarMenu'

const SideBarMenuItem = styled(Menu)`
  background-color: #1b1c1d !important;
  padding: 0.5rem 1rem !important;
`
type MenuItemProps = { item: SidebarMenuItem; index: number; activeIndex: number | null; onClick: MouseEventHandler }

const MenuItem: FC<MenuItemProps> = ({ item, index, activeIndex, onClick }) => {
  const user = useCurrentUser().user
  const location = useLocation()
  const currentPath = location.pathname
  const isOpen = activeIndex === index
  const filteredItems = useMemo(
    () =>
      item.items?.filter(
        subItem => !subItem.access || (user && canAccess(subItem.access, user?.mergedPermissions, user?.userRoleIds)),
      ) || [],
    [item.items, user],
  )

  if (filteredItems.length === 0) return null

  return (
    <Accordion key={item.id}>
      <Accordion.Title active={isOpen} onClick={onClick}>
        <div className={classnames({ 'text-white': isOpen, 'text-gray-400 ': !isOpen })}>
          <Icon name="dropdown" />
          <label className="cursor-pointer text-base pl-3">{item.title}</label>
        </div>
      </Accordion.Title>
      <Accordion.Content className="overflow-hidden" active={isOpen}>
        <Menu vertical borderless inverted>
          {filteredItems.map(filteredItem => {
            return (
              <Link to={filteredItem.route} key={filteredItem.id}>
                <SideBarMenuItem
                  key={filteredItem.id}
                  className={classnames('cursor-pointer', {
                    'text-white': currentPath.startsWith(filteredItem.route) === true,
                    'text-gray-400 ': currentPath.startsWith(filteredItem.route) === false,
                  })}
                >
                  <div className="ml-4">{filteredItem.title}</div>
                </SideBarMenuItem>
              </Link>
            )
          })}
        </Menu>
      </Accordion.Content>
    </Accordion>
  )
}

const SideBar = ({ className }: { className?: string }) => {
  const intl = useIntl()

  const items = menuItems(intl)
  const [activeIndex, setActiveIndex] = useState<number | null>(null)

  const handleClick = useCallback(
    (index: number) => () => {
      setActiveIndex(activeIndex === index ? null : index)
    },
    [activeIndex],
  )

  return (
    <div className="flex flex-col h-full" style={{ backgroundColor: '#1b1c1d' }}>
      <Menu className={className} inverted vertical>
        {items.map((item, index) => (
          <MenuItem key={item.id} item={item} index={index} onClick={handleClick(index)} activeIndex={activeIndex} />
        ))}
      </Menu>

      <div className="mt-auto self-center p-4">
        <Versions />
      </div>
    </div>
  )
}

export default SideBar
