export interface CSVData {
  headers: string[]
  rows: Array<Array<string | number | boolean>>
}

export function makeCSVFile(data: CSVData) {
  let csvContent = `${data.headers.join(',')}\n`
  csvContent = data.rows.reduce((content, row) => content + row.join(',') + '\n', csvContent)

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
  const url = URL.createObjectURL(blob)

  return url
}

export function clearCSVFile(url: string) {
  URL.revokeObjectURL(url)
}
