import { useMutation } from '@apollo/client'
import { ApolloError, GraphQLErrors } from '@apollo/client/errors'
import { MutationFunctionOptions, MutationResult } from '@apollo/react-common'
import { DocumentNode } from 'graphql'
import { useMemo } from 'react'
import { pickDeep } from 'tools/tools'

export const getErrorMessage = (error: Error | ApolloError) => {
  return error instanceof ApolloError ? getGraphqlErrorMessage(error.graphQLErrors) : error.message
}

export const getGraphqlErrorMessage = (errors: GraphQLErrors): string | undefined => {
  const error = errors.find(error => error?.extensions?.message ?? error?.extensions?.originalError?.message)
  if (error) {
    return error.extensions?.message ?? error?.extensions?.originalError?.message
  }

  return errors.length ? errors[0].message : undefined
}

export const parseGraphqlErrorMessages = (errors: GraphQLErrors): GraphQLErrors =>
  errors.map(({ extensions, message, ...error }) => ({
    ...error,
    extensions,
    message: extensions?.message ?? message,
  }))

export const getStatusCode = (errors: GraphQLErrors): number =>
  errors.find(({ extensions }) => extensions?.statusCode)?.extensions?.statusCode ?? -1

export function cloneWithoutGraphqlCacheTypenames<T>(value: T & { __typename?: string }): T {
  return pickDeep(value, (_, key) => key !== '__typename')
}

export function cloneWithoutTimestamps<T>(value: T & { createdAt?: Date; updatedAt?: Date; deletedAt?: Date }): T {
  return pickDeep(value, (_, key) => !['createdAt', 'updatedAt', 'deletedAt'].includes(key as string))
}

export const useGraphQLDataMap = <T extends { id: string }>(
  dataArray: T[] | undefined | null,
): { [key: string]: T } => {
  return useMemo(() => {
    const dataObject: { [key: string]: T } = {}
    if (dataArray) {
      if (typeof dataArray.forEach !== 'function') {
        // tslint:disable-next-line: no-debugger
        debugger
      }
      dataArray.forEach(data => {
        dataObject[data.id] = data
      })
    }
    return dataObject
  }, [dataArray])
}

/** Short mutation tuple, allow to call a mutation by directly passing its args */
export type ShortMutationReturnType<Response, MutationArgs> = [
  (variables: MutationArgs, options?: MutationFunctionOptions<Response, MutationArgs>) => Promise<Response | undefined>,
  MutationResult<Response>,
]

/**
 * Mutation that can be called directly with args and returns the result data
 */
export function useShortMutation<Response, MutationArgs>(
  mutation: DocumentNode,
): ShortMutationReturnType<Response, MutationArgs> {
  const [call, mutationRes] = useMutation<Response, MutationArgs>(mutation)
  return [
    async (variables: MutationArgs, options?: MutationFunctionOptions<Response, MutationArgs>) => {
      const result = await call({ variables, ...options })
      return result.data ?? undefined
    },
    { ...mutationRes, data: mutationRes.data ?? undefined },
  ]
}
