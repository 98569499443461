import { graphql } from 'gql'

export const OrganismFragment = graphql(`
  fragment organism on Organism @_unmask {
    id
    sveChannelIds
    sportIds
    visibleInMultisportApplication
    tags {
      id
      title
      subTags
    }
  }
`)

export const WmStreamFragment = graphql(`
  fragment wmStream on WMStream @_unmask {
    id
    name
  }
`)

export const SVEChannelFragment = graphql(`
  fragment sveChannel on SVEChannel @_unmask {
    id
    name
  }
`)

export const GetOrganismStreamsSVEAndWM = graphql(
  `
    query organismStreamsSVEAndWM($id: ID!, $input: OrganismAvailableSVEChannelsInput) {
      organism(id: $id) {
        ...organism
        streams {
          ...wmStream
        }
        sveChannels {
          ...sveChannel
        }
        availableSVEChannels(input: $input) {
          ...sveChannel
        }
        availableWMStreams {
          ...wmStream
        }
      }
    }
  `,
  [OrganismFragment, WmStreamFragment, SVEChannelFragment],
)
