import SwitchInput, { SwitchOption } from 'Components/Form/SwitchInput'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { PremiumAccess } from 'services/api/graphql'

export interface SelectPremiumAccessProps {
  value: PremiumAccess
  onChange: (value: PremiumAccess) => void
}

const SelectPremiumAccessInput = ({ value, onChange }: SelectPremiumAccessProps) => {
  const intl = useIntl()
  const options: Array<SwitchOption<PremiumAccess>> = useMemo(
    () => [
      {
        key: PremiumAccess.None,
        value: PremiumAccess.None,
        text: intl.formatMessage({
          id: `premiumAccess.none`,
        }),
      },
      {
        key: PremiumAccess.LiveReplay,
        value: PremiumAccess.LiveReplay,
        text: intl.formatMessage({
          id: `premiumAccess.liveReplay`,
        }),
      },
      {
        key: PremiumAccess.All,
        value: PremiumAccess.All,
        text: intl.formatMessage({
          id: `premiumAccess.all`,
        }),
      },
    ],
    [intl],
  )
  return <SwitchInput value={value} onChange={onChange} options={options} />
}

export default SelectPremiumAccessInput
