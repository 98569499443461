import PageHeader from 'Components/PageHeader'
import SelectApplicationInput from 'Components/SelectApplicationInput'
import CustomPageForm from 'Forms/CustomPage/CustomPageForm'
import { applicationUris } from 'Layout/uris'
import { observer } from 'mobx-react-lite'
import { useGetCustomPage } from 'models'
import React from 'react'
import { useIntl } from 'react-intl'
import { useNavigate, useParams } from 'react-router-dom'
import { Segment } from 'semantic-ui-react'

import styled from 'styled-components'
import { cloneWithoutGraphqlCacheTypenames } from 'tools/graphql'

const CustomPageView = observer(() => {
  const intl = useIntl()

  const navigate = useNavigate()

  const { customPageId } = useParams()

  const searchParams = new URLSearchParams(location.search)
  const applicationId = searchParams.get('applicationId')

  const { data, loading: customPageLoading } = useGetCustomPage(customPageId as string)

  return (
    <CustomPageContainer>
      <div className="p-2">
        <PageHeader title={intl.formatMessage({ id: 'customPage.name' }, { type: 'singular' })} />
        {!customPageId && <SelectApplicationInput isCustomPageCreate={true} />}
      </div>
      <Segment>
        {!customPageLoading && (
          <CustomPageForm
            customPage={data && cloneWithoutGraphqlCacheTypenames(data.customPage)}
            applicationId={applicationId || undefined}
            onCancel={() => {
              navigate({
                pathname: applicationUris.customPages,
                search: `?applicationId=${applicationId || data?.customPage.applicationId}`,
              })
            }}
            onDone={() =>
              navigate({
                pathname: applicationUris.customPages,
                search: `?applicationId=${applicationId || data?.customPage.applicationId}`,
              })
            }
          />
        )}
      </Segment>
    </CustomPageContainer>
  )
})
const CustomPageContainer = styled.div`
  display: 'flex',
  'flex-direction': 'column',
  padding: '20px',
`

export default CustomPageView
