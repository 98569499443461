import ConfirmationButton from 'Containers/Button/ConfirmationButton'
import AdCampaignForm from 'Forms/AdCampaign/AdCampaignForm'
import { useGetLightOrganism } from 'models'
import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Icon, Modal, Table } from 'semantic-ui-react'
import { AdCampaign, Permission } from 'services/api/graphql'
import { useCurrentUser } from 'stores'

interface AdCampaignViewRowProps {
  adCampaign: AdCampaign
  onUpdateAdCampaign: () => Promise<any>
  onRemoveAdCampaign: () => Promise<any>
}

const AdCampaignViewRow = ({ adCampaign, onUpdateAdCampaign, onRemoveAdCampaign }: AdCampaignViewRowProps) => {
  const intl = useIntl()
  const currentUser = useCurrentUser()
  const [formModalOpen, setFormModalOpen] = useState(false)
  const { id, title, publicationStatus, organismId } = adCampaign
  const { organism } = useGetLightOrganism(organismId)

  return (
    <Table.Row key={id}>
      <Table.Cell textAlign="center" width={3}>
        {title}
      </Table.Cell>
      <Table.Cell textAlign="center">
        <FormattedMessage id={`publicationStatus.${publicationStatus}`} />
      </Table.Cell>
      <Table.Cell textAlign="center" width={3}>
        {organism?.title}
      </Table.Cell>
      <Table.Cell textAlign="center">
        <div className="flex justify-center align-middle mr-1 last:mr-0">
          {currentUser.can(Permission.AdCampaignUpdate) && (
            <Modal
              mountNode={document.getElementById('modals')}
              trigger={<Icon name="edit" link size="large" inverted color="black" />}
              content={
                <AdCampaignForm
                  adCampaign={adCampaign}
                  organismId={organism?.id ?? ''}
                  onDone={async () => {
                    await onUpdateAdCampaign()
                    setFormModalOpen(false)
                  }}
                  onCancel={() => setFormModalOpen(false)}
                />
              }
              onClose={() => setFormModalOpen(false)}
              onOpen={() => setFormModalOpen(true)}
              open={formModalOpen}
            />
          )}
          {currentUser.can(Permission.AdCampaignDelete) && (
            <ConfirmationButton
              action={onRemoveAdCampaign}
              confirmText={intl.formatMessage({
                id: 'adCampaign.delete_confirm',
              })}
              successText={intl.formatMessage({
                id: 'adCampaign.delete_success',
              })}
            />
          )}
        </div>
      </Table.Cell>
    </Table.Row>
  )
}

export default AdCampaignViewRow
