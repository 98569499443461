import { applicationUris } from 'Layout/uris'
import React from 'react'

import { useIntl } from 'react-intl'
import { Navigate, useParams } from 'react-router-dom'

import SportItemSetViewBase from './SportItemSetViewBase'

const PlaylistView = () => {
  const intl = useIntl()
  const { sportItemSetId } = useParams<{ sportItemSetId: string }>()

  if (!sportItemSetId) return <Navigate to="/dashboard" />

  return (
    <SportItemSetViewBase
      genericTitle={intl.formatMessage({
        id: 'sportItemSet.generic_header.playlist',
      })}
      sportItemSetId={sportItemSetId}
      allSportItemSetsTitle={intl.formatMessage({
        id: 'sportItemSets.list.playlist',
      })}
      allSportItemSetsViewUrl={applicationUris.playlists}
    />
  )
}

export default PlaylistView
