import ConfirmationButton, { ConfirmationButtonBaseProps } from 'Containers/Button/ConfirmationButton'
import React from 'react'
import { SveChannel, useDeleteSveChannelMutation } from 'services/api/graphql'

export interface RemoveSVEChannelButtonProps extends ConfirmationButtonBaseProps {
  sveChannelId: SveChannel['id']
}

export const RemoveSVEChannelButton = ({ sveChannelId, ...props }: RemoveSVEChannelButtonProps) => {
  const [deleteSVEChannel] = useDeleteSveChannelMutation()
  return <ConfirmationButton action={() => deleteSVEChannel({ variables: { channelId: sveChannelId } })} {...props} />
}
