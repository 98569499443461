import { useApiCall } from 'hooks/api'
import { parse } from 'query-string'
import React, { FC, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useLocation } from 'react-router-dom'
import { Container, Segment } from 'semantic-ui-react'
import { verifyAccount } from 'services/api/auth'

const VerifyAccount: FC = () => {
  const { execute: callVerifyPassword, result, error, loading } = useApiCall(verifyAccount)
  const location = useLocation()
  const { verificationToken } = parse(location.search)

  useEffect(() => {
    if (verificationToken) callVerifyPassword(verificationToken as string)
  }, [callVerifyPassword, verificationToken])

  return (
    <Segment>
      <Container>
        {!verificationToken && <FormattedMessage id="verify_account.token_missing" />}
        {loading && <FormattedMessage id="verify_account.loading" />}
        {error && <FormattedMessage id="verify_account.invalid_token" />}
        {result && <FormattedMessage id="verify_account.success" values={{ email: result.user.email }} />}
      </Container>
    </Segment>
  )
}

export default VerifyAccount
