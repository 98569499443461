import RefreshButton from 'Components/Button/RefreshButton'
import Loader from 'Components/Loader'
import PageHeader from 'Components/PageHeader'
import { SVEChannelsTable, SVEChannelWithOrganism } from 'Containers/SVEChannelsTable'
import AddSVEChannelButton from 'Forms/SVEChannel/AddSVEChannelButton'
import { isNil } from 'lodash'
import { observer } from 'mobx-react-lite'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Button, Grid } from 'semantic-ui-react'
import { Organism, Permission, useOrganismsQuery, UserRoleId, useSveChannelsQuery } from 'services/api/graphql'
import { useCurrentUser, useStore } from 'stores'
import { notifyError } from 'tools/toaster'

const usePagination = <T,>(items: T[], pageSize = 10) => {
  const [page, setPage] = useState<number>(1)
  const offset = (page - 1) * pageSize
  const first = pageSize
  const pageItems = isNil(first) ? items : items?.slice(offset, first + offset)

  const totalPages = useMemo(() => {
    const totalCount = items.length
    if (!totalCount) return 0
    return Math.ceil(totalCount / pageSize)
  }, [items.length, pageSize])
  return { items: pageItems, page, setPage, totalPages }
}
export interface UserRoleIconsProps {
  roleIds: UserRoleId[]
}

export interface SVEChannelsViewCoreProps {
  organismId: Organism['id'] | null
}
export const SVEChannelsViewCore: FC<SVEChannelsViewCoreProps> = ({ organismId }) => {
  const currentUser = useCurrentUser()

  const OrganismsQuery = useOrganismsQuery()
  const organisms = OrganismsQuery.data?.organisms

  const {
    data,
    error,
    refetch,
    loading: sveChannelsLoading,
  } = useSveChannelsQuery({
    variables: { input: {} },
    fetchPolicy: 'cache-and-network',
  })
  const sveChannels = data?.sveChannels.sveChannels

  const sveChannelsWithOrganism: SVEChannelWithOrganism[] | undefined = useMemo(() => {
    if (!organisms) return
    return sveChannels?.map(sveChannel => ({
      ...sveChannel,
      organism: organisms?.organisms.find(({ sveChannelIds }) => sveChannelIds?.includes(sveChannel.id)),
    }))
  }, [organisms, sveChannels])
  const keptSVEChannelsWithOrganism = useMemo(() => {
    const sveChannels = organismId
      ? sveChannelsWithOrganism?.filter(sveChannel => !sveChannel.organism || sveChannel.organism.id === organismId)
      : sveChannelsWithOrganism
    return (sveChannels ?? []).sort((a, b) => {
      if (a.organism && !b.organism) return -1
      if (!a.organism && b.organism) return 1
      return 0
    })
  }, [organismId, sveChannelsWithOrganism])
  const { items, page, setPage, totalPages } = usePagination(keptSVEChannelsWithOrganism ?? [])

  useEffect(() => {
    if (error) notifyError(<FormattedMessage id="error.general" />, error)
  }, [error])

  const loading = sveChannelsLoading || OrganismsQuery.loading
  return (
    <Loader loading={loading}>
      <Grid padded>
        <Grid.Column>
          <PageHeader title={<FormattedMessage id={`sveChannels.management`} />}>
            <RefreshButton onClick={() => refetch()} loading={loading} />
            {currentUser.can(Permission.SveChannelCreate) && (
              <AddSVEChannelButton
                onDone={() => refetch()}
                render={onClick => <Button circular icon="add" color="red" onClick={onClick} />}
              />
            )}
          </PageHeader>

          {sveChannelsWithOrganism && (
            <SVEChannelsTable
              sveChannels={items}
              organismId={organismId}
              totalPages={totalPages}
              activePage={page}
              onPageChange={page => setPage(page)}
              withActions
              refetchSVEchannels={refetch}
            />
          )}
        </Grid.Column>
      </Grid>
    </Loader>
  )
}

export const SVEChannelsView = observer(() => {
  const { organismId } = useStore()

  return <SVEChannelsViewCore organismId={organismId} />
})
