import { MediaLightContentFragment, UpdateMediaInput, useUpdateMedia } from 'models'
import React, { useCallback, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { notifyError, notifySuccess } from 'tools/toaster'

import TrimButton from './TrimButton'

interface EditTrimVideoProps {
  mediaToUpdate?: MediaLightContentFragment
  currentTime: number
  duration: number
  startTrim: number
  endTrim: number
  validFormat: boolean
  onClose: () => void
  onUpdateStartTrim: (value: number) => void
  onUpdateEndTrim: (value: number) => void
  onValidFormat: (value: boolean) => void
}
const EditTrimVideo: React.FC<EditTrimVideoProps> = ({
  mediaToUpdate,
  currentTime,
  duration,
  startTrim,
  endTrim,
  validFormat,
  onValidFormat,
  onClose,
  onUpdateStartTrim,
  onUpdateEndTrim,
}) => {
  const intl = useIntl()

  const [positionToOpen, setPositionToOpen] = useState<string | undefined>(undefined)

  const [updateMedia] = useUpdateMedia()

  const startTrimIntialValue = useRef<number>(startTrim)
  const endTrimIntialValue = useRef<number>(endTrim)

  const canceledUpdateMedia = (e: { preventDefault: () => void }) => {
    e.preventDefault()
    onUpdateStartTrim(startTrimIntialValue.current)
    onUpdateEndTrim(endTrimIntialValue.current)
    onClose()
  }

  const handleUpdateMedia = useCallback(
    async ({ startTrim, endTrim }: UpdateMediaInput) => {
      if (mediaToUpdate) {
        try {
          await updateMedia({
            variables: { mediaId: mediaToUpdate.id, input: { startTrim, endTrim } },
          }),
            notifySuccess(intl.formatMessage({ id: 'sportItem.media_updated' }))
          onClose()
        } catch (e) {
          notifyError(intl.formatMessage({ id: 'sportItem.update_media_error' }))
        }
      }
    },
    [mediaToUpdate, updateMedia, intl, onClose],
  )

  return (
    <div className="flex flex-row justify-between">
      <TrimButton
        buttonPosition="left"
        trimRef={startTrimIntialValue.current}
        positionToOpen={positionToOpen}
        mediaToUpdate={mediaToUpdate}
        duration={duration}
        trimValue={startTrim}
        currentTime={currentTime}
        validFormat={validFormat}
        onUpdateTrim={onUpdateStartTrim}
        onValidFormat={onValidFormat}
        onSetPositionToOpen={setPositionToOpen}
      />

      <>
        {validFormat && !positionToOpen && endTrim > startTrim && endTrim <= duration && (
          <div className="self-center">
            <button
              className="bg-green-600 rounded text-white w-20 h-12 mr-2"
              onClick={() => handleUpdateMedia({ startTrim, endTrim })}
            >
              {intl.formatMessage({ id: 'edit.validate' })}
            </button>
            <button className="bg-red-600 rounded text-white w-20 h-12 ml-2" onClick={canceledUpdateMedia}>
              {intl.formatMessage({ id: 'edit.cancel' })}
            </button>
          </div>
        )}
        {!validFormat && (
          <span className="text-red-600 mx-4 text-justify self-center">
            {intl.formatMessage({ id: 'edit.invalidFormatTime' })}
          </span>
        )}
        {endTrim < startTrim && (
          <span className="text-red-600 mx-4 text-justify self-center">
            {intl.formatMessage({ id: 'trim.invalidInput' })}
          </span>
        )}
        {endTrim > duration && (
          <span className="text-red-600 mx-4 text-justify self-center">
            {intl.formatMessage({ id: 'trim.invalidEndTrim' })}
          </span>
        )}
      </>

      <TrimButton
        buttonPosition="right"
        trimRef={endTrimIntialValue.current}
        positionToOpen={positionToOpen}
        mediaToUpdate={mediaToUpdate}
        trimValue={endTrim}
        duration={duration}
        currentTime={currentTime}
        validFormat={validFormat}
        onUpdateTrim={onUpdateEndTrim}
        onValidFormat={onValidFormat}
        onSetPositionToOpen={setPositionToOpen}
      />
    </div>
  )
}
export default EditTrimVideo
