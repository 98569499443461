import { SelectedTranslationsType } from 'Components/Form/SelectLocalInput'
import { FormBaseProps } from 'Forms/FormBase'
import { useGetSportItemSet } from 'models'
import React, { useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { Loader } from 'semantic-ui-react'
import { SportItemSet, SportItemSetContentFragment, useUpdateSportItemSetMutation } from 'services/api/graphql'

import { SportItemSetForm, SportItemSetFormValues } from './SportItemSetForm'
import { SportItemSet_APIToFormValues, SportItemSet_FormValuesToAPI_Update } from './SportItemSetForm.transformers'

interface EditSportItemSetFormProps extends Omit<FormBaseProps<SportItemSet>, 'onDone'> {
  editType: boolean
  isModal: boolean
  sportItemSetId: SportItemSet['id']
  onDone: (value: SportItemSetContentFragment) => void
}
const EditSportItemSetForm = ({
  editType,
  isModal,
  sportItemSetId,
  onCancel,
  onDone,
  onError,
}: EditSportItemSetFormProps) => {
  const useGetSportItemSetQuery = useGetSportItemSet(sportItemSetId, { fetchPolicy: 'cache-and-network' })
  const sportItemSetValues = useMemo(
    () => useGetSportItemSetQuery.sportItemSet && SportItemSet_APIToFormValues(useGetSportItemSetQuery.sportItemSet),
    [useGetSportItemSetQuery.sportItemSet],
  )
  const [updateSportItemSet, { loading }] = useUpdateSportItemSetMutation()
  const intl = useIntl()

  const handleSubmit = useCallback(
    async (values: SportItemSetFormValues, _, selectedTranslations: SelectedTranslationsType[]) => {
      const input = SportItemSet_FormValuesToAPI_Update({ ...values, id: sportItemSetId }, selectedTranslations)

      try {
        const res = await updateSportItemSet({
          variables: { input },
        })

        if (res?.data?.updateSportItemSet) onDone(res.data.updateSportItemSet)
      } catch (err: any) {
        onError?.(err)
      }
    },
    [onDone, onError, sportItemSetId, updateSportItemSet],
  )

  if (useGetSportItemSetQuery.loading) {
    return <Loader />
  }

  if (!sportItemSetValues) {
    return null
  }

  return (
    <SportItemSetForm
      title={intl.formatMessage({ id: 'sportItemSet.edit.header' }, { title: sportItemSetValues.title })}
      type={sportItemSetValues.type}
      edit
      isModal={isModal}
      editType={editType}
      initialValues={sportItemSetValues}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      loading={loading}
    />
  )
}

export default EditSportItemSetForm
