import { FormBaseProps } from 'Forms/FormBase'
import { useGetSport, useUpdateSport } from 'models'
import React, { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { Sport } from 'services/api/graphql'
import { getTranslationInputFromForm, getTranslationInputFromValues } from 'tools/translationTools'

import SportForm, { SportFormValues } from './SportForm'

interface EditSportFormWithSportProps extends FormBaseProps<Sport> {
  sportValues: SportFormValues
}
const EditSportFormWithSport = ({ sportValues, onCancel, onDone, onError }: EditSportFormWithSportProps) => {
  const intl = useIntl()
  const [updateSport, { loading }] = useUpdateSport()

  const handleSubmit = useCallback(
    async ({ defaultLocale: _locale, ...values }, defaultLocale, selectedTranslations) => {
      if (!sportValues.id) {
        throw new Error(`Missing Sport id !`)
      }
      const titleT = getTranslationInputFromForm(selectedTranslations, values.titleT)
      try {
        const res = await updateSport({
          variables: {
            input: {
              ...values,
              id: sportValues.id,
              titleT,
              title: values.titleT?.[defaultLocale] || '',
            },
          },
        })
        if (res && res.data) onDone(res.data.updateSport)
      } catch (err: any) {
        onError?.(err)
      }
    },
    [onDone, onError, sportValues.id, updateSport],
  )

  return (
    <SportForm
      title={intl.formatMessage({ id: 'sports.form.edit.title' }, { title: sportValues.title })}
      edit
      initialValues={sportValues}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      loading={loading}
    />
  )
}

interface EditSportFormProps extends FormBaseProps<Sport> {
  sportId: Sport['id']
}
const EditSportForm = ({ sportId, ...props }: EditSportFormProps) => {
  const { sport } = useGetSport(sportId, { fetchPolicy: 'network-only' })
  if (!sport) return null

  const { cardImage, iconImage, bannerImage, bannerVideo } = sport
  const sportValues: SportFormValues = {
    ...getTranslationInputFromValues({ titleT: sport.titleT }),
    id: sport.id,
    title: sport.title,
    color: sport.color,
    cardImageFileId: (cardImage && cardImage.id) || '',
    iconImageFileId: (iconImage && iconImage.id) || '',
    bannerImageFileId: (bannerImage && bannerImage.id) || '',
    bannerVideoId: (bannerVideo && bannerVideo.id) || undefined,
    defaultLocale: sport.defaultLocale || undefined,
  }
  return <EditSportFormWithSport sportValues={sportValues} {...props} />
}
export default EditSportForm
