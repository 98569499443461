import { DraggableProvided } from '@hello-pangea/dnd'
import ConfirmationButton from 'Containers/Button/ConfirmationButton'
import { applicationUris } from 'Layout/uris'
import React, { useCallback, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { Icon, Modal, Table } from 'semantic-ui-react'
import { OfferMode, Permission, PurchasableOffer } from 'services/api/graphql'
import { useCurrentUser } from 'stores'
import styled from 'styled-components'
import { format } from 'tools/date'
import { cloneWithoutGraphqlCacheTypenames } from 'tools/graphql'

import PurchasableOffersForm from './PurchasableOffersForm'

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  & > :not(:last-child) {
    margin-right: 8px;
  }
`

interface PurchasableOffersDataRowProps {
  purchasableOffer: PurchasableOffer
  draggableProvided: DraggableProvided
  onDelete?: () => Promise<void>
}

const PurchasableOffersDataRow = ({ purchasableOffer, draggableProvided, onDelete }: PurchasableOffersDataRowProps) => {
  const intl = useIntl()
  const currentUser = useCurrentUser()
  const [isModalOpen, setModalOpen] = useState(false)

  const deleteOffer = useCallback(async () => {
    if (onDelete) {
      await onDelete()
    }
  }, [onDelete])

  return (
    <>
      <Table.Cell collapsing textAlign="center" {...draggableProvided.dragHandleProps}>
        <Icon name="bars" />
      </Table.Cell>
      <Table.Cell>{purchasableOffer.title}</Table.Cell>
      <Table.Cell>
        <FormattedMessage id={`purchasableOffer.mode.${purchasableOffer.mode}`} />
      </Table.Cell>
      <Table.Cell>
        {purchasableOffer.mode === OfferMode.Pass && !purchasableOffer.expiredAt ? (
          <>
            {purchasableOffer.delay} <FormattedMessage id="purchasableOffer.delay.time" />
          </>
        ) : (
          purchasableOffer.mode === OfferMode.Pass &&
          purchasableOffer.expiredAt && <>{format(new Date(purchasableOffer.expiredAt), 'DD/MM/YYYY')}</>
        )}
      </Table.Cell>
      <Table.Cell>{purchasableOffer.nbValidSubscriptions}</Table.Cell>
      <Table.Cell>
        <FormattedMessage id={`publicationStatus.${purchasableOffer.publicationStatus}`} />
      </Table.Cell>

      <Table.Cell textAlign="center">
        <Buttons>
          <Link to={applicationUris.products('{{id}}').replace('{{id}}', purchasableOffer.id)}>
            <Icon name="sitemap" link size="large" inverted color="black" />
          </Link>
          {currentUser.can(Permission.PurchasableOfferWrite) && (
            <>
              <Modal
                mountNode={document.getElementById('modals')}
                trigger={<Icon name="edit" link size="large" inverted color="black" />}
                onOpen={() => setModalOpen(true)}
                onClose={() => setModalOpen(false)}
                open={isModalOpen}
              >
                <PurchasableOffersForm
                  purchasableOffer={cloneWithoutGraphqlCacheTypenames(purchasableOffer)}
                  onCancel={() => setModalOpen(false)}
                  onDone={() => setModalOpen(false)}
                />
              </Modal>
              <ConfirmationButton
                confirmText={
                  <FormattedMessage id="purchasableOffer.delete.confirm" values={{ offer: purchasableOffer.title }} />
                }
                successText={intl.formatMessage(
                  { id: 'purchasableOffer.delete.success' },
                  { offer: purchasableOffer.title },
                )}
                action={deleteOffer}
                onDone={() => undefined}
              />
            </>
          )}
        </Buttons>
      </Table.Cell>
    </>
  )
}

export default PurchasableOffersDataRow
