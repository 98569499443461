import { applicationUris } from 'Layout/uris'
import React from 'react'
import { useIntl } from 'react-intl'
import { Navigate, useParams } from 'react-router-dom'

import SportItemSetViewBase from './SportItemSetViewBase'

const SportEventView = () => {
  const intl = useIntl()
  const { sportItemSetId } = useParams<{ sportItemSetId: string }>()

  if (!sportItemSetId) return <Navigate to="/dashboard" />

  return (
    <SportItemSetViewBase
      genericTitle={intl.formatMessage({
        id: 'sportItemSet.generic_header.sportEvent',
      })}
      sportItemSetId={sportItemSetId}
      allSportItemSetsTitle={intl.formatMessage({
        id: 'sportItemSets.list.sportEvent',
      })}
      allSportItemSetsViewUrl={applicationUris.sportEvents}
    />
  )
}

export default SportEventView
