import SwitchInput from 'Components/Form/SwitchInput'
import React from 'react'

import { IntlShape, useIntl } from 'react-intl'
import { VideoEngine } from 'services/api/graphql'

export interface SelectVideoEngineInputProps {
  value: VideoEngine | undefined
  onChange: (value: VideoEngine | undefined) => void
}

const options = (intl: IntlShape) => [
  {
    key: VideoEngine.Sportall,
    value: VideoEngine.Sportall,
    text: intl.formatMessage({ id: 'sportItemSets.videoEngine.sportall' }),
  },
  {
    key: VideoEngine.Wildmoka,
    value: VideoEngine.Wildmoka,
    text: intl.formatMessage({ id: 'sportItemSets.videoEngine.wildmoka' }),
  },
  {
    key: VideoEngine.ExternalFeed,
    value: VideoEngine.ExternalFeed,
    text: intl.formatMessage({ id: 'sportItemSets.videoEngine.externalFeed' }),
  },
]

const SelectVideoEngineInput = ({ value, onChange }: SelectVideoEngineInputProps) => {
  const intl = useIntl()
  return <SwitchInput value={value} onChange={onChange} options={options(intl)} />
}

export default SelectVideoEngineInput
