import { graphql } from 'gql'

export const UserFanFragment = graphql(`
  fragment userFan on User {
    birthdayDate
    cguAccepted
    location {
      country
      postalCode
    }
    signUpOrigin
    verified
  }
`)

export const UserLicensesFragment = graphql(`
  fragment userLicenses on User {
    licenses {
      organismId
      isLicensed
      registrationDate
    }
  }
`)

export const UserOffersFragment = graphql(`
  fragment userOffers on User {
    receipts {
      offerId
      productId
      status
      offer {
        id
        title
      }
    }
  }
`)

export const GetUserDetailsQuery = graphql(
  `
    query user($userId: ID!, $offers: Boolean!, $licenses: Boolean!, $fan: Boolean!) {
      user(id: $userId) {
        ...userOffers @include(if: $offers)
        ...userLicenses @include(if: $licenses)
        ...userFan @include(if: $fan)
      }
    }
  `,
  [UserOffersFragment, UserLicensesFragment, UserFanFragment],
)
