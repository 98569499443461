import { gql } from '@apollo/client'

import { CONTENT_TRANSLATION_FRAGMENT } from './ContentTranslation'

export const PURCHASABLE_OFFER_PRODUCT_FRAGMENT = gql`
  fragment purchasableOfferProductContent on Product {
    productId
    platform
    period
    publicationStatus
    purchasableOfferId
    licensedUserOnly
    premiumOf
    prices {
      value
      currency
    }
  }
`

export const PURCHASABLE_OFFER_FRAGMENT = gql`
  fragment purchasableOfferContent on PurchasableOffer {
    id
    mode
    delay
    expiredAt
    publicationStatus
    title
    titleT {
      ...contentTranslationContent
    }
    description {
      header
      content
    }
    descriptionT {
      locale
      content {
        header
        content
      }
    }
    defaultLocale
    url
    bannerImageId
    thumbnailImageId
    freeWithLicense
    products {
      ...purchasableOfferProductContent
    }
    position
  }
  ${CONTENT_TRANSLATION_FRAGMENT}
  ${PURCHASABLE_OFFER_PRODUCT_FRAGMENT}
`

export const GET_PURCHASABLE_OFFERS_QUERY = gql`
  query purchasableOffers {
    purchasableOffers {
      ...purchasableOfferContent
      nbValidSubscriptions
    }
  }
  ${PURCHASABLE_OFFER_FRAGMENT}
`

export const GET_PURCHASABLE_OFFER_QUERY = gql`
  query purchasableOffer($purchasableOfferId: ID!) {
    purchasableOffer(id: $purchasableOfferId) {
      ...purchasableOfferContent
    }
  }
  ${PURCHASABLE_OFFER_FRAGMENT}
`

export const CREATE_PURCHASABLE_OFFER_MUTATION = gql`
  mutation createPurchasableOffer($input: CreatePurchasableOfferInput!) {
    createPurchasableOffer(input: $input) {
      ...purchasableOfferContent
    }
  }
  ${PURCHASABLE_OFFER_FRAGMENT}
`

export const UPDATE_PURCHASABLE_OFFER_MUTATION = gql`
  mutation updatePurchasableOffer($id: ID!, $input: UpdatePurchasableOfferInput!) {
    updatePurchasableOffer(id: $id, input: $input) {
      ...purchasableOfferContent
    }
  }
  ${PURCHASABLE_OFFER_FRAGMENT}
`

export const UPDATE_PURCHASABLE_OFFER_PRODUCT_MUTATION = gql`
  mutation updateProduct($id: ID!, $productId: String!, $input: UpdateProductInput!) {
    updateProduct(id: $id, productId: $productId, input: $input) {
      ...purchasableOfferProductContent
    }
  }
  ${PURCHASABLE_OFFER_PRODUCT_FRAGMENT}
`

export const LATEST_PURCHASE_EXPORT_DOWNLOAD_URL = gql`
  query latestPurchaseExportDownloadUrl {
    latestPurchaseExportDownloadUrl
  }
`

export const DELETE_PURCHASABLE_OFFER_PRODUCT_MUTATION = gql`
  mutation deleteProduct($id: ID!, $input: DeleteProductInput!) {
    deleteProduct(id: $id, input: $input)
  }
`

export const DELETE_PURCHASABLE_OFFER_MUTATION = gql`
  mutation deletePurchasableOffer($id: ID!) {
    deletePurchasableOffer(id: $id)
  }
`

export const SCHEDULE_PURCHASE_EXPORT = gql`
  mutation schedulePurchaseExport {
    schedulePurchaseExport
  }
`
export const MOVE_PURCHASABLE_OFFER_MUTATION = gql`
  mutation movePurchasableOffer($input: MovePurchasableOfferInput!) {
    movePurchasableOffer(input: $input) {
      id
      position
    }
  }
`
