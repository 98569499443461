import SelectItemListInput from 'Components/Form/SelectItemListInput'
import { isArray, isString } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useGetOrganisms } from 'models'
import React, { useCallback, useMemo } from 'react'
import { Maybe, Organism, OrganismFilters, Permission } from 'services/api/graphql'
import { useCurrentUser } from 'stores'

export interface SelectOrganismsInputProps {
  value: Organism['id'] | Array<Organism['id']> | undefined
  onChange: (value: Maybe<Organism['id'] | Array<Organism['id']>>) => void
  noSelectionEnabled?: boolean
  noSelectionTitle?: string
  multiple?: boolean
  upward?: boolean
  filters?: OrganismFilters
}

const SelectOrganismsInput = observer(
  ({
    value,
    onChange,
    noSelectionEnabled,
    noSelectionTitle = 'Aucun',
    multiple,
    upward,
    filters,
  }: SelectOrganismsInputProps) => {
    const currentUser = useCurrentUser()
    const { organisms: organismsArray, loading /*, error, refetch*/ } = useGetOrganisms({ variables: { filters } })
    const allowedOrganismIds = useMemo(
      () => currentUser.getOrganismIdsForPermission(Permission.UserOrganismsUpdate),
      [currentUser],
    )
    const options = useMemo(() => {
      const organisms = organismsArray || []
      return organisms
        .filter(
          organism =>
            currentUser.can(Permission.UserOrganismsUpdate, undefined, true) ||
            allowedOrganismIds.includes(organism.id) ||
            (value &&
              ((isString(value) && (value as string) === organism.id) ||
                (isArray(value) && (value as string[]).includes(organism.id)))),
        )
        .map(organism => ({
          key: organism.id,
          value: organism.id,
          text: organism.title,
        }))
    }, [organismsArray, currentUser, allowedOrganismIds, value])

    const validateChange = useCallback(
      (updatedValue: string | string[] | null) => {
        // Call on change if the user can update any organism
        if (currentUser.can(Permission.UserOrganismsUpdate, undefined, true)) {
          onChange(updatedValue)
        }

        // Changes value into array
        const previousOrganismsIds = value ? (isArray(value) ? [...value] : [value]) : []
        const newOrganismsIds = updatedValue ? (isArray(updatedValue) ? [...updatedValue] : [updatedValue]) : []

        // Compare old and new value to list added or removed organisms
        const diff = [
          ...previousOrganismsIds.filter(id => !newOrganismsIds.includes(id)),
          ...newOrganismsIds.filter(id => !previousOrganismsIds.includes(id)),
        ]
        // Only call onChange if all new or removed organisms are allowed
        if (diff.every(id => allowedOrganismIds.includes(id))) {
          onChange(updatedValue)
        }
      },
      [currentUser, value, onChange, allowedOrganismIds],
    )

    return (
      <SelectItemListInput
        value={value}
        loading={loading}
        options={options}
        multiple={multiple}
        upward={upward}
        noSelectionEnabled={noSelectionEnabled}
        noSelectionTitle={noSelectionTitle}
        onChange={validateChange}
        clearable
      />
    )
  },
)

export default SelectOrganismsInput
