export type ImageDimensions = {
  width: number
  height: number
}

export enum ImageFormat {
  PNG = 'png',
  JPEG = 'jpeg',
  BMP = 'bmp',
  WEBP = 'webp',
}

export type ImageConstraints = {
  aspect?: number
  minWidth?: number
  minHeight?: number
  maxWidth?: number
  maxHeight?: number
  format?: ImageFormat
  maxSize?: number
}
