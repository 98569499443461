import { useQuery } from '@apollo/client'
import { GET_USERROLES_QUERY, GET_USERROLE_QUERY, QueryUserRoleArgs, UserRole, UserRoleId } from 'services/api/graphql'
import { useGraphQLDataMap } from 'tools/graphql'

/**
 * Hooks
 */
export type GetUserRolesResult = {
  userRoles: UserRole[]
}

export const useUserRolesArray = () => {
  const { data, ...rest } = useQuery<GetUserRolesResult>(GET_USERROLES_QUERY)
  return { userRolesArray: data && data.userRoles, ...rest }
}

export const useUserRoles = () => {
  const { userRolesArray, error, loading } = useUserRolesArray()
  const userRoles = useGraphQLDataMap(userRolesArray)
  return { userRoles, error, loading }
}

export type GetUserRoleResult = {
  userRole: UserRole
}

export const useUserRole = (userRoleId: UserRoleId) => {
  const { data, ...rest } = useQuery<GetUserRoleResult, QueryUserRoleArgs>(GET_USERROLE_QUERY, {
    variables: { id: userRoleId },
  })
  return { userRole: data && data.userRole, ...rest }
}
