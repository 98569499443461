import { SelectSVEChannelsInput, SelectSVEChannelsInputProps } from 'Containers/Form/SelectSVEChannelsInput'
import { useCheckStreamUnused } from 'models'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  LiveStream,
  LiveStreamStreamConflict,
  SportItemSet,
  SveChannel,
  useCheckStreamUnusedQuery,
} from 'services/api/graphql'

export interface SelectSVEChannelWithConflictsProps extends Omit<SelectSVEChannelsInputProps, 'value' | 'onChange'> {
  value: SveChannel['id'] | undefined
  onChange: (value: SveChannel['id'] | null) => void
  onConflicts: (conflicts: LiveStreamStreamConflict[]) => void
  onLoading?: (loading: boolean) => void
  loading?: boolean
  from: Date
  to: Date
  sportItemSetId: SportItemSet['id']
  livestreamId?: LiveStream['id']
}

/**
 * Select a single stream ID from Wildmoka, and check if it isn't used during a given period in a SportItemSet
 */
export const SelectSVEChannelWithConflicts: React.FC<SelectSVEChannelWithConflictsProps> = ({
  value,
  onChange,
  onConflicts,
  onLoading,
  source,
  loading,
  from,
  to,
  sportItemSetId,
  livestreamId,
  ...props
}) => {
  const [streamId, setStreamId] = useState(value || '')
  useCheckStreamUnusedQuery({
    variables: {
      sportItemSetId,
      from,
      to,
      streamId,
    },
    skip: !sportItemSetId || !from || !to || !streamId,
  })
  // Call API to obtain livestreams using the stream in sportItemSet
  const { data: streamConflictsData, loading: loadingCheckStream } = useCheckStreamUnused(
    {
      sportItemSetId,
      from,
      to,
      streamId,
    },
    { skip: !sportItemSetId || !from || !to || !streamId },
  )
  // Filter with liveStreamId to see OTHER conflicting livestreams
  const streamConflicts = useMemo(
    () =>
      streamConflictsData &&
      streamConflictsData.filter(conflict => !livestreamId || livestreamId !== conflict.liveStream.id),
    [livestreamId, streamConflictsData],
  )

  const updateValue = useCallback(
    (stream: SveChannel['id'] | Array<SveChannel['id']> | null) => {
      if (!Array.isArray(stream)) {
        setStreamId(stream || '')
        onChange(stream)
      }
    },
    [onChange],
  )

  useEffect(() => {
    if (streamConflicts) onConflicts(streamConflicts)
  }, [streamConflicts, onConflicts])

  useEffect(() => {
    if (onLoading) onLoading(loadingCheckStream)
  }, [onLoading, loadingCheckStream])

  return (
    <SelectSVEChannelsInput
      value={value}
      onChange={updateValue}
      noSelectionEnabled
      source={source}
      loading={loading || loadingCheckStream}
      multiple={false}
      {...props}
    />
  )
}
