import { SwitchOption } from 'Components/Form/SwitchInput'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { HasOfferFilter } from 'services/api/graphql'

import SelectItemListInput from './Form/SelectItemListInput'

export interface SelectPremiumAccessProps {
  value?: HasOfferFilter | null
  onChange: (value: HasOfferFilter | null) => void
}

const SelectHasOfferFilterInput = ({ value, onChange }: SelectPremiumAccessProps) => {
  const intl = useIntl()
  const options: Array<SwitchOption<HasOfferFilter>> = useMemo(
    () => [
      {
        key: HasOfferFilter.All,
        value: HasOfferFilter.All,
        text: intl.formatMessage({
          id: `offerFilter.all`,
        }),
      },
      {
        key: HasOfferFilter.ValidOnly,
        value: HasOfferFilter.ValidOnly,
        text: intl.formatMessage({
          id: `offerFilter.validOnly`,
        }),
      },
    ],
    [intl],
  )
  return <SelectItemListInput value={value} onChange={onChange} options={options} noSelectionEnabled />
}

export default SelectHasOfferFilterInput
