import EditSportItemForm from 'Forms/SportItem/EditSportItemForm'
import React from 'react'
import { useIntl } from 'react-intl'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Segment } from 'semantic-ui-react'
import { notifyError, notifySuccess } from 'tools/toaster'

type EditSportItemButtonProps = {
  sportItemViewModalId?: string
  onClose?: () => void
}

const EditSportItemButton = ({ sportItemViewModalId, onClose }: EditSportItemButtonProps) => {
  const intl = useIntl()
  const { sportItemId } = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  const isModal = location.state?.isModal || false
  return (
    <Segment>
      {(sportItemId || sportItemViewModalId) && (
        <EditSportItemForm
          sportItemId={sportItemViewModalId || (sportItemId as string)}
          isModal={isModal}
          editType={true}
          onCancel={() => (onClose ? onClose() : navigate(-1))}
          onDone={sportItem => {
            notifySuccess(
              intl.formatMessage(
                { id: `sportItem.update_success.${sportItem.videoContentType}` },
                {
                  title: sportItem.title,
                },
              ),
            )
            onClose ? onClose() : navigate(-1)
          }}
          onError={notifyError}
        />
      )}
    </Segment>
  )
}

export default EditSportItemButton
