import { applicationUris } from 'Layout/uris'
import React from 'react'
import { SportItemSetType } from 'services/api/graphql'

import SportItemSetsViewBase from './SportItemSetsViewBase'

const PlaylistsView = () => {
  return (
    <SportItemSetsViewBase
      type={SportItemSetType.Playlist}
      sportItemUrlFormat={applicationUris.playlistItems('{{id}}')}
      sportItemSetUrlFormat={applicationUris.playlist('{{id}}')}
    />
  )
}

export default PlaylistsView
