import { QueryHookOptions, useMutation, useQuery } from '@apollo/client'
import {
  CreateSportInput,
  CREATE_SPORT_MUTATION,
  DELETE_SPORT_MUTATION,
  GET_SPORTS_QUERY,
  GET_SPORTS_SHORT_QUERY,
  GET_SPORT_QUERY,
  MoveFrontPageInput,
  MOVE_SPORT_FRONT_PAGE_MUTATION,
  Sport,
  UpdateSportInput,
  UPDATE_SPORT_MUTATION,
} from 'services/api/graphql'

/**
 * Hooks
 */
export type GetSportResult = {
  sport: Sport | undefined
}
export const useGetSport = (sportId: Sport['id'], options?: QueryHookOptions<GetSportResult>) => {
  const { data, ...rest } = useQuery<GetSportResult>(GET_SPORT_QUERY, {
    variables: { sportId },
    ...options,
  })
  return { sport: data && data.sport, ...rest }
}

export type GetSportsResult = {
  sports: {
    sports: Sport[]
    totalCount: number
  }
}
export const useGetSports = (options?: QueryHookOptions<GetSportsResult>) => {
  const { data, ...rest } = useQuery(GET_SPORTS_QUERY, options)

  return { sports: data && data.sports && data.sports.sports, ...rest }
}

export type SportShort = {
  id: Sport['id']
  title: string
}
export type GetSportsShortResult = {
  sports: {
    sports: SportShort[]
    totalCount: number
  }
}
export const useGetSportsShort = (options?: QueryHookOptions<GetSportsShortResult>) =>
  useQuery(GET_SPORTS_SHORT_QUERY, options)

export const useCreateSport = () =>
  useMutation<{ createSport: Sport }, { input: CreateSportInput }>(CREATE_SPORT_MUTATION)
export const useUpdateSport = () =>
  useMutation<{ updateSport: Sport }, { input: UpdateSportInput }>(UPDATE_SPORT_MUTATION)
export const useDeleteSport = () => useMutation<{ deleteSport: Sport }, { sportId: Sport['id'] }>(DELETE_SPORT_MUTATION)

export const useMoveSportFrontPage = () => {
  return useMutation<{ moveSportFrontPage: Sport[] }, { input: MoveFrontPageInput }>(MOVE_SPORT_FRONT_PAGE_MUTATION)
}
