import React from 'react'
import { Outlet } from 'react-router-dom'
import styled from 'styled-components'

import SideBar from './SideBar'
import TopBar from './TopBar'

const Screen = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-height: 100vh;
`

const Content = styled.div`
  flex: 1;
  display: flex;
  overflow: hidden;
`

const PageContent = styled.div`
  flex: 1;
  display: flex;
  overflow: auto;
  flex-direction: column;
  position: relative;
`

const LeftSideBar = styled(SideBar)`
  overflow: auto;
`

const DashboardLayout = () => {
  return (
    <Screen>
      <TopBar />
      <Content>
        <LeftSideBar />
        <PageContent>
          <Outlet />
        </PageContent>
      </Content>
    </Screen>
  )
}

export default DashboardLayout
