import { applicationUris } from 'Layout/uris'
import { Permission, useApplicationsQuery } from 'models'
import React, { useEffect, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Dropdown, Message } from 'semantic-ui-react'
import { useCurrentUser, useStore } from 'stores/useStores'

interface SelectApplicationInputProps {
  isCustomPageCreate?: boolean
}

const SelectApplicationInput = ({ isCustomPageCreate }: SelectApplicationInputProps) => {
  const store = useStore()
  const currentUser = useCurrentUser()
  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const applicationId = searchParams.get('applicationId')

  const { data, loading } = useApplicationsQuery({
    variables: {
      filters: {
        organismId: store.organismId || undefined,
        multiOrganism: !store.organismId,
      },
    },
    fetchPolicy: 'cache-and-network',
  })

  const applications = useMemo(() => {
    const applicationsToShow = data?.applications?.applications ?? []

    if (!currentUser.can(Permission.ApplicationReadUnpublished))
      return applicationsToShow.filter(application => application.publicationStatus === 'published')

    return applicationsToShow
  }, [currentUser, data?.applications?.applications])

  const defaultApplicationIdValue = applications?.[0]?.id

  useEffect(() => {
    if (!applicationId && !isCustomPageCreate && defaultApplicationIdValue) {
      navigate(
        {
          pathname: applicationUris.customPages,
          search: `applicationId=${defaultApplicationIdValue}`,
        },
        { replace: true },
      )
    }
  }, [applicationId, defaultApplicationIdValue, isCustomPageCreate, navigate])

  const dropdownOptions = applications.map(app => ({
    key: app.applicationId,
    value: app.id,
    text: app.title,
  }))

  const handleChange = (value: string) => {
    navigate({
      pathname: isCustomPageCreate ? applicationUris.customPageCreate : applicationUris.customPages,
      search: `applicationId=${value}`,
    })
  }

  return (
    <>
      {!dropdownOptions.length && !loading && (
        <Link to={applicationUris.applications}>
          <Message error>
            <FormattedMessage id="customPage.create_application" />
          </Message>
        </Link>
      )}
      {dropdownOptions.length > 0 && (
        <Dropdown
          selection
          onChange={(e, data) => {
            e.preventDefault
            handleChange(data.value as string)
          }}
          options={dropdownOptions}
          value={applicationId as string}
        />
      )}
    </>
  )
}

export default SelectApplicationInput
