import GenericForm from 'Components/Form/GenericForm'
import SAFormField from 'Components/Form/SAFormField'
import SelectItemListInput from 'Components/Form/SelectItemListInput'
import SelectOrganismsInput from 'Containers/Form/SelectOrganismsInput'
import SelectCountryInput from 'Forms/SelectCountryInput'
import { format } from 'date-fns'
import { FormApi, ValidationErrors } from 'final-form'

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FieldInputProps, FieldRenderProps } from 'react-final-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { TimeInput } from 'semantic-ui-calendar-react'
import { DropdownItemProps, Form } from 'semantic-ui-react'
import { MatureFilter, RestrictionType, RestrictionTargetFilter } from 'services/api/graphql'
import styled from 'styled-components'
import { validateRequired } from 'tools/formValidators'

import { LegalRestriction } from 'types/legalRestriction'

import AddOffersInput from './AddOffersInput'
import AddSportItemSetsWithSportItemsInput from './AddSportItemSetsWithSportItemsInput'
import SelectRestrictionTypeInput from './SelectRestrictionTypeInput'

const FormFieldset = styled.div`
  min-height: 22rem;
`

export interface LegalRestrictionFormValue {
  countries: string[]
  mature?: MatureFilter | null
  organismId?: string | null
  sportItemSetIds?: string[] | null
  sportItemIds?: string[] | null
  offerIds?: string[] | null
  restrictionTarget?: RestrictionTargetFilter
  sportItemSets?: LegalRestriction['sportItemSets']
  description?: string | null
  type: RestrictionType
  from?: Date
  to?: Date
}

interface LegalRestrictionFormProps {
  open: boolean
  value?: LegalRestrictionFormValue
  onSubmit: (values: LegalRestrictionFormValue) => void
  onCancel: () => void
}

export enum RestrictionTarget {
  matureFree = 'matureFree',
  maturePaid = 'maturePaid',
  organismId = 'organismId',
  sportItemSetIds = 'sportItemSetIds',
  offerIds = 'offerIds',
}

const LegalRestrictionForm: React.FC<LegalRestrictionFormProps> = ({ open, value, onSubmit, onCancel }) => {
  const intl = useIntl()
  const initialValues = useMemo<LegalRestrictionFormValue>(
    () => (value ? { ...value } : { countries: [], type: RestrictionType.Forbidden }),
    [value],
  )

  const targetOptions: DropdownItemProps[] = [
    {
      key: RestrictionTarget.matureFree,
      value: RestrictionTarget.matureFree,
      text: intl.formatMessage({ id: 'legalRestriction.mature.free' }),
    },
    {
      key: RestrictionTarget.maturePaid,
      value: RestrictionTarget.maturePaid,
      text: intl.formatMessage({ id: 'legalRestriction.mature.paid' }),
    },
    {
      key: RestrictionTarget.organismId,
      value: RestrictionTarget.organismId,
      text: intl.formatMessage({ id: 'legalRestriction.targetOrganism' }),
    },
    {
      key: RestrictionTarget.sportItemSetIds,
      value: RestrictionTarget.sportItemSetIds,
      text: intl.formatMessage({ id: 'legalRestriction.targetSportItemSet' }),
    },
    {
      key: RestrictionTarget.offerIds,
      value: RestrictionTarget.offerIds,
      text: intl.formatMessage({ id: 'legalRestriction.offer' }),
    },
  ]

  const [selectedTarget, setSelectedTarget] = useState<RestrictionTarget | undefined>()

  const onChangeTarget = (target: RestrictionTarget, form: FormApi<LegalRestrictionFormValue>) => {
    setSelectedTarget(target)
    switch (target) {
      case RestrictionTarget.organismId:
      case RestrictionTarget.sportItemSetIds:
        form.change('mature', null)
        form.change('offerIds', null)
        break
      case RestrictionTarget.offerIds:
        form.change('mature', null)
        form.change('sportItemSetIds', null)
        form.change('sportItemIds', null)
        break
      case RestrictionTarget.matureFree:
      case RestrictionTarget.maturePaid:
        const mature = target === RestrictionTarget.matureFree ? MatureFilter.Free : MatureFilter.Paid
        form.change('mature', mature)
        form.change('organismId', null)
        form.change('sportItemSetIds', null)
        form.change('sportItemIds', null)
        form.change('offerIds', null)
        break
    }
  }

  useEffect(() => {
    if (value) {
      if (value.mature) {
        setSelectedTarget(
          value.mature === MatureFilter.Free ? RestrictionTarget.matureFree : RestrictionTarget.maturePaid,
        )
      } else if (value.sportItemSetIds && value.sportItemSetIds.length > 0) {
        setSelectedTarget(RestrictionTarget.sportItemSetIds)
      } else if (value.offerIds && value.offerIds.length > 0) {
        setSelectedTarget(RestrictionTarget.offerIds)
      } else {
        setSelectedTarget(RestrictionTarget.organismId)
      }
    } else {
      setSelectedTarget(undefined)
    }
  }, [value])

  const onTimeChange = (timeStr: string, input: FieldInputProps<string | undefined, HTMLElement>) => {
    const date = new Date()
    const [hours, minutes] = timeStr.split(':')
    date.setHours(Number(hours), Number(minutes))

    input.onChange(date)
  }

  const validate: (v: LegalRestrictionFormValue) => ValidationErrors | undefined = useCallback(
    ({ mature, organismId, sportItemSetIds, offerIds }) => {
      const errors: ValidationErrors = {}
      if (!mature && !organismId && !offerIds && (!sportItemSetIds || sportItemSetIds.length === 0)) {
        errors.target = true
      }
      return errors
    },
    [],
  )

  return (
    <GenericForm
      open={open}
      loading={false}
      initialValues={initialValues}
      onSubmit={onSubmit}
      onCancel={onCancel}
      header={intl.formatMessage({
        id: `legalRestriction.${value ? 'edit' : 'create'}`,
      })}
      validate={validate}
    >
      {({ values, form }) => (
        <FormFieldset>
          <SAFormField
            name="countries"
            validate={validateRequired}
            render={({ input }) => (
              <Form.Field required>
                <label>
                  <FormattedMessage id="legalRestriction.countries" />
                </label>
                <SelectCountryInput {...input} multiple={true} />
              </Form.Field>
            )}
          />
          <Form.Field required>
            <label>
              <FormattedMessage id="legalRestriction.target" />
            </label>
            <SelectItemListInput
              options={targetOptions}
              value={selectedTarget}
              onChange={target => {
                onChangeTarget(target, form)
                setSelectedTarget(target)
              }}
            />
          </Form.Field>

          {selectedTarget &&
            [RestrictionTarget.organismId, RestrictionTarget.sportItemSetIds, RestrictionTarget.offerIds].includes(
              selectedTarget,
            ) && (
              <SAFormField
                name="organismId"
                validate={validateRequired}
                render={({ input }) => (
                  <Form.Field
                    label={intl.formatMessage({
                      id: 'legalRestriction.organismId',
                    })}
                    required
                    control={SelectOrganismsInput}
                    {...input}
                    onChange={(organismId: string) => {
                      input.onChange(organismId)
                      form.change('sportItemSetIds', null)
                      form.change('sportItemIds', null)
                    }}
                  />
                )}
              />
            )}

          {selectedTarget === RestrictionTarget.sportItemSetIds && (
            <SAFormField
              name="sportItemSetIds"
              validate={validateRequired}
              render={({ input }) => (
                <AddSportItemSetsWithSportItemsInput
                  form={form}
                  input={input}
                  values={values}
                  initialValues={initialValues}
                />
              )}
            />
          )}
          {selectedTarget === RestrictionTarget.offerIds && (
            <SAFormField
              name="offerIds"
              validate={validateRequired}
              render={({ input }) => (
                <AddOffersInput form={form} input={input} values={values} initialValues={initialValues} />
              )}
            />
          )}
          <SAFormField
            name="type"
            render={({ input }) => (
              <Form.Field
                label={intl.formatMessage({ id: 'legalRestriction.type' })}
                required
                target={selectedTarget}
                control={SelectRestrictionTypeInput}
                {...input}
              />
            )}
          />

          {values.type === RestrictionType.TimeSlots && selectedTarget !== RestrictionTarget.offerIds && (
            <>
              <Form.Group>
                <SAFormField
                  name="from"
                  validate={validateRequired}
                  render={({ input }: FieldRenderProps<string | undefined, HTMLElement>) => (
                    <Form.Field required>
                      <label>
                        <FormattedMessage id="legalRestriction.timeSlots.from" />
                      </label>
                      <TimeInput
                        {...input}
                        value={input.value ? format(input.value, 'HH:mm') : ''}
                        onChange={(e, data) => onTimeChange(data.value, input)}
                        closable
                      />
                    </Form.Field>
                  )}
                />

                <SAFormField
                  name="to"
                  validate={validateRequired}
                  render={({ input }: FieldRenderProps<string | undefined, HTMLElement>) => (
                    <Form.Field required>
                      <label>
                        <FormattedMessage id="legalRestriction.timeSlots.to" />
                      </label>
                      <TimeInput
                        {...input}
                        value={input.value ? format(input.value, 'HH:mm') : ''}
                        onChange={(e, data) => onTimeChange(data.value, input)}
                        closable
                      />
                    </Form.Field>
                  )}
                />
              </Form.Group>
              <FormattedMessage id="legalRestriction.timeSlots.details" />
            </>
          )}

          <SAFormField
            name="description"
            render={({ input }) => (
              <Form.Field>
                <label>
                  <FormattedMessage id="legalRestriction.description" />
                </label>
                <Form.Input {...input} />
              </Form.Field>
            )}
          />
        </FormFieldset>
      )}
    </GenericForm>
  )
}

export default LegalRestrictionForm
