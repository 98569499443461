import { DecoratorBlockNode, SerializedDecoratorBlockNode } from '@lexical/react/LexicalDecoratorBlockNode'
import {
  COMMAND_PRIORITY_LOW,
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
  EditorConfig,
  ElementFormatType,
  LexicalEditor,
  LexicalNode,
  NodeKey,
  Spread,
} from 'lexical'
import React from 'react'

import YoutubeElement from './YoutubeElement'

export type SerializedYouTubeNode = Spread<
  {
    videoID: string
    type: 'youtube'
    version: 1
  },
  SerializedDecoratorBlockNode
>

export const $createYouTubeNode = (videoID: string) => new YouTubeNode(videoID, 'center')

export const $isYouTubeNode = (node: YouTubeNode | LexicalNode | null | undefined): node is YouTubeNode =>
  node instanceof YouTubeNode

export class YouTubeNode extends DecoratorBlockNode {
  __id: string

  static getType(): string {
    return 'youtube'
  }

  static clone(node: YouTubeNode): YouTubeNode {
    return new YouTubeNode(node.__id, node.__format, node.__key)
  }

  static importJSON(serializedNode: SerializedYouTubeNode): YouTubeNode {
    const node = $createYouTubeNode(serializedNode.videoID)
    node.setFormat(serializedNode.format)
    return node
  }

  exportJSON(): SerializedYouTubeNode {
    return {
      ...super.exportJSON(),
      type: 'youtube',
      version: 1,
      videoID: this.__id,
    }
  }

  static importDOM(): DOMConversionMap | null {
    return {
      iframe: (domNode: HTMLElement) => {
        if (!domNode.hasAttribute('data-lexical-youtube')) {
          return null
        }
        return {
          conversion: (domNode: HTMLElement): null | DOMConversionOutput => {
            const videoID = domNode.getAttribute('data-lexical-youtube')
            if (videoID) {
              const node = $createYouTubeNode(videoID)
              return { node }
            }
            return null
          },
          priority: COMMAND_PRIORITY_LOW,
        }
      },
    }
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement('iframe')
    element.setAttribute('data-lexical-youtube', this.__id)
    element.setAttribute('width', '560')
    element.setAttribute('height', '315')
    element.setAttribute('src', `https://www.youtube.com/embed/${this.__id}`)
    element.setAttribute('frameborder', '0')
    element.setAttribute(
      'allow',
      'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture',
    )
    element.setAttribute('allowfullscreen', 'true')
    element.setAttribute('title', 'YouTube video')
    return { element }
  }

  constructor(id: string, format?: ElementFormatType, key?: NodeKey) {
    super(format, key)
    this.__id = id
  }

  updateDOM(): false {
    return false
  }

  getId(): string {
    return this.__id
  }

  getTextContent(_includeInert?: boolean | undefined, _includeDirectionless?: false | undefined): string {
    return `https://www.youtube.com/watch?v=${this.__id}`
  }

  getFormatType(): ElementFormatType {
    return this.__format
  }

  decorate(editor: LexicalEditor, config: EditorConfig): JSX.Element {
    const theme = config.theme.embedBlock || {}
    const className = {
      base: theme.base || '',
      focus: theme.focus || '',
    }
    return <YoutubeElement className={className} format={this.__format} nodeKey={this.getKey()} videoID={this.__id} />
  }

  isInline(): false {
    return false
  }
}
